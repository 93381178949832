/* Do not remove the comments below. It's the markers used by wiredep to inject
sass dependencies when defined in the bower.json of your dependencies */
/* MaterialDesignIcons.com */
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=1.9.32");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=1.9.32") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=1.9.32") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=1.9.32") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=1.9.32") format("truetype"), url("../fonts/materialdesignicons-webfont.svg?v=1.9.32#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-access-point:before {
  content: "\F002"; }

.mdi-access-point-network:before {
  content: "\F003"; }

.mdi-account:before {
  content: "\F004"; }

.mdi-account-alert:before {
  content: "\F005"; }

.mdi-account-box:before {
  content: "\F006"; }

.mdi-account-box-outline:before {
  content: "\F007"; }

.mdi-account-card-details:before {
  content: "\F5D2"; }

.mdi-account-check:before {
  content: "\F008"; }

.mdi-account-circle:before {
  content: "\F009"; }

.mdi-account-convert:before {
  content: "\F00A"; }

.mdi-account-edit:before {
  content: "\F6BB"; }

.mdi-account-key:before {
  content: "\F00B"; }

.mdi-account-location:before {
  content: "\F00C"; }

.mdi-account-minus:before {
  content: "\F00D"; }

.mdi-account-multiple:before {
  content: "\F00E"; }

.mdi-account-multiple-minus:before {
  content: "\F5D3"; }

.mdi-account-multiple-outline:before {
  content: "\F00F"; }

.mdi-account-multiple-plus:before {
  content: "\F010"; }

.mdi-account-network:before {
  content: "\F011"; }

.mdi-account-off:before {
  content: "\F012"; }

.mdi-account-outline:before {
  content: "\F013"; }

.mdi-account-plus:before {
  content: "\F014"; }

.mdi-account-remove:before {
  content: "\F015"; }

.mdi-account-search:before {
  content: "\F016"; }

.mdi-account-settings:before {
  content: "\F630"; }

.mdi-account-settings-variant:before {
  content: "\F631"; }

.mdi-account-star:before {
  content: "\F017"; }

.mdi-account-switch:before {
  content: "\F019"; }

.mdi-adjust:before {
  content: "\F01A"; }

.mdi-air-conditioner:before {
  content: "\F01B"; }

.mdi-airballoon:before {
  content: "\F01C"; }

.mdi-airplane:before {
  content: "\F01D"; }

.mdi-airplane-landing:before {
  content: "\F5D4"; }

.mdi-airplane-off:before {
  content: "\F01E"; }

.mdi-airplane-takeoff:before {
  content: "\F5D5"; }

.mdi-airplay:before {
  content: "\F01F"; }

.mdi-alarm:before {
  content: "\F020"; }

.mdi-alarm-check:before {
  content: "\F021"; }

.mdi-alarm-multiple:before {
  content: "\F022"; }

.mdi-alarm-off:before {
  content: "\F023"; }

.mdi-alarm-plus:before {
  content: "\F024"; }

.mdi-alarm-snooze:before {
  content: "\F68D"; }

.mdi-album:before {
  content: "\F025"; }

.mdi-alert:before {
  content: "\F026"; }

.mdi-alert-box:before {
  content: "\F027"; }

.mdi-alert-circle:before {
  content: "\F028"; }

.mdi-alert-circle-outline:before {
  content: "\F5D6"; }

.mdi-alert-decagram:before {
  content: "\F6BC"; }

.mdi-alert-octagon:before {
  content: "\F029"; }

.mdi-alert-octagram:before {
  content: "\F766"; }

.mdi-alert-outline:before {
  content: "\F02A"; }

.mdi-all-inclusive:before {
  content: "\F6BD"; }

.mdi-alpha:before {
  content: "\F02B"; }

.mdi-alphabetical:before {
  content: "\F02C"; }

.mdi-altimeter:before {
  content: "\F5D7"; }

.mdi-amazon:before {
  content: "\F02D"; }

.mdi-amazon-clouddrive:before {
  content: "\F02E"; }

.mdi-ambulance:before {
  content: "\F02F"; }

.mdi-amplifier:before {
  content: "\F030"; }

.mdi-anchor:before {
  content: "\F031"; }

.mdi-android:before {
  content: "\F032"; }

.mdi-android-debug-bridge:before {
  content: "\F033"; }

.mdi-android-studio:before {
  content: "\F034"; }

.mdi-angular:before {
  content: "\F6B1"; }

.mdi-angularjs:before {
  content: "\F6BE"; }

.mdi-animation:before {
  content: "\F5D8"; }

.mdi-apple:before {
  content: "\F035"; }

.mdi-apple-finder:before {
  content: "\F036"; }

.mdi-apple-ios:before {
  content: "\F037"; }

.mdi-apple-keyboard-caps:before {
  content: "\F632"; }

.mdi-apple-keyboard-command:before {
  content: "\F633"; }

.mdi-apple-keyboard-control:before {
  content: "\F634"; }

.mdi-apple-keyboard-option:before {
  content: "\F635"; }

.mdi-apple-keyboard-shift:before {
  content: "\F636"; }

.mdi-apple-mobileme:before {
  content: "\F038"; }

.mdi-apple-safari:before {
  content: "\F039"; }

.mdi-application:before {
  content: "\F614"; }

.mdi-apps:before {
  content: "\F03B"; }

.mdi-archive:before {
  content: "\F03C"; }

.mdi-arrange-bring-forward:before {
  content: "\F03D"; }

.mdi-arrange-bring-to-front:before {
  content: "\F03E"; }

.mdi-arrange-send-backward:before {
  content: "\F03F"; }

.mdi-arrange-send-to-back:before {
  content: "\F040"; }

.mdi-arrow-all:before {
  content: "\F041"; }

.mdi-arrow-bottom-left:before {
  content: "\F042"; }

.mdi-arrow-bottom-right:before {
  content: "\F043"; }

.mdi-arrow-compress:before {
  content: "\F615"; }

.mdi-arrow-compress-all:before {
  content: "\F044"; }

.mdi-arrow-down:before {
  content: "\F045"; }

.mdi-arrow-down-bold:before {
  content: "\F72D"; }

.mdi-arrow-down-bold-box:before {
  content: "\F72E"; }

.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F"; }

.mdi-arrow-down-bold-circle:before {
  content: "\F047"; }

.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048"; }

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049"; }

.mdi-arrow-down-box:before {
  content: "\F6BF"; }

.mdi-arrow-down-drop-circle:before {
  content: "\F04A"; }

.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B"; }

.mdi-arrow-down-thick:before {
  content: "\F046"; }

.mdi-arrow-expand:before {
  content: "\F616"; }

.mdi-arrow-expand-all:before {
  content: "\F04C"; }

.mdi-arrow-left:before {
  content: "\F04D"; }

.mdi-arrow-left-bold:before {
  content: "\F730"; }

.mdi-arrow-left-bold-box:before {
  content: "\F731"; }

.mdi-arrow-left-bold-box-outline:before {
  content: "\F732"; }

.mdi-arrow-left-bold-circle:before {
  content: "\F04F"; }

.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050"; }

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051"; }

.mdi-arrow-left-box:before {
  content: "\F6C0"; }

.mdi-arrow-left-drop-circle:before {
  content: "\F052"; }

.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053"; }

.mdi-arrow-left-thick:before {
  content: "\F04E"; }

.mdi-arrow-right:before {
  content: "\F054"; }

.mdi-arrow-right-bold:before {
  content: "\F733"; }

.mdi-arrow-right-bold-box:before {
  content: "\F734"; }

.mdi-arrow-right-bold-box-outline:before {
  content: "\F735"; }

.mdi-arrow-right-bold-circle:before {
  content: "\F056"; }

.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057"; }

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058"; }

.mdi-arrow-right-box:before {
  content: "\F6C1"; }

.mdi-arrow-right-drop-circle:before {
  content: "\F059"; }

.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A"; }

.mdi-arrow-right-thick:before {
  content: "\F055"; }

.mdi-arrow-top-left:before {
  content: "\F05B"; }

.mdi-arrow-top-right:before {
  content: "\F05C"; }

.mdi-arrow-up:before {
  content: "\F05D"; }

.mdi-arrow-up-bold:before {
  content: "\F736"; }

.mdi-arrow-up-bold-box:before {
  content: "\F737"; }

.mdi-arrow-up-bold-box-outline:before {
  content: "\F738"; }

.mdi-arrow-up-bold-circle:before {
  content: "\F05F"; }

.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060"; }

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061"; }

.mdi-arrow-up-box:before {
  content: "\F6C2"; }

.mdi-arrow-up-drop-circle:before {
  content: "\F062"; }

.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063"; }

.mdi-arrow-up-thick:before {
  content: "\F05E"; }

.mdi-assistant:before {
  content: "\F064"; }

.mdi-asterisk:before {
  content: "\F6C3"; }

.mdi-at:before {
  content: "\F065"; }

.mdi-atom:before {
  content: "\F767"; }

.mdi-attachment:before {
  content: "\F066"; }

.mdi-audiobook:before {
  content: "\F067"; }

.mdi-auto-fix:before {
  content: "\F068"; }

.mdi-auto-upload:before {
  content: "\F069"; }

.mdi-autorenew:before {
  content: "\F06A"; }

.mdi-av-timer:before {
  content: "\F06B"; }

.mdi-baby:before {
  content: "\F06C"; }

.mdi-baby-buggy:before {
  content: "\F68E"; }

.mdi-backburger:before {
  content: "\F06D"; }

.mdi-backspace:before {
  content: "\F06E"; }

.mdi-backup-restore:before {
  content: "\F06F"; }

.mdi-bandcamp:before {
  content: "\F674"; }

.mdi-bank:before {
  content: "\F070"; }

.mdi-barcode:before {
  content: "\F071"; }

.mdi-barcode-scan:before {
  content: "\F072"; }

.mdi-barley:before {
  content: "\F073"; }

.mdi-barrel:before {
  content: "\F074"; }

.mdi-basecamp:before {
  content: "\F075"; }

.mdi-basket:before {
  content: "\F076"; }

.mdi-basket-fill:before {
  content: "\F077"; }

.mdi-basket-unfill:before {
  content: "\F078"; }

.mdi-battery:before {
  content: "\F079"; }

.mdi-battery-10:before {
  content: "\F07A"; }

.mdi-battery-20:before {
  content: "\F07B"; }

.mdi-battery-30:before {
  content: "\F07C"; }

.mdi-battery-40:before {
  content: "\F07D"; }

.mdi-battery-50:before {
  content: "\F07E"; }

.mdi-battery-60:before {
  content: "\F07F"; }

.mdi-battery-70:before {
  content: "\F080"; }

.mdi-battery-80:before {
  content: "\F081"; }

.mdi-battery-90:before {
  content: "\F082"; }

.mdi-battery-alert:before {
  content: "\F083"; }

.mdi-battery-charging:before {
  content: "\F084"; }

.mdi-battery-charging-100:before {
  content: "\F085"; }

.mdi-battery-charging-20:before {
  content: "\F086"; }

.mdi-battery-charging-30:before {
  content: "\F087"; }

.mdi-battery-charging-40:before {
  content: "\F088"; }

.mdi-battery-charging-60:before {
  content: "\F089"; }

.mdi-battery-charging-80:before {
  content: "\F08A"; }

.mdi-battery-charging-90:before {
  content: "\F08B"; }

.mdi-battery-minus:before {
  content: "\F08C"; }

.mdi-battery-negative:before {
  content: "\F08D"; }

.mdi-battery-outline:before {
  content: "\F08E"; }

.mdi-battery-plus:before {
  content: "\F08F"; }

.mdi-battery-positive:before {
  content: "\F090"; }

.mdi-battery-unknown:before {
  content: "\F091"; }

.mdi-beach:before {
  content: "\F092"; }

.mdi-beaker:before {
  content: "\F68F"; }

.mdi-beats:before {
  content: "\F097"; }

.mdi-beer:before {
  content: "\F098"; }

.mdi-behance:before {
  content: "\F099"; }

.mdi-bell:before {
  content: "\F09A"; }

.mdi-bell-off:before {
  content: "\F09B"; }

.mdi-bell-outline:before {
  content: "\F09C"; }

.mdi-bell-plus:before {
  content: "\F09D"; }

.mdi-bell-ring:before {
  content: "\F09E"; }

.mdi-bell-ring-outline:before {
  content: "\F09F"; }

.mdi-bell-sleep:before {
  content: "\F0A0"; }

.mdi-beta:before {
  content: "\F0A1"; }

.mdi-bible:before {
  content: "\F0A2"; }

.mdi-bike:before {
  content: "\F0A3"; }

.mdi-bing:before {
  content: "\F0A4"; }

.mdi-binoculars:before {
  content: "\F0A5"; }

.mdi-bio:before {
  content: "\F0A6"; }

.mdi-biohazard:before {
  content: "\F0A7"; }

.mdi-bitbucket:before {
  content: "\F0A8"; }

.mdi-black-mesa:before {
  content: "\F0A9"; }

.mdi-blackberry:before {
  content: "\F0AA"; }

.mdi-blender:before {
  content: "\F0AB"; }

.mdi-blinds:before {
  content: "\F0AC"; }

.mdi-block-helper:before {
  content: "\F0AD"; }

.mdi-blogger:before {
  content: "\F0AE"; }

.mdi-bluetooth:before {
  content: "\F0AF"; }

.mdi-bluetooth-audio:before {
  content: "\F0B0"; }

.mdi-bluetooth-connect:before {
  content: "\F0B1"; }

.mdi-bluetooth-off:before {
  content: "\F0B2"; }

.mdi-bluetooth-settings:before {
  content: "\F0B3"; }

.mdi-bluetooth-transfer:before {
  content: "\F0B4"; }

.mdi-blur:before {
  content: "\F0B5"; }

.mdi-blur-linear:before {
  content: "\F0B6"; }

.mdi-blur-off:before {
  content: "\F0B7"; }

.mdi-blur-radial:before {
  content: "\F0B8"; }

.mdi-bomb:before {
  content: "\F690"; }

.mdi-bomb-off:before {
  content: "\F6C4"; }

.mdi-bone:before {
  content: "\F0B9"; }

.mdi-book:before {
  content: "\F0BA"; }

.mdi-book-minus:before {
  content: "\F5D9"; }

.mdi-book-multiple:before {
  content: "\F0BB"; }

.mdi-book-multiple-variant:before {
  content: "\F0BC"; }

.mdi-book-open:before {
  content: "\F0BD"; }

.mdi-book-open-page-variant:before {
  content: "\F5DA"; }

.mdi-book-open-variant:before {
  content: "\F0BE"; }

.mdi-book-plus:before {
  content: "\F5DB"; }

.mdi-book-variant:before {
  content: "\F0BF"; }

.mdi-bookmark:before {
  content: "\F0C0"; }

.mdi-bookmark-check:before {
  content: "\F0C1"; }

.mdi-bookmark-music:before {
  content: "\F0C2"; }

.mdi-bookmark-outline:before {
  content: "\F0C3"; }

.mdi-bookmark-plus:before {
  content: "\F0C5"; }

.mdi-bookmark-plus-outline:before {
  content: "\F0C4"; }

.mdi-bookmark-remove:before {
  content: "\F0C6"; }

.mdi-boombox:before {
  content: "\F5DC"; }

.mdi-bootstrap:before {
  content: "\F6C5"; }

.mdi-border-all:before {
  content: "\F0C7"; }

.mdi-border-bottom:before {
  content: "\F0C8"; }

.mdi-border-color:before {
  content: "\F0C9"; }

.mdi-border-horizontal:before {
  content: "\F0CA"; }

.mdi-border-inside:before {
  content: "\F0CB"; }

.mdi-border-left:before {
  content: "\F0CC"; }

.mdi-border-none:before {
  content: "\F0CD"; }

.mdi-border-outside:before {
  content: "\F0CE"; }

.mdi-border-right:before {
  content: "\F0CF"; }

.mdi-border-style:before {
  content: "\F0D0"; }

.mdi-border-top:before {
  content: "\F0D1"; }

.mdi-border-vertical:before {
  content: "\F0D2"; }

.mdi-bow-tie:before {
  content: "\F677"; }

.mdi-bowl:before {
  content: "\F617"; }

.mdi-bowling:before {
  content: "\F0D3"; }

.mdi-box:before {
  content: "\F0D4"; }

.mdi-box-cutter:before {
  content: "\F0D5"; }

.mdi-box-shadow:before {
  content: "\F637"; }

.mdi-bridge:before {
  content: "\F618"; }

.mdi-briefcase:before {
  content: "\F0D6"; }

.mdi-briefcase-check:before {
  content: "\F0D7"; }

.mdi-briefcase-download:before {
  content: "\F0D8"; }

.mdi-briefcase-upload:before {
  content: "\F0D9"; }

.mdi-brightness-1:before {
  content: "\F0DA"; }

.mdi-brightness-2:before {
  content: "\F0DB"; }

.mdi-brightness-3:before {
  content: "\F0DC"; }

.mdi-brightness-4:before {
  content: "\F0DD"; }

.mdi-brightness-5:before {
  content: "\F0DE"; }

.mdi-brightness-6:before {
  content: "\F0DF"; }

.mdi-brightness-7:before {
  content: "\F0E0"; }

.mdi-brightness-auto:before {
  content: "\F0E1"; }

.mdi-broom:before {
  content: "\F0E2"; }

.mdi-brush:before {
  content: "\F0E3"; }

.mdi-buffer:before {
  content: "\F619"; }

.mdi-bug:before {
  content: "\F0E4"; }

.mdi-bulletin-board:before {
  content: "\F0E5"; }

.mdi-bullhorn:before {
  content: "\F0E6"; }

.mdi-bullseye:before {
  content: "\F5DD"; }

.mdi-burst-mode:before {
  content: "\F5DE"; }

.mdi-bus:before {
  content: "\F0E7"; }

.mdi-cached:before {
  content: "\F0E8"; }

.mdi-cake:before {
  content: "\F0E9"; }

.mdi-cake-layered:before {
  content: "\F0EA"; }

.mdi-cake-variant:before {
  content: "\F0EB"; }

.mdi-calculator:before {
  content: "\F0EC"; }

.mdi-calendar:before {
  content: "\F0ED"; }

.mdi-calendar-blank:before {
  content: "\F0EE"; }

.mdi-calendar-check:before {
  content: "\F0EF"; }

.mdi-calendar-clock:before {
  content: "\F0F0"; }

.mdi-calendar-multiple:before {
  content: "\F0F1"; }

.mdi-calendar-multiple-check:before {
  content: "\F0F2"; }

.mdi-calendar-plus:before {
  content: "\F0F3"; }

.mdi-calendar-question:before {
  content: "\F691"; }

.mdi-calendar-range:before {
  content: "\F678"; }

.mdi-calendar-remove:before {
  content: "\F0F4"; }

.mdi-calendar-text:before {
  content: "\F0F5"; }

.mdi-calendar-today:before {
  content: "\F0F6"; }

.mdi-call-made:before {
  content: "\F0F7"; }

.mdi-call-merge:before {
  content: "\F0F8"; }

.mdi-call-missed:before {
  content: "\F0F9"; }

.mdi-call-received:before {
  content: "\F0FA"; }

.mdi-call-split:before {
  content: "\F0FB"; }

.mdi-camcorder:before {
  content: "\F0FC"; }

.mdi-camcorder-box:before {
  content: "\F0FD"; }

.mdi-camcorder-box-off:before {
  content: "\F0FE"; }

.mdi-camcorder-off:before {
  content: "\F0FF"; }

.mdi-camera:before {
  content: "\F100"; }

.mdi-camera-burst:before {
  content: "\F692"; }

.mdi-camera-enhance:before {
  content: "\F101"; }

.mdi-camera-front:before {
  content: "\F102"; }

.mdi-camera-front-variant:before {
  content: "\F103"; }

.mdi-camera-iris:before {
  content: "\F104"; }

.mdi-camera-off:before {
  content: "\F5DF"; }

.mdi-camera-party-mode:before {
  content: "\F105"; }

.mdi-camera-rear:before {
  content: "\F106"; }

.mdi-camera-rear-variant:before {
  content: "\F107"; }

.mdi-camera-switch:before {
  content: "\F108"; }

.mdi-camera-timer:before {
  content: "\F109"; }

.mdi-cancel:before {
  content: "\F739"; }

.mdi-candle:before {
  content: "\F5E2"; }

.mdi-candycane:before {
  content: "\F10A"; }

.mdi-car:before {
  content: "\F10B"; }

.mdi-car-battery:before {
  content: "\F10C"; }

.mdi-car-connected:before {
  content: "\F10D"; }

.mdi-car-wash:before {
  content: "\F10E"; }

.mdi-cards:before {
  content: "\F638"; }

.mdi-cards-outline:before {
  content: "\F639"; }

.mdi-cards-playing-outline:before {
  content: "\F63A"; }

.mdi-cards-variant:before {
  content: "\F6C6"; }

.mdi-carrot:before {
  content: "\F10F"; }

.mdi-cart:before {
  content: "\F110"; }

.mdi-cart-off:before {
  content: "\F66B"; }

.mdi-cart-outline:before {
  content: "\F111"; }

.mdi-cart-plus:before {
  content: "\F112"; }

.mdi-case-sensitive-alt:before {
  content: "\F113"; }

.mdi-cash:before {
  content: "\F114"; }

.mdi-cash-100:before {
  content: "\F115"; }

.mdi-cash-multiple:before {
  content: "\F116"; }

.mdi-cash-usd:before {
  content: "\F117"; }

.mdi-cast:before {
  content: "\F118"; }

.mdi-cast-connected:before {
  content: "\F119"; }

.mdi-cast-off:before {
  content: "\F789"; }

.mdi-castle:before {
  content: "\F11A"; }

.mdi-cat:before {
  content: "\F11B"; }

.mdi-ceiling-light:before {
  content: "\F768"; }

.mdi-cellphone:before {
  content: "\F11C"; }

.mdi-cellphone-android:before {
  content: "\F11D"; }

.mdi-cellphone-basic:before {
  content: "\F11E"; }

.mdi-cellphone-dock:before {
  content: "\F11F"; }

.mdi-cellphone-iphone:before {
  content: "\F120"; }

.mdi-cellphone-link:before {
  content: "\F121"; }

.mdi-cellphone-link-off:before {
  content: "\F122"; }

.mdi-cellphone-settings:before {
  content: "\F123"; }

.mdi-certificate:before {
  content: "\F124"; }

.mdi-chair-school:before {
  content: "\F125"; }

.mdi-chart-arc:before {
  content: "\F126"; }

.mdi-chart-areaspline:before {
  content: "\F127"; }

.mdi-chart-bar:before {
  content: "\F128"; }

.mdi-chart-bar-stacked:before {
  content: "\F769"; }

.mdi-chart-bubble:before {
  content: "\F5E3"; }

.mdi-chart-gantt:before {
  content: "\F66C"; }

.mdi-chart-histogram:before {
  content: "\F129"; }

.mdi-chart-line:before {
  content: "\F12A"; }

.mdi-chart-line-stacked:before {
  content: "\F76A"; }

.mdi-chart-pie:before {
  content: "\F12B"; }

.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D"; }

.mdi-chart-timeline:before {
  content: "\F66E"; }

.mdi-check:before {
  content: "\F12C"; }

.mdi-check-all:before {
  content: "\F12D"; }

.mdi-check-circle:before {
  content: "\F5E0"; }

.mdi-check-circle-outline:before {
  content: "\F5E1"; }

.mdi-checkbox-blank:before {
  content: "\F12E"; }

.mdi-checkbox-blank-circle:before {
  content: "\F12F"; }

.mdi-checkbox-blank-circle-outline:before {
  content: "\F130"; }

.mdi-checkbox-blank-outline:before {
  content: "\F131"; }

.mdi-checkbox-marked:before {
  content: "\F132"; }

.mdi-checkbox-marked-circle:before {
  content: "\F133"; }

.mdi-checkbox-marked-circle-outline:before {
  content: "\F134"; }

.mdi-checkbox-marked-outline:before {
  content: "\F135"; }

.mdi-checkbox-multiple-blank:before {
  content: "\F136"; }

.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B"; }

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C"; }

.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137"; }

.mdi-checkbox-multiple-marked:before {
  content: "\F138"; }

.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D"; }

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E"; }

.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139"; }

.mdi-checkerboard:before {
  content: "\F13A"; }

.mdi-chemical-weapon:before {
  content: "\F13B"; }

.mdi-chevron-double-down:before {
  content: "\F13C"; }

.mdi-chevron-double-left:before {
  content: "\F13D"; }

.mdi-chevron-double-right:before {
  content: "\F13E"; }

.mdi-chevron-double-up:before {
  content: "\F13F"; }

.mdi-chevron-down:before {
  content: "\F140"; }

.mdi-chevron-left:before {
  content: "\F141"; }

.mdi-chevron-right:before {
  content: "\F142"; }

.mdi-chevron-up:before {
  content: "\F143"; }

.mdi-chip:before {
  content: "\F61A"; }

.mdi-church:before {
  content: "\F144"; }

.mdi-circle:before {
  content: "\F764"; }

.mdi-circle-outline:before {
  content: "\F765"; }

.mdi-cisco-webex:before {
  content: "\F145"; }

.mdi-city:before {
  content: "\F146"; }

.mdi-clipboard:before {
  content: "\F147"; }

.mdi-clipboard-account:before {
  content: "\F148"; }

.mdi-clipboard-alert:before {
  content: "\F149"; }

.mdi-clipboard-arrow-down:before {
  content: "\F14A"; }

.mdi-clipboard-arrow-left:before {
  content: "\F14B"; }

.mdi-clipboard-check:before {
  content: "\F14C"; }

.mdi-clipboard-flow:before {
  content: "\F6C7"; }

.mdi-clipboard-outline:before {
  content: "\F14D"; }

.mdi-clipboard-plus:before {
  content: "\F750"; }

.mdi-clipboard-text:before {
  content: "\F14E"; }

.mdi-clippy:before {
  content: "\F14F"; }

.mdi-clock:before {
  content: "\F150"; }

.mdi-clock-alert:before {
  content: "\F5CE"; }

.mdi-clock-end:before {
  content: "\F151"; }

.mdi-clock-fast:before {
  content: "\F152"; }

.mdi-clock-in:before {
  content: "\F153"; }

.mdi-clock-out:before {
  content: "\F154"; }

.mdi-clock-start:before {
  content: "\F155"; }

.mdi-close:before {
  content: "\F156"; }

.mdi-close-box:before {
  content: "\F157"; }

.mdi-close-box-outline:before {
  content: "\F158"; }

.mdi-close-circle:before {
  content: "\F159"; }

.mdi-close-circle-outline:before {
  content: "\F15A"; }

.mdi-close-network:before {
  content: "\F15B"; }

.mdi-close-octagon:before {
  content: "\F15C"; }

.mdi-close-octagon-outline:before {
  content: "\F15D"; }

.mdi-close-outline:before {
  content: "\F6C8"; }

.mdi-closed-caption:before {
  content: "\F15E"; }

.mdi-cloud:before {
  content: "\F15F"; }

.mdi-cloud-check:before {
  content: "\F160"; }

.mdi-cloud-circle:before {
  content: "\F161"; }

.mdi-cloud-download:before {
  content: "\F162"; }

.mdi-cloud-off-outline:before {
  content: "\F164"; }

.mdi-cloud-outline:before {
  content: "\F163"; }

.mdi-cloud-print:before {
  content: "\F165"; }

.mdi-cloud-print-outline:before {
  content: "\F166"; }

.mdi-cloud-sync:before {
  content: "\F63F"; }

.mdi-cloud-upload:before {
  content: "\F167"; }

.mdi-code-array:before {
  content: "\F168"; }

.mdi-code-braces:before {
  content: "\F169"; }

.mdi-code-brackets:before {
  content: "\F16A"; }

.mdi-code-equal:before {
  content: "\F16B"; }

.mdi-code-greater-than:before {
  content: "\F16C"; }

.mdi-code-greater-than-or-equal:before {
  content: "\F16D"; }

.mdi-code-less-than:before {
  content: "\F16E"; }

.mdi-code-less-than-or-equal:before {
  content: "\F16F"; }

.mdi-code-not-equal:before {
  content: "\F170"; }

.mdi-code-not-equal-variant:before {
  content: "\F171"; }

.mdi-code-parentheses:before {
  content: "\F172"; }

.mdi-code-string:before {
  content: "\F173"; }

.mdi-code-tags:before {
  content: "\F174"; }

.mdi-code-tags-check:before {
  content: "\F693"; }

.mdi-codepen:before {
  content: "\F175"; }

.mdi-coffee:before {
  content: "\F176"; }

.mdi-coffee-outline:before {
  content: "\F6C9"; }

.mdi-coffee-to-go:before {
  content: "\F177"; }

.mdi-coin:before {
  content: "\F178"; }

.mdi-coins:before {
  content: "\F694"; }

.mdi-collage:before {
  content: "\F640"; }

.mdi-color-helper:before {
  content: "\F179"; }

.mdi-comment:before {
  content: "\F17A"; }

.mdi-comment-account:before {
  content: "\F17B"; }

.mdi-comment-account-outline:before {
  content: "\F17C"; }

.mdi-comment-alert:before {
  content: "\F17D"; }

.mdi-comment-alert-outline:before {
  content: "\F17E"; }

.mdi-comment-check:before {
  content: "\F17F"; }

.mdi-comment-check-outline:before {
  content: "\F180"; }

.mdi-comment-multiple-outline:before {
  content: "\F181"; }

.mdi-comment-outline:before {
  content: "\F182"; }

.mdi-comment-plus-outline:before {
  content: "\F183"; }

.mdi-comment-processing:before {
  content: "\F184"; }

.mdi-comment-processing-outline:before {
  content: "\F185"; }

.mdi-comment-question-outline:before {
  content: "\F186"; }

.mdi-comment-remove-outline:before {
  content: "\F187"; }

.mdi-comment-text:before {
  content: "\F188"; }

.mdi-comment-text-outline:before {
  content: "\F189"; }

.mdi-compare:before {
  content: "\F18A"; }

.mdi-compass:before {
  content: "\F18B"; }

.mdi-compass-outline:before {
  content: "\F18C"; }

.mdi-console:before {
  content: "\F18D"; }

.mdi-contact-mail:before {
  content: "\F18E"; }

.mdi-contacts:before {
  content: "\F6CA"; }

.mdi-content-copy:before {
  content: "\F18F"; }

.mdi-content-cut:before {
  content: "\F190"; }

.mdi-content-duplicate:before {
  content: "\F191"; }

.mdi-content-paste:before {
  content: "\F192"; }

.mdi-content-save:before {
  content: "\F193"; }

.mdi-content-save-all:before {
  content: "\F194"; }

.mdi-content-save-settings:before {
  content: "\F61B"; }

.mdi-contrast:before {
  content: "\F195"; }

.mdi-contrast-box:before {
  content: "\F196"; }

.mdi-contrast-circle:before {
  content: "\F197"; }

.mdi-cookie:before {
  content: "\F198"; }

.mdi-copyright:before {
  content: "\F5E6"; }

.mdi-counter:before {
  content: "\F199"; }

.mdi-cow:before {
  content: "\F19A"; }

.mdi-creation:before {
  content: "\F1C9"; }

.mdi-credit-card:before {
  content: "\F19B"; }

.mdi-credit-card-multiple:before {
  content: "\F19C"; }

.mdi-credit-card-off:before {
  content: "\F5E4"; }

.mdi-credit-card-plus:before {
  content: "\F675"; }

.mdi-credit-card-scan:before {
  content: "\F19D"; }

.mdi-crop:before {
  content: "\F19E"; }

.mdi-crop-free:before {
  content: "\F19F"; }

.mdi-crop-landscape:before {
  content: "\F1A0"; }

.mdi-crop-portrait:before {
  content: "\F1A1"; }

.mdi-crop-rotate:before {
  content: "\F695"; }

.mdi-crop-square:before {
  content: "\F1A2"; }

.mdi-crosshairs:before {
  content: "\F1A3"; }

.mdi-crosshairs-gps:before {
  content: "\F1A4"; }

.mdi-crown:before {
  content: "\F1A5"; }

.mdi-cube:before {
  content: "\F1A6"; }

.mdi-cube-outline:before {
  content: "\F1A7"; }

.mdi-cube-send:before {
  content: "\F1A8"; }

.mdi-cube-unfolded:before {
  content: "\F1A9"; }

.mdi-cup:before {
  content: "\F1AA"; }

.mdi-cup-off:before {
  content: "\F5E5"; }

.mdi-cup-water:before {
  content: "\F1AB"; }

.mdi-currency-btc:before {
  content: "\F1AC"; }

.mdi-currency-eur:before {
  content: "\F1AD"; }

.mdi-currency-gbp:before {
  content: "\F1AE"; }

.mdi-currency-inr:before {
  content: "\F1AF"; }

.mdi-currency-ngn:before {
  content: "\F1B0"; }

.mdi-currency-rub:before {
  content: "\F1B1"; }

.mdi-currency-try:before {
  content: "\F1B2"; }

.mdi-currency-usd:before {
  content: "\F1B3"; }

.mdi-currency-usd-off:before {
  content: "\F679"; }

.mdi-cursor-default:before {
  content: "\F1B4"; }

.mdi-cursor-default-outline:before {
  content: "\F1B5"; }

.mdi-cursor-move:before {
  content: "\F1B6"; }

.mdi-cursor-pointer:before {
  content: "\F1B7"; }

.mdi-cursor-text:before {
  content: "\F5E7"; }

.mdi-database:before {
  content: "\F1B8"; }

.mdi-database-minus:before {
  content: "\F1B9"; }

.mdi-database-plus:before {
  content: "\F1BA"; }

.mdi-debug-step-into:before {
  content: "\F1BB"; }

.mdi-debug-step-out:before {
  content: "\F1BC"; }

.mdi-debug-step-over:before {
  content: "\F1BD"; }

.mdi-decagram:before {
  content: "\F76B"; }

.mdi-decagram-outline:before {
  content: "\F76C"; }

.mdi-decimal-decrease:before {
  content: "\F1BE"; }

.mdi-decimal-increase:before {
  content: "\F1BF"; }

.mdi-delete:before {
  content: "\F1C0"; }

.mdi-delete-circle:before {
  content: "\F682"; }

.mdi-delete-empty:before {
  content: "\F6CB"; }

.mdi-delete-forever:before {
  content: "\F5E8"; }

.mdi-delete-sweep:before {
  content: "\F5E9"; }

.mdi-delete-variant:before {
  content: "\F1C1"; }

.mdi-delta:before {
  content: "\F1C2"; }

.mdi-deskphone:before {
  content: "\F1C3"; }

.mdi-desktop-mac:before {
  content: "\F1C4"; }

.mdi-desktop-tower:before {
  content: "\F1C5"; }

.mdi-details:before {
  content: "\F1C6"; }

.mdi-developer-board:before {
  content: "\F696"; }

.mdi-deviantart:before {
  content: "\F1C7"; }

.mdi-dialpad:before {
  content: "\F61C"; }

.mdi-diamond:before {
  content: "\F1C8"; }

.mdi-dice-1:before {
  content: "\F1CA"; }

.mdi-dice-2:before {
  content: "\F1CB"; }

.mdi-dice-3:before {
  content: "\F1CC"; }

.mdi-dice-4:before {
  content: "\F1CD"; }

.mdi-dice-5:before {
  content: "\F1CE"; }

.mdi-dice-6:before {
  content: "\F1CF"; }

.mdi-dice-d10:before {
  content: "\F76E"; }

.mdi-dice-d20:before {
  content: "\F5EA"; }

.mdi-dice-d4:before {
  content: "\F5EB"; }

.mdi-dice-d6:before {
  content: "\F5EC"; }

.mdi-dice-d8:before {
  content: "\F5ED"; }

.mdi-dice-multiple:before {
  content: "\F76D"; }

.mdi-dictionary:before {
  content: "\F61D"; }

.mdi-directions:before {
  content: "\F1D0"; }

.mdi-directions-fork:before {
  content: "\F641"; }

.mdi-discord:before {
  content: "\F66F"; }

.mdi-disk:before {
  content: "\F5EE"; }

.mdi-disk-alert:before {
  content: "\F1D1"; }

.mdi-disqus:before {
  content: "\F1D2"; }

.mdi-disqus-outline:before {
  content: "\F1D3"; }

.mdi-division:before {
  content: "\F1D4"; }

.mdi-division-box:before {
  content: "\F1D5"; }

.mdi-dna:before {
  content: "\F683"; }

.mdi-dns:before {
  content: "\F1D6"; }

.mdi-do-not-disturb:before {
  content: "\F697"; }

.mdi-do-not-disturb-off:before {
  content: "\F698"; }

.mdi-dolby:before {
  content: "\F6B2"; }

.mdi-domain:before {
  content: "\F1D7"; }

.mdi-dots-horizontal:before {
  content: "\F1D8"; }

.mdi-dots-vertical:before {
  content: "\F1D9"; }

.mdi-douban:before {
  content: "\F699"; }

.mdi-download:before {
  content: "\F1DA"; }

.mdi-download-network:before {
  content: "\F6F3"; }

.mdi-drag:before {
  content: "\F1DB"; }

.mdi-drag-horizontal:before {
  content: "\F1DC"; }

.mdi-drag-vertical:before {
  content: "\F1DD"; }

.mdi-drawing:before {
  content: "\F1DE"; }

.mdi-drawing-box:before {
  content: "\F1DF"; }

.mdi-dribbble:before {
  content: "\F1E0"; }

.mdi-dribbble-box:before {
  content: "\F1E1"; }

.mdi-drone:before {
  content: "\F1E2"; }

.mdi-dropbox:before {
  content: "\F1E3"; }

.mdi-drupal:before {
  content: "\F1E4"; }

.mdi-duck:before {
  content: "\F1E5"; }

.mdi-dumbbell:before {
  content: "\F1E6"; }

.mdi-earth:before {
  content: "\F1E7"; }

.mdi-earth-box:before {
  content: "\F6CC"; }

.mdi-earth-box-off:before {
  content: "\F6CD"; }

.mdi-earth-off:before {
  content: "\F1E8"; }

.mdi-edge:before {
  content: "\F1E9"; }

.mdi-eject:before {
  content: "\F1EA"; }

.mdi-elevation-decline:before {
  content: "\F1EB"; }

.mdi-elevation-rise:before {
  content: "\F1EC"; }

.mdi-elevator:before {
  content: "\F1ED"; }

.mdi-email:before {
  content: "\F1EE"; }

.mdi-email-alert:before {
  content: "\F6CE"; }

.mdi-email-open:before {
  content: "\F1EF"; }

.mdi-email-open-outline:before {
  content: "\F5EF"; }

.mdi-email-outline:before {
  content: "\F1F0"; }

.mdi-email-secure:before {
  content: "\F1F1"; }

.mdi-email-variant:before {
  content: "\F5F0"; }

.mdi-emby:before {
  content: "\F6B3"; }

.mdi-emoticon:before {
  content: "\F1F2"; }

.mdi-emoticon-cool:before {
  content: "\F1F3"; }

.mdi-emoticon-dead:before {
  content: "\F69A"; }

.mdi-emoticon-devil:before {
  content: "\F1F4"; }

.mdi-emoticon-excited:before {
  content: "\F69B"; }

.mdi-emoticon-happy:before {
  content: "\F1F5"; }

.mdi-emoticon-neutral:before {
  content: "\F1F6"; }

.mdi-emoticon-poop:before {
  content: "\F1F7"; }

.mdi-emoticon-sad:before {
  content: "\F1F8"; }

.mdi-emoticon-tongue:before {
  content: "\F1F9"; }

.mdi-engine:before {
  content: "\F1FA"; }

.mdi-engine-outline:before {
  content: "\F1FB"; }

.mdi-equal:before {
  content: "\F1FC"; }

.mdi-equal-box:before {
  content: "\F1FD"; }

.mdi-eraser:before {
  content: "\F1FE"; }

.mdi-eraser-variant:before {
  content: "\F642"; }

.mdi-escalator:before {
  content: "\F1FF"; }

.mdi-ethernet:before {
  content: "\F200"; }

.mdi-ethernet-cable:before {
  content: "\F201"; }

.mdi-ethernet-cable-off:before {
  content: "\F202"; }

.mdi-etsy:before {
  content: "\F203"; }

.mdi-ev-station:before {
  content: "\F5F1"; }

.mdi-evernote:before {
  content: "\F204"; }

.mdi-exclamation:before {
  content: "\F205"; }

.mdi-exit-to-app:before {
  content: "\F206"; }

.mdi-export:before {
  content: "\F207"; }

.mdi-eye:before {
  content: "\F208"; }

.mdi-eye-off:before {
  content: "\F209"; }

.mdi-eye-off-outline:before {
  content: "\F6D0"; }

.mdi-eye-outline:before {
  content: "\F6CF"; }

.mdi-eyedropper:before {
  content: "\F20A"; }

.mdi-eyedropper-variant:before {
  content: "\F20B"; }

.mdi-face:before {
  content: "\F643"; }

.mdi-face-profile:before {
  content: "\F644"; }

.mdi-facebook:before {
  content: "\F20C"; }

.mdi-facebook-box:before {
  content: "\F20D"; }

.mdi-facebook-messenger:before {
  content: "\F20E"; }

.mdi-factory:before {
  content: "\F20F"; }

.mdi-fan:before {
  content: "\F210"; }

.mdi-fast-forward:before {
  content: "\F211"; }

.mdi-fast-forward-outline:before {
  content: "\F6D1"; }

.mdi-fax:before {
  content: "\F212"; }

.mdi-feather:before {
  content: "\F6D2"; }

.mdi-ferry:before {
  content: "\F213"; }

.mdi-file:before {
  content: "\F214"; }

.mdi-file-account:before {
  content: "\F73A"; }

.mdi-file-chart:before {
  content: "\F215"; }

.mdi-file-check:before {
  content: "\F216"; }

.mdi-file-cloud:before {
  content: "\F217"; }

.mdi-file-delimited:before {
  content: "\F218"; }

.mdi-file-document:before {
  content: "\F219"; }

.mdi-file-document-box:before {
  content: "\F21A"; }

.mdi-file-excel:before {
  content: "\F21B"; }

.mdi-file-excel-box:before {
  content: "\F21C"; }

.mdi-file-export:before {
  content: "\F21D"; }

.mdi-file-find:before {
  content: "\F21E"; }

.mdi-file-hidden:before {
  content: "\F613"; }

.mdi-file-image:before {
  content: "\F21F"; }

.mdi-file-import:before {
  content: "\F220"; }

.mdi-file-lock:before {
  content: "\F221"; }

.mdi-file-multiple:before {
  content: "\F222"; }

.mdi-file-music:before {
  content: "\F223"; }

.mdi-file-outline:before {
  content: "\F224"; }

.mdi-file-pdf:before {
  content: "\F225"; }

.mdi-file-pdf-box:before {
  content: "\F226"; }

.mdi-file-plus:before {
  content: "\F751"; }

.mdi-file-powerpoint:before {
  content: "\F227"; }

.mdi-file-powerpoint-box:before {
  content: "\F228"; }

.mdi-file-presentation-box:before {
  content: "\F229"; }

.mdi-file-restore:before {
  content: "\F670"; }

.mdi-file-send:before {
  content: "\F22A"; }

.mdi-file-tree:before {
  content: "\F645"; }

.mdi-file-video:before {
  content: "\F22B"; }

.mdi-file-word:before {
  content: "\F22C"; }

.mdi-file-word-box:before {
  content: "\F22D"; }

.mdi-file-xml:before {
  content: "\F22E"; }

.mdi-film:before {
  content: "\F22F"; }

.mdi-filmstrip:before {
  content: "\F230"; }

.mdi-filmstrip-off:before {
  content: "\F231"; }

.mdi-filter:before {
  content: "\F232"; }

.mdi-filter-outline:before {
  content: "\F233"; }

.mdi-filter-remove:before {
  content: "\F234"; }

.mdi-filter-remove-outline:before {
  content: "\F235"; }

.mdi-filter-variant:before {
  content: "\F236"; }

.mdi-find-replace:before {
  content: "\F6D3"; }

.mdi-fingerprint:before {
  content: "\F237"; }

.mdi-fire:before {
  content: "\F238"; }

.mdi-firefox:before {
  content: "\F239"; }

.mdi-fish:before {
  content: "\F23A"; }

.mdi-flag:before {
  content: "\F23B"; }

.mdi-flag-checkered:before {
  content: "\F23C"; }

.mdi-flag-outline:before {
  content: "\F23D"; }

.mdi-flag-outline-variant:before {
  content: "\F23E"; }

.mdi-flag-triangle:before {
  content: "\F23F"; }

.mdi-flag-variant:before {
  content: "\F240"; }

.mdi-flash:before {
  content: "\F241"; }

.mdi-flash-auto:before {
  content: "\F242"; }

.mdi-flash-off:before {
  content: "\F243"; }

.mdi-flash-outline:before {
  content: "\F6D4"; }

.mdi-flash-red-eye:before {
  content: "\F67A"; }

.mdi-flashlight:before {
  content: "\F244"; }

.mdi-flashlight-off:before {
  content: "\F245"; }

.mdi-flask:before {
  content: "\F093"; }

.mdi-flask-empty:before {
  content: "\F094"; }

.mdi-flask-empty-outline:before {
  content: "\F095"; }

.mdi-flask-outline:before {
  content: "\F096"; }

.mdi-flattr:before {
  content: "\F246"; }

.mdi-flip-to-back:before {
  content: "\F247"; }

.mdi-flip-to-front:before {
  content: "\F248"; }

.mdi-floppy:before {
  content: "\F249"; }

.mdi-flower:before {
  content: "\F24A"; }

.mdi-folder:before {
  content: "\F24B"; }

.mdi-folder-account:before {
  content: "\F24C"; }

.mdi-folder-download:before {
  content: "\F24D"; }

.mdi-folder-google-drive:before {
  content: "\F24E"; }

.mdi-folder-image:before {
  content: "\F24F"; }

.mdi-folder-lock:before {
  content: "\F250"; }

.mdi-folder-lock-open:before {
  content: "\F251"; }

.mdi-folder-move:before {
  content: "\F252"; }

.mdi-folder-multiple:before {
  content: "\F253"; }

.mdi-folder-multiple-image:before {
  content: "\F254"; }

.mdi-folder-multiple-outline:before {
  content: "\F255"; }

.mdi-folder-open:before {
  content: "\F76F"; }

.mdi-folder-outline:before {
  content: "\F256"; }

.mdi-folder-plus:before {
  content: "\F257"; }

.mdi-folder-remove:before {
  content: "\F258"; }

.mdi-folder-star:before {
  content: "\F69C"; }

.mdi-folder-upload:before {
  content: "\F259"; }

.mdi-font-awesome:before {
  content: "\F03A"; }

.mdi-food:before {
  content: "\F25A"; }

.mdi-food-apple:before {
  content: "\F25B"; }

.mdi-food-fork-drink:before {
  content: "\F5F2"; }

.mdi-food-off:before {
  content: "\F5F3"; }

.mdi-food-variant:before {
  content: "\F25C"; }

.mdi-football:before {
  content: "\F25D"; }

.mdi-football-australian:before {
  content: "\F25E"; }

.mdi-football-helmet:before {
  content: "\F25F"; }

.mdi-format-align-bottom:before {
  content: "\F752"; }

.mdi-format-align-center:before {
  content: "\F260"; }

.mdi-format-align-justify:before {
  content: "\F261"; }

.mdi-format-align-left:before {
  content: "\F262"; }

.mdi-format-align-middle:before {
  content: "\F753"; }

.mdi-format-align-right:before {
  content: "\F263"; }

.mdi-format-align-top:before {
  content: "\F754"; }

.mdi-format-annotation-plus:before {
  content: "\F646"; }

.mdi-format-bold:before {
  content: "\F264"; }

.mdi-format-clear:before {
  content: "\F265"; }

.mdi-format-color-fill:before {
  content: "\F266"; }

.mdi-format-color-text:before {
  content: "\F69D"; }

.mdi-format-float-center:before {
  content: "\F267"; }

.mdi-format-float-left:before {
  content: "\F268"; }

.mdi-format-float-none:before {
  content: "\F269"; }

.mdi-format-float-right:before {
  content: "\F26A"; }

.mdi-format-font:before {
  content: "\F6D5"; }

.mdi-format-header-1:before {
  content: "\F26B"; }

.mdi-format-header-2:before {
  content: "\F26C"; }

.mdi-format-header-3:before {
  content: "\F26D"; }

.mdi-format-header-4:before {
  content: "\F26E"; }

.mdi-format-header-5:before {
  content: "\F26F"; }

.mdi-format-header-6:before {
  content: "\F270"; }

.mdi-format-header-decrease:before {
  content: "\F271"; }

.mdi-format-header-equal:before {
  content: "\F272"; }

.mdi-format-header-increase:before {
  content: "\F273"; }

.mdi-format-header-pound:before {
  content: "\F274"; }

.mdi-format-horizontal-align-center:before {
  content: "\F61E"; }

.mdi-format-horizontal-align-left:before {
  content: "\F61F"; }

.mdi-format-horizontal-align-right:before {
  content: "\F620"; }

.mdi-format-indent-decrease:before {
  content: "\F275"; }

.mdi-format-indent-increase:before {
  content: "\F276"; }

.mdi-format-italic:before {
  content: "\F277"; }

.mdi-format-line-spacing:before {
  content: "\F278"; }

.mdi-format-line-style:before {
  content: "\F5C8"; }

.mdi-format-line-weight:before {
  content: "\F5C9"; }

.mdi-format-list-bulleted:before {
  content: "\F279"; }

.mdi-format-list-bulleted-type:before {
  content: "\F27A"; }

.mdi-format-list-checks:before {
  content: "\F755"; }

.mdi-format-list-numbers:before {
  content: "\F27B"; }

.mdi-format-page-break:before {
  content: "\F6D6"; }

.mdi-format-paint:before {
  content: "\F27C"; }

.mdi-format-paragraph:before {
  content: "\F27D"; }

.mdi-format-pilcrow:before {
  content: "\F6D7"; }

.mdi-format-quote-close:before {
  content: "\F27E"; }

.mdi-format-quote-open:before {
  content: "\F756"; }

.mdi-format-rotate-90:before {
  content: "\F6A9"; }

.mdi-format-section:before {
  content: "\F69E"; }

.mdi-format-size:before {
  content: "\F27F"; }

.mdi-format-strikethrough:before {
  content: "\F280"; }

.mdi-format-strikethrough-variant:before {
  content: "\F281"; }

.mdi-format-subscript:before {
  content: "\F282"; }

.mdi-format-superscript:before {
  content: "\F283"; }

.mdi-format-text:before {
  content: "\F284"; }

.mdi-format-textdirection-l-to-r:before {
  content: "\F285"; }

.mdi-format-textdirection-r-to-l:before {
  content: "\F286"; }

.mdi-format-title:before {
  content: "\F5F4"; }

.mdi-format-underline:before {
  content: "\F287"; }

.mdi-format-vertical-align-bottom:before {
  content: "\F621"; }

.mdi-format-vertical-align-center:before {
  content: "\F622"; }

.mdi-format-vertical-align-top:before {
  content: "\F623"; }

.mdi-format-wrap-inline:before {
  content: "\F288"; }

.mdi-format-wrap-square:before {
  content: "\F289"; }

.mdi-format-wrap-tight:before {
  content: "\F28A"; }

.mdi-format-wrap-top-bottom:before {
  content: "\F28B"; }

.mdi-forum:before {
  content: "\F28C"; }

.mdi-forward:before {
  content: "\F28D"; }

.mdi-foursquare:before {
  content: "\F28E"; }

.mdi-fridge:before {
  content: "\F28F"; }

.mdi-fridge-filled:before {
  content: "\F290"; }

.mdi-fridge-filled-bottom:before {
  content: "\F291"; }

.mdi-fridge-filled-top:before {
  content: "\F292"; }

.mdi-fullscreen:before {
  content: "\F293"; }

.mdi-fullscreen-exit:before {
  content: "\F294"; }

.mdi-function:before {
  content: "\F295"; }

.mdi-gamepad:before {
  content: "\F296"; }

.mdi-gamepad-variant:before {
  content: "\F297"; }

.mdi-garage:before {
  content: "\F6D8"; }

.mdi-garage-open:before {
  content: "\F6D9"; }

.mdi-gas-cylinder:before {
  content: "\F647"; }

.mdi-gas-station:before {
  content: "\F298"; }

.mdi-gate:before {
  content: "\F299"; }

.mdi-gauge:before {
  content: "\F29A"; }

.mdi-gavel:before {
  content: "\F29B"; }

.mdi-gender-female:before {
  content: "\F29C"; }

.mdi-gender-male:before {
  content: "\F29D"; }

.mdi-gender-male-female:before {
  content: "\F29E"; }

.mdi-gender-transgender:before {
  content: "\F29F"; }

.mdi-gesture-double-tap:before {
  content: "\F73B"; }

.mdi-gesture-swipe-down:before {
  content: "\F73C"; }

.mdi-gesture-swipe-left:before {
  content: "\F73D"; }

.mdi-gesture-swipe-right:before {
  content: "\F73E"; }

.mdi-gesture-swipe-up:before {
  content: "\F73F"; }

.mdi-gesture-tap:before {
  content: "\F740"; }

.mdi-gesture-two-double-tap:before {
  content: "\F741"; }

.mdi-gesture-two-tap:before {
  content: "\F742"; }

.mdi-ghost:before {
  content: "\F2A0"; }

.mdi-gift:before {
  content: "\F2A1"; }

.mdi-git:before {
  content: "\F2A2"; }

.mdi-github-box:before {
  content: "\F2A3"; }

.mdi-github-circle:before {
  content: "\F2A4"; }

.mdi-github-face:before {
  content: "\F6DA"; }

.mdi-glass-flute:before {
  content: "\F2A5"; }

.mdi-glass-mug:before {
  content: "\F2A6"; }

.mdi-glass-stange:before {
  content: "\F2A7"; }

.mdi-glass-tulip:before {
  content: "\F2A8"; }

.mdi-glassdoor:before {
  content: "\F2A9"; }

.mdi-glasses:before {
  content: "\F2AA"; }

.mdi-gmail:before {
  content: "\F2AB"; }

.mdi-gnome:before {
  content: "\F2AC"; }

.mdi-gondola:before {
  content: "\F685"; }

.mdi-google:before {
  content: "\F2AD"; }

.mdi-google-cardboard:before {
  content: "\F2AE"; }

.mdi-google-chrome:before {
  content: "\F2AF"; }

.mdi-google-circles:before {
  content: "\F2B0"; }

.mdi-google-circles-communities:before {
  content: "\F2B1"; }

.mdi-google-circles-extended:before {
  content: "\F2B2"; }

.mdi-google-circles-group:before {
  content: "\F2B3"; }

.mdi-google-controller:before {
  content: "\F2B4"; }

.mdi-google-controller-off:before {
  content: "\F2B5"; }

.mdi-google-drive:before {
  content: "\F2B6"; }

.mdi-google-earth:before {
  content: "\F2B7"; }

.mdi-google-glass:before {
  content: "\F2B8"; }

.mdi-google-keep:before {
  content: "\F6DB"; }

.mdi-google-maps:before {
  content: "\F5F5"; }

.mdi-google-nearby:before {
  content: "\F2B9"; }

.mdi-google-pages:before {
  content: "\F2BA"; }

.mdi-google-photos:before {
  content: "\F6DC"; }

.mdi-google-physical-web:before {
  content: "\F2BB"; }

.mdi-google-play:before {
  content: "\F2BC"; }

.mdi-google-plus:before {
  content: "\F2BD"; }

.mdi-google-plus-box:before {
  content: "\F2BE"; }

.mdi-google-translate:before {
  content: "\F2BF"; }

.mdi-google-wallet:before {
  content: "\F2C0"; }

.mdi-gradient:before {
  content: "\F69F"; }

.mdi-grease-pencil:before {
  content: "\F648"; }

.mdi-grid:before {
  content: "\F2C1"; }

.mdi-grid-large:before {
  content: "\F757"; }

.mdi-grid-off:before {
  content: "\F2C2"; }

.mdi-group:before {
  content: "\F2C3"; }

.mdi-guitar-acoustic:before {
  content: "\F770"; }

.mdi-guitar-electric:before {
  content: "\F2C4"; }

.mdi-guitar-pick:before {
  content: "\F2C5"; }

.mdi-guitar-pick-outline:before {
  content: "\F2C6"; }

.mdi-hackernews:before {
  content: "\F624"; }

.mdi-hamburger:before {
  content: "\F684"; }

.mdi-hand-pointing-right:before {
  content: "\F2C7"; }

.mdi-hanger:before {
  content: "\F2C8"; }

.mdi-hangouts:before {
  content: "\F2C9"; }

.mdi-harddisk:before {
  content: "\F2CA"; }

.mdi-headphones:before {
  content: "\F2CB"; }

.mdi-headphones-box:before {
  content: "\F2CC"; }

.mdi-headphones-settings:before {
  content: "\F2CD"; }

.mdi-headset:before {
  content: "\F2CE"; }

.mdi-headset-dock:before {
  content: "\F2CF"; }

.mdi-headset-off:before {
  content: "\F2D0"; }

.mdi-heart:before {
  content: "\F2D1"; }

.mdi-heart-box:before {
  content: "\F2D2"; }

.mdi-heart-box-outline:before {
  content: "\F2D3"; }

.mdi-heart-broken:before {
  content: "\F2D4"; }

.mdi-heart-half:before {
  content: "\F6DE"; }

.mdi-heart-half-full:before {
  content: "\F6DD"; }

.mdi-heart-half-outline:before {
  content: "\F6DF"; }

.mdi-heart-off:before {
  content: "\F758"; }

.mdi-heart-outline:before {
  content: "\F2D5"; }

.mdi-heart-pulse:before {
  content: "\F5F6"; }

.mdi-help:before {
  content: "\F2D6"; }

.mdi-help-box:before {
  content: "\F78A"; }

.mdi-help-circle:before {
  content: "\F2D7"; }

.mdi-help-circle-outline:before {
  content: "\F625"; }

.mdi-help-network:before {
  content: "\F6F4"; }

.mdi-hexagon:before {
  content: "\F2D8"; }

.mdi-hexagon-multiple:before {
  content: "\F6E0"; }

.mdi-hexagon-outline:before {
  content: "\F2D9"; }

.mdi-highway:before {
  content: "\F5F7"; }

.mdi-history:before {
  content: "\F2DA"; }

.mdi-hololens:before {
  content: "\F2DB"; }

.mdi-home:before {
  content: "\F2DC"; }

.mdi-home-map-marker:before {
  content: "\F5F8"; }

.mdi-home-modern:before {
  content: "\F2DD"; }

.mdi-home-outline:before {
  content: "\F6A0"; }

.mdi-home-variant:before {
  content: "\F2DE"; }

.mdi-hook:before {
  content: "\F6E1"; }

.mdi-hook-off:before {
  content: "\F6E2"; }

.mdi-hops:before {
  content: "\F2DF"; }

.mdi-hospital:before {
  content: "\F2E0"; }

.mdi-hospital-building:before {
  content: "\F2E1"; }

.mdi-hospital-marker:before {
  content: "\F2E2"; }

.mdi-hotel:before {
  content: "\F2E3"; }

.mdi-houzz:before {
  content: "\F2E4"; }

.mdi-houzz-box:before {
  content: "\F2E5"; }

.mdi-human:before {
  content: "\F2E6"; }

.mdi-human-child:before {
  content: "\F2E7"; }

.mdi-human-female:before {
  content: "\F649"; }

.mdi-human-greeting:before {
  content: "\F64A"; }

.mdi-human-handsdown:before {
  content: "\F64B"; }

.mdi-human-handsup:before {
  content: "\F64C"; }

.mdi-human-male:before {
  content: "\F64D"; }

.mdi-human-male-female:before {
  content: "\F2E8"; }

.mdi-human-pregnant:before {
  content: "\F5CF"; }

.mdi-humble-bundle:before {
  content: "\F743"; }

.mdi-image:before {
  content: "\F2E9"; }

.mdi-image-album:before {
  content: "\F2EA"; }

.mdi-image-area:before {
  content: "\F2EB"; }

.mdi-image-area-close:before {
  content: "\F2EC"; }

.mdi-image-broken:before {
  content: "\F2ED"; }

.mdi-image-broken-variant:before {
  content: "\F2EE"; }

.mdi-image-filter:before {
  content: "\F2EF"; }

.mdi-image-filter-black-white:before {
  content: "\F2F0"; }

.mdi-image-filter-center-focus:before {
  content: "\F2F1"; }

.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2"; }

.mdi-image-filter-drama:before {
  content: "\F2F3"; }

.mdi-image-filter-frames:before {
  content: "\F2F4"; }

.mdi-image-filter-hdr:before {
  content: "\F2F5"; }

.mdi-image-filter-none:before {
  content: "\F2F6"; }

.mdi-image-filter-tilt-shift:before {
  content: "\F2F7"; }

.mdi-image-filter-vintage:before {
  content: "\F2F8"; }

.mdi-image-multiple:before {
  content: "\F2F9"; }

.mdi-import:before {
  content: "\F2FA"; }

.mdi-inbox:before {
  content: "\F686"; }

.mdi-inbox-arrow-down:before {
  content: "\F2FB"; }

.mdi-inbox-arrow-up:before {
  content: "\F3D1"; }

.mdi-incognito:before {
  content: "\F5F9"; }

.mdi-infinity:before {
  content: "\F6E3"; }

.mdi-information:before {
  content: "\F2FC"; }

.mdi-information-outline:before {
  content: "\F2FD"; }

.mdi-information-variant:before {
  content: "\F64E"; }

.mdi-instagram:before {
  content: "\F2FE"; }

.mdi-instapaper:before {
  content: "\F2FF"; }

.mdi-internet-explorer:before {
  content: "\F300"; }

.mdi-invert-colors:before {
  content: "\F301"; }

.mdi-itunes:before {
  content: "\F676"; }

.mdi-jeepney:before {
  content: "\F302"; }

.mdi-jira:before {
  content: "\F303"; }

.mdi-jsfiddle:before {
  content: "\F304"; }

.mdi-json:before {
  content: "\F626"; }

.mdi-keg:before {
  content: "\F305"; }

.mdi-kettle:before {
  content: "\F5FA"; }

.mdi-key:before {
  content: "\F306"; }

.mdi-key-change:before {
  content: "\F307"; }

.mdi-key-minus:before {
  content: "\F308"; }

.mdi-key-plus:before {
  content: "\F309"; }

.mdi-key-remove:before {
  content: "\F30A"; }

.mdi-key-variant:before {
  content: "\F30B"; }

.mdi-keyboard:before {
  content: "\F30C"; }

.mdi-keyboard-backspace:before {
  content: "\F30D"; }

.mdi-keyboard-caps:before {
  content: "\F30E"; }

.mdi-keyboard-close:before {
  content: "\F30F"; }

.mdi-keyboard-off:before {
  content: "\F310"; }

.mdi-keyboard-return:before {
  content: "\F311"; }

.mdi-keyboard-tab:before {
  content: "\F312"; }

.mdi-keyboard-variant:before {
  content: "\F313"; }

.mdi-kickstarter:before {
  content: "\F744"; }

.mdi-kodi:before {
  content: "\F314"; }

.mdi-label:before {
  content: "\F315"; }

.mdi-label-outline:before {
  content: "\F316"; }

.mdi-lambda:before {
  content: "\F627"; }

.mdi-lamp:before {
  content: "\F6B4"; }

.mdi-lan:before {
  content: "\F317"; }

.mdi-lan-connect:before {
  content: "\F318"; }

.mdi-lan-disconnect:before {
  content: "\F319"; }

.mdi-lan-pending:before {
  content: "\F31A"; }

.mdi-language-c:before {
  content: "\F671"; }

.mdi-language-cpp:before {
  content: "\F672"; }

.mdi-language-csharp:before {
  content: "\F31B"; }

.mdi-language-css3:before {
  content: "\F31C"; }

.mdi-language-html5:before {
  content: "\F31D"; }

.mdi-language-javascript:before {
  content: "\F31E"; }

.mdi-language-php:before {
  content: "\F31F"; }

.mdi-language-python:before {
  content: "\F320"; }

.mdi-language-python-text:before {
  content: "\F321"; }

.mdi-language-swift:before {
  content: "\F6E4"; }

.mdi-language-typescript:before {
  content: "\F6E5"; }

.mdi-laptop:before {
  content: "\F322"; }

.mdi-laptop-chromebook:before {
  content: "\F323"; }

.mdi-laptop-mac:before {
  content: "\F324"; }

.mdi-laptop-off:before {
  content: "\F6E6"; }

.mdi-laptop-windows:before {
  content: "\F325"; }

.mdi-lastfm:before {
  content: "\F326"; }

.mdi-launch:before {
  content: "\F327"; }

.mdi-layers:before {
  content: "\F328"; }

.mdi-layers-off:before {
  content: "\F329"; }

.mdi-lead-pencil:before {
  content: "\F64F"; }

.mdi-leaf:before {
  content: "\F32A"; }

.mdi-led-off:before {
  content: "\F32B"; }

.mdi-led-on:before {
  content: "\F32C"; }

.mdi-led-outline:before {
  content: "\F32D"; }

.mdi-led-variant-off:before {
  content: "\F32E"; }

.mdi-led-variant-on:before {
  content: "\F32F"; }

.mdi-led-variant-outline:before {
  content: "\F330"; }

.mdi-library:before {
  content: "\F331"; }

.mdi-library-books:before {
  content: "\F332"; }

.mdi-library-music:before {
  content: "\F333"; }

.mdi-library-plus:before {
  content: "\F334"; }

.mdi-lightbulb:before {
  content: "\F335"; }

.mdi-lightbulb-on:before {
  content: "\F6E7"; }

.mdi-lightbulb-on-outline:before {
  content: "\F6E8"; }

.mdi-lightbulb-outline:before {
  content: "\F336"; }

.mdi-link:before {
  content: "\F337"; }

.mdi-link-off:before {
  content: "\F338"; }

.mdi-link-variant:before {
  content: "\F339"; }

.mdi-link-variant-off:before {
  content: "\F33A"; }

.mdi-linkedin:before {
  content: "\F33B"; }

.mdi-linkedin-box:before {
  content: "\F33C"; }

.mdi-linux:before {
  content: "\F33D"; }

.mdi-loading:before {
  content: "\F771"; }

.mdi-lock:before {
  content: "\F33E"; }

.mdi-lock-open:before {
  content: "\F33F"; }

.mdi-lock-open-outline:before {
  content: "\F340"; }

.mdi-lock-outline:before {
  content: "\F341"; }

.mdi-lock-pattern:before {
  content: "\F6E9"; }

.mdi-lock-plus:before {
  content: "\F5FB"; }

.mdi-lock-reset:before {
  content: "\F772"; }

.mdi-login:before {
  content: "\F342"; }

.mdi-login-variant:before {
  content: "\F5FC"; }

.mdi-logout:before {
  content: "\F343"; }

.mdi-logout-variant:before {
  content: "\F5FD"; }

.mdi-looks:before {
  content: "\F344"; }

.mdi-loop:before {
  content: "\F6EA"; }

.mdi-loupe:before {
  content: "\F345"; }

.mdi-lumx:before {
  content: "\F346"; }

.mdi-magnet:before {
  content: "\F347"; }

.mdi-magnet-on:before {
  content: "\F348"; }

.mdi-magnify:before {
  content: "\F349"; }

.mdi-magnify-minus:before {
  content: "\F34A"; }

.mdi-magnify-minus-outline:before {
  content: "\F6EB"; }

.mdi-magnify-plus:before {
  content: "\F34B"; }

.mdi-magnify-plus-outline:before {
  content: "\F6EC"; }

.mdi-mail-ru:before {
  content: "\F34C"; }

.mdi-mailbox:before {
  content: "\F6ED"; }

.mdi-map:before {
  content: "\F34D"; }

.mdi-map-marker:before {
  content: "\F34E"; }

.mdi-map-marker-circle:before {
  content: "\F34F"; }

.mdi-map-marker-minus:before {
  content: "\F650"; }

.mdi-map-marker-multiple:before {
  content: "\F350"; }

.mdi-map-marker-off:before {
  content: "\F351"; }

.mdi-map-marker-plus:before {
  content: "\F651"; }

.mdi-map-marker-radius:before {
  content: "\F352"; }

.mdi-margin:before {
  content: "\F353"; }

.mdi-markdown:before {
  content: "\F354"; }

.mdi-marker:before {
  content: "\F652"; }

.mdi-marker-check:before {
  content: "\F355"; }

.mdi-martini:before {
  content: "\F356"; }

.mdi-material-ui:before {
  content: "\F357"; }

.mdi-math-compass:before {
  content: "\F358"; }

.mdi-matrix:before {
  content: "\F628"; }

.mdi-maxcdn:before {
  content: "\F359"; }

.mdi-medical-bag:before {
  content: "\F6EE"; }

.mdi-medium:before {
  content: "\F35A"; }

.mdi-memory:before {
  content: "\F35B"; }

.mdi-menu:before {
  content: "\F35C"; }

.mdi-menu-down:before {
  content: "\F35D"; }

.mdi-menu-down-outline:before {
  content: "\F6B5"; }

.mdi-menu-left:before {
  content: "\F35E"; }

.mdi-menu-right:before {
  content: "\F35F"; }

.mdi-menu-up:before {
  content: "\F360"; }

.mdi-menu-up-outline:before {
  content: "\F6B6"; }

.mdi-message:before {
  content: "\F361"; }

.mdi-message-alert:before {
  content: "\F362"; }

.mdi-message-bulleted:before {
  content: "\F6A1"; }

.mdi-message-bulleted-off:before {
  content: "\F6A2"; }

.mdi-message-draw:before {
  content: "\F363"; }

.mdi-message-image:before {
  content: "\F364"; }

.mdi-message-outline:before {
  content: "\F365"; }

.mdi-message-plus:before {
  content: "\F653"; }

.mdi-message-processing:before {
  content: "\F366"; }

.mdi-message-reply:before {
  content: "\F367"; }

.mdi-message-reply-text:before {
  content: "\F368"; }

.mdi-message-settings:before {
  content: "\F6EF"; }

.mdi-message-settings-variant:before {
  content: "\F6F0"; }

.mdi-message-text:before {
  content: "\F369"; }

.mdi-message-text-outline:before {
  content: "\F36A"; }

.mdi-message-video:before {
  content: "\F36B"; }

.mdi-meteor:before {
  content: "\F629"; }

.mdi-microphone:before {
  content: "\F36C"; }

.mdi-microphone-off:before {
  content: "\F36D"; }

.mdi-microphone-outline:before {
  content: "\F36E"; }

.mdi-microphone-settings:before {
  content: "\F36F"; }

.mdi-microphone-variant:before {
  content: "\F370"; }

.mdi-microphone-variant-off:before {
  content: "\F371"; }

.mdi-microscope:before {
  content: "\F654"; }

.mdi-microsoft:before {
  content: "\F372"; }

.mdi-minecraft:before {
  content: "\F373"; }

.mdi-minus:before {
  content: "\F374"; }

.mdi-minus-box:before {
  content: "\F375"; }

.mdi-minus-box-outline:before {
  content: "\F6F1"; }

.mdi-minus-circle:before {
  content: "\F376"; }

.mdi-minus-circle-outline:before {
  content: "\F377"; }

.mdi-minus-network:before {
  content: "\F378"; }

.mdi-mixcloud:before {
  content: "\F62A"; }

.mdi-monitor:before {
  content: "\F379"; }

.mdi-monitor-multiple:before {
  content: "\F37A"; }

.mdi-more:before {
  content: "\F37B"; }

.mdi-motorbike:before {
  content: "\F37C"; }

.mdi-mouse:before {
  content: "\F37D"; }

.mdi-mouse-off:before {
  content: "\F37E"; }

.mdi-mouse-variant:before {
  content: "\F37F"; }

.mdi-mouse-variant-off:before {
  content: "\F380"; }

.mdi-move-resize:before {
  content: "\F655"; }

.mdi-move-resize-variant:before {
  content: "\F656"; }

.mdi-movie:before {
  content: "\F381"; }

.mdi-multiplication:before {
  content: "\F382"; }

.mdi-multiplication-box:before {
  content: "\F383"; }

.mdi-music:before {
  content: "\F759"; }

.mdi-music-box:before {
  content: "\F384"; }

.mdi-music-box-outline:before {
  content: "\F385"; }

.mdi-music-circle:before {
  content: "\F386"; }

.mdi-music-note:before {
  content: "\F387"; }

.mdi-music-note-bluetooth:before {
  content: "\F5FE"; }

.mdi-music-note-bluetooth-off:before {
  content: "\F5FF"; }

.mdi-music-note-eighth:before {
  content: "\F388"; }

.mdi-music-note-half:before {
  content: "\F389"; }

.mdi-music-note-off:before {
  content: "\F38A"; }

.mdi-music-note-quarter:before {
  content: "\F38B"; }

.mdi-music-note-sixteenth:before {
  content: "\F38C"; }

.mdi-music-note-whole:before {
  content: "\F38D"; }

.mdi-music-off:before {
  content: "\F75A"; }

.mdi-nature:before {
  content: "\F38E"; }

.mdi-nature-people:before {
  content: "\F38F"; }

.mdi-navigation:before {
  content: "\F390"; }

.mdi-near-me:before {
  content: "\F5CD"; }

.mdi-needle:before {
  content: "\F391"; }

.mdi-nest-protect:before {
  content: "\F392"; }

.mdi-nest-thermostat:before {
  content: "\F393"; }

.mdi-netflix:before {
  content: "\F745"; }

.mdi-network:before {
  content: "\F6F2"; }

.mdi-new-box:before {
  content: "\F394"; }

.mdi-newspaper:before {
  content: "\F395"; }

.mdi-nfc:before {
  content: "\F396"; }

.mdi-nfc-tap:before {
  content: "\F397"; }

.mdi-nfc-variant:before {
  content: "\F398"; }

.mdi-ninja:before {
  content: "\F773"; }

.mdi-nodejs:before {
  content: "\F399"; }

.mdi-note:before {
  content: "\F39A"; }

.mdi-note-multiple:before {
  content: "\F6B7"; }

.mdi-note-multiple-outline:before {
  content: "\F6B8"; }

.mdi-note-outline:before {
  content: "\F39B"; }

.mdi-note-plus:before {
  content: "\F39C"; }

.mdi-note-plus-outline:before {
  content: "\F39D"; }

.mdi-note-text:before {
  content: "\F39E"; }

.mdi-notification-clear-all:before {
  content: "\F39F"; }

.mdi-npm:before {
  content: "\F6F6"; }

.mdi-nuke:before {
  content: "\F6A3"; }

.mdi-numeric:before {
  content: "\F3A0"; }

.mdi-numeric-0-box:before {
  content: "\F3A1"; }

.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2"; }

.mdi-numeric-0-box-outline:before {
  content: "\F3A3"; }

.mdi-numeric-1-box:before {
  content: "\F3A4"; }

.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5"; }

.mdi-numeric-1-box-outline:before {
  content: "\F3A6"; }

.mdi-numeric-2-box:before {
  content: "\F3A7"; }

.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8"; }

.mdi-numeric-2-box-outline:before {
  content: "\F3A9"; }

.mdi-numeric-3-box:before {
  content: "\F3AA"; }

.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB"; }

.mdi-numeric-3-box-outline:before {
  content: "\F3AC"; }

.mdi-numeric-4-box:before {
  content: "\F3AD"; }

.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE"; }

.mdi-numeric-4-box-outline:before {
  content: "\F3AF"; }

.mdi-numeric-5-box:before {
  content: "\F3B0"; }

.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1"; }

.mdi-numeric-5-box-outline:before {
  content: "\F3B2"; }

.mdi-numeric-6-box:before {
  content: "\F3B3"; }

.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4"; }

.mdi-numeric-6-box-outline:before {
  content: "\F3B5"; }

.mdi-numeric-7-box:before {
  content: "\F3B6"; }

.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7"; }

.mdi-numeric-7-box-outline:before {
  content: "\F3B8"; }

.mdi-numeric-8-box:before {
  content: "\F3B9"; }

.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA"; }

.mdi-numeric-8-box-outline:before {
  content: "\F3BB"; }

.mdi-numeric-9-box:before {
  content: "\F3BC"; }

.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD"; }

.mdi-numeric-9-box-outline:before {
  content: "\F3BE"; }

.mdi-numeric-9-plus-box:before {
  content: "\F3BF"; }

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0"; }

.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1"; }

.mdi-nut:before {
  content: "\F6F7"; }

.mdi-nutrition:before {
  content: "\F3C2"; }

.mdi-oar:before {
  content: "\F67B"; }

.mdi-octagon:before {
  content: "\F3C3"; }

.mdi-octagon-outline:before {
  content: "\F3C4"; }

.mdi-octagram:before {
  content: "\F6F8"; }

.mdi-octagram-outline:before {
  content: "\F774"; }

.mdi-odnoklassniki:before {
  content: "\F3C5"; }

.mdi-office:before {
  content: "\F3C6"; }

.mdi-oil:before {
  content: "\F3C7"; }

.mdi-oil-temperature:before {
  content: "\F3C8"; }

.mdi-omega:before {
  content: "\F3C9"; }

.mdi-onedrive:before {
  content: "\F3CA"; }

.mdi-onenote:before {
  content: "\F746"; }

.mdi-opacity:before {
  content: "\F5CC"; }

.mdi-open-in-app:before {
  content: "\F3CB"; }

.mdi-open-in-new:before {
  content: "\F3CC"; }

.mdi-openid:before {
  content: "\F3CD"; }

.mdi-opera:before {
  content: "\F3CE"; }

.mdi-orbit:before {
  content: "\F018"; }

.mdi-ornament:before {
  content: "\F3CF"; }

.mdi-ornament-variant:before {
  content: "\F3D0"; }

.mdi-owl:before {
  content: "\F3D2"; }

.mdi-package:before {
  content: "\F3D3"; }

.mdi-package-down:before {
  content: "\F3D4"; }

.mdi-package-up:before {
  content: "\F3D5"; }

.mdi-package-variant:before {
  content: "\F3D6"; }

.mdi-package-variant-closed:before {
  content: "\F3D7"; }

.mdi-page-first:before {
  content: "\F600"; }

.mdi-page-last:before {
  content: "\F601"; }

.mdi-page-layout-body:before {
  content: "\F6F9"; }

.mdi-page-layout-footer:before {
  content: "\F6FA"; }

.mdi-page-layout-header:before {
  content: "\F6FB"; }

.mdi-page-layout-sidebar-left:before {
  content: "\F6FC"; }

.mdi-page-layout-sidebar-right:before {
  content: "\F6FD"; }

.mdi-palette:before {
  content: "\F3D8"; }

.mdi-palette-advanced:before {
  content: "\F3D9"; }

.mdi-panda:before {
  content: "\F3DA"; }

.mdi-pandora:before {
  content: "\F3DB"; }

.mdi-panorama:before {
  content: "\F3DC"; }

.mdi-panorama-fisheye:before {
  content: "\F3DD"; }

.mdi-panorama-horizontal:before {
  content: "\F3DE"; }

.mdi-panorama-vertical:before {
  content: "\F3DF"; }

.mdi-panorama-wide-angle:before {
  content: "\F3E0"; }

.mdi-paper-cut-vertical:before {
  content: "\F3E1"; }

.mdi-paperclip:before {
  content: "\F3E2"; }

.mdi-parking:before {
  content: "\F3E3"; }

.mdi-pause:before {
  content: "\F3E4"; }

.mdi-pause-circle:before {
  content: "\F3E5"; }

.mdi-pause-circle-outline:before {
  content: "\F3E6"; }

.mdi-pause-octagon:before {
  content: "\F3E7"; }

.mdi-pause-octagon-outline:before {
  content: "\F3E8"; }

.mdi-paw:before {
  content: "\F3E9"; }

.mdi-paw-off:before {
  content: "\F657"; }

.mdi-pen:before {
  content: "\F3EA"; }

.mdi-pencil:before {
  content: "\F3EB"; }

.mdi-pencil-box:before {
  content: "\F3EC"; }

.mdi-pencil-box-outline:before {
  content: "\F3ED"; }

.mdi-pencil-circle:before {
  content: "\F6FE"; }

.mdi-pencil-circle-outline:before {
  content: "\F775"; }

.mdi-pencil-lock:before {
  content: "\F3EE"; }

.mdi-pencil-off:before {
  content: "\F3EF"; }

.mdi-pentagon:before {
  content: "\F6FF"; }

.mdi-pentagon-outline:before {
  content: "\F700"; }

.mdi-percent:before {
  content: "\F3F0"; }

.mdi-periscope:before {
  content: "\F747"; }

.mdi-pharmacy:before {
  content: "\F3F1"; }

.mdi-phone:before {
  content: "\F3F2"; }

.mdi-phone-bluetooth:before {
  content: "\F3F3"; }

.mdi-phone-classic:before {
  content: "\F602"; }

.mdi-phone-forward:before {
  content: "\F3F4"; }

.mdi-phone-hangup:before {
  content: "\F3F5"; }

.mdi-phone-in-talk:before {
  content: "\F3F6"; }

.mdi-phone-incoming:before {
  content: "\F3F7"; }

.mdi-phone-locked:before {
  content: "\F3F8"; }

.mdi-phone-log:before {
  content: "\F3F9"; }

.mdi-phone-minus:before {
  content: "\F658"; }

.mdi-phone-missed:before {
  content: "\F3FA"; }

.mdi-phone-outgoing:before {
  content: "\F3FB"; }

.mdi-phone-paused:before {
  content: "\F3FC"; }

.mdi-phone-plus:before {
  content: "\F659"; }

.mdi-phone-settings:before {
  content: "\F3FD"; }

.mdi-phone-voip:before {
  content: "\F3FE"; }

.mdi-pi:before {
  content: "\F3FF"; }

.mdi-pi-box:before {
  content: "\F400"; }

.mdi-piano:before {
  content: "\F67C"; }

.mdi-pig:before {
  content: "\F401"; }

.mdi-pill:before {
  content: "\F402"; }

.mdi-pillar:before {
  content: "\F701"; }

.mdi-pin:before {
  content: "\F403"; }

.mdi-pin-off:before {
  content: "\F404"; }

.mdi-pine-tree:before {
  content: "\F405"; }

.mdi-pine-tree-box:before {
  content: "\F406"; }

.mdi-pinterest:before {
  content: "\F407"; }

.mdi-pinterest-box:before {
  content: "\F408"; }

.mdi-pistol:before {
  content: "\F702"; }

.mdi-pizza:before {
  content: "\F409"; }

.mdi-plane-shield:before {
  content: "\F6BA"; }

.mdi-play:before {
  content: "\F40A"; }

.mdi-play-box-outline:before {
  content: "\F40B"; }

.mdi-play-circle:before {
  content: "\F40C"; }

.mdi-play-circle-outline:before {
  content: "\F40D"; }

.mdi-play-pause:before {
  content: "\F40E"; }

.mdi-play-protected-content:before {
  content: "\F40F"; }

.mdi-playlist-check:before {
  content: "\F5C7"; }

.mdi-playlist-minus:before {
  content: "\F410"; }

.mdi-playlist-play:before {
  content: "\F411"; }

.mdi-playlist-plus:before {
  content: "\F412"; }

.mdi-playlist-remove:before {
  content: "\F413"; }

.mdi-playstation:before {
  content: "\F414"; }

.mdi-plex:before {
  content: "\F6B9"; }

.mdi-plus:before {
  content: "\F415"; }

.mdi-plus-box:before {
  content: "\F416"; }

.mdi-plus-box-outline:before {
  content: "\F703"; }

.mdi-plus-circle:before {
  content: "\F417"; }

.mdi-plus-circle-multiple-outline:before {
  content: "\F418"; }

.mdi-plus-circle-outline:before {
  content: "\F419"; }

.mdi-plus-network:before {
  content: "\F41A"; }

.mdi-plus-one:before {
  content: "\F41B"; }

.mdi-plus-outline:before {
  content: "\F704"; }

.mdi-pocket:before {
  content: "\F41C"; }

.mdi-pokeball:before {
  content: "\F41D"; }

.mdi-polaroid:before {
  content: "\F41E"; }

.mdi-poll:before {
  content: "\F41F"; }

.mdi-poll-box:before {
  content: "\F420"; }

.mdi-polymer:before {
  content: "\F421"; }

.mdi-pool:before {
  content: "\F606"; }

.mdi-popcorn:before {
  content: "\F422"; }

.mdi-pot:before {
  content: "\F65A"; }

.mdi-pot-mix:before {
  content: "\F65B"; }

.mdi-pound:before {
  content: "\F423"; }

.mdi-pound-box:before {
  content: "\F424"; }

.mdi-power:before {
  content: "\F425"; }

.mdi-power-plug:before {
  content: "\F6A4"; }

.mdi-power-plug-off:before {
  content: "\F6A5"; }

.mdi-power-settings:before {
  content: "\F426"; }

.mdi-power-socket:before {
  content: "\F427"; }

.mdi-prescription:before {
  content: "\F705"; }

.mdi-presentation:before {
  content: "\F428"; }

.mdi-presentation-play:before {
  content: "\F429"; }

.mdi-printer:before {
  content: "\F42A"; }

.mdi-printer-3d:before {
  content: "\F42B"; }

.mdi-printer-alert:before {
  content: "\F42C"; }

.mdi-printer-settings:before {
  content: "\F706"; }

.mdi-priority-high:before {
  content: "\F603"; }

.mdi-priority-low:before {
  content: "\F604"; }

.mdi-professional-hexagon:before {
  content: "\F42D"; }

.mdi-projector:before {
  content: "\F42E"; }

.mdi-projector-screen:before {
  content: "\F42F"; }

.mdi-publish:before {
  content: "\F6A6"; }

.mdi-pulse:before {
  content: "\F430"; }

.mdi-puzzle:before {
  content: "\F431"; }

.mdi-qqchat:before {
  content: "\F605"; }

.mdi-qrcode:before {
  content: "\F432"; }

.mdi-qrcode-scan:before {
  content: "\F433"; }

.mdi-quadcopter:before {
  content: "\F434"; }

.mdi-quality-high:before {
  content: "\F435"; }

.mdi-quicktime:before {
  content: "\F436"; }

.mdi-radar:before {
  content: "\F437"; }

.mdi-radiator:before {
  content: "\F438"; }

.mdi-radio:before {
  content: "\F439"; }

.mdi-radio-handheld:before {
  content: "\F43A"; }

.mdi-radio-tower:before {
  content: "\F43B"; }

.mdi-radioactive:before {
  content: "\F43C"; }

.mdi-radiobox-blank:before {
  content: "\F43D"; }

.mdi-radiobox-marked:before {
  content: "\F43E"; }

.mdi-raspberrypi:before {
  content: "\F43F"; }

.mdi-ray-end:before {
  content: "\F440"; }

.mdi-ray-end-arrow:before {
  content: "\F441"; }

.mdi-ray-start:before {
  content: "\F442"; }

.mdi-ray-start-arrow:before {
  content: "\F443"; }

.mdi-ray-start-end:before {
  content: "\F444"; }

.mdi-ray-vertex:before {
  content: "\F445"; }

.mdi-rdio:before {
  content: "\F446"; }

.mdi-react:before {
  content: "\F707"; }

.mdi-read:before {
  content: "\F447"; }

.mdi-readability:before {
  content: "\F448"; }

.mdi-receipt:before {
  content: "\F449"; }

.mdi-record:before {
  content: "\F44A"; }

.mdi-record-rec:before {
  content: "\F44B"; }

.mdi-recycle:before {
  content: "\F44C"; }

.mdi-reddit:before {
  content: "\F44D"; }

.mdi-redo:before {
  content: "\F44E"; }

.mdi-redo-variant:before {
  content: "\F44F"; }

.mdi-refresh:before {
  content: "\F450"; }

.mdi-regex:before {
  content: "\F451"; }

.mdi-relative-scale:before {
  content: "\F452"; }

.mdi-reload:before {
  content: "\F453"; }

.mdi-remote:before {
  content: "\F454"; }

.mdi-rename-box:before {
  content: "\F455"; }

.mdi-reorder-horizontal:before {
  content: "\F687"; }

.mdi-reorder-vertical:before {
  content: "\F688"; }

.mdi-repeat:before {
  content: "\F456"; }

.mdi-repeat-off:before {
  content: "\F457"; }

.mdi-repeat-once:before {
  content: "\F458"; }

.mdi-replay:before {
  content: "\F459"; }

.mdi-reply:before {
  content: "\F45A"; }

.mdi-reply-all:before {
  content: "\F45B"; }

.mdi-reproduction:before {
  content: "\F45C"; }

.mdi-resize-bottom-right:before {
  content: "\F45D"; }

.mdi-responsive:before {
  content: "\F45E"; }

.mdi-restart:before {
  content: "\F708"; }

.mdi-restore:before {
  content: "\F6A7"; }

.mdi-rewind:before {
  content: "\F45F"; }

.mdi-rewind-outline:before {
  content: "\F709"; }

.mdi-rhombus:before {
  content: "\F70A"; }

.mdi-rhombus-outline:before {
  content: "\F70B"; }

.mdi-ribbon:before {
  content: "\F460"; }

.mdi-road:before {
  content: "\F461"; }

.mdi-road-variant:before {
  content: "\F462"; }

.mdi-robot:before {
  content: "\F6A8"; }

.mdi-rocket:before {
  content: "\F463"; }

.mdi-roomba:before {
  content: "\F70C"; }

.mdi-rotate-3d:before {
  content: "\F464"; }

.mdi-rotate-left:before {
  content: "\F465"; }

.mdi-rotate-left-variant:before {
  content: "\F466"; }

.mdi-rotate-right:before {
  content: "\F467"; }

.mdi-rotate-right-variant:before {
  content: "\F468"; }

.mdi-rounded-corner:before {
  content: "\F607"; }

.mdi-router-wireless:before {
  content: "\F469"; }

.mdi-routes:before {
  content: "\F46A"; }

.mdi-rowing:before {
  content: "\F608"; }

.mdi-rss:before {
  content: "\F46B"; }

.mdi-rss-box:before {
  content: "\F46C"; }

.mdi-ruler:before {
  content: "\F46D"; }

.mdi-run:before {
  content: "\F70D"; }

.mdi-run-fast:before {
  content: "\F46E"; }

.mdi-sale:before {
  content: "\F46F"; }

.mdi-satellite:before {
  content: "\F470"; }

.mdi-satellite-variant:before {
  content: "\F471"; }

.mdi-saxophone:before {
  content: "\F609"; }

.mdi-scale:before {
  content: "\F472"; }

.mdi-scale-balance:before {
  content: "\F5D1"; }

.mdi-scale-bathroom:before {
  content: "\F473"; }

.mdi-scanner:before {
  content: "\F6AA"; }

.mdi-school:before {
  content: "\F474"; }

.mdi-screen-rotation:before {
  content: "\F475"; }

.mdi-screen-rotation-lock:before {
  content: "\F476"; }

.mdi-screwdriver:before {
  content: "\F477"; }

.mdi-script:before {
  content: "\F478"; }

.mdi-sd:before {
  content: "\F479"; }

.mdi-seal:before {
  content: "\F47A"; }

.mdi-search-web:before {
  content: "\F70E"; }

.mdi-seat-flat:before {
  content: "\F47B"; }

.mdi-seat-flat-angled:before {
  content: "\F47C"; }

.mdi-seat-individual-suite:before {
  content: "\F47D"; }

.mdi-seat-legroom-extra:before {
  content: "\F47E"; }

.mdi-seat-legroom-normal:before {
  content: "\F47F"; }

.mdi-seat-legroom-reduced:before {
  content: "\F480"; }

.mdi-seat-recline-extra:before {
  content: "\F481"; }

.mdi-seat-recline-normal:before {
  content: "\F482"; }

.mdi-security:before {
  content: "\F483"; }

.mdi-security-home:before {
  content: "\F689"; }

.mdi-security-network:before {
  content: "\F484"; }

.mdi-select:before {
  content: "\F485"; }

.mdi-select-all:before {
  content: "\F486"; }

.mdi-select-inverse:before {
  content: "\F487"; }

.mdi-select-off:before {
  content: "\F488"; }

.mdi-selection:before {
  content: "\F489"; }

.mdi-selection-off:before {
  content: "\F776"; }

.mdi-send:before {
  content: "\F48A"; }

.mdi-serial-port:before {
  content: "\F65C"; }

.mdi-server:before {
  content: "\F48B"; }

.mdi-server-minus:before {
  content: "\F48C"; }

.mdi-server-network:before {
  content: "\F48D"; }

.mdi-server-network-off:before {
  content: "\F48E"; }

.mdi-server-off:before {
  content: "\F48F"; }

.mdi-server-plus:before {
  content: "\F490"; }

.mdi-server-remove:before {
  content: "\F491"; }

.mdi-server-security:before {
  content: "\F492"; }

.mdi-set-all:before {
  content: "\F777"; }

.mdi-set-center:before {
  content: "\F778"; }

.mdi-set-center-right:before {
  content: "\F779"; }

.mdi-set-left:before {
  content: "\F77A"; }

.mdi-set-left-center:before {
  content: "\F77B"; }

.mdi-set-left-right:before {
  content: "\F77C"; }

.mdi-set-none:before {
  content: "\F77D"; }

.mdi-set-right:before {
  content: "\F77E"; }

.mdi-settings:before {
  content: "\F493"; }

.mdi-settings-box:before {
  content: "\F494"; }

.mdi-shape-circle-plus:before {
  content: "\F65D"; }

.mdi-shape-plus:before {
  content: "\F495"; }

.mdi-shape-polygon-plus:before {
  content: "\F65E"; }

.mdi-shape-rectangle-plus:before {
  content: "\F65F"; }

.mdi-shape-square-plus:before {
  content: "\F660"; }

.mdi-share:before {
  content: "\F496"; }

.mdi-share-variant:before {
  content: "\F497"; }

.mdi-shield:before {
  content: "\F498"; }

.mdi-shield-half-full:before {
  content: "\F77F"; }

.mdi-shield-outline:before {
  content: "\F499"; }

.mdi-shopping:before {
  content: "\F49A"; }

.mdi-shopping-music:before {
  content: "\F49B"; }

.mdi-shovel:before {
  content: "\F70F"; }

.mdi-shovel-off:before {
  content: "\F710"; }

.mdi-shredder:before {
  content: "\F49C"; }

.mdi-shuffle:before {
  content: "\F49D"; }

.mdi-shuffle-disabled:before {
  content: "\F49E"; }

.mdi-shuffle-variant:before {
  content: "\F49F"; }

.mdi-sigma:before {
  content: "\F4A0"; }

.mdi-sigma-lower:before {
  content: "\F62B"; }

.mdi-sign-caution:before {
  content: "\F4A1"; }

.mdi-sign-direction:before {
  content: "\F780"; }

.mdi-sign-text:before {
  content: "\F781"; }

.mdi-signal:before {
  content: "\F4A2"; }

.mdi-signal-2g:before {
  content: "\F711"; }

.mdi-signal-3g:before {
  content: "\F712"; }

.mdi-signal-4g:before {
  content: "\F713"; }

.mdi-signal-hspa:before {
  content: "\F714"; }

.mdi-signal-hspa-plus:before {
  content: "\F715"; }

.mdi-signal-off:before {
  content: "\F782"; }

.mdi-signal-variant:before {
  content: "\F60A"; }

.mdi-silverware:before {
  content: "\F4A3"; }

.mdi-silverware-fork:before {
  content: "\F4A4"; }

.mdi-silverware-spoon:before {
  content: "\F4A5"; }

.mdi-silverware-variant:before {
  content: "\F4A6"; }

.mdi-sim:before {
  content: "\F4A7"; }

.mdi-sim-alert:before {
  content: "\F4A8"; }

.mdi-sim-off:before {
  content: "\F4A9"; }

.mdi-sitemap:before {
  content: "\F4AA"; }

.mdi-skip-backward:before {
  content: "\F4AB"; }

.mdi-skip-forward:before {
  content: "\F4AC"; }

.mdi-skip-next:before {
  content: "\F4AD"; }

.mdi-skip-next-circle:before {
  content: "\F661"; }

.mdi-skip-next-circle-outline:before {
  content: "\F662"; }

.mdi-skip-previous:before {
  content: "\F4AE"; }

.mdi-skip-previous-circle:before {
  content: "\F663"; }

.mdi-skip-previous-circle-outline:before {
  content: "\F664"; }

.mdi-skull:before {
  content: "\F68B"; }

.mdi-skype:before {
  content: "\F4AF"; }

.mdi-skype-business:before {
  content: "\F4B0"; }

.mdi-slack:before {
  content: "\F4B1"; }

.mdi-sleep:before {
  content: "\F4B2"; }

.mdi-sleep-off:before {
  content: "\F4B3"; }

.mdi-smoking:before {
  content: "\F4B4"; }

.mdi-smoking-off:before {
  content: "\F4B5"; }

.mdi-snapchat:before {
  content: "\F4B6"; }

.mdi-snowflake:before {
  content: "\F716"; }

.mdi-snowman:before {
  content: "\F4B7"; }

.mdi-soccer:before {
  content: "\F4B8"; }

.mdi-sofa:before {
  content: "\F4B9"; }

.mdi-solid:before {
  content: "\F68C"; }

.mdi-sort:before {
  content: "\F4BA"; }

.mdi-sort-alphabetical:before {
  content: "\F4BB"; }

.mdi-sort-ascending:before {
  content: "\F4BC"; }

.mdi-sort-descending:before {
  content: "\F4BD"; }

.mdi-sort-numeric:before {
  content: "\F4BE"; }

.mdi-sort-variant:before {
  content: "\F4BF"; }

.mdi-soundcloud:before {
  content: "\F4C0"; }

.mdi-source-branch:before {
  content: "\F62C"; }

.mdi-source-commit:before {
  content: "\F717"; }

.mdi-source-commit-end:before {
  content: "\F718"; }

.mdi-source-commit-end-local:before {
  content: "\F719"; }

.mdi-source-commit-local:before {
  content: "\F71A"; }

.mdi-source-commit-next-local:before {
  content: "\F71B"; }

.mdi-source-commit-start:before {
  content: "\F71C"; }

.mdi-source-commit-start-next-local:before {
  content: "\F71D"; }

.mdi-source-fork:before {
  content: "\F4C1"; }

.mdi-source-merge:before {
  content: "\F62D"; }

.mdi-source-pull:before {
  content: "\F4C2"; }

.mdi-speaker:before {
  content: "\F4C3"; }

.mdi-speaker-off:before {
  content: "\F4C4"; }

.mdi-speaker-wireless:before {
  content: "\F71E"; }

.mdi-speedometer:before {
  content: "\F4C5"; }

.mdi-spellcheck:before {
  content: "\F4C6"; }

.mdi-spotify:before {
  content: "\F4C7"; }

.mdi-spotlight:before {
  content: "\F4C8"; }

.mdi-spotlight-beam:before {
  content: "\F4C9"; }

.mdi-spray:before {
  content: "\F665"; }

.mdi-square:before {
  content: "\F763"; }

.mdi-square-inc:before {
  content: "\F4CA"; }

.mdi-square-inc-cash:before {
  content: "\F4CB"; }

.mdi-square-outline:before {
  content: "\F762"; }

.mdi-square-root:before {
  content: "\F783"; }

.mdi-stackexchange:before {
  content: "\F60B"; }

.mdi-stackoverflow:before {
  content: "\F4CC"; }

.mdi-stadium:before {
  content: "\F71F"; }

.mdi-stairs:before {
  content: "\F4CD"; }

.mdi-star:before {
  content: "\F4CE"; }

.mdi-star-circle:before {
  content: "\F4CF"; }

.mdi-star-half:before {
  content: "\F4D0"; }

.mdi-star-off:before {
  content: "\F4D1"; }

.mdi-star-outline:before {
  content: "\F4D2"; }

.mdi-steam:before {
  content: "\F4D3"; }

.mdi-steering:before {
  content: "\F4D4"; }

.mdi-step-backward:before {
  content: "\F4D5"; }

.mdi-step-backward-2:before {
  content: "\F4D6"; }

.mdi-step-forward:before {
  content: "\F4D7"; }

.mdi-step-forward-2:before {
  content: "\F4D8"; }

.mdi-stethoscope:before {
  content: "\F4D9"; }

.mdi-sticker:before {
  content: "\F5D0"; }

.mdi-sticker-emoji:before {
  content: "\F784"; }

.mdi-stocking:before {
  content: "\F4DA"; }

.mdi-stop:before {
  content: "\F4DB"; }

.mdi-stop-circle:before {
  content: "\F666"; }

.mdi-stop-circle-outline:before {
  content: "\F667"; }

.mdi-store:before {
  content: "\F4DC"; }

.mdi-store-24-hour:before {
  content: "\F4DD"; }

.mdi-stove:before {
  content: "\F4DE"; }

.mdi-subdirectory-arrow-left:before {
  content: "\F60C"; }

.mdi-subdirectory-arrow-right:before {
  content: "\F60D"; }

.mdi-subway:before {
  content: "\F6AB"; }

.mdi-subway-variant:before {
  content: "\F4DF"; }

.mdi-summit:before {
  content: "\F785"; }

.mdi-sunglasses:before {
  content: "\F4E0"; }

.mdi-surround-sound:before {
  content: "\F5C5"; }

.mdi-svg:before {
  content: "\F720"; }

.mdi-swap-horizontal:before {
  content: "\F4E1"; }

.mdi-swap-vertical:before {
  content: "\F4E2"; }

.mdi-swim:before {
  content: "\F4E3"; }

.mdi-switch:before {
  content: "\F4E4"; }

.mdi-sword:before {
  content: "\F4E5"; }

.mdi-sword-cross:before {
  content: "\F786"; }

.mdi-sync:before {
  content: "\F4E6"; }

.mdi-sync-alert:before {
  content: "\F4E7"; }

.mdi-sync-off:before {
  content: "\F4E8"; }

.mdi-tab:before {
  content: "\F4E9"; }

.mdi-tab-plus:before {
  content: "\F75B"; }

.mdi-tab-unselected:before {
  content: "\F4EA"; }

.mdi-table:before {
  content: "\F4EB"; }

.mdi-table-column-plus-after:before {
  content: "\F4EC"; }

.mdi-table-column-plus-before:before {
  content: "\F4ED"; }

.mdi-table-column-remove:before {
  content: "\F4EE"; }

.mdi-table-column-width:before {
  content: "\F4EF"; }

.mdi-table-edit:before {
  content: "\F4F0"; }

.mdi-table-large:before {
  content: "\F4F1"; }

.mdi-table-row-height:before {
  content: "\F4F2"; }

.mdi-table-row-plus-after:before {
  content: "\F4F3"; }

.mdi-table-row-plus-before:before {
  content: "\F4F4"; }

.mdi-table-row-remove:before {
  content: "\F4F5"; }

.mdi-tablet:before {
  content: "\F4F6"; }

.mdi-tablet-android:before {
  content: "\F4F7"; }

.mdi-tablet-ipad:before {
  content: "\F4F8"; }

.mdi-taco:before {
  content: "\F761"; }

.mdi-tag:before {
  content: "\F4F9"; }

.mdi-tag-faces:before {
  content: "\F4FA"; }

.mdi-tag-heart:before {
  content: "\F68A"; }

.mdi-tag-multiple:before {
  content: "\F4FB"; }

.mdi-tag-outline:before {
  content: "\F4FC"; }

.mdi-tag-plus:before {
  content: "\F721"; }

.mdi-tag-remove:before {
  content: "\F722"; }

.mdi-tag-text-outline:before {
  content: "\F4FD"; }

.mdi-target:before {
  content: "\F4FE"; }

.mdi-taxi:before {
  content: "\F4FF"; }

.mdi-teamviewer:before {
  content: "\F500"; }

.mdi-telegram:before {
  content: "\F501"; }

.mdi-television:before {
  content: "\F502"; }

.mdi-television-guide:before {
  content: "\F503"; }

.mdi-temperature-celsius:before {
  content: "\F504"; }

.mdi-temperature-fahrenheit:before {
  content: "\F505"; }

.mdi-temperature-kelvin:before {
  content: "\F506"; }

.mdi-tennis:before {
  content: "\F507"; }

.mdi-tent:before {
  content: "\F508"; }

.mdi-terrain:before {
  content: "\F509"; }

.mdi-test-tube:before {
  content: "\F668"; }

.mdi-text-shadow:before {
  content: "\F669"; }

.mdi-text-to-speech:before {
  content: "\F50A"; }

.mdi-text-to-speech-off:before {
  content: "\F50B"; }

.mdi-textbox:before {
  content: "\F60E"; }

.mdi-texture:before {
  content: "\F50C"; }

.mdi-theater:before {
  content: "\F50D"; }

.mdi-theme-light-dark:before {
  content: "\F50E"; }

.mdi-thermometer:before {
  content: "\F50F"; }

.mdi-thermometer-lines:before {
  content: "\F510"; }

.mdi-thumb-down:before {
  content: "\F511"; }

.mdi-thumb-down-outline:before {
  content: "\F512"; }

.mdi-thumb-up:before {
  content: "\F513"; }

.mdi-thumb-up-outline:before {
  content: "\F514"; }

.mdi-thumbs-up-down:before {
  content: "\F515"; }

.mdi-ticket:before {
  content: "\F516"; }

.mdi-ticket-account:before {
  content: "\F517"; }

.mdi-ticket-confirmation:before {
  content: "\F518"; }

.mdi-ticket-percent:before {
  content: "\F723"; }

.mdi-tie:before {
  content: "\F519"; }

.mdi-tilde:before {
  content: "\F724"; }

.mdi-timelapse:before {
  content: "\F51A"; }

.mdi-timer:before {
  content: "\F51B"; }

.mdi-timer-10:before {
  content: "\F51C"; }

.mdi-timer-3:before {
  content: "\F51D"; }

.mdi-timer-off:before {
  content: "\F51E"; }

.mdi-timer-sand:before {
  content: "\F51F"; }

.mdi-timer-sand-empty:before {
  content: "\F6AC"; }

.mdi-timer-sand-full:before {
  content: "\F78B"; }

.mdi-timetable:before {
  content: "\F520"; }

.mdi-toggle-switch:before {
  content: "\F521"; }

.mdi-toggle-switch-off:before {
  content: "\F522"; }

.mdi-tooltip:before {
  content: "\F523"; }

.mdi-tooltip-edit:before {
  content: "\F524"; }

.mdi-tooltip-image:before {
  content: "\F525"; }

.mdi-tooltip-outline:before {
  content: "\F526"; }

.mdi-tooltip-outline-plus:before {
  content: "\F527"; }

.mdi-tooltip-text:before {
  content: "\F528"; }

.mdi-tooth:before {
  content: "\F529"; }

.mdi-tor:before {
  content: "\F52A"; }

.mdi-tower-beach:before {
  content: "\F680"; }

.mdi-tower-fire:before {
  content: "\F681"; }

.mdi-traffic-light:before {
  content: "\F52B"; }

.mdi-train:before {
  content: "\F52C"; }

.mdi-tram:before {
  content: "\F52D"; }

.mdi-transcribe:before {
  content: "\F52E"; }

.mdi-transcribe-close:before {
  content: "\F52F"; }

.mdi-transfer:before {
  content: "\F530"; }

.mdi-transit-transfer:before {
  content: "\F6AD"; }

.mdi-translate:before {
  content: "\F5CA"; }

.mdi-treasure-chest:before {
  content: "\F725"; }

.mdi-tree:before {
  content: "\F531"; }

.mdi-trello:before {
  content: "\F532"; }

.mdi-trending-down:before {
  content: "\F533"; }

.mdi-trending-neutral:before {
  content: "\F534"; }

.mdi-trending-up:before {
  content: "\F535"; }

.mdi-triangle:before {
  content: "\F536"; }

.mdi-triangle-outline:before {
  content: "\F537"; }

.mdi-trophy:before {
  content: "\F538"; }

.mdi-trophy-award:before {
  content: "\F539"; }

.mdi-trophy-outline:before {
  content: "\F53A"; }

.mdi-trophy-variant:before {
  content: "\F53B"; }

.mdi-trophy-variant-outline:before {
  content: "\F53C"; }

.mdi-truck:before {
  content: "\F53D"; }

.mdi-truck-delivery:before {
  content: "\F53E"; }

.mdi-truck-fast:before {
  content: "\F787"; }

.mdi-truck-trailer:before {
  content: "\F726"; }

.mdi-tshirt-crew:before {
  content: "\F53F"; }

.mdi-tshirt-v:before {
  content: "\F540"; }

.mdi-tumblr:before {
  content: "\F541"; }

.mdi-tumblr-reblog:before {
  content: "\F542"; }

.mdi-tune:before {
  content: "\F62E"; }

.mdi-tune-vertical:before {
  content: "\F66A"; }

.mdi-twitch:before {
  content: "\F543"; }

.mdi-twitter:before {
  content: "\F544"; }

.mdi-twitter-box:before {
  content: "\F545"; }

.mdi-twitter-circle:before {
  content: "\F546"; }

.mdi-twitter-retweet:before {
  content: "\F547"; }

.mdi-uber:before {
  content: "\F748"; }

.mdi-ubuntu:before {
  content: "\F548"; }

.mdi-umbraco:before {
  content: "\F549"; }

.mdi-umbrella:before {
  content: "\F54A"; }

.mdi-umbrella-outline:before {
  content: "\F54B"; }

.mdi-undo:before {
  content: "\F54C"; }

.mdi-undo-variant:before {
  content: "\F54D"; }

.mdi-unfold-less-horizontal:before {
  content: "\F54E"; }

.mdi-unfold-less-vertical:before {
  content: "\F75F"; }

.mdi-unfold-more-horizontal:before {
  content: "\F54F"; }

.mdi-unfold-more-vertical:before {
  content: "\F760"; }

.mdi-ungroup:before {
  content: "\F550"; }

.mdi-unity:before {
  content: "\F6AE"; }

.mdi-untappd:before {
  content: "\F551"; }

.mdi-update:before {
  content: "\F6AF"; }

.mdi-upload:before {
  content: "\F552"; }

.mdi-upload-network:before {
  content: "\F6F5"; }

.mdi-usb:before {
  content: "\F553"; }

.mdi-vector-arrange-above:before {
  content: "\F554"; }

.mdi-vector-arrange-below:before {
  content: "\F555"; }

.mdi-vector-circle:before {
  content: "\F556"; }

.mdi-vector-circle-variant:before {
  content: "\F557"; }

.mdi-vector-combine:before {
  content: "\F558"; }

.mdi-vector-curve:before {
  content: "\F559"; }

.mdi-vector-difference:before {
  content: "\F55A"; }

.mdi-vector-difference-ab:before {
  content: "\F55B"; }

.mdi-vector-difference-ba:before {
  content: "\F55C"; }

.mdi-vector-intersection:before {
  content: "\F55D"; }

.mdi-vector-line:before {
  content: "\F55E"; }

.mdi-vector-point:before {
  content: "\F55F"; }

.mdi-vector-polygon:before {
  content: "\F560"; }

.mdi-vector-polyline:before {
  content: "\F561"; }

.mdi-vector-radius:before {
  content: "\F749"; }

.mdi-vector-rectangle:before {
  content: "\F5C6"; }

.mdi-vector-selection:before {
  content: "\F562"; }

.mdi-vector-square:before {
  content: "\F001"; }

.mdi-vector-triangle:before {
  content: "\F563"; }

.mdi-vector-union:before {
  content: "\F564"; }

.mdi-verified:before {
  content: "\F565"; }

.mdi-vibrate:before {
  content: "\F566"; }

.mdi-video:before {
  content: "\F567"; }

.mdi-video-off:before {
  content: "\F568"; }

.mdi-video-switch:before {
  content: "\F569"; }

.mdi-view-agenda:before {
  content: "\F56A"; }

.mdi-view-array:before {
  content: "\F56B"; }

.mdi-view-carousel:before {
  content: "\F56C"; }

.mdi-view-column:before {
  content: "\F56D"; }

.mdi-view-dashboard:before {
  content: "\F56E"; }

.mdi-view-day:before {
  content: "\F56F"; }

.mdi-view-grid:before {
  content: "\F570"; }

.mdi-view-headline:before {
  content: "\F571"; }

.mdi-view-list:before {
  content: "\F572"; }

.mdi-view-module:before {
  content: "\F573"; }

.mdi-view-parallel:before {
  content: "\F727"; }

.mdi-view-quilt:before {
  content: "\F574"; }

.mdi-view-sequential:before {
  content: "\F728"; }

.mdi-view-stream:before {
  content: "\F575"; }

.mdi-view-week:before {
  content: "\F576"; }

.mdi-vimeo:before {
  content: "\F577"; }

.mdi-vine:before {
  content: "\F578"; }

.mdi-violin:before {
  content: "\F60F"; }

.mdi-visualstudio:before {
  content: "\F610"; }

.mdi-vk:before {
  content: "\F579"; }

.mdi-vk-box:before {
  content: "\F57A"; }

.mdi-vk-circle:before {
  content: "\F57B"; }

.mdi-vlc:before {
  content: "\F57C"; }

.mdi-voice:before {
  content: "\F5CB"; }

.mdi-voicemail:before {
  content: "\F57D"; }

.mdi-volume-high:before {
  content: "\F57E"; }

.mdi-volume-low:before {
  content: "\F57F"; }

.mdi-volume-medium:before {
  content: "\F580"; }

.mdi-volume-minus:before {
  content: "\F75D"; }

.mdi-volume-mute:before {
  content: "\F75E"; }

.mdi-volume-off:before {
  content: "\F581"; }

.mdi-volume-plus:before {
  content: "\F75C"; }

.mdi-vpn:before {
  content: "\F582"; }

.mdi-walk:before {
  content: "\F583"; }

.mdi-wallet:before {
  content: "\F584"; }

.mdi-wallet-giftcard:before {
  content: "\F585"; }

.mdi-wallet-membership:before {
  content: "\F586"; }

.mdi-wallet-travel:before {
  content: "\F587"; }

.mdi-wan:before {
  content: "\F588"; }

.mdi-washing-machine:before {
  content: "\F729"; }

.mdi-watch:before {
  content: "\F589"; }

.mdi-watch-export:before {
  content: "\F58A"; }

.mdi-watch-import:before {
  content: "\F58B"; }

.mdi-watch-vibrate:before {
  content: "\F6B0"; }

.mdi-water:before {
  content: "\F58C"; }

.mdi-water-off:before {
  content: "\F58D"; }

.mdi-water-percent:before {
  content: "\F58E"; }

.mdi-water-pump:before {
  content: "\F58F"; }

.mdi-watermark:before {
  content: "\F612"; }

.mdi-waves:before {
  content: "\F78C"; }

.mdi-weather-cloudy:before {
  content: "\F590"; }

.mdi-weather-fog:before {
  content: "\F591"; }

.mdi-weather-hail:before {
  content: "\F592"; }

.mdi-weather-lightning:before {
  content: "\F593"; }

.mdi-weather-lightning-rainy:before {
  content: "\F67D"; }

.mdi-weather-night:before {
  content: "\F594"; }

.mdi-weather-partlycloudy:before {
  content: "\F595"; }

.mdi-weather-pouring:before {
  content: "\F596"; }

.mdi-weather-rainy:before {
  content: "\F597"; }

.mdi-weather-snowy:before {
  content: "\F598"; }

.mdi-weather-snowy-rainy:before {
  content: "\F67E"; }

.mdi-weather-sunny:before {
  content: "\F599"; }

.mdi-weather-sunset:before {
  content: "\F59A"; }

.mdi-weather-sunset-down:before {
  content: "\F59B"; }

.mdi-weather-sunset-up:before {
  content: "\F59C"; }

.mdi-weather-windy:before {
  content: "\F59D"; }

.mdi-weather-windy-variant:before {
  content: "\F59E"; }

.mdi-web:before {
  content: "\F59F"; }

.mdi-webcam:before {
  content: "\F5A0"; }

.mdi-webhook:before {
  content: "\F62F"; }

.mdi-webpack:before {
  content: "\F72A"; }

.mdi-wechat:before {
  content: "\F611"; }

.mdi-weight:before {
  content: "\F5A1"; }

.mdi-weight-kilogram:before {
  content: "\F5A2"; }

.mdi-whatsapp:before {
  content: "\F5A3"; }

.mdi-wheelchair-accessibility:before {
  content: "\F5A4"; }

.mdi-white-balance-auto:before {
  content: "\F5A5"; }

.mdi-white-balance-incandescent:before {
  content: "\F5A6"; }

.mdi-white-balance-iridescent:before {
  content: "\F5A7"; }

.mdi-white-balance-sunny:before {
  content: "\F5A8"; }

.mdi-widgets:before {
  content: "\F72B"; }

.mdi-wifi:before {
  content: "\F5A9"; }

.mdi-wifi-off:before {
  content: "\F5AA"; }

.mdi-wii:before {
  content: "\F5AB"; }

.mdi-wiiu:before {
  content: "\F72C"; }

.mdi-wikipedia:before {
  content: "\F5AC"; }

.mdi-window-close:before {
  content: "\F5AD"; }

.mdi-window-closed:before {
  content: "\F5AE"; }

.mdi-window-maximize:before {
  content: "\F5AF"; }

.mdi-window-minimize:before {
  content: "\F5B0"; }

.mdi-window-open:before {
  content: "\F5B1"; }

.mdi-window-restore:before {
  content: "\F5B2"; }

.mdi-windows:before {
  content: "\F5B3"; }

.mdi-wordpress:before {
  content: "\F5B4"; }

.mdi-worker:before {
  content: "\F5B5"; }

.mdi-wrap:before {
  content: "\F5B6"; }

.mdi-wrench:before {
  content: "\F5B7"; }

.mdi-wunderlist:before {
  content: "\F5B8"; }

.mdi-xaml:before {
  content: "\F673"; }

.mdi-xbox:before {
  content: "\F5B9"; }

.mdi-xbox-controller:before {
  content: "\F5BA"; }

.mdi-xbox-controller-battery-alert:before {
  content: "\F74A"; }

.mdi-xbox-controller-battery-empty:before {
  content: "\F74B"; }

.mdi-xbox-controller-battery-full:before {
  content: "\F74C"; }

.mdi-xbox-controller-battery-low:before {
  content: "\F74D"; }

.mdi-xbox-controller-battery-medium:before {
  content: "\F74E"; }

.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F"; }

.mdi-xbox-controller-off:before {
  content: "\F5BB"; }

.mdi-xda:before {
  content: "\F5BC"; }

.mdi-xing:before {
  content: "\F5BD"; }

.mdi-xing-box:before {
  content: "\F5BE"; }

.mdi-xing-circle:before {
  content: "\F5BF"; }

.mdi-xml:before {
  content: "\F5C0"; }

.mdi-yammer:before {
  content: "\F788"; }

.mdi-yeast:before {
  content: "\F5C1"; }

.mdi-yelp:before {
  content: "\F5C2"; }

.mdi-yin-yang:before {
  content: "\F67F"; }

.mdi-youtube-play:before {
  content: "\F5C3"; }

.mdi-zip-box:before {
  content: "\F5C4"; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: white; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-45:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-90:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-135:before {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg); }

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-180:before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-225:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg); }

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-270:before {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
  .mdi-rotate-315:before {
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg); }

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.20-master-595fefe
 */
html, body {
  height: 100%;
  position: relative; }

body {
  margin: 0;
  padding: 0; }

[tabindex='-1']:focus {
  outline: none; }

.inset {
  padding: 10px; }

a.md-no-style,
button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0; }

select,
button,
textarea,
input {
  vertical-align: baseline; }

input[type="reset"],
input[type="submit"],
html input[type="button"],
button {
  cursor: pointer;
  -webkit-appearance: button; }
  input[type="reset"][disabled],
  input[type="submit"][disabled],
  html input[type="button"][disabled],
  button[disabled] {
    cursor: default; }

textarea {
  vertical-align: top;
  overflow: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box; }
  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }

input:-webkit-autofill {
  text-shadow: none; }

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px; }

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none; }

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%; }
  .md-ripple.md-ripple-placed {
    transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-ripple.md-ripple-scaled {
    transform: translate(-50%, -50%) scale(1); }
  .md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
    opacity: 0.20; }
  .md-ripple.md-ripple-remove {
    animation: md-remove-ripple 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }

@keyframes md-remove-ripple {
  0% {
    opacity: .15; }
  100% {
    opacity: 0; } }

.md-padding {
  padding: 8px; }

.md-margin {
  margin: 8px; }

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50; }
  .md-scroll-mask > .md-scroll-mask-bar {
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); }

.md-no-momentum {
  -webkit-overflow-scrolling: auto; }

.md-no-flicker {
  -webkit-filter: blur(0px); }

@media (min-width: 960px) {
  .md-padding {
    padding: 16px; } }

html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed; }

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override; }

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override; }

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px; }

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px; }

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px; }

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.md-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em; }

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px; }

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px; }

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px; }

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em; }

.md-button {
  letter-spacing: 0.010em; }

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

select,
button,
textarea,
input {
  font-size: 100%; }

/*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*/
.md-panel-outer-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

._md-panel-hidden {
  display: none; }

._md-panel-offscreen {
  left: -9999px; }

._md-panel-fullscreen {
  border-radius: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0; }

._md-panel-shown .md-panel {
  opacity: 1;
  transition: none; }

.md-panel {
  opacity: 0;
  position: fixed; }
  .md-panel._md-panel-shown {
    opacity: 1;
    transition: none; }
  .md-panel._md-panel-animate-enter {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .md-panel._md-panel-animate-leave {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1); }
  .md-panel._md-panel-animate-scale-out, .md-panel._md-panel-animate-fade-out {
    opacity: 0; }
  .md-panel._md-panel-backdrop {
    height: 100%;
    position: absolute;
    width: 100%; }
  .md-panel._md-opaque-enter {
    opacity: .48;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .md-panel._md-opaque-leave {
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1); }

md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px; }
  md-autocomplete[disabled] input {
    cursor: default; }
  md-autocomplete[md-floating-label] {
    border-radius: 0;
    background: transparent;
    height: auto; }
    md-autocomplete[md-floating-label] md-input-container {
      padding-bottom: 0; }
    md-autocomplete[md-floating-label] md-autocomplete-wrap {
      height: auto; }
    md-autocomplete[md-floating-label] .md-show-clear-button button {
      display: block;
      position: absolute;
      right: 0;
      top: 20px;
      width: 30px;
      height: 30px; }
    md-autocomplete[md-floating-label] .md-show-clear-button input {
      padding-right: 30px; }
      [dir=rtl] md-autocomplete[md-floating-label] .md-show-clear-button input {
        padding-right: 0;
        padding-left: 30px; }
  md-autocomplete md-autocomplete-wrap {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    overflow: visible;
    height: 40px; }
    md-autocomplete md-autocomplete-wrap.md-menu-showing {
      z-index: 51; }
    md-autocomplete md-autocomplete-wrap md-input-container,
    md-autocomplete md-autocomplete-wrap input {
      flex: 1 1 0;
      box-sizing: border-box;
      min-width: 0; }
    md-autocomplete md-autocomplete-wrap md-progress-linear {
      position: absolute;
      bottom: -2px;
      left: 0; }
      md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
        bottom: 40px;
        right: 2px;
        left: 2px;
        width: auto; }
      md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        transition: none; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate .md-container {
          transition: none;
          height: 3px; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter.ng-enter-active {
            opacity: 1; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave.ng-leave-active {
            opacity: 0; }
  md-autocomplete input:not(.md-input) {
    font-size: 14px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px; }
    md-autocomplete input:not(.md-input)::-ms-clear {
      display: none; }
  md-autocomplete .md-show-clear-button button {
    position: relative;
    line-height: 20px;
    text-align: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    padding: 0;
    font-size: 12px;
    background: transparent;
    margin: auto 5px; }
    md-autocomplete .md-show-clear-button button:after {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-autocomplete .md-show-clear-button button:focus {
      outline: none; }
      md-autocomplete .md-show-clear-button button:focus:after {
        transform: scale(1);
        opacity: 1; }
    md-autocomplete .md-show-clear-button button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.9); }
      md-autocomplete .md-show-clear-button button md-icon path {
        stroke-width: 0; }
    md-autocomplete .md-show-clear-button button.ng-enter {
      transform: scale(0);
      transition: transform 0.15s ease-out; }
      md-autocomplete .md-show-clear-button button.ng-enter.ng-enter-active {
        transform: scale(1); }
    md-autocomplete .md-show-clear-button button.ng-leave {
      transition: transform 0.15s ease-out; }
      md-autocomplete .md-show-clear-button button.ng-leave.ng-leave-active {
        transform: scale(0); }
  @media screen and (-ms-high-contrast: active) {
    md-autocomplete input {
      border: 1px solid #fff; }
    md-autocomplete .md-autocomplete-suggestion:focus {
      color: #fff; } }

.md-virtual-repeat-container.md-autocomplete-suggestions-container,
.md-standard-list-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
  height: 100%; }
  .md-virtual-repeat-container.md-autocomplete-suggestions-container .highlight,
  .md-standard-list-container.md-autocomplete-suggestions-container .highlight {
    font-weight: bold; }

.md-standard-list-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 0; }

.md-virtual-repeat-container.md-not-found,
.md-standard-list-container.md-not-found {
  height: 48px; }

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0; }
  .md-autocomplete-suggestions .md-autocomplete-suggestion {
    font-size: 14px;
    overflow: hidden;
    padding: 0 15px;
    line-height: 48px;
    height: 48px;
    transition: background 0.15s linear;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .md-autocomplete-suggestions .md-autocomplete-suggestion:focus {
      outline: none; }
    .md-autocomplete-suggestions .md-autocomplete-suggestion:not(.md-not-found-wrapper) {
      cursor: pointer; }

@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff; } }

md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50; }
  md-backdrop.md-menu-backdrop {
    position: fixed !important;
    z-index: 99; }
  md-backdrop.md-select-backdrop {
    z-index: 81;
    transition-duration: 0; }
  md-backdrop.md-dialog-backdrop {
    z-index: 79; }
  md-backdrop.md-bottom-sheet-backdrop {
    z-index: 69; }
  md-backdrop.md-sidenav-backdrop {
    z-index: 59; }
  md-backdrop.md-click-catcher {
    position: absolute; }
  md-backdrop.md-opaque {
    opacity: .48; }
    md-backdrop.md-opaque.ng-enter {
      opacity: 0; }
    md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
      opacity: .48; }
    md-backdrop.md-opaque.ng-leave {
      opacity: .48;
      transition: opacity 400ms; }
    md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
      opacity: 0; }

md-bottom-sheet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 16px 88px 16px;
  z-index: 70;
  border-top-width: 1px;
  border-top-style: solid;
  transform: translate3d(0, 80px, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform; }
  md-bottom-sheet.md-has-header {
    padding-top: 0; }
  md-bottom-sheet.ng-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-bottom-sheet.ng-enter-active {
    opacity: 1;
    display: block;
    transform: translate3d(0, 80px, 0) !important; }
  md-bottom-sheet.ng-leave-active {
    transform: translate3d(0, 100%, 0) !important;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-bottom-sheet .md-subheader {
    background-color: transparent;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    line-height: 56px;
    padding: 0;
    white-space: nowrap; }
  md-bottom-sheet md-inline-icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    fill: #444; }
  md-bottom-sheet md-list-item {
    display: flex;
    outline: none; }
    md-bottom-sheet md-list-item:hover {
      cursor: pointer; }
  md-bottom-sheet.md-list md-list-item {
    padding: 0;
    align-items: center;
    height: 48px; }
  md-bottom-sheet.md-grid {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0; }
    md-bottom-sheet.md-grid md-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      transition: all 0.5s;
      align-items: center; }
    md-bottom-sheet.md-grid md-list-item {
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;
      height: 96px;
      margin-top: 8px;
      margin-bottom: 8px;
      /* Mixin for how many grid items to show per row */ }
      @media (max-width: 960px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 33.33333%;
          max-width: 33.33333%; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n + 1) {
            align-items: flex-start; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n) {
            align-items: flex-end; } }
      @media (min-width: 960px) and (max-width: 1279px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 25%;
          max-width: 25%; } }
      @media (min-width: 1280px) and (max-width: 1919px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 16.66667%;
          max-width: 16.66667%; } }
      @media (min-width: 1920px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 14.28571%;
          max-width: 14.28571%; } }
      md-bottom-sheet.md-grid md-list-item::before {
        display: none; }
      md-bottom-sheet.md-grid md-list-item .md-list-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48px;
        padding-bottom: 16px; }
      md-bottom-sheet.md-grid md-list-item .md-grid-item-content {
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px; }
      md-bottom-sheet.md-grid md-list-item .md-grid-text {
        font-weight: 400;
        line-height: 16px;
        font-size: 13px;
        margin: 0;
        white-space: nowrap;
        width: 64px;
        text-align: center;
        text-transform: none;
        padding-top: 8px; }

@media screen and (-ms-high-contrast: active) {
  md-bottom-sheet {
    border: 1px solid #fff; } }

button.md-button::-moz-focus-inner {
  border: 0; }

.md-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /** Alignment adjustments */
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  /* Reset default button appearance */
  user-select: none;
  outline: none;
  border: 0;
  /** Custom styling for button */
  padding: 0 6px;
  margin: 6px 8px;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  /* Uppercase text content */
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    min-height: 32px; }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    line-height: 32px; }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    font-size: 13px; }
  .md-button:focus {
    outline: none; }
  .md-button:hover, .md-button:focus {
    text-decoration: none; }
  .md-button.ng-hide, .md-button.ng-leave {
    transition: none; }
  .md-button.md-cornered {
    border-radius: 0; }
  .md-button.md-icon {
    padding: 0;
    background: none; }
  .md-button.md-raised:not([disabled]) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button.md-icon-button {
    margin: 0 6px;
    height: 40px;
    min-width: 0;
    line-height: 24px;
    padding: 8px;
    width: 40px;
    border-radius: 50%; }
  .md-button.md-fab {
    z-index: 20;
    line-height: 56px;
    min-width: 0;
    width: 56px;
    height: 56px;
    vertical-align: middle;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-property: background-color, box-shadow, transform; }
    .md-button.md-fab.md-fab-bottom-right {
      top: auto;
      right: 20px;
      bottom: 20px;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-bottom-left {
      top: auto;
      right: auto;
      bottom: 20px;
      left: 20px;
      position: absolute; }
    .md-button.md-fab.md-fab-top-right {
      top: 20px;
      right: 20px;
      bottom: auto;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-top-left {
      top: 20px;
      right: auto;
      bottom: auto;
      left: 20px;
      position: absolute; }
    .md-button.md-fab.md-mini {
      line-height: 40px;
      width: 40px;
      height: 40px; }
    .md-button.md-fab.ng-hide, .md-button.md-fab.ng-leave {
      transition: none; }
  .md-button:not([disabled]).md-raised.md-focused, .md-button:not([disabled]).md-fab.md-focused {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button:not([disabled]).md-raised:active, .md-button:not([disabled]).md-fab:active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }
  .md-button .md-ripple-container {
    border-radius: inherit;
    background-clip: padding-box;
    overflow: hidden;
    -webkit-transform: translateZ(0); }

.md-button.md-icon-button md-icon,
button.md-button.md-fab md-icon {
  display: block; }

.md-toast-open-top .md-button.md-fab-top-left,
.md-toast-open-top .md-button.md-fab-top-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, 42px, 0); }
  .md-toast-open-top .md-button.md-fab-top-left:not([disabled]).md-focused, .md-toast-open-top .md-button.md-fab-top-left:not([disabled]):hover,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]).md-focused,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]):hover {
    transform: translate3d(0, 41px, 0); }

.md-toast-open-bottom .md-button.md-fab-bottom-left,
.md-toast-open-bottom .md-button.md-fab-bottom-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, -42px, 0); }
  .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]).md-focused, .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]):hover,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]).md-focused,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]):hover {
    transform: translate3d(0, -43px, 0); }

.md-button-group {
  display: flex;
  flex: 1;
  width: 100%; }
  .md-button-group > .md-button {
    flex: 1;
    display: block;
    overflow: hidden;
    width: 0;
    border-width: 1px 0px 1px 1px;
    border-radius: 0;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .md-button-group > .md-button:first-child {
      border-radius: 2px 0px 0px 2px; }
    .md-button-group > .md-button:last-child {
      border-right-width: 1px;
      border-radius: 0px 2px 2px 0px; }

@media screen and (-ms-high-contrast: active) {
  .md-button.md-raised,
  .md-button.md-fab {
    border: 1px solid #fff; } }

md-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  md-card md-card-header {
    padding: 16px;
    display: flex;
    flex-direction: row; }
    md-card md-card-header:first-child md-card-avatar {
      margin-right: 12px; }
      [dir=rtl] md-card md-card-header:first-child md-card-avatar {
        margin-right: auto;
        margin-left: 12px; }
    md-card md-card-header:last-child md-card-avatar {
      margin-left: 12px; }
      [dir=rtl] md-card md-card-header:last-child md-card-avatar {
        margin-left: auto;
        margin-right: 12px; }
    md-card md-card-header md-card-avatar {
      width: 40px;
      height: 40px; }
      md-card md-card-header md-card-avatar .md-user-avatar,
      md-card md-card-header md-card-avatar md-icon {
        border-radius: 50%; }
      md-card md-card-header md-card-avatar md-icon {
        padding: 8px; }
        md-card md-card-header md-card-avatar md-icon > svg {
          height: inherit;
          width: inherit; }
      md-card md-card-header md-card-avatar + md-card-header-text {
        max-height: 40px; }
        md-card md-card-header md-card-avatar + md-card-header-text .md-title {
          font-size: 14px; }
    md-card md-card-header md-card-header-text {
      display: flex;
      flex: 1;
      flex-direction: column; }
      md-card md-card-header md-card-header-text .md-subhead {
        font-size: 14px; }
  md-card > img,
  md-card > md-card-header img,
  md-card md-card-title-media img {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    height: auto; }
  md-card md-card-title {
    padding: 24px 16px 16px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row; }
    md-card md-card-title + md-card-content {
      padding-top: 0; }
    md-card md-card-title md-card-title-text {
      flex: 1;
      flex-direction: column;
      display: flex; }
      md-card md-card-title md-card-title-text .md-subhead {
        padding-top: 0;
        font-size: 14px; }
      md-card md-card-title md-card-title-text:only-child .md-subhead {
        padding-top: 12px; }
    md-card md-card-title md-card-title-media {
      margin-top: -8px; }
      md-card md-card-title md-card-title-media .md-media-sm {
        height: 80px;
        width: 80px; }
      md-card md-card-title md-card-title-media .md-media-md {
        height: 112px;
        width: 112px; }
      md-card md-card-title md-card-title-media .md-media-lg {
        height: 152px;
        width: 152px; }
  md-card md-card-content {
    display: block;
    padding: 16px; }
    md-card md-card-content > p:first-child {
      margin-top: 0; }
    md-card md-card-content > p:last-child {
      margin-bottom: 0; }
    md-card md-card-content .md-media-xl {
      height: 240px;
      width: 240px; }
  md-card .md-actions, md-card md-card-actions {
    margin: 8px; }
    md-card .md-actions.layout-column .md-button:not(.md-icon-button), md-card md-card-actions.layout-column .md-button:not(.md-icon-button) {
      margin: 2px 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):first-of-type {
        margin-top: 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):last-of-type {
        margin-bottom: 0; }
    md-card .md-actions.layout-column .md-button.md-icon-button, md-card md-card-actions.layout-column .md-button.md-icon-button {
      margin-top: 6px;
      margin-bottom: 6px; }
    md-card .md-actions md-card-icon-actions, md-card md-card-actions md-card-icon-actions {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      flex-direction: row; }
    md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button), md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button) {
      margin: 0 4px; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
        margin-left: 0; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
          margin-left: auto;
          margin-right: 0; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
        margin-right: 0; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
          margin-right: auto;
          margin-left: 0; }
    md-card .md-actions:not(.layout-column) .md-button.md-icon-button, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button {
      margin-left: 6px;
      margin-right: 6px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
        margin-left: 12px; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
          margin-left: auto;
          margin-right: 12px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
        margin-right: 12px; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
          margin-right: auto;
          margin-left: 12px; }
    md-card .md-actions:not(.layout-column) .md-button + md-card-icon-actions, md-card md-card-actions:not(.layout-column) .md-button + md-card-icon-actions {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      flex-direction: row; }
  md-card md-card-footer {
    margin-top: auto;
    padding: 16px; }

@media screen and (-ms-high-contrast: active) {
  md-card {
    border: 1px solid #fff; } }

.md-image-no-fill > img {
  width: auto;
  height: auto; }

.md-inline-form md-checkbox {
  margin: 19px 0 18px; }

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-left: 0;
  margin-right: 16px; }
  [dir=rtl] md-checkbox {
    margin-left: 16px; }
  [dir=rtl] md-checkbox {
    margin-right: 0; }
  md-checkbox:last-of-type {
    margin-left: 0;
    margin-right: 0; }
  md-checkbox.md-focused:not([disabled]) .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
    background-color: rgba(0, 0, 0, 0.12); }
  md-checkbox.md-align-top-left > div.md-container {
    top: 12px; }
  md-checkbox .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto; }
    [dir=rtl] md-checkbox .md-container {
      left: auto; }
    [dir=rtl] md-checkbox .md-container {
      right: 0; }
    md-checkbox .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-checkbox .md-container:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
    md-checkbox .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
  md-checkbox .md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px; }
  md-checkbox.md-checked .md-icon {
    border-color: transparent; }
    md-checkbox.md-checked .md-icon:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      position: absolute;
      left: 4.66667px;
      top: 0.22222px;
      display: table;
      width: 6.66667px;
      height: 13.33333px;
      border-width: 2px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      content: ''; }
  md-checkbox[disabled] {
    cursor: default; }
  md-checkbox.md-indeterminate .md-icon:after {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: 12px;
    height: 2px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: ''; }
  md-checkbox .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    user-select: text;
    margin-left: 30px;
    margin-right: 0; }
    [dir=rtl] md-checkbox .md-label {
      margin-left: 0; }
    [dir=rtl] md-checkbox .md-label {
      margin-right: 30px; }

.md-contact-chips .md-chips md-chip {
  padding: 0 25px 0 0; }
  [dir=rtl] .md-contact-chips .md-chips md-chip {
    padding: 0 0 0 25px; }
  .md-contact-chips .md-chips md-chip .md-contact-avatar {
    float: left; }
    [dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-avatar {
      float: right; }
    .md-contact-chips .md-chips md-chip .md-contact-avatar img {
      height: 32px;
      border-radius: 16px; }
  .md-contact-chips .md-chips md-chip .md-contact-name {
    display: inline-block;
    height: 32px;
    margin-left: 8px; }
    [dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-name {
      margin-left: auto;
      margin-right: 8px; }

.md-contact-suggestion {
  height: 56px; }
  .md-contact-suggestion img {
    height: 40px;
    border-radius: 20px;
    margin-top: 8px; }
  .md-contact-suggestion .md-contact-name {
    margin-left: 8px;
    width: 120px; }
    [dir=rtl] .md-contact-suggestion .md-contact-name {
      margin-left: auto;
      margin-right: 8px; }
  .md-contact-suggestion .md-contact-name, .md-contact-suggestion .md-contact-email {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; }

.md-contact-chips-suggestions li {
  height: 100%; }

.md-chips {
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 0 0 8px 3px;
  vertical-align: middle; }
  .md-chips:after {
    content: '';
    display: table;
    clear: both; }
  [dir=rtl] .md-chips {
    padding: 0 3px 8px 0; }
  .md-chips.md-readonly .md-chip-input-container {
    min-height: 32px; }
  .md-chips:not(.md-readonly) {
    cursor: text; }
  .md-chips.md-removable md-chip {
    padding-right: 22px; }
    [dir=rtl] .md-chips.md-removable md-chip {
      padding-right: 0;
      padding-left: 22px; }
    .md-chips.md-removable md-chip .md-chip-content {
      padding-right: 4px; }
      [dir=rtl] .md-chips.md-removable md-chip .md-chip-content {
        padding-right: 0;
        padding-left: 4px; }
  .md-chips md-chip {
    cursor: default;
    border-radius: 16px;
    display: block;
    height: 32px;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0 12px 0 12px;
    float: left;
    box-sizing: border-box;
    max-width: 100%;
    position: relative; }
    [dir=rtl] .md-chips md-chip {
      margin: 8px 0 0 8px; }
    [dir=rtl] .md-chips md-chip {
      float: right; }
    .md-chips md-chip .md-chip-content {
      display: block;
      float: left;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
      [dir=rtl] .md-chips md-chip .md-chip-content {
        float: right; }
      .md-chips md-chip .md-chip-content:focus {
        outline: none; }
    .md-chips md-chip._md-chip-content-edit-is-enabled {
      -webkit-user-select: none;
      /* webkit (safari, chrome) browsers */
      -moz-user-select: none;
      /* mozilla browsers */
      -khtml-user-select: none;
      /* webkit (konqueror) browsers */
      -ms-user-select: none;
      /* IE10+ */ }
    .md-chips md-chip .md-chip-remove-container {
      position: absolute;
      right: 0;
      line-height: 22px; }
      [dir=rtl] .md-chips md-chip .md-chip-remove-container {
        right: auto;
        left: 0; }
    .md-chips md-chip .md-chip-remove {
      text-align: center;
      width: 32px;
      height: 32px;
      min-width: 0;
      padding: 0;
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      position: relative; }
      .md-chips md-chip .md-chip-remove md-icon {
        height: 18px;
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0); }
  .md-chips .md-chip-input-container {
    display: block;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0;
    float: left; }
    [dir=rtl] .md-chips .md-chip-input-container {
      margin: 8px 0 0 8px; }
    [dir=rtl] .md-chips .md-chip-input-container {
      float: right; }
    .md-chips .md-chip-input-container input:not([type]), .md-chips .md-chip-input-container input[type="email"], .md-chips .md-chip-input-container input[type="number"], .md-chips .md-chip-input-container input[type="tel"], .md-chips .md-chip-input-container input[type="url"], .md-chips .md-chip-input-container input[type="text"] {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:not([type]):focus, .md-chips .md-chip-input-container input[type="email"]:focus, .md-chips .md-chip-input-container input[type="number"]:focus, .md-chips .md-chip-input-container input[type="tel"]:focus, .md-chips .md-chip-input-container input[type="url"]:focus, .md-chips .md-chip-input-container input[type="text"]:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      background: transparent;
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap {
      box-shadow: none; }
    .md-chips .md-chip-input-container md-autocomplete input {
      position: relative; }
    .md-chips .md-chip-input-container input {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete {
      box-shadow: none; }
      .md-chips .md-chip-input-container md-autocomplete input {
        position: relative; }
    .md-chips .md-chip-input-container:not(:first-child) {
      margin: 8px 8px 0 0; }
      [dir=rtl] .md-chips .md-chip-input-container:not(:first-child) {
        margin: 8px 0 0 8px; }
    .md-chips .md-chip-input-container input {
      background: transparent;
      border-width: 0; }
  .md-chips md-autocomplete button {
    display: none; }

@media screen and (-ms-high-contrast: active) {
  .md-chip-input-container,
  md-chip {
    border: 1px solid #fff; }
  .md-chip-input-container md-autocomplete {
    border: none; } }

md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-content[md-scroll-y] {
    overflow-y: auto;
    overflow-x: hidden; }
  md-content[md-scroll-x] {
    overflow-x: auto;
    overflow-y: hidden; }
  @media print {
    md-content {
      overflow: visible !important; } }

/** Styles for mdCalendar. */
md-calendar {
  font-size: 13px;
  user-select: none; }

.md-calendar-scroll-mask {
  display: inline-block;
  overflow: hidden;
  height: 308px; }
  .md-calendar-scroll-mask .md-virtual-repeat-scroller {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .md-calendar-scroll-mask .md-virtual-repeat-scroller::-webkit-scrollbar {
      display: none; }
  .md-calendar-scroll-mask .md-virtual-repeat-offsetter {
    width: 100%; }

.md-calendar-scroll-container {
  box-shadow: inset -3px 3px 6px rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 308px;
  width: 346px; }

.md-calendar-date {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none;
  box-sizing: content-box; }
  .md-calendar-date:first-child {
    padding-left: 16px; }
    [dir=rtl] .md-calendar-date:first-child {
      padding-left: 0;
      padding-right: 16px; }
  .md-calendar-date:last-child {
    padding-right: 16px; }
    [dir=rtl] .md-calendar-date:last-child {
      padding-right: 0;
      padding-left: 16px; }
  .md-calendar-date.md-calendar-date-disabled {
    cursor: default; }

.md-calendar-date-selection-indicator {
  transition: background-color, color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px; }
  .md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator {
    cursor: pointer; }

.md-calendar-month-label {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 24px; }
  [dir=rtl] .md-calendar-month-label {
    padding: 0 24px 0 0; }
  .md-calendar-month-label.md-calendar-label-clickable {
    cursor: pointer; }
  .md-calendar-month-label md-icon {
    transform: rotate(180deg); }
    [dir=rtl] .md-calendar-month-label md-icon {
      transform: none; }
  .md-calendar-month-label span {
    vertical-align: middle; }

.md-calendar-day-header {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar-day-header th {
    height: 40px;
    width: 44px;
    text-align: center;
    padding: 0;
    border: none;
    box-sizing: content-box;
    font-weight: normal; }
    .md-calendar-day-header th:first-child {
      padding-left: 16px; }
      [dir=rtl] .md-calendar-day-header th:first-child {
        padding-left: 0;
        padding-right: 16px; }
    .md-calendar-day-header th:last-child {
      padding-right: 16px; }
      [dir=rtl] .md-calendar-day-header th:last-child {
        padding-right: 0;
        padding-left: 16px; }

.md-calendar {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar tr:last-child td {
    border-bottom-width: 1px;
    border-bottom-style: solid; }
  .md-calendar:first-child {
    border-top: 1px solid transparent; }
  .md-calendar tbody, .md-calendar td, .md-calendar tr {
    vertical-align: middle;
    box-sizing: content-box; }

/** Styles for mdDatepicker. */
md-datepicker {
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle; }

.md-inline-form md-datepicker {
  margin-top: 12px; }

.md-datepicker-button {
  display: inline-block;
  box-sizing: border-box;
  background: none;
  vertical-align: middle;
  position: relative; }
  .md-datepicker-button:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    speak: none; }

.md-datepicker-input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  min-width: 120px;
  max-width: 328px;
  padding: 0 0 5px; }
  .md-datepicker-input::-ms-clear {
    display: none; }

._md-datepicker-floating-label > md-datepicker {
  overflow: visible; }
  ._md-datepicker-floating-label > md-datepicker .md-datepicker-input-container {
    border: none; }
  ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
    float: left;
    margin-top: -12px;
    top: 9.5px; }
    [dir=rtl] ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
      float: right; }

._md-datepicker-floating-label .md-input {
  float: none; }

._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  right: 18px;
  left: auto;
  width: calc(100% - 84px); }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
    right: auto; }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
    left: 18px; }

._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
  margin-left: 64px; }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
    margin-left: auto;
    margin-right: 64px; }

._md-datepicker-has-triangle-icon {
  padding-right: 18px;
  margin-right: -18px; }
  [dir=rtl] ._md-datepicker-has-triangle-icon {
    padding-right: 0;
    padding-left: 18px; }
  [dir=rtl] ._md-datepicker-has-triangle-icon {
    margin-right: auto;
    margin-left: -18px; }

.md-datepicker-input-container {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
  width: auto; }
  .md-icon-button + .md-datepicker-input-container {
    margin-left: 12px; }
    [dir=rtl] .md-icon-button + .md-datepicker-input-container {
      margin-left: auto;
      margin-right: 12px; }
  .md-datepicker-input-container.md-datepicker-focused {
    border-bottom-width: 2px; }

.md-datepicker-is-showing .md-scroll-mask {
  z-index: 99; }

.md-datepicker-calendar-pane {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 100;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  transform: scale(0);
  transform-origin: 0 0;
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-datepicker-calendar-pane.md-pane-open {
    transform: scale(1); }

.md-datepicker-input-mask {
  height: 40px;
  width: 340px;
  position: relative;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
  cursor: text; }

.md-datepicker-calendar {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.5, 0, 0.25, 1); }
  .md-pane-open .md-datepicker-calendar {
    opacity: 1; }
  .md-datepicker-calendar md-calendar:focus {
    outline: none; }

.md-datepicker-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid; }

.md-datepicker-triangle-button {
  position: absolute;
  right: 0;
  bottom: -2.5px;
  transform: translateX(45%); }
  [dir=rtl] .md-datepicker-triangle-button {
    right: auto;
    left: 0; }
  [dir=rtl] .md-datepicker-triangle-button {
    transform: translateX(-45%); }

.md-datepicker-triangle-button.md-button.md-icon-button {
  height: 36px;
  width: 36px;
  position: absolute;
  padding: 8px; }

md-datepicker[disabled] .md-datepicker-input-container {
  border-bottom-color: transparent; }

md-datepicker[disabled] .md-datepicker-triangle-button {
  display: none; }

.md-datepicker-open {
  overflow: hidden; }
  .md-datepicker-open .md-datepicker-input-container,
  .md-datepicker-open input.md-input {
    border-bottom-color: transparent; }
  .md-datepicker-open .md-datepicker-triangle-button,
  .md-datepicker-open.md-input-has-value > label,
  .md-datepicker-open.md-input-has-placeholder > label {
    display: none; }

.md-datepicker-pos-adjusted .md-datepicker-input-mask {
  display: none; }

.md-datepicker-calendar-pane .md-calendar {
  transform: translateY(-85px);
  transition: transform 0.65s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-delay: 0.125s; }

.md-datepicker-calendar-pane.md-pane-open .md-calendar {
  transform: translateY(0); }

.md-dialog-is-showing {
  max-height: 100%; }

.md-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  overflow: hidden; }

md-dialog {
  opacity: 0;
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column; }
  md-dialog.md-transition-in {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 0) scale(1); }
  md-dialog.md-transition-out {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 100%) scale(0.2); }
  md-dialog > form {
    display: flex;
    flex-direction: column;
    overflow: auto; }
  md-dialog .md-dialog-content {
    padding: 24px; }
  md-dialog md-dialog-content {
    order: 1;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
      margin-top: 0; }
    md-dialog md-dialog-content:focus {
      outline: none; }
    md-dialog md-dialog-content .md-subheader {
      margin: 0; }
    md-dialog md-dialog-content .md-dialog-content-body {
      width: 100%; }
    md-dialog md-dialog-content .md-prompt-input-container {
      width: 100%;
      box-sizing: border-box; }
  md-dialog .md-actions, md-dialog md-dialog-actions {
    display: flex;
    order: 2;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-right: 8px;
    padding-left: 16px;
    min-height: 52px;
    overflow: hidden; }
    [dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
      padding-right: 16px; }
    [dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
      padding-left: 8px; }
    md-dialog .md-actions .md-button, md-dialog md-dialog-actions .md-button {
      margin-bottom: 8px;
      margin-left: 8px;
      margin-right: 0;
      margin-top: 8px; }
      [dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
        margin-left: 0; }
      [dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
        margin-right: 8px; }
  md-dialog.md-content-overflow .md-actions, md-dialog.md-content-overflow md-dialog-actions {
    border-top-width: 1px;
    border-top-style: solid; }

@media screen and (-ms-high-contrast: active) {
  md-dialog {
    border: 1px solid #fff; } }

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0; } }

md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0; }
  md-divider[md-inset] {
    margin-left: 80px; }
    [dir=rtl] md-divider[md-inset] {
      margin-left: auto;
      margin-right: 80px; }

.layout-row > md-divider,
.layout-xs-row > md-divider, .layout-gt-xs-row > md-divider,
.layout-sm-row > md-divider, .layout-gt-sm-row > md-divider,
.layout-md-row > md-divider, .layout-gt-md-row > md-divider,
.layout-lg-row > md-divider, .layout-gt-lg-row > md-divider,
.layout-xl-row > md-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid; }

md-fab-speed-dial {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 20;
  /*
   * Hide some graphics glitches if switching animation types
   */
  /*
   * Handle the animations
   */ }
  md-fab-speed-dial.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial:not(.md-hover-full) {
    pointer-events: none; }
    md-fab-speed-dial:not(.md-hover-full) md-fab-trigger, md-fab-speed-dial:not(.md-hover-full) .md-fab-action-item {
      pointer-events: auto; }
    md-fab-speed-dial:not(.md-hover-full).md-is-open {
      pointer-events: auto; }
  md-fab-speed-dial ._md-css-variables {
    z-index: 20; }
  md-fab-speed-dial.md-is-open .md-fab-action-item {
    align-items: center; }
  md-fab-speed-dial md-fab-actions {
    display: flex;
    height: auto; }
    md-fab-speed-dial md-fab-actions .md-fab-action-item {
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-down {
    flex-direction: column; }
    md-fab-speed-dial.md-down md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-down md-fab-actions {
      flex-direction: column;
      order: 2; }
  md-fab-speed-dial.md-up {
    flex-direction: column; }
    md-fab-speed-dial.md-up md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-up md-fab-actions {
      flex-direction: column-reverse;
      order: 1; }
  md-fab-speed-dial.md-left {
    flex-direction: row; }
    md-fab-speed-dial.md-left md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-left md-fab-actions {
      flex-direction: row-reverse;
      order: 1; }
      md-fab-speed-dial.md-left md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-right {
    flex-direction: row; }
    md-fab-speed-dial.md-right md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-right md-fab-actions {
      flex-direction: row;
      order: 2; }
      md-fab-speed-dial.md-right md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-fling-remove .md-fab-action-item > *, md-fab-speed-dial.md-scale-remove .md-fab-action-item > * {
    visibility: hidden; }
  md-fab-speed-dial.md-fling .md-fab-action-item {
    opacity: 1; }
  md-fab-speed-dial.md-fling.md-animations-waiting .md-fab-action-item {
    opacity: 0;
    transition-duration: 0s; }
  md-fab-speed-dial.md-scale .md-fab-action-item {
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 0.14286s; }

md-fab-toolbar {
  display: block;
  /*
   * Closed styling
   */
  /*
   * Hover styling
   */ }
  md-fab-toolbar.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-toolbar.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-toolbar .md-fab-toolbar-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    height: 68px; }
  md-fab-toolbar md-fab-trigger {
    position: absolute;
    z-index: 20; }
    md-fab-toolbar md-fab-trigger button {
      overflow: visible !important; }
    md-fab-toolbar md-fab-trigger .md-fab-toolbar-background {
      display: block;
      position: absolute;
      z-index: 21;
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
    md-fab-toolbar md-fab-trigger md-icon {
      position: relative;
      z-index: 22;
      opacity: 1;
      transition: all 200ms ease-in; }
  md-fab-toolbar.md-left md-fab-trigger {
    right: 0; }
    [dir=rtl] md-fab-toolbar.md-left md-fab-trigger {
      right: auto;
      left: 0; }
  md-fab-toolbar.md-left .md-toolbar-tools {
    flex-direction: row-reverse; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-right: 0.6rem; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
        margin-right: auto;
        margin-left: 0.6rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-left: -0.8rem; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
        margin-left: auto;
        margin-right: -0.8rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
      margin-right: 8px; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
        margin-right: auto;
        margin-left: 8px; }
  md-fab-toolbar.md-right md-fab-trigger {
    left: 0; }
    [dir=rtl] md-fab-toolbar.md-right md-fab-trigger {
      left: auto;
      right: 0; }
  md-fab-toolbar.md-right .md-toolbar-tools {
    flex-direction: row; }
  md-fab-toolbar md-toolbar {
    background-color: transparent !important;
    pointer-events: none;
    z-index: 23; }
    md-fab-toolbar md-toolbar .md-toolbar-tools {
      padding: 0 20px;
      margin-top: 3px; }
    md-fab-toolbar md-toolbar .md-fab-action-item {
      opacity: 0;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 0.15s; }
  md-fab-toolbar.md-is-open md-fab-trigger > button {
    box-shadow: none; }
    md-fab-toolbar.md-is-open md-fab-trigger > button md-icon {
      opacity: 0; }
  md-fab-toolbar.md-is-open .md-fab-action-item {
    opacity: 1;
    transform: scale(1); }

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative; }
  md-grid-list md-grid-tile,
  md-grid-list md-grid-tile > figure,
  md-grid-list md-grid-tile-header,
  md-grid-list md-grid-tile-footer {
    box-sizing: border-box; }
  md-grid-list md-grid-tile {
    display: block;
    position: absolute; }
    md-grid-list md-grid-tile figure {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0; }
    md-grid-list md-grid-tile md-grid-tile-header,
    md-grid-list md-grid-tile md-grid-tile-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      color: #fff;
      background: rgba(0, 0, 0, 0.18);
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h3,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-weight: 400;
        margin: 0 0 0 16px; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-footer h3 {
        font-size: 14px; }
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-size: 12px; }
    md-grid-list md-grid-tile md-grid-tile-header {
      top: 0; }
    md-grid-list md-grid-tile md-grid-tile-footer {
      bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff; }
  md-grid-tile-footer {
    border-top: 1px solid #fff; } }

md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px; }
  md-icon svg {
    pointer-events: none;
    display: block; }
  md-icon[md-font-icon] {
    line-height: 24px;
    width: auto; }

md-input-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  margin: 18px 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */ }
  md-input-container:after {
    content: '';
    display: table;
    clear: both; }
  md-input-container.md-block {
    display: block; }
  md-input-container .md-errors-spacer {
    float: right;
    min-height: 24px;
    min-width: 1px; }
    [dir=rtl] md-input-container .md-errors-spacer {
      float: left; }
  md-input-container > md-icon {
    position: absolute;
    top: 8px;
    left: 2px;
    right: auto; }
    [dir=rtl] md-input-container > md-icon {
      left: auto; }
    [dir=rtl] md-input-container > md-icon {
      right: 2px; }
  md-input-container textarea,
  md-input-container input[type="text"],
  md-input-container input[type="password"],
  md-input-container input[type="datetime"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="date"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"],
  md-input-container input[type="number"],
  md-input-container input[type="email"],
  md-input-container input[type="url"],
  md-input-container input[type="search"],
  md-input-container input[type="tel"],
  md-input-container input[type="color"] {
    /* remove default appearance from all input/textarea */
    -moz-appearance: none;
    -webkit-appearance: none; }
  md-input-container input[type="date"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"] {
    min-height: 26px; }
  md-input-container textarea {
    resize: none;
    overflow: hidden; }
    md-input-container textarea.md-input {
      min-height: 26px;
      -ms-flex-preferred-size: auto; }
    md-input-container textarea[md-no-autogrow] {
      height: auto;
      overflow: auto; }
  md-input-container label:not(.md-container-ignore) {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: auto; }
    [dir=rtl] md-input-container label:not(.md-container-ignore) {
      left: auto; }
    [dir=rtl] md-input-container label:not(.md-container-ignore) {
      right: 0; }
    md-input-container label:not(.md-container-ignore).md-required:after {
      content: ' *';
      font-size: 13px;
      vertical-align: top; }
  md-input-container label:not(.md-no-float):not(.md-container-ignore),
  md-input-container .md-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 3px;
    padding-right: 0;
    z-index: 1;
    transform: translate3d(0, 28px, 0) scale(1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    max-width: 100%;
    transform-origin: left top; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      padding-left: 0; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      padding-right: 3px; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      transform-origin: right top; }
  md-input-container .md-placeholder {
    position: absolute;
    top: 0;
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d(0, 30px, 0); }
  md-input-container.md-input-focused .md-placeholder {
    opacity: 1;
    transform: translate3d(0, 24px, 0); }
  md-input-container.md-input-has-value .md-placeholder {
    transition: none;
    opacity: 0; }
  md-input-container:not(.md-input-has-value):not(.md-input-has-placeholder) input:not(:focus) {
    color: transparent; }
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
    color: transparent; }
  md-input-container .md-input {
    order: 2;
    display: block;
    margin-top: 0;
    background: none;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    border-width: 0 0 1px 0;
    line-height: 26px;
    height: 30px;
    -ms-flex-preferred-size: 26px;
    border-radius: 0;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    float: left; }
    [dir=rtl] md-input-container .md-input {
      float: right; }
    md-input-container .md-input:focus {
      outline: none; }
    md-input-container .md-input:invalid {
      outline: none;
      box-shadow: none; }
    md-input-container .md-input.md-no-flex {
      flex: none !important; }
  md-input-container .md-char-counter {
    text-align: right;
    padding-right: 2px;
    padding-left: 0; }
    [dir=rtl] md-input-container .md-char-counter {
      text-align: left; }
    [dir=rtl] md-input-container .md-char-counter {
      padding-right: 0; }
    [dir=rtl] md-input-container .md-char-counter {
      padding-left: 2px; }
  md-input-container .md-input-messages-animation {
    position: relative;
    order: 4;
    overflow: hidden;
    clear: left; }
    [dir=rtl] md-input-container .md-input-messages-animation {
      clear: right; }
  md-input-container .md-input-message-animation, md-input-container .md-char-counter {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    opacity: 1;
    margin-top: 0;
    padding-top: 5px; }
    md-input-container .md-input-message-animation:not(.md-char-counter), md-input-container .md-char-counter:not(.md-char-counter) {
      padding-right: 5px;
      padding-left: 0; }
      [dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
        padding-right: 0; }
      [dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
        padding-left: 5px; }
  md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-input-message-animation.ng-enter-prepare {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-input-message-animation.ng-enter:not(.ng-enter-active) {
    opacity: 0;
    margin-top: -100px; }
  md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
    transform: translate3d(0, 6px, 0) scale(0.75);
    transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s; }
  md-input-container.md-input-has-value label {
    transition: none; }
  md-input-container.md-input-focused .md-input,
  md-input-container .md-input.ng-invalid.ng-dirty,
  md-input-container.md-input-resized .md-input {
    padding-bottom: 0;
    border-width: 0 0 2px 0; }
  md-input-container .md-input[disabled],
  [disabled] md-input-container .md-input {
    background-position: bottom -1px left 0;
    background-size: 4px 1px;
    background-repeat: repeat-x; }
  md-input-container.md-icon-float {
    transition: margin-top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-input-container.md-icon-float > label {
      pointer-events: none;
      position: absolute; }
    md-input-container.md-icon-float > md-icon {
      top: 8px;
      left: 2px;
      right: auto; }
      [dir=rtl] md-input-container.md-icon-float > md-icon {
        left: auto; }
      [dir=rtl] md-input-container.md-icon-float > md-icon {
        right: 2px; }
  md-input-container.md-icon-left > label:not(.md-no-float):not(.md-container-ignore),
  md-input-container.md-icon-left > label .md-placeholder, md-input-container.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
  md-input-container.md-icon-right > label .md-placeholder {
    width: calc(100% - 36px - 18px); }
  md-input-container.md-icon-left {
    padding-left: 36px;
    padding-right: 0; }
    [dir=rtl] md-input-container.md-icon-left {
      padding-left: 0; }
    [dir=rtl] md-input-container.md-icon-left {
      padding-right: 36px; }
    md-input-container.md-icon-left > label {
      left: 36px;
      right: auto; }
      [dir=rtl] md-input-container.md-icon-left > label {
        left: auto; }
      [dir=rtl] md-input-container.md-icon-left > label {
        right: 36px; }
  md-input-container.md-icon-right {
    padding-left: 0;
    padding-right: 36px; }
    [dir=rtl] md-input-container.md-icon-right {
      padding-left: 36px; }
    [dir=rtl] md-input-container.md-icon-right {
      padding-right: 0; }
    md-input-container.md-icon-right > md-icon:last-of-type {
      margin: 0;
      right: 2px;
      left: auto; }
      [dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        right: auto; }
      [dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        left: 2px; }
  md-input-container.md-icon-left.md-icon-right {
    padding-left: 36px;
    padding-right: 36px; }
    md-input-container.md-icon-left.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
    md-input-container.md-icon-left.md-icon-right > label .md-placeholder {
      width: calc(100% - (36px * 2)); }

.md-resize-wrapper {
  position: relative; }
  .md-resize-wrapper:after {
    content: '';
    display: table;
    clear: both; }

.md-resize-handle {
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 10px;
  background: transparent;
  width: 100%;
  cursor: ns-resize; }

@media screen and (-ms-high-contrast: active) {
  md-input-container.md-default-theme > md-icon {
    fill: #fff; } }

md-list {
  display: block;
  padding: 8px 0px 8px 0px; }
  md-list .md-subheader {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.010em;
    line-height: 1.2em; }
  md-list.md-dense md-list-item,
  md-list.md-dense md-list-item .md-list-item-inner {
    min-height: 48px; }
    md-list.md-dense md-list-item::before,
    md-list.md-dense md-list-item .md-list-item-inner::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item md-icon:first-child,
    md-list.md-dense md-list-item .md-list-item-inner md-icon:first-child {
      width: 20px;
      height: 20px; }
    md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
      margin-right: 36px; }
      [dir=rtl] md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon), [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
        margin-right: auto;
        margin-left: 36px; }
    md-list.md-dense md-list-item .md-avatar, md-list.md-dense md-list-item .md-avatar-icon,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
      margin-right: 20px; }
      [dir=rtl] md-list.md-dense md-list-item .md-avatar, [dir=rtl] md-list.md-dense md-list-item .md-avatar-icon, [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner .md-avatar, [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
        margin-right: auto;
        margin-left: 20px; }
    md-list.md-dense md-list-item .md-avatar,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar {
      flex: none;
      width: 36px;
      height: 36px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
    margin-left: 56px; }
    [dir=rtl] md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
      margin-left: auto;
      margin-right: 56px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h3,
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h4,
  md-list.md-dense md-list-item.md-2-line .md-list-item-text p, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
  md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
  md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3,
  md-list.md-dense md-list-item.md-3-line .md-list-item-text h4,
  md-list.md-dense md-list-item.md-3-line .md-list-item-text p, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3,
  md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h4,
  md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text p {
    line-height: 1.05;
    font-size: 12px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h3, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
    font-size: 13px; }
  md-list.md-dense md-list-item.md-2-line, md-list.md-dense md-list-item.md-2-line > .md-no-style {
    min-height: 60px; }
    md-list.md-dense md-list-item.md-2-line::before, md-list.md-dense md-list-item.md-2-line > .md-no-style::before {
      content: '';
      min-height: 60px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item.md-2-line > .md-avatar, md-list.md-dense md-list-item.md-2-line .md-avatar-icon, md-list.md-dense md-list-item.md-2-line > .md-no-style > .md-avatar, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-avatar-icon {
      margin-top: 12px; }
  md-list.md-dense md-list-item.md-3-line, md-list.md-dense md-list-item.md-3-line > .md-no-style {
    min-height: 76px; }
    md-list.md-dense md-list-item.md-3-line::before, md-list.md-dense md-list-item.md-3-line > .md-no-style::before {
      content: '';
      min-height: 76px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item.md-3-line > md-icon:first-child,
    md-list.md-dense md-list-item.md-3-line > .md-avatar, md-list.md-dense md-list-item.md-3-line > .md-no-style > md-icon:first-child,
    md-list.md-dense md-list-item.md-3-line > .md-no-style > .md-avatar {
      margin-top: 16px; }

md-list-item {
  position: relative; }
  md-list-item.md-proxy-focus.md-focused .md-no-style {
    transition: background-color 0.15s linear; }
  md-list-item._md-button-wrap {
    position: relative; }
    md-list-item._md-button-wrap > div.md-button:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 16px;
      margin: 0;
      font-weight: 400;
      text-align: left;
      border: medium none; }
      [dir=rtl] md-list-item._md-button-wrap > div.md-button:first-child {
        text-align: right; }
      md-list-item._md-button-wrap > div.md-button:first-child > .md-button:first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        margin: 0;
        padding: 0; }
      md-list-item._md-button-wrap > div.md-button:first-child .md-list-item-inner {
        width: 100%; }
        md-list-item._md-button-wrap > div.md-button:first-child .md-list-item-inner::before {
          content: '';
          min-height: inherit;
          visibility: hidden;
          display: inline-block; }
  md-list-item.md-no-proxy,
  md-list-item .md-no-style {
    position: relative;
    padding: 0px 16px;
    flex: 1 1 auto; }
    md-list-item.md-no-proxy.md-button,
    md-list-item .md-no-style.md-button {
      font-size: inherit;
      height: inherit;
      text-align: left;
      text-transform: none;
      width: 100%;
      white-space: normal;
      flex-direction: inherit;
      align-items: inherit;
      border-radius: 0;
      margin: 0; }
      [dir=rtl] md-list-item.md-no-proxy.md-button, [dir=rtl]
      md-list-item .md-no-style.md-button {
        text-align: right; }
      md-list-item.md-no-proxy.md-button > .md-ripple-container,
      md-list-item .md-no-style.md-button > .md-ripple-container {
        border-radius: 0; }
    md-list-item.md-no-proxy:focus,
    md-list-item .md-no-style:focus {
      outline: none; }
  md-list-item.md-clickable:hover {
    cursor: pointer; }
  md-list-item md-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    [dir=rtl] md-list-item md-divider {
      left: auto;
      right: 0; }
    md-list-item md-divider[md-inset] {
      left: 72px;
      width: calc(100% - 72px);
      margin: 0 !important; }
      [dir=rtl] md-list-item md-divider[md-inset] {
        left: auto;
        right: 72px; }
  md-list-item,
  md-list-item .md-list-item-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 48px;
    height: auto; }
    md-list-item::before,
    md-list-item .md-list-item-inner::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    md-list-item > div.md-primary > md-icon:not(.md-avatar-icon),
    md-list-item > div.md-secondary > md-icon:not(.md-avatar-icon),
    md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list-item > md-icon.md-secondary:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > div.md-primary > md-icon:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > div.md-secondary > md-icon:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon.md-secondary:not(.md-avatar-icon) {
      width: 24px;
      margin-top: 16px;
      margin-bottom: 12px;
      box-sizing: content-box; }
    md-list-item > div.md-primary > md-checkbox,
    md-list-item > div.md-secondary > md-checkbox,
    md-list-item > md-checkbox,
    md-list-item md-checkbox.md-secondary,
    md-list-item .md-list-item-inner > div.md-primary > md-checkbox,
    md-list-item .md-list-item-inner > div.md-secondary > md-checkbox,
    md-list-item .md-list-item-inner > md-checkbox,
    md-list-item .md-list-item-inner md-checkbox.md-secondary {
      align-self: center; }
      md-list-item > div.md-primary > md-checkbox .md-label,
      md-list-item > div.md-secondary > md-checkbox .md-label,
      md-list-item > md-checkbox .md-label,
      md-list-item md-checkbox.md-secondary .md-label,
      md-list-item .md-list-item-inner > div.md-primary > md-checkbox .md-label,
      md-list-item .md-list-item-inner > div.md-secondary > md-checkbox .md-label,
      md-list-item .md-list-item-inner > md-checkbox .md-label,
      md-list-item .md-list-item-inner md-checkbox.md-secondary .md-label {
        display: none; }
    md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
      margin-right: 32px; }
      [dir=rtl] md-list-item > md-icon:first-child:not(.md-avatar-icon), [dir=rtl]
      md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
        margin-right: auto;
        margin-left: 32px; }
    md-list-item .md-avatar, md-list-item .md-avatar-icon,
    md-list-item .md-list-item-inner .md-avatar,
    md-list-item .md-list-item-inner .md-avatar-icon {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 16px;
      border-radius: 50%;
      box-sizing: content-box; }
      [dir=rtl] md-list-item .md-avatar, [dir=rtl] md-list-item .md-avatar-icon, [dir=rtl]
      md-list-item .md-list-item-inner .md-avatar, [dir=rtl]
      md-list-item .md-list-item-inner .md-avatar-icon {
        margin-right: auto;
        margin-left: 16px; }
    md-list-item .md-avatar,
    md-list-item .md-list-item-inner .md-avatar {
      flex: none;
      width: 40px;
      height: 40px; }
    md-list-item .md-avatar-icon,
    md-list-item .md-list-item-inner .md-avatar-icon {
      padding: 8px; }
      md-list-item .md-avatar-icon svg,
      md-list-item .md-list-item-inner .md-avatar-icon svg {
        width: 24px;
        height: 24px; }
    md-list-item > md-checkbox,
    md-list-item .md-list-item-inner > md-checkbox {
      width: 24px;
      margin-left: 3px;
      margin-right: 29px;
      margin-top: 16px; }
      [dir=rtl] md-list-item > md-checkbox, [dir=rtl]
      md-list-item .md-list-item-inner > md-checkbox {
        margin-left: 29px; }
      [dir=rtl] md-list-item > md-checkbox, [dir=rtl]
      md-list-item .md-list-item-inner > md-checkbox {
        margin-right: 3px; }
    md-list-item .md-secondary-container,
    md-list-item .md-list-item-inner .md-secondary-container {
      display: flex;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      margin: auto;
      margin-right: 0;
      margin-left: auto; }
      [dir=rtl] md-list-item .md-secondary-container, [dir=rtl]
      md-list-item .md-list-item-inner .md-secondary-container {
        margin-right: auto; }
      [dir=rtl] md-list-item .md-secondary-container, [dir=rtl]
      md-list-item .md-list-item-inner .md-secondary-container {
        margin-left: 0; }
      md-list-item .md-secondary-container .md-button:last-of-type, md-list-item .md-secondary-container .md-icon-button:last-of-type,
      md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type,
      md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
        margin-right: 0; }
        [dir=rtl] md-list-item .md-secondary-container .md-button:last-of-type, [dir=rtl] md-list-item .md-secondary-container .md-icon-button:last-of-type, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
          margin-right: auto;
          margin-left: 0; }
      md-list-item .md-secondary-container md-checkbox,
      md-list-item .md-list-item-inner .md-secondary-container md-checkbox {
        margin-top: 0;
        margin-bottom: 0; }
        md-list-item .md-secondary-container md-checkbox:last-child,
        md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
          width: 24px;
          margin-right: 0; }
          [dir=rtl] md-list-item .md-secondary-container md-checkbox:last-child, [dir=rtl]
          md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
            margin-right: auto;
            margin-left: 0; }
      md-list-item .md-secondary-container md-switch,
      md-list-item .md-list-item-inner .md-secondary-container md-switch {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: -6px; }
        [dir=rtl] md-list-item .md-secondary-container md-switch, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container md-switch {
          margin-right: auto;
          margin-left: -6px; }
    md-list-item > p, md-list-item > .md-list-item-inner > p,
    md-list-item .md-list-item-inner > p,
    md-list-item .md-list-item-inner > .md-list-item-inner > p {
      flex: 1 1 auto;
      margin: 0; }
  md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style, md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
    align-items: flex-start;
    justify-content: center; }
    md-list-item.md-2-line.md-long-text, md-list-item.md-2-line > .md-no-style.md-long-text, md-list-item.md-3-line.md-long-text, md-list-item.md-3-line > .md-no-style.md-long-text {
      margin-top: 8px;
      margin-bottom: 8px; }
    md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text, md-list-item.md-3-line .md-list-item-text, md-list-item.md-3-line > .md-no-style .md-list-item-text {
      flex: 1 1 auto;
      margin: auto;
      text-overflow: ellipsis;
      overflow: hidden; }
      md-list-item.md-2-line .md-list-item-text.md-offset, md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list-item.md-3-line .md-list-item-text.md-offset, md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
        margin-left: 56px; }
        [dir=rtl] md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
          margin-left: auto;
          margin-right: 56px; }
      md-list-item.md-2-line .md-list-item-text h3, md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list-item.md-3-line .md-list-item-text h3, md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.010em;
        margin: 0 0 0px 0;
        line-height: 1.2em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      md-list-item.md-2-line .md-list-item-text h4, md-list-item.md-2-line > .md-no-style .md-list-item-text h4, md-list-item.md-3-line .md-list-item-text h4, md-list-item.md-3-line > .md-no-style .md-list-item-text h4 {
        font-size: 14px;
        letter-spacing: 0.010em;
        margin: 3px 0 1px 0;
        font-weight: 400;
        line-height: 1.2em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      md-list-item.md-2-line .md-list-item-text p, md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list-item.md-3-line .md-list-item-text p, md-list-item.md-3-line > .md-no-style .md-list-item-text p {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.010em;
        margin: 0 0 0 0;
        line-height: 1.6em; }
  md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style {
    height: auto;
    min-height: 72px; }
    md-list-item.md-2-line::before, md-list-item.md-2-line > .md-no-style::before {
      content: '';
      min-height: 72px;
      visibility: hidden;
      display: inline-block; }
    md-list-item.md-2-line > .md-avatar, md-list-item.md-2-line .md-avatar-icon, md-list-item.md-2-line > .md-no-style > .md-avatar, md-list-item.md-2-line > .md-no-style .md-avatar-icon {
      margin-top: 12px; }
    md-list-item.md-2-line > md-icon:first-child, md-list-item.md-2-line > .md-no-style > md-icon:first-child {
      align-self: flex-start; }
    md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text {
      flex: 1 1 auto; }
  md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
    height: auto;
    min-height: 88px; }
    md-list-item.md-3-line::before, md-list-item.md-3-line > .md-no-style::before {
      content: '';
      min-height: 88px;
      visibility: hidden;
      display: inline-block; }
    md-list-item.md-3-line > md-icon:first-child,
    md-list-item.md-3-line > .md-avatar, md-list-item.md-3-line > .md-no-style > md-icon:first-child,
    md-list-item.md-3-line > .md-no-style > .md-avatar {
      margin-top: 16px; }

.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 2px;
  max-height: calc(100vh - 10px);
  overflow: auto; }
  .md-open-menu-container md-menu-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    width: 100%; }
  .md-open-menu-container md-menu-content > * {
    opacity: 0; }
  .md-open-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-open-menu-container.md-active {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 200ms; }
    .md-open-menu-container.md-active > md-menu-content > * {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 200ms;
      transition-delay: 100ms; }
  .md-open-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-menu-content {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto; }
  md-menu-content.md-dense {
    max-height: 208px; }
    md-menu-content.md-dense md-menu-item {
      height: 32px;
      min-height: 32px; }

md-menu-item {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  height: 48px;
  align-content: center;
  justify-content: flex-start;
  /*
   * We cannot use flex on <button> elements due to a bug in Firefox, so we also can't use it on
   * <a> elements. Add some top padding to fix alignment since buttons automatically align their
   * text vertically.
   */ }
  md-menu-item > * {
    width: 100%;
    margin: auto 0;
    padding-left: 16px;
    padding-right: 16px; }
  md-menu-item > a.md-button {
    padding-top: 5px; }
  md-menu-item > .md-button {
    text-align: left;
    display: inline-block;
    border-radius: 0;
    margin: auto 0;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%; }
    md-menu-item > .md-button::-moz-focus-inner {
      padding: 0;
      border: 0; }
    [dir=rtl] md-menu-item > .md-button {
      text-align: right; }
    md-menu-item > .md-button md-icon {
      margin: auto 16px auto 0; }
      [dir=rtl] md-menu-item > .md-button md-icon {
        margin: auto 0 auto 16px; }
    md-menu-item > .md-button p {
      display: inline-block;
      margin: auto; }
    md-menu-item > .md-button span {
      margin-top: auto;
      margin-bottom: auto; }
    md-menu-item > .md-button .md-ripple-container {
      border-radius: inherit; }

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0; }

@media (max-width: 959px) {
  md-menu-content {
    min-width: 112px; }
  md-menu-content[width="3"] {
    min-width: 168px; }
  md-menu-content[width="4"] {
    min-width: 224px; }
  md-menu-content[width="5"] {
    min-width: 280px; }
  md-menu-content[width="6"] {
    min-width: 336px; }
  md-menu-content[width="7"] {
    min-width: 392px; } }

@media (min-width: 960px) {
  md-menu-content {
    min-width: 96px; }
  md-menu-content[width="3"] {
    min-width: 192px; }
  md-menu-content[width="4"] {
    min-width: 256px; }
  md-menu-content[width="5"] {
    min-width: 320px; }
  md-menu-content[width="6"] {
    min-width: 384px; }
  md-menu-content[width="7"] {
    min-width: 448px; } }

md-toolbar.md-menu-toolbar h2.md-toolbar-tools {
  line-height: 1rem;
  height: auto;
  padding: 28px;
  padding-bottom: 12px; }

md-toolbar.md-has-open-menu {
  position: relative;
  z-index: 100; }

md-menu-bar {
  padding: 0 20px;
  display: block;
  position: relative;
  z-index: 2; }
  md-menu-bar .md-menu {
    display: inline-block;
    padding: 0;
    position: relative; }
  md-menu-bar button {
    font-size: 14px;
    padding: 0 10px;
    margin: 0;
    border: 0;
    background-color: transparent;
    height: 40px; }
  md-menu-bar md-backdrop.md-menu-backdrop {
    z-index: -2; }

md-menu-content.md-menu-bar-menu.md-dense {
  max-height: none;
  padding: 16px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent {
    position: relative; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
      position: absolute;
      padding: 0;
      width: 24px;
      top: 6px;
      left: 24px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
        left: auto;
        right: 24px; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
      padding: 0 32px 0 64px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
        padding: 0 64px 0 32px; }
  md-menu-content.md-menu-bar-menu.md-dense .md-button {
    min-height: 0;
    height: 32px; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span {
      float: left; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-button span {
        float: right; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
      float: right;
      margin: 0 8px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
        float: left; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-divider {
    margin: 8px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
    text-align: left; }
    [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      text-align: right; }
  md-menu-content.md-menu-bar-menu.md-dense .md-menu {
    padding: 0; }
    md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      position: relative;
      margin: 0;
      width: 100%;
      text-transform: none;
      font-weight: normal;
      border-radius: 0px;
      padding-left: 16px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
        padding-left: 0;
        padding-right: 16px; }
      md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
        display: block;
        content: '\25BC';
        position: absolute;
        top: 0px;
        speak: none;
        transform: rotate(270deg) scaleY(0.45) scaleX(0.9);
        right: 28px; }
        [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
          transform: rotate(90deg) scaleY(0.45) scaleX(0.9); }
        [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
          right: auto;
          left: 28px; }

/** Matches "md-tabs md-tabs-wrapper" style. */
.md-nav-bar {
  border-style: solid;
  border-width: 0 0 1px;
  height: 48px;
  position: relative; }

._md-nav-bar-list {
  outline: none;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.md-nav-item:first-of-type {
  margin-left: 8px; }

.md-button._md-nav-button {
  line-height: 24px;
  margin: 0 4px;
  padding: 12px 16px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
  .md-button._md-nav-button:focus {
    outline: none; }

md-nav-ink-bar {
  bottom: 0;
  height: 2px;
  left: auto;
  position: absolute;
  right: auto;
  background-color: black; }
  md-nav-ink-bar._md-left {
    transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }
  md-nav-ink-bar._md-right {
    transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }
  md-nav-ink-bar.ng-animate {
    transition: none; }

md-nav-extra-content {
  min-height: 48px;
  padding-right: 12px; }

@keyframes indeterminate-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

md-progress-circular {
  position: relative;
  display: block; }
  md-progress-circular._md-progress-circular-disabled {
    visibility: hidden; }
  md-progress-circular.md-mode-indeterminate svg {
    animation: indeterminate-rotate 1568.63ms linear infinite; }
  md-progress-circular svg {
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0; }

md-progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding-top: 0 !important;
  margin-bottom: 0 !important; }
  md-progress-linear._md-progress-linear-disabled {
    visibility: hidden; }
  md-progress-linear .md-container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    transform: translate(0, 0) scale(1, 1); }
    md-progress-linear .md-container .md-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 5px; }
    md-progress-linear .md-container .md-dashed:before {
      content: "";
      display: none;
      position: absolute;
      margin-top: 0;
      height: 5px;
      width: 100%;
      background-color: transparent;
      background-size: 10px 10px !important;
      background-position: 0px -23px; }
    md-progress-linear .md-container .md-bar1, md-progress-linear .md-container .md-bar2 {
      transition: transform 0.2s linear; }
    md-progress-linear .md-container.md-mode-query .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-query .md-bar2 {
      transition: all 0.2s linear;
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1); }
    md-progress-linear .md-container.md-mode-determinate .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar1 {
      animation: md-progress-linear-indeterminate-scale-1 4s infinite, md-progress-linear-indeterminate-1 4s infinite; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar2 {
      animation: md-progress-linear-indeterminate-scale-2 4s infinite, md-progress-linear-indeterminate-2 4s infinite; }
    md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container {
      animation: none; }
      md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar1 {
        animation-name: none; }
      md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar2 {
        animation-name: none; }
  md-progress-linear .md-container.md-mode-buffer {
    background-color: transparent !important;
    transition: all 0.2s linear; }
    md-progress-linear .md-container.md-mode-buffer .md-dashed:before {
      display: block;
      animation: buffer 3s infinite linear; }

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1); } }

@keyframes buffer {
  0% {
    opacity: 1;
    background-position: 0px -23px; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    background-position: -200px -23px; } }

@keyframes md-progress-linear-indeterminate-scale-1 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: linear; }
  36.6% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1); }
  69.15% {
    transform: scaleX(0.83);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-1 {
  0% {
    left: -105.16667%;
    animation-timing-function: linear; }
  20% {
    left: -105.16667%;
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582); }
  69.15% {
    left: 21.5%;
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635); }
  100% {
    left: 95.44444%; } }

@keyframes md-progress-linear-indeterminate-scale-2 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397); }
  19.15% {
    transform: scaleX(0.57);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432); }
  44.15% {
    transform: scaleX(0.91);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-2 {
  0% {
    left: -54.88889%;
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968); }
  25% {
    left: -17.25%;
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372); }
  48.35% {
    left: 29.5%;
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203); }
  100% {
    left: 117.38889%; } }

md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative; }
  md-radio-button[disabled] {
    cursor: default; }
    md-radio-button[disabled] .md-container {
      cursor: default; }
  md-radio-button .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    left: 0;
    right: auto; }
    [dir=rtl] md-radio-button .md-container {
      left: auto; }
    [dir=rtl] md-radio-button .md-container {
      right: 0; }
    md-radio-button .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
    md-radio-button .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
  md-radio-button.md-align-top-left > div.md-container {
    top: 12px; }
  md-radio-button .md-off {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    transition: border-color ease 0.28s; }
  md-radio-button .md-on {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: transform ease 0.28s;
    transform: scale(0); }
  md-radio-button.md-checked .md-on {
    transform: scale(0.5); }
  md-radio-button .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin-left: 30px;
    margin-right: 0;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    width: auto; }
    [dir=rtl] md-radio-button .md-label {
      margin-left: 0; }
    [dir=rtl] md-radio-button .md-label {
      margin-right: 30px; }

md-radio-group {
  /** Layout adjustments for the radio group. */ }
  md-radio-group.layout-column md-radio-button, md-radio-group.layout-xs-column md-radio-button, md-radio-group.layout-gt-xs-column md-radio-button, md-radio-group.layout-sm-column md-radio-button, md-radio-group.layout-gt-sm-column md-radio-button, md-radio-group.layout-md-column md-radio-button, md-radio-group.layout-gt-md-column md-radio-button, md-radio-group.layout-lg-column md-radio-button, md-radio-group.layout-gt-lg-column md-radio-button, md-radio-group.layout-xl-column md-radio-button {
    margin-bottom: 16px; }
  md-radio-group.layout-row md-radio-button, md-radio-group.layout-xs-row md-radio-button, md-radio-group.layout-gt-xs-row md-radio-button, md-radio-group.layout-sm-row md-radio-button, md-radio-group.layout-gt-sm-row md-radio-button, md-radio-group.layout-md-row md-radio-button, md-radio-group.layout-gt-md-row md-radio-button, md-radio-group.layout-lg-row md-radio-button, md-radio-group.layout-gt-lg-row md-radio-button, md-radio-group.layout-xl-row md-radio-button {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 16px; }
    [dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
      margin-left: 16px; }
    [dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
      margin-right: 0; }
    md-radio-group.layout-row md-radio-button:last-of-type, md-radio-group.layout-xs-row md-radio-button:last-of-type, md-radio-group.layout-gt-xs-row md-radio-button:last-of-type, md-radio-group.layout-sm-row md-radio-button:last-of-type, md-radio-group.layout-gt-sm-row md-radio-button:last-of-type, md-radio-group.layout-md-row md-radio-button:last-of-type, md-radio-group.layout-gt-md-row md-radio-button:last-of-type, md-radio-group.layout-lg-row md-radio-button:last-of-type, md-radio-group.layout-gt-lg-row md-radio-button:last-of-type, md-radio-group.layout-xl-row md-radio-button:last-of-type {
      margin-left: 0;
      margin-right: 0; }
  md-radio-group:focus {
    outline: none; }
  md-radio-group.md-focused.ng-not-empty .md-checked .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-radio-group.md-focused.ng-empty > md-radio-button:first-child .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-radio-group[disabled] md-radio-button {
    cursor: default; }
    md-radio-group[disabled] md-radio-button .md-container {
      cursor: default; }

.md-inline-form md-radio-group {
  margin: 18px 0 19px; }
  .md-inline-form md-radio-group md-radio-button {
    display: inline-block;
    height: 30px;
    padding: 2px;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff; } }

md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform-origin: left top; }
  [dir=rtl] md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
    transform-origin: right top; }

md-input-container.md-input-focused:not([md-no-float]) .md-select-placeholder span:first-child {
  transform: translateY(-22px) translateX(-2px) scale(0.75); }

.md-select-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  opacity: 0;
  display: none;
  transform: translateY(-1px); }
  .md-select-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-select-menu-container md-progress-circular {
    display: table;
    margin: 24px auto !important; }
  .md-select-menu-container.md-active {
    display: block;
    opacity: 1; }
    .md-select-menu-container.md-active md-select-menu {
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 150ms; }
      .md-select-menu-container.md-active md-select-menu > * {
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        transition-duration: 150ms;
        transition-delay: 100ms; }
  .md-select-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-input-container > md-select {
  margin: 0;
  order: 2; }

md-input-container:not(.md-input-has-value) md-select[required]:not(.md-no-asterisk) .md-select-value span:first-child:after, md-input-container:not(.md-input-has-value) md-select.ng-required:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top; }

md-input-container.md-input-invalid md-select .md-select-value {
  border-bottom-style: solid;
  padding-bottom: 1px; }

md-select {
  display: flex;
  margin: 20px 0 26px 0; }
  md-select[required].ng-empty.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-empty.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: ' *';
    font-size: 13px;
    vertical-align: top; }
  md-select[disabled] .md-select-value {
    background-position: 0 bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    margin-bottom: -1px; }
  md-select:focus {
    outline: none; }
  md-select[disabled]:hover {
    cursor: default; }
  md-select:not([disabled]):hover {
    cursor: pointer; }
  md-select:not([disabled]).ng-invalid.ng-touched .md-select-value {
    border-bottom-style: solid;
    padding-bottom: 1px; }
  md-select:not([disabled]):focus .md-select-value {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 0; }
  md-select:not([disabled]):focus.ng-invalid.ng-touched .md-select-value {
    padding-bottom: 0; }

md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  transform: translate3d(0, 1px, 0); }

.md-select-value {
  display: flex;
  align-items: center;
  padding: 2px 2px 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  box-sizing: content-box;
  min-width: 64px;
  min-height: 26px;
  flex-grow: 1; }
  .md-select-value > span:not(.md-select-icon) {
    max-width: 100%;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .md-select-value > span:not(.md-select-icon) .md-text {
      display: inline; }
  .md-select-value .md-select-icon {
    display: block;
    align-items: flex-end;
    text-align: right;
    width: 24px;
    margin: 0 4px;
    transform: translate3d(0, -2px, 0);
    font-size: 1.2rem; }
    [dir=rtl] .md-select-value .md-select-icon {
      align-items: flex-start; }
    [dir=rtl] .md-select-value .md-select-icon {
      text-align: left; }
  .md-select-value .md-select-icon:after {
    display: block;
    content: '\25BC';
    position: relative;
    top: 2px;
    speak: none;
    font-size: 13px;
    transform: scaleY(0.5) scaleX(1); }
  .md-select-value.md-select-placeholder {
    display: flex;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 2px;
    z-index: 1; }

md-select-menu {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: 256px;
  min-height: 48px;
  overflow-y: hidden;
  transform-origin: left top;
  transform: scale(1); }
  md-select-menu.md-reverse {
    flex-direction: column-reverse; }
  md-select-menu:not(.md-overflow) md-content {
    padding-top: 8px;
    padding-bottom: 8px; }
  [dir=rtl] md-select-menu {
    transform-origin: right top; }
  md-select-menu md-content {
    min-width: 136px;
    min-height: 48px;
    max-height: 256px;
    overflow-y: auto; }
  md-select-menu > * {
    opacity: 0; }

md-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  transition: background 0.15s linear;
  padding: 0 16px 0 16px;
  height: 48px; }
  md-option[disabled] {
    cursor: default; }
  md-option:focus {
    outline: none; }
  md-option .md-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

md-optgroup {
  display: block; }
  md-optgroup label {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    padding: 16px;
    font-weight: 500; }
  md-optgroup md-option {
    padding-left: 32px;
    padding-right: 32px; }

@media screen and (-ms-high-contrast: active) {
  .md-select-backdrop {
    background-color: transparent; }
  md-select-menu {
    border: 1px solid #fff; } }

md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-left: 40px;
  padding-right: 16px; }
  [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
    padding-left: 16px; }
  [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
    padding-right: 40px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      left: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      right: 0; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon {
    border-color: transparent; }
    md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      position: absolute;
      left: 4.66667px;
      top: 0.22222px;
      display: table;
      width: 6.66667px;
      height: 13.33333px;
      border-width: 2px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      content: ''; }
  md-select-menu[multiple] md-option.md-checkbox-enabled[disabled] {
    cursor: default; }
  md-select-menu[multiple] md-option.md-checkbox-enabled.md-indeterminate .md-icon:after {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: 12px;
    height: 2px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: ''; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
    margin-left: 10.66667px;
    margin-right: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      margin-left: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      margin-right: 10.66667px; }

md-sidenav {
  box-sizing: border-box;
  position: absolute;
  flex-direction: column;
  z-index: 60;
  width: 320px;
  max-width: 320px;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-sidenav ul {
    list-style: none; }
  md-sidenav.md-closed {
    display: none; }
  md-sidenav.md-closed-add, md-sidenav.md-closed-remove {
    display: flex;
    transition: 0.2s ease-in all; }
  md-sidenav.md-closed-add.md-closed-add-active, md-sidenav.md-closed-remove.md-closed-remove-active {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-sidenav.md-locked-open-add, md-sidenav.md-locked-open-remove {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-left, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-right {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open-remove.md-closed {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-closed.md-locked-open-add {
    position: static;
    display: flex;
    transform: translate3d(0%, 0, 0); }
  md-sidenav.md-closed.md-locked-open-add:not(.md-locked-open-add-active) {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 0 !important;
    min-width: 0 !important; }
  md-sidenav.md-closed.md-locked-open-add-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-sidenav.md-locked-open-remove-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 0 !important;
    min-width: 0 !important; }

.md-sidenav-backdrop.md-locked-open {
  display: none; }

.md-sidenav-left, md-sidenav {
  left: 0;
  top: 0;
  transform: translate3d(0%, 0, 0); }
  .md-sidenav-left.md-closed, md-sidenav.md-closed {
    transform: translate3d(-100%, 0, 0); }

.md-sidenav-right {
  left: 100%;
  top: 0;
  transform: translate(-100%, 0); }
  .md-sidenav-right.md-closed {
    transform: translate(0%, 0); }

@media (min-width: 600px) {
  md-sidenav {
    max-width: 400px; } }

@media (max-width: 456px) {
  md-sidenav {
    width: calc(100% - 56px);
    min-width: calc(100% - 56px);
    max-width: calc(100% - 56px); } }

@media screen and (-ms-high-contrast: active) {
  .md-sidenav-left, md-sidenav {
    border-right: 1px solid #fff; }
  .md-sidenav-right {
    border-left: 1px solid #fff; } }

@keyframes sliderFocusThumb {
  0% {
    transform: scale(0.7); }
  30% {
    transform: scale(1); }
  100% {
    transform: scale(0.7); } }

@keyframes sliderDiscreteFocusThumb {
  0% {
    transform: scale(0.7); }
  50% {
    transform: scale(0.8); }
  100% {
    transform: scale(0); } }

@keyframes sliderDiscreteFocusRing {
  0% {
    transform: scale(0.7);
    opacity: 0; }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0); } }

md-slider {
  height: 48px;
  min-width: 128px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  display: block;
  flex-direction: row;
  /**
   * Track
   */
  /**
   * Slider thumb
   */
  /* The sign that's focused in discrete mode */
  /**
   * The border/background that comes in when focused in non-discrete mode
   */
  /* Don't animate left/right while panning */ }
  md-slider *, md-slider *:after {
    box-sizing: border-box; }
  md-slider .md-slider-wrapper {
    outline: none;
    width: 100%;
    height: 100%; }
  md-slider .md-slider-content {
    position: relative; }
  md-slider .md-track-container {
    width: 100%;
    position: absolute;
    top: 23px;
    height: 2px; }
  md-slider .md-track {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-fill {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: width, height; }
  md-slider .md-track-ticks {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-ticks canvas {
    width: 100%;
    height: 100%; }
  md-slider .md-thumb-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: left, right, bottom; }
    [dir=rtl] md-slider .md-thumb-container {
      left: auto;
      right: 0; }
  md-slider .md-thumb {
    z-index: 1;
    position: absolute;
    left: -10px;
    top: 14px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transform: scale(0.7);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    [dir=rtl] md-slider .md-thumb {
      left: auto;
      right: -10px; }
    md-slider .md-thumb:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border-width: 3px;
      border-style: solid;
      transition: inherit; }
  md-slider .md-sign {
    /* Center the children (slider-thumb-text) */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -14px;
    top: -17px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.4) translate3d(0, 67.5px, 0);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    /* The arrow pointing down under the sign */ }
    md-slider .md-sign:after {
      position: absolute;
      content: '';
      left: 0px;
      border-radius: 16px;
      top: 19px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top-width: 16px;
      border-top-style: solid;
      opacity: 0;
      transform: translate3d(0, -8px, 0);
      transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1); }
      [dir=rtl] md-slider .md-sign:after {
        left: auto;
        right: 0px; }
    md-slider .md-sign .md-thumb-text {
      z-index: 1;
      font-size: 12px;
      font-weight: bold; }
  md-slider .md-focus-ring {
    position: absolute;
    left: -17px;
    top: 7px;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
    [dir=rtl] md-slider .md-focus-ring {
      left: auto;
      right: -17px; }
  md-slider .md-disabled-thumb {
    position: absolute;
    left: -14px;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.5);
    border-width: 4px;
    border-style: solid;
    display: none; }
    [dir=rtl] md-slider .md-disabled-thumb {
      left: auto;
      right: -14px; }
  md-slider.md-min .md-sign {
    opacity: 0; }
  md-slider:focus {
    outline: none; }
  md-slider.md-dragging .md-thumb-container,
  md-slider.md-dragging .md-track-fill {
    transition: none; }
  md-slider:not([md-discrete]) {
    /* Hide the sign and ticks in non-discrete mode */ }
    md-slider:not([md-discrete]) .md-track-ticks,
    md-slider:not([md-discrete]) .md-sign {
      display: none; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper .md-thumb:hover {
      transform: scale(0.8); }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
      transform: scale(1);
      opacity: 1; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
      animation: sliderFocusThumb 0.7s cubic-bezier(0.35, 0, 0.25, 1); }
    md-slider:not([md-discrete]):not([disabled]).md-active .md-slider-wrapper .md-thumb {
      transform: scale(1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
    transform: scale(0);
    animation: sliderDiscreteFocusRing 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
    animation: sliderDiscreteFocusThumb 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb, md-slider[md-discrete]:not([disabled]).md-active .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign,
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign:after, md-slider[md-discrete]:not([disabled]).md-active .md-sign,
  md-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-discrete][disabled][readonly] .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete][disabled][readonly] .md-sign,
  md-slider[md-discrete][disabled][readonly] .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[disabled] .md-track-fill {
    display: none; }
  md-slider[disabled] .md-track-ticks {
    opacity: 0; }
  md-slider[disabled]:not([readonly]) .md-sign {
    opacity: 0; }
  md-slider[disabled] .md-thumb {
    transform: scale(0.5); }
  md-slider[disabled] .md-disabled-thumb {
    display: block; }
  md-slider[md-vertical] {
    flex-direction: column;
    min-height: 128px;
    min-width: 0; }
    md-slider[md-vertical] .md-slider-wrapper {
      flex: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 48px;
      align-self: center;
      display: flex;
      justify-content: center; }
    md-slider[md-vertical] .md-track-container {
      height: 100%;
      width: 2px;
      top: 0;
      left: calc(50% - (2px / 2)); }
    md-slider[md-vertical] .md-thumb-container {
      top: auto;
      margin-bottom: 23px;
      left: calc(50% - 1px);
      bottom: 0; }
      md-slider[md-vertical] .md-thumb-container .md-thumb:after {
        left: 1px; }
      md-slider[md-vertical] .md-thumb-container .md-focus-ring {
        left: -16px; }
    md-slider[md-vertical] .md-track-fill {
      bottom: 0; }
    md-slider[md-vertical][md-discrete] .md-sign {
      left: -40px;
      top: 9.5px;
      transform: scale(0.4) translate3d(67.5px, 0, 0);
      /* The arrow pointing left next the sign */ }
      md-slider[md-vertical][md-discrete] .md-sign:after {
        top: 9.5px;
        left: 19px;
        border-top: 14px solid transparent;
        border-right: 0;
        border-bottom: 14px solid transparent;
        border-left-width: 16px;
        border-left-style: solid;
        opacity: 0;
        transform: translate3d(0, -8px, 0);
        transition: all 0.2s ease-in-out; }
      md-slider[md-vertical][md-discrete] .md-sign .md-thumb-text {
        z-index: 1;
        font-size: 12px;
        font-weight: bold; }
    md-slider[md-vertical][md-discrete].md-active .md-sign:after,
    md-slider[md-vertical][md-discrete] .md-focused .md-sign:after, md-slider[md-vertical][md-discrete][disabled][readonly] .md-sign:after {
      top: 0; }
    md-slider[md-vertical][disabled][readonly] .md-thumb {
      transform: scale(0); }
    md-slider[md-vertical][disabled][readonly] .md-sign,
    md-slider[md-vertical][disabled][readonly] .md-sign:after {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-invert]:not([md-vertical]) .md-track-fill {
    left: auto;
    right: 0; }
    [dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
      left: 0; }
    [dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
      right: auto; }
  md-slider[md-invert][md-vertical] .md-track-fill {
    bottom: auto;
    top: 0; }

md-slider-container {
  display: flex;
  align-items: center;
  flex-direction: row; }
  md-slider-container > *:first-child:not(md-slider),
  md-slider-container > *:last-child:not(md-slider) {
    min-width: 25px;
    max-width: 42px;
    height: 25px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: color, max-width; }
  md-slider-container > *:first-child:not(md-slider) {
    margin-right: 16px; }
    [dir=rtl] md-slider-container > *:first-child:not(md-slider) {
      margin-right: auto;
      margin-left: 16px; }
  md-slider-container > *:last-child:not(md-slider) {
    margin-left: 16px; }
    [dir=rtl] md-slider-container > *:last-child:not(md-slider) {
      margin-left: auto;
      margin-right: 16px; }
  md-slider-container[md-vertical] {
    flex-direction: column; }
    md-slider-container[md-vertical] > *:first-child:not(md-slider),
    md-slider-container[md-vertical] > *:last-child:not(md-slider) {
      margin-right: 0;
      margin-left: 0;
      text-align: center; }
  md-slider-container md-input-container input[type="number"] {
    text-align: center;
    padding-left: 15px;
    height: 50px;
    margin-top: -25px; }
    [dir=rtl] md-slider-container md-input-container input[type="number"] {
      padding-left: 0;
      padding-right: 15px; }

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff; } }

.md-sticky-clone {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  position: absolute !important;
  transform: translate3d(-9999px, -9999px, 0); }
  .md-sticky-clone[sticky-state="active"] {
    transform: translate3d(0, 0, 0); }
    .md-sticky-clone[sticky-state="active"]:not(.md-sticky-no-effect) .md-subheader-inner {
      animation: subheaderStickyHoverIn 0.3s ease-out both; }

@keyframes subheaderStickyHoverIn {
  0% {
    box-shadow: 0 0 0 0 transparent; }
  100% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); } }

@keyframes subheaderStickyHoverOut {
  0% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); }
  100% {
    box-shadow: 0 0 0 0 transparent; } }

.md-subheader-wrapper:not(.md-sticky-no-effect) {
  transition: 0.2s ease-out margin; }
  .md-subheader-wrapper:not(.md-sticky-no-effect) .md-subheader {
    margin: 0; }
  .md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
    z-index: 2; }
  .md-subheader-wrapper:not(.md-sticky-no-effect)[sticky-state="active"] {
    margin-top: -2px; }
  .md-subheader-wrapper:not(.md-sticky-no-effect):not(.md-sticky-clone)[sticky-prev-state="active"] .md-subheader-inner:after {
    animation: subheaderStickyHoverOut 0.3s ease-out both; }

.md-subheader {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 0 0 0 0;
  position: relative; }
  .md-subheader .md-subheader-inner {
    display: block;
    padding: 16px; }
  .md-subheader .md-subheader-content {
    display: block;
    z-index: 1;
    position: relative; }

[md-swipe-left], [md-swipe-right] {
  touch-action: pan-y; }

[md-swipe-up], [md-swipe-down] {
  touch-action: pan-x; }

.md-inline-form md-switch {
  margin-top: 18px;
  margin-bottom: 19px; }

md-switch {
  margin: 16px 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  align-items: center;
  display: flex;
  margin-left: inherit;
  margin-right: 16px; }
  [dir=rtl] md-switch {
    margin-left: 16px; }
  [dir=rtl] md-switch {
    margin-right: inherit; }
  md-switch:last-of-type {
    margin-left: inherit;
    margin-right: 0; }
    [dir=rtl] md-switch:last-of-type {
      margin-left: 0; }
    [dir=rtl] md-switch:last-of-type {
      margin-right: inherit; }
  md-switch[disabled] {
    cursor: default; }
    md-switch[disabled] .md-container {
      cursor: default; }
  md-switch .md-container {
    cursor: grab;
    width: 36px;
    height: 24px;
    position: relative;
    user-select: none;
    margin-right: 8px;
    float: left; }
    [dir=rtl] md-switch .md-container {
      margin-right: 0px;
      margin-left: 8px; }
  md-switch.md-inverted .md-container {
    margin-right: initial;
    margin-left: 8px; }
    [dir=rtl] md-switch.md-inverted .md-container {
      margin-right: 8px; }
    [dir=rtl] md-switch.md-inverted .md-container {
      margin-left: initial; }
  md-switch:not([disabled]) .md-dragging,
  md-switch:not([disabled]).md-dragging .md-container {
    cursor: grabbing; }
  md-switch.md-focused .md-thumb:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-switch .md-label {
    border-color: transparent;
    border-width: 0;
    float: left; }
  md-switch .md-bar {
    left: 1px;
    width: 34px;
    top: 5px;
    height: 14px;
    border-radius: 8px;
    position: absolute; }
  md-switch .md-thumb-container {
    top: 2px;
    left: 0;
    width: 16px;
    position: absolute;
    transform: translate3d(0, 0, 0);
    z-index: 1; }
  md-switch.md-checked .md-thumb-container {
    transform: translate3d(100%, 0, 0); }
  md-switch .md-thumb {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    outline: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    md-switch .md-thumb:before {
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-switch .md-thumb .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -20px;
      top: -20px;
      right: -20px;
      bottom: -20px; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb-container,
  md-switch:not(.md-dragging) .md-thumb {
    transition: all 0.08s linear;
    transition-property: transform, background-color; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb {
    transition-delay: 0.05s; }

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666; }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E; }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff; } }

@keyframes md-tab-content-hide {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0; }

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0; }
  md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 248px; }
  md-tabs[md-align-tabs="bottom"] {
    padding-bottom: 48px; }
    md-tabs[md-align-tabs="bottom"] md-tabs-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 48px;
      z-index: 2; }
    md-tabs[md-align-tabs="bottom"] md-tabs-content-wrapper {
      top: 0;
      bottom: 48px; }
  md-tabs.md-dynamic-height md-tabs-content-wrapper {
    min-height: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    overflow: visible; }
  md-tabs.md-dynamic-height md-tab-content.md-active {
    position: relative; }
  md-tabs[md-border-bottom] md-tabs-wrapper {
    border-width: 0 0 1px;
    border-style: solid; }
  md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 49px; }

md-tabs-wrapper {
  display: block;
  position: relative;
  transform: translate(0, 0); }
  md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
    height: 100%;
    width: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1em;
    z-index: 2;
    cursor: pointer;
    font-size: 16px;
    background: transparent no-repeat center center;
    transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
    md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
      outline: none; }
    md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
      opacity: 0.25;
      cursor: default; }
    md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
      transition: none; }
    md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    [dir="rtl"] md-tabs-wrapper md-prev-button, [dir="rtl"] md-tabs-wrapper md-next-button {
      transform: rotateY(180deg) translateY(-50%); }
  md-tabs-wrapper md-prev-button {
    left: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg=="); }
    [dir=rtl] md-tabs-wrapper md-prev-button {
      left: auto;
      right: 0; }
  md-tabs-wrapper md-next-button {
    right: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K"); }
    [dir=rtl] md-tabs-wrapper md-next-button {
      right: auto;
      left: 0; }
    md-tabs-wrapper md-next-button md-icon {
      transform: translate(-50%, -50%) rotate(180deg); }
  md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
    width: 100%;
    flex-direction: row; }
    md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
      flex-grow: 1; }

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px; }
  md-tabs-canvas:after {
    content: '';
    display: table;
    clear: both; }
  md-tabs-canvas .md-dummy-wrapper {
    position: absolute;
    top: 0;
    left: 0; }
    [dir=rtl] md-tabs-canvas .md-dummy-wrapper {
      left: auto;
      right: 0; }
  md-tabs-canvas.md-paginated {
    margin: 0 32px; }
  md-tabs-canvas.md-center-tabs {
    display: flex;
    flex-direction: column;
    text-align: center; }
    md-tabs-canvas.md-center-tabs .md-tab {
      float: none;
      display: inline-block; }

md-pagination-wrapper {
  height: 48px;
  display: flex;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  left: 0;
  transform: translate(0, 0); }
  md-pagination-wrapper:after {
    content: '';
    display: table;
    clear: both; }
  [dir=rtl] md-pagination-wrapper {
    left: auto;
    right: 0; }
  md-pagination-wrapper.md-center-tabs {
    position: relative;
    justify-content: center; }

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

md-tab-content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto;
  transform: translate(0, 0); }
  md-tab-content.md-no-scroll {
    bottom: auto;
    overflow: hidden; }
  md-tab-content.ng-leave, md-tab-content.md-no-transition {
    transition: none; }
  md-tab-content.md-left:not(.md-active) {
    transform: translateX(-100%);
    animation: 1s md-tab-content-hide;
    visibility: hidden; }
    [dir=rtl] md-tab-content.md-left:not(.md-active) {
      transform: translateX(100%); }
    md-tab-content.md-left:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content.md-right:not(.md-active) {
    transform: translateX(100%);
    animation: 1s md-tab-content-hide;
    visibility: hidden; }
    [dir=rtl] md-tab-content.md-right:not(.md-active) {
      transform: translateX(-100%); }
    md-tab-content.md-right:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content > div {
    flex: 1 0 100%;
    min-width: 0; }
    md-tab-content > div.ng-leave {
      animation: 1s md-tab-content-hide; }

md-ink-bar {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px; }
  md-ink-bar.md-left {
    transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }
  md-ink-bar.md-right {
    transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px; }

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  padding: 12px 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis; }
  [dir=rtl] .md-tab {
    float: right; }
  .md-tab.md-focused, .md-tab:focus {
    box-shadow: none;
    outline: none; }
  .md-tab.md-active {
    cursor: default; }
  .md-tab.md-disabled {
    pointer-events: none;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    opacity: 0.5;
    cursor: default; }
  .md-tab.ng-leave {
    transition: none; }

md-toolbar + md-tabs, md-toolbar + md-dialog-content md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.md-toast-text {
  padding: 0 6px; }

md-toast {
  position: absolute;
  z-index: 105;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  padding: 8px;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Transition differently when swiping */
  /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
  md-toast .md-toast-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 168px;
    max-width: 100%;
    min-height: 48px;
    padding: 0 18px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 2px;
    font-size: 14px;
    overflow: hidden;
    transform: translate3d(0, 0, 0) rotateZ(0deg);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    justify-content: flex-start; }
    md-toast .md-toast-content::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    [dir=rtl] md-toast .md-toast-content {
      justify-content: flex-end; }
    md-toast .md-toast-content span {
      flex: 1 1 0%;
      box-sizing: border-box;
      min-width: 0; }
  md-toast.md-capsule {
    border-radius: 24px; }
    md-toast.md-capsule .md-toast-content {
      border-radius: 24px; }
  md-toast.ng-leave-active .md-toast-content {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-toast.md-swipeleft .md-toast-content, md-toast.md-swiperight .md-toast-content, md-toast.md-swipeup .md-toast-content, md-toast.md-swipedown .md-toast-content {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-toast.ng-enter {
    opacity: 0; }
    md-toast.ng-enter .md-toast-content {
      transform: translate3d(0, 100%, 0); }
    md-toast.ng-enter.md-top .md-toast-content {
      transform: translate3d(0, -100%, 0); }
    md-toast.ng-enter.ng-enter-active {
      opacity: 1; }
      md-toast.ng-enter.ng-enter-active .md-toast-content {
        transform: translate3d(0, 0, 0); }
  md-toast.ng-leave.ng-leave-active .md-toast-content {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
    transform: translate3d(0, -50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
    transform: translate3d(0, 50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-top .md-toast-content {
    transform: translate3d(0, -100%, 0); }
  md-toast .md-action {
    line-height: 19px;
    margin-left: 24px;
    margin-right: 0;
    cursor: pointer;
    text-transform: uppercase;
    float: right; }
  md-toast .md-button {
    min-width: 0;
    margin-right: 0;
    margin-left: 12px; }
    [dir=rtl] md-toast .md-button {
      margin-right: 12px; }
    [dir=rtl] md-toast .md-button {
      margin-left: 0; }

@media (max-width: 959px) {
  md-toast {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    border-radius: 0;
    bottom: 0;
    padding: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
      transform: translate3d(0, -50%, 0); }
    md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
      transform: translate3d(0, 50%, 0); } }

@media (min-width: 960px) {
  md-toast {
    min-width: 304px;
    /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
    md-toast.md-bottom {
      bottom: 0; }
    md-toast.md-left {
      left: 0; }
    md-toast.md-right {
      right: 0; }
    md-toast.md-top {
      top: 0; }
    md-toast._md-start {
      left: 0; }
      [dir=rtl] md-toast._md-start {
        left: auto;
        right: 0; }
    md-toast._md-end {
      right: 0; }
      [dir=rtl] md-toast._md-end {
        right: auto;
        left: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeleft .md-toast-content {
      transform: translate3d(-50%, 0, 0); }
    md-toast.ng-leave.ng-leave-active.md-swiperight .md-toast-content {
      transform: translate3d(50%, 0, 0); } }

@media (min-width: 1920px) {
  md-toast .md-toast-content {
    max-width: 568px; } }

@media screen and (-ms-high-contrast: active) {
  md-toast {
    border: 1px solid #fff; } }

.md-toast-animating {
  overflow: hidden !important; }

md-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  min-height: 64px;
  width: 100%; }
  md-toolbar._md-toolbar-transitions {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    transition-property: background-color, fill, color; }
  md-toolbar.md-whiteframe-z1-add, md-toolbar.md-whiteframe-z1-remove {
    transition: box-shadow 0.5s linear; }
  md-toolbar md-toolbar-filler {
    width: 72px; }
  md-toolbar *,
  md-toolbar *:before,
  md-toolbar *:after {
    box-sizing: border-box; }
  md-toolbar.ng-animate {
    transition: none; }
  md-toolbar.md-tall {
    height: 128px;
    min-height: 128px;
    max-height: 128px; }
  md-toolbar.md-medium-tall {
    height: 88px;
    min-height: 88px;
    max-height: 88px; }
    md-toolbar.md-medium-tall .md-toolbar-tools {
      height: 48px;
      min-height: 48px;
      max-height: 48px; }
  md-toolbar > .md-indent {
    margin-left: 64px; }
    [dir=rtl] md-toolbar > .md-indent {
      margin-left: auto;
      margin-right: 64px; }
  md-toolbar ~ md-content > md-list {
    padding: 0; }
    md-toolbar ~ md-content > md-list md-list-item:last-child md-divider {
      display: none; }

.md-toolbar-tools {
  font-size: 20px;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  margin: 0; }
  .md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
    font-size: inherit;
    font-weight: inherit;
    margin: inherit; }
  .md-toolbar-tools a {
    color: inherit;
    text-decoration: none; }
  .md-toolbar-tools .fill-height {
    display: flex;
    align-items: center; }
  .md-toolbar-tools md-checkbox {
    margin: inherit; }
  .md-toolbar-tools .md-button {
    margin-top: 0;
    margin-bottom: 0; }
    .md-toolbar-tools .md-button, .md-toolbar-tools .md-button.md-icon-button md-icon {
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      transition-property: background-color, fill, color; }
      .md-toolbar-tools .md-button.ng-animate, .md-toolbar-tools .md-button.md-icon-button md-icon.ng-animate {
        transition: none; }
  .md-toolbar-tools > .md-button:first-child {
    margin-left: -8px; }
    [dir=rtl] .md-toolbar-tools > .md-button:first-child {
      margin-left: auto;
      margin-right: -8px; }
  .md-toolbar-tools > .md-button:last-child {
    margin-right: -8px; }
    [dir=rtl] .md-toolbar-tools > .md-button:last-child {
      margin-right: auto;
      margin-left: -8px; }
  .md-toolbar-tools > md-menu:last-child {
    margin-right: -8px; }
    [dir=rtl] .md-toolbar-tools > md-menu:last-child {
      margin-right: auto;
      margin-left: -8px; }
    .md-toolbar-tools > md-menu:last-child > .md-button {
      margin-right: 0; }
      [dir=rtl] .md-toolbar-tools > md-menu:last-child > .md-button {
        margin-right: auto;
        margin-left: 0; }
  @media screen and (-ms-high-contrast: active) {
    .md-toolbar-tools {
      border-bottom: 1px solid #fff; } }

@media (min-width: 0) and (max-width: 959px) and (orientation: portrait) {
  md-toolbar {
    min-height: 56px; }
  .md-toolbar-tools {
    height: 56px;
    max-height: 56px; } }

@media (min-width: 0) and (max-width: 959px) and (orientation: landscape) {
  md-toolbar {
    min-height: 48px; }
  .md-toolbar-tools {
    height: 48px;
    max-height: 48px; } }

.md-tooltip {
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  line-height: 32px;
  padding-right: 16px;
  padding-left: 16px; }
  .md-tooltip.md-origin-top {
    transform-origin: center bottom;
    margin-top: -24px; }
  .md-tooltip.md-origin-right {
    transform-origin: left center;
    margin-left: 24px; }
  .md-tooltip.md-origin-bottom {
    transform-origin: center top;
    margin-top: 24px; }
  .md-tooltip.md-origin-left {
    transform-origin: right center;
    margin-left: -24px; }
  @media (min-width: 960px) {
    .md-tooltip {
      font-size: 10px;
      height: 22px;
      line-height: 22px;
      padding-right: 8px;
      padding-left: 8px; }
      .md-tooltip.md-origin-top {
        margin-top: -14px; }
      .md-tooltip.md-origin-right {
        margin-left: 14px; }
      .md-tooltip.md-origin-bottom {
        margin-top: 14px; }
      .md-tooltip.md-origin-left {
        margin-left: -14px; } }
  .md-tooltip.md-show-add {
    transform: scale(0); }
  .md-tooltip.md-show {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 150ms;
    transform: scale(1);
    opacity: 0.9; }
  .md-tooltip.md-hide {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 150ms;
    transform: scale(0);
    opacity: 0; }

.md-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .md-truncate.md-clip {
    text-overflow: clip; }
  .md-truncate.flex {
    width: 0; }

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .md-virtual-repeat-container .md-virtual-repeat-scroller {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-overflow-scrolling: touch; }
  .md-virtual-repeat-container .md-virtual-repeat-sizer {
    box-sizing: border-box;
    height: 1px;
    display: block;
    margin: 0;
    padding: 0;
    width: 1px; }
  .md-virtual-repeat-container .md-virtual-repeat-offsetter {
    box-sizing: border-box;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-scroller {
  overflow-x: auto;
  overflow-y: hidden; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  bottom: 16px;
  right: auto;
  white-space: nowrap; }
  [dir=rtl] .md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
    right: auto;
    left: auto; }

.md-whiteframe-1dp, .md-whiteframe-z1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-2dp {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-3dp {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-4dp, .md-whiteframe-z2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-6dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-7dp, .md-whiteframe-z3 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-9dp {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-10dp, .md-whiteframe-z4 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-11dp {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-12dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-13dp, .md-whiteframe-z5 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-14dp {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-15dp {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-16dp {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-17dp {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-18dp {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-19dp {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-20dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-21dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-22dp {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-23dp {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.md-whiteframe-24dp {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

@media screen and (-ms-high-contrast: active) {
  md-whiteframe {
    border: 1px solid #fff; } }

@media print {
  md-whiteframe, [md-whiteframe] {
    background-color: #ffffff; } }

/*
* Since Layout API uses ng-cloak to hide the dom elements while layouts are adjusted
*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*/
@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; } }

/*
 *  Apply Mixins to create Layout/Flexbox styles
 */
.flex-order {
  order: 0; }

.flex-order--20 {
  order: -20; }

.flex-order--19 {
  order: -19; }

.flex-order--18 {
  order: -18; }

.flex-order--17 {
  order: -17; }

.flex-order--16 {
  order: -16; }

.flex-order--15 {
  order: -15; }

.flex-order--14 {
  order: -14; }

.flex-order--13 {
  order: -13; }

.flex-order--12 {
  order: -12; }

.flex-order--11 {
  order: -11; }

.flex-order--10 {
  order: -10; }

.flex-order--9 {
  order: -9; }

.flex-order--8 {
  order: -8; }

.flex-order--7 {
  order: -7; }

.flex-order--6 {
  order: -6; }

.flex-order--5 {
  order: -5; }

.flex-order--4 {
  order: -4; }

.flex-order--3 {
  order: -3; }

.flex-order--2 {
  order: -2; }

.flex-order--1 {
  order: -1; }

.flex-order-0 {
  order: 0; }

.flex-order-1 {
  order: 1; }

.flex-order-2 {
  order: 2; }

.flex-order-3 {
  order: 3; }

.flex-order-4 {
  order: 4; }

.flex-order-5 {
  order: 5; }

.flex-order-6 {
  order: 6; }

.flex-order-7 {
  order: 7; }

.flex-order-8 {
  order: 8; }

.flex-order-9 {
  order: 9; }

.flex-order-10 {
  order: 10; }

.flex-order-11 {
  order: 11; }

.flex-order-12 {
  order: 12; }

.flex-order-13 {
  order: 13; }

.flex-order-14 {
  order: 14; }

.flex-order-15 {
  order: 15; }

.flex-order-16 {
  order: 16; }

.flex-order-17 {
  order: 17; }

.flex-order-18 {
  order: 18; }

.flex-order-19 {
  order: 19; }

.flex-order-20 {
  order: 20; }

.offset-0, .flex-offset-0, .layout-margin .flex-offset-0, .layout-margin .offset-0 {
  margin-left: 0; }
  [dir=rtl] .offset-0, [dir=rtl] .flex-offset-0, [dir=rtl] .layout-margin .flex-offset-0, [dir=rtl] .layout-margin .offset-0 {
    margin-left: auto;
    margin-right: 0; }

.offset-5, .flex-offset-5, .layout-margin .flex-offset-5, .layout-margin .offset-5 {
  margin-left: 5%; }
  [dir=rtl] .offset-5, [dir=rtl] .flex-offset-5, [dir=rtl] .layout-margin .flex-offset-5, [dir=rtl] .layout-margin .offset-5 {
    margin-left: auto;
    margin-right: 5%; }

.offset-10, .flex-offset-10, .layout-margin .flex-offset-10, .layout-margin .offset-10 {
  margin-left: 10%; }
  [dir=rtl] .offset-10, [dir=rtl] .flex-offset-10, [dir=rtl] .layout-margin .flex-offset-10, [dir=rtl] .layout-margin .offset-10 {
    margin-left: auto;
    margin-right: 10%; }

.offset-15, .flex-offset-15, .layout-margin .flex-offset-15, .layout-margin .offset-15 {
  margin-left: 15%; }
  [dir=rtl] .offset-15, [dir=rtl] .flex-offset-15, [dir=rtl] .layout-margin .flex-offset-15, [dir=rtl] .layout-margin .offset-15 {
    margin-left: auto;
    margin-right: 15%; }

.offset-20, .flex-offset-20, .layout-margin .flex-offset-20, .layout-margin .offset-20 {
  margin-left: 20%; }
  [dir=rtl] .offset-20, [dir=rtl] .flex-offset-20, [dir=rtl] .layout-margin .flex-offset-20, [dir=rtl] .layout-margin .offset-20 {
    margin-left: auto;
    margin-right: 20%; }

.offset-25, .flex-offset-25, .layout-margin .flex-offset-25, .layout-margin .offset-25 {
  margin-left: 25%; }
  [dir=rtl] .offset-25, [dir=rtl] .flex-offset-25, [dir=rtl] .layout-margin .flex-offset-25, [dir=rtl] .layout-margin .offset-25 {
    margin-left: auto;
    margin-right: 25%; }

.offset-30, .flex-offset-30, .layout-margin .flex-offset-30, .layout-margin .offset-30 {
  margin-left: 30%; }
  [dir=rtl] .offset-30, [dir=rtl] .flex-offset-30, [dir=rtl] .layout-margin .flex-offset-30, [dir=rtl] .layout-margin .offset-30 {
    margin-left: auto;
    margin-right: 30%; }

.offset-35, .flex-offset-35, .layout-margin .flex-offset-35, .layout-margin .offset-35 {
  margin-left: 35%; }
  [dir=rtl] .offset-35, [dir=rtl] .flex-offset-35, [dir=rtl] .layout-margin .flex-offset-35, [dir=rtl] .layout-margin .offset-35 {
    margin-left: auto;
    margin-right: 35%; }

.offset-40, .flex-offset-40, .layout-margin .flex-offset-40, .layout-margin .offset-40 {
  margin-left: 40%; }
  [dir=rtl] .offset-40, [dir=rtl] .flex-offset-40, [dir=rtl] .layout-margin .flex-offset-40, [dir=rtl] .layout-margin .offset-40 {
    margin-left: auto;
    margin-right: 40%; }

.offset-45, .flex-offset-45, .layout-margin .flex-offset-45, .layout-margin .offset-45 {
  margin-left: 45%; }
  [dir=rtl] .offset-45, [dir=rtl] .flex-offset-45, [dir=rtl] .layout-margin .flex-offset-45, [dir=rtl] .layout-margin .offset-45 {
    margin-left: auto;
    margin-right: 45%; }

.offset-50, .flex-offset-50, .layout-margin .flex-offset-50, .layout-margin .offset-50 {
  margin-left: 50%; }
  [dir=rtl] .offset-50, [dir=rtl] .flex-offset-50, [dir=rtl] .layout-margin .flex-offset-50, [dir=rtl] .layout-margin .offset-50 {
    margin-left: auto;
    margin-right: 50%; }

.offset-55, .flex-offset-55, .layout-margin .flex-offset-55, .layout-margin .offset-55 {
  margin-left: 55%; }
  [dir=rtl] .offset-55, [dir=rtl] .flex-offset-55, [dir=rtl] .layout-margin .flex-offset-55, [dir=rtl] .layout-margin .offset-55 {
    margin-left: auto;
    margin-right: 55%; }

.offset-60, .flex-offset-60, .layout-margin .flex-offset-60, .layout-margin .offset-60 {
  margin-left: 60%; }
  [dir=rtl] .offset-60, [dir=rtl] .flex-offset-60, [dir=rtl] .layout-margin .flex-offset-60, [dir=rtl] .layout-margin .offset-60 {
    margin-left: auto;
    margin-right: 60%; }

.offset-65, .flex-offset-65, .layout-margin .flex-offset-65, .layout-margin .offset-65 {
  margin-left: 65%; }
  [dir=rtl] .offset-65, [dir=rtl] .flex-offset-65, [dir=rtl] .layout-margin .flex-offset-65, [dir=rtl] .layout-margin .offset-65 {
    margin-left: auto;
    margin-right: 65%; }

.offset-70, .flex-offset-70, .layout-margin .flex-offset-70, .layout-margin .offset-70 {
  margin-left: 70%; }
  [dir=rtl] .offset-70, [dir=rtl] .flex-offset-70, [dir=rtl] .layout-margin .flex-offset-70, [dir=rtl] .layout-margin .offset-70 {
    margin-left: auto;
    margin-right: 70%; }

.offset-75, .flex-offset-75, .layout-margin .flex-offset-75, .layout-margin .offset-75 {
  margin-left: 75%; }
  [dir=rtl] .offset-75, [dir=rtl] .flex-offset-75, [dir=rtl] .layout-margin .flex-offset-75, [dir=rtl] .layout-margin .offset-75 {
    margin-left: auto;
    margin-right: 75%; }

.offset-80, .flex-offset-80, .layout-margin .flex-offset-80, .layout-margin .offset-80 {
  margin-left: 80%; }
  [dir=rtl] .offset-80, [dir=rtl] .flex-offset-80, [dir=rtl] .layout-margin .flex-offset-80, [dir=rtl] .layout-margin .offset-80 {
    margin-left: auto;
    margin-right: 80%; }

.offset-85, .flex-offset-85, .layout-margin .flex-offset-85, .layout-margin .offset-85 {
  margin-left: 85%; }
  [dir=rtl] .offset-85, [dir=rtl] .flex-offset-85, [dir=rtl] .layout-margin .flex-offset-85, [dir=rtl] .layout-margin .offset-85 {
    margin-left: auto;
    margin-right: 85%; }

.offset-90, .flex-offset-90, .layout-margin .flex-offset-90, .layout-margin .offset-90 {
  margin-left: 90%; }
  [dir=rtl] .offset-90, [dir=rtl] .flex-offset-90, [dir=rtl] .layout-margin .flex-offset-90, [dir=rtl] .layout-margin .offset-90 {
    margin-left: auto;
    margin-right: 90%; }

.offset-95, .flex-offset-95, .layout-margin .flex-offset-95, .layout-margin .offset-95 {
  margin-left: 95%; }
  [dir=rtl] .offset-95, [dir=rtl] .flex-offset-95, [dir=rtl] .layout-margin .flex-offset-95, [dir=rtl] .layout-margin .offset-95 {
    margin-left: auto;
    margin-right: 95%; }

.offset-33, .flex-offset-33, .layout-margin .flex-offset-33, .layout-margin .offset-33 {
  margin-left: calc(100% / 3); }

.offset-66, .flex-offset-66, .layout-margin .flex-offset-66, .layout-margin .offset-66 {
  margin-left: calc(200% / 3); }
  [dir=rtl] .offset-66, [dir=rtl] .flex-offset-66, [dir=rtl] .layout-margin .flex-offset-66, [dir=rtl] .layout-margin .offset-66 {
    margin-left: auto;
    margin-right: calc(200% / 3); }

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch; }

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start; }

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center; }

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end; }

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  justify-content: space-around; }

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  justify-content: space-between; }

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  align-items: flex-start;
  align-content: flex-start; }

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%; }

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box; }

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  align-items: flex-end;
  align-content: flex-end; }

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch; }

.flex {
  flex: 1;
  box-sizing: border-box; }

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box; }

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box; }

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box; }

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box; }

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0; }

.layout-column > .flex-0 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box; }

.flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-5 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

.flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-10 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

.flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-15 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

.flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-20 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

.flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-25 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

.flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-30 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

.flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-35 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

.flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-40 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

.flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-45 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

.flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-50 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

.flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-55 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

.flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-60 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

.flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-65 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

.flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-70 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

.flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-75 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

.flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-80 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

.flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-85 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

.flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-90 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

.flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-95 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.flex-33 {
  flex: 1 1 100%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.flex-66 {
  flex: 1 1 100%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-row > .flex-33 {
  flex: 1 1 100%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66 {
  flex: 1 1 100%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex {
  min-width: 0; }

.layout-column > .flex-33 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-column > .flex {
  min-height: 0; }

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.layout-column {
  flex-direction: column; }

.layout-row {
  flex-direction: row; }

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 4px; }

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 8px; }

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 16px; }

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 4px; }

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 8px; }

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 16px; }

.layout-wrap {
  flex-wrap: wrap; }

.layout-nowrap {
  flex-wrap: nowrap; }

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none; }
  .flex-order-xs--20 {
    order: -20; }
  .flex-order-xs--19 {
    order: -19; }
  .flex-order-xs--18 {
    order: -18; }
  .flex-order-xs--17 {
    order: -17; }
  .flex-order-xs--16 {
    order: -16; }
  .flex-order-xs--15 {
    order: -15; }
  .flex-order-xs--14 {
    order: -14; }
  .flex-order-xs--13 {
    order: -13; }
  .flex-order-xs--12 {
    order: -12; }
  .flex-order-xs--11 {
    order: -11; }
  .flex-order-xs--10 {
    order: -10; }
  .flex-order-xs--9 {
    order: -9; }
  .flex-order-xs--8 {
    order: -8; }
  .flex-order-xs--7 {
    order: -7; }
  .flex-order-xs--6 {
    order: -6; }
  .flex-order-xs--5 {
    order: -5; }
  .flex-order-xs--4 {
    order: -4; }
  .flex-order-xs--3 {
    order: -3; }
  .flex-order-xs--2 {
    order: -2; }
  .flex-order-xs--1 {
    order: -1; }
  .flex-order-xs-0 {
    order: 0; }
  .flex-order-xs-1 {
    order: 1; }
  .flex-order-xs-2 {
    order: 2; }
  .flex-order-xs-3 {
    order: 3; }
  .flex-order-xs-4 {
    order: 4; }
  .flex-order-xs-5 {
    order: 5; }
  .flex-order-xs-6 {
    order: 6; }
  .flex-order-xs-7 {
    order: 7; }
  .flex-order-xs-8 {
    order: 8; }
  .flex-order-xs-9 {
    order: 9; }
  .flex-order-xs-10 {
    order: 10; }
  .flex-order-xs-11 {
    order: 11; }
  .flex-order-xs-12 {
    order: 12; }
  .flex-order-xs-13 {
    order: 13; }
  .flex-order-xs-14 {
    order: 14; }
  .flex-order-xs-15 {
    order: 15; }
  .flex-order-xs-16 {
    order: 16; }
  .flex-order-xs-17 {
    order: 17; }
  .flex-order-xs-18 {
    order: 18; }
  .flex-order-xs-19 {
    order: 19; }
  .flex-order-xs-20 {
    order: 20; }
  .offset-xs-0, .flex-offset-xs-0, .layout-margin .flex-offset-xs-0, .layout-margin .offset-xs-0 {
    margin-left: 0; }
    [dir=rtl] .offset-xs-0, [dir=rtl] .flex-offset-xs-0, [dir=rtl] .layout-margin .flex-offset-xs-0, [dir=rtl] .layout-margin .offset-xs-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-xs-5, .flex-offset-xs-5, .layout-margin .flex-offset-xs-5, .layout-margin .offset-xs-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-xs-5, [dir=rtl] .flex-offset-xs-5, [dir=rtl] .layout-margin .flex-offset-xs-5, [dir=rtl] .layout-margin .offset-xs-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-xs-10, .flex-offset-xs-10, .layout-margin .flex-offset-xs-10, .layout-margin .offset-xs-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-xs-10, [dir=rtl] .flex-offset-xs-10, [dir=rtl] .layout-margin .flex-offset-xs-10, [dir=rtl] .layout-margin .offset-xs-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-xs-15, .flex-offset-xs-15, .layout-margin .flex-offset-xs-15, .layout-margin .offset-xs-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-xs-15, [dir=rtl] .flex-offset-xs-15, [dir=rtl] .layout-margin .flex-offset-xs-15, [dir=rtl] .layout-margin .offset-xs-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-xs-20, .flex-offset-xs-20, .layout-margin .flex-offset-xs-20, .layout-margin .offset-xs-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-xs-20, [dir=rtl] .flex-offset-xs-20, [dir=rtl] .layout-margin .flex-offset-xs-20, [dir=rtl] .layout-margin .offset-xs-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-xs-25, .flex-offset-xs-25, .layout-margin .flex-offset-xs-25, .layout-margin .offset-xs-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-xs-25, [dir=rtl] .flex-offset-xs-25, [dir=rtl] .layout-margin .flex-offset-xs-25, [dir=rtl] .layout-margin .offset-xs-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-xs-30, .flex-offset-xs-30, .layout-margin .flex-offset-xs-30, .layout-margin .offset-xs-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-xs-30, [dir=rtl] .flex-offset-xs-30, [dir=rtl] .layout-margin .flex-offset-xs-30, [dir=rtl] .layout-margin .offset-xs-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-xs-35, .flex-offset-xs-35, .layout-margin .flex-offset-xs-35, .layout-margin .offset-xs-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-xs-35, [dir=rtl] .flex-offset-xs-35, [dir=rtl] .layout-margin .flex-offset-xs-35, [dir=rtl] .layout-margin .offset-xs-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-xs-40, .flex-offset-xs-40, .layout-margin .flex-offset-xs-40, .layout-margin .offset-xs-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-xs-40, [dir=rtl] .flex-offset-xs-40, [dir=rtl] .layout-margin .flex-offset-xs-40, [dir=rtl] .layout-margin .offset-xs-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-xs-45, .flex-offset-xs-45, .layout-margin .flex-offset-xs-45, .layout-margin .offset-xs-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-xs-45, [dir=rtl] .flex-offset-xs-45, [dir=rtl] .layout-margin .flex-offset-xs-45, [dir=rtl] .layout-margin .offset-xs-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-xs-50, .flex-offset-xs-50, .layout-margin .flex-offset-xs-50, .layout-margin .offset-xs-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-xs-50, [dir=rtl] .flex-offset-xs-50, [dir=rtl] .layout-margin .flex-offset-xs-50, [dir=rtl] .layout-margin .offset-xs-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-xs-55, .flex-offset-xs-55, .layout-margin .flex-offset-xs-55, .layout-margin .offset-xs-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-xs-55, [dir=rtl] .flex-offset-xs-55, [dir=rtl] .layout-margin .flex-offset-xs-55, [dir=rtl] .layout-margin .offset-xs-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-xs-60, .flex-offset-xs-60, .layout-margin .flex-offset-xs-60, .layout-margin .offset-xs-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-xs-60, [dir=rtl] .flex-offset-xs-60, [dir=rtl] .layout-margin .flex-offset-xs-60, [dir=rtl] .layout-margin .offset-xs-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-xs-65, .flex-offset-xs-65, .layout-margin .flex-offset-xs-65, .layout-margin .offset-xs-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-xs-65, [dir=rtl] .flex-offset-xs-65, [dir=rtl] .layout-margin .flex-offset-xs-65, [dir=rtl] .layout-margin .offset-xs-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-xs-70, .flex-offset-xs-70, .layout-margin .flex-offset-xs-70, .layout-margin .offset-xs-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-xs-70, [dir=rtl] .flex-offset-xs-70, [dir=rtl] .layout-margin .flex-offset-xs-70, [dir=rtl] .layout-margin .offset-xs-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-xs-75, .flex-offset-xs-75, .layout-margin .flex-offset-xs-75, .layout-margin .offset-xs-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-xs-75, [dir=rtl] .flex-offset-xs-75, [dir=rtl] .layout-margin .flex-offset-xs-75, [dir=rtl] .layout-margin .offset-xs-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-xs-80, .flex-offset-xs-80, .layout-margin .flex-offset-xs-80, .layout-margin .offset-xs-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-xs-80, [dir=rtl] .flex-offset-xs-80, [dir=rtl] .layout-margin .flex-offset-xs-80, [dir=rtl] .layout-margin .offset-xs-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-xs-85, .flex-offset-xs-85, .layout-margin .flex-offset-xs-85, .layout-margin .offset-xs-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-xs-85, [dir=rtl] .flex-offset-xs-85, [dir=rtl] .layout-margin .flex-offset-xs-85, [dir=rtl] .layout-margin .offset-xs-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-xs-90, .flex-offset-xs-90, .layout-margin .flex-offset-xs-90, .layout-margin .offset-xs-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-xs-90, [dir=rtl] .flex-offset-xs-90, [dir=rtl] .layout-margin .flex-offset-xs-90, [dir=rtl] .layout-margin .offset-xs-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-xs-95, .flex-offset-xs-95, .layout-margin .flex-offset-xs-95, .layout-margin .offset-xs-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-xs-95, [dir=rtl] .flex-offset-xs-95, [dir=rtl] .layout-margin .flex-offset-xs-95, [dir=rtl] .layout-margin .offset-xs-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-xs-33, .flex-offset-xs-33, .layout-margin .flex-offset-xs-33, .layout-margin .offset-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-xs-66, .flex-offset-xs-66, .layout-margin .flex-offset-xs-66, .layout-margin .offset-xs-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-xs-66, [dir=rtl] .flex-offset-xs-66, [dir=rtl] .layout-margin .flex-offset-xs-66, [dir=rtl] .layout-margin .offset-xs-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-xs,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    justify-content: center; }
  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xs {
    flex: 1;
    box-sizing: border-box; }
  .flex-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-xs-column > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex {
    min-width: 0; }
  .layout-xs-column > .flex-xs-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xs-column > .flex {
    min-height: 0; }
  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xs-column {
    flex-direction: column; }
  .layout-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) {
  .flex-order-gt-xs--20 {
    order: -20; }
  .flex-order-gt-xs--19 {
    order: -19; }
  .flex-order-gt-xs--18 {
    order: -18; }
  .flex-order-gt-xs--17 {
    order: -17; }
  .flex-order-gt-xs--16 {
    order: -16; }
  .flex-order-gt-xs--15 {
    order: -15; }
  .flex-order-gt-xs--14 {
    order: -14; }
  .flex-order-gt-xs--13 {
    order: -13; }
  .flex-order-gt-xs--12 {
    order: -12; }
  .flex-order-gt-xs--11 {
    order: -11; }
  .flex-order-gt-xs--10 {
    order: -10; }
  .flex-order-gt-xs--9 {
    order: -9; }
  .flex-order-gt-xs--8 {
    order: -8; }
  .flex-order-gt-xs--7 {
    order: -7; }
  .flex-order-gt-xs--6 {
    order: -6; }
  .flex-order-gt-xs--5 {
    order: -5; }
  .flex-order-gt-xs--4 {
    order: -4; }
  .flex-order-gt-xs--3 {
    order: -3; }
  .flex-order-gt-xs--2 {
    order: -2; }
  .flex-order-gt-xs--1 {
    order: -1; }
  .flex-order-gt-xs-0 {
    order: 0; }
  .flex-order-gt-xs-1 {
    order: 1; }
  .flex-order-gt-xs-2 {
    order: 2; }
  .flex-order-gt-xs-3 {
    order: 3; }
  .flex-order-gt-xs-4 {
    order: 4; }
  .flex-order-gt-xs-5 {
    order: 5; }
  .flex-order-gt-xs-6 {
    order: 6; }
  .flex-order-gt-xs-7 {
    order: 7; }
  .flex-order-gt-xs-8 {
    order: 8; }
  .flex-order-gt-xs-9 {
    order: 9; }
  .flex-order-gt-xs-10 {
    order: 10; }
  .flex-order-gt-xs-11 {
    order: 11; }
  .flex-order-gt-xs-12 {
    order: 12; }
  .flex-order-gt-xs-13 {
    order: 13; }
  .flex-order-gt-xs-14 {
    order: 14; }
  .flex-order-gt-xs-15 {
    order: 15; }
  .flex-order-gt-xs-16 {
    order: 16; }
  .flex-order-gt-xs-17 {
    order: 17; }
  .flex-order-gt-xs-18 {
    order: 18; }
  .flex-order-gt-xs-19 {
    order: 19; }
  .flex-order-gt-xs-20 {
    order: 20; }
  .offset-gt-xs-0, .flex-offset-gt-xs-0, .layout-margin .flex-offset-gt-xs-0, .layout-margin .offset-gt-xs-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-xs-0, [dir=rtl] .flex-offset-gt-xs-0, [dir=rtl] .layout-margin .flex-offset-gt-xs-0, [dir=rtl] .layout-margin .offset-gt-xs-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-xs-5, .flex-offset-gt-xs-5, .layout-margin .flex-offset-gt-xs-5, .layout-margin .offset-gt-xs-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-xs-5, [dir=rtl] .flex-offset-gt-xs-5, [dir=rtl] .layout-margin .flex-offset-gt-xs-5, [dir=rtl] .layout-margin .offset-gt-xs-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-xs-10, .flex-offset-gt-xs-10, .layout-margin .flex-offset-gt-xs-10, .layout-margin .offset-gt-xs-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-xs-10, [dir=rtl] .flex-offset-gt-xs-10, [dir=rtl] .layout-margin .flex-offset-gt-xs-10, [dir=rtl] .layout-margin .offset-gt-xs-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-xs-15, .flex-offset-gt-xs-15, .layout-margin .flex-offset-gt-xs-15, .layout-margin .offset-gt-xs-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-xs-15, [dir=rtl] .flex-offset-gt-xs-15, [dir=rtl] .layout-margin .flex-offset-gt-xs-15, [dir=rtl] .layout-margin .offset-gt-xs-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-xs-20, .flex-offset-gt-xs-20, .layout-margin .flex-offset-gt-xs-20, .layout-margin .offset-gt-xs-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-xs-20, [dir=rtl] .flex-offset-gt-xs-20, [dir=rtl] .layout-margin .flex-offset-gt-xs-20, [dir=rtl] .layout-margin .offset-gt-xs-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-xs-25, .flex-offset-gt-xs-25, .layout-margin .flex-offset-gt-xs-25, .layout-margin .offset-gt-xs-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-xs-25, [dir=rtl] .flex-offset-gt-xs-25, [dir=rtl] .layout-margin .flex-offset-gt-xs-25, [dir=rtl] .layout-margin .offset-gt-xs-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-xs-30, .flex-offset-gt-xs-30, .layout-margin .flex-offset-gt-xs-30, .layout-margin .offset-gt-xs-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-xs-30, [dir=rtl] .flex-offset-gt-xs-30, [dir=rtl] .layout-margin .flex-offset-gt-xs-30, [dir=rtl] .layout-margin .offset-gt-xs-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-xs-35, .flex-offset-gt-xs-35, .layout-margin .flex-offset-gt-xs-35, .layout-margin .offset-gt-xs-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-xs-35, [dir=rtl] .flex-offset-gt-xs-35, [dir=rtl] .layout-margin .flex-offset-gt-xs-35, [dir=rtl] .layout-margin .offset-gt-xs-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-xs-40, .flex-offset-gt-xs-40, .layout-margin .flex-offset-gt-xs-40, .layout-margin .offset-gt-xs-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-xs-40, [dir=rtl] .flex-offset-gt-xs-40, [dir=rtl] .layout-margin .flex-offset-gt-xs-40, [dir=rtl] .layout-margin .offset-gt-xs-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-xs-45, .flex-offset-gt-xs-45, .layout-margin .flex-offset-gt-xs-45, .layout-margin .offset-gt-xs-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-xs-45, [dir=rtl] .flex-offset-gt-xs-45, [dir=rtl] .layout-margin .flex-offset-gt-xs-45, [dir=rtl] .layout-margin .offset-gt-xs-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-xs-50, .flex-offset-gt-xs-50, .layout-margin .flex-offset-gt-xs-50, .layout-margin .offset-gt-xs-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-xs-50, [dir=rtl] .flex-offset-gt-xs-50, [dir=rtl] .layout-margin .flex-offset-gt-xs-50, [dir=rtl] .layout-margin .offset-gt-xs-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-xs-55, .flex-offset-gt-xs-55, .layout-margin .flex-offset-gt-xs-55, .layout-margin .offset-gt-xs-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-xs-55, [dir=rtl] .flex-offset-gt-xs-55, [dir=rtl] .layout-margin .flex-offset-gt-xs-55, [dir=rtl] .layout-margin .offset-gt-xs-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-xs-60, .flex-offset-gt-xs-60, .layout-margin .flex-offset-gt-xs-60, .layout-margin .offset-gt-xs-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-xs-60, [dir=rtl] .flex-offset-gt-xs-60, [dir=rtl] .layout-margin .flex-offset-gt-xs-60, [dir=rtl] .layout-margin .offset-gt-xs-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-xs-65, .flex-offset-gt-xs-65, .layout-margin .flex-offset-gt-xs-65, .layout-margin .offset-gt-xs-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-xs-65, [dir=rtl] .flex-offset-gt-xs-65, [dir=rtl] .layout-margin .flex-offset-gt-xs-65, [dir=rtl] .layout-margin .offset-gt-xs-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-xs-70, .flex-offset-gt-xs-70, .layout-margin .flex-offset-gt-xs-70, .layout-margin .offset-gt-xs-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-xs-70, [dir=rtl] .flex-offset-gt-xs-70, [dir=rtl] .layout-margin .flex-offset-gt-xs-70, [dir=rtl] .layout-margin .offset-gt-xs-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-xs-75, .flex-offset-gt-xs-75, .layout-margin .flex-offset-gt-xs-75, .layout-margin .offset-gt-xs-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-xs-75, [dir=rtl] .flex-offset-gt-xs-75, [dir=rtl] .layout-margin .flex-offset-gt-xs-75, [dir=rtl] .layout-margin .offset-gt-xs-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-xs-80, .flex-offset-gt-xs-80, .layout-margin .flex-offset-gt-xs-80, .layout-margin .offset-gt-xs-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-xs-80, [dir=rtl] .flex-offset-gt-xs-80, [dir=rtl] .layout-margin .flex-offset-gt-xs-80, [dir=rtl] .layout-margin .offset-gt-xs-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-xs-85, .flex-offset-gt-xs-85, .layout-margin .flex-offset-gt-xs-85, .layout-margin .offset-gt-xs-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-xs-85, [dir=rtl] .flex-offset-gt-xs-85, [dir=rtl] .layout-margin .flex-offset-gt-xs-85, [dir=rtl] .layout-margin .offset-gt-xs-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-xs-90, .flex-offset-gt-xs-90, .layout-margin .flex-offset-gt-xs-90, .layout-margin .offset-gt-xs-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-xs-90, [dir=rtl] .flex-offset-gt-xs-90, [dir=rtl] .layout-margin .flex-offset-gt-xs-90, [dir=rtl] .layout-margin .offset-gt-xs-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-xs-95, .flex-offset-gt-xs-95, .layout-margin .flex-offset-gt-xs-95, .layout-margin .offset-gt-xs-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-xs-95, [dir=rtl] .flex-offset-gt-xs-95, [dir=rtl] .layout-margin .flex-offset-gt-xs-95, [dir=rtl] .layout-margin .offset-gt-xs-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-xs-33, .flex-offset-gt-xs-33, .layout-margin .flex-offset-gt-xs-33, .layout-margin .offset-gt-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-xs-66, .flex-offset-gt-xs-66, .layout-margin .flex-offset-gt-xs-66, .layout-margin .offset-gt-xs-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-xs-66, [dir=rtl] .flex-offset-gt-xs-66, [dir=rtl] .layout-margin .flex-offset-gt-xs-66, [dir=rtl] .layout-margin .offset-gt-xs-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    justify-content: center; }
  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-xs {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-xs-column > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex {
    min-width: 0; }
  .layout-gt-xs-column > .flex-gt-xs-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex {
    min-height: 0; }
  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-xs-column {
    flex-direction: column; }
  .layout-gt-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) and (max-width: 959px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .flex-order-sm--20 {
    order: -20; }
  .flex-order-sm--19 {
    order: -19; }
  .flex-order-sm--18 {
    order: -18; }
  .flex-order-sm--17 {
    order: -17; }
  .flex-order-sm--16 {
    order: -16; }
  .flex-order-sm--15 {
    order: -15; }
  .flex-order-sm--14 {
    order: -14; }
  .flex-order-sm--13 {
    order: -13; }
  .flex-order-sm--12 {
    order: -12; }
  .flex-order-sm--11 {
    order: -11; }
  .flex-order-sm--10 {
    order: -10; }
  .flex-order-sm--9 {
    order: -9; }
  .flex-order-sm--8 {
    order: -8; }
  .flex-order-sm--7 {
    order: -7; }
  .flex-order-sm--6 {
    order: -6; }
  .flex-order-sm--5 {
    order: -5; }
  .flex-order-sm--4 {
    order: -4; }
  .flex-order-sm--3 {
    order: -3; }
  .flex-order-sm--2 {
    order: -2; }
  .flex-order-sm--1 {
    order: -1; }
  .flex-order-sm-0 {
    order: 0; }
  .flex-order-sm-1 {
    order: 1; }
  .flex-order-sm-2 {
    order: 2; }
  .flex-order-sm-3 {
    order: 3; }
  .flex-order-sm-4 {
    order: 4; }
  .flex-order-sm-5 {
    order: 5; }
  .flex-order-sm-6 {
    order: 6; }
  .flex-order-sm-7 {
    order: 7; }
  .flex-order-sm-8 {
    order: 8; }
  .flex-order-sm-9 {
    order: 9; }
  .flex-order-sm-10 {
    order: 10; }
  .flex-order-sm-11 {
    order: 11; }
  .flex-order-sm-12 {
    order: 12; }
  .flex-order-sm-13 {
    order: 13; }
  .flex-order-sm-14 {
    order: 14; }
  .flex-order-sm-15 {
    order: 15; }
  .flex-order-sm-16 {
    order: 16; }
  .flex-order-sm-17 {
    order: 17; }
  .flex-order-sm-18 {
    order: 18; }
  .flex-order-sm-19 {
    order: 19; }
  .flex-order-sm-20 {
    order: 20; }
  .offset-sm-0, .flex-offset-sm-0, .layout-margin .flex-offset-sm-0, .layout-margin .offset-sm-0 {
    margin-left: 0; }
    [dir=rtl] .offset-sm-0, [dir=rtl] .flex-offset-sm-0, [dir=rtl] .layout-margin .flex-offset-sm-0, [dir=rtl] .layout-margin .offset-sm-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-sm-5, .flex-offset-sm-5, .layout-margin .flex-offset-sm-5, .layout-margin .offset-sm-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-sm-5, [dir=rtl] .flex-offset-sm-5, [dir=rtl] .layout-margin .flex-offset-sm-5, [dir=rtl] .layout-margin .offset-sm-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-sm-10, .flex-offset-sm-10, .layout-margin .flex-offset-sm-10, .layout-margin .offset-sm-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-sm-10, [dir=rtl] .flex-offset-sm-10, [dir=rtl] .layout-margin .flex-offset-sm-10, [dir=rtl] .layout-margin .offset-sm-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-sm-15, .flex-offset-sm-15, .layout-margin .flex-offset-sm-15, .layout-margin .offset-sm-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-sm-15, [dir=rtl] .flex-offset-sm-15, [dir=rtl] .layout-margin .flex-offset-sm-15, [dir=rtl] .layout-margin .offset-sm-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-sm-20, .flex-offset-sm-20, .layout-margin .flex-offset-sm-20, .layout-margin .offset-sm-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-sm-20, [dir=rtl] .flex-offset-sm-20, [dir=rtl] .layout-margin .flex-offset-sm-20, [dir=rtl] .layout-margin .offset-sm-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-sm-25, .flex-offset-sm-25, .layout-margin .flex-offset-sm-25, .layout-margin .offset-sm-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-sm-25, [dir=rtl] .flex-offset-sm-25, [dir=rtl] .layout-margin .flex-offset-sm-25, [dir=rtl] .layout-margin .offset-sm-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-sm-30, .flex-offset-sm-30, .layout-margin .flex-offset-sm-30, .layout-margin .offset-sm-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-sm-30, [dir=rtl] .flex-offset-sm-30, [dir=rtl] .layout-margin .flex-offset-sm-30, [dir=rtl] .layout-margin .offset-sm-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-sm-35, .flex-offset-sm-35, .layout-margin .flex-offset-sm-35, .layout-margin .offset-sm-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-sm-35, [dir=rtl] .flex-offset-sm-35, [dir=rtl] .layout-margin .flex-offset-sm-35, [dir=rtl] .layout-margin .offset-sm-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-sm-40, .flex-offset-sm-40, .layout-margin .flex-offset-sm-40, .layout-margin .offset-sm-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-sm-40, [dir=rtl] .flex-offset-sm-40, [dir=rtl] .layout-margin .flex-offset-sm-40, [dir=rtl] .layout-margin .offset-sm-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-sm-45, .flex-offset-sm-45, .layout-margin .flex-offset-sm-45, .layout-margin .offset-sm-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-sm-45, [dir=rtl] .flex-offset-sm-45, [dir=rtl] .layout-margin .flex-offset-sm-45, [dir=rtl] .layout-margin .offset-sm-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-sm-50, .flex-offset-sm-50, .layout-margin .flex-offset-sm-50, .layout-margin .offset-sm-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-sm-50, [dir=rtl] .flex-offset-sm-50, [dir=rtl] .layout-margin .flex-offset-sm-50, [dir=rtl] .layout-margin .offset-sm-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-sm-55, .flex-offset-sm-55, .layout-margin .flex-offset-sm-55, .layout-margin .offset-sm-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-sm-55, [dir=rtl] .flex-offset-sm-55, [dir=rtl] .layout-margin .flex-offset-sm-55, [dir=rtl] .layout-margin .offset-sm-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-sm-60, .flex-offset-sm-60, .layout-margin .flex-offset-sm-60, .layout-margin .offset-sm-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-sm-60, [dir=rtl] .flex-offset-sm-60, [dir=rtl] .layout-margin .flex-offset-sm-60, [dir=rtl] .layout-margin .offset-sm-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-sm-65, .flex-offset-sm-65, .layout-margin .flex-offset-sm-65, .layout-margin .offset-sm-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-sm-65, [dir=rtl] .flex-offset-sm-65, [dir=rtl] .layout-margin .flex-offset-sm-65, [dir=rtl] .layout-margin .offset-sm-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-sm-70, .flex-offset-sm-70, .layout-margin .flex-offset-sm-70, .layout-margin .offset-sm-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-sm-70, [dir=rtl] .flex-offset-sm-70, [dir=rtl] .layout-margin .flex-offset-sm-70, [dir=rtl] .layout-margin .offset-sm-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-sm-75, .flex-offset-sm-75, .layout-margin .flex-offset-sm-75, .layout-margin .offset-sm-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-sm-75, [dir=rtl] .flex-offset-sm-75, [dir=rtl] .layout-margin .flex-offset-sm-75, [dir=rtl] .layout-margin .offset-sm-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-sm-80, .flex-offset-sm-80, .layout-margin .flex-offset-sm-80, .layout-margin .offset-sm-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-sm-80, [dir=rtl] .flex-offset-sm-80, [dir=rtl] .layout-margin .flex-offset-sm-80, [dir=rtl] .layout-margin .offset-sm-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-sm-85, .flex-offset-sm-85, .layout-margin .flex-offset-sm-85, .layout-margin .offset-sm-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-sm-85, [dir=rtl] .flex-offset-sm-85, [dir=rtl] .layout-margin .flex-offset-sm-85, [dir=rtl] .layout-margin .offset-sm-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-sm-90, .flex-offset-sm-90, .layout-margin .flex-offset-sm-90, .layout-margin .offset-sm-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-sm-90, [dir=rtl] .flex-offset-sm-90, [dir=rtl] .layout-margin .flex-offset-sm-90, [dir=rtl] .layout-margin .offset-sm-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-sm-95, .flex-offset-sm-95, .layout-margin .flex-offset-sm-95, .layout-margin .offset-sm-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-sm-95, [dir=rtl] .flex-offset-sm-95, [dir=rtl] .layout-margin .flex-offset-sm-95, [dir=rtl] .layout-margin .offset-sm-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-sm-33, .flex-offset-sm-33, .layout-margin .flex-offset-sm-33, .layout-margin .offset-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-sm-66, .flex-offset-sm-66, .layout-margin .flex-offset-sm-66, .layout-margin .offset-sm-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-sm-66, [dir=rtl] .flex-offset-sm-66, [dir=rtl] .layout-margin .flex-offset-sm-66, [dir=rtl] .layout-margin .offset-sm-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-sm,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    justify-content: center; }
  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-sm {
    flex: 1;
    box-sizing: border-box; }
  .flex-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-sm-column > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex {
    min-width: 0; }
  .layout-sm-column > .flex-sm-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-sm-column > .flex {
    min-height: 0; }
  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-sm-column {
    flex-direction: column; }
  .layout-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) {
  .flex-order-gt-sm--20 {
    order: -20; }
  .flex-order-gt-sm--19 {
    order: -19; }
  .flex-order-gt-sm--18 {
    order: -18; }
  .flex-order-gt-sm--17 {
    order: -17; }
  .flex-order-gt-sm--16 {
    order: -16; }
  .flex-order-gt-sm--15 {
    order: -15; }
  .flex-order-gt-sm--14 {
    order: -14; }
  .flex-order-gt-sm--13 {
    order: -13; }
  .flex-order-gt-sm--12 {
    order: -12; }
  .flex-order-gt-sm--11 {
    order: -11; }
  .flex-order-gt-sm--10 {
    order: -10; }
  .flex-order-gt-sm--9 {
    order: -9; }
  .flex-order-gt-sm--8 {
    order: -8; }
  .flex-order-gt-sm--7 {
    order: -7; }
  .flex-order-gt-sm--6 {
    order: -6; }
  .flex-order-gt-sm--5 {
    order: -5; }
  .flex-order-gt-sm--4 {
    order: -4; }
  .flex-order-gt-sm--3 {
    order: -3; }
  .flex-order-gt-sm--2 {
    order: -2; }
  .flex-order-gt-sm--1 {
    order: -1; }
  .flex-order-gt-sm-0 {
    order: 0; }
  .flex-order-gt-sm-1 {
    order: 1; }
  .flex-order-gt-sm-2 {
    order: 2; }
  .flex-order-gt-sm-3 {
    order: 3; }
  .flex-order-gt-sm-4 {
    order: 4; }
  .flex-order-gt-sm-5 {
    order: 5; }
  .flex-order-gt-sm-6 {
    order: 6; }
  .flex-order-gt-sm-7 {
    order: 7; }
  .flex-order-gt-sm-8 {
    order: 8; }
  .flex-order-gt-sm-9 {
    order: 9; }
  .flex-order-gt-sm-10 {
    order: 10; }
  .flex-order-gt-sm-11 {
    order: 11; }
  .flex-order-gt-sm-12 {
    order: 12; }
  .flex-order-gt-sm-13 {
    order: 13; }
  .flex-order-gt-sm-14 {
    order: 14; }
  .flex-order-gt-sm-15 {
    order: 15; }
  .flex-order-gt-sm-16 {
    order: 16; }
  .flex-order-gt-sm-17 {
    order: 17; }
  .flex-order-gt-sm-18 {
    order: 18; }
  .flex-order-gt-sm-19 {
    order: 19; }
  .flex-order-gt-sm-20 {
    order: 20; }
  .offset-gt-sm-0, .flex-offset-gt-sm-0, .layout-margin .flex-offset-gt-sm-0, .layout-margin .offset-gt-sm-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-sm-0, [dir=rtl] .flex-offset-gt-sm-0, [dir=rtl] .layout-margin .flex-offset-gt-sm-0, [dir=rtl] .layout-margin .offset-gt-sm-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-sm-5, .flex-offset-gt-sm-5, .layout-margin .flex-offset-gt-sm-5, .layout-margin .offset-gt-sm-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-sm-5, [dir=rtl] .flex-offset-gt-sm-5, [dir=rtl] .layout-margin .flex-offset-gt-sm-5, [dir=rtl] .layout-margin .offset-gt-sm-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-sm-10, .flex-offset-gt-sm-10, .layout-margin .flex-offset-gt-sm-10, .layout-margin .offset-gt-sm-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-sm-10, [dir=rtl] .flex-offset-gt-sm-10, [dir=rtl] .layout-margin .flex-offset-gt-sm-10, [dir=rtl] .layout-margin .offset-gt-sm-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-sm-15, .flex-offset-gt-sm-15, .layout-margin .flex-offset-gt-sm-15, .layout-margin .offset-gt-sm-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-sm-15, [dir=rtl] .flex-offset-gt-sm-15, [dir=rtl] .layout-margin .flex-offset-gt-sm-15, [dir=rtl] .layout-margin .offset-gt-sm-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-sm-20, .flex-offset-gt-sm-20, .layout-margin .flex-offset-gt-sm-20, .layout-margin .offset-gt-sm-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-sm-20, [dir=rtl] .flex-offset-gt-sm-20, [dir=rtl] .layout-margin .flex-offset-gt-sm-20, [dir=rtl] .layout-margin .offset-gt-sm-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-sm-25, .flex-offset-gt-sm-25, .layout-margin .flex-offset-gt-sm-25, .layout-margin .offset-gt-sm-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-sm-25, [dir=rtl] .flex-offset-gt-sm-25, [dir=rtl] .layout-margin .flex-offset-gt-sm-25, [dir=rtl] .layout-margin .offset-gt-sm-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-sm-30, .flex-offset-gt-sm-30, .layout-margin .flex-offset-gt-sm-30, .layout-margin .offset-gt-sm-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-sm-30, [dir=rtl] .flex-offset-gt-sm-30, [dir=rtl] .layout-margin .flex-offset-gt-sm-30, [dir=rtl] .layout-margin .offset-gt-sm-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-sm-35, .flex-offset-gt-sm-35, .layout-margin .flex-offset-gt-sm-35, .layout-margin .offset-gt-sm-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-sm-35, [dir=rtl] .flex-offset-gt-sm-35, [dir=rtl] .layout-margin .flex-offset-gt-sm-35, [dir=rtl] .layout-margin .offset-gt-sm-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-sm-40, .flex-offset-gt-sm-40, .layout-margin .flex-offset-gt-sm-40, .layout-margin .offset-gt-sm-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-sm-40, [dir=rtl] .flex-offset-gt-sm-40, [dir=rtl] .layout-margin .flex-offset-gt-sm-40, [dir=rtl] .layout-margin .offset-gt-sm-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-sm-45, .flex-offset-gt-sm-45, .layout-margin .flex-offset-gt-sm-45, .layout-margin .offset-gt-sm-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-sm-45, [dir=rtl] .flex-offset-gt-sm-45, [dir=rtl] .layout-margin .flex-offset-gt-sm-45, [dir=rtl] .layout-margin .offset-gt-sm-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-sm-50, .flex-offset-gt-sm-50, .layout-margin .flex-offset-gt-sm-50, .layout-margin .offset-gt-sm-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-sm-50, [dir=rtl] .flex-offset-gt-sm-50, [dir=rtl] .layout-margin .flex-offset-gt-sm-50, [dir=rtl] .layout-margin .offset-gt-sm-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-sm-55, .flex-offset-gt-sm-55, .layout-margin .flex-offset-gt-sm-55, .layout-margin .offset-gt-sm-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-sm-55, [dir=rtl] .flex-offset-gt-sm-55, [dir=rtl] .layout-margin .flex-offset-gt-sm-55, [dir=rtl] .layout-margin .offset-gt-sm-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-sm-60, .flex-offset-gt-sm-60, .layout-margin .flex-offset-gt-sm-60, .layout-margin .offset-gt-sm-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-sm-60, [dir=rtl] .flex-offset-gt-sm-60, [dir=rtl] .layout-margin .flex-offset-gt-sm-60, [dir=rtl] .layout-margin .offset-gt-sm-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-sm-65, .flex-offset-gt-sm-65, .layout-margin .flex-offset-gt-sm-65, .layout-margin .offset-gt-sm-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-sm-65, [dir=rtl] .flex-offset-gt-sm-65, [dir=rtl] .layout-margin .flex-offset-gt-sm-65, [dir=rtl] .layout-margin .offset-gt-sm-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-sm-70, .flex-offset-gt-sm-70, .layout-margin .flex-offset-gt-sm-70, .layout-margin .offset-gt-sm-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-sm-70, [dir=rtl] .flex-offset-gt-sm-70, [dir=rtl] .layout-margin .flex-offset-gt-sm-70, [dir=rtl] .layout-margin .offset-gt-sm-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-sm-75, .flex-offset-gt-sm-75, .layout-margin .flex-offset-gt-sm-75, .layout-margin .offset-gt-sm-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-sm-75, [dir=rtl] .flex-offset-gt-sm-75, [dir=rtl] .layout-margin .flex-offset-gt-sm-75, [dir=rtl] .layout-margin .offset-gt-sm-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-sm-80, .flex-offset-gt-sm-80, .layout-margin .flex-offset-gt-sm-80, .layout-margin .offset-gt-sm-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-sm-80, [dir=rtl] .flex-offset-gt-sm-80, [dir=rtl] .layout-margin .flex-offset-gt-sm-80, [dir=rtl] .layout-margin .offset-gt-sm-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-sm-85, .flex-offset-gt-sm-85, .layout-margin .flex-offset-gt-sm-85, .layout-margin .offset-gt-sm-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-sm-85, [dir=rtl] .flex-offset-gt-sm-85, [dir=rtl] .layout-margin .flex-offset-gt-sm-85, [dir=rtl] .layout-margin .offset-gt-sm-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-sm-90, .flex-offset-gt-sm-90, .layout-margin .flex-offset-gt-sm-90, .layout-margin .offset-gt-sm-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-sm-90, [dir=rtl] .flex-offset-gt-sm-90, [dir=rtl] .layout-margin .flex-offset-gt-sm-90, [dir=rtl] .layout-margin .offset-gt-sm-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-sm-95, .flex-offset-gt-sm-95, .layout-margin .flex-offset-gt-sm-95, .layout-margin .offset-gt-sm-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-sm-95, [dir=rtl] .flex-offset-gt-sm-95, [dir=rtl] .layout-margin .flex-offset-gt-sm-95, [dir=rtl] .layout-margin .offset-gt-sm-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-sm-33, .flex-offset-gt-sm-33, .layout-margin .flex-offset-gt-sm-33, .layout-margin .offset-gt-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-sm-66, .flex-offset-gt-sm-66, .layout-margin .flex-offset-gt-sm-66, .layout-margin .offset-gt-sm-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-sm-66, [dir=rtl] .flex-offset-gt-sm-66, [dir=rtl] .layout-margin .flex-offset-gt-sm-66, [dir=rtl] .layout-margin .offset-gt-sm-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    justify-content: center; }
  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-sm {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-sm-column > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex {
    min-width: 0; }
  .layout-gt-sm-column > .flex-gt-sm-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex {
    min-height: 0; }
  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-sm-column {
    flex-direction: column; }
  .layout-gt-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none; }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-md--20 {
    order: -20; }
  .flex-order-md--19 {
    order: -19; }
  .flex-order-md--18 {
    order: -18; }
  .flex-order-md--17 {
    order: -17; }
  .flex-order-md--16 {
    order: -16; }
  .flex-order-md--15 {
    order: -15; }
  .flex-order-md--14 {
    order: -14; }
  .flex-order-md--13 {
    order: -13; }
  .flex-order-md--12 {
    order: -12; }
  .flex-order-md--11 {
    order: -11; }
  .flex-order-md--10 {
    order: -10; }
  .flex-order-md--9 {
    order: -9; }
  .flex-order-md--8 {
    order: -8; }
  .flex-order-md--7 {
    order: -7; }
  .flex-order-md--6 {
    order: -6; }
  .flex-order-md--5 {
    order: -5; }
  .flex-order-md--4 {
    order: -4; }
  .flex-order-md--3 {
    order: -3; }
  .flex-order-md--2 {
    order: -2; }
  .flex-order-md--1 {
    order: -1; }
  .flex-order-md-0 {
    order: 0; }
  .flex-order-md-1 {
    order: 1; }
  .flex-order-md-2 {
    order: 2; }
  .flex-order-md-3 {
    order: 3; }
  .flex-order-md-4 {
    order: 4; }
  .flex-order-md-5 {
    order: 5; }
  .flex-order-md-6 {
    order: 6; }
  .flex-order-md-7 {
    order: 7; }
  .flex-order-md-8 {
    order: 8; }
  .flex-order-md-9 {
    order: 9; }
  .flex-order-md-10 {
    order: 10; }
  .flex-order-md-11 {
    order: 11; }
  .flex-order-md-12 {
    order: 12; }
  .flex-order-md-13 {
    order: 13; }
  .flex-order-md-14 {
    order: 14; }
  .flex-order-md-15 {
    order: 15; }
  .flex-order-md-16 {
    order: 16; }
  .flex-order-md-17 {
    order: 17; }
  .flex-order-md-18 {
    order: 18; }
  .flex-order-md-19 {
    order: 19; }
  .flex-order-md-20 {
    order: 20; }
  .offset-md-0, .flex-offset-md-0, .layout-margin .flex-offset-md-0, .layout-margin .offset-md-0 {
    margin-left: 0; }
    [dir=rtl] .offset-md-0, [dir=rtl] .flex-offset-md-0, [dir=rtl] .layout-margin .flex-offset-md-0, [dir=rtl] .layout-margin .offset-md-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-md-5, .flex-offset-md-5, .layout-margin .flex-offset-md-5, .layout-margin .offset-md-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-md-5, [dir=rtl] .flex-offset-md-5, [dir=rtl] .layout-margin .flex-offset-md-5, [dir=rtl] .layout-margin .offset-md-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-md-10, .flex-offset-md-10, .layout-margin .flex-offset-md-10, .layout-margin .offset-md-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-md-10, [dir=rtl] .flex-offset-md-10, [dir=rtl] .layout-margin .flex-offset-md-10, [dir=rtl] .layout-margin .offset-md-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-md-15, .flex-offset-md-15, .layout-margin .flex-offset-md-15, .layout-margin .offset-md-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-md-15, [dir=rtl] .flex-offset-md-15, [dir=rtl] .layout-margin .flex-offset-md-15, [dir=rtl] .layout-margin .offset-md-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-md-20, .flex-offset-md-20, .layout-margin .flex-offset-md-20, .layout-margin .offset-md-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-md-20, [dir=rtl] .flex-offset-md-20, [dir=rtl] .layout-margin .flex-offset-md-20, [dir=rtl] .layout-margin .offset-md-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-md-25, .flex-offset-md-25, .layout-margin .flex-offset-md-25, .layout-margin .offset-md-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-md-25, [dir=rtl] .flex-offset-md-25, [dir=rtl] .layout-margin .flex-offset-md-25, [dir=rtl] .layout-margin .offset-md-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-md-30, .flex-offset-md-30, .layout-margin .flex-offset-md-30, .layout-margin .offset-md-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-md-30, [dir=rtl] .flex-offset-md-30, [dir=rtl] .layout-margin .flex-offset-md-30, [dir=rtl] .layout-margin .offset-md-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-md-35, .flex-offset-md-35, .layout-margin .flex-offset-md-35, .layout-margin .offset-md-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-md-35, [dir=rtl] .flex-offset-md-35, [dir=rtl] .layout-margin .flex-offset-md-35, [dir=rtl] .layout-margin .offset-md-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-md-40, .flex-offset-md-40, .layout-margin .flex-offset-md-40, .layout-margin .offset-md-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-md-40, [dir=rtl] .flex-offset-md-40, [dir=rtl] .layout-margin .flex-offset-md-40, [dir=rtl] .layout-margin .offset-md-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-md-45, .flex-offset-md-45, .layout-margin .flex-offset-md-45, .layout-margin .offset-md-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-md-45, [dir=rtl] .flex-offset-md-45, [dir=rtl] .layout-margin .flex-offset-md-45, [dir=rtl] .layout-margin .offset-md-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-md-50, .flex-offset-md-50, .layout-margin .flex-offset-md-50, .layout-margin .offset-md-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-md-50, [dir=rtl] .flex-offset-md-50, [dir=rtl] .layout-margin .flex-offset-md-50, [dir=rtl] .layout-margin .offset-md-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-md-55, .flex-offset-md-55, .layout-margin .flex-offset-md-55, .layout-margin .offset-md-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-md-55, [dir=rtl] .flex-offset-md-55, [dir=rtl] .layout-margin .flex-offset-md-55, [dir=rtl] .layout-margin .offset-md-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-md-60, .flex-offset-md-60, .layout-margin .flex-offset-md-60, .layout-margin .offset-md-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-md-60, [dir=rtl] .flex-offset-md-60, [dir=rtl] .layout-margin .flex-offset-md-60, [dir=rtl] .layout-margin .offset-md-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-md-65, .flex-offset-md-65, .layout-margin .flex-offset-md-65, .layout-margin .offset-md-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-md-65, [dir=rtl] .flex-offset-md-65, [dir=rtl] .layout-margin .flex-offset-md-65, [dir=rtl] .layout-margin .offset-md-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-md-70, .flex-offset-md-70, .layout-margin .flex-offset-md-70, .layout-margin .offset-md-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-md-70, [dir=rtl] .flex-offset-md-70, [dir=rtl] .layout-margin .flex-offset-md-70, [dir=rtl] .layout-margin .offset-md-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-md-75, .flex-offset-md-75, .layout-margin .flex-offset-md-75, .layout-margin .offset-md-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-md-75, [dir=rtl] .flex-offset-md-75, [dir=rtl] .layout-margin .flex-offset-md-75, [dir=rtl] .layout-margin .offset-md-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-md-80, .flex-offset-md-80, .layout-margin .flex-offset-md-80, .layout-margin .offset-md-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-md-80, [dir=rtl] .flex-offset-md-80, [dir=rtl] .layout-margin .flex-offset-md-80, [dir=rtl] .layout-margin .offset-md-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-md-85, .flex-offset-md-85, .layout-margin .flex-offset-md-85, .layout-margin .offset-md-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-md-85, [dir=rtl] .flex-offset-md-85, [dir=rtl] .layout-margin .flex-offset-md-85, [dir=rtl] .layout-margin .offset-md-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-md-90, .flex-offset-md-90, .layout-margin .flex-offset-md-90, .layout-margin .offset-md-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-md-90, [dir=rtl] .flex-offset-md-90, [dir=rtl] .layout-margin .flex-offset-md-90, [dir=rtl] .layout-margin .offset-md-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-md-95, .flex-offset-md-95, .layout-margin .flex-offset-md-95, .layout-margin .offset-md-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-md-95, [dir=rtl] .flex-offset-md-95, [dir=rtl] .layout-margin .flex-offset-md-95, [dir=rtl] .layout-margin .offset-md-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-md-33, .flex-offset-md-33, .layout-margin .flex-offset-md-33, .layout-margin .offset-md-33 {
    margin-left: calc(100% / 3); }
  .offset-md-66, .flex-offset-md-66, .layout-margin .flex-offset-md-66, .layout-margin .offset-md-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-md-66, [dir=rtl] .flex-offset-md-66, [dir=rtl] .layout-margin .flex-offset-md-66, [dir=rtl] .layout-margin .offset-md-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-md,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    justify-content: center; }
  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-md {
    flex: 1;
    box-sizing: border-box; }
  .flex-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-md-column > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex {
    min-width: 0; }
  .layout-md-column > .flex-md-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-md-column > .flex {
    min-height: 0; }
  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-md-column {
    flex-direction: column; }
  .layout-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    order: -20; }
  .flex-order-gt-md--19 {
    order: -19; }
  .flex-order-gt-md--18 {
    order: -18; }
  .flex-order-gt-md--17 {
    order: -17; }
  .flex-order-gt-md--16 {
    order: -16; }
  .flex-order-gt-md--15 {
    order: -15; }
  .flex-order-gt-md--14 {
    order: -14; }
  .flex-order-gt-md--13 {
    order: -13; }
  .flex-order-gt-md--12 {
    order: -12; }
  .flex-order-gt-md--11 {
    order: -11; }
  .flex-order-gt-md--10 {
    order: -10; }
  .flex-order-gt-md--9 {
    order: -9; }
  .flex-order-gt-md--8 {
    order: -8; }
  .flex-order-gt-md--7 {
    order: -7; }
  .flex-order-gt-md--6 {
    order: -6; }
  .flex-order-gt-md--5 {
    order: -5; }
  .flex-order-gt-md--4 {
    order: -4; }
  .flex-order-gt-md--3 {
    order: -3; }
  .flex-order-gt-md--2 {
    order: -2; }
  .flex-order-gt-md--1 {
    order: -1; }
  .flex-order-gt-md-0 {
    order: 0; }
  .flex-order-gt-md-1 {
    order: 1; }
  .flex-order-gt-md-2 {
    order: 2; }
  .flex-order-gt-md-3 {
    order: 3; }
  .flex-order-gt-md-4 {
    order: 4; }
  .flex-order-gt-md-5 {
    order: 5; }
  .flex-order-gt-md-6 {
    order: 6; }
  .flex-order-gt-md-7 {
    order: 7; }
  .flex-order-gt-md-8 {
    order: 8; }
  .flex-order-gt-md-9 {
    order: 9; }
  .flex-order-gt-md-10 {
    order: 10; }
  .flex-order-gt-md-11 {
    order: 11; }
  .flex-order-gt-md-12 {
    order: 12; }
  .flex-order-gt-md-13 {
    order: 13; }
  .flex-order-gt-md-14 {
    order: 14; }
  .flex-order-gt-md-15 {
    order: 15; }
  .flex-order-gt-md-16 {
    order: 16; }
  .flex-order-gt-md-17 {
    order: 17; }
  .flex-order-gt-md-18 {
    order: 18; }
  .flex-order-gt-md-19 {
    order: 19; }
  .flex-order-gt-md-20 {
    order: 20; }
  .offset-gt-md-0, .flex-offset-gt-md-0, .layout-margin .flex-offset-gt-md-0, .layout-margin .offset-gt-md-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-md-0, [dir=rtl] .flex-offset-gt-md-0, [dir=rtl] .layout-margin .flex-offset-gt-md-0, [dir=rtl] .layout-margin .offset-gt-md-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-md-5, .flex-offset-gt-md-5, .layout-margin .flex-offset-gt-md-5, .layout-margin .offset-gt-md-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-md-5, [dir=rtl] .flex-offset-gt-md-5, [dir=rtl] .layout-margin .flex-offset-gt-md-5, [dir=rtl] .layout-margin .offset-gt-md-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-md-10, .flex-offset-gt-md-10, .layout-margin .flex-offset-gt-md-10, .layout-margin .offset-gt-md-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-md-10, [dir=rtl] .flex-offset-gt-md-10, [dir=rtl] .layout-margin .flex-offset-gt-md-10, [dir=rtl] .layout-margin .offset-gt-md-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-md-15, .flex-offset-gt-md-15, .layout-margin .flex-offset-gt-md-15, .layout-margin .offset-gt-md-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-md-15, [dir=rtl] .flex-offset-gt-md-15, [dir=rtl] .layout-margin .flex-offset-gt-md-15, [dir=rtl] .layout-margin .offset-gt-md-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-md-20, .flex-offset-gt-md-20, .layout-margin .flex-offset-gt-md-20, .layout-margin .offset-gt-md-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-md-20, [dir=rtl] .flex-offset-gt-md-20, [dir=rtl] .layout-margin .flex-offset-gt-md-20, [dir=rtl] .layout-margin .offset-gt-md-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-md-25, .flex-offset-gt-md-25, .layout-margin .flex-offset-gt-md-25, .layout-margin .offset-gt-md-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-md-25, [dir=rtl] .flex-offset-gt-md-25, [dir=rtl] .layout-margin .flex-offset-gt-md-25, [dir=rtl] .layout-margin .offset-gt-md-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-md-30, .flex-offset-gt-md-30, .layout-margin .flex-offset-gt-md-30, .layout-margin .offset-gt-md-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-md-30, [dir=rtl] .flex-offset-gt-md-30, [dir=rtl] .layout-margin .flex-offset-gt-md-30, [dir=rtl] .layout-margin .offset-gt-md-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-md-35, .flex-offset-gt-md-35, .layout-margin .flex-offset-gt-md-35, .layout-margin .offset-gt-md-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-md-35, [dir=rtl] .flex-offset-gt-md-35, [dir=rtl] .layout-margin .flex-offset-gt-md-35, [dir=rtl] .layout-margin .offset-gt-md-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-md-40, .flex-offset-gt-md-40, .layout-margin .flex-offset-gt-md-40, .layout-margin .offset-gt-md-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-md-40, [dir=rtl] .flex-offset-gt-md-40, [dir=rtl] .layout-margin .flex-offset-gt-md-40, [dir=rtl] .layout-margin .offset-gt-md-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-md-45, .flex-offset-gt-md-45, .layout-margin .flex-offset-gt-md-45, .layout-margin .offset-gt-md-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-md-45, [dir=rtl] .flex-offset-gt-md-45, [dir=rtl] .layout-margin .flex-offset-gt-md-45, [dir=rtl] .layout-margin .offset-gt-md-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-md-50, .flex-offset-gt-md-50, .layout-margin .flex-offset-gt-md-50, .layout-margin .offset-gt-md-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-md-50, [dir=rtl] .flex-offset-gt-md-50, [dir=rtl] .layout-margin .flex-offset-gt-md-50, [dir=rtl] .layout-margin .offset-gt-md-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-md-55, .flex-offset-gt-md-55, .layout-margin .flex-offset-gt-md-55, .layout-margin .offset-gt-md-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-md-55, [dir=rtl] .flex-offset-gt-md-55, [dir=rtl] .layout-margin .flex-offset-gt-md-55, [dir=rtl] .layout-margin .offset-gt-md-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-md-60, .flex-offset-gt-md-60, .layout-margin .flex-offset-gt-md-60, .layout-margin .offset-gt-md-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-md-60, [dir=rtl] .flex-offset-gt-md-60, [dir=rtl] .layout-margin .flex-offset-gt-md-60, [dir=rtl] .layout-margin .offset-gt-md-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-md-65, .flex-offset-gt-md-65, .layout-margin .flex-offset-gt-md-65, .layout-margin .offset-gt-md-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-md-65, [dir=rtl] .flex-offset-gt-md-65, [dir=rtl] .layout-margin .flex-offset-gt-md-65, [dir=rtl] .layout-margin .offset-gt-md-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-md-70, .flex-offset-gt-md-70, .layout-margin .flex-offset-gt-md-70, .layout-margin .offset-gt-md-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-md-70, [dir=rtl] .flex-offset-gt-md-70, [dir=rtl] .layout-margin .flex-offset-gt-md-70, [dir=rtl] .layout-margin .offset-gt-md-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-md-75, .flex-offset-gt-md-75, .layout-margin .flex-offset-gt-md-75, .layout-margin .offset-gt-md-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-md-75, [dir=rtl] .flex-offset-gt-md-75, [dir=rtl] .layout-margin .flex-offset-gt-md-75, [dir=rtl] .layout-margin .offset-gt-md-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-md-80, .flex-offset-gt-md-80, .layout-margin .flex-offset-gt-md-80, .layout-margin .offset-gt-md-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-md-80, [dir=rtl] .flex-offset-gt-md-80, [dir=rtl] .layout-margin .flex-offset-gt-md-80, [dir=rtl] .layout-margin .offset-gt-md-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-md-85, .flex-offset-gt-md-85, .layout-margin .flex-offset-gt-md-85, .layout-margin .offset-gt-md-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-md-85, [dir=rtl] .flex-offset-gt-md-85, [dir=rtl] .layout-margin .flex-offset-gt-md-85, [dir=rtl] .layout-margin .offset-gt-md-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-md-90, .flex-offset-gt-md-90, .layout-margin .flex-offset-gt-md-90, .layout-margin .offset-gt-md-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-md-90, [dir=rtl] .flex-offset-gt-md-90, [dir=rtl] .layout-margin .flex-offset-gt-md-90, [dir=rtl] .layout-margin .offset-gt-md-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-md-95, .flex-offset-gt-md-95, .layout-margin .flex-offset-gt-md-95, .layout-margin .offset-gt-md-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-md-95, [dir=rtl] .flex-offset-gt-md-95, [dir=rtl] .layout-margin .flex-offset-gt-md-95, [dir=rtl] .layout-margin .offset-gt-md-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-md-33, .flex-offset-gt-md-33, .layout-margin .flex-offset-gt-md-33, .layout-margin .offset-gt-md-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-md-66, .flex-offset-gt-md-66, .layout-margin .flex-offset-gt-md-66, .layout-margin .offset-gt-md-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-md-66, [dir=rtl] .flex-offset-gt-md-66, [dir=rtl] .layout-margin .flex-offset-gt-md-66, [dir=rtl] .layout-margin .offset-gt-md-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    justify-content: center; }
  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-md {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-md-column > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex {
    min-width: 0; }
  .layout-gt-md-column > .flex-gt-md-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex {
    min-height: 0; }
  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-md-column {
    flex-direction: column; }
  .layout-gt-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none; }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-lg--20 {
    order: -20; }
  .flex-order-lg--19 {
    order: -19; }
  .flex-order-lg--18 {
    order: -18; }
  .flex-order-lg--17 {
    order: -17; }
  .flex-order-lg--16 {
    order: -16; }
  .flex-order-lg--15 {
    order: -15; }
  .flex-order-lg--14 {
    order: -14; }
  .flex-order-lg--13 {
    order: -13; }
  .flex-order-lg--12 {
    order: -12; }
  .flex-order-lg--11 {
    order: -11; }
  .flex-order-lg--10 {
    order: -10; }
  .flex-order-lg--9 {
    order: -9; }
  .flex-order-lg--8 {
    order: -8; }
  .flex-order-lg--7 {
    order: -7; }
  .flex-order-lg--6 {
    order: -6; }
  .flex-order-lg--5 {
    order: -5; }
  .flex-order-lg--4 {
    order: -4; }
  .flex-order-lg--3 {
    order: -3; }
  .flex-order-lg--2 {
    order: -2; }
  .flex-order-lg--1 {
    order: -1; }
  .flex-order-lg-0 {
    order: 0; }
  .flex-order-lg-1 {
    order: 1; }
  .flex-order-lg-2 {
    order: 2; }
  .flex-order-lg-3 {
    order: 3; }
  .flex-order-lg-4 {
    order: 4; }
  .flex-order-lg-5 {
    order: 5; }
  .flex-order-lg-6 {
    order: 6; }
  .flex-order-lg-7 {
    order: 7; }
  .flex-order-lg-8 {
    order: 8; }
  .flex-order-lg-9 {
    order: 9; }
  .flex-order-lg-10 {
    order: 10; }
  .flex-order-lg-11 {
    order: 11; }
  .flex-order-lg-12 {
    order: 12; }
  .flex-order-lg-13 {
    order: 13; }
  .flex-order-lg-14 {
    order: 14; }
  .flex-order-lg-15 {
    order: 15; }
  .flex-order-lg-16 {
    order: 16; }
  .flex-order-lg-17 {
    order: 17; }
  .flex-order-lg-18 {
    order: 18; }
  .flex-order-lg-19 {
    order: 19; }
  .flex-order-lg-20 {
    order: 20; }
  .offset-lg-0, .flex-offset-lg-0, .layout-margin .flex-offset-lg-0, .layout-margin .offset-lg-0 {
    margin-left: 0; }
    [dir=rtl] .offset-lg-0, [dir=rtl] .flex-offset-lg-0, [dir=rtl] .layout-margin .flex-offset-lg-0, [dir=rtl] .layout-margin .offset-lg-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-lg-5, .flex-offset-lg-5, .layout-margin .flex-offset-lg-5, .layout-margin .offset-lg-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-lg-5, [dir=rtl] .flex-offset-lg-5, [dir=rtl] .layout-margin .flex-offset-lg-5, [dir=rtl] .layout-margin .offset-lg-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-lg-10, .flex-offset-lg-10, .layout-margin .flex-offset-lg-10, .layout-margin .offset-lg-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-lg-10, [dir=rtl] .flex-offset-lg-10, [dir=rtl] .layout-margin .flex-offset-lg-10, [dir=rtl] .layout-margin .offset-lg-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-lg-15, .flex-offset-lg-15, .layout-margin .flex-offset-lg-15, .layout-margin .offset-lg-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-lg-15, [dir=rtl] .flex-offset-lg-15, [dir=rtl] .layout-margin .flex-offset-lg-15, [dir=rtl] .layout-margin .offset-lg-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-lg-20, .flex-offset-lg-20, .layout-margin .flex-offset-lg-20, .layout-margin .offset-lg-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-lg-20, [dir=rtl] .flex-offset-lg-20, [dir=rtl] .layout-margin .flex-offset-lg-20, [dir=rtl] .layout-margin .offset-lg-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-lg-25, .flex-offset-lg-25, .layout-margin .flex-offset-lg-25, .layout-margin .offset-lg-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-lg-25, [dir=rtl] .flex-offset-lg-25, [dir=rtl] .layout-margin .flex-offset-lg-25, [dir=rtl] .layout-margin .offset-lg-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-lg-30, .flex-offset-lg-30, .layout-margin .flex-offset-lg-30, .layout-margin .offset-lg-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-lg-30, [dir=rtl] .flex-offset-lg-30, [dir=rtl] .layout-margin .flex-offset-lg-30, [dir=rtl] .layout-margin .offset-lg-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-lg-35, .flex-offset-lg-35, .layout-margin .flex-offset-lg-35, .layout-margin .offset-lg-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-lg-35, [dir=rtl] .flex-offset-lg-35, [dir=rtl] .layout-margin .flex-offset-lg-35, [dir=rtl] .layout-margin .offset-lg-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-lg-40, .flex-offset-lg-40, .layout-margin .flex-offset-lg-40, .layout-margin .offset-lg-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-lg-40, [dir=rtl] .flex-offset-lg-40, [dir=rtl] .layout-margin .flex-offset-lg-40, [dir=rtl] .layout-margin .offset-lg-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-lg-45, .flex-offset-lg-45, .layout-margin .flex-offset-lg-45, .layout-margin .offset-lg-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-lg-45, [dir=rtl] .flex-offset-lg-45, [dir=rtl] .layout-margin .flex-offset-lg-45, [dir=rtl] .layout-margin .offset-lg-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-lg-50, .flex-offset-lg-50, .layout-margin .flex-offset-lg-50, .layout-margin .offset-lg-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-lg-50, [dir=rtl] .flex-offset-lg-50, [dir=rtl] .layout-margin .flex-offset-lg-50, [dir=rtl] .layout-margin .offset-lg-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-lg-55, .flex-offset-lg-55, .layout-margin .flex-offset-lg-55, .layout-margin .offset-lg-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-lg-55, [dir=rtl] .flex-offset-lg-55, [dir=rtl] .layout-margin .flex-offset-lg-55, [dir=rtl] .layout-margin .offset-lg-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-lg-60, .flex-offset-lg-60, .layout-margin .flex-offset-lg-60, .layout-margin .offset-lg-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-lg-60, [dir=rtl] .flex-offset-lg-60, [dir=rtl] .layout-margin .flex-offset-lg-60, [dir=rtl] .layout-margin .offset-lg-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-lg-65, .flex-offset-lg-65, .layout-margin .flex-offset-lg-65, .layout-margin .offset-lg-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-lg-65, [dir=rtl] .flex-offset-lg-65, [dir=rtl] .layout-margin .flex-offset-lg-65, [dir=rtl] .layout-margin .offset-lg-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-lg-70, .flex-offset-lg-70, .layout-margin .flex-offset-lg-70, .layout-margin .offset-lg-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-lg-70, [dir=rtl] .flex-offset-lg-70, [dir=rtl] .layout-margin .flex-offset-lg-70, [dir=rtl] .layout-margin .offset-lg-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-lg-75, .flex-offset-lg-75, .layout-margin .flex-offset-lg-75, .layout-margin .offset-lg-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-lg-75, [dir=rtl] .flex-offset-lg-75, [dir=rtl] .layout-margin .flex-offset-lg-75, [dir=rtl] .layout-margin .offset-lg-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-lg-80, .flex-offset-lg-80, .layout-margin .flex-offset-lg-80, .layout-margin .offset-lg-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-lg-80, [dir=rtl] .flex-offset-lg-80, [dir=rtl] .layout-margin .flex-offset-lg-80, [dir=rtl] .layout-margin .offset-lg-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-lg-85, .flex-offset-lg-85, .layout-margin .flex-offset-lg-85, .layout-margin .offset-lg-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-lg-85, [dir=rtl] .flex-offset-lg-85, [dir=rtl] .layout-margin .flex-offset-lg-85, [dir=rtl] .layout-margin .offset-lg-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-lg-90, .flex-offset-lg-90, .layout-margin .flex-offset-lg-90, .layout-margin .offset-lg-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-lg-90, [dir=rtl] .flex-offset-lg-90, [dir=rtl] .layout-margin .flex-offset-lg-90, [dir=rtl] .layout-margin .offset-lg-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-lg-95, .flex-offset-lg-95, .layout-margin .flex-offset-lg-95, .layout-margin .offset-lg-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-lg-95, [dir=rtl] .flex-offset-lg-95, [dir=rtl] .layout-margin .flex-offset-lg-95, [dir=rtl] .layout-margin .offset-lg-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-lg-33, .flex-offset-lg-33, .layout-margin .flex-offset-lg-33, .layout-margin .offset-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-lg-66, .flex-offset-lg-66, .layout-margin .flex-offset-lg-66, .layout-margin .offset-lg-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-lg-66, [dir=rtl] .flex-offset-lg-66, [dir=rtl] .layout-margin .flex-offset-lg-66, [dir=rtl] .layout-margin .offset-lg-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-lg,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    justify-content: center; }
  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-lg {
    flex: 1;
    box-sizing: border-box; }
  .flex-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-lg-column > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex {
    min-width: 0; }
  .layout-lg-column > .flex-lg-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-lg-column > .flex {
    min-height: 0; }
  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-lg-column {
    flex-direction: column; }
  .layout-lg-row {
    flex-direction: row; } }

@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    order: -20; }
  .flex-order-gt-lg--19 {
    order: -19; }
  .flex-order-gt-lg--18 {
    order: -18; }
  .flex-order-gt-lg--17 {
    order: -17; }
  .flex-order-gt-lg--16 {
    order: -16; }
  .flex-order-gt-lg--15 {
    order: -15; }
  .flex-order-gt-lg--14 {
    order: -14; }
  .flex-order-gt-lg--13 {
    order: -13; }
  .flex-order-gt-lg--12 {
    order: -12; }
  .flex-order-gt-lg--11 {
    order: -11; }
  .flex-order-gt-lg--10 {
    order: -10; }
  .flex-order-gt-lg--9 {
    order: -9; }
  .flex-order-gt-lg--8 {
    order: -8; }
  .flex-order-gt-lg--7 {
    order: -7; }
  .flex-order-gt-lg--6 {
    order: -6; }
  .flex-order-gt-lg--5 {
    order: -5; }
  .flex-order-gt-lg--4 {
    order: -4; }
  .flex-order-gt-lg--3 {
    order: -3; }
  .flex-order-gt-lg--2 {
    order: -2; }
  .flex-order-gt-lg--1 {
    order: -1; }
  .flex-order-gt-lg-0 {
    order: 0; }
  .flex-order-gt-lg-1 {
    order: 1; }
  .flex-order-gt-lg-2 {
    order: 2; }
  .flex-order-gt-lg-3 {
    order: 3; }
  .flex-order-gt-lg-4 {
    order: 4; }
  .flex-order-gt-lg-5 {
    order: 5; }
  .flex-order-gt-lg-6 {
    order: 6; }
  .flex-order-gt-lg-7 {
    order: 7; }
  .flex-order-gt-lg-8 {
    order: 8; }
  .flex-order-gt-lg-9 {
    order: 9; }
  .flex-order-gt-lg-10 {
    order: 10; }
  .flex-order-gt-lg-11 {
    order: 11; }
  .flex-order-gt-lg-12 {
    order: 12; }
  .flex-order-gt-lg-13 {
    order: 13; }
  .flex-order-gt-lg-14 {
    order: 14; }
  .flex-order-gt-lg-15 {
    order: 15; }
  .flex-order-gt-lg-16 {
    order: 16; }
  .flex-order-gt-lg-17 {
    order: 17; }
  .flex-order-gt-lg-18 {
    order: 18; }
  .flex-order-gt-lg-19 {
    order: 19; }
  .flex-order-gt-lg-20 {
    order: 20; }
  .offset-gt-lg-0, .flex-offset-gt-lg-0, .layout-margin .flex-offset-gt-lg-0, .layout-margin .offset-gt-lg-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-lg-0, [dir=rtl] .flex-offset-gt-lg-0, [dir=rtl] .layout-margin .flex-offset-gt-lg-0, [dir=rtl] .layout-margin .offset-gt-lg-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-lg-5, .flex-offset-gt-lg-5, .layout-margin .flex-offset-gt-lg-5, .layout-margin .offset-gt-lg-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-lg-5, [dir=rtl] .flex-offset-gt-lg-5, [dir=rtl] .layout-margin .flex-offset-gt-lg-5, [dir=rtl] .layout-margin .offset-gt-lg-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-lg-10, .flex-offset-gt-lg-10, .layout-margin .flex-offset-gt-lg-10, .layout-margin .offset-gt-lg-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-lg-10, [dir=rtl] .flex-offset-gt-lg-10, [dir=rtl] .layout-margin .flex-offset-gt-lg-10, [dir=rtl] .layout-margin .offset-gt-lg-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-lg-15, .flex-offset-gt-lg-15, .layout-margin .flex-offset-gt-lg-15, .layout-margin .offset-gt-lg-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-lg-15, [dir=rtl] .flex-offset-gt-lg-15, [dir=rtl] .layout-margin .flex-offset-gt-lg-15, [dir=rtl] .layout-margin .offset-gt-lg-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-lg-20, .flex-offset-gt-lg-20, .layout-margin .flex-offset-gt-lg-20, .layout-margin .offset-gt-lg-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-lg-20, [dir=rtl] .flex-offset-gt-lg-20, [dir=rtl] .layout-margin .flex-offset-gt-lg-20, [dir=rtl] .layout-margin .offset-gt-lg-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-lg-25, .flex-offset-gt-lg-25, .layout-margin .flex-offset-gt-lg-25, .layout-margin .offset-gt-lg-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-lg-25, [dir=rtl] .flex-offset-gt-lg-25, [dir=rtl] .layout-margin .flex-offset-gt-lg-25, [dir=rtl] .layout-margin .offset-gt-lg-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-lg-30, .flex-offset-gt-lg-30, .layout-margin .flex-offset-gt-lg-30, .layout-margin .offset-gt-lg-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-lg-30, [dir=rtl] .flex-offset-gt-lg-30, [dir=rtl] .layout-margin .flex-offset-gt-lg-30, [dir=rtl] .layout-margin .offset-gt-lg-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-lg-35, .flex-offset-gt-lg-35, .layout-margin .flex-offset-gt-lg-35, .layout-margin .offset-gt-lg-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-lg-35, [dir=rtl] .flex-offset-gt-lg-35, [dir=rtl] .layout-margin .flex-offset-gt-lg-35, [dir=rtl] .layout-margin .offset-gt-lg-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-lg-40, .flex-offset-gt-lg-40, .layout-margin .flex-offset-gt-lg-40, .layout-margin .offset-gt-lg-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-lg-40, [dir=rtl] .flex-offset-gt-lg-40, [dir=rtl] .layout-margin .flex-offset-gt-lg-40, [dir=rtl] .layout-margin .offset-gt-lg-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-lg-45, .flex-offset-gt-lg-45, .layout-margin .flex-offset-gt-lg-45, .layout-margin .offset-gt-lg-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-lg-45, [dir=rtl] .flex-offset-gt-lg-45, [dir=rtl] .layout-margin .flex-offset-gt-lg-45, [dir=rtl] .layout-margin .offset-gt-lg-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-lg-50, .flex-offset-gt-lg-50, .layout-margin .flex-offset-gt-lg-50, .layout-margin .offset-gt-lg-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-lg-50, [dir=rtl] .flex-offset-gt-lg-50, [dir=rtl] .layout-margin .flex-offset-gt-lg-50, [dir=rtl] .layout-margin .offset-gt-lg-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-lg-55, .flex-offset-gt-lg-55, .layout-margin .flex-offset-gt-lg-55, .layout-margin .offset-gt-lg-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-lg-55, [dir=rtl] .flex-offset-gt-lg-55, [dir=rtl] .layout-margin .flex-offset-gt-lg-55, [dir=rtl] .layout-margin .offset-gt-lg-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-lg-60, .flex-offset-gt-lg-60, .layout-margin .flex-offset-gt-lg-60, .layout-margin .offset-gt-lg-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-lg-60, [dir=rtl] .flex-offset-gt-lg-60, [dir=rtl] .layout-margin .flex-offset-gt-lg-60, [dir=rtl] .layout-margin .offset-gt-lg-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-lg-65, .flex-offset-gt-lg-65, .layout-margin .flex-offset-gt-lg-65, .layout-margin .offset-gt-lg-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-lg-65, [dir=rtl] .flex-offset-gt-lg-65, [dir=rtl] .layout-margin .flex-offset-gt-lg-65, [dir=rtl] .layout-margin .offset-gt-lg-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-lg-70, .flex-offset-gt-lg-70, .layout-margin .flex-offset-gt-lg-70, .layout-margin .offset-gt-lg-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-lg-70, [dir=rtl] .flex-offset-gt-lg-70, [dir=rtl] .layout-margin .flex-offset-gt-lg-70, [dir=rtl] .layout-margin .offset-gt-lg-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-lg-75, .flex-offset-gt-lg-75, .layout-margin .flex-offset-gt-lg-75, .layout-margin .offset-gt-lg-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-lg-75, [dir=rtl] .flex-offset-gt-lg-75, [dir=rtl] .layout-margin .flex-offset-gt-lg-75, [dir=rtl] .layout-margin .offset-gt-lg-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-lg-80, .flex-offset-gt-lg-80, .layout-margin .flex-offset-gt-lg-80, .layout-margin .offset-gt-lg-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-lg-80, [dir=rtl] .flex-offset-gt-lg-80, [dir=rtl] .layout-margin .flex-offset-gt-lg-80, [dir=rtl] .layout-margin .offset-gt-lg-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-lg-85, .flex-offset-gt-lg-85, .layout-margin .flex-offset-gt-lg-85, .layout-margin .offset-gt-lg-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-lg-85, [dir=rtl] .flex-offset-gt-lg-85, [dir=rtl] .layout-margin .flex-offset-gt-lg-85, [dir=rtl] .layout-margin .offset-gt-lg-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-lg-90, .flex-offset-gt-lg-90, .layout-margin .flex-offset-gt-lg-90, .layout-margin .offset-gt-lg-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-lg-90, [dir=rtl] .flex-offset-gt-lg-90, [dir=rtl] .layout-margin .flex-offset-gt-lg-90, [dir=rtl] .layout-margin .offset-gt-lg-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-lg-95, .flex-offset-gt-lg-95, .layout-margin .flex-offset-gt-lg-95, .layout-margin .offset-gt-lg-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-lg-95, [dir=rtl] .flex-offset-gt-lg-95, [dir=rtl] .layout-margin .flex-offset-gt-lg-95, [dir=rtl] .layout-margin .offset-gt-lg-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-lg-33, .flex-offset-gt-lg-33, .layout-margin .flex-offset-gt-lg-33, .layout-margin .offset-gt-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-lg-66, .flex-offset-gt-lg-66, .layout-margin .flex-offset-gt-lg-66, .layout-margin .offset-gt-lg-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-lg-66, [dir=rtl] .flex-offset-gt-lg-66, [dir=rtl] .layout-margin .flex-offset-gt-lg-66, [dir=rtl] .layout-margin .offset-gt-lg-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    justify-content: center; }
  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-lg {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-lg-column > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex {
    min-width: 0; }
  .layout-gt-lg-column > .flex-gt-lg-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex {
    min-height: 0; }
  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-lg-column {
    flex-direction: column; }
  .layout-gt-lg-row {
    flex-direction: row; }
  .flex-order-xl--20 {
    order: -20; }
  .flex-order-xl--19 {
    order: -19; }
  .flex-order-xl--18 {
    order: -18; }
  .flex-order-xl--17 {
    order: -17; }
  .flex-order-xl--16 {
    order: -16; }
  .flex-order-xl--15 {
    order: -15; }
  .flex-order-xl--14 {
    order: -14; }
  .flex-order-xl--13 {
    order: -13; }
  .flex-order-xl--12 {
    order: -12; }
  .flex-order-xl--11 {
    order: -11; }
  .flex-order-xl--10 {
    order: -10; }
  .flex-order-xl--9 {
    order: -9; }
  .flex-order-xl--8 {
    order: -8; }
  .flex-order-xl--7 {
    order: -7; }
  .flex-order-xl--6 {
    order: -6; }
  .flex-order-xl--5 {
    order: -5; }
  .flex-order-xl--4 {
    order: -4; }
  .flex-order-xl--3 {
    order: -3; }
  .flex-order-xl--2 {
    order: -2; }
  .flex-order-xl--1 {
    order: -1; }
  .flex-order-xl-0 {
    order: 0; }
  .flex-order-xl-1 {
    order: 1; }
  .flex-order-xl-2 {
    order: 2; }
  .flex-order-xl-3 {
    order: 3; }
  .flex-order-xl-4 {
    order: 4; }
  .flex-order-xl-5 {
    order: 5; }
  .flex-order-xl-6 {
    order: 6; }
  .flex-order-xl-7 {
    order: 7; }
  .flex-order-xl-8 {
    order: 8; }
  .flex-order-xl-9 {
    order: 9; }
  .flex-order-xl-10 {
    order: 10; }
  .flex-order-xl-11 {
    order: 11; }
  .flex-order-xl-12 {
    order: 12; }
  .flex-order-xl-13 {
    order: 13; }
  .flex-order-xl-14 {
    order: 14; }
  .flex-order-xl-15 {
    order: 15; }
  .flex-order-xl-16 {
    order: 16; }
  .flex-order-xl-17 {
    order: 17; }
  .flex-order-xl-18 {
    order: 18; }
  .flex-order-xl-19 {
    order: 19; }
  .flex-order-xl-20 {
    order: 20; }
  .offset-xl-0, .flex-offset-xl-0, .layout-margin .flex-offset-xl-0, .layout-margin .offset-xl-0 {
    margin-left: 0; }
    [dir=rtl] .offset-xl-0, [dir=rtl] .flex-offset-xl-0, [dir=rtl] .layout-margin .flex-offset-xl-0, [dir=rtl] .layout-margin .offset-xl-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-xl-5, .flex-offset-xl-5, .layout-margin .flex-offset-xl-5, .layout-margin .offset-xl-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-xl-5, [dir=rtl] .flex-offset-xl-5, [dir=rtl] .layout-margin .flex-offset-xl-5, [dir=rtl] .layout-margin .offset-xl-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-xl-10, .flex-offset-xl-10, .layout-margin .flex-offset-xl-10, .layout-margin .offset-xl-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-xl-10, [dir=rtl] .flex-offset-xl-10, [dir=rtl] .layout-margin .flex-offset-xl-10, [dir=rtl] .layout-margin .offset-xl-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-xl-15, .flex-offset-xl-15, .layout-margin .flex-offset-xl-15, .layout-margin .offset-xl-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-xl-15, [dir=rtl] .flex-offset-xl-15, [dir=rtl] .layout-margin .flex-offset-xl-15, [dir=rtl] .layout-margin .offset-xl-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-xl-20, .flex-offset-xl-20, .layout-margin .flex-offset-xl-20, .layout-margin .offset-xl-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-xl-20, [dir=rtl] .flex-offset-xl-20, [dir=rtl] .layout-margin .flex-offset-xl-20, [dir=rtl] .layout-margin .offset-xl-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-xl-25, .flex-offset-xl-25, .layout-margin .flex-offset-xl-25, .layout-margin .offset-xl-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-xl-25, [dir=rtl] .flex-offset-xl-25, [dir=rtl] .layout-margin .flex-offset-xl-25, [dir=rtl] .layout-margin .offset-xl-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-xl-30, .flex-offset-xl-30, .layout-margin .flex-offset-xl-30, .layout-margin .offset-xl-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-xl-30, [dir=rtl] .flex-offset-xl-30, [dir=rtl] .layout-margin .flex-offset-xl-30, [dir=rtl] .layout-margin .offset-xl-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-xl-35, .flex-offset-xl-35, .layout-margin .flex-offset-xl-35, .layout-margin .offset-xl-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-xl-35, [dir=rtl] .flex-offset-xl-35, [dir=rtl] .layout-margin .flex-offset-xl-35, [dir=rtl] .layout-margin .offset-xl-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-xl-40, .flex-offset-xl-40, .layout-margin .flex-offset-xl-40, .layout-margin .offset-xl-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-xl-40, [dir=rtl] .flex-offset-xl-40, [dir=rtl] .layout-margin .flex-offset-xl-40, [dir=rtl] .layout-margin .offset-xl-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-xl-45, .flex-offset-xl-45, .layout-margin .flex-offset-xl-45, .layout-margin .offset-xl-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-xl-45, [dir=rtl] .flex-offset-xl-45, [dir=rtl] .layout-margin .flex-offset-xl-45, [dir=rtl] .layout-margin .offset-xl-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-xl-50, .flex-offset-xl-50, .layout-margin .flex-offset-xl-50, .layout-margin .offset-xl-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-xl-50, [dir=rtl] .flex-offset-xl-50, [dir=rtl] .layout-margin .flex-offset-xl-50, [dir=rtl] .layout-margin .offset-xl-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-xl-55, .flex-offset-xl-55, .layout-margin .flex-offset-xl-55, .layout-margin .offset-xl-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-xl-55, [dir=rtl] .flex-offset-xl-55, [dir=rtl] .layout-margin .flex-offset-xl-55, [dir=rtl] .layout-margin .offset-xl-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-xl-60, .flex-offset-xl-60, .layout-margin .flex-offset-xl-60, .layout-margin .offset-xl-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-xl-60, [dir=rtl] .flex-offset-xl-60, [dir=rtl] .layout-margin .flex-offset-xl-60, [dir=rtl] .layout-margin .offset-xl-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-xl-65, .flex-offset-xl-65, .layout-margin .flex-offset-xl-65, .layout-margin .offset-xl-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-xl-65, [dir=rtl] .flex-offset-xl-65, [dir=rtl] .layout-margin .flex-offset-xl-65, [dir=rtl] .layout-margin .offset-xl-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-xl-70, .flex-offset-xl-70, .layout-margin .flex-offset-xl-70, .layout-margin .offset-xl-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-xl-70, [dir=rtl] .flex-offset-xl-70, [dir=rtl] .layout-margin .flex-offset-xl-70, [dir=rtl] .layout-margin .offset-xl-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-xl-75, .flex-offset-xl-75, .layout-margin .flex-offset-xl-75, .layout-margin .offset-xl-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-xl-75, [dir=rtl] .flex-offset-xl-75, [dir=rtl] .layout-margin .flex-offset-xl-75, [dir=rtl] .layout-margin .offset-xl-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-xl-80, .flex-offset-xl-80, .layout-margin .flex-offset-xl-80, .layout-margin .offset-xl-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-xl-80, [dir=rtl] .flex-offset-xl-80, [dir=rtl] .layout-margin .flex-offset-xl-80, [dir=rtl] .layout-margin .offset-xl-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-xl-85, .flex-offset-xl-85, .layout-margin .flex-offset-xl-85, .layout-margin .offset-xl-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-xl-85, [dir=rtl] .flex-offset-xl-85, [dir=rtl] .layout-margin .flex-offset-xl-85, [dir=rtl] .layout-margin .offset-xl-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-xl-90, .flex-offset-xl-90, .layout-margin .flex-offset-xl-90, .layout-margin .offset-xl-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-xl-90, [dir=rtl] .flex-offset-xl-90, [dir=rtl] .layout-margin .flex-offset-xl-90, [dir=rtl] .layout-margin .offset-xl-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-xl-95, .flex-offset-xl-95, .layout-margin .flex-offset-xl-95, .layout-margin .offset-xl-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-xl-95, [dir=rtl] .flex-offset-xl-95, [dir=rtl] .layout-margin .flex-offset-xl-95, [dir=rtl] .layout-margin .offset-xl-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-xl-33, .flex-offset-xl-33, .layout-margin .flex-offset-xl-33, .layout-margin .offset-xl-33 {
    margin-left: calc(100% / 3); }
  .offset-xl-66, .flex-offset-xl-66, .layout-margin .flex-offset-xl-66, .layout-margin .offset-xl-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-xl-66, [dir=rtl] .flex-offset-xl-66, [dir=rtl] .layout-margin .flex-offset-xl-66, [dir=rtl] .layout-margin .offset-xl-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-xl,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    justify-content: flex-start; }
  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    justify-content: center; }
  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    justify-content: flex-end; }
  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xl {
    flex: 1;
    box-sizing: border-box; }
  .flex-xl-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xl-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xl-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xl-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xl-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-xl-column > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex {
    min-width: 0; }
  .layout-xl-column > .flex-xl-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xl-column > .flex {
    min-height: 0; }
  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xl-column {
    flex-direction: column; }
  .layout-xl-row {
    flex-direction: row; }
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none; }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; } }

@media print {
  .hide-print:not(.show-print):not(.show) {
    display: none !important; } }

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Colors
/*----------------------------------------------------------------*/
.black-fg,
.black-text {
  color: rgba(0, 0, 0, 0.87) !important; }
  .black-fg.secondary-text,
  .black-fg .secondary-text, .black-fg.icon,
  .black-fg .icon,
  .black-text.secondary-text,
  .black-text .secondary-text,
  .black-text.icon,
  .black-text .icon {
    color: rgba(0, 0, 0, 0.54) !important; }
  .black-fg.hint-text,
  .black-fg .hint-text, .black-fg.disabled-text,
  .black-fg .disabled-text,
  .black-text.hint-text,
  .black-text .hint-text,
  .black-text.disabled-text,
  .black-text .disabled-text {
    color: rgba(0, 0, 0, 0.26) !important; }
  .black-fg.divider,
  .black-fg .divider, .black-fg.faint-text,
  .black-fg .faint-text,
  .black-text.divider,
  .black-text .divider,
  .black-text.faint-text,
  .black-text .faint-text {
    color: rgba(0, 0, 0, 0.12) !important; }

.white-fg,
.white-text {
  color: white !important; }
  .white-fg.secondary-text,
  .white-fg .secondary-text, .white-fg.icon,
  .white-fg .icon,
  .white-text.secondary-text,
  .white-text .secondary-text,
  .white-text.icon,
  .white-text .icon {
    color: rgba(255, 255, 255, 0.7) !important; }
  .white-fg.hint-text,
  .white-fg .hint-text, .white-fg.disabled-text,
  .white-fg .disabled-text,
  .white-text.hint-text,
  .white-text .hint-text,
  .white-text.disabled-text,
  .white-text .disabled-text {
    color: rgba(255, 255, 255, 0.3) !important; }
  .white-fg.divider,
  .white-fg .divider, .white-fg.faint-text,
  .white-fg .faint-text,
  .white-text.divider,
  .white-text .divider,
  .white-text.faint-text,
  .white-text .faint-text {
    color: rgba(255, 255, 255, 0.12) !important; }
  .white-fg md-icon,
  .white-text md-icon {
    color: #FFFFFF; }
  .white-fg md-progress-linear .md-container,
  .white-text md-progress-linear .md-container {
    background: rgba(255, 255, 255, 0.3); }
  .white-fg md-progress-linear .md-bar,
  .white-text md-progress-linear .md-bar {
    background: rgba(255, 255, 255, 0.7); }

.red-50-bg {
  background-color: #FFEBEE !important; }

.md-red-50-bg {
  background-color: #FFEBEE !important;
  color: rgba(0, 0, 0, 0.87); }

.red-50-fg {
  color: #FFEBEE !important; }

.red-50-border {
  border-color: #FFEBEE !important; }

.red-50-border-top {
  border-top-color: #FFEBEE !important; }

.red-50-border-right {
  border-right-color: #FFEBEE !important; }

.red-50-border-bottom {
  border-bottom-color: #FFEBEE !important; }

.red-50-border-left {
  border-left-color: #FFEBEE !important; }

.red-100-bg {
  background-color: #FFCDD2 !important; }

.md-red-100-bg {
  background-color: #FFCDD2 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-100-fg {
  color: #FFCDD2 !important; }

.red-100-border {
  border-color: #FFCDD2 !important; }

.red-100-border-top {
  border-top-color: #FFCDD2 !important; }

.red-100-border-right {
  border-right-color: #FFCDD2 !important; }

.red-100-border-bottom {
  border-bottom-color: #FFCDD2 !important; }

.red-100-border-left {
  border-left-color: #FFCDD2 !important; }

.red-200-bg {
  background-color: #EF9A9A !important; }

.md-red-200-bg {
  background-color: #EF9A9A !important;
  color: rgba(0, 0, 0, 0.87); }

.red-200-fg {
  color: #EF9A9A !important; }

.red-200-border {
  border-color: #EF9A9A !important; }

.red-200-border-top {
  border-top-color: #EF9A9A !important; }

.red-200-border-right {
  border-right-color: #EF9A9A !important; }

.red-200-border-bottom {
  border-bottom-color: #EF9A9A !important; }

.red-200-border-left {
  border-left-color: #EF9A9A !important; }

.red-300-bg {
  background-color: #E57373 !important; }

.md-red-300-bg {
  background-color: #E57373 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-300-fg {
  color: #E57373 !important; }

.red-300-border {
  border-color: #E57373 !important; }

.red-300-border-top {
  border-top-color: #E57373 !important; }

.red-300-border-right {
  border-right-color: #E57373 !important; }

.red-300-border-bottom {
  border-bottom-color: #E57373 !important; }

.red-300-border-left {
  border-left-color: #E57373 !important; }

.red-400-bg {
  background-color: #EF5350 !important; }

.md-red-400-bg {
  background-color: #EF5350 !important;
  color: white; }

.red-400-fg {
  color: #EF5350 !important; }

.red-400-border {
  border-color: #EF5350 !important; }

.red-400-border-top {
  border-top-color: #EF5350 !important; }

.red-400-border-right {
  border-right-color: #EF5350 !important; }

.red-400-border-bottom {
  border-bottom-color: #EF5350 !important; }

.red-400-border-left {
  border-left-color: #EF5350 !important; }

.red-500-bg {
  background-color: #F44336 !important; }

.md-red-500-bg {
  background-color: #F44336 !important;
  color: white; }

.red-500-fg, .event-report table.md-table .event-occurrence .td-event-type,
.event-report table.md-table .event-occurrence [class*="icon-"] {
  color: #F44336 !important; }

.red-500-border {
  border-color: #F44336 !important; }

.red-500-border-top {
  border-top-color: #F44336 !important; }

.red-500-border-right {
  border-right-color: #F44336 !important; }

.red-500-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-500-border-left {
  border-left-color: #F44336 !important; }

.md-red-bg {
  background-color: #F44336 !important;
  color: white; }

.red-bg {
  background-color: #F44336 !important; }

.red-fg {
  color: #F44336 !important; }

.red-border {
  border-color: #F44336 !important; }

.red-border-top {
  border-top-color: #F44336 !important; }

.red-border-right {
  border-right-color: #F44336 !important; }

.red-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-border-left {
  border-left-color: #F44336 !important; }

.red-600-bg {
  background-color: #E53935 !important; }

.md-red-600-bg {
  background-color: #E53935 !important;
  color: white; }

.red-600-fg {
  color: #E53935 !important; }

.red-600-border {
  border-color: #E53935 !important; }

.red-600-border-top {
  border-top-color: #E53935 !important; }

.red-600-border-right {
  border-right-color: #E53935 !important; }

.red-600-border-bottom {
  border-bottom-color: #E53935 !important; }

.red-600-border-left {
  border-left-color: #E53935 !important; }

.red-700-bg {
  background-color: #D32F2F !important; }

.md-red-700-bg {
  background-color: #D32F2F !important;
  color: white; }

.red-700-fg {
  color: #D32F2F !important; }

.red-700-border {
  border-color: #D32F2F !important; }

.red-700-border-top {
  border-top-color: #D32F2F !important; }

.red-700-border-right {
  border-right-color: #D32F2F !important; }

.red-700-border-bottom {
  border-bottom-color: #D32F2F !important; }

.red-700-border-left {
  border-left-color: #D32F2F !important; }

.red-800-bg {
  background-color: #C62828 !important; }

.md-red-800-bg {
  background-color: #C62828 !important;
  color: rgba(255, 255, 255, 0.87); }

.red-800-fg {
  color: #C62828 !important; }

.red-800-border {
  border-color: #C62828 !important; }

.red-800-border-top {
  border-top-color: #C62828 !important; }

.red-800-border-right {
  border-right-color: #C62828 !important; }

.red-800-border-bottom {
  border-bottom-color: #C62828 !important; }

.red-800-border-left {
  border-left-color: #C62828 !important; }

.red-900-bg {
  background-color: #B71C1C !important; }

.md-red-900-bg {
  background-color: #B71C1C !important;
  color: rgba(255, 255, 255, 0.87); }

.red-900-fg {
  color: #B71C1C !important; }

.red-900-border {
  border-color: #B71C1C !important; }

.red-900-border-top {
  border-top-color: #B71C1C !important; }

.red-900-border-right {
  border-right-color: #B71C1C !important; }

.red-900-border-bottom {
  border-bottom-color: #B71C1C !important; }

.red-900-border-left {
  border-left-color: #B71C1C !important; }

.red-A100-bg {
  background-color: #FF8A80 !important; }

.md-red-A100-bg {
  background-color: #FF8A80 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-A100-fg {
  color: #FF8A80 !important; }

.red-A100-border {
  border-color: #FF8A80 !important; }

.red-A100-border-top {
  border-top-color: #FF8A80 !important; }

.red-A100-border-right {
  border-right-color: #FF8A80 !important; }

.red-A100-border-bottom {
  border-bottom-color: #FF8A80 !important; }

.red-A100-border-left {
  border-left-color: #FF8A80 !important; }

.red-A200-bg {
  background-color: #FF5252 !important; }

.md-red-A200-bg {
  background-color: #FF5252 !important;
  color: white; }

.red-A200-fg {
  color: #FF5252 !important; }

.red-A200-border {
  border-color: #FF5252 !important; }

.red-A200-border-top {
  border-top-color: #FF5252 !important; }

.red-A200-border-right {
  border-right-color: #FF5252 !important; }

.red-A200-border-bottom {
  border-bottom-color: #FF5252 !important; }

.red-A200-border-left {
  border-left-color: #FF5252 !important; }

.red-A400-bg {
  background-color: #FF1744 !important; }

.md-red-A400-bg {
  background-color: #FF1744 !important;
  color: white; }

.red-A400-fg {
  color: #FF1744 !important; }

.red-A400-border {
  border-color: #FF1744 !important; }

.red-A400-border-top {
  border-top-color: #FF1744 !important; }

.red-A400-border-right {
  border-right-color: #FF1744 !important; }

.red-A400-border-bottom {
  border-bottom-color: #FF1744 !important; }

.red-A400-border-left {
  border-left-color: #FF1744 !important; }

.red-A700-bg {
  background-color: #D50000 !important; }

.md-red-A700-bg {
  background-color: #D50000 !important;
  color: white; }

.red-A700-fg {
  color: #D50000 !important; }

.red-A700-border {
  border-color: #D50000 !important; }

.red-A700-border-top {
  border-top-color: #D50000 !important; }

.red-A700-border-right {
  border-right-color: #D50000 !important; }

.red-A700-border-bottom {
  border-bottom-color: #D50000 !important; }

.red-A700-border-left {
  border-left-color: #D50000 !important; }

.pink-50-bg {
  background-color: #FCE4EC !important; }

.md-pink-50-bg {
  background-color: #FCE4EC !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-50-fg {
  color: #FCE4EC !important; }

.pink-50-border {
  border-color: #FCE4EC !important; }

.pink-50-border-top {
  border-top-color: #FCE4EC !important; }

.pink-50-border-right {
  border-right-color: #FCE4EC !important; }

.pink-50-border-bottom {
  border-bottom-color: #FCE4EC !important; }

.pink-50-border-left {
  border-left-color: #FCE4EC !important; }

.pink-100-bg {
  background-color: #F8BBD0 !important; }

.md-pink-100-bg {
  background-color: #F8BBD0 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-100-fg {
  color: #F8BBD0 !important; }

.pink-100-border {
  border-color: #F8BBD0 !important; }

.pink-100-border-top {
  border-top-color: #F8BBD0 !important; }

.pink-100-border-right {
  border-right-color: #F8BBD0 !important; }

.pink-100-border-bottom {
  border-bottom-color: #F8BBD0 !important; }

.pink-100-border-left {
  border-left-color: #F8BBD0 !important; }

.pink-200-bg {
  background-color: #F48FB1 !important; }

.md-pink-200-bg {
  background-color: #F48FB1 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-200-fg {
  color: #F48FB1 !important; }

.pink-200-border {
  border-color: #F48FB1 !important; }

.pink-200-border-top {
  border-top-color: #F48FB1 !important; }

.pink-200-border-right {
  border-right-color: #F48FB1 !important; }

.pink-200-border-bottom {
  border-bottom-color: #F48FB1 !important; }

.pink-200-border-left {
  border-left-color: #F48FB1 !important; }

.pink-300-bg {
  background-color: #F06292 !important; }

.md-pink-300-bg {
  background-color: #F06292 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-300-fg {
  color: #F06292 !important; }

.pink-300-border {
  border-color: #F06292 !important; }

.pink-300-border-top {
  border-top-color: #F06292 !important; }

.pink-300-border-right {
  border-right-color: #F06292 !important; }

.pink-300-border-bottom {
  border-bottom-color: #F06292 !important; }

.pink-300-border-left {
  border-left-color: #F06292 !important; }

.pink-400-bg {
  background-color: #EC407A !important; }

.md-pink-400-bg {
  background-color: #EC407A !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-400-fg {
  color: #EC407A !important; }

.pink-400-border {
  border-color: #EC407A !important; }

.pink-400-border-top {
  border-top-color: #EC407A !important; }

.pink-400-border-right {
  border-right-color: #EC407A !important; }

.pink-400-border-bottom {
  border-bottom-color: #EC407A !important; }

.pink-400-border-left {
  border-left-color: #EC407A !important; }

.pink-500-bg {
  background-color: #E91E63 !important; }

.md-pink-500-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-500-fg {
  color: #E91E63 !important; }

.pink-500-border {
  border-color: #E91E63 !important; }

.pink-500-border-top {
  border-top-color: #E91E63 !important; }

.pink-500-border-right {
  border-right-color: #E91E63 !important; }

.pink-500-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-500-border-left {
  border-left-color: #E91E63 !important; }

.md-pink-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-bg {
  background-color: #E91E63 !important; }

.pink-fg {
  color: #E91E63 !important; }

.pink-border {
  border-color: #E91E63 !important; }

.pink-border-top {
  border-top-color: #E91E63 !important; }

.pink-border-right {
  border-right-color: #E91E63 !important; }

.pink-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-border-left {
  border-left-color: #E91E63 !important; }

.pink-600-bg {
  background-color: #D81B60 !important; }

.md-pink-600-bg {
  background-color: #D81B60 !important;
  color: white; }

.pink-600-fg {
  color: #D81B60 !important; }

.pink-600-border {
  border-color: #D81B60 !important; }

.pink-600-border-top {
  border-top-color: #D81B60 !important; }

.pink-600-border-right {
  border-right-color: #D81B60 !important; }

.pink-600-border-bottom {
  border-bottom-color: #D81B60 !important; }

.pink-600-border-left {
  border-left-color: #D81B60 !important; }

.pink-700-bg {
  background-color: #C2185B !important; }

.md-pink-700-bg {
  background-color: #C2185B !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-700-fg {
  color: #C2185B !important; }

.pink-700-border {
  border-color: #C2185B !important; }

.pink-700-border-top {
  border-top-color: #C2185B !important; }

.pink-700-border-right {
  border-right-color: #C2185B !important; }

.pink-700-border-bottom {
  border-bottom-color: #C2185B !important; }

.pink-700-border-left {
  border-left-color: #C2185B !important; }

.pink-800-bg {
  background-color: #AD1457 !important; }

.md-pink-800-bg {
  background-color: #AD1457 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-800-fg {
  color: #AD1457 !important; }

.pink-800-border {
  border-color: #AD1457 !important; }

.pink-800-border-top {
  border-top-color: #AD1457 !important; }

.pink-800-border-right {
  border-right-color: #AD1457 !important; }

.pink-800-border-bottom {
  border-bottom-color: #AD1457 !important; }

.pink-800-border-left {
  border-left-color: #AD1457 !important; }

.pink-900-bg {
  background-color: #880E4F !important; }

.md-pink-900-bg {
  background-color: #880E4F !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-900-fg {
  color: #880E4F !important; }

.pink-900-border {
  border-color: #880E4F !important; }

.pink-900-border-top {
  border-top-color: #880E4F !important; }

.pink-900-border-right {
  border-right-color: #880E4F !important; }

.pink-900-border-bottom {
  border-bottom-color: #880E4F !important; }

.pink-900-border-left {
  border-left-color: #880E4F !important; }

.pink-A100-bg {
  background-color: #FF80AB !important; }

.md-pink-A100-bg {
  background-color: #FF80AB !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-A100-fg {
  color: #FF80AB !important; }

.pink-A100-border {
  border-color: #FF80AB !important; }

.pink-A100-border-top {
  border-top-color: #FF80AB !important; }

.pink-A100-border-right {
  border-right-color: #FF80AB !important; }

.pink-A100-border-bottom {
  border-bottom-color: #FF80AB !important; }

.pink-A100-border-left {
  border-left-color: #FF80AB !important; }

.pink-A200-bg {
  background-color: #FF4081 !important; }

.md-pink-A200-bg {
  background-color: #FF4081 !important;
  color: white; }

.pink-A200-fg {
  color: #FF4081 !important; }

.pink-A200-border {
  border-color: #FF4081 !important; }

.pink-A200-border-top {
  border-top-color: #FF4081 !important; }

.pink-A200-border-right {
  border-right-color: #FF4081 !important; }

.pink-A200-border-bottom {
  border-bottom-color: #FF4081 !important; }

.pink-A200-border-left {
  border-left-color: #FF4081 !important; }

.pink-A400-bg {
  background-color: #F50057 !important; }

.md-pink-A400-bg {
  background-color: #F50057 !important;
  color: white; }

.pink-A400-fg {
  color: #F50057 !important; }

.pink-A400-border {
  border-color: #F50057 !important; }

.pink-A400-border-top {
  border-top-color: #F50057 !important; }

.pink-A400-border-right {
  border-right-color: #F50057 !important; }

.pink-A400-border-bottom {
  border-bottom-color: #F50057 !important; }

.pink-A400-border-left {
  border-left-color: #F50057 !important; }

.pink-A700-bg {
  background-color: #C51162 !important; }

.md-pink-A700-bg {
  background-color: #C51162 !important;
  color: white; }

.pink-A700-fg {
  color: #C51162 !important; }

.pink-A700-border {
  border-color: #C51162 !important; }

.pink-A700-border-top {
  border-top-color: #C51162 !important; }

.pink-A700-border-right {
  border-right-color: #C51162 !important; }

.pink-A700-border-bottom {
  border-bottom-color: #C51162 !important; }

.pink-A700-border-left {
  border-left-color: #C51162 !important; }

.purple-50-bg {
  background-color: #F3E5F5 !important; }

.md-purple-50-bg {
  background-color: #F3E5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-50-fg {
  color: #F3E5F5 !important; }

.purple-50-border {
  border-color: #F3E5F5 !important; }

.purple-50-border-top {
  border-top-color: #F3E5F5 !important; }

.purple-50-border-right {
  border-right-color: #F3E5F5 !important; }

.purple-50-border-bottom {
  border-bottom-color: #F3E5F5 !important; }

.purple-50-border-left {
  border-left-color: #F3E5F5 !important; }

.purple-100-bg {
  background-color: #E1BEE7 !important; }

.md-purple-100-bg {
  background-color: #E1BEE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-100-fg {
  color: #E1BEE7 !important; }

.purple-100-border {
  border-color: #E1BEE7 !important; }

.purple-100-border-top {
  border-top-color: #E1BEE7 !important; }

.purple-100-border-right {
  border-right-color: #E1BEE7 !important; }

.purple-100-border-bottom {
  border-bottom-color: #E1BEE7 !important; }

.purple-100-border-left {
  border-left-color: #E1BEE7 !important; }

.purple-200-bg {
  background-color: #CE93D8 !important; }

.md-purple-200-bg {
  background-color: #CE93D8 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-200-fg {
  color: #CE93D8 !important; }

.purple-200-border {
  border-color: #CE93D8 !important; }

.purple-200-border-top {
  border-top-color: #CE93D8 !important; }

.purple-200-border-right {
  border-right-color: #CE93D8 !important; }

.purple-200-border-bottom {
  border-bottom-color: #CE93D8 !important; }

.purple-200-border-left {
  border-left-color: #CE93D8 !important; }

.purple-300-bg {
  background-color: #BA68C8 !important; }

.md-purple-300-bg {
  background-color: #BA68C8 !important;
  color: white; }

.purple-300-fg {
  color: #BA68C8 !important; }

.purple-300-border {
  border-color: #BA68C8 !important; }

.purple-300-border-top {
  border-top-color: #BA68C8 !important; }

.purple-300-border-right {
  border-right-color: #BA68C8 !important; }

.purple-300-border-bottom {
  border-bottom-color: #BA68C8 !important; }

.purple-300-border-left {
  border-left-color: #BA68C8 !important; }

.purple-400-bg {
  background-color: #AB47BC !important; }

.md-purple-400-bg {
  background-color: #AB47BC !important;
  color: white; }

.purple-400-fg {
  color: #AB47BC !important; }

.purple-400-border {
  border-color: #AB47BC !important; }

.purple-400-border-top {
  border-top-color: #AB47BC !important; }

.purple-400-border-right {
  border-right-color: #AB47BC !important; }

.purple-400-border-bottom {
  border-bottom-color: #AB47BC !important; }

.purple-400-border-left {
  border-left-color: #AB47BC !important; }

.purple-500-bg {
  background-color: #9C27B0 !important; }

.md-purple-500-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-500-fg {
  color: #9C27B0 !important; }

.purple-500-border {
  border-color: #9C27B0 !important; }

.purple-500-border-top {
  border-top-color: #9C27B0 !important; }

.purple-500-border-right {
  border-right-color: #9C27B0 !important; }

.purple-500-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-500-border-left {
  border-left-color: #9C27B0 !important; }

.md-purple-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-bg {
  background-color: #9C27B0 !important; }

.purple-fg {
  color: #9C27B0 !important; }

.purple-border {
  border-color: #9C27B0 !important; }

.purple-border-top {
  border-top-color: #9C27B0 !important; }

.purple-border-right {
  border-right-color: #9C27B0 !important; }

.purple-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-border-left {
  border-left-color: #9C27B0 !important; }

.purple-600-bg {
  background-color: #8E24AA !important; }

.md-purple-600-bg {
  background-color: #8E24AA !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-600-fg {
  color: #8E24AA !important; }

.purple-600-border {
  border-color: #8E24AA !important; }

.purple-600-border-top {
  border-top-color: #8E24AA !important; }

.purple-600-border-right {
  border-right-color: #8E24AA !important; }

.purple-600-border-bottom {
  border-bottom-color: #8E24AA !important; }

.purple-600-border-left {
  border-left-color: #8E24AA !important; }

.purple-700-bg {
  background-color: #7B1FA2 !important; }

.md-purple-700-bg {
  background-color: #7B1FA2 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-700-fg {
  color: #7B1FA2 !important; }

.purple-700-border {
  border-color: #7B1FA2 !important; }

.purple-700-border-top {
  border-top-color: #7B1FA2 !important; }

.purple-700-border-right {
  border-right-color: #7B1FA2 !important; }

.purple-700-border-bottom {
  border-bottom-color: #7B1FA2 !important; }

.purple-700-border-left {
  border-left-color: #7B1FA2 !important; }

.purple-800-bg {
  background-color: #6A1B9A !important; }

.md-purple-800-bg {
  background-color: #6A1B9A !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-800-fg {
  color: #6A1B9A !important; }

.purple-800-border {
  border-color: #6A1B9A !important; }

.purple-800-border-top {
  border-top-color: #6A1B9A !important; }

.purple-800-border-right {
  border-right-color: #6A1B9A !important; }

.purple-800-border-bottom {
  border-bottom-color: #6A1B9A !important; }

.purple-800-border-left {
  border-left-color: #6A1B9A !important; }

.purple-900-bg {
  background-color: #4A148C !important; }

.md-purple-900-bg {
  background-color: #4A148C !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-900-fg {
  color: #4A148C !important; }

.purple-900-border {
  border-color: #4A148C !important; }

.purple-900-border-top {
  border-top-color: #4A148C !important; }

.purple-900-border-right {
  border-right-color: #4A148C !important; }

.purple-900-border-bottom {
  border-bottom-color: #4A148C !important; }

.purple-900-border-left {
  border-left-color: #4A148C !important; }

.purple-A100-bg {
  background-color: #EA80FC !important; }

.md-purple-A100-bg {
  background-color: #EA80FC !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-A100-fg {
  color: #EA80FC !important; }

.purple-A100-border {
  border-color: #EA80FC !important; }

.purple-A100-border-top {
  border-top-color: #EA80FC !important; }

.purple-A100-border-right {
  border-right-color: #EA80FC !important; }

.purple-A100-border-bottom {
  border-bottom-color: #EA80FC !important; }

.purple-A100-border-left {
  border-left-color: #EA80FC !important; }

.purple-A200-bg {
  background-color: #E040FB !important; }

.md-purple-A200-bg {
  background-color: #E040FB !important;
  color: white; }

.purple-A200-fg {
  color: #E040FB !important; }

.purple-A200-border {
  border-color: #E040FB !important; }

.purple-A200-border-top {
  border-top-color: #E040FB !important; }

.purple-A200-border-right {
  border-right-color: #E040FB !important; }

.purple-A200-border-bottom {
  border-bottom-color: #E040FB !important; }

.purple-A200-border-left {
  border-left-color: #E040FB !important; }

.purple-A400-bg {
  background-color: #D500F9 !important; }

.md-purple-A400-bg {
  background-color: #D500F9 !important;
  color: white; }

.purple-A400-fg {
  color: #D500F9 !important; }

.purple-A400-border {
  border-color: #D500F9 !important; }

.purple-A400-border-top {
  border-top-color: #D500F9 !important; }

.purple-A400-border-right {
  border-right-color: #D500F9 !important; }

.purple-A400-border-bottom {
  border-bottom-color: #D500F9 !important; }

.purple-A400-border-left {
  border-left-color: #D500F9 !important; }

.purple-A700-bg {
  background-color: #AA00FF !important; }

.md-purple-A700-bg {
  background-color: #AA00FF !important;
  color: white; }

.purple-A700-fg {
  color: #AA00FF !important; }

.purple-A700-border {
  border-color: #AA00FF !important; }

.purple-A700-border-top {
  border-top-color: #AA00FF !important; }

.purple-A700-border-right {
  border-right-color: #AA00FF !important; }

.purple-A700-border-bottom {
  border-bottom-color: #AA00FF !important; }

.purple-A700-border-left {
  border-left-color: #AA00FF !important; }

.deep-purple-50-bg {
  background-color: #EDE7F6 !important; }

.md-deep-purple-50-bg {
  background-color: #EDE7F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-50-fg {
  color: #EDE7F6 !important; }

.deep-purple-50-border {
  border-color: #EDE7F6 !important; }

.deep-purple-50-border-top {
  border-top-color: #EDE7F6 !important; }

.deep-purple-50-border-right {
  border-right-color: #EDE7F6 !important; }

.deep-purple-50-border-bottom {
  border-bottom-color: #EDE7F6 !important; }

.deep-purple-50-border-left {
  border-left-color: #EDE7F6 !important; }

.deep-purple-100-bg {
  background-color: #D1C4E9 !important; }

.md-deep-purple-100-bg {
  background-color: #D1C4E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-100-fg {
  color: #D1C4E9 !important; }

.deep-purple-100-border {
  border-color: #D1C4E9 !important; }

.deep-purple-100-border-top {
  border-top-color: #D1C4E9 !important; }

.deep-purple-100-border-right {
  border-right-color: #D1C4E9 !important; }

.deep-purple-100-border-bottom {
  border-bottom-color: #D1C4E9 !important; }

.deep-purple-100-border-left {
  border-left-color: #D1C4E9 !important; }

.deep-purple-200-bg {
  background-color: #B39DDB !important; }

.md-deep-purple-200-bg {
  background-color: #B39DDB !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-200-fg {
  color: #B39DDB !important; }

.deep-purple-200-border {
  border-color: #B39DDB !important; }

.deep-purple-200-border-top {
  border-top-color: #B39DDB !important; }

.deep-purple-200-border-right {
  border-right-color: #B39DDB !important; }

.deep-purple-200-border-bottom {
  border-bottom-color: #B39DDB !important; }

.deep-purple-200-border-left {
  border-left-color: #B39DDB !important; }

.deep-purple-300-bg {
  background-color: #9575CD !important; }

.md-deep-purple-300-bg {
  background-color: #9575CD !important;
  color: white; }

.deep-purple-300-fg {
  color: #9575CD !important; }

.deep-purple-300-border {
  border-color: #9575CD !important; }

.deep-purple-300-border-top {
  border-top-color: #9575CD !important; }

.deep-purple-300-border-right {
  border-right-color: #9575CD !important; }

.deep-purple-300-border-bottom {
  border-bottom-color: #9575CD !important; }

.deep-purple-300-border-left {
  border-left-color: #9575CD !important; }

.deep-purple-400-bg {
  background-color: #7E57C2 !important; }

.md-deep-purple-400-bg {
  background-color: #7E57C2 !important;
  color: white; }

.deep-purple-400-fg {
  color: #7E57C2 !important; }

.deep-purple-400-border {
  border-color: #7E57C2 !important; }

.deep-purple-400-border-top {
  border-top-color: #7E57C2 !important; }

.deep-purple-400-border-right {
  border-right-color: #7E57C2 !important; }

.deep-purple-400-border-bottom {
  border-bottom-color: #7E57C2 !important; }

.deep-purple-400-border-left {
  border-left-color: #7E57C2 !important; }

.deep-purple-500-bg {
  background-color: #673AB7 !important; }

.md-deep-purple-500-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-500-fg {
  color: #673AB7 !important; }

.deep-purple-500-border {
  border-color: #673AB7 !important; }

.deep-purple-500-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-500-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-500-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-500-border-left {
  border-left-color: #673AB7 !important; }

.md-deep-purple-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-bg {
  background-color: #673AB7 !important; }

.deep-purple-fg {
  color: #673AB7 !important; }

.deep-purple-border {
  border-color: #673AB7 !important; }

.deep-purple-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-border-left {
  border-left-color: #673AB7 !important; }

.deep-purple-600-bg {
  background-color: #5E35B1 !important; }

.md-deep-purple-600-bg {
  background-color: #5E35B1 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-600-fg {
  color: #5E35B1 !important; }

.deep-purple-600-border {
  border-color: #5E35B1 !important; }

.deep-purple-600-border-top {
  border-top-color: #5E35B1 !important; }

.deep-purple-600-border-right {
  border-right-color: #5E35B1 !important; }

.deep-purple-600-border-bottom {
  border-bottom-color: #5E35B1 !important; }

.deep-purple-600-border-left {
  border-left-color: #5E35B1 !important; }

.deep-purple-700-bg {
  background-color: #512DA8 !important; }

.md-deep-purple-700-bg {
  background-color: #512DA8 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-700-fg {
  color: #512DA8 !important; }

.deep-purple-700-border {
  border-color: #512DA8 !important; }

.deep-purple-700-border-top {
  border-top-color: #512DA8 !important; }

.deep-purple-700-border-right {
  border-right-color: #512DA8 !important; }

.deep-purple-700-border-bottom {
  border-bottom-color: #512DA8 !important; }

.deep-purple-700-border-left {
  border-left-color: #512DA8 !important; }

.deep-purple-800-bg {
  background-color: #4527A0 !important; }

.md-deep-purple-800-bg {
  background-color: #4527A0 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-800-fg {
  color: #4527A0 !important; }

.deep-purple-800-border {
  border-color: #4527A0 !important; }

.deep-purple-800-border-top {
  border-top-color: #4527A0 !important; }

.deep-purple-800-border-right {
  border-right-color: #4527A0 !important; }

.deep-purple-800-border-bottom {
  border-bottom-color: #4527A0 !important; }

.deep-purple-800-border-left {
  border-left-color: #4527A0 !important; }

.deep-purple-900-bg {
  background-color: #311B92 !important; }

.md-deep-purple-900-bg {
  background-color: #311B92 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-900-fg {
  color: #311B92 !important; }

.deep-purple-900-border {
  border-color: #311B92 !important; }

.deep-purple-900-border-top {
  border-top-color: #311B92 !important; }

.deep-purple-900-border-right {
  border-right-color: #311B92 !important; }

.deep-purple-900-border-bottom {
  border-bottom-color: #311B92 !important; }

.deep-purple-900-border-left {
  border-left-color: #311B92 !important; }

.deep-purple-A100-bg {
  background-color: #B388FF !important; }

.md-deep-purple-A100-bg {
  background-color: #B388FF !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-A100-fg {
  color: #B388FF !important; }

.deep-purple-A100-border {
  border-color: #B388FF !important; }

.deep-purple-A100-border-top {
  border-top-color: #B388FF !important; }

.deep-purple-A100-border-right {
  border-right-color: #B388FF !important; }

.deep-purple-A100-border-bottom {
  border-bottom-color: #B388FF !important; }

.deep-purple-A100-border-left {
  border-left-color: #B388FF !important; }

.deep-purple-A200-bg {
  background-color: #7C4DFF !important; }

.md-deep-purple-A200-bg {
  background-color: #7C4DFF !important;
  color: white; }

.deep-purple-A200-fg {
  color: #7C4DFF !important; }

.deep-purple-A200-border {
  border-color: #7C4DFF !important; }

.deep-purple-A200-border-top {
  border-top-color: #7C4DFF !important; }

.deep-purple-A200-border-right {
  border-right-color: #7C4DFF !important; }

.deep-purple-A200-border-bottom {
  border-bottom-color: #7C4DFF !important; }

.deep-purple-A200-border-left {
  border-left-color: #7C4DFF !important; }

.deep-purple-A400-bg {
  background-color: #651FFF !important; }

.md-deep-purple-A400-bg {
  background-color: #651FFF !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A400-fg {
  color: #651FFF !important; }

.deep-purple-A400-border {
  border-color: #651FFF !important; }

.deep-purple-A400-border-top {
  border-top-color: #651FFF !important; }

.deep-purple-A400-border-right {
  border-right-color: #651FFF !important; }

.deep-purple-A400-border-bottom {
  border-bottom-color: #651FFF !important; }

.deep-purple-A400-border-left {
  border-left-color: #651FFF !important; }

.deep-purple-A700-bg {
  background-color: #6200EA !important; }

.md-deep-purple-A700-bg {
  background-color: #6200EA !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A700-fg {
  color: #6200EA !important; }

.deep-purple-A700-border {
  border-color: #6200EA !important; }

.deep-purple-A700-border-top {
  border-top-color: #6200EA !important; }

.deep-purple-A700-border-right {
  border-right-color: #6200EA !important; }

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200EA !important; }

.deep-purple-A700-border-left {
  border-left-color: #6200EA !important; }

.indigo-50-bg {
  background-color: #E8EAF6 !important; }

.md-indigo-50-bg {
  background-color: #E8EAF6 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-50-fg {
  color: #E8EAF6 !important; }

.indigo-50-border {
  border-color: #E8EAF6 !important; }

.indigo-50-border-top {
  border-top-color: #E8EAF6 !important; }

.indigo-50-border-right {
  border-right-color: #E8EAF6 !important; }

.indigo-50-border-bottom {
  border-bottom-color: #E8EAF6 !important; }

.indigo-50-border-left {
  border-left-color: #E8EAF6 !important; }

.indigo-100-bg {
  background-color: #C5CAE9 !important; }

.md-indigo-100-bg {
  background-color: #C5CAE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-100-fg {
  color: #C5CAE9 !important; }

.indigo-100-border {
  border-color: #C5CAE9 !important; }

.indigo-100-border-top {
  border-top-color: #C5CAE9 !important; }

.indigo-100-border-right {
  border-right-color: #C5CAE9 !important; }

.indigo-100-border-bottom {
  border-bottom-color: #C5CAE9 !important; }

.indigo-100-border-left {
  border-left-color: #C5CAE9 !important; }

.indigo-200-bg {
  background-color: #9FA8DA !important; }

.md-indigo-200-bg {
  background-color: #9FA8DA !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-200-fg {
  color: #9FA8DA !important; }

.indigo-200-border {
  border-color: #9FA8DA !important; }

.indigo-200-border-top {
  border-top-color: #9FA8DA !important; }

.indigo-200-border-right {
  border-right-color: #9FA8DA !important; }

.indigo-200-border-bottom {
  border-bottom-color: #9FA8DA !important; }

.indigo-200-border-left {
  border-left-color: #9FA8DA !important; }

.indigo-300-bg {
  background-color: #7986CB !important; }

.md-indigo-300-bg {
  background-color: #7986CB !important;
  color: white; }

.indigo-300-fg {
  color: #7986CB !important; }

.indigo-300-border {
  border-color: #7986CB !important; }

.indigo-300-border-top {
  border-top-color: #7986CB !important; }

.indigo-300-border-right {
  border-right-color: #7986CB !important; }

.indigo-300-border-bottom {
  border-bottom-color: #7986CB !important; }

.indigo-300-border-left {
  border-left-color: #7986CB !important; }

.indigo-400-bg {
  background-color: #5C6BC0 !important; }

.md-indigo-400-bg {
  background-color: #5C6BC0 !important;
  color: white; }

.indigo-400-fg {
  color: #5C6BC0 !important; }

.indigo-400-border {
  border-color: #5C6BC0 !important; }

.indigo-400-border-top {
  border-top-color: #5C6BC0 !important; }

.indigo-400-border-right {
  border-right-color: #5C6BC0 !important; }

.indigo-400-border-bottom {
  border-bottom-color: #5C6BC0 !important; }

.indigo-400-border-left {
  border-left-color: #5C6BC0 !important; }

.indigo-500-bg {
  background-color: #3F51B5 !important; }

.md-indigo-500-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-500-fg {
  color: #3F51B5 !important; }

.indigo-500-border {
  border-color: #3F51B5 !important; }

.indigo-500-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-500-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-500-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-500-border-left {
  border-left-color: #3F51B5 !important; }

.md-indigo-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-bg {
  background-color: #3F51B5 !important; }

.indigo-fg {
  color: #3F51B5 !important; }

.indigo-border {
  border-color: #3F51B5 !important; }

.indigo-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-border-left {
  border-left-color: #3F51B5 !important; }

.indigo-600-bg {
  background-color: #3949AB !important; }

.md-indigo-600-bg {
  background-color: #3949AB !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-600-fg {
  color: #3949AB !important; }

.indigo-600-border {
  border-color: #3949AB !important; }

.indigo-600-border-top {
  border-top-color: #3949AB !important; }

.indigo-600-border-right {
  border-right-color: #3949AB !important; }

.indigo-600-border-bottom {
  border-bottom-color: #3949AB !important; }

.indigo-600-border-left {
  border-left-color: #3949AB !important; }

.indigo-700-bg {
  background-color: #303F9F !important; }

.md-indigo-700-bg {
  background-color: #303F9F !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-700-fg {
  color: #303F9F !important; }

.indigo-700-border {
  border-color: #303F9F !important; }

.indigo-700-border-top {
  border-top-color: #303F9F !important; }

.indigo-700-border-right {
  border-right-color: #303F9F !important; }

.indigo-700-border-bottom {
  border-bottom-color: #303F9F !important; }

.indigo-700-border-left {
  border-left-color: #303F9F !important; }

.indigo-800-bg {
  background-color: #283593 !important; }

.md-indigo-800-bg {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-800-fg {
  color: #283593 !important; }

.indigo-800-border {
  border-color: #283593 !important; }

.indigo-800-border-top {
  border-top-color: #283593 !important; }

.indigo-800-border-right {
  border-right-color: #283593 !important; }

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important; }

.indigo-800-border-left {
  border-left-color: #283593 !important; }

.indigo-900-bg {
  background-color: #1A237E !important; }

.md-indigo-900-bg {
  background-color: #1A237E !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-900-fg {
  color: #1A237E !important; }

.indigo-900-border {
  border-color: #1A237E !important; }

.indigo-900-border-top {
  border-top-color: #1A237E !important; }

.indigo-900-border-right {
  border-right-color: #1A237E !important; }

.indigo-900-border-bottom {
  border-bottom-color: #1A237E !important; }

.indigo-900-border-left {
  border-left-color: #1A237E !important; }

.indigo-A100-bg {
  background-color: #8C9EFF !important; }

.md-indigo-A100-bg {
  background-color: #8C9EFF !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-A100-fg {
  color: #8C9EFF !important; }

.indigo-A100-border {
  border-color: #8C9EFF !important; }

.indigo-A100-border-top {
  border-top-color: #8C9EFF !important; }

.indigo-A100-border-right {
  border-right-color: #8C9EFF !important; }

.indigo-A100-border-bottom {
  border-bottom-color: #8C9EFF !important; }

.indigo-A100-border-left {
  border-left-color: #8C9EFF !important; }

.indigo-A200-bg {
  background-color: #536DFE !important; }

.md-indigo-A200-bg {
  background-color: #536DFE !important;
  color: white; }

.indigo-A200-fg {
  color: #536DFE !important; }

.indigo-A200-border {
  border-color: #536DFE !important; }

.indigo-A200-border-top {
  border-top-color: #536DFE !important; }

.indigo-A200-border-right {
  border-right-color: #536DFE !important; }

.indigo-A200-border-bottom {
  border-bottom-color: #536DFE !important; }

.indigo-A200-border-left {
  border-left-color: #536DFE !important; }

.indigo-A400-bg {
  background-color: #3D5AFE !important; }

.md-indigo-A400-bg {
  background-color: #3D5AFE !important;
  color: white; }

.indigo-A400-fg {
  color: #3D5AFE !important; }

.indigo-A400-border {
  border-color: #3D5AFE !important; }

.indigo-A400-border-top {
  border-top-color: #3D5AFE !important; }

.indigo-A400-border-right {
  border-right-color: #3D5AFE !important; }

.indigo-A400-border-bottom {
  border-bottom-color: #3D5AFE !important; }

.indigo-A400-border-left {
  border-left-color: #3D5AFE !important; }

.indigo-A700-bg {
  background-color: #304FFE !important; }

.md-indigo-A700-bg {
  background-color: #304FFE !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A700-fg {
  color: #304FFE !important; }

.indigo-A700-border {
  border-color: #304FFE !important; }

.indigo-A700-border-top {
  border-top-color: #304FFE !important; }

.indigo-A700-border-right {
  border-right-color: #304FFE !important; }

.indigo-A700-border-bottom {
  border-bottom-color: #304FFE !important; }

.indigo-A700-border-left {
  border-left-color: #304FFE !important; }

.blue-50-bg {
  background-color: #E3F2FD !important; }

.md-blue-50-bg {
  background-color: #E3F2FD !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-50-fg {
  color: #E3F2FD !important; }

.blue-50-border {
  border-color: #E3F2FD !important; }

.blue-50-border-top {
  border-top-color: #E3F2FD !important; }

.blue-50-border-right {
  border-right-color: #E3F2FD !important; }

.blue-50-border-bottom {
  border-bottom-color: #E3F2FD !important; }

.blue-50-border-left {
  border-left-color: #E3F2FD !important; }

.blue-100-bg {
  background-color: #BBDEFB !important; }

.md-blue-100-bg {
  background-color: #BBDEFB !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-100-fg {
  color: #BBDEFB !important; }

.blue-100-border {
  border-color: #BBDEFB !important; }

.blue-100-border-top {
  border-top-color: #BBDEFB !important; }

.blue-100-border-right {
  border-right-color: #BBDEFB !important; }

.blue-100-border-bottom {
  border-bottom-color: #BBDEFB !important; }

.blue-100-border-left {
  border-left-color: #BBDEFB !important; }

.blue-200-bg {
  background-color: #90CAF9 !important; }

.md-blue-200-bg {
  background-color: #90CAF9 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-200-fg {
  color: #90CAF9 !important; }

.blue-200-border {
  border-color: #90CAF9 !important; }

.blue-200-border-top {
  border-top-color: #90CAF9 !important; }

.blue-200-border-right {
  border-right-color: #90CAF9 !important; }

.blue-200-border-bottom {
  border-bottom-color: #90CAF9 !important; }

.blue-200-border-left {
  border-left-color: #90CAF9 !important; }

.blue-300-bg {
  background-color: #64B5F6 !important; }

.md-blue-300-bg {
  background-color: #64B5F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-300-fg {
  color: #64B5F6 !important; }

.blue-300-border {
  border-color: #64B5F6 !important; }

.blue-300-border-top {
  border-top-color: #64B5F6 !important; }

.blue-300-border-right {
  border-right-color: #64B5F6 !important; }

.blue-300-border-bottom {
  border-bottom-color: #64B5F6 !important; }

.blue-300-border-left {
  border-left-color: #64B5F6 !important; }

.blue-400-bg {
  background-color: #42A5F5 !important; }

.md-blue-400-bg {
  background-color: #42A5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-400-fg {
  color: #42A5F5 !important; }

.blue-400-border {
  border-color: #42A5F5 !important; }

.blue-400-border-top {
  border-top-color: #42A5F5 !important; }

.blue-400-border-right {
  border-right-color: #42A5F5 !important; }

.blue-400-border-bottom {
  border-bottom-color: #42A5F5 !important; }

.blue-400-border-left {
  border-left-color: #42A5F5 !important; }

.blue-500-bg {
  background-color: #2196F3 !important; }

.md-blue-500-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-500-fg {
  color: #2196F3 !important; }

.blue-500-border {
  border-color: #2196F3 !important; }

.blue-500-border-top {
  border-top-color: #2196F3 !important; }

.blue-500-border-right {
  border-right-color: #2196F3 !important; }

.blue-500-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-500-border-left {
  border-left-color: #2196F3 !important; }

.md-blue-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-bg {
  background-color: #2196F3 !important; }

.blue-fg {
  color: #2196F3 !important; }

.blue-border {
  border-color: #2196F3 !important; }

.blue-border-top {
  border-top-color: #2196F3 !important; }

.blue-border-right {
  border-right-color: #2196F3 !important; }

.blue-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-border-left {
  border-left-color: #2196F3 !important; }

.blue-600-bg {
  background-color: #1E88E5 !important; }

.md-blue-600-bg {
  background-color: #1E88E5 !important;
  color: white; }

.blue-600-fg {
  color: #1E88E5 !important; }

.blue-600-border {
  border-color: #1E88E5 !important; }

.blue-600-border-top {
  border-top-color: #1E88E5 !important; }

.blue-600-border-right {
  border-right-color: #1E88E5 !important; }

.blue-600-border-bottom {
  border-bottom-color: #1E88E5 !important; }

.blue-600-border-left {
  border-left-color: #1E88E5 !important; }

.blue-700-bg {
  background-color: #1976D2 !important; }

.md-blue-700-bg {
  background-color: #1976D2 !important;
  color: white; }

.blue-700-fg {
  color: #1976D2 !important; }

.blue-700-border {
  border-color: #1976D2 !important; }

.blue-700-border-top {
  border-top-color: #1976D2 !important; }

.blue-700-border-right {
  border-right-color: #1976D2 !important; }

.blue-700-border-bottom {
  border-bottom-color: #1976D2 !important; }

.blue-700-border-left {
  border-left-color: #1976D2 !important; }

.blue-800-bg {
  background-color: #1565C0 !important; }

.md-blue-800-bg {
  background-color: #1565C0 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-800-fg {
  color: #1565C0 !important; }

.blue-800-border {
  border-color: #1565C0 !important; }

.blue-800-border-top {
  border-top-color: #1565C0 !important; }

.blue-800-border-right {
  border-right-color: #1565C0 !important; }

.blue-800-border-bottom {
  border-bottom-color: #1565C0 !important; }

.blue-800-border-left {
  border-left-color: #1565C0 !important; }

.blue-900-bg {
  background-color: #0D47A1 !important; }

.md-blue-900-bg {
  background-color: #0D47A1 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-900-fg {
  color: #0D47A1 !important; }

.blue-900-border {
  border-color: #0D47A1 !important; }

.blue-900-border-top {
  border-top-color: #0D47A1 !important; }

.blue-900-border-right {
  border-right-color: #0D47A1 !important; }

.blue-900-border-bottom {
  border-bottom-color: #0D47A1 !important; }

.blue-900-border-left {
  border-left-color: #0D47A1 !important; }

.blue-A100-bg {
  background-color: #82B1FF !important; }

.md-blue-A100-bg {
  background-color: #82B1FF !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-A100-fg {
  color: #82B1FF !important; }

.blue-A100-border {
  border-color: #82B1FF !important; }

.blue-A100-border-top {
  border-top-color: #82B1FF !important; }

.blue-A100-border-right {
  border-right-color: #82B1FF !important; }

.blue-A100-border-bottom {
  border-bottom-color: #82B1FF !important; }

.blue-A100-border-left {
  border-left-color: #82B1FF !important; }

.blue-A200-bg {
  background-color: #448AFF !important; }

.md-blue-A200-bg {
  background-color: #448AFF !important;
  color: white; }

.blue-A200-fg {
  color: #448AFF !important; }

.blue-A200-border {
  border-color: #448AFF !important; }

.blue-A200-border-top {
  border-top-color: #448AFF !important; }

.blue-A200-border-right {
  border-right-color: #448AFF !important; }

.blue-A200-border-bottom {
  border-bottom-color: #448AFF !important; }

.blue-A200-border-left {
  border-left-color: #448AFF !important; }

.blue-A400-bg {
  background-color: #2979FF !important; }

.md-blue-A400-bg {
  background-color: #2979FF !important;
  color: white; }

.blue-A400-fg {
  color: #2979FF !important; }

.blue-A400-border {
  border-color: #2979FF !important; }

.blue-A400-border-top {
  border-top-color: #2979FF !important; }

.blue-A400-border-right {
  border-right-color: #2979FF !important; }

.blue-A400-border-bottom {
  border-bottom-color: #2979FF !important; }

.blue-A400-border-left {
  border-left-color: #2979FF !important; }

.blue-A700-bg {
  background-color: #2962FF !important; }

.md-blue-A700-bg {
  background-color: #2962FF !important;
  color: white; }

.blue-A700-fg {
  color: #2962FF !important; }

.blue-A700-border {
  border-color: #2962FF !important; }

.blue-A700-border-top {
  border-top-color: #2962FF !important; }

.blue-A700-border-right {
  border-right-color: #2962FF !important; }

.blue-A700-border-bottom {
  border-bottom-color: #2962FF !important; }

.blue-A700-border-left {
  border-left-color: #2962FF !important; }

.light-blue-50-bg {
  background-color: #E1F5FE !important; }

.md-light-blue-50-bg {
  background-color: #E1F5FE !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-50-fg {
  color: #E1F5FE !important; }

.light-blue-50-border {
  border-color: #E1F5FE !important; }

.light-blue-50-border-top {
  border-top-color: #E1F5FE !important; }

.light-blue-50-border-right {
  border-right-color: #E1F5FE !important; }

.light-blue-50-border-bottom {
  border-bottom-color: #E1F5FE !important; }

.light-blue-50-border-left {
  border-left-color: #E1F5FE !important; }

.light-blue-100-bg {
  background-color: #B3E5FC !important; }

.md-light-blue-100-bg {
  background-color: #B3E5FC !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-100-fg {
  color: #B3E5FC !important; }

.light-blue-100-border {
  border-color: #B3E5FC !important; }

.light-blue-100-border-top {
  border-top-color: #B3E5FC !important; }

.light-blue-100-border-right {
  border-right-color: #B3E5FC !important; }

.light-blue-100-border-bottom {
  border-bottom-color: #B3E5FC !important; }

.light-blue-100-border-left {
  border-left-color: #B3E5FC !important; }

.light-blue-200-bg {
  background-color: #81D4FA !important; }

.md-light-blue-200-bg {
  background-color: #81D4FA !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-200-fg {
  color: #81D4FA !important; }

.light-blue-200-border {
  border-color: #81D4FA !important; }

.light-blue-200-border-top {
  border-top-color: #81D4FA !important; }

.light-blue-200-border-right {
  border-right-color: #81D4FA !important; }

.light-blue-200-border-bottom {
  border-bottom-color: #81D4FA !important; }

.light-blue-200-border-left {
  border-left-color: #81D4FA !important; }

.light-blue-300-bg {
  background-color: #4FC3F7 !important; }

.md-light-blue-300-bg {
  background-color: #4FC3F7 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-300-fg {
  color: #4FC3F7 !important; }

.light-blue-300-border {
  border-color: #4FC3F7 !important; }

.light-blue-300-border-top {
  border-top-color: #4FC3F7 !important; }

.light-blue-300-border-right {
  border-right-color: #4FC3F7 !important; }

.light-blue-300-border-bottom {
  border-bottom-color: #4FC3F7 !important; }

.light-blue-300-border-left {
  border-left-color: #4FC3F7 !important; }

.light-blue-400-bg {
  background-color: #29B6F6 !important; }

.md-light-blue-400-bg {
  background-color: #29B6F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-400-fg {
  color: #29B6F6 !important; }

.light-blue-400-border {
  border-color: #29B6F6 !important; }

.light-blue-400-border-top {
  border-top-color: #29B6F6 !important; }

.light-blue-400-border-right {
  border-right-color: #29B6F6 !important; }

.light-blue-400-border-bottom {
  border-bottom-color: #29B6F6 !important; }

.light-blue-400-border-left {
  border-left-color: #29B6F6 !important; }

.light-blue-500-bg {
  background-color: #03A9F4 !important; }

.md-light-blue-500-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-500-fg {
  color: #03A9F4 !important; }

.light-blue-500-border {
  border-color: #03A9F4 !important; }

.light-blue-500-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-500-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-500-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-500-border-left {
  border-left-color: #03A9F4 !important; }

.md-light-blue-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-bg {
  background-color: #03A9F4 !important; }

.light-blue-fg {
  color: #03A9F4 !important; }

.light-blue-border {
  border-color: #03A9F4 !important; }

.light-blue-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-border-left {
  border-left-color: #03A9F4 !important; }

.light-blue-600-bg {
  background-color: #039BE5 !important; }

.md-light-blue-600-bg {
  background-color: #039BE5 !important;
  color: white; }

.light-blue-600-fg {
  color: #039BE5 !important; }

.light-blue-600-border {
  border-color: #039BE5 !important; }

.light-blue-600-border-top {
  border-top-color: #039BE5 !important; }

.light-blue-600-border-right {
  border-right-color: #039BE5 !important; }

.light-blue-600-border-bottom {
  border-bottom-color: #039BE5 !important; }

.light-blue-600-border-left {
  border-left-color: #039BE5 !important; }

.light-blue-700-bg {
  background-color: #0288D1 !important; }

.md-light-blue-700-bg {
  background-color: #0288D1 !important;
  color: white; }

.light-blue-700-fg {
  color: #0288D1 !important; }

.light-blue-700-border {
  border-color: #0288D1 !important; }

.light-blue-700-border-top {
  border-top-color: #0288D1 !important; }

.light-blue-700-border-right {
  border-right-color: #0288D1 !important; }

.light-blue-700-border-bottom {
  border-bottom-color: #0288D1 !important; }

.light-blue-700-border-left {
  border-left-color: #0288D1 !important; }

.light-blue-800-bg {
  background-color: #0277BD !important; }

.md-light-blue-800-bg {
  background-color: #0277BD !important;
  color: white; }

.light-blue-800-fg {
  color: #0277BD !important; }

.light-blue-800-border {
  border-color: #0277BD !important; }

.light-blue-800-border-top {
  border-top-color: #0277BD !important; }

.light-blue-800-border-right {
  border-right-color: #0277BD !important; }

.light-blue-800-border-bottom {
  border-bottom-color: #0277BD !important; }

.light-blue-800-border-left {
  border-left-color: #0277BD !important; }

.light-blue-900-bg {
  background-color: #01579B !important; }

.md-light-blue-900-bg {
  background-color: #01579B !important;
  color: white; }

.light-blue-900-fg {
  color: #01579B !important; }

.light-blue-900-border {
  border-color: #01579B !important; }

.light-blue-900-border-top {
  border-top-color: #01579B !important; }

.light-blue-900-border-right {
  border-right-color: #01579B !important; }

.light-blue-900-border-bottom {
  border-bottom-color: #01579B !important; }

.light-blue-900-border-left {
  border-left-color: #01579B !important; }

.light-blue-A100-bg {
  background-color: #80D8FF !important; }

.md-light-blue-A100-bg {
  background-color: #80D8FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A100-fg {
  color: #80D8FF !important; }

.light-blue-A100-border {
  border-color: #80D8FF !important; }

.light-blue-A100-border-top {
  border-top-color: #80D8FF !important; }

.light-blue-A100-border-right {
  border-right-color: #80D8FF !important; }

.light-blue-A100-border-bottom {
  border-bottom-color: #80D8FF !important; }

.light-blue-A100-border-left {
  border-left-color: #80D8FF !important; }

.light-blue-A200-bg {
  background-color: #40C4FF !important; }

.md-light-blue-A200-bg {
  background-color: #40C4FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A200-fg {
  color: #40C4FF !important; }

.light-blue-A200-border {
  border-color: #40C4FF !important; }

.light-blue-A200-border-top {
  border-top-color: #40C4FF !important; }

.light-blue-A200-border-right {
  border-right-color: #40C4FF !important; }

.light-blue-A200-border-bottom {
  border-bottom-color: #40C4FF !important; }

.light-blue-A200-border-left {
  border-left-color: #40C4FF !important; }

.light-blue-A400-bg {
  background-color: #00B0FF !important; }

.md-light-blue-A400-bg {
  background-color: #00B0FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A400-fg {
  color: #00B0FF !important; }

.light-blue-A400-border {
  border-color: #00B0FF !important; }

.light-blue-A400-border-top {
  border-top-color: #00B0FF !important; }

.light-blue-A400-border-right {
  border-right-color: #00B0FF !important; }

.light-blue-A400-border-bottom {
  border-bottom-color: #00B0FF !important; }

.light-blue-A400-border-left {
  border-left-color: #00B0FF !important; }

.light-blue-A700-bg {
  background-color: #0091EA !important; }

.md-light-blue-A700-bg {
  background-color: #0091EA !important;
  color: white; }

.light-blue-A700-fg {
  color: #0091EA !important; }

.light-blue-A700-border {
  border-color: #0091EA !important; }

.light-blue-A700-border-top {
  border-top-color: #0091EA !important; }

.light-blue-A700-border-right {
  border-right-color: #0091EA !important; }

.light-blue-A700-border-bottom {
  border-bottom-color: #0091EA !important; }

.light-blue-A700-border-left {
  border-left-color: #0091EA !important; }

.cyan-50-bg {
  background-color: #E0F7FA !important; }

.md-cyan-50-bg {
  background-color: #E0F7FA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-50-fg {
  color: #E0F7FA !important; }

.cyan-50-border {
  border-color: #E0F7FA !important; }

.cyan-50-border-top {
  border-top-color: #E0F7FA !important; }

.cyan-50-border-right {
  border-right-color: #E0F7FA !important; }

.cyan-50-border-bottom {
  border-bottom-color: #E0F7FA !important; }

.cyan-50-border-left {
  border-left-color: #E0F7FA !important; }

.cyan-100-bg {
  background-color: #B2EBF2 !important; }

.md-cyan-100-bg {
  background-color: #B2EBF2 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-100-fg {
  color: #B2EBF2 !important; }

.cyan-100-border {
  border-color: #B2EBF2 !important; }

.cyan-100-border-top {
  border-top-color: #B2EBF2 !important; }

.cyan-100-border-right {
  border-right-color: #B2EBF2 !important; }

.cyan-100-border-bottom {
  border-bottom-color: #B2EBF2 !important; }

.cyan-100-border-left {
  border-left-color: #B2EBF2 !important; }

.cyan-200-bg {
  background-color: #80DEEA !important; }

.md-cyan-200-bg {
  background-color: #80DEEA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-200-fg {
  color: #80DEEA !important; }

.cyan-200-border {
  border-color: #80DEEA !important; }

.cyan-200-border-top {
  border-top-color: #80DEEA !important; }

.cyan-200-border-right {
  border-right-color: #80DEEA !important; }

.cyan-200-border-bottom {
  border-bottom-color: #80DEEA !important; }

.cyan-200-border-left {
  border-left-color: #80DEEA !important; }

.cyan-300-bg {
  background-color: #4DD0E1 !important; }

.md-cyan-300-bg {
  background-color: #4DD0E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-300-fg {
  color: #4DD0E1 !important; }

.cyan-300-border {
  border-color: #4DD0E1 !important; }

.cyan-300-border-top {
  border-top-color: #4DD0E1 !important; }

.cyan-300-border-right {
  border-right-color: #4DD0E1 !important; }

.cyan-300-border-bottom {
  border-bottom-color: #4DD0E1 !important; }

.cyan-300-border-left {
  border-left-color: #4DD0E1 !important; }

.cyan-400-bg {
  background-color: #26C6DA !important; }

.md-cyan-400-bg {
  background-color: #26C6DA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-400-fg {
  color: #26C6DA !important; }

.cyan-400-border {
  border-color: #26C6DA !important; }

.cyan-400-border-top {
  border-top-color: #26C6DA !important; }

.cyan-400-border-right {
  border-right-color: #26C6DA !important; }

.cyan-400-border-bottom {
  border-bottom-color: #26C6DA !important; }

.cyan-400-border-left {
  border-left-color: #26C6DA !important; }

.cyan-500-bg {
  background-color: #00BCD4 !important; }

.md-cyan-500-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-500-fg {
  color: #00BCD4 !important; }

.cyan-500-border {
  border-color: #00BCD4 !important; }

.cyan-500-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-500-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-500-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-500-border-left {
  border-left-color: #00BCD4 !important; }

.md-cyan-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-bg {
  background-color: #00BCD4 !important; }

.cyan-fg {
  color: #00BCD4 !important; }

.cyan-border {
  border-color: #00BCD4 !important; }

.cyan-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-border-left {
  border-left-color: #00BCD4 !important; }

.cyan-600-bg {
  background-color: #00ACC1 !important; }

.md-cyan-600-bg {
  background-color: #00ACC1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-600-fg {
  color: #00ACC1 !important; }

.cyan-600-border {
  border-color: #00ACC1 !important; }

.cyan-600-border-top {
  border-top-color: #00ACC1 !important; }

.cyan-600-border-right {
  border-right-color: #00ACC1 !important; }

.cyan-600-border-bottom {
  border-bottom-color: #00ACC1 !important; }

.cyan-600-border-left {
  border-left-color: #00ACC1 !important; }

.cyan-700-bg {
  background-color: #0097A7 !important; }

.md-cyan-700-bg {
  background-color: #0097A7 !important;
  color: white; }

.cyan-700-fg {
  color: #0097A7 !important; }

.cyan-700-border {
  border-color: #0097A7 !important; }

.cyan-700-border-top {
  border-top-color: #0097A7 !important; }

.cyan-700-border-right {
  border-right-color: #0097A7 !important; }

.cyan-700-border-bottom {
  border-bottom-color: #0097A7 !important; }

.cyan-700-border-left {
  border-left-color: #0097A7 !important; }

.cyan-800-bg {
  background-color: #00838F !important; }

.md-cyan-800-bg {
  background-color: #00838F !important;
  color: white; }

.cyan-800-fg {
  color: #00838F !important; }

.cyan-800-border {
  border-color: #00838F !important; }

.cyan-800-border-top {
  border-top-color: #00838F !important; }

.cyan-800-border-right {
  border-right-color: #00838F !important; }

.cyan-800-border-bottom {
  border-bottom-color: #00838F !important; }

.cyan-800-border-left {
  border-left-color: #00838F !important; }

.cyan-900-bg {
  background-color: #006064 !important; }

.md-cyan-900-bg {
  background-color: #006064 !important;
  color: white; }

.cyan-900-fg {
  color: #006064 !important; }

.cyan-900-border {
  border-color: #006064 !important; }

.cyan-900-border-top {
  border-top-color: #006064 !important; }

.cyan-900-border-right {
  border-right-color: #006064 !important; }

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important; }

.cyan-900-border-left {
  border-left-color: #006064 !important; }

.cyan-A100-bg {
  background-color: #84FFFF !important; }

.md-cyan-A100-bg {
  background-color: #84FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A100-fg {
  color: #84FFFF !important; }

.cyan-A100-border {
  border-color: #84FFFF !important; }

.cyan-A100-border-top {
  border-top-color: #84FFFF !important; }

.cyan-A100-border-right {
  border-right-color: #84FFFF !important; }

.cyan-A100-border-bottom {
  border-bottom-color: #84FFFF !important; }

.cyan-A100-border-left {
  border-left-color: #84FFFF !important; }

.cyan-A200-bg {
  background-color: #18FFFF !important; }

.md-cyan-A200-bg {
  background-color: #18FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A200-fg {
  color: #18FFFF !important; }

.cyan-A200-border {
  border-color: #18FFFF !important; }

.cyan-A200-border-top {
  border-top-color: #18FFFF !important; }

.cyan-A200-border-right {
  border-right-color: #18FFFF !important; }

.cyan-A200-border-bottom {
  border-bottom-color: #18FFFF !important; }

.cyan-A200-border-left {
  border-left-color: #18FFFF !important; }

.cyan-A400-bg {
  background-color: #00E5FF !important; }

.md-cyan-A400-bg {
  background-color: #00E5FF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A400-fg {
  color: #00E5FF !important; }

.cyan-A400-border {
  border-color: #00E5FF !important; }

.cyan-A400-border-top {
  border-top-color: #00E5FF !important; }

.cyan-A400-border-right {
  border-right-color: #00E5FF !important; }

.cyan-A400-border-bottom {
  border-bottom-color: #00E5FF !important; }

.cyan-A400-border-left {
  border-left-color: #00E5FF !important; }

.cyan-A700-bg {
  background-color: #00B8D4 !important; }

.md-cyan-A700-bg {
  background-color: #00B8D4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A700-fg {
  color: #00B8D4 !important; }

.cyan-A700-border {
  border-color: #00B8D4 !important; }

.cyan-A700-border-top {
  border-top-color: #00B8D4 !important; }

.cyan-A700-border-right {
  border-right-color: #00B8D4 !important; }

.cyan-A700-border-bottom {
  border-bottom-color: #00B8D4 !important; }

.cyan-A700-border-left {
  border-left-color: #00B8D4 !important; }

.teal-50-bg {
  background-color: #E0F2F1 !important; }

.md-teal-50-bg {
  background-color: #E0F2F1 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-50-fg {
  color: #E0F2F1 !important; }

.teal-50-border {
  border-color: #E0F2F1 !important; }

.teal-50-border-top {
  border-top-color: #E0F2F1 !important; }

.teal-50-border-right {
  border-right-color: #E0F2F1 !important; }

.teal-50-border-bottom {
  border-bottom-color: #E0F2F1 !important; }

.teal-50-border-left {
  border-left-color: #E0F2F1 !important; }

.teal-100-bg {
  background-color: #B2DFDB !important; }

.md-teal-100-bg {
  background-color: #B2DFDB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-100-fg {
  color: #B2DFDB !important; }

.teal-100-border {
  border-color: #B2DFDB !important; }

.teal-100-border-top {
  border-top-color: #B2DFDB !important; }

.teal-100-border-right {
  border-right-color: #B2DFDB !important; }

.teal-100-border-bottom {
  border-bottom-color: #B2DFDB !important; }

.teal-100-border-left {
  border-left-color: #B2DFDB !important; }

.teal-200-bg {
  background-color: #80CBC4 !important; }

.md-teal-200-bg {
  background-color: #80CBC4 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-200-fg {
  color: #80CBC4 !important; }

.teal-200-border {
  border-color: #80CBC4 !important; }

.teal-200-border-top {
  border-top-color: #80CBC4 !important; }

.teal-200-border-right {
  border-right-color: #80CBC4 !important; }

.teal-200-border-bottom {
  border-bottom-color: #80CBC4 !important; }

.teal-200-border-left {
  border-left-color: #80CBC4 !important; }

.teal-300-bg {
  background-color: #4DB6AC !important; }

.md-teal-300-bg {
  background-color: #4DB6AC !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-300-fg {
  color: #4DB6AC !important; }

.teal-300-border {
  border-color: #4DB6AC !important; }

.teal-300-border-top {
  border-top-color: #4DB6AC !important; }

.teal-300-border-right {
  border-right-color: #4DB6AC !important; }

.teal-300-border-bottom {
  border-bottom-color: #4DB6AC !important; }

.teal-300-border-left {
  border-left-color: #4DB6AC !important; }

.teal-400-bg {
  background-color: #26A69A !important; }

.md-teal-400-bg {
  background-color: #26A69A !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-400-fg {
  color: #26A69A !important; }

.teal-400-border {
  border-color: #26A69A !important; }

.teal-400-border-top {
  border-top-color: #26A69A !important; }

.teal-400-border-right {
  border-right-color: #26A69A !important; }

.teal-400-border-bottom {
  border-bottom-color: #26A69A !important; }

.teal-400-border-left {
  border-left-color: #26A69A !important; }

.teal-500-bg {
  background-color: #009688 !important; }

.md-teal-500-bg {
  background-color: #009688 !important;
  color: white; }

.teal-500-fg {
  color: #009688 !important; }

.teal-500-border {
  border-color: #009688 !important; }

.teal-500-border-top {
  border-top-color: #009688 !important; }

.teal-500-border-right {
  border-right-color: #009688 !important; }

.teal-500-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-500-border-left {
  border-left-color: #009688 !important; }

.md-teal-bg {
  background-color: #009688 !important;
  color: white; }

.teal-bg {
  background-color: #009688 !important; }

.teal-fg {
  color: #009688 !important; }

.teal-border {
  border-color: #009688 !important; }

.teal-border-top {
  border-top-color: #009688 !important; }

.teal-border-right {
  border-right-color: #009688 !important; }

.teal-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-border-left {
  border-left-color: #009688 !important; }

.teal-600-bg {
  background-color: #00897B !important; }

.md-teal-600-bg {
  background-color: #00897B !important;
  color: white; }

.teal-600-fg {
  color: #00897B !important; }

.teal-600-border {
  border-color: #00897B !important; }

.teal-600-border-top {
  border-top-color: #00897B !important; }

.teal-600-border-right {
  border-right-color: #00897B !important; }

.teal-600-border-bottom {
  border-bottom-color: #00897B !important; }

.teal-600-border-left {
  border-left-color: #00897B !important; }

.teal-700-bg {
  background-color: #00796B !important; }

.md-teal-700-bg {
  background-color: #00796B !important;
  color: white; }

.teal-700-fg {
  color: #00796B !important; }

.teal-700-border {
  border-color: #00796B !important; }

.teal-700-border-top {
  border-top-color: #00796B !important; }

.teal-700-border-right {
  border-right-color: #00796B !important; }

.teal-700-border-bottom {
  border-bottom-color: #00796B !important; }

.teal-700-border-left {
  border-left-color: #00796B !important; }

.teal-800-bg {
  background-color: #00695C !important; }

.md-teal-800-bg {
  background-color: #00695C !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-800-fg {
  color: #00695C !important; }

.teal-800-border {
  border-color: #00695C !important; }

.teal-800-border-top {
  border-top-color: #00695C !important; }

.teal-800-border-right {
  border-right-color: #00695C !important; }

.teal-800-border-bottom {
  border-bottom-color: #00695C !important; }

.teal-800-border-left {
  border-left-color: #00695C !important; }

.teal-900-bg {
  background-color: #004D40 !important; }

.md-teal-900-bg {
  background-color: #004D40 !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-900-fg {
  color: #004D40 !important; }

.teal-900-border {
  border-color: #004D40 !important; }

.teal-900-border-top {
  border-top-color: #004D40 !important; }

.teal-900-border-right {
  border-right-color: #004D40 !important; }

.teal-900-border-bottom {
  border-bottom-color: #004D40 !important; }

.teal-900-border-left {
  border-left-color: #004D40 !important; }

.teal-A100-bg {
  background-color: #A7FFEB !important; }

.md-teal-A100-bg {
  background-color: #A7FFEB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A100-fg {
  color: #A7FFEB !important; }

.teal-A100-border {
  border-color: #A7FFEB !important; }

.teal-A100-border-top {
  border-top-color: #A7FFEB !important; }

.teal-A100-border-right {
  border-right-color: #A7FFEB !important; }

.teal-A100-border-bottom {
  border-bottom-color: #A7FFEB !important; }

.teal-A100-border-left {
  border-left-color: #A7FFEB !important; }

.teal-A200-bg {
  background-color: #64FFDA !important; }

.md-teal-A200-bg {
  background-color: #64FFDA !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A200-fg {
  color: #64FFDA !important; }

.teal-A200-border {
  border-color: #64FFDA !important; }

.teal-A200-border-top {
  border-top-color: #64FFDA !important; }

.teal-A200-border-right {
  border-right-color: #64FFDA !important; }

.teal-A200-border-bottom {
  border-bottom-color: #64FFDA !important; }

.teal-A200-border-left {
  border-left-color: #64FFDA !important; }

.teal-A400-bg {
  background-color: #1DE9B6 !important; }

.md-teal-A400-bg {
  background-color: #1DE9B6 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A400-fg {
  color: #1DE9B6 !important; }

.teal-A400-border {
  border-color: #1DE9B6 !important; }

.teal-A400-border-top {
  border-top-color: #1DE9B6 !important; }

.teal-A400-border-right {
  border-right-color: #1DE9B6 !important; }

.teal-A400-border-bottom {
  border-bottom-color: #1DE9B6 !important; }

.teal-A400-border-left {
  border-left-color: #1DE9B6 !important; }

.teal-A700-bg {
  background-color: #00BFA5 !important; }

.md-teal-A700-bg {
  background-color: #00BFA5 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A700-fg {
  color: #00BFA5 !important; }

.teal-A700-border {
  border-color: #00BFA5 !important; }

.teal-A700-border-top {
  border-top-color: #00BFA5 !important; }

.teal-A700-border-right {
  border-right-color: #00BFA5 !important; }

.teal-A700-border-bottom {
  border-bottom-color: #00BFA5 !important; }

.teal-A700-border-left {
  border-left-color: #00BFA5 !important; }

.green-50-bg {
  background-color: #E8F5E9 !important; }

.md-green-50-bg {
  background-color: #E8F5E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-50-fg {
  color: #E8F5E9 !important; }

.green-50-border {
  border-color: #E8F5E9 !important; }

.green-50-border-top {
  border-top-color: #E8F5E9 !important; }

.green-50-border-right {
  border-right-color: #E8F5E9 !important; }

.green-50-border-bottom {
  border-bottom-color: #E8F5E9 !important; }

.green-50-border-left {
  border-left-color: #E8F5E9 !important; }

.green-100-bg {
  background-color: #C8E6C9 !important; }

.md-green-100-bg {
  background-color: #C8E6C9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-100-fg {
  color: #C8E6C9 !important; }

.green-100-border {
  border-color: #C8E6C9 !important; }

.green-100-border-top {
  border-top-color: #C8E6C9 !important; }

.green-100-border-right {
  border-right-color: #C8E6C9 !important; }

.green-100-border-bottom {
  border-bottom-color: #C8E6C9 !important; }

.green-100-border-left {
  border-left-color: #C8E6C9 !important; }

.green-200-bg {
  background-color: #A5D6A7 !important; }

.md-green-200-bg {
  background-color: #A5D6A7 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-200-fg {
  color: #A5D6A7 !important; }

.green-200-border {
  border-color: #A5D6A7 !important; }

.green-200-border-top {
  border-top-color: #A5D6A7 !important; }

.green-200-border-right {
  border-right-color: #A5D6A7 !important; }

.green-200-border-bottom {
  border-bottom-color: #A5D6A7 !important; }

.green-200-border-left {
  border-left-color: #A5D6A7 !important; }

.green-300-bg {
  background-color: #81C784 !important; }

.md-green-300-bg {
  background-color: #81C784 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-300-fg {
  color: #81C784 !important; }

.green-300-border {
  border-color: #81C784 !important; }

.green-300-border-top {
  border-top-color: #81C784 !important; }

.green-300-border-right {
  border-right-color: #81C784 !important; }

.green-300-border-bottom {
  border-bottom-color: #81C784 !important; }

.green-300-border-left {
  border-left-color: #81C784 !important; }

.green-400-bg {
  background-color: #66BB6A !important; }

.md-green-400-bg {
  background-color: #66BB6A !important;
  color: rgba(0, 0, 0, 0.87); }

.green-400-fg {
  color: #66BB6A !important; }

.green-400-border {
  border-color: #66BB6A !important; }

.green-400-border-top {
  border-top-color: #66BB6A !important; }

.green-400-border-right {
  border-right-color: #66BB6A !important; }

.green-400-border-bottom {
  border-bottom-color: #66BB6A !important; }

.green-400-border-left {
  border-left-color: #66BB6A !important; }

.green-500-bg {
  background-color: #4CAF50 !important; }

.md-green-500-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-500-fg {
  color: #4CAF50 !important; }

.green-500-border {
  border-color: #4CAF50 !important; }

.green-500-border-top {
  border-top-color: #4CAF50 !important; }

.green-500-border-right {
  border-right-color: #4CAF50 !important; }

.green-500-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-500-border-left {
  border-left-color: #4CAF50 !important; }

.md-green-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-bg {
  background-color: #4CAF50 !important; }

.green-fg {
  color: #4CAF50 !important; }

.green-border {
  border-color: #4CAF50 !important; }

.green-border-top {
  border-top-color: #4CAF50 !important; }

.green-border-right {
  border-right-color: #4CAF50 !important; }

.green-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-border-left {
  border-left-color: #4CAF50 !important; }

.green-600-bg {
  background-color: #43A047 !important; }

.md-green-600-bg {
  background-color: #43A047 !important;
  color: white; }

.green-600-fg {
  color: #43A047 !important; }

.green-600-border {
  border-color: #43A047 !important; }

.green-600-border-top {
  border-top-color: #43A047 !important; }

.green-600-border-right {
  border-right-color: #43A047 !important; }

.green-600-border-bottom {
  border-bottom-color: #43A047 !important; }

.green-600-border-left {
  border-left-color: #43A047 !important; }

.green-700-bg {
  background-color: #388E3C !important; }

.md-green-700-bg {
  background-color: #388E3C !important;
  color: white; }

.green-700-fg {
  color: #388E3C !important; }

.green-700-border {
  border-color: #388E3C !important; }

.green-700-border-top {
  border-top-color: #388E3C !important; }

.green-700-border-right {
  border-right-color: #388E3C !important; }

.green-700-border-bottom {
  border-bottom-color: #388E3C !important; }

.green-700-border-left {
  border-left-color: #388E3C !important; }

.green-800-bg {
  background-color: #2E7D32 !important; }

.md-green-800-bg {
  background-color: #2E7D32 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-800-fg {
  color: #2E7D32 !important; }

.green-800-border {
  border-color: #2E7D32 !important; }

.green-800-border-top {
  border-top-color: #2E7D32 !important; }

.green-800-border-right {
  border-right-color: #2E7D32 !important; }

.green-800-border-bottom {
  border-bottom-color: #2E7D32 !important; }

.green-800-border-left {
  border-left-color: #2E7D32 !important; }

.green-900-bg {
  background-color: #1B5E20 !important; }

.md-green-900-bg {
  background-color: #1B5E20 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-900-fg {
  color: #1B5E20 !important; }

.green-900-border {
  border-color: #1B5E20 !important; }

.green-900-border-top {
  border-top-color: #1B5E20 !important; }

.green-900-border-right {
  border-right-color: #1B5E20 !important; }

.green-900-border-bottom {
  border-bottom-color: #1B5E20 !important; }

.green-900-border-left {
  border-left-color: #1B5E20 !important; }

.green-A100-bg {
  background-color: #B9F6CA !important; }

.md-green-A100-bg {
  background-color: #B9F6CA !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A100-fg {
  color: #B9F6CA !important; }

.green-A100-border {
  border-color: #B9F6CA !important; }

.green-A100-border-top {
  border-top-color: #B9F6CA !important; }

.green-A100-border-right {
  border-right-color: #B9F6CA !important; }

.green-A100-border-bottom {
  border-bottom-color: #B9F6CA !important; }

.green-A100-border-left {
  border-left-color: #B9F6CA !important; }

.green-A200-bg {
  background-color: #69F0AE !important; }

.md-green-A200-bg {
  background-color: #69F0AE !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A200-fg {
  color: #69F0AE !important; }

.green-A200-border {
  border-color: #69F0AE !important; }

.green-A200-border-top {
  border-top-color: #69F0AE !important; }

.green-A200-border-right {
  border-right-color: #69F0AE !important; }

.green-A200-border-bottom {
  border-bottom-color: #69F0AE !important; }

.green-A200-border-left {
  border-left-color: #69F0AE !important; }

.green-A400-bg {
  background-color: #00E676 !important; }

.md-green-A400-bg {
  background-color: #00E676 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A400-fg {
  color: #00E676 !important; }

.green-A400-border {
  border-color: #00E676 !important; }

.green-A400-border-top {
  border-top-color: #00E676 !important; }

.green-A400-border-right {
  border-right-color: #00E676 !important; }

.green-A400-border-bottom {
  border-bottom-color: #00E676 !important; }

.green-A400-border-left {
  border-left-color: #00E676 !important; }

.green-A700-bg {
  background-color: #00C853 !important; }

.md-green-A700-bg {
  background-color: #00C853 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A700-fg {
  color: #00C853 !important; }

.green-A700-border {
  border-color: #00C853 !important; }

.green-A700-border-top {
  border-top-color: #00C853 !important; }

.green-A700-border-right {
  border-right-color: #00C853 !important; }

.green-A700-border-bottom {
  border-bottom-color: #00C853 !important; }

.green-A700-border-left {
  border-left-color: #00C853 !important; }

.light-green-50-bg {
  background-color: #F1F8E9 !important; }

.md-light-green-50-bg {
  background-color: #F1F8E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-50-fg {
  color: #F1F8E9 !important; }

.light-green-50-border {
  border-color: #F1F8E9 !important; }

.light-green-50-border-top {
  border-top-color: #F1F8E9 !important; }

.light-green-50-border-right {
  border-right-color: #F1F8E9 !important; }

.light-green-50-border-bottom {
  border-bottom-color: #F1F8E9 !important; }

.light-green-50-border-left {
  border-left-color: #F1F8E9 !important; }

.light-green-100-bg {
  background-color: #DCEDC8 !important; }

.md-light-green-100-bg {
  background-color: #DCEDC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-100-fg {
  color: #DCEDC8 !important; }

.light-green-100-border {
  border-color: #DCEDC8 !important; }

.light-green-100-border-top {
  border-top-color: #DCEDC8 !important; }

.light-green-100-border-right {
  border-right-color: #DCEDC8 !important; }

.light-green-100-border-bottom {
  border-bottom-color: #DCEDC8 !important; }

.light-green-100-border-left {
  border-left-color: #DCEDC8 !important; }

.light-green-200-bg {
  background-color: #C5E1A5 !important; }

.md-light-green-200-bg {
  background-color: #C5E1A5 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-200-fg {
  color: #C5E1A5 !important; }

.light-green-200-border {
  border-color: #C5E1A5 !important; }

.light-green-200-border-top {
  border-top-color: #C5E1A5 !important; }

.light-green-200-border-right {
  border-right-color: #C5E1A5 !important; }

.light-green-200-border-bottom {
  border-bottom-color: #C5E1A5 !important; }

.light-green-200-border-left {
  border-left-color: #C5E1A5 !important; }

.light-green-300-bg {
  background-color: #AED581 !important; }

.md-light-green-300-bg {
  background-color: #AED581 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-300-fg {
  color: #AED581 !important; }

.light-green-300-border {
  border-color: #AED581 !important; }

.light-green-300-border-top {
  border-top-color: #AED581 !important; }

.light-green-300-border-right {
  border-right-color: #AED581 !important; }

.light-green-300-border-bottom {
  border-bottom-color: #AED581 !important; }

.light-green-300-border-left {
  border-left-color: #AED581 !important; }

.light-green-400-bg {
  background-color: #9CCC65 !important; }

.md-light-green-400-bg {
  background-color: #9CCC65 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-400-fg {
  color: #9CCC65 !important; }

.light-green-400-border {
  border-color: #9CCC65 !important; }

.light-green-400-border-top {
  border-top-color: #9CCC65 !important; }

.light-green-400-border-right {
  border-right-color: #9CCC65 !important; }

.light-green-400-border-bottom {
  border-bottom-color: #9CCC65 !important; }

.light-green-400-border-left {
  border-left-color: #9CCC65 !important; }

.light-green-500-bg {
  background-color: #8BC34A !important; }

.md-light-green-500-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-500-fg {
  color: #8BC34A !important; }

.light-green-500-border {
  border-color: #8BC34A !important; }

.light-green-500-border-top {
  border-top-color: #8BC34A !important; }

.light-green-500-border-right {
  border-right-color: #8BC34A !important; }

.light-green-500-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-500-border-left {
  border-left-color: #8BC34A !important; }

.md-light-green-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-bg {
  background-color: #8BC34A !important; }

.light-green-fg {
  color: #8BC34A !important; }

.light-green-border {
  border-color: #8BC34A !important; }

.light-green-border-top {
  border-top-color: #8BC34A !important; }

.light-green-border-right {
  border-right-color: #8BC34A !important; }

.light-green-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-border-left {
  border-left-color: #8BC34A !important; }

.light-green-600-bg {
  background-color: #7CB342 !important; }

.md-light-green-600-bg {
  background-color: #7CB342 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-600-fg {
  color: #7CB342 !important; }

.light-green-600-border {
  border-color: #7CB342 !important; }

.light-green-600-border-top {
  border-top-color: #7CB342 !important; }

.light-green-600-border-right {
  border-right-color: #7CB342 !important; }

.light-green-600-border-bottom {
  border-bottom-color: #7CB342 !important; }

.light-green-600-border-left {
  border-left-color: #7CB342 !important; }

.light-green-700-bg {
  background-color: #689F38 !important; }

.md-light-green-700-bg {
  background-color: #689F38 !important;
  color: white; }

.light-green-700-fg {
  color: #689F38 !important; }

.light-green-700-border {
  border-color: #689F38 !important; }

.light-green-700-border-top {
  border-top-color: #689F38 !important; }

.light-green-700-border-right {
  border-right-color: #689F38 !important; }

.light-green-700-border-bottom {
  border-bottom-color: #689F38 !important; }

.light-green-700-border-left {
  border-left-color: #689F38 !important; }

.light-green-800-bg {
  background-color: #558B2F !important; }

.md-light-green-800-bg {
  background-color: #558B2F !important;
  color: white; }

.light-green-800-fg {
  color: #558B2F !important; }

.light-green-800-border {
  border-color: #558B2F !important; }

.light-green-800-border-top {
  border-top-color: #558B2F !important; }

.light-green-800-border-right {
  border-right-color: #558B2F !important; }

.light-green-800-border-bottom {
  border-bottom-color: #558B2F !important; }

.light-green-800-border-left {
  border-left-color: #558B2F !important; }

.light-green-900-bg {
  background-color: #33691E !important; }

.md-light-green-900-bg {
  background-color: #33691E !important;
  color: white; }

.light-green-900-fg {
  color: #33691E !important; }

.light-green-900-border {
  border-color: #33691E !important; }

.light-green-900-border-top {
  border-top-color: #33691E !important; }

.light-green-900-border-right {
  border-right-color: #33691E !important; }

.light-green-900-border-bottom {
  border-bottom-color: #33691E !important; }

.light-green-900-border-left {
  border-left-color: #33691E !important; }

.light-green-A100-bg {
  background-color: #CCFF90 !important; }

.md-light-green-A100-bg {
  background-color: #CCFF90 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A100-fg {
  color: #CCFF90 !important; }

.light-green-A100-border {
  border-color: #CCFF90 !important; }

.light-green-A100-border-top {
  border-top-color: #CCFF90 !important; }

.light-green-A100-border-right {
  border-right-color: #CCFF90 !important; }

.light-green-A100-border-bottom {
  border-bottom-color: #CCFF90 !important; }

.light-green-A100-border-left {
  border-left-color: #CCFF90 !important; }

.light-green-A200-bg {
  background-color: #B2FF59 !important; }

.md-light-green-A200-bg {
  background-color: #B2FF59 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A200-fg {
  color: #B2FF59 !important; }

.light-green-A200-border {
  border-color: #B2FF59 !important; }

.light-green-A200-border-top {
  border-top-color: #B2FF59 !important; }

.light-green-A200-border-right {
  border-right-color: #B2FF59 !important; }

.light-green-A200-border-bottom {
  border-bottom-color: #B2FF59 !important; }

.light-green-A200-border-left {
  border-left-color: #B2FF59 !important; }

.light-green-A400-bg {
  background-color: #76FF03 !important; }

.md-light-green-A400-bg {
  background-color: #76FF03 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A400-fg {
  color: #76FF03 !important; }

.light-green-A400-border {
  border-color: #76FF03 !important; }

.light-green-A400-border-top {
  border-top-color: #76FF03 !important; }

.light-green-A400-border-right {
  border-right-color: #76FF03 !important; }

.light-green-A400-border-bottom {
  border-bottom-color: #76FF03 !important; }

.light-green-A400-border-left {
  border-left-color: #76FF03 !important; }

.light-green-A700-bg {
  background-color: #64DD17 !important; }

.md-light-green-A700-bg {
  background-color: #64DD17 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A700-fg {
  color: #64DD17 !important; }

.light-green-A700-border {
  border-color: #64DD17 !important; }

.light-green-A700-border-top {
  border-top-color: #64DD17 !important; }

.light-green-A700-border-right {
  border-right-color: #64DD17 !important; }

.light-green-A700-border-bottom {
  border-bottom-color: #64DD17 !important; }

.light-green-A700-border-left {
  border-left-color: #64DD17 !important; }

.lime-50-bg {
  background-color: #F9FBE7 !important; }

.md-lime-50-bg {
  background-color: #F9FBE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-50-fg {
  color: #F9FBE7 !important; }

.lime-50-border {
  border-color: #F9FBE7 !important; }

.lime-50-border-top {
  border-top-color: #F9FBE7 !important; }

.lime-50-border-right {
  border-right-color: #F9FBE7 !important; }

.lime-50-border-bottom {
  border-bottom-color: #F9FBE7 !important; }

.lime-50-border-left {
  border-left-color: #F9FBE7 !important; }

.lime-100-bg {
  background-color: #F0F4C3 !important; }

.md-lime-100-bg {
  background-color: #F0F4C3 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-100-fg {
  color: #F0F4C3 !important; }

.lime-100-border {
  border-color: #F0F4C3 !important; }

.lime-100-border-top {
  border-top-color: #F0F4C3 !important; }

.lime-100-border-right {
  border-right-color: #F0F4C3 !important; }

.lime-100-border-bottom {
  border-bottom-color: #F0F4C3 !important; }

.lime-100-border-left {
  border-left-color: #F0F4C3 !important; }

.lime-200-bg {
  background-color: #E6EE9C !important; }

.md-lime-200-bg {
  background-color: #E6EE9C !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-200-fg {
  color: #E6EE9C !important; }

.lime-200-border {
  border-color: #E6EE9C !important; }

.lime-200-border-top {
  border-top-color: #E6EE9C !important; }

.lime-200-border-right {
  border-right-color: #E6EE9C !important; }

.lime-200-border-bottom {
  border-bottom-color: #E6EE9C !important; }

.lime-200-border-left {
  border-left-color: #E6EE9C !important; }

.lime-300-bg {
  background-color: #DCE775 !important; }

.md-lime-300-bg {
  background-color: #DCE775 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-300-fg {
  color: #DCE775 !important; }

.lime-300-border {
  border-color: #DCE775 !important; }

.lime-300-border-top {
  border-top-color: #DCE775 !important; }

.lime-300-border-right {
  border-right-color: #DCE775 !important; }

.lime-300-border-bottom {
  border-bottom-color: #DCE775 !important; }

.lime-300-border-left {
  border-left-color: #DCE775 !important; }

.lime-400-bg {
  background-color: #D4E157 !important; }

.md-lime-400-bg {
  background-color: #D4E157 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-400-fg {
  color: #D4E157 !important; }

.lime-400-border {
  border-color: #D4E157 !important; }

.lime-400-border-top {
  border-top-color: #D4E157 !important; }

.lime-400-border-right {
  border-right-color: #D4E157 !important; }

.lime-400-border-bottom {
  border-bottom-color: #D4E157 !important; }

.lime-400-border-left {
  border-left-color: #D4E157 !important; }

.lime-500-bg {
  background-color: #CDDC39 !important; }

.md-lime-500-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-500-fg {
  color: #CDDC39 !important; }

.lime-500-border {
  border-color: #CDDC39 !important; }

.lime-500-border-top {
  border-top-color: #CDDC39 !important; }

.lime-500-border-right {
  border-right-color: #CDDC39 !important; }

.lime-500-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-500-border-left {
  border-left-color: #CDDC39 !important; }

.md-lime-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-bg {
  background-color: #CDDC39 !important; }

.lime-fg {
  color: #CDDC39 !important; }

.lime-border {
  border-color: #CDDC39 !important; }

.lime-border-top {
  border-top-color: #CDDC39 !important; }

.lime-border-right {
  border-right-color: #CDDC39 !important; }

.lime-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-border-left {
  border-left-color: #CDDC39 !important; }

.lime-600-bg {
  background-color: #C0CA33 !important; }

.md-lime-600-bg {
  background-color: #C0CA33 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-600-fg {
  color: #C0CA33 !important; }

.lime-600-border {
  border-color: #C0CA33 !important; }

.lime-600-border-top {
  border-top-color: #C0CA33 !important; }

.lime-600-border-right {
  border-right-color: #C0CA33 !important; }

.lime-600-border-bottom {
  border-bottom-color: #C0CA33 !important; }

.lime-600-border-left {
  border-left-color: #C0CA33 !important; }

.lime-700-bg {
  background-color: #AFB42B !important; }

.md-lime-700-bg {
  background-color: #AFB42B !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-700-fg {
  color: #AFB42B !important; }

.lime-700-border {
  border-color: #AFB42B !important; }

.lime-700-border-top {
  border-top-color: #AFB42B !important; }

.lime-700-border-right {
  border-right-color: #AFB42B !important; }

.lime-700-border-bottom {
  border-bottom-color: #AFB42B !important; }

.lime-700-border-left {
  border-left-color: #AFB42B !important; }

.lime-800-bg {
  background-color: #9E9D24 !important; }

.md-lime-800-bg {
  background-color: #9E9D24 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-800-fg {
  color: #9E9D24 !important; }

.lime-800-border {
  border-color: #9E9D24 !important; }

.lime-800-border-top {
  border-top-color: #9E9D24 !important; }

.lime-800-border-right {
  border-right-color: #9E9D24 !important; }

.lime-800-border-bottom {
  border-bottom-color: #9E9D24 !important; }

.lime-800-border-left {
  border-left-color: #9E9D24 !important; }

.lime-900-bg {
  background-color: #827717 !important; }

.md-lime-900-bg {
  background-color: #827717 !important;
  color: white; }

.lime-900-fg {
  color: #827717 !important; }

.lime-900-border {
  border-color: #827717 !important; }

.lime-900-border-top {
  border-top-color: #827717 !important; }

.lime-900-border-right {
  border-right-color: #827717 !important; }

.lime-900-border-bottom {
  border-bottom-color: #827717 !important; }

.lime-900-border-left {
  border-left-color: #827717 !important; }

.lime-A100-bg {
  background-color: #F4FF81 !important; }

.md-lime-A100-bg {
  background-color: #F4FF81 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A100-fg {
  color: #F4FF81 !important; }

.lime-A100-border {
  border-color: #F4FF81 !important; }

.lime-A100-border-top {
  border-top-color: #F4FF81 !important; }

.lime-A100-border-right {
  border-right-color: #F4FF81 !important; }

.lime-A100-border-bottom {
  border-bottom-color: #F4FF81 !important; }

.lime-A100-border-left {
  border-left-color: #F4FF81 !important; }

.lime-A200-bg {
  background-color: #EEFF41 !important; }

.md-lime-A200-bg {
  background-color: #EEFF41 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A200-fg {
  color: #EEFF41 !important; }

.lime-A200-border {
  border-color: #EEFF41 !important; }

.lime-A200-border-top {
  border-top-color: #EEFF41 !important; }

.lime-A200-border-right {
  border-right-color: #EEFF41 !important; }

.lime-A200-border-bottom {
  border-bottom-color: #EEFF41 !important; }

.lime-A200-border-left {
  border-left-color: #EEFF41 !important; }

.lime-A400-bg {
  background-color: #C6FF00 !important; }

.md-lime-A400-bg {
  background-color: #C6FF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A400-fg {
  color: #C6FF00 !important; }

.lime-A400-border {
  border-color: #C6FF00 !important; }

.lime-A400-border-top {
  border-top-color: #C6FF00 !important; }

.lime-A400-border-right {
  border-right-color: #C6FF00 !important; }

.lime-A400-border-bottom {
  border-bottom-color: #C6FF00 !important; }

.lime-A400-border-left {
  border-left-color: #C6FF00 !important; }

.lime-A700-bg {
  background-color: #AEEA00 !important; }

.md-lime-A700-bg {
  background-color: #AEEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A700-fg {
  color: #AEEA00 !important; }

.lime-A700-border {
  border-color: #AEEA00 !important; }

.lime-A700-border-top {
  border-top-color: #AEEA00 !important; }

.lime-A700-border-right {
  border-right-color: #AEEA00 !important; }

.lime-A700-border-bottom {
  border-bottom-color: #AEEA00 !important; }

.lime-A700-border-left {
  border-left-color: #AEEA00 !important; }

.yellow-50-bg {
  background-color: #FFFDE7 !important; }

.md-yellow-50-bg {
  background-color: #FFFDE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-50-fg {
  color: #FFFDE7 !important; }

.yellow-50-border {
  border-color: #FFFDE7 !important; }

.yellow-50-border-top {
  border-top-color: #FFFDE7 !important; }

.yellow-50-border-right {
  border-right-color: #FFFDE7 !important; }

.yellow-50-border-bottom {
  border-bottom-color: #FFFDE7 !important; }

.yellow-50-border-left {
  border-left-color: #FFFDE7 !important; }

.yellow-100-bg {
  background-color: #FFF9C4 !important; }

.md-yellow-100-bg {
  background-color: #FFF9C4 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-100-fg {
  color: #FFF9C4 !important; }

.yellow-100-border {
  border-color: #FFF9C4 !important; }

.yellow-100-border-top {
  border-top-color: #FFF9C4 !important; }

.yellow-100-border-right {
  border-right-color: #FFF9C4 !important; }

.yellow-100-border-bottom {
  border-bottom-color: #FFF9C4 !important; }

.yellow-100-border-left {
  border-left-color: #FFF9C4 !important; }

.yellow-200-bg {
  background-color: #FFF59D !important; }

.md-yellow-200-bg {
  background-color: #FFF59D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-200-fg {
  color: #FFF59D !important; }

.yellow-200-border {
  border-color: #FFF59D !important; }

.yellow-200-border-top {
  border-top-color: #FFF59D !important; }

.yellow-200-border-right {
  border-right-color: #FFF59D !important; }

.yellow-200-border-bottom {
  border-bottom-color: #FFF59D !important; }

.yellow-200-border-left {
  border-left-color: #FFF59D !important; }

.yellow-300-bg {
  background-color: #FFF176 !important; }

.md-yellow-300-bg {
  background-color: #FFF176 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300-fg {
  color: #FFF176 !important; }

.yellow-300-border {
  border-color: #FFF176 !important; }

.yellow-300-border-top {
  border-top-color: #FFF176 !important; }

.yellow-300-border-right {
  border-right-color: #FFF176 !important; }

.yellow-300-border-bottom {
  border-bottom-color: #FFF176 !important; }

.yellow-300-border-left {
  border-left-color: #FFF176 !important; }

.yellow-400-bg {
  background-color: #FFEE58 !important; }

.md-yellow-400-bg {
  background-color: #FFEE58 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-400-fg {
  color: #FFEE58 !important; }

.yellow-400-border {
  border-color: #FFEE58 !important; }

.yellow-400-border-top {
  border-top-color: #FFEE58 !important; }

.yellow-400-border-right {
  border-right-color: #FFEE58 !important; }

.yellow-400-border-bottom {
  border-bottom-color: #FFEE58 !important; }

.yellow-400-border-left {
  border-left-color: #FFEE58 !important; }

.yellow-500-bg {
  background-color: #FFEB3B !important; }

.md-yellow-500-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500-fg {
  color: #FFEB3B !important; }

.yellow-500-border {
  border-color: #FFEB3B !important; }

.yellow-500-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-500-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-500-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-500-border-left {
  border-left-color: #FFEB3B !important; }

.md-yellow-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-bg {
  background-color: #FFEB3B !important; }

.yellow-fg {
  color: #FFEB3B !important; }

.yellow-border {
  border-color: #FFEB3B !important; }

.yellow-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-border-left {
  border-left-color: #FFEB3B !important; }

.yellow-600-bg {
  background-color: #FDD835 !important; }

.md-yellow-600-bg {
  background-color: #FDD835 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-600-fg {
  color: #FDD835 !important; }

.yellow-600-border {
  border-color: #FDD835 !important; }

.yellow-600-border-top {
  border-top-color: #FDD835 !important; }

.yellow-600-border-right {
  border-right-color: #FDD835 !important; }

.yellow-600-border-bottom {
  border-bottom-color: #FDD835 !important; }

.yellow-600-border-left {
  border-left-color: #FDD835 !important; }

.yellow-700-bg {
  background-color: #FBC02D !important; }

.md-yellow-700-bg {
  background-color: #FBC02D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-700-fg {
  color: #FBC02D !important; }

.yellow-700-border {
  border-color: #FBC02D !important; }

.yellow-700-border-top {
  border-top-color: #FBC02D !important; }

.yellow-700-border-right {
  border-right-color: #FBC02D !important; }

.yellow-700-border-bottom {
  border-bottom-color: #FBC02D !important; }

.yellow-700-border-left {
  border-left-color: #FBC02D !important; }

.yellow-800-bg {
  background-color: #F9A825 !important; }

.md-yellow-800-bg {
  background-color: #F9A825 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-800-fg {
  color: #F9A825 !important; }

.yellow-800-border {
  border-color: #F9A825 !important; }

.yellow-800-border-top {
  border-top-color: #F9A825 !important; }

.yellow-800-border-right {
  border-right-color: #F9A825 !important; }

.yellow-800-border-bottom {
  border-bottom-color: #F9A825 !important; }

.yellow-800-border-left {
  border-left-color: #F9A825 !important; }

.yellow-900-bg {
  background-color: #F57F17 !important; }

.md-yellow-900-bg {
  background-color: #F57F17 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-900-fg {
  color: #F57F17 !important; }

.yellow-900-border {
  border-color: #F57F17 !important; }

.yellow-900-border-top {
  border-top-color: #F57F17 !important; }

.yellow-900-border-right {
  border-right-color: #F57F17 !important; }

.yellow-900-border-bottom {
  border-bottom-color: #F57F17 !important; }

.yellow-900-border-left {
  border-left-color: #F57F17 !important; }

.yellow-A100-bg {
  background-color: #FFFF8D !important; }

.md-yellow-A100-bg {
  background-color: #FFFF8D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A100-fg {
  color: #FFFF8D !important; }

.yellow-A100-border {
  border-color: #FFFF8D !important; }

.yellow-A100-border-top {
  border-top-color: #FFFF8D !important; }

.yellow-A100-border-right {
  border-right-color: #FFFF8D !important; }

.yellow-A100-border-bottom {
  border-bottom-color: #FFFF8D !important; }

.yellow-A100-border-left {
  border-left-color: #FFFF8D !important; }

.yellow-A200-bg {
  background-color: #FFFF00 !important; }

.md-yellow-A200-bg {
  background-color: #FFFF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A200-fg {
  color: #FFFF00 !important; }

.yellow-A200-border {
  border-color: #FFFF00 !important; }

.yellow-A200-border-top {
  border-top-color: #FFFF00 !important; }

.yellow-A200-border-right {
  border-right-color: #FFFF00 !important; }

.yellow-A200-border-bottom {
  border-bottom-color: #FFFF00 !important; }

.yellow-A200-border-left {
  border-left-color: #FFFF00 !important; }

.yellow-A400-bg {
  background-color: #FFEA00 !important; }

.md-yellow-A400-bg {
  background-color: #FFEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A400-fg {
  color: #FFEA00 !important; }

.yellow-A400-border {
  border-color: #FFEA00 !important; }

.yellow-A400-border-top {
  border-top-color: #FFEA00 !important; }

.yellow-A400-border-right {
  border-right-color: #FFEA00 !important; }

.yellow-A400-border-bottom {
  border-bottom-color: #FFEA00 !important; }

.yellow-A400-border-left {
  border-left-color: #FFEA00 !important; }

.yellow-A700-bg {
  background-color: #FFD600 !important; }

.md-yellow-A700-bg {
  background-color: #FFD600 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A700-fg {
  color: #FFD600 !important; }

.yellow-A700-border {
  border-color: #FFD600 !important; }

.yellow-A700-border-top {
  border-top-color: #FFD600 !important; }

.yellow-A700-border-right {
  border-right-color: #FFD600 !important; }

.yellow-A700-border-bottom {
  border-bottom-color: #FFD600 !important; }

.yellow-A700-border-left {
  border-left-color: #FFD600 !important; }

.amber-50-bg {
  background-color: #FFF8E1 !important; }

.md-amber-50-bg {
  background-color: #FFF8E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-50-fg {
  color: #FFF8E1 !important; }

.amber-50-border {
  border-color: #FFF8E1 !important; }

.amber-50-border-top {
  border-top-color: #FFF8E1 !important; }

.amber-50-border-right {
  border-right-color: #FFF8E1 !important; }

.amber-50-border-bottom {
  border-bottom-color: #FFF8E1 !important; }

.amber-50-border-left {
  border-left-color: #FFF8E1 !important; }

.amber-100-bg {
  background-color: #FFECB3 !important; }

.md-amber-100-bg {
  background-color: #FFECB3 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-100-fg {
  color: #FFECB3 !important; }

.amber-100-border {
  border-color: #FFECB3 !important; }

.amber-100-border-top {
  border-top-color: #FFECB3 !important; }

.amber-100-border-right {
  border-right-color: #FFECB3 !important; }

.amber-100-border-bottom {
  border-bottom-color: #FFECB3 !important; }

.amber-100-border-left {
  border-left-color: #FFECB3 !important; }

.amber-200-bg {
  background-color: #FFE082 !important; }

.md-amber-200-bg {
  background-color: #FFE082 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-200-fg {
  color: #FFE082 !important; }

.amber-200-border {
  border-color: #FFE082 !important; }

.amber-200-border-top {
  border-top-color: #FFE082 !important; }

.amber-200-border-right {
  border-right-color: #FFE082 !important; }

.amber-200-border-bottom {
  border-bottom-color: #FFE082 !important; }

.amber-200-border-left {
  border-left-color: #FFE082 !important; }

.amber-300-bg {
  background-color: #FFD54F !important; }

.md-amber-300-bg {
  background-color: #FFD54F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-300-fg {
  color: #FFD54F !important; }

.amber-300-border {
  border-color: #FFD54F !important; }

.amber-300-border-top {
  border-top-color: #FFD54F !important; }

.amber-300-border-right {
  border-right-color: #FFD54F !important; }

.amber-300-border-bottom {
  border-bottom-color: #FFD54F !important; }

.amber-300-border-left {
  border-left-color: #FFD54F !important; }

.amber-400-bg {
  background-color: #FFCA28 !important; }

.md-amber-400-bg {
  background-color: #FFCA28 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-400-fg {
  color: #FFCA28 !important; }

.amber-400-border {
  border-color: #FFCA28 !important; }

.amber-400-border-top {
  border-top-color: #FFCA28 !important; }

.amber-400-border-right {
  border-right-color: #FFCA28 !important; }

.amber-400-border-bottom {
  border-bottom-color: #FFCA28 !important; }

.amber-400-border-left {
  border-left-color: #FFCA28 !important; }

.amber-500-bg {
  background-color: #FFC107 !important; }

.md-amber-500-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-500-fg {
  color: #FFC107 !important; }

.amber-500-border {
  border-color: #FFC107 !important; }

.amber-500-border-top {
  border-top-color: #FFC107 !important; }

.amber-500-border-right {
  border-right-color: #FFC107 !important; }

.amber-500-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-500-border-left {
  border-left-color: #FFC107 !important; }

.md-amber-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-bg {
  background-color: #FFC107 !important; }

.amber-fg {
  color: #FFC107 !important; }

.amber-border {
  border-color: #FFC107 !important; }

.amber-border-top {
  border-top-color: #FFC107 !important; }

.amber-border-right {
  border-right-color: #FFC107 !important; }

.amber-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-border-left {
  border-left-color: #FFC107 !important; }

.amber-600-bg {
  background-color: #FFB300 !important; }

.md-amber-600-bg {
  background-color: #FFB300 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-600-fg {
  color: #FFB300 !important; }

.amber-600-border {
  border-color: #FFB300 !important; }

.amber-600-border-top {
  border-top-color: #FFB300 !important; }

.amber-600-border-right {
  border-right-color: #FFB300 !important; }

.amber-600-border-bottom {
  border-bottom-color: #FFB300 !important; }

.amber-600-border-left {
  border-left-color: #FFB300 !important; }

.amber-700-bg {
  background-color: #FFA000 !important; }

.md-amber-700-bg {
  background-color: #FFA000 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-700-fg {
  color: #FFA000 !important; }

.amber-700-border {
  border-color: #FFA000 !important; }

.amber-700-border-top {
  border-top-color: #FFA000 !important; }

.amber-700-border-right {
  border-right-color: #FFA000 !important; }

.amber-700-border-bottom {
  border-bottom-color: #FFA000 !important; }

.amber-700-border-left {
  border-left-color: #FFA000 !important; }

.amber-800-bg {
  background-color: #FF8F00 !important; }

.md-amber-800-bg {
  background-color: #FF8F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-800-fg {
  color: #FF8F00 !important; }

.amber-800-border {
  border-color: #FF8F00 !important; }

.amber-800-border-top {
  border-top-color: #FF8F00 !important; }

.amber-800-border-right {
  border-right-color: #FF8F00 !important; }

.amber-800-border-bottom {
  border-bottom-color: #FF8F00 !important; }

.amber-800-border-left {
  border-left-color: #FF8F00 !important; }

.amber-900-bg {
  background-color: #FF6F00 !important; }

.md-amber-900-bg {
  background-color: #FF6F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-900-fg {
  color: #FF6F00 !important; }

.amber-900-border {
  border-color: #FF6F00 !important; }

.amber-900-border-top {
  border-top-color: #FF6F00 !important; }

.amber-900-border-right {
  border-right-color: #FF6F00 !important; }

.amber-900-border-bottom {
  border-bottom-color: #FF6F00 !important; }

.amber-900-border-left {
  border-left-color: #FF6F00 !important; }

.amber-A100-bg {
  background-color: #FFE57F !important; }

.md-amber-A100-bg {
  background-color: #FFE57F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A100-fg {
  color: #FFE57F !important; }

.amber-A100-border {
  border-color: #FFE57F !important; }

.amber-A100-border-top {
  border-top-color: #FFE57F !important; }

.amber-A100-border-right {
  border-right-color: #FFE57F !important; }

.amber-A100-border-bottom {
  border-bottom-color: #FFE57F !important; }

.amber-A100-border-left {
  border-left-color: #FFE57F !important; }

.amber-A200-bg {
  background-color: #FFD740 !important; }

.md-amber-A200-bg {
  background-color: #FFD740 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A200-fg {
  color: #FFD740 !important; }

.amber-A200-border {
  border-color: #FFD740 !important; }

.amber-A200-border-top {
  border-top-color: #FFD740 !important; }

.amber-A200-border-right {
  border-right-color: #FFD740 !important; }

.amber-A200-border-bottom {
  border-bottom-color: #FFD740 !important; }

.amber-A200-border-left {
  border-left-color: #FFD740 !important; }

.amber-A400-bg {
  background-color: #FFC400 !important; }

.md-amber-A400-bg {
  background-color: #FFC400 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A400-fg {
  color: #FFC400 !important; }

.amber-A400-border {
  border-color: #FFC400 !important; }

.amber-A400-border-top {
  border-top-color: #FFC400 !important; }

.amber-A400-border-right {
  border-right-color: #FFC400 !important; }

.amber-A400-border-bottom {
  border-bottom-color: #FFC400 !important; }

.amber-A400-border-left {
  border-left-color: #FFC400 !important; }

.amber-A700-bg {
  background-color: #FFAB00 !important; }

.md-amber-A700-bg {
  background-color: #FFAB00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A700-fg {
  color: #FFAB00 !important; }

.amber-A700-border {
  border-color: #FFAB00 !important; }

.amber-A700-border-top {
  border-top-color: #FFAB00 !important; }

.amber-A700-border-right {
  border-right-color: #FFAB00 !important; }

.amber-A700-border-bottom {
  border-bottom-color: #FFAB00 !important; }

.amber-A700-border-left {
  border-left-color: #FFAB00 !important; }

.orange-50-bg {
  background-color: #FFF3E0 !important; }

.md-orange-50-bg {
  background-color: #FFF3E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-50-fg {
  color: #FFF3E0 !important; }

.orange-50-border {
  border-color: #FFF3E0 !important; }

.orange-50-border-top {
  border-top-color: #FFF3E0 !important; }

.orange-50-border-right {
  border-right-color: #FFF3E0 !important; }

.orange-50-border-bottom {
  border-bottom-color: #FFF3E0 !important; }

.orange-50-border-left {
  border-left-color: #FFF3E0 !important; }

.orange-100-bg {
  background-color: #FFE0B2 !important; }

.md-orange-100-bg {
  background-color: #FFE0B2 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-100-fg {
  color: #FFE0B2 !important; }

.orange-100-border {
  border-color: #FFE0B2 !important; }

.orange-100-border-top {
  border-top-color: #FFE0B2 !important; }

.orange-100-border-right {
  border-right-color: #FFE0B2 !important; }

.orange-100-border-bottom {
  border-bottom-color: #FFE0B2 !important; }

.orange-100-border-left {
  border-left-color: #FFE0B2 !important; }

.orange-200-bg {
  background-color: #FFCC80 !important; }

.md-orange-200-bg {
  background-color: #FFCC80 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-200-fg {
  color: #FFCC80 !important; }

.orange-200-border {
  border-color: #FFCC80 !important; }

.orange-200-border-top {
  border-top-color: #FFCC80 !important; }

.orange-200-border-right {
  border-right-color: #FFCC80 !important; }

.orange-200-border-bottom {
  border-bottom-color: #FFCC80 !important; }

.orange-200-border-left {
  border-left-color: #FFCC80 !important; }

.orange-300-bg {
  background-color: #FFB74D !important; }

.md-orange-300-bg {
  background-color: #FFB74D !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-300-fg {
  color: #FFB74D !important; }

.orange-300-border {
  border-color: #FFB74D !important; }

.orange-300-border-top {
  border-top-color: #FFB74D !important; }

.orange-300-border-right {
  border-right-color: #FFB74D !important; }

.orange-300-border-bottom {
  border-bottom-color: #FFB74D !important; }

.orange-300-border-left {
  border-left-color: #FFB74D !important; }

.orange-400-bg {
  background-color: #FFA726 !important; }

.md-orange-400-bg {
  background-color: #FFA726 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-400-fg {
  color: #FFA726 !important; }

.orange-400-border {
  border-color: #FFA726 !important; }

.orange-400-border-top {
  border-top-color: #FFA726 !important; }

.orange-400-border-right {
  border-right-color: #FFA726 !important; }

.orange-400-border-bottom {
  border-bottom-color: #FFA726 !important; }

.orange-400-border-left {
  border-left-color: #FFA726 !important; }

.orange-500-bg {
  background-color: #FF9800 !important; }

.md-orange-500-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-500-fg, .event-report table.md-table .event-subevent .td-event-type,
.event-report table.md-table .event-subevent [class*="icon-"] {
  color: #FF9800 !important; }

.orange-500-border {
  border-color: #FF9800 !important; }

.orange-500-border-top {
  border-top-color: #FF9800 !important; }

.orange-500-border-right {
  border-right-color: #FF9800 !important; }

.orange-500-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-500-border-left {
  border-left-color: #FF9800 !important; }

.md-orange-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-bg {
  background-color: #FF9800 !important; }

.orange-fg {
  color: #FF9800 !important; }

.orange-border {
  border-color: #FF9800 !important; }

.orange-border-top {
  border-top-color: #FF9800 !important; }

.orange-border-right {
  border-right-color: #FF9800 !important; }

.orange-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-border-left {
  border-left-color: #FF9800 !important; }

.orange-600-bg {
  background-color: #FB8C00 !important; }

.md-orange-600-bg {
  background-color: #FB8C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-600-fg {
  color: #FB8C00 !important; }

.orange-600-border {
  border-color: #FB8C00 !important; }

.orange-600-border-top {
  border-top-color: #FB8C00 !important; }

.orange-600-border-right {
  border-right-color: #FB8C00 !important; }

.orange-600-border-bottom {
  border-bottom-color: #FB8C00 !important; }

.orange-600-border-left {
  border-left-color: #FB8C00 !important; }

.orange-700-bg {
  background-color: #F57C00 !important; }

.md-orange-700-bg {
  background-color: #F57C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-700-fg {
  color: #F57C00 !important; }

.orange-700-border {
  border-color: #F57C00 !important; }

.orange-700-border-top {
  border-top-color: #F57C00 !important; }

.orange-700-border-right {
  border-right-color: #F57C00 !important; }

.orange-700-border-bottom {
  border-bottom-color: #F57C00 !important; }

.orange-700-border-left {
  border-left-color: #F57C00 !important; }

.orange-800-bg {
  background-color: #EF6C00 !important; }

.md-orange-800-bg {
  background-color: #EF6C00 !important;
  color: white; }

.orange-800-fg {
  color: #EF6C00 !important; }

.orange-800-border {
  border-color: #EF6C00 !important; }

.orange-800-border-top {
  border-top-color: #EF6C00 !important; }

.orange-800-border-right {
  border-right-color: #EF6C00 !important; }

.orange-800-border-bottom {
  border-bottom-color: #EF6C00 !important; }

.orange-800-border-left {
  border-left-color: #EF6C00 !important; }

.orange-900-bg {
  background-color: #E65100 !important; }

.md-orange-900-bg {
  background-color: #E65100 !important;
  color: white; }

.orange-900-fg {
  color: #E65100 !important; }

.orange-900-border {
  border-color: #E65100 !important; }

.orange-900-border-top {
  border-top-color: #E65100 !important; }

.orange-900-border-right {
  border-right-color: #E65100 !important; }

.orange-900-border-bottom {
  border-bottom-color: #E65100 !important; }

.orange-900-border-left {
  border-left-color: #E65100 !important; }

.orange-A100-bg {
  background-color: #FFD180 !important; }

.md-orange-A100-bg {
  background-color: #FFD180 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A100-fg {
  color: #FFD180 !important; }

.orange-A100-border {
  border-color: #FFD180 !important; }

.orange-A100-border-top {
  border-top-color: #FFD180 !important; }

.orange-A100-border-right {
  border-right-color: #FFD180 !important; }

.orange-A100-border-bottom {
  border-bottom-color: #FFD180 !important; }

.orange-A100-border-left {
  border-left-color: #FFD180 !important; }

.orange-A200-bg {
  background-color: #FFAB40 !important; }

.md-orange-A200-bg {
  background-color: #FFAB40 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A200-fg {
  color: #FFAB40 !important; }

.orange-A200-border {
  border-color: #FFAB40 !important; }

.orange-A200-border-top {
  border-top-color: #FFAB40 !important; }

.orange-A200-border-right {
  border-right-color: #FFAB40 !important; }

.orange-A200-border-bottom {
  border-bottom-color: #FFAB40 !important; }

.orange-A200-border-left {
  border-left-color: #FFAB40 !important; }

.orange-A400-bg {
  background-color: #FF9100 !important; }

.md-orange-A400-bg {
  background-color: #FF9100 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A400-fg {
  color: #FF9100 !important; }

.orange-A400-border {
  border-color: #FF9100 !important; }

.orange-A400-border-top {
  border-top-color: #FF9100 !important; }

.orange-A400-border-right {
  border-right-color: #FF9100 !important; }

.orange-A400-border-bottom {
  border-bottom-color: #FF9100 !important; }

.orange-A400-border-left {
  border-left-color: #FF9100 !important; }

.orange-A700-bg {
  background-color: #FF6D00 !important; }

.md-orange-A700-bg {
  background-color: #FF6D00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A700-fg {
  color: #FF6D00 !important; }

.orange-A700-border {
  border-color: #FF6D00 !important; }

.orange-A700-border-top {
  border-top-color: #FF6D00 !important; }

.orange-A700-border-right {
  border-right-color: #FF6D00 !important; }

.orange-A700-border-bottom {
  border-bottom-color: #FF6D00 !important; }

.orange-A700-border-left {
  border-left-color: #FF6D00 !important; }

.deep-orange-50-bg {
  background-color: #FBE9E7 !important; }

.md-deep-orange-50-bg {
  background-color: #FBE9E7 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-50-fg {
  color: #FBE9E7 !important; }

.deep-orange-50-border {
  border-color: #FBE9E7 !important; }

.deep-orange-50-border-top {
  border-top-color: #FBE9E7 !important; }

.deep-orange-50-border-right {
  border-right-color: #FBE9E7 !important; }

.deep-orange-50-border-bottom {
  border-bottom-color: #FBE9E7 !important; }

.deep-orange-50-border-left {
  border-left-color: #FBE9E7 !important; }

.deep-orange-100-bg {
  background-color: #FFCCBC !important; }

.md-deep-orange-100-bg {
  background-color: #FFCCBC !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-100-fg {
  color: #FFCCBC !important; }

.deep-orange-100-border {
  border-color: #FFCCBC !important; }

.deep-orange-100-border-top {
  border-top-color: #FFCCBC !important; }

.deep-orange-100-border-right {
  border-right-color: #FFCCBC !important; }

.deep-orange-100-border-bottom {
  border-bottom-color: #FFCCBC !important; }

.deep-orange-100-border-left {
  border-left-color: #FFCCBC !important; }

.deep-orange-200-bg {
  background-color: #FFAB91 !important; }

.md-deep-orange-200-bg {
  background-color: #FFAB91 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-200-fg {
  color: #FFAB91 !important; }

.deep-orange-200-border {
  border-color: #FFAB91 !important; }

.deep-orange-200-border-top {
  border-top-color: #FFAB91 !important; }

.deep-orange-200-border-right {
  border-right-color: #FFAB91 !important; }

.deep-orange-200-border-bottom {
  border-bottom-color: #FFAB91 !important; }

.deep-orange-200-border-left {
  border-left-color: #FFAB91 !important; }

.deep-orange-300-bg {
  background-color: #FF8A65 !important; }

.md-deep-orange-300-bg {
  background-color: #FF8A65 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-300-fg {
  color: #FF8A65 !important; }

.deep-orange-300-border {
  border-color: #FF8A65 !important; }

.deep-orange-300-border-top {
  border-top-color: #FF8A65 !important; }

.deep-orange-300-border-right {
  border-right-color: #FF8A65 !important; }

.deep-orange-300-border-bottom {
  border-bottom-color: #FF8A65 !important; }

.deep-orange-300-border-left {
  border-left-color: #FF8A65 !important; }

.deep-orange-400-bg {
  background-color: #FF7043 !important; }

.md-deep-orange-400-bg {
  background-color: #FF7043 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-400-fg {
  color: #FF7043 !important; }

.deep-orange-400-border {
  border-color: #FF7043 !important; }

.deep-orange-400-border-top {
  border-top-color: #FF7043 !important; }

.deep-orange-400-border-right {
  border-right-color: #FF7043 !important; }

.deep-orange-400-border-bottom {
  border-bottom-color: #FF7043 !important; }

.deep-orange-400-border-left {
  border-left-color: #FF7043 !important; }

.deep-orange-500-bg {
  background-color: #FF5722 !important; }

.md-deep-orange-500-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-500-fg {
  color: #FF5722 !important; }

.deep-orange-500-border {
  border-color: #FF5722 !important; }

.deep-orange-500-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-500-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-500-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-500-border-left {
  border-left-color: #FF5722 !important; }

.md-deep-orange-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-bg {
  background-color: #FF5722 !important; }

.deep-orange-fg {
  color: #FF5722 !important; }

.deep-orange-border {
  border-color: #FF5722 !important; }

.deep-orange-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-border-left {
  border-left-color: #FF5722 !important; }

.deep-orange-600-bg {
  background-color: #F4511E !important; }

.md-deep-orange-600-bg {
  background-color: #F4511E !important;
  color: white; }

.deep-orange-600-fg {
  color: #F4511E !important; }

.deep-orange-600-border {
  border-color: #F4511E !important; }

.deep-orange-600-border-top {
  border-top-color: #F4511E !important; }

.deep-orange-600-border-right {
  border-right-color: #F4511E !important; }

.deep-orange-600-border-bottom {
  border-bottom-color: #F4511E !important; }

.deep-orange-600-border-left {
  border-left-color: #F4511E !important; }

.deep-orange-700-bg {
  background-color: #E64A19 !important; }

.md-deep-orange-700-bg {
  background-color: #E64A19 !important;
  color: white; }

.deep-orange-700-fg {
  color: #E64A19 !important; }

.deep-orange-700-border {
  border-color: #E64A19 !important; }

.deep-orange-700-border-top {
  border-top-color: #E64A19 !important; }

.deep-orange-700-border-right {
  border-right-color: #E64A19 !important; }

.deep-orange-700-border-bottom {
  border-bottom-color: #E64A19 !important; }

.deep-orange-700-border-left {
  border-left-color: #E64A19 !important; }

.deep-orange-800-bg {
  background-color: #D84315 !important; }

.md-deep-orange-800-bg {
  background-color: #D84315 !important;
  color: white; }

.deep-orange-800-fg {
  color: #D84315 !important; }

.deep-orange-800-border {
  border-color: #D84315 !important; }

.deep-orange-800-border-top {
  border-top-color: #D84315 !important; }

.deep-orange-800-border-right {
  border-right-color: #D84315 !important; }

.deep-orange-800-border-bottom {
  border-bottom-color: #D84315 !important; }

.deep-orange-800-border-left {
  border-left-color: #D84315 !important; }

.deep-orange-900-bg {
  background-color: #BF360C !important; }

.md-deep-orange-900-bg {
  background-color: #BF360C !important;
  color: white; }

.deep-orange-900-fg {
  color: #BF360C !important; }

.deep-orange-900-border {
  border-color: #BF360C !important; }

.deep-orange-900-border-top {
  border-top-color: #BF360C !important; }

.deep-orange-900-border-right {
  border-right-color: #BF360C !important; }

.deep-orange-900-border-bottom {
  border-bottom-color: #BF360C !important; }

.deep-orange-900-border-left {
  border-left-color: #BF360C !important; }

.deep-orange-A100-bg {
  background-color: #FF9E80 !important; }

.md-deep-orange-A100-bg {
  background-color: #FF9E80 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A100-fg {
  color: #FF9E80 !important; }

.deep-orange-A100-border {
  border-color: #FF9E80 !important; }

.deep-orange-A100-border-top {
  border-top-color: #FF9E80 !important; }

.deep-orange-A100-border-right {
  border-right-color: #FF9E80 !important; }

.deep-orange-A100-border-bottom {
  border-bottom-color: #FF9E80 !important; }

.deep-orange-A100-border-left {
  border-left-color: #FF9E80 !important; }

.deep-orange-A200-bg {
  background-color: #FF6E40 !important; }

.md-deep-orange-A200-bg {
  background-color: #FF6E40 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A200-fg {
  color: #FF6E40 !important; }

.deep-orange-A200-border {
  border-color: #FF6E40 !important; }

.deep-orange-A200-border-top {
  border-top-color: #FF6E40 !important; }

.deep-orange-A200-border-right {
  border-right-color: #FF6E40 !important; }

.deep-orange-A200-border-bottom {
  border-bottom-color: #FF6E40 !important; }

.deep-orange-A200-border-left {
  border-left-color: #FF6E40 !important; }

.deep-orange-A400-bg {
  background-color: #FF3D00 !important; }

.md-deep-orange-A400-bg {
  background-color: #FF3D00 !important;
  color: white; }

.deep-orange-A400-fg {
  color: #FF3D00 !important; }

.deep-orange-A400-border {
  border-color: #FF3D00 !important; }

.deep-orange-A400-border-top {
  border-top-color: #FF3D00 !important; }

.deep-orange-A400-border-right {
  border-right-color: #FF3D00 !important; }

.deep-orange-A400-border-bottom {
  border-bottom-color: #FF3D00 !important; }

.deep-orange-A400-border-left {
  border-left-color: #FF3D00 !important; }

.deep-orange-A700-bg {
  background-color: #DD2C00 !important; }

.md-deep-orange-A700-bg {
  background-color: #DD2C00 !important;
  color: white; }

.deep-orange-A700-fg {
  color: #DD2C00 !important; }

.deep-orange-A700-border {
  border-color: #DD2C00 !important; }

.deep-orange-A700-border-top {
  border-top-color: #DD2C00 !important; }

.deep-orange-A700-border-right {
  border-right-color: #DD2C00 !important; }

.deep-orange-A700-border-bottom {
  border-bottom-color: #DD2C00 !important; }

.deep-orange-A700-border-left {
  border-left-color: #DD2C00 !important; }

.brown-50-bg {
  background-color: #EFEBE9 !important; }

.md-brown-50-bg {
  background-color: #EFEBE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-50-fg {
  color: #EFEBE9 !important; }

.brown-50-border {
  border-color: #EFEBE9 !important; }

.brown-50-border-top {
  border-top-color: #EFEBE9 !important; }

.brown-50-border-right {
  border-right-color: #EFEBE9 !important; }

.brown-50-border-bottom {
  border-bottom-color: #EFEBE9 !important; }

.brown-50-border-left {
  border-left-color: #EFEBE9 !important; }

.brown-100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-100-fg {
  color: #D7CCC8 !important; }

.brown-100-border {
  border-color: #D7CCC8 !important; }

.brown-100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-200-fg {
  color: #BCAAA4 !important; }

.brown-200-border {
  border-color: #BCAAA4 !important; }

.brown-200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-300-bg {
  background-color: #A1887F !important; }

.md-brown-300-bg {
  background-color: #A1887F !important;
  color: white; }

.brown-300-fg {
  color: #A1887F !important; }

.brown-300-border {
  border-color: #A1887F !important; }

.brown-300-border-top {
  border-top-color: #A1887F !important; }

.brown-300-border-right {
  border-right-color: #A1887F !important; }

.brown-300-border-bottom {
  border-bottom-color: #A1887F !important; }

.brown-300-border-left {
  border-left-color: #A1887F !important; }

.brown-400-bg {
  background-color: #8D6E63 !important; }

.md-brown-400-bg {
  background-color: #8D6E63 !important;
  color: white; }

.brown-400-fg {
  color: #8D6E63 !important; }

.brown-400-border {
  border-color: #8D6E63 !important; }

.brown-400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-500-bg {
  background-color: #795548 !important; }

.md-brown-500-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-500-fg {
  color: #795548 !important; }

.brown-500-border {
  border-color: #795548 !important; }

.brown-500-border-top {
  border-top-color: #795548 !important; }

.brown-500-border-right {
  border-right-color: #795548 !important; }

.brown-500-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-500-border-left {
  border-left-color: #795548 !important; }

.md-brown-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-bg {
  background-color: #795548 !important; }

.brown-fg {
  color: #795548 !important; }

.brown-border {
  border-color: #795548 !important; }

.brown-border-top {
  border-top-color: #795548 !important; }

.brown-border-right {
  border-right-color: #795548 !important; }

.brown-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-border-left {
  border-left-color: #795548 !important; }

.brown-600-bg {
  background-color: #6D4C41 !important; }

.md-brown-600-bg {
  background-color: #6D4C41 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-600-fg {
  color: #6D4C41 !important; }

.brown-600-border {
  border-color: #6D4C41 !important; }

.brown-600-border-top {
  border-top-color: #6D4C41 !important; }

.brown-600-border-right {
  border-right-color: #6D4C41 !important; }

.brown-600-border-bottom {
  border-bottom-color: #6D4C41 !important; }

.brown-600-border-left {
  border-left-color: #6D4C41 !important; }

.brown-700-bg {
  background-color: #5D4037 !important; }

.md-brown-700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-700-fg {
  color: #5D4037 !important; }

.brown-700-border {
  border-color: #5D4037 !important; }

.brown-700-border-top {
  border-top-color: #5D4037 !important; }

.brown-700-border-right {
  border-right-color: #5D4037 !important; }

.brown-700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-700-border-left {
  border-left-color: #5D4037 !important; }

.brown-800-bg {
  background-color: #4E342E !important; }

.md-brown-800-bg {
  background-color: #4E342E !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-800-fg {
  color: #4E342E !important; }

.brown-800-border {
  border-color: #4E342E !important; }

.brown-800-border-top {
  border-top-color: #4E342E !important; }

.brown-800-border-right {
  border-right-color: #4E342E !important; }

.brown-800-border-bottom {
  border-bottom-color: #4E342E !important; }

.brown-800-border-left {
  border-left-color: #4E342E !important; }

.brown-900-bg {
  background-color: #3E2723 !important; }

.md-brown-900-bg {
  background-color: #3E2723 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-900-fg {
  color: #3E2723 !important; }

.brown-900-border {
  border-color: #3E2723 !important; }

.brown-900-border-top {
  border-top-color: #3E2723 !important; }

.brown-900-border-right {
  border-right-color: #3E2723 !important; }

.brown-900-border-bottom {
  border-bottom-color: #3E2723 !important; }

.brown-900-border-left {
  border-left-color: #3E2723 !important; }

.brown-A100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-A100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A100-fg {
  color: #D7CCC8 !important; }

.brown-A100-border {
  border-color: #D7CCC8 !important; }

.brown-A100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-A100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-A100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-A100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-A200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-A200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A200-fg {
  color: #BCAAA4 !important; }

.brown-A200-border {
  border-color: #BCAAA4 !important; }

.brown-A200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-A200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-A200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-A200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-A400-bg {
  background-color: #8D6E63 !important; }

.md-brown-A400-bg {
  background-color: #8D6E63 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A400-fg {
  color: #8D6E63 !important; }

.brown-A400-border {
  border-color: #8D6E63 !important; }

.brown-A400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-A400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-A400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-A400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-A700-bg {
  background-color: #5D4037 !important; }

.md-brown-A700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A700-fg {
  color: #5D4037 !important; }

.brown-A700-border {
  border-color: #5D4037 !important; }

.brown-A700-border-top {
  border-top-color: #5D4037 !important; }

.brown-A700-border-right {
  border-right-color: #5D4037 !important; }

.brown-A700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-A700-border-left {
  border-left-color: #5D4037 !important; }

.grey-50-bg {
  background-color: #FAFAFA !important; }

.md-grey-50-bg {
  background-color: #FAFAFA !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-50-fg {
  color: #FAFAFA !important; }

.grey-50-border {
  border-color: #FAFAFA !important; }

.grey-50-border-top {
  border-top-color: #FAFAFA !important; }

.grey-50-border-right {
  border-right-color: #FAFAFA !important; }

.grey-50-border-bottom {
  border-bottom-color: #FAFAFA !important; }

.grey-50-border-left {
  border-left-color: #FAFAFA !important; }

.grey-100-bg {
  background-color: #F5F5F5 !important; }

.md-grey-100-bg {
  background-color: #F5F5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-100-fg {
  color: #F5F5F5 !important; }

.grey-100-border {
  border-color: #F5F5F5 !important; }

.grey-100-border-top {
  border-top-color: #F5F5F5 !important; }

.grey-100-border-right {
  border-right-color: #F5F5F5 !important; }

.grey-100-border-bottom {
  border-bottom-color: #F5F5F5 !important; }

.grey-100-border-left {
  border-left-color: #F5F5F5 !important; }

.grey-200-bg {
  background-color: #EEEEEE !important; }

.md-grey-200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-200-fg {
  color: #EEEEEE !important; }

.grey-200-border {
  border-color: #EEEEEE !important; }

.grey-200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-300-bg {
  background-color: #E0E0E0 !important; }

.md-grey-300-bg {
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-300-fg {
  color: #E0E0E0 !important; }

.grey-300-border {
  border-color: #E0E0E0 !important; }

.grey-300-border-top {
  border-top-color: #E0E0E0 !important; }

.grey-300-border-right {
  border-right-color: #E0E0E0 !important; }

.grey-300-border-bottom {
  border-bottom-color: #E0E0E0 !important; }

.grey-300-border-left {
  border-left-color: #E0E0E0 !important; }

.grey-400-bg {
  background-color: #BDBDBD !important; }

.md-grey-400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-400-fg {
  color: #BDBDBD !important; }

.grey-400-border {
  border-color: #BDBDBD !important; }

.grey-400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-500-bg {
  background-color: #9E9E9E !important; }

.md-grey-500-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-500-fg {
  color: #9E9E9E !important; }

.grey-500-border {
  border-color: #9E9E9E !important; }

.grey-500-border-top {
  border-top-color: #9E9E9E !important; }

.grey-500-border-right {
  border-right-color: #9E9E9E !important; }

.grey-500-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-500-border-left {
  border-left-color: #9E9E9E !important; }

.md-grey-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-bg {
  background-color: #9E9E9E !important; }

.grey-fg {
  color: #9E9E9E !important; }

.grey-border {
  border-color: #9E9E9E !important; }

.grey-border-top {
  border-top-color: #9E9E9E !important; }

.grey-border-right {
  border-right-color: #9E9E9E !important; }

.grey-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-border-left {
  border-left-color: #9E9E9E !important; }

.grey-600-bg {
  background-color: #757575 !important; }

.md-grey-600-bg {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-600-fg {
  color: #757575 !important; }

.grey-600-border {
  border-color: #757575 !important; }

.grey-600-border-top {
  border-top-color: #757575 !important; }

.grey-600-border-right {
  border-right-color: #757575 !important; }

.grey-600-border-bottom {
  border-bottom-color: #757575 !important; }

.grey-600-border-left {
  border-left-color: #757575 !important; }

.grey-700-bg {
  background-color: #616161 !important; }

.md-grey-700-bg {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-700-fg {
  color: #616161 !important; }

.grey-700-border {
  border-color: #616161 !important; }

.grey-700-border-top {
  border-top-color: #616161 !important; }

.grey-700-border-right {
  border-right-color: #616161 !important; }

.grey-700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-700-border-left {
  border-left-color: #616161 !important; }

.grey-800-bg {
  background-color: #424242 !important; }

.md-grey-800-bg {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-800-fg {
  color: #424242 !important; }

.grey-800-border {
  border-color: #424242 !important; }

.grey-800-border-top {
  border-top-color: #424242 !important; }

.grey-800-border-right {
  border-right-color: #424242 !important; }

.grey-800-border-bottom {
  border-bottom-color: #424242 !important; }

.grey-800-border-left {
  border-left-color: #424242 !important; }

.grey-900-bg {
  background-color: #212121 !important; }

.md-grey-900-bg {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-900-fg {
  color: #212121 !important; }

.grey-900-border {
  border-color: #212121 !important; }

.grey-900-border-top {
  border-top-color: #212121 !important; }

.grey-900-border-right {
  border-right-color: #212121 !important; }

.grey-900-border-bottom {
  border-bottom-color: #212121 !important; }

.grey-900-border-left {
  border-left-color: #212121 !important; }

.grey-1000-bg {
  background-color: #000000 !important; }

.md-grey-1000-bg {
  background-color: #000000 !important; }

.grey-1000-fg {
  color: #000000 !important; }

.grey-1000-border {
  border-color: #000000 !important; }

.grey-1000-border-top {
  border-top-color: #000000 !important; }

.grey-1000-border-right {
  border-right-color: #000000 !important; }

.grey-1000-border-bottom {
  border-bottom-color: #000000 !important; }

.grey-1000-border-left {
  border-left-color: #000000 !important; }

.grey-A100-bg {
  background-color: #FFFFFF !important; }

.md-grey-A100-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A100-fg {
  color: #FFFFFF !important; }

.grey-A100-border {
  border-color: #FFFFFF !important; }

.grey-A100-border-top {
  border-top-color: #FFFFFF !important; }

.grey-A100-border-right {
  border-right-color: #FFFFFF !important; }

.grey-A100-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.grey-A100-border-left {
  border-left-color: #FFFFFF !important; }

.grey-A200-bg {
  background-color: #EEEEEE !important; }

.md-grey-A200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A200-fg {
  color: #EEEEEE !important; }

.grey-A200-border {
  border-color: #EEEEEE !important; }

.grey-A200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-A200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-A200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-A200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-A400-bg {
  background-color: #BDBDBD !important; }

.md-grey-A400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A400-fg {
  color: #BDBDBD !important; }

.grey-A400-border {
  border-color: #BDBDBD !important; }

.grey-A400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-A400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-A400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-A400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-A700-bg {
  background-color: #616161 !important; }

.md-grey-A700-bg {
  background-color: #616161 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A700-fg {
  color: #616161 !important; }

.grey-A700-border {
  border-color: #616161 !important; }

.grey-A700-border-top {
  border-top-color: #616161 !important; }

.grey-A700-border-right {
  border-right-color: #616161 !important; }

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-A700-border-left {
  border-left-color: #616161 !important; }

.blue-grey-50-bg {
  background-color: #ECEFF1 !important; }

.md-blue-grey-50-bg {
  background-color: #ECEFF1 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-50-fg {
  color: #ECEFF1 !important; }

.blue-grey-50-border {
  border-color: #ECEFF1 !important; }

.blue-grey-50-border-top {
  border-top-color: #ECEFF1 !important; }

.blue-grey-50-border-right {
  border-right-color: #ECEFF1 !important; }

.blue-grey-50-border-bottom {
  border-bottom-color: #ECEFF1 !important; }

.blue-grey-50-border-left {
  border-left-color: #ECEFF1 !important; }

.blue-grey-100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-100-bg {
  background-color: #CFD8DC !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-100-fg {
  color: #CFD8DC !important; }

.blue-grey-100-border {
  border-color: #CFD8DC !important; }

.blue-grey-100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-200-fg {
  color: #B0BEC5 !important; }

.blue-grey-200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-300-bg {
  background-color: #90A4AE !important; }

.md-blue-grey-300-bg {
  background-color: #90A4AE !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-300-fg {
  color: #90A4AE !important; }

.blue-grey-300-border {
  border-color: #90A4AE !important; }

.blue-grey-300-border-top {
  border-top-color: #90A4AE !important; }

.blue-grey-300-border-right {
  border-right-color: #90A4AE !important; }

.blue-grey-300-border-bottom {
  border-bottom-color: #90A4AE !important; }

.blue-grey-300-border-left {
  border-left-color: #90A4AE !important; }

.blue-grey-400-bg {
  background-color: #78909C !important; }

.md-blue-grey-400-bg {
  background-color: #78909C !important;
  color: white; }

.blue-grey-400-fg {
  color: #78909C !important; }

.blue-grey-400-border {
  border-color: #78909C !important; }

.blue-grey-400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-500-bg {
  background-color: #607D8B !important; }

.md-blue-grey-500-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-500-fg {
  color: #607D8B !important; }

.blue-grey-500-border {
  border-color: #607D8B !important; }

.blue-grey-500-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-500-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-500-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-500-border-left {
  border-left-color: #607D8B !important; }

.md-blue-grey-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-bg {
  background-color: #607D8B !important; }

.blue-grey-fg {
  color: #607D8B !important; }

.blue-grey-border {
  border-color: #607D8B !important; }

.blue-grey-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-border-left {
  border-left-color: #607D8B !important; }

.blue-grey-600-bg {
  background-color: #546E7A !important; }

.md-blue-grey-600-bg {
  background-color: #546E7A !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-600-fg {
  color: #546E7A !important; }

.blue-grey-600-border {
  border-color: #546E7A !important; }

.blue-grey-600-border-top {
  border-top-color: #546E7A !important; }

.blue-grey-600-border-right {
  border-right-color: #546E7A !important; }

.blue-grey-600-border-bottom {
  border-bottom-color: #546E7A !important; }

.blue-grey-600-border-left {
  border-left-color: #546E7A !important; }

.blue-grey-700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-700-fg {
  color: #455A64 !important; }

.blue-grey-700-border {
  border-color: #455A64 !important; }

.blue-grey-700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-700-border-left {
  border-left-color: #455A64 !important; }

.blue-grey-800-bg {
  background-color: #37474F !important; }

.md-blue-grey-800-bg {
  background-color: #37474F !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-800-fg {
  color: #37474F !important; }

.blue-grey-800-border {
  border-color: #37474F !important; }

.blue-grey-800-border-top {
  border-top-color: #37474F !important; }

.blue-grey-800-border-right {
  border-right-color: #37474F !important; }

.blue-grey-800-border-bottom {
  border-bottom-color: #37474F !important; }

.blue-grey-800-border-left {
  border-left-color: #37474F !important; }

.blue-grey-900-bg {
  background-color: #263238 !important; }

.md-blue-grey-900-bg {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-900-fg {
  color: #263238 !important; }

.blue-grey-900-border {
  border-color: #263238 !important; }

.blue-grey-900-border-top {
  border-top-color: #263238 !important; }

.blue-grey-900-border-right {
  border-right-color: #263238 !important; }

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important; }

.blue-grey-900-border-left {
  border-left-color: #263238 !important; }

.blue-grey-A100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-A100-bg {
  background-color: #CFD8DC !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A100-fg {
  color: #CFD8DC !important; }

.blue-grey-A100-border {
  border-color: #CFD8DC !important; }

.blue-grey-A100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-A100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-A100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-A100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-A200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A200-fg {
  color: #B0BEC5 !important; }

.blue-grey-A200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-A200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-A200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-A200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-A200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-A400-bg {
  background-color: #78909C !important; }

.md-blue-grey-A400-bg {
  background-color: #78909C !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A400-fg {
  color: #78909C !important; }

.blue-grey-A400-border {
  border-color: #78909C !important; }

.blue-grey-A400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-A400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-A400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-A700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-A700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A700-fg {
  color: #455A64 !important; }

.blue-grey-A700-border {
  border-color: #455A64 !important; }

.blue-grey-A700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-A700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-A700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-A700-border-left {
  border-left-color: #455A64 !important; }

.white-500-bg {
  background-color: #FFFFFF !important; }

.md-white-500-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-500-fg, md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container input,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container label,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container label::after, md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input:focus,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label::after {
  color: #FFFFFF !important; }

.white-500-border {
  border-color: #FFFFFF !important; }

.white-500-border-top {
  border-top-color: #FFFFFF !important; }

.white-500-border-right {
  border-right-color: #FFFFFF !important; }

.white-500-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-500-border-left {
  border-left-color: #FFFFFF !important; }

.md-white-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-bg {
  background-color: #FFFFFF !important; }

.white-fg {
  color: #FFFFFF !important; }

.white-border {
  border-color: #FFFFFF !important; }

.white-border-top {
  border-top-color: #FFFFFF !important; }

.white-border-right {
  border-right-color: #FFFFFF !important; }

.white-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-border-left {
  border-left-color: #FFFFFF !important; }

.black-500-bg {
  background-color: #000000 !important; }

.md-black-500-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-500-fg {
  color: #000000 !important; }

.black-500-border {
  border-color: #000000 !important; }

.black-500-border-top {
  border-top-color: #000000 !important; }

.black-500-border-right {
  border-right-color: #000000 !important; }

.black-500-border-bottom {
  border-bottom-color: #000000 !important; }

.black-500-border-left {
  border-left-color: #000000 !important; }

.md-black-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-bg {
  background-color: #000000 !important; }

.black-fg {
  color: #000000 !important; }

.black-border {
  border-color: #000000 !important; }

.black-border-top {
  border-top-color: #000000 !important; }

.black-border-right {
  border-right-color: #000000 !important; }

.black-border-bottom {
  border-bottom-color: #000000 !important; }

.black-border-left {
  border-left-color: #000000 !important; }

/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/
html {
  font-size: 62.5%;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.4;
  letter-spacing: -0.1px; }

body {
  font-size: 1.3rem; }

html, body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

form {
  font-size: 1.6rem; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal; }

h1, .h1 {
  font-size: 2.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.4rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.2rem; }

.la {
  color: red !important; }

a {
  text-decoration: none; }
  a:hover, a:active {
    text-decoration: underline; }

abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54); }

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px; }
  blockquote footer {
    font-style: normal; }
    blockquote footer:before {
      content: '\2014 \00A0'; }
  blockquote.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px; }
    blockquote.reverse footer:before {
      content: ''; }
    blockquote.reverse footer:after {
      content: '\2014 \00A0'; }

dl dt {
  font-weight: bold; }

dl dd {
  margin: 4px 0 16px 0; }

mark {
  background: #F7F49A; }

small {
  font-size: 80%; }

table thead tr th {
  text-align: left; }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-size-1 {
  font-size: 1px; }

.font-size-2 {
  font-size: 2px; }

.font-size-3 {
  font-size: 3px; }

.font-size-4 {
  font-size: 4px; }

.font-size-5 {
  font-size: 5px; }

.font-size-6 {
  font-size: 6px; }

.font-size-7 {
  font-size: 7px; }

.font-size-8 {
  font-size: 8px; }

.font-size-9 {
  font-size: 9px; }

.font-size-10 {
  font-size: 10px; }

.font-size-11 {
  font-size: 11px; }

.font-size-12 {
  font-size: 12px; }

.font-size-13 {
  font-size: 13px; }

.font-size-14 {
  font-size: 14px; }

.font-size-15 {
  font-size: 15px; }

.font-size-16 {
  font-size: 16px; }

.font-size-17 {
  font-size: 17px; }

.font-size-18 {
  font-size: 18px; }

.font-size-19 {
  font-size: 19px; }

.font-size-20 {
  font-size: 20px; }

.font-size-21 {
  font-size: 21px; }

.font-size-22 {
  font-size: 22px; }

.font-size-23 {
  font-size: 23px; }

.font-size-24 {
  font-size: 24px; }

.font-size-25 {
  font-size: 25px; }

.font-size-26 {
  font-size: 26px; }

.font-size-27 {
  font-size: 27px; }

.font-size-28 {
  font-size: 28px; }

.font-size-29 {
  font-size: 29px; }

.font-size-30 {
  font-size: 30px; }

.font-size-31 {
  font-size: 31px; }

.font-size-32 {
  font-size: 32px; }

.font-size-33 {
  font-size: 33px; }

.font-size-34 {
  font-size: 34px; }

.font-size-35 {
  font-size: 35px; }

.font-size-36 {
  font-size: 36px; }

.font-size-37 {
  font-size: 37px; }

.font-size-38 {
  font-size: 38px; }

.font-size-39 {
  font-size: 39px; }

.font-size-40 {
  font-size: 40px; }

.font-size-41 {
  font-size: 41px; }

.font-size-42 {
  font-size: 42px; }

.font-size-43 {
  font-size: 43px; }

.font-size-44 {
  font-size: 44px; }

.font-size-45 {
  font-size: 45px; }

.font-size-46 {
  font-size: 46px; }

.font-size-47 {
  font-size: 47px; }

.font-size-48 {
  font-size: 48px; }

.font-size-49 {
  font-size: 49px; }

.font-size-50 {
  font-size: 50px; }

.font-size-51 {
  font-size: 51px; }

.font-size-52 {
  font-size: 52px; }

.font-size-53 {
  font-size: 53px; }

.font-size-54 {
  font-size: 54px; }

.font-size-55 {
  font-size: 55px; }

.font-size-56 {
  font-size: 56px; }

.font-size-57 {
  font-size: 57px; }

.font-size-58 {
  font-size: 58px; }

.font-size-59 {
  font-size: 59px; }

.font-size-60 {
  font-size: 60px; }

.font-size-61 {
  font-size: 61px; }

.font-size-62 {
  font-size: 62px; }

.font-size-63 {
  font-size: 63px; }

.font-size-64 {
  font-size: 64px; }

.font-size-65 {
  font-size: 65px; }

.font-size-66 {
  font-size: 66px; }

.font-size-67 {
  font-size: 67px; }

.font-size-68 {
  font-size: 68px; }

.font-size-69 {
  font-size: 69px; }

.font-size-70 {
  font-size: 70px; }

.font-size-71 {
  font-size: 71px; }

.font-size-72 {
  font-size: 72px; }

.font-size-73 {
  font-size: 73px; }

.font-size-74 {
  font-size: 74px; }

.font-size-75 {
  font-size: 75px; }

.font-size-76 {
  font-size: 76px; }

.font-size-77 {
  font-size: 77px; }

.font-size-78 {
  font-size: 78px; }

.font-size-79 {
  font-size: 79px; }

.font-size-80 {
  font-size: 80px; }

.font-size-81 {
  font-size: 81px; }

.font-size-82 {
  font-size: 82px; }

.font-size-83 {
  font-size: 83px; }

.font-size-84 {
  font-size: 84px; }

.font-size-85 {
  font-size: 85px; }

.font-size-86 {
  font-size: 86px; }

.font-size-87 {
  font-size: 87px; }

.font-size-88 {
  font-size: 88px; }

.font-size-89 {
  font-size: 89px; }

.font-size-90 {
  font-size: 90px; }

.font-size-91 {
  font-size: 91px; }

.font-size-92 {
  font-size: 92px; }

.font-size-93 {
  font-size: 93px; }

.font-size-94 {
  font-size: 94px; }

.font-size-95 {
  font-size: 95px; }

.font-size-96 {
  font-size: 96px; }

.font-size-97 {
  font-size: 97px; }

.font-size-98 {
  font-size: 98px; }

.font-size-99 {
  font-size: 99px; }

.font-size-100 {
  font-size: 100px; }

.line-height-2 {
  line-height: 2px; }

.line-height-4 {
  line-height: 4px; }

.line-height-6 {
  line-height: 6px; }

.line-height-8 {
  line-height: 8px; }

.line-height-10 {
  line-height: 10px; }

.line-height-12 {
  line-height: 12px; }

.line-height-14 {
  line-height: 14px; }

.line-height-16 {
  line-height: 16px; }

.line-height-18 {
  line-height: 18px; }

.line-height-20 {
  line-height: 20px; }

.line-height-22 {
  line-height: 22px; }

.line-height-24 {
  line-height: 24px; }

.line-height-26 {
  line-height: 26px; }

.line-height-28 {
  line-height: 28px; }

.line-height-30 {
  line-height: 30px; }

.line-height-32 {
  line-height: 32px; }

.line-height-34 {
  line-height: 34px; }

.line-height-36 {
  line-height: 36px; }

.line-height-38 {
  line-height: 38px; }

.line-height-40 {
  line-height: 40px; }

.line-height-42 {
  line-height: 42px; }

.line-height-44 {
  line-height: 44px; }

.line-height-46 {
  line-height: 46px; }

.line-height-48 {
  line-height: 48px; }

.line-height-50 {
  line-height: 50px; }

.line-height-52 {
  line-height: 52px; }

.line-height-54 {
  line-height: 54px; }

.line-height-56 {
  line-height: 56px; }

.line-height-58 {
  line-height: 58px; }

.line-height-60 {
  line-height: 60px; }

.line-height-62 {
  line-height: 62px; }

.line-height-64 {
  line-height: 64px; }

.line-height-66 {
  line-height: 66px; }

.line-height-68 {
  line-height: 68px; }

.line-height-70 {
  line-height: 70px; }

.line-height-72 {
  line-height: 72px; }

.line-height-74 {
  line-height: 74px; }

.line-height-76 {
  line-height: 76px; }

.line-height-78 {
  line-height: 78px; }

.line-height-80 {
  line-height: 80px; }

.line-height-82 {
  line-height: 82px; }

.line-height-84 {
  line-height: 84px; }

.line-height-86 {
  line-height: 86px; }

.line-height-88 {
  line-height: 88px; }

.line-height-90 {
  line-height: 90px; }

.line-height-92 {
  line-height: 92px; }

.line-height-94 {
  line-height: 94px; }

.line-height-96 {
  line-height: 96px; }

.line-height-98 {
  line-height: 98px; }

.line-height-100 {
  line-height: 100px; }

.line-height-102 {
  line-height: 102px; }

.line-height-104 {
  line-height: 104px; }

.line-height-106 {
  line-height: 106px; }

.line-height-108 {
  line-height: 108px; }

.line-height-110 {
  line-height: 110px; }

.line-height-112 {
  line-height: 112px; }

.line-height-114 {
  line-height: 114px; }

.line-height-116 {
  line-height: 116px; }

.line-height-118 {
  line-height: 118px; }

.line-height-120 {
  line-height: 120px; }

md-icon,
md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px; }
  md-icon.s4,
  md-icon[md-font-icon].s4,
  i.s4 {
    font-size: 4px !important;
    width: 4px !important;
    height: 4px !important;
    min-width: 4px !important;
    min-height: 4px !important;
    line-height: 4px !important; }
  md-icon.s6,
  md-icon[md-font-icon].s6,
  i.s6 {
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    min-width: 6px !important;
    min-height: 6px !important;
    line-height: 6px !important; }
  md-icon.s8,
  md-icon[md-font-icon].s8,
  i.s8 {
    font-size: 8px !important;
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    min-height: 8px !important;
    line-height: 8px !important; }
  md-icon.s10,
  md-icon[md-font-icon].s10,
  i.s10 {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
    min-width: 10px !important;
    min-height: 10px !important;
    line-height: 10px !important; }
  md-icon.s12,
  md-icon[md-font-icon].s12,
  i.s12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    line-height: 12px !important; }
  md-icon.s14,
  md-icon[md-font-icon].s14,
  i.s14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    line-height: 14px !important; }
  md-icon.s16,
  md-icon[md-font-icon].s16,
  i.s16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    line-height: 16px !important; }
  md-icon.s18,
  md-icon[md-font-icon].s18,
  i.s18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important; }
  md-icon.s20,
  md-icon[md-font-icon].s20,
  i.s20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important; }
  md-icon.s22,
  md-icon[md-font-icon].s22,
  i.s22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    line-height: 22px !important; }
  md-icon.s24,
  md-icon[md-font-icon].s24,
  i.s24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important; }
  md-icon.s26,
  md-icon[md-font-icon].s26,
  i.s26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    min-width: 26px !important;
    min-height: 26px !important;
    line-height: 26px !important; }
  md-icon.s28,
  md-icon[md-font-icon].s28,
  i.s28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important; }
  md-icon.s30,
  md-icon[md-font-icon].s30,
  i.s30 {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important; }
  md-icon.s32,
  md-icon[md-font-icon].s32,
  i.s32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    line-height: 32px !important; }
  md-icon.s34,
  md-icon[md-font-icon].s34,
  i.s34 {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    line-height: 34px !important; }
  md-icon.s36,
  md-icon[md-font-icon].s36,
  i.s36 {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    line-height: 36px !important; }
  md-icon.s38,
  md-icon[md-font-icon].s38,
  i.s38 {
    font-size: 38px !important;
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important; }
  md-icon.s40,
  md-icon[md-font-icon].s40,
  i.s40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important; }
  md-icon.s42,
  md-icon[md-font-icon].s42,
  i.s42 {
    font-size: 42px !important;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    line-height: 42px !important; }
  md-icon.s44,
  md-icon[md-font-icon].s44,
  i.s44 {
    font-size: 44px !important;
    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
    line-height: 44px !important; }
  md-icon.s46,
  md-icon[md-font-icon].s46,
  i.s46 {
    font-size: 46px !important;
    width: 46px !important;
    height: 46px !important;
    min-width: 46px !important;
    min-height: 46px !important;
    line-height: 46px !important; }
  md-icon.s48,
  md-icon[md-font-icon].s48,
  i.s48 {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    line-height: 48px !important; }
  md-icon.s50,
  md-icon[md-font-icon].s50,
  i.s50 {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    line-height: 50px !important; }
  md-icon.s52,
  md-icon[md-font-icon].s52,
  i.s52 {
    font-size: 52px !important;
    width: 52px !important;
    height: 52px !important;
    min-width: 52px !important;
    min-height: 52px !important;
    line-height: 52px !important; }
  md-icon.s54,
  md-icon[md-font-icon].s54,
  i.s54 {
    font-size: 54px !important;
    width: 54px !important;
    height: 54px !important;
    min-width: 54px !important;
    min-height: 54px !important;
    line-height: 54px !important; }
  md-icon.s56,
  md-icon[md-font-icon].s56,
  i.s56 {
    font-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    line-height: 56px !important; }
  md-icon.s58,
  md-icon[md-font-icon].s58,
  i.s58 {
    font-size: 58px !important;
    width: 58px !important;
    height: 58px !important;
    min-width: 58px !important;
    min-height: 58px !important;
    line-height: 58px !important; }
  md-icon.s60,
  md-icon[md-font-icon].s60,
  i.s60 {
    font-size: 60px !important;
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;
    min-height: 60px !important;
    line-height: 60px !important; }
  md-icon.s62,
  md-icon[md-font-icon].s62,
  i.s62 {
    font-size: 62px !important;
    width: 62px !important;
    height: 62px !important;
    min-width: 62px !important;
    min-height: 62px !important;
    line-height: 62px !important; }
  md-icon.s64,
  md-icon[md-font-icon].s64,
  i.s64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    line-height: 64px !important; }
  md-icon.s66,
  md-icon[md-font-icon].s66,
  i.s66 {
    font-size: 66px !important;
    width: 66px !important;
    height: 66px !important;
    min-width: 66px !important;
    min-height: 66px !important;
    line-height: 66px !important; }
  md-icon.s68,
  md-icon[md-font-icon].s68,
  i.s68 {
    font-size: 68px !important;
    width: 68px !important;
    height: 68px !important;
    min-width: 68px !important;
    min-height: 68px !important;
    line-height: 68px !important; }
  md-icon.s70,
  md-icon[md-font-icon].s70,
  i.s70 {
    font-size: 70px !important;
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    line-height: 70px !important; }
  md-icon.s72,
  md-icon[md-font-icon].s72,
  i.s72 {
    font-size: 72px !important;
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    line-height: 72px !important; }
  md-icon.s74,
  md-icon[md-font-icon].s74,
  i.s74 {
    font-size: 74px !important;
    width: 74px !important;
    height: 74px !important;
    min-width: 74px !important;
    min-height: 74px !important;
    line-height: 74px !important; }
  md-icon.s76,
  md-icon[md-font-icon].s76,
  i.s76 {
    font-size: 76px !important;
    width: 76px !important;
    height: 76px !important;
    min-width: 76px !important;
    min-height: 76px !important;
    line-height: 76px !important; }
  md-icon.s78,
  md-icon[md-font-icon].s78,
  i.s78 {
    font-size: 78px !important;
    width: 78px !important;
    height: 78px !important;
    min-width: 78px !important;
    min-height: 78px !important;
    line-height: 78px !important; }
  md-icon.s80,
  md-icon[md-font-icon].s80,
  i.s80 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    line-height: 80px !important; }
  md-icon.s82,
  md-icon[md-font-icon].s82,
  i.s82 {
    font-size: 82px !important;
    width: 82px !important;
    height: 82px !important;
    min-width: 82px !important;
    min-height: 82px !important;
    line-height: 82px !important; }
  md-icon.s84,
  md-icon[md-font-icon].s84,
  i.s84 {
    font-size: 84px !important;
    width: 84px !important;
    height: 84px !important;
    min-width: 84px !important;
    min-height: 84px !important;
    line-height: 84px !important; }
  md-icon.s86,
  md-icon[md-font-icon].s86,
  i.s86 {
    font-size: 86px !important;
    width: 86px !important;
    height: 86px !important;
    min-width: 86px !important;
    min-height: 86px !important;
    line-height: 86px !important; }
  md-icon.s88,
  md-icon[md-font-icon].s88,
  i.s88 {
    font-size: 88px !important;
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    line-height: 88px !important; }
  md-icon.s90,
  md-icon[md-font-icon].s90,
  i.s90 {
    font-size: 90px !important;
    width: 90px !important;
    height: 90px !important;
    min-width: 90px !important;
    min-height: 90px !important;
    line-height: 90px !important; }
  md-icon.s92,
  md-icon[md-font-icon].s92,
  i.s92 {
    font-size: 92px !important;
    width: 92px !important;
    height: 92px !important;
    min-width: 92px !important;
    min-height: 92px !important;
    line-height: 92px !important; }
  md-icon.s94,
  md-icon[md-font-icon].s94,
  i.s94 {
    font-size: 94px !important;
    width: 94px !important;
    height: 94px !important;
    min-width: 94px !important;
    min-height: 94px !important;
    line-height: 94px !important; }
  md-icon.s96,
  md-icon[md-font-icon].s96,
  i.s96 {
    font-size: 96px !important;
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    line-height: 96px !important; }
  md-icon.s98,
  md-icon[md-font-icon].s98,
  i.s98 {
    font-size: 98px !important;
    width: 98px !important;
    height: 98px !important;
    min-width: 98px !important;
    min-height: 98px !important;
    line-height: 98px !important; }
  md-icon.s100,
  md-icon[md-font-icon].s100,
  i.s100 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    line-height: 100px !important; }
  md-icon.s102,
  md-icon[md-font-icon].s102,
  i.s102 {
    font-size: 102px !important;
    width: 102px !important;
    height: 102px !important;
    min-width: 102px !important;
    min-height: 102px !important;
    line-height: 102px !important; }
  md-icon.s104,
  md-icon[md-font-icon].s104,
  i.s104 {
    font-size: 104px !important;
    width: 104px !important;
    height: 104px !important;
    min-width: 104px !important;
    min-height: 104px !important;
    line-height: 104px !important; }
  md-icon.s106,
  md-icon[md-font-icon].s106,
  i.s106 {
    font-size: 106px !important;
    width: 106px !important;
    height: 106px !important;
    min-width: 106px !important;
    min-height: 106px !important;
    line-height: 106px !important; }
  md-icon.s108,
  md-icon[md-font-icon].s108,
  i.s108 {
    font-size: 108px !important;
    width: 108px !important;
    height: 108px !important;
    min-width: 108px !important;
    min-height: 108px !important;
    line-height: 108px !important; }
  md-icon.s110,
  md-icon[md-font-icon].s110,
  i.s110 {
    font-size: 110px !important;
    width: 110px !important;
    height: 110px !important;
    min-width: 110px !important;
    min-height: 110px !important;
    line-height: 110px !important; }
  md-icon.s112,
  md-icon[md-font-icon].s112,
  i.s112 {
    font-size: 112px !important;
    width: 112px !important;
    height: 112px !important;
    min-width: 112px !important;
    min-height: 112px !important;
    line-height: 112px !important; }
  md-icon.s114,
  md-icon[md-font-icon].s114,
  i.s114 {
    font-size: 114px !important;
    width: 114px !important;
    height: 114px !important;
    min-width: 114px !important;
    min-height: 114px !important;
    line-height: 114px !important; }
  md-icon.s116,
  md-icon[md-font-icon].s116,
  i.s116 {
    font-size: 116px !important;
    width: 116px !important;
    height: 116px !important;
    min-width: 116px !important;
    min-height: 116px !important;
    line-height: 116px !important; }
  md-icon.s118,
  md-icon[md-font-icon].s118,
  i.s118 {
    font-size: 118px !important;
    width: 118px !important;
    height: 118px !important;
    min-width: 118px !important;
    min-height: 118px !important;
    line-height: 118px !important; }
  md-icon.s120,
  md-icon[md-font-icon].s120,
  i.s120 {
    font-size: 120px !important;
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
    min-height: 120px !important;
    line-height: 120px !important; }
  md-icon.s122,
  md-icon[md-font-icon].s122,
  i.s122 {
    font-size: 122px !important;
    width: 122px !important;
    height: 122px !important;
    min-width: 122px !important;
    min-height: 122px !important;
    line-height: 122px !important; }
  md-icon.s124,
  md-icon[md-font-icon].s124,
  i.s124 {
    font-size: 124px !important;
    width: 124px !important;
    height: 124px !important;
    min-width: 124px !important;
    min-height: 124px !important;
    line-height: 124px !important; }
  md-icon.s126,
  md-icon[md-font-icon].s126,
  i.s126 {
    font-size: 126px !important;
    width: 126px !important;
    height: 126px !important;
    min-width: 126px !important;
    min-height: 126px !important;
    line-height: 126px !important; }
  md-icon.s128,
  md-icon[md-font-icon].s128,
  i.s128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    min-width: 128px !important;
    min-height: 128px !important;
    line-height: 128px !important; }
  md-icon.s130,
  md-icon[md-font-icon].s130,
  i.s130 {
    font-size: 130px !important;
    width: 130px !important;
    height: 130px !important;
    min-width: 130px !important;
    min-height: 130px !important;
    line-height: 130px !important; }
  md-icon.s132,
  md-icon[md-font-icon].s132,
  i.s132 {
    font-size: 132px !important;
    width: 132px !important;
    height: 132px !important;
    min-width: 132px !important;
    min-height: 132px !important;
    line-height: 132px !important; }
  md-icon.s134,
  md-icon[md-font-icon].s134,
  i.s134 {
    font-size: 134px !important;
    width: 134px !important;
    height: 134px !important;
    min-width: 134px !important;
    min-height: 134px !important;
    line-height: 134px !important; }
  md-icon.s136,
  md-icon[md-font-icon].s136,
  i.s136 {
    font-size: 136px !important;
    width: 136px !important;
    height: 136px !important;
    min-width: 136px !important;
    min-height: 136px !important;
    line-height: 136px !important; }
  md-icon.s138,
  md-icon[md-font-icon].s138,
  i.s138 {
    font-size: 138px !important;
    width: 138px !important;
    height: 138px !important;
    min-width: 138px !important;
    min-height: 138px !important;
    line-height: 138px !important; }
  md-icon.s140,
  md-icon[md-font-icon].s140,
  i.s140 {
    font-size: 140px !important;
    width: 140px !important;
    height: 140px !important;
    min-width: 140px !important;
    min-height: 140px !important;
    line-height: 140px !important; }
  md-icon.s142,
  md-icon[md-font-icon].s142,
  i.s142 {
    font-size: 142px !important;
    width: 142px !important;
    height: 142px !important;
    min-width: 142px !important;
    min-height: 142px !important;
    line-height: 142px !important; }
  md-icon.s144,
  md-icon[md-font-icon].s144,
  i.s144 {
    font-size: 144px !important;
    width: 144px !important;
    height: 144px !important;
    min-width: 144px !important;
    min-height: 144px !important;
    line-height: 144px !important; }
  md-icon.s146,
  md-icon[md-font-icon].s146,
  i.s146 {
    font-size: 146px !important;
    width: 146px !important;
    height: 146px !important;
    min-width: 146px !important;
    min-height: 146px !important;
    line-height: 146px !important; }
  md-icon.s148,
  md-icon[md-font-icon].s148,
  i.s148 {
    font-size: 148px !important;
    width: 148px !important;
    height: 148px !important;
    min-width: 148px !important;
    min-height: 148px !important;
    line-height: 148px !important; }
  md-icon.s150,
  md-icon[md-font-icon].s150,
  i.s150 {
    font-size: 150px !important;
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
    min-height: 150px !important;
    line-height: 150px !important; }
  md-icon.s152,
  md-icon[md-font-icon].s152,
  i.s152 {
    font-size: 152px !important;
    width: 152px !important;
    height: 152px !important;
    min-width: 152px !important;
    min-height: 152px !important;
    line-height: 152px !important; }
  md-icon.s154,
  md-icon[md-font-icon].s154,
  i.s154 {
    font-size: 154px !important;
    width: 154px !important;
    height: 154px !important;
    min-width: 154px !important;
    min-height: 154px !important;
    line-height: 154px !important; }
  md-icon.s156,
  md-icon[md-font-icon].s156,
  i.s156 {
    font-size: 156px !important;
    width: 156px !important;
    height: 156px !important;
    min-width: 156px !important;
    min-height: 156px !important;
    line-height: 156px !important; }
  md-icon.s158,
  md-icon[md-font-icon].s158,
  i.s158 {
    font-size: 158px !important;
    width: 158px !important;
    height: 158px !important;
    min-width: 158px !important;
    min-height: 158px !important;
    line-height: 158px !important; }
  md-icon.s160,
  md-icon[md-font-icon].s160,
  i.s160 {
    font-size: 160px !important;
    width: 160px !important;
    height: 160px !important;
    min-width: 160px !important;
    min-height: 160px !important;
    line-height: 160px !important; }
  md-icon.s162,
  md-icon[md-font-icon].s162,
  i.s162 {
    font-size: 162px !important;
    width: 162px !important;
    height: 162px !important;
    min-width: 162px !important;
    min-height: 162px !important;
    line-height: 162px !important; }
  md-icon.s164,
  md-icon[md-font-icon].s164,
  i.s164 {
    font-size: 164px !important;
    width: 164px !important;
    height: 164px !important;
    min-width: 164px !important;
    min-height: 164px !important;
    line-height: 164px !important; }
  md-icon.s166,
  md-icon[md-font-icon].s166,
  i.s166 {
    font-size: 166px !important;
    width: 166px !important;
    height: 166px !important;
    min-width: 166px !important;
    min-height: 166px !important;
    line-height: 166px !important; }
  md-icon.s168,
  md-icon[md-font-icon].s168,
  i.s168 {
    font-size: 168px !important;
    width: 168px !important;
    height: 168px !important;
    min-width: 168px !important;
    min-height: 168px !important;
    line-height: 168px !important; }
  md-icon.s170,
  md-icon[md-font-icon].s170,
  i.s170 {
    font-size: 170px !important;
    width: 170px !important;
    height: 170px !important;
    min-width: 170px !important;
    min-height: 170px !important;
    line-height: 170px !important; }
  md-icon.s172,
  md-icon[md-font-icon].s172,
  i.s172 {
    font-size: 172px !important;
    width: 172px !important;
    height: 172px !important;
    min-width: 172px !important;
    min-height: 172px !important;
    line-height: 172px !important; }
  md-icon.s174,
  md-icon[md-font-icon].s174,
  i.s174 {
    font-size: 174px !important;
    width: 174px !important;
    height: 174px !important;
    min-width: 174px !important;
    min-height: 174px !important;
    line-height: 174px !important; }
  md-icon.s176,
  md-icon[md-font-icon].s176,
  i.s176 {
    font-size: 176px !important;
    width: 176px !important;
    height: 176px !important;
    min-width: 176px !important;
    min-height: 176px !important;
    line-height: 176px !important; }
  md-icon.s178,
  md-icon[md-font-icon].s178,
  i.s178 {
    font-size: 178px !important;
    width: 178px !important;
    height: 178px !important;
    min-width: 178px !important;
    min-height: 178px !important;
    line-height: 178px !important; }
  md-icon.s180,
  md-icon[md-font-icon].s180,
  i.s180 {
    font-size: 180px !important;
    width: 180px !important;
    height: 180px !important;
    min-width: 180px !important;
    min-height: 180px !important;
    line-height: 180px !important; }
  md-icon.s182,
  md-icon[md-font-icon].s182,
  i.s182 {
    font-size: 182px !important;
    width: 182px !important;
    height: 182px !important;
    min-width: 182px !important;
    min-height: 182px !important;
    line-height: 182px !important; }
  md-icon.s184,
  md-icon[md-font-icon].s184,
  i.s184 {
    font-size: 184px !important;
    width: 184px !important;
    height: 184px !important;
    min-width: 184px !important;
    min-height: 184px !important;
    line-height: 184px !important; }
  md-icon.s186,
  md-icon[md-font-icon].s186,
  i.s186 {
    font-size: 186px !important;
    width: 186px !important;
    height: 186px !important;
    min-width: 186px !important;
    min-height: 186px !important;
    line-height: 186px !important; }
  md-icon.s188,
  md-icon[md-font-icon].s188,
  i.s188 {
    font-size: 188px !important;
    width: 188px !important;
    height: 188px !important;
    min-width: 188px !important;
    min-height: 188px !important;
    line-height: 188px !important; }
  md-icon.s190,
  md-icon[md-font-icon].s190,
  i.s190 {
    font-size: 190px !important;
    width: 190px !important;
    height: 190px !important;
    min-width: 190px !important;
    min-height: 190px !important;
    line-height: 190px !important; }
  md-icon.s192,
  md-icon[md-font-icon].s192,
  i.s192 {
    font-size: 192px !important;
    width: 192px !important;
    height: 192px !important;
    min-width: 192px !important;
    min-height: 192px !important;
    line-height: 192px !important; }
  md-icon.s194,
  md-icon[md-font-icon].s194,
  i.s194 {
    font-size: 194px !important;
    width: 194px !important;
    height: 194px !important;
    min-width: 194px !important;
    min-height: 194px !important;
    line-height: 194px !important; }
  md-icon.s196,
  md-icon[md-font-icon].s196,
  i.s196 {
    font-size: 196px !important;
    width: 196px !important;
    height: 196px !important;
    min-width: 196px !important;
    min-height: 196px !important;
    line-height: 196px !important; }
  md-icon.s198,
  md-icon[md-font-icon].s198,
  i.s198 {
    font-size: 198px !important;
    width: 198px !important;
    height: 198px !important;
    min-width: 198px !important;
    min-height: 198px !important;
    line-height: 198px !important; }
  md-icon.s200,
  md-icon[md-font-icon].s200,
  i.s200 {
    font-size: 200px !important;
    width: 200px !important;
    height: 200px !important;
    min-width: 200px !important;
    min-height: 200px !important;
    line-height: 200px !important; }
  md-icon.s202,
  md-icon[md-font-icon].s202,
  i.s202 {
    font-size: 202px !important;
    width: 202px !important;
    height: 202px !important;
    min-width: 202px !important;
    min-height: 202px !important;
    line-height: 202px !important; }
  md-icon.s204,
  md-icon[md-font-icon].s204,
  i.s204 {
    font-size: 204px !important;
    width: 204px !important;
    height: 204px !important;
    min-width: 204px !important;
    min-height: 204px !important;
    line-height: 204px !important; }
  md-icon.s206,
  md-icon[md-font-icon].s206,
  i.s206 {
    font-size: 206px !important;
    width: 206px !important;
    height: 206px !important;
    min-width: 206px !important;
    min-height: 206px !important;
    line-height: 206px !important; }
  md-icon.s208,
  md-icon[md-font-icon].s208,
  i.s208 {
    font-size: 208px !important;
    width: 208px !important;
    height: 208px !important;
    min-width: 208px !important;
    min-height: 208px !important;
    line-height: 208px !important; }
  md-icon.s210,
  md-icon[md-font-icon].s210,
  i.s210 {
    font-size: 210px !important;
    width: 210px !important;
    height: 210px !important;
    min-width: 210px !important;
    min-height: 210px !important;
    line-height: 210px !important; }
  md-icon.s212,
  md-icon[md-font-icon].s212,
  i.s212 {
    font-size: 212px !important;
    width: 212px !important;
    height: 212px !important;
    min-width: 212px !important;
    min-height: 212px !important;
    line-height: 212px !important; }
  md-icon.s214,
  md-icon[md-font-icon].s214,
  i.s214 {
    font-size: 214px !important;
    width: 214px !important;
    height: 214px !important;
    min-width: 214px !important;
    min-height: 214px !important;
    line-height: 214px !important; }
  md-icon.s216,
  md-icon[md-font-icon].s216,
  i.s216 {
    font-size: 216px !important;
    width: 216px !important;
    height: 216px !important;
    min-width: 216px !important;
    min-height: 216px !important;
    line-height: 216px !important; }
  md-icon.s218,
  md-icon[md-font-icon].s218,
  i.s218 {
    font-size: 218px !important;
    width: 218px !important;
    height: 218px !important;
    min-width: 218px !important;
    min-height: 218px !important;
    line-height: 218px !important; }
  md-icon.s220,
  md-icon[md-font-icon].s220,
  i.s220 {
    font-size: 220px !important;
    width: 220px !important;
    height: 220px !important;
    min-width: 220px !important;
    min-height: 220px !important;
    line-height: 220px !important; }
  md-icon.s222,
  md-icon[md-font-icon].s222,
  i.s222 {
    font-size: 222px !important;
    width: 222px !important;
    height: 222px !important;
    min-width: 222px !important;
    min-height: 222px !important;
    line-height: 222px !important; }
  md-icon.s224,
  md-icon[md-font-icon].s224,
  i.s224 {
    font-size: 224px !important;
    width: 224px !important;
    height: 224px !important;
    min-width: 224px !important;
    min-height: 224px !important;
    line-height: 224px !important; }
  md-icon.s226,
  md-icon[md-font-icon].s226,
  i.s226 {
    font-size: 226px !important;
    width: 226px !important;
    height: 226px !important;
    min-width: 226px !important;
    min-height: 226px !important;
    line-height: 226px !important; }
  md-icon.s228,
  md-icon[md-font-icon].s228,
  i.s228 {
    font-size: 228px !important;
    width: 228px !important;
    height: 228px !important;
    min-width: 228px !important;
    min-height: 228px !important;
    line-height: 228px !important; }
  md-icon.s230,
  md-icon[md-font-icon].s230,
  i.s230 {
    font-size: 230px !important;
    width: 230px !important;
    height: 230px !important;
    min-width: 230px !important;
    min-height: 230px !important;
    line-height: 230px !important; }
  md-icon.s232,
  md-icon[md-font-icon].s232,
  i.s232 {
    font-size: 232px !important;
    width: 232px !important;
    height: 232px !important;
    min-width: 232px !important;
    min-height: 232px !important;
    line-height: 232px !important; }
  md-icon.s234,
  md-icon[md-font-icon].s234,
  i.s234 {
    font-size: 234px !important;
    width: 234px !important;
    height: 234px !important;
    min-width: 234px !important;
    min-height: 234px !important;
    line-height: 234px !important; }
  md-icon.s236,
  md-icon[md-font-icon].s236,
  i.s236 {
    font-size: 236px !important;
    width: 236px !important;
    height: 236px !important;
    min-width: 236px !important;
    min-height: 236px !important;
    line-height: 236px !important; }
  md-icon.s238,
  md-icon[md-font-icon].s238,
  i.s238 {
    font-size: 238px !important;
    width: 238px !important;
    height: 238px !important;
    min-width: 238px !important;
    min-height: 238px !important;
    line-height: 238px !important; }
  md-icon.s240,
  md-icon[md-font-icon].s240,
  i.s240 {
    font-size: 240px !important;
    width: 240px !important;
    height: 240px !important;
    min-width: 240px !important;
    min-height: 240px !important;
    line-height: 240px !important; }
  md-icon.s242,
  md-icon[md-font-icon].s242,
  i.s242 {
    font-size: 242px !important;
    width: 242px !important;
    height: 242px !important;
    min-width: 242px !important;
    min-height: 242px !important;
    line-height: 242px !important; }
  md-icon.s244,
  md-icon[md-font-icon].s244,
  i.s244 {
    font-size: 244px !important;
    width: 244px !important;
    height: 244px !important;
    min-width: 244px !important;
    min-height: 244px !important;
    line-height: 244px !important; }
  md-icon.s246,
  md-icon[md-font-icon].s246,
  i.s246 {
    font-size: 246px !important;
    width: 246px !important;
    height: 246px !important;
    min-width: 246px !important;
    min-height: 246px !important;
    line-height: 246px !important; }
  md-icon.s248,
  md-icon[md-font-icon].s248,
  i.s248 {
    font-size: 248px !important;
    width: 248px !important;
    height: 248px !important;
    min-width: 248px !important;
    min-height: 248px !important;
    line-height: 248px !important; }
  md-icon.s250,
  md-icon[md-font-icon].s250,
  i.s250 {
    font-size: 250px !important;
    width: 250px !important;
    height: 250px !important;
    min-width: 250px !important;
    min-height: 250px !important;
    line-height: 250px !important; }
  md-icon.s252,
  md-icon[md-font-icon].s252,
  i.s252 {
    font-size: 252px !important;
    width: 252px !important;
    height: 252px !important;
    min-width: 252px !important;
    min-height: 252px !important;
    line-height: 252px !important; }
  md-icon.s254,
  md-icon[md-font-icon].s254,
  i.s254 {
    font-size: 254px !important;
    width: 254px !important;
    height: 254px !important;
    min-width: 254px !important;
    min-height: 254px !important;
    line-height: 254px !important; }
  md-icon.s256,
  md-icon[md-font-icon].s256,
  i.s256 {
    font-size: 256px !important;
    width: 256px !important;
    height: 256px !important;
    min-width: 256px !important;
    min-height: 256px !important;
    line-height: 256px !important; }

.text-boxed, .text-boxed-light {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap; }

.text-boxed-light {
  background-color: rgba(255, 255, 255, 0.7); }

.text-truncate, .allocations-view > .md-subheader .info-allocation,
.allocations-view > md-toolbar .info-allocation, .allocations-proData-view > .md-subheader .info-allocation,
.allocations-proData-view > md-toolbar .info-allocation, .allocationMonitrips-view > .md-subheader .info-allocationMonitrip,
.allocationMonitrips-view > md-toolbar .info-allocationMonitrip, .service-report .service-sidebar .service-view > .md-subheader .info-allocation,
.service-report .service-sidebar .service-view > md-toolbar .info-allocation {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-overflow {
  text-overflow: clip;
  overflow: visible; }

.text-nowrap {
  white-space: nowrap; }

.text-first-letter-uppercase:first-letter {
  text-transform: uppercase; }

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-radius {
  border-radius: 0; }

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px; }

.hidden {
  visibility: hidden;
  opacity: 0; }

[fill-width] {
  min-width: 100%; }

[fill-height] {
  min-height: 100%; }

.full-height {
  height: 100% !important; }

a img {
  display: block;
  outline: none; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }

.overflow-x {
  overflow-x: hidden; }

.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .flex-scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .flex-scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.align-vertical:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle; }

.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
  display: inline-block; }

.align-vertical .align-vertical-top {
  vertical-align: top; }

.align-vertical .align-vertical-middle {
  vertical-align: middle; }

.align-vertical .align-vertical-bottom {
  vertical-align: bottom; }

.align-vertical-top,
.align-vertical-middle,
.align-vertical-bottom {
  display: inline-block; }

.align-vertical-top {
  vertical-align: top; }

.align-vertical-middle {
  vertical-align: middle; }

.align-vertical-bottom {
  vertical-align: bottom; }

.align-vertical-text-bottom {
  vertical-align: text-bottom !important; }

.text-italic {
  font-style: italic; }

.text-semibold, .allocations-view md-list-item .md-list-item-text .title, .allocations-proData-view md-list-item .md-list-item-text .title, .allocationMonitrips-view md-list-item .md-list-item-text .title, md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .nv-noData, md-dialog.allocationPRODATA-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.allocationCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.allocationTicketsCentroidsCSV-list md-content md-list-item .md-list-item-text .title, md-dialog.centroidCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.lineCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.employeeCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.serviceCSV-list md-dialog-content md-list-item .md-list-item-text .title, .service-report .service-sidebar md-list-item .md-list-item-text .title {
  font-weight: 600; }

.text-bold, strong {
  font-weight: 700; }

.text-strike {
  text-decoration: line-through; }

.text-super {
  vertical-align: super; }

.text-sub {
  vertical-align: sub; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.z-index-0 {
  z-index: 0 !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.z-index-6 {
  z-index: 6 !important; }

.z-index-7 {
  z-index: 7 !important; }

.z-index-8 {
  z-index: 8 !important; }

.z-index-9 {
  z-index: 9 !important; }

.z-index-10 {
  z-index: 10 !important; }

.z-index-11 {
  z-index: 11 !important; }

.z-index-12 {
  z-index: 12 !important; }

.z-index-13 {
  z-index: 13 !important; }

.z-index-14 {
  z-index: 14 !important; }

.z-index-15 {
  z-index: 15 !important; }

.z-index-16 {
  z-index: 16 !important; }

.z-index-17 {
  z-index: 17 !important; }

.z-index-18 {
  z-index: 18 !important; }

.z-index-19 {
  z-index: 19 !important; }

.z-index-20 {
  z-index: 20 !important; }

.padding-0,
.p-0 {
  padding: 0px !important; }

.padding-top-0,
.pt-0 {
  padding-top: 0px !important; }

.padding-right-0,
.pr-0 {
  padding-right: 0px !important; }

.padding-bottom-0,
.pb-0 {
  padding-bottom: 0px !important; }

.padding-left-0,
.pl-0 {
  padding-left: 0px !important; }

.padding-horizontal-0,
.ph-0 {
  padding-left: 0px;
  padding-right: 0px; }

.padding-vertical-0,
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.padding-1,
.p-1 {
  padding: 1px !important; }

.padding-top-1,
.pt-1 {
  padding-top: 1px !important; }

.padding-right-1,
.pr-1 {
  padding-right: 1px !important; }

.padding-bottom-1,
.pb-1 {
  padding-bottom: 1px !important; }

.padding-left-1,
.pl-1 {
  padding-left: 1px !important; }

.padding-horizontal-1,
.ph-1 {
  padding-left: 1px;
  padding-right: 1px; }

.padding-vertical-1,
.pv-1 {
  padding-top: 1px;
  padding-bottom: 1px; }

.padding-2,
.p-2 {
  padding: 2px !important; }

.padding-top-2,
.pt-2 {
  padding-top: 2px !important; }

.padding-right-2,
.pr-2 {
  padding-right: 2px !important; }

.padding-bottom-2,
.pb-2 {
  padding-bottom: 2px !important; }

.padding-left-2,
.pl-2 {
  padding-left: 2px !important; }

.padding-horizontal-2,
.ph-2 {
  padding-left: 2px;
  padding-right: 2px; }

.padding-vertical-2,
.pv-2 {
  padding-top: 2px;
  padding-bottom: 2px; }

.padding-3,
.p-3 {
  padding: 3px !important; }

.padding-top-3,
.pt-3 {
  padding-top: 3px !important; }

.padding-right-3,
.pr-3 {
  padding-right: 3px !important; }

.padding-bottom-3,
.pb-3 {
  padding-bottom: 3px !important; }

.padding-left-3,
.pl-3 {
  padding-left: 3px !important; }

.padding-horizontal-3,
.ph-3 {
  padding-left: 3px;
  padding-right: 3px; }

.padding-vertical-3,
.pv-3 {
  padding-top: 3px;
  padding-bottom: 3px; }

.padding-4,
.p-4 {
  padding: 4px !important; }

.padding-top-4,
.pt-4 {
  padding-top: 4px !important; }

.padding-right-4,
.pr-4 {
  padding-right: 4px !important; }

.padding-bottom-4,
.pb-4 {
  padding-bottom: 4px !important; }

.padding-left-4,
.pl-4 {
  padding-left: 4px !important; }

.padding-horizontal-4,
.ph-4 {
  padding-left: 4px;
  padding-right: 4px; }

.padding-vertical-4,
.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px; }

.padding-5,
.p-5 {
  padding: 5px !important; }

.padding-top-5,
.pt-5 {
  padding-top: 5px !important; }

.padding-right-5,
.pr-5 {
  padding-right: 5px !important; }

.padding-bottom-5,
.pb-5 {
  padding-bottom: 5px !important; }

.padding-left-5,
.pl-5 {
  padding-left: 5px !important; }

.padding-horizontal-5,
.ph-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding-vertical-5,
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.padding-6,
.p-6 {
  padding: 6px !important; }

.padding-top-6,
.pt-6 {
  padding-top: 6px !important; }

.padding-right-6,
.pr-6 {
  padding-right: 6px !important; }

.padding-bottom-6,
.pb-6 {
  padding-bottom: 6px !important; }

.padding-left-6,
.pl-6 {
  padding-left: 6px !important; }

.padding-horizontal-6,
.ph-6 {
  padding-left: 6px;
  padding-right: 6px; }

.padding-vertical-6,
.pv-6 {
  padding-top: 6px;
  padding-bottom: 6px; }

.padding-7,
.p-7 {
  padding: 7px !important; }

.padding-top-7,
.pt-7 {
  padding-top: 7px !important; }

.padding-right-7,
.pr-7 {
  padding-right: 7px !important; }

.padding-bottom-7,
.pb-7 {
  padding-bottom: 7px !important; }

.padding-left-7,
.pl-7 {
  padding-left: 7px !important; }

.padding-horizontal-7,
.ph-7 {
  padding-left: 7px;
  padding-right: 7px; }

.padding-vertical-7,
.pv-7 {
  padding-top: 7px;
  padding-bottom: 7px; }

.padding-8,
.p-8 {
  padding: 8px !important; }

.padding-top-8,
.pt-8 {
  padding-top: 8px !important; }

.padding-right-8,
.pr-8 {
  padding-right: 8px !important; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px !important; }

.padding-left-8,
.pl-8 {
  padding-left: 8px !important; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-9,
.p-9 {
  padding: 9px !important; }

.padding-top-9,
.pt-9 {
  padding-top: 9px !important; }

.padding-right-9,
.pr-9 {
  padding-right: 9px !important; }

.padding-bottom-9,
.pb-9 {
  padding-bottom: 9px !important; }

.padding-left-9,
.pl-9 {
  padding-left: 9px !important; }

.padding-horizontal-9,
.ph-9 {
  padding-left: 9px;
  padding-right: 9px; }

.padding-vertical-9,
.pv-9 {
  padding-top: 9px;
  padding-bottom: 9px; }

.padding-10,
.p-10 {
  padding: 10px !important; }

.padding-top-10,
.pt-10 {
  padding-top: 10px !important; }

.padding-right-10,
.pr-10 {
  padding-right: 10px !important; }

.padding-bottom-10,
.pb-10 {
  padding-bottom: 10px !important; }

.padding-left-10,
.pl-10 {
  padding-left: 10px !important; }

.padding-horizontal-10,
.ph-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding-vertical-10,
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.padding-11,
.p-11 {
  padding: 11px !important; }

.padding-top-11,
.pt-11 {
  padding-top: 11px !important; }

.padding-right-11,
.pr-11 {
  padding-right: 11px !important; }

.padding-bottom-11,
.pb-11 {
  padding-bottom: 11px !important; }

.padding-left-11,
.pl-11 {
  padding-left: 11px !important; }

.padding-horizontal-11,
.ph-11 {
  padding-left: 11px;
  padding-right: 11px; }

.padding-vertical-11,
.pv-11 {
  padding-top: 11px;
  padding-bottom: 11px; }

.padding-12,
.p-12 {
  padding: 12px !important; }

.padding-top-12,
.pt-12 {
  padding-top: 12px !important; }

.padding-right-12,
.pr-12 {
  padding-right: 12px !important; }

.padding-bottom-12,
.pb-12 {
  padding-bottom: 12px !important; }

.padding-left-12,
.pl-12 {
  padding-left: 12px !important; }

.padding-horizontal-12,
.ph-12 {
  padding-left: 12px;
  padding-right: 12px; }

.padding-vertical-12,
.pv-12 {
  padding-top: 12px;
  padding-bottom: 12px; }

.padding-13,
.p-13 {
  padding: 13px !important; }

.padding-top-13,
.pt-13 {
  padding-top: 13px !important; }

.padding-right-13,
.pr-13 {
  padding-right: 13px !important; }

.padding-bottom-13,
.pb-13 {
  padding-bottom: 13px !important; }

.padding-left-13,
.pl-13 {
  padding-left: 13px !important; }

.padding-horizontal-13,
.ph-13 {
  padding-left: 13px;
  padding-right: 13px; }

.padding-vertical-13,
.pv-13 {
  padding-top: 13px;
  padding-bottom: 13px; }

.padding-14,
.p-14 {
  padding: 14px !important; }

.padding-top-14,
.pt-14 {
  padding-top: 14px !important; }

.padding-right-14,
.pr-14 {
  padding-right: 14px !important; }

.padding-bottom-14,
.pb-14 {
  padding-bottom: 14px !important; }

.padding-left-14,
.pl-14 {
  padding-left: 14px !important; }

.padding-horizontal-14,
.ph-14 {
  padding-left: 14px;
  padding-right: 14px; }

.padding-vertical-14,
.pv-14 {
  padding-top: 14px;
  padding-bottom: 14px; }

.padding-15,
.p-15 {
  padding: 15px !important; }

.padding-top-15,
.pt-15 {
  padding-top: 15px !important; }

.padding-right-15,
.pr-15 {
  padding-right: 15px !important; }

.padding-bottom-15,
.pb-15 {
  padding-bottom: 15px !important; }

.padding-left-15,
.pl-15 {
  padding-left: 15px !important; }

.padding-horizontal-15,
.ph-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding-vertical-15,
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.padding-16,
.p-16 {
  padding: 16px !important; }

.padding-top-16,
.pt-16 {
  padding-top: 16px !important; }

.padding-right-16,
.pr-16 {
  padding-right: 16px !important; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px !important; }

.padding-left-16,
.pl-16 {
  padding-left: 16px !important; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-17,
.p-17 {
  padding: 17px !important; }

.padding-top-17,
.pt-17 {
  padding-top: 17px !important; }

.padding-right-17,
.pr-17 {
  padding-right: 17px !important; }

.padding-bottom-17,
.pb-17 {
  padding-bottom: 17px !important; }

.padding-left-17,
.pl-17 {
  padding-left: 17px !important; }

.padding-horizontal-17,
.ph-17 {
  padding-left: 17px;
  padding-right: 17px; }

.padding-vertical-17,
.pv-17 {
  padding-top: 17px;
  padding-bottom: 17px; }

.padding-18,
.p-18 {
  padding: 18px !important; }

.padding-top-18,
.pt-18 {
  padding-top: 18px !important; }

.padding-right-18,
.pr-18 {
  padding-right: 18px !important; }

.padding-bottom-18,
.pb-18 {
  padding-bottom: 18px !important; }

.padding-left-18,
.pl-18 {
  padding-left: 18px !important; }

.padding-horizontal-18,
.ph-18 {
  padding-left: 18px;
  padding-right: 18px; }

.padding-vertical-18,
.pv-18 {
  padding-top: 18px;
  padding-bottom: 18px; }

.padding-19,
.p-19 {
  padding: 19px !important; }

.padding-top-19,
.pt-19 {
  padding-top: 19px !important; }

.padding-right-19,
.pr-19 {
  padding-right: 19px !important; }

.padding-bottom-19,
.pb-19 {
  padding-bottom: 19px !important; }

.padding-left-19,
.pl-19 {
  padding-left: 19px !important; }

.padding-horizontal-19,
.ph-19 {
  padding-left: 19px;
  padding-right: 19px; }

.padding-vertical-19,
.pv-19 {
  padding-top: 19px;
  padding-bottom: 19px; }

.padding-20,
.p-20 {
  padding: 20px !important; }

.padding-top-20,
.pt-20 {
  padding-top: 20px !important; }

.padding-right-20,
.pr-20 {
  padding-right: 20px !important; }

.padding-bottom-20,
.pb-20 {
  padding-bottom: 20px !important; }

.padding-left-20,
.pl-20 {
  padding-left: 20px !important; }

.padding-horizontal-20,
.ph-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding-vertical-20,
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.padding-21,
.p-21 {
  padding: 21px !important; }

.padding-top-21,
.pt-21 {
  padding-top: 21px !important; }

.padding-right-21,
.pr-21 {
  padding-right: 21px !important; }

.padding-bottom-21,
.pb-21 {
  padding-bottom: 21px !important; }

.padding-left-21,
.pl-21 {
  padding-left: 21px !important; }

.padding-horizontal-21,
.ph-21 {
  padding-left: 21px;
  padding-right: 21px; }

.padding-vertical-21,
.pv-21 {
  padding-top: 21px;
  padding-bottom: 21px; }

.padding-22,
.p-22 {
  padding: 22px !important; }

.padding-top-22,
.pt-22 {
  padding-top: 22px !important; }

.padding-right-22,
.pr-22 {
  padding-right: 22px !important; }

.padding-bottom-22,
.pb-22 {
  padding-bottom: 22px !important; }

.padding-left-22,
.pl-22 {
  padding-left: 22px !important; }

.padding-horizontal-22,
.ph-22 {
  padding-left: 22px;
  padding-right: 22px; }

.padding-vertical-22,
.pv-22 {
  padding-top: 22px;
  padding-bottom: 22px; }

.padding-23,
.p-23 {
  padding: 23px !important; }

.padding-top-23,
.pt-23 {
  padding-top: 23px !important; }

.padding-right-23,
.pr-23 {
  padding-right: 23px !important; }

.padding-bottom-23,
.pb-23 {
  padding-bottom: 23px !important; }

.padding-left-23,
.pl-23 {
  padding-left: 23px !important; }

.padding-horizontal-23,
.ph-23 {
  padding-left: 23px;
  padding-right: 23px; }

.padding-vertical-23,
.pv-23 {
  padding-top: 23px;
  padding-bottom: 23px; }

.padding-24,
.p-24 {
  padding: 24px !important; }

.padding-top-24,
.pt-24 {
  padding-top: 24px !important; }

.padding-right-24,
.pr-24 {
  padding-right: 24px !important; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px !important; }

.padding-left-24,
.pl-24 {
  padding-left: 24px !important; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-25,
.p-25 {
  padding: 25px !important; }

.padding-top-25,
.pt-25 {
  padding-top: 25px !important; }

.padding-right-25,
.pr-25 {
  padding-right: 25px !important; }

.padding-bottom-25,
.pb-25 {
  padding-bottom: 25px !important; }

.padding-left-25,
.pl-25 {
  padding-left: 25px !important; }

.padding-horizontal-25,
.ph-25 {
  padding-left: 25px;
  padding-right: 25px; }

.padding-vertical-25,
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.padding-26,
.p-26 {
  padding: 26px !important; }

.padding-top-26,
.pt-26 {
  padding-top: 26px !important; }

.padding-right-26,
.pr-26 {
  padding-right: 26px !important; }

.padding-bottom-26,
.pb-26 {
  padding-bottom: 26px !important; }

.padding-left-26,
.pl-26 {
  padding-left: 26px !important; }

.padding-horizontal-26,
.ph-26 {
  padding-left: 26px;
  padding-right: 26px; }

.padding-vertical-26,
.pv-26 {
  padding-top: 26px;
  padding-bottom: 26px; }

.padding-27,
.p-27 {
  padding: 27px !important; }

.padding-top-27,
.pt-27 {
  padding-top: 27px !important; }

.padding-right-27,
.pr-27 {
  padding-right: 27px !important; }

.padding-bottom-27,
.pb-27 {
  padding-bottom: 27px !important; }

.padding-left-27,
.pl-27 {
  padding-left: 27px !important; }

.padding-horizontal-27,
.ph-27 {
  padding-left: 27px;
  padding-right: 27px; }

.padding-vertical-27,
.pv-27 {
  padding-top: 27px;
  padding-bottom: 27px; }

.padding-28,
.p-28 {
  padding: 28px !important; }

.padding-top-28,
.pt-28 {
  padding-top: 28px !important; }

.padding-right-28,
.pr-28 {
  padding-right: 28px !important; }

.padding-bottom-28,
.pb-28 {
  padding-bottom: 28px !important; }

.padding-left-28,
.pl-28 {
  padding-left: 28px !important; }

.padding-horizontal-28,
.ph-28 {
  padding-left: 28px;
  padding-right: 28px; }

.padding-vertical-28,
.pv-28 {
  padding-top: 28px;
  padding-bottom: 28px; }

.padding-29,
.p-29 {
  padding: 29px !important; }

.padding-top-29,
.pt-29 {
  padding-top: 29px !important; }

.padding-right-29,
.pr-29 {
  padding-right: 29px !important; }

.padding-bottom-29,
.pb-29 {
  padding-bottom: 29px !important; }

.padding-left-29,
.pl-29 {
  padding-left: 29px !important; }

.padding-horizontal-29,
.ph-29 {
  padding-left: 29px;
  padding-right: 29px; }

.padding-vertical-29,
.pv-29 {
  padding-top: 29px;
  padding-bottom: 29px; }

.padding-30,
.p-30 {
  padding: 30px !important; }

.padding-top-30,
.pt-30 {
  padding-top: 30px !important; }

.padding-right-30,
.pr-30 {
  padding-right: 30px !important; }

.padding-bottom-30,
.pb-30 {
  padding-bottom: 30px !important; }

.padding-left-30,
.pl-30 {
  padding-left: 30px !important; }

.padding-horizontal-30,
.ph-30 {
  padding-left: 30px;
  padding-right: 30px; }

.padding-vertical-30,
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.padding-31,
.p-31 {
  padding: 31px !important; }

.padding-top-31,
.pt-31 {
  padding-top: 31px !important; }

.padding-right-31,
.pr-31 {
  padding-right: 31px !important; }

.padding-bottom-31,
.pb-31 {
  padding-bottom: 31px !important; }

.padding-left-31,
.pl-31 {
  padding-left: 31px !important; }

.padding-horizontal-31,
.ph-31 {
  padding-left: 31px;
  padding-right: 31px; }

.padding-vertical-31,
.pv-31 {
  padding-top: 31px;
  padding-bottom: 31px; }

.padding-32,
.p-32 {
  padding: 32px !important; }

.padding-top-32,
.pt-32 {
  padding-top: 32px !important; }

.padding-right-32,
.pr-32 {
  padding-right: 32px !important; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px !important; }

.padding-left-32,
.pl-32 {
  padding-left: 32px !important; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-33,
.p-33 {
  padding: 33px !important; }

.padding-top-33,
.pt-33 {
  padding-top: 33px !important; }

.padding-right-33,
.pr-33 {
  padding-right: 33px !important; }

.padding-bottom-33,
.pb-33 {
  padding-bottom: 33px !important; }

.padding-left-33,
.pl-33 {
  padding-left: 33px !important; }

.padding-horizontal-33,
.ph-33 {
  padding-left: 33px;
  padding-right: 33px; }

.padding-vertical-33,
.pv-33 {
  padding-top: 33px;
  padding-bottom: 33px; }

.padding-34,
.p-34 {
  padding: 34px !important; }

.padding-top-34,
.pt-34 {
  padding-top: 34px !important; }

.padding-right-34,
.pr-34 {
  padding-right: 34px !important; }

.padding-bottom-34,
.pb-34 {
  padding-bottom: 34px !important; }

.padding-left-34,
.pl-34 {
  padding-left: 34px !important; }

.padding-horizontal-34,
.ph-34 {
  padding-left: 34px;
  padding-right: 34px; }

.padding-vertical-34,
.pv-34 {
  padding-top: 34px;
  padding-bottom: 34px; }

.padding-35,
.p-35 {
  padding: 35px !important; }

.padding-top-35,
.pt-35 {
  padding-top: 35px !important; }

.padding-right-35,
.pr-35 {
  padding-right: 35px !important; }

.padding-bottom-35,
.pb-35 {
  padding-bottom: 35px !important; }

.padding-left-35,
.pl-35 {
  padding-left: 35px !important; }

.padding-horizontal-35,
.ph-35 {
  padding-left: 35px;
  padding-right: 35px; }

.padding-vertical-35,
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.padding-36,
.p-36 {
  padding: 36px !important; }

.padding-top-36,
.pt-36 {
  padding-top: 36px !important; }

.padding-right-36,
.pr-36 {
  padding-right: 36px !important; }

.padding-bottom-36,
.pb-36 {
  padding-bottom: 36px !important; }

.padding-left-36,
.pl-36 {
  padding-left: 36px !important; }

.padding-horizontal-36,
.ph-36 {
  padding-left: 36px;
  padding-right: 36px; }

.padding-vertical-36,
.pv-36 {
  padding-top: 36px;
  padding-bottom: 36px; }

.padding-37,
.p-37 {
  padding: 37px !important; }

.padding-top-37,
.pt-37 {
  padding-top: 37px !important; }

.padding-right-37,
.pr-37 {
  padding-right: 37px !important; }

.padding-bottom-37,
.pb-37 {
  padding-bottom: 37px !important; }

.padding-left-37,
.pl-37 {
  padding-left: 37px !important; }

.padding-horizontal-37,
.ph-37 {
  padding-left: 37px;
  padding-right: 37px; }

.padding-vertical-37,
.pv-37 {
  padding-top: 37px;
  padding-bottom: 37px; }

.padding-38,
.p-38 {
  padding: 38px !important; }

.padding-top-38,
.pt-38 {
  padding-top: 38px !important; }

.padding-right-38,
.pr-38 {
  padding-right: 38px !important; }

.padding-bottom-38,
.pb-38 {
  padding-bottom: 38px !important; }

.padding-left-38,
.pl-38 {
  padding-left: 38px !important; }

.padding-horizontal-38,
.ph-38 {
  padding-left: 38px;
  padding-right: 38px; }

.padding-vertical-38,
.pv-38 {
  padding-top: 38px;
  padding-bottom: 38px; }

.padding-39,
.p-39 {
  padding: 39px !important; }

.padding-top-39,
.pt-39 {
  padding-top: 39px !important; }

.padding-right-39,
.pr-39 {
  padding-right: 39px !important; }

.padding-bottom-39,
.pb-39 {
  padding-bottom: 39px !important; }

.padding-left-39,
.pl-39 {
  padding-left: 39px !important; }

.padding-horizontal-39,
.ph-39 {
  padding-left: 39px;
  padding-right: 39px; }

.padding-vertical-39,
.pv-39 {
  padding-top: 39px;
  padding-bottom: 39px; }

.padding-40,
.p-40 {
  padding: 40px !important; }

.padding-top-40,
.pt-40 {
  padding-top: 40px !important; }

.padding-right-40,
.pr-40 {
  padding-right: 40px !important; }

.padding-bottom-40,
.pb-40 {
  padding-bottom: 40px !important; }

.padding-left-40,
.pl-40 {
  padding-left: 40px !important; }

.padding-horizontal-40,
.ph-40 {
  padding-left: 40px;
  padding-right: 40px; }

.padding-vertical-40,
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.padding-41,
.p-41 {
  padding: 41px !important; }

.padding-top-41,
.pt-41 {
  padding-top: 41px !important; }

.padding-right-41,
.pr-41 {
  padding-right: 41px !important; }

.padding-bottom-41,
.pb-41 {
  padding-bottom: 41px !important; }

.padding-left-41,
.pl-41 {
  padding-left: 41px !important; }

.padding-horizontal-41,
.ph-41 {
  padding-left: 41px;
  padding-right: 41px; }

.padding-vertical-41,
.pv-41 {
  padding-top: 41px;
  padding-bottom: 41px; }

.padding-42,
.p-42 {
  padding: 42px !important; }

.padding-top-42,
.pt-42 {
  padding-top: 42px !important; }

.padding-right-42,
.pr-42 {
  padding-right: 42px !important; }

.padding-bottom-42,
.pb-42 {
  padding-bottom: 42px !important; }

.padding-left-42,
.pl-42 {
  padding-left: 42px !important; }

.padding-horizontal-42,
.ph-42 {
  padding-left: 42px;
  padding-right: 42px; }

.padding-vertical-42,
.pv-42 {
  padding-top: 42px;
  padding-bottom: 42px; }

.padding-43,
.p-43 {
  padding: 43px !important; }

.padding-top-43,
.pt-43 {
  padding-top: 43px !important; }

.padding-right-43,
.pr-43 {
  padding-right: 43px !important; }

.padding-bottom-43,
.pb-43 {
  padding-bottom: 43px !important; }

.padding-left-43,
.pl-43 {
  padding-left: 43px !important; }

.padding-horizontal-43,
.ph-43 {
  padding-left: 43px;
  padding-right: 43px; }

.padding-vertical-43,
.pv-43 {
  padding-top: 43px;
  padding-bottom: 43px; }

.padding-44,
.p-44 {
  padding: 44px !important; }

.padding-top-44,
.pt-44 {
  padding-top: 44px !important; }

.padding-right-44,
.pr-44 {
  padding-right: 44px !important; }

.padding-bottom-44,
.pb-44 {
  padding-bottom: 44px !important; }

.padding-left-44,
.pl-44 {
  padding-left: 44px !important; }

.padding-horizontal-44,
.ph-44 {
  padding-left: 44px;
  padding-right: 44px; }

.padding-vertical-44,
.pv-44 {
  padding-top: 44px;
  padding-bottom: 44px; }

.padding-45,
.p-45 {
  padding: 45px !important; }

.padding-top-45,
.pt-45 {
  padding-top: 45px !important; }

.padding-right-45,
.pr-45 {
  padding-right: 45px !important; }

.padding-bottom-45,
.pb-45 {
  padding-bottom: 45px !important; }

.padding-left-45,
.pl-45 {
  padding-left: 45px !important; }

.padding-horizontal-45,
.ph-45 {
  padding-left: 45px;
  padding-right: 45px; }

.padding-vertical-45,
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.padding-46,
.p-46 {
  padding: 46px !important; }

.padding-top-46,
.pt-46 {
  padding-top: 46px !important; }

.padding-right-46,
.pr-46 {
  padding-right: 46px !important; }

.padding-bottom-46,
.pb-46 {
  padding-bottom: 46px !important; }

.padding-left-46,
.pl-46 {
  padding-left: 46px !important; }

.padding-horizontal-46,
.ph-46 {
  padding-left: 46px;
  padding-right: 46px; }

.padding-vertical-46,
.pv-46 {
  padding-top: 46px;
  padding-bottom: 46px; }

.padding-47,
.p-47 {
  padding: 47px !important; }

.padding-top-47,
.pt-47 {
  padding-top: 47px !important; }

.padding-right-47,
.pr-47 {
  padding-right: 47px !important; }

.padding-bottom-47,
.pb-47 {
  padding-bottom: 47px !important; }

.padding-left-47,
.pl-47 {
  padding-left: 47px !important; }

.padding-horizontal-47,
.ph-47 {
  padding-left: 47px;
  padding-right: 47px; }

.padding-vertical-47,
.pv-47 {
  padding-top: 47px;
  padding-bottom: 47px; }

.padding-48,
.p-48 {
  padding: 48px !important; }

.padding-top-48,
.pt-48 {
  padding-top: 48px !important; }

.padding-right-48,
.pr-48 {
  padding-right: 48px !important; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px !important; }

.padding-left-48,
.pl-48 {
  padding-left: 48px !important; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-49,
.p-49 {
  padding: 49px !important; }

.padding-top-49,
.pt-49 {
  padding-top: 49px !important; }

.padding-right-49,
.pr-49 {
  padding-right: 49px !important; }

.padding-bottom-49,
.pb-49 {
  padding-bottom: 49px !important; }

.padding-left-49,
.pl-49 {
  padding-left: 49px !important; }

.padding-horizontal-49,
.ph-49 {
  padding-left: 49px;
  padding-right: 49px; }

.padding-vertical-49,
.pv-49 {
  padding-top: 49px;
  padding-bottom: 49px; }

.padding-50,
.p-50 {
  padding: 50px !important; }

.padding-top-50,
.pt-50 {
  padding-top: 50px !important; }

.padding-right-50,
.pr-50 {
  padding-right: 50px !important; }

.padding-bottom-50,
.pb-50 {
  padding-bottom: 50px !important; }

.padding-left-50,
.pl-50 {
  padding-left: 50px !important; }

.padding-horizontal-50,
.ph-50 {
  padding-left: 50px;
  padding-right: 50px; }

.padding-vertical-50,
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.padding-51,
.p-51 {
  padding: 51px !important; }

.padding-top-51,
.pt-51 {
  padding-top: 51px !important; }

.padding-right-51,
.pr-51 {
  padding-right: 51px !important; }

.padding-bottom-51,
.pb-51 {
  padding-bottom: 51px !important; }

.padding-left-51,
.pl-51 {
  padding-left: 51px !important; }

.padding-horizontal-51,
.ph-51 {
  padding-left: 51px;
  padding-right: 51px; }

.padding-vertical-51,
.pv-51 {
  padding-top: 51px;
  padding-bottom: 51px; }

.padding-52,
.p-52 {
  padding: 52px !important; }

.padding-top-52,
.pt-52 {
  padding-top: 52px !important; }

.padding-right-52,
.pr-52 {
  padding-right: 52px !important; }

.padding-bottom-52,
.pb-52 {
  padding-bottom: 52px !important; }

.padding-left-52,
.pl-52 {
  padding-left: 52px !important; }

.padding-horizontal-52,
.ph-52 {
  padding-left: 52px;
  padding-right: 52px; }

.padding-vertical-52,
.pv-52 {
  padding-top: 52px;
  padding-bottom: 52px; }

.padding-53,
.p-53 {
  padding: 53px !important; }

.padding-top-53,
.pt-53 {
  padding-top: 53px !important; }

.padding-right-53,
.pr-53 {
  padding-right: 53px !important; }

.padding-bottom-53,
.pb-53 {
  padding-bottom: 53px !important; }

.padding-left-53,
.pl-53 {
  padding-left: 53px !important; }

.padding-horizontal-53,
.ph-53 {
  padding-left: 53px;
  padding-right: 53px; }

.padding-vertical-53,
.pv-53 {
  padding-top: 53px;
  padding-bottom: 53px; }

.padding-54,
.p-54 {
  padding: 54px !important; }

.padding-top-54,
.pt-54 {
  padding-top: 54px !important; }

.padding-right-54,
.pr-54 {
  padding-right: 54px !important; }

.padding-bottom-54,
.pb-54 {
  padding-bottom: 54px !important; }

.padding-left-54,
.pl-54 {
  padding-left: 54px !important; }

.padding-horizontal-54,
.ph-54 {
  padding-left: 54px;
  padding-right: 54px; }

.padding-vertical-54,
.pv-54 {
  padding-top: 54px;
  padding-bottom: 54px; }

.padding-55,
.p-55 {
  padding: 55px !important; }

.padding-top-55,
.pt-55 {
  padding-top: 55px !important; }

.padding-right-55,
.pr-55 {
  padding-right: 55px !important; }

.padding-bottom-55,
.pb-55 {
  padding-bottom: 55px !important; }

.padding-left-55,
.pl-55 {
  padding-left: 55px !important; }

.padding-horizontal-55,
.ph-55 {
  padding-left: 55px;
  padding-right: 55px; }

.padding-vertical-55,
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.padding-56,
.p-56 {
  padding: 56px !important; }

.padding-top-56,
.pt-56 {
  padding-top: 56px !important; }

.padding-right-56,
.pr-56 {
  padding-right: 56px !important; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px !important; }

.padding-left-56,
.pl-56 {
  padding-left: 56px !important; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-57,
.p-57 {
  padding: 57px !important; }

.padding-top-57,
.pt-57 {
  padding-top: 57px !important; }

.padding-right-57,
.pr-57 {
  padding-right: 57px !important; }

.padding-bottom-57,
.pb-57 {
  padding-bottom: 57px !important; }

.padding-left-57,
.pl-57 {
  padding-left: 57px !important; }

.padding-horizontal-57,
.ph-57 {
  padding-left: 57px;
  padding-right: 57px; }

.padding-vertical-57,
.pv-57 {
  padding-top: 57px;
  padding-bottom: 57px; }

.padding-58,
.p-58 {
  padding: 58px !important; }

.padding-top-58,
.pt-58 {
  padding-top: 58px !important; }

.padding-right-58,
.pr-58 {
  padding-right: 58px !important; }

.padding-bottom-58,
.pb-58 {
  padding-bottom: 58px !important; }

.padding-left-58,
.pl-58 {
  padding-left: 58px !important; }

.padding-horizontal-58,
.ph-58 {
  padding-left: 58px;
  padding-right: 58px; }

.padding-vertical-58,
.pv-58 {
  padding-top: 58px;
  padding-bottom: 58px; }

.padding-59,
.p-59 {
  padding: 59px !important; }

.padding-top-59,
.pt-59 {
  padding-top: 59px !important; }

.padding-right-59,
.pr-59 {
  padding-right: 59px !important; }

.padding-bottom-59,
.pb-59 {
  padding-bottom: 59px !important; }

.padding-left-59,
.pl-59 {
  padding-left: 59px !important; }

.padding-horizontal-59,
.ph-59 {
  padding-left: 59px;
  padding-right: 59px; }

.padding-vertical-59,
.pv-59 {
  padding-top: 59px;
  padding-bottom: 59px; }

.padding-60,
.p-60 {
  padding: 60px !important; }

.padding-top-60,
.pt-60 {
  padding-top: 60px !important; }

.padding-right-60,
.pr-60 {
  padding-right: 60px !important; }

.padding-bottom-60,
.pb-60 {
  padding-bottom: 60px !important; }

.padding-left-60,
.pl-60 {
  padding-left: 60px !important; }

.padding-horizontal-60,
.ph-60 {
  padding-left: 60px;
  padding-right: 60px; }

.padding-vertical-60,
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding-61,
.p-61 {
  padding: 61px !important; }

.padding-top-61,
.pt-61 {
  padding-top: 61px !important; }

.padding-right-61,
.pr-61 {
  padding-right: 61px !important; }

.padding-bottom-61,
.pb-61 {
  padding-bottom: 61px !important; }

.padding-left-61,
.pl-61 {
  padding-left: 61px !important; }

.padding-horizontal-61,
.ph-61 {
  padding-left: 61px;
  padding-right: 61px; }

.padding-vertical-61,
.pv-61 {
  padding-top: 61px;
  padding-bottom: 61px; }

.padding-62,
.p-62 {
  padding: 62px !important; }

.padding-top-62,
.pt-62 {
  padding-top: 62px !important; }

.padding-right-62,
.pr-62 {
  padding-right: 62px !important; }

.padding-bottom-62,
.pb-62 {
  padding-bottom: 62px !important; }

.padding-left-62,
.pl-62 {
  padding-left: 62px !important; }

.padding-horizontal-62,
.ph-62 {
  padding-left: 62px;
  padding-right: 62px; }

.padding-vertical-62,
.pv-62 {
  padding-top: 62px;
  padding-bottom: 62px; }

.padding-63,
.p-63 {
  padding: 63px !important; }

.padding-top-63,
.pt-63 {
  padding-top: 63px !important; }

.padding-right-63,
.pr-63 {
  padding-right: 63px !important; }

.padding-bottom-63,
.pb-63 {
  padding-bottom: 63px !important; }

.padding-left-63,
.pl-63 {
  padding-left: 63px !important; }

.padding-horizontal-63,
.ph-63 {
  padding-left: 63px;
  padding-right: 63px; }

.padding-vertical-63,
.pv-63 {
  padding-top: 63px;
  padding-bottom: 63px; }

.padding-64,
.p-64 {
  padding: 64px !important; }

.padding-top-64,
.pt-64 {
  padding-top: 64px !important; }

.padding-right-64,
.pr-64 {
  padding-right: 64px !important; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px !important; }

.padding-left-64,
.pl-64 {
  padding-left: 64px !important; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-65,
.p-65 {
  padding: 65px !important; }

.padding-top-65,
.pt-65 {
  padding-top: 65px !important; }

.padding-right-65,
.pr-65 {
  padding-right: 65px !important; }

.padding-bottom-65,
.pb-65 {
  padding-bottom: 65px !important; }

.padding-left-65,
.pl-65 {
  padding-left: 65px !important; }

.padding-horizontal-65,
.ph-65 {
  padding-left: 65px;
  padding-right: 65px; }

.padding-vertical-65,
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.padding-66,
.p-66 {
  padding: 66px !important; }

.padding-top-66,
.pt-66 {
  padding-top: 66px !important; }

.padding-right-66,
.pr-66 {
  padding-right: 66px !important; }

.padding-bottom-66,
.pb-66 {
  padding-bottom: 66px !important; }

.padding-left-66,
.pl-66 {
  padding-left: 66px !important; }

.padding-horizontal-66,
.ph-66 {
  padding-left: 66px;
  padding-right: 66px; }

.padding-vertical-66,
.pv-66 {
  padding-top: 66px;
  padding-bottom: 66px; }

.padding-67,
.p-67 {
  padding: 67px !important; }

.padding-top-67,
.pt-67 {
  padding-top: 67px !important; }

.padding-right-67,
.pr-67 {
  padding-right: 67px !important; }

.padding-bottom-67,
.pb-67 {
  padding-bottom: 67px !important; }

.padding-left-67,
.pl-67 {
  padding-left: 67px !important; }

.padding-horizontal-67,
.ph-67 {
  padding-left: 67px;
  padding-right: 67px; }

.padding-vertical-67,
.pv-67 {
  padding-top: 67px;
  padding-bottom: 67px; }

.padding-68,
.p-68 {
  padding: 68px !important; }

.padding-top-68,
.pt-68 {
  padding-top: 68px !important; }

.padding-right-68,
.pr-68 {
  padding-right: 68px !important; }

.padding-bottom-68,
.pb-68 {
  padding-bottom: 68px !important; }

.padding-left-68,
.pl-68 {
  padding-left: 68px !important; }

.padding-horizontal-68,
.ph-68 {
  padding-left: 68px;
  padding-right: 68px; }

.padding-vertical-68,
.pv-68 {
  padding-top: 68px;
  padding-bottom: 68px; }

.padding-69,
.p-69 {
  padding: 69px !important; }

.padding-top-69,
.pt-69 {
  padding-top: 69px !important; }

.padding-right-69,
.pr-69 {
  padding-right: 69px !important; }

.padding-bottom-69,
.pb-69 {
  padding-bottom: 69px !important; }

.padding-left-69,
.pl-69 {
  padding-left: 69px !important; }

.padding-horizontal-69,
.ph-69 {
  padding-left: 69px;
  padding-right: 69px; }

.padding-vertical-69,
.pv-69 {
  padding-top: 69px;
  padding-bottom: 69px; }

.padding-70,
.p-70 {
  padding: 70px !important; }

.padding-top-70,
.pt-70 {
  padding-top: 70px !important; }

.padding-right-70,
.pr-70 {
  padding-right: 70px !important; }

.padding-bottom-70,
.pb-70 {
  padding-bottom: 70px !important; }

.padding-left-70,
.pl-70 {
  padding-left: 70px !important; }

.padding-horizontal-70,
.ph-70 {
  padding-left: 70px;
  padding-right: 70px; }

.padding-vertical-70,
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.padding-71,
.p-71 {
  padding: 71px !important; }

.padding-top-71,
.pt-71 {
  padding-top: 71px !important; }

.padding-right-71,
.pr-71 {
  padding-right: 71px !important; }

.padding-bottom-71,
.pb-71 {
  padding-bottom: 71px !important; }

.padding-left-71,
.pl-71 {
  padding-left: 71px !important; }

.padding-horizontal-71,
.ph-71 {
  padding-left: 71px;
  padding-right: 71px; }

.padding-vertical-71,
.pv-71 {
  padding-top: 71px;
  padding-bottom: 71px; }

.padding-72,
.p-72 {
  padding: 72px !important; }

.padding-top-72,
.pt-72 {
  padding-top: 72px !important; }

.padding-right-72,
.pr-72 {
  padding-right: 72px !important; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px !important; }

.padding-left-72,
.pl-72 {
  padding-left: 72px !important; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-73,
.p-73 {
  padding: 73px !important; }

.padding-top-73,
.pt-73 {
  padding-top: 73px !important; }

.padding-right-73,
.pr-73 {
  padding-right: 73px !important; }

.padding-bottom-73,
.pb-73 {
  padding-bottom: 73px !important; }

.padding-left-73,
.pl-73 {
  padding-left: 73px !important; }

.padding-horizontal-73,
.ph-73 {
  padding-left: 73px;
  padding-right: 73px; }

.padding-vertical-73,
.pv-73 {
  padding-top: 73px;
  padding-bottom: 73px; }

.padding-74,
.p-74 {
  padding: 74px !important; }

.padding-top-74,
.pt-74 {
  padding-top: 74px !important; }

.padding-right-74,
.pr-74 {
  padding-right: 74px !important; }

.padding-bottom-74,
.pb-74 {
  padding-bottom: 74px !important; }

.padding-left-74,
.pl-74 {
  padding-left: 74px !important; }

.padding-horizontal-74,
.ph-74 {
  padding-left: 74px;
  padding-right: 74px; }

.padding-vertical-74,
.pv-74 {
  padding-top: 74px;
  padding-bottom: 74px; }

.padding-75,
.p-75 {
  padding: 75px !important; }

.padding-top-75,
.pt-75 {
  padding-top: 75px !important; }

.padding-right-75,
.pr-75 {
  padding-right: 75px !important; }

.padding-bottom-75,
.pb-75 {
  padding-bottom: 75px !important; }

.padding-left-75,
.pl-75 {
  padding-left: 75px !important; }

.padding-horizontal-75,
.ph-75 {
  padding-left: 75px;
  padding-right: 75px; }

.padding-vertical-75,
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.padding-76,
.p-76 {
  padding: 76px !important; }

.padding-top-76,
.pt-76 {
  padding-top: 76px !important; }

.padding-right-76,
.pr-76 {
  padding-right: 76px !important; }

.padding-bottom-76,
.pb-76 {
  padding-bottom: 76px !important; }

.padding-left-76,
.pl-76 {
  padding-left: 76px !important; }

.padding-horizontal-76,
.ph-76 {
  padding-left: 76px;
  padding-right: 76px; }

.padding-vertical-76,
.pv-76 {
  padding-top: 76px;
  padding-bottom: 76px; }

.padding-77,
.p-77 {
  padding: 77px !important; }

.padding-top-77,
.pt-77 {
  padding-top: 77px !important; }

.padding-right-77,
.pr-77 {
  padding-right: 77px !important; }

.padding-bottom-77,
.pb-77 {
  padding-bottom: 77px !important; }

.padding-left-77,
.pl-77 {
  padding-left: 77px !important; }

.padding-horizontal-77,
.ph-77 {
  padding-left: 77px;
  padding-right: 77px; }

.padding-vertical-77,
.pv-77 {
  padding-top: 77px;
  padding-bottom: 77px; }

.padding-78,
.p-78 {
  padding: 78px !important; }

.padding-top-78,
.pt-78 {
  padding-top: 78px !important; }

.padding-right-78,
.pr-78 {
  padding-right: 78px !important; }

.padding-bottom-78,
.pb-78 {
  padding-bottom: 78px !important; }

.padding-left-78,
.pl-78 {
  padding-left: 78px !important; }

.padding-horizontal-78,
.ph-78 {
  padding-left: 78px;
  padding-right: 78px; }

.padding-vertical-78,
.pv-78 {
  padding-top: 78px;
  padding-bottom: 78px; }

.padding-79,
.p-79 {
  padding: 79px !important; }

.padding-top-79,
.pt-79 {
  padding-top: 79px !important; }

.padding-right-79,
.pr-79 {
  padding-right: 79px !important; }

.padding-bottom-79,
.pb-79 {
  padding-bottom: 79px !important; }

.padding-left-79,
.pl-79 {
  padding-left: 79px !important; }

.padding-horizontal-79,
.ph-79 {
  padding-left: 79px;
  padding-right: 79px; }

.padding-vertical-79,
.pv-79 {
  padding-top: 79px;
  padding-bottom: 79px; }

.padding-80,
.p-80 {
  padding: 80px !important; }

.padding-top-80,
.pt-80 {
  padding-top: 80px !important; }

.padding-right-80,
.pr-80 {
  padding-right: 80px !important; }

.padding-bottom-80,
.pb-80 {
  padding-bottom: 80px !important; }

.padding-left-80,
.pl-80 {
  padding-left: 80px !important; }

.padding-horizontal-80,
.ph-80 {
  padding-left: 80px;
  padding-right: 80px; }

.padding-vertical-80,
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding-81,
.p-81 {
  padding: 81px !important; }

.padding-top-81,
.pt-81 {
  padding-top: 81px !important; }

.padding-right-81,
.pr-81 {
  padding-right: 81px !important; }

.padding-bottom-81,
.pb-81 {
  padding-bottom: 81px !important; }

.padding-left-81,
.pl-81 {
  padding-left: 81px !important; }

.padding-horizontal-81,
.ph-81 {
  padding-left: 81px;
  padding-right: 81px; }

.padding-vertical-81,
.pv-81 {
  padding-top: 81px;
  padding-bottom: 81px; }

.padding-82,
.p-82 {
  padding: 82px !important; }

.padding-top-82,
.pt-82 {
  padding-top: 82px !important; }

.padding-right-82,
.pr-82 {
  padding-right: 82px !important; }

.padding-bottom-82,
.pb-82 {
  padding-bottom: 82px !important; }

.padding-left-82,
.pl-82 {
  padding-left: 82px !important; }

.padding-horizontal-82,
.ph-82 {
  padding-left: 82px;
  padding-right: 82px; }

.padding-vertical-82,
.pv-82 {
  padding-top: 82px;
  padding-bottom: 82px; }

.padding-83,
.p-83 {
  padding: 83px !important; }

.padding-top-83,
.pt-83 {
  padding-top: 83px !important; }

.padding-right-83,
.pr-83 {
  padding-right: 83px !important; }

.padding-bottom-83,
.pb-83 {
  padding-bottom: 83px !important; }

.padding-left-83,
.pl-83 {
  padding-left: 83px !important; }

.padding-horizontal-83,
.ph-83 {
  padding-left: 83px;
  padding-right: 83px; }

.padding-vertical-83,
.pv-83 {
  padding-top: 83px;
  padding-bottom: 83px; }

.padding-84,
.p-84 {
  padding: 84px !important; }

.padding-top-84,
.pt-84 {
  padding-top: 84px !important; }

.padding-right-84,
.pr-84 {
  padding-right: 84px !important; }

.padding-bottom-84,
.pb-84 {
  padding-bottom: 84px !important; }

.padding-left-84,
.pl-84 {
  padding-left: 84px !important; }

.padding-horizontal-84,
.ph-84 {
  padding-left: 84px;
  padding-right: 84px; }

.padding-vertical-84,
.pv-84 {
  padding-top: 84px;
  padding-bottom: 84px; }

.padding-85,
.p-85 {
  padding: 85px !important; }

.padding-top-85,
.pt-85 {
  padding-top: 85px !important; }

.padding-right-85,
.pr-85 {
  padding-right: 85px !important; }

.padding-bottom-85,
.pb-85 {
  padding-bottom: 85px !important; }

.padding-left-85,
.pl-85 {
  padding-left: 85px !important; }

.padding-horizontal-85,
.ph-85 {
  padding-left: 85px;
  padding-right: 85px; }

.padding-vertical-85,
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.padding-86,
.p-86 {
  padding: 86px !important; }

.padding-top-86,
.pt-86 {
  padding-top: 86px !important; }

.padding-right-86,
.pr-86 {
  padding-right: 86px !important; }

.padding-bottom-86,
.pb-86 {
  padding-bottom: 86px !important; }

.padding-left-86,
.pl-86 {
  padding-left: 86px !important; }

.padding-horizontal-86,
.ph-86 {
  padding-left: 86px;
  padding-right: 86px; }

.padding-vertical-86,
.pv-86 {
  padding-top: 86px;
  padding-bottom: 86px; }

.padding-87,
.p-87 {
  padding: 87px !important; }

.padding-top-87,
.pt-87 {
  padding-top: 87px !important; }

.padding-right-87,
.pr-87 {
  padding-right: 87px !important; }

.padding-bottom-87,
.pb-87 {
  padding-bottom: 87px !important; }

.padding-left-87,
.pl-87 {
  padding-left: 87px !important; }

.padding-horizontal-87,
.ph-87 {
  padding-left: 87px;
  padding-right: 87px; }

.padding-vertical-87,
.pv-87 {
  padding-top: 87px;
  padding-bottom: 87px; }

.padding-88,
.p-88 {
  padding: 88px !important; }

.padding-top-88,
.pt-88 {
  padding-top: 88px !important; }

.padding-right-88,
.pr-88 {
  padding-right: 88px !important; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px !important; }

.padding-left-88,
.pl-88 {
  padding-left: 88px !important; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-89,
.p-89 {
  padding: 89px !important; }

.padding-top-89,
.pt-89 {
  padding-top: 89px !important; }

.padding-right-89,
.pr-89 {
  padding-right: 89px !important; }

.padding-bottom-89,
.pb-89 {
  padding-bottom: 89px !important; }

.padding-left-89,
.pl-89 {
  padding-left: 89px !important; }

.padding-horizontal-89,
.ph-89 {
  padding-left: 89px;
  padding-right: 89px; }

.padding-vertical-89,
.pv-89 {
  padding-top: 89px;
  padding-bottom: 89px; }

.padding-90,
.p-90 {
  padding: 90px !important; }

.padding-top-90,
.pt-90 {
  padding-top: 90px !important; }

.padding-right-90,
.pr-90 {
  padding-right: 90px !important; }

.padding-bottom-90,
.pb-90 {
  padding-bottom: 90px !important; }

.padding-left-90,
.pl-90 {
  padding-left: 90px !important; }

.padding-horizontal-90,
.ph-90 {
  padding-left: 90px;
  padding-right: 90px; }

.padding-vertical-90,
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.padding-91,
.p-91 {
  padding: 91px !important; }

.padding-top-91,
.pt-91 {
  padding-top: 91px !important; }

.padding-right-91,
.pr-91 {
  padding-right: 91px !important; }

.padding-bottom-91,
.pb-91 {
  padding-bottom: 91px !important; }

.padding-left-91,
.pl-91 {
  padding-left: 91px !important; }

.padding-horizontal-91,
.ph-91 {
  padding-left: 91px;
  padding-right: 91px; }

.padding-vertical-91,
.pv-91 {
  padding-top: 91px;
  padding-bottom: 91px; }

.padding-92,
.p-92 {
  padding: 92px !important; }

.padding-top-92,
.pt-92 {
  padding-top: 92px !important; }

.padding-right-92,
.pr-92 {
  padding-right: 92px !important; }

.padding-bottom-92,
.pb-92 {
  padding-bottom: 92px !important; }

.padding-left-92,
.pl-92 {
  padding-left: 92px !important; }

.padding-horizontal-92,
.ph-92 {
  padding-left: 92px;
  padding-right: 92px; }

.padding-vertical-92,
.pv-92 {
  padding-top: 92px;
  padding-bottom: 92px; }

.padding-93,
.p-93 {
  padding: 93px !important; }

.padding-top-93,
.pt-93 {
  padding-top: 93px !important; }

.padding-right-93,
.pr-93 {
  padding-right: 93px !important; }

.padding-bottom-93,
.pb-93 {
  padding-bottom: 93px !important; }

.padding-left-93,
.pl-93 {
  padding-left: 93px !important; }

.padding-horizontal-93,
.ph-93 {
  padding-left: 93px;
  padding-right: 93px; }

.padding-vertical-93,
.pv-93 {
  padding-top: 93px;
  padding-bottom: 93px; }

.padding-94,
.p-94 {
  padding: 94px !important; }

.padding-top-94,
.pt-94 {
  padding-top: 94px !important; }

.padding-right-94,
.pr-94 {
  padding-right: 94px !important; }

.padding-bottom-94,
.pb-94 {
  padding-bottom: 94px !important; }

.padding-left-94,
.pl-94 {
  padding-left: 94px !important; }

.padding-horizontal-94,
.ph-94 {
  padding-left: 94px;
  padding-right: 94px; }

.padding-vertical-94,
.pv-94 {
  padding-top: 94px;
  padding-bottom: 94px; }

.padding-95,
.p-95 {
  padding: 95px !important; }

.padding-top-95,
.pt-95 {
  padding-top: 95px !important; }

.padding-right-95,
.pr-95 {
  padding-right: 95px !important; }

.padding-bottom-95,
.pb-95 {
  padding-bottom: 95px !important; }

.padding-left-95,
.pl-95 {
  padding-left: 95px !important; }

.padding-horizontal-95,
.ph-95 {
  padding-left: 95px;
  padding-right: 95px; }

.padding-vertical-95,
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.padding-96,
.p-96 {
  padding: 96px !important; }

.padding-top-96,
.pt-96 {
  padding-top: 96px !important; }

.padding-right-96,
.pr-96 {
  padding-right: 96px !important; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px !important; }

.padding-left-96,
.pl-96 {
  padding-left: 96px !important; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.padding-97,
.p-97 {
  padding: 97px !important; }

.padding-top-97,
.pt-97 {
  padding-top: 97px !important; }

.padding-right-97,
.pr-97 {
  padding-right: 97px !important; }

.padding-bottom-97,
.pb-97 {
  padding-bottom: 97px !important; }

.padding-left-97,
.pl-97 {
  padding-left: 97px !important; }

.padding-horizontal-97,
.ph-97 {
  padding-left: 97px;
  padding-right: 97px; }

.padding-vertical-97,
.pv-97 {
  padding-top: 97px;
  padding-bottom: 97px; }

.padding-98,
.p-98 {
  padding: 98px !important; }

.padding-top-98,
.pt-98 {
  padding-top: 98px !important; }

.padding-right-98,
.pr-98 {
  padding-right: 98px !important; }

.padding-bottom-98,
.pb-98 {
  padding-bottom: 98px !important; }

.padding-left-98,
.pl-98 {
  padding-left: 98px !important; }

.padding-horizontal-98,
.ph-98 {
  padding-left: 98px;
  padding-right: 98px; }

.padding-vertical-98,
.pv-98 {
  padding-top: 98px;
  padding-bottom: 98px; }

.padding-99,
.p-99 {
  padding: 99px !important; }

.padding-top-99,
.pt-99 {
  padding-top: 99px !important; }

.padding-right-99,
.pr-99 {
  padding-right: 99px !important; }

.padding-bottom-99,
.pb-99 {
  padding-bottom: 99px !important; }

.padding-left-99,
.pl-99 {
  padding-left: 99px !important; }

.padding-horizontal-99,
.ph-99 {
  padding-left: 99px;
  padding-right: 99px; }

.padding-vertical-99,
.pv-99 {
  padding-top: 99px;
  padding-bottom: 99px; }

.padding-100,
.p-100 {
  padding: 100px !important; }

.padding-top-100,
.pt-100 {
  padding-top: 100px !important; }

.padding-right-100,
.pr-100 {
  padding-right: 100px !important; }

.padding-bottom-100,
.pb-100 {
  padding-bottom: 100px !important; }

.padding-left-100,
.pl-100 {
  padding-left: 100px !important; }

.padding-horizontal-100,
.ph-100 {
  padding-left: 100px;
  padding-right: 100px; }

.padding-vertical-100,
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.padding-8,
.p-8 {
  padding: 8px !important; }

.padding-top-8,
.pt-8 {
  padding-top: 8px !important; }

.padding-right-8,
.pr-8 {
  padding-right: 8px !important; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px !important; }

.padding-left-8,
.pl-8 {
  padding-left: 8px !important; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-16,
.p-16 {
  padding: 16px !important; }

.padding-top-16,
.pt-16 {
  padding-top: 16px !important; }

.padding-right-16,
.pr-16 {
  padding-right: 16px !important; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px !important; }

.padding-left-16,
.pl-16 {
  padding-left: 16px !important; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-24,
.p-24 {
  padding: 24px !important; }

.padding-top-24,
.pt-24 {
  padding-top: 24px !important; }

.padding-right-24,
.pr-24 {
  padding-right: 24px !important; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px !important; }

.padding-left-24,
.pl-24 {
  padding-left: 24px !important; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-32,
.p-32 {
  padding: 32px !important; }

.padding-top-32,
.pt-32 {
  padding-top: 32px !important; }

.padding-right-32,
.pr-32 {
  padding-right: 32px !important; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px !important; }

.padding-left-32,
.pl-32 {
  padding-left: 32px !important; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-48,
.p-48 {
  padding: 48px !important; }

.padding-top-48,
.pt-48 {
  padding-top: 48px !important; }

.padding-right-48,
.pr-48 {
  padding-right: 48px !important; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px !important; }

.padding-left-48,
.pl-48 {
  padding-left: 48px !important; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-56,
.p-56 {
  padding: 56px !important; }

.padding-top-56,
.pt-56 {
  padding-top: 56px !important; }

.padding-right-56,
.pr-56 {
  padding-right: 56px !important; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px !important; }

.padding-left-56,
.pl-56 {
  padding-left: 56px !important; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-64,
.p-64 {
  padding: 64px !important; }

.padding-top-64,
.pt-64 {
  padding-top: 64px !important; }

.padding-right-64,
.pr-64 {
  padding-right: 64px !important; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px !important; }

.padding-left-64,
.pl-64 {
  padding-left: 64px !important; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-72,
.p-72 {
  padding: 72px !important; }

.padding-top-72,
.pt-72 {
  padding-top: 72px !important; }

.padding-right-72,
.pr-72 {
  padding-right: 72px !important; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px !important; }

.padding-left-72,
.pl-72 {
  padding-left: 72px !important; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-88,
.p-88 {
  padding: 88px !important; }

.padding-top-88,
.pt-88 {
  padding-top: 88px !important; }

.padding-right-88,
.pr-88 {
  padding-right: 88px !important; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px !important; }

.padding-left-88,
.pl-88 {
  padding-left: 88px !important; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-96,
.p-96 {
  padding: 96px !important; }

.padding-top-96,
.pt-96 {
  padding-top: 96px !important; }

.padding-right-96,
.pr-96 {
  padding-right: 96px !important; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px !important; }

.padding-left-96,
.pl-96 {
  padding-left: 96px !important; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.margin-0,
.m-0 {
  margin: 0px !important; }

.margin-top-0,
.mt-0 {
  margin-top: 0px !important; }

.margin-right-0,
.mr-0 {
  margin-right: 0px !important; }

.margin-bottom-0,
.mb-0 {
  margin-bottom: 0px !important; }

.margin-left-0,
.ml-0 {
  margin-left: 0px !important; }

.margin-horizontal-0,
.mh-0 {
  margin-left: 0px;
  margin-right: 0px; }

.margin-vertical-0,
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.margin-5,
.m-5 {
  margin: 5px !important; }

.margin-top-5,
.mt-5 {
  margin-top: 5px !important; }

.margin-right-5,
.mr-5 {
  margin-right: 5px !important; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px !important; }

.margin-left-5,
.ml-5 {
  margin-left: 5px !important; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-10,
.m-10 {
  margin: 10px !important; }

.margin-top-10,
.mt-10 {
  margin-top: 10px !important; }

.margin-right-10,
.mr-10 {
  margin-right: 10px !important; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px !important; }

.margin-left-10,
.ml-10 {
  margin-left: 10px !important; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-15,
.m-15 {
  margin: 15px !important; }

.margin-top-15,
.mt-15 {
  margin-top: 15px !important; }

.margin-right-15,
.mr-15 {
  margin-right: 15px !important; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px !important; }

.margin-left-15,
.ml-15 {
  margin-left: 15px !important; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-20,
.m-20 {
  margin: 20px !important; }

.margin-top-20,
.mt-20 {
  margin-top: 20px !important; }

.margin-right-20,
.mr-20 {
  margin-right: 20px !important; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px !important; }

.margin-left-20,
.ml-20 {
  margin-left: 20px !important; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-25,
.m-25 {
  margin: 25px !important; }

.margin-top-25,
.mt-25 {
  margin-top: 25px !important; }

.margin-right-25,
.mr-25 {
  margin-right: 25px !important; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px !important; }

.margin-left-25,
.ml-25 {
  margin-left: 25px !important; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-30,
.m-30 {
  margin: 30px !important; }

.margin-top-30,
.mt-30 {
  margin-top: 30px !important; }

.margin-right-30,
.mr-30 {
  margin-right: 30px !important; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px !important; }

.margin-left-30,
.ml-30 {
  margin-left: 30px !important; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-35,
.m-35 {
  margin: 35px !important; }

.margin-top-35,
.mt-35 {
  margin-top: 35px !important; }

.margin-right-35,
.mr-35 {
  margin-right: 35px !important; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px !important; }

.margin-left-35,
.ml-35 {
  margin-left: 35px !important; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-40,
.m-40 {
  margin: 40px !important; }

.margin-top-40,
.mt-40 {
  margin-top: 40px !important; }

.margin-right-40,
.mr-40 {
  margin-right: 40px !important; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px !important; }

.margin-left-40,
.ml-40 {
  margin-left: 40px !important; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-45,
.m-45 {
  margin: 45px !important; }

.margin-top-45,
.mt-45 {
  margin-top: 45px !important; }

.margin-right-45,
.mr-45 {
  margin-right: 45px !important; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px !important; }

.margin-left-45,
.ml-45 {
  margin-left: 45px !important; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-50,
.m-50 {
  margin: 50px !important; }

.margin-top-50,
.mt-50 {
  margin-top: 50px !important; }

.margin-right-50,
.mr-50 {
  margin-right: 50px !important; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px !important; }

.margin-left-50,
.ml-50 {
  margin-left: 50px !important; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-55,
.m-55 {
  margin: 55px !important; }

.margin-top-55,
.mt-55 {
  margin-top: 55px !important; }

.margin-right-55,
.mr-55 {
  margin-right: 55px !important; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px !important; }

.margin-left-55,
.ml-55 {
  margin-left: 55px !important; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-60,
.m-60 {
  margin: 60px !important; }

.margin-top-60,
.mt-60 {
  margin-top: 60px !important; }

.margin-right-60,
.mr-60 {
  margin-right: 60px !important; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px !important; }

.margin-left-60,
.ml-60 {
  margin-left: 60px !important; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-65,
.m-65 {
  margin: 65px !important; }

.margin-top-65,
.mt-65 {
  margin-top: 65px !important; }

.margin-right-65,
.mr-65 {
  margin-right: 65px !important; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px !important; }

.margin-left-65,
.ml-65 {
  margin-left: 65px !important; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-70,
.m-70 {
  margin: 70px !important; }

.margin-top-70,
.mt-70 {
  margin-top: 70px !important; }

.margin-right-70,
.mr-70 {
  margin-right: 70px !important; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px !important; }

.margin-left-70,
.ml-70 {
  margin-left: 70px !important; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-75,
.m-75 {
  margin: 75px !important; }

.margin-top-75,
.mt-75 {
  margin-top: 75px !important; }

.margin-right-75,
.mr-75 {
  margin-right: 75px !important; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px !important; }

.margin-left-75,
.ml-75 {
  margin-left: 75px !important; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-80,
.m-80 {
  margin: 80px !important; }

.margin-top-80,
.mt-80 {
  margin-top: 80px !important; }

.margin-right-80,
.mr-80 {
  margin-right: 80px !important; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px !important; }

.margin-left-80,
.ml-80 {
  margin-left: 80px !important; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-85,
.m-85 {
  margin: 85px !important; }

.margin-top-85,
.mt-85 {
  margin-top: 85px !important; }

.margin-right-85,
.mr-85 {
  margin-right: 85px !important; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px !important; }

.margin-left-85,
.ml-85 {
  margin-left: 85px !important; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-90,
.m-90 {
  margin: 90px !important; }

.margin-top-90,
.mt-90 {
  margin-top: 90px !important; }

.margin-right-90,
.mr-90 {
  margin-right: 90px !important; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px !important; }

.margin-left-90,
.ml-90 {
  margin-left: 90px !important; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-95,
.m-95 {
  margin: 95px !important; }

.margin-top-95,
.mt-95 {
  margin-top: 95px !important; }

.margin-right-95,
.mr-95 {
  margin-right: 95px !important; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px !important; }

.margin-left-95,
.ml-95 {
  margin-left: 95px !important; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-100,
.m-100 {
  margin: 100px !important; }

.margin-top-100,
.mt-100 {
  margin-top: 100px !important; }

.margin-right-100,
.mr-100 {
  margin-right: 100px !important; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px !important; }

.margin-left-100,
.ml-100 {
  margin-left: 100px !important; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.margin-1,
.m-1 {
  margin: 1px !important; }

.margin-top-1,
.mt-1 {
  margin-top: 1px !important; }

.margin-right-1,
.mr-1 {
  margin-right: 1px !important; }

.margin-bottom-1,
.mb-1 {
  margin-bottom: 1px !important; }

.margin-left-1,
.ml-1 {
  margin-left: 1px !important; }

.margin-horizontal-1,
.mh-1 {
  margin-left: 1px;
  margin-right: 1px; }

.margin-vertical-1,
.mv-1 {
  margin-top: 1px;
  margin-bottom: 1px; }

.margin-2,
.m-2 {
  margin: 2px !important; }

.margin-top-2,
.mt-2 {
  margin-top: 2px !important; }

.margin-right-2,
.mr-2 {
  margin-right: 2px !important; }

.margin-bottom-2,
.mb-2 {
  margin-bottom: 2px !important; }

.margin-left-2,
.ml-2 {
  margin-left: 2px !important; }

.margin-horizontal-2,
.mh-2 {
  margin-left: 2px;
  margin-right: 2px; }

.margin-vertical-2,
.mv-2 {
  margin-top: 2px;
  margin-bottom: 2px; }

.margin-3,
.m-3 {
  margin: 3px !important; }

.margin-top-3,
.mt-3 {
  margin-top: 3px !important; }

.margin-right-3,
.mr-3 {
  margin-right: 3px !important; }

.margin-bottom-3,
.mb-3 {
  margin-bottom: 3px !important; }

.margin-left-3,
.ml-3 {
  margin-left: 3px !important; }

.margin-horizontal-3,
.mh-3 {
  margin-left: 3px;
  margin-right: 3px; }

.margin-vertical-3,
.mv-3 {
  margin-top: 3px;
  margin-bottom: 3px; }

.margin-4,
.m-4 {
  margin: 4px !important; }

.margin-top-4,
.mt-4 {
  margin-top: 4px !important; }

.margin-right-4,
.mr-4 {
  margin-right: 4px !important; }

.margin-bottom-4,
.mb-4 {
  margin-bottom: 4px !important; }

.margin-left-4,
.ml-4 {
  margin-left: 4px !important; }

.margin-horizontal-4,
.mh-4 {
  margin-left: 4px;
  margin-right: 4px; }

.margin-vertical-4,
.mv-4 {
  margin-top: 4px;
  margin-bottom: 4px; }

.margin-5,
.m-5 {
  margin: 5px !important; }

.margin-top-5,
.mt-5 {
  margin-top: 5px !important; }

.margin-right-5,
.mr-5 {
  margin-right: 5px !important; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px !important; }

.margin-left-5,
.ml-5 {
  margin-left: 5px !important; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-6,
.m-6 {
  margin: 6px !important; }

.margin-top-6,
.mt-6 {
  margin-top: 6px !important; }

.margin-right-6,
.mr-6 {
  margin-right: 6px !important; }

.margin-bottom-6,
.mb-6 {
  margin-bottom: 6px !important; }

.margin-left-6,
.ml-6 {
  margin-left: 6px !important; }

.margin-horizontal-6,
.mh-6 {
  margin-left: 6px;
  margin-right: 6px; }

.margin-vertical-6,
.mv-6 {
  margin-top: 6px;
  margin-bottom: 6px; }

.margin-7,
.m-7 {
  margin: 7px !important; }

.margin-top-7,
.mt-7 {
  margin-top: 7px !important; }

.margin-right-7,
.mr-7 {
  margin-right: 7px !important; }

.margin-bottom-7,
.mb-7 {
  margin-bottom: 7px !important; }

.margin-left-7,
.ml-7 {
  margin-left: 7px !important; }

.margin-horizontal-7,
.mh-7 {
  margin-left: 7px;
  margin-right: 7px; }

.margin-vertical-7,
.mv-7 {
  margin-top: 7px;
  margin-bottom: 7px; }

.margin-8,
.m-8 {
  margin: 8px !important; }

.margin-top-8,
.mt-8 {
  margin-top: 8px !important; }

.margin-right-8,
.mr-8 {
  margin-right: 8px !important; }

.margin-bottom-8,
.mb-8 {
  margin-bottom: 8px !important; }

.margin-left-8,
.ml-8 {
  margin-left: 8px !important; }

.margin-horizontal-8,
.mh-8 {
  margin-left: 8px;
  margin-right: 8px; }

.margin-vertical-8,
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px; }

.margin-9,
.m-9 {
  margin: 9px !important; }

.margin-top-9,
.mt-9 {
  margin-top: 9px !important; }

.margin-right-9,
.mr-9 {
  margin-right: 9px !important; }

.margin-bottom-9,
.mb-9 {
  margin-bottom: 9px !important; }

.margin-left-9,
.ml-9 {
  margin-left: 9px !important; }

.margin-horizontal-9,
.mh-9 {
  margin-left: 9px;
  margin-right: 9px; }

.margin-vertical-9,
.mv-9 {
  margin-top: 9px;
  margin-bottom: 9px; }

.margin-10,
.m-10 {
  margin: 10px !important; }

.margin-top-10,
.mt-10 {
  margin-top: 10px !important; }

.margin-right-10,
.mr-10 {
  margin-right: 10px !important; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px !important; }

.margin-left-10,
.ml-10 {
  margin-left: 10px !important; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-11,
.m-11 {
  margin: 11px !important; }

.margin-top-11,
.mt-11 {
  margin-top: 11px !important; }

.margin-right-11,
.mr-11 {
  margin-right: 11px !important; }

.margin-bottom-11,
.mb-11 {
  margin-bottom: 11px !important; }

.margin-left-11,
.ml-11 {
  margin-left: 11px !important; }

.margin-horizontal-11,
.mh-11 {
  margin-left: 11px;
  margin-right: 11px; }

.margin-vertical-11,
.mv-11 {
  margin-top: 11px;
  margin-bottom: 11px; }

.margin-12,
.m-12 {
  margin: 12px !important; }

.margin-top-12,
.mt-12 {
  margin-top: 12px !important; }

.margin-right-12,
.mr-12 {
  margin-right: 12px !important; }

.margin-bottom-12,
.mb-12 {
  margin-bottom: 12px !important; }

.margin-left-12,
.ml-12 {
  margin-left: 12px !important; }

.margin-horizontal-12,
.mh-12 {
  margin-left: 12px;
  margin-right: 12px; }

.margin-vertical-12,
.mv-12 {
  margin-top: 12px;
  margin-bottom: 12px; }

.margin-13,
.m-13 {
  margin: 13px !important; }

.margin-top-13,
.mt-13 {
  margin-top: 13px !important; }

.margin-right-13,
.mr-13 {
  margin-right: 13px !important; }

.margin-bottom-13,
.mb-13 {
  margin-bottom: 13px !important; }

.margin-left-13,
.ml-13 {
  margin-left: 13px !important; }

.margin-horizontal-13,
.mh-13 {
  margin-left: 13px;
  margin-right: 13px; }

.margin-vertical-13,
.mv-13 {
  margin-top: 13px;
  margin-bottom: 13px; }

.margin-14,
.m-14 {
  margin: 14px !important; }

.margin-top-14,
.mt-14 {
  margin-top: 14px !important; }

.margin-right-14,
.mr-14 {
  margin-right: 14px !important; }

.margin-bottom-14,
.mb-14 {
  margin-bottom: 14px !important; }

.margin-left-14,
.ml-14 {
  margin-left: 14px !important; }

.margin-horizontal-14,
.mh-14 {
  margin-left: 14px;
  margin-right: 14px; }

.margin-vertical-14,
.mv-14 {
  margin-top: 14px;
  margin-bottom: 14px; }

.margin-15,
.m-15 {
  margin: 15px !important; }

.margin-top-15,
.mt-15 {
  margin-top: 15px !important; }

.margin-right-15,
.mr-15 {
  margin-right: 15px !important; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px !important; }

.margin-left-15,
.ml-15 {
  margin-left: 15px !important; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-16,
.m-16 {
  margin: 16px !important; }

.margin-top-16,
.mt-16 {
  margin-top: 16px !important; }

.margin-right-16,
.mr-16 {
  margin-right: 16px !important; }

.margin-bottom-16,
.mb-16 {
  margin-bottom: 16px !important; }

.margin-left-16,
.ml-16 {
  margin-left: 16px !important; }

.margin-horizontal-16,
.mh-16 {
  margin-left: 16px;
  margin-right: 16px; }

.margin-vertical-16,
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px; }

.margin-17,
.m-17 {
  margin: 17px !important; }

.margin-top-17,
.mt-17 {
  margin-top: 17px !important; }

.margin-right-17,
.mr-17 {
  margin-right: 17px !important; }

.margin-bottom-17,
.mb-17 {
  margin-bottom: 17px !important; }

.margin-left-17,
.ml-17 {
  margin-left: 17px !important; }

.margin-horizontal-17,
.mh-17 {
  margin-left: 17px;
  margin-right: 17px; }

.margin-vertical-17,
.mv-17 {
  margin-top: 17px;
  margin-bottom: 17px; }

.margin-18,
.m-18 {
  margin: 18px !important; }

.margin-top-18,
.mt-18 {
  margin-top: 18px !important; }

.margin-right-18,
.mr-18 {
  margin-right: 18px !important; }

.margin-bottom-18,
.mb-18 {
  margin-bottom: 18px !important; }

.margin-left-18,
.ml-18 {
  margin-left: 18px !important; }

.margin-horizontal-18,
.mh-18 {
  margin-left: 18px;
  margin-right: 18px; }

.margin-vertical-18,
.mv-18 {
  margin-top: 18px;
  margin-bottom: 18px; }

.margin-19,
.m-19 {
  margin: 19px !important; }

.margin-top-19,
.mt-19 {
  margin-top: 19px !important; }

.margin-right-19,
.mr-19 {
  margin-right: 19px !important; }

.margin-bottom-19,
.mb-19 {
  margin-bottom: 19px !important; }

.margin-left-19,
.ml-19 {
  margin-left: 19px !important; }

.margin-horizontal-19,
.mh-19 {
  margin-left: 19px;
  margin-right: 19px; }

.margin-vertical-19,
.mv-19 {
  margin-top: 19px;
  margin-bottom: 19px; }

.margin-20,
.m-20 {
  margin: 20px !important; }

.margin-top-20,
.mt-20 {
  margin-top: 20px !important; }

.margin-right-20,
.mr-20 {
  margin-right: 20px !important; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px !important; }

.margin-left-20,
.ml-20 {
  margin-left: 20px !important; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-21,
.m-21 {
  margin: 21px !important; }

.margin-top-21,
.mt-21 {
  margin-top: 21px !important; }

.margin-right-21,
.mr-21 {
  margin-right: 21px !important; }

.margin-bottom-21,
.mb-21 {
  margin-bottom: 21px !important; }

.margin-left-21,
.ml-21 {
  margin-left: 21px !important; }

.margin-horizontal-21,
.mh-21 {
  margin-left: 21px;
  margin-right: 21px; }

.margin-vertical-21,
.mv-21 {
  margin-top: 21px;
  margin-bottom: 21px; }

.margin-22,
.m-22 {
  margin: 22px !important; }

.margin-top-22,
.mt-22 {
  margin-top: 22px !important; }

.margin-right-22,
.mr-22 {
  margin-right: 22px !important; }

.margin-bottom-22,
.mb-22 {
  margin-bottom: 22px !important; }

.margin-left-22,
.ml-22 {
  margin-left: 22px !important; }

.margin-horizontal-22,
.mh-22 {
  margin-left: 22px;
  margin-right: 22px; }

.margin-vertical-22,
.mv-22 {
  margin-top: 22px;
  margin-bottom: 22px; }

.margin-23,
.m-23 {
  margin: 23px !important; }

.margin-top-23,
.mt-23 {
  margin-top: 23px !important; }

.margin-right-23,
.mr-23 {
  margin-right: 23px !important; }

.margin-bottom-23,
.mb-23 {
  margin-bottom: 23px !important; }

.margin-left-23,
.ml-23 {
  margin-left: 23px !important; }

.margin-horizontal-23,
.mh-23 {
  margin-left: 23px;
  margin-right: 23px; }

.margin-vertical-23,
.mv-23 {
  margin-top: 23px;
  margin-bottom: 23px; }

.margin-24,
.m-24 {
  margin: 24px !important; }

.margin-top-24,
.mt-24 {
  margin-top: 24px !important; }

.margin-right-24,
.mr-24 {
  margin-right: 24px !important; }

.margin-bottom-24,
.mb-24 {
  margin-bottom: 24px !important; }

.margin-left-24,
.ml-24 {
  margin-left: 24px !important; }

.margin-horizontal-24,
.mh-24 {
  margin-left: 24px;
  margin-right: 24px; }

.margin-vertical-24,
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px; }

.margin-25,
.m-25 {
  margin: 25px !important; }

.margin-top-25,
.mt-25 {
  margin-top: 25px !important; }

.margin-right-25,
.mr-25 {
  margin-right: 25px !important; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px !important; }

.margin-left-25,
.ml-25 {
  margin-left: 25px !important; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-26,
.m-26 {
  margin: 26px !important; }

.margin-top-26,
.mt-26 {
  margin-top: 26px !important; }

.margin-right-26,
.mr-26 {
  margin-right: 26px !important; }

.margin-bottom-26,
.mb-26 {
  margin-bottom: 26px !important; }

.margin-left-26,
.ml-26 {
  margin-left: 26px !important; }

.margin-horizontal-26,
.mh-26 {
  margin-left: 26px;
  margin-right: 26px; }

.margin-vertical-26,
.mv-26 {
  margin-top: 26px;
  margin-bottom: 26px; }

.margin-27,
.m-27 {
  margin: 27px !important; }

.margin-top-27,
.mt-27 {
  margin-top: 27px !important; }

.margin-right-27,
.mr-27 {
  margin-right: 27px !important; }

.margin-bottom-27,
.mb-27 {
  margin-bottom: 27px !important; }

.margin-left-27,
.ml-27 {
  margin-left: 27px !important; }

.margin-horizontal-27,
.mh-27 {
  margin-left: 27px;
  margin-right: 27px; }

.margin-vertical-27,
.mv-27 {
  margin-top: 27px;
  margin-bottom: 27px; }

.margin-28,
.m-28 {
  margin: 28px !important; }

.margin-top-28,
.mt-28 {
  margin-top: 28px !important; }

.margin-right-28,
.mr-28 {
  margin-right: 28px !important; }

.margin-bottom-28,
.mb-28 {
  margin-bottom: 28px !important; }

.margin-left-28,
.ml-28 {
  margin-left: 28px !important; }

.margin-horizontal-28,
.mh-28 {
  margin-left: 28px;
  margin-right: 28px; }

.margin-vertical-28,
.mv-28 {
  margin-top: 28px;
  margin-bottom: 28px; }

.margin-29,
.m-29 {
  margin: 29px !important; }

.margin-top-29,
.mt-29 {
  margin-top: 29px !important; }

.margin-right-29,
.mr-29 {
  margin-right: 29px !important; }

.margin-bottom-29,
.mb-29 {
  margin-bottom: 29px !important; }

.margin-left-29,
.ml-29 {
  margin-left: 29px !important; }

.margin-horizontal-29,
.mh-29 {
  margin-left: 29px;
  margin-right: 29px; }

.margin-vertical-29,
.mv-29 {
  margin-top: 29px;
  margin-bottom: 29px; }

.margin-30,
.m-30 {
  margin: 30px !important; }

.margin-top-30,
.mt-30 {
  margin-top: 30px !important; }

.margin-right-30,
.mr-30 {
  margin-right: 30px !important; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px !important; }

.margin-left-30,
.ml-30 {
  margin-left: 30px !important; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-31,
.m-31 {
  margin: 31px !important; }

.margin-top-31,
.mt-31 {
  margin-top: 31px !important; }

.margin-right-31,
.mr-31 {
  margin-right: 31px !important; }

.margin-bottom-31,
.mb-31 {
  margin-bottom: 31px !important; }

.margin-left-31,
.ml-31 {
  margin-left: 31px !important; }

.margin-horizontal-31,
.mh-31 {
  margin-left: 31px;
  margin-right: 31px; }

.margin-vertical-31,
.mv-31 {
  margin-top: 31px;
  margin-bottom: 31px; }

.margin-32,
.m-32 {
  margin: 32px !important; }

.margin-top-32,
.mt-32 {
  margin-top: 32px !important; }

.margin-right-32,
.mr-32 {
  margin-right: 32px !important; }

.margin-bottom-32,
.mb-32 {
  margin-bottom: 32px !important; }

.margin-left-32,
.ml-32 {
  margin-left: 32px !important; }

.margin-horizontal-32,
.mh-32 {
  margin-left: 32px;
  margin-right: 32px; }

.margin-vertical-32,
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px; }

.margin-33,
.m-33 {
  margin: 33px !important; }

.margin-top-33,
.mt-33 {
  margin-top: 33px !important; }

.margin-right-33,
.mr-33 {
  margin-right: 33px !important; }

.margin-bottom-33,
.mb-33 {
  margin-bottom: 33px !important; }

.margin-left-33,
.ml-33 {
  margin-left: 33px !important; }

.margin-horizontal-33,
.mh-33 {
  margin-left: 33px;
  margin-right: 33px; }

.margin-vertical-33,
.mv-33 {
  margin-top: 33px;
  margin-bottom: 33px; }

.margin-34,
.m-34 {
  margin: 34px !important; }

.margin-top-34,
.mt-34 {
  margin-top: 34px !important; }

.margin-right-34,
.mr-34 {
  margin-right: 34px !important; }

.margin-bottom-34,
.mb-34 {
  margin-bottom: 34px !important; }

.margin-left-34,
.ml-34 {
  margin-left: 34px !important; }

.margin-horizontal-34,
.mh-34 {
  margin-left: 34px;
  margin-right: 34px; }

.margin-vertical-34,
.mv-34 {
  margin-top: 34px;
  margin-bottom: 34px; }

.margin-35,
.m-35 {
  margin: 35px !important; }

.margin-top-35,
.mt-35 {
  margin-top: 35px !important; }

.margin-right-35,
.mr-35 {
  margin-right: 35px !important; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px !important; }

.margin-left-35,
.ml-35 {
  margin-left: 35px !important; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-36,
.m-36 {
  margin: 36px !important; }

.margin-top-36,
.mt-36 {
  margin-top: 36px !important; }

.margin-right-36,
.mr-36 {
  margin-right: 36px !important; }

.margin-bottom-36,
.mb-36 {
  margin-bottom: 36px !important; }

.margin-left-36,
.ml-36 {
  margin-left: 36px !important; }

.margin-horizontal-36,
.mh-36 {
  margin-left: 36px;
  margin-right: 36px; }

.margin-vertical-36,
.mv-36 {
  margin-top: 36px;
  margin-bottom: 36px; }

.margin-37,
.m-37 {
  margin: 37px !important; }

.margin-top-37,
.mt-37 {
  margin-top: 37px !important; }

.margin-right-37,
.mr-37 {
  margin-right: 37px !important; }

.margin-bottom-37,
.mb-37 {
  margin-bottom: 37px !important; }

.margin-left-37,
.ml-37 {
  margin-left: 37px !important; }

.margin-horizontal-37,
.mh-37 {
  margin-left: 37px;
  margin-right: 37px; }

.margin-vertical-37,
.mv-37 {
  margin-top: 37px;
  margin-bottom: 37px; }

.margin-38,
.m-38 {
  margin: 38px !important; }

.margin-top-38,
.mt-38 {
  margin-top: 38px !important; }

.margin-right-38,
.mr-38 {
  margin-right: 38px !important; }

.margin-bottom-38,
.mb-38 {
  margin-bottom: 38px !important; }

.margin-left-38,
.ml-38 {
  margin-left: 38px !important; }

.margin-horizontal-38,
.mh-38 {
  margin-left: 38px;
  margin-right: 38px; }

.margin-vertical-38,
.mv-38 {
  margin-top: 38px;
  margin-bottom: 38px; }

.margin-39,
.m-39 {
  margin: 39px !important; }

.margin-top-39,
.mt-39 {
  margin-top: 39px !important; }

.margin-right-39,
.mr-39 {
  margin-right: 39px !important; }

.margin-bottom-39,
.mb-39 {
  margin-bottom: 39px !important; }

.margin-left-39,
.ml-39 {
  margin-left: 39px !important; }

.margin-horizontal-39,
.mh-39 {
  margin-left: 39px;
  margin-right: 39px; }

.margin-vertical-39,
.mv-39 {
  margin-top: 39px;
  margin-bottom: 39px; }

.margin-40,
.m-40 {
  margin: 40px !important; }

.margin-top-40,
.mt-40 {
  margin-top: 40px !important; }

.margin-right-40,
.mr-40 {
  margin-right: 40px !important; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px !important; }

.margin-left-40,
.ml-40 {
  margin-left: 40px !important; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-41,
.m-41 {
  margin: 41px !important; }

.margin-top-41,
.mt-41 {
  margin-top: 41px !important; }

.margin-right-41,
.mr-41 {
  margin-right: 41px !important; }

.margin-bottom-41,
.mb-41 {
  margin-bottom: 41px !important; }

.margin-left-41,
.ml-41 {
  margin-left: 41px !important; }

.margin-horizontal-41,
.mh-41 {
  margin-left: 41px;
  margin-right: 41px; }

.margin-vertical-41,
.mv-41 {
  margin-top: 41px;
  margin-bottom: 41px; }

.margin-42,
.m-42 {
  margin: 42px !important; }

.margin-top-42,
.mt-42 {
  margin-top: 42px !important; }

.margin-right-42,
.mr-42 {
  margin-right: 42px !important; }

.margin-bottom-42,
.mb-42 {
  margin-bottom: 42px !important; }

.margin-left-42,
.ml-42 {
  margin-left: 42px !important; }

.margin-horizontal-42,
.mh-42 {
  margin-left: 42px;
  margin-right: 42px; }

.margin-vertical-42,
.mv-42 {
  margin-top: 42px;
  margin-bottom: 42px; }

.margin-43,
.m-43 {
  margin: 43px !important; }

.margin-top-43,
.mt-43 {
  margin-top: 43px !important; }

.margin-right-43,
.mr-43 {
  margin-right: 43px !important; }

.margin-bottom-43,
.mb-43 {
  margin-bottom: 43px !important; }

.margin-left-43,
.ml-43 {
  margin-left: 43px !important; }

.margin-horizontal-43,
.mh-43 {
  margin-left: 43px;
  margin-right: 43px; }

.margin-vertical-43,
.mv-43 {
  margin-top: 43px;
  margin-bottom: 43px; }

.margin-44,
.m-44 {
  margin: 44px !important; }

.margin-top-44,
.mt-44 {
  margin-top: 44px !important; }

.margin-right-44,
.mr-44 {
  margin-right: 44px !important; }

.margin-bottom-44,
.mb-44 {
  margin-bottom: 44px !important; }

.margin-left-44,
.ml-44 {
  margin-left: 44px !important; }

.margin-horizontal-44,
.mh-44 {
  margin-left: 44px;
  margin-right: 44px; }

.margin-vertical-44,
.mv-44 {
  margin-top: 44px;
  margin-bottom: 44px; }

.margin-45,
.m-45 {
  margin: 45px !important; }

.margin-top-45,
.mt-45 {
  margin-top: 45px !important; }

.margin-right-45,
.mr-45 {
  margin-right: 45px !important; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px !important; }

.margin-left-45,
.ml-45 {
  margin-left: 45px !important; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-46,
.m-46 {
  margin: 46px !important; }

.margin-top-46,
.mt-46 {
  margin-top: 46px !important; }

.margin-right-46,
.mr-46 {
  margin-right: 46px !important; }

.margin-bottom-46,
.mb-46 {
  margin-bottom: 46px !important; }

.margin-left-46,
.ml-46 {
  margin-left: 46px !important; }

.margin-horizontal-46,
.mh-46 {
  margin-left: 46px;
  margin-right: 46px; }

.margin-vertical-46,
.mv-46 {
  margin-top: 46px;
  margin-bottom: 46px; }

.margin-47,
.m-47 {
  margin: 47px !important; }

.margin-top-47,
.mt-47 {
  margin-top: 47px !important; }

.margin-right-47,
.mr-47 {
  margin-right: 47px !important; }

.margin-bottom-47,
.mb-47 {
  margin-bottom: 47px !important; }

.margin-left-47,
.ml-47 {
  margin-left: 47px !important; }

.margin-horizontal-47,
.mh-47 {
  margin-left: 47px;
  margin-right: 47px; }

.margin-vertical-47,
.mv-47 {
  margin-top: 47px;
  margin-bottom: 47px; }

.margin-48,
.m-48 {
  margin: 48px !important; }

.margin-top-48,
.mt-48 {
  margin-top: 48px !important; }

.margin-right-48,
.mr-48 {
  margin-right: 48px !important; }

.margin-bottom-48,
.mb-48 {
  margin-bottom: 48px !important; }

.margin-left-48,
.ml-48 {
  margin-left: 48px !important; }

.margin-horizontal-48,
.mh-48 {
  margin-left: 48px;
  margin-right: 48px; }

.margin-vertical-48,
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px; }

.margin-49,
.m-49 {
  margin: 49px !important; }

.margin-top-49,
.mt-49 {
  margin-top: 49px !important; }

.margin-right-49,
.mr-49 {
  margin-right: 49px !important; }

.margin-bottom-49,
.mb-49 {
  margin-bottom: 49px !important; }

.margin-left-49,
.ml-49 {
  margin-left: 49px !important; }

.margin-horizontal-49,
.mh-49 {
  margin-left: 49px;
  margin-right: 49px; }

.margin-vertical-49,
.mv-49 {
  margin-top: 49px;
  margin-bottom: 49px; }

.margin-50,
.m-50 {
  margin: 50px !important; }

.margin-top-50,
.mt-50 {
  margin-top: 50px !important; }

.margin-right-50,
.mr-50 {
  margin-right: 50px !important; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px !important; }

.margin-left-50,
.ml-50 {
  margin-left: 50px !important; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-51,
.m-51 {
  margin: 51px !important; }

.margin-top-51,
.mt-51 {
  margin-top: 51px !important; }

.margin-right-51,
.mr-51 {
  margin-right: 51px !important; }

.margin-bottom-51,
.mb-51 {
  margin-bottom: 51px !important; }

.margin-left-51,
.ml-51 {
  margin-left: 51px !important; }

.margin-horizontal-51,
.mh-51 {
  margin-left: 51px;
  margin-right: 51px; }

.margin-vertical-51,
.mv-51 {
  margin-top: 51px;
  margin-bottom: 51px; }

.margin-52,
.m-52 {
  margin: 52px !important; }

.margin-top-52,
.mt-52 {
  margin-top: 52px !important; }

.margin-right-52,
.mr-52 {
  margin-right: 52px !important; }

.margin-bottom-52,
.mb-52 {
  margin-bottom: 52px !important; }

.margin-left-52,
.ml-52 {
  margin-left: 52px !important; }

.margin-horizontal-52,
.mh-52 {
  margin-left: 52px;
  margin-right: 52px; }

.margin-vertical-52,
.mv-52 {
  margin-top: 52px;
  margin-bottom: 52px; }

.margin-53,
.m-53 {
  margin: 53px !important; }

.margin-top-53,
.mt-53 {
  margin-top: 53px !important; }

.margin-right-53,
.mr-53 {
  margin-right: 53px !important; }

.margin-bottom-53,
.mb-53 {
  margin-bottom: 53px !important; }

.margin-left-53,
.ml-53 {
  margin-left: 53px !important; }

.margin-horizontal-53,
.mh-53 {
  margin-left: 53px;
  margin-right: 53px; }

.margin-vertical-53,
.mv-53 {
  margin-top: 53px;
  margin-bottom: 53px; }

.margin-54,
.m-54 {
  margin: 54px !important; }

.margin-top-54,
.mt-54 {
  margin-top: 54px !important; }

.margin-right-54,
.mr-54 {
  margin-right: 54px !important; }

.margin-bottom-54,
.mb-54 {
  margin-bottom: 54px !important; }

.margin-left-54,
.ml-54 {
  margin-left: 54px !important; }

.margin-horizontal-54,
.mh-54 {
  margin-left: 54px;
  margin-right: 54px; }

.margin-vertical-54,
.mv-54 {
  margin-top: 54px;
  margin-bottom: 54px; }

.margin-55,
.m-55 {
  margin: 55px !important; }

.margin-top-55,
.mt-55 {
  margin-top: 55px !important; }

.margin-right-55,
.mr-55 {
  margin-right: 55px !important; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px !important; }

.margin-left-55,
.ml-55 {
  margin-left: 55px !important; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-56,
.m-56 {
  margin: 56px !important; }

.margin-top-56,
.mt-56 {
  margin-top: 56px !important; }

.margin-right-56,
.mr-56 {
  margin-right: 56px !important; }

.margin-bottom-56,
.mb-56 {
  margin-bottom: 56px !important; }

.margin-left-56,
.ml-56 {
  margin-left: 56px !important; }

.margin-horizontal-56,
.mh-56 {
  margin-left: 56px;
  margin-right: 56px; }

.margin-vertical-56,
.mv-56 {
  margin-top: 56px;
  margin-bottom: 56px; }

.margin-57,
.m-57 {
  margin: 57px !important; }

.margin-top-57,
.mt-57 {
  margin-top: 57px !important; }

.margin-right-57,
.mr-57 {
  margin-right: 57px !important; }

.margin-bottom-57,
.mb-57 {
  margin-bottom: 57px !important; }

.margin-left-57,
.ml-57 {
  margin-left: 57px !important; }

.margin-horizontal-57,
.mh-57 {
  margin-left: 57px;
  margin-right: 57px; }

.margin-vertical-57,
.mv-57 {
  margin-top: 57px;
  margin-bottom: 57px; }

.margin-58,
.m-58 {
  margin: 58px !important; }

.margin-top-58,
.mt-58 {
  margin-top: 58px !important; }

.margin-right-58,
.mr-58 {
  margin-right: 58px !important; }

.margin-bottom-58,
.mb-58 {
  margin-bottom: 58px !important; }

.margin-left-58,
.ml-58 {
  margin-left: 58px !important; }

.margin-horizontal-58,
.mh-58 {
  margin-left: 58px;
  margin-right: 58px; }

.margin-vertical-58,
.mv-58 {
  margin-top: 58px;
  margin-bottom: 58px; }

.margin-59,
.m-59 {
  margin: 59px !important; }

.margin-top-59,
.mt-59 {
  margin-top: 59px !important; }

.margin-right-59,
.mr-59 {
  margin-right: 59px !important; }

.margin-bottom-59,
.mb-59 {
  margin-bottom: 59px !important; }

.margin-left-59,
.ml-59 {
  margin-left: 59px !important; }

.margin-horizontal-59,
.mh-59 {
  margin-left: 59px;
  margin-right: 59px; }

.margin-vertical-59,
.mv-59 {
  margin-top: 59px;
  margin-bottom: 59px; }

.margin-60,
.m-60 {
  margin: 60px !important; }

.margin-top-60,
.mt-60 {
  margin-top: 60px !important; }

.margin-right-60,
.mr-60 {
  margin-right: 60px !important; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px !important; }

.margin-left-60,
.ml-60 {
  margin-left: 60px !important; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-61,
.m-61 {
  margin: 61px !important; }

.margin-top-61,
.mt-61 {
  margin-top: 61px !important; }

.margin-right-61,
.mr-61 {
  margin-right: 61px !important; }

.margin-bottom-61,
.mb-61 {
  margin-bottom: 61px !important; }

.margin-left-61,
.ml-61 {
  margin-left: 61px !important; }

.margin-horizontal-61,
.mh-61 {
  margin-left: 61px;
  margin-right: 61px; }

.margin-vertical-61,
.mv-61 {
  margin-top: 61px;
  margin-bottom: 61px; }

.margin-62,
.m-62 {
  margin: 62px !important; }

.margin-top-62,
.mt-62 {
  margin-top: 62px !important; }

.margin-right-62,
.mr-62 {
  margin-right: 62px !important; }

.margin-bottom-62,
.mb-62 {
  margin-bottom: 62px !important; }

.margin-left-62,
.ml-62 {
  margin-left: 62px !important; }

.margin-horizontal-62,
.mh-62 {
  margin-left: 62px;
  margin-right: 62px; }

.margin-vertical-62,
.mv-62 {
  margin-top: 62px;
  margin-bottom: 62px; }

.margin-63,
.m-63 {
  margin: 63px !important; }

.margin-top-63,
.mt-63 {
  margin-top: 63px !important; }

.margin-right-63,
.mr-63 {
  margin-right: 63px !important; }

.margin-bottom-63,
.mb-63 {
  margin-bottom: 63px !important; }

.margin-left-63,
.ml-63 {
  margin-left: 63px !important; }

.margin-horizontal-63,
.mh-63 {
  margin-left: 63px;
  margin-right: 63px; }

.margin-vertical-63,
.mv-63 {
  margin-top: 63px;
  margin-bottom: 63px; }

.margin-64,
.m-64 {
  margin: 64px !important; }

.margin-top-64,
.mt-64 {
  margin-top: 64px !important; }

.margin-right-64,
.mr-64 {
  margin-right: 64px !important; }

.margin-bottom-64,
.mb-64 {
  margin-bottom: 64px !important; }

.margin-left-64,
.ml-64 {
  margin-left: 64px !important; }

.margin-horizontal-64,
.mh-64 {
  margin-left: 64px;
  margin-right: 64px; }

.margin-vertical-64,
.mv-64 {
  margin-top: 64px;
  margin-bottom: 64px; }

.margin-65,
.m-65 {
  margin: 65px !important; }

.margin-top-65,
.mt-65 {
  margin-top: 65px !important; }

.margin-right-65,
.mr-65 {
  margin-right: 65px !important; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px !important; }

.margin-left-65,
.ml-65 {
  margin-left: 65px !important; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-66,
.m-66 {
  margin: 66px !important; }

.margin-top-66,
.mt-66 {
  margin-top: 66px !important; }

.margin-right-66,
.mr-66 {
  margin-right: 66px !important; }

.margin-bottom-66,
.mb-66 {
  margin-bottom: 66px !important; }

.margin-left-66,
.ml-66 {
  margin-left: 66px !important; }

.margin-horizontal-66,
.mh-66 {
  margin-left: 66px;
  margin-right: 66px; }

.margin-vertical-66,
.mv-66 {
  margin-top: 66px;
  margin-bottom: 66px; }

.margin-67,
.m-67 {
  margin: 67px !important; }

.margin-top-67,
.mt-67 {
  margin-top: 67px !important; }

.margin-right-67,
.mr-67 {
  margin-right: 67px !important; }

.margin-bottom-67,
.mb-67 {
  margin-bottom: 67px !important; }

.margin-left-67,
.ml-67 {
  margin-left: 67px !important; }

.margin-horizontal-67,
.mh-67 {
  margin-left: 67px;
  margin-right: 67px; }

.margin-vertical-67,
.mv-67 {
  margin-top: 67px;
  margin-bottom: 67px; }

.margin-68,
.m-68 {
  margin: 68px !important; }

.margin-top-68,
.mt-68 {
  margin-top: 68px !important; }

.margin-right-68,
.mr-68 {
  margin-right: 68px !important; }

.margin-bottom-68,
.mb-68 {
  margin-bottom: 68px !important; }

.margin-left-68,
.ml-68 {
  margin-left: 68px !important; }

.margin-horizontal-68,
.mh-68 {
  margin-left: 68px;
  margin-right: 68px; }

.margin-vertical-68,
.mv-68 {
  margin-top: 68px;
  margin-bottom: 68px; }

.margin-69,
.m-69 {
  margin: 69px !important; }

.margin-top-69,
.mt-69 {
  margin-top: 69px !important; }

.margin-right-69,
.mr-69 {
  margin-right: 69px !important; }

.margin-bottom-69,
.mb-69 {
  margin-bottom: 69px !important; }

.margin-left-69,
.ml-69 {
  margin-left: 69px !important; }

.margin-horizontal-69,
.mh-69 {
  margin-left: 69px;
  margin-right: 69px; }

.margin-vertical-69,
.mv-69 {
  margin-top: 69px;
  margin-bottom: 69px; }

.margin-70,
.m-70 {
  margin: 70px !important; }

.margin-top-70,
.mt-70 {
  margin-top: 70px !important; }

.margin-right-70,
.mr-70 {
  margin-right: 70px !important; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px !important; }

.margin-left-70,
.ml-70 {
  margin-left: 70px !important; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-71,
.m-71 {
  margin: 71px !important; }

.margin-top-71,
.mt-71 {
  margin-top: 71px !important; }

.margin-right-71,
.mr-71 {
  margin-right: 71px !important; }

.margin-bottom-71,
.mb-71 {
  margin-bottom: 71px !important; }

.margin-left-71,
.ml-71 {
  margin-left: 71px !important; }

.margin-horizontal-71,
.mh-71 {
  margin-left: 71px;
  margin-right: 71px; }

.margin-vertical-71,
.mv-71 {
  margin-top: 71px;
  margin-bottom: 71px; }

.margin-72,
.m-72 {
  margin: 72px !important; }

.margin-top-72,
.mt-72 {
  margin-top: 72px !important; }

.margin-right-72,
.mr-72 {
  margin-right: 72px !important; }

.margin-bottom-72,
.mb-72 {
  margin-bottom: 72px !important; }

.margin-left-72,
.ml-72 {
  margin-left: 72px !important; }

.margin-horizontal-72,
.mh-72 {
  margin-left: 72px;
  margin-right: 72px; }

.margin-vertical-72,
.mv-72 {
  margin-top: 72px;
  margin-bottom: 72px; }

.margin-73,
.m-73 {
  margin: 73px !important; }

.margin-top-73,
.mt-73 {
  margin-top: 73px !important; }

.margin-right-73,
.mr-73 {
  margin-right: 73px !important; }

.margin-bottom-73,
.mb-73 {
  margin-bottom: 73px !important; }

.margin-left-73,
.ml-73 {
  margin-left: 73px !important; }

.margin-horizontal-73,
.mh-73 {
  margin-left: 73px;
  margin-right: 73px; }

.margin-vertical-73,
.mv-73 {
  margin-top: 73px;
  margin-bottom: 73px; }

.margin-74,
.m-74 {
  margin: 74px !important; }

.margin-top-74,
.mt-74 {
  margin-top: 74px !important; }

.margin-right-74,
.mr-74 {
  margin-right: 74px !important; }

.margin-bottom-74,
.mb-74 {
  margin-bottom: 74px !important; }

.margin-left-74,
.ml-74 {
  margin-left: 74px !important; }

.margin-horizontal-74,
.mh-74 {
  margin-left: 74px;
  margin-right: 74px; }

.margin-vertical-74,
.mv-74 {
  margin-top: 74px;
  margin-bottom: 74px; }

.margin-75,
.m-75 {
  margin: 75px !important; }

.margin-top-75,
.mt-75 {
  margin-top: 75px !important; }

.margin-right-75,
.mr-75 {
  margin-right: 75px !important; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px !important; }

.margin-left-75,
.ml-75 {
  margin-left: 75px !important; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-76,
.m-76 {
  margin: 76px !important; }

.margin-top-76,
.mt-76 {
  margin-top: 76px !important; }

.margin-right-76,
.mr-76 {
  margin-right: 76px !important; }

.margin-bottom-76,
.mb-76 {
  margin-bottom: 76px !important; }

.margin-left-76,
.ml-76 {
  margin-left: 76px !important; }

.margin-horizontal-76,
.mh-76 {
  margin-left: 76px;
  margin-right: 76px; }

.margin-vertical-76,
.mv-76 {
  margin-top: 76px;
  margin-bottom: 76px; }

.margin-77,
.m-77 {
  margin: 77px !important; }

.margin-top-77,
.mt-77 {
  margin-top: 77px !important; }

.margin-right-77,
.mr-77 {
  margin-right: 77px !important; }

.margin-bottom-77,
.mb-77 {
  margin-bottom: 77px !important; }

.margin-left-77,
.ml-77 {
  margin-left: 77px !important; }

.margin-horizontal-77,
.mh-77 {
  margin-left: 77px;
  margin-right: 77px; }

.margin-vertical-77,
.mv-77 {
  margin-top: 77px;
  margin-bottom: 77px; }

.margin-78,
.m-78 {
  margin: 78px !important; }

.margin-top-78,
.mt-78 {
  margin-top: 78px !important; }

.margin-right-78,
.mr-78 {
  margin-right: 78px !important; }

.margin-bottom-78,
.mb-78 {
  margin-bottom: 78px !important; }

.margin-left-78,
.ml-78 {
  margin-left: 78px !important; }

.margin-horizontal-78,
.mh-78 {
  margin-left: 78px;
  margin-right: 78px; }

.margin-vertical-78,
.mv-78 {
  margin-top: 78px;
  margin-bottom: 78px; }

.margin-79,
.m-79 {
  margin: 79px !important; }

.margin-top-79,
.mt-79 {
  margin-top: 79px !important; }

.margin-right-79,
.mr-79 {
  margin-right: 79px !important; }

.margin-bottom-79,
.mb-79 {
  margin-bottom: 79px !important; }

.margin-left-79,
.ml-79 {
  margin-left: 79px !important; }

.margin-horizontal-79,
.mh-79 {
  margin-left: 79px;
  margin-right: 79px; }

.margin-vertical-79,
.mv-79 {
  margin-top: 79px;
  margin-bottom: 79px; }

.margin-80,
.m-80 {
  margin: 80px !important; }

.margin-top-80,
.mt-80 {
  margin-top: 80px !important; }

.margin-right-80,
.mr-80 {
  margin-right: 80px !important; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px !important; }

.margin-left-80,
.ml-80 {
  margin-left: 80px !important; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-81,
.m-81 {
  margin: 81px !important; }

.margin-top-81,
.mt-81 {
  margin-top: 81px !important; }

.margin-right-81,
.mr-81 {
  margin-right: 81px !important; }

.margin-bottom-81,
.mb-81 {
  margin-bottom: 81px !important; }

.margin-left-81,
.ml-81 {
  margin-left: 81px !important; }

.margin-horizontal-81,
.mh-81 {
  margin-left: 81px;
  margin-right: 81px; }

.margin-vertical-81,
.mv-81 {
  margin-top: 81px;
  margin-bottom: 81px; }

.margin-82,
.m-82 {
  margin: 82px !important; }

.margin-top-82,
.mt-82 {
  margin-top: 82px !important; }

.margin-right-82,
.mr-82 {
  margin-right: 82px !important; }

.margin-bottom-82,
.mb-82 {
  margin-bottom: 82px !important; }

.margin-left-82,
.ml-82 {
  margin-left: 82px !important; }

.margin-horizontal-82,
.mh-82 {
  margin-left: 82px;
  margin-right: 82px; }

.margin-vertical-82,
.mv-82 {
  margin-top: 82px;
  margin-bottom: 82px; }

.margin-83,
.m-83 {
  margin: 83px !important; }

.margin-top-83,
.mt-83 {
  margin-top: 83px !important; }

.margin-right-83,
.mr-83 {
  margin-right: 83px !important; }

.margin-bottom-83,
.mb-83 {
  margin-bottom: 83px !important; }

.margin-left-83,
.ml-83 {
  margin-left: 83px !important; }

.margin-horizontal-83,
.mh-83 {
  margin-left: 83px;
  margin-right: 83px; }

.margin-vertical-83,
.mv-83 {
  margin-top: 83px;
  margin-bottom: 83px; }

.margin-84,
.m-84 {
  margin: 84px !important; }

.margin-top-84,
.mt-84 {
  margin-top: 84px !important; }

.margin-right-84,
.mr-84 {
  margin-right: 84px !important; }

.margin-bottom-84,
.mb-84 {
  margin-bottom: 84px !important; }

.margin-left-84,
.ml-84 {
  margin-left: 84px !important; }

.margin-horizontal-84,
.mh-84 {
  margin-left: 84px;
  margin-right: 84px; }

.margin-vertical-84,
.mv-84 {
  margin-top: 84px;
  margin-bottom: 84px; }

.margin-85,
.m-85 {
  margin: 85px !important; }

.margin-top-85,
.mt-85 {
  margin-top: 85px !important; }

.margin-right-85,
.mr-85 {
  margin-right: 85px !important; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px !important; }

.margin-left-85,
.ml-85 {
  margin-left: 85px !important; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-86,
.m-86 {
  margin: 86px !important; }

.margin-top-86,
.mt-86 {
  margin-top: 86px !important; }

.margin-right-86,
.mr-86 {
  margin-right: 86px !important; }

.margin-bottom-86,
.mb-86 {
  margin-bottom: 86px !important; }

.margin-left-86,
.ml-86 {
  margin-left: 86px !important; }

.margin-horizontal-86,
.mh-86 {
  margin-left: 86px;
  margin-right: 86px; }

.margin-vertical-86,
.mv-86 {
  margin-top: 86px;
  margin-bottom: 86px; }

.margin-87,
.m-87 {
  margin: 87px !important; }

.margin-top-87,
.mt-87 {
  margin-top: 87px !important; }

.margin-right-87,
.mr-87 {
  margin-right: 87px !important; }

.margin-bottom-87,
.mb-87 {
  margin-bottom: 87px !important; }

.margin-left-87,
.ml-87 {
  margin-left: 87px !important; }

.margin-horizontal-87,
.mh-87 {
  margin-left: 87px;
  margin-right: 87px; }

.margin-vertical-87,
.mv-87 {
  margin-top: 87px;
  margin-bottom: 87px; }

.margin-88,
.m-88 {
  margin: 88px !important; }

.margin-top-88,
.mt-88 {
  margin-top: 88px !important; }

.margin-right-88,
.mr-88 {
  margin-right: 88px !important; }

.margin-bottom-88,
.mb-88 {
  margin-bottom: 88px !important; }

.margin-left-88,
.ml-88 {
  margin-left: 88px !important; }

.margin-horizontal-88,
.mh-88 {
  margin-left: 88px;
  margin-right: 88px; }

.margin-vertical-88,
.mv-88 {
  margin-top: 88px;
  margin-bottom: 88px; }

.margin-89,
.m-89 {
  margin: 89px !important; }

.margin-top-89,
.mt-89 {
  margin-top: 89px !important; }

.margin-right-89,
.mr-89 {
  margin-right: 89px !important; }

.margin-bottom-89,
.mb-89 {
  margin-bottom: 89px !important; }

.margin-left-89,
.ml-89 {
  margin-left: 89px !important; }

.margin-horizontal-89,
.mh-89 {
  margin-left: 89px;
  margin-right: 89px; }

.margin-vertical-89,
.mv-89 {
  margin-top: 89px;
  margin-bottom: 89px; }

.margin-90,
.m-90 {
  margin: 90px !important; }

.margin-top-90,
.mt-90 {
  margin-top: 90px !important; }

.margin-right-90,
.mr-90 {
  margin-right: 90px !important; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px !important; }

.margin-left-90,
.ml-90 {
  margin-left: 90px !important; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-91,
.m-91 {
  margin: 91px !important; }

.margin-top-91,
.mt-91 {
  margin-top: 91px !important; }

.margin-right-91,
.mr-91 {
  margin-right: 91px !important; }

.margin-bottom-91,
.mb-91 {
  margin-bottom: 91px !important; }

.margin-left-91,
.ml-91 {
  margin-left: 91px !important; }

.margin-horizontal-91,
.mh-91 {
  margin-left: 91px;
  margin-right: 91px; }

.margin-vertical-91,
.mv-91 {
  margin-top: 91px;
  margin-bottom: 91px; }

.margin-92,
.m-92 {
  margin: 92px !important; }

.margin-top-92,
.mt-92 {
  margin-top: 92px !important; }

.margin-right-92,
.mr-92 {
  margin-right: 92px !important; }

.margin-bottom-92,
.mb-92 {
  margin-bottom: 92px !important; }

.margin-left-92,
.ml-92 {
  margin-left: 92px !important; }

.margin-horizontal-92,
.mh-92 {
  margin-left: 92px;
  margin-right: 92px; }

.margin-vertical-92,
.mv-92 {
  margin-top: 92px;
  margin-bottom: 92px; }

.margin-93,
.m-93 {
  margin: 93px !important; }

.margin-top-93,
.mt-93 {
  margin-top: 93px !important; }

.margin-right-93,
.mr-93 {
  margin-right: 93px !important; }

.margin-bottom-93,
.mb-93 {
  margin-bottom: 93px !important; }

.margin-left-93,
.ml-93 {
  margin-left: 93px !important; }

.margin-horizontal-93,
.mh-93 {
  margin-left: 93px;
  margin-right: 93px; }

.margin-vertical-93,
.mv-93 {
  margin-top: 93px;
  margin-bottom: 93px; }

.margin-94,
.m-94 {
  margin: 94px !important; }

.margin-top-94,
.mt-94 {
  margin-top: 94px !important; }

.margin-right-94,
.mr-94 {
  margin-right: 94px !important; }

.margin-bottom-94,
.mb-94 {
  margin-bottom: 94px !important; }

.margin-left-94,
.ml-94 {
  margin-left: 94px !important; }

.margin-horizontal-94,
.mh-94 {
  margin-left: 94px;
  margin-right: 94px; }

.margin-vertical-94,
.mv-94 {
  margin-top: 94px;
  margin-bottom: 94px; }

.margin-95,
.m-95 {
  margin: 95px !important; }

.margin-top-95,
.mt-95 {
  margin-top: 95px !important; }

.margin-right-95,
.mr-95 {
  margin-right: 95px !important; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px !important; }

.margin-left-95,
.ml-95 {
  margin-left: 95px !important; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-96,
.m-96 {
  margin: 96px !important; }

.margin-top-96,
.mt-96 {
  margin-top: 96px !important; }

.margin-right-96,
.mr-96 {
  margin-right: 96px !important; }

.margin-bottom-96,
.mb-96 {
  margin-bottom: 96px !important; }

.margin-left-96,
.ml-96 {
  margin-left: 96px !important; }

.margin-horizontal-96,
.mh-96 {
  margin-left: 96px;
  margin-right: 96px; }

.margin-vertical-96,
.mv-96 {
  margin-top: 96px;
  margin-bottom: 96px; }

.margin-97,
.m-97 {
  margin: 97px !important; }

.margin-top-97,
.mt-97 {
  margin-top: 97px !important; }

.margin-right-97,
.mr-97 {
  margin-right: 97px !important; }

.margin-bottom-97,
.mb-97 {
  margin-bottom: 97px !important; }

.margin-left-97,
.ml-97 {
  margin-left: 97px !important; }

.margin-horizontal-97,
.mh-97 {
  margin-left: 97px;
  margin-right: 97px; }

.margin-vertical-97,
.mv-97 {
  margin-top: 97px;
  margin-bottom: 97px; }

.margin-98,
.m-98 {
  margin: 98px !important; }

.margin-top-98,
.mt-98 {
  margin-top: 98px !important; }

.margin-right-98,
.mr-98 {
  margin-right: 98px !important; }

.margin-bottom-98,
.mb-98 {
  margin-bottom: 98px !important; }

.margin-left-98,
.ml-98 {
  margin-left: 98px !important; }

.margin-horizontal-98,
.mh-98 {
  margin-left: 98px;
  margin-right: 98px; }

.margin-vertical-98,
.mv-98 {
  margin-top: 98px;
  margin-bottom: 98px; }

.margin-99,
.m-99 {
  margin: 99px !important; }

.margin-top-99,
.mt-99 {
  margin-top: 99px !important; }

.margin-right-99,
.mr-99 {
  margin-right: 99px !important; }

.margin-bottom-99,
.mb-99 {
  margin-bottom: 99px !important; }

.margin-left-99,
.ml-99 {
  margin-left: 99px !important; }

.margin-horizontal-99,
.mh-99 {
  margin-left: 99px;
  margin-right: 99px; }

.margin-vertical-99,
.mv-99 {
  margin-top: 99px;
  margin-bottom: 99px; }

.margin-100,
.m-100 {
  margin: 100px !important; }

.margin-top-100,
.mt-100 {
  margin-top: 100px !important; }

.margin-right-100,
.mr-100 {
  margin-right: 100px !important; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px !important; }

.margin-left-100,
.ml-100 {
  margin-left: 100px !important; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.height-0,
.h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important; }

.width-0,
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important; }

.height-5,
.h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important; }

.width-5,
.w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important; }

.height-10,
.h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important; }

.width-10,
.w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important; }

.height-15,
.h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important; }

.width-15,
.w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important; }

.height-20,
.h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important; }

.width-20,
.w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important; }

.height-25,
.h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important; }

.width-25,
.w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important; }

.height-30,
.h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important; }

.width-30,
.w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important; }

.height-35,
.h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important; }

.width-35,
.w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important; }

.height-40,
.h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important; }

.width-40,
.w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important; }

.height-45,
.h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important; }

.width-45,
.w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important; }

.height-50,
.h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important; }

.width-50,
.w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important; }

.height-55,
.h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important; }

.width-55,
.w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important; }

.height-60,
.h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important; }

.width-60,
.w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important; }

.height-65,
.h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important; }

.width-65,
.w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important; }

.height-70,
.h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important; }

.width-70,
.w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important; }

.height-75,
.h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important; }

.width-75,
.w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important; }

.height-80,
.h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important; }

.width-80,
.w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important; }

.height-85,
.h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important; }

.width-85,
.w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important; }

.height-90,
.h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important; }

.width-90,
.w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important; }

.height-95,
.h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important; }

.width-95,
.w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important; }

.height-100,
.h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important; }

.width-100,
.w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important; }

.height-105,
.h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important; }

.width-105,
.w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important; }

.height-110,
.h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important; }

.width-110,
.w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important; }

.height-115,
.h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important; }

.width-115,
.w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important; }

.height-120,
.h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important; }

.width-120,
.w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important; }

.height-125,
.h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important; }

.width-125,
.w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important; }

.height-130,
.h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important; }

.width-130,
.w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important; }

.height-135,
.h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important; }

.width-135,
.w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important; }

.height-140,
.h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important; }

.width-140,
.w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important; }

.height-145,
.h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important; }

.width-145,
.w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important; }

.height-150,
.h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important; }

.width-150,
.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important; }

.height-155,
.h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important; }

.width-155,
.w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important; }

.height-160,
.h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important; }

.width-160,
.w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important; }

.height-165,
.h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important; }

.width-165,
.w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important; }

.height-170,
.h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important; }

.width-170,
.w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important; }

.height-175,
.h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important; }

.width-175,
.w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important; }

.height-180,
.h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important; }

.width-180,
.w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important; }

.height-185,
.h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important; }

.width-185,
.w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important; }

.height-190,
.h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important; }

.width-190,
.w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important; }

.height-195,
.h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important; }

.width-195,
.w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important; }

.height-200,
.h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important; }

.width-200,
.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important; }

.height-205,
.h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important; }

.width-205,
.w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important; }

.height-210,
.h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important; }

.width-210,
.w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important; }

.height-215,
.h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important; }

.width-215,
.w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important; }

.height-220,
.h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important; }

.width-220,
.w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important; }

.height-225,
.h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important; }

.width-225,
.w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important; }

.height-230,
.h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important; }

.width-230,
.w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important; }

.height-235,
.h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important; }

.width-235,
.w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important; }

.height-240,
.h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important; }

.width-240,
.w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important; }

.height-245,
.h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important; }

.width-245,
.w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important; }

.height-250,
.h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important; }

.width-250,
.w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important; }

.height-255,
.h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important; }

.width-255,
.w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important; }

.height-260,
.h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important; }

.width-260,
.w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important; }

.height-265,
.h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important; }

.width-265,
.w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important; }

.height-270,
.h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important; }

.width-270,
.w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important; }

.height-275,
.h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important; }

.width-275,
.w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important; }

.height-280,
.h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important; }

.width-280,
.w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important; }

.height-285,
.h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important; }

.width-285,
.w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important; }

.height-290,
.h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important; }

.width-290,
.w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important; }

.height-295,
.h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important; }

.width-295,
.w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important; }

.height-300,
.h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important; }

.width-300,
.w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important; }

.height-305,
.h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important; }

.width-305,
.w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important; }

.height-310,
.h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important; }

.width-310,
.w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important; }

.height-315,
.h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important; }

.width-315,
.w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important; }

.height-320,
.h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important; }

.width-320,
.w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important; }

.height-325,
.h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important; }

.width-325,
.w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important; }

.height-330,
.h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important; }

.width-330,
.w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important; }

.height-335,
.h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important; }

.width-335,
.w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important; }

.height-340,
.h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important; }

.width-340,
.w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important; }

.height-345,
.h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important; }

.width-345,
.w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important; }

.height-350,
.h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important; }

.width-350,
.w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important; }

.height-355,
.h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important; }

.width-355,
.w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important; }

.height-360,
.h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important; }

.width-360,
.w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important; }

.height-365,
.h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important; }

.width-365,
.w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important; }

.height-370,
.h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important; }

.width-370,
.w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important; }

.height-375,
.h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important; }

.width-375,
.w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important; }

.height-380,
.h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important; }

.width-380,
.w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important; }

.height-385,
.h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important; }

.width-385,
.w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important; }

.height-390,
.h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important; }

.width-390,
.w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important; }

.height-395,
.h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important; }

.width-395,
.w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important; }

.height-400,
.h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important; }

.width-400,
.w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important; }

.height-405,
.h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important; }

.width-405,
.w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important; }

.height-410,
.h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important; }

.width-410,
.w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important; }

.height-415,
.h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important; }

.width-415,
.w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important; }

.height-420,
.h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important; }

.width-420,
.w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important; }

.height-425,
.h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important; }

.width-425,
.w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important; }

.height-430,
.h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important; }

.width-430,
.w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important; }

.height-435,
.h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important; }

.width-435,
.w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important; }

.height-440,
.h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important; }

.width-440,
.w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important; }

.height-445,
.h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important; }

.width-445,
.w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important; }

.height-450,
.h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important; }

.width-450,
.w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important; }

.height-455,
.h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important; }

.width-455,
.w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important; }

.height-460,
.h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important; }

.width-460,
.w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important; }

.height-465,
.h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important; }

.width-465,
.w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important; }

.height-470,
.h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important; }

.width-470,
.w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important; }

.height-475,
.h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important; }

.width-475,
.w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important; }

.height-480,
.h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important; }

.width-480,
.w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important; }

.height-485,
.h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important; }

.width-485,
.w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important; }

.height-490,
.h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important; }

.width-490,
.w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important; }

.height-495,
.h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important; }

.width-495,
.w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important; }

.height-500,
.h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important; }

.width-500,
.w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important; }

.height-auto {
  height: auto !important; }

.width-auto {
  width: auto !important; }

.height-percent-0,
.h-percent-0 {
  height: 0% !important;
  min-height: 0% !important;
  max-height: 0% !important; }

.width-percent-0,
.w-percent-0 {
  width: 0% !important;
  min-width: 0% !important;
  max-width: 0% !important; }

.height-percent-1,
.h-percent-1 {
  height: 1% !important;
  min-height: 1% !important;
  max-height: 1% !important; }

.width-percent-1,
.w-percent-1 {
  width: 1% !important;
  min-width: 1% !important;
  max-width: 1% !important; }

.height-percent-2,
.h-percent-2 {
  height: 2% !important;
  min-height: 2% !important;
  max-height: 2% !important; }

.width-percent-2,
.w-percent-2 {
  width: 2% !important;
  min-width: 2% !important;
  max-width: 2% !important; }

.height-percent-3,
.h-percent-3 {
  height: 3% !important;
  min-height: 3% !important;
  max-height: 3% !important; }

.width-percent-3,
.w-percent-3 {
  width: 3% !important;
  min-width: 3% !important;
  max-width: 3% !important; }

.height-percent-4,
.h-percent-4 {
  height: 4% !important;
  min-height: 4% !important;
  max-height: 4% !important; }

.width-percent-4,
.w-percent-4 {
  width: 4% !important;
  min-width: 4% !important;
  max-width: 4% !important; }

.height-percent-5,
.h-percent-5 {
  height: 5% !important;
  min-height: 5% !important;
  max-height: 5% !important; }

.width-percent-5,
.w-percent-5 {
  width: 5% !important;
  min-width: 5% !important;
  max-width: 5% !important; }

.height-percent-6,
.h-percent-6 {
  height: 6% !important;
  min-height: 6% !important;
  max-height: 6% !important; }

.width-percent-6,
.w-percent-6 {
  width: 6% !important;
  min-width: 6% !important;
  max-width: 6% !important; }

.height-percent-7,
.h-percent-7 {
  height: 7% !important;
  min-height: 7% !important;
  max-height: 7% !important; }

.width-percent-7,
.w-percent-7 {
  width: 7% !important;
  min-width: 7% !important;
  max-width: 7% !important; }

.height-percent-8,
.h-percent-8 {
  height: 8% !important;
  min-height: 8% !important;
  max-height: 8% !important; }

.width-percent-8,
.w-percent-8 {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important; }

.height-percent-9,
.h-percent-9 {
  height: 9% !important;
  min-height: 9% !important;
  max-height: 9% !important; }

.width-percent-9,
.w-percent-9 {
  width: 9% !important;
  min-width: 9% !important;
  max-width: 9% !important; }

.height-percent-10,
.h-percent-10 {
  height: 10% !important;
  min-height: 10% !important;
  max-height: 10% !important; }

.width-percent-10,
.w-percent-10 {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important; }

.height-percent-11,
.h-percent-11 {
  height: 11% !important;
  min-height: 11% !important;
  max-height: 11% !important; }

.width-percent-11,
.w-percent-11 {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important; }

.height-percent-12,
.h-percent-12 {
  height: 12% !important;
  min-height: 12% !important;
  max-height: 12% !important; }

.width-percent-12,
.w-percent-12 {
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important; }

.height-percent-13,
.h-percent-13 {
  height: 13% !important;
  min-height: 13% !important;
  max-height: 13% !important; }

.width-percent-13,
.w-percent-13 {
  width: 13% !important;
  min-width: 13% !important;
  max-width: 13% !important; }

.height-percent-14,
.h-percent-14 {
  height: 14% !important;
  min-height: 14% !important;
  max-height: 14% !important; }

.width-percent-14,
.w-percent-14 {
  width: 14% !important;
  min-width: 14% !important;
  max-width: 14% !important; }

.height-percent-15,
.h-percent-15 {
  height: 15% !important;
  min-height: 15% !important;
  max-height: 15% !important; }

.width-percent-15,
.w-percent-15 {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important; }

.height-percent-16,
.h-percent-16 {
  height: 16% !important;
  min-height: 16% !important;
  max-height: 16% !important; }

.width-percent-16,
.w-percent-16 {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important; }

.height-percent-17,
.h-percent-17 {
  height: 17% !important;
  min-height: 17% !important;
  max-height: 17% !important; }

.width-percent-17,
.w-percent-17 {
  width: 17% !important;
  min-width: 17% !important;
  max-width: 17% !important; }

.height-percent-18,
.h-percent-18 {
  height: 18% !important;
  min-height: 18% !important;
  max-height: 18% !important; }

.width-percent-18,
.w-percent-18 {
  width: 18% !important;
  min-width: 18% !important;
  max-width: 18% !important; }

.height-percent-19,
.h-percent-19 {
  height: 19% !important;
  min-height: 19% !important;
  max-height: 19% !important; }

.width-percent-19,
.w-percent-19 {
  width: 19% !important;
  min-width: 19% !important;
  max-width: 19% !important; }

.height-percent-20,
.h-percent-20 {
  height: 20% !important;
  min-height: 20% !important;
  max-height: 20% !important; }

.width-percent-20,
.w-percent-20 {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important; }

.height-percent-21,
.h-percent-21 {
  height: 21% !important;
  min-height: 21% !important;
  max-height: 21% !important; }

.width-percent-21,
.w-percent-21 {
  width: 21% !important;
  min-width: 21% !important;
  max-width: 21% !important; }

.height-percent-22,
.h-percent-22 {
  height: 22% !important;
  min-height: 22% !important;
  max-height: 22% !important; }

.width-percent-22,
.w-percent-22 {
  width: 22% !important;
  min-width: 22% !important;
  max-width: 22% !important; }

.height-percent-23,
.h-percent-23 {
  height: 23% !important;
  min-height: 23% !important;
  max-height: 23% !important; }

.width-percent-23,
.w-percent-23 {
  width: 23% !important;
  min-width: 23% !important;
  max-width: 23% !important; }

.height-percent-24,
.h-percent-24 {
  height: 24% !important;
  min-height: 24% !important;
  max-height: 24% !important; }

.width-percent-24,
.w-percent-24 {
  width: 24% !important;
  min-width: 24% !important;
  max-width: 24% !important; }

.height-percent-25,
.h-percent-25 {
  height: 25% !important;
  min-height: 25% !important;
  max-height: 25% !important; }

.width-percent-25,
.w-percent-25 {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important; }

.height-percent-26,
.h-percent-26 {
  height: 26% !important;
  min-height: 26% !important;
  max-height: 26% !important; }

.width-percent-26,
.w-percent-26 {
  width: 26% !important;
  min-width: 26% !important;
  max-width: 26% !important; }

.height-percent-27,
.h-percent-27 {
  height: 27% !important;
  min-height: 27% !important;
  max-height: 27% !important; }

.width-percent-27,
.w-percent-27 {
  width: 27% !important;
  min-width: 27% !important;
  max-width: 27% !important; }

.height-percent-28,
.h-percent-28 {
  height: 28% !important;
  min-height: 28% !important;
  max-height: 28% !important; }

.width-percent-28,
.w-percent-28 {
  width: 28% !important;
  min-width: 28% !important;
  max-width: 28% !important; }

.height-percent-29,
.h-percent-29 {
  height: 29% !important;
  min-height: 29% !important;
  max-height: 29% !important; }

.width-percent-29,
.w-percent-29 {
  width: 29% !important;
  min-width: 29% !important;
  max-width: 29% !important; }

.height-percent-30,
.h-percent-30 {
  height: 30% !important;
  min-height: 30% !important;
  max-height: 30% !important; }

.width-percent-30,
.w-percent-30 {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important; }

.height-percent-31,
.h-percent-31 {
  height: 31% !important;
  min-height: 31% !important;
  max-height: 31% !important; }

.width-percent-31,
.w-percent-31 {
  width: 31% !important;
  min-width: 31% !important;
  max-width: 31% !important; }

.height-percent-32,
.h-percent-32 {
  height: 32% !important;
  min-height: 32% !important;
  max-height: 32% !important; }

.width-percent-32,
.w-percent-32 {
  width: 32% !important;
  min-width: 32% !important;
  max-width: 32% !important; }

.height-percent-33,
.h-percent-33 {
  height: 33% !important;
  min-height: 33% !important;
  max-height: 33% !important; }

.width-percent-33,
.w-percent-33 {
  width: 33% !important;
  min-width: 33% !important;
  max-width: 33% !important; }

.height-percent-34,
.h-percent-34 {
  height: 34% !important;
  min-height: 34% !important;
  max-height: 34% !important; }

.width-percent-34,
.w-percent-34 {
  width: 34% !important;
  min-width: 34% !important;
  max-width: 34% !important; }

.height-percent-35,
.h-percent-35 {
  height: 35% !important;
  min-height: 35% !important;
  max-height: 35% !important; }

.width-percent-35,
.w-percent-35 {
  width: 35% !important;
  min-width: 35% !important;
  max-width: 35% !important; }

.height-percent-36,
.h-percent-36 {
  height: 36% !important;
  min-height: 36% !important;
  max-height: 36% !important; }

.width-percent-36,
.w-percent-36 {
  width: 36% !important;
  min-width: 36% !important;
  max-width: 36% !important; }

.height-percent-37,
.h-percent-37 {
  height: 37% !important;
  min-height: 37% !important;
  max-height: 37% !important; }

.width-percent-37,
.w-percent-37 {
  width: 37% !important;
  min-width: 37% !important;
  max-width: 37% !important; }

.height-percent-38,
.h-percent-38 {
  height: 38% !important;
  min-height: 38% !important;
  max-height: 38% !important; }

.width-percent-38,
.w-percent-38 {
  width: 38% !important;
  min-width: 38% !important;
  max-width: 38% !important; }

.height-percent-39,
.h-percent-39 {
  height: 39% !important;
  min-height: 39% !important;
  max-height: 39% !important; }

.width-percent-39,
.w-percent-39 {
  width: 39% !important;
  min-width: 39% !important;
  max-width: 39% !important; }

.height-percent-40,
.h-percent-40 {
  height: 40% !important;
  min-height: 40% !important;
  max-height: 40% !important; }

.width-percent-40,
.w-percent-40 {
  width: 40% !important;
  min-width: 40% !important;
  max-width: 40% !important; }

.height-percent-41,
.h-percent-41 {
  height: 41% !important;
  min-height: 41% !important;
  max-height: 41% !important; }

.width-percent-41,
.w-percent-41 {
  width: 41% !important;
  min-width: 41% !important;
  max-width: 41% !important; }

.height-percent-42,
.h-percent-42 {
  height: 42% !important;
  min-height: 42% !important;
  max-height: 42% !important; }

.width-percent-42,
.w-percent-42 {
  width: 42% !important;
  min-width: 42% !important;
  max-width: 42% !important; }

.height-percent-43,
.h-percent-43 {
  height: 43% !important;
  min-height: 43% !important;
  max-height: 43% !important; }

.width-percent-43,
.w-percent-43 {
  width: 43% !important;
  min-width: 43% !important;
  max-width: 43% !important; }

.height-percent-44,
.h-percent-44 {
  height: 44% !important;
  min-height: 44% !important;
  max-height: 44% !important; }

.width-percent-44,
.w-percent-44 {
  width: 44% !important;
  min-width: 44% !important;
  max-width: 44% !important; }

.height-percent-45,
.h-percent-45 {
  height: 45% !important;
  min-height: 45% !important;
  max-height: 45% !important; }

.width-percent-45,
.w-percent-45 {
  width: 45% !important;
  min-width: 45% !important;
  max-width: 45% !important; }

.height-percent-46,
.h-percent-46 {
  height: 46% !important;
  min-height: 46% !important;
  max-height: 46% !important; }

.width-percent-46,
.w-percent-46 {
  width: 46% !important;
  min-width: 46% !important;
  max-width: 46% !important; }

.height-percent-47,
.h-percent-47 {
  height: 47% !important;
  min-height: 47% !important;
  max-height: 47% !important; }

.width-percent-47,
.w-percent-47 {
  width: 47% !important;
  min-width: 47% !important;
  max-width: 47% !important; }

.height-percent-48,
.h-percent-48 {
  height: 48% !important;
  min-height: 48% !important;
  max-height: 48% !important; }

.width-percent-48,
.w-percent-48 {
  width: 48% !important;
  min-width: 48% !important;
  max-width: 48% !important; }

.height-percent-49,
.h-percent-49 {
  height: 49% !important;
  min-height: 49% !important;
  max-height: 49% !important; }

.width-percent-49,
.w-percent-49 {
  width: 49% !important;
  min-width: 49% !important;
  max-width: 49% !important; }

.height-percent-50,
.h-percent-50 {
  height: 50% !important;
  min-height: 50% !important;
  max-height: 50% !important; }

.width-percent-50,
.w-percent-50 {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important; }

.height-percent-51,
.h-percent-51 {
  height: 51% !important;
  min-height: 51% !important;
  max-height: 51% !important; }

.width-percent-51,
.w-percent-51 {
  width: 51% !important;
  min-width: 51% !important;
  max-width: 51% !important; }

.height-percent-52,
.h-percent-52 {
  height: 52% !important;
  min-height: 52% !important;
  max-height: 52% !important; }

.width-percent-52,
.w-percent-52 {
  width: 52% !important;
  min-width: 52% !important;
  max-width: 52% !important; }

.height-percent-53,
.h-percent-53 {
  height: 53% !important;
  min-height: 53% !important;
  max-height: 53% !important; }

.width-percent-53,
.w-percent-53 {
  width: 53% !important;
  min-width: 53% !important;
  max-width: 53% !important; }

.height-percent-54,
.h-percent-54 {
  height: 54% !important;
  min-height: 54% !important;
  max-height: 54% !important; }

.width-percent-54,
.w-percent-54 {
  width: 54% !important;
  min-width: 54% !important;
  max-width: 54% !important; }

.height-percent-55,
.h-percent-55 {
  height: 55% !important;
  min-height: 55% !important;
  max-height: 55% !important; }

.width-percent-55,
.w-percent-55 {
  width: 55% !important;
  min-width: 55% !important;
  max-width: 55% !important; }

.height-percent-56,
.h-percent-56 {
  height: 56% !important;
  min-height: 56% !important;
  max-height: 56% !important; }

.width-percent-56,
.w-percent-56 {
  width: 56% !important;
  min-width: 56% !important;
  max-width: 56% !important; }

.height-percent-57,
.h-percent-57 {
  height: 57% !important;
  min-height: 57% !important;
  max-height: 57% !important; }

.width-percent-57,
.w-percent-57 {
  width: 57% !important;
  min-width: 57% !important;
  max-width: 57% !important; }

.height-percent-58,
.h-percent-58 {
  height: 58% !important;
  min-height: 58% !important;
  max-height: 58% !important; }

.width-percent-58,
.w-percent-58 {
  width: 58% !important;
  min-width: 58% !important;
  max-width: 58% !important; }

.height-percent-59,
.h-percent-59 {
  height: 59% !important;
  min-height: 59% !important;
  max-height: 59% !important; }

.width-percent-59,
.w-percent-59 {
  width: 59% !important;
  min-width: 59% !important;
  max-width: 59% !important; }

.height-percent-60,
.h-percent-60 {
  height: 60% !important;
  min-height: 60% !important;
  max-height: 60% !important; }

.width-percent-60,
.w-percent-60 {
  width: 60% !important;
  min-width: 60% !important;
  max-width: 60% !important; }

.height-percent-61,
.h-percent-61 {
  height: 61% !important;
  min-height: 61% !important;
  max-height: 61% !important; }

.width-percent-61,
.w-percent-61 {
  width: 61% !important;
  min-width: 61% !important;
  max-width: 61% !important; }

.height-percent-62,
.h-percent-62 {
  height: 62% !important;
  min-height: 62% !important;
  max-height: 62% !important; }

.width-percent-62,
.w-percent-62 {
  width: 62% !important;
  min-width: 62% !important;
  max-width: 62% !important; }

.height-percent-63,
.h-percent-63 {
  height: 63% !important;
  min-height: 63% !important;
  max-height: 63% !important; }

.width-percent-63,
.w-percent-63 {
  width: 63% !important;
  min-width: 63% !important;
  max-width: 63% !important; }

.height-percent-64,
.h-percent-64 {
  height: 64% !important;
  min-height: 64% !important;
  max-height: 64% !important; }

.width-percent-64,
.w-percent-64 {
  width: 64% !important;
  min-width: 64% !important;
  max-width: 64% !important; }

.height-percent-65,
.h-percent-65 {
  height: 65% !important;
  min-height: 65% !important;
  max-height: 65% !important; }

.width-percent-65,
.w-percent-65 {
  width: 65% !important;
  min-width: 65% !important;
  max-width: 65% !important; }

.height-percent-66,
.h-percent-66 {
  height: 66% !important;
  min-height: 66% !important;
  max-height: 66% !important; }

.width-percent-66,
.w-percent-66 {
  width: 66% !important;
  min-width: 66% !important;
  max-width: 66% !important; }

.height-percent-67,
.h-percent-67 {
  height: 67% !important;
  min-height: 67% !important;
  max-height: 67% !important; }

.width-percent-67,
.w-percent-67 {
  width: 67% !important;
  min-width: 67% !important;
  max-width: 67% !important; }

.height-percent-68,
.h-percent-68 {
  height: 68% !important;
  min-height: 68% !important;
  max-height: 68% !important; }

.width-percent-68,
.w-percent-68 {
  width: 68% !important;
  min-width: 68% !important;
  max-width: 68% !important; }

.height-percent-69,
.h-percent-69 {
  height: 69% !important;
  min-height: 69% !important;
  max-height: 69% !important; }

.width-percent-69,
.w-percent-69 {
  width: 69% !important;
  min-width: 69% !important;
  max-width: 69% !important; }

.height-percent-70,
.h-percent-70 {
  height: 70% !important;
  min-height: 70% !important;
  max-height: 70% !important; }

.width-percent-70,
.w-percent-70 {
  width: 70% !important;
  min-width: 70% !important;
  max-width: 70% !important; }

.height-percent-71,
.h-percent-71 {
  height: 71% !important;
  min-height: 71% !important;
  max-height: 71% !important; }

.width-percent-71,
.w-percent-71 {
  width: 71% !important;
  min-width: 71% !important;
  max-width: 71% !important; }

.height-percent-72,
.h-percent-72 {
  height: 72% !important;
  min-height: 72% !important;
  max-height: 72% !important; }

.width-percent-72,
.w-percent-72 {
  width: 72% !important;
  min-width: 72% !important;
  max-width: 72% !important; }

.height-percent-73,
.h-percent-73 {
  height: 73% !important;
  min-height: 73% !important;
  max-height: 73% !important; }

.width-percent-73,
.w-percent-73 {
  width: 73% !important;
  min-width: 73% !important;
  max-width: 73% !important; }

.height-percent-74,
.h-percent-74 {
  height: 74% !important;
  min-height: 74% !important;
  max-height: 74% !important; }

.width-percent-74,
.w-percent-74 {
  width: 74% !important;
  min-width: 74% !important;
  max-width: 74% !important; }

.height-percent-75,
.h-percent-75 {
  height: 75% !important;
  min-height: 75% !important;
  max-height: 75% !important; }

.width-percent-75,
.w-percent-75 {
  width: 75% !important;
  min-width: 75% !important;
  max-width: 75% !important; }

.height-percent-76,
.h-percent-76 {
  height: 76% !important;
  min-height: 76% !important;
  max-height: 76% !important; }

.width-percent-76,
.w-percent-76 {
  width: 76% !important;
  min-width: 76% !important;
  max-width: 76% !important; }

.height-percent-77,
.h-percent-77 {
  height: 77% !important;
  min-height: 77% !important;
  max-height: 77% !important; }

.width-percent-77,
.w-percent-77 {
  width: 77% !important;
  min-width: 77% !important;
  max-width: 77% !important; }

.height-percent-78,
.h-percent-78 {
  height: 78% !important;
  min-height: 78% !important;
  max-height: 78% !important; }

.width-percent-78,
.w-percent-78 {
  width: 78% !important;
  min-width: 78% !important;
  max-width: 78% !important; }

.height-percent-79,
.h-percent-79 {
  height: 79% !important;
  min-height: 79% !important;
  max-height: 79% !important; }

.width-percent-79,
.w-percent-79 {
  width: 79% !important;
  min-width: 79% !important;
  max-width: 79% !important; }

.height-percent-80,
.h-percent-80 {
  height: 80% !important;
  min-height: 80% !important;
  max-height: 80% !important; }

.width-percent-80,
.w-percent-80 {
  width: 80% !important;
  min-width: 80% !important;
  max-width: 80% !important; }

.height-percent-81,
.h-percent-81 {
  height: 81% !important;
  min-height: 81% !important;
  max-height: 81% !important; }

.width-percent-81,
.w-percent-81 {
  width: 81% !important;
  min-width: 81% !important;
  max-width: 81% !important; }

.height-percent-82,
.h-percent-82 {
  height: 82% !important;
  min-height: 82% !important;
  max-height: 82% !important; }

.width-percent-82,
.w-percent-82 {
  width: 82% !important;
  min-width: 82% !important;
  max-width: 82% !important; }

.height-percent-83,
.h-percent-83 {
  height: 83% !important;
  min-height: 83% !important;
  max-height: 83% !important; }

.width-percent-83,
.w-percent-83 {
  width: 83% !important;
  min-width: 83% !important;
  max-width: 83% !important; }

.height-percent-84,
.h-percent-84 {
  height: 84% !important;
  min-height: 84% !important;
  max-height: 84% !important; }

.width-percent-84,
.w-percent-84 {
  width: 84% !important;
  min-width: 84% !important;
  max-width: 84% !important; }

.height-percent-85,
.h-percent-85 {
  height: 85% !important;
  min-height: 85% !important;
  max-height: 85% !important; }

.width-percent-85,
.w-percent-85 {
  width: 85% !important;
  min-width: 85% !important;
  max-width: 85% !important; }

.height-percent-86,
.h-percent-86 {
  height: 86% !important;
  min-height: 86% !important;
  max-height: 86% !important; }

.width-percent-86,
.w-percent-86 {
  width: 86% !important;
  min-width: 86% !important;
  max-width: 86% !important; }

.height-percent-87,
.h-percent-87 {
  height: 87% !important;
  min-height: 87% !important;
  max-height: 87% !important; }

.width-percent-87,
.w-percent-87 {
  width: 87% !important;
  min-width: 87% !important;
  max-width: 87% !important; }

.height-percent-88,
.h-percent-88 {
  height: 88% !important;
  min-height: 88% !important;
  max-height: 88% !important; }

.width-percent-88,
.w-percent-88 {
  width: 88% !important;
  min-width: 88% !important;
  max-width: 88% !important; }

.height-percent-89,
.h-percent-89 {
  height: 89% !important;
  min-height: 89% !important;
  max-height: 89% !important; }

.width-percent-89,
.w-percent-89 {
  width: 89% !important;
  min-width: 89% !important;
  max-width: 89% !important; }

.height-percent-90,
.h-percent-90 {
  height: 90% !important;
  min-height: 90% !important;
  max-height: 90% !important; }

.width-percent-90,
.w-percent-90 {
  width: 90% !important;
  min-width: 90% !important;
  max-width: 90% !important; }

.height-percent-91,
.h-percent-91 {
  height: 91% !important;
  min-height: 91% !important;
  max-height: 91% !important; }

.width-percent-91,
.w-percent-91 {
  width: 91% !important;
  min-width: 91% !important;
  max-width: 91% !important; }

.height-percent-92,
.h-percent-92 {
  height: 92% !important;
  min-height: 92% !important;
  max-height: 92% !important; }

.width-percent-92,
.w-percent-92 {
  width: 92% !important;
  min-width: 92% !important;
  max-width: 92% !important; }

.height-percent-93,
.h-percent-93 {
  height: 93% !important;
  min-height: 93% !important;
  max-height: 93% !important; }

.width-percent-93,
.w-percent-93 {
  width: 93% !important;
  min-width: 93% !important;
  max-width: 93% !important; }

.height-percent-94,
.h-percent-94 {
  height: 94% !important;
  min-height: 94% !important;
  max-height: 94% !important; }

.width-percent-94,
.w-percent-94 {
  width: 94% !important;
  min-width: 94% !important;
  max-width: 94% !important; }

.height-percent-95,
.h-percent-95 {
  height: 95% !important;
  min-height: 95% !important;
  max-height: 95% !important; }

.width-percent-95,
.w-percent-95 {
  width: 95% !important;
  min-width: 95% !important;
  max-width: 95% !important; }

.height-percent-96,
.h-percent-96 {
  height: 96% !important;
  min-height: 96% !important;
  max-height: 96% !important; }

.width-percent-96,
.w-percent-96 {
  width: 96% !important;
  min-width: 96% !important;
  max-width: 96% !important; }

.height-percent-97,
.h-percent-97 {
  height: 97% !important;
  min-height: 97% !important;
  max-height: 97% !important; }

.width-percent-97,
.w-percent-97 {
  width: 97% !important;
  min-width: 97% !important;
  max-width: 97% !important; }

.height-percent-98,
.h-percent-98 {
  height: 98% !important;
  min-height: 98% !important;
  max-height: 98% !important; }

.width-percent-98,
.w-percent-98 {
  width: 98% !important;
  min-width: 98% !important;
  max-width: 98% !important; }

.height-percent-99,
.h-percent-99 {
  height: 99% !important;
  min-height: 99% !important;
  max-height: 99% !important; }

.width-percent-99,
.w-percent-99 {
  width: 99% !important;
  min-width: 99% !important;
  max-width: 99% !important; }

.height-percent-100,
.h-percent-100 {
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important; }

.width-percent-100,
.w-percent-100 {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important; }

.height-8,
.h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important; }

.width-8,
.w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important; }

.height-16,
.h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important; }

.width-16,
.w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important; }

.height-24,
.h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important; }

.width-24,
.w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important; }

.height-32,
.h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important; }

.width-32,
.w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important; }

.height-48,
.h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important; }

.width-48,
.w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important; }

.height-56,
.h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important; }

.width-56,
.w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important; }

.height-64,
.h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important; }

.width-64,
.w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important; }

.height-72,
.h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important; }

.width-72,
.w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important; }

.height-88,
.h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important; }

.width-88,
.w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important; }

.height-96,
.h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important; }

.width-96,
.w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important; }

.height-104,
.h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important; }

.width-104,
.w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important; }

.height-112,
.h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important; }

.width-112,
.w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important; }

.height-128,
.h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important; }

.width-128,
.w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important; }

.height-136,
.h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important; }

.width-136,
.w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important; }

.height-144,
.h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important; }

.width-144,
.w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important; }

.height-152,
.h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important; }

.width-152,
.w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important; }

.height-168,
.h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important; }

.width-168,
.w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important; }

.height-176,
.h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important; }

.width-176,
.w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important; }

.height-184,
.h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important; }

.width-184,
.w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important; }

.height-192,
.h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important; }

.width-192,
.w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important; }

.height-208,
.h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important; }

.width-208,
.w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important; }

.height-216,
.h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important; }

.width-216,
.w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important; }

.height-224,
.h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important; }

.width-224,
.w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important; }

.height-232,
.h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important; }

.width-232,
.w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important; }

.height-248,
.h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important; }

.width-248,
.w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important; }

.height-256,
.h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important; }

.width-256,
.w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important; }

.height-264,
.h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important; }

.width-264,
.w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important; }

.height-272,
.h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important; }

.width-272,
.w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important; }

.height-288,
.h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important; }

.width-288,
.w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important; }

.height-296,
.h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important; }

.width-296,
.w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important; }

.height-304,
.h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important; }

.width-304,
.w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important; }

.height-312,
.h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important; }

.width-312,
.w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important; }

.height-328,
.h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important; }

.width-328,
.w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important; }

.height-336,
.h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important; }

.width-336,
.w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important; }

.height-344,
.h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important; }

.width-344,
.w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important; }

.height-352,
.h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important; }

.width-352,
.w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important; }

.height-368,
.h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important; }

.width-368,
.w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important; }

.height-376,
.h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important; }

.width-376,
.w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important; }

.height-384,
.h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important; }

.width-384,
.w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important; }

.height-392,
.h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important; }

.width-392,
.w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important; }

.height-408,
.h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important; }

.width-408,
.w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important; }

.height-416,
.h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important; }

.width-416,
.w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important; }

.height-424,
.h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important; }

.width-424,
.w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important; }

.height-432,
.h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important; }

.width-432,
.w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important; }

.height-448,
.h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important; }

.width-448,
.w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important; }

.height-456,
.h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important; }

.width-456,
.w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important; }

.height-464,
.h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important; }

.width-464,
.w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important; }

.height-472,
.h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important; }

.width-472,
.w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important; }

.height-488,
.h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important; }

.width-488,
.w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important; }

.height-496,
.h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important; }

.width-496,
.w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important; }

.height-504,
.h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important; }

.width-504,
.w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important; }

.height-512,
.h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important; }

.width-512,
.w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important; }

.height-528,
.h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important; }

.width-528,
.w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important; }

.height-536,
.h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important; }

.width-536,
.w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important; }

.height-544,
.h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important; }

.width-544,
.w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important; }

.height-552,
.h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important; }

.width-552,
.w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important; }

.height-568,
.h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important; }

.width-568,
.w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important; }

.height-576,
.h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important; }

.width-576,
.w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important; }

.height-584,
.h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important; }

.width-584,
.w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important; }

.height-592,
.h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important; }

.width-592,
.w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important; }

.height-608,
.h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important; }

.width-608,
.w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important; }

.height-616,
.h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important; }

.width-616,
.w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important; }

.height-624,
.h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important; }

.width-624,
.w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important; }

.height-632,
.h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important; }

.width-632,
.w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important; }

.height-648,
.h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important; }

.width-648,
.w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important; }

.height-656,
.h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important; }

.width-656,
.w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important; }

.height-664,
.h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important; }

.width-664,
.w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important; }

.height-672,
.h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important; }

.width-672,
.w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important; }

.height-688,
.h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important; }

.width-688,
.w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important; }

.height-696,
.h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important; }

.width-696,
.w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important; }

.height-704,
.h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important; }

.width-704,
.w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important; }

.height-712,
.h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important; }

.width-712,
.w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important; }

.height-728,
.h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important; }

.width-728,
.w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important; }

.height-736,
.h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important; }

.width-736,
.w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important; }

.height-744,
.h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important; }

.width-744,
.w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important; }

.height-752,
.h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important; }

.width-752,
.w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important; }

.height-768,
.h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important; }

.width-768,
.w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important; }

.height-776,
.h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important; }

.width-776,
.w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important; }

.height-784,
.h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important; }

.width-784,
.w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important; }

.height-792,
.h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important; }

.width-792,
.w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important; }

.opacity-0-0 {
  opacity: 0 !important; }

.opacity-0-1 {
  opacity: 0.1 !important; }

.opacity-0-2 {
  opacity: 0.2 !important; }

.opacity-0-3 {
  opacity: 0.3 !important; }

.opacity-0-4 {
  opacity: 0.4 !important; }

.opacity-0-5 {
  opacity: 0.5 !important; }

.opacity-0-6 {
  opacity: 0.6 !important; }

.opacity-0-7 {
  opacity: 0.7 !important; }

.opacity-0-8 {
  opacity: 0.8 !important; }

.opacity-0-9 {
  opacity: 0.9 !important; }

.opacity-0-10 {
  opacity: 1 !important; }

.border,
.b {
  border: 1px solid rgba(0, 0, 0, 0.12); }

.border-top,
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.border-right,
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-bottom,
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.border-left,
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.border-horizontal,
.bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-vertical,
.bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.brad-1 {
  border-radius: 1px; }

.brad-2 {
  border-radius: 2px; }

.brad-3 {
  border-radius: 3px; }

.brad-4 {
  border-radius: 4px; }

.brad-5 {
  border-radius: 5px; }

.brad-6 {
  border-radius: 6px; }

.brad-7 {
  border-radius: 7px; }

.brad-8 {
  border-radius: 8px; }

.brad-9 {
  border-radius: 9px; }

.brad-10 {
  border-radius: 10px; }

.brad-11 {
  border-radius: 11px; }

.brad-12 {
  border-radius: 12px; }

/*-----------------------------------------------------------------------*/
/* Contains custom elements, components and general styles that
/* follows Google(TM) Material Design(TM) specs
/*
/* https://www.google.com/design/spec/material-design/introduction.html
/*-----------------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Avatars
/*----------------------------------------------------------------*/
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF; }
  .avatar.square {
    border-radius: 0; }
  .avatar.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px; }
  .avatar.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px; }
  .avatar.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px; }

/*----------------------------------------------------------------*/
/*  Forms
/*----------------------------------------------------------------*/
.form-wrapper {
  background: #FFFFFF;
  padding: 16px; }
  .form-wrapper .form-title {
    font-size: 21px;
    padding: 8px 0; }

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px; }
  .navigation-simple .item md-icon {
    margin: 0 16px 0 0; }
  .navigation-simple .item .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 1; }
  .navigation-simple .item.selected {
    background-color: rgba(0, 0, 0, 0.06); }

.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px; }
  .navigation-simple .subheader.light {
    border-top: 1px solid rgba(255, 255, 255, 0.12); }

.navigation-simple md-divider {
  margin: 8px 0; }

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left; }
  table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    min-width: 120px; }
    table.simple thead tr th:first-child {
      padding-left: 24px; }
    table.simple thead tr th:last-child {
      padding-right: 24px; }
  table.simple tbody tr td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    table.simple tbody tr td:first-child {
      padding-left: 24px; }
    table.simple tbody tr td:last-child {
      padding-right: 24px; }
  table.simple tbody tr:last-child td {
    border-bottom: none; }
  table.simple.clickable tbody tr {
    cursor: pointer; }
    table.simple.clickable tbody tr:hover {
      background: rgba(0, 0, 0, 0.03); }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.md-datepicker-expand-triangle {
  width: auto !important;
  height: auto !important; }

* {
  padding: 0;
  margin: 0;
  outline: none; }

body {
  overflow-x: hidden; }

html,
body,
body [ui-view],
body div[ui-view] div.ng-scope {
  width: 100%;
  height: 100%; }

a {
  text-decoration: none;
  cursor: pointer; }

.validation-messages {
  font-size: 12px;
  color: #dd2c00;
  margin: 10px 0 0 25px; }

.hint-messages {
  position: absolute;
  left: 2px;
  right: auto;
  bottom: 7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  color: grey; }

.gm-style a img {
  display: none; }

.dtp table.dtp-picker-days tr > td > a.selected,
.dtp .dtp-hand.on,
.dtp div.dtp-date,
.dtp div.dtp-time {
  background: #3F51B5; }

.dtp > .dtp-content > .dtp-date-view > header.dtp-header,
.dtp .dtp-picker-time > a.dtp-select-hour.selected,
.dtp .dtp-actual-meridien a.selected {
  background: #303F9F; }

.list-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .list-item .avatar {
    font-size: 14px; }
  .list-item .info {
    margin: 0 16px 0 8px; }
    .list-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .list-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .list-item .secondary-text md-icon {
    margin: 0; }
  .list-item.selected {
    background-color: rgba(0, 0, 0, 0.06); }

.empty-list {
  height: 100%; }

.hide-validation-error .md-errors-spacer {
  display: none; }

.div-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  opacity: .6;
  z-index: 9;
  background-color: white; }
  .div-loading .div-icon-loading {
    display: flex; }

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #fff; }

.div-floating-list-button {
  position: fixed;
  bottom: 12px;
  right: 16px; }
  .div-floating-list-button.dialog {
    bottom: 56px;
    right: 0;
    animation-name: floatingButtonDialogFirefoxFix;
    animation-duration: .1s; }

.md-button[disabled] {
  opacity: .4 !important; }

.date-container {
  padding: 16px 24px; }

.div-list-controls .order-by-directive {
  margin-right: 5px; }

md-dialog .md-title {
  line-height: 1.4em; }

.md-datepicker-input-container {
  margin-left: 0 !important;
  border-bottom-width: 0; }
  .md-datepicker-input-container.md-datepicker-focused {
    border-bottom-width: 0; }
  .md-datepicker-input-container .md-datepicker-input {
    border-bottom: none; }

md-toast.success-toast .md-toast-content {
  color: white; }

md-toast.error-toast .md-toast-content {
  color: white; }

.print-only {
  display: none !important; }

md-select-menu md-select-header {
  display: flex;
  width: auto;
  height: 48px;
  padding-left: 10.667px;
  cursor: pointer;
  position: relative;
  align-items: center;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12); }
  md-select-menu md-select-header > input {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    outline: none; }

md-select-menu md-optgroup {
  padding-top: 12px; }
  md-select-menu md-optgroup > label {
    text-transform: none;
    padding-top: 5px; }
  md-select-menu md-optgroup > span {
    padding: 12px;
    font-size: 14px; }

@keyframes floatingButtonDialogFirefoxFix {
  from {
    position: absolute; }
  to {
    position: fixed; } }

@media screen and (min-width: 960px) {
  .div-list-controls {
    min-width: 350px;
    max-width: 350px; } }

@media screen and (max-width: 960px) {
  .div-list-controls {
    min-width: 150px;
    max-width: 150px; }
    .div-list-controls > * {
      max-height: 30px; }
    .div-list-controls .pagination-directive {
      margin-top: 5px; }
    .div-list-controls .order-by-directive md-input-container {
      margin: 0; }
      .div-list-controls .order-by-directive md-input-container label {
        display: none; } }

@media screen and (max-width: 600px) {
  .md-dialog-container {
    height: 100% !important; }
  md-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important; }
    md-dialog form,
    md-dialog md-dialog-content {
      height: 100% !important; } }

@media print {
  .no-printable {
    display: none !important; }
  .print-only {
    display: block !important; } }

/* Do not remove the comments below. It's the markers used by gulp-inject to inject
all your sass files automatically */
.cluster0 {
  text-align: center;
  z-index: 9999; }

.marker-cluster {
  cursor: pointer;
  font-size: 12px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  background-position: 0 0;
  color: black; }
  .marker-cluster.out {
    background-image: url("../assets/img/map/out_icon.png");
    position: absolute;
    height: 19px;
    width: 45px;
    padding-top: 19px; }
    .marker-cluster.out:after {
      content: ' ';
      padding-left: 5px; }
  .marker-cluster.in-occurrence {
    background-image: url("../assets/img/map/in_alert_icon.png");
    position: absolute;
    height: 20px;
    width: 48px;
    padding-top: 5px; }
    .marker-cluster.in-occurrence:after {
      content: ' ';
      padding-left: 10px; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.20-master-595fefe
 */
html, body {
  height: 100%;
  position: relative; }

body {
  margin: 0;
  padding: 0; }

[tabindex='-1']:focus {
  outline: none; }

.inset {
  padding: 10px; }

a.md-no-style,
button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0; }

select,
button,
textarea,
input {
  vertical-align: baseline; }

input[type="reset"],
input[type="submit"],
html input[type="button"],
button {
  cursor: pointer;
  -webkit-appearance: button; }
  input[type="reset"][disabled],
  input[type="submit"][disabled],
  html input[type="button"][disabled],
  button[disabled] {
    cursor: default; }

textarea {
  vertical-align: top;
  overflow: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box; }
  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }

input:-webkit-autofill {
  text-shadow: none; }

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px; }

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none; }

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%; }
  .md-ripple.md-ripple-placed {
    transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-ripple.md-ripple-scaled {
    transform: translate(-50%, -50%) scale(1); }
  .md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
    opacity: 0.20; }
  .md-ripple.md-ripple-remove {
    animation: md-remove-ripple 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }

@keyframes md-remove-ripple {
  0% {
    opacity: .15; }
  100% {
    opacity: 0; } }

.md-padding {
  padding: 8px; }

.md-margin {
  margin: 8px; }

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50; }
  .md-scroll-mask > .md-scroll-mask-bar {
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); }

.md-no-momentum {
  -webkit-overflow-scrolling: auto; }

.md-no-flicker {
  -webkit-filter: blur(0px); }

@media (min-width: 960px) {
  .md-padding {
    padding: 16px; } }

html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed; }

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override; }

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override; }

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px; }

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px; }

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px; }

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.md-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em; }

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px; }

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px; }

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px; }

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em; }

.md-button {
  letter-spacing: 0.010em; }

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

select,
button,
textarea,
input {
  font-size: 100%; }

/*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*/
.md-panel-outer-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

._md-panel-hidden {
  display: none; }

._md-panel-offscreen {
  left: -9999px; }

._md-panel-fullscreen {
  border-radius: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0; }

._md-panel-shown .md-panel {
  opacity: 1;
  transition: none; }

.md-panel {
  opacity: 0;
  position: fixed; }
  .md-panel._md-panel-shown {
    opacity: 1;
    transition: none; }
  .md-panel._md-panel-animate-enter {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .md-panel._md-panel-animate-leave {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1); }
  .md-panel._md-panel-animate-scale-out, .md-panel._md-panel-animate-fade-out {
    opacity: 0; }
  .md-panel._md-panel-backdrop {
    height: 100%;
    position: absolute;
    width: 100%; }
  .md-panel._md-opaque-enter {
    opacity: .48;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .md-panel._md-opaque-leave {
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1); }

md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px; }
  md-autocomplete[disabled] input {
    cursor: default; }
  md-autocomplete[md-floating-label] {
    border-radius: 0;
    background: transparent;
    height: auto; }
    md-autocomplete[md-floating-label] md-input-container {
      padding-bottom: 0; }
    md-autocomplete[md-floating-label] md-autocomplete-wrap {
      height: auto; }
    md-autocomplete[md-floating-label] .md-show-clear-button button {
      display: block;
      position: absolute;
      right: 0;
      top: 20px;
      width: 30px;
      height: 30px; }
    md-autocomplete[md-floating-label] .md-show-clear-button input {
      padding-right: 30px; }
      [dir=rtl] md-autocomplete[md-floating-label] .md-show-clear-button input {
        padding-right: 0;
        padding-left: 30px; }
  md-autocomplete md-autocomplete-wrap {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    overflow: visible;
    height: 40px; }
    md-autocomplete md-autocomplete-wrap.md-menu-showing {
      z-index: 51; }
    md-autocomplete md-autocomplete-wrap md-input-container,
    md-autocomplete md-autocomplete-wrap input {
      flex: 1 1 0;
      box-sizing: border-box;
      min-width: 0; }
    md-autocomplete md-autocomplete-wrap md-progress-linear {
      position: absolute;
      bottom: -2px;
      left: 0; }
      md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
        bottom: 40px;
        right: 2px;
        left: 2px;
        width: auto; }
      md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        transition: none; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate .md-container {
          transition: none;
          height: 3px; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter.ng-enter-active {
            opacity: 1; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave.ng-leave-active {
            opacity: 0; }
  md-autocomplete input:not(.md-input) {
    font-size: 14px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px; }
    md-autocomplete input:not(.md-input)::-ms-clear {
      display: none; }
  md-autocomplete .md-show-clear-button button {
    position: relative;
    line-height: 20px;
    text-align: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    padding: 0;
    font-size: 12px;
    background: transparent;
    margin: auto 5px; }
    md-autocomplete .md-show-clear-button button:after {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-autocomplete .md-show-clear-button button:focus {
      outline: none; }
      md-autocomplete .md-show-clear-button button:focus:after {
        transform: scale(1);
        opacity: 1; }
    md-autocomplete .md-show-clear-button button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.9); }
      md-autocomplete .md-show-clear-button button md-icon path {
        stroke-width: 0; }
    md-autocomplete .md-show-clear-button button.ng-enter {
      transform: scale(0);
      transition: transform 0.15s ease-out; }
      md-autocomplete .md-show-clear-button button.ng-enter.ng-enter-active {
        transform: scale(1); }
    md-autocomplete .md-show-clear-button button.ng-leave {
      transition: transform 0.15s ease-out; }
      md-autocomplete .md-show-clear-button button.ng-leave.ng-leave-active {
        transform: scale(0); }
  @media screen and (-ms-high-contrast: active) {
    md-autocomplete input {
      border: 1px solid #fff; }
    md-autocomplete .md-autocomplete-suggestion:focus {
      color: #fff; } }

.md-virtual-repeat-container.md-autocomplete-suggestions-container,
.md-standard-list-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
  height: 100%; }
  .md-virtual-repeat-container.md-autocomplete-suggestions-container .highlight,
  .md-standard-list-container.md-autocomplete-suggestions-container .highlight {
    font-weight: bold; }

.md-standard-list-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 0; }

.md-virtual-repeat-container.md-not-found,
.md-standard-list-container.md-not-found {
  height: 48px; }

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0; }
  .md-autocomplete-suggestions .md-autocomplete-suggestion {
    font-size: 14px;
    overflow: hidden;
    padding: 0 15px;
    line-height: 48px;
    height: 48px;
    transition: background 0.15s linear;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .md-autocomplete-suggestions .md-autocomplete-suggestion:focus {
      outline: none; }
    .md-autocomplete-suggestions .md-autocomplete-suggestion:not(.md-not-found-wrapper) {
      cursor: pointer; }

@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff; } }

md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50; }
  md-backdrop.md-menu-backdrop {
    position: fixed !important;
    z-index: 99; }
  md-backdrop.md-select-backdrop {
    z-index: 81;
    transition-duration: 0; }
  md-backdrop.md-dialog-backdrop {
    z-index: 79; }
  md-backdrop.md-bottom-sheet-backdrop {
    z-index: 69; }
  md-backdrop.md-sidenav-backdrop {
    z-index: 59; }
  md-backdrop.md-click-catcher {
    position: absolute; }
  md-backdrop.md-opaque {
    opacity: .48; }
    md-backdrop.md-opaque.ng-enter {
      opacity: 0; }
    md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
      opacity: .48; }
    md-backdrop.md-opaque.ng-leave {
      opacity: .48;
      transition: opacity 400ms; }
    md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
      opacity: 0; }

md-bottom-sheet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 16px 88px 16px;
  z-index: 70;
  border-top-width: 1px;
  border-top-style: solid;
  transform: translate3d(0, 80px, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform; }
  md-bottom-sheet.md-has-header {
    padding-top: 0; }
  md-bottom-sheet.ng-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-bottom-sheet.ng-enter-active {
    opacity: 1;
    display: block;
    transform: translate3d(0, 80px, 0) !important; }
  md-bottom-sheet.ng-leave-active {
    transform: translate3d(0, 100%, 0) !important;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-bottom-sheet .md-subheader {
    background-color: transparent;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    line-height: 56px;
    padding: 0;
    white-space: nowrap; }
  md-bottom-sheet md-inline-icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    fill: #444; }
  md-bottom-sheet md-list-item {
    display: flex;
    outline: none; }
    md-bottom-sheet md-list-item:hover {
      cursor: pointer; }
  md-bottom-sheet.md-list md-list-item {
    padding: 0;
    align-items: center;
    height: 48px; }
  md-bottom-sheet.md-grid {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0; }
    md-bottom-sheet.md-grid md-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      transition: all 0.5s;
      align-items: center; }
    md-bottom-sheet.md-grid md-list-item {
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;
      height: 96px;
      margin-top: 8px;
      margin-bottom: 8px;
      /* Mixin for how many grid items to show per row */ }
      @media (max-width: 960px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 33.33333%;
          max-width: 33.33333%; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n + 1) {
            align-items: flex-start; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n) {
            align-items: flex-end; } }
      @media (min-width: 960px) and (max-width: 1279px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 25%;
          max-width: 25%; } }
      @media (min-width: 1280px) and (max-width: 1919px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 16.66667%;
          max-width: 16.66667%; } }
      @media (min-width: 1920px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 14.28571%;
          max-width: 14.28571%; } }
      md-bottom-sheet.md-grid md-list-item::before {
        display: none; }
      md-bottom-sheet.md-grid md-list-item .md-list-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48px;
        padding-bottom: 16px; }
      md-bottom-sheet.md-grid md-list-item .md-grid-item-content {
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px; }
      md-bottom-sheet.md-grid md-list-item .md-grid-text {
        font-weight: 400;
        line-height: 16px;
        font-size: 13px;
        margin: 0;
        white-space: nowrap;
        width: 64px;
        text-align: center;
        text-transform: none;
        padding-top: 8px; }

@media screen and (-ms-high-contrast: active) {
  md-bottom-sheet {
    border: 1px solid #fff; } }

button.md-button::-moz-focus-inner {
  border: 0; }

.md-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /** Alignment adjustments */
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  /* Reset default button appearance */
  user-select: none;
  outline: none;
  border: 0;
  /** Custom styling for button */
  padding: 0 6px;
  margin: 6px 8px;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  /* Uppercase text content */
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    min-height: 32px; }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    line-height: 32px; }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    font-size: 13px; }
  .md-button:focus {
    outline: none; }
  .md-button:hover, .md-button:focus {
    text-decoration: none; }
  .md-button.ng-hide, .md-button.ng-leave {
    transition: none; }
  .md-button.md-cornered {
    border-radius: 0; }
  .md-button.md-icon {
    padding: 0;
    background: none; }
  .md-button.md-raised:not([disabled]) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button.md-icon-button {
    margin: 0 6px;
    height: 40px;
    min-width: 0;
    line-height: 24px;
    padding: 8px;
    width: 40px;
    border-radius: 50%; }
  .md-button.md-fab {
    z-index: 20;
    line-height: 56px;
    min-width: 0;
    width: 56px;
    height: 56px;
    vertical-align: middle;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-property: background-color, box-shadow, transform; }
    .md-button.md-fab.md-fab-bottom-right {
      top: auto;
      right: 20px;
      bottom: 20px;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-bottom-left {
      top: auto;
      right: auto;
      bottom: 20px;
      left: 20px;
      position: absolute; }
    .md-button.md-fab.md-fab-top-right {
      top: 20px;
      right: 20px;
      bottom: auto;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-top-left {
      top: 20px;
      right: auto;
      bottom: auto;
      left: 20px;
      position: absolute; }
    .md-button.md-fab.md-mini {
      line-height: 40px;
      width: 40px;
      height: 40px; }
    .md-button.md-fab.ng-hide, .md-button.md-fab.ng-leave {
      transition: none; }
  .md-button:not([disabled]).md-raised.md-focused, .md-button:not([disabled]).md-fab.md-focused {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button:not([disabled]).md-raised:active, .md-button:not([disabled]).md-fab:active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }
  .md-button .md-ripple-container {
    border-radius: inherit;
    background-clip: padding-box;
    overflow: hidden;
    -webkit-transform: translateZ(0); }

.md-button.md-icon-button md-icon,
button.md-button.md-fab md-icon {
  display: block; }

.md-toast-open-top .md-button.md-fab-top-left,
.md-toast-open-top .md-button.md-fab-top-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, 42px, 0); }
  .md-toast-open-top .md-button.md-fab-top-left:not([disabled]).md-focused, .md-toast-open-top .md-button.md-fab-top-left:not([disabled]):hover,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]).md-focused,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]):hover {
    transform: translate3d(0, 41px, 0); }

.md-toast-open-bottom .md-button.md-fab-bottom-left,
.md-toast-open-bottom .md-button.md-fab-bottom-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, -42px, 0); }
  .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]).md-focused, .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]):hover,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]).md-focused,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]):hover {
    transform: translate3d(0, -43px, 0); }

.md-button-group {
  display: flex;
  flex: 1;
  width: 100%; }
  .md-button-group > .md-button {
    flex: 1;
    display: block;
    overflow: hidden;
    width: 0;
    border-width: 1px 0px 1px 1px;
    border-radius: 0;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .md-button-group > .md-button:first-child {
      border-radius: 2px 0px 0px 2px; }
    .md-button-group > .md-button:last-child {
      border-right-width: 1px;
      border-radius: 0px 2px 2px 0px; }

@media screen and (-ms-high-contrast: active) {
  .md-button.md-raised,
  .md-button.md-fab {
    border: 1px solid #fff; } }

md-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  md-card md-card-header {
    padding: 16px;
    display: flex;
    flex-direction: row; }
    md-card md-card-header:first-child md-card-avatar {
      margin-right: 12px; }
      [dir=rtl] md-card md-card-header:first-child md-card-avatar {
        margin-right: auto;
        margin-left: 12px; }
    md-card md-card-header:last-child md-card-avatar {
      margin-left: 12px; }
      [dir=rtl] md-card md-card-header:last-child md-card-avatar {
        margin-left: auto;
        margin-right: 12px; }
    md-card md-card-header md-card-avatar {
      width: 40px;
      height: 40px; }
      md-card md-card-header md-card-avatar .md-user-avatar,
      md-card md-card-header md-card-avatar md-icon {
        border-radius: 50%; }
      md-card md-card-header md-card-avatar md-icon {
        padding: 8px; }
        md-card md-card-header md-card-avatar md-icon > svg {
          height: inherit;
          width: inherit; }
      md-card md-card-header md-card-avatar + md-card-header-text {
        max-height: 40px; }
        md-card md-card-header md-card-avatar + md-card-header-text .md-title {
          font-size: 14px; }
    md-card md-card-header md-card-header-text {
      display: flex;
      flex: 1;
      flex-direction: column; }
      md-card md-card-header md-card-header-text .md-subhead {
        font-size: 14px; }
  md-card > img,
  md-card > md-card-header img,
  md-card md-card-title-media img {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    height: auto; }
  md-card md-card-title {
    padding: 24px 16px 16px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row; }
    md-card md-card-title + md-card-content {
      padding-top: 0; }
    md-card md-card-title md-card-title-text {
      flex: 1;
      flex-direction: column;
      display: flex; }
      md-card md-card-title md-card-title-text .md-subhead {
        padding-top: 0;
        font-size: 14px; }
      md-card md-card-title md-card-title-text:only-child .md-subhead {
        padding-top: 12px; }
    md-card md-card-title md-card-title-media {
      margin-top: -8px; }
      md-card md-card-title md-card-title-media .md-media-sm {
        height: 80px;
        width: 80px; }
      md-card md-card-title md-card-title-media .md-media-md {
        height: 112px;
        width: 112px; }
      md-card md-card-title md-card-title-media .md-media-lg {
        height: 152px;
        width: 152px; }
  md-card md-card-content {
    display: block;
    padding: 16px; }
    md-card md-card-content > p:first-child {
      margin-top: 0; }
    md-card md-card-content > p:last-child {
      margin-bottom: 0; }
    md-card md-card-content .md-media-xl {
      height: 240px;
      width: 240px; }
  md-card .md-actions, md-card md-card-actions {
    margin: 8px; }
    md-card .md-actions.layout-column .md-button:not(.md-icon-button), md-card md-card-actions.layout-column .md-button:not(.md-icon-button) {
      margin: 2px 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):first-of-type {
        margin-top: 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):last-of-type {
        margin-bottom: 0; }
    md-card .md-actions.layout-column .md-button.md-icon-button, md-card md-card-actions.layout-column .md-button.md-icon-button {
      margin-top: 6px;
      margin-bottom: 6px; }
    md-card .md-actions md-card-icon-actions, md-card md-card-actions md-card-icon-actions {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      flex-direction: row; }
    md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button), md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button) {
      margin: 0 4px; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
        margin-left: 0; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
          margin-left: auto;
          margin-right: 0; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
        margin-right: 0; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
          margin-right: auto;
          margin-left: 0; }
    md-card .md-actions:not(.layout-column) .md-button.md-icon-button, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button {
      margin-left: 6px;
      margin-right: 6px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
        margin-left: 12px; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
          margin-left: auto;
          margin-right: 12px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
        margin-right: 12px; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
          margin-right: auto;
          margin-left: 12px; }
    md-card .md-actions:not(.layout-column) .md-button + md-card-icon-actions, md-card md-card-actions:not(.layout-column) .md-button + md-card-icon-actions {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      flex-direction: row; }
  md-card md-card-footer {
    margin-top: auto;
    padding: 16px; }

@media screen and (-ms-high-contrast: active) {
  md-card {
    border: 1px solid #fff; } }

.md-image-no-fill > img {
  width: auto;
  height: auto; }

.md-inline-form md-checkbox {
  margin: 19px 0 18px; }

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-left: 0;
  margin-right: 16px; }
  [dir=rtl] md-checkbox {
    margin-left: 16px; }
  [dir=rtl] md-checkbox {
    margin-right: 0; }
  md-checkbox:last-of-type {
    margin-left: 0;
    margin-right: 0; }
  md-checkbox.md-focused:not([disabled]) .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
    background-color: rgba(0, 0, 0, 0.12); }
  md-checkbox.md-align-top-left > div.md-container {
    top: 12px; }
  md-checkbox .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto; }
    [dir=rtl] md-checkbox .md-container {
      left: auto; }
    [dir=rtl] md-checkbox .md-container {
      right: 0; }
    md-checkbox .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-checkbox .md-container:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
    md-checkbox .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
  md-checkbox .md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px; }
  md-checkbox.md-checked .md-icon {
    border-color: transparent; }
    md-checkbox.md-checked .md-icon:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      position: absolute;
      left: 4.66667px;
      top: 0.22222px;
      display: table;
      width: 6.66667px;
      height: 13.33333px;
      border-width: 2px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      content: ''; }
  md-checkbox[disabled] {
    cursor: default; }
  md-checkbox.md-indeterminate .md-icon:after {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: 12px;
    height: 2px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: ''; }
  md-checkbox .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    user-select: text;
    margin-left: 30px;
    margin-right: 0; }
    [dir=rtl] md-checkbox .md-label {
      margin-left: 0; }
    [dir=rtl] md-checkbox .md-label {
      margin-right: 30px; }

.md-contact-chips .md-chips md-chip {
  padding: 0 25px 0 0; }
  [dir=rtl] .md-contact-chips .md-chips md-chip {
    padding: 0 0 0 25px; }
  .md-contact-chips .md-chips md-chip .md-contact-avatar {
    float: left; }
    [dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-avatar {
      float: right; }
    .md-contact-chips .md-chips md-chip .md-contact-avatar img {
      height: 32px;
      border-radius: 16px; }
  .md-contact-chips .md-chips md-chip .md-contact-name {
    display: inline-block;
    height: 32px;
    margin-left: 8px; }
    [dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-name {
      margin-left: auto;
      margin-right: 8px; }

.md-contact-suggestion {
  height: 56px; }
  .md-contact-suggestion img {
    height: 40px;
    border-radius: 20px;
    margin-top: 8px; }
  .md-contact-suggestion .md-contact-name {
    margin-left: 8px;
    width: 120px; }
    [dir=rtl] .md-contact-suggestion .md-contact-name {
      margin-left: auto;
      margin-right: 8px; }
  .md-contact-suggestion .md-contact-name, .md-contact-suggestion .md-contact-email {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; }

.md-contact-chips-suggestions li {
  height: 100%; }

.md-chips {
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 0 0 8px 3px;
  vertical-align: middle; }
  .md-chips:after {
    content: '';
    display: table;
    clear: both; }
  [dir=rtl] .md-chips {
    padding: 0 3px 8px 0; }
  .md-chips.md-readonly .md-chip-input-container {
    min-height: 32px; }
  .md-chips:not(.md-readonly) {
    cursor: text; }
  .md-chips.md-removable md-chip {
    padding-right: 22px; }
    [dir=rtl] .md-chips.md-removable md-chip {
      padding-right: 0;
      padding-left: 22px; }
    .md-chips.md-removable md-chip .md-chip-content {
      padding-right: 4px; }
      [dir=rtl] .md-chips.md-removable md-chip .md-chip-content {
        padding-right: 0;
        padding-left: 4px; }
  .md-chips md-chip {
    cursor: default;
    border-radius: 16px;
    display: block;
    height: 32px;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0 12px 0 12px;
    float: left;
    box-sizing: border-box;
    max-width: 100%;
    position: relative; }
    [dir=rtl] .md-chips md-chip {
      margin: 8px 0 0 8px; }
    [dir=rtl] .md-chips md-chip {
      float: right; }
    .md-chips md-chip .md-chip-content {
      display: block;
      float: left;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
      [dir=rtl] .md-chips md-chip .md-chip-content {
        float: right; }
      .md-chips md-chip .md-chip-content:focus {
        outline: none; }
    .md-chips md-chip._md-chip-content-edit-is-enabled {
      -webkit-user-select: none;
      /* webkit (safari, chrome) browsers */
      -moz-user-select: none;
      /* mozilla browsers */
      -khtml-user-select: none;
      /* webkit (konqueror) browsers */
      -ms-user-select: none;
      /* IE10+ */ }
    .md-chips md-chip .md-chip-remove-container {
      position: absolute;
      right: 0;
      line-height: 22px; }
      [dir=rtl] .md-chips md-chip .md-chip-remove-container {
        right: auto;
        left: 0; }
    .md-chips md-chip .md-chip-remove {
      text-align: center;
      width: 32px;
      height: 32px;
      min-width: 0;
      padding: 0;
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      position: relative; }
      .md-chips md-chip .md-chip-remove md-icon {
        height: 18px;
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0); }
  .md-chips .md-chip-input-container {
    display: block;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0;
    float: left; }
    [dir=rtl] .md-chips .md-chip-input-container {
      margin: 8px 0 0 8px; }
    [dir=rtl] .md-chips .md-chip-input-container {
      float: right; }
    .md-chips .md-chip-input-container input:not([type]), .md-chips .md-chip-input-container input[type="email"], .md-chips .md-chip-input-container input[type="number"], .md-chips .md-chip-input-container input[type="tel"], .md-chips .md-chip-input-container input[type="url"], .md-chips .md-chip-input-container input[type="text"] {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:not([type]):focus, .md-chips .md-chip-input-container input[type="email"]:focus, .md-chips .md-chip-input-container input[type="number"]:focus, .md-chips .md-chip-input-container input[type="tel"]:focus, .md-chips .md-chip-input-container input[type="url"]:focus, .md-chips .md-chip-input-container input[type="text"]:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      background: transparent;
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap {
      box-shadow: none; }
    .md-chips .md-chip-input-container md-autocomplete input {
      position: relative; }
    .md-chips .md-chip-input-container input {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete {
      box-shadow: none; }
      .md-chips .md-chip-input-container md-autocomplete input {
        position: relative; }
    .md-chips .md-chip-input-container:not(:first-child) {
      margin: 8px 8px 0 0; }
      [dir=rtl] .md-chips .md-chip-input-container:not(:first-child) {
        margin: 8px 0 0 8px; }
    .md-chips .md-chip-input-container input {
      background: transparent;
      border-width: 0; }
  .md-chips md-autocomplete button {
    display: none; }

@media screen and (-ms-high-contrast: active) {
  .md-chip-input-container,
  md-chip {
    border: 1px solid #fff; }
  .md-chip-input-container md-autocomplete {
    border: none; } }

md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-content[md-scroll-y] {
    overflow-y: auto;
    overflow-x: hidden; }
  md-content[md-scroll-x] {
    overflow-x: auto;
    overflow-y: hidden; }
  @media print {
    md-content {
      overflow: visible !important; } }

/** Styles for mdCalendar. */
md-calendar {
  font-size: 13px;
  user-select: none; }

.md-calendar-scroll-mask {
  display: inline-block;
  overflow: hidden;
  height: 308px; }
  .md-calendar-scroll-mask .md-virtual-repeat-scroller {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .md-calendar-scroll-mask .md-virtual-repeat-scroller::-webkit-scrollbar {
      display: none; }
  .md-calendar-scroll-mask .md-virtual-repeat-offsetter {
    width: 100%; }

.md-calendar-scroll-container {
  box-shadow: inset -3px 3px 6px rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 308px;
  width: 346px; }

.md-calendar-date {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none;
  box-sizing: content-box; }
  .md-calendar-date:first-child {
    padding-left: 16px; }
    [dir=rtl] .md-calendar-date:first-child {
      padding-left: 0;
      padding-right: 16px; }
  .md-calendar-date:last-child {
    padding-right: 16px; }
    [dir=rtl] .md-calendar-date:last-child {
      padding-right: 0;
      padding-left: 16px; }
  .md-calendar-date.md-calendar-date-disabled {
    cursor: default; }

.md-calendar-date-selection-indicator {
  transition: background-color, color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px; }
  .md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator {
    cursor: pointer; }

.md-calendar-month-label {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 24px; }
  [dir=rtl] .md-calendar-month-label {
    padding: 0 24px 0 0; }
  .md-calendar-month-label.md-calendar-label-clickable {
    cursor: pointer; }
  .md-calendar-month-label md-icon {
    transform: rotate(180deg); }
    [dir=rtl] .md-calendar-month-label md-icon {
      transform: none; }
  .md-calendar-month-label span {
    vertical-align: middle; }

.md-calendar-day-header {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar-day-header th {
    height: 40px;
    width: 44px;
    text-align: center;
    padding: 0;
    border: none;
    box-sizing: content-box;
    font-weight: normal; }
    .md-calendar-day-header th:first-child {
      padding-left: 16px; }
      [dir=rtl] .md-calendar-day-header th:first-child {
        padding-left: 0;
        padding-right: 16px; }
    .md-calendar-day-header th:last-child {
      padding-right: 16px; }
      [dir=rtl] .md-calendar-day-header th:last-child {
        padding-right: 0;
        padding-left: 16px; }

.md-calendar {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar tr:last-child td {
    border-bottom-width: 1px;
    border-bottom-style: solid; }
  .md-calendar:first-child {
    border-top: 1px solid transparent; }
  .md-calendar tbody, .md-calendar td, .md-calendar tr {
    vertical-align: middle;
    box-sizing: content-box; }

/** Styles for mdDatepicker. */
md-datepicker {
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle; }

.md-inline-form md-datepicker {
  margin-top: 12px; }

.md-datepicker-button {
  display: inline-block;
  box-sizing: border-box;
  background: none;
  vertical-align: middle;
  position: relative; }
  .md-datepicker-button:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    speak: none; }

.md-datepicker-input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  min-width: 120px;
  max-width: 328px;
  padding: 0 0 5px; }
  .md-datepicker-input::-ms-clear {
    display: none; }

._md-datepicker-floating-label > md-datepicker {
  overflow: visible; }
  ._md-datepicker-floating-label > md-datepicker .md-datepicker-input-container {
    border: none; }
  ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
    float: left;
    margin-top: -12px;
    top: 9.5px; }
    [dir=rtl] ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
      float: right; }

._md-datepicker-floating-label .md-input {
  float: none; }

._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  right: 18px;
  left: auto;
  width: calc(100% - 84px); }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
    right: auto; }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
    left: 18px; }

._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
  margin-left: 64px; }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
    margin-left: auto;
    margin-right: 64px; }

._md-datepicker-has-triangle-icon {
  padding-right: 18px;
  margin-right: -18px; }
  [dir=rtl] ._md-datepicker-has-triangle-icon {
    padding-right: 0;
    padding-left: 18px; }
  [dir=rtl] ._md-datepicker-has-triangle-icon {
    margin-right: auto;
    margin-left: -18px; }

.md-datepicker-input-container {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
  width: auto; }
  .md-icon-button + .md-datepicker-input-container {
    margin-left: 12px; }
    [dir=rtl] .md-icon-button + .md-datepicker-input-container {
      margin-left: auto;
      margin-right: 12px; }
  .md-datepicker-input-container.md-datepicker-focused {
    border-bottom-width: 2px; }

.md-datepicker-is-showing .md-scroll-mask {
  z-index: 99; }

.md-datepicker-calendar-pane {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 100;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  transform: scale(0);
  transform-origin: 0 0;
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-datepicker-calendar-pane.md-pane-open {
    transform: scale(1); }

.md-datepicker-input-mask {
  height: 40px;
  width: 340px;
  position: relative;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
  cursor: text; }

.md-datepicker-calendar {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.5, 0, 0.25, 1); }
  .md-pane-open .md-datepicker-calendar {
    opacity: 1; }
  .md-datepicker-calendar md-calendar:focus {
    outline: none; }

.md-datepicker-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid; }

.md-datepicker-triangle-button {
  position: absolute;
  right: 0;
  bottom: -2.5px;
  transform: translateX(45%); }
  [dir=rtl] .md-datepicker-triangle-button {
    right: auto;
    left: 0; }
  [dir=rtl] .md-datepicker-triangle-button {
    transform: translateX(-45%); }

.md-datepicker-triangle-button.md-button.md-icon-button {
  height: 36px;
  width: 36px;
  position: absolute;
  padding: 8px; }

md-datepicker[disabled] .md-datepicker-input-container {
  border-bottom-color: transparent; }

md-datepicker[disabled] .md-datepicker-triangle-button {
  display: none; }

.md-datepicker-open {
  overflow: hidden; }
  .md-datepicker-open .md-datepicker-input-container,
  .md-datepicker-open input.md-input {
    border-bottom-color: transparent; }
  .md-datepicker-open .md-datepicker-triangle-button,
  .md-datepicker-open.md-input-has-value > label,
  .md-datepicker-open.md-input-has-placeholder > label {
    display: none; }

.md-datepicker-pos-adjusted .md-datepicker-input-mask {
  display: none; }

.md-datepicker-calendar-pane .md-calendar {
  transform: translateY(-85px);
  transition: transform 0.65s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-delay: 0.125s; }

.md-datepicker-calendar-pane.md-pane-open .md-calendar {
  transform: translateY(0); }

.md-dialog-is-showing {
  max-height: 100%; }

.md-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  overflow: hidden; }

md-dialog {
  opacity: 0;
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column; }
  md-dialog.md-transition-in {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 0) scale(1); }
  md-dialog.md-transition-out {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 100%) scale(0.2); }
  md-dialog > form {
    display: flex;
    flex-direction: column;
    overflow: auto; }
  md-dialog .md-dialog-content {
    padding: 24px; }
  md-dialog md-dialog-content {
    order: 1;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
      margin-top: 0; }
    md-dialog md-dialog-content:focus {
      outline: none; }
    md-dialog md-dialog-content .md-subheader {
      margin: 0; }
    md-dialog md-dialog-content .md-dialog-content-body {
      width: 100%; }
    md-dialog md-dialog-content .md-prompt-input-container {
      width: 100%;
      box-sizing: border-box; }
  md-dialog .md-actions, md-dialog md-dialog-actions {
    display: flex;
    order: 2;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-right: 8px;
    padding-left: 16px;
    min-height: 52px;
    overflow: hidden; }
    [dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
      padding-right: 16px; }
    [dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
      padding-left: 8px; }
    md-dialog .md-actions .md-button, md-dialog md-dialog-actions .md-button {
      margin-bottom: 8px;
      margin-left: 8px;
      margin-right: 0;
      margin-top: 8px; }
      [dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
        margin-left: 0; }
      [dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
        margin-right: 8px; }
  md-dialog.md-content-overflow .md-actions, md-dialog.md-content-overflow md-dialog-actions {
    border-top-width: 1px;
    border-top-style: solid; }

@media screen and (-ms-high-contrast: active) {
  md-dialog {
    border: 1px solid #fff; } }

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0; } }

md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0; }
  md-divider[md-inset] {
    margin-left: 80px; }
    [dir=rtl] md-divider[md-inset] {
      margin-left: auto;
      margin-right: 80px; }

.layout-row > md-divider,
.layout-xs-row > md-divider, .layout-gt-xs-row > md-divider,
.layout-sm-row > md-divider, .layout-gt-sm-row > md-divider,
.layout-md-row > md-divider, .layout-gt-md-row > md-divider,
.layout-lg-row > md-divider, .layout-gt-lg-row > md-divider,
.layout-xl-row > md-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid; }

md-fab-speed-dial {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 20;
  /*
   * Hide some graphics glitches if switching animation types
   */
  /*
   * Handle the animations
   */ }
  md-fab-speed-dial.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial:not(.md-hover-full) {
    pointer-events: none; }
    md-fab-speed-dial:not(.md-hover-full) md-fab-trigger, md-fab-speed-dial:not(.md-hover-full) .md-fab-action-item {
      pointer-events: auto; }
    md-fab-speed-dial:not(.md-hover-full).md-is-open {
      pointer-events: auto; }
  md-fab-speed-dial ._md-css-variables {
    z-index: 20; }
  md-fab-speed-dial.md-is-open .md-fab-action-item {
    align-items: center; }
  md-fab-speed-dial md-fab-actions {
    display: flex;
    height: auto; }
    md-fab-speed-dial md-fab-actions .md-fab-action-item {
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-down {
    flex-direction: column; }
    md-fab-speed-dial.md-down md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-down md-fab-actions {
      flex-direction: column;
      order: 2; }
  md-fab-speed-dial.md-up {
    flex-direction: column; }
    md-fab-speed-dial.md-up md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-up md-fab-actions {
      flex-direction: column-reverse;
      order: 1; }
  md-fab-speed-dial.md-left {
    flex-direction: row; }
    md-fab-speed-dial.md-left md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-left md-fab-actions {
      flex-direction: row-reverse;
      order: 1; }
      md-fab-speed-dial.md-left md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-right {
    flex-direction: row; }
    md-fab-speed-dial.md-right md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-right md-fab-actions {
      flex-direction: row;
      order: 2; }
      md-fab-speed-dial.md-right md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-fling-remove .md-fab-action-item > *, md-fab-speed-dial.md-scale-remove .md-fab-action-item > * {
    visibility: hidden; }
  md-fab-speed-dial.md-fling .md-fab-action-item {
    opacity: 1; }
  md-fab-speed-dial.md-fling.md-animations-waiting .md-fab-action-item {
    opacity: 0;
    transition-duration: 0s; }
  md-fab-speed-dial.md-scale .md-fab-action-item {
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 0.14286s; }

md-fab-toolbar {
  display: block;
  /*
   * Closed styling
   */
  /*
   * Hover styling
   */ }
  md-fab-toolbar.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-toolbar.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-toolbar .md-fab-toolbar-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    height: 68px; }
  md-fab-toolbar md-fab-trigger {
    position: absolute;
    z-index: 20; }
    md-fab-toolbar md-fab-trigger button {
      overflow: visible !important; }
    md-fab-toolbar md-fab-trigger .md-fab-toolbar-background {
      display: block;
      position: absolute;
      z-index: 21;
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
    md-fab-toolbar md-fab-trigger md-icon {
      position: relative;
      z-index: 22;
      opacity: 1;
      transition: all 200ms ease-in; }
  md-fab-toolbar.md-left md-fab-trigger {
    right: 0; }
    [dir=rtl] md-fab-toolbar.md-left md-fab-trigger {
      right: auto;
      left: 0; }
  md-fab-toolbar.md-left .md-toolbar-tools {
    flex-direction: row-reverse; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-right: 0.6rem; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
        margin-right: auto;
        margin-left: 0.6rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-left: -0.8rem; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
        margin-left: auto;
        margin-right: -0.8rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
      margin-right: 8px; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
        margin-right: auto;
        margin-left: 8px; }
  md-fab-toolbar.md-right md-fab-trigger {
    left: 0; }
    [dir=rtl] md-fab-toolbar.md-right md-fab-trigger {
      left: auto;
      right: 0; }
  md-fab-toolbar.md-right .md-toolbar-tools {
    flex-direction: row; }
  md-fab-toolbar md-toolbar {
    background-color: transparent !important;
    pointer-events: none;
    z-index: 23; }
    md-fab-toolbar md-toolbar .md-toolbar-tools {
      padding: 0 20px;
      margin-top: 3px; }
    md-fab-toolbar md-toolbar .md-fab-action-item {
      opacity: 0;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 0.15s; }
  md-fab-toolbar.md-is-open md-fab-trigger > button {
    box-shadow: none; }
    md-fab-toolbar.md-is-open md-fab-trigger > button md-icon {
      opacity: 0; }
  md-fab-toolbar.md-is-open .md-fab-action-item {
    opacity: 1;
    transform: scale(1); }

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative; }
  md-grid-list md-grid-tile,
  md-grid-list md-grid-tile > figure,
  md-grid-list md-grid-tile-header,
  md-grid-list md-grid-tile-footer {
    box-sizing: border-box; }
  md-grid-list md-grid-tile {
    display: block;
    position: absolute; }
    md-grid-list md-grid-tile figure {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0; }
    md-grid-list md-grid-tile md-grid-tile-header,
    md-grid-list md-grid-tile md-grid-tile-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      color: #fff;
      background: rgba(0, 0, 0, 0.18);
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h3,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-weight: 400;
        margin: 0 0 0 16px; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-footer h3 {
        font-size: 14px; }
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-size: 12px; }
    md-grid-list md-grid-tile md-grid-tile-header {
      top: 0; }
    md-grid-list md-grid-tile md-grid-tile-footer {
      bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff; }
  md-grid-tile-footer {
    border-top: 1px solid #fff; } }

md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px; }
  md-icon svg {
    pointer-events: none;
    display: block; }
  md-icon[md-font-icon] {
    line-height: 24px;
    width: auto; }

md-input-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  margin: 18px 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */ }
  md-input-container:after {
    content: '';
    display: table;
    clear: both; }
  md-input-container.md-block {
    display: block; }
  md-input-container .md-errors-spacer {
    float: right;
    min-height: 24px;
    min-width: 1px; }
    [dir=rtl] md-input-container .md-errors-spacer {
      float: left; }
  md-input-container > md-icon {
    position: absolute;
    top: 8px;
    left: 2px;
    right: auto; }
    [dir=rtl] md-input-container > md-icon {
      left: auto; }
    [dir=rtl] md-input-container > md-icon {
      right: 2px; }
  md-input-container textarea,
  md-input-container input[type="text"],
  md-input-container input[type="password"],
  md-input-container input[type="datetime"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="date"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"],
  md-input-container input[type="number"],
  md-input-container input[type="email"],
  md-input-container input[type="url"],
  md-input-container input[type="search"],
  md-input-container input[type="tel"],
  md-input-container input[type="color"] {
    /* remove default appearance from all input/textarea */
    -moz-appearance: none;
    -webkit-appearance: none; }
  md-input-container input[type="date"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"] {
    min-height: 26px; }
  md-input-container textarea {
    resize: none;
    overflow: hidden; }
    md-input-container textarea.md-input {
      min-height: 26px;
      -ms-flex-preferred-size: auto; }
    md-input-container textarea[md-no-autogrow] {
      height: auto;
      overflow: auto; }
  md-input-container label:not(.md-container-ignore) {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: auto; }
    [dir=rtl] md-input-container label:not(.md-container-ignore) {
      left: auto; }
    [dir=rtl] md-input-container label:not(.md-container-ignore) {
      right: 0; }
    md-input-container label:not(.md-container-ignore).md-required:after {
      content: ' *';
      font-size: 13px;
      vertical-align: top; }
  md-input-container label:not(.md-no-float):not(.md-container-ignore),
  md-input-container .md-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 3px;
    padding-right: 0;
    z-index: 1;
    transform: translate3d(0, 28px, 0) scale(1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    max-width: 100%;
    transform-origin: left top; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      padding-left: 0; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      padding-right: 3px; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      transform-origin: right top; }
  md-input-container .md-placeholder {
    position: absolute;
    top: 0;
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d(0, 30px, 0); }
  md-input-container.md-input-focused .md-placeholder {
    opacity: 1;
    transform: translate3d(0, 24px, 0); }
  md-input-container.md-input-has-value .md-placeholder {
    transition: none;
    opacity: 0; }
  md-input-container:not(.md-input-has-value):not(.md-input-has-placeholder) input:not(:focus) {
    color: transparent; }
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
    color: transparent; }
  md-input-container .md-input {
    order: 2;
    display: block;
    margin-top: 0;
    background: none;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    border-width: 0 0 1px 0;
    line-height: 26px;
    height: 30px;
    -ms-flex-preferred-size: 26px;
    border-radius: 0;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    float: left; }
    [dir=rtl] md-input-container .md-input {
      float: right; }
    md-input-container .md-input:focus {
      outline: none; }
    md-input-container .md-input:invalid {
      outline: none;
      box-shadow: none; }
    md-input-container .md-input.md-no-flex {
      flex: none !important; }
  md-input-container .md-char-counter {
    text-align: right;
    padding-right: 2px;
    padding-left: 0; }
    [dir=rtl] md-input-container .md-char-counter {
      text-align: left; }
    [dir=rtl] md-input-container .md-char-counter {
      padding-right: 0; }
    [dir=rtl] md-input-container .md-char-counter {
      padding-left: 2px; }
  md-input-container .md-input-messages-animation {
    position: relative;
    order: 4;
    overflow: hidden;
    clear: left; }
    [dir=rtl] md-input-container .md-input-messages-animation {
      clear: right; }
  md-input-container .md-input-message-animation, md-input-container .md-char-counter {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    opacity: 1;
    margin-top: 0;
    padding-top: 5px; }
    md-input-container .md-input-message-animation:not(.md-char-counter), md-input-container .md-char-counter:not(.md-char-counter) {
      padding-right: 5px;
      padding-left: 0; }
      [dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
        padding-right: 0; }
      [dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
        padding-left: 5px; }
  md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-input-message-animation.ng-enter-prepare {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-input-message-animation.ng-enter:not(.ng-enter-active) {
    opacity: 0;
    margin-top: -100px; }
  md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
    transform: translate3d(0, 6px, 0) scale(0.75);
    transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s; }
  md-input-container.md-input-has-value label {
    transition: none; }
  md-input-container.md-input-focused .md-input,
  md-input-container .md-input.ng-invalid.ng-dirty,
  md-input-container.md-input-resized .md-input {
    padding-bottom: 0;
    border-width: 0 0 2px 0; }
  md-input-container .md-input[disabled],
  [disabled] md-input-container .md-input {
    background-position: bottom -1px left 0;
    background-size: 4px 1px;
    background-repeat: repeat-x; }
  md-input-container.md-icon-float {
    transition: margin-top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-input-container.md-icon-float > label {
      pointer-events: none;
      position: absolute; }
    md-input-container.md-icon-float > md-icon {
      top: 8px;
      left: 2px;
      right: auto; }
      [dir=rtl] md-input-container.md-icon-float > md-icon {
        left: auto; }
      [dir=rtl] md-input-container.md-icon-float > md-icon {
        right: 2px; }
  md-input-container.md-icon-left > label:not(.md-no-float):not(.md-container-ignore),
  md-input-container.md-icon-left > label .md-placeholder, md-input-container.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
  md-input-container.md-icon-right > label .md-placeholder {
    width: calc(100% - 36px - 18px); }
  md-input-container.md-icon-left {
    padding-left: 36px;
    padding-right: 0; }
    [dir=rtl] md-input-container.md-icon-left {
      padding-left: 0; }
    [dir=rtl] md-input-container.md-icon-left {
      padding-right: 36px; }
    md-input-container.md-icon-left > label {
      left: 36px;
      right: auto; }
      [dir=rtl] md-input-container.md-icon-left > label {
        left: auto; }
      [dir=rtl] md-input-container.md-icon-left > label {
        right: 36px; }
  md-input-container.md-icon-right {
    padding-left: 0;
    padding-right: 36px; }
    [dir=rtl] md-input-container.md-icon-right {
      padding-left: 36px; }
    [dir=rtl] md-input-container.md-icon-right {
      padding-right: 0; }
    md-input-container.md-icon-right > md-icon:last-of-type {
      margin: 0;
      right: 2px;
      left: auto; }
      [dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        right: auto; }
      [dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        left: 2px; }
  md-input-container.md-icon-left.md-icon-right {
    padding-left: 36px;
    padding-right: 36px; }
    md-input-container.md-icon-left.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
    md-input-container.md-icon-left.md-icon-right > label .md-placeholder {
      width: calc(100% - (36px * 2)); }

.md-resize-wrapper {
  position: relative; }
  .md-resize-wrapper:after {
    content: '';
    display: table;
    clear: both; }

.md-resize-handle {
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 10px;
  background: transparent;
  width: 100%;
  cursor: ns-resize; }

@media screen and (-ms-high-contrast: active) {
  md-input-container.md-default-theme > md-icon {
    fill: #fff; } }

md-list {
  display: block;
  padding: 8px 0px 8px 0px; }
  md-list .md-subheader {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.010em;
    line-height: 1.2em; }
  md-list.md-dense md-list-item,
  md-list.md-dense md-list-item .md-list-item-inner {
    min-height: 48px; }
    md-list.md-dense md-list-item::before,
    md-list.md-dense md-list-item .md-list-item-inner::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item md-icon:first-child,
    md-list.md-dense md-list-item .md-list-item-inner md-icon:first-child {
      width: 20px;
      height: 20px; }
    md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
      margin-right: 36px; }
      [dir=rtl] md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon), [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
        margin-right: auto;
        margin-left: 36px; }
    md-list.md-dense md-list-item .md-avatar, md-list.md-dense md-list-item .md-avatar-icon,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
      margin-right: 20px; }
      [dir=rtl] md-list.md-dense md-list-item .md-avatar, [dir=rtl] md-list.md-dense md-list-item .md-avatar-icon, [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner .md-avatar, [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
        margin-right: auto;
        margin-left: 20px; }
    md-list.md-dense md-list-item .md-avatar,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar {
      flex: none;
      width: 36px;
      height: 36px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
    margin-left: 56px; }
    [dir=rtl] md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
      margin-left: auto;
      margin-right: 56px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h3,
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h4,
  md-list.md-dense md-list-item.md-2-line .md-list-item-text p, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
  md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
  md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3,
  md-list.md-dense md-list-item.md-3-line .md-list-item-text h4,
  md-list.md-dense md-list-item.md-3-line .md-list-item-text p, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3,
  md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h4,
  md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text p {
    line-height: 1.05;
    font-size: 12px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h3, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
    font-size: 13px; }
  md-list.md-dense md-list-item.md-2-line, md-list.md-dense md-list-item.md-2-line > .md-no-style {
    min-height: 60px; }
    md-list.md-dense md-list-item.md-2-line::before, md-list.md-dense md-list-item.md-2-line > .md-no-style::before {
      content: '';
      min-height: 60px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item.md-2-line > .md-avatar, md-list.md-dense md-list-item.md-2-line .md-avatar-icon, md-list.md-dense md-list-item.md-2-line > .md-no-style > .md-avatar, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-avatar-icon {
      margin-top: 12px; }
  md-list.md-dense md-list-item.md-3-line, md-list.md-dense md-list-item.md-3-line > .md-no-style {
    min-height: 76px; }
    md-list.md-dense md-list-item.md-3-line::before, md-list.md-dense md-list-item.md-3-line > .md-no-style::before {
      content: '';
      min-height: 76px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item.md-3-line > md-icon:first-child,
    md-list.md-dense md-list-item.md-3-line > .md-avatar, md-list.md-dense md-list-item.md-3-line > .md-no-style > md-icon:first-child,
    md-list.md-dense md-list-item.md-3-line > .md-no-style > .md-avatar {
      margin-top: 16px; }

md-list-item {
  position: relative; }
  md-list-item.md-proxy-focus.md-focused .md-no-style {
    transition: background-color 0.15s linear; }
  md-list-item._md-button-wrap {
    position: relative; }
    md-list-item._md-button-wrap > div.md-button:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 16px;
      margin: 0;
      font-weight: 400;
      text-align: left;
      border: medium none; }
      [dir=rtl] md-list-item._md-button-wrap > div.md-button:first-child {
        text-align: right; }
      md-list-item._md-button-wrap > div.md-button:first-child > .md-button:first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        margin: 0;
        padding: 0; }
      md-list-item._md-button-wrap > div.md-button:first-child .md-list-item-inner {
        width: 100%; }
        md-list-item._md-button-wrap > div.md-button:first-child .md-list-item-inner::before {
          content: '';
          min-height: inherit;
          visibility: hidden;
          display: inline-block; }
  md-list-item.md-no-proxy,
  md-list-item .md-no-style {
    position: relative;
    padding: 0px 16px;
    flex: 1 1 auto; }
    md-list-item.md-no-proxy.md-button,
    md-list-item .md-no-style.md-button {
      font-size: inherit;
      height: inherit;
      text-align: left;
      text-transform: none;
      width: 100%;
      white-space: normal;
      flex-direction: inherit;
      align-items: inherit;
      border-radius: 0;
      margin: 0; }
      [dir=rtl] md-list-item.md-no-proxy.md-button, [dir=rtl]
      md-list-item .md-no-style.md-button {
        text-align: right; }
      md-list-item.md-no-proxy.md-button > .md-ripple-container,
      md-list-item .md-no-style.md-button > .md-ripple-container {
        border-radius: 0; }
    md-list-item.md-no-proxy:focus,
    md-list-item .md-no-style:focus {
      outline: none; }
  md-list-item.md-clickable:hover {
    cursor: pointer; }
  md-list-item md-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    [dir=rtl] md-list-item md-divider {
      left: auto;
      right: 0; }
    md-list-item md-divider[md-inset] {
      left: 72px;
      width: calc(100% - 72px);
      margin: 0 !important; }
      [dir=rtl] md-list-item md-divider[md-inset] {
        left: auto;
        right: 72px; }
  md-list-item,
  md-list-item .md-list-item-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 48px;
    height: auto; }
    md-list-item::before,
    md-list-item .md-list-item-inner::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    md-list-item > div.md-primary > md-icon:not(.md-avatar-icon),
    md-list-item > div.md-secondary > md-icon:not(.md-avatar-icon),
    md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list-item > md-icon.md-secondary:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > div.md-primary > md-icon:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > div.md-secondary > md-icon:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon.md-secondary:not(.md-avatar-icon) {
      width: 24px;
      margin-top: 16px;
      margin-bottom: 12px;
      box-sizing: content-box; }
    md-list-item > div.md-primary > md-checkbox,
    md-list-item > div.md-secondary > md-checkbox,
    md-list-item > md-checkbox,
    md-list-item md-checkbox.md-secondary,
    md-list-item .md-list-item-inner > div.md-primary > md-checkbox,
    md-list-item .md-list-item-inner > div.md-secondary > md-checkbox,
    md-list-item .md-list-item-inner > md-checkbox,
    md-list-item .md-list-item-inner md-checkbox.md-secondary {
      align-self: center; }
      md-list-item > div.md-primary > md-checkbox .md-label,
      md-list-item > div.md-secondary > md-checkbox .md-label,
      md-list-item > md-checkbox .md-label,
      md-list-item md-checkbox.md-secondary .md-label,
      md-list-item .md-list-item-inner > div.md-primary > md-checkbox .md-label,
      md-list-item .md-list-item-inner > div.md-secondary > md-checkbox .md-label,
      md-list-item .md-list-item-inner > md-checkbox .md-label,
      md-list-item .md-list-item-inner md-checkbox.md-secondary .md-label {
        display: none; }
    md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
      margin-right: 32px; }
      [dir=rtl] md-list-item > md-icon:first-child:not(.md-avatar-icon), [dir=rtl]
      md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
        margin-right: auto;
        margin-left: 32px; }
    md-list-item .md-avatar, md-list-item .md-avatar-icon,
    md-list-item .md-list-item-inner .md-avatar,
    md-list-item .md-list-item-inner .md-avatar-icon {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 16px;
      border-radius: 50%;
      box-sizing: content-box; }
      [dir=rtl] md-list-item .md-avatar, [dir=rtl] md-list-item .md-avatar-icon, [dir=rtl]
      md-list-item .md-list-item-inner .md-avatar, [dir=rtl]
      md-list-item .md-list-item-inner .md-avatar-icon {
        margin-right: auto;
        margin-left: 16px; }
    md-list-item .md-avatar,
    md-list-item .md-list-item-inner .md-avatar {
      flex: none;
      width: 40px;
      height: 40px; }
    md-list-item .md-avatar-icon,
    md-list-item .md-list-item-inner .md-avatar-icon {
      padding: 8px; }
      md-list-item .md-avatar-icon svg,
      md-list-item .md-list-item-inner .md-avatar-icon svg {
        width: 24px;
        height: 24px; }
    md-list-item > md-checkbox,
    md-list-item .md-list-item-inner > md-checkbox {
      width: 24px;
      margin-left: 3px;
      margin-right: 29px;
      margin-top: 16px; }
      [dir=rtl] md-list-item > md-checkbox, [dir=rtl]
      md-list-item .md-list-item-inner > md-checkbox {
        margin-left: 29px; }
      [dir=rtl] md-list-item > md-checkbox, [dir=rtl]
      md-list-item .md-list-item-inner > md-checkbox {
        margin-right: 3px; }
    md-list-item .md-secondary-container,
    md-list-item .md-list-item-inner .md-secondary-container {
      display: flex;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      margin: auto;
      margin-right: 0;
      margin-left: auto; }
      [dir=rtl] md-list-item .md-secondary-container, [dir=rtl]
      md-list-item .md-list-item-inner .md-secondary-container {
        margin-right: auto; }
      [dir=rtl] md-list-item .md-secondary-container, [dir=rtl]
      md-list-item .md-list-item-inner .md-secondary-container {
        margin-left: 0; }
      md-list-item .md-secondary-container .md-button:last-of-type, md-list-item .md-secondary-container .md-icon-button:last-of-type,
      md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type,
      md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
        margin-right: 0; }
        [dir=rtl] md-list-item .md-secondary-container .md-button:last-of-type, [dir=rtl] md-list-item .md-secondary-container .md-icon-button:last-of-type, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
          margin-right: auto;
          margin-left: 0; }
      md-list-item .md-secondary-container md-checkbox,
      md-list-item .md-list-item-inner .md-secondary-container md-checkbox {
        margin-top: 0;
        margin-bottom: 0; }
        md-list-item .md-secondary-container md-checkbox:last-child,
        md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
          width: 24px;
          margin-right: 0; }
          [dir=rtl] md-list-item .md-secondary-container md-checkbox:last-child, [dir=rtl]
          md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
            margin-right: auto;
            margin-left: 0; }
      md-list-item .md-secondary-container md-switch,
      md-list-item .md-list-item-inner .md-secondary-container md-switch {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: -6px; }
        [dir=rtl] md-list-item .md-secondary-container md-switch, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container md-switch {
          margin-right: auto;
          margin-left: -6px; }
    md-list-item > p, md-list-item > .md-list-item-inner > p,
    md-list-item .md-list-item-inner > p,
    md-list-item .md-list-item-inner > .md-list-item-inner > p {
      flex: 1 1 auto;
      margin: 0; }
  md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style, md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
    align-items: flex-start;
    justify-content: center; }
    md-list-item.md-2-line.md-long-text, md-list-item.md-2-line > .md-no-style.md-long-text, md-list-item.md-3-line.md-long-text, md-list-item.md-3-line > .md-no-style.md-long-text {
      margin-top: 8px;
      margin-bottom: 8px; }
    md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text, md-list-item.md-3-line .md-list-item-text, md-list-item.md-3-line > .md-no-style .md-list-item-text {
      flex: 1 1 auto;
      margin: auto;
      text-overflow: ellipsis;
      overflow: hidden; }
      md-list-item.md-2-line .md-list-item-text.md-offset, md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list-item.md-3-line .md-list-item-text.md-offset, md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
        margin-left: 56px; }
        [dir=rtl] md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
          margin-left: auto;
          margin-right: 56px; }
      md-list-item.md-2-line .md-list-item-text h3, md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list-item.md-3-line .md-list-item-text h3, md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.010em;
        margin: 0 0 0px 0;
        line-height: 1.2em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      md-list-item.md-2-line .md-list-item-text h4, md-list-item.md-2-line > .md-no-style .md-list-item-text h4, md-list-item.md-3-line .md-list-item-text h4, md-list-item.md-3-line > .md-no-style .md-list-item-text h4 {
        font-size: 14px;
        letter-spacing: 0.010em;
        margin: 3px 0 1px 0;
        font-weight: 400;
        line-height: 1.2em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      md-list-item.md-2-line .md-list-item-text p, md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list-item.md-3-line .md-list-item-text p, md-list-item.md-3-line > .md-no-style .md-list-item-text p {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.010em;
        margin: 0 0 0 0;
        line-height: 1.6em; }
  md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style {
    height: auto;
    min-height: 72px; }
    md-list-item.md-2-line::before, md-list-item.md-2-line > .md-no-style::before {
      content: '';
      min-height: 72px;
      visibility: hidden;
      display: inline-block; }
    md-list-item.md-2-line > .md-avatar, md-list-item.md-2-line .md-avatar-icon, md-list-item.md-2-line > .md-no-style > .md-avatar, md-list-item.md-2-line > .md-no-style .md-avatar-icon {
      margin-top: 12px; }
    md-list-item.md-2-line > md-icon:first-child, md-list-item.md-2-line > .md-no-style > md-icon:first-child {
      align-self: flex-start; }
    md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text {
      flex: 1 1 auto; }
  md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
    height: auto;
    min-height: 88px; }
    md-list-item.md-3-line::before, md-list-item.md-3-line > .md-no-style::before {
      content: '';
      min-height: 88px;
      visibility: hidden;
      display: inline-block; }
    md-list-item.md-3-line > md-icon:first-child,
    md-list-item.md-3-line > .md-avatar, md-list-item.md-3-line > .md-no-style > md-icon:first-child,
    md-list-item.md-3-line > .md-no-style > .md-avatar {
      margin-top: 16px; }

.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 2px;
  max-height: calc(100vh - 10px);
  overflow: auto; }
  .md-open-menu-container md-menu-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    width: 100%; }
  .md-open-menu-container md-menu-content > * {
    opacity: 0; }
  .md-open-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-open-menu-container.md-active {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 200ms; }
    .md-open-menu-container.md-active > md-menu-content > * {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 200ms;
      transition-delay: 100ms; }
  .md-open-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-menu-content {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto; }
  md-menu-content.md-dense {
    max-height: 208px; }
    md-menu-content.md-dense md-menu-item {
      height: 32px;
      min-height: 32px; }

md-menu-item {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  height: 48px;
  align-content: center;
  justify-content: flex-start;
  /*
   * We cannot use flex on <button> elements due to a bug in Firefox, so we also can't use it on
   * <a> elements. Add some top padding to fix alignment since buttons automatically align their
   * text vertically.
   */ }
  md-menu-item > * {
    width: 100%;
    margin: auto 0;
    padding-left: 16px;
    padding-right: 16px; }
  md-menu-item > a.md-button {
    padding-top: 5px; }
  md-menu-item > .md-button {
    text-align: left;
    display: inline-block;
    border-radius: 0;
    margin: auto 0;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%; }
    md-menu-item > .md-button::-moz-focus-inner {
      padding: 0;
      border: 0; }
    [dir=rtl] md-menu-item > .md-button {
      text-align: right; }
    md-menu-item > .md-button md-icon {
      margin: auto 16px auto 0; }
      [dir=rtl] md-menu-item > .md-button md-icon {
        margin: auto 0 auto 16px; }
    md-menu-item > .md-button p {
      display: inline-block;
      margin: auto; }
    md-menu-item > .md-button span {
      margin-top: auto;
      margin-bottom: auto; }
    md-menu-item > .md-button .md-ripple-container {
      border-radius: inherit; }

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0; }

@media (max-width: 959px) {
  md-menu-content {
    min-width: 112px; }
  md-menu-content[width="3"] {
    min-width: 168px; }
  md-menu-content[width="4"] {
    min-width: 224px; }
  md-menu-content[width="5"] {
    min-width: 280px; }
  md-menu-content[width="6"] {
    min-width: 336px; }
  md-menu-content[width="7"] {
    min-width: 392px; } }

@media (min-width: 960px) {
  md-menu-content {
    min-width: 96px; }
  md-menu-content[width="3"] {
    min-width: 192px; }
  md-menu-content[width="4"] {
    min-width: 256px; }
  md-menu-content[width="5"] {
    min-width: 320px; }
  md-menu-content[width="6"] {
    min-width: 384px; }
  md-menu-content[width="7"] {
    min-width: 448px; } }

md-toolbar.md-menu-toolbar h2.md-toolbar-tools {
  line-height: 1rem;
  height: auto;
  padding: 28px;
  padding-bottom: 12px; }

md-toolbar.md-has-open-menu {
  position: relative;
  z-index: 100; }

md-menu-bar {
  padding: 0 20px;
  display: block;
  position: relative;
  z-index: 2; }
  md-menu-bar .md-menu {
    display: inline-block;
    padding: 0;
    position: relative; }
  md-menu-bar button {
    font-size: 14px;
    padding: 0 10px;
    margin: 0;
    border: 0;
    background-color: transparent;
    height: 40px; }
  md-menu-bar md-backdrop.md-menu-backdrop {
    z-index: -2; }

md-menu-content.md-menu-bar-menu.md-dense {
  max-height: none;
  padding: 16px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent {
    position: relative; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
      position: absolute;
      padding: 0;
      width: 24px;
      top: 6px;
      left: 24px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
        left: auto;
        right: 24px; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
      padding: 0 32px 0 64px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
        padding: 0 64px 0 32px; }
  md-menu-content.md-menu-bar-menu.md-dense .md-button {
    min-height: 0;
    height: 32px; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span {
      float: left; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-button span {
        float: right; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
      float: right;
      margin: 0 8px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
        float: left; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-divider {
    margin: 8px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
    text-align: left; }
    [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      text-align: right; }
  md-menu-content.md-menu-bar-menu.md-dense .md-menu {
    padding: 0; }
    md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      position: relative;
      margin: 0;
      width: 100%;
      text-transform: none;
      font-weight: normal;
      border-radius: 0px;
      padding-left: 16px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
        padding-left: 0;
        padding-right: 16px; }
      md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
        display: block;
        content: '\25BC';
        position: absolute;
        top: 0px;
        speak: none;
        transform: rotate(270deg) scaleY(0.45) scaleX(0.9);
        right: 28px; }
        [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
          transform: rotate(90deg) scaleY(0.45) scaleX(0.9); }
        [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
          right: auto;
          left: 28px; }

/** Matches "md-tabs md-tabs-wrapper" style. */
.md-nav-bar {
  border-style: solid;
  border-width: 0 0 1px;
  height: 48px;
  position: relative; }

._md-nav-bar-list {
  outline: none;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.md-nav-item:first-of-type {
  margin-left: 8px; }

.md-button._md-nav-button {
  line-height: 24px;
  margin: 0 4px;
  padding: 12px 16px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
  .md-button._md-nav-button:focus {
    outline: none; }

md-nav-ink-bar {
  bottom: 0;
  height: 2px;
  left: auto;
  position: absolute;
  right: auto;
  background-color: black; }
  md-nav-ink-bar._md-left {
    transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }
  md-nav-ink-bar._md-right {
    transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }
  md-nav-ink-bar.ng-animate {
    transition: none; }

md-nav-extra-content {
  min-height: 48px;
  padding-right: 12px; }

@keyframes indeterminate-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

md-progress-circular {
  position: relative;
  display: block; }
  md-progress-circular._md-progress-circular-disabled {
    visibility: hidden; }
  md-progress-circular.md-mode-indeterminate svg {
    animation: indeterminate-rotate 1568.63ms linear infinite; }
  md-progress-circular svg {
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0; }

md-progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding-top: 0 !important;
  margin-bottom: 0 !important; }
  md-progress-linear._md-progress-linear-disabled {
    visibility: hidden; }
  md-progress-linear .md-container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    transform: translate(0, 0) scale(1, 1); }
    md-progress-linear .md-container .md-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 5px; }
    md-progress-linear .md-container .md-dashed:before {
      content: "";
      display: none;
      position: absolute;
      margin-top: 0;
      height: 5px;
      width: 100%;
      background-color: transparent;
      background-size: 10px 10px !important;
      background-position: 0px -23px; }
    md-progress-linear .md-container .md-bar1, md-progress-linear .md-container .md-bar2 {
      transition: transform 0.2s linear; }
    md-progress-linear .md-container.md-mode-query .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-query .md-bar2 {
      transition: all 0.2s linear;
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1); }
    md-progress-linear .md-container.md-mode-determinate .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar1 {
      animation: md-progress-linear-indeterminate-scale-1 4s infinite, md-progress-linear-indeterminate-1 4s infinite; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar2 {
      animation: md-progress-linear-indeterminate-scale-2 4s infinite, md-progress-linear-indeterminate-2 4s infinite; }
    md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container {
      animation: none; }
      md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar1 {
        animation-name: none; }
      md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar2 {
        animation-name: none; }
  md-progress-linear .md-container.md-mode-buffer {
    background-color: transparent !important;
    transition: all 0.2s linear; }
    md-progress-linear .md-container.md-mode-buffer .md-dashed:before {
      display: block;
      animation: buffer 3s infinite linear; }

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1); } }

@keyframes buffer {
  0% {
    opacity: 1;
    background-position: 0px -23px; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    background-position: -200px -23px; } }

@keyframes md-progress-linear-indeterminate-scale-1 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: linear; }
  36.6% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1); }
  69.15% {
    transform: scaleX(0.83);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-1 {
  0% {
    left: -105.16667%;
    animation-timing-function: linear; }
  20% {
    left: -105.16667%;
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582); }
  69.15% {
    left: 21.5%;
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635); }
  100% {
    left: 95.44444%; } }

@keyframes md-progress-linear-indeterminate-scale-2 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397); }
  19.15% {
    transform: scaleX(0.57);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432); }
  44.15% {
    transform: scaleX(0.91);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-2 {
  0% {
    left: -54.88889%;
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968); }
  25% {
    left: -17.25%;
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372); }
  48.35% {
    left: 29.5%;
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203); }
  100% {
    left: 117.38889%; } }

md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative; }
  md-radio-button[disabled] {
    cursor: default; }
    md-radio-button[disabled] .md-container {
      cursor: default; }
  md-radio-button .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    left: 0;
    right: auto; }
    [dir=rtl] md-radio-button .md-container {
      left: auto; }
    [dir=rtl] md-radio-button .md-container {
      right: 0; }
    md-radio-button .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
    md-radio-button .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
  md-radio-button.md-align-top-left > div.md-container {
    top: 12px; }
  md-radio-button .md-off {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    transition: border-color ease 0.28s; }
  md-radio-button .md-on {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: transform ease 0.28s;
    transform: scale(0); }
  md-radio-button.md-checked .md-on {
    transform: scale(0.5); }
  md-radio-button .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin-left: 30px;
    margin-right: 0;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    width: auto; }
    [dir=rtl] md-radio-button .md-label {
      margin-left: 0; }
    [dir=rtl] md-radio-button .md-label {
      margin-right: 30px; }

md-radio-group {
  /** Layout adjustments for the radio group. */ }
  md-radio-group.layout-column md-radio-button, md-radio-group.layout-xs-column md-radio-button, md-radio-group.layout-gt-xs-column md-radio-button, md-radio-group.layout-sm-column md-radio-button, md-radio-group.layout-gt-sm-column md-radio-button, md-radio-group.layout-md-column md-radio-button, md-radio-group.layout-gt-md-column md-radio-button, md-radio-group.layout-lg-column md-radio-button, md-radio-group.layout-gt-lg-column md-radio-button, md-radio-group.layout-xl-column md-radio-button {
    margin-bottom: 16px; }
  md-radio-group.layout-row md-radio-button, md-radio-group.layout-xs-row md-radio-button, md-radio-group.layout-gt-xs-row md-radio-button, md-radio-group.layout-sm-row md-radio-button, md-radio-group.layout-gt-sm-row md-radio-button, md-radio-group.layout-md-row md-radio-button, md-radio-group.layout-gt-md-row md-radio-button, md-radio-group.layout-lg-row md-radio-button, md-radio-group.layout-gt-lg-row md-radio-button, md-radio-group.layout-xl-row md-radio-button {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 16px; }
    [dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
      margin-left: 16px; }
    [dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
      margin-right: 0; }
    md-radio-group.layout-row md-radio-button:last-of-type, md-radio-group.layout-xs-row md-radio-button:last-of-type, md-radio-group.layout-gt-xs-row md-radio-button:last-of-type, md-radio-group.layout-sm-row md-radio-button:last-of-type, md-radio-group.layout-gt-sm-row md-radio-button:last-of-type, md-radio-group.layout-md-row md-radio-button:last-of-type, md-radio-group.layout-gt-md-row md-radio-button:last-of-type, md-radio-group.layout-lg-row md-radio-button:last-of-type, md-radio-group.layout-gt-lg-row md-radio-button:last-of-type, md-radio-group.layout-xl-row md-radio-button:last-of-type {
      margin-left: 0;
      margin-right: 0; }
  md-radio-group:focus {
    outline: none; }
  md-radio-group.md-focused.ng-not-empty .md-checked .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-radio-group.md-focused.ng-empty > md-radio-button:first-child .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-radio-group[disabled] md-radio-button {
    cursor: default; }
    md-radio-group[disabled] md-radio-button .md-container {
      cursor: default; }

.md-inline-form md-radio-group {
  margin: 18px 0 19px; }
  .md-inline-form md-radio-group md-radio-button {
    display: inline-block;
    height: 30px;
    padding: 2px;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff; } }

md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform-origin: left top; }
  [dir=rtl] md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
    transform-origin: right top; }

md-input-container.md-input-focused:not([md-no-float]) .md-select-placeholder span:first-child {
  transform: translateY(-22px) translateX(-2px) scale(0.75); }

.md-select-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  opacity: 0;
  display: none;
  transform: translateY(-1px); }
  .md-select-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-select-menu-container md-progress-circular {
    display: table;
    margin: 24px auto !important; }
  .md-select-menu-container.md-active {
    display: block;
    opacity: 1; }
    .md-select-menu-container.md-active md-select-menu {
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 150ms; }
      .md-select-menu-container.md-active md-select-menu > * {
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        transition-duration: 150ms;
        transition-delay: 100ms; }
  .md-select-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-input-container > md-select {
  margin: 0;
  order: 2; }

md-input-container:not(.md-input-has-value) md-select[required]:not(.md-no-asterisk) .md-select-value span:first-child:after, md-input-container:not(.md-input-has-value) md-select.ng-required:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top; }

md-input-container.md-input-invalid md-select .md-select-value {
  border-bottom-style: solid;
  padding-bottom: 1px; }

md-select {
  display: flex;
  margin: 20px 0 26px 0; }
  md-select[required].ng-empty.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-empty.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: ' *';
    font-size: 13px;
    vertical-align: top; }
  md-select[disabled] .md-select-value {
    background-position: 0 bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    margin-bottom: -1px; }
  md-select:focus {
    outline: none; }
  md-select[disabled]:hover {
    cursor: default; }
  md-select:not([disabled]):hover {
    cursor: pointer; }
  md-select:not([disabled]).ng-invalid.ng-touched .md-select-value {
    border-bottom-style: solid;
    padding-bottom: 1px; }
  md-select:not([disabled]):focus .md-select-value {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 0; }
  md-select:not([disabled]):focus.ng-invalid.ng-touched .md-select-value {
    padding-bottom: 0; }

md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  transform: translate3d(0, 1px, 0); }

.md-select-value {
  display: flex;
  align-items: center;
  padding: 2px 2px 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  box-sizing: content-box;
  min-width: 64px;
  min-height: 26px;
  flex-grow: 1; }
  .md-select-value > span:not(.md-select-icon) {
    max-width: 100%;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .md-select-value > span:not(.md-select-icon) .md-text {
      display: inline; }
  .md-select-value .md-select-icon {
    display: block;
    align-items: flex-end;
    text-align: right;
    width: 24px;
    margin: 0 4px;
    transform: translate3d(0, -2px, 0);
    font-size: 1.2rem; }
    [dir=rtl] .md-select-value .md-select-icon {
      align-items: flex-start; }
    [dir=rtl] .md-select-value .md-select-icon {
      text-align: left; }
  .md-select-value .md-select-icon:after {
    display: block;
    content: '\25BC';
    position: relative;
    top: 2px;
    speak: none;
    font-size: 13px;
    transform: scaleY(0.5) scaleX(1); }
  .md-select-value.md-select-placeholder {
    display: flex;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 2px;
    z-index: 1; }

md-select-menu {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: 256px;
  min-height: 48px;
  overflow-y: hidden;
  transform-origin: left top;
  transform: scale(1); }
  md-select-menu.md-reverse {
    flex-direction: column-reverse; }
  md-select-menu:not(.md-overflow) md-content {
    padding-top: 8px;
    padding-bottom: 8px; }
  [dir=rtl] md-select-menu {
    transform-origin: right top; }
  md-select-menu md-content {
    min-width: 136px;
    min-height: 48px;
    max-height: 256px;
    overflow-y: auto; }
  md-select-menu > * {
    opacity: 0; }

md-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  transition: background 0.15s linear;
  padding: 0 16px 0 16px;
  height: 48px; }
  md-option[disabled] {
    cursor: default; }
  md-option:focus {
    outline: none; }
  md-option .md-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

md-optgroup {
  display: block; }
  md-optgroup label {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    padding: 16px;
    font-weight: 500; }
  md-optgroup md-option {
    padding-left: 32px;
    padding-right: 32px; }

@media screen and (-ms-high-contrast: active) {
  .md-select-backdrop {
    background-color: transparent; }
  md-select-menu {
    border: 1px solid #fff; } }

md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-left: 40px;
  padding-right: 16px; }
  [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
    padding-left: 16px; }
  [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
    padding-right: 40px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      left: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      right: 0; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon {
    border-color: transparent; }
    md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      position: absolute;
      left: 4.66667px;
      top: 0.22222px;
      display: table;
      width: 6.66667px;
      height: 13.33333px;
      border-width: 2px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      content: ''; }
  md-select-menu[multiple] md-option.md-checkbox-enabled[disabled] {
    cursor: default; }
  md-select-menu[multiple] md-option.md-checkbox-enabled.md-indeterminate .md-icon:after {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: 12px;
    height: 2px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: ''; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
    margin-left: 10.66667px;
    margin-right: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      margin-left: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      margin-right: 10.66667px; }

md-sidenav {
  box-sizing: border-box;
  position: absolute;
  flex-direction: column;
  z-index: 60;
  width: 320px;
  max-width: 320px;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-sidenav ul {
    list-style: none; }
  md-sidenav.md-closed {
    display: none; }
  md-sidenav.md-closed-add, md-sidenav.md-closed-remove {
    display: flex;
    transition: 0.2s ease-in all; }
  md-sidenav.md-closed-add.md-closed-add-active, md-sidenav.md-closed-remove.md-closed-remove-active {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-sidenav.md-locked-open-add, md-sidenav.md-locked-open-remove {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-left, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-right {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open-remove.md-closed {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-closed.md-locked-open-add {
    position: static;
    display: flex;
    transform: translate3d(0%, 0, 0); }
  md-sidenav.md-closed.md-locked-open-add:not(.md-locked-open-add-active) {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 0 !important;
    min-width: 0 !important; }
  md-sidenav.md-closed.md-locked-open-add-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-sidenav.md-locked-open-remove-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 0 !important;
    min-width: 0 !important; }

.md-sidenav-backdrop.md-locked-open {
  display: none; }

.md-sidenav-left, md-sidenav {
  left: 0;
  top: 0;
  transform: translate3d(0%, 0, 0); }
  .md-sidenav-left.md-closed, md-sidenav.md-closed {
    transform: translate3d(-100%, 0, 0); }

.md-sidenav-right {
  left: 100%;
  top: 0;
  transform: translate(-100%, 0); }
  .md-sidenav-right.md-closed {
    transform: translate(0%, 0); }

@media (min-width: 600px) {
  md-sidenav {
    max-width: 400px; } }

@media (max-width: 456px) {
  md-sidenav {
    width: calc(100% - 56px);
    min-width: calc(100% - 56px);
    max-width: calc(100% - 56px); } }

@media screen and (-ms-high-contrast: active) {
  .md-sidenav-left, md-sidenav {
    border-right: 1px solid #fff; }
  .md-sidenav-right {
    border-left: 1px solid #fff; } }

@keyframes sliderFocusThumb {
  0% {
    transform: scale(0.7); }
  30% {
    transform: scale(1); }
  100% {
    transform: scale(0.7); } }

@keyframes sliderDiscreteFocusThumb {
  0% {
    transform: scale(0.7); }
  50% {
    transform: scale(0.8); }
  100% {
    transform: scale(0); } }

@keyframes sliderDiscreteFocusRing {
  0% {
    transform: scale(0.7);
    opacity: 0; }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0); } }

md-slider {
  height: 48px;
  min-width: 128px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  display: block;
  flex-direction: row;
  /**
   * Track
   */
  /**
   * Slider thumb
   */
  /* The sign that's focused in discrete mode */
  /**
   * The border/background that comes in when focused in non-discrete mode
   */
  /* Don't animate left/right while panning */ }
  md-slider *, md-slider *:after {
    box-sizing: border-box; }
  md-slider .md-slider-wrapper {
    outline: none;
    width: 100%;
    height: 100%; }
  md-slider .md-slider-content {
    position: relative; }
  md-slider .md-track-container {
    width: 100%;
    position: absolute;
    top: 23px;
    height: 2px; }
  md-slider .md-track {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-fill {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: width, height; }
  md-slider .md-track-ticks {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-ticks canvas {
    width: 100%;
    height: 100%; }
  md-slider .md-thumb-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: left, right, bottom; }
    [dir=rtl] md-slider .md-thumb-container {
      left: auto;
      right: 0; }
  md-slider .md-thumb {
    z-index: 1;
    position: absolute;
    left: -10px;
    top: 14px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transform: scale(0.7);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    [dir=rtl] md-slider .md-thumb {
      left: auto;
      right: -10px; }
    md-slider .md-thumb:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border-width: 3px;
      border-style: solid;
      transition: inherit; }
  md-slider .md-sign {
    /* Center the children (slider-thumb-text) */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -14px;
    top: -17px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.4) translate3d(0, 67.5px, 0);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    /* The arrow pointing down under the sign */ }
    md-slider .md-sign:after {
      position: absolute;
      content: '';
      left: 0px;
      border-radius: 16px;
      top: 19px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top-width: 16px;
      border-top-style: solid;
      opacity: 0;
      transform: translate3d(0, -8px, 0);
      transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1); }
      [dir=rtl] md-slider .md-sign:after {
        left: auto;
        right: 0px; }
    md-slider .md-sign .md-thumb-text {
      z-index: 1;
      font-size: 12px;
      font-weight: bold; }
  md-slider .md-focus-ring {
    position: absolute;
    left: -17px;
    top: 7px;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
    [dir=rtl] md-slider .md-focus-ring {
      left: auto;
      right: -17px; }
  md-slider .md-disabled-thumb {
    position: absolute;
    left: -14px;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.5);
    border-width: 4px;
    border-style: solid;
    display: none; }
    [dir=rtl] md-slider .md-disabled-thumb {
      left: auto;
      right: -14px; }
  md-slider.md-min .md-sign {
    opacity: 0; }
  md-slider:focus {
    outline: none; }
  md-slider.md-dragging .md-thumb-container,
  md-slider.md-dragging .md-track-fill {
    transition: none; }
  md-slider:not([md-discrete]) {
    /* Hide the sign and ticks in non-discrete mode */ }
    md-slider:not([md-discrete]) .md-track-ticks,
    md-slider:not([md-discrete]) .md-sign {
      display: none; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper .md-thumb:hover {
      transform: scale(0.8); }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
      transform: scale(1);
      opacity: 1; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
      animation: sliderFocusThumb 0.7s cubic-bezier(0.35, 0, 0.25, 1); }
    md-slider:not([md-discrete]):not([disabled]).md-active .md-slider-wrapper .md-thumb {
      transform: scale(1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
    transform: scale(0);
    animation: sliderDiscreteFocusRing 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
    animation: sliderDiscreteFocusThumb 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb, md-slider[md-discrete]:not([disabled]).md-active .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign,
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign:after, md-slider[md-discrete]:not([disabled]).md-active .md-sign,
  md-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-discrete][disabled][readonly] .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete][disabled][readonly] .md-sign,
  md-slider[md-discrete][disabled][readonly] .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[disabled] .md-track-fill {
    display: none; }
  md-slider[disabled] .md-track-ticks {
    opacity: 0; }
  md-slider[disabled]:not([readonly]) .md-sign {
    opacity: 0; }
  md-slider[disabled] .md-thumb {
    transform: scale(0.5); }
  md-slider[disabled] .md-disabled-thumb {
    display: block; }
  md-slider[md-vertical] {
    flex-direction: column;
    min-height: 128px;
    min-width: 0; }
    md-slider[md-vertical] .md-slider-wrapper {
      flex: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 48px;
      align-self: center;
      display: flex;
      justify-content: center; }
    md-slider[md-vertical] .md-track-container {
      height: 100%;
      width: 2px;
      top: 0;
      left: calc(50% - (2px / 2)); }
    md-slider[md-vertical] .md-thumb-container {
      top: auto;
      margin-bottom: 23px;
      left: calc(50% - 1px);
      bottom: 0; }
      md-slider[md-vertical] .md-thumb-container .md-thumb:after {
        left: 1px; }
      md-slider[md-vertical] .md-thumb-container .md-focus-ring {
        left: -16px; }
    md-slider[md-vertical] .md-track-fill {
      bottom: 0; }
    md-slider[md-vertical][md-discrete] .md-sign {
      left: -40px;
      top: 9.5px;
      transform: scale(0.4) translate3d(67.5px, 0, 0);
      /* The arrow pointing left next the sign */ }
      md-slider[md-vertical][md-discrete] .md-sign:after {
        top: 9.5px;
        left: 19px;
        border-top: 14px solid transparent;
        border-right: 0;
        border-bottom: 14px solid transparent;
        border-left-width: 16px;
        border-left-style: solid;
        opacity: 0;
        transform: translate3d(0, -8px, 0);
        transition: all 0.2s ease-in-out; }
      md-slider[md-vertical][md-discrete] .md-sign .md-thumb-text {
        z-index: 1;
        font-size: 12px;
        font-weight: bold; }
    md-slider[md-vertical][md-discrete].md-active .md-sign:after,
    md-slider[md-vertical][md-discrete] .md-focused .md-sign:after, md-slider[md-vertical][md-discrete][disabled][readonly] .md-sign:after {
      top: 0; }
    md-slider[md-vertical][disabled][readonly] .md-thumb {
      transform: scale(0); }
    md-slider[md-vertical][disabled][readonly] .md-sign,
    md-slider[md-vertical][disabled][readonly] .md-sign:after {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-invert]:not([md-vertical]) .md-track-fill {
    left: auto;
    right: 0; }
    [dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
      left: 0; }
    [dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
      right: auto; }
  md-slider[md-invert][md-vertical] .md-track-fill {
    bottom: auto;
    top: 0; }

md-slider-container {
  display: flex;
  align-items: center;
  flex-direction: row; }
  md-slider-container > *:first-child:not(md-slider),
  md-slider-container > *:last-child:not(md-slider) {
    min-width: 25px;
    max-width: 42px;
    height: 25px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: color, max-width; }
  md-slider-container > *:first-child:not(md-slider) {
    margin-right: 16px; }
    [dir=rtl] md-slider-container > *:first-child:not(md-slider) {
      margin-right: auto;
      margin-left: 16px; }
  md-slider-container > *:last-child:not(md-slider) {
    margin-left: 16px; }
    [dir=rtl] md-slider-container > *:last-child:not(md-slider) {
      margin-left: auto;
      margin-right: 16px; }
  md-slider-container[md-vertical] {
    flex-direction: column; }
    md-slider-container[md-vertical] > *:first-child:not(md-slider),
    md-slider-container[md-vertical] > *:last-child:not(md-slider) {
      margin-right: 0;
      margin-left: 0;
      text-align: center; }
  md-slider-container md-input-container input[type="number"] {
    text-align: center;
    padding-left: 15px;
    height: 50px;
    margin-top: -25px; }
    [dir=rtl] md-slider-container md-input-container input[type="number"] {
      padding-left: 0;
      padding-right: 15px; }

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff; } }

.md-sticky-clone {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  position: absolute !important;
  transform: translate3d(-9999px, -9999px, 0); }
  .md-sticky-clone[sticky-state="active"] {
    transform: translate3d(0, 0, 0); }
    .md-sticky-clone[sticky-state="active"]:not(.md-sticky-no-effect) .md-subheader-inner {
      animation: subheaderStickyHoverIn 0.3s ease-out both; }

@keyframes subheaderStickyHoverIn {
  0% {
    box-shadow: 0 0 0 0 transparent; }
  100% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); } }

@keyframes subheaderStickyHoverOut {
  0% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); }
  100% {
    box-shadow: 0 0 0 0 transparent; } }

.md-subheader-wrapper:not(.md-sticky-no-effect) {
  transition: 0.2s ease-out margin; }
  .md-subheader-wrapper:not(.md-sticky-no-effect) .md-subheader {
    margin: 0; }
  .md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
    z-index: 2; }
  .md-subheader-wrapper:not(.md-sticky-no-effect)[sticky-state="active"] {
    margin-top: -2px; }
  .md-subheader-wrapper:not(.md-sticky-no-effect):not(.md-sticky-clone)[sticky-prev-state="active"] .md-subheader-inner:after {
    animation: subheaderStickyHoverOut 0.3s ease-out both; }

.md-subheader {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 0 0 0 0;
  position: relative; }
  .md-subheader .md-subheader-inner {
    display: block;
    padding: 16px; }
  .md-subheader .md-subheader-content {
    display: block;
    z-index: 1;
    position: relative; }

[md-swipe-left], [md-swipe-right] {
  touch-action: pan-y; }

[md-swipe-up], [md-swipe-down] {
  touch-action: pan-x; }

.md-inline-form md-switch {
  margin-top: 18px;
  margin-bottom: 19px; }

md-switch {
  margin: 16px 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  align-items: center;
  display: flex;
  margin-left: inherit;
  margin-right: 16px; }
  [dir=rtl] md-switch {
    margin-left: 16px; }
  [dir=rtl] md-switch {
    margin-right: inherit; }
  md-switch:last-of-type {
    margin-left: inherit;
    margin-right: 0; }
    [dir=rtl] md-switch:last-of-type {
      margin-left: 0; }
    [dir=rtl] md-switch:last-of-type {
      margin-right: inherit; }
  md-switch[disabled] {
    cursor: default; }
    md-switch[disabled] .md-container {
      cursor: default; }
  md-switch .md-container {
    cursor: grab;
    width: 36px;
    height: 24px;
    position: relative;
    user-select: none;
    margin-right: 8px;
    float: left; }
    [dir=rtl] md-switch .md-container {
      margin-right: 0px;
      margin-left: 8px; }
  md-switch.md-inverted .md-container {
    margin-right: initial;
    margin-left: 8px; }
    [dir=rtl] md-switch.md-inverted .md-container {
      margin-right: 8px; }
    [dir=rtl] md-switch.md-inverted .md-container {
      margin-left: initial; }
  md-switch:not([disabled]) .md-dragging,
  md-switch:not([disabled]).md-dragging .md-container {
    cursor: grabbing; }
  md-switch.md-focused .md-thumb:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-switch .md-label {
    border-color: transparent;
    border-width: 0;
    float: left; }
  md-switch .md-bar {
    left: 1px;
    width: 34px;
    top: 5px;
    height: 14px;
    border-radius: 8px;
    position: absolute; }
  md-switch .md-thumb-container {
    top: 2px;
    left: 0;
    width: 16px;
    position: absolute;
    transform: translate3d(0, 0, 0);
    z-index: 1; }
  md-switch.md-checked .md-thumb-container {
    transform: translate3d(100%, 0, 0); }
  md-switch .md-thumb {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    outline: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    md-switch .md-thumb:before {
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-switch .md-thumb .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -20px;
      top: -20px;
      right: -20px;
      bottom: -20px; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb-container,
  md-switch:not(.md-dragging) .md-thumb {
    transition: all 0.08s linear;
    transition-property: transform, background-color; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb {
    transition-delay: 0.05s; }

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666; }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E; }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff; } }

@keyframes md-tab-content-hide {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0; }

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0; }
  md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 248px; }
  md-tabs[md-align-tabs="bottom"] {
    padding-bottom: 48px; }
    md-tabs[md-align-tabs="bottom"] md-tabs-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 48px;
      z-index: 2; }
    md-tabs[md-align-tabs="bottom"] md-tabs-content-wrapper {
      top: 0;
      bottom: 48px; }
  md-tabs.md-dynamic-height md-tabs-content-wrapper {
    min-height: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    overflow: visible; }
  md-tabs.md-dynamic-height md-tab-content.md-active {
    position: relative; }
  md-tabs[md-border-bottom] md-tabs-wrapper {
    border-width: 0 0 1px;
    border-style: solid; }
  md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 49px; }

md-tabs-wrapper {
  display: block;
  position: relative;
  transform: translate(0, 0); }
  md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
    height: 100%;
    width: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1em;
    z-index: 2;
    cursor: pointer;
    font-size: 16px;
    background: transparent no-repeat center center;
    transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
    md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
      outline: none; }
    md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
      opacity: 0.25;
      cursor: default; }
    md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
      transition: none; }
    md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    [dir="rtl"] md-tabs-wrapper md-prev-button, [dir="rtl"] md-tabs-wrapper md-next-button {
      transform: rotateY(180deg) translateY(-50%); }
  md-tabs-wrapper md-prev-button {
    left: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg=="); }
    [dir=rtl] md-tabs-wrapper md-prev-button {
      left: auto;
      right: 0; }
  md-tabs-wrapper md-next-button {
    right: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K"); }
    [dir=rtl] md-tabs-wrapper md-next-button {
      right: auto;
      left: 0; }
    md-tabs-wrapper md-next-button md-icon {
      transform: translate(-50%, -50%) rotate(180deg); }
  md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
    width: 100%;
    flex-direction: row; }
    md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
      flex-grow: 1; }

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px; }
  md-tabs-canvas:after {
    content: '';
    display: table;
    clear: both; }
  md-tabs-canvas .md-dummy-wrapper {
    position: absolute;
    top: 0;
    left: 0; }
    [dir=rtl] md-tabs-canvas .md-dummy-wrapper {
      left: auto;
      right: 0; }
  md-tabs-canvas.md-paginated {
    margin: 0 32px; }
  md-tabs-canvas.md-center-tabs {
    display: flex;
    flex-direction: column;
    text-align: center; }
    md-tabs-canvas.md-center-tabs .md-tab {
      float: none;
      display: inline-block; }

md-pagination-wrapper {
  height: 48px;
  display: flex;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  left: 0;
  transform: translate(0, 0); }
  md-pagination-wrapper:after {
    content: '';
    display: table;
    clear: both; }
  [dir=rtl] md-pagination-wrapper {
    left: auto;
    right: 0; }
  md-pagination-wrapper.md-center-tabs {
    position: relative;
    justify-content: center; }

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

md-tab-content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto;
  transform: translate(0, 0); }
  md-tab-content.md-no-scroll {
    bottom: auto;
    overflow: hidden; }
  md-tab-content.ng-leave, md-tab-content.md-no-transition {
    transition: none; }
  md-tab-content.md-left:not(.md-active) {
    transform: translateX(-100%);
    animation: 1s md-tab-content-hide;
    visibility: hidden; }
    [dir=rtl] md-tab-content.md-left:not(.md-active) {
      transform: translateX(100%); }
    md-tab-content.md-left:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content.md-right:not(.md-active) {
    transform: translateX(100%);
    animation: 1s md-tab-content-hide;
    visibility: hidden; }
    [dir=rtl] md-tab-content.md-right:not(.md-active) {
      transform: translateX(-100%); }
    md-tab-content.md-right:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content > div {
    flex: 1 0 100%;
    min-width: 0; }
    md-tab-content > div.ng-leave {
      animation: 1s md-tab-content-hide; }

md-ink-bar {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px; }
  md-ink-bar.md-left {
    transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }
  md-ink-bar.md-right {
    transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px; }

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  padding: 12px 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis; }
  [dir=rtl] .md-tab {
    float: right; }
  .md-tab.md-focused, .md-tab:focus {
    box-shadow: none;
    outline: none; }
  .md-tab.md-active {
    cursor: default; }
  .md-tab.md-disabled {
    pointer-events: none;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    opacity: 0.5;
    cursor: default; }
  .md-tab.ng-leave {
    transition: none; }

md-toolbar + md-tabs, md-toolbar + md-dialog-content md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.md-toast-text {
  padding: 0 6px; }

md-toast {
  position: absolute;
  z-index: 105;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  padding: 8px;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Transition differently when swiping */
  /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
  md-toast .md-toast-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 168px;
    max-width: 100%;
    min-height: 48px;
    padding: 0 18px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 2px;
    font-size: 14px;
    overflow: hidden;
    transform: translate3d(0, 0, 0) rotateZ(0deg);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    justify-content: flex-start; }
    md-toast .md-toast-content::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    [dir=rtl] md-toast .md-toast-content {
      justify-content: flex-end; }
    md-toast .md-toast-content span {
      flex: 1 1 0%;
      box-sizing: border-box;
      min-width: 0; }
  md-toast.md-capsule {
    border-radius: 24px; }
    md-toast.md-capsule .md-toast-content {
      border-radius: 24px; }
  md-toast.ng-leave-active .md-toast-content {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-toast.md-swipeleft .md-toast-content, md-toast.md-swiperight .md-toast-content, md-toast.md-swipeup .md-toast-content, md-toast.md-swipedown .md-toast-content {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-toast.ng-enter {
    opacity: 0; }
    md-toast.ng-enter .md-toast-content {
      transform: translate3d(0, 100%, 0); }
    md-toast.ng-enter.md-top .md-toast-content {
      transform: translate3d(0, -100%, 0); }
    md-toast.ng-enter.ng-enter-active {
      opacity: 1; }
      md-toast.ng-enter.ng-enter-active .md-toast-content {
        transform: translate3d(0, 0, 0); }
  md-toast.ng-leave.ng-leave-active .md-toast-content {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
    transform: translate3d(0, -50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
    transform: translate3d(0, 50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-top .md-toast-content {
    transform: translate3d(0, -100%, 0); }
  md-toast .md-action {
    line-height: 19px;
    margin-left: 24px;
    margin-right: 0;
    cursor: pointer;
    text-transform: uppercase;
    float: right; }
  md-toast .md-button {
    min-width: 0;
    margin-right: 0;
    margin-left: 12px; }
    [dir=rtl] md-toast .md-button {
      margin-right: 12px; }
    [dir=rtl] md-toast .md-button {
      margin-left: 0; }

@media (max-width: 959px) {
  md-toast {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    border-radius: 0;
    bottom: 0;
    padding: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
      transform: translate3d(0, -50%, 0); }
    md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
      transform: translate3d(0, 50%, 0); } }

@media (min-width: 960px) {
  md-toast {
    min-width: 304px;
    /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
    md-toast.md-bottom {
      bottom: 0; }
    md-toast.md-left {
      left: 0; }
    md-toast.md-right {
      right: 0; }
    md-toast.md-top {
      top: 0; }
    md-toast._md-start {
      left: 0; }
      [dir=rtl] md-toast._md-start {
        left: auto;
        right: 0; }
    md-toast._md-end {
      right: 0; }
      [dir=rtl] md-toast._md-end {
        right: auto;
        left: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeleft .md-toast-content {
      transform: translate3d(-50%, 0, 0); }
    md-toast.ng-leave.ng-leave-active.md-swiperight .md-toast-content {
      transform: translate3d(50%, 0, 0); } }

@media (min-width: 1920px) {
  md-toast .md-toast-content {
    max-width: 568px; } }

@media screen and (-ms-high-contrast: active) {
  md-toast {
    border: 1px solid #fff; } }

.md-toast-animating {
  overflow: hidden !important; }

md-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  min-height: 64px;
  width: 100%; }
  md-toolbar._md-toolbar-transitions {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    transition-property: background-color, fill, color; }
  md-toolbar.md-whiteframe-z1-add, md-toolbar.md-whiteframe-z1-remove {
    transition: box-shadow 0.5s linear; }
  md-toolbar md-toolbar-filler {
    width: 72px; }
  md-toolbar *,
  md-toolbar *:before,
  md-toolbar *:after {
    box-sizing: border-box; }
  md-toolbar.ng-animate {
    transition: none; }
  md-toolbar.md-tall {
    height: 128px;
    min-height: 128px;
    max-height: 128px; }
  md-toolbar.md-medium-tall {
    height: 88px;
    min-height: 88px;
    max-height: 88px; }
    md-toolbar.md-medium-tall .md-toolbar-tools {
      height: 48px;
      min-height: 48px;
      max-height: 48px; }
  md-toolbar > .md-indent {
    margin-left: 64px; }
    [dir=rtl] md-toolbar > .md-indent {
      margin-left: auto;
      margin-right: 64px; }
  md-toolbar ~ md-content > md-list {
    padding: 0; }
    md-toolbar ~ md-content > md-list md-list-item:last-child md-divider {
      display: none; }

.md-toolbar-tools {
  font-size: 20px;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  margin: 0; }
  .md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
    font-size: inherit;
    font-weight: inherit;
    margin: inherit; }
  .md-toolbar-tools a {
    color: inherit;
    text-decoration: none; }
  .md-toolbar-tools .fill-height {
    display: flex;
    align-items: center; }
  .md-toolbar-tools md-checkbox {
    margin: inherit; }
  .md-toolbar-tools .md-button {
    margin-top: 0;
    margin-bottom: 0; }
    .md-toolbar-tools .md-button, .md-toolbar-tools .md-button.md-icon-button md-icon {
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      transition-property: background-color, fill, color; }
      .md-toolbar-tools .md-button.ng-animate, .md-toolbar-tools .md-button.md-icon-button md-icon.ng-animate {
        transition: none; }
  .md-toolbar-tools > .md-button:first-child {
    margin-left: -8px; }
    [dir=rtl] .md-toolbar-tools > .md-button:first-child {
      margin-left: auto;
      margin-right: -8px; }
  .md-toolbar-tools > .md-button:last-child {
    margin-right: -8px; }
    [dir=rtl] .md-toolbar-tools > .md-button:last-child {
      margin-right: auto;
      margin-left: -8px; }
  .md-toolbar-tools > md-menu:last-child {
    margin-right: -8px; }
    [dir=rtl] .md-toolbar-tools > md-menu:last-child {
      margin-right: auto;
      margin-left: -8px; }
    .md-toolbar-tools > md-menu:last-child > .md-button {
      margin-right: 0; }
      [dir=rtl] .md-toolbar-tools > md-menu:last-child > .md-button {
        margin-right: auto;
        margin-left: 0; }
  @media screen and (-ms-high-contrast: active) {
    .md-toolbar-tools {
      border-bottom: 1px solid #fff; } }

@media (min-width: 0) and (max-width: 959px) and (orientation: portrait) {
  md-toolbar {
    min-height: 56px; }
  .md-toolbar-tools {
    height: 56px;
    max-height: 56px; } }

@media (min-width: 0) and (max-width: 959px) and (orientation: landscape) {
  md-toolbar {
    min-height: 48px; }
  .md-toolbar-tools {
    height: 48px;
    max-height: 48px; } }

.md-tooltip {
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  line-height: 32px;
  padding-right: 16px;
  padding-left: 16px; }
  .md-tooltip.md-origin-top {
    transform-origin: center bottom;
    margin-top: -24px; }
  .md-tooltip.md-origin-right {
    transform-origin: left center;
    margin-left: 24px; }
  .md-tooltip.md-origin-bottom {
    transform-origin: center top;
    margin-top: 24px; }
  .md-tooltip.md-origin-left {
    transform-origin: right center;
    margin-left: -24px; }
  @media (min-width: 960px) {
    .md-tooltip {
      font-size: 10px;
      height: 22px;
      line-height: 22px;
      padding-right: 8px;
      padding-left: 8px; }
      .md-tooltip.md-origin-top {
        margin-top: -14px; }
      .md-tooltip.md-origin-right {
        margin-left: 14px; }
      .md-tooltip.md-origin-bottom {
        margin-top: 14px; }
      .md-tooltip.md-origin-left {
        margin-left: -14px; } }
  .md-tooltip.md-show-add {
    transform: scale(0); }
  .md-tooltip.md-show {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 150ms;
    transform: scale(1);
    opacity: 0.9; }
  .md-tooltip.md-hide {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 150ms;
    transform: scale(0);
    opacity: 0; }

.md-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .md-truncate.md-clip {
    text-overflow: clip; }
  .md-truncate.flex {
    width: 0; }

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .md-virtual-repeat-container .md-virtual-repeat-scroller {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-overflow-scrolling: touch; }
  .md-virtual-repeat-container .md-virtual-repeat-sizer {
    box-sizing: border-box;
    height: 1px;
    display: block;
    margin: 0;
    padding: 0;
    width: 1px; }
  .md-virtual-repeat-container .md-virtual-repeat-offsetter {
    box-sizing: border-box;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-scroller {
  overflow-x: auto;
  overflow-y: hidden; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  bottom: 16px;
  right: auto;
  white-space: nowrap; }
  [dir=rtl] .md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
    right: auto;
    left: auto; }

.md-whiteframe-1dp, .md-whiteframe-z1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-2dp {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-3dp {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-4dp, .md-whiteframe-z2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-6dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-7dp, .md-whiteframe-z3 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-9dp {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-10dp, .md-whiteframe-z4 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-11dp {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-12dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-13dp, .md-whiteframe-z5 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-14dp {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-15dp {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-16dp {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-17dp {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-18dp {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-19dp {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-20dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-21dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-22dp {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-23dp {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.md-whiteframe-24dp {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

@media screen and (-ms-high-contrast: active) {
  md-whiteframe {
    border: 1px solid #fff; } }

@media print {
  md-whiteframe, [md-whiteframe] {
    background-color: #ffffff; } }

/*
* Since Layout API uses ng-cloak to hide the dom elements while layouts are adjusted
*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*/
@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; } }

/*
 *  Apply Mixins to create Layout/Flexbox styles
 */
.flex-order {
  order: 0; }

.flex-order--20 {
  order: -20; }

.flex-order--19 {
  order: -19; }

.flex-order--18 {
  order: -18; }

.flex-order--17 {
  order: -17; }

.flex-order--16 {
  order: -16; }

.flex-order--15 {
  order: -15; }

.flex-order--14 {
  order: -14; }

.flex-order--13 {
  order: -13; }

.flex-order--12 {
  order: -12; }

.flex-order--11 {
  order: -11; }

.flex-order--10 {
  order: -10; }

.flex-order--9 {
  order: -9; }

.flex-order--8 {
  order: -8; }

.flex-order--7 {
  order: -7; }

.flex-order--6 {
  order: -6; }

.flex-order--5 {
  order: -5; }

.flex-order--4 {
  order: -4; }

.flex-order--3 {
  order: -3; }

.flex-order--2 {
  order: -2; }

.flex-order--1 {
  order: -1; }

.flex-order-0 {
  order: 0; }

.flex-order-1 {
  order: 1; }

.flex-order-2 {
  order: 2; }

.flex-order-3 {
  order: 3; }

.flex-order-4 {
  order: 4; }

.flex-order-5 {
  order: 5; }

.flex-order-6 {
  order: 6; }

.flex-order-7 {
  order: 7; }

.flex-order-8 {
  order: 8; }

.flex-order-9 {
  order: 9; }

.flex-order-10 {
  order: 10; }

.flex-order-11 {
  order: 11; }

.flex-order-12 {
  order: 12; }

.flex-order-13 {
  order: 13; }

.flex-order-14 {
  order: 14; }

.flex-order-15 {
  order: 15; }

.flex-order-16 {
  order: 16; }

.flex-order-17 {
  order: 17; }

.flex-order-18 {
  order: 18; }

.flex-order-19 {
  order: 19; }

.flex-order-20 {
  order: 20; }

.offset-0, .flex-offset-0, .layout-margin .flex-offset-0, .layout-margin .offset-0 {
  margin-left: 0; }
  [dir=rtl] .offset-0, [dir=rtl] .flex-offset-0, [dir=rtl] .layout-margin .flex-offset-0, [dir=rtl] .layout-margin .offset-0 {
    margin-left: auto;
    margin-right: 0; }

.offset-5, .flex-offset-5, .layout-margin .flex-offset-5, .layout-margin .offset-5 {
  margin-left: 5%; }
  [dir=rtl] .offset-5, [dir=rtl] .flex-offset-5, [dir=rtl] .layout-margin .flex-offset-5, [dir=rtl] .layout-margin .offset-5 {
    margin-left: auto;
    margin-right: 5%; }

.offset-10, .flex-offset-10, .layout-margin .flex-offset-10, .layout-margin .offset-10 {
  margin-left: 10%; }
  [dir=rtl] .offset-10, [dir=rtl] .flex-offset-10, [dir=rtl] .layout-margin .flex-offset-10, [dir=rtl] .layout-margin .offset-10 {
    margin-left: auto;
    margin-right: 10%; }

.offset-15, .flex-offset-15, .layout-margin .flex-offset-15, .layout-margin .offset-15 {
  margin-left: 15%; }
  [dir=rtl] .offset-15, [dir=rtl] .flex-offset-15, [dir=rtl] .layout-margin .flex-offset-15, [dir=rtl] .layout-margin .offset-15 {
    margin-left: auto;
    margin-right: 15%; }

.offset-20, .flex-offset-20, .layout-margin .flex-offset-20, .layout-margin .offset-20 {
  margin-left: 20%; }
  [dir=rtl] .offset-20, [dir=rtl] .flex-offset-20, [dir=rtl] .layout-margin .flex-offset-20, [dir=rtl] .layout-margin .offset-20 {
    margin-left: auto;
    margin-right: 20%; }

.offset-25, .flex-offset-25, .layout-margin .flex-offset-25, .layout-margin .offset-25 {
  margin-left: 25%; }
  [dir=rtl] .offset-25, [dir=rtl] .flex-offset-25, [dir=rtl] .layout-margin .flex-offset-25, [dir=rtl] .layout-margin .offset-25 {
    margin-left: auto;
    margin-right: 25%; }

.offset-30, .flex-offset-30, .layout-margin .flex-offset-30, .layout-margin .offset-30 {
  margin-left: 30%; }
  [dir=rtl] .offset-30, [dir=rtl] .flex-offset-30, [dir=rtl] .layout-margin .flex-offset-30, [dir=rtl] .layout-margin .offset-30 {
    margin-left: auto;
    margin-right: 30%; }

.offset-35, .flex-offset-35, .layout-margin .flex-offset-35, .layout-margin .offset-35 {
  margin-left: 35%; }
  [dir=rtl] .offset-35, [dir=rtl] .flex-offset-35, [dir=rtl] .layout-margin .flex-offset-35, [dir=rtl] .layout-margin .offset-35 {
    margin-left: auto;
    margin-right: 35%; }

.offset-40, .flex-offset-40, .layout-margin .flex-offset-40, .layout-margin .offset-40 {
  margin-left: 40%; }
  [dir=rtl] .offset-40, [dir=rtl] .flex-offset-40, [dir=rtl] .layout-margin .flex-offset-40, [dir=rtl] .layout-margin .offset-40 {
    margin-left: auto;
    margin-right: 40%; }

.offset-45, .flex-offset-45, .layout-margin .flex-offset-45, .layout-margin .offset-45 {
  margin-left: 45%; }
  [dir=rtl] .offset-45, [dir=rtl] .flex-offset-45, [dir=rtl] .layout-margin .flex-offset-45, [dir=rtl] .layout-margin .offset-45 {
    margin-left: auto;
    margin-right: 45%; }

.offset-50, .flex-offset-50, .layout-margin .flex-offset-50, .layout-margin .offset-50 {
  margin-left: 50%; }
  [dir=rtl] .offset-50, [dir=rtl] .flex-offset-50, [dir=rtl] .layout-margin .flex-offset-50, [dir=rtl] .layout-margin .offset-50 {
    margin-left: auto;
    margin-right: 50%; }

.offset-55, .flex-offset-55, .layout-margin .flex-offset-55, .layout-margin .offset-55 {
  margin-left: 55%; }
  [dir=rtl] .offset-55, [dir=rtl] .flex-offset-55, [dir=rtl] .layout-margin .flex-offset-55, [dir=rtl] .layout-margin .offset-55 {
    margin-left: auto;
    margin-right: 55%; }

.offset-60, .flex-offset-60, .layout-margin .flex-offset-60, .layout-margin .offset-60 {
  margin-left: 60%; }
  [dir=rtl] .offset-60, [dir=rtl] .flex-offset-60, [dir=rtl] .layout-margin .flex-offset-60, [dir=rtl] .layout-margin .offset-60 {
    margin-left: auto;
    margin-right: 60%; }

.offset-65, .flex-offset-65, .layout-margin .flex-offset-65, .layout-margin .offset-65 {
  margin-left: 65%; }
  [dir=rtl] .offset-65, [dir=rtl] .flex-offset-65, [dir=rtl] .layout-margin .flex-offset-65, [dir=rtl] .layout-margin .offset-65 {
    margin-left: auto;
    margin-right: 65%; }

.offset-70, .flex-offset-70, .layout-margin .flex-offset-70, .layout-margin .offset-70 {
  margin-left: 70%; }
  [dir=rtl] .offset-70, [dir=rtl] .flex-offset-70, [dir=rtl] .layout-margin .flex-offset-70, [dir=rtl] .layout-margin .offset-70 {
    margin-left: auto;
    margin-right: 70%; }

.offset-75, .flex-offset-75, .layout-margin .flex-offset-75, .layout-margin .offset-75 {
  margin-left: 75%; }
  [dir=rtl] .offset-75, [dir=rtl] .flex-offset-75, [dir=rtl] .layout-margin .flex-offset-75, [dir=rtl] .layout-margin .offset-75 {
    margin-left: auto;
    margin-right: 75%; }

.offset-80, .flex-offset-80, .layout-margin .flex-offset-80, .layout-margin .offset-80 {
  margin-left: 80%; }
  [dir=rtl] .offset-80, [dir=rtl] .flex-offset-80, [dir=rtl] .layout-margin .flex-offset-80, [dir=rtl] .layout-margin .offset-80 {
    margin-left: auto;
    margin-right: 80%; }

.offset-85, .flex-offset-85, .layout-margin .flex-offset-85, .layout-margin .offset-85 {
  margin-left: 85%; }
  [dir=rtl] .offset-85, [dir=rtl] .flex-offset-85, [dir=rtl] .layout-margin .flex-offset-85, [dir=rtl] .layout-margin .offset-85 {
    margin-left: auto;
    margin-right: 85%; }

.offset-90, .flex-offset-90, .layout-margin .flex-offset-90, .layout-margin .offset-90 {
  margin-left: 90%; }
  [dir=rtl] .offset-90, [dir=rtl] .flex-offset-90, [dir=rtl] .layout-margin .flex-offset-90, [dir=rtl] .layout-margin .offset-90 {
    margin-left: auto;
    margin-right: 90%; }

.offset-95, .flex-offset-95, .layout-margin .flex-offset-95, .layout-margin .offset-95 {
  margin-left: 95%; }
  [dir=rtl] .offset-95, [dir=rtl] .flex-offset-95, [dir=rtl] .layout-margin .flex-offset-95, [dir=rtl] .layout-margin .offset-95 {
    margin-left: auto;
    margin-right: 95%; }

.offset-33, .flex-offset-33, .layout-margin .flex-offset-33, .layout-margin .offset-33 {
  margin-left: calc(100% / 3); }

.offset-66, .flex-offset-66, .layout-margin .flex-offset-66, .layout-margin .offset-66 {
  margin-left: calc(200% / 3); }
  [dir=rtl] .offset-66, [dir=rtl] .flex-offset-66, [dir=rtl] .layout-margin .flex-offset-66, [dir=rtl] .layout-margin .offset-66 {
    margin-left: auto;
    margin-right: calc(200% / 3); }

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch; }

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start; }

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center; }

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end; }

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  justify-content: space-around; }

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  justify-content: space-between; }

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  align-items: flex-start;
  align-content: flex-start; }

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%; }

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box; }

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  align-items: flex-end;
  align-content: flex-end; }

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch; }

.flex {
  flex: 1;
  box-sizing: border-box; }

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box; }

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box; }

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box; }

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box; }

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0; }

.layout-column > .flex-0 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box; }

.flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-5 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

.flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-10 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

.flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-15 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

.flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-20 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

.flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-25 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

.flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-30 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

.flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-35 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

.flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-40 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

.flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-45 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

.flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-50 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

.flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-55 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

.flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-60 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

.flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-65 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

.flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-70 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

.flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-75 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

.flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-80 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

.flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-85 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

.flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-90 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

.flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-95 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.flex-33 {
  flex: 1 1 100%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.flex-66 {
  flex: 1 1 100%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-row > .flex-33 {
  flex: 1 1 100%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66 {
  flex: 1 1 100%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex {
  min-width: 0; }

.layout-column > .flex-33 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-column > .flex {
  min-height: 0; }

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.layout-column {
  flex-direction: column; }

.layout-row {
  flex-direction: row; }

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 4px; }

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 8px; }

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 16px; }

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 4px; }

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 8px; }

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 16px; }

.layout-wrap {
  flex-wrap: wrap; }

.layout-nowrap {
  flex-wrap: nowrap; }

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none; }
  .flex-order-xs--20 {
    order: -20; }
  .flex-order-xs--19 {
    order: -19; }
  .flex-order-xs--18 {
    order: -18; }
  .flex-order-xs--17 {
    order: -17; }
  .flex-order-xs--16 {
    order: -16; }
  .flex-order-xs--15 {
    order: -15; }
  .flex-order-xs--14 {
    order: -14; }
  .flex-order-xs--13 {
    order: -13; }
  .flex-order-xs--12 {
    order: -12; }
  .flex-order-xs--11 {
    order: -11; }
  .flex-order-xs--10 {
    order: -10; }
  .flex-order-xs--9 {
    order: -9; }
  .flex-order-xs--8 {
    order: -8; }
  .flex-order-xs--7 {
    order: -7; }
  .flex-order-xs--6 {
    order: -6; }
  .flex-order-xs--5 {
    order: -5; }
  .flex-order-xs--4 {
    order: -4; }
  .flex-order-xs--3 {
    order: -3; }
  .flex-order-xs--2 {
    order: -2; }
  .flex-order-xs--1 {
    order: -1; }
  .flex-order-xs-0 {
    order: 0; }
  .flex-order-xs-1 {
    order: 1; }
  .flex-order-xs-2 {
    order: 2; }
  .flex-order-xs-3 {
    order: 3; }
  .flex-order-xs-4 {
    order: 4; }
  .flex-order-xs-5 {
    order: 5; }
  .flex-order-xs-6 {
    order: 6; }
  .flex-order-xs-7 {
    order: 7; }
  .flex-order-xs-8 {
    order: 8; }
  .flex-order-xs-9 {
    order: 9; }
  .flex-order-xs-10 {
    order: 10; }
  .flex-order-xs-11 {
    order: 11; }
  .flex-order-xs-12 {
    order: 12; }
  .flex-order-xs-13 {
    order: 13; }
  .flex-order-xs-14 {
    order: 14; }
  .flex-order-xs-15 {
    order: 15; }
  .flex-order-xs-16 {
    order: 16; }
  .flex-order-xs-17 {
    order: 17; }
  .flex-order-xs-18 {
    order: 18; }
  .flex-order-xs-19 {
    order: 19; }
  .flex-order-xs-20 {
    order: 20; }
  .offset-xs-0, .flex-offset-xs-0, .layout-margin .flex-offset-xs-0, .layout-margin .offset-xs-0 {
    margin-left: 0; }
    [dir=rtl] .offset-xs-0, [dir=rtl] .flex-offset-xs-0, [dir=rtl] .layout-margin .flex-offset-xs-0, [dir=rtl] .layout-margin .offset-xs-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-xs-5, .flex-offset-xs-5, .layout-margin .flex-offset-xs-5, .layout-margin .offset-xs-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-xs-5, [dir=rtl] .flex-offset-xs-5, [dir=rtl] .layout-margin .flex-offset-xs-5, [dir=rtl] .layout-margin .offset-xs-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-xs-10, .flex-offset-xs-10, .layout-margin .flex-offset-xs-10, .layout-margin .offset-xs-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-xs-10, [dir=rtl] .flex-offset-xs-10, [dir=rtl] .layout-margin .flex-offset-xs-10, [dir=rtl] .layout-margin .offset-xs-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-xs-15, .flex-offset-xs-15, .layout-margin .flex-offset-xs-15, .layout-margin .offset-xs-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-xs-15, [dir=rtl] .flex-offset-xs-15, [dir=rtl] .layout-margin .flex-offset-xs-15, [dir=rtl] .layout-margin .offset-xs-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-xs-20, .flex-offset-xs-20, .layout-margin .flex-offset-xs-20, .layout-margin .offset-xs-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-xs-20, [dir=rtl] .flex-offset-xs-20, [dir=rtl] .layout-margin .flex-offset-xs-20, [dir=rtl] .layout-margin .offset-xs-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-xs-25, .flex-offset-xs-25, .layout-margin .flex-offset-xs-25, .layout-margin .offset-xs-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-xs-25, [dir=rtl] .flex-offset-xs-25, [dir=rtl] .layout-margin .flex-offset-xs-25, [dir=rtl] .layout-margin .offset-xs-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-xs-30, .flex-offset-xs-30, .layout-margin .flex-offset-xs-30, .layout-margin .offset-xs-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-xs-30, [dir=rtl] .flex-offset-xs-30, [dir=rtl] .layout-margin .flex-offset-xs-30, [dir=rtl] .layout-margin .offset-xs-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-xs-35, .flex-offset-xs-35, .layout-margin .flex-offset-xs-35, .layout-margin .offset-xs-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-xs-35, [dir=rtl] .flex-offset-xs-35, [dir=rtl] .layout-margin .flex-offset-xs-35, [dir=rtl] .layout-margin .offset-xs-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-xs-40, .flex-offset-xs-40, .layout-margin .flex-offset-xs-40, .layout-margin .offset-xs-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-xs-40, [dir=rtl] .flex-offset-xs-40, [dir=rtl] .layout-margin .flex-offset-xs-40, [dir=rtl] .layout-margin .offset-xs-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-xs-45, .flex-offset-xs-45, .layout-margin .flex-offset-xs-45, .layout-margin .offset-xs-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-xs-45, [dir=rtl] .flex-offset-xs-45, [dir=rtl] .layout-margin .flex-offset-xs-45, [dir=rtl] .layout-margin .offset-xs-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-xs-50, .flex-offset-xs-50, .layout-margin .flex-offset-xs-50, .layout-margin .offset-xs-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-xs-50, [dir=rtl] .flex-offset-xs-50, [dir=rtl] .layout-margin .flex-offset-xs-50, [dir=rtl] .layout-margin .offset-xs-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-xs-55, .flex-offset-xs-55, .layout-margin .flex-offset-xs-55, .layout-margin .offset-xs-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-xs-55, [dir=rtl] .flex-offset-xs-55, [dir=rtl] .layout-margin .flex-offset-xs-55, [dir=rtl] .layout-margin .offset-xs-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-xs-60, .flex-offset-xs-60, .layout-margin .flex-offset-xs-60, .layout-margin .offset-xs-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-xs-60, [dir=rtl] .flex-offset-xs-60, [dir=rtl] .layout-margin .flex-offset-xs-60, [dir=rtl] .layout-margin .offset-xs-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-xs-65, .flex-offset-xs-65, .layout-margin .flex-offset-xs-65, .layout-margin .offset-xs-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-xs-65, [dir=rtl] .flex-offset-xs-65, [dir=rtl] .layout-margin .flex-offset-xs-65, [dir=rtl] .layout-margin .offset-xs-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-xs-70, .flex-offset-xs-70, .layout-margin .flex-offset-xs-70, .layout-margin .offset-xs-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-xs-70, [dir=rtl] .flex-offset-xs-70, [dir=rtl] .layout-margin .flex-offset-xs-70, [dir=rtl] .layout-margin .offset-xs-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-xs-75, .flex-offset-xs-75, .layout-margin .flex-offset-xs-75, .layout-margin .offset-xs-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-xs-75, [dir=rtl] .flex-offset-xs-75, [dir=rtl] .layout-margin .flex-offset-xs-75, [dir=rtl] .layout-margin .offset-xs-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-xs-80, .flex-offset-xs-80, .layout-margin .flex-offset-xs-80, .layout-margin .offset-xs-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-xs-80, [dir=rtl] .flex-offset-xs-80, [dir=rtl] .layout-margin .flex-offset-xs-80, [dir=rtl] .layout-margin .offset-xs-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-xs-85, .flex-offset-xs-85, .layout-margin .flex-offset-xs-85, .layout-margin .offset-xs-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-xs-85, [dir=rtl] .flex-offset-xs-85, [dir=rtl] .layout-margin .flex-offset-xs-85, [dir=rtl] .layout-margin .offset-xs-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-xs-90, .flex-offset-xs-90, .layout-margin .flex-offset-xs-90, .layout-margin .offset-xs-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-xs-90, [dir=rtl] .flex-offset-xs-90, [dir=rtl] .layout-margin .flex-offset-xs-90, [dir=rtl] .layout-margin .offset-xs-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-xs-95, .flex-offset-xs-95, .layout-margin .flex-offset-xs-95, .layout-margin .offset-xs-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-xs-95, [dir=rtl] .flex-offset-xs-95, [dir=rtl] .layout-margin .flex-offset-xs-95, [dir=rtl] .layout-margin .offset-xs-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-xs-33, .flex-offset-xs-33, .layout-margin .flex-offset-xs-33, .layout-margin .offset-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-xs-66, .flex-offset-xs-66, .layout-margin .flex-offset-xs-66, .layout-margin .offset-xs-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-xs-66, [dir=rtl] .flex-offset-xs-66, [dir=rtl] .layout-margin .flex-offset-xs-66, [dir=rtl] .layout-margin .offset-xs-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-xs,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    justify-content: center; }
  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xs {
    flex: 1;
    box-sizing: border-box; }
  .flex-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-xs-column > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex {
    min-width: 0; }
  .layout-xs-column > .flex-xs-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xs-column > .flex {
    min-height: 0; }
  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xs-column {
    flex-direction: column; }
  .layout-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) {
  .flex-order-gt-xs--20 {
    order: -20; }
  .flex-order-gt-xs--19 {
    order: -19; }
  .flex-order-gt-xs--18 {
    order: -18; }
  .flex-order-gt-xs--17 {
    order: -17; }
  .flex-order-gt-xs--16 {
    order: -16; }
  .flex-order-gt-xs--15 {
    order: -15; }
  .flex-order-gt-xs--14 {
    order: -14; }
  .flex-order-gt-xs--13 {
    order: -13; }
  .flex-order-gt-xs--12 {
    order: -12; }
  .flex-order-gt-xs--11 {
    order: -11; }
  .flex-order-gt-xs--10 {
    order: -10; }
  .flex-order-gt-xs--9 {
    order: -9; }
  .flex-order-gt-xs--8 {
    order: -8; }
  .flex-order-gt-xs--7 {
    order: -7; }
  .flex-order-gt-xs--6 {
    order: -6; }
  .flex-order-gt-xs--5 {
    order: -5; }
  .flex-order-gt-xs--4 {
    order: -4; }
  .flex-order-gt-xs--3 {
    order: -3; }
  .flex-order-gt-xs--2 {
    order: -2; }
  .flex-order-gt-xs--1 {
    order: -1; }
  .flex-order-gt-xs-0 {
    order: 0; }
  .flex-order-gt-xs-1 {
    order: 1; }
  .flex-order-gt-xs-2 {
    order: 2; }
  .flex-order-gt-xs-3 {
    order: 3; }
  .flex-order-gt-xs-4 {
    order: 4; }
  .flex-order-gt-xs-5 {
    order: 5; }
  .flex-order-gt-xs-6 {
    order: 6; }
  .flex-order-gt-xs-7 {
    order: 7; }
  .flex-order-gt-xs-8 {
    order: 8; }
  .flex-order-gt-xs-9 {
    order: 9; }
  .flex-order-gt-xs-10 {
    order: 10; }
  .flex-order-gt-xs-11 {
    order: 11; }
  .flex-order-gt-xs-12 {
    order: 12; }
  .flex-order-gt-xs-13 {
    order: 13; }
  .flex-order-gt-xs-14 {
    order: 14; }
  .flex-order-gt-xs-15 {
    order: 15; }
  .flex-order-gt-xs-16 {
    order: 16; }
  .flex-order-gt-xs-17 {
    order: 17; }
  .flex-order-gt-xs-18 {
    order: 18; }
  .flex-order-gt-xs-19 {
    order: 19; }
  .flex-order-gt-xs-20 {
    order: 20; }
  .offset-gt-xs-0, .flex-offset-gt-xs-0, .layout-margin .flex-offset-gt-xs-0, .layout-margin .offset-gt-xs-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-xs-0, [dir=rtl] .flex-offset-gt-xs-0, [dir=rtl] .layout-margin .flex-offset-gt-xs-0, [dir=rtl] .layout-margin .offset-gt-xs-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-xs-5, .flex-offset-gt-xs-5, .layout-margin .flex-offset-gt-xs-5, .layout-margin .offset-gt-xs-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-xs-5, [dir=rtl] .flex-offset-gt-xs-5, [dir=rtl] .layout-margin .flex-offset-gt-xs-5, [dir=rtl] .layout-margin .offset-gt-xs-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-xs-10, .flex-offset-gt-xs-10, .layout-margin .flex-offset-gt-xs-10, .layout-margin .offset-gt-xs-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-xs-10, [dir=rtl] .flex-offset-gt-xs-10, [dir=rtl] .layout-margin .flex-offset-gt-xs-10, [dir=rtl] .layout-margin .offset-gt-xs-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-xs-15, .flex-offset-gt-xs-15, .layout-margin .flex-offset-gt-xs-15, .layout-margin .offset-gt-xs-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-xs-15, [dir=rtl] .flex-offset-gt-xs-15, [dir=rtl] .layout-margin .flex-offset-gt-xs-15, [dir=rtl] .layout-margin .offset-gt-xs-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-xs-20, .flex-offset-gt-xs-20, .layout-margin .flex-offset-gt-xs-20, .layout-margin .offset-gt-xs-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-xs-20, [dir=rtl] .flex-offset-gt-xs-20, [dir=rtl] .layout-margin .flex-offset-gt-xs-20, [dir=rtl] .layout-margin .offset-gt-xs-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-xs-25, .flex-offset-gt-xs-25, .layout-margin .flex-offset-gt-xs-25, .layout-margin .offset-gt-xs-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-xs-25, [dir=rtl] .flex-offset-gt-xs-25, [dir=rtl] .layout-margin .flex-offset-gt-xs-25, [dir=rtl] .layout-margin .offset-gt-xs-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-xs-30, .flex-offset-gt-xs-30, .layout-margin .flex-offset-gt-xs-30, .layout-margin .offset-gt-xs-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-xs-30, [dir=rtl] .flex-offset-gt-xs-30, [dir=rtl] .layout-margin .flex-offset-gt-xs-30, [dir=rtl] .layout-margin .offset-gt-xs-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-xs-35, .flex-offset-gt-xs-35, .layout-margin .flex-offset-gt-xs-35, .layout-margin .offset-gt-xs-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-xs-35, [dir=rtl] .flex-offset-gt-xs-35, [dir=rtl] .layout-margin .flex-offset-gt-xs-35, [dir=rtl] .layout-margin .offset-gt-xs-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-xs-40, .flex-offset-gt-xs-40, .layout-margin .flex-offset-gt-xs-40, .layout-margin .offset-gt-xs-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-xs-40, [dir=rtl] .flex-offset-gt-xs-40, [dir=rtl] .layout-margin .flex-offset-gt-xs-40, [dir=rtl] .layout-margin .offset-gt-xs-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-xs-45, .flex-offset-gt-xs-45, .layout-margin .flex-offset-gt-xs-45, .layout-margin .offset-gt-xs-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-xs-45, [dir=rtl] .flex-offset-gt-xs-45, [dir=rtl] .layout-margin .flex-offset-gt-xs-45, [dir=rtl] .layout-margin .offset-gt-xs-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-xs-50, .flex-offset-gt-xs-50, .layout-margin .flex-offset-gt-xs-50, .layout-margin .offset-gt-xs-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-xs-50, [dir=rtl] .flex-offset-gt-xs-50, [dir=rtl] .layout-margin .flex-offset-gt-xs-50, [dir=rtl] .layout-margin .offset-gt-xs-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-xs-55, .flex-offset-gt-xs-55, .layout-margin .flex-offset-gt-xs-55, .layout-margin .offset-gt-xs-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-xs-55, [dir=rtl] .flex-offset-gt-xs-55, [dir=rtl] .layout-margin .flex-offset-gt-xs-55, [dir=rtl] .layout-margin .offset-gt-xs-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-xs-60, .flex-offset-gt-xs-60, .layout-margin .flex-offset-gt-xs-60, .layout-margin .offset-gt-xs-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-xs-60, [dir=rtl] .flex-offset-gt-xs-60, [dir=rtl] .layout-margin .flex-offset-gt-xs-60, [dir=rtl] .layout-margin .offset-gt-xs-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-xs-65, .flex-offset-gt-xs-65, .layout-margin .flex-offset-gt-xs-65, .layout-margin .offset-gt-xs-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-xs-65, [dir=rtl] .flex-offset-gt-xs-65, [dir=rtl] .layout-margin .flex-offset-gt-xs-65, [dir=rtl] .layout-margin .offset-gt-xs-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-xs-70, .flex-offset-gt-xs-70, .layout-margin .flex-offset-gt-xs-70, .layout-margin .offset-gt-xs-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-xs-70, [dir=rtl] .flex-offset-gt-xs-70, [dir=rtl] .layout-margin .flex-offset-gt-xs-70, [dir=rtl] .layout-margin .offset-gt-xs-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-xs-75, .flex-offset-gt-xs-75, .layout-margin .flex-offset-gt-xs-75, .layout-margin .offset-gt-xs-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-xs-75, [dir=rtl] .flex-offset-gt-xs-75, [dir=rtl] .layout-margin .flex-offset-gt-xs-75, [dir=rtl] .layout-margin .offset-gt-xs-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-xs-80, .flex-offset-gt-xs-80, .layout-margin .flex-offset-gt-xs-80, .layout-margin .offset-gt-xs-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-xs-80, [dir=rtl] .flex-offset-gt-xs-80, [dir=rtl] .layout-margin .flex-offset-gt-xs-80, [dir=rtl] .layout-margin .offset-gt-xs-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-xs-85, .flex-offset-gt-xs-85, .layout-margin .flex-offset-gt-xs-85, .layout-margin .offset-gt-xs-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-xs-85, [dir=rtl] .flex-offset-gt-xs-85, [dir=rtl] .layout-margin .flex-offset-gt-xs-85, [dir=rtl] .layout-margin .offset-gt-xs-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-xs-90, .flex-offset-gt-xs-90, .layout-margin .flex-offset-gt-xs-90, .layout-margin .offset-gt-xs-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-xs-90, [dir=rtl] .flex-offset-gt-xs-90, [dir=rtl] .layout-margin .flex-offset-gt-xs-90, [dir=rtl] .layout-margin .offset-gt-xs-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-xs-95, .flex-offset-gt-xs-95, .layout-margin .flex-offset-gt-xs-95, .layout-margin .offset-gt-xs-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-xs-95, [dir=rtl] .flex-offset-gt-xs-95, [dir=rtl] .layout-margin .flex-offset-gt-xs-95, [dir=rtl] .layout-margin .offset-gt-xs-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-xs-33, .flex-offset-gt-xs-33, .layout-margin .flex-offset-gt-xs-33, .layout-margin .offset-gt-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-xs-66, .flex-offset-gt-xs-66, .layout-margin .flex-offset-gt-xs-66, .layout-margin .offset-gt-xs-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-xs-66, [dir=rtl] .flex-offset-gt-xs-66, [dir=rtl] .layout-margin .flex-offset-gt-xs-66, [dir=rtl] .layout-margin .offset-gt-xs-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    justify-content: center; }
  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-xs {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-xs-column > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex {
    min-width: 0; }
  .layout-gt-xs-column > .flex-gt-xs-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex {
    min-height: 0; }
  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-xs-column {
    flex-direction: column; }
  .layout-gt-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) and (max-width: 959px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .flex-order-sm--20 {
    order: -20; }
  .flex-order-sm--19 {
    order: -19; }
  .flex-order-sm--18 {
    order: -18; }
  .flex-order-sm--17 {
    order: -17; }
  .flex-order-sm--16 {
    order: -16; }
  .flex-order-sm--15 {
    order: -15; }
  .flex-order-sm--14 {
    order: -14; }
  .flex-order-sm--13 {
    order: -13; }
  .flex-order-sm--12 {
    order: -12; }
  .flex-order-sm--11 {
    order: -11; }
  .flex-order-sm--10 {
    order: -10; }
  .flex-order-sm--9 {
    order: -9; }
  .flex-order-sm--8 {
    order: -8; }
  .flex-order-sm--7 {
    order: -7; }
  .flex-order-sm--6 {
    order: -6; }
  .flex-order-sm--5 {
    order: -5; }
  .flex-order-sm--4 {
    order: -4; }
  .flex-order-sm--3 {
    order: -3; }
  .flex-order-sm--2 {
    order: -2; }
  .flex-order-sm--1 {
    order: -1; }
  .flex-order-sm-0 {
    order: 0; }
  .flex-order-sm-1 {
    order: 1; }
  .flex-order-sm-2 {
    order: 2; }
  .flex-order-sm-3 {
    order: 3; }
  .flex-order-sm-4 {
    order: 4; }
  .flex-order-sm-5 {
    order: 5; }
  .flex-order-sm-6 {
    order: 6; }
  .flex-order-sm-7 {
    order: 7; }
  .flex-order-sm-8 {
    order: 8; }
  .flex-order-sm-9 {
    order: 9; }
  .flex-order-sm-10 {
    order: 10; }
  .flex-order-sm-11 {
    order: 11; }
  .flex-order-sm-12 {
    order: 12; }
  .flex-order-sm-13 {
    order: 13; }
  .flex-order-sm-14 {
    order: 14; }
  .flex-order-sm-15 {
    order: 15; }
  .flex-order-sm-16 {
    order: 16; }
  .flex-order-sm-17 {
    order: 17; }
  .flex-order-sm-18 {
    order: 18; }
  .flex-order-sm-19 {
    order: 19; }
  .flex-order-sm-20 {
    order: 20; }
  .offset-sm-0, .flex-offset-sm-0, .layout-margin .flex-offset-sm-0, .layout-margin .offset-sm-0 {
    margin-left: 0; }
    [dir=rtl] .offset-sm-0, [dir=rtl] .flex-offset-sm-0, [dir=rtl] .layout-margin .flex-offset-sm-0, [dir=rtl] .layout-margin .offset-sm-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-sm-5, .flex-offset-sm-5, .layout-margin .flex-offset-sm-5, .layout-margin .offset-sm-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-sm-5, [dir=rtl] .flex-offset-sm-5, [dir=rtl] .layout-margin .flex-offset-sm-5, [dir=rtl] .layout-margin .offset-sm-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-sm-10, .flex-offset-sm-10, .layout-margin .flex-offset-sm-10, .layout-margin .offset-sm-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-sm-10, [dir=rtl] .flex-offset-sm-10, [dir=rtl] .layout-margin .flex-offset-sm-10, [dir=rtl] .layout-margin .offset-sm-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-sm-15, .flex-offset-sm-15, .layout-margin .flex-offset-sm-15, .layout-margin .offset-sm-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-sm-15, [dir=rtl] .flex-offset-sm-15, [dir=rtl] .layout-margin .flex-offset-sm-15, [dir=rtl] .layout-margin .offset-sm-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-sm-20, .flex-offset-sm-20, .layout-margin .flex-offset-sm-20, .layout-margin .offset-sm-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-sm-20, [dir=rtl] .flex-offset-sm-20, [dir=rtl] .layout-margin .flex-offset-sm-20, [dir=rtl] .layout-margin .offset-sm-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-sm-25, .flex-offset-sm-25, .layout-margin .flex-offset-sm-25, .layout-margin .offset-sm-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-sm-25, [dir=rtl] .flex-offset-sm-25, [dir=rtl] .layout-margin .flex-offset-sm-25, [dir=rtl] .layout-margin .offset-sm-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-sm-30, .flex-offset-sm-30, .layout-margin .flex-offset-sm-30, .layout-margin .offset-sm-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-sm-30, [dir=rtl] .flex-offset-sm-30, [dir=rtl] .layout-margin .flex-offset-sm-30, [dir=rtl] .layout-margin .offset-sm-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-sm-35, .flex-offset-sm-35, .layout-margin .flex-offset-sm-35, .layout-margin .offset-sm-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-sm-35, [dir=rtl] .flex-offset-sm-35, [dir=rtl] .layout-margin .flex-offset-sm-35, [dir=rtl] .layout-margin .offset-sm-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-sm-40, .flex-offset-sm-40, .layout-margin .flex-offset-sm-40, .layout-margin .offset-sm-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-sm-40, [dir=rtl] .flex-offset-sm-40, [dir=rtl] .layout-margin .flex-offset-sm-40, [dir=rtl] .layout-margin .offset-sm-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-sm-45, .flex-offset-sm-45, .layout-margin .flex-offset-sm-45, .layout-margin .offset-sm-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-sm-45, [dir=rtl] .flex-offset-sm-45, [dir=rtl] .layout-margin .flex-offset-sm-45, [dir=rtl] .layout-margin .offset-sm-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-sm-50, .flex-offset-sm-50, .layout-margin .flex-offset-sm-50, .layout-margin .offset-sm-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-sm-50, [dir=rtl] .flex-offset-sm-50, [dir=rtl] .layout-margin .flex-offset-sm-50, [dir=rtl] .layout-margin .offset-sm-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-sm-55, .flex-offset-sm-55, .layout-margin .flex-offset-sm-55, .layout-margin .offset-sm-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-sm-55, [dir=rtl] .flex-offset-sm-55, [dir=rtl] .layout-margin .flex-offset-sm-55, [dir=rtl] .layout-margin .offset-sm-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-sm-60, .flex-offset-sm-60, .layout-margin .flex-offset-sm-60, .layout-margin .offset-sm-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-sm-60, [dir=rtl] .flex-offset-sm-60, [dir=rtl] .layout-margin .flex-offset-sm-60, [dir=rtl] .layout-margin .offset-sm-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-sm-65, .flex-offset-sm-65, .layout-margin .flex-offset-sm-65, .layout-margin .offset-sm-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-sm-65, [dir=rtl] .flex-offset-sm-65, [dir=rtl] .layout-margin .flex-offset-sm-65, [dir=rtl] .layout-margin .offset-sm-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-sm-70, .flex-offset-sm-70, .layout-margin .flex-offset-sm-70, .layout-margin .offset-sm-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-sm-70, [dir=rtl] .flex-offset-sm-70, [dir=rtl] .layout-margin .flex-offset-sm-70, [dir=rtl] .layout-margin .offset-sm-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-sm-75, .flex-offset-sm-75, .layout-margin .flex-offset-sm-75, .layout-margin .offset-sm-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-sm-75, [dir=rtl] .flex-offset-sm-75, [dir=rtl] .layout-margin .flex-offset-sm-75, [dir=rtl] .layout-margin .offset-sm-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-sm-80, .flex-offset-sm-80, .layout-margin .flex-offset-sm-80, .layout-margin .offset-sm-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-sm-80, [dir=rtl] .flex-offset-sm-80, [dir=rtl] .layout-margin .flex-offset-sm-80, [dir=rtl] .layout-margin .offset-sm-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-sm-85, .flex-offset-sm-85, .layout-margin .flex-offset-sm-85, .layout-margin .offset-sm-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-sm-85, [dir=rtl] .flex-offset-sm-85, [dir=rtl] .layout-margin .flex-offset-sm-85, [dir=rtl] .layout-margin .offset-sm-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-sm-90, .flex-offset-sm-90, .layout-margin .flex-offset-sm-90, .layout-margin .offset-sm-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-sm-90, [dir=rtl] .flex-offset-sm-90, [dir=rtl] .layout-margin .flex-offset-sm-90, [dir=rtl] .layout-margin .offset-sm-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-sm-95, .flex-offset-sm-95, .layout-margin .flex-offset-sm-95, .layout-margin .offset-sm-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-sm-95, [dir=rtl] .flex-offset-sm-95, [dir=rtl] .layout-margin .flex-offset-sm-95, [dir=rtl] .layout-margin .offset-sm-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-sm-33, .flex-offset-sm-33, .layout-margin .flex-offset-sm-33, .layout-margin .offset-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-sm-66, .flex-offset-sm-66, .layout-margin .flex-offset-sm-66, .layout-margin .offset-sm-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-sm-66, [dir=rtl] .flex-offset-sm-66, [dir=rtl] .layout-margin .flex-offset-sm-66, [dir=rtl] .layout-margin .offset-sm-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-sm,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    justify-content: center; }
  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-sm {
    flex: 1;
    box-sizing: border-box; }
  .flex-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-sm-column > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex {
    min-width: 0; }
  .layout-sm-column > .flex-sm-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-sm-column > .flex {
    min-height: 0; }
  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-sm-column {
    flex-direction: column; }
  .layout-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) {
  .flex-order-gt-sm--20 {
    order: -20; }
  .flex-order-gt-sm--19 {
    order: -19; }
  .flex-order-gt-sm--18 {
    order: -18; }
  .flex-order-gt-sm--17 {
    order: -17; }
  .flex-order-gt-sm--16 {
    order: -16; }
  .flex-order-gt-sm--15 {
    order: -15; }
  .flex-order-gt-sm--14 {
    order: -14; }
  .flex-order-gt-sm--13 {
    order: -13; }
  .flex-order-gt-sm--12 {
    order: -12; }
  .flex-order-gt-sm--11 {
    order: -11; }
  .flex-order-gt-sm--10 {
    order: -10; }
  .flex-order-gt-sm--9 {
    order: -9; }
  .flex-order-gt-sm--8 {
    order: -8; }
  .flex-order-gt-sm--7 {
    order: -7; }
  .flex-order-gt-sm--6 {
    order: -6; }
  .flex-order-gt-sm--5 {
    order: -5; }
  .flex-order-gt-sm--4 {
    order: -4; }
  .flex-order-gt-sm--3 {
    order: -3; }
  .flex-order-gt-sm--2 {
    order: -2; }
  .flex-order-gt-sm--1 {
    order: -1; }
  .flex-order-gt-sm-0 {
    order: 0; }
  .flex-order-gt-sm-1 {
    order: 1; }
  .flex-order-gt-sm-2 {
    order: 2; }
  .flex-order-gt-sm-3 {
    order: 3; }
  .flex-order-gt-sm-4 {
    order: 4; }
  .flex-order-gt-sm-5 {
    order: 5; }
  .flex-order-gt-sm-6 {
    order: 6; }
  .flex-order-gt-sm-7 {
    order: 7; }
  .flex-order-gt-sm-8 {
    order: 8; }
  .flex-order-gt-sm-9 {
    order: 9; }
  .flex-order-gt-sm-10 {
    order: 10; }
  .flex-order-gt-sm-11 {
    order: 11; }
  .flex-order-gt-sm-12 {
    order: 12; }
  .flex-order-gt-sm-13 {
    order: 13; }
  .flex-order-gt-sm-14 {
    order: 14; }
  .flex-order-gt-sm-15 {
    order: 15; }
  .flex-order-gt-sm-16 {
    order: 16; }
  .flex-order-gt-sm-17 {
    order: 17; }
  .flex-order-gt-sm-18 {
    order: 18; }
  .flex-order-gt-sm-19 {
    order: 19; }
  .flex-order-gt-sm-20 {
    order: 20; }
  .offset-gt-sm-0, .flex-offset-gt-sm-0, .layout-margin .flex-offset-gt-sm-0, .layout-margin .offset-gt-sm-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-sm-0, [dir=rtl] .flex-offset-gt-sm-0, [dir=rtl] .layout-margin .flex-offset-gt-sm-0, [dir=rtl] .layout-margin .offset-gt-sm-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-sm-5, .flex-offset-gt-sm-5, .layout-margin .flex-offset-gt-sm-5, .layout-margin .offset-gt-sm-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-sm-5, [dir=rtl] .flex-offset-gt-sm-5, [dir=rtl] .layout-margin .flex-offset-gt-sm-5, [dir=rtl] .layout-margin .offset-gt-sm-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-sm-10, .flex-offset-gt-sm-10, .layout-margin .flex-offset-gt-sm-10, .layout-margin .offset-gt-sm-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-sm-10, [dir=rtl] .flex-offset-gt-sm-10, [dir=rtl] .layout-margin .flex-offset-gt-sm-10, [dir=rtl] .layout-margin .offset-gt-sm-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-sm-15, .flex-offset-gt-sm-15, .layout-margin .flex-offset-gt-sm-15, .layout-margin .offset-gt-sm-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-sm-15, [dir=rtl] .flex-offset-gt-sm-15, [dir=rtl] .layout-margin .flex-offset-gt-sm-15, [dir=rtl] .layout-margin .offset-gt-sm-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-sm-20, .flex-offset-gt-sm-20, .layout-margin .flex-offset-gt-sm-20, .layout-margin .offset-gt-sm-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-sm-20, [dir=rtl] .flex-offset-gt-sm-20, [dir=rtl] .layout-margin .flex-offset-gt-sm-20, [dir=rtl] .layout-margin .offset-gt-sm-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-sm-25, .flex-offset-gt-sm-25, .layout-margin .flex-offset-gt-sm-25, .layout-margin .offset-gt-sm-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-sm-25, [dir=rtl] .flex-offset-gt-sm-25, [dir=rtl] .layout-margin .flex-offset-gt-sm-25, [dir=rtl] .layout-margin .offset-gt-sm-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-sm-30, .flex-offset-gt-sm-30, .layout-margin .flex-offset-gt-sm-30, .layout-margin .offset-gt-sm-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-sm-30, [dir=rtl] .flex-offset-gt-sm-30, [dir=rtl] .layout-margin .flex-offset-gt-sm-30, [dir=rtl] .layout-margin .offset-gt-sm-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-sm-35, .flex-offset-gt-sm-35, .layout-margin .flex-offset-gt-sm-35, .layout-margin .offset-gt-sm-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-sm-35, [dir=rtl] .flex-offset-gt-sm-35, [dir=rtl] .layout-margin .flex-offset-gt-sm-35, [dir=rtl] .layout-margin .offset-gt-sm-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-sm-40, .flex-offset-gt-sm-40, .layout-margin .flex-offset-gt-sm-40, .layout-margin .offset-gt-sm-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-sm-40, [dir=rtl] .flex-offset-gt-sm-40, [dir=rtl] .layout-margin .flex-offset-gt-sm-40, [dir=rtl] .layout-margin .offset-gt-sm-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-sm-45, .flex-offset-gt-sm-45, .layout-margin .flex-offset-gt-sm-45, .layout-margin .offset-gt-sm-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-sm-45, [dir=rtl] .flex-offset-gt-sm-45, [dir=rtl] .layout-margin .flex-offset-gt-sm-45, [dir=rtl] .layout-margin .offset-gt-sm-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-sm-50, .flex-offset-gt-sm-50, .layout-margin .flex-offset-gt-sm-50, .layout-margin .offset-gt-sm-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-sm-50, [dir=rtl] .flex-offset-gt-sm-50, [dir=rtl] .layout-margin .flex-offset-gt-sm-50, [dir=rtl] .layout-margin .offset-gt-sm-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-sm-55, .flex-offset-gt-sm-55, .layout-margin .flex-offset-gt-sm-55, .layout-margin .offset-gt-sm-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-sm-55, [dir=rtl] .flex-offset-gt-sm-55, [dir=rtl] .layout-margin .flex-offset-gt-sm-55, [dir=rtl] .layout-margin .offset-gt-sm-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-sm-60, .flex-offset-gt-sm-60, .layout-margin .flex-offset-gt-sm-60, .layout-margin .offset-gt-sm-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-sm-60, [dir=rtl] .flex-offset-gt-sm-60, [dir=rtl] .layout-margin .flex-offset-gt-sm-60, [dir=rtl] .layout-margin .offset-gt-sm-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-sm-65, .flex-offset-gt-sm-65, .layout-margin .flex-offset-gt-sm-65, .layout-margin .offset-gt-sm-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-sm-65, [dir=rtl] .flex-offset-gt-sm-65, [dir=rtl] .layout-margin .flex-offset-gt-sm-65, [dir=rtl] .layout-margin .offset-gt-sm-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-sm-70, .flex-offset-gt-sm-70, .layout-margin .flex-offset-gt-sm-70, .layout-margin .offset-gt-sm-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-sm-70, [dir=rtl] .flex-offset-gt-sm-70, [dir=rtl] .layout-margin .flex-offset-gt-sm-70, [dir=rtl] .layout-margin .offset-gt-sm-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-sm-75, .flex-offset-gt-sm-75, .layout-margin .flex-offset-gt-sm-75, .layout-margin .offset-gt-sm-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-sm-75, [dir=rtl] .flex-offset-gt-sm-75, [dir=rtl] .layout-margin .flex-offset-gt-sm-75, [dir=rtl] .layout-margin .offset-gt-sm-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-sm-80, .flex-offset-gt-sm-80, .layout-margin .flex-offset-gt-sm-80, .layout-margin .offset-gt-sm-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-sm-80, [dir=rtl] .flex-offset-gt-sm-80, [dir=rtl] .layout-margin .flex-offset-gt-sm-80, [dir=rtl] .layout-margin .offset-gt-sm-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-sm-85, .flex-offset-gt-sm-85, .layout-margin .flex-offset-gt-sm-85, .layout-margin .offset-gt-sm-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-sm-85, [dir=rtl] .flex-offset-gt-sm-85, [dir=rtl] .layout-margin .flex-offset-gt-sm-85, [dir=rtl] .layout-margin .offset-gt-sm-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-sm-90, .flex-offset-gt-sm-90, .layout-margin .flex-offset-gt-sm-90, .layout-margin .offset-gt-sm-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-sm-90, [dir=rtl] .flex-offset-gt-sm-90, [dir=rtl] .layout-margin .flex-offset-gt-sm-90, [dir=rtl] .layout-margin .offset-gt-sm-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-sm-95, .flex-offset-gt-sm-95, .layout-margin .flex-offset-gt-sm-95, .layout-margin .offset-gt-sm-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-sm-95, [dir=rtl] .flex-offset-gt-sm-95, [dir=rtl] .layout-margin .flex-offset-gt-sm-95, [dir=rtl] .layout-margin .offset-gt-sm-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-sm-33, .flex-offset-gt-sm-33, .layout-margin .flex-offset-gt-sm-33, .layout-margin .offset-gt-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-sm-66, .flex-offset-gt-sm-66, .layout-margin .flex-offset-gt-sm-66, .layout-margin .offset-gt-sm-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-sm-66, [dir=rtl] .flex-offset-gt-sm-66, [dir=rtl] .layout-margin .flex-offset-gt-sm-66, [dir=rtl] .layout-margin .offset-gt-sm-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    justify-content: center; }
  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-sm {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-sm-column > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex {
    min-width: 0; }
  .layout-gt-sm-column > .flex-gt-sm-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex {
    min-height: 0; }
  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-sm-column {
    flex-direction: column; }
  .layout-gt-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none; }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-md--20 {
    order: -20; }
  .flex-order-md--19 {
    order: -19; }
  .flex-order-md--18 {
    order: -18; }
  .flex-order-md--17 {
    order: -17; }
  .flex-order-md--16 {
    order: -16; }
  .flex-order-md--15 {
    order: -15; }
  .flex-order-md--14 {
    order: -14; }
  .flex-order-md--13 {
    order: -13; }
  .flex-order-md--12 {
    order: -12; }
  .flex-order-md--11 {
    order: -11; }
  .flex-order-md--10 {
    order: -10; }
  .flex-order-md--9 {
    order: -9; }
  .flex-order-md--8 {
    order: -8; }
  .flex-order-md--7 {
    order: -7; }
  .flex-order-md--6 {
    order: -6; }
  .flex-order-md--5 {
    order: -5; }
  .flex-order-md--4 {
    order: -4; }
  .flex-order-md--3 {
    order: -3; }
  .flex-order-md--2 {
    order: -2; }
  .flex-order-md--1 {
    order: -1; }
  .flex-order-md-0 {
    order: 0; }
  .flex-order-md-1 {
    order: 1; }
  .flex-order-md-2 {
    order: 2; }
  .flex-order-md-3 {
    order: 3; }
  .flex-order-md-4 {
    order: 4; }
  .flex-order-md-5 {
    order: 5; }
  .flex-order-md-6 {
    order: 6; }
  .flex-order-md-7 {
    order: 7; }
  .flex-order-md-8 {
    order: 8; }
  .flex-order-md-9 {
    order: 9; }
  .flex-order-md-10 {
    order: 10; }
  .flex-order-md-11 {
    order: 11; }
  .flex-order-md-12 {
    order: 12; }
  .flex-order-md-13 {
    order: 13; }
  .flex-order-md-14 {
    order: 14; }
  .flex-order-md-15 {
    order: 15; }
  .flex-order-md-16 {
    order: 16; }
  .flex-order-md-17 {
    order: 17; }
  .flex-order-md-18 {
    order: 18; }
  .flex-order-md-19 {
    order: 19; }
  .flex-order-md-20 {
    order: 20; }
  .offset-md-0, .flex-offset-md-0, .layout-margin .flex-offset-md-0, .layout-margin .offset-md-0 {
    margin-left: 0; }
    [dir=rtl] .offset-md-0, [dir=rtl] .flex-offset-md-0, [dir=rtl] .layout-margin .flex-offset-md-0, [dir=rtl] .layout-margin .offset-md-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-md-5, .flex-offset-md-5, .layout-margin .flex-offset-md-5, .layout-margin .offset-md-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-md-5, [dir=rtl] .flex-offset-md-5, [dir=rtl] .layout-margin .flex-offset-md-5, [dir=rtl] .layout-margin .offset-md-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-md-10, .flex-offset-md-10, .layout-margin .flex-offset-md-10, .layout-margin .offset-md-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-md-10, [dir=rtl] .flex-offset-md-10, [dir=rtl] .layout-margin .flex-offset-md-10, [dir=rtl] .layout-margin .offset-md-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-md-15, .flex-offset-md-15, .layout-margin .flex-offset-md-15, .layout-margin .offset-md-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-md-15, [dir=rtl] .flex-offset-md-15, [dir=rtl] .layout-margin .flex-offset-md-15, [dir=rtl] .layout-margin .offset-md-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-md-20, .flex-offset-md-20, .layout-margin .flex-offset-md-20, .layout-margin .offset-md-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-md-20, [dir=rtl] .flex-offset-md-20, [dir=rtl] .layout-margin .flex-offset-md-20, [dir=rtl] .layout-margin .offset-md-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-md-25, .flex-offset-md-25, .layout-margin .flex-offset-md-25, .layout-margin .offset-md-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-md-25, [dir=rtl] .flex-offset-md-25, [dir=rtl] .layout-margin .flex-offset-md-25, [dir=rtl] .layout-margin .offset-md-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-md-30, .flex-offset-md-30, .layout-margin .flex-offset-md-30, .layout-margin .offset-md-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-md-30, [dir=rtl] .flex-offset-md-30, [dir=rtl] .layout-margin .flex-offset-md-30, [dir=rtl] .layout-margin .offset-md-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-md-35, .flex-offset-md-35, .layout-margin .flex-offset-md-35, .layout-margin .offset-md-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-md-35, [dir=rtl] .flex-offset-md-35, [dir=rtl] .layout-margin .flex-offset-md-35, [dir=rtl] .layout-margin .offset-md-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-md-40, .flex-offset-md-40, .layout-margin .flex-offset-md-40, .layout-margin .offset-md-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-md-40, [dir=rtl] .flex-offset-md-40, [dir=rtl] .layout-margin .flex-offset-md-40, [dir=rtl] .layout-margin .offset-md-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-md-45, .flex-offset-md-45, .layout-margin .flex-offset-md-45, .layout-margin .offset-md-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-md-45, [dir=rtl] .flex-offset-md-45, [dir=rtl] .layout-margin .flex-offset-md-45, [dir=rtl] .layout-margin .offset-md-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-md-50, .flex-offset-md-50, .layout-margin .flex-offset-md-50, .layout-margin .offset-md-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-md-50, [dir=rtl] .flex-offset-md-50, [dir=rtl] .layout-margin .flex-offset-md-50, [dir=rtl] .layout-margin .offset-md-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-md-55, .flex-offset-md-55, .layout-margin .flex-offset-md-55, .layout-margin .offset-md-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-md-55, [dir=rtl] .flex-offset-md-55, [dir=rtl] .layout-margin .flex-offset-md-55, [dir=rtl] .layout-margin .offset-md-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-md-60, .flex-offset-md-60, .layout-margin .flex-offset-md-60, .layout-margin .offset-md-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-md-60, [dir=rtl] .flex-offset-md-60, [dir=rtl] .layout-margin .flex-offset-md-60, [dir=rtl] .layout-margin .offset-md-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-md-65, .flex-offset-md-65, .layout-margin .flex-offset-md-65, .layout-margin .offset-md-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-md-65, [dir=rtl] .flex-offset-md-65, [dir=rtl] .layout-margin .flex-offset-md-65, [dir=rtl] .layout-margin .offset-md-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-md-70, .flex-offset-md-70, .layout-margin .flex-offset-md-70, .layout-margin .offset-md-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-md-70, [dir=rtl] .flex-offset-md-70, [dir=rtl] .layout-margin .flex-offset-md-70, [dir=rtl] .layout-margin .offset-md-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-md-75, .flex-offset-md-75, .layout-margin .flex-offset-md-75, .layout-margin .offset-md-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-md-75, [dir=rtl] .flex-offset-md-75, [dir=rtl] .layout-margin .flex-offset-md-75, [dir=rtl] .layout-margin .offset-md-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-md-80, .flex-offset-md-80, .layout-margin .flex-offset-md-80, .layout-margin .offset-md-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-md-80, [dir=rtl] .flex-offset-md-80, [dir=rtl] .layout-margin .flex-offset-md-80, [dir=rtl] .layout-margin .offset-md-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-md-85, .flex-offset-md-85, .layout-margin .flex-offset-md-85, .layout-margin .offset-md-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-md-85, [dir=rtl] .flex-offset-md-85, [dir=rtl] .layout-margin .flex-offset-md-85, [dir=rtl] .layout-margin .offset-md-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-md-90, .flex-offset-md-90, .layout-margin .flex-offset-md-90, .layout-margin .offset-md-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-md-90, [dir=rtl] .flex-offset-md-90, [dir=rtl] .layout-margin .flex-offset-md-90, [dir=rtl] .layout-margin .offset-md-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-md-95, .flex-offset-md-95, .layout-margin .flex-offset-md-95, .layout-margin .offset-md-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-md-95, [dir=rtl] .flex-offset-md-95, [dir=rtl] .layout-margin .flex-offset-md-95, [dir=rtl] .layout-margin .offset-md-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-md-33, .flex-offset-md-33, .layout-margin .flex-offset-md-33, .layout-margin .offset-md-33 {
    margin-left: calc(100% / 3); }
  .offset-md-66, .flex-offset-md-66, .layout-margin .flex-offset-md-66, .layout-margin .offset-md-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-md-66, [dir=rtl] .flex-offset-md-66, [dir=rtl] .layout-margin .flex-offset-md-66, [dir=rtl] .layout-margin .offset-md-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-md,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    justify-content: center; }
  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-md {
    flex: 1;
    box-sizing: border-box; }
  .flex-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-md-column > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex {
    min-width: 0; }
  .layout-md-column > .flex-md-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-md-column > .flex {
    min-height: 0; }
  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-md-column {
    flex-direction: column; }
  .layout-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    order: -20; }
  .flex-order-gt-md--19 {
    order: -19; }
  .flex-order-gt-md--18 {
    order: -18; }
  .flex-order-gt-md--17 {
    order: -17; }
  .flex-order-gt-md--16 {
    order: -16; }
  .flex-order-gt-md--15 {
    order: -15; }
  .flex-order-gt-md--14 {
    order: -14; }
  .flex-order-gt-md--13 {
    order: -13; }
  .flex-order-gt-md--12 {
    order: -12; }
  .flex-order-gt-md--11 {
    order: -11; }
  .flex-order-gt-md--10 {
    order: -10; }
  .flex-order-gt-md--9 {
    order: -9; }
  .flex-order-gt-md--8 {
    order: -8; }
  .flex-order-gt-md--7 {
    order: -7; }
  .flex-order-gt-md--6 {
    order: -6; }
  .flex-order-gt-md--5 {
    order: -5; }
  .flex-order-gt-md--4 {
    order: -4; }
  .flex-order-gt-md--3 {
    order: -3; }
  .flex-order-gt-md--2 {
    order: -2; }
  .flex-order-gt-md--1 {
    order: -1; }
  .flex-order-gt-md-0 {
    order: 0; }
  .flex-order-gt-md-1 {
    order: 1; }
  .flex-order-gt-md-2 {
    order: 2; }
  .flex-order-gt-md-3 {
    order: 3; }
  .flex-order-gt-md-4 {
    order: 4; }
  .flex-order-gt-md-5 {
    order: 5; }
  .flex-order-gt-md-6 {
    order: 6; }
  .flex-order-gt-md-7 {
    order: 7; }
  .flex-order-gt-md-8 {
    order: 8; }
  .flex-order-gt-md-9 {
    order: 9; }
  .flex-order-gt-md-10 {
    order: 10; }
  .flex-order-gt-md-11 {
    order: 11; }
  .flex-order-gt-md-12 {
    order: 12; }
  .flex-order-gt-md-13 {
    order: 13; }
  .flex-order-gt-md-14 {
    order: 14; }
  .flex-order-gt-md-15 {
    order: 15; }
  .flex-order-gt-md-16 {
    order: 16; }
  .flex-order-gt-md-17 {
    order: 17; }
  .flex-order-gt-md-18 {
    order: 18; }
  .flex-order-gt-md-19 {
    order: 19; }
  .flex-order-gt-md-20 {
    order: 20; }
  .offset-gt-md-0, .flex-offset-gt-md-0, .layout-margin .flex-offset-gt-md-0, .layout-margin .offset-gt-md-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-md-0, [dir=rtl] .flex-offset-gt-md-0, [dir=rtl] .layout-margin .flex-offset-gt-md-0, [dir=rtl] .layout-margin .offset-gt-md-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-md-5, .flex-offset-gt-md-5, .layout-margin .flex-offset-gt-md-5, .layout-margin .offset-gt-md-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-md-5, [dir=rtl] .flex-offset-gt-md-5, [dir=rtl] .layout-margin .flex-offset-gt-md-5, [dir=rtl] .layout-margin .offset-gt-md-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-md-10, .flex-offset-gt-md-10, .layout-margin .flex-offset-gt-md-10, .layout-margin .offset-gt-md-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-md-10, [dir=rtl] .flex-offset-gt-md-10, [dir=rtl] .layout-margin .flex-offset-gt-md-10, [dir=rtl] .layout-margin .offset-gt-md-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-md-15, .flex-offset-gt-md-15, .layout-margin .flex-offset-gt-md-15, .layout-margin .offset-gt-md-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-md-15, [dir=rtl] .flex-offset-gt-md-15, [dir=rtl] .layout-margin .flex-offset-gt-md-15, [dir=rtl] .layout-margin .offset-gt-md-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-md-20, .flex-offset-gt-md-20, .layout-margin .flex-offset-gt-md-20, .layout-margin .offset-gt-md-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-md-20, [dir=rtl] .flex-offset-gt-md-20, [dir=rtl] .layout-margin .flex-offset-gt-md-20, [dir=rtl] .layout-margin .offset-gt-md-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-md-25, .flex-offset-gt-md-25, .layout-margin .flex-offset-gt-md-25, .layout-margin .offset-gt-md-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-md-25, [dir=rtl] .flex-offset-gt-md-25, [dir=rtl] .layout-margin .flex-offset-gt-md-25, [dir=rtl] .layout-margin .offset-gt-md-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-md-30, .flex-offset-gt-md-30, .layout-margin .flex-offset-gt-md-30, .layout-margin .offset-gt-md-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-md-30, [dir=rtl] .flex-offset-gt-md-30, [dir=rtl] .layout-margin .flex-offset-gt-md-30, [dir=rtl] .layout-margin .offset-gt-md-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-md-35, .flex-offset-gt-md-35, .layout-margin .flex-offset-gt-md-35, .layout-margin .offset-gt-md-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-md-35, [dir=rtl] .flex-offset-gt-md-35, [dir=rtl] .layout-margin .flex-offset-gt-md-35, [dir=rtl] .layout-margin .offset-gt-md-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-md-40, .flex-offset-gt-md-40, .layout-margin .flex-offset-gt-md-40, .layout-margin .offset-gt-md-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-md-40, [dir=rtl] .flex-offset-gt-md-40, [dir=rtl] .layout-margin .flex-offset-gt-md-40, [dir=rtl] .layout-margin .offset-gt-md-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-md-45, .flex-offset-gt-md-45, .layout-margin .flex-offset-gt-md-45, .layout-margin .offset-gt-md-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-md-45, [dir=rtl] .flex-offset-gt-md-45, [dir=rtl] .layout-margin .flex-offset-gt-md-45, [dir=rtl] .layout-margin .offset-gt-md-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-md-50, .flex-offset-gt-md-50, .layout-margin .flex-offset-gt-md-50, .layout-margin .offset-gt-md-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-md-50, [dir=rtl] .flex-offset-gt-md-50, [dir=rtl] .layout-margin .flex-offset-gt-md-50, [dir=rtl] .layout-margin .offset-gt-md-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-md-55, .flex-offset-gt-md-55, .layout-margin .flex-offset-gt-md-55, .layout-margin .offset-gt-md-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-md-55, [dir=rtl] .flex-offset-gt-md-55, [dir=rtl] .layout-margin .flex-offset-gt-md-55, [dir=rtl] .layout-margin .offset-gt-md-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-md-60, .flex-offset-gt-md-60, .layout-margin .flex-offset-gt-md-60, .layout-margin .offset-gt-md-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-md-60, [dir=rtl] .flex-offset-gt-md-60, [dir=rtl] .layout-margin .flex-offset-gt-md-60, [dir=rtl] .layout-margin .offset-gt-md-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-md-65, .flex-offset-gt-md-65, .layout-margin .flex-offset-gt-md-65, .layout-margin .offset-gt-md-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-md-65, [dir=rtl] .flex-offset-gt-md-65, [dir=rtl] .layout-margin .flex-offset-gt-md-65, [dir=rtl] .layout-margin .offset-gt-md-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-md-70, .flex-offset-gt-md-70, .layout-margin .flex-offset-gt-md-70, .layout-margin .offset-gt-md-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-md-70, [dir=rtl] .flex-offset-gt-md-70, [dir=rtl] .layout-margin .flex-offset-gt-md-70, [dir=rtl] .layout-margin .offset-gt-md-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-md-75, .flex-offset-gt-md-75, .layout-margin .flex-offset-gt-md-75, .layout-margin .offset-gt-md-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-md-75, [dir=rtl] .flex-offset-gt-md-75, [dir=rtl] .layout-margin .flex-offset-gt-md-75, [dir=rtl] .layout-margin .offset-gt-md-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-md-80, .flex-offset-gt-md-80, .layout-margin .flex-offset-gt-md-80, .layout-margin .offset-gt-md-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-md-80, [dir=rtl] .flex-offset-gt-md-80, [dir=rtl] .layout-margin .flex-offset-gt-md-80, [dir=rtl] .layout-margin .offset-gt-md-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-md-85, .flex-offset-gt-md-85, .layout-margin .flex-offset-gt-md-85, .layout-margin .offset-gt-md-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-md-85, [dir=rtl] .flex-offset-gt-md-85, [dir=rtl] .layout-margin .flex-offset-gt-md-85, [dir=rtl] .layout-margin .offset-gt-md-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-md-90, .flex-offset-gt-md-90, .layout-margin .flex-offset-gt-md-90, .layout-margin .offset-gt-md-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-md-90, [dir=rtl] .flex-offset-gt-md-90, [dir=rtl] .layout-margin .flex-offset-gt-md-90, [dir=rtl] .layout-margin .offset-gt-md-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-md-95, .flex-offset-gt-md-95, .layout-margin .flex-offset-gt-md-95, .layout-margin .offset-gt-md-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-md-95, [dir=rtl] .flex-offset-gt-md-95, [dir=rtl] .layout-margin .flex-offset-gt-md-95, [dir=rtl] .layout-margin .offset-gt-md-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-md-33, .flex-offset-gt-md-33, .layout-margin .flex-offset-gt-md-33, .layout-margin .offset-gt-md-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-md-66, .flex-offset-gt-md-66, .layout-margin .flex-offset-gt-md-66, .layout-margin .offset-gt-md-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-md-66, [dir=rtl] .flex-offset-gt-md-66, [dir=rtl] .layout-margin .flex-offset-gt-md-66, [dir=rtl] .layout-margin .offset-gt-md-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    justify-content: center; }
  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-md {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-md-column > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex {
    min-width: 0; }
  .layout-gt-md-column > .flex-gt-md-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex {
    min-height: 0; }
  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-md-column {
    flex-direction: column; }
  .layout-gt-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none; }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-lg--20 {
    order: -20; }
  .flex-order-lg--19 {
    order: -19; }
  .flex-order-lg--18 {
    order: -18; }
  .flex-order-lg--17 {
    order: -17; }
  .flex-order-lg--16 {
    order: -16; }
  .flex-order-lg--15 {
    order: -15; }
  .flex-order-lg--14 {
    order: -14; }
  .flex-order-lg--13 {
    order: -13; }
  .flex-order-lg--12 {
    order: -12; }
  .flex-order-lg--11 {
    order: -11; }
  .flex-order-lg--10 {
    order: -10; }
  .flex-order-lg--9 {
    order: -9; }
  .flex-order-lg--8 {
    order: -8; }
  .flex-order-lg--7 {
    order: -7; }
  .flex-order-lg--6 {
    order: -6; }
  .flex-order-lg--5 {
    order: -5; }
  .flex-order-lg--4 {
    order: -4; }
  .flex-order-lg--3 {
    order: -3; }
  .flex-order-lg--2 {
    order: -2; }
  .flex-order-lg--1 {
    order: -1; }
  .flex-order-lg-0 {
    order: 0; }
  .flex-order-lg-1 {
    order: 1; }
  .flex-order-lg-2 {
    order: 2; }
  .flex-order-lg-3 {
    order: 3; }
  .flex-order-lg-4 {
    order: 4; }
  .flex-order-lg-5 {
    order: 5; }
  .flex-order-lg-6 {
    order: 6; }
  .flex-order-lg-7 {
    order: 7; }
  .flex-order-lg-8 {
    order: 8; }
  .flex-order-lg-9 {
    order: 9; }
  .flex-order-lg-10 {
    order: 10; }
  .flex-order-lg-11 {
    order: 11; }
  .flex-order-lg-12 {
    order: 12; }
  .flex-order-lg-13 {
    order: 13; }
  .flex-order-lg-14 {
    order: 14; }
  .flex-order-lg-15 {
    order: 15; }
  .flex-order-lg-16 {
    order: 16; }
  .flex-order-lg-17 {
    order: 17; }
  .flex-order-lg-18 {
    order: 18; }
  .flex-order-lg-19 {
    order: 19; }
  .flex-order-lg-20 {
    order: 20; }
  .offset-lg-0, .flex-offset-lg-0, .layout-margin .flex-offset-lg-0, .layout-margin .offset-lg-0 {
    margin-left: 0; }
    [dir=rtl] .offset-lg-0, [dir=rtl] .flex-offset-lg-0, [dir=rtl] .layout-margin .flex-offset-lg-0, [dir=rtl] .layout-margin .offset-lg-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-lg-5, .flex-offset-lg-5, .layout-margin .flex-offset-lg-5, .layout-margin .offset-lg-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-lg-5, [dir=rtl] .flex-offset-lg-5, [dir=rtl] .layout-margin .flex-offset-lg-5, [dir=rtl] .layout-margin .offset-lg-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-lg-10, .flex-offset-lg-10, .layout-margin .flex-offset-lg-10, .layout-margin .offset-lg-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-lg-10, [dir=rtl] .flex-offset-lg-10, [dir=rtl] .layout-margin .flex-offset-lg-10, [dir=rtl] .layout-margin .offset-lg-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-lg-15, .flex-offset-lg-15, .layout-margin .flex-offset-lg-15, .layout-margin .offset-lg-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-lg-15, [dir=rtl] .flex-offset-lg-15, [dir=rtl] .layout-margin .flex-offset-lg-15, [dir=rtl] .layout-margin .offset-lg-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-lg-20, .flex-offset-lg-20, .layout-margin .flex-offset-lg-20, .layout-margin .offset-lg-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-lg-20, [dir=rtl] .flex-offset-lg-20, [dir=rtl] .layout-margin .flex-offset-lg-20, [dir=rtl] .layout-margin .offset-lg-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-lg-25, .flex-offset-lg-25, .layout-margin .flex-offset-lg-25, .layout-margin .offset-lg-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-lg-25, [dir=rtl] .flex-offset-lg-25, [dir=rtl] .layout-margin .flex-offset-lg-25, [dir=rtl] .layout-margin .offset-lg-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-lg-30, .flex-offset-lg-30, .layout-margin .flex-offset-lg-30, .layout-margin .offset-lg-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-lg-30, [dir=rtl] .flex-offset-lg-30, [dir=rtl] .layout-margin .flex-offset-lg-30, [dir=rtl] .layout-margin .offset-lg-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-lg-35, .flex-offset-lg-35, .layout-margin .flex-offset-lg-35, .layout-margin .offset-lg-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-lg-35, [dir=rtl] .flex-offset-lg-35, [dir=rtl] .layout-margin .flex-offset-lg-35, [dir=rtl] .layout-margin .offset-lg-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-lg-40, .flex-offset-lg-40, .layout-margin .flex-offset-lg-40, .layout-margin .offset-lg-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-lg-40, [dir=rtl] .flex-offset-lg-40, [dir=rtl] .layout-margin .flex-offset-lg-40, [dir=rtl] .layout-margin .offset-lg-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-lg-45, .flex-offset-lg-45, .layout-margin .flex-offset-lg-45, .layout-margin .offset-lg-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-lg-45, [dir=rtl] .flex-offset-lg-45, [dir=rtl] .layout-margin .flex-offset-lg-45, [dir=rtl] .layout-margin .offset-lg-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-lg-50, .flex-offset-lg-50, .layout-margin .flex-offset-lg-50, .layout-margin .offset-lg-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-lg-50, [dir=rtl] .flex-offset-lg-50, [dir=rtl] .layout-margin .flex-offset-lg-50, [dir=rtl] .layout-margin .offset-lg-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-lg-55, .flex-offset-lg-55, .layout-margin .flex-offset-lg-55, .layout-margin .offset-lg-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-lg-55, [dir=rtl] .flex-offset-lg-55, [dir=rtl] .layout-margin .flex-offset-lg-55, [dir=rtl] .layout-margin .offset-lg-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-lg-60, .flex-offset-lg-60, .layout-margin .flex-offset-lg-60, .layout-margin .offset-lg-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-lg-60, [dir=rtl] .flex-offset-lg-60, [dir=rtl] .layout-margin .flex-offset-lg-60, [dir=rtl] .layout-margin .offset-lg-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-lg-65, .flex-offset-lg-65, .layout-margin .flex-offset-lg-65, .layout-margin .offset-lg-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-lg-65, [dir=rtl] .flex-offset-lg-65, [dir=rtl] .layout-margin .flex-offset-lg-65, [dir=rtl] .layout-margin .offset-lg-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-lg-70, .flex-offset-lg-70, .layout-margin .flex-offset-lg-70, .layout-margin .offset-lg-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-lg-70, [dir=rtl] .flex-offset-lg-70, [dir=rtl] .layout-margin .flex-offset-lg-70, [dir=rtl] .layout-margin .offset-lg-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-lg-75, .flex-offset-lg-75, .layout-margin .flex-offset-lg-75, .layout-margin .offset-lg-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-lg-75, [dir=rtl] .flex-offset-lg-75, [dir=rtl] .layout-margin .flex-offset-lg-75, [dir=rtl] .layout-margin .offset-lg-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-lg-80, .flex-offset-lg-80, .layout-margin .flex-offset-lg-80, .layout-margin .offset-lg-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-lg-80, [dir=rtl] .flex-offset-lg-80, [dir=rtl] .layout-margin .flex-offset-lg-80, [dir=rtl] .layout-margin .offset-lg-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-lg-85, .flex-offset-lg-85, .layout-margin .flex-offset-lg-85, .layout-margin .offset-lg-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-lg-85, [dir=rtl] .flex-offset-lg-85, [dir=rtl] .layout-margin .flex-offset-lg-85, [dir=rtl] .layout-margin .offset-lg-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-lg-90, .flex-offset-lg-90, .layout-margin .flex-offset-lg-90, .layout-margin .offset-lg-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-lg-90, [dir=rtl] .flex-offset-lg-90, [dir=rtl] .layout-margin .flex-offset-lg-90, [dir=rtl] .layout-margin .offset-lg-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-lg-95, .flex-offset-lg-95, .layout-margin .flex-offset-lg-95, .layout-margin .offset-lg-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-lg-95, [dir=rtl] .flex-offset-lg-95, [dir=rtl] .layout-margin .flex-offset-lg-95, [dir=rtl] .layout-margin .offset-lg-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-lg-33, .flex-offset-lg-33, .layout-margin .flex-offset-lg-33, .layout-margin .offset-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-lg-66, .flex-offset-lg-66, .layout-margin .flex-offset-lg-66, .layout-margin .offset-lg-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-lg-66, [dir=rtl] .flex-offset-lg-66, [dir=rtl] .layout-margin .flex-offset-lg-66, [dir=rtl] .layout-margin .offset-lg-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-lg,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    justify-content: center; }
  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-lg {
    flex: 1;
    box-sizing: border-box; }
  .flex-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-lg-column > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex {
    min-width: 0; }
  .layout-lg-column > .flex-lg-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-lg-column > .flex {
    min-height: 0; }
  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-lg-column {
    flex-direction: column; }
  .layout-lg-row {
    flex-direction: row; } }

@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    order: -20; }
  .flex-order-gt-lg--19 {
    order: -19; }
  .flex-order-gt-lg--18 {
    order: -18; }
  .flex-order-gt-lg--17 {
    order: -17; }
  .flex-order-gt-lg--16 {
    order: -16; }
  .flex-order-gt-lg--15 {
    order: -15; }
  .flex-order-gt-lg--14 {
    order: -14; }
  .flex-order-gt-lg--13 {
    order: -13; }
  .flex-order-gt-lg--12 {
    order: -12; }
  .flex-order-gt-lg--11 {
    order: -11; }
  .flex-order-gt-lg--10 {
    order: -10; }
  .flex-order-gt-lg--9 {
    order: -9; }
  .flex-order-gt-lg--8 {
    order: -8; }
  .flex-order-gt-lg--7 {
    order: -7; }
  .flex-order-gt-lg--6 {
    order: -6; }
  .flex-order-gt-lg--5 {
    order: -5; }
  .flex-order-gt-lg--4 {
    order: -4; }
  .flex-order-gt-lg--3 {
    order: -3; }
  .flex-order-gt-lg--2 {
    order: -2; }
  .flex-order-gt-lg--1 {
    order: -1; }
  .flex-order-gt-lg-0 {
    order: 0; }
  .flex-order-gt-lg-1 {
    order: 1; }
  .flex-order-gt-lg-2 {
    order: 2; }
  .flex-order-gt-lg-3 {
    order: 3; }
  .flex-order-gt-lg-4 {
    order: 4; }
  .flex-order-gt-lg-5 {
    order: 5; }
  .flex-order-gt-lg-6 {
    order: 6; }
  .flex-order-gt-lg-7 {
    order: 7; }
  .flex-order-gt-lg-8 {
    order: 8; }
  .flex-order-gt-lg-9 {
    order: 9; }
  .flex-order-gt-lg-10 {
    order: 10; }
  .flex-order-gt-lg-11 {
    order: 11; }
  .flex-order-gt-lg-12 {
    order: 12; }
  .flex-order-gt-lg-13 {
    order: 13; }
  .flex-order-gt-lg-14 {
    order: 14; }
  .flex-order-gt-lg-15 {
    order: 15; }
  .flex-order-gt-lg-16 {
    order: 16; }
  .flex-order-gt-lg-17 {
    order: 17; }
  .flex-order-gt-lg-18 {
    order: 18; }
  .flex-order-gt-lg-19 {
    order: 19; }
  .flex-order-gt-lg-20 {
    order: 20; }
  .offset-gt-lg-0, .flex-offset-gt-lg-0, .layout-margin .flex-offset-gt-lg-0, .layout-margin .offset-gt-lg-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-lg-0, [dir=rtl] .flex-offset-gt-lg-0, [dir=rtl] .layout-margin .flex-offset-gt-lg-0, [dir=rtl] .layout-margin .offset-gt-lg-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-lg-5, .flex-offset-gt-lg-5, .layout-margin .flex-offset-gt-lg-5, .layout-margin .offset-gt-lg-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-lg-5, [dir=rtl] .flex-offset-gt-lg-5, [dir=rtl] .layout-margin .flex-offset-gt-lg-5, [dir=rtl] .layout-margin .offset-gt-lg-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-lg-10, .flex-offset-gt-lg-10, .layout-margin .flex-offset-gt-lg-10, .layout-margin .offset-gt-lg-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-lg-10, [dir=rtl] .flex-offset-gt-lg-10, [dir=rtl] .layout-margin .flex-offset-gt-lg-10, [dir=rtl] .layout-margin .offset-gt-lg-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-lg-15, .flex-offset-gt-lg-15, .layout-margin .flex-offset-gt-lg-15, .layout-margin .offset-gt-lg-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-lg-15, [dir=rtl] .flex-offset-gt-lg-15, [dir=rtl] .layout-margin .flex-offset-gt-lg-15, [dir=rtl] .layout-margin .offset-gt-lg-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-lg-20, .flex-offset-gt-lg-20, .layout-margin .flex-offset-gt-lg-20, .layout-margin .offset-gt-lg-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-lg-20, [dir=rtl] .flex-offset-gt-lg-20, [dir=rtl] .layout-margin .flex-offset-gt-lg-20, [dir=rtl] .layout-margin .offset-gt-lg-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-lg-25, .flex-offset-gt-lg-25, .layout-margin .flex-offset-gt-lg-25, .layout-margin .offset-gt-lg-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-lg-25, [dir=rtl] .flex-offset-gt-lg-25, [dir=rtl] .layout-margin .flex-offset-gt-lg-25, [dir=rtl] .layout-margin .offset-gt-lg-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-lg-30, .flex-offset-gt-lg-30, .layout-margin .flex-offset-gt-lg-30, .layout-margin .offset-gt-lg-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-lg-30, [dir=rtl] .flex-offset-gt-lg-30, [dir=rtl] .layout-margin .flex-offset-gt-lg-30, [dir=rtl] .layout-margin .offset-gt-lg-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-lg-35, .flex-offset-gt-lg-35, .layout-margin .flex-offset-gt-lg-35, .layout-margin .offset-gt-lg-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-lg-35, [dir=rtl] .flex-offset-gt-lg-35, [dir=rtl] .layout-margin .flex-offset-gt-lg-35, [dir=rtl] .layout-margin .offset-gt-lg-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-lg-40, .flex-offset-gt-lg-40, .layout-margin .flex-offset-gt-lg-40, .layout-margin .offset-gt-lg-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-lg-40, [dir=rtl] .flex-offset-gt-lg-40, [dir=rtl] .layout-margin .flex-offset-gt-lg-40, [dir=rtl] .layout-margin .offset-gt-lg-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-lg-45, .flex-offset-gt-lg-45, .layout-margin .flex-offset-gt-lg-45, .layout-margin .offset-gt-lg-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-lg-45, [dir=rtl] .flex-offset-gt-lg-45, [dir=rtl] .layout-margin .flex-offset-gt-lg-45, [dir=rtl] .layout-margin .offset-gt-lg-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-lg-50, .flex-offset-gt-lg-50, .layout-margin .flex-offset-gt-lg-50, .layout-margin .offset-gt-lg-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-lg-50, [dir=rtl] .flex-offset-gt-lg-50, [dir=rtl] .layout-margin .flex-offset-gt-lg-50, [dir=rtl] .layout-margin .offset-gt-lg-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-lg-55, .flex-offset-gt-lg-55, .layout-margin .flex-offset-gt-lg-55, .layout-margin .offset-gt-lg-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-lg-55, [dir=rtl] .flex-offset-gt-lg-55, [dir=rtl] .layout-margin .flex-offset-gt-lg-55, [dir=rtl] .layout-margin .offset-gt-lg-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-lg-60, .flex-offset-gt-lg-60, .layout-margin .flex-offset-gt-lg-60, .layout-margin .offset-gt-lg-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-lg-60, [dir=rtl] .flex-offset-gt-lg-60, [dir=rtl] .layout-margin .flex-offset-gt-lg-60, [dir=rtl] .layout-margin .offset-gt-lg-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-lg-65, .flex-offset-gt-lg-65, .layout-margin .flex-offset-gt-lg-65, .layout-margin .offset-gt-lg-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-lg-65, [dir=rtl] .flex-offset-gt-lg-65, [dir=rtl] .layout-margin .flex-offset-gt-lg-65, [dir=rtl] .layout-margin .offset-gt-lg-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-lg-70, .flex-offset-gt-lg-70, .layout-margin .flex-offset-gt-lg-70, .layout-margin .offset-gt-lg-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-lg-70, [dir=rtl] .flex-offset-gt-lg-70, [dir=rtl] .layout-margin .flex-offset-gt-lg-70, [dir=rtl] .layout-margin .offset-gt-lg-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-lg-75, .flex-offset-gt-lg-75, .layout-margin .flex-offset-gt-lg-75, .layout-margin .offset-gt-lg-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-lg-75, [dir=rtl] .flex-offset-gt-lg-75, [dir=rtl] .layout-margin .flex-offset-gt-lg-75, [dir=rtl] .layout-margin .offset-gt-lg-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-lg-80, .flex-offset-gt-lg-80, .layout-margin .flex-offset-gt-lg-80, .layout-margin .offset-gt-lg-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-lg-80, [dir=rtl] .flex-offset-gt-lg-80, [dir=rtl] .layout-margin .flex-offset-gt-lg-80, [dir=rtl] .layout-margin .offset-gt-lg-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-lg-85, .flex-offset-gt-lg-85, .layout-margin .flex-offset-gt-lg-85, .layout-margin .offset-gt-lg-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-lg-85, [dir=rtl] .flex-offset-gt-lg-85, [dir=rtl] .layout-margin .flex-offset-gt-lg-85, [dir=rtl] .layout-margin .offset-gt-lg-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-lg-90, .flex-offset-gt-lg-90, .layout-margin .flex-offset-gt-lg-90, .layout-margin .offset-gt-lg-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-lg-90, [dir=rtl] .flex-offset-gt-lg-90, [dir=rtl] .layout-margin .flex-offset-gt-lg-90, [dir=rtl] .layout-margin .offset-gt-lg-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-lg-95, .flex-offset-gt-lg-95, .layout-margin .flex-offset-gt-lg-95, .layout-margin .offset-gt-lg-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-lg-95, [dir=rtl] .flex-offset-gt-lg-95, [dir=rtl] .layout-margin .flex-offset-gt-lg-95, [dir=rtl] .layout-margin .offset-gt-lg-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-lg-33, .flex-offset-gt-lg-33, .layout-margin .flex-offset-gt-lg-33, .layout-margin .offset-gt-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-lg-66, .flex-offset-gt-lg-66, .layout-margin .flex-offset-gt-lg-66, .layout-margin .offset-gt-lg-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-lg-66, [dir=rtl] .flex-offset-gt-lg-66, [dir=rtl] .layout-margin .flex-offset-gt-lg-66, [dir=rtl] .layout-margin .offset-gt-lg-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    justify-content: center; }
  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-lg {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-lg-column > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex {
    min-width: 0; }
  .layout-gt-lg-column > .flex-gt-lg-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex {
    min-height: 0; }
  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-lg-column {
    flex-direction: column; }
  .layout-gt-lg-row {
    flex-direction: row; }
  .flex-order-xl--20 {
    order: -20; }
  .flex-order-xl--19 {
    order: -19; }
  .flex-order-xl--18 {
    order: -18; }
  .flex-order-xl--17 {
    order: -17; }
  .flex-order-xl--16 {
    order: -16; }
  .flex-order-xl--15 {
    order: -15; }
  .flex-order-xl--14 {
    order: -14; }
  .flex-order-xl--13 {
    order: -13; }
  .flex-order-xl--12 {
    order: -12; }
  .flex-order-xl--11 {
    order: -11; }
  .flex-order-xl--10 {
    order: -10; }
  .flex-order-xl--9 {
    order: -9; }
  .flex-order-xl--8 {
    order: -8; }
  .flex-order-xl--7 {
    order: -7; }
  .flex-order-xl--6 {
    order: -6; }
  .flex-order-xl--5 {
    order: -5; }
  .flex-order-xl--4 {
    order: -4; }
  .flex-order-xl--3 {
    order: -3; }
  .flex-order-xl--2 {
    order: -2; }
  .flex-order-xl--1 {
    order: -1; }
  .flex-order-xl-0 {
    order: 0; }
  .flex-order-xl-1 {
    order: 1; }
  .flex-order-xl-2 {
    order: 2; }
  .flex-order-xl-3 {
    order: 3; }
  .flex-order-xl-4 {
    order: 4; }
  .flex-order-xl-5 {
    order: 5; }
  .flex-order-xl-6 {
    order: 6; }
  .flex-order-xl-7 {
    order: 7; }
  .flex-order-xl-8 {
    order: 8; }
  .flex-order-xl-9 {
    order: 9; }
  .flex-order-xl-10 {
    order: 10; }
  .flex-order-xl-11 {
    order: 11; }
  .flex-order-xl-12 {
    order: 12; }
  .flex-order-xl-13 {
    order: 13; }
  .flex-order-xl-14 {
    order: 14; }
  .flex-order-xl-15 {
    order: 15; }
  .flex-order-xl-16 {
    order: 16; }
  .flex-order-xl-17 {
    order: 17; }
  .flex-order-xl-18 {
    order: 18; }
  .flex-order-xl-19 {
    order: 19; }
  .flex-order-xl-20 {
    order: 20; }
  .offset-xl-0, .flex-offset-xl-0, .layout-margin .flex-offset-xl-0, .layout-margin .offset-xl-0 {
    margin-left: 0; }
    [dir=rtl] .offset-xl-0, [dir=rtl] .flex-offset-xl-0, [dir=rtl] .layout-margin .flex-offset-xl-0, [dir=rtl] .layout-margin .offset-xl-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-xl-5, .flex-offset-xl-5, .layout-margin .flex-offset-xl-5, .layout-margin .offset-xl-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-xl-5, [dir=rtl] .flex-offset-xl-5, [dir=rtl] .layout-margin .flex-offset-xl-5, [dir=rtl] .layout-margin .offset-xl-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-xl-10, .flex-offset-xl-10, .layout-margin .flex-offset-xl-10, .layout-margin .offset-xl-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-xl-10, [dir=rtl] .flex-offset-xl-10, [dir=rtl] .layout-margin .flex-offset-xl-10, [dir=rtl] .layout-margin .offset-xl-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-xl-15, .flex-offset-xl-15, .layout-margin .flex-offset-xl-15, .layout-margin .offset-xl-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-xl-15, [dir=rtl] .flex-offset-xl-15, [dir=rtl] .layout-margin .flex-offset-xl-15, [dir=rtl] .layout-margin .offset-xl-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-xl-20, .flex-offset-xl-20, .layout-margin .flex-offset-xl-20, .layout-margin .offset-xl-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-xl-20, [dir=rtl] .flex-offset-xl-20, [dir=rtl] .layout-margin .flex-offset-xl-20, [dir=rtl] .layout-margin .offset-xl-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-xl-25, .flex-offset-xl-25, .layout-margin .flex-offset-xl-25, .layout-margin .offset-xl-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-xl-25, [dir=rtl] .flex-offset-xl-25, [dir=rtl] .layout-margin .flex-offset-xl-25, [dir=rtl] .layout-margin .offset-xl-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-xl-30, .flex-offset-xl-30, .layout-margin .flex-offset-xl-30, .layout-margin .offset-xl-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-xl-30, [dir=rtl] .flex-offset-xl-30, [dir=rtl] .layout-margin .flex-offset-xl-30, [dir=rtl] .layout-margin .offset-xl-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-xl-35, .flex-offset-xl-35, .layout-margin .flex-offset-xl-35, .layout-margin .offset-xl-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-xl-35, [dir=rtl] .flex-offset-xl-35, [dir=rtl] .layout-margin .flex-offset-xl-35, [dir=rtl] .layout-margin .offset-xl-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-xl-40, .flex-offset-xl-40, .layout-margin .flex-offset-xl-40, .layout-margin .offset-xl-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-xl-40, [dir=rtl] .flex-offset-xl-40, [dir=rtl] .layout-margin .flex-offset-xl-40, [dir=rtl] .layout-margin .offset-xl-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-xl-45, .flex-offset-xl-45, .layout-margin .flex-offset-xl-45, .layout-margin .offset-xl-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-xl-45, [dir=rtl] .flex-offset-xl-45, [dir=rtl] .layout-margin .flex-offset-xl-45, [dir=rtl] .layout-margin .offset-xl-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-xl-50, .flex-offset-xl-50, .layout-margin .flex-offset-xl-50, .layout-margin .offset-xl-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-xl-50, [dir=rtl] .flex-offset-xl-50, [dir=rtl] .layout-margin .flex-offset-xl-50, [dir=rtl] .layout-margin .offset-xl-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-xl-55, .flex-offset-xl-55, .layout-margin .flex-offset-xl-55, .layout-margin .offset-xl-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-xl-55, [dir=rtl] .flex-offset-xl-55, [dir=rtl] .layout-margin .flex-offset-xl-55, [dir=rtl] .layout-margin .offset-xl-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-xl-60, .flex-offset-xl-60, .layout-margin .flex-offset-xl-60, .layout-margin .offset-xl-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-xl-60, [dir=rtl] .flex-offset-xl-60, [dir=rtl] .layout-margin .flex-offset-xl-60, [dir=rtl] .layout-margin .offset-xl-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-xl-65, .flex-offset-xl-65, .layout-margin .flex-offset-xl-65, .layout-margin .offset-xl-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-xl-65, [dir=rtl] .flex-offset-xl-65, [dir=rtl] .layout-margin .flex-offset-xl-65, [dir=rtl] .layout-margin .offset-xl-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-xl-70, .flex-offset-xl-70, .layout-margin .flex-offset-xl-70, .layout-margin .offset-xl-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-xl-70, [dir=rtl] .flex-offset-xl-70, [dir=rtl] .layout-margin .flex-offset-xl-70, [dir=rtl] .layout-margin .offset-xl-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-xl-75, .flex-offset-xl-75, .layout-margin .flex-offset-xl-75, .layout-margin .offset-xl-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-xl-75, [dir=rtl] .flex-offset-xl-75, [dir=rtl] .layout-margin .flex-offset-xl-75, [dir=rtl] .layout-margin .offset-xl-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-xl-80, .flex-offset-xl-80, .layout-margin .flex-offset-xl-80, .layout-margin .offset-xl-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-xl-80, [dir=rtl] .flex-offset-xl-80, [dir=rtl] .layout-margin .flex-offset-xl-80, [dir=rtl] .layout-margin .offset-xl-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-xl-85, .flex-offset-xl-85, .layout-margin .flex-offset-xl-85, .layout-margin .offset-xl-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-xl-85, [dir=rtl] .flex-offset-xl-85, [dir=rtl] .layout-margin .flex-offset-xl-85, [dir=rtl] .layout-margin .offset-xl-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-xl-90, .flex-offset-xl-90, .layout-margin .flex-offset-xl-90, .layout-margin .offset-xl-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-xl-90, [dir=rtl] .flex-offset-xl-90, [dir=rtl] .layout-margin .flex-offset-xl-90, [dir=rtl] .layout-margin .offset-xl-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-xl-95, .flex-offset-xl-95, .layout-margin .flex-offset-xl-95, .layout-margin .offset-xl-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-xl-95, [dir=rtl] .flex-offset-xl-95, [dir=rtl] .layout-margin .flex-offset-xl-95, [dir=rtl] .layout-margin .offset-xl-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-xl-33, .flex-offset-xl-33, .layout-margin .flex-offset-xl-33, .layout-margin .offset-xl-33 {
    margin-left: calc(100% / 3); }
  .offset-xl-66, .flex-offset-xl-66, .layout-margin .flex-offset-xl-66, .layout-margin .offset-xl-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-xl-66, [dir=rtl] .flex-offset-xl-66, [dir=rtl] .layout-margin .flex-offset-xl-66, [dir=rtl] .layout-margin .offset-xl-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-xl,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    justify-content: flex-start; }
  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    justify-content: center; }
  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    justify-content: flex-end; }
  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xl {
    flex: 1;
    box-sizing: border-box; }
  .flex-xl-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xl-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xl-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xl-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xl-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-xl-column > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex {
    min-width: 0; }
  .layout-xl-column > .flex-xl-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xl-column > .flex {
    min-height: 0; }
  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xl-column {
    flex-direction: column; }
  .layout-xl-row {
    flex-direction: row; }
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none; }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; } }

@media print {
  .hide-print:not(.show-print):not(.show) {
    display: none !important; } }

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Colors
/*----------------------------------------------------------------*/
.black-fg,
.black-text {
  color: rgba(0, 0, 0, 0.87) !important; }
  .black-fg.secondary-text,
  .black-fg .secondary-text, .black-fg.icon,
  .black-fg .icon,
  .black-text.secondary-text,
  .black-text .secondary-text,
  .black-text.icon,
  .black-text .icon {
    color: rgba(0, 0, 0, 0.54) !important; }
  .black-fg.hint-text,
  .black-fg .hint-text, .black-fg.disabled-text,
  .black-fg .disabled-text,
  .black-text.hint-text,
  .black-text .hint-text,
  .black-text.disabled-text,
  .black-text .disabled-text {
    color: rgba(0, 0, 0, 0.26) !important; }
  .black-fg.divider,
  .black-fg .divider, .black-fg.faint-text,
  .black-fg .faint-text,
  .black-text.divider,
  .black-text .divider,
  .black-text.faint-text,
  .black-text .faint-text {
    color: rgba(0, 0, 0, 0.12) !important; }

.white-fg,
.white-text {
  color: white !important; }
  .white-fg.secondary-text,
  .white-fg .secondary-text, .white-fg.icon,
  .white-fg .icon,
  .white-text.secondary-text,
  .white-text .secondary-text,
  .white-text.icon,
  .white-text .icon {
    color: rgba(255, 255, 255, 0.7) !important; }
  .white-fg.hint-text,
  .white-fg .hint-text, .white-fg.disabled-text,
  .white-fg .disabled-text,
  .white-text.hint-text,
  .white-text .hint-text,
  .white-text.disabled-text,
  .white-text .disabled-text {
    color: rgba(255, 255, 255, 0.3) !important; }
  .white-fg.divider,
  .white-fg .divider, .white-fg.faint-text,
  .white-fg .faint-text,
  .white-text.divider,
  .white-text .divider,
  .white-text.faint-text,
  .white-text .faint-text {
    color: rgba(255, 255, 255, 0.12) !important; }
  .white-fg md-icon,
  .white-text md-icon {
    color: #FFFFFF; }
  .white-fg md-progress-linear .md-container,
  .white-text md-progress-linear .md-container {
    background: rgba(255, 255, 255, 0.3); }
  .white-fg md-progress-linear .md-bar,
  .white-text md-progress-linear .md-bar {
    background: rgba(255, 255, 255, 0.7); }

.red-50-bg {
  background-color: #FFEBEE !important; }

.md-red-50-bg {
  background-color: #FFEBEE !important;
  color: rgba(0, 0, 0, 0.87); }

.red-50-fg {
  color: #FFEBEE !important; }

.red-50-border {
  border-color: #FFEBEE !important; }

.red-50-border-top {
  border-top-color: #FFEBEE !important; }

.red-50-border-right {
  border-right-color: #FFEBEE !important; }

.red-50-border-bottom {
  border-bottom-color: #FFEBEE !important; }

.red-50-border-left {
  border-left-color: #FFEBEE !important; }

.red-100-bg {
  background-color: #FFCDD2 !important; }

.md-red-100-bg {
  background-color: #FFCDD2 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-100-fg {
  color: #FFCDD2 !important; }

.red-100-border {
  border-color: #FFCDD2 !important; }

.red-100-border-top {
  border-top-color: #FFCDD2 !important; }

.red-100-border-right {
  border-right-color: #FFCDD2 !important; }

.red-100-border-bottom {
  border-bottom-color: #FFCDD2 !important; }

.red-100-border-left {
  border-left-color: #FFCDD2 !important; }

.red-200-bg {
  background-color: #EF9A9A !important; }

.md-red-200-bg {
  background-color: #EF9A9A !important;
  color: rgba(0, 0, 0, 0.87); }

.red-200-fg {
  color: #EF9A9A !important; }

.red-200-border {
  border-color: #EF9A9A !important; }

.red-200-border-top {
  border-top-color: #EF9A9A !important; }

.red-200-border-right {
  border-right-color: #EF9A9A !important; }

.red-200-border-bottom {
  border-bottom-color: #EF9A9A !important; }

.red-200-border-left {
  border-left-color: #EF9A9A !important; }

.red-300-bg {
  background-color: #E57373 !important; }

.md-red-300-bg {
  background-color: #E57373 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-300-fg {
  color: #E57373 !important; }

.red-300-border {
  border-color: #E57373 !important; }

.red-300-border-top {
  border-top-color: #E57373 !important; }

.red-300-border-right {
  border-right-color: #E57373 !important; }

.red-300-border-bottom {
  border-bottom-color: #E57373 !important; }

.red-300-border-left {
  border-left-color: #E57373 !important; }

.red-400-bg {
  background-color: #EF5350 !important; }

.md-red-400-bg {
  background-color: #EF5350 !important;
  color: white; }

.red-400-fg {
  color: #EF5350 !important; }

.red-400-border {
  border-color: #EF5350 !important; }

.red-400-border-top {
  border-top-color: #EF5350 !important; }

.red-400-border-right {
  border-right-color: #EF5350 !important; }

.red-400-border-bottom {
  border-bottom-color: #EF5350 !important; }

.red-400-border-left {
  border-left-color: #EF5350 !important; }

.red-500-bg {
  background-color: #F44336 !important; }

.md-red-500-bg {
  background-color: #F44336 !important;
  color: white; }

.red-500-fg, .event-report table.md-table .event-occurrence .td-event-type,
.event-report table.md-table .event-occurrence [class*="icon-"] {
  color: #F44336 !important; }

.red-500-border {
  border-color: #F44336 !important; }

.red-500-border-top {
  border-top-color: #F44336 !important; }

.red-500-border-right {
  border-right-color: #F44336 !important; }

.red-500-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-500-border-left {
  border-left-color: #F44336 !important; }

.md-red-bg {
  background-color: #F44336 !important;
  color: white; }

.red-bg {
  background-color: #F44336 !important; }

.red-fg {
  color: #F44336 !important; }

.red-border {
  border-color: #F44336 !important; }

.red-border-top {
  border-top-color: #F44336 !important; }

.red-border-right {
  border-right-color: #F44336 !important; }

.red-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-border-left {
  border-left-color: #F44336 !important; }

.red-600-bg {
  background-color: #E53935 !important; }

.md-red-600-bg {
  background-color: #E53935 !important;
  color: white; }

.red-600-fg {
  color: #E53935 !important; }

.red-600-border {
  border-color: #E53935 !important; }

.red-600-border-top {
  border-top-color: #E53935 !important; }

.red-600-border-right {
  border-right-color: #E53935 !important; }

.red-600-border-bottom {
  border-bottom-color: #E53935 !important; }

.red-600-border-left {
  border-left-color: #E53935 !important; }

.red-700-bg {
  background-color: #D32F2F !important; }

.md-red-700-bg {
  background-color: #D32F2F !important;
  color: white; }

.red-700-fg {
  color: #D32F2F !important; }

.red-700-border {
  border-color: #D32F2F !important; }

.red-700-border-top {
  border-top-color: #D32F2F !important; }

.red-700-border-right {
  border-right-color: #D32F2F !important; }

.red-700-border-bottom {
  border-bottom-color: #D32F2F !important; }

.red-700-border-left {
  border-left-color: #D32F2F !important; }

.red-800-bg {
  background-color: #C62828 !important; }

.md-red-800-bg {
  background-color: #C62828 !important;
  color: rgba(255, 255, 255, 0.87); }

.red-800-fg {
  color: #C62828 !important; }

.red-800-border {
  border-color: #C62828 !important; }

.red-800-border-top {
  border-top-color: #C62828 !important; }

.red-800-border-right {
  border-right-color: #C62828 !important; }

.red-800-border-bottom {
  border-bottom-color: #C62828 !important; }

.red-800-border-left {
  border-left-color: #C62828 !important; }

.red-900-bg {
  background-color: #B71C1C !important; }

.md-red-900-bg {
  background-color: #B71C1C !important;
  color: rgba(255, 255, 255, 0.87); }

.red-900-fg {
  color: #B71C1C !important; }

.red-900-border {
  border-color: #B71C1C !important; }

.red-900-border-top {
  border-top-color: #B71C1C !important; }

.red-900-border-right {
  border-right-color: #B71C1C !important; }

.red-900-border-bottom {
  border-bottom-color: #B71C1C !important; }

.red-900-border-left {
  border-left-color: #B71C1C !important; }

.red-A100-bg {
  background-color: #FF8A80 !important; }

.md-red-A100-bg {
  background-color: #FF8A80 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-A100-fg {
  color: #FF8A80 !important; }

.red-A100-border {
  border-color: #FF8A80 !important; }

.red-A100-border-top {
  border-top-color: #FF8A80 !important; }

.red-A100-border-right {
  border-right-color: #FF8A80 !important; }

.red-A100-border-bottom {
  border-bottom-color: #FF8A80 !important; }

.red-A100-border-left {
  border-left-color: #FF8A80 !important; }

.red-A200-bg {
  background-color: #FF5252 !important; }

.md-red-A200-bg {
  background-color: #FF5252 !important;
  color: white; }

.red-A200-fg {
  color: #FF5252 !important; }

.red-A200-border {
  border-color: #FF5252 !important; }

.red-A200-border-top {
  border-top-color: #FF5252 !important; }

.red-A200-border-right {
  border-right-color: #FF5252 !important; }

.red-A200-border-bottom {
  border-bottom-color: #FF5252 !important; }

.red-A200-border-left {
  border-left-color: #FF5252 !important; }

.red-A400-bg {
  background-color: #FF1744 !important; }

.md-red-A400-bg {
  background-color: #FF1744 !important;
  color: white; }

.red-A400-fg {
  color: #FF1744 !important; }

.red-A400-border {
  border-color: #FF1744 !important; }

.red-A400-border-top {
  border-top-color: #FF1744 !important; }

.red-A400-border-right {
  border-right-color: #FF1744 !important; }

.red-A400-border-bottom {
  border-bottom-color: #FF1744 !important; }

.red-A400-border-left {
  border-left-color: #FF1744 !important; }

.red-A700-bg {
  background-color: #D50000 !important; }

.md-red-A700-bg {
  background-color: #D50000 !important;
  color: white; }

.red-A700-fg {
  color: #D50000 !important; }

.red-A700-border {
  border-color: #D50000 !important; }

.red-A700-border-top {
  border-top-color: #D50000 !important; }

.red-A700-border-right {
  border-right-color: #D50000 !important; }

.red-A700-border-bottom {
  border-bottom-color: #D50000 !important; }

.red-A700-border-left {
  border-left-color: #D50000 !important; }

.pink-50-bg {
  background-color: #FCE4EC !important; }

.md-pink-50-bg {
  background-color: #FCE4EC !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-50-fg {
  color: #FCE4EC !important; }

.pink-50-border {
  border-color: #FCE4EC !important; }

.pink-50-border-top {
  border-top-color: #FCE4EC !important; }

.pink-50-border-right {
  border-right-color: #FCE4EC !important; }

.pink-50-border-bottom {
  border-bottom-color: #FCE4EC !important; }

.pink-50-border-left {
  border-left-color: #FCE4EC !important; }

.pink-100-bg {
  background-color: #F8BBD0 !important; }

.md-pink-100-bg {
  background-color: #F8BBD0 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-100-fg {
  color: #F8BBD0 !important; }

.pink-100-border {
  border-color: #F8BBD0 !important; }

.pink-100-border-top {
  border-top-color: #F8BBD0 !important; }

.pink-100-border-right {
  border-right-color: #F8BBD0 !important; }

.pink-100-border-bottom {
  border-bottom-color: #F8BBD0 !important; }

.pink-100-border-left {
  border-left-color: #F8BBD0 !important; }

.pink-200-bg {
  background-color: #F48FB1 !important; }

.md-pink-200-bg {
  background-color: #F48FB1 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-200-fg {
  color: #F48FB1 !important; }

.pink-200-border {
  border-color: #F48FB1 !important; }

.pink-200-border-top {
  border-top-color: #F48FB1 !important; }

.pink-200-border-right {
  border-right-color: #F48FB1 !important; }

.pink-200-border-bottom {
  border-bottom-color: #F48FB1 !important; }

.pink-200-border-left {
  border-left-color: #F48FB1 !important; }

.pink-300-bg {
  background-color: #F06292 !important; }

.md-pink-300-bg {
  background-color: #F06292 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-300-fg {
  color: #F06292 !important; }

.pink-300-border {
  border-color: #F06292 !important; }

.pink-300-border-top {
  border-top-color: #F06292 !important; }

.pink-300-border-right {
  border-right-color: #F06292 !important; }

.pink-300-border-bottom {
  border-bottom-color: #F06292 !important; }

.pink-300-border-left {
  border-left-color: #F06292 !important; }

.pink-400-bg {
  background-color: #EC407A !important; }

.md-pink-400-bg {
  background-color: #EC407A !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-400-fg {
  color: #EC407A !important; }

.pink-400-border {
  border-color: #EC407A !important; }

.pink-400-border-top {
  border-top-color: #EC407A !important; }

.pink-400-border-right {
  border-right-color: #EC407A !important; }

.pink-400-border-bottom {
  border-bottom-color: #EC407A !important; }

.pink-400-border-left {
  border-left-color: #EC407A !important; }

.pink-500-bg {
  background-color: #E91E63 !important; }

.md-pink-500-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-500-fg {
  color: #E91E63 !important; }

.pink-500-border {
  border-color: #E91E63 !important; }

.pink-500-border-top {
  border-top-color: #E91E63 !important; }

.pink-500-border-right {
  border-right-color: #E91E63 !important; }

.pink-500-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-500-border-left {
  border-left-color: #E91E63 !important; }

.md-pink-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-bg {
  background-color: #E91E63 !important; }

.pink-fg {
  color: #E91E63 !important; }

.pink-border {
  border-color: #E91E63 !important; }

.pink-border-top {
  border-top-color: #E91E63 !important; }

.pink-border-right {
  border-right-color: #E91E63 !important; }

.pink-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-border-left {
  border-left-color: #E91E63 !important; }

.pink-600-bg {
  background-color: #D81B60 !important; }

.md-pink-600-bg {
  background-color: #D81B60 !important;
  color: white; }

.pink-600-fg {
  color: #D81B60 !important; }

.pink-600-border {
  border-color: #D81B60 !important; }

.pink-600-border-top {
  border-top-color: #D81B60 !important; }

.pink-600-border-right {
  border-right-color: #D81B60 !important; }

.pink-600-border-bottom {
  border-bottom-color: #D81B60 !important; }

.pink-600-border-left {
  border-left-color: #D81B60 !important; }

.pink-700-bg {
  background-color: #C2185B !important; }

.md-pink-700-bg {
  background-color: #C2185B !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-700-fg {
  color: #C2185B !important; }

.pink-700-border {
  border-color: #C2185B !important; }

.pink-700-border-top {
  border-top-color: #C2185B !important; }

.pink-700-border-right {
  border-right-color: #C2185B !important; }

.pink-700-border-bottom {
  border-bottom-color: #C2185B !important; }

.pink-700-border-left {
  border-left-color: #C2185B !important; }

.pink-800-bg {
  background-color: #AD1457 !important; }

.md-pink-800-bg {
  background-color: #AD1457 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-800-fg {
  color: #AD1457 !important; }

.pink-800-border {
  border-color: #AD1457 !important; }

.pink-800-border-top {
  border-top-color: #AD1457 !important; }

.pink-800-border-right {
  border-right-color: #AD1457 !important; }

.pink-800-border-bottom {
  border-bottom-color: #AD1457 !important; }

.pink-800-border-left {
  border-left-color: #AD1457 !important; }

.pink-900-bg {
  background-color: #880E4F !important; }

.md-pink-900-bg {
  background-color: #880E4F !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-900-fg {
  color: #880E4F !important; }

.pink-900-border {
  border-color: #880E4F !important; }

.pink-900-border-top {
  border-top-color: #880E4F !important; }

.pink-900-border-right {
  border-right-color: #880E4F !important; }

.pink-900-border-bottom {
  border-bottom-color: #880E4F !important; }

.pink-900-border-left {
  border-left-color: #880E4F !important; }

.pink-A100-bg {
  background-color: #FF80AB !important; }

.md-pink-A100-bg {
  background-color: #FF80AB !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-A100-fg {
  color: #FF80AB !important; }

.pink-A100-border {
  border-color: #FF80AB !important; }

.pink-A100-border-top {
  border-top-color: #FF80AB !important; }

.pink-A100-border-right {
  border-right-color: #FF80AB !important; }

.pink-A100-border-bottom {
  border-bottom-color: #FF80AB !important; }

.pink-A100-border-left {
  border-left-color: #FF80AB !important; }

.pink-A200-bg {
  background-color: #FF4081 !important; }

.md-pink-A200-bg {
  background-color: #FF4081 !important;
  color: white; }

.pink-A200-fg {
  color: #FF4081 !important; }

.pink-A200-border {
  border-color: #FF4081 !important; }

.pink-A200-border-top {
  border-top-color: #FF4081 !important; }

.pink-A200-border-right {
  border-right-color: #FF4081 !important; }

.pink-A200-border-bottom {
  border-bottom-color: #FF4081 !important; }

.pink-A200-border-left {
  border-left-color: #FF4081 !important; }

.pink-A400-bg {
  background-color: #F50057 !important; }

.md-pink-A400-bg {
  background-color: #F50057 !important;
  color: white; }

.pink-A400-fg {
  color: #F50057 !important; }

.pink-A400-border {
  border-color: #F50057 !important; }

.pink-A400-border-top {
  border-top-color: #F50057 !important; }

.pink-A400-border-right {
  border-right-color: #F50057 !important; }

.pink-A400-border-bottom {
  border-bottom-color: #F50057 !important; }

.pink-A400-border-left {
  border-left-color: #F50057 !important; }

.pink-A700-bg {
  background-color: #C51162 !important; }

.md-pink-A700-bg {
  background-color: #C51162 !important;
  color: white; }

.pink-A700-fg {
  color: #C51162 !important; }

.pink-A700-border {
  border-color: #C51162 !important; }

.pink-A700-border-top {
  border-top-color: #C51162 !important; }

.pink-A700-border-right {
  border-right-color: #C51162 !important; }

.pink-A700-border-bottom {
  border-bottom-color: #C51162 !important; }

.pink-A700-border-left {
  border-left-color: #C51162 !important; }

.purple-50-bg {
  background-color: #F3E5F5 !important; }

.md-purple-50-bg {
  background-color: #F3E5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-50-fg {
  color: #F3E5F5 !important; }

.purple-50-border {
  border-color: #F3E5F5 !important; }

.purple-50-border-top {
  border-top-color: #F3E5F5 !important; }

.purple-50-border-right {
  border-right-color: #F3E5F5 !important; }

.purple-50-border-bottom {
  border-bottom-color: #F3E5F5 !important; }

.purple-50-border-left {
  border-left-color: #F3E5F5 !important; }

.purple-100-bg {
  background-color: #E1BEE7 !important; }

.md-purple-100-bg {
  background-color: #E1BEE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-100-fg {
  color: #E1BEE7 !important; }

.purple-100-border {
  border-color: #E1BEE7 !important; }

.purple-100-border-top {
  border-top-color: #E1BEE7 !important; }

.purple-100-border-right {
  border-right-color: #E1BEE7 !important; }

.purple-100-border-bottom {
  border-bottom-color: #E1BEE7 !important; }

.purple-100-border-left {
  border-left-color: #E1BEE7 !important; }

.purple-200-bg {
  background-color: #CE93D8 !important; }

.md-purple-200-bg {
  background-color: #CE93D8 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-200-fg {
  color: #CE93D8 !important; }

.purple-200-border {
  border-color: #CE93D8 !important; }

.purple-200-border-top {
  border-top-color: #CE93D8 !important; }

.purple-200-border-right {
  border-right-color: #CE93D8 !important; }

.purple-200-border-bottom {
  border-bottom-color: #CE93D8 !important; }

.purple-200-border-left {
  border-left-color: #CE93D8 !important; }

.purple-300-bg {
  background-color: #BA68C8 !important; }

.md-purple-300-bg {
  background-color: #BA68C8 !important;
  color: white; }

.purple-300-fg {
  color: #BA68C8 !important; }

.purple-300-border {
  border-color: #BA68C8 !important; }

.purple-300-border-top {
  border-top-color: #BA68C8 !important; }

.purple-300-border-right {
  border-right-color: #BA68C8 !important; }

.purple-300-border-bottom {
  border-bottom-color: #BA68C8 !important; }

.purple-300-border-left {
  border-left-color: #BA68C8 !important; }

.purple-400-bg {
  background-color: #AB47BC !important; }

.md-purple-400-bg {
  background-color: #AB47BC !important;
  color: white; }

.purple-400-fg {
  color: #AB47BC !important; }

.purple-400-border {
  border-color: #AB47BC !important; }

.purple-400-border-top {
  border-top-color: #AB47BC !important; }

.purple-400-border-right {
  border-right-color: #AB47BC !important; }

.purple-400-border-bottom {
  border-bottom-color: #AB47BC !important; }

.purple-400-border-left {
  border-left-color: #AB47BC !important; }

.purple-500-bg {
  background-color: #9C27B0 !important; }

.md-purple-500-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-500-fg {
  color: #9C27B0 !important; }

.purple-500-border {
  border-color: #9C27B0 !important; }

.purple-500-border-top {
  border-top-color: #9C27B0 !important; }

.purple-500-border-right {
  border-right-color: #9C27B0 !important; }

.purple-500-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-500-border-left {
  border-left-color: #9C27B0 !important; }

.md-purple-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-bg {
  background-color: #9C27B0 !important; }

.purple-fg {
  color: #9C27B0 !important; }

.purple-border {
  border-color: #9C27B0 !important; }

.purple-border-top {
  border-top-color: #9C27B0 !important; }

.purple-border-right {
  border-right-color: #9C27B0 !important; }

.purple-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-border-left {
  border-left-color: #9C27B0 !important; }

.purple-600-bg {
  background-color: #8E24AA !important; }

.md-purple-600-bg {
  background-color: #8E24AA !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-600-fg {
  color: #8E24AA !important; }

.purple-600-border {
  border-color: #8E24AA !important; }

.purple-600-border-top {
  border-top-color: #8E24AA !important; }

.purple-600-border-right {
  border-right-color: #8E24AA !important; }

.purple-600-border-bottom {
  border-bottom-color: #8E24AA !important; }

.purple-600-border-left {
  border-left-color: #8E24AA !important; }

.purple-700-bg {
  background-color: #7B1FA2 !important; }

.md-purple-700-bg {
  background-color: #7B1FA2 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-700-fg {
  color: #7B1FA2 !important; }

.purple-700-border {
  border-color: #7B1FA2 !important; }

.purple-700-border-top {
  border-top-color: #7B1FA2 !important; }

.purple-700-border-right {
  border-right-color: #7B1FA2 !important; }

.purple-700-border-bottom {
  border-bottom-color: #7B1FA2 !important; }

.purple-700-border-left {
  border-left-color: #7B1FA2 !important; }

.purple-800-bg {
  background-color: #6A1B9A !important; }

.md-purple-800-bg {
  background-color: #6A1B9A !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-800-fg {
  color: #6A1B9A !important; }

.purple-800-border {
  border-color: #6A1B9A !important; }

.purple-800-border-top {
  border-top-color: #6A1B9A !important; }

.purple-800-border-right {
  border-right-color: #6A1B9A !important; }

.purple-800-border-bottom {
  border-bottom-color: #6A1B9A !important; }

.purple-800-border-left {
  border-left-color: #6A1B9A !important; }

.purple-900-bg {
  background-color: #4A148C !important; }

.md-purple-900-bg {
  background-color: #4A148C !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-900-fg {
  color: #4A148C !important; }

.purple-900-border {
  border-color: #4A148C !important; }

.purple-900-border-top {
  border-top-color: #4A148C !important; }

.purple-900-border-right {
  border-right-color: #4A148C !important; }

.purple-900-border-bottom {
  border-bottom-color: #4A148C !important; }

.purple-900-border-left {
  border-left-color: #4A148C !important; }

.purple-A100-bg {
  background-color: #EA80FC !important; }

.md-purple-A100-bg {
  background-color: #EA80FC !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-A100-fg {
  color: #EA80FC !important; }

.purple-A100-border {
  border-color: #EA80FC !important; }

.purple-A100-border-top {
  border-top-color: #EA80FC !important; }

.purple-A100-border-right {
  border-right-color: #EA80FC !important; }

.purple-A100-border-bottom {
  border-bottom-color: #EA80FC !important; }

.purple-A100-border-left {
  border-left-color: #EA80FC !important; }

.purple-A200-bg {
  background-color: #E040FB !important; }

.md-purple-A200-bg {
  background-color: #E040FB !important;
  color: white; }

.purple-A200-fg {
  color: #E040FB !important; }

.purple-A200-border {
  border-color: #E040FB !important; }

.purple-A200-border-top {
  border-top-color: #E040FB !important; }

.purple-A200-border-right {
  border-right-color: #E040FB !important; }

.purple-A200-border-bottom {
  border-bottom-color: #E040FB !important; }

.purple-A200-border-left {
  border-left-color: #E040FB !important; }

.purple-A400-bg {
  background-color: #D500F9 !important; }

.md-purple-A400-bg {
  background-color: #D500F9 !important;
  color: white; }

.purple-A400-fg {
  color: #D500F9 !important; }

.purple-A400-border {
  border-color: #D500F9 !important; }

.purple-A400-border-top {
  border-top-color: #D500F9 !important; }

.purple-A400-border-right {
  border-right-color: #D500F9 !important; }

.purple-A400-border-bottom {
  border-bottom-color: #D500F9 !important; }

.purple-A400-border-left {
  border-left-color: #D500F9 !important; }

.purple-A700-bg {
  background-color: #AA00FF !important; }

.md-purple-A700-bg {
  background-color: #AA00FF !important;
  color: white; }

.purple-A700-fg {
  color: #AA00FF !important; }

.purple-A700-border {
  border-color: #AA00FF !important; }

.purple-A700-border-top {
  border-top-color: #AA00FF !important; }

.purple-A700-border-right {
  border-right-color: #AA00FF !important; }

.purple-A700-border-bottom {
  border-bottom-color: #AA00FF !important; }

.purple-A700-border-left {
  border-left-color: #AA00FF !important; }

.deep-purple-50-bg {
  background-color: #EDE7F6 !important; }

.md-deep-purple-50-bg {
  background-color: #EDE7F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-50-fg {
  color: #EDE7F6 !important; }

.deep-purple-50-border {
  border-color: #EDE7F6 !important; }

.deep-purple-50-border-top {
  border-top-color: #EDE7F6 !important; }

.deep-purple-50-border-right {
  border-right-color: #EDE7F6 !important; }

.deep-purple-50-border-bottom {
  border-bottom-color: #EDE7F6 !important; }

.deep-purple-50-border-left {
  border-left-color: #EDE7F6 !important; }

.deep-purple-100-bg {
  background-color: #D1C4E9 !important; }

.md-deep-purple-100-bg {
  background-color: #D1C4E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-100-fg {
  color: #D1C4E9 !important; }

.deep-purple-100-border {
  border-color: #D1C4E9 !important; }

.deep-purple-100-border-top {
  border-top-color: #D1C4E9 !important; }

.deep-purple-100-border-right {
  border-right-color: #D1C4E9 !important; }

.deep-purple-100-border-bottom {
  border-bottom-color: #D1C4E9 !important; }

.deep-purple-100-border-left {
  border-left-color: #D1C4E9 !important; }

.deep-purple-200-bg {
  background-color: #B39DDB !important; }

.md-deep-purple-200-bg {
  background-color: #B39DDB !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-200-fg {
  color: #B39DDB !important; }

.deep-purple-200-border {
  border-color: #B39DDB !important; }

.deep-purple-200-border-top {
  border-top-color: #B39DDB !important; }

.deep-purple-200-border-right {
  border-right-color: #B39DDB !important; }

.deep-purple-200-border-bottom {
  border-bottom-color: #B39DDB !important; }

.deep-purple-200-border-left {
  border-left-color: #B39DDB !important; }

.deep-purple-300-bg {
  background-color: #9575CD !important; }

.md-deep-purple-300-bg {
  background-color: #9575CD !important;
  color: white; }

.deep-purple-300-fg {
  color: #9575CD !important; }

.deep-purple-300-border {
  border-color: #9575CD !important; }

.deep-purple-300-border-top {
  border-top-color: #9575CD !important; }

.deep-purple-300-border-right {
  border-right-color: #9575CD !important; }

.deep-purple-300-border-bottom {
  border-bottom-color: #9575CD !important; }

.deep-purple-300-border-left {
  border-left-color: #9575CD !important; }

.deep-purple-400-bg {
  background-color: #7E57C2 !important; }

.md-deep-purple-400-bg {
  background-color: #7E57C2 !important;
  color: white; }

.deep-purple-400-fg {
  color: #7E57C2 !important; }

.deep-purple-400-border {
  border-color: #7E57C2 !important; }

.deep-purple-400-border-top {
  border-top-color: #7E57C2 !important; }

.deep-purple-400-border-right {
  border-right-color: #7E57C2 !important; }

.deep-purple-400-border-bottom {
  border-bottom-color: #7E57C2 !important; }

.deep-purple-400-border-left {
  border-left-color: #7E57C2 !important; }

.deep-purple-500-bg {
  background-color: #673AB7 !important; }

.md-deep-purple-500-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-500-fg {
  color: #673AB7 !important; }

.deep-purple-500-border {
  border-color: #673AB7 !important; }

.deep-purple-500-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-500-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-500-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-500-border-left {
  border-left-color: #673AB7 !important; }

.md-deep-purple-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-bg {
  background-color: #673AB7 !important; }

.deep-purple-fg {
  color: #673AB7 !important; }

.deep-purple-border {
  border-color: #673AB7 !important; }

.deep-purple-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-border-left {
  border-left-color: #673AB7 !important; }

.deep-purple-600-bg {
  background-color: #5E35B1 !important; }

.md-deep-purple-600-bg {
  background-color: #5E35B1 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-600-fg {
  color: #5E35B1 !important; }

.deep-purple-600-border {
  border-color: #5E35B1 !important; }

.deep-purple-600-border-top {
  border-top-color: #5E35B1 !important; }

.deep-purple-600-border-right {
  border-right-color: #5E35B1 !important; }

.deep-purple-600-border-bottom {
  border-bottom-color: #5E35B1 !important; }

.deep-purple-600-border-left {
  border-left-color: #5E35B1 !important; }

.deep-purple-700-bg {
  background-color: #512DA8 !important; }

.md-deep-purple-700-bg {
  background-color: #512DA8 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-700-fg {
  color: #512DA8 !important; }

.deep-purple-700-border {
  border-color: #512DA8 !important; }

.deep-purple-700-border-top {
  border-top-color: #512DA8 !important; }

.deep-purple-700-border-right {
  border-right-color: #512DA8 !important; }

.deep-purple-700-border-bottom {
  border-bottom-color: #512DA8 !important; }

.deep-purple-700-border-left {
  border-left-color: #512DA8 !important; }

.deep-purple-800-bg {
  background-color: #4527A0 !important; }

.md-deep-purple-800-bg {
  background-color: #4527A0 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-800-fg {
  color: #4527A0 !important; }

.deep-purple-800-border {
  border-color: #4527A0 !important; }

.deep-purple-800-border-top {
  border-top-color: #4527A0 !important; }

.deep-purple-800-border-right {
  border-right-color: #4527A0 !important; }

.deep-purple-800-border-bottom {
  border-bottom-color: #4527A0 !important; }

.deep-purple-800-border-left {
  border-left-color: #4527A0 !important; }

.deep-purple-900-bg {
  background-color: #311B92 !important; }

.md-deep-purple-900-bg {
  background-color: #311B92 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-900-fg {
  color: #311B92 !important; }

.deep-purple-900-border {
  border-color: #311B92 !important; }

.deep-purple-900-border-top {
  border-top-color: #311B92 !important; }

.deep-purple-900-border-right {
  border-right-color: #311B92 !important; }

.deep-purple-900-border-bottom {
  border-bottom-color: #311B92 !important; }

.deep-purple-900-border-left {
  border-left-color: #311B92 !important; }

.deep-purple-A100-bg {
  background-color: #B388FF !important; }

.md-deep-purple-A100-bg {
  background-color: #B388FF !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-A100-fg {
  color: #B388FF !important; }

.deep-purple-A100-border {
  border-color: #B388FF !important; }

.deep-purple-A100-border-top {
  border-top-color: #B388FF !important; }

.deep-purple-A100-border-right {
  border-right-color: #B388FF !important; }

.deep-purple-A100-border-bottom {
  border-bottom-color: #B388FF !important; }

.deep-purple-A100-border-left {
  border-left-color: #B388FF !important; }

.deep-purple-A200-bg {
  background-color: #7C4DFF !important; }

.md-deep-purple-A200-bg {
  background-color: #7C4DFF !important;
  color: white; }

.deep-purple-A200-fg {
  color: #7C4DFF !important; }

.deep-purple-A200-border {
  border-color: #7C4DFF !important; }

.deep-purple-A200-border-top {
  border-top-color: #7C4DFF !important; }

.deep-purple-A200-border-right {
  border-right-color: #7C4DFF !important; }

.deep-purple-A200-border-bottom {
  border-bottom-color: #7C4DFF !important; }

.deep-purple-A200-border-left {
  border-left-color: #7C4DFF !important; }

.deep-purple-A400-bg {
  background-color: #651FFF !important; }

.md-deep-purple-A400-bg {
  background-color: #651FFF !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A400-fg {
  color: #651FFF !important; }

.deep-purple-A400-border {
  border-color: #651FFF !important; }

.deep-purple-A400-border-top {
  border-top-color: #651FFF !important; }

.deep-purple-A400-border-right {
  border-right-color: #651FFF !important; }

.deep-purple-A400-border-bottom {
  border-bottom-color: #651FFF !important; }

.deep-purple-A400-border-left {
  border-left-color: #651FFF !important; }

.deep-purple-A700-bg {
  background-color: #6200EA !important; }

.md-deep-purple-A700-bg {
  background-color: #6200EA !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A700-fg {
  color: #6200EA !important; }

.deep-purple-A700-border {
  border-color: #6200EA !important; }

.deep-purple-A700-border-top {
  border-top-color: #6200EA !important; }

.deep-purple-A700-border-right {
  border-right-color: #6200EA !important; }

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200EA !important; }

.deep-purple-A700-border-left {
  border-left-color: #6200EA !important; }

.indigo-50-bg {
  background-color: #E8EAF6 !important; }

.md-indigo-50-bg {
  background-color: #E8EAF6 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-50-fg {
  color: #E8EAF6 !important; }

.indigo-50-border {
  border-color: #E8EAF6 !important; }

.indigo-50-border-top {
  border-top-color: #E8EAF6 !important; }

.indigo-50-border-right {
  border-right-color: #E8EAF6 !important; }

.indigo-50-border-bottom {
  border-bottom-color: #E8EAF6 !important; }

.indigo-50-border-left {
  border-left-color: #E8EAF6 !important; }

.indigo-100-bg {
  background-color: #C5CAE9 !important; }

.md-indigo-100-bg {
  background-color: #C5CAE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-100-fg {
  color: #C5CAE9 !important; }

.indigo-100-border {
  border-color: #C5CAE9 !important; }

.indigo-100-border-top {
  border-top-color: #C5CAE9 !important; }

.indigo-100-border-right {
  border-right-color: #C5CAE9 !important; }

.indigo-100-border-bottom {
  border-bottom-color: #C5CAE9 !important; }

.indigo-100-border-left {
  border-left-color: #C5CAE9 !important; }

.indigo-200-bg {
  background-color: #9FA8DA !important; }

.md-indigo-200-bg {
  background-color: #9FA8DA !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-200-fg {
  color: #9FA8DA !important; }

.indigo-200-border {
  border-color: #9FA8DA !important; }

.indigo-200-border-top {
  border-top-color: #9FA8DA !important; }

.indigo-200-border-right {
  border-right-color: #9FA8DA !important; }

.indigo-200-border-bottom {
  border-bottom-color: #9FA8DA !important; }

.indigo-200-border-left {
  border-left-color: #9FA8DA !important; }

.indigo-300-bg {
  background-color: #7986CB !important; }

.md-indigo-300-bg {
  background-color: #7986CB !important;
  color: white; }

.indigo-300-fg {
  color: #7986CB !important; }

.indigo-300-border {
  border-color: #7986CB !important; }

.indigo-300-border-top {
  border-top-color: #7986CB !important; }

.indigo-300-border-right {
  border-right-color: #7986CB !important; }

.indigo-300-border-bottom {
  border-bottom-color: #7986CB !important; }

.indigo-300-border-left {
  border-left-color: #7986CB !important; }

.indigo-400-bg {
  background-color: #5C6BC0 !important; }

.md-indigo-400-bg {
  background-color: #5C6BC0 !important;
  color: white; }

.indigo-400-fg {
  color: #5C6BC0 !important; }

.indigo-400-border {
  border-color: #5C6BC0 !important; }

.indigo-400-border-top {
  border-top-color: #5C6BC0 !important; }

.indigo-400-border-right {
  border-right-color: #5C6BC0 !important; }

.indigo-400-border-bottom {
  border-bottom-color: #5C6BC0 !important; }

.indigo-400-border-left {
  border-left-color: #5C6BC0 !important; }

.indigo-500-bg {
  background-color: #3F51B5 !important; }

.md-indigo-500-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-500-fg {
  color: #3F51B5 !important; }

.indigo-500-border {
  border-color: #3F51B5 !important; }

.indigo-500-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-500-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-500-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-500-border-left {
  border-left-color: #3F51B5 !important; }

.md-indigo-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-bg {
  background-color: #3F51B5 !important; }

.indigo-fg {
  color: #3F51B5 !important; }

.indigo-border {
  border-color: #3F51B5 !important; }

.indigo-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-border-left {
  border-left-color: #3F51B5 !important; }

.indigo-600-bg {
  background-color: #3949AB !important; }

.md-indigo-600-bg {
  background-color: #3949AB !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-600-fg {
  color: #3949AB !important; }

.indigo-600-border {
  border-color: #3949AB !important; }

.indigo-600-border-top {
  border-top-color: #3949AB !important; }

.indigo-600-border-right {
  border-right-color: #3949AB !important; }

.indigo-600-border-bottom {
  border-bottom-color: #3949AB !important; }

.indigo-600-border-left {
  border-left-color: #3949AB !important; }

.indigo-700-bg {
  background-color: #303F9F !important; }

.md-indigo-700-bg {
  background-color: #303F9F !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-700-fg {
  color: #303F9F !important; }

.indigo-700-border {
  border-color: #303F9F !important; }

.indigo-700-border-top {
  border-top-color: #303F9F !important; }

.indigo-700-border-right {
  border-right-color: #303F9F !important; }

.indigo-700-border-bottom {
  border-bottom-color: #303F9F !important; }

.indigo-700-border-left {
  border-left-color: #303F9F !important; }

.indigo-800-bg {
  background-color: #283593 !important; }

.md-indigo-800-bg {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-800-fg {
  color: #283593 !important; }

.indigo-800-border {
  border-color: #283593 !important; }

.indigo-800-border-top {
  border-top-color: #283593 !important; }

.indigo-800-border-right {
  border-right-color: #283593 !important; }

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important; }

.indigo-800-border-left {
  border-left-color: #283593 !important; }

.indigo-900-bg {
  background-color: #1A237E !important; }

.md-indigo-900-bg {
  background-color: #1A237E !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-900-fg {
  color: #1A237E !important; }

.indigo-900-border {
  border-color: #1A237E !important; }

.indigo-900-border-top {
  border-top-color: #1A237E !important; }

.indigo-900-border-right {
  border-right-color: #1A237E !important; }

.indigo-900-border-bottom {
  border-bottom-color: #1A237E !important; }

.indigo-900-border-left {
  border-left-color: #1A237E !important; }

.indigo-A100-bg {
  background-color: #8C9EFF !important; }

.md-indigo-A100-bg {
  background-color: #8C9EFF !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-A100-fg {
  color: #8C9EFF !important; }

.indigo-A100-border {
  border-color: #8C9EFF !important; }

.indigo-A100-border-top {
  border-top-color: #8C9EFF !important; }

.indigo-A100-border-right {
  border-right-color: #8C9EFF !important; }

.indigo-A100-border-bottom {
  border-bottom-color: #8C9EFF !important; }

.indigo-A100-border-left {
  border-left-color: #8C9EFF !important; }

.indigo-A200-bg {
  background-color: #536DFE !important; }

.md-indigo-A200-bg {
  background-color: #536DFE !important;
  color: white; }

.indigo-A200-fg {
  color: #536DFE !important; }

.indigo-A200-border {
  border-color: #536DFE !important; }

.indigo-A200-border-top {
  border-top-color: #536DFE !important; }

.indigo-A200-border-right {
  border-right-color: #536DFE !important; }

.indigo-A200-border-bottom {
  border-bottom-color: #536DFE !important; }

.indigo-A200-border-left {
  border-left-color: #536DFE !important; }

.indigo-A400-bg {
  background-color: #3D5AFE !important; }

.md-indigo-A400-bg {
  background-color: #3D5AFE !important;
  color: white; }

.indigo-A400-fg {
  color: #3D5AFE !important; }

.indigo-A400-border {
  border-color: #3D5AFE !important; }

.indigo-A400-border-top {
  border-top-color: #3D5AFE !important; }

.indigo-A400-border-right {
  border-right-color: #3D5AFE !important; }

.indigo-A400-border-bottom {
  border-bottom-color: #3D5AFE !important; }

.indigo-A400-border-left {
  border-left-color: #3D5AFE !important; }

.indigo-A700-bg {
  background-color: #304FFE !important; }

.md-indigo-A700-bg {
  background-color: #304FFE !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A700-fg {
  color: #304FFE !important; }

.indigo-A700-border {
  border-color: #304FFE !important; }

.indigo-A700-border-top {
  border-top-color: #304FFE !important; }

.indigo-A700-border-right {
  border-right-color: #304FFE !important; }

.indigo-A700-border-bottom {
  border-bottom-color: #304FFE !important; }

.indigo-A700-border-left {
  border-left-color: #304FFE !important; }

.blue-50-bg {
  background-color: #E3F2FD !important; }

.md-blue-50-bg {
  background-color: #E3F2FD !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-50-fg {
  color: #E3F2FD !important; }

.blue-50-border {
  border-color: #E3F2FD !important; }

.blue-50-border-top {
  border-top-color: #E3F2FD !important; }

.blue-50-border-right {
  border-right-color: #E3F2FD !important; }

.blue-50-border-bottom {
  border-bottom-color: #E3F2FD !important; }

.blue-50-border-left {
  border-left-color: #E3F2FD !important; }

.blue-100-bg {
  background-color: #BBDEFB !important; }

.md-blue-100-bg {
  background-color: #BBDEFB !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-100-fg {
  color: #BBDEFB !important; }

.blue-100-border {
  border-color: #BBDEFB !important; }

.blue-100-border-top {
  border-top-color: #BBDEFB !important; }

.blue-100-border-right {
  border-right-color: #BBDEFB !important; }

.blue-100-border-bottom {
  border-bottom-color: #BBDEFB !important; }

.blue-100-border-left {
  border-left-color: #BBDEFB !important; }

.blue-200-bg {
  background-color: #90CAF9 !important; }

.md-blue-200-bg {
  background-color: #90CAF9 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-200-fg {
  color: #90CAF9 !important; }

.blue-200-border {
  border-color: #90CAF9 !important; }

.blue-200-border-top {
  border-top-color: #90CAF9 !important; }

.blue-200-border-right {
  border-right-color: #90CAF9 !important; }

.blue-200-border-bottom {
  border-bottom-color: #90CAF9 !important; }

.blue-200-border-left {
  border-left-color: #90CAF9 !important; }

.blue-300-bg {
  background-color: #64B5F6 !important; }

.md-blue-300-bg {
  background-color: #64B5F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-300-fg {
  color: #64B5F6 !important; }

.blue-300-border {
  border-color: #64B5F6 !important; }

.blue-300-border-top {
  border-top-color: #64B5F6 !important; }

.blue-300-border-right {
  border-right-color: #64B5F6 !important; }

.blue-300-border-bottom {
  border-bottom-color: #64B5F6 !important; }

.blue-300-border-left {
  border-left-color: #64B5F6 !important; }

.blue-400-bg {
  background-color: #42A5F5 !important; }

.md-blue-400-bg {
  background-color: #42A5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-400-fg {
  color: #42A5F5 !important; }

.blue-400-border {
  border-color: #42A5F5 !important; }

.blue-400-border-top {
  border-top-color: #42A5F5 !important; }

.blue-400-border-right {
  border-right-color: #42A5F5 !important; }

.blue-400-border-bottom {
  border-bottom-color: #42A5F5 !important; }

.blue-400-border-left {
  border-left-color: #42A5F5 !important; }

.blue-500-bg {
  background-color: #2196F3 !important; }

.md-blue-500-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-500-fg {
  color: #2196F3 !important; }

.blue-500-border {
  border-color: #2196F3 !important; }

.blue-500-border-top {
  border-top-color: #2196F3 !important; }

.blue-500-border-right {
  border-right-color: #2196F3 !important; }

.blue-500-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-500-border-left {
  border-left-color: #2196F3 !important; }

.md-blue-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-bg {
  background-color: #2196F3 !important; }

.blue-fg {
  color: #2196F3 !important; }

.blue-border {
  border-color: #2196F3 !important; }

.blue-border-top {
  border-top-color: #2196F3 !important; }

.blue-border-right {
  border-right-color: #2196F3 !important; }

.blue-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-border-left {
  border-left-color: #2196F3 !important; }

.blue-600-bg {
  background-color: #1E88E5 !important; }

.md-blue-600-bg {
  background-color: #1E88E5 !important;
  color: white; }

.blue-600-fg {
  color: #1E88E5 !important; }

.blue-600-border {
  border-color: #1E88E5 !important; }

.blue-600-border-top {
  border-top-color: #1E88E5 !important; }

.blue-600-border-right {
  border-right-color: #1E88E5 !important; }

.blue-600-border-bottom {
  border-bottom-color: #1E88E5 !important; }

.blue-600-border-left {
  border-left-color: #1E88E5 !important; }

.blue-700-bg {
  background-color: #1976D2 !important; }

.md-blue-700-bg {
  background-color: #1976D2 !important;
  color: white; }

.blue-700-fg {
  color: #1976D2 !important; }

.blue-700-border {
  border-color: #1976D2 !important; }

.blue-700-border-top {
  border-top-color: #1976D2 !important; }

.blue-700-border-right {
  border-right-color: #1976D2 !important; }

.blue-700-border-bottom {
  border-bottom-color: #1976D2 !important; }

.blue-700-border-left {
  border-left-color: #1976D2 !important; }

.blue-800-bg {
  background-color: #1565C0 !important; }

.md-blue-800-bg {
  background-color: #1565C0 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-800-fg {
  color: #1565C0 !important; }

.blue-800-border {
  border-color: #1565C0 !important; }

.blue-800-border-top {
  border-top-color: #1565C0 !important; }

.blue-800-border-right {
  border-right-color: #1565C0 !important; }

.blue-800-border-bottom {
  border-bottom-color: #1565C0 !important; }

.blue-800-border-left {
  border-left-color: #1565C0 !important; }

.blue-900-bg {
  background-color: #0D47A1 !important; }

.md-blue-900-bg {
  background-color: #0D47A1 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-900-fg {
  color: #0D47A1 !important; }

.blue-900-border {
  border-color: #0D47A1 !important; }

.blue-900-border-top {
  border-top-color: #0D47A1 !important; }

.blue-900-border-right {
  border-right-color: #0D47A1 !important; }

.blue-900-border-bottom {
  border-bottom-color: #0D47A1 !important; }

.blue-900-border-left {
  border-left-color: #0D47A1 !important; }

.blue-A100-bg {
  background-color: #82B1FF !important; }

.md-blue-A100-bg {
  background-color: #82B1FF !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-A100-fg {
  color: #82B1FF !important; }

.blue-A100-border {
  border-color: #82B1FF !important; }

.blue-A100-border-top {
  border-top-color: #82B1FF !important; }

.blue-A100-border-right {
  border-right-color: #82B1FF !important; }

.blue-A100-border-bottom {
  border-bottom-color: #82B1FF !important; }

.blue-A100-border-left {
  border-left-color: #82B1FF !important; }

.blue-A200-bg {
  background-color: #448AFF !important; }

.md-blue-A200-bg {
  background-color: #448AFF !important;
  color: white; }

.blue-A200-fg {
  color: #448AFF !important; }

.blue-A200-border {
  border-color: #448AFF !important; }

.blue-A200-border-top {
  border-top-color: #448AFF !important; }

.blue-A200-border-right {
  border-right-color: #448AFF !important; }

.blue-A200-border-bottom {
  border-bottom-color: #448AFF !important; }

.blue-A200-border-left {
  border-left-color: #448AFF !important; }

.blue-A400-bg {
  background-color: #2979FF !important; }

.md-blue-A400-bg {
  background-color: #2979FF !important;
  color: white; }

.blue-A400-fg {
  color: #2979FF !important; }

.blue-A400-border {
  border-color: #2979FF !important; }

.blue-A400-border-top {
  border-top-color: #2979FF !important; }

.blue-A400-border-right {
  border-right-color: #2979FF !important; }

.blue-A400-border-bottom {
  border-bottom-color: #2979FF !important; }

.blue-A400-border-left {
  border-left-color: #2979FF !important; }

.blue-A700-bg {
  background-color: #2962FF !important; }

.md-blue-A700-bg {
  background-color: #2962FF !important;
  color: white; }

.blue-A700-fg {
  color: #2962FF !important; }

.blue-A700-border {
  border-color: #2962FF !important; }

.blue-A700-border-top {
  border-top-color: #2962FF !important; }

.blue-A700-border-right {
  border-right-color: #2962FF !important; }

.blue-A700-border-bottom {
  border-bottom-color: #2962FF !important; }

.blue-A700-border-left {
  border-left-color: #2962FF !important; }

.light-blue-50-bg {
  background-color: #E1F5FE !important; }

.md-light-blue-50-bg {
  background-color: #E1F5FE !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-50-fg {
  color: #E1F5FE !important; }

.light-blue-50-border {
  border-color: #E1F5FE !important; }

.light-blue-50-border-top {
  border-top-color: #E1F5FE !important; }

.light-blue-50-border-right {
  border-right-color: #E1F5FE !important; }

.light-blue-50-border-bottom {
  border-bottom-color: #E1F5FE !important; }

.light-blue-50-border-left {
  border-left-color: #E1F5FE !important; }

.light-blue-100-bg {
  background-color: #B3E5FC !important; }

.md-light-blue-100-bg {
  background-color: #B3E5FC !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-100-fg {
  color: #B3E5FC !important; }

.light-blue-100-border {
  border-color: #B3E5FC !important; }

.light-blue-100-border-top {
  border-top-color: #B3E5FC !important; }

.light-blue-100-border-right {
  border-right-color: #B3E5FC !important; }

.light-blue-100-border-bottom {
  border-bottom-color: #B3E5FC !important; }

.light-blue-100-border-left {
  border-left-color: #B3E5FC !important; }

.light-blue-200-bg {
  background-color: #81D4FA !important; }

.md-light-blue-200-bg {
  background-color: #81D4FA !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-200-fg {
  color: #81D4FA !important; }

.light-blue-200-border {
  border-color: #81D4FA !important; }

.light-blue-200-border-top {
  border-top-color: #81D4FA !important; }

.light-blue-200-border-right {
  border-right-color: #81D4FA !important; }

.light-blue-200-border-bottom {
  border-bottom-color: #81D4FA !important; }

.light-blue-200-border-left {
  border-left-color: #81D4FA !important; }

.light-blue-300-bg {
  background-color: #4FC3F7 !important; }

.md-light-blue-300-bg {
  background-color: #4FC3F7 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-300-fg {
  color: #4FC3F7 !important; }

.light-blue-300-border {
  border-color: #4FC3F7 !important; }

.light-blue-300-border-top {
  border-top-color: #4FC3F7 !important; }

.light-blue-300-border-right {
  border-right-color: #4FC3F7 !important; }

.light-blue-300-border-bottom {
  border-bottom-color: #4FC3F7 !important; }

.light-blue-300-border-left {
  border-left-color: #4FC3F7 !important; }

.light-blue-400-bg {
  background-color: #29B6F6 !important; }

.md-light-blue-400-bg {
  background-color: #29B6F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-400-fg {
  color: #29B6F6 !important; }

.light-blue-400-border {
  border-color: #29B6F6 !important; }

.light-blue-400-border-top {
  border-top-color: #29B6F6 !important; }

.light-blue-400-border-right {
  border-right-color: #29B6F6 !important; }

.light-blue-400-border-bottom {
  border-bottom-color: #29B6F6 !important; }

.light-blue-400-border-left {
  border-left-color: #29B6F6 !important; }

.light-blue-500-bg {
  background-color: #03A9F4 !important; }

.md-light-blue-500-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-500-fg {
  color: #03A9F4 !important; }

.light-blue-500-border {
  border-color: #03A9F4 !important; }

.light-blue-500-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-500-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-500-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-500-border-left {
  border-left-color: #03A9F4 !important; }

.md-light-blue-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-bg {
  background-color: #03A9F4 !important; }

.light-blue-fg {
  color: #03A9F4 !important; }

.light-blue-border {
  border-color: #03A9F4 !important; }

.light-blue-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-border-left {
  border-left-color: #03A9F4 !important; }

.light-blue-600-bg {
  background-color: #039BE5 !important; }

.md-light-blue-600-bg {
  background-color: #039BE5 !important;
  color: white; }

.light-blue-600-fg {
  color: #039BE5 !important; }

.light-blue-600-border {
  border-color: #039BE5 !important; }

.light-blue-600-border-top {
  border-top-color: #039BE5 !important; }

.light-blue-600-border-right {
  border-right-color: #039BE5 !important; }

.light-blue-600-border-bottom {
  border-bottom-color: #039BE5 !important; }

.light-blue-600-border-left {
  border-left-color: #039BE5 !important; }

.light-blue-700-bg {
  background-color: #0288D1 !important; }

.md-light-blue-700-bg {
  background-color: #0288D1 !important;
  color: white; }

.light-blue-700-fg {
  color: #0288D1 !important; }

.light-blue-700-border {
  border-color: #0288D1 !important; }

.light-blue-700-border-top {
  border-top-color: #0288D1 !important; }

.light-blue-700-border-right {
  border-right-color: #0288D1 !important; }

.light-blue-700-border-bottom {
  border-bottom-color: #0288D1 !important; }

.light-blue-700-border-left {
  border-left-color: #0288D1 !important; }

.light-blue-800-bg {
  background-color: #0277BD !important; }

.md-light-blue-800-bg {
  background-color: #0277BD !important;
  color: white; }

.light-blue-800-fg {
  color: #0277BD !important; }

.light-blue-800-border {
  border-color: #0277BD !important; }

.light-blue-800-border-top {
  border-top-color: #0277BD !important; }

.light-blue-800-border-right {
  border-right-color: #0277BD !important; }

.light-blue-800-border-bottom {
  border-bottom-color: #0277BD !important; }

.light-blue-800-border-left {
  border-left-color: #0277BD !important; }

.light-blue-900-bg {
  background-color: #01579B !important; }

.md-light-blue-900-bg {
  background-color: #01579B !important;
  color: white; }

.light-blue-900-fg {
  color: #01579B !important; }

.light-blue-900-border {
  border-color: #01579B !important; }

.light-blue-900-border-top {
  border-top-color: #01579B !important; }

.light-blue-900-border-right {
  border-right-color: #01579B !important; }

.light-blue-900-border-bottom {
  border-bottom-color: #01579B !important; }

.light-blue-900-border-left {
  border-left-color: #01579B !important; }

.light-blue-A100-bg {
  background-color: #80D8FF !important; }

.md-light-blue-A100-bg {
  background-color: #80D8FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A100-fg {
  color: #80D8FF !important; }

.light-blue-A100-border {
  border-color: #80D8FF !important; }

.light-blue-A100-border-top {
  border-top-color: #80D8FF !important; }

.light-blue-A100-border-right {
  border-right-color: #80D8FF !important; }

.light-blue-A100-border-bottom {
  border-bottom-color: #80D8FF !important; }

.light-blue-A100-border-left {
  border-left-color: #80D8FF !important; }

.light-blue-A200-bg {
  background-color: #40C4FF !important; }

.md-light-blue-A200-bg {
  background-color: #40C4FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A200-fg {
  color: #40C4FF !important; }

.light-blue-A200-border {
  border-color: #40C4FF !important; }

.light-blue-A200-border-top {
  border-top-color: #40C4FF !important; }

.light-blue-A200-border-right {
  border-right-color: #40C4FF !important; }

.light-blue-A200-border-bottom {
  border-bottom-color: #40C4FF !important; }

.light-blue-A200-border-left {
  border-left-color: #40C4FF !important; }

.light-blue-A400-bg {
  background-color: #00B0FF !important; }

.md-light-blue-A400-bg {
  background-color: #00B0FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A400-fg {
  color: #00B0FF !important; }

.light-blue-A400-border {
  border-color: #00B0FF !important; }

.light-blue-A400-border-top {
  border-top-color: #00B0FF !important; }

.light-blue-A400-border-right {
  border-right-color: #00B0FF !important; }

.light-blue-A400-border-bottom {
  border-bottom-color: #00B0FF !important; }

.light-blue-A400-border-left {
  border-left-color: #00B0FF !important; }

.light-blue-A700-bg {
  background-color: #0091EA !important; }

.md-light-blue-A700-bg {
  background-color: #0091EA !important;
  color: white; }

.light-blue-A700-fg {
  color: #0091EA !important; }

.light-blue-A700-border {
  border-color: #0091EA !important; }

.light-blue-A700-border-top {
  border-top-color: #0091EA !important; }

.light-blue-A700-border-right {
  border-right-color: #0091EA !important; }

.light-blue-A700-border-bottom {
  border-bottom-color: #0091EA !important; }

.light-blue-A700-border-left {
  border-left-color: #0091EA !important; }

.cyan-50-bg {
  background-color: #E0F7FA !important; }

.md-cyan-50-bg {
  background-color: #E0F7FA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-50-fg {
  color: #E0F7FA !important; }

.cyan-50-border {
  border-color: #E0F7FA !important; }

.cyan-50-border-top {
  border-top-color: #E0F7FA !important; }

.cyan-50-border-right {
  border-right-color: #E0F7FA !important; }

.cyan-50-border-bottom {
  border-bottom-color: #E0F7FA !important; }

.cyan-50-border-left {
  border-left-color: #E0F7FA !important; }

.cyan-100-bg {
  background-color: #B2EBF2 !important; }

.md-cyan-100-bg {
  background-color: #B2EBF2 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-100-fg {
  color: #B2EBF2 !important; }

.cyan-100-border {
  border-color: #B2EBF2 !important; }

.cyan-100-border-top {
  border-top-color: #B2EBF2 !important; }

.cyan-100-border-right {
  border-right-color: #B2EBF2 !important; }

.cyan-100-border-bottom {
  border-bottom-color: #B2EBF2 !important; }

.cyan-100-border-left {
  border-left-color: #B2EBF2 !important; }

.cyan-200-bg {
  background-color: #80DEEA !important; }

.md-cyan-200-bg {
  background-color: #80DEEA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-200-fg {
  color: #80DEEA !important; }

.cyan-200-border {
  border-color: #80DEEA !important; }

.cyan-200-border-top {
  border-top-color: #80DEEA !important; }

.cyan-200-border-right {
  border-right-color: #80DEEA !important; }

.cyan-200-border-bottom {
  border-bottom-color: #80DEEA !important; }

.cyan-200-border-left {
  border-left-color: #80DEEA !important; }

.cyan-300-bg {
  background-color: #4DD0E1 !important; }

.md-cyan-300-bg {
  background-color: #4DD0E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-300-fg {
  color: #4DD0E1 !important; }

.cyan-300-border {
  border-color: #4DD0E1 !important; }

.cyan-300-border-top {
  border-top-color: #4DD0E1 !important; }

.cyan-300-border-right {
  border-right-color: #4DD0E1 !important; }

.cyan-300-border-bottom {
  border-bottom-color: #4DD0E1 !important; }

.cyan-300-border-left {
  border-left-color: #4DD0E1 !important; }

.cyan-400-bg {
  background-color: #26C6DA !important; }

.md-cyan-400-bg {
  background-color: #26C6DA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-400-fg {
  color: #26C6DA !important; }

.cyan-400-border {
  border-color: #26C6DA !important; }

.cyan-400-border-top {
  border-top-color: #26C6DA !important; }

.cyan-400-border-right {
  border-right-color: #26C6DA !important; }

.cyan-400-border-bottom {
  border-bottom-color: #26C6DA !important; }

.cyan-400-border-left {
  border-left-color: #26C6DA !important; }

.cyan-500-bg {
  background-color: #00BCD4 !important; }

.md-cyan-500-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-500-fg {
  color: #00BCD4 !important; }

.cyan-500-border {
  border-color: #00BCD4 !important; }

.cyan-500-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-500-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-500-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-500-border-left {
  border-left-color: #00BCD4 !important; }

.md-cyan-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-bg {
  background-color: #00BCD4 !important; }

.cyan-fg {
  color: #00BCD4 !important; }

.cyan-border {
  border-color: #00BCD4 !important; }

.cyan-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-border-left {
  border-left-color: #00BCD4 !important; }

.cyan-600-bg {
  background-color: #00ACC1 !important; }

.md-cyan-600-bg {
  background-color: #00ACC1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-600-fg {
  color: #00ACC1 !important; }

.cyan-600-border {
  border-color: #00ACC1 !important; }

.cyan-600-border-top {
  border-top-color: #00ACC1 !important; }

.cyan-600-border-right {
  border-right-color: #00ACC1 !important; }

.cyan-600-border-bottom {
  border-bottom-color: #00ACC1 !important; }

.cyan-600-border-left {
  border-left-color: #00ACC1 !important; }

.cyan-700-bg {
  background-color: #0097A7 !important; }

.md-cyan-700-bg {
  background-color: #0097A7 !important;
  color: white; }

.cyan-700-fg {
  color: #0097A7 !important; }

.cyan-700-border {
  border-color: #0097A7 !important; }

.cyan-700-border-top {
  border-top-color: #0097A7 !important; }

.cyan-700-border-right {
  border-right-color: #0097A7 !important; }

.cyan-700-border-bottom {
  border-bottom-color: #0097A7 !important; }

.cyan-700-border-left {
  border-left-color: #0097A7 !important; }

.cyan-800-bg {
  background-color: #00838F !important; }

.md-cyan-800-bg {
  background-color: #00838F !important;
  color: white; }

.cyan-800-fg {
  color: #00838F !important; }

.cyan-800-border {
  border-color: #00838F !important; }

.cyan-800-border-top {
  border-top-color: #00838F !important; }

.cyan-800-border-right {
  border-right-color: #00838F !important; }

.cyan-800-border-bottom {
  border-bottom-color: #00838F !important; }

.cyan-800-border-left {
  border-left-color: #00838F !important; }

.cyan-900-bg {
  background-color: #006064 !important; }

.md-cyan-900-bg {
  background-color: #006064 !important;
  color: white; }

.cyan-900-fg {
  color: #006064 !important; }

.cyan-900-border {
  border-color: #006064 !important; }

.cyan-900-border-top {
  border-top-color: #006064 !important; }

.cyan-900-border-right {
  border-right-color: #006064 !important; }

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important; }

.cyan-900-border-left {
  border-left-color: #006064 !important; }

.cyan-A100-bg {
  background-color: #84FFFF !important; }

.md-cyan-A100-bg {
  background-color: #84FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A100-fg {
  color: #84FFFF !important; }

.cyan-A100-border {
  border-color: #84FFFF !important; }

.cyan-A100-border-top {
  border-top-color: #84FFFF !important; }

.cyan-A100-border-right {
  border-right-color: #84FFFF !important; }

.cyan-A100-border-bottom {
  border-bottom-color: #84FFFF !important; }

.cyan-A100-border-left {
  border-left-color: #84FFFF !important; }

.cyan-A200-bg {
  background-color: #18FFFF !important; }

.md-cyan-A200-bg {
  background-color: #18FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A200-fg {
  color: #18FFFF !important; }

.cyan-A200-border {
  border-color: #18FFFF !important; }

.cyan-A200-border-top {
  border-top-color: #18FFFF !important; }

.cyan-A200-border-right {
  border-right-color: #18FFFF !important; }

.cyan-A200-border-bottom {
  border-bottom-color: #18FFFF !important; }

.cyan-A200-border-left {
  border-left-color: #18FFFF !important; }

.cyan-A400-bg {
  background-color: #00E5FF !important; }

.md-cyan-A400-bg {
  background-color: #00E5FF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A400-fg {
  color: #00E5FF !important; }

.cyan-A400-border {
  border-color: #00E5FF !important; }

.cyan-A400-border-top {
  border-top-color: #00E5FF !important; }

.cyan-A400-border-right {
  border-right-color: #00E5FF !important; }

.cyan-A400-border-bottom {
  border-bottom-color: #00E5FF !important; }

.cyan-A400-border-left {
  border-left-color: #00E5FF !important; }

.cyan-A700-bg {
  background-color: #00B8D4 !important; }

.md-cyan-A700-bg {
  background-color: #00B8D4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A700-fg {
  color: #00B8D4 !important; }

.cyan-A700-border {
  border-color: #00B8D4 !important; }

.cyan-A700-border-top {
  border-top-color: #00B8D4 !important; }

.cyan-A700-border-right {
  border-right-color: #00B8D4 !important; }

.cyan-A700-border-bottom {
  border-bottom-color: #00B8D4 !important; }

.cyan-A700-border-left {
  border-left-color: #00B8D4 !important; }

.teal-50-bg {
  background-color: #E0F2F1 !important; }

.md-teal-50-bg {
  background-color: #E0F2F1 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-50-fg {
  color: #E0F2F1 !important; }

.teal-50-border {
  border-color: #E0F2F1 !important; }

.teal-50-border-top {
  border-top-color: #E0F2F1 !important; }

.teal-50-border-right {
  border-right-color: #E0F2F1 !important; }

.teal-50-border-bottom {
  border-bottom-color: #E0F2F1 !important; }

.teal-50-border-left {
  border-left-color: #E0F2F1 !important; }

.teal-100-bg {
  background-color: #B2DFDB !important; }

.md-teal-100-bg {
  background-color: #B2DFDB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-100-fg {
  color: #B2DFDB !important; }

.teal-100-border {
  border-color: #B2DFDB !important; }

.teal-100-border-top {
  border-top-color: #B2DFDB !important; }

.teal-100-border-right {
  border-right-color: #B2DFDB !important; }

.teal-100-border-bottom {
  border-bottom-color: #B2DFDB !important; }

.teal-100-border-left {
  border-left-color: #B2DFDB !important; }

.teal-200-bg {
  background-color: #80CBC4 !important; }

.md-teal-200-bg {
  background-color: #80CBC4 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-200-fg {
  color: #80CBC4 !important; }

.teal-200-border {
  border-color: #80CBC4 !important; }

.teal-200-border-top {
  border-top-color: #80CBC4 !important; }

.teal-200-border-right {
  border-right-color: #80CBC4 !important; }

.teal-200-border-bottom {
  border-bottom-color: #80CBC4 !important; }

.teal-200-border-left {
  border-left-color: #80CBC4 !important; }

.teal-300-bg {
  background-color: #4DB6AC !important; }

.md-teal-300-bg {
  background-color: #4DB6AC !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-300-fg {
  color: #4DB6AC !important; }

.teal-300-border {
  border-color: #4DB6AC !important; }

.teal-300-border-top {
  border-top-color: #4DB6AC !important; }

.teal-300-border-right {
  border-right-color: #4DB6AC !important; }

.teal-300-border-bottom {
  border-bottom-color: #4DB6AC !important; }

.teal-300-border-left {
  border-left-color: #4DB6AC !important; }

.teal-400-bg {
  background-color: #26A69A !important; }

.md-teal-400-bg {
  background-color: #26A69A !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-400-fg {
  color: #26A69A !important; }

.teal-400-border {
  border-color: #26A69A !important; }

.teal-400-border-top {
  border-top-color: #26A69A !important; }

.teal-400-border-right {
  border-right-color: #26A69A !important; }

.teal-400-border-bottom {
  border-bottom-color: #26A69A !important; }

.teal-400-border-left {
  border-left-color: #26A69A !important; }

.teal-500-bg {
  background-color: #009688 !important; }

.md-teal-500-bg {
  background-color: #009688 !important;
  color: white; }

.teal-500-fg {
  color: #009688 !important; }

.teal-500-border {
  border-color: #009688 !important; }

.teal-500-border-top {
  border-top-color: #009688 !important; }

.teal-500-border-right {
  border-right-color: #009688 !important; }

.teal-500-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-500-border-left {
  border-left-color: #009688 !important; }

.md-teal-bg {
  background-color: #009688 !important;
  color: white; }

.teal-bg {
  background-color: #009688 !important; }

.teal-fg {
  color: #009688 !important; }

.teal-border {
  border-color: #009688 !important; }

.teal-border-top {
  border-top-color: #009688 !important; }

.teal-border-right {
  border-right-color: #009688 !important; }

.teal-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-border-left {
  border-left-color: #009688 !important; }

.teal-600-bg {
  background-color: #00897B !important; }

.md-teal-600-bg {
  background-color: #00897B !important;
  color: white; }

.teal-600-fg {
  color: #00897B !important; }

.teal-600-border {
  border-color: #00897B !important; }

.teal-600-border-top {
  border-top-color: #00897B !important; }

.teal-600-border-right {
  border-right-color: #00897B !important; }

.teal-600-border-bottom {
  border-bottom-color: #00897B !important; }

.teal-600-border-left {
  border-left-color: #00897B !important; }

.teal-700-bg {
  background-color: #00796B !important; }

.md-teal-700-bg {
  background-color: #00796B !important;
  color: white; }

.teal-700-fg {
  color: #00796B !important; }

.teal-700-border {
  border-color: #00796B !important; }

.teal-700-border-top {
  border-top-color: #00796B !important; }

.teal-700-border-right {
  border-right-color: #00796B !important; }

.teal-700-border-bottom {
  border-bottom-color: #00796B !important; }

.teal-700-border-left {
  border-left-color: #00796B !important; }

.teal-800-bg {
  background-color: #00695C !important; }

.md-teal-800-bg {
  background-color: #00695C !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-800-fg {
  color: #00695C !important; }

.teal-800-border {
  border-color: #00695C !important; }

.teal-800-border-top {
  border-top-color: #00695C !important; }

.teal-800-border-right {
  border-right-color: #00695C !important; }

.teal-800-border-bottom {
  border-bottom-color: #00695C !important; }

.teal-800-border-left {
  border-left-color: #00695C !important; }

.teal-900-bg {
  background-color: #004D40 !important; }

.md-teal-900-bg {
  background-color: #004D40 !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-900-fg {
  color: #004D40 !important; }

.teal-900-border {
  border-color: #004D40 !important; }

.teal-900-border-top {
  border-top-color: #004D40 !important; }

.teal-900-border-right {
  border-right-color: #004D40 !important; }

.teal-900-border-bottom {
  border-bottom-color: #004D40 !important; }

.teal-900-border-left {
  border-left-color: #004D40 !important; }

.teal-A100-bg {
  background-color: #A7FFEB !important; }

.md-teal-A100-bg {
  background-color: #A7FFEB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A100-fg {
  color: #A7FFEB !important; }

.teal-A100-border {
  border-color: #A7FFEB !important; }

.teal-A100-border-top {
  border-top-color: #A7FFEB !important; }

.teal-A100-border-right {
  border-right-color: #A7FFEB !important; }

.teal-A100-border-bottom {
  border-bottom-color: #A7FFEB !important; }

.teal-A100-border-left {
  border-left-color: #A7FFEB !important; }

.teal-A200-bg {
  background-color: #64FFDA !important; }

.md-teal-A200-bg {
  background-color: #64FFDA !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A200-fg {
  color: #64FFDA !important; }

.teal-A200-border {
  border-color: #64FFDA !important; }

.teal-A200-border-top {
  border-top-color: #64FFDA !important; }

.teal-A200-border-right {
  border-right-color: #64FFDA !important; }

.teal-A200-border-bottom {
  border-bottom-color: #64FFDA !important; }

.teal-A200-border-left {
  border-left-color: #64FFDA !important; }

.teal-A400-bg {
  background-color: #1DE9B6 !important; }

.md-teal-A400-bg {
  background-color: #1DE9B6 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A400-fg {
  color: #1DE9B6 !important; }

.teal-A400-border {
  border-color: #1DE9B6 !important; }

.teal-A400-border-top {
  border-top-color: #1DE9B6 !important; }

.teal-A400-border-right {
  border-right-color: #1DE9B6 !important; }

.teal-A400-border-bottom {
  border-bottom-color: #1DE9B6 !important; }

.teal-A400-border-left {
  border-left-color: #1DE9B6 !important; }

.teal-A700-bg {
  background-color: #00BFA5 !important; }

.md-teal-A700-bg {
  background-color: #00BFA5 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A700-fg {
  color: #00BFA5 !important; }

.teal-A700-border {
  border-color: #00BFA5 !important; }

.teal-A700-border-top {
  border-top-color: #00BFA5 !important; }

.teal-A700-border-right {
  border-right-color: #00BFA5 !important; }

.teal-A700-border-bottom {
  border-bottom-color: #00BFA5 !important; }

.teal-A700-border-left {
  border-left-color: #00BFA5 !important; }

.green-50-bg {
  background-color: #E8F5E9 !important; }

.md-green-50-bg {
  background-color: #E8F5E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-50-fg {
  color: #E8F5E9 !important; }

.green-50-border {
  border-color: #E8F5E9 !important; }

.green-50-border-top {
  border-top-color: #E8F5E9 !important; }

.green-50-border-right {
  border-right-color: #E8F5E9 !important; }

.green-50-border-bottom {
  border-bottom-color: #E8F5E9 !important; }

.green-50-border-left {
  border-left-color: #E8F5E9 !important; }

.green-100-bg {
  background-color: #C8E6C9 !important; }

.md-green-100-bg {
  background-color: #C8E6C9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-100-fg {
  color: #C8E6C9 !important; }

.green-100-border {
  border-color: #C8E6C9 !important; }

.green-100-border-top {
  border-top-color: #C8E6C9 !important; }

.green-100-border-right {
  border-right-color: #C8E6C9 !important; }

.green-100-border-bottom {
  border-bottom-color: #C8E6C9 !important; }

.green-100-border-left {
  border-left-color: #C8E6C9 !important; }

.green-200-bg {
  background-color: #A5D6A7 !important; }

.md-green-200-bg {
  background-color: #A5D6A7 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-200-fg {
  color: #A5D6A7 !important; }

.green-200-border {
  border-color: #A5D6A7 !important; }

.green-200-border-top {
  border-top-color: #A5D6A7 !important; }

.green-200-border-right {
  border-right-color: #A5D6A7 !important; }

.green-200-border-bottom {
  border-bottom-color: #A5D6A7 !important; }

.green-200-border-left {
  border-left-color: #A5D6A7 !important; }

.green-300-bg {
  background-color: #81C784 !important; }

.md-green-300-bg {
  background-color: #81C784 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-300-fg {
  color: #81C784 !important; }

.green-300-border {
  border-color: #81C784 !important; }

.green-300-border-top {
  border-top-color: #81C784 !important; }

.green-300-border-right {
  border-right-color: #81C784 !important; }

.green-300-border-bottom {
  border-bottom-color: #81C784 !important; }

.green-300-border-left {
  border-left-color: #81C784 !important; }

.green-400-bg {
  background-color: #66BB6A !important; }

.md-green-400-bg {
  background-color: #66BB6A !important;
  color: rgba(0, 0, 0, 0.87); }

.green-400-fg {
  color: #66BB6A !important; }

.green-400-border {
  border-color: #66BB6A !important; }

.green-400-border-top {
  border-top-color: #66BB6A !important; }

.green-400-border-right {
  border-right-color: #66BB6A !important; }

.green-400-border-bottom {
  border-bottom-color: #66BB6A !important; }

.green-400-border-left {
  border-left-color: #66BB6A !important; }

.green-500-bg {
  background-color: #4CAF50 !important; }

.md-green-500-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-500-fg {
  color: #4CAF50 !important; }

.green-500-border {
  border-color: #4CAF50 !important; }

.green-500-border-top {
  border-top-color: #4CAF50 !important; }

.green-500-border-right {
  border-right-color: #4CAF50 !important; }

.green-500-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-500-border-left {
  border-left-color: #4CAF50 !important; }

.md-green-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-bg {
  background-color: #4CAF50 !important; }

.green-fg {
  color: #4CAF50 !important; }

.green-border {
  border-color: #4CAF50 !important; }

.green-border-top {
  border-top-color: #4CAF50 !important; }

.green-border-right {
  border-right-color: #4CAF50 !important; }

.green-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-border-left {
  border-left-color: #4CAF50 !important; }

.green-600-bg {
  background-color: #43A047 !important; }

.md-green-600-bg {
  background-color: #43A047 !important;
  color: white; }

.green-600-fg {
  color: #43A047 !important; }

.green-600-border {
  border-color: #43A047 !important; }

.green-600-border-top {
  border-top-color: #43A047 !important; }

.green-600-border-right {
  border-right-color: #43A047 !important; }

.green-600-border-bottom {
  border-bottom-color: #43A047 !important; }

.green-600-border-left {
  border-left-color: #43A047 !important; }

.green-700-bg {
  background-color: #388E3C !important; }

.md-green-700-bg {
  background-color: #388E3C !important;
  color: white; }

.green-700-fg {
  color: #388E3C !important; }

.green-700-border {
  border-color: #388E3C !important; }

.green-700-border-top {
  border-top-color: #388E3C !important; }

.green-700-border-right {
  border-right-color: #388E3C !important; }

.green-700-border-bottom {
  border-bottom-color: #388E3C !important; }

.green-700-border-left {
  border-left-color: #388E3C !important; }

.green-800-bg {
  background-color: #2E7D32 !important; }

.md-green-800-bg {
  background-color: #2E7D32 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-800-fg {
  color: #2E7D32 !important; }

.green-800-border {
  border-color: #2E7D32 !important; }

.green-800-border-top {
  border-top-color: #2E7D32 !important; }

.green-800-border-right {
  border-right-color: #2E7D32 !important; }

.green-800-border-bottom {
  border-bottom-color: #2E7D32 !important; }

.green-800-border-left {
  border-left-color: #2E7D32 !important; }

.green-900-bg {
  background-color: #1B5E20 !important; }

.md-green-900-bg {
  background-color: #1B5E20 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-900-fg {
  color: #1B5E20 !important; }

.green-900-border {
  border-color: #1B5E20 !important; }

.green-900-border-top {
  border-top-color: #1B5E20 !important; }

.green-900-border-right {
  border-right-color: #1B5E20 !important; }

.green-900-border-bottom {
  border-bottom-color: #1B5E20 !important; }

.green-900-border-left {
  border-left-color: #1B5E20 !important; }

.green-A100-bg {
  background-color: #B9F6CA !important; }

.md-green-A100-bg {
  background-color: #B9F6CA !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A100-fg {
  color: #B9F6CA !important; }

.green-A100-border {
  border-color: #B9F6CA !important; }

.green-A100-border-top {
  border-top-color: #B9F6CA !important; }

.green-A100-border-right {
  border-right-color: #B9F6CA !important; }

.green-A100-border-bottom {
  border-bottom-color: #B9F6CA !important; }

.green-A100-border-left {
  border-left-color: #B9F6CA !important; }

.green-A200-bg {
  background-color: #69F0AE !important; }

.md-green-A200-bg {
  background-color: #69F0AE !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A200-fg {
  color: #69F0AE !important; }

.green-A200-border {
  border-color: #69F0AE !important; }

.green-A200-border-top {
  border-top-color: #69F0AE !important; }

.green-A200-border-right {
  border-right-color: #69F0AE !important; }

.green-A200-border-bottom {
  border-bottom-color: #69F0AE !important; }

.green-A200-border-left {
  border-left-color: #69F0AE !important; }

.green-A400-bg {
  background-color: #00E676 !important; }

.md-green-A400-bg {
  background-color: #00E676 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A400-fg {
  color: #00E676 !important; }

.green-A400-border {
  border-color: #00E676 !important; }

.green-A400-border-top {
  border-top-color: #00E676 !important; }

.green-A400-border-right {
  border-right-color: #00E676 !important; }

.green-A400-border-bottom {
  border-bottom-color: #00E676 !important; }

.green-A400-border-left {
  border-left-color: #00E676 !important; }

.green-A700-bg {
  background-color: #00C853 !important; }

.md-green-A700-bg {
  background-color: #00C853 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A700-fg {
  color: #00C853 !important; }

.green-A700-border {
  border-color: #00C853 !important; }

.green-A700-border-top {
  border-top-color: #00C853 !important; }

.green-A700-border-right {
  border-right-color: #00C853 !important; }

.green-A700-border-bottom {
  border-bottom-color: #00C853 !important; }

.green-A700-border-left {
  border-left-color: #00C853 !important; }

.light-green-50-bg {
  background-color: #F1F8E9 !important; }

.md-light-green-50-bg {
  background-color: #F1F8E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-50-fg {
  color: #F1F8E9 !important; }

.light-green-50-border {
  border-color: #F1F8E9 !important; }

.light-green-50-border-top {
  border-top-color: #F1F8E9 !important; }

.light-green-50-border-right {
  border-right-color: #F1F8E9 !important; }

.light-green-50-border-bottom {
  border-bottom-color: #F1F8E9 !important; }

.light-green-50-border-left {
  border-left-color: #F1F8E9 !important; }

.light-green-100-bg {
  background-color: #DCEDC8 !important; }

.md-light-green-100-bg {
  background-color: #DCEDC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-100-fg {
  color: #DCEDC8 !important; }

.light-green-100-border {
  border-color: #DCEDC8 !important; }

.light-green-100-border-top {
  border-top-color: #DCEDC8 !important; }

.light-green-100-border-right {
  border-right-color: #DCEDC8 !important; }

.light-green-100-border-bottom {
  border-bottom-color: #DCEDC8 !important; }

.light-green-100-border-left {
  border-left-color: #DCEDC8 !important; }

.light-green-200-bg {
  background-color: #C5E1A5 !important; }

.md-light-green-200-bg {
  background-color: #C5E1A5 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-200-fg {
  color: #C5E1A5 !important; }

.light-green-200-border {
  border-color: #C5E1A5 !important; }

.light-green-200-border-top {
  border-top-color: #C5E1A5 !important; }

.light-green-200-border-right {
  border-right-color: #C5E1A5 !important; }

.light-green-200-border-bottom {
  border-bottom-color: #C5E1A5 !important; }

.light-green-200-border-left {
  border-left-color: #C5E1A5 !important; }

.light-green-300-bg {
  background-color: #AED581 !important; }

.md-light-green-300-bg {
  background-color: #AED581 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-300-fg {
  color: #AED581 !important; }

.light-green-300-border {
  border-color: #AED581 !important; }

.light-green-300-border-top {
  border-top-color: #AED581 !important; }

.light-green-300-border-right {
  border-right-color: #AED581 !important; }

.light-green-300-border-bottom {
  border-bottom-color: #AED581 !important; }

.light-green-300-border-left {
  border-left-color: #AED581 !important; }

.light-green-400-bg {
  background-color: #9CCC65 !important; }

.md-light-green-400-bg {
  background-color: #9CCC65 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-400-fg {
  color: #9CCC65 !important; }

.light-green-400-border {
  border-color: #9CCC65 !important; }

.light-green-400-border-top {
  border-top-color: #9CCC65 !important; }

.light-green-400-border-right {
  border-right-color: #9CCC65 !important; }

.light-green-400-border-bottom {
  border-bottom-color: #9CCC65 !important; }

.light-green-400-border-left {
  border-left-color: #9CCC65 !important; }

.light-green-500-bg {
  background-color: #8BC34A !important; }

.md-light-green-500-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-500-fg {
  color: #8BC34A !important; }

.light-green-500-border {
  border-color: #8BC34A !important; }

.light-green-500-border-top {
  border-top-color: #8BC34A !important; }

.light-green-500-border-right {
  border-right-color: #8BC34A !important; }

.light-green-500-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-500-border-left {
  border-left-color: #8BC34A !important; }

.md-light-green-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-bg {
  background-color: #8BC34A !important; }

.light-green-fg {
  color: #8BC34A !important; }

.light-green-border {
  border-color: #8BC34A !important; }

.light-green-border-top {
  border-top-color: #8BC34A !important; }

.light-green-border-right {
  border-right-color: #8BC34A !important; }

.light-green-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-border-left {
  border-left-color: #8BC34A !important; }

.light-green-600-bg {
  background-color: #7CB342 !important; }

.md-light-green-600-bg {
  background-color: #7CB342 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-600-fg {
  color: #7CB342 !important; }

.light-green-600-border {
  border-color: #7CB342 !important; }

.light-green-600-border-top {
  border-top-color: #7CB342 !important; }

.light-green-600-border-right {
  border-right-color: #7CB342 !important; }

.light-green-600-border-bottom {
  border-bottom-color: #7CB342 !important; }

.light-green-600-border-left {
  border-left-color: #7CB342 !important; }

.light-green-700-bg {
  background-color: #689F38 !important; }

.md-light-green-700-bg {
  background-color: #689F38 !important;
  color: white; }

.light-green-700-fg {
  color: #689F38 !important; }

.light-green-700-border {
  border-color: #689F38 !important; }

.light-green-700-border-top {
  border-top-color: #689F38 !important; }

.light-green-700-border-right {
  border-right-color: #689F38 !important; }

.light-green-700-border-bottom {
  border-bottom-color: #689F38 !important; }

.light-green-700-border-left {
  border-left-color: #689F38 !important; }

.light-green-800-bg {
  background-color: #558B2F !important; }

.md-light-green-800-bg {
  background-color: #558B2F !important;
  color: white; }

.light-green-800-fg {
  color: #558B2F !important; }

.light-green-800-border {
  border-color: #558B2F !important; }

.light-green-800-border-top {
  border-top-color: #558B2F !important; }

.light-green-800-border-right {
  border-right-color: #558B2F !important; }

.light-green-800-border-bottom {
  border-bottom-color: #558B2F !important; }

.light-green-800-border-left {
  border-left-color: #558B2F !important; }

.light-green-900-bg {
  background-color: #33691E !important; }

.md-light-green-900-bg {
  background-color: #33691E !important;
  color: white; }

.light-green-900-fg {
  color: #33691E !important; }

.light-green-900-border {
  border-color: #33691E !important; }

.light-green-900-border-top {
  border-top-color: #33691E !important; }

.light-green-900-border-right {
  border-right-color: #33691E !important; }

.light-green-900-border-bottom {
  border-bottom-color: #33691E !important; }

.light-green-900-border-left {
  border-left-color: #33691E !important; }

.light-green-A100-bg {
  background-color: #CCFF90 !important; }

.md-light-green-A100-bg {
  background-color: #CCFF90 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A100-fg {
  color: #CCFF90 !important; }

.light-green-A100-border {
  border-color: #CCFF90 !important; }

.light-green-A100-border-top {
  border-top-color: #CCFF90 !important; }

.light-green-A100-border-right {
  border-right-color: #CCFF90 !important; }

.light-green-A100-border-bottom {
  border-bottom-color: #CCFF90 !important; }

.light-green-A100-border-left {
  border-left-color: #CCFF90 !important; }

.light-green-A200-bg {
  background-color: #B2FF59 !important; }

.md-light-green-A200-bg {
  background-color: #B2FF59 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A200-fg {
  color: #B2FF59 !important; }

.light-green-A200-border {
  border-color: #B2FF59 !important; }

.light-green-A200-border-top {
  border-top-color: #B2FF59 !important; }

.light-green-A200-border-right {
  border-right-color: #B2FF59 !important; }

.light-green-A200-border-bottom {
  border-bottom-color: #B2FF59 !important; }

.light-green-A200-border-left {
  border-left-color: #B2FF59 !important; }

.light-green-A400-bg {
  background-color: #76FF03 !important; }

.md-light-green-A400-bg {
  background-color: #76FF03 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A400-fg {
  color: #76FF03 !important; }

.light-green-A400-border {
  border-color: #76FF03 !important; }

.light-green-A400-border-top {
  border-top-color: #76FF03 !important; }

.light-green-A400-border-right {
  border-right-color: #76FF03 !important; }

.light-green-A400-border-bottom {
  border-bottom-color: #76FF03 !important; }

.light-green-A400-border-left {
  border-left-color: #76FF03 !important; }

.light-green-A700-bg {
  background-color: #64DD17 !important; }

.md-light-green-A700-bg {
  background-color: #64DD17 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A700-fg {
  color: #64DD17 !important; }

.light-green-A700-border {
  border-color: #64DD17 !important; }

.light-green-A700-border-top {
  border-top-color: #64DD17 !important; }

.light-green-A700-border-right {
  border-right-color: #64DD17 !important; }

.light-green-A700-border-bottom {
  border-bottom-color: #64DD17 !important; }

.light-green-A700-border-left {
  border-left-color: #64DD17 !important; }

.lime-50-bg {
  background-color: #F9FBE7 !important; }

.md-lime-50-bg {
  background-color: #F9FBE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-50-fg {
  color: #F9FBE7 !important; }

.lime-50-border {
  border-color: #F9FBE7 !important; }

.lime-50-border-top {
  border-top-color: #F9FBE7 !important; }

.lime-50-border-right {
  border-right-color: #F9FBE7 !important; }

.lime-50-border-bottom {
  border-bottom-color: #F9FBE7 !important; }

.lime-50-border-left {
  border-left-color: #F9FBE7 !important; }

.lime-100-bg {
  background-color: #F0F4C3 !important; }

.md-lime-100-bg {
  background-color: #F0F4C3 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-100-fg {
  color: #F0F4C3 !important; }

.lime-100-border {
  border-color: #F0F4C3 !important; }

.lime-100-border-top {
  border-top-color: #F0F4C3 !important; }

.lime-100-border-right {
  border-right-color: #F0F4C3 !important; }

.lime-100-border-bottom {
  border-bottom-color: #F0F4C3 !important; }

.lime-100-border-left {
  border-left-color: #F0F4C3 !important; }

.lime-200-bg {
  background-color: #E6EE9C !important; }

.md-lime-200-bg {
  background-color: #E6EE9C !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-200-fg {
  color: #E6EE9C !important; }

.lime-200-border {
  border-color: #E6EE9C !important; }

.lime-200-border-top {
  border-top-color: #E6EE9C !important; }

.lime-200-border-right {
  border-right-color: #E6EE9C !important; }

.lime-200-border-bottom {
  border-bottom-color: #E6EE9C !important; }

.lime-200-border-left {
  border-left-color: #E6EE9C !important; }

.lime-300-bg {
  background-color: #DCE775 !important; }

.md-lime-300-bg {
  background-color: #DCE775 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-300-fg {
  color: #DCE775 !important; }

.lime-300-border {
  border-color: #DCE775 !important; }

.lime-300-border-top {
  border-top-color: #DCE775 !important; }

.lime-300-border-right {
  border-right-color: #DCE775 !important; }

.lime-300-border-bottom {
  border-bottom-color: #DCE775 !important; }

.lime-300-border-left {
  border-left-color: #DCE775 !important; }

.lime-400-bg {
  background-color: #D4E157 !important; }

.md-lime-400-bg {
  background-color: #D4E157 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-400-fg {
  color: #D4E157 !important; }

.lime-400-border {
  border-color: #D4E157 !important; }

.lime-400-border-top {
  border-top-color: #D4E157 !important; }

.lime-400-border-right {
  border-right-color: #D4E157 !important; }

.lime-400-border-bottom {
  border-bottom-color: #D4E157 !important; }

.lime-400-border-left {
  border-left-color: #D4E157 !important; }

.lime-500-bg {
  background-color: #CDDC39 !important; }

.md-lime-500-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-500-fg {
  color: #CDDC39 !important; }

.lime-500-border {
  border-color: #CDDC39 !important; }

.lime-500-border-top {
  border-top-color: #CDDC39 !important; }

.lime-500-border-right {
  border-right-color: #CDDC39 !important; }

.lime-500-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-500-border-left {
  border-left-color: #CDDC39 !important; }

.md-lime-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-bg {
  background-color: #CDDC39 !important; }

.lime-fg {
  color: #CDDC39 !important; }

.lime-border {
  border-color: #CDDC39 !important; }

.lime-border-top {
  border-top-color: #CDDC39 !important; }

.lime-border-right {
  border-right-color: #CDDC39 !important; }

.lime-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-border-left {
  border-left-color: #CDDC39 !important; }

.lime-600-bg {
  background-color: #C0CA33 !important; }

.md-lime-600-bg {
  background-color: #C0CA33 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-600-fg {
  color: #C0CA33 !important; }

.lime-600-border {
  border-color: #C0CA33 !important; }

.lime-600-border-top {
  border-top-color: #C0CA33 !important; }

.lime-600-border-right {
  border-right-color: #C0CA33 !important; }

.lime-600-border-bottom {
  border-bottom-color: #C0CA33 !important; }

.lime-600-border-left {
  border-left-color: #C0CA33 !important; }

.lime-700-bg {
  background-color: #AFB42B !important; }

.md-lime-700-bg {
  background-color: #AFB42B !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-700-fg {
  color: #AFB42B !important; }

.lime-700-border {
  border-color: #AFB42B !important; }

.lime-700-border-top {
  border-top-color: #AFB42B !important; }

.lime-700-border-right {
  border-right-color: #AFB42B !important; }

.lime-700-border-bottom {
  border-bottom-color: #AFB42B !important; }

.lime-700-border-left {
  border-left-color: #AFB42B !important; }

.lime-800-bg {
  background-color: #9E9D24 !important; }

.md-lime-800-bg {
  background-color: #9E9D24 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-800-fg {
  color: #9E9D24 !important; }

.lime-800-border {
  border-color: #9E9D24 !important; }

.lime-800-border-top {
  border-top-color: #9E9D24 !important; }

.lime-800-border-right {
  border-right-color: #9E9D24 !important; }

.lime-800-border-bottom {
  border-bottom-color: #9E9D24 !important; }

.lime-800-border-left {
  border-left-color: #9E9D24 !important; }

.lime-900-bg {
  background-color: #827717 !important; }

.md-lime-900-bg {
  background-color: #827717 !important;
  color: white; }

.lime-900-fg {
  color: #827717 !important; }

.lime-900-border {
  border-color: #827717 !important; }

.lime-900-border-top {
  border-top-color: #827717 !important; }

.lime-900-border-right {
  border-right-color: #827717 !important; }

.lime-900-border-bottom {
  border-bottom-color: #827717 !important; }

.lime-900-border-left {
  border-left-color: #827717 !important; }

.lime-A100-bg {
  background-color: #F4FF81 !important; }

.md-lime-A100-bg {
  background-color: #F4FF81 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A100-fg {
  color: #F4FF81 !important; }

.lime-A100-border {
  border-color: #F4FF81 !important; }

.lime-A100-border-top {
  border-top-color: #F4FF81 !important; }

.lime-A100-border-right {
  border-right-color: #F4FF81 !important; }

.lime-A100-border-bottom {
  border-bottom-color: #F4FF81 !important; }

.lime-A100-border-left {
  border-left-color: #F4FF81 !important; }

.lime-A200-bg {
  background-color: #EEFF41 !important; }

.md-lime-A200-bg {
  background-color: #EEFF41 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A200-fg {
  color: #EEFF41 !important; }

.lime-A200-border {
  border-color: #EEFF41 !important; }

.lime-A200-border-top {
  border-top-color: #EEFF41 !important; }

.lime-A200-border-right {
  border-right-color: #EEFF41 !important; }

.lime-A200-border-bottom {
  border-bottom-color: #EEFF41 !important; }

.lime-A200-border-left {
  border-left-color: #EEFF41 !important; }

.lime-A400-bg {
  background-color: #C6FF00 !important; }

.md-lime-A400-bg {
  background-color: #C6FF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A400-fg {
  color: #C6FF00 !important; }

.lime-A400-border {
  border-color: #C6FF00 !important; }

.lime-A400-border-top {
  border-top-color: #C6FF00 !important; }

.lime-A400-border-right {
  border-right-color: #C6FF00 !important; }

.lime-A400-border-bottom {
  border-bottom-color: #C6FF00 !important; }

.lime-A400-border-left {
  border-left-color: #C6FF00 !important; }

.lime-A700-bg {
  background-color: #AEEA00 !important; }

.md-lime-A700-bg {
  background-color: #AEEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A700-fg {
  color: #AEEA00 !important; }

.lime-A700-border {
  border-color: #AEEA00 !important; }

.lime-A700-border-top {
  border-top-color: #AEEA00 !important; }

.lime-A700-border-right {
  border-right-color: #AEEA00 !important; }

.lime-A700-border-bottom {
  border-bottom-color: #AEEA00 !important; }

.lime-A700-border-left {
  border-left-color: #AEEA00 !important; }

.yellow-50-bg {
  background-color: #FFFDE7 !important; }

.md-yellow-50-bg {
  background-color: #FFFDE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-50-fg {
  color: #FFFDE7 !important; }

.yellow-50-border {
  border-color: #FFFDE7 !important; }

.yellow-50-border-top {
  border-top-color: #FFFDE7 !important; }

.yellow-50-border-right {
  border-right-color: #FFFDE7 !important; }

.yellow-50-border-bottom {
  border-bottom-color: #FFFDE7 !important; }

.yellow-50-border-left {
  border-left-color: #FFFDE7 !important; }

.yellow-100-bg {
  background-color: #FFF9C4 !important; }

.md-yellow-100-bg {
  background-color: #FFF9C4 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-100-fg {
  color: #FFF9C4 !important; }

.yellow-100-border {
  border-color: #FFF9C4 !important; }

.yellow-100-border-top {
  border-top-color: #FFF9C4 !important; }

.yellow-100-border-right {
  border-right-color: #FFF9C4 !important; }

.yellow-100-border-bottom {
  border-bottom-color: #FFF9C4 !important; }

.yellow-100-border-left {
  border-left-color: #FFF9C4 !important; }

.yellow-200-bg {
  background-color: #FFF59D !important; }

.md-yellow-200-bg {
  background-color: #FFF59D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-200-fg {
  color: #FFF59D !important; }

.yellow-200-border {
  border-color: #FFF59D !important; }

.yellow-200-border-top {
  border-top-color: #FFF59D !important; }

.yellow-200-border-right {
  border-right-color: #FFF59D !important; }

.yellow-200-border-bottom {
  border-bottom-color: #FFF59D !important; }

.yellow-200-border-left {
  border-left-color: #FFF59D !important; }

.yellow-300-bg {
  background-color: #FFF176 !important; }

.md-yellow-300-bg {
  background-color: #FFF176 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300-fg {
  color: #FFF176 !important; }

.yellow-300-border {
  border-color: #FFF176 !important; }

.yellow-300-border-top {
  border-top-color: #FFF176 !important; }

.yellow-300-border-right {
  border-right-color: #FFF176 !important; }

.yellow-300-border-bottom {
  border-bottom-color: #FFF176 !important; }

.yellow-300-border-left {
  border-left-color: #FFF176 !important; }

.yellow-400-bg {
  background-color: #FFEE58 !important; }

.md-yellow-400-bg {
  background-color: #FFEE58 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-400-fg {
  color: #FFEE58 !important; }

.yellow-400-border {
  border-color: #FFEE58 !important; }

.yellow-400-border-top {
  border-top-color: #FFEE58 !important; }

.yellow-400-border-right {
  border-right-color: #FFEE58 !important; }

.yellow-400-border-bottom {
  border-bottom-color: #FFEE58 !important; }

.yellow-400-border-left {
  border-left-color: #FFEE58 !important; }

.yellow-500-bg {
  background-color: #FFEB3B !important; }

.md-yellow-500-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500-fg {
  color: #FFEB3B !important; }

.yellow-500-border {
  border-color: #FFEB3B !important; }

.yellow-500-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-500-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-500-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-500-border-left {
  border-left-color: #FFEB3B !important; }

.md-yellow-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-bg {
  background-color: #FFEB3B !important; }

.yellow-fg {
  color: #FFEB3B !important; }

.yellow-border {
  border-color: #FFEB3B !important; }

.yellow-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-border-left {
  border-left-color: #FFEB3B !important; }

.yellow-600-bg {
  background-color: #FDD835 !important; }

.md-yellow-600-bg {
  background-color: #FDD835 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-600-fg {
  color: #FDD835 !important; }

.yellow-600-border {
  border-color: #FDD835 !important; }

.yellow-600-border-top {
  border-top-color: #FDD835 !important; }

.yellow-600-border-right {
  border-right-color: #FDD835 !important; }

.yellow-600-border-bottom {
  border-bottom-color: #FDD835 !important; }

.yellow-600-border-left {
  border-left-color: #FDD835 !important; }

.yellow-700-bg {
  background-color: #FBC02D !important; }

.md-yellow-700-bg {
  background-color: #FBC02D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-700-fg {
  color: #FBC02D !important; }

.yellow-700-border {
  border-color: #FBC02D !important; }

.yellow-700-border-top {
  border-top-color: #FBC02D !important; }

.yellow-700-border-right {
  border-right-color: #FBC02D !important; }

.yellow-700-border-bottom {
  border-bottom-color: #FBC02D !important; }

.yellow-700-border-left {
  border-left-color: #FBC02D !important; }

.yellow-800-bg {
  background-color: #F9A825 !important; }

.md-yellow-800-bg {
  background-color: #F9A825 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-800-fg {
  color: #F9A825 !important; }

.yellow-800-border {
  border-color: #F9A825 !important; }

.yellow-800-border-top {
  border-top-color: #F9A825 !important; }

.yellow-800-border-right {
  border-right-color: #F9A825 !important; }

.yellow-800-border-bottom {
  border-bottom-color: #F9A825 !important; }

.yellow-800-border-left {
  border-left-color: #F9A825 !important; }

.yellow-900-bg {
  background-color: #F57F17 !important; }

.md-yellow-900-bg {
  background-color: #F57F17 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-900-fg {
  color: #F57F17 !important; }

.yellow-900-border {
  border-color: #F57F17 !important; }

.yellow-900-border-top {
  border-top-color: #F57F17 !important; }

.yellow-900-border-right {
  border-right-color: #F57F17 !important; }

.yellow-900-border-bottom {
  border-bottom-color: #F57F17 !important; }

.yellow-900-border-left {
  border-left-color: #F57F17 !important; }

.yellow-A100-bg {
  background-color: #FFFF8D !important; }

.md-yellow-A100-bg {
  background-color: #FFFF8D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A100-fg {
  color: #FFFF8D !important; }

.yellow-A100-border {
  border-color: #FFFF8D !important; }

.yellow-A100-border-top {
  border-top-color: #FFFF8D !important; }

.yellow-A100-border-right {
  border-right-color: #FFFF8D !important; }

.yellow-A100-border-bottom {
  border-bottom-color: #FFFF8D !important; }

.yellow-A100-border-left {
  border-left-color: #FFFF8D !important; }

.yellow-A200-bg {
  background-color: #FFFF00 !important; }

.md-yellow-A200-bg {
  background-color: #FFFF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A200-fg {
  color: #FFFF00 !important; }

.yellow-A200-border {
  border-color: #FFFF00 !important; }

.yellow-A200-border-top {
  border-top-color: #FFFF00 !important; }

.yellow-A200-border-right {
  border-right-color: #FFFF00 !important; }

.yellow-A200-border-bottom {
  border-bottom-color: #FFFF00 !important; }

.yellow-A200-border-left {
  border-left-color: #FFFF00 !important; }

.yellow-A400-bg {
  background-color: #FFEA00 !important; }

.md-yellow-A400-bg {
  background-color: #FFEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A400-fg {
  color: #FFEA00 !important; }

.yellow-A400-border {
  border-color: #FFEA00 !important; }

.yellow-A400-border-top {
  border-top-color: #FFEA00 !important; }

.yellow-A400-border-right {
  border-right-color: #FFEA00 !important; }

.yellow-A400-border-bottom {
  border-bottom-color: #FFEA00 !important; }

.yellow-A400-border-left {
  border-left-color: #FFEA00 !important; }

.yellow-A700-bg {
  background-color: #FFD600 !important; }

.md-yellow-A700-bg {
  background-color: #FFD600 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A700-fg {
  color: #FFD600 !important; }

.yellow-A700-border {
  border-color: #FFD600 !important; }

.yellow-A700-border-top {
  border-top-color: #FFD600 !important; }

.yellow-A700-border-right {
  border-right-color: #FFD600 !important; }

.yellow-A700-border-bottom {
  border-bottom-color: #FFD600 !important; }

.yellow-A700-border-left {
  border-left-color: #FFD600 !important; }

.amber-50-bg {
  background-color: #FFF8E1 !important; }

.md-amber-50-bg {
  background-color: #FFF8E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-50-fg {
  color: #FFF8E1 !important; }

.amber-50-border {
  border-color: #FFF8E1 !important; }

.amber-50-border-top {
  border-top-color: #FFF8E1 !important; }

.amber-50-border-right {
  border-right-color: #FFF8E1 !important; }

.amber-50-border-bottom {
  border-bottom-color: #FFF8E1 !important; }

.amber-50-border-left {
  border-left-color: #FFF8E1 !important; }

.amber-100-bg {
  background-color: #FFECB3 !important; }

.md-amber-100-bg {
  background-color: #FFECB3 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-100-fg {
  color: #FFECB3 !important; }

.amber-100-border {
  border-color: #FFECB3 !important; }

.amber-100-border-top {
  border-top-color: #FFECB3 !important; }

.amber-100-border-right {
  border-right-color: #FFECB3 !important; }

.amber-100-border-bottom {
  border-bottom-color: #FFECB3 !important; }

.amber-100-border-left {
  border-left-color: #FFECB3 !important; }

.amber-200-bg {
  background-color: #FFE082 !important; }

.md-amber-200-bg {
  background-color: #FFE082 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-200-fg {
  color: #FFE082 !important; }

.amber-200-border {
  border-color: #FFE082 !important; }

.amber-200-border-top {
  border-top-color: #FFE082 !important; }

.amber-200-border-right {
  border-right-color: #FFE082 !important; }

.amber-200-border-bottom {
  border-bottom-color: #FFE082 !important; }

.amber-200-border-left {
  border-left-color: #FFE082 !important; }

.amber-300-bg {
  background-color: #FFD54F !important; }

.md-amber-300-bg {
  background-color: #FFD54F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-300-fg {
  color: #FFD54F !important; }

.amber-300-border {
  border-color: #FFD54F !important; }

.amber-300-border-top {
  border-top-color: #FFD54F !important; }

.amber-300-border-right {
  border-right-color: #FFD54F !important; }

.amber-300-border-bottom {
  border-bottom-color: #FFD54F !important; }

.amber-300-border-left {
  border-left-color: #FFD54F !important; }

.amber-400-bg {
  background-color: #FFCA28 !important; }

.md-amber-400-bg {
  background-color: #FFCA28 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-400-fg {
  color: #FFCA28 !important; }

.amber-400-border {
  border-color: #FFCA28 !important; }

.amber-400-border-top {
  border-top-color: #FFCA28 !important; }

.amber-400-border-right {
  border-right-color: #FFCA28 !important; }

.amber-400-border-bottom {
  border-bottom-color: #FFCA28 !important; }

.amber-400-border-left {
  border-left-color: #FFCA28 !important; }

.amber-500-bg {
  background-color: #FFC107 !important; }

.md-amber-500-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-500-fg {
  color: #FFC107 !important; }

.amber-500-border {
  border-color: #FFC107 !important; }

.amber-500-border-top {
  border-top-color: #FFC107 !important; }

.amber-500-border-right {
  border-right-color: #FFC107 !important; }

.amber-500-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-500-border-left {
  border-left-color: #FFC107 !important; }

.md-amber-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-bg {
  background-color: #FFC107 !important; }

.amber-fg {
  color: #FFC107 !important; }

.amber-border {
  border-color: #FFC107 !important; }

.amber-border-top {
  border-top-color: #FFC107 !important; }

.amber-border-right {
  border-right-color: #FFC107 !important; }

.amber-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-border-left {
  border-left-color: #FFC107 !important; }

.amber-600-bg {
  background-color: #FFB300 !important; }

.md-amber-600-bg {
  background-color: #FFB300 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-600-fg {
  color: #FFB300 !important; }

.amber-600-border {
  border-color: #FFB300 !important; }

.amber-600-border-top {
  border-top-color: #FFB300 !important; }

.amber-600-border-right {
  border-right-color: #FFB300 !important; }

.amber-600-border-bottom {
  border-bottom-color: #FFB300 !important; }

.amber-600-border-left {
  border-left-color: #FFB300 !important; }

.amber-700-bg {
  background-color: #FFA000 !important; }

.md-amber-700-bg {
  background-color: #FFA000 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-700-fg {
  color: #FFA000 !important; }

.amber-700-border {
  border-color: #FFA000 !important; }

.amber-700-border-top {
  border-top-color: #FFA000 !important; }

.amber-700-border-right {
  border-right-color: #FFA000 !important; }

.amber-700-border-bottom {
  border-bottom-color: #FFA000 !important; }

.amber-700-border-left {
  border-left-color: #FFA000 !important; }

.amber-800-bg {
  background-color: #FF8F00 !important; }

.md-amber-800-bg {
  background-color: #FF8F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-800-fg {
  color: #FF8F00 !important; }

.amber-800-border {
  border-color: #FF8F00 !important; }

.amber-800-border-top {
  border-top-color: #FF8F00 !important; }

.amber-800-border-right {
  border-right-color: #FF8F00 !important; }

.amber-800-border-bottom {
  border-bottom-color: #FF8F00 !important; }

.amber-800-border-left {
  border-left-color: #FF8F00 !important; }

.amber-900-bg {
  background-color: #FF6F00 !important; }

.md-amber-900-bg {
  background-color: #FF6F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-900-fg {
  color: #FF6F00 !important; }

.amber-900-border {
  border-color: #FF6F00 !important; }

.amber-900-border-top {
  border-top-color: #FF6F00 !important; }

.amber-900-border-right {
  border-right-color: #FF6F00 !important; }

.amber-900-border-bottom {
  border-bottom-color: #FF6F00 !important; }

.amber-900-border-left {
  border-left-color: #FF6F00 !important; }

.amber-A100-bg {
  background-color: #FFE57F !important; }

.md-amber-A100-bg {
  background-color: #FFE57F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A100-fg {
  color: #FFE57F !important; }

.amber-A100-border {
  border-color: #FFE57F !important; }

.amber-A100-border-top {
  border-top-color: #FFE57F !important; }

.amber-A100-border-right {
  border-right-color: #FFE57F !important; }

.amber-A100-border-bottom {
  border-bottom-color: #FFE57F !important; }

.amber-A100-border-left {
  border-left-color: #FFE57F !important; }

.amber-A200-bg {
  background-color: #FFD740 !important; }

.md-amber-A200-bg {
  background-color: #FFD740 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A200-fg {
  color: #FFD740 !important; }

.amber-A200-border {
  border-color: #FFD740 !important; }

.amber-A200-border-top {
  border-top-color: #FFD740 !important; }

.amber-A200-border-right {
  border-right-color: #FFD740 !important; }

.amber-A200-border-bottom {
  border-bottom-color: #FFD740 !important; }

.amber-A200-border-left {
  border-left-color: #FFD740 !important; }

.amber-A400-bg {
  background-color: #FFC400 !important; }

.md-amber-A400-bg {
  background-color: #FFC400 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A400-fg {
  color: #FFC400 !important; }

.amber-A400-border {
  border-color: #FFC400 !important; }

.amber-A400-border-top {
  border-top-color: #FFC400 !important; }

.amber-A400-border-right {
  border-right-color: #FFC400 !important; }

.amber-A400-border-bottom {
  border-bottom-color: #FFC400 !important; }

.amber-A400-border-left {
  border-left-color: #FFC400 !important; }

.amber-A700-bg {
  background-color: #FFAB00 !important; }

.md-amber-A700-bg {
  background-color: #FFAB00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A700-fg {
  color: #FFAB00 !important; }

.amber-A700-border {
  border-color: #FFAB00 !important; }

.amber-A700-border-top {
  border-top-color: #FFAB00 !important; }

.amber-A700-border-right {
  border-right-color: #FFAB00 !important; }

.amber-A700-border-bottom {
  border-bottom-color: #FFAB00 !important; }

.amber-A700-border-left {
  border-left-color: #FFAB00 !important; }

.orange-50-bg {
  background-color: #FFF3E0 !important; }

.md-orange-50-bg {
  background-color: #FFF3E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-50-fg {
  color: #FFF3E0 !important; }

.orange-50-border {
  border-color: #FFF3E0 !important; }

.orange-50-border-top {
  border-top-color: #FFF3E0 !important; }

.orange-50-border-right {
  border-right-color: #FFF3E0 !important; }

.orange-50-border-bottom {
  border-bottom-color: #FFF3E0 !important; }

.orange-50-border-left {
  border-left-color: #FFF3E0 !important; }

.orange-100-bg {
  background-color: #FFE0B2 !important; }

.md-orange-100-bg {
  background-color: #FFE0B2 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-100-fg {
  color: #FFE0B2 !important; }

.orange-100-border {
  border-color: #FFE0B2 !important; }

.orange-100-border-top {
  border-top-color: #FFE0B2 !important; }

.orange-100-border-right {
  border-right-color: #FFE0B2 !important; }

.orange-100-border-bottom {
  border-bottom-color: #FFE0B2 !important; }

.orange-100-border-left {
  border-left-color: #FFE0B2 !important; }

.orange-200-bg {
  background-color: #FFCC80 !important; }

.md-orange-200-bg {
  background-color: #FFCC80 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-200-fg {
  color: #FFCC80 !important; }

.orange-200-border {
  border-color: #FFCC80 !important; }

.orange-200-border-top {
  border-top-color: #FFCC80 !important; }

.orange-200-border-right {
  border-right-color: #FFCC80 !important; }

.orange-200-border-bottom {
  border-bottom-color: #FFCC80 !important; }

.orange-200-border-left {
  border-left-color: #FFCC80 !important; }

.orange-300-bg {
  background-color: #FFB74D !important; }

.md-orange-300-bg {
  background-color: #FFB74D !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-300-fg {
  color: #FFB74D !important; }

.orange-300-border {
  border-color: #FFB74D !important; }

.orange-300-border-top {
  border-top-color: #FFB74D !important; }

.orange-300-border-right {
  border-right-color: #FFB74D !important; }

.orange-300-border-bottom {
  border-bottom-color: #FFB74D !important; }

.orange-300-border-left {
  border-left-color: #FFB74D !important; }

.orange-400-bg {
  background-color: #FFA726 !important; }

.md-orange-400-bg {
  background-color: #FFA726 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-400-fg {
  color: #FFA726 !important; }

.orange-400-border {
  border-color: #FFA726 !important; }

.orange-400-border-top {
  border-top-color: #FFA726 !important; }

.orange-400-border-right {
  border-right-color: #FFA726 !important; }

.orange-400-border-bottom {
  border-bottom-color: #FFA726 !important; }

.orange-400-border-left {
  border-left-color: #FFA726 !important; }

.orange-500-bg {
  background-color: #FF9800 !important; }

.md-orange-500-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-500-fg, .event-report table.md-table .event-subevent .td-event-type,
.event-report table.md-table .event-subevent [class*="icon-"] {
  color: #FF9800 !important; }

.orange-500-border {
  border-color: #FF9800 !important; }

.orange-500-border-top {
  border-top-color: #FF9800 !important; }

.orange-500-border-right {
  border-right-color: #FF9800 !important; }

.orange-500-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-500-border-left {
  border-left-color: #FF9800 !important; }

.md-orange-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-bg {
  background-color: #FF9800 !important; }

.orange-fg {
  color: #FF9800 !important; }

.orange-border {
  border-color: #FF9800 !important; }

.orange-border-top {
  border-top-color: #FF9800 !important; }

.orange-border-right {
  border-right-color: #FF9800 !important; }

.orange-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-border-left {
  border-left-color: #FF9800 !important; }

.orange-600-bg {
  background-color: #FB8C00 !important; }

.md-orange-600-bg {
  background-color: #FB8C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-600-fg {
  color: #FB8C00 !important; }

.orange-600-border {
  border-color: #FB8C00 !important; }

.orange-600-border-top {
  border-top-color: #FB8C00 !important; }

.orange-600-border-right {
  border-right-color: #FB8C00 !important; }

.orange-600-border-bottom {
  border-bottom-color: #FB8C00 !important; }

.orange-600-border-left {
  border-left-color: #FB8C00 !important; }

.orange-700-bg {
  background-color: #F57C00 !important; }

.md-orange-700-bg {
  background-color: #F57C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-700-fg {
  color: #F57C00 !important; }

.orange-700-border {
  border-color: #F57C00 !important; }

.orange-700-border-top {
  border-top-color: #F57C00 !important; }

.orange-700-border-right {
  border-right-color: #F57C00 !important; }

.orange-700-border-bottom {
  border-bottom-color: #F57C00 !important; }

.orange-700-border-left {
  border-left-color: #F57C00 !important; }

.orange-800-bg {
  background-color: #EF6C00 !important; }

.md-orange-800-bg {
  background-color: #EF6C00 !important;
  color: white; }

.orange-800-fg {
  color: #EF6C00 !important; }

.orange-800-border {
  border-color: #EF6C00 !important; }

.orange-800-border-top {
  border-top-color: #EF6C00 !important; }

.orange-800-border-right {
  border-right-color: #EF6C00 !important; }

.orange-800-border-bottom {
  border-bottom-color: #EF6C00 !important; }

.orange-800-border-left {
  border-left-color: #EF6C00 !important; }

.orange-900-bg {
  background-color: #E65100 !important; }

.md-orange-900-bg {
  background-color: #E65100 !important;
  color: white; }

.orange-900-fg {
  color: #E65100 !important; }

.orange-900-border {
  border-color: #E65100 !important; }

.orange-900-border-top {
  border-top-color: #E65100 !important; }

.orange-900-border-right {
  border-right-color: #E65100 !important; }

.orange-900-border-bottom {
  border-bottom-color: #E65100 !important; }

.orange-900-border-left {
  border-left-color: #E65100 !important; }

.orange-A100-bg {
  background-color: #FFD180 !important; }

.md-orange-A100-bg {
  background-color: #FFD180 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A100-fg {
  color: #FFD180 !important; }

.orange-A100-border {
  border-color: #FFD180 !important; }

.orange-A100-border-top {
  border-top-color: #FFD180 !important; }

.orange-A100-border-right {
  border-right-color: #FFD180 !important; }

.orange-A100-border-bottom {
  border-bottom-color: #FFD180 !important; }

.orange-A100-border-left {
  border-left-color: #FFD180 !important; }

.orange-A200-bg {
  background-color: #FFAB40 !important; }

.md-orange-A200-bg {
  background-color: #FFAB40 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A200-fg {
  color: #FFAB40 !important; }

.orange-A200-border {
  border-color: #FFAB40 !important; }

.orange-A200-border-top {
  border-top-color: #FFAB40 !important; }

.orange-A200-border-right {
  border-right-color: #FFAB40 !important; }

.orange-A200-border-bottom {
  border-bottom-color: #FFAB40 !important; }

.orange-A200-border-left {
  border-left-color: #FFAB40 !important; }

.orange-A400-bg {
  background-color: #FF9100 !important; }

.md-orange-A400-bg {
  background-color: #FF9100 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A400-fg {
  color: #FF9100 !important; }

.orange-A400-border {
  border-color: #FF9100 !important; }

.orange-A400-border-top {
  border-top-color: #FF9100 !important; }

.orange-A400-border-right {
  border-right-color: #FF9100 !important; }

.orange-A400-border-bottom {
  border-bottom-color: #FF9100 !important; }

.orange-A400-border-left {
  border-left-color: #FF9100 !important; }

.orange-A700-bg {
  background-color: #FF6D00 !important; }

.md-orange-A700-bg {
  background-color: #FF6D00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A700-fg {
  color: #FF6D00 !important; }

.orange-A700-border {
  border-color: #FF6D00 !important; }

.orange-A700-border-top {
  border-top-color: #FF6D00 !important; }

.orange-A700-border-right {
  border-right-color: #FF6D00 !important; }

.orange-A700-border-bottom {
  border-bottom-color: #FF6D00 !important; }

.orange-A700-border-left {
  border-left-color: #FF6D00 !important; }

.deep-orange-50-bg {
  background-color: #FBE9E7 !important; }

.md-deep-orange-50-bg {
  background-color: #FBE9E7 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-50-fg {
  color: #FBE9E7 !important; }

.deep-orange-50-border {
  border-color: #FBE9E7 !important; }

.deep-orange-50-border-top {
  border-top-color: #FBE9E7 !important; }

.deep-orange-50-border-right {
  border-right-color: #FBE9E7 !important; }

.deep-orange-50-border-bottom {
  border-bottom-color: #FBE9E7 !important; }

.deep-orange-50-border-left {
  border-left-color: #FBE9E7 !important; }

.deep-orange-100-bg {
  background-color: #FFCCBC !important; }

.md-deep-orange-100-bg {
  background-color: #FFCCBC !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-100-fg {
  color: #FFCCBC !important; }

.deep-orange-100-border {
  border-color: #FFCCBC !important; }

.deep-orange-100-border-top {
  border-top-color: #FFCCBC !important; }

.deep-orange-100-border-right {
  border-right-color: #FFCCBC !important; }

.deep-orange-100-border-bottom {
  border-bottom-color: #FFCCBC !important; }

.deep-orange-100-border-left {
  border-left-color: #FFCCBC !important; }

.deep-orange-200-bg {
  background-color: #FFAB91 !important; }

.md-deep-orange-200-bg {
  background-color: #FFAB91 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-200-fg {
  color: #FFAB91 !important; }

.deep-orange-200-border {
  border-color: #FFAB91 !important; }

.deep-orange-200-border-top {
  border-top-color: #FFAB91 !important; }

.deep-orange-200-border-right {
  border-right-color: #FFAB91 !important; }

.deep-orange-200-border-bottom {
  border-bottom-color: #FFAB91 !important; }

.deep-orange-200-border-left {
  border-left-color: #FFAB91 !important; }

.deep-orange-300-bg {
  background-color: #FF8A65 !important; }

.md-deep-orange-300-bg {
  background-color: #FF8A65 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-300-fg {
  color: #FF8A65 !important; }

.deep-orange-300-border {
  border-color: #FF8A65 !important; }

.deep-orange-300-border-top {
  border-top-color: #FF8A65 !important; }

.deep-orange-300-border-right {
  border-right-color: #FF8A65 !important; }

.deep-orange-300-border-bottom {
  border-bottom-color: #FF8A65 !important; }

.deep-orange-300-border-left {
  border-left-color: #FF8A65 !important; }

.deep-orange-400-bg {
  background-color: #FF7043 !important; }

.md-deep-orange-400-bg {
  background-color: #FF7043 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-400-fg {
  color: #FF7043 !important; }

.deep-orange-400-border {
  border-color: #FF7043 !important; }

.deep-orange-400-border-top {
  border-top-color: #FF7043 !important; }

.deep-orange-400-border-right {
  border-right-color: #FF7043 !important; }

.deep-orange-400-border-bottom {
  border-bottom-color: #FF7043 !important; }

.deep-orange-400-border-left {
  border-left-color: #FF7043 !important; }

.deep-orange-500-bg {
  background-color: #FF5722 !important; }

.md-deep-orange-500-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-500-fg {
  color: #FF5722 !important; }

.deep-orange-500-border {
  border-color: #FF5722 !important; }

.deep-orange-500-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-500-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-500-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-500-border-left {
  border-left-color: #FF5722 !important; }

.md-deep-orange-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-bg {
  background-color: #FF5722 !important; }

.deep-orange-fg {
  color: #FF5722 !important; }

.deep-orange-border {
  border-color: #FF5722 !important; }

.deep-orange-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-border-left {
  border-left-color: #FF5722 !important; }

.deep-orange-600-bg {
  background-color: #F4511E !important; }

.md-deep-orange-600-bg {
  background-color: #F4511E !important;
  color: white; }

.deep-orange-600-fg {
  color: #F4511E !important; }

.deep-orange-600-border {
  border-color: #F4511E !important; }

.deep-orange-600-border-top {
  border-top-color: #F4511E !important; }

.deep-orange-600-border-right {
  border-right-color: #F4511E !important; }

.deep-orange-600-border-bottom {
  border-bottom-color: #F4511E !important; }

.deep-orange-600-border-left {
  border-left-color: #F4511E !important; }

.deep-orange-700-bg {
  background-color: #E64A19 !important; }

.md-deep-orange-700-bg {
  background-color: #E64A19 !important;
  color: white; }

.deep-orange-700-fg {
  color: #E64A19 !important; }

.deep-orange-700-border {
  border-color: #E64A19 !important; }

.deep-orange-700-border-top {
  border-top-color: #E64A19 !important; }

.deep-orange-700-border-right {
  border-right-color: #E64A19 !important; }

.deep-orange-700-border-bottom {
  border-bottom-color: #E64A19 !important; }

.deep-orange-700-border-left {
  border-left-color: #E64A19 !important; }

.deep-orange-800-bg {
  background-color: #D84315 !important; }

.md-deep-orange-800-bg {
  background-color: #D84315 !important;
  color: white; }

.deep-orange-800-fg {
  color: #D84315 !important; }

.deep-orange-800-border {
  border-color: #D84315 !important; }

.deep-orange-800-border-top {
  border-top-color: #D84315 !important; }

.deep-orange-800-border-right {
  border-right-color: #D84315 !important; }

.deep-orange-800-border-bottom {
  border-bottom-color: #D84315 !important; }

.deep-orange-800-border-left {
  border-left-color: #D84315 !important; }

.deep-orange-900-bg {
  background-color: #BF360C !important; }

.md-deep-orange-900-bg {
  background-color: #BF360C !important;
  color: white; }

.deep-orange-900-fg {
  color: #BF360C !important; }

.deep-orange-900-border {
  border-color: #BF360C !important; }

.deep-orange-900-border-top {
  border-top-color: #BF360C !important; }

.deep-orange-900-border-right {
  border-right-color: #BF360C !important; }

.deep-orange-900-border-bottom {
  border-bottom-color: #BF360C !important; }

.deep-orange-900-border-left {
  border-left-color: #BF360C !important; }

.deep-orange-A100-bg {
  background-color: #FF9E80 !important; }

.md-deep-orange-A100-bg {
  background-color: #FF9E80 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A100-fg {
  color: #FF9E80 !important; }

.deep-orange-A100-border {
  border-color: #FF9E80 !important; }

.deep-orange-A100-border-top {
  border-top-color: #FF9E80 !important; }

.deep-orange-A100-border-right {
  border-right-color: #FF9E80 !important; }

.deep-orange-A100-border-bottom {
  border-bottom-color: #FF9E80 !important; }

.deep-orange-A100-border-left {
  border-left-color: #FF9E80 !important; }

.deep-orange-A200-bg {
  background-color: #FF6E40 !important; }

.md-deep-orange-A200-bg {
  background-color: #FF6E40 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A200-fg {
  color: #FF6E40 !important; }

.deep-orange-A200-border {
  border-color: #FF6E40 !important; }

.deep-orange-A200-border-top {
  border-top-color: #FF6E40 !important; }

.deep-orange-A200-border-right {
  border-right-color: #FF6E40 !important; }

.deep-orange-A200-border-bottom {
  border-bottom-color: #FF6E40 !important; }

.deep-orange-A200-border-left {
  border-left-color: #FF6E40 !important; }

.deep-orange-A400-bg {
  background-color: #FF3D00 !important; }

.md-deep-orange-A400-bg {
  background-color: #FF3D00 !important;
  color: white; }

.deep-orange-A400-fg {
  color: #FF3D00 !important; }

.deep-orange-A400-border {
  border-color: #FF3D00 !important; }

.deep-orange-A400-border-top {
  border-top-color: #FF3D00 !important; }

.deep-orange-A400-border-right {
  border-right-color: #FF3D00 !important; }

.deep-orange-A400-border-bottom {
  border-bottom-color: #FF3D00 !important; }

.deep-orange-A400-border-left {
  border-left-color: #FF3D00 !important; }

.deep-orange-A700-bg {
  background-color: #DD2C00 !important; }

.md-deep-orange-A700-bg {
  background-color: #DD2C00 !important;
  color: white; }

.deep-orange-A700-fg {
  color: #DD2C00 !important; }

.deep-orange-A700-border {
  border-color: #DD2C00 !important; }

.deep-orange-A700-border-top {
  border-top-color: #DD2C00 !important; }

.deep-orange-A700-border-right {
  border-right-color: #DD2C00 !important; }

.deep-orange-A700-border-bottom {
  border-bottom-color: #DD2C00 !important; }

.deep-orange-A700-border-left {
  border-left-color: #DD2C00 !important; }

.brown-50-bg {
  background-color: #EFEBE9 !important; }

.md-brown-50-bg {
  background-color: #EFEBE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-50-fg {
  color: #EFEBE9 !important; }

.brown-50-border {
  border-color: #EFEBE9 !important; }

.brown-50-border-top {
  border-top-color: #EFEBE9 !important; }

.brown-50-border-right {
  border-right-color: #EFEBE9 !important; }

.brown-50-border-bottom {
  border-bottom-color: #EFEBE9 !important; }

.brown-50-border-left {
  border-left-color: #EFEBE9 !important; }

.brown-100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-100-fg {
  color: #D7CCC8 !important; }

.brown-100-border {
  border-color: #D7CCC8 !important; }

.brown-100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-200-fg {
  color: #BCAAA4 !important; }

.brown-200-border {
  border-color: #BCAAA4 !important; }

.brown-200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-300-bg {
  background-color: #A1887F !important; }

.md-brown-300-bg {
  background-color: #A1887F !important;
  color: white; }

.brown-300-fg {
  color: #A1887F !important; }

.brown-300-border {
  border-color: #A1887F !important; }

.brown-300-border-top {
  border-top-color: #A1887F !important; }

.brown-300-border-right {
  border-right-color: #A1887F !important; }

.brown-300-border-bottom {
  border-bottom-color: #A1887F !important; }

.brown-300-border-left {
  border-left-color: #A1887F !important; }

.brown-400-bg {
  background-color: #8D6E63 !important; }

.md-brown-400-bg {
  background-color: #8D6E63 !important;
  color: white; }

.brown-400-fg {
  color: #8D6E63 !important; }

.brown-400-border {
  border-color: #8D6E63 !important; }

.brown-400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-500-bg {
  background-color: #795548 !important; }

.md-brown-500-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-500-fg {
  color: #795548 !important; }

.brown-500-border {
  border-color: #795548 !important; }

.brown-500-border-top {
  border-top-color: #795548 !important; }

.brown-500-border-right {
  border-right-color: #795548 !important; }

.brown-500-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-500-border-left {
  border-left-color: #795548 !important; }

.md-brown-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-bg {
  background-color: #795548 !important; }

.brown-fg {
  color: #795548 !important; }

.brown-border {
  border-color: #795548 !important; }

.brown-border-top {
  border-top-color: #795548 !important; }

.brown-border-right {
  border-right-color: #795548 !important; }

.brown-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-border-left {
  border-left-color: #795548 !important; }

.brown-600-bg {
  background-color: #6D4C41 !important; }

.md-brown-600-bg {
  background-color: #6D4C41 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-600-fg {
  color: #6D4C41 !important; }

.brown-600-border {
  border-color: #6D4C41 !important; }

.brown-600-border-top {
  border-top-color: #6D4C41 !important; }

.brown-600-border-right {
  border-right-color: #6D4C41 !important; }

.brown-600-border-bottom {
  border-bottom-color: #6D4C41 !important; }

.brown-600-border-left {
  border-left-color: #6D4C41 !important; }

.brown-700-bg {
  background-color: #5D4037 !important; }

.md-brown-700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-700-fg {
  color: #5D4037 !important; }

.brown-700-border {
  border-color: #5D4037 !important; }

.brown-700-border-top {
  border-top-color: #5D4037 !important; }

.brown-700-border-right {
  border-right-color: #5D4037 !important; }

.brown-700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-700-border-left {
  border-left-color: #5D4037 !important; }

.brown-800-bg {
  background-color: #4E342E !important; }

.md-brown-800-bg {
  background-color: #4E342E !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-800-fg {
  color: #4E342E !important; }

.brown-800-border {
  border-color: #4E342E !important; }

.brown-800-border-top {
  border-top-color: #4E342E !important; }

.brown-800-border-right {
  border-right-color: #4E342E !important; }

.brown-800-border-bottom {
  border-bottom-color: #4E342E !important; }

.brown-800-border-left {
  border-left-color: #4E342E !important; }

.brown-900-bg {
  background-color: #3E2723 !important; }

.md-brown-900-bg {
  background-color: #3E2723 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-900-fg {
  color: #3E2723 !important; }

.brown-900-border {
  border-color: #3E2723 !important; }

.brown-900-border-top {
  border-top-color: #3E2723 !important; }

.brown-900-border-right {
  border-right-color: #3E2723 !important; }

.brown-900-border-bottom {
  border-bottom-color: #3E2723 !important; }

.brown-900-border-left {
  border-left-color: #3E2723 !important; }

.brown-A100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-A100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A100-fg {
  color: #D7CCC8 !important; }

.brown-A100-border {
  border-color: #D7CCC8 !important; }

.brown-A100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-A100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-A100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-A100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-A200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-A200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A200-fg {
  color: #BCAAA4 !important; }

.brown-A200-border {
  border-color: #BCAAA4 !important; }

.brown-A200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-A200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-A200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-A200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-A400-bg {
  background-color: #8D6E63 !important; }

.md-brown-A400-bg {
  background-color: #8D6E63 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A400-fg {
  color: #8D6E63 !important; }

.brown-A400-border {
  border-color: #8D6E63 !important; }

.brown-A400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-A400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-A400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-A400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-A700-bg {
  background-color: #5D4037 !important; }

.md-brown-A700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A700-fg {
  color: #5D4037 !important; }

.brown-A700-border {
  border-color: #5D4037 !important; }

.brown-A700-border-top {
  border-top-color: #5D4037 !important; }

.brown-A700-border-right {
  border-right-color: #5D4037 !important; }

.brown-A700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-A700-border-left {
  border-left-color: #5D4037 !important; }

.grey-50-bg {
  background-color: #FAFAFA !important; }

.md-grey-50-bg {
  background-color: #FAFAFA !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-50-fg {
  color: #FAFAFA !important; }

.grey-50-border {
  border-color: #FAFAFA !important; }

.grey-50-border-top {
  border-top-color: #FAFAFA !important; }

.grey-50-border-right {
  border-right-color: #FAFAFA !important; }

.grey-50-border-bottom {
  border-bottom-color: #FAFAFA !important; }

.grey-50-border-left {
  border-left-color: #FAFAFA !important; }

.grey-100-bg {
  background-color: #F5F5F5 !important; }

.md-grey-100-bg {
  background-color: #F5F5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-100-fg {
  color: #F5F5F5 !important; }

.grey-100-border {
  border-color: #F5F5F5 !important; }

.grey-100-border-top {
  border-top-color: #F5F5F5 !important; }

.grey-100-border-right {
  border-right-color: #F5F5F5 !important; }

.grey-100-border-bottom {
  border-bottom-color: #F5F5F5 !important; }

.grey-100-border-left {
  border-left-color: #F5F5F5 !important; }

.grey-200-bg {
  background-color: #EEEEEE !important; }

.md-grey-200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-200-fg {
  color: #EEEEEE !important; }

.grey-200-border {
  border-color: #EEEEEE !important; }

.grey-200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-300-bg {
  background-color: #E0E0E0 !important; }

.md-grey-300-bg {
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-300-fg {
  color: #E0E0E0 !important; }

.grey-300-border {
  border-color: #E0E0E0 !important; }

.grey-300-border-top {
  border-top-color: #E0E0E0 !important; }

.grey-300-border-right {
  border-right-color: #E0E0E0 !important; }

.grey-300-border-bottom {
  border-bottom-color: #E0E0E0 !important; }

.grey-300-border-left {
  border-left-color: #E0E0E0 !important; }

.grey-400-bg {
  background-color: #BDBDBD !important; }

.md-grey-400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-400-fg {
  color: #BDBDBD !important; }

.grey-400-border {
  border-color: #BDBDBD !important; }

.grey-400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-500-bg {
  background-color: #9E9E9E !important; }

.md-grey-500-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-500-fg {
  color: #9E9E9E !important; }

.grey-500-border {
  border-color: #9E9E9E !important; }

.grey-500-border-top {
  border-top-color: #9E9E9E !important; }

.grey-500-border-right {
  border-right-color: #9E9E9E !important; }

.grey-500-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-500-border-left {
  border-left-color: #9E9E9E !important; }

.md-grey-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-bg {
  background-color: #9E9E9E !important; }

.grey-fg {
  color: #9E9E9E !important; }

.grey-border {
  border-color: #9E9E9E !important; }

.grey-border-top {
  border-top-color: #9E9E9E !important; }

.grey-border-right {
  border-right-color: #9E9E9E !important; }

.grey-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-border-left {
  border-left-color: #9E9E9E !important; }

.grey-600-bg {
  background-color: #757575 !important; }

.md-grey-600-bg {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-600-fg {
  color: #757575 !important; }

.grey-600-border {
  border-color: #757575 !important; }

.grey-600-border-top {
  border-top-color: #757575 !important; }

.grey-600-border-right {
  border-right-color: #757575 !important; }

.grey-600-border-bottom {
  border-bottom-color: #757575 !important; }

.grey-600-border-left {
  border-left-color: #757575 !important; }

.grey-700-bg {
  background-color: #616161 !important; }

.md-grey-700-bg {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-700-fg {
  color: #616161 !important; }

.grey-700-border {
  border-color: #616161 !important; }

.grey-700-border-top {
  border-top-color: #616161 !important; }

.grey-700-border-right {
  border-right-color: #616161 !important; }

.grey-700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-700-border-left {
  border-left-color: #616161 !important; }

.grey-800-bg {
  background-color: #424242 !important; }

.md-grey-800-bg {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-800-fg {
  color: #424242 !important; }

.grey-800-border {
  border-color: #424242 !important; }

.grey-800-border-top {
  border-top-color: #424242 !important; }

.grey-800-border-right {
  border-right-color: #424242 !important; }

.grey-800-border-bottom {
  border-bottom-color: #424242 !important; }

.grey-800-border-left {
  border-left-color: #424242 !important; }

.grey-900-bg {
  background-color: #212121 !important; }

.md-grey-900-bg {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-900-fg {
  color: #212121 !important; }

.grey-900-border {
  border-color: #212121 !important; }

.grey-900-border-top {
  border-top-color: #212121 !important; }

.grey-900-border-right {
  border-right-color: #212121 !important; }

.grey-900-border-bottom {
  border-bottom-color: #212121 !important; }

.grey-900-border-left {
  border-left-color: #212121 !important; }

.grey-1000-bg {
  background-color: #000000 !important; }

.md-grey-1000-bg {
  background-color: #000000 !important; }

.grey-1000-fg {
  color: #000000 !important; }

.grey-1000-border {
  border-color: #000000 !important; }

.grey-1000-border-top {
  border-top-color: #000000 !important; }

.grey-1000-border-right {
  border-right-color: #000000 !important; }

.grey-1000-border-bottom {
  border-bottom-color: #000000 !important; }

.grey-1000-border-left {
  border-left-color: #000000 !important; }

.grey-A100-bg {
  background-color: #FFFFFF !important; }

.md-grey-A100-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A100-fg {
  color: #FFFFFF !important; }

.grey-A100-border {
  border-color: #FFFFFF !important; }

.grey-A100-border-top {
  border-top-color: #FFFFFF !important; }

.grey-A100-border-right {
  border-right-color: #FFFFFF !important; }

.grey-A100-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.grey-A100-border-left {
  border-left-color: #FFFFFF !important; }

.grey-A200-bg {
  background-color: #EEEEEE !important; }

.md-grey-A200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A200-fg {
  color: #EEEEEE !important; }

.grey-A200-border {
  border-color: #EEEEEE !important; }

.grey-A200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-A200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-A200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-A200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-A400-bg {
  background-color: #BDBDBD !important; }

.md-grey-A400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A400-fg {
  color: #BDBDBD !important; }

.grey-A400-border {
  border-color: #BDBDBD !important; }

.grey-A400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-A400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-A400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-A400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-A700-bg {
  background-color: #616161 !important; }

.md-grey-A700-bg {
  background-color: #616161 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A700-fg {
  color: #616161 !important; }

.grey-A700-border {
  border-color: #616161 !important; }

.grey-A700-border-top {
  border-top-color: #616161 !important; }

.grey-A700-border-right {
  border-right-color: #616161 !important; }

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-A700-border-left {
  border-left-color: #616161 !important; }

.blue-grey-50-bg {
  background-color: #ECEFF1 !important; }

.md-blue-grey-50-bg {
  background-color: #ECEFF1 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-50-fg {
  color: #ECEFF1 !important; }

.blue-grey-50-border {
  border-color: #ECEFF1 !important; }

.blue-grey-50-border-top {
  border-top-color: #ECEFF1 !important; }

.blue-grey-50-border-right {
  border-right-color: #ECEFF1 !important; }

.blue-grey-50-border-bottom {
  border-bottom-color: #ECEFF1 !important; }

.blue-grey-50-border-left {
  border-left-color: #ECEFF1 !important; }

.blue-grey-100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-100-bg {
  background-color: #CFD8DC !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-100-fg {
  color: #CFD8DC !important; }

.blue-grey-100-border {
  border-color: #CFD8DC !important; }

.blue-grey-100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-200-fg {
  color: #B0BEC5 !important; }

.blue-grey-200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-300-bg {
  background-color: #90A4AE !important; }

.md-blue-grey-300-bg {
  background-color: #90A4AE !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-300-fg {
  color: #90A4AE !important; }

.blue-grey-300-border {
  border-color: #90A4AE !important; }

.blue-grey-300-border-top {
  border-top-color: #90A4AE !important; }

.blue-grey-300-border-right {
  border-right-color: #90A4AE !important; }

.blue-grey-300-border-bottom {
  border-bottom-color: #90A4AE !important; }

.blue-grey-300-border-left {
  border-left-color: #90A4AE !important; }

.blue-grey-400-bg {
  background-color: #78909C !important; }

.md-blue-grey-400-bg {
  background-color: #78909C !important;
  color: white; }

.blue-grey-400-fg {
  color: #78909C !important; }

.blue-grey-400-border {
  border-color: #78909C !important; }

.blue-grey-400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-500-bg {
  background-color: #607D8B !important; }

.md-blue-grey-500-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-500-fg {
  color: #607D8B !important; }

.blue-grey-500-border {
  border-color: #607D8B !important; }

.blue-grey-500-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-500-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-500-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-500-border-left {
  border-left-color: #607D8B !important; }

.md-blue-grey-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-bg {
  background-color: #607D8B !important; }

.blue-grey-fg {
  color: #607D8B !important; }

.blue-grey-border {
  border-color: #607D8B !important; }

.blue-grey-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-border-left {
  border-left-color: #607D8B !important; }

.blue-grey-600-bg {
  background-color: #546E7A !important; }

.md-blue-grey-600-bg {
  background-color: #546E7A !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-600-fg {
  color: #546E7A !important; }

.blue-grey-600-border {
  border-color: #546E7A !important; }

.blue-grey-600-border-top {
  border-top-color: #546E7A !important; }

.blue-grey-600-border-right {
  border-right-color: #546E7A !important; }

.blue-grey-600-border-bottom {
  border-bottom-color: #546E7A !important; }

.blue-grey-600-border-left {
  border-left-color: #546E7A !important; }

.blue-grey-700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-700-fg {
  color: #455A64 !important; }

.blue-grey-700-border {
  border-color: #455A64 !important; }

.blue-grey-700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-700-border-left {
  border-left-color: #455A64 !important; }

.blue-grey-800-bg {
  background-color: #37474F !important; }

.md-blue-grey-800-bg {
  background-color: #37474F !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-800-fg {
  color: #37474F !important; }

.blue-grey-800-border {
  border-color: #37474F !important; }

.blue-grey-800-border-top {
  border-top-color: #37474F !important; }

.blue-grey-800-border-right {
  border-right-color: #37474F !important; }

.blue-grey-800-border-bottom {
  border-bottom-color: #37474F !important; }

.blue-grey-800-border-left {
  border-left-color: #37474F !important; }

.blue-grey-900-bg {
  background-color: #263238 !important; }

.md-blue-grey-900-bg {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-900-fg {
  color: #263238 !important; }

.blue-grey-900-border {
  border-color: #263238 !important; }

.blue-grey-900-border-top {
  border-top-color: #263238 !important; }

.blue-grey-900-border-right {
  border-right-color: #263238 !important; }

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important; }

.blue-grey-900-border-left {
  border-left-color: #263238 !important; }

.blue-grey-A100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-A100-bg {
  background-color: #CFD8DC !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A100-fg {
  color: #CFD8DC !important; }

.blue-grey-A100-border {
  border-color: #CFD8DC !important; }

.blue-grey-A100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-A100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-A100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-A100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-A200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A200-fg {
  color: #B0BEC5 !important; }

.blue-grey-A200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-A200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-A200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-A200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-A200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-A400-bg {
  background-color: #78909C !important; }

.md-blue-grey-A400-bg {
  background-color: #78909C !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A400-fg {
  color: #78909C !important; }

.blue-grey-A400-border {
  border-color: #78909C !important; }

.blue-grey-A400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-A400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-A400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-A700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-A700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A700-fg {
  color: #455A64 !important; }

.blue-grey-A700-border {
  border-color: #455A64 !important; }

.blue-grey-A700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-A700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-A700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-A700-border-left {
  border-left-color: #455A64 !important; }

.white-500-bg {
  background-color: #FFFFFF !important; }

.md-white-500-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-500-fg, md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container input,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container label,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container label::after, md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input:focus,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label::after {
  color: #FFFFFF !important; }

.white-500-border {
  border-color: #FFFFFF !important; }

.white-500-border-top {
  border-top-color: #FFFFFF !important; }

.white-500-border-right {
  border-right-color: #FFFFFF !important; }

.white-500-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-500-border-left {
  border-left-color: #FFFFFF !important; }

.md-white-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-bg {
  background-color: #FFFFFF !important; }

.white-fg {
  color: #FFFFFF !important; }

.white-border {
  border-color: #FFFFFF !important; }

.white-border-top {
  border-top-color: #FFFFFF !important; }

.white-border-right {
  border-right-color: #FFFFFF !important; }

.white-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-border-left {
  border-left-color: #FFFFFF !important; }

.black-500-bg {
  background-color: #000000 !important; }

.md-black-500-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-500-fg {
  color: #000000 !important; }

.black-500-border {
  border-color: #000000 !important; }

.black-500-border-top {
  border-top-color: #000000 !important; }

.black-500-border-right {
  border-right-color: #000000 !important; }

.black-500-border-bottom {
  border-bottom-color: #000000 !important; }

.black-500-border-left {
  border-left-color: #000000 !important; }

.md-black-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-bg {
  background-color: #000000 !important; }

.black-fg {
  color: #000000 !important; }

.black-border {
  border-color: #000000 !important; }

.black-border-top {
  border-top-color: #000000 !important; }

.black-border-right {
  border-right-color: #000000 !important; }

.black-border-bottom {
  border-bottom-color: #000000 !important; }

.black-border-left {
  border-left-color: #000000 !important; }

/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/
html {
  font-size: 62.5%;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.4;
  letter-spacing: -0.1px; }

body {
  font-size: 1.3rem; }

html, body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

form {
  font-size: 1.6rem; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal; }

h1, .h1 {
  font-size: 2.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.4rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.2rem; }

.la {
  color: red !important; }

a {
  text-decoration: none; }
  a:hover, a:active {
    text-decoration: underline; }

abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54); }

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px; }
  blockquote footer {
    font-style: normal; }
    blockquote footer:before {
      content: '\2014 \00A0'; }
  blockquote.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px; }
    blockquote.reverse footer:before {
      content: ''; }
    blockquote.reverse footer:after {
      content: '\2014 \00A0'; }

dl dt {
  font-weight: bold; }

dl dd {
  margin: 4px 0 16px 0; }

mark {
  background: #F7F49A; }

small {
  font-size: 80%; }

table thead tr th {
  text-align: left; }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-size-1 {
  font-size: 1px; }

.font-size-2 {
  font-size: 2px; }

.font-size-3 {
  font-size: 3px; }

.font-size-4 {
  font-size: 4px; }

.font-size-5 {
  font-size: 5px; }

.font-size-6 {
  font-size: 6px; }

.font-size-7 {
  font-size: 7px; }

.font-size-8 {
  font-size: 8px; }

.font-size-9 {
  font-size: 9px; }

.font-size-10 {
  font-size: 10px; }

.font-size-11 {
  font-size: 11px; }

.font-size-12 {
  font-size: 12px; }

.font-size-13 {
  font-size: 13px; }

.font-size-14 {
  font-size: 14px; }

.font-size-15 {
  font-size: 15px; }

.font-size-16 {
  font-size: 16px; }

.font-size-17 {
  font-size: 17px; }

.font-size-18 {
  font-size: 18px; }

.font-size-19 {
  font-size: 19px; }

.font-size-20 {
  font-size: 20px; }

.font-size-21 {
  font-size: 21px; }

.font-size-22 {
  font-size: 22px; }

.font-size-23 {
  font-size: 23px; }

.font-size-24 {
  font-size: 24px; }

.font-size-25 {
  font-size: 25px; }

.font-size-26 {
  font-size: 26px; }

.font-size-27 {
  font-size: 27px; }

.font-size-28 {
  font-size: 28px; }

.font-size-29 {
  font-size: 29px; }

.font-size-30 {
  font-size: 30px; }

.font-size-31 {
  font-size: 31px; }

.font-size-32 {
  font-size: 32px; }

.font-size-33 {
  font-size: 33px; }

.font-size-34 {
  font-size: 34px; }

.font-size-35 {
  font-size: 35px; }

.font-size-36 {
  font-size: 36px; }

.font-size-37 {
  font-size: 37px; }

.font-size-38 {
  font-size: 38px; }

.font-size-39 {
  font-size: 39px; }

.font-size-40 {
  font-size: 40px; }

.font-size-41 {
  font-size: 41px; }

.font-size-42 {
  font-size: 42px; }

.font-size-43 {
  font-size: 43px; }

.font-size-44 {
  font-size: 44px; }

.font-size-45 {
  font-size: 45px; }

.font-size-46 {
  font-size: 46px; }

.font-size-47 {
  font-size: 47px; }

.font-size-48 {
  font-size: 48px; }

.font-size-49 {
  font-size: 49px; }

.font-size-50 {
  font-size: 50px; }

.font-size-51 {
  font-size: 51px; }

.font-size-52 {
  font-size: 52px; }

.font-size-53 {
  font-size: 53px; }

.font-size-54 {
  font-size: 54px; }

.font-size-55 {
  font-size: 55px; }

.font-size-56 {
  font-size: 56px; }

.font-size-57 {
  font-size: 57px; }

.font-size-58 {
  font-size: 58px; }

.font-size-59 {
  font-size: 59px; }

.font-size-60 {
  font-size: 60px; }

.font-size-61 {
  font-size: 61px; }

.font-size-62 {
  font-size: 62px; }

.font-size-63 {
  font-size: 63px; }

.font-size-64 {
  font-size: 64px; }

.font-size-65 {
  font-size: 65px; }

.font-size-66 {
  font-size: 66px; }

.font-size-67 {
  font-size: 67px; }

.font-size-68 {
  font-size: 68px; }

.font-size-69 {
  font-size: 69px; }

.font-size-70 {
  font-size: 70px; }

.font-size-71 {
  font-size: 71px; }

.font-size-72 {
  font-size: 72px; }

.font-size-73 {
  font-size: 73px; }

.font-size-74 {
  font-size: 74px; }

.font-size-75 {
  font-size: 75px; }

.font-size-76 {
  font-size: 76px; }

.font-size-77 {
  font-size: 77px; }

.font-size-78 {
  font-size: 78px; }

.font-size-79 {
  font-size: 79px; }

.font-size-80 {
  font-size: 80px; }

.font-size-81 {
  font-size: 81px; }

.font-size-82 {
  font-size: 82px; }

.font-size-83 {
  font-size: 83px; }

.font-size-84 {
  font-size: 84px; }

.font-size-85 {
  font-size: 85px; }

.font-size-86 {
  font-size: 86px; }

.font-size-87 {
  font-size: 87px; }

.font-size-88 {
  font-size: 88px; }

.font-size-89 {
  font-size: 89px; }

.font-size-90 {
  font-size: 90px; }

.font-size-91 {
  font-size: 91px; }

.font-size-92 {
  font-size: 92px; }

.font-size-93 {
  font-size: 93px; }

.font-size-94 {
  font-size: 94px; }

.font-size-95 {
  font-size: 95px; }

.font-size-96 {
  font-size: 96px; }

.font-size-97 {
  font-size: 97px; }

.font-size-98 {
  font-size: 98px; }

.font-size-99 {
  font-size: 99px; }

.font-size-100 {
  font-size: 100px; }

.line-height-2 {
  line-height: 2px; }

.line-height-4 {
  line-height: 4px; }

.line-height-6 {
  line-height: 6px; }

.line-height-8 {
  line-height: 8px; }

.line-height-10 {
  line-height: 10px; }

.line-height-12 {
  line-height: 12px; }

.line-height-14 {
  line-height: 14px; }

.line-height-16 {
  line-height: 16px; }

.line-height-18 {
  line-height: 18px; }

.line-height-20 {
  line-height: 20px; }

.line-height-22 {
  line-height: 22px; }

.line-height-24 {
  line-height: 24px; }

.line-height-26 {
  line-height: 26px; }

.line-height-28 {
  line-height: 28px; }

.line-height-30 {
  line-height: 30px; }

.line-height-32 {
  line-height: 32px; }

.line-height-34 {
  line-height: 34px; }

.line-height-36 {
  line-height: 36px; }

.line-height-38 {
  line-height: 38px; }

.line-height-40 {
  line-height: 40px; }

.line-height-42 {
  line-height: 42px; }

.line-height-44 {
  line-height: 44px; }

.line-height-46 {
  line-height: 46px; }

.line-height-48 {
  line-height: 48px; }

.line-height-50 {
  line-height: 50px; }

.line-height-52 {
  line-height: 52px; }

.line-height-54 {
  line-height: 54px; }

.line-height-56 {
  line-height: 56px; }

.line-height-58 {
  line-height: 58px; }

.line-height-60 {
  line-height: 60px; }

.line-height-62 {
  line-height: 62px; }

.line-height-64 {
  line-height: 64px; }

.line-height-66 {
  line-height: 66px; }

.line-height-68 {
  line-height: 68px; }

.line-height-70 {
  line-height: 70px; }

.line-height-72 {
  line-height: 72px; }

.line-height-74 {
  line-height: 74px; }

.line-height-76 {
  line-height: 76px; }

.line-height-78 {
  line-height: 78px; }

.line-height-80 {
  line-height: 80px; }

.line-height-82 {
  line-height: 82px; }

.line-height-84 {
  line-height: 84px; }

.line-height-86 {
  line-height: 86px; }

.line-height-88 {
  line-height: 88px; }

.line-height-90 {
  line-height: 90px; }

.line-height-92 {
  line-height: 92px; }

.line-height-94 {
  line-height: 94px; }

.line-height-96 {
  line-height: 96px; }

.line-height-98 {
  line-height: 98px; }

.line-height-100 {
  line-height: 100px; }

.line-height-102 {
  line-height: 102px; }

.line-height-104 {
  line-height: 104px; }

.line-height-106 {
  line-height: 106px; }

.line-height-108 {
  line-height: 108px; }

.line-height-110 {
  line-height: 110px; }

.line-height-112 {
  line-height: 112px; }

.line-height-114 {
  line-height: 114px; }

.line-height-116 {
  line-height: 116px; }

.line-height-118 {
  line-height: 118px; }

.line-height-120 {
  line-height: 120px; }

md-icon,
md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px; }
  md-icon.s4,
  md-icon[md-font-icon].s4,
  i.s4 {
    font-size: 4px !important;
    width: 4px !important;
    height: 4px !important;
    min-width: 4px !important;
    min-height: 4px !important;
    line-height: 4px !important; }
  md-icon.s6,
  md-icon[md-font-icon].s6,
  i.s6 {
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    min-width: 6px !important;
    min-height: 6px !important;
    line-height: 6px !important; }
  md-icon.s8,
  md-icon[md-font-icon].s8,
  i.s8 {
    font-size: 8px !important;
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    min-height: 8px !important;
    line-height: 8px !important; }
  md-icon.s10,
  md-icon[md-font-icon].s10,
  i.s10 {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
    min-width: 10px !important;
    min-height: 10px !important;
    line-height: 10px !important; }
  md-icon.s12,
  md-icon[md-font-icon].s12,
  i.s12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    line-height: 12px !important; }
  md-icon.s14,
  md-icon[md-font-icon].s14,
  i.s14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    line-height: 14px !important; }
  md-icon.s16,
  md-icon[md-font-icon].s16,
  i.s16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    line-height: 16px !important; }
  md-icon.s18,
  md-icon[md-font-icon].s18,
  i.s18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important; }
  md-icon.s20,
  md-icon[md-font-icon].s20,
  i.s20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important; }
  md-icon.s22,
  md-icon[md-font-icon].s22,
  i.s22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    line-height: 22px !important; }
  md-icon.s24,
  md-icon[md-font-icon].s24,
  i.s24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important; }
  md-icon.s26,
  md-icon[md-font-icon].s26,
  i.s26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    min-width: 26px !important;
    min-height: 26px !important;
    line-height: 26px !important; }
  md-icon.s28,
  md-icon[md-font-icon].s28,
  i.s28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important; }
  md-icon.s30,
  md-icon[md-font-icon].s30,
  i.s30 {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important; }
  md-icon.s32,
  md-icon[md-font-icon].s32,
  i.s32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    line-height: 32px !important; }
  md-icon.s34,
  md-icon[md-font-icon].s34,
  i.s34 {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    line-height: 34px !important; }
  md-icon.s36,
  md-icon[md-font-icon].s36,
  i.s36 {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    line-height: 36px !important; }
  md-icon.s38,
  md-icon[md-font-icon].s38,
  i.s38 {
    font-size: 38px !important;
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important; }
  md-icon.s40,
  md-icon[md-font-icon].s40,
  i.s40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important; }
  md-icon.s42,
  md-icon[md-font-icon].s42,
  i.s42 {
    font-size: 42px !important;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    line-height: 42px !important; }
  md-icon.s44,
  md-icon[md-font-icon].s44,
  i.s44 {
    font-size: 44px !important;
    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
    line-height: 44px !important; }
  md-icon.s46,
  md-icon[md-font-icon].s46,
  i.s46 {
    font-size: 46px !important;
    width: 46px !important;
    height: 46px !important;
    min-width: 46px !important;
    min-height: 46px !important;
    line-height: 46px !important; }
  md-icon.s48,
  md-icon[md-font-icon].s48,
  i.s48 {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    line-height: 48px !important; }
  md-icon.s50,
  md-icon[md-font-icon].s50,
  i.s50 {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    line-height: 50px !important; }
  md-icon.s52,
  md-icon[md-font-icon].s52,
  i.s52 {
    font-size: 52px !important;
    width: 52px !important;
    height: 52px !important;
    min-width: 52px !important;
    min-height: 52px !important;
    line-height: 52px !important; }
  md-icon.s54,
  md-icon[md-font-icon].s54,
  i.s54 {
    font-size: 54px !important;
    width: 54px !important;
    height: 54px !important;
    min-width: 54px !important;
    min-height: 54px !important;
    line-height: 54px !important; }
  md-icon.s56,
  md-icon[md-font-icon].s56,
  i.s56 {
    font-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    line-height: 56px !important; }
  md-icon.s58,
  md-icon[md-font-icon].s58,
  i.s58 {
    font-size: 58px !important;
    width: 58px !important;
    height: 58px !important;
    min-width: 58px !important;
    min-height: 58px !important;
    line-height: 58px !important; }
  md-icon.s60,
  md-icon[md-font-icon].s60,
  i.s60 {
    font-size: 60px !important;
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;
    min-height: 60px !important;
    line-height: 60px !important; }
  md-icon.s62,
  md-icon[md-font-icon].s62,
  i.s62 {
    font-size: 62px !important;
    width: 62px !important;
    height: 62px !important;
    min-width: 62px !important;
    min-height: 62px !important;
    line-height: 62px !important; }
  md-icon.s64,
  md-icon[md-font-icon].s64,
  i.s64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    line-height: 64px !important; }
  md-icon.s66,
  md-icon[md-font-icon].s66,
  i.s66 {
    font-size: 66px !important;
    width: 66px !important;
    height: 66px !important;
    min-width: 66px !important;
    min-height: 66px !important;
    line-height: 66px !important; }
  md-icon.s68,
  md-icon[md-font-icon].s68,
  i.s68 {
    font-size: 68px !important;
    width: 68px !important;
    height: 68px !important;
    min-width: 68px !important;
    min-height: 68px !important;
    line-height: 68px !important; }
  md-icon.s70,
  md-icon[md-font-icon].s70,
  i.s70 {
    font-size: 70px !important;
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    line-height: 70px !important; }
  md-icon.s72,
  md-icon[md-font-icon].s72,
  i.s72 {
    font-size: 72px !important;
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    line-height: 72px !important; }
  md-icon.s74,
  md-icon[md-font-icon].s74,
  i.s74 {
    font-size: 74px !important;
    width: 74px !important;
    height: 74px !important;
    min-width: 74px !important;
    min-height: 74px !important;
    line-height: 74px !important; }
  md-icon.s76,
  md-icon[md-font-icon].s76,
  i.s76 {
    font-size: 76px !important;
    width: 76px !important;
    height: 76px !important;
    min-width: 76px !important;
    min-height: 76px !important;
    line-height: 76px !important; }
  md-icon.s78,
  md-icon[md-font-icon].s78,
  i.s78 {
    font-size: 78px !important;
    width: 78px !important;
    height: 78px !important;
    min-width: 78px !important;
    min-height: 78px !important;
    line-height: 78px !important; }
  md-icon.s80,
  md-icon[md-font-icon].s80,
  i.s80 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    line-height: 80px !important; }
  md-icon.s82,
  md-icon[md-font-icon].s82,
  i.s82 {
    font-size: 82px !important;
    width: 82px !important;
    height: 82px !important;
    min-width: 82px !important;
    min-height: 82px !important;
    line-height: 82px !important; }
  md-icon.s84,
  md-icon[md-font-icon].s84,
  i.s84 {
    font-size: 84px !important;
    width: 84px !important;
    height: 84px !important;
    min-width: 84px !important;
    min-height: 84px !important;
    line-height: 84px !important; }
  md-icon.s86,
  md-icon[md-font-icon].s86,
  i.s86 {
    font-size: 86px !important;
    width: 86px !important;
    height: 86px !important;
    min-width: 86px !important;
    min-height: 86px !important;
    line-height: 86px !important; }
  md-icon.s88,
  md-icon[md-font-icon].s88,
  i.s88 {
    font-size: 88px !important;
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    line-height: 88px !important; }
  md-icon.s90,
  md-icon[md-font-icon].s90,
  i.s90 {
    font-size: 90px !important;
    width: 90px !important;
    height: 90px !important;
    min-width: 90px !important;
    min-height: 90px !important;
    line-height: 90px !important; }
  md-icon.s92,
  md-icon[md-font-icon].s92,
  i.s92 {
    font-size: 92px !important;
    width: 92px !important;
    height: 92px !important;
    min-width: 92px !important;
    min-height: 92px !important;
    line-height: 92px !important; }
  md-icon.s94,
  md-icon[md-font-icon].s94,
  i.s94 {
    font-size: 94px !important;
    width: 94px !important;
    height: 94px !important;
    min-width: 94px !important;
    min-height: 94px !important;
    line-height: 94px !important; }
  md-icon.s96,
  md-icon[md-font-icon].s96,
  i.s96 {
    font-size: 96px !important;
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    line-height: 96px !important; }
  md-icon.s98,
  md-icon[md-font-icon].s98,
  i.s98 {
    font-size: 98px !important;
    width: 98px !important;
    height: 98px !important;
    min-width: 98px !important;
    min-height: 98px !important;
    line-height: 98px !important; }
  md-icon.s100,
  md-icon[md-font-icon].s100,
  i.s100 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    line-height: 100px !important; }
  md-icon.s102,
  md-icon[md-font-icon].s102,
  i.s102 {
    font-size: 102px !important;
    width: 102px !important;
    height: 102px !important;
    min-width: 102px !important;
    min-height: 102px !important;
    line-height: 102px !important; }
  md-icon.s104,
  md-icon[md-font-icon].s104,
  i.s104 {
    font-size: 104px !important;
    width: 104px !important;
    height: 104px !important;
    min-width: 104px !important;
    min-height: 104px !important;
    line-height: 104px !important; }
  md-icon.s106,
  md-icon[md-font-icon].s106,
  i.s106 {
    font-size: 106px !important;
    width: 106px !important;
    height: 106px !important;
    min-width: 106px !important;
    min-height: 106px !important;
    line-height: 106px !important; }
  md-icon.s108,
  md-icon[md-font-icon].s108,
  i.s108 {
    font-size: 108px !important;
    width: 108px !important;
    height: 108px !important;
    min-width: 108px !important;
    min-height: 108px !important;
    line-height: 108px !important; }
  md-icon.s110,
  md-icon[md-font-icon].s110,
  i.s110 {
    font-size: 110px !important;
    width: 110px !important;
    height: 110px !important;
    min-width: 110px !important;
    min-height: 110px !important;
    line-height: 110px !important; }
  md-icon.s112,
  md-icon[md-font-icon].s112,
  i.s112 {
    font-size: 112px !important;
    width: 112px !important;
    height: 112px !important;
    min-width: 112px !important;
    min-height: 112px !important;
    line-height: 112px !important; }
  md-icon.s114,
  md-icon[md-font-icon].s114,
  i.s114 {
    font-size: 114px !important;
    width: 114px !important;
    height: 114px !important;
    min-width: 114px !important;
    min-height: 114px !important;
    line-height: 114px !important; }
  md-icon.s116,
  md-icon[md-font-icon].s116,
  i.s116 {
    font-size: 116px !important;
    width: 116px !important;
    height: 116px !important;
    min-width: 116px !important;
    min-height: 116px !important;
    line-height: 116px !important; }
  md-icon.s118,
  md-icon[md-font-icon].s118,
  i.s118 {
    font-size: 118px !important;
    width: 118px !important;
    height: 118px !important;
    min-width: 118px !important;
    min-height: 118px !important;
    line-height: 118px !important; }
  md-icon.s120,
  md-icon[md-font-icon].s120,
  i.s120 {
    font-size: 120px !important;
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
    min-height: 120px !important;
    line-height: 120px !important; }
  md-icon.s122,
  md-icon[md-font-icon].s122,
  i.s122 {
    font-size: 122px !important;
    width: 122px !important;
    height: 122px !important;
    min-width: 122px !important;
    min-height: 122px !important;
    line-height: 122px !important; }
  md-icon.s124,
  md-icon[md-font-icon].s124,
  i.s124 {
    font-size: 124px !important;
    width: 124px !important;
    height: 124px !important;
    min-width: 124px !important;
    min-height: 124px !important;
    line-height: 124px !important; }
  md-icon.s126,
  md-icon[md-font-icon].s126,
  i.s126 {
    font-size: 126px !important;
    width: 126px !important;
    height: 126px !important;
    min-width: 126px !important;
    min-height: 126px !important;
    line-height: 126px !important; }
  md-icon.s128,
  md-icon[md-font-icon].s128,
  i.s128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    min-width: 128px !important;
    min-height: 128px !important;
    line-height: 128px !important; }
  md-icon.s130,
  md-icon[md-font-icon].s130,
  i.s130 {
    font-size: 130px !important;
    width: 130px !important;
    height: 130px !important;
    min-width: 130px !important;
    min-height: 130px !important;
    line-height: 130px !important; }
  md-icon.s132,
  md-icon[md-font-icon].s132,
  i.s132 {
    font-size: 132px !important;
    width: 132px !important;
    height: 132px !important;
    min-width: 132px !important;
    min-height: 132px !important;
    line-height: 132px !important; }
  md-icon.s134,
  md-icon[md-font-icon].s134,
  i.s134 {
    font-size: 134px !important;
    width: 134px !important;
    height: 134px !important;
    min-width: 134px !important;
    min-height: 134px !important;
    line-height: 134px !important; }
  md-icon.s136,
  md-icon[md-font-icon].s136,
  i.s136 {
    font-size: 136px !important;
    width: 136px !important;
    height: 136px !important;
    min-width: 136px !important;
    min-height: 136px !important;
    line-height: 136px !important; }
  md-icon.s138,
  md-icon[md-font-icon].s138,
  i.s138 {
    font-size: 138px !important;
    width: 138px !important;
    height: 138px !important;
    min-width: 138px !important;
    min-height: 138px !important;
    line-height: 138px !important; }
  md-icon.s140,
  md-icon[md-font-icon].s140,
  i.s140 {
    font-size: 140px !important;
    width: 140px !important;
    height: 140px !important;
    min-width: 140px !important;
    min-height: 140px !important;
    line-height: 140px !important; }
  md-icon.s142,
  md-icon[md-font-icon].s142,
  i.s142 {
    font-size: 142px !important;
    width: 142px !important;
    height: 142px !important;
    min-width: 142px !important;
    min-height: 142px !important;
    line-height: 142px !important; }
  md-icon.s144,
  md-icon[md-font-icon].s144,
  i.s144 {
    font-size: 144px !important;
    width: 144px !important;
    height: 144px !important;
    min-width: 144px !important;
    min-height: 144px !important;
    line-height: 144px !important; }
  md-icon.s146,
  md-icon[md-font-icon].s146,
  i.s146 {
    font-size: 146px !important;
    width: 146px !important;
    height: 146px !important;
    min-width: 146px !important;
    min-height: 146px !important;
    line-height: 146px !important; }
  md-icon.s148,
  md-icon[md-font-icon].s148,
  i.s148 {
    font-size: 148px !important;
    width: 148px !important;
    height: 148px !important;
    min-width: 148px !important;
    min-height: 148px !important;
    line-height: 148px !important; }
  md-icon.s150,
  md-icon[md-font-icon].s150,
  i.s150 {
    font-size: 150px !important;
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
    min-height: 150px !important;
    line-height: 150px !important; }
  md-icon.s152,
  md-icon[md-font-icon].s152,
  i.s152 {
    font-size: 152px !important;
    width: 152px !important;
    height: 152px !important;
    min-width: 152px !important;
    min-height: 152px !important;
    line-height: 152px !important; }
  md-icon.s154,
  md-icon[md-font-icon].s154,
  i.s154 {
    font-size: 154px !important;
    width: 154px !important;
    height: 154px !important;
    min-width: 154px !important;
    min-height: 154px !important;
    line-height: 154px !important; }
  md-icon.s156,
  md-icon[md-font-icon].s156,
  i.s156 {
    font-size: 156px !important;
    width: 156px !important;
    height: 156px !important;
    min-width: 156px !important;
    min-height: 156px !important;
    line-height: 156px !important; }
  md-icon.s158,
  md-icon[md-font-icon].s158,
  i.s158 {
    font-size: 158px !important;
    width: 158px !important;
    height: 158px !important;
    min-width: 158px !important;
    min-height: 158px !important;
    line-height: 158px !important; }
  md-icon.s160,
  md-icon[md-font-icon].s160,
  i.s160 {
    font-size: 160px !important;
    width: 160px !important;
    height: 160px !important;
    min-width: 160px !important;
    min-height: 160px !important;
    line-height: 160px !important; }
  md-icon.s162,
  md-icon[md-font-icon].s162,
  i.s162 {
    font-size: 162px !important;
    width: 162px !important;
    height: 162px !important;
    min-width: 162px !important;
    min-height: 162px !important;
    line-height: 162px !important; }
  md-icon.s164,
  md-icon[md-font-icon].s164,
  i.s164 {
    font-size: 164px !important;
    width: 164px !important;
    height: 164px !important;
    min-width: 164px !important;
    min-height: 164px !important;
    line-height: 164px !important; }
  md-icon.s166,
  md-icon[md-font-icon].s166,
  i.s166 {
    font-size: 166px !important;
    width: 166px !important;
    height: 166px !important;
    min-width: 166px !important;
    min-height: 166px !important;
    line-height: 166px !important; }
  md-icon.s168,
  md-icon[md-font-icon].s168,
  i.s168 {
    font-size: 168px !important;
    width: 168px !important;
    height: 168px !important;
    min-width: 168px !important;
    min-height: 168px !important;
    line-height: 168px !important; }
  md-icon.s170,
  md-icon[md-font-icon].s170,
  i.s170 {
    font-size: 170px !important;
    width: 170px !important;
    height: 170px !important;
    min-width: 170px !important;
    min-height: 170px !important;
    line-height: 170px !important; }
  md-icon.s172,
  md-icon[md-font-icon].s172,
  i.s172 {
    font-size: 172px !important;
    width: 172px !important;
    height: 172px !important;
    min-width: 172px !important;
    min-height: 172px !important;
    line-height: 172px !important; }
  md-icon.s174,
  md-icon[md-font-icon].s174,
  i.s174 {
    font-size: 174px !important;
    width: 174px !important;
    height: 174px !important;
    min-width: 174px !important;
    min-height: 174px !important;
    line-height: 174px !important; }
  md-icon.s176,
  md-icon[md-font-icon].s176,
  i.s176 {
    font-size: 176px !important;
    width: 176px !important;
    height: 176px !important;
    min-width: 176px !important;
    min-height: 176px !important;
    line-height: 176px !important; }
  md-icon.s178,
  md-icon[md-font-icon].s178,
  i.s178 {
    font-size: 178px !important;
    width: 178px !important;
    height: 178px !important;
    min-width: 178px !important;
    min-height: 178px !important;
    line-height: 178px !important; }
  md-icon.s180,
  md-icon[md-font-icon].s180,
  i.s180 {
    font-size: 180px !important;
    width: 180px !important;
    height: 180px !important;
    min-width: 180px !important;
    min-height: 180px !important;
    line-height: 180px !important; }
  md-icon.s182,
  md-icon[md-font-icon].s182,
  i.s182 {
    font-size: 182px !important;
    width: 182px !important;
    height: 182px !important;
    min-width: 182px !important;
    min-height: 182px !important;
    line-height: 182px !important; }
  md-icon.s184,
  md-icon[md-font-icon].s184,
  i.s184 {
    font-size: 184px !important;
    width: 184px !important;
    height: 184px !important;
    min-width: 184px !important;
    min-height: 184px !important;
    line-height: 184px !important; }
  md-icon.s186,
  md-icon[md-font-icon].s186,
  i.s186 {
    font-size: 186px !important;
    width: 186px !important;
    height: 186px !important;
    min-width: 186px !important;
    min-height: 186px !important;
    line-height: 186px !important; }
  md-icon.s188,
  md-icon[md-font-icon].s188,
  i.s188 {
    font-size: 188px !important;
    width: 188px !important;
    height: 188px !important;
    min-width: 188px !important;
    min-height: 188px !important;
    line-height: 188px !important; }
  md-icon.s190,
  md-icon[md-font-icon].s190,
  i.s190 {
    font-size: 190px !important;
    width: 190px !important;
    height: 190px !important;
    min-width: 190px !important;
    min-height: 190px !important;
    line-height: 190px !important; }
  md-icon.s192,
  md-icon[md-font-icon].s192,
  i.s192 {
    font-size: 192px !important;
    width: 192px !important;
    height: 192px !important;
    min-width: 192px !important;
    min-height: 192px !important;
    line-height: 192px !important; }
  md-icon.s194,
  md-icon[md-font-icon].s194,
  i.s194 {
    font-size: 194px !important;
    width: 194px !important;
    height: 194px !important;
    min-width: 194px !important;
    min-height: 194px !important;
    line-height: 194px !important; }
  md-icon.s196,
  md-icon[md-font-icon].s196,
  i.s196 {
    font-size: 196px !important;
    width: 196px !important;
    height: 196px !important;
    min-width: 196px !important;
    min-height: 196px !important;
    line-height: 196px !important; }
  md-icon.s198,
  md-icon[md-font-icon].s198,
  i.s198 {
    font-size: 198px !important;
    width: 198px !important;
    height: 198px !important;
    min-width: 198px !important;
    min-height: 198px !important;
    line-height: 198px !important; }
  md-icon.s200,
  md-icon[md-font-icon].s200,
  i.s200 {
    font-size: 200px !important;
    width: 200px !important;
    height: 200px !important;
    min-width: 200px !important;
    min-height: 200px !important;
    line-height: 200px !important; }
  md-icon.s202,
  md-icon[md-font-icon].s202,
  i.s202 {
    font-size: 202px !important;
    width: 202px !important;
    height: 202px !important;
    min-width: 202px !important;
    min-height: 202px !important;
    line-height: 202px !important; }
  md-icon.s204,
  md-icon[md-font-icon].s204,
  i.s204 {
    font-size: 204px !important;
    width: 204px !important;
    height: 204px !important;
    min-width: 204px !important;
    min-height: 204px !important;
    line-height: 204px !important; }
  md-icon.s206,
  md-icon[md-font-icon].s206,
  i.s206 {
    font-size: 206px !important;
    width: 206px !important;
    height: 206px !important;
    min-width: 206px !important;
    min-height: 206px !important;
    line-height: 206px !important; }
  md-icon.s208,
  md-icon[md-font-icon].s208,
  i.s208 {
    font-size: 208px !important;
    width: 208px !important;
    height: 208px !important;
    min-width: 208px !important;
    min-height: 208px !important;
    line-height: 208px !important; }
  md-icon.s210,
  md-icon[md-font-icon].s210,
  i.s210 {
    font-size: 210px !important;
    width: 210px !important;
    height: 210px !important;
    min-width: 210px !important;
    min-height: 210px !important;
    line-height: 210px !important; }
  md-icon.s212,
  md-icon[md-font-icon].s212,
  i.s212 {
    font-size: 212px !important;
    width: 212px !important;
    height: 212px !important;
    min-width: 212px !important;
    min-height: 212px !important;
    line-height: 212px !important; }
  md-icon.s214,
  md-icon[md-font-icon].s214,
  i.s214 {
    font-size: 214px !important;
    width: 214px !important;
    height: 214px !important;
    min-width: 214px !important;
    min-height: 214px !important;
    line-height: 214px !important; }
  md-icon.s216,
  md-icon[md-font-icon].s216,
  i.s216 {
    font-size: 216px !important;
    width: 216px !important;
    height: 216px !important;
    min-width: 216px !important;
    min-height: 216px !important;
    line-height: 216px !important; }
  md-icon.s218,
  md-icon[md-font-icon].s218,
  i.s218 {
    font-size: 218px !important;
    width: 218px !important;
    height: 218px !important;
    min-width: 218px !important;
    min-height: 218px !important;
    line-height: 218px !important; }
  md-icon.s220,
  md-icon[md-font-icon].s220,
  i.s220 {
    font-size: 220px !important;
    width: 220px !important;
    height: 220px !important;
    min-width: 220px !important;
    min-height: 220px !important;
    line-height: 220px !important; }
  md-icon.s222,
  md-icon[md-font-icon].s222,
  i.s222 {
    font-size: 222px !important;
    width: 222px !important;
    height: 222px !important;
    min-width: 222px !important;
    min-height: 222px !important;
    line-height: 222px !important; }
  md-icon.s224,
  md-icon[md-font-icon].s224,
  i.s224 {
    font-size: 224px !important;
    width: 224px !important;
    height: 224px !important;
    min-width: 224px !important;
    min-height: 224px !important;
    line-height: 224px !important; }
  md-icon.s226,
  md-icon[md-font-icon].s226,
  i.s226 {
    font-size: 226px !important;
    width: 226px !important;
    height: 226px !important;
    min-width: 226px !important;
    min-height: 226px !important;
    line-height: 226px !important; }
  md-icon.s228,
  md-icon[md-font-icon].s228,
  i.s228 {
    font-size: 228px !important;
    width: 228px !important;
    height: 228px !important;
    min-width: 228px !important;
    min-height: 228px !important;
    line-height: 228px !important; }
  md-icon.s230,
  md-icon[md-font-icon].s230,
  i.s230 {
    font-size: 230px !important;
    width: 230px !important;
    height: 230px !important;
    min-width: 230px !important;
    min-height: 230px !important;
    line-height: 230px !important; }
  md-icon.s232,
  md-icon[md-font-icon].s232,
  i.s232 {
    font-size: 232px !important;
    width: 232px !important;
    height: 232px !important;
    min-width: 232px !important;
    min-height: 232px !important;
    line-height: 232px !important; }
  md-icon.s234,
  md-icon[md-font-icon].s234,
  i.s234 {
    font-size: 234px !important;
    width: 234px !important;
    height: 234px !important;
    min-width: 234px !important;
    min-height: 234px !important;
    line-height: 234px !important; }
  md-icon.s236,
  md-icon[md-font-icon].s236,
  i.s236 {
    font-size: 236px !important;
    width: 236px !important;
    height: 236px !important;
    min-width: 236px !important;
    min-height: 236px !important;
    line-height: 236px !important; }
  md-icon.s238,
  md-icon[md-font-icon].s238,
  i.s238 {
    font-size: 238px !important;
    width: 238px !important;
    height: 238px !important;
    min-width: 238px !important;
    min-height: 238px !important;
    line-height: 238px !important; }
  md-icon.s240,
  md-icon[md-font-icon].s240,
  i.s240 {
    font-size: 240px !important;
    width: 240px !important;
    height: 240px !important;
    min-width: 240px !important;
    min-height: 240px !important;
    line-height: 240px !important; }
  md-icon.s242,
  md-icon[md-font-icon].s242,
  i.s242 {
    font-size: 242px !important;
    width: 242px !important;
    height: 242px !important;
    min-width: 242px !important;
    min-height: 242px !important;
    line-height: 242px !important; }
  md-icon.s244,
  md-icon[md-font-icon].s244,
  i.s244 {
    font-size: 244px !important;
    width: 244px !important;
    height: 244px !important;
    min-width: 244px !important;
    min-height: 244px !important;
    line-height: 244px !important; }
  md-icon.s246,
  md-icon[md-font-icon].s246,
  i.s246 {
    font-size: 246px !important;
    width: 246px !important;
    height: 246px !important;
    min-width: 246px !important;
    min-height: 246px !important;
    line-height: 246px !important; }
  md-icon.s248,
  md-icon[md-font-icon].s248,
  i.s248 {
    font-size: 248px !important;
    width: 248px !important;
    height: 248px !important;
    min-width: 248px !important;
    min-height: 248px !important;
    line-height: 248px !important; }
  md-icon.s250,
  md-icon[md-font-icon].s250,
  i.s250 {
    font-size: 250px !important;
    width: 250px !important;
    height: 250px !important;
    min-width: 250px !important;
    min-height: 250px !important;
    line-height: 250px !important; }
  md-icon.s252,
  md-icon[md-font-icon].s252,
  i.s252 {
    font-size: 252px !important;
    width: 252px !important;
    height: 252px !important;
    min-width: 252px !important;
    min-height: 252px !important;
    line-height: 252px !important; }
  md-icon.s254,
  md-icon[md-font-icon].s254,
  i.s254 {
    font-size: 254px !important;
    width: 254px !important;
    height: 254px !important;
    min-width: 254px !important;
    min-height: 254px !important;
    line-height: 254px !important; }
  md-icon.s256,
  md-icon[md-font-icon].s256,
  i.s256 {
    font-size: 256px !important;
    width: 256px !important;
    height: 256px !important;
    min-width: 256px !important;
    min-height: 256px !important;
    line-height: 256px !important; }

.text-boxed, .text-boxed-light {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap; }

.text-boxed-light {
  background-color: rgba(255, 255, 255, 0.7); }

.text-truncate, .allocations-view > .md-subheader .info-allocation,
.allocations-view > md-toolbar .info-allocation, .allocations-proData-view > .md-subheader .info-allocation,
.allocations-proData-view > md-toolbar .info-allocation, .allocationMonitrips-view > .md-subheader .info-allocationMonitrip,
.allocationMonitrips-view > md-toolbar .info-allocationMonitrip, .service-report .service-sidebar .service-view > .md-subheader .info-allocation,
.service-report .service-sidebar .service-view > md-toolbar .info-allocation {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-overflow {
  text-overflow: clip;
  overflow: visible; }

.text-nowrap {
  white-space: nowrap; }

.text-first-letter-uppercase:first-letter {
  text-transform: uppercase; }

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-radius {
  border-radius: 0; }

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px; }

.hidden {
  visibility: hidden;
  opacity: 0; }

[fill-width] {
  min-width: 100%; }

[fill-height] {
  min-height: 100%; }

.full-height {
  height: 100% !important; }

a img {
  display: block;
  outline: none; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }

.overflow-x {
  overflow-x: hidden; }

.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .flex-scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .flex-scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.align-vertical:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle; }

.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
  display: inline-block; }

.align-vertical .align-vertical-top {
  vertical-align: top; }

.align-vertical .align-vertical-middle {
  vertical-align: middle; }

.align-vertical .align-vertical-bottom {
  vertical-align: bottom; }

.align-vertical-top,
.align-vertical-middle,
.align-vertical-bottom {
  display: inline-block; }

.align-vertical-top {
  vertical-align: top; }

.align-vertical-middle {
  vertical-align: middle; }

.align-vertical-bottom {
  vertical-align: bottom; }

.align-vertical-text-bottom {
  vertical-align: text-bottom !important; }

.text-italic {
  font-style: italic; }

.text-semibold, .allocations-view md-list-item .md-list-item-text .title, .allocations-proData-view md-list-item .md-list-item-text .title, .allocationMonitrips-view md-list-item .md-list-item-text .title, md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .nv-noData, md-dialog.allocationPRODATA-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.allocationCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.allocationTicketsCentroidsCSV-list md-content md-list-item .md-list-item-text .title, md-dialog.centroidCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.lineCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.employeeCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.serviceCSV-list md-dialog-content md-list-item .md-list-item-text .title, .service-report .service-sidebar md-list-item .md-list-item-text .title {
  font-weight: 600; }

.text-bold, strong {
  font-weight: 700; }

.text-strike {
  text-decoration: line-through; }

.text-super {
  vertical-align: super; }

.text-sub {
  vertical-align: sub; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.z-index-0 {
  z-index: 0 !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.z-index-6 {
  z-index: 6 !important; }

.z-index-7 {
  z-index: 7 !important; }

.z-index-8 {
  z-index: 8 !important; }

.z-index-9 {
  z-index: 9 !important; }

.z-index-10 {
  z-index: 10 !important; }

.z-index-11 {
  z-index: 11 !important; }

.z-index-12 {
  z-index: 12 !important; }

.z-index-13 {
  z-index: 13 !important; }

.z-index-14 {
  z-index: 14 !important; }

.z-index-15 {
  z-index: 15 !important; }

.z-index-16 {
  z-index: 16 !important; }

.z-index-17 {
  z-index: 17 !important; }

.z-index-18 {
  z-index: 18 !important; }

.z-index-19 {
  z-index: 19 !important; }

.z-index-20 {
  z-index: 20 !important; }

.padding-0,
.p-0 {
  padding: 0px !important; }

.padding-top-0,
.pt-0 {
  padding-top: 0px !important; }

.padding-right-0,
.pr-0 {
  padding-right: 0px !important; }

.padding-bottom-0,
.pb-0 {
  padding-bottom: 0px !important; }

.padding-left-0,
.pl-0 {
  padding-left: 0px !important; }

.padding-horizontal-0,
.ph-0 {
  padding-left: 0px;
  padding-right: 0px; }

.padding-vertical-0,
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.padding-1,
.p-1 {
  padding: 1px !important; }

.padding-top-1,
.pt-1 {
  padding-top: 1px !important; }

.padding-right-1,
.pr-1 {
  padding-right: 1px !important; }

.padding-bottom-1,
.pb-1 {
  padding-bottom: 1px !important; }

.padding-left-1,
.pl-1 {
  padding-left: 1px !important; }

.padding-horizontal-1,
.ph-1 {
  padding-left: 1px;
  padding-right: 1px; }

.padding-vertical-1,
.pv-1 {
  padding-top: 1px;
  padding-bottom: 1px; }

.padding-2,
.p-2 {
  padding: 2px !important; }

.padding-top-2,
.pt-2 {
  padding-top: 2px !important; }

.padding-right-2,
.pr-2 {
  padding-right: 2px !important; }

.padding-bottom-2,
.pb-2 {
  padding-bottom: 2px !important; }

.padding-left-2,
.pl-2 {
  padding-left: 2px !important; }

.padding-horizontal-2,
.ph-2 {
  padding-left: 2px;
  padding-right: 2px; }

.padding-vertical-2,
.pv-2 {
  padding-top: 2px;
  padding-bottom: 2px; }

.padding-3,
.p-3 {
  padding: 3px !important; }

.padding-top-3,
.pt-3 {
  padding-top: 3px !important; }

.padding-right-3,
.pr-3 {
  padding-right: 3px !important; }

.padding-bottom-3,
.pb-3 {
  padding-bottom: 3px !important; }

.padding-left-3,
.pl-3 {
  padding-left: 3px !important; }

.padding-horizontal-3,
.ph-3 {
  padding-left: 3px;
  padding-right: 3px; }

.padding-vertical-3,
.pv-3 {
  padding-top: 3px;
  padding-bottom: 3px; }

.padding-4,
.p-4 {
  padding: 4px !important; }

.padding-top-4,
.pt-4 {
  padding-top: 4px !important; }

.padding-right-4,
.pr-4 {
  padding-right: 4px !important; }

.padding-bottom-4,
.pb-4 {
  padding-bottom: 4px !important; }

.padding-left-4,
.pl-4 {
  padding-left: 4px !important; }

.padding-horizontal-4,
.ph-4 {
  padding-left: 4px;
  padding-right: 4px; }

.padding-vertical-4,
.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px; }

.padding-5,
.p-5 {
  padding: 5px !important; }

.padding-top-5,
.pt-5 {
  padding-top: 5px !important; }

.padding-right-5,
.pr-5 {
  padding-right: 5px !important; }

.padding-bottom-5,
.pb-5 {
  padding-bottom: 5px !important; }

.padding-left-5,
.pl-5 {
  padding-left: 5px !important; }

.padding-horizontal-5,
.ph-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding-vertical-5,
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.padding-6,
.p-6 {
  padding: 6px !important; }

.padding-top-6,
.pt-6 {
  padding-top: 6px !important; }

.padding-right-6,
.pr-6 {
  padding-right: 6px !important; }

.padding-bottom-6,
.pb-6 {
  padding-bottom: 6px !important; }

.padding-left-6,
.pl-6 {
  padding-left: 6px !important; }

.padding-horizontal-6,
.ph-6 {
  padding-left: 6px;
  padding-right: 6px; }

.padding-vertical-6,
.pv-6 {
  padding-top: 6px;
  padding-bottom: 6px; }

.padding-7,
.p-7 {
  padding: 7px !important; }

.padding-top-7,
.pt-7 {
  padding-top: 7px !important; }

.padding-right-7,
.pr-7 {
  padding-right: 7px !important; }

.padding-bottom-7,
.pb-7 {
  padding-bottom: 7px !important; }

.padding-left-7,
.pl-7 {
  padding-left: 7px !important; }

.padding-horizontal-7,
.ph-7 {
  padding-left: 7px;
  padding-right: 7px; }

.padding-vertical-7,
.pv-7 {
  padding-top: 7px;
  padding-bottom: 7px; }

.padding-8,
.p-8 {
  padding: 8px !important; }

.padding-top-8,
.pt-8 {
  padding-top: 8px !important; }

.padding-right-8,
.pr-8 {
  padding-right: 8px !important; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px !important; }

.padding-left-8,
.pl-8 {
  padding-left: 8px !important; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-9,
.p-9 {
  padding: 9px !important; }

.padding-top-9,
.pt-9 {
  padding-top: 9px !important; }

.padding-right-9,
.pr-9 {
  padding-right: 9px !important; }

.padding-bottom-9,
.pb-9 {
  padding-bottom: 9px !important; }

.padding-left-9,
.pl-9 {
  padding-left: 9px !important; }

.padding-horizontal-9,
.ph-9 {
  padding-left: 9px;
  padding-right: 9px; }

.padding-vertical-9,
.pv-9 {
  padding-top: 9px;
  padding-bottom: 9px; }

.padding-10,
.p-10 {
  padding: 10px !important; }

.padding-top-10,
.pt-10 {
  padding-top: 10px !important; }

.padding-right-10,
.pr-10 {
  padding-right: 10px !important; }

.padding-bottom-10,
.pb-10 {
  padding-bottom: 10px !important; }

.padding-left-10,
.pl-10 {
  padding-left: 10px !important; }

.padding-horizontal-10,
.ph-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding-vertical-10,
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.padding-11,
.p-11 {
  padding: 11px !important; }

.padding-top-11,
.pt-11 {
  padding-top: 11px !important; }

.padding-right-11,
.pr-11 {
  padding-right: 11px !important; }

.padding-bottom-11,
.pb-11 {
  padding-bottom: 11px !important; }

.padding-left-11,
.pl-11 {
  padding-left: 11px !important; }

.padding-horizontal-11,
.ph-11 {
  padding-left: 11px;
  padding-right: 11px; }

.padding-vertical-11,
.pv-11 {
  padding-top: 11px;
  padding-bottom: 11px; }

.padding-12,
.p-12 {
  padding: 12px !important; }

.padding-top-12,
.pt-12 {
  padding-top: 12px !important; }

.padding-right-12,
.pr-12 {
  padding-right: 12px !important; }

.padding-bottom-12,
.pb-12 {
  padding-bottom: 12px !important; }

.padding-left-12,
.pl-12 {
  padding-left: 12px !important; }

.padding-horizontal-12,
.ph-12 {
  padding-left: 12px;
  padding-right: 12px; }

.padding-vertical-12,
.pv-12 {
  padding-top: 12px;
  padding-bottom: 12px; }

.padding-13,
.p-13 {
  padding: 13px !important; }

.padding-top-13,
.pt-13 {
  padding-top: 13px !important; }

.padding-right-13,
.pr-13 {
  padding-right: 13px !important; }

.padding-bottom-13,
.pb-13 {
  padding-bottom: 13px !important; }

.padding-left-13,
.pl-13 {
  padding-left: 13px !important; }

.padding-horizontal-13,
.ph-13 {
  padding-left: 13px;
  padding-right: 13px; }

.padding-vertical-13,
.pv-13 {
  padding-top: 13px;
  padding-bottom: 13px; }

.padding-14,
.p-14 {
  padding: 14px !important; }

.padding-top-14,
.pt-14 {
  padding-top: 14px !important; }

.padding-right-14,
.pr-14 {
  padding-right: 14px !important; }

.padding-bottom-14,
.pb-14 {
  padding-bottom: 14px !important; }

.padding-left-14,
.pl-14 {
  padding-left: 14px !important; }

.padding-horizontal-14,
.ph-14 {
  padding-left: 14px;
  padding-right: 14px; }

.padding-vertical-14,
.pv-14 {
  padding-top: 14px;
  padding-bottom: 14px; }

.padding-15,
.p-15 {
  padding: 15px !important; }

.padding-top-15,
.pt-15 {
  padding-top: 15px !important; }

.padding-right-15,
.pr-15 {
  padding-right: 15px !important; }

.padding-bottom-15,
.pb-15 {
  padding-bottom: 15px !important; }

.padding-left-15,
.pl-15 {
  padding-left: 15px !important; }

.padding-horizontal-15,
.ph-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding-vertical-15,
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.padding-16,
.p-16 {
  padding: 16px !important; }

.padding-top-16,
.pt-16 {
  padding-top: 16px !important; }

.padding-right-16,
.pr-16 {
  padding-right: 16px !important; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px !important; }

.padding-left-16,
.pl-16 {
  padding-left: 16px !important; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-17,
.p-17 {
  padding: 17px !important; }

.padding-top-17,
.pt-17 {
  padding-top: 17px !important; }

.padding-right-17,
.pr-17 {
  padding-right: 17px !important; }

.padding-bottom-17,
.pb-17 {
  padding-bottom: 17px !important; }

.padding-left-17,
.pl-17 {
  padding-left: 17px !important; }

.padding-horizontal-17,
.ph-17 {
  padding-left: 17px;
  padding-right: 17px; }

.padding-vertical-17,
.pv-17 {
  padding-top: 17px;
  padding-bottom: 17px; }

.padding-18,
.p-18 {
  padding: 18px !important; }

.padding-top-18,
.pt-18 {
  padding-top: 18px !important; }

.padding-right-18,
.pr-18 {
  padding-right: 18px !important; }

.padding-bottom-18,
.pb-18 {
  padding-bottom: 18px !important; }

.padding-left-18,
.pl-18 {
  padding-left: 18px !important; }

.padding-horizontal-18,
.ph-18 {
  padding-left: 18px;
  padding-right: 18px; }

.padding-vertical-18,
.pv-18 {
  padding-top: 18px;
  padding-bottom: 18px; }

.padding-19,
.p-19 {
  padding: 19px !important; }

.padding-top-19,
.pt-19 {
  padding-top: 19px !important; }

.padding-right-19,
.pr-19 {
  padding-right: 19px !important; }

.padding-bottom-19,
.pb-19 {
  padding-bottom: 19px !important; }

.padding-left-19,
.pl-19 {
  padding-left: 19px !important; }

.padding-horizontal-19,
.ph-19 {
  padding-left: 19px;
  padding-right: 19px; }

.padding-vertical-19,
.pv-19 {
  padding-top: 19px;
  padding-bottom: 19px; }

.padding-20,
.p-20 {
  padding: 20px !important; }

.padding-top-20,
.pt-20 {
  padding-top: 20px !important; }

.padding-right-20,
.pr-20 {
  padding-right: 20px !important; }

.padding-bottom-20,
.pb-20 {
  padding-bottom: 20px !important; }

.padding-left-20,
.pl-20 {
  padding-left: 20px !important; }

.padding-horizontal-20,
.ph-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding-vertical-20,
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.padding-21,
.p-21 {
  padding: 21px !important; }

.padding-top-21,
.pt-21 {
  padding-top: 21px !important; }

.padding-right-21,
.pr-21 {
  padding-right: 21px !important; }

.padding-bottom-21,
.pb-21 {
  padding-bottom: 21px !important; }

.padding-left-21,
.pl-21 {
  padding-left: 21px !important; }

.padding-horizontal-21,
.ph-21 {
  padding-left: 21px;
  padding-right: 21px; }

.padding-vertical-21,
.pv-21 {
  padding-top: 21px;
  padding-bottom: 21px; }

.padding-22,
.p-22 {
  padding: 22px !important; }

.padding-top-22,
.pt-22 {
  padding-top: 22px !important; }

.padding-right-22,
.pr-22 {
  padding-right: 22px !important; }

.padding-bottom-22,
.pb-22 {
  padding-bottom: 22px !important; }

.padding-left-22,
.pl-22 {
  padding-left: 22px !important; }

.padding-horizontal-22,
.ph-22 {
  padding-left: 22px;
  padding-right: 22px; }

.padding-vertical-22,
.pv-22 {
  padding-top: 22px;
  padding-bottom: 22px; }

.padding-23,
.p-23 {
  padding: 23px !important; }

.padding-top-23,
.pt-23 {
  padding-top: 23px !important; }

.padding-right-23,
.pr-23 {
  padding-right: 23px !important; }

.padding-bottom-23,
.pb-23 {
  padding-bottom: 23px !important; }

.padding-left-23,
.pl-23 {
  padding-left: 23px !important; }

.padding-horizontal-23,
.ph-23 {
  padding-left: 23px;
  padding-right: 23px; }

.padding-vertical-23,
.pv-23 {
  padding-top: 23px;
  padding-bottom: 23px; }

.padding-24,
.p-24 {
  padding: 24px !important; }

.padding-top-24,
.pt-24 {
  padding-top: 24px !important; }

.padding-right-24,
.pr-24 {
  padding-right: 24px !important; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px !important; }

.padding-left-24,
.pl-24 {
  padding-left: 24px !important; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-25,
.p-25 {
  padding: 25px !important; }

.padding-top-25,
.pt-25 {
  padding-top: 25px !important; }

.padding-right-25,
.pr-25 {
  padding-right: 25px !important; }

.padding-bottom-25,
.pb-25 {
  padding-bottom: 25px !important; }

.padding-left-25,
.pl-25 {
  padding-left: 25px !important; }

.padding-horizontal-25,
.ph-25 {
  padding-left: 25px;
  padding-right: 25px; }

.padding-vertical-25,
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.padding-26,
.p-26 {
  padding: 26px !important; }

.padding-top-26,
.pt-26 {
  padding-top: 26px !important; }

.padding-right-26,
.pr-26 {
  padding-right: 26px !important; }

.padding-bottom-26,
.pb-26 {
  padding-bottom: 26px !important; }

.padding-left-26,
.pl-26 {
  padding-left: 26px !important; }

.padding-horizontal-26,
.ph-26 {
  padding-left: 26px;
  padding-right: 26px; }

.padding-vertical-26,
.pv-26 {
  padding-top: 26px;
  padding-bottom: 26px; }

.padding-27,
.p-27 {
  padding: 27px !important; }

.padding-top-27,
.pt-27 {
  padding-top: 27px !important; }

.padding-right-27,
.pr-27 {
  padding-right: 27px !important; }

.padding-bottom-27,
.pb-27 {
  padding-bottom: 27px !important; }

.padding-left-27,
.pl-27 {
  padding-left: 27px !important; }

.padding-horizontal-27,
.ph-27 {
  padding-left: 27px;
  padding-right: 27px; }

.padding-vertical-27,
.pv-27 {
  padding-top: 27px;
  padding-bottom: 27px; }

.padding-28,
.p-28 {
  padding: 28px !important; }

.padding-top-28,
.pt-28 {
  padding-top: 28px !important; }

.padding-right-28,
.pr-28 {
  padding-right: 28px !important; }

.padding-bottom-28,
.pb-28 {
  padding-bottom: 28px !important; }

.padding-left-28,
.pl-28 {
  padding-left: 28px !important; }

.padding-horizontal-28,
.ph-28 {
  padding-left: 28px;
  padding-right: 28px; }

.padding-vertical-28,
.pv-28 {
  padding-top: 28px;
  padding-bottom: 28px; }

.padding-29,
.p-29 {
  padding: 29px !important; }

.padding-top-29,
.pt-29 {
  padding-top: 29px !important; }

.padding-right-29,
.pr-29 {
  padding-right: 29px !important; }

.padding-bottom-29,
.pb-29 {
  padding-bottom: 29px !important; }

.padding-left-29,
.pl-29 {
  padding-left: 29px !important; }

.padding-horizontal-29,
.ph-29 {
  padding-left: 29px;
  padding-right: 29px; }

.padding-vertical-29,
.pv-29 {
  padding-top: 29px;
  padding-bottom: 29px; }

.padding-30,
.p-30 {
  padding: 30px !important; }

.padding-top-30,
.pt-30 {
  padding-top: 30px !important; }

.padding-right-30,
.pr-30 {
  padding-right: 30px !important; }

.padding-bottom-30,
.pb-30 {
  padding-bottom: 30px !important; }

.padding-left-30,
.pl-30 {
  padding-left: 30px !important; }

.padding-horizontal-30,
.ph-30 {
  padding-left: 30px;
  padding-right: 30px; }

.padding-vertical-30,
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.padding-31,
.p-31 {
  padding: 31px !important; }

.padding-top-31,
.pt-31 {
  padding-top: 31px !important; }

.padding-right-31,
.pr-31 {
  padding-right: 31px !important; }

.padding-bottom-31,
.pb-31 {
  padding-bottom: 31px !important; }

.padding-left-31,
.pl-31 {
  padding-left: 31px !important; }

.padding-horizontal-31,
.ph-31 {
  padding-left: 31px;
  padding-right: 31px; }

.padding-vertical-31,
.pv-31 {
  padding-top: 31px;
  padding-bottom: 31px; }

.padding-32,
.p-32 {
  padding: 32px !important; }

.padding-top-32,
.pt-32 {
  padding-top: 32px !important; }

.padding-right-32,
.pr-32 {
  padding-right: 32px !important; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px !important; }

.padding-left-32,
.pl-32 {
  padding-left: 32px !important; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-33,
.p-33 {
  padding: 33px !important; }

.padding-top-33,
.pt-33 {
  padding-top: 33px !important; }

.padding-right-33,
.pr-33 {
  padding-right: 33px !important; }

.padding-bottom-33,
.pb-33 {
  padding-bottom: 33px !important; }

.padding-left-33,
.pl-33 {
  padding-left: 33px !important; }

.padding-horizontal-33,
.ph-33 {
  padding-left: 33px;
  padding-right: 33px; }

.padding-vertical-33,
.pv-33 {
  padding-top: 33px;
  padding-bottom: 33px; }

.padding-34,
.p-34 {
  padding: 34px !important; }

.padding-top-34,
.pt-34 {
  padding-top: 34px !important; }

.padding-right-34,
.pr-34 {
  padding-right: 34px !important; }

.padding-bottom-34,
.pb-34 {
  padding-bottom: 34px !important; }

.padding-left-34,
.pl-34 {
  padding-left: 34px !important; }

.padding-horizontal-34,
.ph-34 {
  padding-left: 34px;
  padding-right: 34px; }

.padding-vertical-34,
.pv-34 {
  padding-top: 34px;
  padding-bottom: 34px; }

.padding-35,
.p-35 {
  padding: 35px !important; }

.padding-top-35,
.pt-35 {
  padding-top: 35px !important; }

.padding-right-35,
.pr-35 {
  padding-right: 35px !important; }

.padding-bottom-35,
.pb-35 {
  padding-bottom: 35px !important; }

.padding-left-35,
.pl-35 {
  padding-left: 35px !important; }

.padding-horizontal-35,
.ph-35 {
  padding-left: 35px;
  padding-right: 35px; }

.padding-vertical-35,
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.padding-36,
.p-36 {
  padding: 36px !important; }

.padding-top-36,
.pt-36 {
  padding-top: 36px !important; }

.padding-right-36,
.pr-36 {
  padding-right: 36px !important; }

.padding-bottom-36,
.pb-36 {
  padding-bottom: 36px !important; }

.padding-left-36,
.pl-36 {
  padding-left: 36px !important; }

.padding-horizontal-36,
.ph-36 {
  padding-left: 36px;
  padding-right: 36px; }

.padding-vertical-36,
.pv-36 {
  padding-top: 36px;
  padding-bottom: 36px; }

.padding-37,
.p-37 {
  padding: 37px !important; }

.padding-top-37,
.pt-37 {
  padding-top: 37px !important; }

.padding-right-37,
.pr-37 {
  padding-right: 37px !important; }

.padding-bottom-37,
.pb-37 {
  padding-bottom: 37px !important; }

.padding-left-37,
.pl-37 {
  padding-left: 37px !important; }

.padding-horizontal-37,
.ph-37 {
  padding-left: 37px;
  padding-right: 37px; }

.padding-vertical-37,
.pv-37 {
  padding-top: 37px;
  padding-bottom: 37px; }

.padding-38,
.p-38 {
  padding: 38px !important; }

.padding-top-38,
.pt-38 {
  padding-top: 38px !important; }

.padding-right-38,
.pr-38 {
  padding-right: 38px !important; }

.padding-bottom-38,
.pb-38 {
  padding-bottom: 38px !important; }

.padding-left-38,
.pl-38 {
  padding-left: 38px !important; }

.padding-horizontal-38,
.ph-38 {
  padding-left: 38px;
  padding-right: 38px; }

.padding-vertical-38,
.pv-38 {
  padding-top: 38px;
  padding-bottom: 38px; }

.padding-39,
.p-39 {
  padding: 39px !important; }

.padding-top-39,
.pt-39 {
  padding-top: 39px !important; }

.padding-right-39,
.pr-39 {
  padding-right: 39px !important; }

.padding-bottom-39,
.pb-39 {
  padding-bottom: 39px !important; }

.padding-left-39,
.pl-39 {
  padding-left: 39px !important; }

.padding-horizontal-39,
.ph-39 {
  padding-left: 39px;
  padding-right: 39px; }

.padding-vertical-39,
.pv-39 {
  padding-top: 39px;
  padding-bottom: 39px; }

.padding-40,
.p-40 {
  padding: 40px !important; }

.padding-top-40,
.pt-40 {
  padding-top: 40px !important; }

.padding-right-40,
.pr-40 {
  padding-right: 40px !important; }

.padding-bottom-40,
.pb-40 {
  padding-bottom: 40px !important; }

.padding-left-40,
.pl-40 {
  padding-left: 40px !important; }

.padding-horizontal-40,
.ph-40 {
  padding-left: 40px;
  padding-right: 40px; }

.padding-vertical-40,
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.padding-41,
.p-41 {
  padding: 41px !important; }

.padding-top-41,
.pt-41 {
  padding-top: 41px !important; }

.padding-right-41,
.pr-41 {
  padding-right: 41px !important; }

.padding-bottom-41,
.pb-41 {
  padding-bottom: 41px !important; }

.padding-left-41,
.pl-41 {
  padding-left: 41px !important; }

.padding-horizontal-41,
.ph-41 {
  padding-left: 41px;
  padding-right: 41px; }

.padding-vertical-41,
.pv-41 {
  padding-top: 41px;
  padding-bottom: 41px; }

.padding-42,
.p-42 {
  padding: 42px !important; }

.padding-top-42,
.pt-42 {
  padding-top: 42px !important; }

.padding-right-42,
.pr-42 {
  padding-right: 42px !important; }

.padding-bottom-42,
.pb-42 {
  padding-bottom: 42px !important; }

.padding-left-42,
.pl-42 {
  padding-left: 42px !important; }

.padding-horizontal-42,
.ph-42 {
  padding-left: 42px;
  padding-right: 42px; }

.padding-vertical-42,
.pv-42 {
  padding-top: 42px;
  padding-bottom: 42px; }

.padding-43,
.p-43 {
  padding: 43px !important; }

.padding-top-43,
.pt-43 {
  padding-top: 43px !important; }

.padding-right-43,
.pr-43 {
  padding-right: 43px !important; }

.padding-bottom-43,
.pb-43 {
  padding-bottom: 43px !important; }

.padding-left-43,
.pl-43 {
  padding-left: 43px !important; }

.padding-horizontal-43,
.ph-43 {
  padding-left: 43px;
  padding-right: 43px; }

.padding-vertical-43,
.pv-43 {
  padding-top: 43px;
  padding-bottom: 43px; }

.padding-44,
.p-44 {
  padding: 44px !important; }

.padding-top-44,
.pt-44 {
  padding-top: 44px !important; }

.padding-right-44,
.pr-44 {
  padding-right: 44px !important; }

.padding-bottom-44,
.pb-44 {
  padding-bottom: 44px !important; }

.padding-left-44,
.pl-44 {
  padding-left: 44px !important; }

.padding-horizontal-44,
.ph-44 {
  padding-left: 44px;
  padding-right: 44px; }

.padding-vertical-44,
.pv-44 {
  padding-top: 44px;
  padding-bottom: 44px; }

.padding-45,
.p-45 {
  padding: 45px !important; }

.padding-top-45,
.pt-45 {
  padding-top: 45px !important; }

.padding-right-45,
.pr-45 {
  padding-right: 45px !important; }

.padding-bottom-45,
.pb-45 {
  padding-bottom: 45px !important; }

.padding-left-45,
.pl-45 {
  padding-left: 45px !important; }

.padding-horizontal-45,
.ph-45 {
  padding-left: 45px;
  padding-right: 45px; }

.padding-vertical-45,
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.padding-46,
.p-46 {
  padding: 46px !important; }

.padding-top-46,
.pt-46 {
  padding-top: 46px !important; }

.padding-right-46,
.pr-46 {
  padding-right: 46px !important; }

.padding-bottom-46,
.pb-46 {
  padding-bottom: 46px !important; }

.padding-left-46,
.pl-46 {
  padding-left: 46px !important; }

.padding-horizontal-46,
.ph-46 {
  padding-left: 46px;
  padding-right: 46px; }

.padding-vertical-46,
.pv-46 {
  padding-top: 46px;
  padding-bottom: 46px; }

.padding-47,
.p-47 {
  padding: 47px !important; }

.padding-top-47,
.pt-47 {
  padding-top: 47px !important; }

.padding-right-47,
.pr-47 {
  padding-right: 47px !important; }

.padding-bottom-47,
.pb-47 {
  padding-bottom: 47px !important; }

.padding-left-47,
.pl-47 {
  padding-left: 47px !important; }

.padding-horizontal-47,
.ph-47 {
  padding-left: 47px;
  padding-right: 47px; }

.padding-vertical-47,
.pv-47 {
  padding-top: 47px;
  padding-bottom: 47px; }

.padding-48,
.p-48 {
  padding: 48px !important; }

.padding-top-48,
.pt-48 {
  padding-top: 48px !important; }

.padding-right-48,
.pr-48 {
  padding-right: 48px !important; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px !important; }

.padding-left-48,
.pl-48 {
  padding-left: 48px !important; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-49,
.p-49 {
  padding: 49px !important; }

.padding-top-49,
.pt-49 {
  padding-top: 49px !important; }

.padding-right-49,
.pr-49 {
  padding-right: 49px !important; }

.padding-bottom-49,
.pb-49 {
  padding-bottom: 49px !important; }

.padding-left-49,
.pl-49 {
  padding-left: 49px !important; }

.padding-horizontal-49,
.ph-49 {
  padding-left: 49px;
  padding-right: 49px; }

.padding-vertical-49,
.pv-49 {
  padding-top: 49px;
  padding-bottom: 49px; }

.padding-50,
.p-50 {
  padding: 50px !important; }

.padding-top-50,
.pt-50 {
  padding-top: 50px !important; }

.padding-right-50,
.pr-50 {
  padding-right: 50px !important; }

.padding-bottom-50,
.pb-50 {
  padding-bottom: 50px !important; }

.padding-left-50,
.pl-50 {
  padding-left: 50px !important; }

.padding-horizontal-50,
.ph-50 {
  padding-left: 50px;
  padding-right: 50px; }

.padding-vertical-50,
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.padding-51,
.p-51 {
  padding: 51px !important; }

.padding-top-51,
.pt-51 {
  padding-top: 51px !important; }

.padding-right-51,
.pr-51 {
  padding-right: 51px !important; }

.padding-bottom-51,
.pb-51 {
  padding-bottom: 51px !important; }

.padding-left-51,
.pl-51 {
  padding-left: 51px !important; }

.padding-horizontal-51,
.ph-51 {
  padding-left: 51px;
  padding-right: 51px; }

.padding-vertical-51,
.pv-51 {
  padding-top: 51px;
  padding-bottom: 51px; }

.padding-52,
.p-52 {
  padding: 52px !important; }

.padding-top-52,
.pt-52 {
  padding-top: 52px !important; }

.padding-right-52,
.pr-52 {
  padding-right: 52px !important; }

.padding-bottom-52,
.pb-52 {
  padding-bottom: 52px !important; }

.padding-left-52,
.pl-52 {
  padding-left: 52px !important; }

.padding-horizontal-52,
.ph-52 {
  padding-left: 52px;
  padding-right: 52px; }

.padding-vertical-52,
.pv-52 {
  padding-top: 52px;
  padding-bottom: 52px; }

.padding-53,
.p-53 {
  padding: 53px !important; }

.padding-top-53,
.pt-53 {
  padding-top: 53px !important; }

.padding-right-53,
.pr-53 {
  padding-right: 53px !important; }

.padding-bottom-53,
.pb-53 {
  padding-bottom: 53px !important; }

.padding-left-53,
.pl-53 {
  padding-left: 53px !important; }

.padding-horizontal-53,
.ph-53 {
  padding-left: 53px;
  padding-right: 53px; }

.padding-vertical-53,
.pv-53 {
  padding-top: 53px;
  padding-bottom: 53px; }

.padding-54,
.p-54 {
  padding: 54px !important; }

.padding-top-54,
.pt-54 {
  padding-top: 54px !important; }

.padding-right-54,
.pr-54 {
  padding-right: 54px !important; }

.padding-bottom-54,
.pb-54 {
  padding-bottom: 54px !important; }

.padding-left-54,
.pl-54 {
  padding-left: 54px !important; }

.padding-horizontal-54,
.ph-54 {
  padding-left: 54px;
  padding-right: 54px; }

.padding-vertical-54,
.pv-54 {
  padding-top: 54px;
  padding-bottom: 54px; }

.padding-55,
.p-55 {
  padding: 55px !important; }

.padding-top-55,
.pt-55 {
  padding-top: 55px !important; }

.padding-right-55,
.pr-55 {
  padding-right: 55px !important; }

.padding-bottom-55,
.pb-55 {
  padding-bottom: 55px !important; }

.padding-left-55,
.pl-55 {
  padding-left: 55px !important; }

.padding-horizontal-55,
.ph-55 {
  padding-left: 55px;
  padding-right: 55px; }

.padding-vertical-55,
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.padding-56,
.p-56 {
  padding: 56px !important; }

.padding-top-56,
.pt-56 {
  padding-top: 56px !important; }

.padding-right-56,
.pr-56 {
  padding-right: 56px !important; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px !important; }

.padding-left-56,
.pl-56 {
  padding-left: 56px !important; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-57,
.p-57 {
  padding: 57px !important; }

.padding-top-57,
.pt-57 {
  padding-top: 57px !important; }

.padding-right-57,
.pr-57 {
  padding-right: 57px !important; }

.padding-bottom-57,
.pb-57 {
  padding-bottom: 57px !important; }

.padding-left-57,
.pl-57 {
  padding-left: 57px !important; }

.padding-horizontal-57,
.ph-57 {
  padding-left: 57px;
  padding-right: 57px; }

.padding-vertical-57,
.pv-57 {
  padding-top: 57px;
  padding-bottom: 57px; }

.padding-58,
.p-58 {
  padding: 58px !important; }

.padding-top-58,
.pt-58 {
  padding-top: 58px !important; }

.padding-right-58,
.pr-58 {
  padding-right: 58px !important; }

.padding-bottom-58,
.pb-58 {
  padding-bottom: 58px !important; }

.padding-left-58,
.pl-58 {
  padding-left: 58px !important; }

.padding-horizontal-58,
.ph-58 {
  padding-left: 58px;
  padding-right: 58px; }

.padding-vertical-58,
.pv-58 {
  padding-top: 58px;
  padding-bottom: 58px; }

.padding-59,
.p-59 {
  padding: 59px !important; }

.padding-top-59,
.pt-59 {
  padding-top: 59px !important; }

.padding-right-59,
.pr-59 {
  padding-right: 59px !important; }

.padding-bottom-59,
.pb-59 {
  padding-bottom: 59px !important; }

.padding-left-59,
.pl-59 {
  padding-left: 59px !important; }

.padding-horizontal-59,
.ph-59 {
  padding-left: 59px;
  padding-right: 59px; }

.padding-vertical-59,
.pv-59 {
  padding-top: 59px;
  padding-bottom: 59px; }

.padding-60,
.p-60 {
  padding: 60px !important; }

.padding-top-60,
.pt-60 {
  padding-top: 60px !important; }

.padding-right-60,
.pr-60 {
  padding-right: 60px !important; }

.padding-bottom-60,
.pb-60 {
  padding-bottom: 60px !important; }

.padding-left-60,
.pl-60 {
  padding-left: 60px !important; }

.padding-horizontal-60,
.ph-60 {
  padding-left: 60px;
  padding-right: 60px; }

.padding-vertical-60,
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding-61,
.p-61 {
  padding: 61px !important; }

.padding-top-61,
.pt-61 {
  padding-top: 61px !important; }

.padding-right-61,
.pr-61 {
  padding-right: 61px !important; }

.padding-bottom-61,
.pb-61 {
  padding-bottom: 61px !important; }

.padding-left-61,
.pl-61 {
  padding-left: 61px !important; }

.padding-horizontal-61,
.ph-61 {
  padding-left: 61px;
  padding-right: 61px; }

.padding-vertical-61,
.pv-61 {
  padding-top: 61px;
  padding-bottom: 61px; }

.padding-62,
.p-62 {
  padding: 62px !important; }

.padding-top-62,
.pt-62 {
  padding-top: 62px !important; }

.padding-right-62,
.pr-62 {
  padding-right: 62px !important; }

.padding-bottom-62,
.pb-62 {
  padding-bottom: 62px !important; }

.padding-left-62,
.pl-62 {
  padding-left: 62px !important; }

.padding-horizontal-62,
.ph-62 {
  padding-left: 62px;
  padding-right: 62px; }

.padding-vertical-62,
.pv-62 {
  padding-top: 62px;
  padding-bottom: 62px; }

.padding-63,
.p-63 {
  padding: 63px !important; }

.padding-top-63,
.pt-63 {
  padding-top: 63px !important; }

.padding-right-63,
.pr-63 {
  padding-right: 63px !important; }

.padding-bottom-63,
.pb-63 {
  padding-bottom: 63px !important; }

.padding-left-63,
.pl-63 {
  padding-left: 63px !important; }

.padding-horizontal-63,
.ph-63 {
  padding-left: 63px;
  padding-right: 63px; }

.padding-vertical-63,
.pv-63 {
  padding-top: 63px;
  padding-bottom: 63px; }

.padding-64,
.p-64 {
  padding: 64px !important; }

.padding-top-64,
.pt-64 {
  padding-top: 64px !important; }

.padding-right-64,
.pr-64 {
  padding-right: 64px !important; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px !important; }

.padding-left-64,
.pl-64 {
  padding-left: 64px !important; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-65,
.p-65 {
  padding: 65px !important; }

.padding-top-65,
.pt-65 {
  padding-top: 65px !important; }

.padding-right-65,
.pr-65 {
  padding-right: 65px !important; }

.padding-bottom-65,
.pb-65 {
  padding-bottom: 65px !important; }

.padding-left-65,
.pl-65 {
  padding-left: 65px !important; }

.padding-horizontal-65,
.ph-65 {
  padding-left: 65px;
  padding-right: 65px; }

.padding-vertical-65,
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.padding-66,
.p-66 {
  padding: 66px !important; }

.padding-top-66,
.pt-66 {
  padding-top: 66px !important; }

.padding-right-66,
.pr-66 {
  padding-right: 66px !important; }

.padding-bottom-66,
.pb-66 {
  padding-bottom: 66px !important; }

.padding-left-66,
.pl-66 {
  padding-left: 66px !important; }

.padding-horizontal-66,
.ph-66 {
  padding-left: 66px;
  padding-right: 66px; }

.padding-vertical-66,
.pv-66 {
  padding-top: 66px;
  padding-bottom: 66px; }

.padding-67,
.p-67 {
  padding: 67px !important; }

.padding-top-67,
.pt-67 {
  padding-top: 67px !important; }

.padding-right-67,
.pr-67 {
  padding-right: 67px !important; }

.padding-bottom-67,
.pb-67 {
  padding-bottom: 67px !important; }

.padding-left-67,
.pl-67 {
  padding-left: 67px !important; }

.padding-horizontal-67,
.ph-67 {
  padding-left: 67px;
  padding-right: 67px; }

.padding-vertical-67,
.pv-67 {
  padding-top: 67px;
  padding-bottom: 67px; }

.padding-68,
.p-68 {
  padding: 68px !important; }

.padding-top-68,
.pt-68 {
  padding-top: 68px !important; }

.padding-right-68,
.pr-68 {
  padding-right: 68px !important; }

.padding-bottom-68,
.pb-68 {
  padding-bottom: 68px !important; }

.padding-left-68,
.pl-68 {
  padding-left: 68px !important; }

.padding-horizontal-68,
.ph-68 {
  padding-left: 68px;
  padding-right: 68px; }

.padding-vertical-68,
.pv-68 {
  padding-top: 68px;
  padding-bottom: 68px; }

.padding-69,
.p-69 {
  padding: 69px !important; }

.padding-top-69,
.pt-69 {
  padding-top: 69px !important; }

.padding-right-69,
.pr-69 {
  padding-right: 69px !important; }

.padding-bottom-69,
.pb-69 {
  padding-bottom: 69px !important; }

.padding-left-69,
.pl-69 {
  padding-left: 69px !important; }

.padding-horizontal-69,
.ph-69 {
  padding-left: 69px;
  padding-right: 69px; }

.padding-vertical-69,
.pv-69 {
  padding-top: 69px;
  padding-bottom: 69px; }

.padding-70,
.p-70 {
  padding: 70px !important; }

.padding-top-70,
.pt-70 {
  padding-top: 70px !important; }

.padding-right-70,
.pr-70 {
  padding-right: 70px !important; }

.padding-bottom-70,
.pb-70 {
  padding-bottom: 70px !important; }

.padding-left-70,
.pl-70 {
  padding-left: 70px !important; }

.padding-horizontal-70,
.ph-70 {
  padding-left: 70px;
  padding-right: 70px; }

.padding-vertical-70,
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.padding-71,
.p-71 {
  padding: 71px !important; }

.padding-top-71,
.pt-71 {
  padding-top: 71px !important; }

.padding-right-71,
.pr-71 {
  padding-right: 71px !important; }

.padding-bottom-71,
.pb-71 {
  padding-bottom: 71px !important; }

.padding-left-71,
.pl-71 {
  padding-left: 71px !important; }

.padding-horizontal-71,
.ph-71 {
  padding-left: 71px;
  padding-right: 71px; }

.padding-vertical-71,
.pv-71 {
  padding-top: 71px;
  padding-bottom: 71px; }

.padding-72,
.p-72 {
  padding: 72px !important; }

.padding-top-72,
.pt-72 {
  padding-top: 72px !important; }

.padding-right-72,
.pr-72 {
  padding-right: 72px !important; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px !important; }

.padding-left-72,
.pl-72 {
  padding-left: 72px !important; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-73,
.p-73 {
  padding: 73px !important; }

.padding-top-73,
.pt-73 {
  padding-top: 73px !important; }

.padding-right-73,
.pr-73 {
  padding-right: 73px !important; }

.padding-bottom-73,
.pb-73 {
  padding-bottom: 73px !important; }

.padding-left-73,
.pl-73 {
  padding-left: 73px !important; }

.padding-horizontal-73,
.ph-73 {
  padding-left: 73px;
  padding-right: 73px; }

.padding-vertical-73,
.pv-73 {
  padding-top: 73px;
  padding-bottom: 73px; }

.padding-74,
.p-74 {
  padding: 74px !important; }

.padding-top-74,
.pt-74 {
  padding-top: 74px !important; }

.padding-right-74,
.pr-74 {
  padding-right: 74px !important; }

.padding-bottom-74,
.pb-74 {
  padding-bottom: 74px !important; }

.padding-left-74,
.pl-74 {
  padding-left: 74px !important; }

.padding-horizontal-74,
.ph-74 {
  padding-left: 74px;
  padding-right: 74px; }

.padding-vertical-74,
.pv-74 {
  padding-top: 74px;
  padding-bottom: 74px; }

.padding-75,
.p-75 {
  padding: 75px !important; }

.padding-top-75,
.pt-75 {
  padding-top: 75px !important; }

.padding-right-75,
.pr-75 {
  padding-right: 75px !important; }

.padding-bottom-75,
.pb-75 {
  padding-bottom: 75px !important; }

.padding-left-75,
.pl-75 {
  padding-left: 75px !important; }

.padding-horizontal-75,
.ph-75 {
  padding-left: 75px;
  padding-right: 75px; }

.padding-vertical-75,
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.padding-76,
.p-76 {
  padding: 76px !important; }

.padding-top-76,
.pt-76 {
  padding-top: 76px !important; }

.padding-right-76,
.pr-76 {
  padding-right: 76px !important; }

.padding-bottom-76,
.pb-76 {
  padding-bottom: 76px !important; }

.padding-left-76,
.pl-76 {
  padding-left: 76px !important; }

.padding-horizontal-76,
.ph-76 {
  padding-left: 76px;
  padding-right: 76px; }

.padding-vertical-76,
.pv-76 {
  padding-top: 76px;
  padding-bottom: 76px; }

.padding-77,
.p-77 {
  padding: 77px !important; }

.padding-top-77,
.pt-77 {
  padding-top: 77px !important; }

.padding-right-77,
.pr-77 {
  padding-right: 77px !important; }

.padding-bottom-77,
.pb-77 {
  padding-bottom: 77px !important; }

.padding-left-77,
.pl-77 {
  padding-left: 77px !important; }

.padding-horizontal-77,
.ph-77 {
  padding-left: 77px;
  padding-right: 77px; }

.padding-vertical-77,
.pv-77 {
  padding-top: 77px;
  padding-bottom: 77px; }

.padding-78,
.p-78 {
  padding: 78px !important; }

.padding-top-78,
.pt-78 {
  padding-top: 78px !important; }

.padding-right-78,
.pr-78 {
  padding-right: 78px !important; }

.padding-bottom-78,
.pb-78 {
  padding-bottom: 78px !important; }

.padding-left-78,
.pl-78 {
  padding-left: 78px !important; }

.padding-horizontal-78,
.ph-78 {
  padding-left: 78px;
  padding-right: 78px; }

.padding-vertical-78,
.pv-78 {
  padding-top: 78px;
  padding-bottom: 78px; }

.padding-79,
.p-79 {
  padding: 79px !important; }

.padding-top-79,
.pt-79 {
  padding-top: 79px !important; }

.padding-right-79,
.pr-79 {
  padding-right: 79px !important; }

.padding-bottom-79,
.pb-79 {
  padding-bottom: 79px !important; }

.padding-left-79,
.pl-79 {
  padding-left: 79px !important; }

.padding-horizontal-79,
.ph-79 {
  padding-left: 79px;
  padding-right: 79px; }

.padding-vertical-79,
.pv-79 {
  padding-top: 79px;
  padding-bottom: 79px; }

.padding-80,
.p-80 {
  padding: 80px !important; }

.padding-top-80,
.pt-80 {
  padding-top: 80px !important; }

.padding-right-80,
.pr-80 {
  padding-right: 80px !important; }

.padding-bottom-80,
.pb-80 {
  padding-bottom: 80px !important; }

.padding-left-80,
.pl-80 {
  padding-left: 80px !important; }

.padding-horizontal-80,
.ph-80 {
  padding-left: 80px;
  padding-right: 80px; }

.padding-vertical-80,
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding-81,
.p-81 {
  padding: 81px !important; }

.padding-top-81,
.pt-81 {
  padding-top: 81px !important; }

.padding-right-81,
.pr-81 {
  padding-right: 81px !important; }

.padding-bottom-81,
.pb-81 {
  padding-bottom: 81px !important; }

.padding-left-81,
.pl-81 {
  padding-left: 81px !important; }

.padding-horizontal-81,
.ph-81 {
  padding-left: 81px;
  padding-right: 81px; }

.padding-vertical-81,
.pv-81 {
  padding-top: 81px;
  padding-bottom: 81px; }

.padding-82,
.p-82 {
  padding: 82px !important; }

.padding-top-82,
.pt-82 {
  padding-top: 82px !important; }

.padding-right-82,
.pr-82 {
  padding-right: 82px !important; }

.padding-bottom-82,
.pb-82 {
  padding-bottom: 82px !important; }

.padding-left-82,
.pl-82 {
  padding-left: 82px !important; }

.padding-horizontal-82,
.ph-82 {
  padding-left: 82px;
  padding-right: 82px; }

.padding-vertical-82,
.pv-82 {
  padding-top: 82px;
  padding-bottom: 82px; }

.padding-83,
.p-83 {
  padding: 83px !important; }

.padding-top-83,
.pt-83 {
  padding-top: 83px !important; }

.padding-right-83,
.pr-83 {
  padding-right: 83px !important; }

.padding-bottom-83,
.pb-83 {
  padding-bottom: 83px !important; }

.padding-left-83,
.pl-83 {
  padding-left: 83px !important; }

.padding-horizontal-83,
.ph-83 {
  padding-left: 83px;
  padding-right: 83px; }

.padding-vertical-83,
.pv-83 {
  padding-top: 83px;
  padding-bottom: 83px; }

.padding-84,
.p-84 {
  padding: 84px !important; }

.padding-top-84,
.pt-84 {
  padding-top: 84px !important; }

.padding-right-84,
.pr-84 {
  padding-right: 84px !important; }

.padding-bottom-84,
.pb-84 {
  padding-bottom: 84px !important; }

.padding-left-84,
.pl-84 {
  padding-left: 84px !important; }

.padding-horizontal-84,
.ph-84 {
  padding-left: 84px;
  padding-right: 84px; }

.padding-vertical-84,
.pv-84 {
  padding-top: 84px;
  padding-bottom: 84px; }

.padding-85,
.p-85 {
  padding: 85px !important; }

.padding-top-85,
.pt-85 {
  padding-top: 85px !important; }

.padding-right-85,
.pr-85 {
  padding-right: 85px !important; }

.padding-bottom-85,
.pb-85 {
  padding-bottom: 85px !important; }

.padding-left-85,
.pl-85 {
  padding-left: 85px !important; }

.padding-horizontal-85,
.ph-85 {
  padding-left: 85px;
  padding-right: 85px; }

.padding-vertical-85,
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.padding-86,
.p-86 {
  padding: 86px !important; }

.padding-top-86,
.pt-86 {
  padding-top: 86px !important; }

.padding-right-86,
.pr-86 {
  padding-right: 86px !important; }

.padding-bottom-86,
.pb-86 {
  padding-bottom: 86px !important; }

.padding-left-86,
.pl-86 {
  padding-left: 86px !important; }

.padding-horizontal-86,
.ph-86 {
  padding-left: 86px;
  padding-right: 86px; }

.padding-vertical-86,
.pv-86 {
  padding-top: 86px;
  padding-bottom: 86px; }

.padding-87,
.p-87 {
  padding: 87px !important; }

.padding-top-87,
.pt-87 {
  padding-top: 87px !important; }

.padding-right-87,
.pr-87 {
  padding-right: 87px !important; }

.padding-bottom-87,
.pb-87 {
  padding-bottom: 87px !important; }

.padding-left-87,
.pl-87 {
  padding-left: 87px !important; }

.padding-horizontal-87,
.ph-87 {
  padding-left: 87px;
  padding-right: 87px; }

.padding-vertical-87,
.pv-87 {
  padding-top: 87px;
  padding-bottom: 87px; }

.padding-88,
.p-88 {
  padding: 88px !important; }

.padding-top-88,
.pt-88 {
  padding-top: 88px !important; }

.padding-right-88,
.pr-88 {
  padding-right: 88px !important; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px !important; }

.padding-left-88,
.pl-88 {
  padding-left: 88px !important; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-89,
.p-89 {
  padding: 89px !important; }

.padding-top-89,
.pt-89 {
  padding-top: 89px !important; }

.padding-right-89,
.pr-89 {
  padding-right: 89px !important; }

.padding-bottom-89,
.pb-89 {
  padding-bottom: 89px !important; }

.padding-left-89,
.pl-89 {
  padding-left: 89px !important; }

.padding-horizontal-89,
.ph-89 {
  padding-left: 89px;
  padding-right: 89px; }

.padding-vertical-89,
.pv-89 {
  padding-top: 89px;
  padding-bottom: 89px; }

.padding-90,
.p-90 {
  padding: 90px !important; }

.padding-top-90,
.pt-90 {
  padding-top: 90px !important; }

.padding-right-90,
.pr-90 {
  padding-right: 90px !important; }

.padding-bottom-90,
.pb-90 {
  padding-bottom: 90px !important; }

.padding-left-90,
.pl-90 {
  padding-left: 90px !important; }

.padding-horizontal-90,
.ph-90 {
  padding-left: 90px;
  padding-right: 90px; }

.padding-vertical-90,
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.padding-91,
.p-91 {
  padding: 91px !important; }

.padding-top-91,
.pt-91 {
  padding-top: 91px !important; }

.padding-right-91,
.pr-91 {
  padding-right: 91px !important; }

.padding-bottom-91,
.pb-91 {
  padding-bottom: 91px !important; }

.padding-left-91,
.pl-91 {
  padding-left: 91px !important; }

.padding-horizontal-91,
.ph-91 {
  padding-left: 91px;
  padding-right: 91px; }

.padding-vertical-91,
.pv-91 {
  padding-top: 91px;
  padding-bottom: 91px; }

.padding-92,
.p-92 {
  padding: 92px !important; }

.padding-top-92,
.pt-92 {
  padding-top: 92px !important; }

.padding-right-92,
.pr-92 {
  padding-right: 92px !important; }

.padding-bottom-92,
.pb-92 {
  padding-bottom: 92px !important; }

.padding-left-92,
.pl-92 {
  padding-left: 92px !important; }

.padding-horizontal-92,
.ph-92 {
  padding-left: 92px;
  padding-right: 92px; }

.padding-vertical-92,
.pv-92 {
  padding-top: 92px;
  padding-bottom: 92px; }

.padding-93,
.p-93 {
  padding: 93px !important; }

.padding-top-93,
.pt-93 {
  padding-top: 93px !important; }

.padding-right-93,
.pr-93 {
  padding-right: 93px !important; }

.padding-bottom-93,
.pb-93 {
  padding-bottom: 93px !important; }

.padding-left-93,
.pl-93 {
  padding-left: 93px !important; }

.padding-horizontal-93,
.ph-93 {
  padding-left: 93px;
  padding-right: 93px; }

.padding-vertical-93,
.pv-93 {
  padding-top: 93px;
  padding-bottom: 93px; }

.padding-94,
.p-94 {
  padding: 94px !important; }

.padding-top-94,
.pt-94 {
  padding-top: 94px !important; }

.padding-right-94,
.pr-94 {
  padding-right: 94px !important; }

.padding-bottom-94,
.pb-94 {
  padding-bottom: 94px !important; }

.padding-left-94,
.pl-94 {
  padding-left: 94px !important; }

.padding-horizontal-94,
.ph-94 {
  padding-left: 94px;
  padding-right: 94px; }

.padding-vertical-94,
.pv-94 {
  padding-top: 94px;
  padding-bottom: 94px; }

.padding-95,
.p-95 {
  padding: 95px !important; }

.padding-top-95,
.pt-95 {
  padding-top: 95px !important; }

.padding-right-95,
.pr-95 {
  padding-right: 95px !important; }

.padding-bottom-95,
.pb-95 {
  padding-bottom: 95px !important; }

.padding-left-95,
.pl-95 {
  padding-left: 95px !important; }

.padding-horizontal-95,
.ph-95 {
  padding-left: 95px;
  padding-right: 95px; }

.padding-vertical-95,
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.padding-96,
.p-96 {
  padding: 96px !important; }

.padding-top-96,
.pt-96 {
  padding-top: 96px !important; }

.padding-right-96,
.pr-96 {
  padding-right: 96px !important; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px !important; }

.padding-left-96,
.pl-96 {
  padding-left: 96px !important; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.padding-97,
.p-97 {
  padding: 97px !important; }

.padding-top-97,
.pt-97 {
  padding-top: 97px !important; }

.padding-right-97,
.pr-97 {
  padding-right: 97px !important; }

.padding-bottom-97,
.pb-97 {
  padding-bottom: 97px !important; }

.padding-left-97,
.pl-97 {
  padding-left: 97px !important; }

.padding-horizontal-97,
.ph-97 {
  padding-left: 97px;
  padding-right: 97px; }

.padding-vertical-97,
.pv-97 {
  padding-top: 97px;
  padding-bottom: 97px; }

.padding-98,
.p-98 {
  padding: 98px !important; }

.padding-top-98,
.pt-98 {
  padding-top: 98px !important; }

.padding-right-98,
.pr-98 {
  padding-right: 98px !important; }

.padding-bottom-98,
.pb-98 {
  padding-bottom: 98px !important; }

.padding-left-98,
.pl-98 {
  padding-left: 98px !important; }

.padding-horizontal-98,
.ph-98 {
  padding-left: 98px;
  padding-right: 98px; }

.padding-vertical-98,
.pv-98 {
  padding-top: 98px;
  padding-bottom: 98px; }

.padding-99,
.p-99 {
  padding: 99px !important; }

.padding-top-99,
.pt-99 {
  padding-top: 99px !important; }

.padding-right-99,
.pr-99 {
  padding-right: 99px !important; }

.padding-bottom-99,
.pb-99 {
  padding-bottom: 99px !important; }

.padding-left-99,
.pl-99 {
  padding-left: 99px !important; }

.padding-horizontal-99,
.ph-99 {
  padding-left: 99px;
  padding-right: 99px; }

.padding-vertical-99,
.pv-99 {
  padding-top: 99px;
  padding-bottom: 99px; }

.padding-100,
.p-100 {
  padding: 100px !important; }

.padding-top-100,
.pt-100 {
  padding-top: 100px !important; }

.padding-right-100,
.pr-100 {
  padding-right: 100px !important; }

.padding-bottom-100,
.pb-100 {
  padding-bottom: 100px !important; }

.padding-left-100,
.pl-100 {
  padding-left: 100px !important; }

.padding-horizontal-100,
.ph-100 {
  padding-left: 100px;
  padding-right: 100px; }

.padding-vertical-100,
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.padding-8,
.p-8 {
  padding: 8px !important; }

.padding-top-8,
.pt-8 {
  padding-top: 8px !important; }

.padding-right-8,
.pr-8 {
  padding-right: 8px !important; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px !important; }

.padding-left-8,
.pl-8 {
  padding-left: 8px !important; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-16,
.p-16 {
  padding: 16px !important; }

.padding-top-16,
.pt-16 {
  padding-top: 16px !important; }

.padding-right-16,
.pr-16 {
  padding-right: 16px !important; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px !important; }

.padding-left-16,
.pl-16 {
  padding-left: 16px !important; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-24,
.p-24 {
  padding: 24px !important; }

.padding-top-24,
.pt-24 {
  padding-top: 24px !important; }

.padding-right-24,
.pr-24 {
  padding-right: 24px !important; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px !important; }

.padding-left-24,
.pl-24 {
  padding-left: 24px !important; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-32,
.p-32 {
  padding: 32px !important; }

.padding-top-32,
.pt-32 {
  padding-top: 32px !important; }

.padding-right-32,
.pr-32 {
  padding-right: 32px !important; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px !important; }

.padding-left-32,
.pl-32 {
  padding-left: 32px !important; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-48,
.p-48 {
  padding: 48px !important; }

.padding-top-48,
.pt-48 {
  padding-top: 48px !important; }

.padding-right-48,
.pr-48 {
  padding-right: 48px !important; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px !important; }

.padding-left-48,
.pl-48 {
  padding-left: 48px !important; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-56,
.p-56 {
  padding: 56px !important; }

.padding-top-56,
.pt-56 {
  padding-top: 56px !important; }

.padding-right-56,
.pr-56 {
  padding-right: 56px !important; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px !important; }

.padding-left-56,
.pl-56 {
  padding-left: 56px !important; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-64,
.p-64 {
  padding: 64px !important; }

.padding-top-64,
.pt-64 {
  padding-top: 64px !important; }

.padding-right-64,
.pr-64 {
  padding-right: 64px !important; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px !important; }

.padding-left-64,
.pl-64 {
  padding-left: 64px !important; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-72,
.p-72 {
  padding: 72px !important; }

.padding-top-72,
.pt-72 {
  padding-top: 72px !important; }

.padding-right-72,
.pr-72 {
  padding-right: 72px !important; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px !important; }

.padding-left-72,
.pl-72 {
  padding-left: 72px !important; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-88,
.p-88 {
  padding: 88px !important; }

.padding-top-88,
.pt-88 {
  padding-top: 88px !important; }

.padding-right-88,
.pr-88 {
  padding-right: 88px !important; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px !important; }

.padding-left-88,
.pl-88 {
  padding-left: 88px !important; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-96,
.p-96 {
  padding: 96px !important; }

.padding-top-96,
.pt-96 {
  padding-top: 96px !important; }

.padding-right-96,
.pr-96 {
  padding-right: 96px !important; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px !important; }

.padding-left-96,
.pl-96 {
  padding-left: 96px !important; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.margin-0,
.m-0 {
  margin: 0px !important; }

.margin-top-0,
.mt-0 {
  margin-top: 0px !important; }

.margin-right-0,
.mr-0 {
  margin-right: 0px !important; }

.margin-bottom-0,
.mb-0 {
  margin-bottom: 0px !important; }

.margin-left-0,
.ml-0 {
  margin-left: 0px !important; }

.margin-horizontal-0,
.mh-0 {
  margin-left: 0px;
  margin-right: 0px; }

.margin-vertical-0,
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.margin-5,
.m-5 {
  margin: 5px !important; }

.margin-top-5,
.mt-5 {
  margin-top: 5px !important; }

.margin-right-5,
.mr-5 {
  margin-right: 5px !important; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px !important; }

.margin-left-5,
.ml-5 {
  margin-left: 5px !important; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-10,
.m-10 {
  margin: 10px !important; }

.margin-top-10,
.mt-10 {
  margin-top: 10px !important; }

.margin-right-10,
.mr-10 {
  margin-right: 10px !important; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px !important; }

.margin-left-10,
.ml-10 {
  margin-left: 10px !important; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-15,
.m-15 {
  margin: 15px !important; }

.margin-top-15,
.mt-15 {
  margin-top: 15px !important; }

.margin-right-15,
.mr-15 {
  margin-right: 15px !important; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px !important; }

.margin-left-15,
.ml-15 {
  margin-left: 15px !important; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-20,
.m-20 {
  margin: 20px !important; }

.margin-top-20,
.mt-20 {
  margin-top: 20px !important; }

.margin-right-20,
.mr-20 {
  margin-right: 20px !important; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px !important; }

.margin-left-20,
.ml-20 {
  margin-left: 20px !important; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-25,
.m-25 {
  margin: 25px !important; }

.margin-top-25,
.mt-25 {
  margin-top: 25px !important; }

.margin-right-25,
.mr-25 {
  margin-right: 25px !important; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px !important; }

.margin-left-25,
.ml-25 {
  margin-left: 25px !important; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-30,
.m-30 {
  margin: 30px !important; }

.margin-top-30,
.mt-30 {
  margin-top: 30px !important; }

.margin-right-30,
.mr-30 {
  margin-right: 30px !important; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px !important; }

.margin-left-30,
.ml-30 {
  margin-left: 30px !important; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-35,
.m-35 {
  margin: 35px !important; }

.margin-top-35,
.mt-35 {
  margin-top: 35px !important; }

.margin-right-35,
.mr-35 {
  margin-right: 35px !important; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px !important; }

.margin-left-35,
.ml-35 {
  margin-left: 35px !important; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-40,
.m-40 {
  margin: 40px !important; }

.margin-top-40,
.mt-40 {
  margin-top: 40px !important; }

.margin-right-40,
.mr-40 {
  margin-right: 40px !important; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px !important; }

.margin-left-40,
.ml-40 {
  margin-left: 40px !important; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-45,
.m-45 {
  margin: 45px !important; }

.margin-top-45,
.mt-45 {
  margin-top: 45px !important; }

.margin-right-45,
.mr-45 {
  margin-right: 45px !important; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px !important; }

.margin-left-45,
.ml-45 {
  margin-left: 45px !important; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-50,
.m-50 {
  margin: 50px !important; }

.margin-top-50,
.mt-50 {
  margin-top: 50px !important; }

.margin-right-50,
.mr-50 {
  margin-right: 50px !important; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px !important; }

.margin-left-50,
.ml-50 {
  margin-left: 50px !important; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-55,
.m-55 {
  margin: 55px !important; }

.margin-top-55,
.mt-55 {
  margin-top: 55px !important; }

.margin-right-55,
.mr-55 {
  margin-right: 55px !important; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px !important; }

.margin-left-55,
.ml-55 {
  margin-left: 55px !important; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-60,
.m-60 {
  margin: 60px !important; }

.margin-top-60,
.mt-60 {
  margin-top: 60px !important; }

.margin-right-60,
.mr-60 {
  margin-right: 60px !important; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px !important; }

.margin-left-60,
.ml-60 {
  margin-left: 60px !important; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-65,
.m-65 {
  margin: 65px !important; }

.margin-top-65,
.mt-65 {
  margin-top: 65px !important; }

.margin-right-65,
.mr-65 {
  margin-right: 65px !important; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px !important; }

.margin-left-65,
.ml-65 {
  margin-left: 65px !important; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-70,
.m-70 {
  margin: 70px !important; }

.margin-top-70,
.mt-70 {
  margin-top: 70px !important; }

.margin-right-70,
.mr-70 {
  margin-right: 70px !important; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px !important; }

.margin-left-70,
.ml-70 {
  margin-left: 70px !important; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-75,
.m-75 {
  margin: 75px !important; }

.margin-top-75,
.mt-75 {
  margin-top: 75px !important; }

.margin-right-75,
.mr-75 {
  margin-right: 75px !important; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px !important; }

.margin-left-75,
.ml-75 {
  margin-left: 75px !important; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-80,
.m-80 {
  margin: 80px !important; }

.margin-top-80,
.mt-80 {
  margin-top: 80px !important; }

.margin-right-80,
.mr-80 {
  margin-right: 80px !important; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px !important; }

.margin-left-80,
.ml-80 {
  margin-left: 80px !important; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-85,
.m-85 {
  margin: 85px !important; }

.margin-top-85,
.mt-85 {
  margin-top: 85px !important; }

.margin-right-85,
.mr-85 {
  margin-right: 85px !important; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px !important; }

.margin-left-85,
.ml-85 {
  margin-left: 85px !important; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-90,
.m-90 {
  margin: 90px !important; }

.margin-top-90,
.mt-90 {
  margin-top: 90px !important; }

.margin-right-90,
.mr-90 {
  margin-right: 90px !important; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px !important; }

.margin-left-90,
.ml-90 {
  margin-left: 90px !important; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-95,
.m-95 {
  margin: 95px !important; }

.margin-top-95,
.mt-95 {
  margin-top: 95px !important; }

.margin-right-95,
.mr-95 {
  margin-right: 95px !important; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px !important; }

.margin-left-95,
.ml-95 {
  margin-left: 95px !important; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-100,
.m-100 {
  margin: 100px !important; }

.margin-top-100,
.mt-100 {
  margin-top: 100px !important; }

.margin-right-100,
.mr-100 {
  margin-right: 100px !important; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px !important; }

.margin-left-100,
.ml-100 {
  margin-left: 100px !important; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.margin-1,
.m-1 {
  margin: 1px !important; }

.margin-top-1,
.mt-1 {
  margin-top: 1px !important; }

.margin-right-1,
.mr-1 {
  margin-right: 1px !important; }

.margin-bottom-1,
.mb-1 {
  margin-bottom: 1px !important; }

.margin-left-1,
.ml-1 {
  margin-left: 1px !important; }

.margin-horizontal-1,
.mh-1 {
  margin-left: 1px;
  margin-right: 1px; }

.margin-vertical-1,
.mv-1 {
  margin-top: 1px;
  margin-bottom: 1px; }

.margin-2,
.m-2 {
  margin: 2px !important; }

.margin-top-2,
.mt-2 {
  margin-top: 2px !important; }

.margin-right-2,
.mr-2 {
  margin-right: 2px !important; }

.margin-bottom-2,
.mb-2 {
  margin-bottom: 2px !important; }

.margin-left-2,
.ml-2 {
  margin-left: 2px !important; }

.margin-horizontal-2,
.mh-2 {
  margin-left: 2px;
  margin-right: 2px; }

.margin-vertical-2,
.mv-2 {
  margin-top: 2px;
  margin-bottom: 2px; }

.margin-3,
.m-3 {
  margin: 3px !important; }

.margin-top-3,
.mt-3 {
  margin-top: 3px !important; }

.margin-right-3,
.mr-3 {
  margin-right: 3px !important; }

.margin-bottom-3,
.mb-3 {
  margin-bottom: 3px !important; }

.margin-left-3,
.ml-3 {
  margin-left: 3px !important; }

.margin-horizontal-3,
.mh-3 {
  margin-left: 3px;
  margin-right: 3px; }

.margin-vertical-3,
.mv-3 {
  margin-top: 3px;
  margin-bottom: 3px; }

.margin-4,
.m-4 {
  margin: 4px !important; }

.margin-top-4,
.mt-4 {
  margin-top: 4px !important; }

.margin-right-4,
.mr-4 {
  margin-right: 4px !important; }

.margin-bottom-4,
.mb-4 {
  margin-bottom: 4px !important; }

.margin-left-4,
.ml-4 {
  margin-left: 4px !important; }

.margin-horizontal-4,
.mh-4 {
  margin-left: 4px;
  margin-right: 4px; }

.margin-vertical-4,
.mv-4 {
  margin-top: 4px;
  margin-bottom: 4px; }

.margin-5,
.m-5 {
  margin: 5px !important; }

.margin-top-5,
.mt-5 {
  margin-top: 5px !important; }

.margin-right-5,
.mr-5 {
  margin-right: 5px !important; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px !important; }

.margin-left-5,
.ml-5 {
  margin-left: 5px !important; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-6,
.m-6 {
  margin: 6px !important; }

.margin-top-6,
.mt-6 {
  margin-top: 6px !important; }

.margin-right-6,
.mr-6 {
  margin-right: 6px !important; }

.margin-bottom-6,
.mb-6 {
  margin-bottom: 6px !important; }

.margin-left-6,
.ml-6 {
  margin-left: 6px !important; }

.margin-horizontal-6,
.mh-6 {
  margin-left: 6px;
  margin-right: 6px; }

.margin-vertical-6,
.mv-6 {
  margin-top: 6px;
  margin-bottom: 6px; }

.margin-7,
.m-7 {
  margin: 7px !important; }

.margin-top-7,
.mt-7 {
  margin-top: 7px !important; }

.margin-right-7,
.mr-7 {
  margin-right: 7px !important; }

.margin-bottom-7,
.mb-7 {
  margin-bottom: 7px !important; }

.margin-left-7,
.ml-7 {
  margin-left: 7px !important; }

.margin-horizontal-7,
.mh-7 {
  margin-left: 7px;
  margin-right: 7px; }

.margin-vertical-7,
.mv-7 {
  margin-top: 7px;
  margin-bottom: 7px; }

.margin-8,
.m-8 {
  margin: 8px !important; }

.margin-top-8,
.mt-8 {
  margin-top: 8px !important; }

.margin-right-8,
.mr-8 {
  margin-right: 8px !important; }

.margin-bottom-8,
.mb-8 {
  margin-bottom: 8px !important; }

.margin-left-8,
.ml-8 {
  margin-left: 8px !important; }

.margin-horizontal-8,
.mh-8 {
  margin-left: 8px;
  margin-right: 8px; }

.margin-vertical-8,
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px; }

.margin-9,
.m-9 {
  margin: 9px !important; }

.margin-top-9,
.mt-9 {
  margin-top: 9px !important; }

.margin-right-9,
.mr-9 {
  margin-right: 9px !important; }

.margin-bottom-9,
.mb-9 {
  margin-bottom: 9px !important; }

.margin-left-9,
.ml-9 {
  margin-left: 9px !important; }

.margin-horizontal-9,
.mh-9 {
  margin-left: 9px;
  margin-right: 9px; }

.margin-vertical-9,
.mv-9 {
  margin-top: 9px;
  margin-bottom: 9px; }

.margin-10,
.m-10 {
  margin: 10px !important; }

.margin-top-10,
.mt-10 {
  margin-top: 10px !important; }

.margin-right-10,
.mr-10 {
  margin-right: 10px !important; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px !important; }

.margin-left-10,
.ml-10 {
  margin-left: 10px !important; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-11,
.m-11 {
  margin: 11px !important; }

.margin-top-11,
.mt-11 {
  margin-top: 11px !important; }

.margin-right-11,
.mr-11 {
  margin-right: 11px !important; }

.margin-bottom-11,
.mb-11 {
  margin-bottom: 11px !important; }

.margin-left-11,
.ml-11 {
  margin-left: 11px !important; }

.margin-horizontal-11,
.mh-11 {
  margin-left: 11px;
  margin-right: 11px; }

.margin-vertical-11,
.mv-11 {
  margin-top: 11px;
  margin-bottom: 11px; }

.margin-12,
.m-12 {
  margin: 12px !important; }

.margin-top-12,
.mt-12 {
  margin-top: 12px !important; }

.margin-right-12,
.mr-12 {
  margin-right: 12px !important; }

.margin-bottom-12,
.mb-12 {
  margin-bottom: 12px !important; }

.margin-left-12,
.ml-12 {
  margin-left: 12px !important; }

.margin-horizontal-12,
.mh-12 {
  margin-left: 12px;
  margin-right: 12px; }

.margin-vertical-12,
.mv-12 {
  margin-top: 12px;
  margin-bottom: 12px; }

.margin-13,
.m-13 {
  margin: 13px !important; }

.margin-top-13,
.mt-13 {
  margin-top: 13px !important; }

.margin-right-13,
.mr-13 {
  margin-right: 13px !important; }

.margin-bottom-13,
.mb-13 {
  margin-bottom: 13px !important; }

.margin-left-13,
.ml-13 {
  margin-left: 13px !important; }

.margin-horizontal-13,
.mh-13 {
  margin-left: 13px;
  margin-right: 13px; }

.margin-vertical-13,
.mv-13 {
  margin-top: 13px;
  margin-bottom: 13px; }

.margin-14,
.m-14 {
  margin: 14px !important; }

.margin-top-14,
.mt-14 {
  margin-top: 14px !important; }

.margin-right-14,
.mr-14 {
  margin-right: 14px !important; }

.margin-bottom-14,
.mb-14 {
  margin-bottom: 14px !important; }

.margin-left-14,
.ml-14 {
  margin-left: 14px !important; }

.margin-horizontal-14,
.mh-14 {
  margin-left: 14px;
  margin-right: 14px; }

.margin-vertical-14,
.mv-14 {
  margin-top: 14px;
  margin-bottom: 14px; }

.margin-15,
.m-15 {
  margin: 15px !important; }

.margin-top-15,
.mt-15 {
  margin-top: 15px !important; }

.margin-right-15,
.mr-15 {
  margin-right: 15px !important; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px !important; }

.margin-left-15,
.ml-15 {
  margin-left: 15px !important; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-16,
.m-16 {
  margin: 16px !important; }

.margin-top-16,
.mt-16 {
  margin-top: 16px !important; }

.margin-right-16,
.mr-16 {
  margin-right: 16px !important; }

.margin-bottom-16,
.mb-16 {
  margin-bottom: 16px !important; }

.margin-left-16,
.ml-16 {
  margin-left: 16px !important; }

.margin-horizontal-16,
.mh-16 {
  margin-left: 16px;
  margin-right: 16px; }

.margin-vertical-16,
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px; }

.margin-17,
.m-17 {
  margin: 17px !important; }

.margin-top-17,
.mt-17 {
  margin-top: 17px !important; }

.margin-right-17,
.mr-17 {
  margin-right: 17px !important; }

.margin-bottom-17,
.mb-17 {
  margin-bottom: 17px !important; }

.margin-left-17,
.ml-17 {
  margin-left: 17px !important; }

.margin-horizontal-17,
.mh-17 {
  margin-left: 17px;
  margin-right: 17px; }

.margin-vertical-17,
.mv-17 {
  margin-top: 17px;
  margin-bottom: 17px; }

.margin-18,
.m-18 {
  margin: 18px !important; }

.margin-top-18,
.mt-18 {
  margin-top: 18px !important; }

.margin-right-18,
.mr-18 {
  margin-right: 18px !important; }

.margin-bottom-18,
.mb-18 {
  margin-bottom: 18px !important; }

.margin-left-18,
.ml-18 {
  margin-left: 18px !important; }

.margin-horizontal-18,
.mh-18 {
  margin-left: 18px;
  margin-right: 18px; }

.margin-vertical-18,
.mv-18 {
  margin-top: 18px;
  margin-bottom: 18px; }

.margin-19,
.m-19 {
  margin: 19px !important; }

.margin-top-19,
.mt-19 {
  margin-top: 19px !important; }

.margin-right-19,
.mr-19 {
  margin-right: 19px !important; }

.margin-bottom-19,
.mb-19 {
  margin-bottom: 19px !important; }

.margin-left-19,
.ml-19 {
  margin-left: 19px !important; }

.margin-horizontal-19,
.mh-19 {
  margin-left: 19px;
  margin-right: 19px; }

.margin-vertical-19,
.mv-19 {
  margin-top: 19px;
  margin-bottom: 19px; }

.margin-20,
.m-20 {
  margin: 20px !important; }

.margin-top-20,
.mt-20 {
  margin-top: 20px !important; }

.margin-right-20,
.mr-20 {
  margin-right: 20px !important; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px !important; }

.margin-left-20,
.ml-20 {
  margin-left: 20px !important; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-21,
.m-21 {
  margin: 21px !important; }

.margin-top-21,
.mt-21 {
  margin-top: 21px !important; }

.margin-right-21,
.mr-21 {
  margin-right: 21px !important; }

.margin-bottom-21,
.mb-21 {
  margin-bottom: 21px !important; }

.margin-left-21,
.ml-21 {
  margin-left: 21px !important; }

.margin-horizontal-21,
.mh-21 {
  margin-left: 21px;
  margin-right: 21px; }

.margin-vertical-21,
.mv-21 {
  margin-top: 21px;
  margin-bottom: 21px; }

.margin-22,
.m-22 {
  margin: 22px !important; }

.margin-top-22,
.mt-22 {
  margin-top: 22px !important; }

.margin-right-22,
.mr-22 {
  margin-right: 22px !important; }

.margin-bottom-22,
.mb-22 {
  margin-bottom: 22px !important; }

.margin-left-22,
.ml-22 {
  margin-left: 22px !important; }

.margin-horizontal-22,
.mh-22 {
  margin-left: 22px;
  margin-right: 22px; }

.margin-vertical-22,
.mv-22 {
  margin-top: 22px;
  margin-bottom: 22px; }

.margin-23,
.m-23 {
  margin: 23px !important; }

.margin-top-23,
.mt-23 {
  margin-top: 23px !important; }

.margin-right-23,
.mr-23 {
  margin-right: 23px !important; }

.margin-bottom-23,
.mb-23 {
  margin-bottom: 23px !important; }

.margin-left-23,
.ml-23 {
  margin-left: 23px !important; }

.margin-horizontal-23,
.mh-23 {
  margin-left: 23px;
  margin-right: 23px; }

.margin-vertical-23,
.mv-23 {
  margin-top: 23px;
  margin-bottom: 23px; }

.margin-24,
.m-24 {
  margin: 24px !important; }

.margin-top-24,
.mt-24 {
  margin-top: 24px !important; }

.margin-right-24,
.mr-24 {
  margin-right: 24px !important; }

.margin-bottom-24,
.mb-24 {
  margin-bottom: 24px !important; }

.margin-left-24,
.ml-24 {
  margin-left: 24px !important; }

.margin-horizontal-24,
.mh-24 {
  margin-left: 24px;
  margin-right: 24px; }

.margin-vertical-24,
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px; }

.margin-25,
.m-25 {
  margin: 25px !important; }

.margin-top-25,
.mt-25 {
  margin-top: 25px !important; }

.margin-right-25,
.mr-25 {
  margin-right: 25px !important; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px !important; }

.margin-left-25,
.ml-25 {
  margin-left: 25px !important; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-26,
.m-26 {
  margin: 26px !important; }

.margin-top-26,
.mt-26 {
  margin-top: 26px !important; }

.margin-right-26,
.mr-26 {
  margin-right: 26px !important; }

.margin-bottom-26,
.mb-26 {
  margin-bottom: 26px !important; }

.margin-left-26,
.ml-26 {
  margin-left: 26px !important; }

.margin-horizontal-26,
.mh-26 {
  margin-left: 26px;
  margin-right: 26px; }

.margin-vertical-26,
.mv-26 {
  margin-top: 26px;
  margin-bottom: 26px; }

.margin-27,
.m-27 {
  margin: 27px !important; }

.margin-top-27,
.mt-27 {
  margin-top: 27px !important; }

.margin-right-27,
.mr-27 {
  margin-right: 27px !important; }

.margin-bottom-27,
.mb-27 {
  margin-bottom: 27px !important; }

.margin-left-27,
.ml-27 {
  margin-left: 27px !important; }

.margin-horizontal-27,
.mh-27 {
  margin-left: 27px;
  margin-right: 27px; }

.margin-vertical-27,
.mv-27 {
  margin-top: 27px;
  margin-bottom: 27px; }

.margin-28,
.m-28 {
  margin: 28px !important; }

.margin-top-28,
.mt-28 {
  margin-top: 28px !important; }

.margin-right-28,
.mr-28 {
  margin-right: 28px !important; }

.margin-bottom-28,
.mb-28 {
  margin-bottom: 28px !important; }

.margin-left-28,
.ml-28 {
  margin-left: 28px !important; }

.margin-horizontal-28,
.mh-28 {
  margin-left: 28px;
  margin-right: 28px; }

.margin-vertical-28,
.mv-28 {
  margin-top: 28px;
  margin-bottom: 28px; }

.margin-29,
.m-29 {
  margin: 29px !important; }

.margin-top-29,
.mt-29 {
  margin-top: 29px !important; }

.margin-right-29,
.mr-29 {
  margin-right: 29px !important; }

.margin-bottom-29,
.mb-29 {
  margin-bottom: 29px !important; }

.margin-left-29,
.ml-29 {
  margin-left: 29px !important; }

.margin-horizontal-29,
.mh-29 {
  margin-left: 29px;
  margin-right: 29px; }

.margin-vertical-29,
.mv-29 {
  margin-top: 29px;
  margin-bottom: 29px; }

.margin-30,
.m-30 {
  margin: 30px !important; }

.margin-top-30,
.mt-30 {
  margin-top: 30px !important; }

.margin-right-30,
.mr-30 {
  margin-right: 30px !important; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px !important; }

.margin-left-30,
.ml-30 {
  margin-left: 30px !important; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-31,
.m-31 {
  margin: 31px !important; }

.margin-top-31,
.mt-31 {
  margin-top: 31px !important; }

.margin-right-31,
.mr-31 {
  margin-right: 31px !important; }

.margin-bottom-31,
.mb-31 {
  margin-bottom: 31px !important; }

.margin-left-31,
.ml-31 {
  margin-left: 31px !important; }

.margin-horizontal-31,
.mh-31 {
  margin-left: 31px;
  margin-right: 31px; }

.margin-vertical-31,
.mv-31 {
  margin-top: 31px;
  margin-bottom: 31px; }

.margin-32,
.m-32 {
  margin: 32px !important; }

.margin-top-32,
.mt-32 {
  margin-top: 32px !important; }

.margin-right-32,
.mr-32 {
  margin-right: 32px !important; }

.margin-bottom-32,
.mb-32 {
  margin-bottom: 32px !important; }

.margin-left-32,
.ml-32 {
  margin-left: 32px !important; }

.margin-horizontal-32,
.mh-32 {
  margin-left: 32px;
  margin-right: 32px; }

.margin-vertical-32,
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px; }

.margin-33,
.m-33 {
  margin: 33px !important; }

.margin-top-33,
.mt-33 {
  margin-top: 33px !important; }

.margin-right-33,
.mr-33 {
  margin-right: 33px !important; }

.margin-bottom-33,
.mb-33 {
  margin-bottom: 33px !important; }

.margin-left-33,
.ml-33 {
  margin-left: 33px !important; }

.margin-horizontal-33,
.mh-33 {
  margin-left: 33px;
  margin-right: 33px; }

.margin-vertical-33,
.mv-33 {
  margin-top: 33px;
  margin-bottom: 33px; }

.margin-34,
.m-34 {
  margin: 34px !important; }

.margin-top-34,
.mt-34 {
  margin-top: 34px !important; }

.margin-right-34,
.mr-34 {
  margin-right: 34px !important; }

.margin-bottom-34,
.mb-34 {
  margin-bottom: 34px !important; }

.margin-left-34,
.ml-34 {
  margin-left: 34px !important; }

.margin-horizontal-34,
.mh-34 {
  margin-left: 34px;
  margin-right: 34px; }

.margin-vertical-34,
.mv-34 {
  margin-top: 34px;
  margin-bottom: 34px; }

.margin-35,
.m-35 {
  margin: 35px !important; }

.margin-top-35,
.mt-35 {
  margin-top: 35px !important; }

.margin-right-35,
.mr-35 {
  margin-right: 35px !important; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px !important; }

.margin-left-35,
.ml-35 {
  margin-left: 35px !important; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-36,
.m-36 {
  margin: 36px !important; }

.margin-top-36,
.mt-36 {
  margin-top: 36px !important; }

.margin-right-36,
.mr-36 {
  margin-right: 36px !important; }

.margin-bottom-36,
.mb-36 {
  margin-bottom: 36px !important; }

.margin-left-36,
.ml-36 {
  margin-left: 36px !important; }

.margin-horizontal-36,
.mh-36 {
  margin-left: 36px;
  margin-right: 36px; }

.margin-vertical-36,
.mv-36 {
  margin-top: 36px;
  margin-bottom: 36px; }

.margin-37,
.m-37 {
  margin: 37px !important; }

.margin-top-37,
.mt-37 {
  margin-top: 37px !important; }

.margin-right-37,
.mr-37 {
  margin-right: 37px !important; }

.margin-bottom-37,
.mb-37 {
  margin-bottom: 37px !important; }

.margin-left-37,
.ml-37 {
  margin-left: 37px !important; }

.margin-horizontal-37,
.mh-37 {
  margin-left: 37px;
  margin-right: 37px; }

.margin-vertical-37,
.mv-37 {
  margin-top: 37px;
  margin-bottom: 37px; }

.margin-38,
.m-38 {
  margin: 38px !important; }

.margin-top-38,
.mt-38 {
  margin-top: 38px !important; }

.margin-right-38,
.mr-38 {
  margin-right: 38px !important; }

.margin-bottom-38,
.mb-38 {
  margin-bottom: 38px !important; }

.margin-left-38,
.ml-38 {
  margin-left: 38px !important; }

.margin-horizontal-38,
.mh-38 {
  margin-left: 38px;
  margin-right: 38px; }

.margin-vertical-38,
.mv-38 {
  margin-top: 38px;
  margin-bottom: 38px; }

.margin-39,
.m-39 {
  margin: 39px !important; }

.margin-top-39,
.mt-39 {
  margin-top: 39px !important; }

.margin-right-39,
.mr-39 {
  margin-right: 39px !important; }

.margin-bottom-39,
.mb-39 {
  margin-bottom: 39px !important; }

.margin-left-39,
.ml-39 {
  margin-left: 39px !important; }

.margin-horizontal-39,
.mh-39 {
  margin-left: 39px;
  margin-right: 39px; }

.margin-vertical-39,
.mv-39 {
  margin-top: 39px;
  margin-bottom: 39px; }

.margin-40,
.m-40 {
  margin: 40px !important; }

.margin-top-40,
.mt-40 {
  margin-top: 40px !important; }

.margin-right-40,
.mr-40 {
  margin-right: 40px !important; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px !important; }

.margin-left-40,
.ml-40 {
  margin-left: 40px !important; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-41,
.m-41 {
  margin: 41px !important; }

.margin-top-41,
.mt-41 {
  margin-top: 41px !important; }

.margin-right-41,
.mr-41 {
  margin-right: 41px !important; }

.margin-bottom-41,
.mb-41 {
  margin-bottom: 41px !important; }

.margin-left-41,
.ml-41 {
  margin-left: 41px !important; }

.margin-horizontal-41,
.mh-41 {
  margin-left: 41px;
  margin-right: 41px; }

.margin-vertical-41,
.mv-41 {
  margin-top: 41px;
  margin-bottom: 41px; }

.margin-42,
.m-42 {
  margin: 42px !important; }

.margin-top-42,
.mt-42 {
  margin-top: 42px !important; }

.margin-right-42,
.mr-42 {
  margin-right: 42px !important; }

.margin-bottom-42,
.mb-42 {
  margin-bottom: 42px !important; }

.margin-left-42,
.ml-42 {
  margin-left: 42px !important; }

.margin-horizontal-42,
.mh-42 {
  margin-left: 42px;
  margin-right: 42px; }

.margin-vertical-42,
.mv-42 {
  margin-top: 42px;
  margin-bottom: 42px; }

.margin-43,
.m-43 {
  margin: 43px !important; }

.margin-top-43,
.mt-43 {
  margin-top: 43px !important; }

.margin-right-43,
.mr-43 {
  margin-right: 43px !important; }

.margin-bottom-43,
.mb-43 {
  margin-bottom: 43px !important; }

.margin-left-43,
.ml-43 {
  margin-left: 43px !important; }

.margin-horizontal-43,
.mh-43 {
  margin-left: 43px;
  margin-right: 43px; }

.margin-vertical-43,
.mv-43 {
  margin-top: 43px;
  margin-bottom: 43px; }

.margin-44,
.m-44 {
  margin: 44px !important; }

.margin-top-44,
.mt-44 {
  margin-top: 44px !important; }

.margin-right-44,
.mr-44 {
  margin-right: 44px !important; }

.margin-bottom-44,
.mb-44 {
  margin-bottom: 44px !important; }

.margin-left-44,
.ml-44 {
  margin-left: 44px !important; }

.margin-horizontal-44,
.mh-44 {
  margin-left: 44px;
  margin-right: 44px; }

.margin-vertical-44,
.mv-44 {
  margin-top: 44px;
  margin-bottom: 44px; }

.margin-45,
.m-45 {
  margin: 45px !important; }

.margin-top-45,
.mt-45 {
  margin-top: 45px !important; }

.margin-right-45,
.mr-45 {
  margin-right: 45px !important; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px !important; }

.margin-left-45,
.ml-45 {
  margin-left: 45px !important; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-46,
.m-46 {
  margin: 46px !important; }

.margin-top-46,
.mt-46 {
  margin-top: 46px !important; }

.margin-right-46,
.mr-46 {
  margin-right: 46px !important; }

.margin-bottom-46,
.mb-46 {
  margin-bottom: 46px !important; }

.margin-left-46,
.ml-46 {
  margin-left: 46px !important; }

.margin-horizontal-46,
.mh-46 {
  margin-left: 46px;
  margin-right: 46px; }

.margin-vertical-46,
.mv-46 {
  margin-top: 46px;
  margin-bottom: 46px; }

.margin-47,
.m-47 {
  margin: 47px !important; }

.margin-top-47,
.mt-47 {
  margin-top: 47px !important; }

.margin-right-47,
.mr-47 {
  margin-right: 47px !important; }

.margin-bottom-47,
.mb-47 {
  margin-bottom: 47px !important; }

.margin-left-47,
.ml-47 {
  margin-left: 47px !important; }

.margin-horizontal-47,
.mh-47 {
  margin-left: 47px;
  margin-right: 47px; }

.margin-vertical-47,
.mv-47 {
  margin-top: 47px;
  margin-bottom: 47px; }

.margin-48,
.m-48 {
  margin: 48px !important; }

.margin-top-48,
.mt-48 {
  margin-top: 48px !important; }

.margin-right-48,
.mr-48 {
  margin-right: 48px !important; }

.margin-bottom-48,
.mb-48 {
  margin-bottom: 48px !important; }

.margin-left-48,
.ml-48 {
  margin-left: 48px !important; }

.margin-horizontal-48,
.mh-48 {
  margin-left: 48px;
  margin-right: 48px; }

.margin-vertical-48,
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px; }

.margin-49,
.m-49 {
  margin: 49px !important; }

.margin-top-49,
.mt-49 {
  margin-top: 49px !important; }

.margin-right-49,
.mr-49 {
  margin-right: 49px !important; }

.margin-bottom-49,
.mb-49 {
  margin-bottom: 49px !important; }

.margin-left-49,
.ml-49 {
  margin-left: 49px !important; }

.margin-horizontal-49,
.mh-49 {
  margin-left: 49px;
  margin-right: 49px; }

.margin-vertical-49,
.mv-49 {
  margin-top: 49px;
  margin-bottom: 49px; }

.margin-50,
.m-50 {
  margin: 50px !important; }

.margin-top-50,
.mt-50 {
  margin-top: 50px !important; }

.margin-right-50,
.mr-50 {
  margin-right: 50px !important; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px !important; }

.margin-left-50,
.ml-50 {
  margin-left: 50px !important; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-51,
.m-51 {
  margin: 51px !important; }

.margin-top-51,
.mt-51 {
  margin-top: 51px !important; }

.margin-right-51,
.mr-51 {
  margin-right: 51px !important; }

.margin-bottom-51,
.mb-51 {
  margin-bottom: 51px !important; }

.margin-left-51,
.ml-51 {
  margin-left: 51px !important; }

.margin-horizontal-51,
.mh-51 {
  margin-left: 51px;
  margin-right: 51px; }

.margin-vertical-51,
.mv-51 {
  margin-top: 51px;
  margin-bottom: 51px; }

.margin-52,
.m-52 {
  margin: 52px !important; }

.margin-top-52,
.mt-52 {
  margin-top: 52px !important; }

.margin-right-52,
.mr-52 {
  margin-right: 52px !important; }

.margin-bottom-52,
.mb-52 {
  margin-bottom: 52px !important; }

.margin-left-52,
.ml-52 {
  margin-left: 52px !important; }

.margin-horizontal-52,
.mh-52 {
  margin-left: 52px;
  margin-right: 52px; }

.margin-vertical-52,
.mv-52 {
  margin-top: 52px;
  margin-bottom: 52px; }

.margin-53,
.m-53 {
  margin: 53px !important; }

.margin-top-53,
.mt-53 {
  margin-top: 53px !important; }

.margin-right-53,
.mr-53 {
  margin-right: 53px !important; }

.margin-bottom-53,
.mb-53 {
  margin-bottom: 53px !important; }

.margin-left-53,
.ml-53 {
  margin-left: 53px !important; }

.margin-horizontal-53,
.mh-53 {
  margin-left: 53px;
  margin-right: 53px; }

.margin-vertical-53,
.mv-53 {
  margin-top: 53px;
  margin-bottom: 53px; }

.margin-54,
.m-54 {
  margin: 54px !important; }

.margin-top-54,
.mt-54 {
  margin-top: 54px !important; }

.margin-right-54,
.mr-54 {
  margin-right: 54px !important; }

.margin-bottom-54,
.mb-54 {
  margin-bottom: 54px !important; }

.margin-left-54,
.ml-54 {
  margin-left: 54px !important; }

.margin-horizontal-54,
.mh-54 {
  margin-left: 54px;
  margin-right: 54px; }

.margin-vertical-54,
.mv-54 {
  margin-top: 54px;
  margin-bottom: 54px; }

.margin-55,
.m-55 {
  margin: 55px !important; }

.margin-top-55,
.mt-55 {
  margin-top: 55px !important; }

.margin-right-55,
.mr-55 {
  margin-right: 55px !important; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px !important; }

.margin-left-55,
.ml-55 {
  margin-left: 55px !important; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-56,
.m-56 {
  margin: 56px !important; }

.margin-top-56,
.mt-56 {
  margin-top: 56px !important; }

.margin-right-56,
.mr-56 {
  margin-right: 56px !important; }

.margin-bottom-56,
.mb-56 {
  margin-bottom: 56px !important; }

.margin-left-56,
.ml-56 {
  margin-left: 56px !important; }

.margin-horizontal-56,
.mh-56 {
  margin-left: 56px;
  margin-right: 56px; }

.margin-vertical-56,
.mv-56 {
  margin-top: 56px;
  margin-bottom: 56px; }

.margin-57,
.m-57 {
  margin: 57px !important; }

.margin-top-57,
.mt-57 {
  margin-top: 57px !important; }

.margin-right-57,
.mr-57 {
  margin-right: 57px !important; }

.margin-bottom-57,
.mb-57 {
  margin-bottom: 57px !important; }

.margin-left-57,
.ml-57 {
  margin-left: 57px !important; }

.margin-horizontal-57,
.mh-57 {
  margin-left: 57px;
  margin-right: 57px; }

.margin-vertical-57,
.mv-57 {
  margin-top: 57px;
  margin-bottom: 57px; }

.margin-58,
.m-58 {
  margin: 58px !important; }

.margin-top-58,
.mt-58 {
  margin-top: 58px !important; }

.margin-right-58,
.mr-58 {
  margin-right: 58px !important; }

.margin-bottom-58,
.mb-58 {
  margin-bottom: 58px !important; }

.margin-left-58,
.ml-58 {
  margin-left: 58px !important; }

.margin-horizontal-58,
.mh-58 {
  margin-left: 58px;
  margin-right: 58px; }

.margin-vertical-58,
.mv-58 {
  margin-top: 58px;
  margin-bottom: 58px; }

.margin-59,
.m-59 {
  margin: 59px !important; }

.margin-top-59,
.mt-59 {
  margin-top: 59px !important; }

.margin-right-59,
.mr-59 {
  margin-right: 59px !important; }

.margin-bottom-59,
.mb-59 {
  margin-bottom: 59px !important; }

.margin-left-59,
.ml-59 {
  margin-left: 59px !important; }

.margin-horizontal-59,
.mh-59 {
  margin-left: 59px;
  margin-right: 59px; }

.margin-vertical-59,
.mv-59 {
  margin-top: 59px;
  margin-bottom: 59px; }

.margin-60,
.m-60 {
  margin: 60px !important; }

.margin-top-60,
.mt-60 {
  margin-top: 60px !important; }

.margin-right-60,
.mr-60 {
  margin-right: 60px !important; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px !important; }

.margin-left-60,
.ml-60 {
  margin-left: 60px !important; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-61,
.m-61 {
  margin: 61px !important; }

.margin-top-61,
.mt-61 {
  margin-top: 61px !important; }

.margin-right-61,
.mr-61 {
  margin-right: 61px !important; }

.margin-bottom-61,
.mb-61 {
  margin-bottom: 61px !important; }

.margin-left-61,
.ml-61 {
  margin-left: 61px !important; }

.margin-horizontal-61,
.mh-61 {
  margin-left: 61px;
  margin-right: 61px; }

.margin-vertical-61,
.mv-61 {
  margin-top: 61px;
  margin-bottom: 61px; }

.margin-62,
.m-62 {
  margin: 62px !important; }

.margin-top-62,
.mt-62 {
  margin-top: 62px !important; }

.margin-right-62,
.mr-62 {
  margin-right: 62px !important; }

.margin-bottom-62,
.mb-62 {
  margin-bottom: 62px !important; }

.margin-left-62,
.ml-62 {
  margin-left: 62px !important; }

.margin-horizontal-62,
.mh-62 {
  margin-left: 62px;
  margin-right: 62px; }

.margin-vertical-62,
.mv-62 {
  margin-top: 62px;
  margin-bottom: 62px; }

.margin-63,
.m-63 {
  margin: 63px !important; }

.margin-top-63,
.mt-63 {
  margin-top: 63px !important; }

.margin-right-63,
.mr-63 {
  margin-right: 63px !important; }

.margin-bottom-63,
.mb-63 {
  margin-bottom: 63px !important; }

.margin-left-63,
.ml-63 {
  margin-left: 63px !important; }

.margin-horizontal-63,
.mh-63 {
  margin-left: 63px;
  margin-right: 63px; }

.margin-vertical-63,
.mv-63 {
  margin-top: 63px;
  margin-bottom: 63px; }

.margin-64,
.m-64 {
  margin: 64px !important; }

.margin-top-64,
.mt-64 {
  margin-top: 64px !important; }

.margin-right-64,
.mr-64 {
  margin-right: 64px !important; }

.margin-bottom-64,
.mb-64 {
  margin-bottom: 64px !important; }

.margin-left-64,
.ml-64 {
  margin-left: 64px !important; }

.margin-horizontal-64,
.mh-64 {
  margin-left: 64px;
  margin-right: 64px; }

.margin-vertical-64,
.mv-64 {
  margin-top: 64px;
  margin-bottom: 64px; }

.margin-65,
.m-65 {
  margin: 65px !important; }

.margin-top-65,
.mt-65 {
  margin-top: 65px !important; }

.margin-right-65,
.mr-65 {
  margin-right: 65px !important; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px !important; }

.margin-left-65,
.ml-65 {
  margin-left: 65px !important; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-66,
.m-66 {
  margin: 66px !important; }

.margin-top-66,
.mt-66 {
  margin-top: 66px !important; }

.margin-right-66,
.mr-66 {
  margin-right: 66px !important; }

.margin-bottom-66,
.mb-66 {
  margin-bottom: 66px !important; }

.margin-left-66,
.ml-66 {
  margin-left: 66px !important; }

.margin-horizontal-66,
.mh-66 {
  margin-left: 66px;
  margin-right: 66px; }

.margin-vertical-66,
.mv-66 {
  margin-top: 66px;
  margin-bottom: 66px; }

.margin-67,
.m-67 {
  margin: 67px !important; }

.margin-top-67,
.mt-67 {
  margin-top: 67px !important; }

.margin-right-67,
.mr-67 {
  margin-right: 67px !important; }

.margin-bottom-67,
.mb-67 {
  margin-bottom: 67px !important; }

.margin-left-67,
.ml-67 {
  margin-left: 67px !important; }

.margin-horizontal-67,
.mh-67 {
  margin-left: 67px;
  margin-right: 67px; }

.margin-vertical-67,
.mv-67 {
  margin-top: 67px;
  margin-bottom: 67px; }

.margin-68,
.m-68 {
  margin: 68px !important; }

.margin-top-68,
.mt-68 {
  margin-top: 68px !important; }

.margin-right-68,
.mr-68 {
  margin-right: 68px !important; }

.margin-bottom-68,
.mb-68 {
  margin-bottom: 68px !important; }

.margin-left-68,
.ml-68 {
  margin-left: 68px !important; }

.margin-horizontal-68,
.mh-68 {
  margin-left: 68px;
  margin-right: 68px; }

.margin-vertical-68,
.mv-68 {
  margin-top: 68px;
  margin-bottom: 68px; }

.margin-69,
.m-69 {
  margin: 69px !important; }

.margin-top-69,
.mt-69 {
  margin-top: 69px !important; }

.margin-right-69,
.mr-69 {
  margin-right: 69px !important; }

.margin-bottom-69,
.mb-69 {
  margin-bottom: 69px !important; }

.margin-left-69,
.ml-69 {
  margin-left: 69px !important; }

.margin-horizontal-69,
.mh-69 {
  margin-left: 69px;
  margin-right: 69px; }

.margin-vertical-69,
.mv-69 {
  margin-top: 69px;
  margin-bottom: 69px; }

.margin-70,
.m-70 {
  margin: 70px !important; }

.margin-top-70,
.mt-70 {
  margin-top: 70px !important; }

.margin-right-70,
.mr-70 {
  margin-right: 70px !important; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px !important; }

.margin-left-70,
.ml-70 {
  margin-left: 70px !important; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-71,
.m-71 {
  margin: 71px !important; }

.margin-top-71,
.mt-71 {
  margin-top: 71px !important; }

.margin-right-71,
.mr-71 {
  margin-right: 71px !important; }

.margin-bottom-71,
.mb-71 {
  margin-bottom: 71px !important; }

.margin-left-71,
.ml-71 {
  margin-left: 71px !important; }

.margin-horizontal-71,
.mh-71 {
  margin-left: 71px;
  margin-right: 71px; }

.margin-vertical-71,
.mv-71 {
  margin-top: 71px;
  margin-bottom: 71px; }

.margin-72,
.m-72 {
  margin: 72px !important; }

.margin-top-72,
.mt-72 {
  margin-top: 72px !important; }

.margin-right-72,
.mr-72 {
  margin-right: 72px !important; }

.margin-bottom-72,
.mb-72 {
  margin-bottom: 72px !important; }

.margin-left-72,
.ml-72 {
  margin-left: 72px !important; }

.margin-horizontal-72,
.mh-72 {
  margin-left: 72px;
  margin-right: 72px; }

.margin-vertical-72,
.mv-72 {
  margin-top: 72px;
  margin-bottom: 72px; }

.margin-73,
.m-73 {
  margin: 73px !important; }

.margin-top-73,
.mt-73 {
  margin-top: 73px !important; }

.margin-right-73,
.mr-73 {
  margin-right: 73px !important; }

.margin-bottom-73,
.mb-73 {
  margin-bottom: 73px !important; }

.margin-left-73,
.ml-73 {
  margin-left: 73px !important; }

.margin-horizontal-73,
.mh-73 {
  margin-left: 73px;
  margin-right: 73px; }

.margin-vertical-73,
.mv-73 {
  margin-top: 73px;
  margin-bottom: 73px; }

.margin-74,
.m-74 {
  margin: 74px !important; }

.margin-top-74,
.mt-74 {
  margin-top: 74px !important; }

.margin-right-74,
.mr-74 {
  margin-right: 74px !important; }

.margin-bottom-74,
.mb-74 {
  margin-bottom: 74px !important; }

.margin-left-74,
.ml-74 {
  margin-left: 74px !important; }

.margin-horizontal-74,
.mh-74 {
  margin-left: 74px;
  margin-right: 74px; }

.margin-vertical-74,
.mv-74 {
  margin-top: 74px;
  margin-bottom: 74px; }

.margin-75,
.m-75 {
  margin: 75px !important; }

.margin-top-75,
.mt-75 {
  margin-top: 75px !important; }

.margin-right-75,
.mr-75 {
  margin-right: 75px !important; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px !important; }

.margin-left-75,
.ml-75 {
  margin-left: 75px !important; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-76,
.m-76 {
  margin: 76px !important; }

.margin-top-76,
.mt-76 {
  margin-top: 76px !important; }

.margin-right-76,
.mr-76 {
  margin-right: 76px !important; }

.margin-bottom-76,
.mb-76 {
  margin-bottom: 76px !important; }

.margin-left-76,
.ml-76 {
  margin-left: 76px !important; }

.margin-horizontal-76,
.mh-76 {
  margin-left: 76px;
  margin-right: 76px; }

.margin-vertical-76,
.mv-76 {
  margin-top: 76px;
  margin-bottom: 76px; }

.margin-77,
.m-77 {
  margin: 77px !important; }

.margin-top-77,
.mt-77 {
  margin-top: 77px !important; }

.margin-right-77,
.mr-77 {
  margin-right: 77px !important; }

.margin-bottom-77,
.mb-77 {
  margin-bottom: 77px !important; }

.margin-left-77,
.ml-77 {
  margin-left: 77px !important; }

.margin-horizontal-77,
.mh-77 {
  margin-left: 77px;
  margin-right: 77px; }

.margin-vertical-77,
.mv-77 {
  margin-top: 77px;
  margin-bottom: 77px; }

.margin-78,
.m-78 {
  margin: 78px !important; }

.margin-top-78,
.mt-78 {
  margin-top: 78px !important; }

.margin-right-78,
.mr-78 {
  margin-right: 78px !important; }

.margin-bottom-78,
.mb-78 {
  margin-bottom: 78px !important; }

.margin-left-78,
.ml-78 {
  margin-left: 78px !important; }

.margin-horizontal-78,
.mh-78 {
  margin-left: 78px;
  margin-right: 78px; }

.margin-vertical-78,
.mv-78 {
  margin-top: 78px;
  margin-bottom: 78px; }

.margin-79,
.m-79 {
  margin: 79px !important; }

.margin-top-79,
.mt-79 {
  margin-top: 79px !important; }

.margin-right-79,
.mr-79 {
  margin-right: 79px !important; }

.margin-bottom-79,
.mb-79 {
  margin-bottom: 79px !important; }

.margin-left-79,
.ml-79 {
  margin-left: 79px !important; }

.margin-horizontal-79,
.mh-79 {
  margin-left: 79px;
  margin-right: 79px; }

.margin-vertical-79,
.mv-79 {
  margin-top: 79px;
  margin-bottom: 79px; }

.margin-80,
.m-80 {
  margin: 80px !important; }

.margin-top-80,
.mt-80 {
  margin-top: 80px !important; }

.margin-right-80,
.mr-80 {
  margin-right: 80px !important; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px !important; }

.margin-left-80,
.ml-80 {
  margin-left: 80px !important; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-81,
.m-81 {
  margin: 81px !important; }

.margin-top-81,
.mt-81 {
  margin-top: 81px !important; }

.margin-right-81,
.mr-81 {
  margin-right: 81px !important; }

.margin-bottom-81,
.mb-81 {
  margin-bottom: 81px !important; }

.margin-left-81,
.ml-81 {
  margin-left: 81px !important; }

.margin-horizontal-81,
.mh-81 {
  margin-left: 81px;
  margin-right: 81px; }

.margin-vertical-81,
.mv-81 {
  margin-top: 81px;
  margin-bottom: 81px; }

.margin-82,
.m-82 {
  margin: 82px !important; }

.margin-top-82,
.mt-82 {
  margin-top: 82px !important; }

.margin-right-82,
.mr-82 {
  margin-right: 82px !important; }

.margin-bottom-82,
.mb-82 {
  margin-bottom: 82px !important; }

.margin-left-82,
.ml-82 {
  margin-left: 82px !important; }

.margin-horizontal-82,
.mh-82 {
  margin-left: 82px;
  margin-right: 82px; }

.margin-vertical-82,
.mv-82 {
  margin-top: 82px;
  margin-bottom: 82px; }

.margin-83,
.m-83 {
  margin: 83px !important; }

.margin-top-83,
.mt-83 {
  margin-top: 83px !important; }

.margin-right-83,
.mr-83 {
  margin-right: 83px !important; }

.margin-bottom-83,
.mb-83 {
  margin-bottom: 83px !important; }

.margin-left-83,
.ml-83 {
  margin-left: 83px !important; }

.margin-horizontal-83,
.mh-83 {
  margin-left: 83px;
  margin-right: 83px; }

.margin-vertical-83,
.mv-83 {
  margin-top: 83px;
  margin-bottom: 83px; }

.margin-84,
.m-84 {
  margin: 84px !important; }

.margin-top-84,
.mt-84 {
  margin-top: 84px !important; }

.margin-right-84,
.mr-84 {
  margin-right: 84px !important; }

.margin-bottom-84,
.mb-84 {
  margin-bottom: 84px !important; }

.margin-left-84,
.ml-84 {
  margin-left: 84px !important; }

.margin-horizontal-84,
.mh-84 {
  margin-left: 84px;
  margin-right: 84px; }

.margin-vertical-84,
.mv-84 {
  margin-top: 84px;
  margin-bottom: 84px; }

.margin-85,
.m-85 {
  margin: 85px !important; }

.margin-top-85,
.mt-85 {
  margin-top: 85px !important; }

.margin-right-85,
.mr-85 {
  margin-right: 85px !important; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px !important; }

.margin-left-85,
.ml-85 {
  margin-left: 85px !important; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-86,
.m-86 {
  margin: 86px !important; }

.margin-top-86,
.mt-86 {
  margin-top: 86px !important; }

.margin-right-86,
.mr-86 {
  margin-right: 86px !important; }

.margin-bottom-86,
.mb-86 {
  margin-bottom: 86px !important; }

.margin-left-86,
.ml-86 {
  margin-left: 86px !important; }

.margin-horizontal-86,
.mh-86 {
  margin-left: 86px;
  margin-right: 86px; }

.margin-vertical-86,
.mv-86 {
  margin-top: 86px;
  margin-bottom: 86px; }

.margin-87,
.m-87 {
  margin: 87px !important; }

.margin-top-87,
.mt-87 {
  margin-top: 87px !important; }

.margin-right-87,
.mr-87 {
  margin-right: 87px !important; }

.margin-bottom-87,
.mb-87 {
  margin-bottom: 87px !important; }

.margin-left-87,
.ml-87 {
  margin-left: 87px !important; }

.margin-horizontal-87,
.mh-87 {
  margin-left: 87px;
  margin-right: 87px; }

.margin-vertical-87,
.mv-87 {
  margin-top: 87px;
  margin-bottom: 87px; }

.margin-88,
.m-88 {
  margin: 88px !important; }

.margin-top-88,
.mt-88 {
  margin-top: 88px !important; }

.margin-right-88,
.mr-88 {
  margin-right: 88px !important; }

.margin-bottom-88,
.mb-88 {
  margin-bottom: 88px !important; }

.margin-left-88,
.ml-88 {
  margin-left: 88px !important; }

.margin-horizontal-88,
.mh-88 {
  margin-left: 88px;
  margin-right: 88px; }

.margin-vertical-88,
.mv-88 {
  margin-top: 88px;
  margin-bottom: 88px; }

.margin-89,
.m-89 {
  margin: 89px !important; }

.margin-top-89,
.mt-89 {
  margin-top: 89px !important; }

.margin-right-89,
.mr-89 {
  margin-right: 89px !important; }

.margin-bottom-89,
.mb-89 {
  margin-bottom: 89px !important; }

.margin-left-89,
.ml-89 {
  margin-left: 89px !important; }

.margin-horizontal-89,
.mh-89 {
  margin-left: 89px;
  margin-right: 89px; }

.margin-vertical-89,
.mv-89 {
  margin-top: 89px;
  margin-bottom: 89px; }

.margin-90,
.m-90 {
  margin: 90px !important; }

.margin-top-90,
.mt-90 {
  margin-top: 90px !important; }

.margin-right-90,
.mr-90 {
  margin-right: 90px !important; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px !important; }

.margin-left-90,
.ml-90 {
  margin-left: 90px !important; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-91,
.m-91 {
  margin: 91px !important; }

.margin-top-91,
.mt-91 {
  margin-top: 91px !important; }

.margin-right-91,
.mr-91 {
  margin-right: 91px !important; }

.margin-bottom-91,
.mb-91 {
  margin-bottom: 91px !important; }

.margin-left-91,
.ml-91 {
  margin-left: 91px !important; }

.margin-horizontal-91,
.mh-91 {
  margin-left: 91px;
  margin-right: 91px; }

.margin-vertical-91,
.mv-91 {
  margin-top: 91px;
  margin-bottom: 91px; }

.margin-92,
.m-92 {
  margin: 92px !important; }

.margin-top-92,
.mt-92 {
  margin-top: 92px !important; }

.margin-right-92,
.mr-92 {
  margin-right: 92px !important; }

.margin-bottom-92,
.mb-92 {
  margin-bottom: 92px !important; }

.margin-left-92,
.ml-92 {
  margin-left: 92px !important; }

.margin-horizontal-92,
.mh-92 {
  margin-left: 92px;
  margin-right: 92px; }

.margin-vertical-92,
.mv-92 {
  margin-top: 92px;
  margin-bottom: 92px; }

.margin-93,
.m-93 {
  margin: 93px !important; }

.margin-top-93,
.mt-93 {
  margin-top: 93px !important; }

.margin-right-93,
.mr-93 {
  margin-right: 93px !important; }

.margin-bottom-93,
.mb-93 {
  margin-bottom: 93px !important; }

.margin-left-93,
.ml-93 {
  margin-left: 93px !important; }

.margin-horizontal-93,
.mh-93 {
  margin-left: 93px;
  margin-right: 93px; }

.margin-vertical-93,
.mv-93 {
  margin-top: 93px;
  margin-bottom: 93px; }

.margin-94,
.m-94 {
  margin: 94px !important; }

.margin-top-94,
.mt-94 {
  margin-top: 94px !important; }

.margin-right-94,
.mr-94 {
  margin-right: 94px !important; }

.margin-bottom-94,
.mb-94 {
  margin-bottom: 94px !important; }

.margin-left-94,
.ml-94 {
  margin-left: 94px !important; }

.margin-horizontal-94,
.mh-94 {
  margin-left: 94px;
  margin-right: 94px; }

.margin-vertical-94,
.mv-94 {
  margin-top: 94px;
  margin-bottom: 94px; }

.margin-95,
.m-95 {
  margin: 95px !important; }

.margin-top-95,
.mt-95 {
  margin-top: 95px !important; }

.margin-right-95,
.mr-95 {
  margin-right: 95px !important; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px !important; }

.margin-left-95,
.ml-95 {
  margin-left: 95px !important; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-96,
.m-96 {
  margin: 96px !important; }

.margin-top-96,
.mt-96 {
  margin-top: 96px !important; }

.margin-right-96,
.mr-96 {
  margin-right: 96px !important; }

.margin-bottom-96,
.mb-96 {
  margin-bottom: 96px !important; }

.margin-left-96,
.ml-96 {
  margin-left: 96px !important; }

.margin-horizontal-96,
.mh-96 {
  margin-left: 96px;
  margin-right: 96px; }

.margin-vertical-96,
.mv-96 {
  margin-top: 96px;
  margin-bottom: 96px; }

.margin-97,
.m-97 {
  margin: 97px !important; }

.margin-top-97,
.mt-97 {
  margin-top: 97px !important; }

.margin-right-97,
.mr-97 {
  margin-right: 97px !important; }

.margin-bottom-97,
.mb-97 {
  margin-bottom: 97px !important; }

.margin-left-97,
.ml-97 {
  margin-left: 97px !important; }

.margin-horizontal-97,
.mh-97 {
  margin-left: 97px;
  margin-right: 97px; }

.margin-vertical-97,
.mv-97 {
  margin-top: 97px;
  margin-bottom: 97px; }

.margin-98,
.m-98 {
  margin: 98px !important; }

.margin-top-98,
.mt-98 {
  margin-top: 98px !important; }

.margin-right-98,
.mr-98 {
  margin-right: 98px !important; }

.margin-bottom-98,
.mb-98 {
  margin-bottom: 98px !important; }

.margin-left-98,
.ml-98 {
  margin-left: 98px !important; }

.margin-horizontal-98,
.mh-98 {
  margin-left: 98px;
  margin-right: 98px; }

.margin-vertical-98,
.mv-98 {
  margin-top: 98px;
  margin-bottom: 98px; }

.margin-99,
.m-99 {
  margin: 99px !important; }

.margin-top-99,
.mt-99 {
  margin-top: 99px !important; }

.margin-right-99,
.mr-99 {
  margin-right: 99px !important; }

.margin-bottom-99,
.mb-99 {
  margin-bottom: 99px !important; }

.margin-left-99,
.ml-99 {
  margin-left: 99px !important; }

.margin-horizontal-99,
.mh-99 {
  margin-left: 99px;
  margin-right: 99px; }

.margin-vertical-99,
.mv-99 {
  margin-top: 99px;
  margin-bottom: 99px; }

.margin-100,
.m-100 {
  margin: 100px !important; }

.margin-top-100,
.mt-100 {
  margin-top: 100px !important; }

.margin-right-100,
.mr-100 {
  margin-right: 100px !important; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px !important; }

.margin-left-100,
.ml-100 {
  margin-left: 100px !important; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.height-0,
.h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important; }

.width-0,
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important; }

.height-5,
.h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important; }

.width-5,
.w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important; }

.height-10,
.h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important; }

.width-10,
.w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important; }

.height-15,
.h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important; }

.width-15,
.w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important; }

.height-20,
.h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important; }

.width-20,
.w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important; }

.height-25,
.h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important; }

.width-25,
.w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important; }

.height-30,
.h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important; }

.width-30,
.w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important; }

.height-35,
.h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important; }

.width-35,
.w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important; }

.height-40,
.h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important; }

.width-40,
.w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important; }

.height-45,
.h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important; }

.width-45,
.w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important; }

.height-50,
.h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important; }

.width-50,
.w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important; }

.height-55,
.h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important; }

.width-55,
.w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important; }

.height-60,
.h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important; }

.width-60,
.w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important; }

.height-65,
.h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important; }

.width-65,
.w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important; }

.height-70,
.h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important; }

.width-70,
.w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important; }

.height-75,
.h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important; }

.width-75,
.w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important; }

.height-80,
.h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important; }

.width-80,
.w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important; }

.height-85,
.h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important; }

.width-85,
.w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important; }

.height-90,
.h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important; }

.width-90,
.w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important; }

.height-95,
.h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important; }

.width-95,
.w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important; }

.height-100,
.h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important; }

.width-100,
.w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important; }

.height-105,
.h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important; }

.width-105,
.w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important; }

.height-110,
.h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important; }

.width-110,
.w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important; }

.height-115,
.h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important; }

.width-115,
.w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important; }

.height-120,
.h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important; }

.width-120,
.w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important; }

.height-125,
.h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important; }

.width-125,
.w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important; }

.height-130,
.h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important; }

.width-130,
.w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important; }

.height-135,
.h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important; }

.width-135,
.w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important; }

.height-140,
.h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important; }

.width-140,
.w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important; }

.height-145,
.h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important; }

.width-145,
.w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important; }

.height-150,
.h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important; }

.width-150,
.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important; }

.height-155,
.h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important; }

.width-155,
.w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important; }

.height-160,
.h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important; }

.width-160,
.w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important; }

.height-165,
.h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important; }

.width-165,
.w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important; }

.height-170,
.h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important; }

.width-170,
.w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important; }

.height-175,
.h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important; }

.width-175,
.w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important; }

.height-180,
.h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important; }

.width-180,
.w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important; }

.height-185,
.h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important; }

.width-185,
.w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important; }

.height-190,
.h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important; }

.width-190,
.w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important; }

.height-195,
.h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important; }

.width-195,
.w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important; }

.height-200,
.h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important; }

.width-200,
.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important; }

.height-205,
.h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important; }

.width-205,
.w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important; }

.height-210,
.h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important; }

.width-210,
.w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important; }

.height-215,
.h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important; }

.width-215,
.w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important; }

.height-220,
.h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important; }

.width-220,
.w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important; }

.height-225,
.h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important; }

.width-225,
.w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important; }

.height-230,
.h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important; }

.width-230,
.w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important; }

.height-235,
.h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important; }

.width-235,
.w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important; }

.height-240,
.h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important; }

.width-240,
.w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important; }

.height-245,
.h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important; }

.width-245,
.w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important; }

.height-250,
.h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important; }

.width-250,
.w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important; }

.height-255,
.h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important; }

.width-255,
.w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important; }

.height-260,
.h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important; }

.width-260,
.w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important; }

.height-265,
.h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important; }

.width-265,
.w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important; }

.height-270,
.h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important; }

.width-270,
.w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important; }

.height-275,
.h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important; }

.width-275,
.w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important; }

.height-280,
.h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important; }

.width-280,
.w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important; }

.height-285,
.h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important; }

.width-285,
.w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important; }

.height-290,
.h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important; }

.width-290,
.w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important; }

.height-295,
.h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important; }

.width-295,
.w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important; }

.height-300,
.h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important; }

.width-300,
.w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important; }

.height-305,
.h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important; }

.width-305,
.w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important; }

.height-310,
.h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important; }

.width-310,
.w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important; }

.height-315,
.h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important; }

.width-315,
.w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important; }

.height-320,
.h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important; }

.width-320,
.w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important; }

.height-325,
.h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important; }

.width-325,
.w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important; }

.height-330,
.h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important; }

.width-330,
.w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important; }

.height-335,
.h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important; }

.width-335,
.w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important; }

.height-340,
.h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important; }

.width-340,
.w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important; }

.height-345,
.h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important; }

.width-345,
.w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important; }

.height-350,
.h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important; }

.width-350,
.w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important; }

.height-355,
.h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important; }

.width-355,
.w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important; }

.height-360,
.h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important; }

.width-360,
.w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important; }

.height-365,
.h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important; }

.width-365,
.w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important; }

.height-370,
.h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important; }

.width-370,
.w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important; }

.height-375,
.h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important; }

.width-375,
.w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important; }

.height-380,
.h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important; }

.width-380,
.w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important; }

.height-385,
.h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important; }

.width-385,
.w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important; }

.height-390,
.h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important; }

.width-390,
.w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important; }

.height-395,
.h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important; }

.width-395,
.w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important; }

.height-400,
.h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important; }

.width-400,
.w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important; }

.height-405,
.h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important; }

.width-405,
.w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important; }

.height-410,
.h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important; }

.width-410,
.w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important; }

.height-415,
.h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important; }

.width-415,
.w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important; }

.height-420,
.h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important; }

.width-420,
.w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important; }

.height-425,
.h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important; }

.width-425,
.w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important; }

.height-430,
.h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important; }

.width-430,
.w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important; }

.height-435,
.h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important; }

.width-435,
.w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important; }

.height-440,
.h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important; }

.width-440,
.w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important; }

.height-445,
.h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important; }

.width-445,
.w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important; }

.height-450,
.h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important; }

.width-450,
.w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important; }

.height-455,
.h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important; }

.width-455,
.w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important; }

.height-460,
.h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important; }

.width-460,
.w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important; }

.height-465,
.h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important; }

.width-465,
.w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important; }

.height-470,
.h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important; }

.width-470,
.w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important; }

.height-475,
.h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important; }

.width-475,
.w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important; }

.height-480,
.h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important; }

.width-480,
.w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important; }

.height-485,
.h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important; }

.width-485,
.w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important; }

.height-490,
.h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important; }

.width-490,
.w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important; }

.height-495,
.h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important; }

.width-495,
.w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important; }

.height-500,
.h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important; }

.width-500,
.w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important; }

.height-auto {
  height: auto !important; }

.width-auto {
  width: auto !important; }

.height-percent-0,
.h-percent-0 {
  height: 0% !important;
  min-height: 0% !important;
  max-height: 0% !important; }

.width-percent-0,
.w-percent-0 {
  width: 0% !important;
  min-width: 0% !important;
  max-width: 0% !important; }

.height-percent-1,
.h-percent-1 {
  height: 1% !important;
  min-height: 1% !important;
  max-height: 1% !important; }

.width-percent-1,
.w-percent-1 {
  width: 1% !important;
  min-width: 1% !important;
  max-width: 1% !important; }

.height-percent-2,
.h-percent-2 {
  height: 2% !important;
  min-height: 2% !important;
  max-height: 2% !important; }

.width-percent-2,
.w-percent-2 {
  width: 2% !important;
  min-width: 2% !important;
  max-width: 2% !important; }

.height-percent-3,
.h-percent-3 {
  height: 3% !important;
  min-height: 3% !important;
  max-height: 3% !important; }

.width-percent-3,
.w-percent-3 {
  width: 3% !important;
  min-width: 3% !important;
  max-width: 3% !important; }

.height-percent-4,
.h-percent-4 {
  height: 4% !important;
  min-height: 4% !important;
  max-height: 4% !important; }

.width-percent-4,
.w-percent-4 {
  width: 4% !important;
  min-width: 4% !important;
  max-width: 4% !important; }

.height-percent-5,
.h-percent-5 {
  height: 5% !important;
  min-height: 5% !important;
  max-height: 5% !important; }

.width-percent-5,
.w-percent-5 {
  width: 5% !important;
  min-width: 5% !important;
  max-width: 5% !important; }

.height-percent-6,
.h-percent-6 {
  height: 6% !important;
  min-height: 6% !important;
  max-height: 6% !important; }

.width-percent-6,
.w-percent-6 {
  width: 6% !important;
  min-width: 6% !important;
  max-width: 6% !important; }

.height-percent-7,
.h-percent-7 {
  height: 7% !important;
  min-height: 7% !important;
  max-height: 7% !important; }

.width-percent-7,
.w-percent-7 {
  width: 7% !important;
  min-width: 7% !important;
  max-width: 7% !important; }

.height-percent-8,
.h-percent-8 {
  height: 8% !important;
  min-height: 8% !important;
  max-height: 8% !important; }

.width-percent-8,
.w-percent-8 {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important; }

.height-percent-9,
.h-percent-9 {
  height: 9% !important;
  min-height: 9% !important;
  max-height: 9% !important; }

.width-percent-9,
.w-percent-9 {
  width: 9% !important;
  min-width: 9% !important;
  max-width: 9% !important; }

.height-percent-10,
.h-percent-10 {
  height: 10% !important;
  min-height: 10% !important;
  max-height: 10% !important; }

.width-percent-10,
.w-percent-10 {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important; }

.height-percent-11,
.h-percent-11 {
  height: 11% !important;
  min-height: 11% !important;
  max-height: 11% !important; }

.width-percent-11,
.w-percent-11 {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important; }

.height-percent-12,
.h-percent-12 {
  height: 12% !important;
  min-height: 12% !important;
  max-height: 12% !important; }

.width-percent-12,
.w-percent-12 {
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important; }

.height-percent-13,
.h-percent-13 {
  height: 13% !important;
  min-height: 13% !important;
  max-height: 13% !important; }

.width-percent-13,
.w-percent-13 {
  width: 13% !important;
  min-width: 13% !important;
  max-width: 13% !important; }

.height-percent-14,
.h-percent-14 {
  height: 14% !important;
  min-height: 14% !important;
  max-height: 14% !important; }

.width-percent-14,
.w-percent-14 {
  width: 14% !important;
  min-width: 14% !important;
  max-width: 14% !important; }

.height-percent-15,
.h-percent-15 {
  height: 15% !important;
  min-height: 15% !important;
  max-height: 15% !important; }

.width-percent-15,
.w-percent-15 {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important; }

.height-percent-16,
.h-percent-16 {
  height: 16% !important;
  min-height: 16% !important;
  max-height: 16% !important; }

.width-percent-16,
.w-percent-16 {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important; }

.height-percent-17,
.h-percent-17 {
  height: 17% !important;
  min-height: 17% !important;
  max-height: 17% !important; }

.width-percent-17,
.w-percent-17 {
  width: 17% !important;
  min-width: 17% !important;
  max-width: 17% !important; }

.height-percent-18,
.h-percent-18 {
  height: 18% !important;
  min-height: 18% !important;
  max-height: 18% !important; }

.width-percent-18,
.w-percent-18 {
  width: 18% !important;
  min-width: 18% !important;
  max-width: 18% !important; }

.height-percent-19,
.h-percent-19 {
  height: 19% !important;
  min-height: 19% !important;
  max-height: 19% !important; }

.width-percent-19,
.w-percent-19 {
  width: 19% !important;
  min-width: 19% !important;
  max-width: 19% !important; }

.height-percent-20,
.h-percent-20 {
  height: 20% !important;
  min-height: 20% !important;
  max-height: 20% !important; }

.width-percent-20,
.w-percent-20 {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important; }

.height-percent-21,
.h-percent-21 {
  height: 21% !important;
  min-height: 21% !important;
  max-height: 21% !important; }

.width-percent-21,
.w-percent-21 {
  width: 21% !important;
  min-width: 21% !important;
  max-width: 21% !important; }

.height-percent-22,
.h-percent-22 {
  height: 22% !important;
  min-height: 22% !important;
  max-height: 22% !important; }

.width-percent-22,
.w-percent-22 {
  width: 22% !important;
  min-width: 22% !important;
  max-width: 22% !important; }

.height-percent-23,
.h-percent-23 {
  height: 23% !important;
  min-height: 23% !important;
  max-height: 23% !important; }

.width-percent-23,
.w-percent-23 {
  width: 23% !important;
  min-width: 23% !important;
  max-width: 23% !important; }

.height-percent-24,
.h-percent-24 {
  height: 24% !important;
  min-height: 24% !important;
  max-height: 24% !important; }

.width-percent-24,
.w-percent-24 {
  width: 24% !important;
  min-width: 24% !important;
  max-width: 24% !important; }

.height-percent-25,
.h-percent-25 {
  height: 25% !important;
  min-height: 25% !important;
  max-height: 25% !important; }

.width-percent-25,
.w-percent-25 {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important; }

.height-percent-26,
.h-percent-26 {
  height: 26% !important;
  min-height: 26% !important;
  max-height: 26% !important; }

.width-percent-26,
.w-percent-26 {
  width: 26% !important;
  min-width: 26% !important;
  max-width: 26% !important; }

.height-percent-27,
.h-percent-27 {
  height: 27% !important;
  min-height: 27% !important;
  max-height: 27% !important; }

.width-percent-27,
.w-percent-27 {
  width: 27% !important;
  min-width: 27% !important;
  max-width: 27% !important; }

.height-percent-28,
.h-percent-28 {
  height: 28% !important;
  min-height: 28% !important;
  max-height: 28% !important; }

.width-percent-28,
.w-percent-28 {
  width: 28% !important;
  min-width: 28% !important;
  max-width: 28% !important; }

.height-percent-29,
.h-percent-29 {
  height: 29% !important;
  min-height: 29% !important;
  max-height: 29% !important; }

.width-percent-29,
.w-percent-29 {
  width: 29% !important;
  min-width: 29% !important;
  max-width: 29% !important; }

.height-percent-30,
.h-percent-30 {
  height: 30% !important;
  min-height: 30% !important;
  max-height: 30% !important; }

.width-percent-30,
.w-percent-30 {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important; }

.height-percent-31,
.h-percent-31 {
  height: 31% !important;
  min-height: 31% !important;
  max-height: 31% !important; }

.width-percent-31,
.w-percent-31 {
  width: 31% !important;
  min-width: 31% !important;
  max-width: 31% !important; }

.height-percent-32,
.h-percent-32 {
  height: 32% !important;
  min-height: 32% !important;
  max-height: 32% !important; }

.width-percent-32,
.w-percent-32 {
  width: 32% !important;
  min-width: 32% !important;
  max-width: 32% !important; }

.height-percent-33,
.h-percent-33 {
  height: 33% !important;
  min-height: 33% !important;
  max-height: 33% !important; }

.width-percent-33,
.w-percent-33 {
  width: 33% !important;
  min-width: 33% !important;
  max-width: 33% !important; }

.height-percent-34,
.h-percent-34 {
  height: 34% !important;
  min-height: 34% !important;
  max-height: 34% !important; }

.width-percent-34,
.w-percent-34 {
  width: 34% !important;
  min-width: 34% !important;
  max-width: 34% !important; }

.height-percent-35,
.h-percent-35 {
  height: 35% !important;
  min-height: 35% !important;
  max-height: 35% !important; }

.width-percent-35,
.w-percent-35 {
  width: 35% !important;
  min-width: 35% !important;
  max-width: 35% !important; }

.height-percent-36,
.h-percent-36 {
  height: 36% !important;
  min-height: 36% !important;
  max-height: 36% !important; }

.width-percent-36,
.w-percent-36 {
  width: 36% !important;
  min-width: 36% !important;
  max-width: 36% !important; }

.height-percent-37,
.h-percent-37 {
  height: 37% !important;
  min-height: 37% !important;
  max-height: 37% !important; }

.width-percent-37,
.w-percent-37 {
  width: 37% !important;
  min-width: 37% !important;
  max-width: 37% !important; }

.height-percent-38,
.h-percent-38 {
  height: 38% !important;
  min-height: 38% !important;
  max-height: 38% !important; }

.width-percent-38,
.w-percent-38 {
  width: 38% !important;
  min-width: 38% !important;
  max-width: 38% !important; }

.height-percent-39,
.h-percent-39 {
  height: 39% !important;
  min-height: 39% !important;
  max-height: 39% !important; }

.width-percent-39,
.w-percent-39 {
  width: 39% !important;
  min-width: 39% !important;
  max-width: 39% !important; }

.height-percent-40,
.h-percent-40 {
  height: 40% !important;
  min-height: 40% !important;
  max-height: 40% !important; }

.width-percent-40,
.w-percent-40 {
  width: 40% !important;
  min-width: 40% !important;
  max-width: 40% !important; }

.height-percent-41,
.h-percent-41 {
  height: 41% !important;
  min-height: 41% !important;
  max-height: 41% !important; }

.width-percent-41,
.w-percent-41 {
  width: 41% !important;
  min-width: 41% !important;
  max-width: 41% !important; }

.height-percent-42,
.h-percent-42 {
  height: 42% !important;
  min-height: 42% !important;
  max-height: 42% !important; }

.width-percent-42,
.w-percent-42 {
  width: 42% !important;
  min-width: 42% !important;
  max-width: 42% !important; }

.height-percent-43,
.h-percent-43 {
  height: 43% !important;
  min-height: 43% !important;
  max-height: 43% !important; }

.width-percent-43,
.w-percent-43 {
  width: 43% !important;
  min-width: 43% !important;
  max-width: 43% !important; }

.height-percent-44,
.h-percent-44 {
  height: 44% !important;
  min-height: 44% !important;
  max-height: 44% !important; }

.width-percent-44,
.w-percent-44 {
  width: 44% !important;
  min-width: 44% !important;
  max-width: 44% !important; }

.height-percent-45,
.h-percent-45 {
  height: 45% !important;
  min-height: 45% !important;
  max-height: 45% !important; }

.width-percent-45,
.w-percent-45 {
  width: 45% !important;
  min-width: 45% !important;
  max-width: 45% !important; }

.height-percent-46,
.h-percent-46 {
  height: 46% !important;
  min-height: 46% !important;
  max-height: 46% !important; }

.width-percent-46,
.w-percent-46 {
  width: 46% !important;
  min-width: 46% !important;
  max-width: 46% !important; }

.height-percent-47,
.h-percent-47 {
  height: 47% !important;
  min-height: 47% !important;
  max-height: 47% !important; }

.width-percent-47,
.w-percent-47 {
  width: 47% !important;
  min-width: 47% !important;
  max-width: 47% !important; }

.height-percent-48,
.h-percent-48 {
  height: 48% !important;
  min-height: 48% !important;
  max-height: 48% !important; }

.width-percent-48,
.w-percent-48 {
  width: 48% !important;
  min-width: 48% !important;
  max-width: 48% !important; }

.height-percent-49,
.h-percent-49 {
  height: 49% !important;
  min-height: 49% !important;
  max-height: 49% !important; }

.width-percent-49,
.w-percent-49 {
  width: 49% !important;
  min-width: 49% !important;
  max-width: 49% !important; }

.height-percent-50,
.h-percent-50 {
  height: 50% !important;
  min-height: 50% !important;
  max-height: 50% !important; }

.width-percent-50,
.w-percent-50 {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important; }

.height-percent-51,
.h-percent-51 {
  height: 51% !important;
  min-height: 51% !important;
  max-height: 51% !important; }

.width-percent-51,
.w-percent-51 {
  width: 51% !important;
  min-width: 51% !important;
  max-width: 51% !important; }

.height-percent-52,
.h-percent-52 {
  height: 52% !important;
  min-height: 52% !important;
  max-height: 52% !important; }

.width-percent-52,
.w-percent-52 {
  width: 52% !important;
  min-width: 52% !important;
  max-width: 52% !important; }

.height-percent-53,
.h-percent-53 {
  height: 53% !important;
  min-height: 53% !important;
  max-height: 53% !important; }

.width-percent-53,
.w-percent-53 {
  width: 53% !important;
  min-width: 53% !important;
  max-width: 53% !important; }

.height-percent-54,
.h-percent-54 {
  height: 54% !important;
  min-height: 54% !important;
  max-height: 54% !important; }

.width-percent-54,
.w-percent-54 {
  width: 54% !important;
  min-width: 54% !important;
  max-width: 54% !important; }

.height-percent-55,
.h-percent-55 {
  height: 55% !important;
  min-height: 55% !important;
  max-height: 55% !important; }

.width-percent-55,
.w-percent-55 {
  width: 55% !important;
  min-width: 55% !important;
  max-width: 55% !important; }

.height-percent-56,
.h-percent-56 {
  height: 56% !important;
  min-height: 56% !important;
  max-height: 56% !important; }

.width-percent-56,
.w-percent-56 {
  width: 56% !important;
  min-width: 56% !important;
  max-width: 56% !important; }

.height-percent-57,
.h-percent-57 {
  height: 57% !important;
  min-height: 57% !important;
  max-height: 57% !important; }

.width-percent-57,
.w-percent-57 {
  width: 57% !important;
  min-width: 57% !important;
  max-width: 57% !important; }

.height-percent-58,
.h-percent-58 {
  height: 58% !important;
  min-height: 58% !important;
  max-height: 58% !important; }

.width-percent-58,
.w-percent-58 {
  width: 58% !important;
  min-width: 58% !important;
  max-width: 58% !important; }

.height-percent-59,
.h-percent-59 {
  height: 59% !important;
  min-height: 59% !important;
  max-height: 59% !important; }

.width-percent-59,
.w-percent-59 {
  width: 59% !important;
  min-width: 59% !important;
  max-width: 59% !important; }

.height-percent-60,
.h-percent-60 {
  height: 60% !important;
  min-height: 60% !important;
  max-height: 60% !important; }

.width-percent-60,
.w-percent-60 {
  width: 60% !important;
  min-width: 60% !important;
  max-width: 60% !important; }

.height-percent-61,
.h-percent-61 {
  height: 61% !important;
  min-height: 61% !important;
  max-height: 61% !important; }

.width-percent-61,
.w-percent-61 {
  width: 61% !important;
  min-width: 61% !important;
  max-width: 61% !important; }

.height-percent-62,
.h-percent-62 {
  height: 62% !important;
  min-height: 62% !important;
  max-height: 62% !important; }

.width-percent-62,
.w-percent-62 {
  width: 62% !important;
  min-width: 62% !important;
  max-width: 62% !important; }

.height-percent-63,
.h-percent-63 {
  height: 63% !important;
  min-height: 63% !important;
  max-height: 63% !important; }

.width-percent-63,
.w-percent-63 {
  width: 63% !important;
  min-width: 63% !important;
  max-width: 63% !important; }

.height-percent-64,
.h-percent-64 {
  height: 64% !important;
  min-height: 64% !important;
  max-height: 64% !important; }

.width-percent-64,
.w-percent-64 {
  width: 64% !important;
  min-width: 64% !important;
  max-width: 64% !important; }

.height-percent-65,
.h-percent-65 {
  height: 65% !important;
  min-height: 65% !important;
  max-height: 65% !important; }

.width-percent-65,
.w-percent-65 {
  width: 65% !important;
  min-width: 65% !important;
  max-width: 65% !important; }

.height-percent-66,
.h-percent-66 {
  height: 66% !important;
  min-height: 66% !important;
  max-height: 66% !important; }

.width-percent-66,
.w-percent-66 {
  width: 66% !important;
  min-width: 66% !important;
  max-width: 66% !important; }

.height-percent-67,
.h-percent-67 {
  height: 67% !important;
  min-height: 67% !important;
  max-height: 67% !important; }

.width-percent-67,
.w-percent-67 {
  width: 67% !important;
  min-width: 67% !important;
  max-width: 67% !important; }

.height-percent-68,
.h-percent-68 {
  height: 68% !important;
  min-height: 68% !important;
  max-height: 68% !important; }

.width-percent-68,
.w-percent-68 {
  width: 68% !important;
  min-width: 68% !important;
  max-width: 68% !important; }

.height-percent-69,
.h-percent-69 {
  height: 69% !important;
  min-height: 69% !important;
  max-height: 69% !important; }

.width-percent-69,
.w-percent-69 {
  width: 69% !important;
  min-width: 69% !important;
  max-width: 69% !important; }

.height-percent-70,
.h-percent-70 {
  height: 70% !important;
  min-height: 70% !important;
  max-height: 70% !important; }

.width-percent-70,
.w-percent-70 {
  width: 70% !important;
  min-width: 70% !important;
  max-width: 70% !important; }

.height-percent-71,
.h-percent-71 {
  height: 71% !important;
  min-height: 71% !important;
  max-height: 71% !important; }

.width-percent-71,
.w-percent-71 {
  width: 71% !important;
  min-width: 71% !important;
  max-width: 71% !important; }

.height-percent-72,
.h-percent-72 {
  height: 72% !important;
  min-height: 72% !important;
  max-height: 72% !important; }

.width-percent-72,
.w-percent-72 {
  width: 72% !important;
  min-width: 72% !important;
  max-width: 72% !important; }

.height-percent-73,
.h-percent-73 {
  height: 73% !important;
  min-height: 73% !important;
  max-height: 73% !important; }

.width-percent-73,
.w-percent-73 {
  width: 73% !important;
  min-width: 73% !important;
  max-width: 73% !important; }

.height-percent-74,
.h-percent-74 {
  height: 74% !important;
  min-height: 74% !important;
  max-height: 74% !important; }

.width-percent-74,
.w-percent-74 {
  width: 74% !important;
  min-width: 74% !important;
  max-width: 74% !important; }

.height-percent-75,
.h-percent-75 {
  height: 75% !important;
  min-height: 75% !important;
  max-height: 75% !important; }

.width-percent-75,
.w-percent-75 {
  width: 75% !important;
  min-width: 75% !important;
  max-width: 75% !important; }

.height-percent-76,
.h-percent-76 {
  height: 76% !important;
  min-height: 76% !important;
  max-height: 76% !important; }

.width-percent-76,
.w-percent-76 {
  width: 76% !important;
  min-width: 76% !important;
  max-width: 76% !important; }

.height-percent-77,
.h-percent-77 {
  height: 77% !important;
  min-height: 77% !important;
  max-height: 77% !important; }

.width-percent-77,
.w-percent-77 {
  width: 77% !important;
  min-width: 77% !important;
  max-width: 77% !important; }

.height-percent-78,
.h-percent-78 {
  height: 78% !important;
  min-height: 78% !important;
  max-height: 78% !important; }

.width-percent-78,
.w-percent-78 {
  width: 78% !important;
  min-width: 78% !important;
  max-width: 78% !important; }

.height-percent-79,
.h-percent-79 {
  height: 79% !important;
  min-height: 79% !important;
  max-height: 79% !important; }

.width-percent-79,
.w-percent-79 {
  width: 79% !important;
  min-width: 79% !important;
  max-width: 79% !important; }

.height-percent-80,
.h-percent-80 {
  height: 80% !important;
  min-height: 80% !important;
  max-height: 80% !important; }

.width-percent-80,
.w-percent-80 {
  width: 80% !important;
  min-width: 80% !important;
  max-width: 80% !important; }

.height-percent-81,
.h-percent-81 {
  height: 81% !important;
  min-height: 81% !important;
  max-height: 81% !important; }

.width-percent-81,
.w-percent-81 {
  width: 81% !important;
  min-width: 81% !important;
  max-width: 81% !important; }

.height-percent-82,
.h-percent-82 {
  height: 82% !important;
  min-height: 82% !important;
  max-height: 82% !important; }

.width-percent-82,
.w-percent-82 {
  width: 82% !important;
  min-width: 82% !important;
  max-width: 82% !important; }

.height-percent-83,
.h-percent-83 {
  height: 83% !important;
  min-height: 83% !important;
  max-height: 83% !important; }

.width-percent-83,
.w-percent-83 {
  width: 83% !important;
  min-width: 83% !important;
  max-width: 83% !important; }

.height-percent-84,
.h-percent-84 {
  height: 84% !important;
  min-height: 84% !important;
  max-height: 84% !important; }

.width-percent-84,
.w-percent-84 {
  width: 84% !important;
  min-width: 84% !important;
  max-width: 84% !important; }

.height-percent-85,
.h-percent-85 {
  height: 85% !important;
  min-height: 85% !important;
  max-height: 85% !important; }

.width-percent-85,
.w-percent-85 {
  width: 85% !important;
  min-width: 85% !important;
  max-width: 85% !important; }

.height-percent-86,
.h-percent-86 {
  height: 86% !important;
  min-height: 86% !important;
  max-height: 86% !important; }

.width-percent-86,
.w-percent-86 {
  width: 86% !important;
  min-width: 86% !important;
  max-width: 86% !important; }

.height-percent-87,
.h-percent-87 {
  height: 87% !important;
  min-height: 87% !important;
  max-height: 87% !important; }

.width-percent-87,
.w-percent-87 {
  width: 87% !important;
  min-width: 87% !important;
  max-width: 87% !important; }

.height-percent-88,
.h-percent-88 {
  height: 88% !important;
  min-height: 88% !important;
  max-height: 88% !important; }

.width-percent-88,
.w-percent-88 {
  width: 88% !important;
  min-width: 88% !important;
  max-width: 88% !important; }

.height-percent-89,
.h-percent-89 {
  height: 89% !important;
  min-height: 89% !important;
  max-height: 89% !important; }

.width-percent-89,
.w-percent-89 {
  width: 89% !important;
  min-width: 89% !important;
  max-width: 89% !important; }

.height-percent-90,
.h-percent-90 {
  height: 90% !important;
  min-height: 90% !important;
  max-height: 90% !important; }

.width-percent-90,
.w-percent-90 {
  width: 90% !important;
  min-width: 90% !important;
  max-width: 90% !important; }

.height-percent-91,
.h-percent-91 {
  height: 91% !important;
  min-height: 91% !important;
  max-height: 91% !important; }

.width-percent-91,
.w-percent-91 {
  width: 91% !important;
  min-width: 91% !important;
  max-width: 91% !important; }

.height-percent-92,
.h-percent-92 {
  height: 92% !important;
  min-height: 92% !important;
  max-height: 92% !important; }

.width-percent-92,
.w-percent-92 {
  width: 92% !important;
  min-width: 92% !important;
  max-width: 92% !important; }

.height-percent-93,
.h-percent-93 {
  height: 93% !important;
  min-height: 93% !important;
  max-height: 93% !important; }

.width-percent-93,
.w-percent-93 {
  width: 93% !important;
  min-width: 93% !important;
  max-width: 93% !important; }

.height-percent-94,
.h-percent-94 {
  height: 94% !important;
  min-height: 94% !important;
  max-height: 94% !important; }

.width-percent-94,
.w-percent-94 {
  width: 94% !important;
  min-width: 94% !important;
  max-width: 94% !important; }

.height-percent-95,
.h-percent-95 {
  height: 95% !important;
  min-height: 95% !important;
  max-height: 95% !important; }

.width-percent-95,
.w-percent-95 {
  width: 95% !important;
  min-width: 95% !important;
  max-width: 95% !important; }

.height-percent-96,
.h-percent-96 {
  height: 96% !important;
  min-height: 96% !important;
  max-height: 96% !important; }

.width-percent-96,
.w-percent-96 {
  width: 96% !important;
  min-width: 96% !important;
  max-width: 96% !important; }

.height-percent-97,
.h-percent-97 {
  height: 97% !important;
  min-height: 97% !important;
  max-height: 97% !important; }

.width-percent-97,
.w-percent-97 {
  width: 97% !important;
  min-width: 97% !important;
  max-width: 97% !important; }

.height-percent-98,
.h-percent-98 {
  height: 98% !important;
  min-height: 98% !important;
  max-height: 98% !important; }

.width-percent-98,
.w-percent-98 {
  width: 98% !important;
  min-width: 98% !important;
  max-width: 98% !important; }

.height-percent-99,
.h-percent-99 {
  height: 99% !important;
  min-height: 99% !important;
  max-height: 99% !important; }

.width-percent-99,
.w-percent-99 {
  width: 99% !important;
  min-width: 99% !important;
  max-width: 99% !important; }

.height-percent-100,
.h-percent-100 {
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important; }

.width-percent-100,
.w-percent-100 {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important; }

.height-8,
.h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important; }

.width-8,
.w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important; }

.height-16,
.h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important; }

.width-16,
.w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important; }

.height-24,
.h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important; }

.width-24,
.w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important; }

.height-32,
.h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important; }

.width-32,
.w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important; }

.height-48,
.h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important; }

.width-48,
.w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important; }

.height-56,
.h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important; }

.width-56,
.w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important; }

.height-64,
.h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important; }

.width-64,
.w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important; }

.height-72,
.h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important; }

.width-72,
.w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important; }

.height-88,
.h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important; }

.width-88,
.w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important; }

.height-96,
.h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important; }

.width-96,
.w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important; }

.height-104,
.h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important; }

.width-104,
.w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important; }

.height-112,
.h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important; }

.width-112,
.w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important; }

.height-128,
.h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important; }

.width-128,
.w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important; }

.height-136,
.h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important; }

.width-136,
.w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important; }

.height-144,
.h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important; }

.width-144,
.w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important; }

.height-152,
.h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important; }

.width-152,
.w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important; }

.height-168,
.h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important; }

.width-168,
.w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important; }

.height-176,
.h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important; }

.width-176,
.w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important; }

.height-184,
.h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important; }

.width-184,
.w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important; }

.height-192,
.h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important; }

.width-192,
.w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important; }

.height-208,
.h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important; }

.width-208,
.w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important; }

.height-216,
.h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important; }

.width-216,
.w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important; }

.height-224,
.h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important; }

.width-224,
.w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important; }

.height-232,
.h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important; }

.width-232,
.w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important; }

.height-248,
.h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important; }

.width-248,
.w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important; }

.height-256,
.h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important; }

.width-256,
.w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important; }

.height-264,
.h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important; }

.width-264,
.w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important; }

.height-272,
.h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important; }

.width-272,
.w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important; }

.height-288,
.h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important; }

.width-288,
.w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important; }

.height-296,
.h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important; }

.width-296,
.w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important; }

.height-304,
.h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important; }

.width-304,
.w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important; }

.height-312,
.h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important; }

.width-312,
.w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important; }

.height-328,
.h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important; }

.width-328,
.w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important; }

.height-336,
.h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important; }

.width-336,
.w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important; }

.height-344,
.h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important; }

.width-344,
.w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important; }

.height-352,
.h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important; }

.width-352,
.w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important; }

.height-368,
.h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important; }

.width-368,
.w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important; }

.height-376,
.h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important; }

.width-376,
.w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important; }

.height-384,
.h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important; }

.width-384,
.w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important; }

.height-392,
.h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important; }

.width-392,
.w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important; }

.height-408,
.h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important; }

.width-408,
.w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important; }

.height-416,
.h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important; }

.width-416,
.w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important; }

.height-424,
.h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important; }

.width-424,
.w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important; }

.height-432,
.h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important; }

.width-432,
.w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important; }

.height-448,
.h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important; }

.width-448,
.w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important; }

.height-456,
.h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important; }

.width-456,
.w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important; }

.height-464,
.h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important; }

.width-464,
.w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important; }

.height-472,
.h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important; }

.width-472,
.w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important; }

.height-488,
.h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important; }

.width-488,
.w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important; }

.height-496,
.h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important; }

.width-496,
.w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important; }

.height-504,
.h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important; }

.width-504,
.w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important; }

.height-512,
.h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important; }

.width-512,
.w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important; }

.height-528,
.h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important; }

.width-528,
.w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important; }

.height-536,
.h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important; }

.width-536,
.w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important; }

.height-544,
.h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important; }

.width-544,
.w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important; }

.height-552,
.h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important; }

.width-552,
.w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important; }

.height-568,
.h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important; }

.width-568,
.w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important; }

.height-576,
.h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important; }

.width-576,
.w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important; }

.height-584,
.h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important; }

.width-584,
.w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important; }

.height-592,
.h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important; }

.width-592,
.w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important; }

.height-608,
.h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important; }

.width-608,
.w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important; }

.height-616,
.h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important; }

.width-616,
.w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important; }

.height-624,
.h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important; }

.width-624,
.w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important; }

.height-632,
.h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important; }

.width-632,
.w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important; }

.height-648,
.h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important; }

.width-648,
.w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important; }

.height-656,
.h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important; }

.width-656,
.w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important; }

.height-664,
.h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important; }

.width-664,
.w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important; }

.height-672,
.h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important; }

.width-672,
.w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important; }

.height-688,
.h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important; }

.width-688,
.w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important; }

.height-696,
.h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important; }

.width-696,
.w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important; }

.height-704,
.h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important; }

.width-704,
.w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important; }

.height-712,
.h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important; }

.width-712,
.w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important; }

.height-728,
.h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important; }

.width-728,
.w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important; }

.height-736,
.h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important; }

.width-736,
.w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important; }

.height-744,
.h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important; }

.width-744,
.w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important; }

.height-752,
.h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important; }

.width-752,
.w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important; }

.height-768,
.h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important; }

.width-768,
.w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important; }

.height-776,
.h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important; }

.width-776,
.w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important; }

.height-784,
.h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important; }

.width-784,
.w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important; }

.height-792,
.h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important; }

.width-792,
.w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important; }

.opacity-0-0 {
  opacity: 0 !important; }

.opacity-0-1 {
  opacity: 0.1 !important; }

.opacity-0-2 {
  opacity: 0.2 !important; }

.opacity-0-3 {
  opacity: 0.3 !important; }

.opacity-0-4 {
  opacity: 0.4 !important; }

.opacity-0-5 {
  opacity: 0.5 !important; }

.opacity-0-6 {
  opacity: 0.6 !important; }

.opacity-0-7 {
  opacity: 0.7 !important; }

.opacity-0-8 {
  opacity: 0.8 !important; }

.opacity-0-9 {
  opacity: 0.9 !important; }

.opacity-0-10 {
  opacity: 1 !important; }

.border,
.b {
  border: 1px solid rgba(0, 0, 0, 0.12); }

.border-top,
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.border-right,
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-bottom,
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.border-left,
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.border-horizontal,
.bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-vertical,
.bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.brad-1 {
  border-radius: 1px; }

.brad-2 {
  border-radius: 2px; }

.brad-3 {
  border-radius: 3px; }

.brad-4 {
  border-radius: 4px; }

.brad-5 {
  border-radius: 5px; }

.brad-6 {
  border-radius: 6px; }

.brad-7 {
  border-radius: 7px; }

.brad-8 {
  border-radius: 8px; }

.brad-9 {
  border-radius: 9px; }

.brad-10 {
  border-radius: 10px; }

.brad-11 {
  border-radius: 11px; }

.brad-12 {
  border-radius: 12px; }

/*-----------------------------------------------------------------------*/
/* Contains custom elements, components and general styles that
/* follows Google(TM) Material Design(TM) specs
/*
/* https://www.google.com/design/spec/material-design/introduction.html
/*-----------------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Avatars
/*----------------------------------------------------------------*/
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF; }
  .avatar.square {
    border-radius: 0; }
  .avatar.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px; }
  .avatar.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px; }
  .avatar.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px; }

/*----------------------------------------------------------------*/
/*  Forms
/*----------------------------------------------------------------*/
.form-wrapper {
  background: #FFFFFF;
  padding: 16px; }
  .form-wrapper .form-title {
    font-size: 21px;
    padding: 8px 0; }

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px; }
  .navigation-simple .item md-icon {
    margin: 0 16px 0 0; }
  .navigation-simple .item .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 1; }
  .navigation-simple .item.selected {
    background-color: rgba(0, 0, 0, 0.06); }

.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px; }
  .navigation-simple .subheader.light {
    border-top: 1px solid rgba(255, 255, 255, 0.12); }

.navigation-simple md-divider {
  margin: 8px 0; }

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left; }
  table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    min-width: 120px; }
    table.simple thead tr th:first-child {
      padding-left: 24px; }
    table.simple thead tr th:last-child {
      padding-right: 24px; }
  table.simple tbody tr td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    table.simple tbody tr td:first-child {
      padding-left: 24px; }
    table.simple tbody tr td:last-child {
      padding-right: 24px; }
  table.simple tbody tr:last-child td {
    border-bottom: none; }
  table.simple.clickable tbody tr {
    cursor: pointer; }
    table.simple.clickable tbody tr:hover {
      background: rgba(0, 0, 0, 0.03); }

.div-generic-search-autocomplete .div-autocomplete-generic-search-autocomplete > md-icon {
  margin-top: 25px;
  margin-bottom: 0; }

.div-generic-search-autocomplete .div-autocomplete-generic-search-autocomplete md-input-container {
  margin: 18px 0; }

/*----------------------------------------------------------------*/
/*  Colors
/*----------------------------------------------------------------*/
.black-fg,
.black-text {
  color: rgba(0, 0, 0, 0.87) !important; }
  .black-fg.secondary-text,
  .black-fg .secondary-text, .black-fg.icon,
  .black-fg .icon,
  .black-text.secondary-text,
  .black-text .secondary-text,
  .black-text.icon,
  .black-text .icon {
    color: rgba(0, 0, 0, 0.54) !important; }
  .black-fg.hint-text,
  .black-fg .hint-text, .black-fg.disabled-text,
  .black-fg .disabled-text,
  .black-text.hint-text,
  .black-text .hint-text,
  .black-text.disabled-text,
  .black-text .disabled-text {
    color: rgba(0, 0, 0, 0.26) !important; }
  .black-fg.divider,
  .black-fg .divider, .black-fg.faint-text,
  .black-fg .faint-text,
  .black-text.divider,
  .black-text .divider,
  .black-text.faint-text,
  .black-text .faint-text {
    color: rgba(0, 0, 0, 0.12) !important; }

.white-fg,
.white-text {
  color: white !important; }
  .white-fg.secondary-text,
  .white-fg .secondary-text, .white-fg.icon,
  .white-fg .icon,
  .white-text.secondary-text,
  .white-text .secondary-text,
  .white-text.icon,
  .white-text .icon {
    color: rgba(255, 255, 255, 0.7) !important; }
  .white-fg.hint-text,
  .white-fg .hint-text, .white-fg.disabled-text,
  .white-fg .disabled-text,
  .white-text.hint-text,
  .white-text .hint-text,
  .white-text.disabled-text,
  .white-text .disabled-text {
    color: rgba(255, 255, 255, 0.3) !important; }
  .white-fg.divider,
  .white-fg .divider, .white-fg.faint-text,
  .white-fg .faint-text,
  .white-text.divider,
  .white-text .divider,
  .white-text.faint-text,
  .white-text .faint-text {
    color: rgba(255, 255, 255, 0.12) !important; }
  .white-fg md-icon,
  .white-text md-icon {
    color: #FFFFFF; }
  .white-fg md-progress-linear .md-container,
  .white-text md-progress-linear .md-container {
    background: rgba(255, 255, 255, 0.3); }
  .white-fg md-progress-linear .md-bar,
  .white-text md-progress-linear .md-bar {
    background: rgba(255, 255, 255, 0.7); }

.red-50-bg {
  background-color: #FFEBEE !important; }

.md-red-50-bg {
  background-color: #FFEBEE !important;
  color: rgba(0, 0, 0, 0.87); }

.red-50-fg {
  color: #FFEBEE !important; }

.red-50-border {
  border-color: #FFEBEE !important; }

.red-50-border-top {
  border-top-color: #FFEBEE !important; }

.red-50-border-right {
  border-right-color: #FFEBEE !important; }

.red-50-border-bottom {
  border-bottom-color: #FFEBEE !important; }

.red-50-border-left {
  border-left-color: #FFEBEE !important; }

.red-100-bg {
  background-color: #FFCDD2 !important; }

.md-red-100-bg {
  background-color: #FFCDD2 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-100-fg {
  color: #FFCDD2 !important; }

.red-100-border {
  border-color: #FFCDD2 !important; }

.red-100-border-top {
  border-top-color: #FFCDD2 !important; }

.red-100-border-right {
  border-right-color: #FFCDD2 !important; }

.red-100-border-bottom {
  border-bottom-color: #FFCDD2 !important; }

.red-100-border-left {
  border-left-color: #FFCDD2 !important; }

.red-200-bg {
  background-color: #EF9A9A !important; }

.md-red-200-bg {
  background-color: #EF9A9A !important;
  color: rgba(0, 0, 0, 0.87); }

.red-200-fg {
  color: #EF9A9A !important; }

.red-200-border {
  border-color: #EF9A9A !important; }

.red-200-border-top {
  border-top-color: #EF9A9A !important; }

.red-200-border-right {
  border-right-color: #EF9A9A !important; }

.red-200-border-bottom {
  border-bottom-color: #EF9A9A !important; }

.red-200-border-left {
  border-left-color: #EF9A9A !important; }

.red-300-bg {
  background-color: #E57373 !important; }

.md-red-300-bg {
  background-color: #E57373 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-300-fg {
  color: #E57373 !important; }

.red-300-border {
  border-color: #E57373 !important; }

.red-300-border-top {
  border-top-color: #E57373 !important; }

.red-300-border-right {
  border-right-color: #E57373 !important; }

.red-300-border-bottom {
  border-bottom-color: #E57373 !important; }

.red-300-border-left {
  border-left-color: #E57373 !important; }

.red-400-bg {
  background-color: #EF5350 !important; }

.md-red-400-bg {
  background-color: #EF5350 !important;
  color: white; }

.red-400-fg {
  color: #EF5350 !important; }

.red-400-border {
  border-color: #EF5350 !important; }

.red-400-border-top {
  border-top-color: #EF5350 !important; }

.red-400-border-right {
  border-right-color: #EF5350 !important; }

.red-400-border-bottom {
  border-bottom-color: #EF5350 !important; }

.red-400-border-left {
  border-left-color: #EF5350 !important; }

.red-500-bg {
  background-color: #F44336 !important; }

.md-red-500-bg {
  background-color: #F44336 !important;
  color: white; }

.red-500-fg, .event-report table.md-table .event-occurrence .td-event-type,
.event-report table.md-table .event-occurrence [class*="icon-"] {
  color: #F44336 !important; }

.red-500-border {
  border-color: #F44336 !important; }

.red-500-border-top {
  border-top-color: #F44336 !important; }

.red-500-border-right {
  border-right-color: #F44336 !important; }

.red-500-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-500-border-left {
  border-left-color: #F44336 !important; }

.md-red-bg {
  background-color: #F44336 !important;
  color: white; }

.red-bg {
  background-color: #F44336 !important; }

.red-fg {
  color: #F44336 !important; }

.red-border {
  border-color: #F44336 !important; }

.red-border-top {
  border-top-color: #F44336 !important; }

.red-border-right {
  border-right-color: #F44336 !important; }

.red-border-bottom {
  border-bottom-color: #F44336 !important; }

.red-border-left {
  border-left-color: #F44336 !important; }

.red-600-bg {
  background-color: #E53935 !important; }

.md-red-600-bg {
  background-color: #E53935 !important;
  color: white; }

.red-600-fg {
  color: #E53935 !important; }

.red-600-border {
  border-color: #E53935 !important; }

.red-600-border-top {
  border-top-color: #E53935 !important; }

.red-600-border-right {
  border-right-color: #E53935 !important; }

.red-600-border-bottom {
  border-bottom-color: #E53935 !important; }

.red-600-border-left {
  border-left-color: #E53935 !important; }

.red-700-bg {
  background-color: #D32F2F !important; }

.md-red-700-bg {
  background-color: #D32F2F !important;
  color: white; }

.red-700-fg {
  color: #D32F2F !important; }

.red-700-border {
  border-color: #D32F2F !important; }

.red-700-border-top {
  border-top-color: #D32F2F !important; }

.red-700-border-right {
  border-right-color: #D32F2F !important; }

.red-700-border-bottom {
  border-bottom-color: #D32F2F !important; }

.red-700-border-left {
  border-left-color: #D32F2F !important; }

.red-800-bg {
  background-color: #C62828 !important; }

.md-red-800-bg {
  background-color: #C62828 !important;
  color: rgba(255, 255, 255, 0.87); }

.red-800-fg {
  color: #C62828 !important; }

.red-800-border {
  border-color: #C62828 !important; }

.red-800-border-top {
  border-top-color: #C62828 !important; }

.red-800-border-right {
  border-right-color: #C62828 !important; }

.red-800-border-bottom {
  border-bottom-color: #C62828 !important; }

.red-800-border-left {
  border-left-color: #C62828 !important; }

.red-900-bg {
  background-color: #B71C1C !important; }

.md-red-900-bg {
  background-color: #B71C1C !important;
  color: rgba(255, 255, 255, 0.87); }

.red-900-fg {
  color: #B71C1C !important; }

.red-900-border {
  border-color: #B71C1C !important; }

.red-900-border-top {
  border-top-color: #B71C1C !important; }

.red-900-border-right {
  border-right-color: #B71C1C !important; }

.red-900-border-bottom {
  border-bottom-color: #B71C1C !important; }

.red-900-border-left {
  border-left-color: #B71C1C !important; }

.red-A100-bg {
  background-color: #FF8A80 !important; }

.md-red-A100-bg {
  background-color: #FF8A80 !important;
  color: rgba(0, 0, 0, 0.87); }

.red-A100-fg {
  color: #FF8A80 !important; }

.red-A100-border {
  border-color: #FF8A80 !important; }

.red-A100-border-top {
  border-top-color: #FF8A80 !important; }

.red-A100-border-right {
  border-right-color: #FF8A80 !important; }

.red-A100-border-bottom {
  border-bottom-color: #FF8A80 !important; }

.red-A100-border-left {
  border-left-color: #FF8A80 !important; }

.red-A200-bg {
  background-color: #FF5252 !important; }

.md-red-A200-bg {
  background-color: #FF5252 !important;
  color: white; }

.red-A200-fg {
  color: #FF5252 !important; }

.red-A200-border {
  border-color: #FF5252 !important; }

.red-A200-border-top {
  border-top-color: #FF5252 !important; }

.red-A200-border-right {
  border-right-color: #FF5252 !important; }

.red-A200-border-bottom {
  border-bottom-color: #FF5252 !important; }

.red-A200-border-left {
  border-left-color: #FF5252 !important; }

.red-A400-bg {
  background-color: #FF1744 !important; }

.md-red-A400-bg {
  background-color: #FF1744 !important;
  color: white; }

.red-A400-fg {
  color: #FF1744 !important; }

.red-A400-border {
  border-color: #FF1744 !important; }

.red-A400-border-top {
  border-top-color: #FF1744 !important; }

.red-A400-border-right {
  border-right-color: #FF1744 !important; }

.red-A400-border-bottom {
  border-bottom-color: #FF1744 !important; }

.red-A400-border-left {
  border-left-color: #FF1744 !important; }

.red-A700-bg {
  background-color: #D50000 !important; }

.md-red-A700-bg {
  background-color: #D50000 !important;
  color: white; }

.red-A700-fg {
  color: #D50000 !important; }

.red-A700-border {
  border-color: #D50000 !important; }

.red-A700-border-top {
  border-top-color: #D50000 !important; }

.red-A700-border-right {
  border-right-color: #D50000 !important; }

.red-A700-border-bottom {
  border-bottom-color: #D50000 !important; }

.red-A700-border-left {
  border-left-color: #D50000 !important; }

.pink-50-bg {
  background-color: #FCE4EC !important; }

.md-pink-50-bg {
  background-color: #FCE4EC !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-50-fg {
  color: #FCE4EC !important; }

.pink-50-border {
  border-color: #FCE4EC !important; }

.pink-50-border-top {
  border-top-color: #FCE4EC !important; }

.pink-50-border-right {
  border-right-color: #FCE4EC !important; }

.pink-50-border-bottom {
  border-bottom-color: #FCE4EC !important; }

.pink-50-border-left {
  border-left-color: #FCE4EC !important; }

.pink-100-bg {
  background-color: #F8BBD0 !important; }

.md-pink-100-bg {
  background-color: #F8BBD0 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-100-fg {
  color: #F8BBD0 !important; }

.pink-100-border {
  border-color: #F8BBD0 !important; }

.pink-100-border-top {
  border-top-color: #F8BBD0 !important; }

.pink-100-border-right {
  border-right-color: #F8BBD0 !important; }

.pink-100-border-bottom {
  border-bottom-color: #F8BBD0 !important; }

.pink-100-border-left {
  border-left-color: #F8BBD0 !important; }

.pink-200-bg {
  background-color: #F48FB1 !important; }

.md-pink-200-bg {
  background-color: #F48FB1 !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-200-fg {
  color: #F48FB1 !important; }

.pink-200-border {
  border-color: #F48FB1 !important; }

.pink-200-border-top {
  border-top-color: #F48FB1 !important; }

.pink-200-border-right {
  border-right-color: #F48FB1 !important; }

.pink-200-border-bottom {
  border-bottom-color: #F48FB1 !important; }

.pink-200-border-left {
  border-left-color: #F48FB1 !important; }

.pink-300-bg {
  background-color: #F06292 !important; }

.md-pink-300-bg {
  background-color: #F06292 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-300-fg {
  color: #F06292 !important; }

.pink-300-border {
  border-color: #F06292 !important; }

.pink-300-border-top {
  border-top-color: #F06292 !important; }

.pink-300-border-right {
  border-right-color: #F06292 !important; }

.pink-300-border-bottom {
  border-bottom-color: #F06292 !important; }

.pink-300-border-left {
  border-left-color: #F06292 !important; }

.pink-400-bg {
  background-color: #EC407A !important; }

.md-pink-400-bg {
  background-color: #EC407A !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-400-fg {
  color: #EC407A !important; }

.pink-400-border {
  border-color: #EC407A !important; }

.pink-400-border-top {
  border-top-color: #EC407A !important; }

.pink-400-border-right {
  border-right-color: #EC407A !important; }

.pink-400-border-bottom {
  border-bottom-color: #EC407A !important; }

.pink-400-border-left {
  border-left-color: #EC407A !important; }

.pink-500-bg {
  background-color: #E91E63 !important; }

.md-pink-500-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-500-fg {
  color: #E91E63 !important; }

.pink-500-border {
  border-color: #E91E63 !important; }

.pink-500-border-top {
  border-top-color: #E91E63 !important; }

.pink-500-border-right {
  border-right-color: #E91E63 !important; }

.pink-500-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-500-border-left {
  border-left-color: #E91E63 !important; }

.md-pink-bg {
  background-color: #E91E63 !important;
  color: white; }

.pink-bg {
  background-color: #E91E63 !important; }

.pink-fg {
  color: #E91E63 !important; }

.pink-border {
  border-color: #E91E63 !important; }

.pink-border-top {
  border-top-color: #E91E63 !important; }

.pink-border-right {
  border-right-color: #E91E63 !important; }

.pink-border-bottom {
  border-bottom-color: #E91E63 !important; }

.pink-border-left {
  border-left-color: #E91E63 !important; }

.pink-600-bg {
  background-color: #D81B60 !important; }

.md-pink-600-bg {
  background-color: #D81B60 !important;
  color: white; }

.pink-600-fg {
  color: #D81B60 !important; }

.pink-600-border {
  border-color: #D81B60 !important; }

.pink-600-border-top {
  border-top-color: #D81B60 !important; }

.pink-600-border-right {
  border-right-color: #D81B60 !important; }

.pink-600-border-bottom {
  border-bottom-color: #D81B60 !important; }

.pink-600-border-left {
  border-left-color: #D81B60 !important; }

.pink-700-bg {
  background-color: #C2185B !important; }

.md-pink-700-bg {
  background-color: #C2185B !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-700-fg {
  color: #C2185B !important; }

.pink-700-border {
  border-color: #C2185B !important; }

.pink-700-border-top {
  border-top-color: #C2185B !important; }

.pink-700-border-right {
  border-right-color: #C2185B !important; }

.pink-700-border-bottom {
  border-bottom-color: #C2185B !important; }

.pink-700-border-left {
  border-left-color: #C2185B !important; }

.pink-800-bg {
  background-color: #AD1457 !important; }

.md-pink-800-bg {
  background-color: #AD1457 !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-800-fg {
  color: #AD1457 !important; }

.pink-800-border {
  border-color: #AD1457 !important; }

.pink-800-border-top {
  border-top-color: #AD1457 !important; }

.pink-800-border-right {
  border-right-color: #AD1457 !important; }

.pink-800-border-bottom {
  border-bottom-color: #AD1457 !important; }

.pink-800-border-left {
  border-left-color: #AD1457 !important; }

.pink-900-bg {
  background-color: #880E4F !important; }

.md-pink-900-bg {
  background-color: #880E4F !important;
  color: rgba(255, 255, 255, 0.87); }

.pink-900-fg {
  color: #880E4F !important; }

.pink-900-border {
  border-color: #880E4F !important; }

.pink-900-border-top {
  border-top-color: #880E4F !important; }

.pink-900-border-right {
  border-right-color: #880E4F !important; }

.pink-900-border-bottom {
  border-bottom-color: #880E4F !important; }

.pink-900-border-left {
  border-left-color: #880E4F !important; }

.pink-A100-bg {
  background-color: #FF80AB !important; }

.md-pink-A100-bg {
  background-color: #FF80AB !important;
  color: rgba(0, 0, 0, 0.87); }

.pink-A100-fg {
  color: #FF80AB !important; }

.pink-A100-border {
  border-color: #FF80AB !important; }

.pink-A100-border-top {
  border-top-color: #FF80AB !important; }

.pink-A100-border-right {
  border-right-color: #FF80AB !important; }

.pink-A100-border-bottom {
  border-bottom-color: #FF80AB !important; }

.pink-A100-border-left {
  border-left-color: #FF80AB !important; }

.pink-A200-bg {
  background-color: #FF4081 !important; }

.md-pink-A200-bg {
  background-color: #FF4081 !important;
  color: white; }

.pink-A200-fg {
  color: #FF4081 !important; }

.pink-A200-border {
  border-color: #FF4081 !important; }

.pink-A200-border-top {
  border-top-color: #FF4081 !important; }

.pink-A200-border-right {
  border-right-color: #FF4081 !important; }

.pink-A200-border-bottom {
  border-bottom-color: #FF4081 !important; }

.pink-A200-border-left {
  border-left-color: #FF4081 !important; }

.pink-A400-bg {
  background-color: #F50057 !important; }

.md-pink-A400-bg {
  background-color: #F50057 !important;
  color: white; }

.pink-A400-fg {
  color: #F50057 !important; }

.pink-A400-border {
  border-color: #F50057 !important; }

.pink-A400-border-top {
  border-top-color: #F50057 !important; }

.pink-A400-border-right {
  border-right-color: #F50057 !important; }

.pink-A400-border-bottom {
  border-bottom-color: #F50057 !important; }

.pink-A400-border-left {
  border-left-color: #F50057 !important; }

.pink-A700-bg {
  background-color: #C51162 !important; }

.md-pink-A700-bg {
  background-color: #C51162 !important;
  color: white; }

.pink-A700-fg {
  color: #C51162 !important; }

.pink-A700-border {
  border-color: #C51162 !important; }

.pink-A700-border-top {
  border-top-color: #C51162 !important; }

.pink-A700-border-right {
  border-right-color: #C51162 !important; }

.pink-A700-border-bottom {
  border-bottom-color: #C51162 !important; }

.pink-A700-border-left {
  border-left-color: #C51162 !important; }

.purple-50-bg {
  background-color: #F3E5F5 !important; }

.md-purple-50-bg {
  background-color: #F3E5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-50-fg {
  color: #F3E5F5 !important; }

.purple-50-border {
  border-color: #F3E5F5 !important; }

.purple-50-border-top {
  border-top-color: #F3E5F5 !important; }

.purple-50-border-right {
  border-right-color: #F3E5F5 !important; }

.purple-50-border-bottom {
  border-bottom-color: #F3E5F5 !important; }

.purple-50-border-left {
  border-left-color: #F3E5F5 !important; }

.purple-100-bg {
  background-color: #E1BEE7 !important; }

.md-purple-100-bg {
  background-color: #E1BEE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-100-fg {
  color: #E1BEE7 !important; }

.purple-100-border {
  border-color: #E1BEE7 !important; }

.purple-100-border-top {
  border-top-color: #E1BEE7 !important; }

.purple-100-border-right {
  border-right-color: #E1BEE7 !important; }

.purple-100-border-bottom {
  border-bottom-color: #E1BEE7 !important; }

.purple-100-border-left {
  border-left-color: #E1BEE7 !important; }

.purple-200-bg {
  background-color: #CE93D8 !important; }

.md-purple-200-bg {
  background-color: #CE93D8 !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-200-fg {
  color: #CE93D8 !important; }

.purple-200-border {
  border-color: #CE93D8 !important; }

.purple-200-border-top {
  border-top-color: #CE93D8 !important; }

.purple-200-border-right {
  border-right-color: #CE93D8 !important; }

.purple-200-border-bottom {
  border-bottom-color: #CE93D8 !important; }

.purple-200-border-left {
  border-left-color: #CE93D8 !important; }

.purple-300-bg {
  background-color: #BA68C8 !important; }

.md-purple-300-bg {
  background-color: #BA68C8 !important;
  color: white; }

.purple-300-fg {
  color: #BA68C8 !important; }

.purple-300-border {
  border-color: #BA68C8 !important; }

.purple-300-border-top {
  border-top-color: #BA68C8 !important; }

.purple-300-border-right {
  border-right-color: #BA68C8 !important; }

.purple-300-border-bottom {
  border-bottom-color: #BA68C8 !important; }

.purple-300-border-left {
  border-left-color: #BA68C8 !important; }

.purple-400-bg {
  background-color: #AB47BC !important; }

.md-purple-400-bg {
  background-color: #AB47BC !important;
  color: white; }

.purple-400-fg {
  color: #AB47BC !important; }

.purple-400-border {
  border-color: #AB47BC !important; }

.purple-400-border-top {
  border-top-color: #AB47BC !important; }

.purple-400-border-right {
  border-right-color: #AB47BC !important; }

.purple-400-border-bottom {
  border-bottom-color: #AB47BC !important; }

.purple-400-border-left {
  border-left-color: #AB47BC !important; }

.purple-500-bg {
  background-color: #9C27B0 !important; }

.md-purple-500-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-500-fg {
  color: #9C27B0 !important; }

.purple-500-border {
  border-color: #9C27B0 !important; }

.purple-500-border-top {
  border-top-color: #9C27B0 !important; }

.purple-500-border-right {
  border-right-color: #9C27B0 !important; }

.purple-500-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-500-border-left {
  border-left-color: #9C27B0 !important; }

.md-purple-bg {
  background-color: #9C27B0 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-bg {
  background-color: #9C27B0 !important; }

.purple-fg {
  color: #9C27B0 !important; }

.purple-border {
  border-color: #9C27B0 !important; }

.purple-border-top {
  border-top-color: #9C27B0 !important; }

.purple-border-right {
  border-right-color: #9C27B0 !important; }

.purple-border-bottom {
  border-bottom-color: #9C27B0 !important; }

.purple-border-left {
  border-left-color: #9C27B0 !important; }

.purple-600-bg {
  background-color: #8E24AA !important; }

.md-purple-600-bg {
  background-color: #8E24AA !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-600-fg {
  color: #8E24AA !important; }

.purple-600-border {
  border-color: #8E24AA !important; }

.purple-600-border-top {
  border-top-color: #8E24AA !important; }

.purple-600-border-right {
  border-right-color: #8E24AA !important; }

.purple-600-border-bottom {
  border-bottom-color: #8E24AA !important; }

.purple-600-border-left {
  border-left-color: #8E24AA !important; }

.purple-700-bg {
  background-color: #7B1FA2 !important; }

.md-purple-700-bg {
  background-color: #7B1FA2 !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-700-fg {
  color: #7B1FA2 !important; }

.purple-700-border {
  border-color: #7B1FA2 !important; }

.purple-700-border-top {
  border-top-color: #7B1FA2 !important; }

.purple-700-border-right {
  border-right-color: #7B1FA2 !important; }

.purple-700-border-bottom {
  border-bottom-color: #7B1FA2 !important; }

.purple-700-border-left {
  border-left-color: #7B1FA2 !important; }

.purple-800-bg {
  background-color: #6A1B9A !important; }

.md-purple-800-bg {
  background-color: #6A1B9A !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-800-fg {
  color: #6A1B9A !important; }

.purple-800-border {
  border-color: #6A1B9A !important; }

.purple-800-border-top {
  border-top-color: #6A1B9A !important; }

.purple-800-border-right {
  border-right-color: #6A1B9A !important; }

.purple-800-border-bottom {
  border-bottom-color: #6A1B9A !important; }

.purple-800-border-left {
  border-left-color: #6A1B9A !important; }

.purple-900-bg {
  background-color: #4A148C !important; }

.md-purple-900-bg {
  background-color: #4A148C !important;
  color: rgba(255, 255, 255, 0.87); }

.purple-900-fg {
  color: #4A148C !important; }

.purple-900-border {
  border-color: #4A148C !important; }

.purple-900-border-top {
  border-top-color: #4A148C !important; }

.purple-900-border-right {
  border-right-color: #4A148C !important; }

.purple-900-border-bottom {
  border-bottom-color: #4A148C !important; }

.purple-900-border-left {
  border-left-color: #4A148C !important; }

.purple-A100-bg {
  background-color: #EA80FC !important; }

.md-purple-A100-bg {
  background-color: #EA80FC !important;
  color: rgba(0, 0, 0, 0.87); }

.purple-A100-fg {
  color: #EA80FC !important; }

.purple-A100-border {
  border-color: #EA80FC !important; }

.purple-A100-border-top {
  border-top-color: #EA80FC !important; }

.purple-A100-border-right {
  border-right-color: #EA80FC !important; }

.purple-A100-border-bottom {
  border-bottom-color: #EA80FC !important; }

.purple-A100-border-left {
  border-left-color: #EA80FC !important; }

.purple-A200-bg {
  background-color: #E040FB !important; }

.md-purple-A200-bg {
  background-color: #E040FB !important;
  color: white; }

.purple-A200-fg {
  color: #E040FB !important; }

.purple-A200-border {
  border-color: #E040FB !important; }

.purple-A200-border-top {
  border-top-color: #E040FB !important; }

.purple-A200-border-right {
  border-right-color: #E040FB !important; }

.purple-A200-border-bottom {
  border-bottom-color: #E040FB !important; }

.purple-A200-border-left {
  border-left-color: #E040FB !important; }

.purple-A400-bg {
  background-color: #D500F9 !important; }

.md-purple-A400-bg {
  background-color: #D500F9 !important;
  color: white; }

.purple-A400-fg {
  color: #D500F9 !important; }

.purple-A400-border {
  border-color: #D500F9 !important; }

.purple-A400-border-top {
  border-top-color: #D500F9 !important; }

.purple-A400-border-right {
  border-right-color: #D500F9 !important; }

.purple-A400-border-bottom {
  border-bottom-color: #D500F9 !important; }

.purple-A400-border-left {
  border-left-color: #D500F9 !important; }

.purple-A700-bg {
  background-color: #AA00FF !important; }

.md-purple-A700-bg {
  background-color: #AA00FF !important;
  color: white; }

.purple-A700-fg {
  color: #AA00FF !important; }

.purple-A700-border {
  border-color: #AA00FF !important; }

.purple-A700-border-top {
  border-top-color: #AA00FF !important; }

.purple-A700-border-right {
  border-right-color: #AA00FF !important; }

.purple-A700-border-bottom {
  border-bottom-color: #AA00FF !important; }

.purple-A700-border-left {
  border-left-color: #AA00FF !important; }

.deep-purple-50-bg {
  background-color: #EDE7F6 !important; }

.md-deep-purple-50-bg {
  background-color: #EDE7F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-50-fg {
  color: #EDE7F6 !important; }

.deep-purple-50-border {
  border-color: #EDE7F6 !important; }

.deep-purple-50-border-top {
  border-top-color: #EDE7F6 !important; }

.deep-purple-50-border-right {
  border-right-color: #EDE7F6 !important; }

.deep-purple-50-border-bottom {
  border-bottom-color: #EDE7F6 !important; }

.deep-purple-50-border-left {
  border-left-color: #EDE7F6 !important; }

.deep-purple-100-bg {
  background-color: #D1C4E9 !important; }

.md-deep-purple-100-bg {
  background-color: #D1C4E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-100-fg {
  color: #D1C4E9 !important; }

.deep-purple-100-border {
  border-color: #D1C4E9 !important; }

.deep-purple-100-border-top {
  border-top-color: #D1C4E9 !important; }

.deep-purple-100-border-right {
  border-right-color: #D1C4E9 !important; }

.deep-purple-100-border-bottom {
  border-bottom-color: #D1C4E9 !important; }

.deep-purple-100-border-left {
  border-left-color: #D1C4E9 !important; }

.deep-purple-200-bg {
  background-color: #B39DDB !important; }

.md-deep-purple-200-bg {
  background-color: #B39DDB !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-200-fg {
  color: #B39DDB !important; }

.deep-purple-200-border {
  border-color: #B39DDB !important; }

.deep-purple-200-border-top {
  border-top-color: #B39DDB !important; }

.deep-purple-200-border-right {
  border-right-color: #B39DDB !important; }

.deep-purple-200-border-bottom {
  border-bottom-color: #B39DDB !important; }

.deep-purple-200-border-left {
  border-left-color: #B39DDB !important; }

.deep-purple-300-bg {
  background-color: #9575CD !important; }

.md-deep-purple-300-bg {
  background-color: #9575CD !important;
  color: white; }

.deep-purple-300-fg {
  color: #9575CD !important; }

.deep-purple-300-border {
  border-color: #9575CD !important; }

.deep-purple-300-border-top {
  border-top-color: #9575CD !important; }

.deep-purple-300-border-right {
  border-right-color: #9575CD !important; }

.deep-purple-300-border-bottom {
  border-bottom-color: #9575CD !important; }

.deep-purple-300-border-left {
  border-left-color: #9575CD !important; }

.deep-purple-400-bg {
  background-color: #7E57C2 !important; }

.md-deep-purple-400-bg {
  background-color: #7E57C2 !important;
  color: white; }

.deep-purple-400-fg {
  color: #7E57C2 !important; }

.deep-purple-400-border {
  border-color: #7E57C2 !important; }

.deep-purple-400-border-top {
  border-top-color: #7E57C2 !important; }

.deep-purple-400-border-right {
  border-right-color: #7E57C2 !important; }

.deep-purple-400-border-bottom {
  border-bottom-color: #7E57C2 !important; }

.deep-purple-400-border-left {
  border-left-color: #7E57C2 !important; }

.deep-purple-500-bg {
  background-color: #673AB7 !important; }

.md-deep-purple-500-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-500-fg {
  color: #673AB7 !important; }

.deep-purple-500-border {
  border-color: #673AB7 !important; }

.deep-purple-500-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-500-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-500-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-500-border-left {
  border-left-color: #673AB7 !important; }

.md-deep-purple-bg {
  background-color: #673AB7 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-bg {
  background-color: #673AB7 !important; }

.deep-purple-fg {
  color: #673AB7 !important; }

.deep-purple-border {
  border-color: #673AB7 !important; }

.deep-purple-border-top {
  border-top-color: #673AB7 !important; }

.deep-purple-border-right {
  border-right-color: #673AB7 !important; }

.deep-purple-border-bottom {
  border-bottom-color: #673AB7 !important; }

.deep-purple-border-left {
  border-left-color: #673AB7 !important; }

.deep-purple-600-bg {
  background-color: #5E35B1 !important; }

.md-deep-purple-600-bg {
  background-color: #5E35B1 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-600-fg {
  color: #5E35B1 !important; }

.deep-purple-600-border {
  border-color: #5E35B1 !important; }

.deep-purple-600-border-top {
  border-top-color: #5E35B1 !important; }

.deep-purple-600-border-right {
  border-right-color: #5E35B1 !important; }

.deep-purple-600-border-bottom {
  border-bottom-color: #5E35B1 !important; }

.deep-purple-600-border-left {
  border-left-color: #5E35B1 !important; }

.deep-purple-700-bg {
  background-color: #512DA8 !important; }

.md-deep-purple-700-bg {
  background-color: #512DA8 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-700-fg {
  color: #512DA8 !important; }

.deep-purple-700-border {
  border-color: #512DA8 !important; }

.deep-purple-700-border-top {
  border-top-color: #512DA8 !important; }

.deep-purple-700-border-right {
  border-right-color: #512DA8 !important; }

.deep-purple-700-border-bottom {
  border-bottom-color: #512DA8 !important; }

.deep-purple-700-border-left {
  border-left-color: #512DA8 !important; }

.deep-purple-800-bg {
  background-color: #4527A0 !important; }

.md-deep-purple-800-bg {
  background-color: #4527A0 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-800-fg {
  color: #4527A0 !important; }

.deep-purple-800-border {
  border-color: #4527A0 !important; }

.deep-purple-800-border-top {
  border-top-color: #4527A0 !important; }

.deep-purple-800-border-right {
  border-right-color: #4527A0 !important; }

.deep-purple-800-border-bottom {
  border-bottom-color: #4527A0 !important; }

.deep-purple-800-border-left {
  border-left-color: #4527A0 !important; }

.deep-purple-900-bg {
  background-color: #311B92 !important; }

.md-deep-purple-900-bg {
  background-color: #311B92 !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-900-fg {
  color: #311B92 !important; }

.deep-purple-900-border {
  border-color: #311B92 !important; }

.deep-purple-900-border-top {
  border-top-color: #311B92 !important; }

.deep-purple-900-border-right {
  border-right-color: #311B92 !important; }

.deep-purple-900-border-bottom {
  border-bottom-color: #311B92 !important; }

.deep-purple-900-border-left {
  border-left-color: #311B92 !important; }

.deep-purple-A100-bg {
  background-color: #B388FF !important; }

.md-deep-purple-A100-bg {
  background-color: #B388FF !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-purple-A100-fg {
  color: #B388FF !important; }

.deep-purple-A100-border {
  border-color: #B388FF !important; }

.deep-purple-A100-border-top {
  border-top-color: #B388FF !important; }

.deep-purple-A100-border-right {
  border-right-color: #B388FF !important; }

.deep-purple-A100-border-bottom {
  border-bottom-color: #B388FF !important; }

.deep-purple-A100-border-left {
  border-left-color: #B388FF !important; }

.deep-purple-A200-bg {
  background-color: #7C4DFF !important; }

.md-deep-purple-A200-bg {
  background-color: #7C4DFF !important;
  color: white; }

.deep-purple-A200-fg {
  color: #7C4DFF !important; }

.deep-purple-A200-border {
  border-color: #7C4DFF !important; }

.deep-purple-A200-border-top {
  border-top-color: #7C4DFF !important; }

.deep-purple-A200-border-right {
  border-right-color: #7C4DFF !important; }

.deep-purple-A200-border-bottom {
  border-bottom-color: #7C4DFF !important; }

.deep-purple-A200-border-left {
  border-left-color: #7C4DFF !important; }

.deep-purple-A400-bg {
  background-color: #651FFF !important; }

.md-deep-purple-A400-bg {
  background-color: #651FFF !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A400-fg {
  color: #651FFF !important; }

.deep-purple-A400-border {
  border-color: #651FFF !important; }

.deep-purple-A400-border-top {
  border-top-color: #651FFF !important; }

.deep-purple-A400-border-right {
  border-right-color: #651FFF !important; }

.deep-purple-A400-border-bottom {
  border-bottom-color: #651FFF !important; }

.deep-purple-A400-border-left {
  border-left-color: #651FFF !important; }

.deep-purple-A700-bg {
  background-color: #6200EA !important; }

.md-deep-purple-A700-bg {
  background-color: #6200EA !important;
  color: rgba(255, 255, 255, 0.87); }

.deep-purple-A700-fg {
  color: #6200EA !important; }

.deep-purple-A700-border {
  border-color: #6200EA !important; }

.deep-purple-A700-border-top {
  border-top-color: #6200EA !important; }

.deep-purple-A700-border-right {
  border-right-color: #6200EA !important; }

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200EA !important; }

.deep-purple-A700-border-left {
  border-left-color: #6200EA !important; }

.indigo-50-bg {
  background-color: #E8EAF6 !important; }

.md-indigo-50-bg {
  background-color: #E8EAF6 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-50-fg {
  color: #E8EAF6 !important; }

.indigo-50-border {
  border-color: #E8EAF6 !important; }

.indigo-50-border-top {
  border-top-color: #E8EAF6 !important; }

.indigo-50-border-right {
  border-right-color: #E8EAF6 !important; }

.indigo-50-border-bottom {
  border-bottom-color: #E8EAF6 !important; }

.indigo-50-border-left {
  border-left-color: #E8EAF6 !important; }

.indigo-100-bg {
  background-color: #C5CAE9 !important; }

.md-indigo-100-bg {
  background-color: #C5CAE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-100-fg {
  color: #C5CAE9 !important; }

.indigo-100-border {
  border-color: #C5CAE9 !important; }

.indigo-100-border-top {
  border-top-color: #C5CAE9 !important; }

.indigo-100-border-right {
  border-right-color: #C5CAE9 !important; }

.indigo-100-border-bottom {
  border-bottom-color: #C5CAE9 !important; }

.indigo-100-border-left {
  border-left-color: #C5CAE9 !important; }

.indigo-200-bg {
  background-color: #9FA8DA !important; }

.md-indigo-200-bg {
  background-color: #9FA8DA !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-200-fg {
  color: #9FA8DA !important; }

.indigo-200-border {
  border-color: #9FA8DA !important; }

.indigo-200-border-top {
  border-top-color: #9FA8DA !important; }

.indigo-200-border-right {
  border-right-color: #9FA8DA !important; }

.indigo-200-border-bottom {
  border-bottom-color: #9FA8DA !important; }

.indigo-200-border-left {
  border-left-color: #9FA8DA !important; }

.indigo-300-bg {
  background-color: #7986CB !important; }

.md-indigo-300-bg {
  background-color: #7986CB !important;
  color: white; }

.indigo-300-fg {
  color: #7986CB !important; }

.indigo-300-border {
  border-color: #7986CB !important; }

.indigo-300-border-top {
  border-top-color: #7986CB !important; }

.indigo-300-border-right {
  border-right-color: #7986CB !important; }

.indigo-300-border-bottom {
  border-bottom-color: #7986CB !important; }

.indigo-300-border-left {
  border-left-color: #7986CB !important; }

.indigo-400-bg {
  background-color: #5C6BC0 !important; }

.md-indigo-400-bg {
  background-color: #5C6BC0 !important;
  color: white; }

.indigo-400-fg {
  color: #5C6BC0 !important; }

.indigo-400-border {
  border-color: #5C6BC0 !important; }

.indigo-400-border-top {
  border-top-color: #5C6BC0 !important; }

.indigo-400-border-right {
  border-right-color: #5C6BC0 !important; }

.indigo-400-border-bottom {
  border-bottom-color: #5C6BC0 !important; }

.indigo-400-border-left {
  border-left-color: #5C6BC0 !important; }

.indigo-500-bg {
  background-color: #3F51B5 !important; }

.md-indigo-500-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-500-fg {
  color: #3F51B5 !important; }

.indigo-500-border {
  border-color: #3F51B5 !important; }

.indigo-500-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-500-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-500-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-500-border-left {
  border-left-color: #3F51B5 !important; }

.md-indigo-bg {
  background-color: #3F51B5 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-bg {
  background-color: #3F51B5 !important; }

.indigo-fg {
  color: #3F51B5 !important; }

.indigo-border {
  border-color: #3F51B5 !important; }

.indigo-border-top {
  border-top-color: #3F51B5 !important; }

.indigo-border-right {
  border-right-color: #3F51B5 !important; }

.indigo-border-bottom {
  border-bottom-color: #3F51B5 !important; }

.indigo-border-left {
  border-left-color: #3F51B5 !important; }

.indigo-600-bg {
  background-color: #3949AB !important; }

.md-indigo-600-bg {
  background-color: #3949AB !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-600-fg {
  color: #3949AB !important; }

.indigo-600-border {
  border-color: #3949AB !important; }

.indigo-600-border-top {
  border-top-color: #3949AB !important; }

.indigo-600-border-right {
  border-right-color: #3949AB !important; }

.indigo-600-border-bottom {
  border-bottom-color: #3949AB !important; }

.indigo-600-border-left {
  border-left-color: #3949AB !important; }

.indigo-700-bg {
  background-color: #303F9F !important; }

.md-indigo-700-bg {
  background-color: #303F9F !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-700-fg {
  color: #303F9F !important; }

.indigo-700-border {
  border-color: #303F9F !important; }

.indigo-700-border-top {
  border-top-color: #303F9F !important; }

.indigo-700-border-right {
  border-right-color: #303F9F !important; }

.indigo-700-border-bottom {
  border-bottom-color: #303F9F !important; }

.indigo-700-border-left {
  border-left-color: #303F9F !important; }

.indigo-800-bg {
  background-color: #283593 !important; }

.md-indigo-800-bg {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-800-fg {
  color: #283593 !important; }

.indigo-800-border {
  border-color: #283593 !important; }

.indigo-800-border-top {
  border-top-color: #283593 !important; }

.indigo-800-border-right {
  border-right-color: #283593 !important; }

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important; }

.indigo-800-border-left {
  border-left-color: #283593 !important; }

.indigo-900-bg {
  background-color: #1A237E !important; }

.md-indigo-900-bg {
  background-color: #1A237E !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-900-fg {
  color: #1A237E !important; }

.indigo-900-border {
  border-color: #1A237E !important; }

.indigo-900-border-top {
  border-top-color: #1A237E !important; }

.indigo-900-border-right {
  border-right-color: #1A237E !important; }

.indigo-900-border-bottom {
  border-bottom-color: #1A237E !important; }

.indigo-900-border-left {
  border-left-color: #1A237E !important; }

.indigo-A100-bg {
  background-color: #8C9EFF !important; }

.md-indigo-A100-bg {
  background-color: #8C9EFF !important;
  color: rgba(0, 0, 0, 0.87); }

.indigo-A100-fg {
  color: #8C9EFF !important; }

.indigo-A100-border {
  border-color: #8C9EFF !important; }

.indigo-A100-border-top {
  border-top-color: #8C9EFF !important; }

.indigo-A100-border-right {
  border-right-color: #8C9EFF !important; }

.indigo-A100-border-bottom {
  border-bottom-color: #8C9EFF !important; }

.indigo-A100-border-left {
  border-left-color: #8C9EFF !important; }

.indigo-A200-bg {
  background-color: #536DFE !important; }

.md-indigo-A200-bg {
  background-color: #536DFE !important;
  color: white; }

.indigo-A200-fg {
  color: #536DFE !important; }

.indigo-A200-border {
  border-color: #536DFE !important; }

.indigo-A200-border-top {
  border-top-color: #536DFE !important; }

.indigo-A200-border-right {
  border-right-color: #536DFE !important; }

.indigo-A200-border-bottom {
  border-bottom-color: #536DFE !important; }

.indigo-A200-border-left {
  border-left-color: #536DFE !important; }

.indigo-A400-bg {
  background-color: #3D5AFE !important; }

.md-indigo-A400-bg {
  background-color: #3D5AFE !important;
  color: white; }

.indigo-A400-fg {
  color: #3D5AFE !important; }

.indigo-A400-border {
  border-color: #3D5AFE !important; }

.indigo-A400-border-top {
  border-top-color: #3D5AFE !important; }

.indigo-A400-border-right {
  border-right-color: #3D5AFE !important; }

.indigo-A400-border-bottom {
  border-bottom-color: #3D5AFE !important; }

.indigo-A400-border-left {
  border-left-color: #3D5AFE !important; }

.indigo-A700-bg {
  background-color: #304FFE !important; }

.md-indigo-A700-bg {
  background-color: #304FFE !important;
  color: rgba(255, 255, 255, 0.87); }

.indigo-A700-fg {
  color: #304FFE !important; }

.indigo-A700-border {
  border-color: #304FFE !important; }

.indigo-A700-border-top {
  border-top-color: #304FFE !important; }

.indigo-A700-border-right {
  border-right-color: #304FFE !important; }

.indigo-A700-border-bottom {
  border-bottom-color: #304FFE !important; }

.indigo-A700-border-left {
  border-left-color: #304FFE !important; }

.blue-50-bg {
  background-color: #E3F2FD !important; }

.md-blue-50-bg {
  background-color: #E3F2FD !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-50-fg {
  color: #E3F2FD !important; }

.blue-50-border {
  border-color: #E3F2FD !important; }

.blue-50-border-top {
  border-top-color: #E3F2FD !important; }

.blue-50-border-right {
  border-right-color: #E3F2FD !important; }

.blue-50-border-bottom {
  border-bottom-color: #E3F2FD !important; }

.blue-50-border-left {
  border-left-color: #E3F2FD !important; }

.blue-100-bg {
  background-color: #BBDEFB !important; }

.md-blue-100-bg {
  background-color: #BBDEFB !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-100-fg {
  color: #BBDEFB !important; }

.blue-100-border {
  border-color: #BBDEFB !important; }

.blue-100-border-top {
  border-top-color: #BBDEFB !important; }

.blue-100-border-right {
  border-right-color: #BBDEFB !important; }

.blue-100-border-bottom {
  border-bottom-color: #BBDEFB !important; }

.blue-100-border-left {
  border-left-color: #BBDEFB !important; }

.blue-200-bg {
  background-color: #90CAF9 !important; }

.md-blue-200-bg {
  background-color: #90CAF9 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-200-fg {
  color: #90CAF9 !important; }

.blue-200-border {
  border-color: #90CAF9 !important; }

.blue-200-border-top {
  border-top-color: #90CAF9 !important; }

.blue-200-border-right {
  border-right-color: #90CAF9 !important; }

.blue-200-border-bottom {
  border-bottom-color: #90CAF9 !important; }

.blue-200-border-left {
  border-left-color: #90CAF9 !important; }

.blue-300-bg {
  background-color: #64B5F6 !important; }

.md-blue-300-bg {
  background-color: #64B5F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-300-fg {
  color: #64B5F6 !important; }

.blue-300-border {
  border-color: #64B5F6 !important; }

.blue-300-border-top {
  border-top-color: #64B5F6 !important; }

.blue-300-border-right {
  border-right-color: #64B5F6 !important; }

.blue-300-border-bottom {
  border-bottom-color: #64B5F6 !important; }

.blue-300-border-left {
  border-left-color: #64B5F6 !important; }

.blue-400-bg {
  background-color: #42A5F5 !important; }

.md-blue-400-bg {
  background-color: #42A5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-400-fg {
  color: #42A5F5 !important; }

.blue-400-border {
  border-color: #42A5F5 !important; }

.blue-400-border-top {
  border-top-color: #42A5F5 !important; }

.blue-400-border-right {
  border-right-color: #42A5F5 !important; }

.blue-400-border-bottom {
  border-bottom-color: #42A5F5 !important; }

.blue-400-border-left {
  border-left-color: #42A5F5 !important; }

.blue-500-bg {
  background-color: #2196F3 !important; }

.md-blue-500-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-500-fg {
  color: #2196F3 !important; }

.blue-500-border {
  border-color: #2196F3 !important; }

.blue-500-border-top {
  border-top-color: #2196F3 !important; }

.blue-500-border-right {
  border-right-color: #2196F3 !important; }

.blue-500-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-500-border-left {
  border-left-color: #2196F3 !important; }

.md-blue-bg {
  background-color: #2196F3 !important;
  color: white; }

.blue-bg {
  background-color: #2196F3 !important; }

.blue-fg {
  color: #2196F3 !important; }

.blue-border {
  border-color: #2196F3 !important; }

.blue-border-top {
  border-top-color: #2196F3 !important; }

.blue-border-right {
  border-right-color: #2196F3 !important; }

.blue-border-bottom {
  border-bottom-color: #2196F3 !important; }

.blue-border-left {
  border-left-color: #2196F3 !important; }

.blue-600-bg {
  background-color: #1E88E5 !important; }

.md-blue-600-bg {
  background-color: #1E88E5 !important;
  color: white; }

.blue-600-fg {
  color: #1E88E5 !important; }

.blue-600-border {
  border-color: #1E88E5 !important; }

.blue-600-border-top {
  border-top-color: #1E88E5 !important; }

.blue-600-border-right {
  border-right-color: #1E88E5 !important; }

.blue-600-border-bottom {
  border-bottom-color: #1E88E5 !important; }

.blue-600-border-left {
  border-left-color: #1E88E5 !important; }

.blue-700-bg {
  background-color: #1976D2 !important; }

.md-blue-700-bg {
  background-color: #1976D2 !important;
  color: white; }

.blue-700-fg {
  color: #1976D2 !important; }

.blue-700-border {
  border-color: #1976D2 !important; }

.blue-700-border-top {
  border-top-color: #1976D2 !important; }

.blue-700-border-right {
  border-right-color: #1976D2 !important; }

.blue-700-border-bottom {
  border-bottom-color: #1976D2 !important; }

.blue-700-border-left {
  border-left-color: #1976D2 !important; }

.blue-800-bg {
  background-color: #1565C0 !important; }

.md-blue-800-bg {
  background-color: #1565C0 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-800-fg {
  color: #1565C0 !important; }

.blue-800-border {
  border-color: #1565C0 !important; }

.blue-800-border-top {
  border-top-color: #1565C0 !important; }

.blue-800-border-right {
  border-right-color: #1565C0 !important; }

.blue-800-border-bottom {
  border-bottom-color: #1565C0 !important; }

.blue-800-border-left {
  border-left-color: #1565C0 !important; }

.blue-900-bg {
  background-color: #0D47A1 !important; }

.md-blue-900-bg {
  background-color: #0D47A1 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-900-fg {
  color: #0D47A1 !important; }

.blue-900-border {
  border-color: #0D47A1 !important; }

.blue-900-border-top {
  border-top-color: #0D47A1 !important; }

.blue-900-border-right {
  border-right-color: #0D47A1 !important; }

.blue-900-border-bottom {
  border-bottom-color: #0D47A1 !important; }

.blue-900-border-left {
  border-left-color: #0D47A1 !important; }

.blue-A100-bg {
  background-color: #82B1FF !important; }

.md-blue-A100-bg {
  background-color: #82B1FF !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-A100-fg {
  color: #82B1FF !important; }

.blue-A100-border {
  border-color: #82B1FF !important; }

.blue-A100-border-top {
  border-top-color: #82B1FF !important; }

.blue-A100-border-right {
  border-right-color: #82B1FF !important; }

.blue-A100-border-bottom {
  border-bottom-color: #82B1FF !important; }

.blue-A100-border-left {
  border-left-color: #82B1FF !important; }

.blue-A200-bg {
  background-color: #448AFF !important; }

.md-blue-A200-bg {
  background-color: #448AFF !important;
  color: white; }

.blue-A200-fg {
  color: #448AFF !important; }

.blue-A200-border {
  border-color: #448AFF !important; }

.blue-A200-border-top {
  border-top-color: #448AFF !important; }

.blue-A200-border-right {
  border-right-color: #448AFF !important; }

.blue-A200-border-bottom {
  border-bottom-color: #448AFF !important; }

.blue-A200-border-left {
  border-left-color: #448AFF !important; }

.blue-A400-bg {
  background-color: #2979FF !important; }

.md-blue-A400-bg {
  background-color: #2979FF !important;
  color: white; }

.blue-A400-fg {
  color: #2979FF !important; }

.blue-A400-border {
  border-color: #2979FF !important; }

.blue-A400-border-top {
  border-top-color: #2979FF !important; }

.blue-A400-border-right {
  border-right-color: #2979FF !important; }

.blue-A400-border-bottom {
  border-bottom-color: #2979FF !important; }

.blue-A400-border-left {
  border-left-color: #2979FF !important; }

.blue-A700-bg {
  background-color: #2962FF !important; }

.md-blue-A700-bg {
  background-color: #2962FF !important;
  color: white; }

.blue-A700-fg {
  color: #2962FF !important; }

.blue-A700-border {
  border-color: #2962FF !important; }

.blue-A700-border-top {
  border-top-color: #2962FF !important; }

.blue-A700-border-right {
  border-right-color: #2962FF !important; }

.blue-A700-border-bottom {
  border-bottom-color: #2962FF !important; }

.blue-A700-border-left {
  border-left-color: #2962FF !important; }

.light-blue-50-bg {
  background-color: #E1F5FE !important; }

.md-light-blue-50-bg {
  background-color: #E1F5FE !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-50-fg {
  color: #E1F5FE !important; }

.light-blue-50-border {
  border-color: #E1F5FE !important; }

.light-blue-50-border-top {
  border-top-color: #E1F5FE !important; }

.light-blue-50-border-right {
  border-right-color: #E1F5FE !important; }

.light-blue-50-border-bottom {
  border-bottom-color: #E1F5FE !important; }

.light-blue-50-border-left {
  border-left-color: #E1F5FE !important; }

.light-blue-100-bg {
  background-color: #B3E5FC !important; }

.md-light-blue-100-bg {
  background-color: #B3E5FC !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-100-fg {
  color: #B3E5FC !important; }

.light-blue-100-border {
  border-color: #B3E5FC !important; }

.light-blue-100-border-top {
  border-top-color: #B3E5FC !important; }

.light-blue-100-border-right {
  border-right-color: #B3E5FC !important; }

.light-blue-100-border-bottom {
  border-bottom-color: #B3E5FC !important; }

.light-blue-100-border-left {
  border-left-color: #B3E5FC !important; }

.light-blue-200-bg {
  background-color: #81D4FA !important; }

.md-light-blue-200-bg {
  background-color: #81D4FA !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-200-fg {
  color: #81D4FA !important; }

.light-blue-200-border {
  border-color: #81D4FA !important; }

.light-blue-200-border-top {
  border-top-color: #81D4FA !important; }

.light-blue-200-border-right {
  border-right-color: #81D4FA !important; }

.light-blue-200-border-bottom {
  border-bottom-color: #81D4FA !important; }

.light-blue-200-border-left {
  border-left-color: #81D4FA !important; }

.light-blue-300-bg {
  background-color: #4FC3F7 !important; }

.md-light-blue-300-bg {
  background-color: #4FC3F7 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-300-fg {
  color: #4FC3F7 !important; }

.light-blue-300-border {
  border-color: #4FC3F7 !important; }

.light-blue-300-border-top {
  border-top-color: #4FC3F7 !important; }

.light-blue-300-border-right {
  border-right-color: #4FC3F7 !important; }

.light-blue-300-border-bottom {
  border-bottom-color: #4FC3F7 !important; }

.light-blue-300-border-left {
  border-left-color: #4FC3F7 !important; }

.light-blue-400-bg {
  background-color: #29B6F6 !important; }

.md-light-blue-400-bg {
  background-color: #29B6F6 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-400-fg {
  color: #29B6F6 !important; }

.light-blue-400-border {
  border-color: #29B6F6 !important; }

.light-blue-400-border-top {
  border-top-color: #29B6F6 !important; }

.light-blue-400-border-right {
  border-right-color: #29B6F6 !important; }

.light-blue-400-border-bottom {
  border-bottom-color: #29B6F6 !important; }

.light-blue-400-border-left {
  border-left-color: #29B6F6 !important; }

.light-blue-500-bg {
  background-color: #03A9F4 !important; }

.md-light-blue-500-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-500-fg {
  color: #03A9F4 !important; }

.light-blue-500-border {
  border-color: #03A9F4 !important; }

.light-blue-500-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-500-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-500-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-500-border-left {
  border-left-color: #03A9F4 !important; }

.md-light-blue-bg {
  background-color: #03A9F4 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-bg {
  background-color: #03A9F4 !important; }

.light-blue-fg {
  color: #03A9F4 !important; }

.light-blue-border {
  border-color: #03A9F4 !important; }

.light-blue-border-top {
  border-top-color: #03A9F4 !important; }

.light-blue-border-right {
  border-right-color: #03A9F4 !important; }

.light-blue-border-bottom {
  border-bottom-color: #03A9F4 !important; }

.light-blue-border-left {
  border-left-color: #03A9F4 !important; }

.light-blue-600-bg {
  background-color: #039BE5 !important; }

.md-light-blue-600-bg {
  background-color: #039BE5 !important;
  color: white; }

.light-blue-600-fg {
  color: #039BE5 !important; }

.light-blue-600-border {
  border-color: #039BE5 !important; }

.light-blue-600-border-top {
  border-top-color: #039BE5 !important; }

.light-blue-600-border-right {
  border-right-color: #039BE5 !important; }

.light-blue-600-border-bottom {
  border-bottom-color: #039BE5 !important; }

.light-blue-600-border-left {
  border-left-color: #039BE5 !important; }

.light-blue-700-bg {
  background-color: #0288D1 !important; }

.md-light-blue-700-bg {
  background-color: #0288D1 !important;
  color: white; }

.light-blue-700-fg {
  color: #0288D1 !important; }

.light-blue-700-border {
  border-color: #0288D1 !important; }

.light-blue-700-border-top {
  border-top-color: #0288D1 !important; }

.light-blue-700-border-right {
  border-right-color: #0288D1 !important; }

.light-blue-700-border-bottom {
  border-bottom-color: #0288D1 !important; }

.light-blue-700-border-left {
  border-left-color: #0288D1 !important; }

.light-blue-800-bg {
  background-color: #0277BD !important; }

.md-light-blue-800-bg {
  background-color: #0277BD !important;
  color: white; }

.light-blue-800-fg {
  color: #0277BD !important; }

.light-blue-800-border {
  border-color: #0277BD !important; }

.light-blue-800-border-top {
  border-top-color: #0277BD !important; }

.light-blue-800-border-right {
  border-right-color: #0277BD !important; }

.light-blue-800-border-bottom {
  border-bottom-color: #0277BD !important; }

.light-blue-800-border-left {
  border-left-color: #0277BD !important; }

.light-blue-900-bg {
  background-color: #01579B !important; }

.md-light-blue-900-bg {
  background-color: #01579B !important;
  color: white; }

.light-blue-900-fg {
  color: #01579B !important; }

.light-blue-900-border {
  border-color: #01579B !important; }

.light-blue-900-border-top {
  border-top-color: #01579B !important; }

.light-blue-900-border-right {
  border-right-color: #01579B !important; }

.light-blue-900-border-bottom {
  border-bottom-color: #01579B !important; }

.light-blue-900-border-left {
  border-left-color: #01579B !important; }

.light-blue-A100-bg {
  background-color: #80D8FF !important; }

.md-light-blue-A100-bg {
  background-color: #80D8FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A100-fg {
  color: #80D8FF !important; }

.light-blue-A100-border {
  border-color: #80D8FF !important; }

.light-blue-A100-border-top {
  border-top-color: #80D8FF !important; }

.light-blue-A100-border-right {
  border-right-color: #80D8FF !important; }

.light-blue-A100-border-bottom {
  border-bottom-color: #80D8FF !important; }

.light-blue-A100-border-left {
  border-left-color: #80D8FF !important; }

.light-blue-A200-bg {
  background-color: #40C4FF !important; }

.md-light-blue-A200-bg {
  background-color: #40C4FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A200-fg {
  color: #40C4FF !important; }

.light-blue-A200-border {
  border-color: #40C4FF !important; }

.light-blue-A200-border-top {
  border-top-color: #40C4FF !important; }

.light-blue-A200-border-right {
  border-right-color: #40C4FF !important; }

.light-blue-A200-border-bottom {
  border-bottom-color: #40C4FF !important; }

.light-blue-A200-border-left {
  border-left-color: #40C4FF !important; }

.light-blue-A400-bg {
  background-color: #00B0FF !important; }

.md-light-blue-A400-bg {
  background-color: #00B0FF !important;
  color: rgba(0, 0, 0, 0.87); }

.light-blue-A400-fg {
  color: #00B0FF !important; }

.light-blue-A400-border {
  border-color: #00B0FF !important; }

.light-blue-A400-border-top {
  border-top-color: #00B0FF !important; }

.light-blue-A400-border-right {
  border-right-color: #00B0FF !important; }

.light-blue-A400-border-bottom {
  border-bottom-color: #00B0FF !important; }

.light-blue-A400-border-left {
  border-left-color: #00B0FF !important; }

.light-blue-A700-bg {
  background-color: #0091EA !important; }

.md-light-blue-A700-bg {
  background-color: #0091EA !important;
  color: white; }

.light-blue-A700-fg {
  color: #0091EA !important; }

.light-blue-A700-border {
  border-color: #0091EA !important; }

.light-blue-A700-border-top {
  border-top-color: #0091EA !important; }

.light-blue-A700-border-right {
  border-right-color: #0091EA !important; }

.light-blue-A700-border-bottom {
  border-bottom-color: #0091EA !important; }

.light-blue-A700-border-left {
  border-left-color: #0091EA !important; }

.cyan-50-bg {
  background-color: #E0F7FA !important; }

.md-cyan-50-bg {
  background-color: #E0F7FA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-50-fg {
  color: #E0F7FA !important; }

.cyan-50-border {
  border-color: #E0F7FA !important; }

.cyan-50-border-top {
  border-top-color: #E0F7FA !important; }

.cyan-50-border-right {
  border-right-color: #E0F7FA !important; }

.cyan-50-border-bottom {
  border-bottom-color: #E0F7FA !important; }

.cyan-50-border-left {
  border-left-color: #E0F7FA !important; }

.cyan-100-bg {
  background-color: #B2EBF2 !important; }

.md-cyan-100-bg {
  background-color: #B2EBF2 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-100-fg {
  color: #B2EBF2 !important; }

.cyan-100-border {
  border-color: #B2EBF2 !important; }

.cyan-100-border-top {
  border-top-color: #B2EBF2 !important; }

.cyan-100-border-right {
  border-right-color: #B2EBF2 !important; }

.cyan-100-border-bottom {
  border-bottom-color: #B2EBF2 !important; }

.cyan-100-border-left {
  border-left-color: #B2EBF2 !important; }

.cyan-200-bg {
  background-color: #80DEEA !important; }

.md-cyan-200-bg {
  background-color: #80DEEA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-200-fg {
  color: #80DEEA !important; }

.cyan-200-border {
  border-color: #80DEEA !important; }

.cyan-200-border-top {
  border-top-color: #80DEEA !important; }

.cyan-200-border-right {
  border-right-color: #80DEEA !important; }

.cyan-200-border-bottom {
  border-bottom-color: #80DEEA !important; }

.cyan-200-border-left {
  border-left-color: #80DEEA !important; }

.cyan-300-bg {
  background-color: #4DD0E1 !important; }

.md-cyan-300-bg {
  background-color: #4DD0E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-300-fg {
  color: #4DD0E1 !important; }

.cyan-300-border {
  border-color: #4DD0E1 !important; }

.cyan-300-border-top {
  border-top-color: #4DD0E1 !important; }

.cyan-300-border-right {
  border-right-color: #4DD0E1 !important; }

.cyan-300-border-bottom {
  border-bottom-color: #4DD0E1 !important; }

.cyan-300-border-left {
  border-left-color: #4DD0E1 !important; }

.cyan-400-bg {
  background-color: #26C6DA !important; }

.md-cyan-400-bg {
  background-color: #26C6DA !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-400-fg {
  color: #26C6DA !important; }

.cyan-400-border {
  border-color: #26C6DA !important; }

.cyan-400-border-top {
  border-top-color: #26C6DA !important; }

.cyan-400-border-right {
  border-right-color: #26C6DA !important; }

.cyan-400-border-bottom {
  border-bottom-color: #26C6DA !important; }

.cyan-400-border-left {
  border-left-color: #26C6DA !important; }

.cyan-500-bg {
  background-color: #00BCD4 !important; }

.md-cyan-500-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-500-fg {
  color: #00BCD4 !important; }

.cyan-500-border {
  border-color: #00BCD4 !important; }

.cyan-500-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-500-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-500-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-500-border-left {
  border-left-color: #00BCD4 !important; }

.md-cyan-bg {
  background-color: #00BCD4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-bg {
  background-color: #00BCD4 !important; }

.cyan-fg {
  color: #00BCD4 !important; }

.cyan-border {
  border-color: #00BCD4 !important; }

.cyan-border-top {
  border-top-color: #00BCD4 !important; }

.cyan-border-right {
  border-right-color: #00BCD4 !important; }

.cyan-border-bottom {
  border-bottom-color: #00BCD4 !important; }

.cyan-border-left {
  border-left-color: #00BCD4 !important; }

.cyan-600-bg {
  background-color: #00ACC1 !important; }

.md-cyan-600-bg {
  background-color: #00ACC1 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-600-fg {
  color: #00ACC1 !important; }

.cyan-600-border {
  border-color: #00ACC1 !important; }

.cyan-600-border-top {
  border-top-color: #00ACC1 !important; }

.cyan-600-border-right {
  border-right-color: #00ACC1 !important; }

.cyan-600-border-bottom {
  border-bottom-color: #00ACC1 !important; }

.cyan-600-border-left {
  border-left-color: #00ACC1 !important; }

.cyan-700-bg {
  background-color: #0097A7 !important; }

.md-cyan-700-bg {
  background-color: #0097A7 !important;
  color: white; }

.cyan-700-fg {
  color: #0097A7 !important; }

.cyan-700-border {
  border-color: #0097A7 !important; }

.cyan-700-border-top {
  border-top-color: #0097A7 !important; }

.cyan-700-border-right {
  border-right-color: #0097A7 !important; }

.cyan-700-border-bottom {
  border-bottom-color: #0097A7 !important; }

.cyan-700-border-left {
  border-left-color: #0097A7 !important; }

.cyan-800-bg {
  background-color: #00838F !important; }

.md-cyan-800-bg {
  background-color: #00838F !important;
  color: white; }

.cyan-800-fg {
  color: #00838F !important; }

.cyan-800-border {
  border-color: #00838F !important; }

.cyan-800-border-top {
  border-top-color: #00838F !important; }

.cyan-800-border-right {
  border-right-color: #00838F !important; }

.cyan-800-border-bottom {
  border-bottom-color: #00838F !important; }

.cyan-800-border-left {
  border-left-color: #00838F !important; }

.cyan-900-bg {
  background-color: #006064 !important; }

.md-cyan-900-bg {
  background-color: #006064 !important;
  color: white; }

.cyan-900-fg {
  color: #006064 !important; }

.cyan-900-border {
  border-color: #006064 !important; }

.cyan-900-border-top {
  border-top-color: #006064 !important; }

.cyan-900-border-right {
  border-right-color: #006064 !important; }

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important; }

.cyan-900-border-left {
  border-left-color: #006064 !important; }

.cyan-A100-bg {
  background-color: #84FFFF !important; }

.md-cyan-A100-bg {
  background-color: #84FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A100-fg {
  color: #84FFFF !important; }

.cyan-A100-border {
  border-color: #84FFFF !important; }

.cyan-A100-border-top {
  border-top-color: #84FFFF !important; }

.cyan-A100-border-right {
  border-right-color: #84FFFF !important; }

.cyan-A100-border-bottom {
  border-bottom-color: #84FFFF !important; }

.cyan-A100-border-left {
  border-left-color: #84FFFF !important; }

.cyan-A200-bg {
  background-color: #18FFFF !important; }

.md-cyan-A200-bg {
  background-color: #18FFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A200-fg {
  color: #18FFFF !important; }

.cyan-A200-border {
  border-color: #18FFFF !important; }

.cyan-A200-border-top {
  border-top-color: #18FFFF !important; }

.cyan-A200-border-right {
  border-right-color: #18FFFF !important; }

.cyan-A200-border-bottom {
  border-bottom-color: #18FFFF !important; }

.cyan-A200-border-left {
  border-left-color: #18FFFF !important; }

.cyan-A400-bg {
  background-color: #00E5FF !important; }

.md-cyan-A400-bg {
  background-color: #00E5FF !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A400-fg {
  color: #00E5FF !important; }

.cyan-A400-border {
  border-color: #00E5FF !important; }

.cyan-A400-border-top {
  border-top-color: #00E5FF !important; }

.cyan-A400-border-right {
  border-right-color: #00E5FF !important; }

.cyan-A400-border-bottom {
  border-bottom-color: #00E5FF !important; }

.cyan-A400-border-left {
  border-left-color: #00E5FF !important; }

.cyan-A700-bg {
  background-color: #00B8D4 !important; }

.md-cyan-A700-bg {
  background-color: #00B8D4 !important;
  color: rgba(0, 0, 0, 0.87); }

.cyan-A700-fg {
  color: #00B8D4 !important; }

.cyan-A700-border {
  border-color: #00B8D4 !important; }

.cyan-A700-border-top {
  border-top-color: #00B8D4 !important; }

.cyan-A700-border-right {
  border-right-color: #00B8D4 !important; }

.cyan-A700-border-bottom {
  border-bottom-color: #00B8D4 !important; }

.cyan-A700-border-left {
  border-left-color: #00B8D4 !important; }

.teal-50-bg {
  background-color: #E0F2F1 !important; }

.md-teal-50-bg {
  background-color: #E0F2F1 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-50-fg {
  color: #E0F2F1 !important; }

.teal-50-border {
  border-color: #E0F2F1 !important; }

.teal-50-border-top {
  border-top-color: #E0F2F1 !important; }

.teal-50-border-right {
  border-right-color: #E0F2F1 !important; }

.teal-50-border-bottom {
  border-bottom-color: #E0F2F1 !important; }

.teal-50-border-left {
  border-left-color: #E0F2F1 !important; }

.teal-100-bg {
  background-color: #B2DFDB !important; }

.md-teal-100-bg {
  background-color: #B2DFDB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-100-fg {
  color: #B2DFDB !important; }

.teal-100-border {
  border-color: #B2DFDB !important; }

.teal-100-border-top {
  border-top-color: #B2DFDB !important; }

.teal-100-border-right {
  border-right-color: #B2DFDB !important; }

.teal-100-border-bottom {
  border-bottom-color: #B2DFDB !important; }

.teal-100-border-left {
  border-left-color: #B2DFDB !important; }

.teal-200-bg {
  background-color: #80CBC4 !important; }

.md-teal-200-bg {
  background-color: #80CBC4 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-200-fg {
  color: #80CBC4 !important; }

.teal-200-border {
  border-color: #80CBC4 !important; }

.teal-200-border-top {
  border-top-color: #80CBC4 !important; }

.teal-200-border-right {
  border-right-color: #80CBC4 !important; }

.teal-200-border-bottom {
  border-bottom-color: #80CBC4 !important; }

.teal-200-border-left {
  border-left-color: #80CBC4 !important; }

.teal-300-bg {
  background-color: #4DB6AC !important; }

.md-teal-300-bg {
  background-color: #4DB6AC !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-300-fg {
  color: #4DB6AC !important; }

.teal-300-border {
  border-color: #4DB6AC !important; }

.teal-300-border-top {
  border-top-color: #4DB6AC !important; }

.teal-300-border-right {
  border-right-color: #4DB6AC !important; }

.teal-300-border-bottom {
  border-bottom-color: #4DB6AC !important; }

.teal-300-border-left {
  border-left-color: #4DB6AC !important; }

.teal-400-bg {
  background-color: #26A69A !important; }

.md-teal-400-bg {
  background-color: #26A69A !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-400-fg {
  color: #26A69A !important; }

.teal-400-border {
  border-color: #26A69A !important; }

.teal-400-border-top {
  border-top-color: #26A69A !important; }

.teal-400-border-right {
  border-right-color: #26A69A !important; }

.teal-400-border-bottom {
  border-bottom-color: #26A69A !important; }

.teal-400-border-left {
  border-left-color: #26A69A !important; }

.teal-500-bg {
  background-color: #009688 !important; }

.md-teal-500-bg {
  background-color: #009688 !important;
  color: white; }

.teal-500-fg {
  color: #009688 !important; }

.teal-500-border {
  border-color: #009688 !important; }

.teal-500-border-top {
  border-top-color: #009688 !important; }

.teal-500-border-right {
  border-right-color: #009688 !important; }

.teal-500-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-500-border-left {
  border-left-color: #009688 !important; }

.md-teal-bg {
  background-color: #009688 !important;
  color: white; }

.teal-bg {
  background-color: #009688 !important; }

.teal-fg {
  color: #009688 !important; }

.teal-border {
  border-color: #009688 !important; }

.teal-border-top {
  border-top-color: #009688 !important; }

.teal-border-right {
  border-right-color: #009688 !important; }

.teal-border-bottom {
  border-bottom-color: #009688 !important; }

.teal-border-left {
  border-left-color: #009688 !important; }

.teal-600-bg {
  background-color: #00897B !important; }

.md-teal-600-bg {
  background-color: #00897B !important;
  color: white; }

.teal-600-fg {
  color: #00897B !important; }

.teal-600-border {
  border-color: #00897B !important; }

.teal-600-border-top {
  border-top-color: #00897B !important; }

.teal-600-border-right {
  border-right-color: #00897B !important; }

.teal-600-border-bottom {
  border-bottom-color: #00897B !important; }

.teal-600-border-left {
  border-left-color: #00897B !important; }

.teal-700-bg {
  background-color: #00796B !important; }

.md-teal-700-bg {
  background-color: #00796B !important;
  color: white; }

.teal-700-fg {
  color: #00796B !important; }

.teal-700-border {
  border-color: #00796B !important; }

.teal-700-border-top {
  border-top-color: #00796B !important; }

.teal-700-border-right {
  border-right-color: #00796B !important; }

.teal-700-border-bottom {
  border-bottom-color: #00796B !important; }

.teal-700-border-left {
  border-left-color: #00796B !important; }

.teal-800-bg {
  background-color: #00695C !important; }

.md-teal-800-bg {
  background-color: #00695C !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-800-fg {
  color: #00695C !important; }

.teal-800-border {
  border-color: #00695C !important; }

.teal-800-border-top {
  border-top-color: #00695C !important; }

.teal-800-border-right {
  border-right-color: #00695C !important; }

.teal-800-border-bottom {
  border-bottom-color: #00695C !important; }

.teal-800-border-left {
  border-left-color: #00695C !important; }

.teal-900-bg {
  background-color: #004D40 !important; }

.md-teal-900-bg {
  background-color: #004D40 !important;
  color: rgba(255, 255, 255, 0.87); }

.teal-900-fg {
  color: #004D40 !important; }

.teal-900-border {
  border-color: #004D40 !important; }

.teal-900-border-top {
  border-top-color: #004D40 !important; }

.teal-900-border-right {
  border-right-color: #004D40 !important; }

.teal-900-border-bottom {
  border-bottom-color: #004D40 !important; }

.teal-900-border-left {
  border-left-color: #004D40 !important; }

.teal-A100-bg {
  background-color: #A7FFEB !important; }

.md-teal-A100-bg {
  background-color: #A7FFEB !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A100-fg {
  color: #A7FFEB !important; }

.teal-A100-border {
  border-color: #A7FFEB !important; }

.teal-A100-border-top {
  border-top-color: #A7FFEB !important; }

.teal-A100-border-right {
  border-right-color: #A7FFEB !important; }

.teal-A100-border-bottom {
  border-bottom-color: #A7FFEB !important; }

.teal-A100-border-left {
  border-left-color: #A7FFEB !important; }

.teal-A200-bg {
  background-color: #64FFDA !important; }

.md-teal-A200-bg {
  background-color: #64FFDA !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A200-fg {
  color: #64FFDA !important; }

.teal-A200-border {
  border-color: #64FFDA !important; }

.teal-A200-border-top {
  border-top-color: #64FFDA !important; }

.teal-A200-border-right {
  border-right-color: #64FFDA !important; }

.teal-A200-border-bottom {
  border-bottom-color: #64FFDA !important; }

.teal-A200-border-left {
  border-left-color: #64FFDA !important; }

.teal-A400-bg {
  background-color: #1DE9B6 !important; }

.md-teal-A400-bg {
  background-color: #1DE9B6 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A400-fg {
  color: #1DE9B6 !important; }

.teal-A400-border {
  border-color: #1DE9B6 !important; }

.teal-A400-border-top {
  border-top-color: #1DE9B6 !important; }

.teal-A400-border-right {
  border-right-color: #1DE9B6 !important; }

.teal-A400-border-bottom {
  border-bottom-color: #1DE9B6 !important; }

.teal-A400-border-left {
  border-left-color: #1DE9B6 !important; }

.teal-A700-bg {
  background-color: #00BFA5 !important; }

.md-teal-A700-bg {
  background-color: #00BFA5 !important;
  color: rgba(0, 0, 0, 0.87); }

.teal-A700-fg {
  color: #00BFA5 !important; }

.teal-A700-border {
  border-color: #00BFA5 !important; }

.teal-A700-border-top {
  border-top-color: #00BFA5 !important; }

.teal-A700-border-right {
  border-right-color: #00BFA5 !important; }

.teal-A700-border-bottom {
  border-bottom-color: #00BFA5 !important; }

.teal-A700-border-left {
  border-left-color: #00BFA5 !important; }

.green-50-bg {
  background-color: #E8F5E9 !important; }

.md-green-50-bg {
  background-color: #E8F5E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-50-fg {
  color: #E8F5E9 !important; }

.green-50-border {
  border-color: #E8F5E9 !important; }

.green-50-border-top {
  border-top-color: #E8F5E9 !important; }

.green-50-border-right {
  border-right-color: #E8F5E9 !important; }

.green-50-border-bottom {
  border-bottom-color: #E8F5E9 !important; }

.green-50-border-left {
  border-left-color: #E8F5E9 !important; }

.green-100-bg {
  background-color: #C8E6C9 !important; }

.md-green-100-bg {
  background-color: #C8E6C9 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-100-fg {
  color: #C8E6C9 !important; }

.green-100-border {
  border-color: #C8E6C9 !important; }

.green-100-border-top {
  border-top-color: #C8E6C9 !important; }

.green-100-border-right {
  border-right-color: #C8E6C9 !important; }

.green-100-border-bottom {
  border-bottom-color: #C8E6C9 !important; }

.green-100-border-left {
  border-left-color: #C8E6C9 !important; }

.green-200-bg {
  background-color: #A5D6A7 !important; }

.md-green-200-bg {
  background-color: #A5D6A7 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-200-fg {
  color: #A5D6A7 !important; }

.green-200-border {
  border-color: #A5D6A7 !important; }

.green-200-border-top {
  border-top-color: #A5D6A7 !important; }

.green-200-border-right {
  border-right-color: #A5D6A7 !important; }

.green-200-border-bottom {
  border-bottom-color: #A5D6A7 !important; }

.green-200-border-left {
  border-left-color: #A5D6A7 !important; }

.green-300-bg {
  background-color: #81C784 !important; }

.md-green-300-bg {
  background-color: #81C784 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-300-fg {
  color: #81C784 !important; }

.green-300-border {
  border-color: #81C784 !important; }

.green-300-border-top {
  border-top-color: #81C784 !important; }

.green-300-border-right {
  border-right-color: #81C784 !important; }

.green-300-border-bottom {
  border-bottom-color: #81C784 !important; }

.green-300-border-left {
  border-left-color: #81C784 !important; }

.green-400-bg {
  background-color: #66BB6A !important; }

.md-green-400-bg {
  background-color: #66BB6A !important;
  color: rgba(0, 0, 0, 0.87); }

.green-400-fg {
  color: #66BB6A !important; }

.green-400-border {
  border-color: #66BB6A !important; }

.green-400-border-top {
  border-top-color: #66BB6A !important; }

.green-400-border-right {
  border-right-color: #66BB6A !important; }

.green-400-border-bottom {
  border-bottom-color: #66BB6A !important; }

.green-400-border-left {
  border-left-color: #66BB6A !important; }

.green-500-bg {
  background-color: #4CAF50 !important; }

.md-green-500-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-500-fg {
  color: #4CAF50 !important; }

.green-500-border {
  border-color: #4CAF50 !important; }

.green-500-border-top {
  border-top-color: #4CAF50 !important; }

.green-500-border-right {
  border-right-color: #4CAF50 !important; }

.green-500-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-500-border-left {
  border-left-color: #4CAF50 !important; }

.md-green-bg {
  background-color: #4CAF50 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-bg {
  background-color: #4CAF50 !important; }

.green-fg {
  color: #4CAF50 !important; }

.green-border {
  border-color: #4CAF50 !important; }

.green-border-top {
  border-top-color: #4CAF50 !important; }

.green-border-right {
  border-right-color: #4CAF50 !important; }

.green-border-bottom {
  border-bottom-color: #4CAF50 !important; }

.green-border-left {
  border-left-color: #4CAF50 !important; }

.green-600-bg {
  background-color: #43A047 !important; }

.md-green-600-bg {
  background-color: #43A047 !important;
  color: white; }

.green-600-fg {
  color: #43A047 !important; }

.green-600-border {
  border-color: #43A047 !important; }

.green-600-border-top {
  border-top-color: #43A047 !important; }

.green-600-border-right {
  border-right-color: #43A047 !important; }

.green-600-border-bottom {
  border-bottom-color: #43A047 !important; }

.green-600-border-left {
  border-left-color: #43A047 !important; }

.green-700-bg {
  background-color: #388E3C !important; }

.md-green-700-bg {
  background-color: #388E3C !important;
  color: white; }

.green-700-fg {
  color: #388E3C !important; }

.green-700-border {
  border-color: #388E3C !important; }

.green-700-border-top {
  border-top-color: #388E3C !important; }

.green-700-border-right {
  border-right-color: #388E3C !important; }

.green-700-border-bottom {
  border-bottom-color: #388E3C !important; }

.green-700-border-left {
  border-left-color: #388E3C !important; }

.green-800-bg {
  background-color: #2E7D32 !important; }

.md-green-800-bg {
  background-color: #2E7D32 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-800-fg {
  color: #2E7D32 !important; }

.green-800-border {
  border-color: #2E7D32 !important; }

.green-800-border-top {
  border-top-color: #2E7D32 !important; }

.green-800-border-right {
  border-right-color: #2E7D32 !important; }

.green-800-border-bottom {
  border-bottom-color: #2E7D32 !important; }

.green-800-border-left {
  border-left-color: #2E7D32 !important; }

.green-900-bg {
  background-color: #1B5E20 !important; }

.md-green-900-bg {
  background-color: #1B5E20 !important;
  color: rgba(255, 255, 255, 0.87); }

.green-900-fg {
  color: #1B5E20 !important; }

.green-900-border {
  border-color: #1B5E20 !important; }

.green-900-border-top {
  border-top-color: #1B5E20 !important; }

.green-900-border-right {
  border-right-color: #1B5E20 !important; }

.green-900-border-bottom {
  border-bottom-color: #1B5E20 !important; }

.green-900-border-left {
  border-left-color: #1B5E20 !important; }

.green-A100-bg {
  background-color: #B9F6CA !important; }

.md-green-A100-bg {
  background-color: #B9F6CA !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A100-fg {
  color: #B9F6CA !important; }

.green-A100-border {
  border-color: #B9F6CA !important; }

.green-A100-border-top {
  border-top-color: #B9F6CA !important; }

.green-A100-border-right {
  border-right-color: #B9F6CA !important; }

.green-A100-border-bottom {
  border-bottom-color: #B9F6CA !important; }

.green-A100-border-left {
  border-left-color: #B9F6CA !important; }

.green-A200-bg {
  background-color: #69F0AE !important; }

.md-green-A200-bg {
  background-color: #69F0AE !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A200-fg {
  color: #69F0AE !important; }

.green-A200-border {
  border-color: #69F0AE !important; }

.green-A200-border-top {
  border-top-color: #69F0AE !important; }

.green-A200-border-right {
  border-right-color: #69F0AE !important; }

.green-A200-border-bottom {
  border-bottom-color: #69F0AE !important; }

.green-A200-border-left {
  border-left-color: #69F0AE !important; }

.green-A400-bg {
  background-color: #00E676 !important; }

.md-green-A400-bg {
  background-color: #00E676 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A400-fg {
  color: #00E676 !important; }

.green-A400-border {
  border-color: #00E676 !important; }

.green-A400-border-top {
  border-top-color: #00E676 !important; }

.green-A400-border-right {
  border-right-color: #00E676 !important; }

.green-A400-border-bottom {
  border-bottom-color: #00E676 !important; }

.green-A400-border-left {
  border-left-color: #00E676 !important; }

.green-A700-bg {
  background-color: #00C853 !important; }

.md-green-A700-bg {
  background-color: #00C853 !important;
  color: rgba(0, 0, 0, 0.87); }

.green-A700-fg {
  color: #00C853 !important; }

.green-A700-border {
  border-color: #00C853 !important; }

.green-A700-border-top {
  border-top-color: #00C853 !important; }

.green-A700-border-right {
  border-right-color: #00C853 !important; }

.green-A700-border-bottom {
  border-bottom-color: #00C853 !important; }

.green-A700-border-left {
  border-left-color: #00C853 !important; }

.light-green-50-bg {
  background-color: #F1F8E9 !important; }

.md-light-green-50-bg {
  background-color: #F1F8E9 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-50-fg {
  color: #F1F8E9 !important; }

.light-green-50-border {
  border-color: #F1F8E9 !important; }

.light-green-50-border-top {
  border-top-color: #F1F8E9 !important; }

.light-green-50-border-right {
  border-right-color: #F1F8E9 !important; }

.light-green-50-border-bottom {
  border-bottom-color: #F1F8E9 !important; }

.light-green-50-border-left {
  border-left-color: #F1F8E9 !important; }

.light-green-100-bg {
  background-color: #DCEDC8 !important; }

.md-light-green-100-bg {
  background-color: #DCEDC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-100-fg {
  color: #DCEDC8 !important; }

.light-green-100-border {
  border-color: #DCEDC8 !important; }

.light-green-100-border-top {
  border-top-color: #DCEDC8 !important; }

.light-green-100-border-right {
  border-right-color: #DCEDC8 !important; }

.light-green-100-border-bottom {
  border-bottom-color: #DCEDC8 !important; }

.light-green-100-border-left {
  border-left-color: #DCEDC8 !important; }

.light-green-200-bg {
  background-color: #C5E1A5 !important; }

.md-light-green-200-bg {
  background-color: #C5E1A5 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-200-fg {
  color: #C5E1A5 !important; }

.light-green-200-border {
  border-color: #C5E1A5 !important; }

.light-green-200-border-top {
  border-top-color: #C5E1A5 !important; }

.light-green-200-border-right {
  border-right-color: #C5E1A5 !important; }

.light-green-200-border-bottom {
  border-bottom-color: #C5E1A5 !important; }

.light-green-200-border-left {
  border-left-color: #C5E1A5 !important; }

.light-green-300-bg {
  background-color: #AED581 !important; }

.md-light-green-300-bg {
  background-color: #AED581 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-300-fg {
  color: #AED581 !important; }

.light-green-300-border {
  border-color: #AED581 !important; }

.light-green-300-border-top {
  border-top-color: #AED581 !important; }

.light-green-300-border-right {
  border-right-color: #AED581 !important; }

.light-green-300-border-bottom {
  border-bottom-color: #AED581 !important; }

.light-green-300-border-left {
  border-left-color: #AED581 !important; }

.light-green-400-bg {
  background-color: #9CCC65 !important; }

.md-light-green-400-bg {
  background-color: #9CCC65 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-400-fg {
  color: #9CCC65 !important; }

.light-green-400-border {
  border-color: #9CCC65 !important; }

.light-green-400-border-top {
  border-top-color: #9CCC65 !important; }

.light-green-400-border-right {
  border-right-color: #9CCC65 !important; }

.light-green-400-border-bottom {
  border-bottom-color: #9CCC65 !important; }

.light-green-400-border-left {
  border-left-color: #9CCC65 !important; }

.light-green-500-bg {
  background-color: #8BC34A !important; }

.md-light-green-500-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-500-fg {
  color: #8BC34A !important; }

.light-green-500-border {
  border-color: #8BC34A !important; }

.light-green-500-border-top {
  border-top-color: #8BC34A !important; }

.light-green-500-border-right {
  border-right-color: #8BC34A !important; }

.light-green-500-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-500-border-left {
  border-left-color: #8BC34A !important; }

.md-light-green-bg {
  background-color: #8BC34A !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-bg {
  background-color: #8BC34A !important; }

.light-green-fg {
  color: #8BC34A !important; }

.light-green-border {
  border-color: #8BC34A !important; }

.light-green-border-top {
  border-top-color: #8BC34A !important; }

.light-green-border-right {
  border-right-color: #8BC34A !important; }

.light-green-border-bottom {
  border-bottom-color: #8BC34A !important; }

.light-green-border-left {
  border-left-color: #8BC34A !important; }

.light-green-600-bg {
  background-color: #7CB342 !important; }

.md-light-green-600-bg {
  background-color: #7CB342 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-600-fg {
  color: #7CB342 !important; }

.light-green-600-border {
  border-color: #7CB342 !important; }

.light-green-600-border-top {
  border-top-color: #7CB342 !important; }

.light-green-600-border-right {
  border-right-color: #7CB342 !important; }

.light-green-600-border-bottom {
  border-bottom-color: #7CB342 !important; }

.light-green-600-border-left {
  border-left-color: #7CB342 !important; }

.light-green-700-bg {
  background-color: #689F38 !important; }

.md-light-green-700-bg {
  background-color: #689F38 !important;
  color: white; }

.light-green-700-fg {
  color: #689F38 !important; }

.light-green-700-border {
  border-color: #689F38 !important; }

.light-green-700-border-top {
  border-top-color: #689F38 !important; }

.light-green-700-border-right {
  border-right-color: #689F38 !important; }

.light-green-700-border-bottom {
  border-bottom-color: #689F38 !important; }

.light-green-700-border-left {
  border-left-color: #689F38 !important; }

.light-green-800-bg {
  background-color: #558B2F !important; }

.md-light-green-800-bg {
  background-color: #558B2F !important;
  color: white; }

.light-green-800-fg {
  color: #558B2F !important; }

.light-green-800-border {
  border-color: #558B2F !important; }

.light-green-800-border-top {
  border-top-color: #558B2F !important; }

.light-green-800-border-right {
  border-right-color: #558B2F !important; }

.light-green-800-border-bottom {
  border-bottom-color: #558B2F !important; }

.light-green-800-border-left {
  border-left-color: #558B2F !important; }

.light-green-900-bg {
  background-color: #33691E !important; }

.md-light-green-900-bg {
  background-color: #33691E !important;
  color: white; }

.light-green-900-fg {
  color: #33691E !important; }

.light-green-900-border {
  border-color: #33691E !important; }

.light-green-900-border-top {
  border-top-color: #33691E !important; }

.light-green-900-border-right {
  border-right-color: #33691E !important; }

.light-green-900-border-bottom {
  border-bottom-color: #33691E !important; }

.light-green-900-border-left {
  border-left-color: #33691E !important; }

.light-green-A100-bg {
  background-color: #CCFF90 !important; }

.md-light-green-A100-bg {
  background-color: #CCFF90 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A100-fg {
  color: #CCFF90 !important; }

.light-green-A100-border {
  border-color: #CCFF90 !important; }

.light-green-A100-border-top {
  border-top-color: #CCFF90 !important; }

.light-green-A100-border-right {
  border-right-color: #CCFF90 !important; }

.light-green-A100-border-bottom {
  border-bottom-color: #CCFF90 !important; }

.light-green-A100-border-left {
  border-left-color: #CCFF90 !important; }

.light-green-A200-bg {
  background-color: #B2FF59 !important; }

.md-light-green-A200-bg {
  background-color: #B2FF59 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A200-fg {
  color: #B2FF59 !important; }

.light-green-A200-border {
  border-color: #B2FF59 !important; }

.light-green-A200-border-top {
  border-top-color: #B2FF59 !important; }

.light-green-A200-border-right {
  border-right-color: #B2FF59 !important; }

.light-green-A200-border-bottom {
  border-bottom-color: #B2FF59 !important; }

.light-green-A200-border-left {
  border-left-color: #B2FF59 !important; }

.light-green-A400-bg {
  background-color: #76FF03 !important; }

.md-light-green-A400-bg {
  background-color: #76FF03 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A400-fg {
  color: #76FF03 !important; }

.light-green-A400-border {
  border-color: #76FF03 !important; }

.light-green-A400-border-top {
  border-top-color: #76FF03 !important; }

.light-green-A400-border-right {
  border-right-color: #76FF03 !important; }

.light-green-A400-border-bottom {
  border-bottom-color: #76FF03 !important; }

.light-green-A400-border-left {
  border-left-color: #76FF03 !important; }

.light-green-A700-bg {
  background-color: #64DD17 !important; }

.md-light-green-A700-bg {
  background-color: #64DD17 !important;
  color: rgba(0, 0, 0, 0.87); }

.light-green-A700-fg {
  color: #64DD17 !important; }

.light-green-A700-border {
  border-color: #64DD17 !important; }

.light-green-A700-border-top {
  border-top-color: #64DD17 !important; }

.light-green-A700-border-right {
  border-right-color: #64DD17 !important; }

.light-green-A700-border-bottom {
  border-bottom-color: #64DD17 !important; }

.light-green-A700-border-left {
  border-left-color: #64DD17 !important; }

.lime-50-bg {
  background-color: #F9FBE7 !important; }

.md-lime-50-bg {
  background-color: #F9FBE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-50-fg {
  color: #F9FBE7 !important; }

.lime-50-border {
  border-color: #F9FBE7 !important; }

.lime-50-border-top {
  border-top-color: #F9FBE7 !important; }

.lime-50-border-right {
  border-right-color: #F9FBE7 !important; }

.lime-50-border-bottom {
  border-bottom-color: #F9FBE7 !important; }

.lime-50-border-left {
  border-left-color: #F9FBE7 !important; }

.lime-100-bg {
  background-color: #F0F4C3 !important; }

.md-lime-100-bg {
  background-color: #F0F4C3 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-100-fg {
  color: #F0F4C3 !important; }

.lime-100-border {
  border-color: #F0F4C3 !important; }

.lime-100-border-top {
  border-top-color: #F0F4C3 !important; }

.lime-100-border-right {
  border-right-color: #F0F4C3 !important; }

.lime-100-border-bottom {
  border-bottom-color: #F0F4C3 !important; }

.lime-100-border-left {
  border-left-color: #F0F4C3 !important; }

.lime-200-bg {
  background-color: #E6EE9C !important; }

.md-lime-200-bg {
  background-color: #E6EE9C !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-200-fg {
  color: #E6EE9C !important; }

.lime-200-border {
  border-color: #E6EE9C !important; }

.lime-200-border-top {
  border-top-color: #E6EE9C !important; }

.lime-200-border-right {
  border-right-color: #E6EE9C !important; }

.lime-200-border-bottom {
  border-bottom-color: #E6EE9C !important; }

.lime-200-border-left {
  border-left-color: #E6EE9C !important; }

.lime-300-bg {
  background-color: #DCE775 !important; }

.md-lime-300-bg {
  background-color: #DCE775 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-300-fg {
  color: #DCE775 !important; }

.lime-300-border {
  border-color: #DCE775 !important; }

.lime-300-border-top {
  border-top-color: #DCE775 !important; }

.lime-300-border-right {
  border-right-color: #DCE775 !important; }

.lime-300-border-bottom {
  border-bottom-color: #DCE775 !important; }

.lime-300-border-left {
  border-left-color: #DCE775 !important; }

.lime-400-bg {
  background-color: #D4E157 !important; }

.md-lime-400-bg {
  background-color: #D4E157 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-400-fg {
  color: #D4E157 !important; }

.lime-400-border {
  border-color: #D4E157 !important; }

.lime-400-border-top {
  border-top-color: #D4E157 !important; }

.lime-400-border-right {
  border-right-color: #D4E157 !important; }

.lime-400-border-bottom {
  border-bottom-color: #D4E157 !important; }

.lime-400-border-left {
  border-left-color: #D4E157 !important; }

.lime-500-bg {
  background-color: #CDDC39 !important; }

.md-lime-500-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-500-fg {
  color: #CDDC39 !important; }

.lime-500-border {
  border-color: #CDDC39 !important; }

.lime-500-border-top {
  border-top-color: #CDDC39 !important; }

.lime-500-border-right {
  border-right-color: #CDDC39 !important; }

.lime-500-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-500-border-left {
  border-left-color: #CDDC39 !important; }

.md-lime-bg {
  background-color: #CDDC39 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-bg {
  background-color: #CDDC39 !important; }

.lime-fg {
  color: #CDDC39 !important; }

.lime-border {
  border-color: #CDDC39 !important; }

.lime-border-top {
  border-top-color: #CDDC39 !important; }

.lime-border-right {
  border-right-color: #CDDC39 !important; }

.lime-border-bottom {
  border-bottom-color: #CDDC39 !important; }

.lime-border-left {
  border-left-color: #CDDC39 !important; }

.lime-600-bg {
  background-color: #C0CA33 !important; }

.md-lime-600-bg {
  background-color: #C0CA33 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-600-fg {
  color: #C0CA33 !important; }

.lime-600-border {
  border-color: #C0CA33 !important; }

.lime-600-border-top {
  border-top-color: #C0CA33 !important; }

.lime-600-border-right {
  border-right-color: #C0CA33 !important; }

.lime-600-border-bottom {
  border-bottom-color: #C0CA33 !important; }

.lime-600-border-left {
  border-left-color: #C0CA33 !important; }

.lime-700-bg {
  background-color: #AFB42B !important; }

.md-lime-700-bg {
  background-color: #AFB42B !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-700-fg {
  color: #AFB42B !important; }

.lime-700-border {
  border-color: #AFB42B !important; }

.lime-700-border-top {
  border-top-color: #AFB42B !important; }

.lime-700-border-right {
  border-right-color: #AFB42B !important; }

.lime-700-border-bottom {
  border-bottom-color: #AFB42B !important; }

.lime-700-border-left {
  border-left-color: #AFB42B !important; }

.lime-800-bg {
  background-color: #9E9D24 !important; }

.md-lime-800-bg {
  background-color: #9E9D24 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-800-fg {
  color: #9E9D24 !important; }

.lime-800-border {
  border-color: #9E9D24 !important; }

.lime-800-border-top {
  border-top-color: #9E9D24 !important; }

.lime-800-border-right {
  border-right-color: #9E9D24 !important; }

.lime-800-border-bottom {
  border-bottom-color: #9E9D24 !important; }

.lime-800-border-left {
  border-left-color: #9E9D24 !important; }

.lime-900-bg {
  background-color: #827717 !important; }

.md-lime-900-bg {
  background-color: #827717 !important;
  color: white; }

.lime-900-fg {
  color: #827717 !important; }

.lime-900-border {
  border-color: #827717 !important; }

.lime-900-border-top {
  border-top-color: #827717 !important; }

.lime-900-border-right {
  border-right-color: #827717 !important; }

.lime-900-border-bottom {
  border-bottom-color: #827717 !important; }

.lime-900-border-left {
  border-left-color: #827717 !important; }

.lime-A100-bg {
  background-color: #F4FF81 !important; }

.md-lime-A100-bg {
  background-color: #F4FF81 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A100-fg {
  color: #F4FF81 !important; }

.lime-A100-border {
  border-color: #F4FF81 !important; }

.lime-A100-border-top {
  border-top-color: #F4FF81 !important; }

.lime-A100-border-right {
  border-right-color: #F4FF81 !important; }

.lime-A100-border-bottom {
  border-bottom-color: #F4FF81 !important; }

.lime-A100-border-left {
  border-left-color: #F4FF81 !important; }

.lime-A200-bg {
  background-color: #EEFF41 !important; }

.md-lime-A200-bg {
  background-color: #EEFF41 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A200-fg {
  color: #EEFF41 !important; }

.lime-A200-border {
  border-color: #EEFF41 !important; }

.lime-A200-border-top {
  border-top-color: #EEFF41 !important; }

.lime-A200-border-right {
  border-right-color: #EEFF41 !important; }

.lime-A200-border-bottom {
  border-bottom-color: #EEFF41 !important; }

.lime-A200-border-left {
  border-left-color: #EEFF41 !important; }

.lime-A400-bg {
  background-color: #C6FF00 !important; }

.md-lime-A400-bg {
  background-color: #C6FF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A400-fg {
  color: #C6FF00 !important; }

.lime-A400-border {
  border-color: #C6FF00 !important; }

.lime-A400-border-top {
  border-top-color: #C6FF00 !important; }

.lime-A400-border-right {
  border-right-color: #C6FF00 !important; }

.lime-A400-border-bottom {
  border-bottom-color: #C6FF00 !important; }

.lime-A400-border-left {
  border-left-color: #C6FF00 !important; }

.lime-A700-bg {
  background-color: #AEEA00 !important; }

.md-lime-A700-bg {
  background-color: #AEEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.lime-A700-fg {
  color: #AEEA00 !important; }

.lime-A700-border {
  border-color: #AEEA00 !important; }

.lime-A700-border-top {
  border-top-color: #AEEA00 !important; }

.lime-A700-border-right {
  border-right-color: #AEEA00 !important; }

.lime-A700-border-bottom {
  border-bottom-color: #AEEA00 !important; }

.lime-A700-border-left {
  border-left-color: #AEEA00 !important; }

.yellow-50-bg {
  background-color: #FFFDE7 !important; }

.md-yellow-50-bg {
  background-color: #FFFDE7 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-50-fg {
  color: #FFFDE7 !important; }

.yellow-50-border {
  border-color: #FFFDE7 !important; }

.yellow-50-border-top {
  border-top-color: #FFFDE7 !important; }

.yellow-50-border-right {
  border-right-color: #FFFDE7 !important; }

.yellow-50-border-bottom {
  border-bottom-color: #FFFDE7 !important; }

.yellow-50-border-left {
  border-left-color: #FFFDE7 !important; }

.yellow-100-bg {
  background-color: #FFF9C4 !important; }

.md-yellow-100-bg {
  background-color: #FFF9C4 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-100-fg {
  color: #FFF9C4 !important; }

.yellow-100-border {
  border-color: #FFF9C4 !important; }

.yellow-100-border-top {
  border-top-color: #FFF9C4 !important; }

.yellow-100-border-right {
  border-right-color: #FFF9C4 !important; }

.yellow-100-border-bottom {
  border-bottom-color: #FFF9C4 !important; }

.yellow-100-border-left {
  border-left-color: #FFF9C4 !important; }

.yellow-200-bg {
  background-color: #FFF59D !important; }

.md-yellow-200-bg {
  background-color: #FFF59D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-200-fg {
  color: #FFF59D !important; }

.yellow-200-border {
  border-color: #FFF59D !important; }

.yellow-200-border-top {
  border-top-color: #FFF59D !important; }

.yellow-200-border-right {
  border-right-color: #FFF59D !important; }

.yellow-200-border-bottom {
  border-bottom-color: #FFF59D !important; }

.yellow-200-border-left {
  border-left-color: #FFF59D !important; }

.yellow-300-bg {
  background-color: #FFF176 !important; }

.md-yellow-300-bg {
  background-color: #FFF176 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-300-fg {
  color: #FFF176 !important; }

.yellow-300-border {
  border-color: #FFF176 !important; }

.yellow-300-border-top {
  border-top-color: #FFF176 !important; }

.yellow-300-border-right {
  border-right-color: #FFF176 !important; }

.yellow-300-border-bottom {
  border-bottom-color: #FFF176 !important; }

.yellow-300-border-left {
  border-left-color: #FFF176 !important; }

.yellow-400-bg {
  background-color: #FFEE58 !important; }

.md-yellow-400-bg {
  background-color: #FFEE58 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-400-fg {
  color: #FFEE58 !important; }

.yellow-400-border {
  border-color: #FFEE58 !important; }

.yellow-400-border-top {
  border-top-color: #FFEE58 !important; }

.yellow-400-border-right {
  border-right-color: #FFEE58 !important; }

.yellow-400-border-bottom {
  border-bottom-color: #FFEE58 !important; }

.yellow-400-border-left {
  border-left-color: #FFEE58 !important; }

.yellow-500-bg {
  background-color: #FFEB3B !important; }

.md-yellow-500-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-500-fg {
  color: #FFEB3B !important; }

.yellow-500-border {
  border-color: #FFEB3B !important; }

.yellow-500-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-500-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-500-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-500-border-left {
  border-left-color: #FFEB3B !important; }

.md-yellow-bg {
  background-color: #FFEB3B !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-bg {
  background-color: #FFEB3B !important; }

.yellow-fg {
  color: #FFEB3B !important; }

.yellow-border {
  border-color: #FFEB3B !important; }

.yellow-border-top {
  border-top-color: #FFEB3B !important; }

.yellow-border-right {
  border-right-color: #FFEB3B !important; }

.yellow-border-bottom {
  border-bottom-color: #FFEB3B !important; }

.yellow-border-left {
  border-left-color: #FFEB3B !important; }

.yellow-600-bg {
  background-color: #FDD835 !important; }

.md-yellow-600-bg {
  background-color: #FDD835 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-600-fg {
  color: #FDD835 !important; }

.yellow-600-border {
  border-color: #FDD835 !important; }

.yellow-600-border-top {
  border-top-color: #FDD835 !important; }

.yellow-600-border-right {
  border-right-color: #FDD835 !important; }

.yellow-600-border-bottom {
  border-bottom-color: #FDD835 !important; }

.yellow-600-border-left {
  border-left-color: #FDD835 !important; }

.yellow-700-bg {
  background-color: #FBC02D !important; }

.md-yellow-700-bg {
  background-color: #FBC02D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-700-fg {
  color: #FBC02D !important; }

.yellow-700-border {
  border-color: #FBC02D !important; }

.yellow-700-border-top {
  border-top-color: #FBC02D !important; }

.yellow-700-border-right {
  border-right-color: #FBC02D !important; }

.yellow-700-border-bottom {
  border-bottom-color: #FBC02D !important; }

.yellow-700-border-left {
  border-left-color: #FBC02D !important; }

.yellow-800-bg {
  background-color: #F9A825 !important; }

.md-yellow-800-bg {
  background-color: #F9A825 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-800-fg {
  color: #F9A825 !important; }

.yellow-800-border {
  border-color: #F9A825 !important; }

.yellow-800-border-top {
  border-top-color: #F9A825 !important; }

.yellow-800-border-right {
  border-right-color: #F9A825 !important; }

.yellow-800-border-bottom {
  border-bottom-color: #F9A825 !important; }

.yellow-800-border-left {
  border-left-color: #F9A825 !important; }

.yellow-900-bg {
  background-color: #F57F17 !important; }

.md-yellow-900-bg {
  background-color: #F57F17 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-900-fg {
  color: #F57F17 !important; }

.yellow-900-border {
  border-color: #F57F17 !important; }

.yellow-900-border-top {
  border-top-color: #F57F17 !important; }

.yellow-900-border-right {
  border-right-color: #F57F17 !important; }

.yellow-900-border-bottom {
  border-bottom-color: #F57F17 !important; }

.yellow-900-border-left {
  border-left-color: #F57F17 !important; }

.yellow-A100-bg {
  background-color: #FFFF8D !important; }

.md-yellow-A100-bg {
  background-color: #FFFF8D !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A100-fg {
  color: #FFFF8D !important; }

.yellow-A100-border {
  border-color: #FFFF8D !important; }

.yellow-A100-border-top {
  border-top-color: #FFFF8D !important; }

.yellow-A100-border-right {
  border-right-color: #FFFF8D !important; }

.yellow-A100-border-bottom {
  border-bottom-color: #FFFF8D !important; }

.yellow-A100-border-left {
  border-left-color: #FFFF8D !important; }

.yellow-A200-bg {
  background-color: #FFFF00 !important; }

.md-yellow-A200-bg {
  background-color: #FFFF00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A200-fg {
  color: #FFFF00 !important; }

.yellow-A200-border {
  border-color: #FFFF00 !important; }

.yellow-A200-border-top {
  border-top-color: #FFFF00 !important; }

.yellow-A200-border-right {
  border-right-color: #FFFF00 !important; }

.yellow-A200-border-bottom {
  border-bottom-color: #FFFF00 !important; }

.yellow-A200-border-left {
  border-left-color: #FFFF00 !important; }

.yellow-A400-bg {
  background-color: #FFEA00 !important; }

.md-yellow-A400-bg {
  background-color: #FFEA00 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A400-fg {
  color: #FFEA00 !important; }

.yellow-A400-border {
  border-color: #FFEA00 !important; }

.yellow-A400-border-top {
  border-top-color: #FFEA00 !important; }

.yellow-A400-border-right {
  border-right-color: #FFEA00 !important; }

.yellow-A400-border-bottom {
  border-bottom-color: #FFEA00 !important; }

.yellow-A400-border-left {
  border-left-color: #FFEA00 !important; }

.yellow-A700-bg {
  background-color: #FFD600 !important; }

.md-yellow-A700-bg {
  background-color: #FFD600 !important;
  color: rgba(0, 0, 0, 0.87); }

.yellow-A700-fg {
  color: #FFD600 !important; }

.yellow-A700-border {
  border-color: #FFD600 !important; }

.yellow-A700-border-top {
  border-top-color: #FFD600 !important; }

.yellow-A700-border-right {
  border-right-color: #FFD600 !important; }

.yellow-A700-border-bottom {
  border-bottom-color: #FFD600 !important; }

.yellow-A700-border-left {
  border-left-color: #FFD600 !important; }

.amber-50-bg {
  background-color: #FFF8E1 !important; }

.md-amber-50-bg {
  background-color: #FFF8E1 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-50-fg {
  color: #FFF8E1 !important; }

.amber-50-border {
  border-color: #FFF8E1 !important; }

.amber-50-border-top {
  border-top-color: #FFF8E1 !important; }

.amber-50-border-right {
  border-right-color: #FFF8E1 !important; }

.amber-50-border-bottom {
  border-bottom-color: #FFF8E1 !important; }

.amber-50-border-left {
  border-left-color: #FFF8E1 !important; }

.amber-100-bg {
  background-color: #FFECB3 !important; }

.md-amber-100-bg {
  background-color: #FFECB3 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-100-fg {
  color: #FFECB3 !important; }

.amber-100-border {
  border-color: #FFECB3 !important; }

.amber-100-border-top {
  border-top-color: #FFECB3 !important; }

.amber-100-border-right {
  border-right-color: #FFECB3 !important; }

.amber-100-border-bottom {
  border-bottom-color: #FFECB3 !important; }

.amber-100-border-left {
  border-left-color: #FFECB3 !important; }

.amber-200-bg {
  background-color: #FFE082 !important; }

.md-amber-200-bg {
  background-color: #FFE082 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-200-fg {
  color: #FFE082 !important; }

.amber-200-border {
  border-color: #FFE082 !important; }

.amber-200-border-top {
  border-top-color: #FFE082 !important; }

.amber-200-border-right {
  border-right-color: #FFE082 !important; }

.amber-200-border-bottom {
  border-bottom-color: #FFE082 !important; }

.amber-200-border-left {
  border-left-color: #FFE082 !important; }

.amber-300-bg {
  background-color: #FFD54F !important; }

.md-amber-300-bg {
  background-color: #FFD54F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-300-fg {
  color: #FFD54F !important; }

.amber-300-border {
  border-color: #FFD54F !important; }

.amber-300-border-top {
  border-top-color: #FFD54F !important; }

.amber-300-border-right {
  border-right-color: #FFD54F !important; }

.amber-300-border-bottom {
  border-bottom-color: #FFD54F !important; }

.amber-300-border-left {
  border-left-color: #FFD54F !important; }

.amber-400-bg {
  background-color: #FFCA28 !important; }

.md-amber-400-bg {
  background-color: #FFCA28 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-400-fg {
  color: #FFCA28 !important; }

.amber-400-border {
  border-color: #FFCA28 !important; }

.amber-400-border-top {
  border-top-color: #FFCA28 !important; }

.amber-400-border-right {
  border-right-color: #FFCA28 !important; }

.amber-400-border-bottom {
  border-bottom-color: #FFCA28 !important; }

.amber-400-border-left {
  border-left-color: #FFCA28 !important; }

.amber-500-bg {
  background-color: #FFC107 !important; }

.md-amber-500-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-500-fg {
  color: #FFC107 !important; }

.amber-500-border {
  border-color: #FFC107 !important; }

.amber-500-border-top {
  border-top-color: #FFC107 !important; }

.amber-500-border-right {
  border-right-color: #FFC107 !important; }

.amber-500-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-500-border-left {
  border-left-color: #FFC107 !important; }

.md-amber-bg {
  background-color: #FFC107 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-bg {
  background-color: #FFC107 !important; }

.amber-fg {
  color: #FFC107 !important; }

.amber-border {
  border-color: #FFC107 !important; }

.amber-border-top {
  border-top-color: #FFC107 !important; }

.amber-border-right {
  border-right-color: #FFC107 !important; }

.amber-border-bottom {
  border-bottom-color: #FFC107 !important; }

.amber-border-left {
  border-left-color: #FFC107 !important; }

.amber-600-bg {
  background-color: #FFB300 !important; }

.md-amber-600-bg {
  background-color: #FFB300 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-600-fg {
  color: #FFB300 !important; }

.amber-600-border {
  border-color: #FFB300 !important; }

.amber-600-border-top {
  border-top-color: #FFB300 !important; }

.amber-600-border-right {
  border-right-color: #FFB300 !important; }

.amber-600-border-bottom {
  border-bottom-color: #FFB300 !important; }

.amber-600-border-left {
  border-left-color: #FFB300 !important; }

.amber-700-bg {
  background-color: #FFA000 !important; }

.md-amber-700-bg {
  background-color: #FFA000 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-700-fg {
  color: #FFA000 !important; }

.amber-700-border {
  border-color: #FFA000 !important; }

.amber-700-border-top {
  border-top-color: #FFA000 !important; }

.amber-700-border-right {
  border-right-color: #FFA000 !important; }

.amber-700-border-bottom {
  border-bottom-color: #FFA000 !important; }

.amber-700-border-left {
  border-left-color: #FFA000 !important; }

.amber-800-bg {
  background-color: #FF8F00 !important; }

.md-amber-800-bg {
  background-color: #FF8F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-800-fg {
  color: #FF8F00 !important; }

.amber-800-border {
  border-color: #FF8F00 !important; }

.amber-800-border-top {
  border-top-color: #FF8F00 !important; }

.amber-800-border-right {
  border-right-color: #FF8F00 !important; }

.amber-800-border-bottom {
  border-bottom-color: #FF8F00 !important; }

.amber-800-border-left {
  border-left-color: #FF8F00 !important; }

.amber-900-bg {
  background-color: #FF6F00 !important; }

.md-amber-900-bg {
  background-color: #FF6F00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-900-fg {
  color: #FF6F00 !important; }

.amber-900-border {
  border-color: #FF6F00 !important; }

.amber-900-border-top {
  border-top-color: #FF6F00 !important; }

.amber-900-border-right {
  border-right-color: #FF6F00 !important; }

.amber-900-border-bottom {
  border-bottom-color: #FF6F00 !important; }

.amber-900-border-left {
  border-left-color: #FF6F00 !important; }

.amber-A100-bg {
  background-color: #FFE57F !important; }

.md-amber-A100-bg {
  background-color: #FFE57F !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A100-fg {
  color: #FFE57F !important; }

.amber-A100-border {
  border-color: #FFE57F !important; }

.amber-A100-border-top {
  border-top-color: #FFE57F !important; }

.amber-A100-border-right {
  border-right-color: #FFE57F !important; }

.amber-A100-border-bottom {
  border-bottom-color: #FFE57F !important; }

.amber-A100-border-left {
  border-left-color: #FFE57F !important; }

.amber-A200-bg {
  background-color: #FFD740 !important; }

.md-amber-A200-bg {
  background-color: #FFD740 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A200-fg {
  color: #FFD740 !important; }

.amber-A200-border {
  border-color: #FFD740 !important; }

.amber-A200-border-top {
  border-top-color: #FFD740 !important; }

.amber-A200-border-right {
  border-right-color: #FFD740 !important; }

.amber-A200-border-bottom {
  border-bottom-color: #FFD740 !important; }

.amber-A200-border-left {
  border-left-color: #FFD740 !important; }

.amber-A400-bg {
  background-color: #FFC400 !important; }

.md-amber-A400-bg {
  background-color: #FFC400 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A400-fg {
  color: #FFC400 !important; }

.amber-A400-border {
  border-color: #FFC400 !important; }

.amber-A400-border-top {
  border-top-color: #FFC400 !important; }

.amber-A400-border-right {
  border-right-color: #FFC400 !important; }

.amber-A400-border-bottom {
  border-bottom-color: #FFC400 !important; }

.amber-A400-border-left {
  border-left-color: #FFC400 !important; }

.amber-A700-bg {
  background-color: #FFAB00 !important; }

.md-amber-A700-bg {
  background-color: #FFAB00 !important;
  color: rgba(0, 0, 0, 0.87); }

.amber-A700-fg {
  color: #FFAB00 !important; }

.amber-A700-border {
  border-color: #FFAB00 !important; }

.amber-A700-border-top {
  border-top-color: #FFAB00 !important; }

.amber-A700-border-right {
  border-right-color: #FFAB00 !important; }

.amber-A700-border-bottom {
  border-bottom-color: #FFAB00 !important; }

.amber-A700-border-left {
  border-left-color: #FFAB00 !important; }

.orange-50-bg {
  background-color: #FFF3E0 !important; }

.md-orange-50-bg {
  background-color: #FFF3E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-50-fg {
  color: #FFF3E0 !important; }

.orange-50-border {
  border-color: #FFF3E0 !important; }

.orange-50-border-top {
  border-top-color: #FFF3E0 !important; }

.orange-50-border-right {
  border-right-color: #FFF3E0 !important; }

.orange-50-border-bottom {
  border-bottom-color: #FFF3E0 !important; }

.orange-50-border-left {
  border-left-color: #FFF3E0 !important; }

.orange-100-bg {
  background-color: #FFE0B2 !important; }

.md-orange-100-bg {
  background-color: #FFE0B2 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-100-fg {
  color: #FFE0B2 !important; }

.orange-100-border {
  border-color: #FFE0B2 !important; }

.orange-100-border-top {
  border-top-color: #FFE0B2 !important; }

.orange-100-border-right {
  border-right-color: #FFE0B2 !important; }

.orange-100-border-bottom {
  border-bottom-color: #FFE0B2 !important; }

.orange-100-border-left {
  border-left-color: #FFE0B2 !important; }

.orange-200-bg {
  background-color: #FFCC80 !important; }

.md-orange-200-bg {
  background-color: #FFCC80 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-200-fg {
  color: #FFCC80 !important; }

.orange-200-border {
  border-color: #FFCC80 !important; }

.orange-200-border-top {
  border-top-color: #FFCC80 !important; }

.orange-200-border-right {
  border-right-color: #FFCC80 !important; }

.orange-200-border-bottom {
  border-bottom-color: #FFCC80 !important; }

.orange-200-border-left {
  border-left-color: #FFCC80 !important; }

.orange-300-bg {
  background-color: #FFB74D !important; }

.md-orange-300-bg {
  background-color: #FFB74D !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-300-fg {
  color: #FFB74D !important; }

.orange-300-border {
  border-color: #FFB74D !important; }

.orange-300-border-top {
  border-top-color: #FFB74D !important; }

.orange-300-border-right {
  border-right-color: #FFB74D !important; }

.orange-300-border-bottom {
  border-bottom-color: #FFB74D !important; }

.orange-300-border-left {
  border-left-color: #FFB74D !important; }

.orange-400-bg {
  background-color: #FFA726 !important; }

.md-orange-400-bg {
  background-color: #FFA726 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-400-fg {
  color: #FFA726 !important; }

.orange-400-border {
  border-color: #FFA726 !important; }

.orange-400-border-top {
  border-top-color: #FFA726 !important; }

.orange-400-border-right {
  border-right-color: #FFA726 !important; }

.orange-400-border-bottom {
  border-bottom-color: #FFA726 !important; }

.orange-400-border-left {
  border-left-color: #FFA726 !important; }

.orange-500-bg {
  background-color: #FF9800 !important; }

.md-orange-500-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-500-fg, .event-report table.md-table .event-subevent .td-event-type,
.event-report table.md-table .event-subevent [class*="icon-"] {
  color: #FF9800 !important; }

.orange-500-border {
  border-color: #FF9800 !important; }

.orange-500-border-top {
  border-top-color: #FF9800 !important; }

.orange-500-border-right {
  border-right-color: #FF9800 !important; }

.orange-500-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-500-border-left {
  border-left-color: #FF9800 !important; }

.md-orange-bg {
  background-color: #FF9800 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-bg {
  background-color: #FF9800 !important; }

.orange-fg {
  color: #FF9800 !important; }

.orange-border {
  border-color: #FF9800 !important; }

.orange-border-top {
  border-top-color: #FF9800 !important; }

.orange-border-right {
  border-right-color: #FF9800 !important; }

.orange-border-bottom {
  border-bottom-color: #FF9800 !important; }

.orange-border-left {
  border-left-color: #FF9800 !important; }

.orange-600-bg {
  background-color: #FB8C00 !important; }

.md-orange-600-bg {
  background-color: #FB8C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-600-fg {
  color: #FB8C00 !important; }

.orange-600-border {
  border-color: #FB8C00 !important; }

.orange-600-border-top {
  border-top-color: #FB8C00 !important; }

.orange-600-border-right {
  border-right-color: #FB8C00 !important; }

.orange-600-border-bottom {
  border-bottom-color: #FB8C00 !important; }

.orange-600-border-left {
  border-left-color: #FB8C00 !important; }

.orange-700-bg {
  background-color: #F57C00 !important; }

.md-orange-700-bg {
  background-color: #F57C00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-700-fg {
  color: #F57C00 !important; }

.orange-700-border {
  border-color: #F57C00 !important; }

.orange-700-border-top {
  border-top-color: #F57C00 !important; }

.orange-700-border-right {
  border-right-color: #F57C00 !important; }

.orange-700-border-bottom {
  border-bottom-color: #F57C00 !important; }

.orange-700-border-left {
  border-left-color: #F57C00 !important; }

.orange-800-bg {
  background-color: #EF6C00 !important; }

.md-orange-800-bg {
  background-color: #EF6C00 !important;
  color: white; }

.orange-800-fg {
  color: #EF6C00 !important; }

.orange-800-border {
  border-color: #EF6C00 !important; }

.orange-800-border-top {
  border-top-color: #EF6C00 !important; }

.orange-800-border-right {
  border-right-color: #EF6C00 !important; }

.orange-800-border-bottom {
  border-bottom-color: #EF6C00 !important; }

.orange-800-border-left {
  border-left-color: #EF6C00 !important; }

.orange-900-bg {
  background-color: #E65100 !important; }

.md-orange-900-bg {
  background-color: #E65100 !important;
  color: white; }

.orange-900-fg {
  color: #E65100 !important; }

.orange-900-border {
  border-color: #E65100 !important; }

.orange-900-border-top {
  border-top-color: #E65100 !important; }

.orange-900-border-right {
  border-right-color: #E65100 !important; }

.orange-900-border-bottom {
  border-bottom-color: #E65100 !important; }

.orange-900-border-left {
  border-left-color: #E65100 !important; }

.orange-A100-bg {
  background-color: #FFD180 !important; }

.md-orange-A100-bg {
  background-color: #FFD180 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A100-fg {
  color: #FFD180 !important; }

.orange-A100-border {
  border-color: #FFD180 !important; }

.orange-A100-border-top {
  border-top-color: #FFD180 !important; }

.orange-A100-border-right {
  border-right-color: #FFD180 !important; }

.orange-A100-border-bottom {
  border-bottom-color: #FFD180 !important; }

.orange-A100-border-left {
  border-left-color: #FFD180 !important; }

.orange-A200-bg {
  background-color: #FFAB40 !important; }

.md-orange-A200-bg {
  background-color: #FFAB40 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A200-fg {
  color: #FFAB40 !important; }

.orange-A200-border {
  border-color: #FFAB40 !important; }

.orange-A200-border-top {
  border-top-color: #FFAB40 !important; }

.orange-A200-border-right {
  border-right-color: #FFAB40 !important; }

.orange-A200-border-bottom {
  border-bottom-color: #FFAB40 !important; }

.orange-A200-border-left {
  border-left-color: #FFAB40 !important; }

.orange-A400-bg {
  background-color: #FF9100 !important; }

.md-orange-A400-bg {
  background-color: #FF9100 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A400-fg {
  color: #FF9100 !important; }

.orange-A400-border {
  border-color: #FF9100 !important; }

.orange-A400-border-top {
  border-top-color: #FF9100 !important; }

.orange-A400-border-right {
  border-right-color: #FF9100 !important; }

.orange-A400-border-bottom {
  border-bottom-color: #FF9100 !important; }

.orange-A400-border-left {
  border-left-color: #FF9100 !important; }

.orange-A700-bg {
  background-color: #FF6D00 !important; }

.md-orange-A700-bg {
  background-color: #FF6D00 !important;
  color: rgba(0, 0, 0, 0.87); }

.orange-A700-fg {
  color: #FF6D00 !important; }

.orange-A700-border {
  border-color: #FF6D00 !important; }

.orange-A700-border-top {
  border-top-color: #FF6D00 !important; }

.orange-A700-border-right {
  border-right-color: #FF6D00 !important; }

.orange-A700-border-bottom {
  border-bottom-color: #FF6D00 !important; }

.orange-A700-border-left {
  border-left-color: #FF6D00 !important; }

.deep-orange-50-bg {
  background-color: #FBE9E7 !important; }

.md-deep-orange-50-bg {
  background-color: #FBE9E7 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-50-fg {
  color: #FBE9E7 !important; }

.deep-orange-50-border {
  border-color: #FBE9E7 !important; }

.deep-orange-50-border-top {
  border-top-color: #FBE9E7 !important; }

.deep-orange-50-border-right {
  border-right-color: #FBE9E7 !important; }

.deep-orange-50-border-bottom {
  border-bottom-color: #FBE9E7 !important; }

.deep-orange-50-border-left {
  border-left-color: #FBE9E7 !important; }

.deep-orange-100-bg {
  background-color: #FFCCBC !important; }

.md-deep-orange-100-bg {
  background-color: #FFCCBC !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-100-fg {
  color: #FFCCBC !important; }

.deep-orange-100-border {
  border-color: #FFCCBC !important; }

.deep-orange-100-border-top {
  border-top-color: #FFCCBC !important; }

.deep-orange-100-border-right {
  border-right-color: #FFCCBC !important; }

.deep-orange-100-border-bottom {
  border-bottom-color: #FFCCBC !important; }

.deep-orange-100-border-left {
  border-left-color: #FFCCBC !important; }

.deep-orange-200-bg {
  background-color: #FFAB91 !important; }

.md-deep-orange-200-bg {
  background-color: #FFAB91 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-200-fg {
  color: #FFAB91 !important; }

.deep-orange-200-border {
  border-color: #FFAB91 !important; }

.deep-orange-200-border-top {
  border-top-color: #FFAB91 !important; }

.deep-orange-200-border-right {
  border-right-color: #FFAB91 !important; }

.deep-orange-200-border-bottom {
  border-bottom-color: #FFAB91 !important; }

.deep-orange-200-border-left {
  border-left-color: #FFAB91 !important; }

.deep-orange-300-bg {
  background-color: #FF8A65 !important; }

.md-deep-orange-300-bg {
  background-color: #FF8A65 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-300-fg {
  color: #FF8A65 !important; }

.deep-orange-300-border {
  border-color: #FF8A65 !important; }

.deep-orange-300-border-top {
  border-top-color: #FF8A65 !important; }

.deep-orange-300-border-right {
  border-right-color: #FF8A65 !important; }

.deep-orange-300-border-bottom {
  border-bottom-color: #FF8A65 !important; }

.deep-orange-300-border-left {
  border-left-color: #FF8A65 !important; }

.deep-orange-400-bg {
  background-color: #FF7043 !important; }

.md-deep-orange-400-bg {
  background-color: #FF7043 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-400-fg {
  color: #FF7043 !important; }

.deep-orange-400-border {
  border-color: #FF7043 !important; }

.deep-orange-400-border-top {
  border-top-color: #FF7043 !important; }

.deep-orange-400-border-right {
  border-right-color: #FF7043 !important; }

.deep-orange-400-border-bottom {
  border-bottom-color: #FF7043 !important; }

.deep-orange-400-border-left {
  border-left-color: #FF7043 !important; }

.deep-orange-500-bg {
  background-color: #FF5722 !important; }

.md-deep-orange-500-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-500-fg {
  color: #FF5722 !important; }

.deep-orange-500-border {
  border-color: #FF5722 !important; }

.deep-orange-500-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-500-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-500-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-500-border-left {
  border-left-color: #FF5722 !important; }

.md-deep-orange-bg {
  background-color: #FF5722 !important;
  color: white; }

.deep-orange-bg {
  background-color: #FF5722 !important; }

.deep-orange-fg {
  color: #FF5722 !important; }

.deep-orange-border {
  border-color: #FF5722 !important; }

.deep-orange-border-top {
  border-top-color: #FF5722 !important; }

.deep-orange-border-right {
  border-right-color: #FF5722 !important; }

.deep-orange-border-bottom {
  border-bottom-color: #FF5722 !important; }

.deep-orange-border-left {
  border-left-color: #FF5722 !important; }

.deep-orange-600-bg {
  background-color: #F4511E !important; }

.md-deep-orange-600-bg {
  background-color: #F4511E !important;
  color: white; }

.deep-orange-600-fg {
  color: #F4511E !important; }

.deep-orange-600-border {
  border-color: #F4511E !important; }

.deep-orange-600-border-top {
  border-top-color: #F4511E !important; }

.deep-orange-600-border-right {
  border-right-color: #F4511E !important; }

.deep-orange-600-border-bottom {
  border-bottom-color: #F4511E !important; }

.deep-orange-600-border-left {
  border-left-color: #F4511E !important; }

.deep-orange-700-bg {
  background-color: #E64A19 !important; }

.md-deep-orange-700-bg {
  background-color: #E64A19 !important;
  color: white; }

.deep-orange-700-fg {
  color: #E64A19 !important; }

.deep-orange-700-border {
  border-color: #E64A19 !important; }

.deep-orange-700-border-top {
  border-top-color: #E64A19 !important; }

.deep-orange-700-border-right {
  border-right-color: #E64A19 !important; }

.deep-orange-700-border-bottom {
  border-bottom-color: #E64A19 !important; }

.deep-orange-700-border-left {
  border-left-color: #E64A19 !important; }

.deep-orange-800-bg {
  background-color: #D84315 !important; }

.md-deep-orange-800-bg {
  background-color: #D84315 !important;
  color: white; }

.deep-orange-800-fg {
  color: #D84315 !important; }

.deep-orange-800-border {
  border-color: #D84315 !important; }

.deep-orange-800-border-top {
  border-top-color: #D84315 !important; }

.deep-orange-800-border-right {
  border-right-color: #D84315 !important; }

.deep-orange-800-border-bottom {
  border-bottom-color: #D84315 !important; }

.deep-orange-800-border-left {
  border-left-color: #D84315 !important; }

.deep-orange-900-bg {
  background-color: #BF360C !important; }

.md-deep-orange-900-bg {
  background-color: #BF360C !important;
  color: white; }

.deep-orange-900-fg {
  color: #BF360C !important; }

.deep-orange-900-border {
  border-color: #BF360C !important; }

.deep-orange-900-border-top {
  border-top-color: #BF360C !important; }

.deep-orange-900-border-right {
  border-right-color: #BF360C !important; }

.deep-orange-900-border-bottom {
  border-bottom-color: #BF360C !important; }

.deep-orange-900-border-left {
  border-left-color: #BF360C !important; }

.deep-orange-A100-bg {
  background-color: #FF9E80 !important; }

.md-deep-orange-A100-bg {
  background-color: #FF9E80 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A100-fg {
  color: #FF9E80 !important; }

.deep-orange-A100-border {
  border-color: #FF9E80 !important; }

.deep-orange-A100-border-top {
  border-top-color: #FF9E80 !important; }

.deep-orange-A100-border-right {
  border-right-color: #FF9E80 !important; }

.deep-orange-A100-border-bottom {
  border-bottom-color: #FF9E80 !important; }

.deep-orange-A100-border-left {
  border-left-color: #FF9E80 !important; }

.deep-orange-A200-bg {
  background-color: #FF6E40 !important; }

.md-deep-orange-A200-bg {
  background-color: #FF6E40 !important;
  color: rgba(0, 0, 0, 0.87); }

.deep-orange-A200-fg {
  color: #FF6E40 !important; }

.deep-orange-A200-border {
  border-color: #FF6E40 !important; }

.deep-orange-A200-border-top {
  border-top-color: #FF6E40 !important; }

.deep-orange-A200-border-right {
  border-right-color: #FF6E40 !important; }

.deep-orange-A200-border-bottom {
  border-bottom-color: #FF6E40 !important; }

.deep-orange-A200-border-left {
  border-left-color: #FF6E40 !important; }

.deep-orange-A400-bg {
  background-color: #FF3D00 !important; }

.md-deep-orange-A400-bg {
  background-color: #FF3D00 !important;
  color: white; }

.deep-orange-A400-fg {
  color: #FF3D00 !important; }

.deep-orange-A400-border {
  border-color: #FF3D00 !important; }

.deep-orange-A400-border-top {
  border-top-color: #FF3D00 !important; }

.deep-orange-A400-border-right {
  border-right-color: #FF3D00 !important; }

.deep-orange-A400-border-bottom {
  border-bottom-color: #FF3D00 !important; }

.deep-orange-A400-border-left {
  border-left-color: #FF3D00 !important; }

.deep-orange-A700-bg {
  background-color: #DD2C00 !important; }

.md-deep-orange-A700-bg {
  background-color: #DD2C00 !important;
  color: white; }

.deep-orange-A700-fg {
  color: #DD2C00 !important; }

.deep-orange-A700-border {
  border-color: #DD2C00 !important; }

.deep-orange-A700-border-top {
  border-top-color: #DD2C00 !important; }

.deep-orange-A700-border-right {
  border-right-color: #DD2C00 !important; }

.deep-orange-A700-border-bottom {
  border-bottom-color: #DD2C00 !important; }

.deep-orange-A700-border-left {
  border-left-color: #DD2C00 !important; }

.brown-50-bg {
  background-color: #EFEBE9 !important; }

.md-brown-50-bg {
  background-color: #EFEBE9 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-50-fg {
  color: #EFEBE9 !important; }

.brown-50-border {
  border-color: #EFEBE9 !important; }

.brown-50-border-top {
  border-top-color: #EFEBE9 !important; }

.brown-50-border-right {
  border-right-color: #EFEBE9 !important; }

.brown-50-border-bottom {
  border-bottom-color: #EFEBE9 !important; }

.brown-50-border-left {
  border-left-color: #EFEBE9 !important; }

.brown-100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-100-fg {
  color: #D7CCC8 !important; }

.brown-100-border {
  border-color: #D7CCC8 !important; }

.brown-100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(0, 0, 0, 0.87); }

.brown-200-fg {
  color: #BCAAA4 !important; }

.brown-200-border {
  border-color: #BCAAA4 !important; }

.brown-200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-300-bg {
  background-color: #A1887F !important; }

.md-brown-300-bg {
  background-color: #A1887F !important;
  color: white; }

.brown-300-fg {
  color: #A1887F !important; }

.brown-300-border {
  border-color: #A1887F !important; }

.brown-300-border-top {
  border-top-color: #A1887F !important; }

.brown-300-border-right {
  border-right-color: #A1887F !important; }

.brown-300-border-bottom {
  border-bottom-color: #A1887F !important; }

.brown-300-border-left {
  border-left-color: #A1887F !important; }

.brown-400-bg {
  background-color: #8D6E63 !important; }

.md-brown-400-bg {
  background-color: #8D6E63 !important;
  color: white; }

.brown-400-fg {
  color: #8D6E63 !important; }

.brown-400-border {
  border-color: #8D6E63 !important; }

.brown-400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-500-bg {
  background-color: #795548 !important; }

.md-brown-500-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-500-fg {
  color: #795548 !important; }

.brown-500-border {
  border-color: #795548 !important; }

.brown-500-border-top {
  border-top-color: #795548 !important; }

.brown-500-border-right {
  border-right-color: #795548 !important; }

.brown-500-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-500-border-left {
  border-left-color: #795548 !important; }

.md-brown-bg {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-bg {
  background-color: #795548 !important; }

.brown-fg {
  color: #795548 !important; }

.brown-border {
  border-color: #795548 !important; }

.brown-border-top {
  border-top-color: #795548 !important; }

.brown-border-right {
  border-right-color: #795548 !important; }

.brown-border-bottom {
  border-bottom-color: #795548 !important; }

.brown-border-left {
  border-left-color: #795548 !important; }

.brown-600-bg {
  background-color: #6D4C41 !important; }

.md-brown-600-bg {
  background-color: #6D4C41 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-600-fg {
  color: #6D4C41 !important; }

.brown-600-border {
  border-color: #6D4C41 !important; }

.brown-600-border-top {
  border-top-color: #6D4C41 !important; }

.brown-600-border-right {
  border-right-color: #6D4C41 !important; }

.brown-600-border-bottom {
  border-bottom-color: #6D4C41 !important; }

.brown-600-border-left {
  border-left-color: #6D4C41 !important; }

.brown-700-bg {
  background-color: #5D4037 !important; }

.md-brown-700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-700-fg {
  color: #5D4037 !important; }

.brown-700-border {
  border-color: #5D4037 !important; }

.brown-700-border-top {
  border-top-color: #5D4037 !important; }

.brown-700-border-right {
  border-right-color: #5D4037 !important; }

.brown-700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-700-border-left {
  border-left-color: #5D4037 !important; }

.brown-800-bg {
  background-color: #4E342E !important; }

.md-brown-800-bg {
  background-color: #4E342E !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-800-fg {
  color: #4E342E !important; }

.brown-800-border {
  border-color: #4E342E !important; }

.brown-800-border-top {
  border-top-color: #4E342E !important; }

.brown-800-border-right {
  border-right-color: #4E342E !important; }

.brown-800-border-bottom {
  border-bottom-color: #4E342E !important; }

.brown-800-border-left {
  border-left-color: #4E342E !important; }

.brown-900-bg {
  background-color: #3E2723 !important; }

.md-brown-900-bg {
  background-color: #3E2723 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-900-fg {
  color: #3E2723 !important; }

.brown-900-border {
  border-color: #3E2723 !important; }

.brown-900-border-top {
  border-top-color: #3E2723 !important; }

.brown-900-border-right {
  border-right-color: #3E2723 !important; }

.brown-900-border-bottom {
  border-bottom-color: #3E2723 !important; }

.brown-900-border-left {
  border-left-color: #3E2723 !important; }

.brown-A100-bg {
  background-color: #D7CCC8 !important; }

.md-brown-A100-bg {
  background-color: #D7CCC8 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A100-fg {
  color: #D7CCC8 !important; }

.brown-A100-border {
  border-color: #D7CCC8 !important; }

.brown-A100-border-top {
  border-top-color: #D7CCC8 !important; }

.brown-A100-border-right {
  border-right-color: #D7CCC8 !important; }

.brown-A100-border-bottom {
  border-bottom-color: #D7CCC8 !important; }

.brown-A100-border-left {
  border-left-color: #D7CCC8 !important; }

.brown-A200-bg {
  background-color: #BCAAA4 !important; }

.md-brown-A200-bg {
  background-color: #BCAAA4 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A200-fg {
  color: #BCAAA4 !important; }

.brown-A200-border {
  border-color: #BCAAA4 !important; }

.brown-A200-border-top {
  border-top-color: #BCAAA4 !important; }

.brown-A200-border-right {
  border-right-color: #BCAAA4 !important; }

.brown-A200-border-bottom {
  border-bottom-color: #BCAAA4 !important; }

.brown-A200-border-left {
  border-left-color: #BCAAA4 !important; }

.brown-A400-bg {
  background-color: #8D6E63 !important; }

.md-brown-A400-bg {
  background-color: #8D6E63 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A400-fg {
  color: #8D6E63 !important; }

.brown-A400-border {
  border-color: #8D6E63 !important; }

.brown-A400-border-top {
  border-top-color: #8D6E63 !important; }

.brown-A400-border-right {
  border-right-color: #8D6E63 !important; }

.brown-A400-border-bottom {
  border-bottom-color: #8D6E63 !important; }

.brown-A400-border-left {
  border-left-color: #8D6E63 !important; }

.brown-A700-bg {
  background-color: #5D4037 !important; }

.md-brown-A700-bg {
  background-color: #5D4037 !important;
  color: rgba(255, 255, 255, 0.87); }

.brown-A700-fg {
  color: #5D4037 !important; }

.brown-A700-border {
  border-color: #5D4037 !important; }

.brown-A700-border-top {
  border-top-color: #5D4037 !important; }

.brown-A700-border-right {
  border-right-color: #5D4037 !important; }

.brown-A700-border-bottom {
  border-bottom-color: #5D4037 !important; }

.brown-A700-border-left {
  border-left-color: #5D4037 !important; }

.grey-50-bg {
  background-color: #FAFAFA !important; }

.md-grey-50-bg {
  background-color: #FAFAFA !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-50-fg {
  color: #FAFAFA !important; }

.grey-50-border {
  border-color: #FAFAFA !important; }

.grey-50-border-top {
  border-top-color: #FAFAFA !important; }

.grey-50-border-right {
  border-right-color: #FAFAFA !important; }

.grey-50-border-bottom {
  border-bottom-color: #FAFAFA !important; }

.grey-50-border-left {
  border-left-color: #FAFAFA !important; }

.grey-100-bg {
  background-color: #F5F5F5 !important; }

.md-grey-100-bg {
  background-color: #F5F5F5 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-100-fg {
  color: #F5F5F5 !important; }

.grey-100-border {
  border-color: #F5F5F5 !important; }

.grey-100-border-top {
  border-top-color: #F5F5F5 !important; }

.grey-100-border-right {
  border-right-color: #F5F5F5 !important; }

.grey-100-border-bottom {
  border-bottom-color: #F5F5F5 !important; }

.grey-100-border-left {
  border-left-color: #F5F5F5 !important; }

.grey-200-bg {
  background-color: #EEEEEE !important; }

.md-grey-200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-200-fg {
  color: #EEEEEE !important; }

.grey-200-border {
  border-color: #EEEEEE !important; }

.grey-200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-300-bg {
  background-color: #E0E0E0 !important; }

.md-grey-300-bg {
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-300-fg {
  color: #E0E0E0 !important; }

.grey-300-border {
  border-color: #E0E0E0 !important; }

.grey-300-border-top {
  border-top-color: #E0E0E0 !important; }

.grey-300-border-right {
  border-right-color: #E0E0E0 !important; }

.grey-300-border-bottom {
  border-bottom-color: #E0E0E0 !important; }

.grey-300-border-left {
  border-left-color: #E0E0E0 !important; }

.grey-400-bg {
  background-color: #BDBDBD !important; }

.md-grey-400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-400-fg {
  color: #BDBDBD !important; }

.grey-400-border {
  border-color: #BDBDBD !important; }

.grey-400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-500-bg {
  background-color: #9E9E9E !important; }

.md-grey-500-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-500-fg {
  color: #9E9E9E !important; }

.grey-500-border {
  border-color: #9E9E9E !important; }

.grey-500-border-top {
  border-top-color: #9E9E9E !important; }

.grey-500-border-right {
  border-right-color: #9E9E9E !important; }

.grey-500-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-500-border-left {
  border-left-color: #9E9E9E !important; }

.md-grey-bg {
  background-color: #9E9E9E !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-bg {
  background-color: #9E9E9E !important; }

.grey-fg {
  color: #9E9E9E !important; }

.grey-border {
  border-color: #9E9E9E !important; }

.grey-border-top {
  border-top-color: #9E9E9E !important; }

.grey-border-right {
  border-right-color: #9E9E9E !important; }

.grey-border-bottom {
  border-bottom-color: #9E9E9E !important; }

.grey-border-left {
  border-left-color: #9E9E9E !important; }

.grey-600-bg {
  background-color: #757575 !important; }

.md-grey-600-bg {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-600-fg {
  color: #757575 !important; }

.grey-600-border {
  border-color: #757575 !important; }

.grey-600-border-top {
  border-top-color: #757575 !important; }

.grey-600-border-right {
  border-right-color: #757575 !important; }

.grey-600-border-bottom {
  border-bottom-color: #757575 !important; }

.grey-600-border-left {
  border-left-color: #757575 !important; }

.grey-700-bg {
  background-color: #616161 !important; }

.md-grey-700-bg {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-700-fg {
  color: #616161 !important; }

.grey-700-border {
  border-color: #616161 !important; }

.grey-700-border-top {
  border-top-color: #616161 !important; }

.grey-700-border-right {
  border-right-color: #616161 !important; }

.grey-700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-700-border-left {
  border-left-color: #616161 !important; }

.grey-800-bg {
  background-color: #424242 !important; }

.md-grey-800-bg {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-800-fg {
  color: #424242 !important; }

.grey-800-border {
  border-color: #424242 !important; }

.grey-800-border-top {
  border-top-color: #424242 !important; }

.grey-800-border-right {
  border-right-color: #424242 !important; }

.grey-800-border-bottom {
  border-bottom-color: #424242 !important; }

.grey-800-border-left {
  border-left-color: #424242 !important; }

.grey-900-bg {
  background-color: #212121 !important; }

.md-grey-900-bg {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87); }

.grey-900-fg {
  color: #212121 !important; }

.grey-900-border {
  border-color: #212121 !important; }

.grey-900-border-top {
  border-top-color: #212121 !important; }

.grey-900-border-right {
  border-right-color: #212121 !important; }

.grey-900-border-bottom {
  border-bottom-color: #212121 !important; }

.grey-900-border-left {
  border-left-color: #212121 !important; }

.grey-1000-bg {
  background-color: #000000 !important; }

.md-grey-1000-bg {
  background-color: #000000 !important; }

.grey-1000-fg {
  color: #000000 !important; }

.grey-1000-border {
  border-color: #000000 !important; }

.grey-1000-border-top {
  border-top-color: #000000 !important; }

.grey-1000-border-right {
  border-right-color: #000000 !important; }

.grey-1000-border-bottom {
  border-bottom-color: #000000 !important; }

.grey-1000-border-left {
  border-left-color: #000000 !important; }

.grey-A100-bg {
  background-color: #FFFFFF !important; }

.md-grey-A100-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A100-fg {
  color: #FFFFFF !important; }

.grey-A100-border {
  border-color: #FFFFFF !important; }

.grey-A100-border-top {
  border-top-color: #FFFFFF !important; }

.grey-A100-border-right {
  border-right-color: #FFFFFF !important; }

.grey-A100-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.grey-A100-border-left {
  border-left-color: #FFFFFF !important; }

.grey-A200-bg {
  background-color: #EEEEEE !important; }

.md-grey-A200-bg {
  background-color: #EEEEEE !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A200-fg {
  color: #EEEEEE !important; }

.grey-A200-border {
  border-color: #EEEEEE !important; }

.grey-A200-border-top {
  border-top-color: #EEEEEE !important; }

.grey-A200-border-right {
  border-right-color: #EEEEEE !important; }

.grey-A200-border-bottom {
  border-bottom-color: #EEEEEE !important; }

.grey-A200-border-left {
  border-left-color: #EEEEEE !important; }

.grey-A400-bg {
  background-color: #BDBDBD !important; }

.md-grey-A400-bg {
  background-color: #BDBDBD !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A400-fg {
  color: #BDBDBD !important; }

.grey-A400-border {
  border-color: #BDBDBD !important; }

.grey-A400-border-top {
  border-top-color: #BDBDBD !important; }

.grey-A400-border-right {
  border-right-color: #BDBDBD !important; }

.grey-A400-border-bottom {
  border-bottom-color: #BDBDBD !important; }

.grey-A400-border-left {
  border-left-color: #BDBDBD !important; }

.grey-A700-bg {
  background-color: #616161 !important; }

.md-grey-A700-bg {
  background-color: #616161 !important;
  color: rgba(0, 0, 0, 0.87); }

.grey-A700-fg {
  color: #616161 !important; }

.grey-A700-border {
  border-color: #616161 !important; }

.grey-A700-border-top {
  border-top-color: #616161 !important; }

.grey-A700-border-right {
  border-right-color: #616161 !important; }

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important; }

.grey-A700-border-left {
  border-left-color: #616161 !important; }

.blue-grey-50-bg {
  background-color: #ECEFF1 !important; }

.md-blue-grey-50-bg {
  background-color: #ECEFF1 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-50-fg {
  color: #ECEFF1 !important; }

.blue-grey-50-border {
  border-color: #ECEFF1 !important; }

.blue-grey-50-border-top {
  border-top-color: #ECEFF1 !important; }

.blue-grey-50-border-right {
  border-right-color: #ECEFF1 !important; }

.blue-grey-50-border-bottom {
  border-bottom-color: #ECEFF1 !important; }

.blue-grey-50-border-left {
  border-left-color: #ECEFF1 !important; }

.blue-grey-100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-100-bg {
  background-color: #CFD8DC !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-100-fg {
  color: #CFD8DC !important; }

.blue-grey-100-border {
  border-color: #CFD8DC !important; }

.blue-grey-100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-200-fg {
  color: #B0BEC5 !important; }

.blue-grey-200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-300-bg {
  background-color: #90A4AE !important; }

.md-blue-grey-300-bg {
  background-color: #90A4AE !important;
  color: rgba(0, 0, 0, 0.87); }

.blue-grey-300-fg {
  color: #90A4AE !important; }

.blue-grey-300-border {
  border-color: #90A4AE !important; }

.blue-grey-300-border-top {
  border-top-color: #90A4AE !important; }

.blue-grey-300-border-right {
  border-right-color: #90A4AE !important; }

.blue-grey-300-border-bottom {
  border-bottom-color: #90A4AE !important; }

.blue-grey-300-border-left {
  border-left-color: #90A4AE !important; }

.blue-grey-400-bg {
  background-color: #78909C !important; }

.md-blue-grey-400-bg {
  background-color: #78909C !important;
  color: white; }

.blue-grey-400-fg {
  color: #78909C !important; }

.blue-grey-400-border {
  border-color: #78909C !important; }

.blue-grey-400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-500-bg {
  background-color: #607D8B !important; }

.md-blue-grey-500-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-500-fg {
  color: #607D8B !important; }

.blue-grey-500-border {
  border-color: #607D8B !important; }

.blue-grey-500-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-500-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-500-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-500-border-left {
  border-left-color: #607D8B !important; }

.md-blue-grey-bg {
  background-color: #607D8B !important;
  color: white; }

.blue-grey-bg {
  background-color: #607D8B !important; }

.blue-grey-fg {
  color: #607D8B !important; }

.blue-grey-border {
  border-color: #607D8B !important; }

.blue-grey-border-top {
  border-top-color: #607D8B !important; }

.blue-grey-border-right {
  border-right-color: #607D8B !important; }

.blue-grey-border-bottom {
  border-bottom-color: #607D8B !important; }

.blue-grey-border-left {
  border-left-color: #607D8B !important; }

.blue-grey-600-bg {
  background-color: #546E7A !important; }

.md-blue-grey-600-bg {
  background-color: #546E7A !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-600-fg {
  color: #546E7A !important; }

.blue-grey-600-border {
  border-color: #546E7A !important; }

.blue-grey-600-border-top {
  border-top-color: #546E7A !important; }

.blue-grey-600-border-right {
  border-right-color: #546E7A !important; }

.blue-grey-600-border-bottom {
  border-bottom-color: #546E7A !important; }

.blue-grey-600-border-left {
  border-left-color: #546E7A !important; }

.blue-grey-700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-700-fg {
  color: #455A64 !important; }

.blue-grey-700-border {
  border-color: #455A64 !important; }

.blue-grey-700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-700-border-left {
  border-left-color: #455A64 !important; }

.blue-grey-800-bg {
  background-color: #37474F !important; }

.md-blue-grey-800-bg {
  background-color: #37474F !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-800-fg {
  color: #37474F !important; }

.blue-grey-800-border {
  border-color: #37474F !important; }

.blue-grey-800-border-top {
  border-top-color: #37474F !important; }

.blue-grey-800-border-right {
  border-right-color: #37474F !important; }

.blue-grey-800-border-bottom {
  border-bottom-color: #37474F !important; }

.blue-grey-800-border-left {
  border-left-color: #37474F !important; }

.blue-grey-900-bg {
  background-color: #263238 !important; }

.md-blue-grey-900-bg {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-900-fg {
  color: #263238 !important; }

.blue-grey-900-border {
  border-color: #263238 !important; }

.blue-grey-900-border-top {
  border-top-color: #263238 !important; }

.blue-grey-900-border-right {
  border-right-color: #263238 !important; }

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important; }

.blue-grey-900-border-left {
  border-left-color: #263238 !important; }

.blue-grey-A100-bg {
  background-color: #CFD8DC !important; }

.md-blue-grey-A100-bg {
  background-color: #CFD8DC !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A100-fg {
  color: #CFD8DC !important; }

.blue-grey-A100-border {
  border-color: #CFD8DC !important; }

.blue-grey-A100-border-top {
  border-top-color: #CFD8DC !important; }

.blue-grey-A100-border-right {
  border-right-color: #CFD8DC !important; }

.blue-grey-A100-border-bottom {
  border-bottom-color: #CFD8DC !important; }

.blue-grey-A100-border-left {
  border-left-color: #CFD8DC !important; }

.blue-grey-A200-bg {
  background-color: #B0BEC5 !important; }

.md-blue-grey-A200-bg {
  background-color: #B0BEC5 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A200-fg {
  color: #B0BEC5 !important; }

.blue-grey-A200-border {
  border-color: #B0BEC5 !important; }

.blue-grey-A200-border-top {
  border-top-color: #B0BEC5 !important; }

.blue-grey-A200-border-right {
  border-right-color: #B0BEC5 !important; }

.blue-grey-A200-border-bottom {
  border-bottom-color: #B0BEC5 !important; }

.blue-grey-A200-border-left {
  border-left-color: #B0BEC5 !important; }

.blue-grey-A400-bg {
  background-color: #78909C !important; }

.md-blue-grey-A400-bg {
  background-color: #78909C !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A400-fg {
  color: #78909C !important; }

.blue-grey-A400-border {
  border-color: #78909C !important; }

.blue-grey-A400-border-top {
  border-top-color: #78909C !important; }

.blue-grey-A400-border-right {
  border-right-color: #78909C !important; }

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909C !important; }

.blue-grey-A400-border-left {
  border-left-color: #78909C !important; }

.blue-grey-A700-bg {
  background-color: #455A64 !important; }

.md-blue-grey-A700-bg {
  background-color: #455A64 !important;
  color: rgba(255, 255, 255, 0.87); }

.blue-grey-A700-fg {
  color: #455A64 !important; }

.blue-grey-A700-border {
  border-color: #455A64 !important; }

.blue-grey-A700-border-top {
  border-top-color: #455A64 !important; }

.blue-grey-A700-border-right {
  border-right-color: #455A64 !important; }

.blue-grey-A700-border-bottom {
  border-bottom-color: #455A64 !important; }

.blue-grey-A700-border-left {
  border-left-color: #455A64 !important; }

.white-500-bg {
  background-color: #FFFFFF !important; }

.md-white-500-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-500-fg, md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container input,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container label,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container label::after, md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input:focus,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label,
md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label::after {
  color: #FFFFFF !important; }

.white-500-border {
  border-color: #FFFFFF !important; }

.white-500-border-top {
  border-top-color: #FFFFFF !important; }

.white-500-border-right {
  border-right-color: #FFFFFF !important; }

.white-500-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-500-border-left {
  border-left-color: #FFFFFF !important; }

.md-white-bg {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.87); }

.white-bg {
  background-color: #FFFFFF !important; }

.white-fg {
  color: #FFFFFF !important; }

.white-border {
  border-color: #FFFFFF !important; }

.white-border-top {
  border-top-color: #FFFFFF !important; }

.white-border-right {
  border-right-color: #FFFFFF !important; }

.white-border-bottom {
  border-bottom-color: #FFFFFF !important; }

.white-border-left {
  border-left-color: #FFFFFF !important; }

.black-500-bg {
  background-color: #000000 !important; }

.md-black-500-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-500-fg {
  color: #000000 !important; }

.black-500-border {
  border-color: #000000 !important; }

.black-500-border-top {
  border-top-color: #000000 !important; }

.black-500-border-right {
  border-right-color: #000000 !important; }

.black-500-border-bottom {
  border-bottom-color: #000000 !important; }

.black-500-border-left {
  border-left-color: #000000 !important; }

.md-black-bg {
  background-color: #000000 !important;
  color: rgba(255, 255, 255, 0.87); }

.black-bg {
  background-color: #000000 !important; }

.black-fg {
  color: #000000 !important; }

.black-border {
  border-color: #000000 !important; }

.black-border-top {
  border-top-color: #000000 !important; }

.black-border-right {
  border-right-color: #000000 !important; }

.black-border-bottom {
  border-bottom-color: #000000 !important; }

.black-border-left {
  border-left-color: #000000 !important; }

/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-radius {
  border-radius: 0; }

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px; }

.hidden {
  visibility: hidden;
  opacity: 0; }

[fill-width] {
  min-width: 100%; }

[fill-height] {
  min-height: 100%; }

.full-height {
  height: 100% !important; }

a img {
  display: block;
  outline: none; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }

.overflow-x {
  overflow-x: hidden; }

.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .flex-scrollable.only-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .flex-scrollable.only-y {
    overflow-x: hidden;
    overflow-y: auto; }

.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.align-vertical:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle; }

.align-vertical .align-vertical-top,
.align-vertical .align-vertical-middle,
.align-vertical .align-vertical-bottom {
  display: inline-block; }

.align-vertical .align-vertical-top {
  vertical-align: top; }

.align-vertical .align-vertical-middle {
  vertical-align: middle; }

.align-vertical .align-vertical-bottom {
  vertical-align: bottom; }

.align-vertical-top,
.align-vertical-middle,
.align-vertical-bottom {
  display: inline-block; }

.align-vertical-top {
  vertical-align: top; }

.align-vertical-middle {
  vertical-align: middle; }

.align-vertical-bottom {
  vertical-align: bottom; }

.align-vertical-text-bottom {
  vertical-align: text-bottom !important; }

.text-italic {
  font-style: italic; }

.text-semibold, .allocations-view md-list-item .md-list-item-text .title, .allocations-proData-view md-list-item .md-list-item-text .title, .allocationMonitrips-view md-list-item .md-list-item-text .title, md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .nv-noData, md-dialog.allocationPRODATA-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.allocationCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.allocationTicketsCentroidsCSV-list md-content md-list-item .md-list-item-text .title, md-dialog.centroidCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.lineCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.employeeCSV-list md-dialog-content md-list-item .md-list-item-text .title, md-dialog.serviceCSV-list md-dialog-content md-list-item .md-list-item-text .title, .service-report .service-sidebar md-list-item .md-list-item-text .title {
  font-weight: 600; }

.text-bold, strong {
  font-weight: 700; }

.text-strike {
  text-decoration: line-through; }

.text-super {
  vertical-align: super; }

.text-sub {
  vertical-align: sub; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.z-index-0 {
  z-index: 0 !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.z-index-6 {
  z-index: 6 !important; }

.z-index-7 {
  z-index: 7 !important; }

.z-index-8 {
  z-index: 8 !important; }

.z-index-9 {
  z-index: 9 !important; }

.z-index-10 {
  z-index: 10 !important; }

.z-index-11 {
  z-index: 11 !important; }

.z-index-12 {
  z-index: 12 !important; }

.z-index-13 {
  z-index: 13 !important; }

.z-index-14 {
  z-index: 14 !important; }

.z-index-15 {
  z-index: 15 !important; }

.z-index-16 {
  z-index: 16 !important; }

.z-index-17 {
  z-index: 17 !important; }

.z-index-18 {
  z-index: 18 !important; }

.z-index-19 {
  z-index: 19 !important; }

.z-index-20 {
  z-index: 20 !important; }

.padding-0,
.p-0 {
  padding: 0px !important; }

.padding-top-0,
.pt-0 {
  padding-top: 0px !important; }

.padding-right-0,
.pr-0 {
  padding-right: 0px !important; }

.padding-bottom-0,
.pb-0 {
  padding-bottom: 0px !important; }

.padding-left-0,
.pl-0 {
  padding-left: 0px !important; }

.padding-horizontal-0,
.ph-0 {
  padding-left: 0px;
  padding-right: 0px; }

.padding-vertical-0,
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.padding-1,
.p-1 {
  padding: 1px !important; }

.padding-top-1,
.pt-1 {
  padding-top: 1px !important; }

.padding-right-1,
.pr-1 {
  padding-right: 1px !important; }

.padding-bottom-1,
.pb-1 {
  padding-bottom: 1px !important; }

.padding-left-1,
.pl-1 {
  padding-left: 1px !important; }

.padding-horizontal-1,
.ph-1 {
  padding-left: 1px;
  padding-right: 1px; }

.padding-vertical-1,
.pv-1 {
  padding-top: 1px;
  padding-bottom: 1px; }

.padding-2,
.p-2 {
  padding: 2px !important; }

.padding-top-2,
.pt-2 {
  padding-top: 2px !important; }

.padding-right-2,
.pr-2 {
  padding-right: 2px !important; }

.padding-bottom-2,
.pb-2 {
  padding-bottom: 2px !important; }

.padding-left-2,
.pl-2 {
  padding-left: 2px !important; }

.padding-horizontal-2,
.ph-2 {
  padding-left: 2px;
  padding-right: 2px; }

.padding-vertical-2,
.pv-2 {
  padding-top: 2px;
  padding-bottom: 2px; }

.padding-3,
.p-3 {
  padding: 3px !important; }

.padding-top-3,
.pt-3 {
  padding-top: 3px !important; }

.padding-right-3,
.pr-3 {
  padding-right: 3px !important; }

.padding-bottom-3,
.pb-3 {
  padding-bottom: 3px !important; }

.padding-left-3,
.pl-3 {
  padding-left: 3px !important; }

.padding-horizontal-3,
.ph-3 {
  padding-left: 3px;
  padding-right: 3px; }

.padding-vertical-3,
.pv-3 {
  padding-top: 3px;
  padding-bottom: 3px; }

.padding-4,
.p-4 {
  padding: 4px !important; }

.padding-top-4,
.pt-4 {
  padding-top: 4px !important; }

.padding-right-4,
.pr-4 {
  padding-right: 4px !important; }

.padding-bottom-4,
.pb-4 {
  padding-bottom: 4px !important; }

.padding-left-4,
.pl-4 {
  padding-left: 4px !important; }

.padding-horizontal-4,
.ph-4 {
  padding-left: 4px;
  padding-right: 4px; }

.padding-vertical-4,
.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px; }

.padding-5,
.p-5 {
  padding: 5px !important; }

.padding-top-5,
.pt-5 {
  padding-top: 5px !important; }

.padding-right-5,
.pr-5 {
  padding-right: 5px !important; }

.padding-bottom-5,
.pb-5 {
  padding-bottom: 5px !important; }

.padding-left-5,
.pl-5 {
  padding-left: 5px !important; }

.padding-horizontal-5,
.ph-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding-vertical-5,
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.padding-6,
.p-6 {
  padding: 6px !important; }

.padding-top-6,
.pt-6 {
  padding-top: 6px !important; }

.padding-right-6,
.pr-6 {
  padding-right: 6px !important; }

.padding-bottom-6,
.pb-6 {
  padding-bottom: 6px !important; }

.padding-left-6,
.pl-6 {
  padding-left: 6px !important; }

.padding-horizontal-6,
.ph-6 {
  padding-left: 6px;
  padding-right: 6px; }

.padding-vertical-6,
.pv-6 {
  padding-top: 6px;
  padding-bottom: 6px; }

.padding-7,
.p-7 {
  padding: 7px !important; }

.padding-top-7,
.pt-7 {
  padding-top: 7px !important; }

.padding-right-7,
.pr-7 {
  padding-right: 7px !important; }

.padding-bottom-7,
.pb-7 {
  padding-bottom: 7px !important; }

.padding-left-7,
.pl-7 {
  padding-left: 7px !important; }

.padding-horizontal-7,
.ph-7 {
  padding-left: 7px;
  padding-right: 7px; }

.padding-vertical-7,
.pv-7 {
  padding-top: 7px;
  padding-bottom: 7px; }

.padding-8,
.p-8 {
  padding: 8px !important; }

.padding-top-8,
.pt-8 {
  padding-top: 8px !important; }

.padding-right-8,
.pr-8 {
  padding-right: 8px !important; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px !important; }

.padding-left-8,
.pl-8 {
  padding-left: 8px !important; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-9,
.p-9 {
  padding: 9px !important; }

.padding-top-9,
.pt-9 {
  padding-top: 9px !important; }

.padding-right-9,
.pr-9 {
  padding-right: 9px !important; }

.padding-bottom-9,
.pb-9 {
  padding-bottom: 9px !important; }

.padding-left-9,
.pl-9 {
  padding-left: 9px !important; }

.padding-horizontal-9,
.ph-9 {
  padding-left: 9px;
  padding-right: 9px; }

.padding-vertical-9,
.pv-9 {
  padding-top: 9px;
  padding-bottom: 9px; }

.padding-10,
.p-10 {
  padding: 10px !important; }

.padding-top-10,
.pt-10 {
  padding-top: 10px !important; }

.padding-right-10,
.pr-10 {
  padding-right: 10px !important; }

.padding-bottom-10,
.pb-10 {
  padding-bottom: 10px !important; }

.padding-left-10,
.pl-10 {
  padding-left: 10px !important; }

.padding-horizontal-10,
.ph-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding-vertical-10,
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.padding-11,
.p-11 {
  padding: 11px !important; }

.padding-top-11,
.pt-11 {
  padding-top: 11px !important; }

.padding-right-11,
.pr-11 {
  padding-right: 11px !important; }

.padding-bottom-11,
.pb-11 {
  padding-bottom: 11px !important; }

.padding-left-11,
.pl-11 {
  padding-left: 11px !important; }

.padding-horizontal-11,
.ph-11 {
  padding-left: 11px;
  padding-right: 11px; }

.padding-vertical-11,
.pv-11 {
  padding-top: 11px;
  padding-bottom: 11px; }

.padding-12,
.p-12 {
  padding: 12px !important; }

.padding-top-12,
.pt-12 {
  padding-top: 12px !important; }

.padding-right-12,
.pr-12 {
  padding-right: 12px !important; }

.padding-bottom-12,
.pb-12 {
  padding-bottom: 12px !important; }

.padding-left-12,
.pl-12 {
  padding-left: 12px !important; }

.padding-horizontal-12,
.ph-12 {
  padding-left: 12px;
  padding-right: 12px; }

.padding-vertical-12,
.pv-12 {
  padding-top: 12px;
  padding-bottom: 12px; }

.padding-13,
.p-13 {
  padding: 13px !important; }

.padding-top-13,
.pt-13 {
  padding-top: 13px !important; }

.padding-right-13,
.pr-13 {
  padding-right: 13px !important; }

.padding-bottom-13,
.pb-13 {
  padding-bottom: 13px !important; }

.padding-left-13,
.pl-13 {
  padding-left: 13px !important; }

.padding-horizontal-13,
.ph-13 {
  padding-left: 13px;
  padding-right: 13px; }

.padding-vertical-13,
.pv-13 {
  padding-top: 13px;
  padding-bottom: 13px; }

.padding-14,
.p-14 {
  padding: 14px !important; }

.padding-top-14,
.pt-14 {
  padding-top: 14px !important; }

.padding-right-14,
.pr-14 {
  padding-right: 14px !important; }

.padding-bottom-14,
.pb-14 {
  padding-bottom: 14px !important; }

.padding-left-14,
.pl-14 {
  padding-left: 14px !important; }

.padding-horizontal-14,
.ph-14 {
  padding-left: 14px;
  padding-right: 14px; }

.padding-vertical-14,
.pv-14 {
  padding-top: 14px;
  padding-bottom: 14px; }

.padding-15,
.p-15 {
  padding: 15px !important; }

.padding-top-15,
.pt-15 {
  padding-top: 15px !important; }

.padding-right-15,
.pr-15 {
  padding-right: 15px !important; }

.padding-bottom-15,
.pb-15 {
  padding-bottom: 15px !important; }

.padding-left-15,
.pl-15 {
  padding-left: 15px !important; }

.padding-horizontal-15,
.ph-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding-vertical-15,
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.padding-16,
.p-16 {
  padding: 16px !important; }

.padding-top-16,
.pt-16 {
  padding-top: 16px !important; }

.padding-right-16,
.pr-16 {
  padding-right: 16px !important; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px !important; }

.padding-left-16,
.pl-16 {
  padding-left: 16px !important; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-17,
.p-17 {
  padding: 17px !important; }

.padding-top-17,
.pt-17 {
  padding-top: 17px !important; }

.padding-right-17,
.pr-17 {
  padding-right: 17px !important; }

.padding-bottom-17,
.pb-17 {
  padding-bottom: 17px !important; }

.padding-left-17,
.pl-17 {
  padding-left: 17px !important; }

.padding-horizontal-17,
.ph-17 {
  padding-left: 17px;
  padding-right: 17px; }

.padding-vertical-17,
.pv-17 {
  padding-top: 17px;
  padding-bottom: 17px; }

.padding-18,
.p-18 {
  padding: 18px !important; }

.padding-top-18,
.pt-18 {
  padding-top: 18px !important; }

.padding-right-18,
.pr-18 {
  padding-right: 18px !important; }

.padding-bottom-18,
.pb-18 {
  padding-bottom: 18px !important; }

.padding-left-18,
.pl-18 {
  padding-left: 18px !important; }

.padding-horizontal-18,
.ph-18 {
  padding-left: 18px;
  padding-right: 18px; }

.padding-vertical-18,
.pv-18 {
  padding-top: 18px;
  padding-bottom: 18px; }

.padding-19,
.p-19 {
  padding: 19px !important; }

.padding-top-19,
.pt-19 {
  padding-top: 19px !important; }

.padding-right-19,
.pr-19 {
  padding-right: 19px !important; }

.padding-bottom-19,
.pb-19 {
  padding-bottom: 19px !important; }

.padding-left-19,
.pl-19 {
  padding-left: 19px !important; }

.padding-horizontal-19,
.ph-19 {
  padding-left: 19px;
  padding-right: 19px; }

.padding-vertical-19,
.pv-19 {
  padding-top: 19px;
  padding-bottom: 19px; }

.padding-20,
.p-20 {
  padding: 20px !important; }

.padding-top-20,
.pt-20 {
  padding-top: 20px !important; }

.padding-right-20,
.pr-20 {
  padding-right: 20px !important; }

.padding-bottom-20,
.pb-20 {
  padding-bottom: 20px !important; }

.padding-left-20,
.pl-20 {
  padding-left: 20px !important; }

.padding-horizontal-20,
.ph-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding-vertical-20,
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.padding-21,
.p-21 {
  padding: 21px !important; }

.padding-top-21,
.pt-21 {
  padding-top: 21px !important; }

.padding-right-21,
.pr-21 {
  padding-right: 21px !important; }

.padding-bottom-21,
.pb-21 {
  padding-bottom: 21px !important; }

.padding-left-21,
.pl-21 {
  padding-left: 21px !important; }

.padding-horizontal-21,
.ph-21 {
  padding-left: 21px;
  padding-right: 21px; }

.padding-vertical-21,
.pv-21 {
  padding-top: 21px;
  padding-bottom: 21px; }

.padding-22,
.p-22 {
  padding: 22px !important; }

.padding-top-22,
.pt-22 {
  padding-top: 22px !important; }

.padding-right-22,
.pr-22 {
  padding-right: 22px !important; }

.padding-bottom-22,
.pb-22 {
  padding-bottom: 22px !important; }

.padding-left-22,
.pl-22 {
  padding-left: 22px !important; }

.padding-horizontal-22,
.ph-22 {
  padding-left: 22px;
  padding-right: 22px; }

.padding-vertical-22,
.pv-22 {
  padding-top: 22px;
  padding-bottom: 22px; }

.padding-23,
.p-23 {
  padding: 23px !important; }

.padding-top-23,
.pt-23 {
  padding-top: 23px !important; }

.padding-right-23,
.pr-23 {
  padding-right: 23px !important; }

.padding-bottom-23,
.pb-23 {
  padding-bottom: 23px !important; }

.padding-left-23,
.pl-23 {
  padding-left: 23px !important; }

.padding-horizontal-23,
.ph-23 {
  padding-left: 23px;
  padding-right: 23px; }

.padding-vertical-23,
.pv-23 {
  padding-top: 23px;
  padding-bottom: 23px; }

.padding-24,
.p-24 {
  padding: 24px !important; }

.padding-top-24,
.pt-24 {
  padding-top: 24px !important; }

.padding-right-24,
.pr-24 {
  padding-right: 24px !important; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px !important; }

.padding-left-24,
.pl-24 {
  padding-left: 24px !important; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-25,
.p-25 {
  padding: 25px !important; }

.padding-top-25,
.pt-25 {
  padding-top: 25px !important; }

.padding-right-25,
.pr-25 {
  padding-right: 25px !important; }

.padding-bottom-25,
.pb-25 {
  padding-bottom: 25px !important; }

.padding-left-25,
.pl-25 {
  padding-left: 25px !important; }

.padding-horizontal-25,
.ph-25 {
  padding-left: 25px;
  padding-right: 25px; }

.padding-vertical-25,
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.padding-26,
.p-26 {
  padding: 26px !important; }

.padding-top-26,
.pt-26 {
  padding-top: 26px !important; }

.padding-right-26,
.pr-26 {
  padding-right: 26px !important; }

.padding-bottom-26,
.pb-26 {
  padding-bottom: 26px !important; }

.padding-left-26,
.pl-26 {
  padding-left: 26px !important; }

.padding-horizontal-26,
.ph-26 {
  padding-left: 26px;
  padding-right: 26px; }

.padding-vertical-26,
.pv-26 {
  padding-top: 26px;
  padding-bottom: 26px; }

.padding-27,
.p-27 {
  padding: 27px !important; }

.padding-top-27,
.pt-27 {
  padding-top: 27px !important; }

.padding-right-27,
.pr-27 {
  padding-right: 27px !important; }

.padding-bottom-27,
.pb-27 {
  padding-bottom: 27px !important; }

.padding-left-27,
.pl-27 {
  padding-left: 27px !important; }

.padding-horizontal-27,
.ph-27 {
  padding-left: 27px;
  padding-right: 27px; }

.padding-vertical-27,
.pv-27 {
  padding-top: 27px;
  padding-bottom: 27px; }

.padding-28,
.p-28 {
  padding: 28px !important; }

.padding-top-28,
.pt-28 {
  padding-top: 28px !important; }

.padding-right-28,
.pr-28 {
  padding-right: 28px !important; }

.padding-bottom-28,
.pb-28 {
  padding-bottom: 28px !important; }

.padding-left-28,
.pl-28 {
  padding-left: 28px !important; }

.padding-horizontal-28,
.ph-28 {
  padding-left: 28px;
  padding-right: 28px; }

.padding-vertical-28,
.pv-28 {
  padding-top: 28px;
  padding-bottom: 28px; }

.padding-29,
.p-29 {
  padding: 29px !important; }

.padding-top-29,
.pt-29 {
  padding-top: 29px !important; }

.padding-right-29,
.pr-29 {
  padding-right: 29px !important; }

.padding-bottom-29,
.pb-29 {
  padding-bottom: 29px !important; }

.padding-left-29,
.pl-29 {
  padding-left: 29px !important; }

.padding-horizontal-29,
.ph-29 {
  padding-left: 29px;
  padding-right: 29px; }

.padding-vertical-29,
.pv-29 {
  padding-top: 29px;
  padding-bottom: 29px; }

.padding-30,
.p-30 {
  padding: 30px !important; }

.padding-top-30,
.pt-30 {
  padding-top: 30px !important; }

.padding-right-30,
.pr-30 {
  padding-right: 30px !important; }

.padding-bottom-30,
.pb-30 {
  padding-bottom: 30px !important; }

.padding-left-30,
.pl-30 {
  padding-left: 30px !important; }

.padding-horizontal-30,
.ph-30 {
  padding-left: 30px;
  padding-right: 30px; }

.padding-vertical-30,
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.padding-31,
.p-31 {
  padding: 31px !important; }

.padding-top-31,
.pt-31 {
  padding-top: 31px !important; }

.padding-right-31,
.pr-31 {
  padding-right: 31px !important; }

.padding-bottom-31,
.pb-31 {
  padding-bottom: 31px !important; }

.padding-left-31,
.pl-31 {
  padding-left: 31px !important; }

.padding-horizontal-31,
.ph-31 {
  padding-left: 31px;
  padding-right: 31px; }

.padding-vertical-31,
.pv-31 {
  padding-top: 31px;
  padding-bottom: 31px; }

.padding-32,
.p-32 {
  padding: 32px !important; }

.padding-top-32,
.pt-32 {
  padding-top: 32px !important; }

.padding-right-32,
.pr-32 {
  padding-right: 32px !important; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px !important; }

.padding-left-32,
.pl-32 {
  padding-left: 32px !important; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-33,
.p-33 {
  padding: 33px !important; }

.padding-top-33,
.pt-33 {
  padding-top: 33px !important; }

.padding-right-33,
.pr-33 {
  padding-right: 33px !important; }

.padding-bottom-33,
.pb-33 {
  padding-bottom: 33px !important; }

.padding-left-33,
.pl-33 {
  padding-left: 33px !important; }

.padding-horizontal-33,
.ph-33 {
  padding-left: 33px;
  padding-right: 33px; }

.padding-vertical-33,
.pv-33 {
  padding-top: 33px;
  padding-bottom: 33px; }

.padding-34,
.p-34 {
  padding: 34px !important; }

.padding-top-34,
.pt-34 {
  padding-top: 34px !important; }

.padding-right-34,
.pr-34 {
  padding-right: 34px !important; }

.padding-bottom-34,
.pb-34 {
  padding-bottom: 34px !important; }

.padding-left-34,
.pl-34 {
  padding-left: 34px !important; }

.padding-horizontal-34,
.ph-34 {
  padding-left: 34px;
  padding-right: 34px; }

.padding-vertical-34,
.pv-34 {
  padding-top: 34px;
  padding-bottom: 34px; }

.padding-35,
.p-35 {
  padding: 35px !important; }

.padding-top-35,
.pt-35 {
  padding-top: 35px !important; }

.padding-right-35,
.pr-35 {
  padding-right: 35px !important; }

.padding-bottom-35,
.pb-35 {
  padding-bottom: 35px !important; }

.padding-left-35,
.pl-35 {
  padding-left: 35px !important; }

.padding-horizontal-35,
.ph-35 {
  padding-left: 35px;
  padding-right: 35px; }

.padding-vertical-35,
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.padding-36,
.p-36 {
  padding: 36px !important; }

.padding-top-36,
.pt-36 {
  padding-top: 36px !important; }

.padding-right-36,
.pr-36 {
  padding-right: 36px !important; }

.padding-bottom-36,
.pb-36 {
  padding-bottom: 36px !important; }

.padding-left-36,
.pl-36 {
  padding-left: 36px !important; }

.padding-horizontal-36,
.ph-36 {
  padding-left: 36px;
  padding-right: 36px; }

.padding-vertical-36,
.pv-36 {
  padding-top: 36px;
  padding-bottom: 36px; }

.padding-37,
.p-37 {
  padding: 37px !important; }

.padding-top-37,
.pt-37 {
  padding-top: 37px !important; }

.padding-right-37,
.pr-37 {
  padding-right: 37px !important; }

.padding-bottom-37,
.pb-37 {
  padding-bottom: 37px !important; }

.padding-left-37,
.pl-37 {
  padding-left: 37px !important; }

.padding-horizontal-37,
.ph-37 {
  padding-left: 37px;
  padding-right: 37px; }

.padding-vertical-37,
.pv-37 {
  padding-top: 37px;
  padding-bottom: 37px; }

.padding-38,
.p-38 {
  padding: 38px !important; }

.padding-top-38,
.pt-38 {
  padding-top: 38px !important; }

.padding-right-38,
.pr-38 {
  padding-right: 38px !important; }

.padding-bottom-38,
.pb-38 {
  padding-bottom: 38px !important; }

.padding-left-38,
.pl-38 {
  padding-left: 38px !important; }

.padding-horizontal-38,
.ph-38 {
  padding-left: 38px;
  padding-right: 38px; }

.padding-vertical-38,
.pv-38 {
  padding-top: 38px;
  padding-bottom: 38px; }

.padding-39,
.p-39 {
  padding: 39px !important; }

.padding-top-39,
.pt-39 {
  padding-top: 39px !important; }

.padding-right-39,
.pr-39 {
  padding-right: 39px !important; }

.padding-bottom-39,
.pb-39 {
  padding-bottom: 39px !important; }

.padding-left-39,
.pl-39 {
  padding-left: 39px !important; }

.padding-horizontal-39,
.ph-39 {
  padding-left: 39px;
  padding-right: 39px; }

.padding-vertical-39,
.pv-39 {
  padding-top: 39px;
  padding-bottom: 39px; }

.padding-40,
.p-40 {
  padding: 40px !important; }

.padding-top-40,
.pt-40 {
  padding-top: 40px !important; }

.padding-right-40,
.pr-40 {
  padding-right: 40px !important; }

.padding-bottom-40,
.pb-40 {
  padding-bottom: 40px !important; }

.padding-left-40,
.pl-40 {
  padding-left: 40px !important; }

.padding-horizontal-40,
.ph-40 {
  padding-left: 40px;
  padding-right: 40px; }

.padding-vertical-40,
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.padding-41,
.p-41 {
  padding: 41px !important; }

.padding-top-41,
.pt-41 {
  padding-top: 41px !important; }

.padding-right-41,
.pr-41 {
  padding-right: 41px !important; }

.padding-bottom-41,
.pb-41 {
  padding-bottom: 41px !important; }

.padding-left-41,
.pl-41 {
  padding-left: 41px !important; }

.padding-horizontal-41,
.ph-41 {
  padding-left: 41px;
  padding-right: 41px; }

.padding-vertical-41,
.pv-41 {
  padding-top: 41px;
  padding-bottom: 41px; }

.padding-42,
.p-42 {
  padding: 42px !important; }

.padding-top-42,
.pt-42 {
  padding-top: 42px !important; }

.padding-right-42,
.pr-42 {
  padding-right: 42px !important; }

.padding-bottom-42,
.pb-42 {
  padding-bottom: 42px !important; }

.padding-left-42,
.pl-42 {
  padding-left: 42px !important; }

.padding-horizontal-42,
.ph-42 {
  padding-left: 42px;
  padding-right: 42px; }

.padding-vertical-42,
.pv-42 {
  padding-top: 42px;
  padding-bottom: 42px; }

.padding-43,
.p-43 {
  padding: 43px !important; }

.padding-top-43,
.pt-43 {
  padding-top: 43px !important; }

.padding-right-43,
.pr-43 {
  padding-right: 43px !important; }

.padding-bottom-43,
.pb-43 {
  padding-bottom: 43px !important; }

.padding-left-43,
.pl-43 {
  padding-left: 43px !important; }

.padding-horizontal-43,
.ph-43 {
  padding-left: 43px;
  padding-right: 43px; }

.padding-vertical-43,
.pv-43 {
  padding-top: 43px;
  padding-bottom: 43px; }

.padding-44,
.p-44 {
  padding: 44px !important; }

.padding-top-44,
.pt-44 {
  padding-top: 44px !important; }

.padding-right-44,
.pr-44 {
  padding-right: 44px !important; }

.padding-bottom-44,
.pb-44 {
  padding-bottom: 44px !important; }

.padding-left-44,
.pl-44 {
  padding-left: 44px !important; }

.padding-horizontal-44,
.ph-44 {
  padding-left: 44px;
  padding-right: 44px; }

.padding-vertical-44,
.pv-44 {
  padding-top: 44px;
  padding-bottom: 44px; }

.padding-45,
.p-45 {
  padding: 45px !important; }

.padding-top-45,
.pt-45 {
  padding-top: 45px !important; }

.padding-right-45,
.pr-45 {
  padding-right: 45px !important; }

.padding-bottom-45,
.pb-45 {
  padding-bottom: 45px !important; }

.padding-left-45,
.pl-45 {
  padding-left: 45px !important; }

.padding-horizontal-45,
.ph-45 {
  padding-left: 45px;
  padding-right: 45px; }

.padding-vertical-45,
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.padding-46,
.p-46 {
  padding: 46px !important; }

.padding-top-46,
.pt-46 {
  padding-top: 46px !important; }

.padding-right-46,
.pr-46 {
  padding-right: 46px !important; }

.padding-bottom-46,
.pb-46 {
  padding-bottom: 46px !important; }

.padding-left-46,
.pl-46 {
  padding-left: 46px !important; }

.padding-horizontal-46,
.ph-46 {
  padding-left: 46px;
  padding-right: 46px; }

.padding-vertical-46,
.pv-46 {
  padding-top: 46px;
  padding-bottom: 46px; }

.padding-47,
.p-47 {
  padding: 47px !important; }

.padding-top-47,
.pt-47 {
  padding-top: 47px !important; }

.padding-right-47,
.pr-47 {
  padding-right: 47px !important; }

.padding-bottom-47,
.pb-47 {
  padding-bottom: 47px !important; }

.padding-left-47,
.pl-47 {
  padding-left: 47px !important; }

.padding-horizontal-47,
.ph-47 {
  padding-left: 47px;
  padding-right: 47px; }

.padding-vertical-47,
.pv-47 {
  padding-top: 47px;
  padding-bottom: 47px; }

.padding-48,
.p-48 {
  padding: 48px !important; }

.padding-top-48,
.pt-48 {
  padding-top: 48px !important; }

.padding-right-48,
.pr-48 {
  padding-right: 48px !important; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px !important; }

.padding-left-48,
.pl-48 {
  padding-left: 48px !important; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-49,
.p-49 {
  padding: 49px !important; }

.padding-top-49,
.pt-49 {
  padding-top: 49px !important; }

.padding-right-49,
.pr-49 {
  padding-right: 49px !important; }

.padding-bottom-49,
.pb-49 {
  padding-bottom: 49px !important; }

.padding-left-49,
.pl-49 {
  padding-left: 49px !important; }

.padding-horizontal-49,
.ph-49 {
  padding-left: 49px;
  padding-right: 49px; }

.padding-vertical-49,
.pv-49 {
  padding-top: 49px;
  padding-bottom: 49px; }

.padding-50,
.p-50 {
  padding: 50px !important; }

.padding-top-50,
.pt-50 {
  padding-top: 50px !important; }

.padding-right-50,
.pr-50 {
  padding-right: 50px !important; }

.padding-bottom-50,
.pb-50 {
  padding-bottom: 50px !important; }

.padding-left-50,
.pl-50 {
  padding-left: 50px !important; }

.padding-horizontal-50,
.ph-50 {
  padding-left: 50px;
  padding-right: 50px; }

.padding-vertical-50,
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.padding-51,
.p-51 {
  padding: 51px !important; }

.padding-top-51,
.pt-51 {
  padding-top: 51px !important; }

.padding-right-51,
.pr-51 {
  padding-right: 51px !important; }

.padding-bottom-51,
.pb-51 {
  padding-bottom: 51px !important; }

.padding-left-51,
.pl-51 {
  padding-left: 51px !important; }

.padding-horizontal-51,
.ph-51 {
  padding-left: 51px;
  padding-right: 51px; }

.padding-vertical-51,
.pv-51 {
  padding-top: 51px;
  padding-bottom: 51px; }

.padding-52,
.p-52 {
  padding: 52px !important; }

.padding-top-52,
.pt-52 {
  padding-top: 52px !important; }

.padding-right-52,
.pr-52 {
  padding-right: 52px !important; }

.padding-bottom-52,
.pb-52 {
  padding-bottom: 52px !important; }

.padding-left-52,
.pl-52 {
  padding-left: 52px !important; }

.padding-horizontal-52,
.ph-52 {
  padding-left: 52px;
  padding-right: 52px; }

.padding-vertical-52,
.pv-52 {
  padding-top: 52px;
  padding-bottom: 52px; }

.padding-53,
.p-53 {
  padding: 53px !important; }

.padding-top-53,
.pt-53 {
  padding-top: 53px !important; }

.padding-right-53,
.pr-53 {
  padding-right: 53px !important; }

.padding-bottom-53,
.pb-53 {
  padding-bottom: 53px !important; }

.padding-left-53,
.pl-53 {
  padding-left: 53px !important; }

.padding-horizontal-53,
.ph-53 {
  padding-left: 53px;
  padding-right: 53px; }

.padding-vertical-53,
.pv-53 {
  padding-top: 53px;
  padding-bottom: 53px; }

.padding-54,
.p-54 {
  padding: 54px !important; }

.padding-top-54,
.pt-54 {
  padding-top: 54px !important; }

.padding-right-54,
.pr-54 {
  padding-right: 54px !important; }

.padding-bottom-54,
.pb-54 {
  padding-bottom: 54px !important; }

.padding-left-54,
.pl-54 {
  padding-left: 54px !important; }

.padding-horizontal-54,
.ph-54 {
  padding-left: 54px;
  padding-right: 54px; }

.padding-vertical-54,
.pv-54 {
  padding-top: 54px;
  padding-bottom: 54px; }

.padding-55,
.p-55 {
  padding: 55px !important; }

.padding-top-55,
.pt-55 {
  padding-top: 55px !important; }

.padding-right-55,
.pr-55 {
  padding-right: 55px !important; }

.padding-bottom-55,
.pb-55 {
  padding-bottom: 55px !important; }

.padding-left-55,
.pl-55 {
  padding-left: 55px !important; }

.padding-horizontal-55,
.ph-55 {
  padding-left: 55px;
  padding-right: 55px; }

.padding-vertical-55,
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.padding-56,
.p-56 {
  padding: 56px !important; }

.padding-top-56,
.pt-56 {
  padding-top: 56px !important; }

.padding-right-56,
.pr-56 {
  padding-right: 56px !important; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px !important; }

.padding-left-56,
.pl-56 {
  padding-left: 56px !important; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-57,
.p-57 {
  padding: 57px !important; }

.padding-top-57,
.pt-57 {
  padding-top: 57px !important; }

.padding-right-57,
.pr-57 {
  padding-right: 57px !important; }

.padding-bottom-57,
.pb-57 {
  padding-bottom: 57px !important; }

.padding-left-57,
.pl-57 {
  padding-left: 57px !important; }

.padding-horizontal-57,
.ph-57 {
  padding-left: 57px;
  padding-right: 57px; }

.padding-vertical-57,
.pv-57 {
  padding-top: 57px;
  padding-bottom: 57px; }

.padding-58,
.p-58 {
  padding: 58px !important; }

.padding-top-58,
.pt-58 {
  padding-top: 58px !important; }

.padding-right-58,
.pr-58 {
  padding-right: 58px !important; }

.padding-bottom-58,
.pb-58 {
  padding-bottom: 58px !important; }

.padding-left-58,
.pl-58 {
  padding-left: 58px !important; }

.padding-horizontal-58,
.ph-58 {
  padding-left: 58px;
  padding-right: 58px; }

.padding-vertical-58,
.pv-58 {
  padding-top: 58px;
  padding-bottom: 58px; }

.padding-59,
.p-59 {
  padding: 59px !important; }

.padding-top-59,
.pt-59 {
  padding-top: 59px !important; }

.padding-right-59,
.pr-59 {
  padding-right: 59px !important; }

.padding-bottom-59,
.pb-59 {
  padding-bottom: 59px !important; }

.padding-left-59,
.pl-59 {
  padding-left: 59px !important; }

.padding-horizontal-59,
.ph-59 {
  padding-left: 59px;
  padding-right: 59px; }

.padding-vertical-59,
.pv-59 {
  padding-top: 59px;
  padding-bottom: 59px; }

.padding-60,
.p-60 {
  padding: 60px !important; }

.padding-top-60,
.pt-60 {
  padding-top: 60px !important; }

.padding-right-60,
.pr-60 {
  padding-right: 60px !important; }

.padding-bottom-60,
.pb-60 {
  padding-bottom: 60px !important; }

.padding-left-60,
.pl-60 {
  padding-left: 60px !important; }

.padding-horizontal-60,
.ph-60 {
  padding-left: 60px;
  padding-right: 60px; }

.padding-vertical-60,
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.padding-61,
.p-61 {
  padding: 61px !important; }

.padding-top-61,
.pt-61 {
  padding-top: 61px !important; }

.padding-right-61,
.pr-61 {
  padding-right: 61px !important; }

.padding-bottom-61,
.pb-61 {
  padding-bottom: 61px !important; }

.padding-left-61,
.pl-61 {
  padding-left: 61px !important; }

.padding-horizontal-61,
.ph-61 {
  padding-left: 61px;
  padding-right: 61px; }

.padding-vertical-61,
.pv-61 {
  padding-top: 61px;
  padding-bottom: 61px; }

.padding-62,
.p-62 {
  padding: 62px !important; }

.padding-top-62,
.pt-62 {
  padding-top: 62px !important; }

.padding-right-62,
.pr-62 {
  padding-right: 62px !important; }

.padding-bottom-62,
.pb-62 {
  padding-bottom: 62px !important; }

.padding-left-62,
.pl-62 {
  padding-left: 62px !important; }

.padding-horizontal-62,
.ph-62 {
  padding-left: 62px;
  padding-right: 62px; }

.padding-vertical-62,
.pv-62 {
  padding-top: 62px;
  padding-bottom: 62px; }

.padding-63,
.p-63 {
  padding: 63px !important; }

.padding-top-63,
.pt-63 {
  padding-top: 63px !important; }

.padding-right-63,
.pr-63 {
  padding-right: 63px !important; }

.padding-bottom-63,
.pb-63 {
  padding-bottom: 63px !important; }

.padding-left-63,
.pl-63 {
  padding-left: 63px !important; }

.padding-horizontal-63,
.ph-63 {
  padding-left: 63px;
  padding-right: 63px; }

.padding-vertical-63,
.pv-63 {
  padding-top: 63px;
  padding-bottom: 63px; }

.padding-64,
.p-64 {
  padding: 64px !important; }

.padding-top-64,
.pt-64 {
  padding-top: 64px !important; }

.padding-right-64,
.pr-64 {
  padding-right: 64px !important; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px !important; }

.padding-left-64,
.pl-64 {
  padding-left: 64px !important; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-65,
.p-65 {
  padding: 65px !important; }

.padding-top-65,
.pt-65 {
  padding-top: 65px !important; }

.padding-right-65,
.pr-65 {
  padding-right: 65px !important; }

.padding-bottom-65,
.pb-65 {
  padding-bottom: 65px !important; }

.padding-left-65,
.pl-65 {
  padding-left: 65px !important; }

.padding-horizontal-65,
.ph-65 {
  padding-left: 65px;
  padding-right: 65px; }

.padding-vertical-65,
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.padding-66,
.p-66 {
  padding: 66px !important; }

.padding-top-66,
.pt-66 {
  padding-top: 66px !important; }

.padding-right-66,
.pr-66 {
  padding-right: 66px !important; }

.padding-bottom-66,
.pb-66 {
  padding-bottom: 66px !important; }

.padding-left-66,
.pl-66 {
  padding-left: 66px !important; }

.padding-horizontal-66,
.ph-66 {
  padding-left: 66px;
  padding-right: 66px; }

.padding-vertical-66,
.pv-66 {
  padding-top: 66px;
  padding-bottom: 66px; }

.padding-67,
.p-67 {
  padding: 67px !important; }

.padding-top-67,
.pt-67 {
  padding-top: 67px !important; }

.padding-right-67,
.pr-67 {
  padding-right: 67px !important; }

.padding-bottom-67,
.pb-67 {
  padding-bottom: 67px !important; }

.padding-left-67,
.pl-67 {
  padding-left: 67px !important; }

.padding-horizontal-67,
.ph-67 {
  padding-left: 67px;
  padding-right: 67px; }

.padding-vertical-67,
.pv-67 {
  padding-top: 67px;
  padding-bottom: 67px; }

.padding-68,
.p-68 {
  padding: 68px !important; }

.padding-top-68,
.pt-68 {
  padding-top: 68px !important; }

.padding-right-68,
.pr-68 {
  padding-right: 68px !important; }

.padding-bottom-68,
.pb-68 {
  padding-bottom: 68px !important; }

.padding-left-68,
.pl-68 {
  padding-left: 68px !important; }

.padding-horizontal-68,
.ph-68 {
  padding-left: 68px;
  padding-right: 68px; }

.padding-vertical-68,
.pv-68 {
  padding-top: 68px;
  padding-bottom: 68px; }

.padding-69,
.p-69 {
  padding: 69px !important; }

.padding-top-69,
.pt-69 {
  padding-top: 69px !important; }

.padding-right-69,
.pr-69 {
  padding-right: 69px !important; }

.padding-bottom-69,
.pb-69 {
  padding-bottom: 69px !important; }

.padding-left-69,
.pl-69 {
  padding-left: 69px !important; }

.padding-horizontal-69,
.ph-69 {
  padding-left: 69px;
  padding-right: 69px; }

.padding-vertical-69,
.pv-69 {
  padding-top: 69px;
  padding-bottom: 69px; }

.padding-70,
.p-70 {
  padding: 70px !important; }

.padding-top-70,
.pt-70 {
  padding-top: 70px !important; }

.padding-right-70,
.pr-70 {
  padding-right: 70px !important; }

.padding-bottom-70,
.pb-70 {
  padding-bottom: 70px !important; }

.padding-left-70,
.pl-70 {
  padding-left: 70px !important; }

.padding-horizontal-70,
.ph-70 {
  padding-left: 70px;
  padding-right: 70px; }

.padding-vertical-70,
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.padding-71,
.p-71 {
  padding: 71px !important; }

.padding-top-71,
.pt-71 {
  padding-top: 71px !important; }

.padding-right-71,
.pr-71 {
  padding-right: 71px !important; }

.padding-bottom-71,
.pb-71 {
  padding-bottom: 71px !important; }

.padding-left-71,
.pl-71 {
  padding-left: 71px !important; }

.padding-horizontal-71,
.ph-71 {
  padding-left: 71px;
  padding-right: 71px; }

.padding-vertical-71,
.pv-71 {
  padding-top: 71px;
  padding-bottom: 71px; }

.padding-72,
.p-72 {
  padding: 72px !important; }

.padding-top-72,
.pt-72 {
  padding-top: 72px !important; }

.padding-right-72,
.pr-72 {
  padding-right: 72px !important; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px !important; }

.padding-left-72,
.pl-72 {
  padding-left: 72px !important; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-73,
.p-73 {
  padding: 73px !important; }

.padding-top-73,
.pt-73 {
  padding-top: 73px !important; }

.padding-right-73,
.pr-73 {
  padding-right: 73px !important; }

.padding-bottom-73,
.pb-73 {
  padding-bottom: 73px !important; }

.padding-left-73,
.pl-73 {
  padding-left: 73px !important; }

.padding-horizontal-73,
.ph-73 {
  padding-left: 73px;
  padding-right: 73px; }

.padding-vertical-73,
.pv-73 {
  padding-top: 73px;
  padding-bottom: 73px; }

.padding-74,
.p-74 {
  padding: 74px !important; }

.padding-top-74,
.pt-74 {
  padding-top: 74px !important; }

.padding-right-74,
.pr-74 {
  padding-right: 74px !important; }

.padding-bottom-74,
.pb-74 {
  padding-bottom: 74px !important; }

.padding-left-74,
.pl-74 {
  padding-left: 74px !important; }

.padding-horizontal-74,
.ph-74 {
  padding-left: 74px;
  padding-right: 74px; }

.padding-vertical-74,
.pv-74 {
  padding-top: 74px;
  padding-bottom: 74px; }

.padding-75,
.p-75 {
  padding: 75px !important; }

.padding-top-75,
.pt-75 {
  padding-top: 75px !important; }

.padding-right-75,
.pr-75 {
  padding-right: 75px !important; }

.padding-bottom-75,
.pb-75 {
  padding-bottom: 75px !important; }

.padding-left-75,
.pl-75 {
  padding-left: 75px !important; }

.padding-horizontal-75,
.ph-75 {
  padding-left: 75px;
  padding-right: 75px; }

.padding-vertical-75,
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.padding-76,
.p-76 {
  padding: 76px !important; }

.padding-top-76,
.pt-76 {
  padding-top: 76px !important; }

.padding-right-76,
.pr-76 {
  padding-right: 76px !important; }

.padding-bottom-76,
.pb-76 {
  padding-bottom: 76px !important; }

.padding-left-76,
.pl-76 {
  padding-left: 76px !important; }

.padding-horizontal-76,
.ph-76 {
  padding-left: 76px;
  padding-right: 76px; }

.padding-vertical-76,
.pv-76 {
  padding-top: 76px;
  padding-bottom: 76px; }

.padding-77,
.p-77 {
  padding: 77px !important; }

.padding-top-77,
.pt-77 {
  padding-top: 77px !important; }

.padding-right-77,
.pr-77 {
  padding-right: 77px !important; }

.padding-bottom-77,
.pb-77 {
  padding-bottom: 77px !important; }

.padding-left-77,
.pl-77 {
  padding-left: 77px !important; }

.padding-horizontal-77,
.ph-77 {
  padding-left: 77px;
  padding-right: 77px; }

.padding-vertical-77,
.pv-77 {
  padding-top: 77px;
  padding-bottom: 77px; }

.padding-78,
.p-78 {
  padding: 78px !important; }

.padding-top-78,
.pt-78 {
  padding-top: 78px !important; }

.padding-right-78,
.pr-78 {
  padding-right: 78px !important; }

.padding-bottom-78,
.pb-78 {
  padding-bottom: 78px !important; }

.padding-left-78,
.pl-78 {
  padding-left: 78px !important; }

.padding-horizontal-78,
.ph-78 {
  padding-left: 78px;
  padding-right: 78px; }

.padding-vertical-78,
.pv-78 {
  padding-top: 78px;
  padding-bottom: 78px; }

.padding-79,
.p-79 {
  padding: 79px !important; }

.padding-top-79,
.pt-79 {
  padding-top: 79px !important; }

.padding-right-79,
.pr-79 {
  padding-right: 79px !important; }

.padding-bottom-79,
.pb-79 {
  padding-bottom: 79px !important; }

.padding-left-79,
.pl-79 {
  padding-left: 79px !important; }

.padding-horizontal-79,
.ph-79 {
  padding-left: 79px;
  padding-right: 79px; }

.padding-vertical-79,
.pv-79 {
  padding-top: 79px;
  padding-bottom: 79px; }

.padding-80,
.p-80 {
  padding: 80px !important; }

.padding-top-80,
.pt-80 {
  padding-top: 80px !important; }

.padding-right-80,
.pr-80 {
  padding-right: 80px !important; }

.padding-bottom-80,
.pb-80 {
  padding-bottom: 80px !important; }

.padding-left-80,
.pl-80 {
  padding-left: 80px !important; }

.padding-horizontal-80,
.ph-80 {
  padding-left: 80px;
  padding-right: 80px; }

.padding-vertical-80,
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.padding-81,
.p-81 {
  padding: 81px !important; }

.padding-top-81,
.pt-81 {
  padding-top: 81px !important; }

.padding-right-81,
.pr-81 {
  padding-right: 81px !important; }

.padding-bottom-81,
.pb-81 {
  padding-bottom: 81px !important; }

.padding-left-81,
.pl-81 {
  padding-left: 81px !important; }

.padding-horizontal-81,
.ph-81 {
  padding-left: 81px;
  padding-right: 81px; }

.padding-vertical-81,
.pv-81 {
  padding-top: 81px;
  padding-bottom: 81px; }

.padding-82,
.p-82 {
  padding: 82px !important; }

.padding-top-82,
.pt-82 {
  padding-top: 82px !important; }

.padding-right-82,
.pr-82 {
  padding-right: 82px !important; }

.padding-bottom-82,
.pb-82 {
  padding-bottom: 82px !important; }

.padding-left-82,
.pl-82 {
  padding-left: 82px !important; }

.padding-horizontal-82,
.ph-82 {
  padding-left: 82px;
  padding-right: 82px; }

.padding-vertical-82,
.pv-82 {
  padding-top: 82px;
  padding-bottom: 82px; }

.padding-83,
.p-83 {
  padding: 83px !important; }

.padding-top-83,
.pt-83 {
  padding-top: 83px !important; }

.padding-right-83,
.pr-83 {
  padding-right: 83px !important; }

.padding-bottom-83,
.pb-83 {
  padding-bottom: 83px !important; }

.padding-left-83,
.pl-83 {
  padding-left: 83px !important; }

.padding-horizontal-83,
.ph-83 {
  padding-left: 83px;
  padding-right: 83px; }

.padding-vertical-83,
.pv-83 {
  padding-top: 83px;
  padding-bottom: 83px; }

.padding-84,
.p-84 {
  padding: 84px !important; }

.padding-top-84,
.pt-84 {
  padding-top: 84px !important; }

.padding-right-84,
.pr-84 {
  padding-right: 84px !important; }

.padding-bottom-84,
.pb-84 {
  padding-bottom: 84px !important; }

.padding-left-84,
.pl-84 {
  padding-left: 84px !important; }

.padding-horizontal-84,
.ph-84 {
  padding-left: 84px;
  padding-right: 84px; }

.padding-vertical-84,
.pv-84 {
  padding-top: 84px;
  padding-bottom: 84px; }

.padding-85,
.p-85 {
  padding: 85px !important; }

.padding-top-85,
.pt-85 {
  padding-top: 85px !important; }

.padding-right-85,
.pr-85 {
  padding-right: 85px !important; }

.padding-bottom-85,
.pb-85 {
  padding-bottom: 85px !important; }

.padding-left-85,
.pl-85 {
  padding-left: 85px !important; }

.padding-horizontal-85,
.ph-85 {
  padding-left: 85px;
  padding-right: 85px; }

.padding-vertical-85,
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.padding-86,
.p-86 {
  padding: 86px !important; }

.padding-top-86,
.pt-86 {
  padding-top: 86px !important; }

.padding-right-86,
.pr-86 {
  padding-right: 86px !important; }

.padding-bottom-86,
.pb-86 {
  padding-bottom: 86px !important; }

.padding-left-86,
.pl-86 {
  padding-left: 86px !important; }

.padding-horizontal-86,
.ph-86 {
  padding-left: 86px;
  padding-right: 86px; }

.padding-vertical-86,
.pv-86 {
  padding-top: 86px;
  padding-bottom: 86px; }

.padding-87,
.p-87 {
  padding: 87px !important; }

.padding-top-87,
.pt-87 {
  padding-top: 87px !important; }

.padding-right-87,
.pr-87 {
  padding-right: 87px !important; }

.padding-bottom-87,
.pb-87 {
  padding-bottom: 87px !important; }

.padding-left-87,
.pl-87 {
  padding-left: 87px !important; }

.padding-horizontal-87,
.ph-87 {
  padding-left: 87px;
  padding-right: 87px; }

.padding-vertical-87,
.pv-87 {
  padding-top: 87px;
  padding-bottom: 87px; }

.padding-88,
.p-88 {
  padding: 88px !important; }

.padding-top-88,
.pt-88 {
  padding-top: 88px !important; }

.padding-right-88,
.pr-88 {
  padding-right: 88px !important; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px !important; }

.padding-left-88,
.pl-88 {
  padding-left: 88px !important; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-89,
.p-89 {
  padding: 89px !important; }

.padding-top-89,
.pt-89 {
  padding-top: 89px !important; }

.padding-right-89,
.pr-89 {
  padding-right: 89px !important; }

.padding-bottom-89,
.pb-89 {
  padding-bottom: 89px !important; }

.padding-left-89,
.pl-89 {
  padding-left: 89px !important; }

.padding-horizontal-89,
.ph-89 {
  padding-left: 89px;
  padding-right: 89px; }

.padding-vertical-89,
.pv-89 {
  padding-top: 89px;
  padding-bottom: 89px; }

.padding-90,
.p-90 {
  padding: 90px !important; }

.padding-top-90,
.pt-90 {
  padding-top: 90px !important; }

.padding-right-90,
.pr-90 {
  padding-right: 90px !important; }

.padding-bottom-90,
.pb-90 {
  padding-bottom: 90px !important; }

.padding-left-90,
.pl-90 {
  padding-left: 90px !important; }

.padding-horizontal-90,
.ph-90 {
  padding-left: 90px;
  padding-right: 90px; }

.padding-vertical-90,
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.padding-91,
.p-91 {
  padding: 91px !important; }

.padding-top-91,
.pt-91 {
  padding-top: 91px !important; }

.padding-right-91,
.pr-91 {
  padding-right: 91px !important; }

.padding-bottom-91,
.pb-91 {
  padding-bottom: 91px !important; }

.padding-left-91,
.pl-91 {
  padding-left: 91px !important; }

.padding-horizontal-91,
.ph-91 {
  padding-left: 91px;
  padding-right: 91px; }

.padding-vertical-91,
.pv-91 {
  padding-top: 91px;
  padding-bottom: 91px; }

.padding-92,
.p-92 {
  padding: 92px !important; }

.padding-top-92,
.pt-92 {
  padding-top: 92px !important; }

.padding-right-92,
.pr-92 {
  padding-right: 92px !important; }

.padding-bottom-92,
.pb-92 {
  padding-bottom: 92px !important; }

.padding-left-92,
.pl-92 {
  padding-left: 92px !important; }

.padding-horizontal-92,
.ph-92 {
  padding-left: 92px;
  padding-right: 92px; }

.padding-vertical-92,
.pv-92 {
  padding-top: 92px;
  padding-bottom: 92px; }

.padding-93,
.p-93 {
  padding: 93px !important; }

.padding-top-93,
.pt-93 {
  padding-top: 93px !important; }

.padding-right-93,
.pr-93 {
  padding-right: 93px !important; }

.padding-bottom-93,
.pb-93 {
  padding-bottom: 93px !important; }

.padding-left-93,
.pl-93 {
  padding-left: 93px !important; }

.padding-horizontal-93,
.ph-93 {
  padding-left: 93px;
  padding-right: 93px; }

.padding-vertical-93,
.pv-93 {
  padding-top: 93px;
  padding-bottom: 93px; }

.padding-94,
.p-94 {
  padding: 94px !important; }

.padding-top-94,
.pt-94 {
  padding-top: 94px !important; }

.padding-right-94,
.pr-94 {
  padding-right: 94px !important; }

.padding-bottom-94,
.pb-94 {
  padding-bottom: 94px !important; }

.padding-left-94,
.pl-94 {
  padding-left: 94px !important; }

.padding-horizontal-94,
.ph-94 {
  padding-left: 94px;
  padding-right: 94px; }

.padding-vertical-94,
.pv-94 {
  padding-top: 94px;
  padding-bottom: 94px; }

.padding-95,
.p-95 {
  padding: 95px !important; }

.padding-top-95,
.pt-95 {
  padding-top: 95px !important; }

.padding-right-95,
.pr-95 {
  padding-right: 95px !important; }

.padding-bottom-95,
.pb-95 {
  padding-bottom: 95px !important; }

.padding-left-95,
.pl-95 {
  padding-left: 95px !important; }

.padding-horizontal-95,
.ph-95 {
  padding-left: 95px;
  padding-right: 95px; }

.padding-vertical-95,
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.padding-96,
.p-96 {
  padding: 96px !important; }

.padding-top-96,
.pt-96 {
  padding-top: 96px !important; }

.padding-right-96,
.pr-96 {
  padding-right: 96px !important; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px !important; }

.padding-left-96,
.pl-96 {
  padding-left: 96px !important; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.padding-97,
.p-97 {
  padding: 97px !important; }

.padding-top-97,
.pt-97 {
  padding-top: 97px !important; }

.padding-right-97,
.pr-97 {
  padding-right: 97px !important; }

.padding-bottom-97,
.pb-97 {
  padding-bottom: 97px !important; }

.padding-left-97,
.pl-97 {
  padding-left: 97px !important; }

.padding-horizontal-97,
.ph-97 {
  padding-left: 97px;
  padding-right: 97px; }

.padding-vertical-97,
.pv-97 {
  padding-top: 97px;
  padding-bottom: 97px; }

.padding-98,
.p-98 {
  padding: 98px !important; }

.padding-top-98,
.pt-98 {
  padding-top: 98px !important; }

.padding-right-98,
.pr-98 {
  padding-right: 98px !important; }

.padding-bottom-98,
.pb-98 {
  padding-bottom: 98px !important; }

.padding-left-98,
.pl-98 {
  padding-left: 98px !important; }

.padding-horizontal-98,
.ph-98 {
  padding-left: 98px;
  padding-right: 98px; }

.padding-vertical-98,
.pv-98 {
  padding-top: 98px;
  padding-bottom: 98px; }

.padding-99,
.p-99 {
  padding: 99px !important; }

.padding-top-99,
.pt-99 {
  padding-top: 99px !important; }

.padding-right-99,
.pr-99 {
  padding-right: 99px !important; }

.padding-bottom-99,
.pb-99 {
  padding-bottom: 99px !important; }

.padding-left-99,
.pl-99 {
  padding-left: 99px !important; }

.padding-horizontal-99,
.ph-99 {
  padding-left: 99px;
  padding-right: 99px; }

.padding-vertical-99,
.pv-99 {
  padding-top: 99px;
  padding-bottom: 99px; }

.padding-100,
.p-100 {
  padding: 100px !important; }

.padding-top-100,
.pt-100 {
  padding-top: 100px !important; }

.padding-right-100,
.pr-100 {
  padding-right: 100px !important; }

.padding-bottom-100,
.pb-100 {
  padding-bottom: 100px !important; }

.padding-left-100,
.pl-100 {
  padding-left: 100px !important; }

.padding-horizontal-100,
.ph-100 {
  padding-left: 100px;
  padding-right: 100px; }

.padding-vertical-100,
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.padding-8,
.p-8 {
  padding: 8px !important; }

.padding-top-8,
.pt-8 {
  padding-top: 8px !important; }

.padding-right-8,
.pr-8 {
  padding-right: 8px !important; }

.padding-bottom-8,
.pb-8 {
  padding-bottom: 8px !important; }

.padding-left-8,
.pl-8 {
  padding-left: 8px !important; }

.padding-horizontal-8,
.ph-8 {
  padding-left: 8px;
  padding-right: 8px; }

.padding-vertical-8,
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.padding-16,
.p-16 {
  padding: 16px !important; }

.padding-top-16,
.pt-16 {
  padding-top: 16px !important; }

.padding-right-16,
.pr-16 {
  padding-right: 16px !important; }

.padding-bottom-16,
.pb-16 {
  padding-bottom: 16px !important; }

.padding-left-16,
.pl-16 {
  padding-left: 16px !important; }

.padding-horizontal-16,
.ph-16 {
  padding-left: 16px;
  padding-right: 16px; }

.padding-vertical-16,
.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.padding-24,
.p-24 {
  padding: 24px !important; }

.padding-top-24,
.pt-24 {
  padding-top: 24px !important; }

.padding-right-24,
.pr-24 {
  padding-right: 24px !important; }

.padding-bottom-24,
.pb-24 {
  padding-bottom: 24px !important; }

.padding-left-24,
.pl-24 {
  padding-left: 24px !important; }

.padding-horizontal-24,
.ph-24 {
  padding-left: 24px;
  padding-right: 24px; }

.padding-vertical-24,
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.padding-32,
.p-32 {
  padding: 32px !important; }

.padding-top-32,
.pt-32 {
  padding-top: 32px !important; }

.padding-right-32,
.pr-32 {
  padding-right: 32px !important; }

.padding-bottom-32,
.pb-32 {
  padding-bottom: 32px !important; }

.padding-left-32,
.pl-32 {
  padding-left: 32px !important; }

.padding-horizontal-32,
.ph-32 {
  padding-left: 32px;
  padding-right: 32px; }

.padding-vertical-32,
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.padding-48,
.p-48 {
  padding: 48px !important; }

.padding-top-48,
.pt-48 {
  padding-top: 48px !important; }

.padding-right-48,
.pr-48 {
  padding-right: 48px !important; }

.padding-bottom-48,
.pb-48 {
  padding-bottom: 48px !important; }

.padding-left-48,
.pl-48 {
  padding-left: 48px !important; }

.padding-horizontal-48,
.ph-48 {
  padding-left: 48px;
  padding-right: 48px; }

.padding-vertical-48,
.pv-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.padding-56,
.p-56 {
  padding: 56px !important; }

.padding-top-56,
.pt-56 {
  padding-top: 56px !important; }

.padding-right-56,
.pr-56 {
  padding-right: 56px !important; }

.padding-bottom-56,
.pb-56 {
  padding-bottom: 56px !important; }

.padding-left-56,
.pl-56 {
  padding-left: 56px !important; }

.padding-horizontal-56,
.ph-56 {
  padding-left: 56px;
  padding-right: 56px; }

.padding-vertical-56,
.pv-56 {
  padding-top: 56px;
  padding-bottom: 56px; }

.padding-64,
.p-64 {
  padding: 64px !important; }

.padding-top-64,
.pt-64 {
  padding-top: 64px !important; }

.padding-right-64,
.pr-64 {
  padding-right: 64px !important; }

.padding-bottom-64,
.pb-64 {
  padding-bottom: 64px !important; }

.padding-left-64,
.pl-64 {
  padding-left: 64px !important; }

.padding-horizontal-64,
.ph-64 {
  padding-left: 64px;
  padding-right: 64px; }

.padding-vertical-64,
.pv-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.padding-72,
.p-72 {
  padding: 72px !important; }

.padding-top-72,
.pt-72 {
  padding-top: 72px !important; }

.padding-right-72,
.pr-72 {
  padding-right: 72px !important; }

.padding-bottom-72,
.pb-72 {
  padding-bottom: 72px !important; }

.padding-left-72,
.pl-72 {
  padding-left: 72px !important; }

.padding-horizontal-72,
.ph-72 {
  padding-left: 72px;
  padding-right: 72px; }

.padding-vertical-72,
.pv-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.padding-88,
.p-88 {
  padding: 88px !important; }

.padding-top-88,
.pt-88 {
  padding-top: 88px !important; }

.padding-right-88,
.pr-88 {
  padding-right: 88px !important; }

.padding-bottom-88,
.pb-88 {
  padding-bottom: 88px !important; }

.padding-left-88,
.pl-88 {
  padding-left: 88px !important; }

.padding-horizontal-88,
.ph-88 {
  padding-left: 88px;
  padding-right: 88px; }

.padding-vertical-88,
.pv-88 {
  padding-top: 88px;
  padding-bottom: 88px; }

.padding-96,
.p-96 {
  padding: 96px !important; }

.padding-top-96,
.pt-96 {
  padding-top: 96px !important; }

.padding-right-96,
.pr-96 {
  padding-right: 96px !important; }

.padding-bottom-96,
.pb-96 {
  padding-bottom: 96px !important; }

.padding-left-96,
.pl-96 {
  padding-left: 96px !important; }

.padding-horizontal-96,
.ph-96 {
  padding-left: 96px;
  padding-right: 96px; }

.padding-vertical-96,
.pv-96 {
  padding-top: 96px;
  padding-bottom: 96px; }

.margin-0,
.m-0 {
  margin: 0px !important; }

.margin-top-0,
.mt-0 {
  margin-top: 0px !important; }

.margin-right-0,
.mr-0 {
  margin-right: 0px !important; }

.margin-bottom-0,
.mb-0 {
  margin-bottom: 0px !important; }

.margin-left-0,
.ml-0 {
  margin-left: 0px !important; }

.margin-horizontal-0,
.mh-0 {
  margin-left: 0px;
  margin-right: 0px; }

.margin-vertical-0,
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.margin-5,
.m-5 {
  margin: 5px !important; }

.margin-top-5,
.mt-5 {
  margin-top: 5px !important; }

.margin-right-5,
.mr-5 {
  margin-right: 5px !important; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px !important; }

.margin-left-5,
.ml-5 {
  margin-left: 5px !important; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-10,
.m-10 {
  margin: 10px !important; }

.margin-top-10,
.mt-10 {
  margin-top: 10px !important; }

.margin-right-10,
.mr-10 {
  margin-right: 10px !important; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px !important; }

.margin-left-10,
.ml-10 {
  margin-left: 10px !important; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-15,
.m-15 {
  margin: 15px !important; }

.margin-top-15,
.mt-15 {
  margin-top: 15px !important; }

.margin-right-15,
.mr-15 {
  margin-right: 15px !important; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px !important; }

.margin-left-15,
.ml-15 {
  margin-left: 15px !important; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-20,
.m-20 {
  margin: 20px !important; }

.margin-top-20,
.mt-20 {
  margin-top: 20px !important; }

.margin-right-20,
.mr-20 {
  margin-right: 20px !important; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px !important; }

.margin-left-20,
.ml-20 {
  margin-left: 20px !important; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-25,
.m-25 {
  margin: 25px !important; }

.margin-top-25,
.mt-25 {
  margin-top: 25px !important; }

.margin-right-25,
.mr-25 {
  margin-right: 25px !important; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px !important; }

.margin-left-25,
.ml-25 {
  margin-left: 25px !important; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-30,
.m-30 {
  margin: 30px !important; }

.margin-top-30,
.mt-30 {
  margin-top: 30px !important; }

.margin-right-30,
.mr-30 {
  margin-right: 30px !important; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px !important; }

.margin-left-30,
.ml-30 {
  margin-left: 30px !important; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-35,
.m-35 {
  margin: 35px !important; }

.margin-top-35,
.mt-35 {
  margin-top: 35px !important; }

.margin-right-35,
.mr-35 {
  margin-right: 35px !important; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px !important; }

.margin-left-35,
.ml-35 {
  margin-left: 35px !important; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-40,
.m-40 {
  margin: 40px !important; }

.margin-top-40,
.mt-40 {
  margin-top: 40px !important; }

.margin-right-40,
.mr-40 {
  margin-right: 40px !important; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px !important; }

.margin-left-40,
.ml-40 {
  margin-left: 40px !important; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-45,
.m-45 {
  margin: 45px !important; }

.margin-top-45,
.mt-45 {
  margin-top: 45px !important; }

.margin-right-45,
.mr-45 {
  margin-right: 45px !important; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px !important; }

.margin-left-45,
.ml-45 {
  margin-left: 45px !important; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-50,
.m-50 {
  margin: 50px !important; }

.margin-top-50,
.mt-50 {
  margin-top: 50px !important; }

.margin-right-50,
.mr-50 {
  margin-right: 50px !important; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px !important; }

.margin-left-50,
.ml-50 {
  margin-left: 50px !important; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-55,
.m-55 {
  margin: 55px !important; }

.margin-top-55,
.mt-55 {
  margin-top: 55px !important; }

.margin-right-55,
.mr-55 {
  margin-right: 55px !important; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px !important; }

.margin-left-55,
.ml-55 {
  margin-left: 55px !important; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-60,
.m-60 {
  margin: 60px !important; }

.margin-top-60,
.mt-60 {
  margin-top: 60px !important; }

.margin-right-60,
.mr-60 {
  margin-right: 60px !important; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px !important; }

.margin-left-60,
.ml-60 {
  margin-left: 60px !important; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-65,
.m-65 {
  margin: 65px !important; }

.margin-top-65,
.mt-65 {
  margin-top: 65px !important; }

.margin-right-65,
.mr-65 {
  margin-right: 65px !important; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px !important; }

.margin-left-65,
.ml-65 {
  margin-left: 65px !important; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-70,
.m-70 {
  margin: 70px !important; }

.margin-top-70,
.mt-70 {
  margin-top: 70px !important; }

.margin-right-70,
.mr-70 {
  margin-right: 70px !important; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px !important; }

.margin-left-70,
.ml-70 {
  margin-left: 70px !important; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-75,
.m-75 {
  margin: 75px !important; }

.margin-top-75,
.mt-75 {
  margin-top: 75px !important; }

.margin-right-75,
.mr-75 {
  margin-right: 75px !important; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px !important; }

.margin-left-75,
.ml-75 {
  margin-left: 75px !important; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-80,
.m-80 {
  margin: 80px !important; }

.margin-top-80,
.mt-80 {
  margin-top: 80px !important; }

.margin-right-80,
.mr-80 {
  margin-right: 80px !important; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px !important; }

.margin-left-80,
.ml-80 {
  margin-left: 80px !important; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-85,
.m-85 {
  margin: 85px !important; }

.margin-top-85,
.mt-85 {
  margin-top: 85px !important; }

.margin-right-85,
.mr-85 {
  margin-right: 85px !important; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px !important; }

.margin-left-85,
.ml-85 {
  margin-left: 85px !important; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-90,
.m-90 {
  margin: 90px !important; }

.margin-top-90,
.mt-90 {
  margin-top: 90px !important; }

.margin-right-90,
.mr-90 {
  margin-right: 90px !important; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px !important; }

.margin-left-90,
.ml-90 {
  margin-left: 90px !important; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-95,
.m-95 {
  margin: 95px !important; }

.margin-top-95,
.mt-95 {
  margin-top: 95px !important; }

.margin-right-95,
.mr-95 {
  margin-right: 95px !important; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px !important; }

.margin-left-95,
.ml-95 {
  margin-left: 95px !important; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-100,
.m-100 {
  margin: 100px !important; }

.margin-top-100,
.mt-100 {
  margin-top: 100px !important; }

.margin-right-100,
.mr-100 {
  margin-right: 100px !important; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px !important; }

.margin-left-100,
.ml-100 {
  margin-left: 100px !important; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.margin-1,
.m-1 {
  margin: 1px !important; }

.margin-top-1,
.mt-1 {
  margin-top: 1px !important; }

.margin-right-1,
.mr-1 {
  margin-right: 1px !important; }

.margin-bottom-1,
.mb-1 {
  margin-bottom: 1px !important; }

.margin-left-1,
.ml-1 {
  margin-left: 1px !important; }

.margin-horizontal-1,
.mh-1 {
  margin-left: 1px;
  margin-right: 1px; }

.margin-vertical-1,
.mv-1 {
  margin-top: 1px;
  margin-bottom: 1px; }

.margin-2,
.m-2 {
  margin: 2px !important; }

.margin-top-2,
.mt-2 {
  margin-top: 2px !important; }

.margin-right-2,
.mr-2 {
  margin-right: 2px !important; }

.margin-bottom-2,
.mb-2 {
  margin-bottom: 2px !important; }

.margin-left-2,
.ml-2 {
  margin-left: 2px !important; }

.margin-horizontal-2,
.mh-2 {
  margin-left: 2px;
  margin-right: 2px; }

.margin-vertical-2,
.mv-2 {
  margin-top: 2px;
  margin-bottom: 2px; }

.margin-3,
.m-3 {
  margin: 3px !important; }

.margin-top-3,
.mt-3 {
  margin-top: 3px !important; }

.margin-right-3,
.mr-3 {
  margin-right: 3px !important; }

.margin-bottom-3,
.mb-3 {
  margin-bottom: 3px !important; }

.margin-left-3,
.ml-3 {
  margin-left: 3px !important; }

.margin-horizontal-3,
.mh-3 {
  margin-left: 3px;
  margin-right: 3px; }

.margin-vertical-3,
.mv-3 {
  margin-top: 3px;
  margin-bottom: 3px; }

.margin-4,
.m-4 {
  margin: 4px !important; }

.margin-top-4,
.mt-4 {
  margin-top: 4px !important; }

.margin-right-4,
.mr-4 {
  margin-right: 4px !important; }

.margin-bottom-4,
.mb-4 {
  margin-bottom: 4px !important; }

.margin-left-4,
.ml-4 {
  margin-left: 4px !important; }

.margin-horizontal-4,
.mh-4 {
  margin-left: 4px;
  margin-right: 4px; }

.margin-vertical-4,
.mv-4 {
  margin-top: 4px;
  margin-bottom: 4px; }

.margin-5,
.m-5 {
  margin: 5px !important; }

.margin-top-5,
.mt-5 {
  margin-top: 5px !important; }

.margin-right-5,
.mr-5 {
  margin-right: 5px !important; }

.margin-bottom-5,
.mb-5 {
  margin-bottom: 5px !important; }

.margin-left-5,
.ml-5 {
  margin-left: 5px !important; }

.margin-horizontal-5,
.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-vertical-5,
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.margin-6,
.m-6 {
  margin: 6px !important; }

.margin-top-6,
.mt-6 {
  margin-top: 6px !important; }

.margin-right-6,
.mr-6 {
  margin-right: 6px !important; }

.margin-bottom-6,
.mb-6 {
  margin-bottom: 6px !important; }

.margin-left-6,
.ml-6 {
  margin-left: 6px !important; }

.margin-horizontal-6,
.mh-6 {
  margin-left: 6px;
  margin-right: 6px; }

.margin-vertical-6,
.mv-6 {
  margin-top: 6px;
  margin-bottom: 6px; }

.margin-7,
.m-7 {
  margin: 7px !important; }

.margin-top-7,
.mt-7 {
  margin-top: 7px !important; }

.margin-right-7,
.mr-7 {
  margin-right: 7px !important; }

.margin-bottom-7,
.mb-7 {
  margin-bottom: 7px !important; }

.margin-left-7,
.ml-7 {
  margin-left: 7px !important; }

.margin-horizontal-7,
.mh-7 {
  margin-left: 7px;
  margin-right: 7px; }

.margin-vertical-7,
.mv-7 {
  margin-top: 7px;
  margin-bottom: 7px; }

.margin-8,
.m-8 {
  margin: 8px !important; }

.margin-top-8,
.mt-8 {
  margin-top: 8px !important; }

.margin-right-8,
.mr-8 {
  margin-right: 8px !important; }

.margin-bottom-8,
.mb-8 {
  margin-bottom: 8px !important; }

.margin-left-8,
.ml-8 {
  margin-left: 8px !important; }

.margin-horizontal-8,
.mh-8 {
  margin-left: 8px;
  margin-right: 8px; }

.margin-vertical-8,
.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px; }

.margin-9,
.m-9 {
  margin: 9px !important; }

.margin-top-9,
.mt-9 {
  margin-top: 9px !important; }

.margin-right-9,
.mr-9 {
  margin-right: 9px !important; }

.margin-bottom-9,
.mb-9 {
  margin-bottom: 9px !important; }

.margin-left-9,
.ml-9 {
  margin-left: 9px !important; }

.margin-horizontal-9,
.mh-9 {
  margin-left: 9px;
  margin-right: 9px; }

.margin-vertical-9,
.mv-9 {
  margin-top: 9px;
  margin-bottom: 9px; }

.margin-10,
.m-10 {
  margin: 10px !important; }

.margin-top-10,
.mt-10 {
  margin-top: 10px !important; }

.margin-right-10,
.mr-10 {
  margin-right: 10px !important; }

.margin-bottom-10,
.mb-10 {
  margin-bottom: 10px !important; }

.margin-left-10,
.ml-10 {
  margin-left: 10px !important; }

.margin-horizontal-10,
.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.margin-vertical-10,
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-11,
.m-11 {
  margin: 11px !important; }

.margin-top-11,
.mt-11 {
  margin-top: 11px !important; }

.margin-right-11,
.mr-11 {
  margin-right: 11px !important; }

.margin-bottom-11,
.mb-11 {
  margin-bottom: 11px !important; }

.margin-left-11,
.ml-11 {
  margin-left: 11px !important; }

.margin-horizontal-11,
.mh-11 {
  margin-left: 11px;
  margin-right: 11px; }

.margin-vertical-11,
.mv-11 {
  margin-top: 11px;
  margin-bottom: 11px; }

.margin-12,
.m-12 {
  margin: 12px !important; }

.margin-top-12,
.mt-12 {
  margin-top: 12px !important; }

.margin-right-12,
.mr-12 {
  margin-right: 12px !important; }

.margin-bottom-12,
.mb-12 {
  margin-bottom: 12px !important; }

.margin-left-12,
.ml-12 {
  margin-left: 12px !important; }

.margin-horizontal-12,
.mh-12 {
  margin-left: 12px;
  margin-right: 12px; }

.margin-vertical-12,
.mv-12 {
  margin-top: 12px;
  margin-bottom: 12px; }

.margin-13,
.m-13 {
  margin: 13px !important; }

.margin-top-13,
.mt-13 {
  margin-top: 13px !important; }

.margin-right-13,
.mr-13 {
  margin-right: 13px !important; }

.margin-bottom-13,
.mb-13 {
  margin-bottom: 13px !important; }

.margin-left-13,
.ml-13 {
  margin-left: 13px !important; }

.margin-horizontal-13,
.mh-13 {
  margin-left: 13px;
  margin-right: 13px; }

.margin-vertical-13,
.mv-13 {
  margin-top: 13px;
  margin-bottom: 13px; }

.margin-14,
.m-14 {
  margin: 14px !important; }

.margin-top-14,
.mt-14 {
  margin-top: 14px !important; }

.margin-right-14,
.mr-14 {
  margin-right: 14px !important; }

.margin-bottom-14,
.mb-14 {
  margin-bottom: 14px !important; }

.margin-left-14,
.ml-14 {
  margin-left: 14px !important; }

.margin-horizontal-14,
.mh-14 {
  margin-left: 14px;
  margin-right: 14px; }

.margin-vertical-14,
.mv-14 {
  margin-top: 14px;
  margin-bottom: 14px; }

.margin-15,
.m-15 {
  margin: 15px !important; }

.margin-top-15,
.mt-15 {
  margin-top: 15px !important; }

.margin-right-15,
.mr-15 {
  margin-right: 15px !important; }

.margin-bottom-15,
.mb-15 {
  margin-bottom: 15px !important; }

.margin-left-15,
.ml-15 {
  margin-left: 15px !important; }

.margin-horizontal-15,
.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.margin-vertical-15,
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.margin-16,
.m-16 {
  margin: 16px !important; }

.margin-top-16,
.mt-16 {
  margin-top: 16px !important; }

.margin-right-16,
.mr-16 {
  margin-right: 16px !important; }

.margin-bottom-16,
.mb-16 {
  margin-bottom: 16px !important; }

.margin-left-16,
.ml-16 {
  margin-left: 16px !important; }

.margin-horizontal-16,
.mh-16 {
  margin-left: 16px;
  margin-right: 16px; }

.margin-vertical-16,
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px; }

.margin-17,
.m-17 {
  margin: 17px !important; }

.margin-top-17,
.mt-17 {
  margin-top: 17px !important; }

.margin-right-17,
.mr-17 {
  margin-right: 17px !important; }

.margin-bottom-17,
.mb-17 {
  margin-bottom: 17px !important; }

.margin-left-17,
.ml-17 {
  margin-left: 17px !important; }

.margin-horizontal-17,
.mh-17 {
  margin-left: 17px;
  margin-right: 17px; }

.margin-vertical-17,
.mv-17 {
  margin-top: 17px;
  margin-bottom: 17px; }

.margin-18,
.m-18 {
  margin: 18px !important; }

.margin-top-18,
.mt-18 {
  margin-top: 18px !important; }

.margin-right-18,
.mr-18 {
  margin-right: 18px !important; }

.margin-bottom-18,
.mb-18 {
  margin-bottom: 18px !important; }

.margin-left-18,
.ml-18 {
  margin-left: 18px !important; }

.margin-horizontal-18,
.mh-18 {
  margin-left: 18px;
  margin-right: 18px; }

.margin-vertical-18,
.mv-18 {
  margin-top: 18px;
  margin-bottom: 18px; }

.margin-19,
.m-19 {
  margin: 19px !important; }

.margin-top-19,
.mt-19 {
  margin-top: 19px !important; }

.margin-right-19,
.mr-19 {
  margin-right: 19px !important; }

.margin-bottom-19,
.mb-19 {
  margin-bottom: 19px !important; }

.margin-left-19,
.ml-19 {
  margin-left: 19px !important; }

.margin-horizontal-19,
.mh-19 {
  margin-left: 19px;
  margin-right: 19px; }

.margin-vertical-19,
.mv-19 {
  margin-top: 19px;
  margin-bottom: 19px; }

.margin-20,
.m-20 {
  margin: 20px !important; }

.margin-top-20,
.mt-20 {
  margin-top: 20px !important; }

.margin-right-20,
.mr-20 {
  margin-right: 20px !important; }

.margin-bottom-20,
.mb-20 {
  margin-bottom: 20px !important; }

.margin-left-20,
.ml-20 {
  margin-left: 20px !important; }

.margin-horizontal-20,
.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.margin-vertical-20,
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.margin-21,
.m-21 {
  margin: 21px !important; }

.margin-top-21,
.mt-21 {
  margin-top: 21px !important; }

.margin-right-21,
.mr-21 {
  margin-right: 21px !important; }

.margin-bottom-21,
.mb-21 {
  margin-bottom: 21px !important; }

.margin-left-21,
.ml-21 {
  margin-left: 21px !important; }

.margin-horizontal-21,
.mh-21 {
  margin-left: 21px;
  margin-right: 21px; }

.margin-vertical-21,
.mv-21 {
  margin-top: 21px;
  margin-bottom: 21px; }

.margin-22,
.m-22 {
  margin: 22px !important; }

.margin-top-22,
.mt-22 {
  margin-top: 22px !important; }

.margin-right-22,
.mr-22 {
  margin-right: 22px !important; }

.margin-bottom-22,
.mb-22 {
  margin-bottom: 22px !important; }

.margin-left-22,
.ml-22 {
  margin-left: 22px !important; }

.margin-horizontal-22,
.mh-22 {
  margin-left: 22px;
  margin-right: 22px; }

.margin-vertical-22,
.mv-22 {
  margin-top: 22px;
  margin-bottom: 22px; }

.margin-23,
.m-23 {
  margin: 23px !important; }

.margin-top-23,
.mt-23 {
  margin-top: 23px !important; }

.margin-right-23,
.mr-23 {
  margin-right: 23px !important; }

.margin-bottom-23,
.mb-23 {
  margin-bottom: 23px !important; }

.margin-left-23,
.ml-23 {
  margin-left: 23px !important; }

.margin-horizontal-23,
.mh-23 {
  margin-left: 23px;
  margin-right: 23px; }

.margin-vertical-23,
.mv-23 {
  margin-top: 23px;
  margin-bottom: 23px; }

.margin-24,
.m-24 {
  margin: 24px !important; }

.margin-top-24,
.mt-24 {
  margin-top: 24px !important; }

.margin-right-24,
.mr-24 {
  margin-right: 24px !important; }

.margin-bottom-24,
.mb-24 {
  margin-bottom: 24px !important; }

.margin-left-24,
.ml-24 {
  margin-left: 24px !important; }

.margin-horizontal-24,
.mh-24 {
  margin-left: 24px;
  margin-right: 24px; }

.margin-vertical-24,
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px; }

.margin-25,
.m-25 {
  margin: 25px !important; }

.margin-top-25,
.mt-25 {
  margin-top: 25px !important; }

.margin-right-25,
.mr-25 {
  margin-right: 25px !important; }

.margin-bottom-25,
.mb-25 {
  margin-bottom: 25px !important; }

.margin-left-25,
.ml-25 {
  margin-left: 25px !important; }

.margin-horizontal-25,
.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.margin-vertical-25,
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.margin-26,
.m-26 {
  margin: 26px !important; }

.margin-top-26,
.mt-26 {
  margin-top: 26px !important; }

.margin-right-26,
.mr-26 {
  margin-right: 26px !important; }

.margin-bottom-26,
.mb-26 {
  margin-bottom: 26px !important; }

.margin-left-26,
.ml-26 {
  margin-left: 26px !important; }

.margin-horizontal-26,
.mh-26 {
  margin-left: 26px;
  margin-right: 26px; }

.margin-vertical-26,
.mv-26 {
  margin-top: 26px;
  margin-bottom: 26px; }

.margin-27,
.m-27 {
  margin: 27px !important; }

.margin-top-27,
.mt-27 {
  margin-top: 27px !important; }

.margin-right-27,
.mr-27 {
  margin-right: 27px !important; }

.margin-bottom-27,
.mb-27 {
  margin-bottom: 27px !important; }

.margin-left-27,
.ml-27 {
  margin-left: 27px !important; }

.margin-horizontal-27,
.mh-27 {
  margin-left: 27px;
  margin-right: 27px; }

.margin-vertical-27,
.mv-27 {
  margin-top: 27px;
  margin-bottom: 27px; }

.margin-28,
.m-28 {
  margin: 28px !important; }

.margin-top-28,
.mt-28 {
  margin-top: 28px !important; }

.margin-right-28,
.mr-28 {
  margin-right: 28px !important; }

.margin-bottom-28,
.mb-28 {
  margin-bottom: 28px !important; }

.margin-left-28,
.ml-28 {
  margin-left: 28px !important; }

.margin-horizontal-28,
.mh-28 {
  margin-left: 28px;
  margin-right: 28px; }

.margin-vertical-28,
.mv-28 {
  margin-top: 28px;
  margin-bottom: 28px; }

.margin-29,
.m-29 {
  margin: 29px !important; }

.margin-top-29,
.mt-29 {
  margin-top: 29px !important; }

.margin-right-29,
.mr-29 {
  margin-right: 29px !important; }

.margin-bottom-29,
.mb-29 {
  margin-bottom: 29px !important; }

.margin-left-29,
.ml-29 {
  margin-left: 29px !important; }

.margin-horizontal-29,
.mh-29 {
  margin-left: 29px;
  margin-right: 29px; }

.margin-vertical-29,
.mv-29 {
  margin-top: 29px;
  margin-bottom: 29px; }

.margin-30,
.m-30 {
  margin: 30px !important; }

.margin-top-30,
.mt-30 {
  margin-top: 30px !important; }

.margin-right-30,
.mr-30 {
  margin-right: 30px !important; }

.margin-bottom-30,
.mb-30 {
  margin-bottom: 30px !important; }

.margin-left-30,
.ml-30 {
  margin-left: 30px !important; }

.margin-horizontal-30,
.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.margin-vertical-30,
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.margin-31,
.m-31 {
  margin: 31px !important; }

.margin-top-31,
.mt-31 {
  margin-top: 31px !important; }

.margin-right-31,
.mr-31 {
  margin-right: 31px !important; }

.margin-bottom-31,
.mb-31 {
  margin-bottom: 31px !important; }

.margin-left-31,
.ml-31 {
  margin-left: 31px !important; }

.margin-horizontal-31,
.mh-31 {
  margin-left: 31px;
  margin-right: 31px; }

.margin-vertical-31,
.mv-31 {
  margin-top: 31px;
  margin-bottom: 31px; }

.margin-32,
.m-32 {
  margin: 32px !important; }

.margin-top-32,
.mt-32 {
  margin-top: 32px !important; }

.margin-right-32,
.mr-32 {
  margin-right: 32px !important; }

.margin-bottom-32,
.mb-32 {
  margin-bottom: 32px !important; }

.margin-left-32,
.ml-32 {
  margin-left: 32px !important; }

.margin-horizontal-32,
.mh-32 {
  margin-left: 32px;
  margin-right: 32px; }

.margin-vertical-32,
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px; }

.margin-33,
.m-33 {
  margin: 33px !important; }

.margin-top-33,
.mt-33 {
  margin-top: 33px !important; }

.margin-right-33,
.mr-33 {
  margin-right: 33px !important; }

.margin-bottom-33,
.mb-33 {
  margin-bottom: 33px !important; }

.margin-left-33,
.ml-33 {
  margin-left: 33px !important; }

.margin-horizontal-33,
.mh-33 {
  margin-left: 33px;
  margin-right: 33px; }

.margin-vertical-33,
.mv-33 {
  margin-top: 33px;
  margin-bottom: 33px; }

.margin-34,
.m-34 {
  margin: 34px !important; }

.margin-top-34,
.mt-34 {
  margin-top: 34px !important; }

.margin-right-34,
.mr-34 {
  margin-right: 34px !important; }

.margin-bottom-34,
.mb-34 {
  margin-bottom: 34px !important; }

.margin-left-34,
.ml-34 {
  margin-left: 34px !important; }

.margin-horizontal-34,
.mh-34 {
  margin-left: 34px;
  margin-right: 34px; }

.margin-vertical-34,
.mv-34 {
  margin-top: 34px;
  margin-bottom: 34px; }

.margin-35,
.m-35 {
  margin: 35px !important; }

.margin-top-35,
.mt-35 {
  margin-top: 35px !important; }

.margin-right-35,
.mr-35 {
  margin-right: 35px !important; }

.margin-bottom-35,
.mb-35 {
  margin-bottom: 35px !important; }

.margin-left-35,
.ml-35 {
  margin-left: 35px !important; }

.margin-horizontal-35,
.mh-35 {
  margin-left: 35px;
  margin-right: 35px; }

.margin-vertical-35,
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.margin-36,
.m-36 {
  margin: 36px !important; }

.margin-top-36,
.mt-36 {
  margin-top: 36px !important; }

.margin-right-36,
.mr-36 {
  margin-right: 36px !important; }

.margin-bottom-36,
.mb-36 {
  margin-bottom: 36px !important; }

.margin-left-36,
.ml-36 {
  margin-left: 36px !important; }

.margin-horizontal-36,
.mh-36 {
  margin-left: 36px;
  margin-right: 36px; }

.margin-vertical-36,
.mv-36 {
  margin-top: 36px;
  margin-bottom: 36px; }

.margin-37,
.m-37 {
  margin: 37px !important; }

.margin-top-37,
.mt-37 {
  margin-top: 37px !important; }

.margin-right-37,
.mr-37 {
  margin-right: 37px !important; }

.margin-bottom-37,
.mb-37 {
  margin-bottom: 37px !important; }

.margin-left-37,
.ml-37 {
  margin-left: 37px !important; }

.margin-horizontal-37,
.mh-37 {
  margin-left: 37px;
  margin-right: 37px; }

.margin-vertical-37,
.mv-37 {
  margin-top: 37px;
  margin-bottom: 37px; }

.margin-38,
.m-38 {
  margin: 38px !important; }

.margin-top-38,
.mt-38 {
  margin-top: 38px !important; }

.margin-right-38,
.mr-38 {
  margin-right: 38px !important; }

.margin-bottom-38,
.mb-38 {
  margin-bottom: 38px !important; }

.margin-left-38,
.ml-38 {
  margin-left: 38px !important; }

.margin-horizontal-38,
.mh-38 {
  margin-left: 38px;
  margin-right: 38px; }

.margin-vertical-38,
.mv-38 {
  margin-top: 38px;
  margin-bottom: 38px; }

.margin-39,
.m-39 {
  margin: 39px !important; }

.margin-top-39,
.mt-39 {
  margin-top: 39px !important; }

.margin-right-39,
.mr-39 {
  margin-right: 39px !important; }

.margin-bottom-39,
.mb-39 {
  margin-bottom: 39px !important; }

.margin-left-39,
.ml-39 {
  margin-left: 39px !important; }

.margin-horizontal-39,
.mh-39 {
  margin-left: 39px;
  margin-right: 39px; }

.margin-vertical-39,
.mv-39 {
  margin-top: 39px;
  margin-bottom: 39px; }

.margin-40,
.m-40 {
  margin: 40px !important; }

.margin-top-40,
.mt-40 {
  margin-top: 40px !important; }

.margin-right-40,
.mr-40 {
  margin-right: 40px !important; }

.margin-bottom-40,
.mb-40 {
  margin-bottom: 40px !important; }

.margin-left-40,
.ml-40 {
  margin-left: 40px !important; }

.margin-horizontal-40,
.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.margin-vertical-40,
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.margin-41,
.m-41 {
  margin: 41px !important; }

.margin-top-41,
.mt-41 {
  margin-top: 41px !important; }

.margin-right-41,
.mr-41 {
  margin-right: 41px !important; }

.margin-bottom-41,
.mb-41 {
  margin-bottom: 41px !important; }

.margin-left-41,
.ml-41 {
  margin-left: 41px !important; }

.margin-horizontal-41,
.mh-41 {
  margin-left: 41px;
  margin-right: 41px; }

.margin-vertical-41,
.mv-41 {
  margin-top: 41px;
  margin-bottom: 41px; }

.margin-42,
.m-42 {
  margin: 42px !important; }

.margin-top-42,
.mt-42 {
  margin-top: 42px !important; }

.margin-right-42,
.mr-42 {
  margin-right: 42px !important; }

.margin-bottom-42,
.mb-42 {
  margin-bottom: 42px !important; }

.margin-left-42,
.ml-42 {
  margin-left: 42px !important; }

.margin-horizontal-42,
.mh-42 {
  margin-left: 42px;
  margin-right: 42px; }

.margin-vertical-42,
.mv-42 {
  margin-top: 42px;
  margin-bottom: 42px; }

.margin-43,
.m-43 {
  margin: 43px !important; }

.margin-top-43,
.mt-43 {
  margin-top: 43px !important; }

.margin-right-43,
.mr-43 {
  margin-right: 43px !important; }

.margin-bottom-43,
.mb-43 {
  margin-bottom: 43px !important; }

.margin-left-43,
.ml-43 {
  margin-left: 43px !important; }

.margin-horizontal-43,
.mh-43 {
  margin-left: 43px;
  margin-right: 43px; }

.margin-vertical-43,
.mv-43 {
  margin-top: 43px;
  margin-bottom: 43px; }

.margin-44,
.m-44 {
  margin: 44px !important; }

.margin-top-44,
.mt-44 {
  margin-top: 44px !important; }

.margin-right-44,
.mr-44 {
  margin-right: 44px !important; }

.margin-bottom-44,
.mb-44 {
  margin-bottom: 44px !important; }

.margin-left-44,
.ml-44 {
  margin-left: 44px !important; }

.margin-horizontal-44,
.mh-44 {
  margin-left: 44px;
  margin-right: 44px; }

.margin-vertical-44,
.mv-44 {
  margin-top: 44px;
  margin-bottom: 44px; }

.margin-45,
.m-45 {
  margin: 45px !important; }

.margin-top-45,
.mt-45 {
  margin-top: 45px !important; }

.margin-right-45,
.mr-45 {
  margin-right: 45px !important; }

.margin-bottom-45,
.mb-45 {
  margin-bottom: 45px !important; }

.margin-left-45,
.ml-45 {
  margin-left: 45px !important; }

.margin-horizontal-45,
.mh-45 {
  margin-left: 45px;
  margin-right: 45px; }

.margin-vertical-45,
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.margin-46,
.m-46 {
  margin: 46px !important; }

.margin-top-46,
.mt-46 {
  margin-top: 46px !important; }

.margin-right-46,
.mr-46 {
  margin-right: 46px !important; }

.margin-bottom-46,
.mb-46 {
  margin-bottom: 46px !important; }

.margin-left-46,
.ml-46 {
  margin-left: 46px !important; }

.margin-horizontal-46,
.mh-46 {
  margin-left: 46px;
  margin-right: 46px; }

.margin-vertical-46,
.mv-46 {
  margin-top: 46px;
  margin-bottom: 46px; }

.margin-47,
.m-47 {
  margin: 47px !important; }

.margin-top-47,
.mt-47 {
  margin-top: 47px !important; }

.margin-right-47,
.mr-47 {
  margin-right: 47px !important; }

.margin-bottom-47,
.mb-47 {
  margin-bottom: 47px !important; }

.margin-left-47,
.ml-47 {
  margin-left: 47px !important; }

.margin-horizontal-47,
.mh-47 {
  margin-left: 47px;
  margin-right: 47px; }

.margin-vertical-47,
.mv-47 {
  margin-top: 47px;
  margin-bottom: 47px; }

.margin-48,
.m-48 {
  margin: 48px !important; }

.margin-top-48,
.mt-48 {
  margin-top: 48px !important; }

.margin-right-48,
.mr-48 {
  margin-right: 48px !important; }

.margin-bottom-48,
.mb-48 {
  margin-bottom: 48px !important; }

.margin-left-48,
.ml-48 {
  margin-left: 48px !important; }

.margin-horizontal-48,
.mh-48 {
  margin-left: 48px;
  margin-right: 48px; }

.margin-vertical-48,
.mv-48 {
  margin-top: 48px;
  margin-bottom: 48px; }

.margin-49,
.m-49 {
  margin: 49px !important; }

.margin-top-49,
.mt-49 {
  margin-top: 49px !important; }

.margin-right-49,
.mr-49 {
  margin-right: 49px !important; }

.margin-bottom-49,
.mb-49 {
  margin-bottom: 49px !important; }

.margin-left-49,
.ml-49 {
  margin-left: 49px !important; }

.margin-horizontal-49,
.mh-49 {
  margin-left: 49px;
  margin-right: 49px; }

.margin-vertical-49,
.mv-49 {
  margin-top: 49px;
  margin-bottom: 49px; }

.margin-50,
.m-50 {
  margin: 50px !important; }

.margin-top-50,
.mt-50 {
  margin-top: 50px !important; }

.margin-right-50,
.mr-50 {
  margin-right: 50px !important; }

.margin-bottom-50,
.mb-50 {
  margin-bottom: 50px !important; }

.margin-left-50,
.ml-50 {
  margin-left: 50px !important; }

.margin-horizontal-50,
.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.margin-vertical-50,
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.margin-51,
.m-51 {
  margin: 51px !important; }

.margin-top-51,
.mt-51 {
  margin-top: 51px !important; }

.margin-right-51,
.mr-51 {
  margin-right: 51px !important; }

.margin-bottom-51,
.mb-51 {
  margin-bottom: 51px !important; }

.margin-left-51,
.ml-51 {
  margin-left: 51px !important; }

.margin-horizontal-51,
.mh-51 {
  margin-left: 51px;
  margin-right: 51px; }

.margin-vertical-51,
.mv-51 {
  margin-top: 51px;
  margin-bottom: 51px; }

.margin-52,
.m-52 {
  margin: 52px !important; }

.margin-top-52,
.mt-52 {
  margin-top: 52px !important; }

.margin-right-52,
.mr-52 {
  margin-right: 52px !important; }

.margin-bottom-52,
.mb-52 {
  margin-bottom: 52px !important; }

.margin-left-52,
.ml-52 {
  margin-left: 52px !important; }

.margin-horizontal-52,
.mh-52 {
  margin-left: 52px;
  margin-right: 52px; }

.margin-vertical-52,
.mv-52 {
  margin-top: 52px;
  margin-bottom: 52px; }

.margin-53,
.m-53 {
  margin: 53px !important; }

.margin-top-53,
.mt-53 {
  margin-top: 53px !important; }

.margin-right-53,
.mr-53 {
  margin-right: 53px !important; }

.margin-bottom-53,
.mb-53 {
  margin-bottom: 53px !important; }

.margin-left-53,
.ml-53 {
  margin-left: 53px !important; }

.margin-horizontal-53,
.mh-53 {
  margin-left: 53px;
  margin-right: 53px; }

.margin-vertical-53,
.mv-53 {
  margin-top: 53px;
  margin-bottom: 53px; }

.margin-54,
.m-54 {
  margin: 54px !important; }

.margin-top-54,
.mt-54 {
  margin-top: 54px !important; }

.margin-right-54,
.mr-54 {
  margin-right: 54px !important; }

.margin-bottom-54,
.mb-54 {
  margin-bottom: 54px !important; }

.margin-left-54,
.ml-54 {
  margin-left: 54px !important; }

.margin-horizontal-54,
.mh-54 {
  margin-left: 54px;
  margin-right: 54px; }

.margin-vertical-54,
.mv-54 {
  margin-top: 54px;
  margin-bottom: 54px; }

.margin-55,
.m-55 {
  margin: 55px !important; }

.margin-top-55,
.mt-55 {
  margin-top: 55px !important; }

.margin-right-55,
.mr-55 {
  margin-right: 55px !important; }

.margin-bottom-55,
.mb-55 {
  margin-bottom: 55px !important; }

.margin-left-55,
.ml-55 {
  margin-left: 55px !important; }

.margin-horizontal-55,
.mh-55 {
  margin-left: 55px;
  margin-right: 55px; }

.margin-vertical-55,
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.margin-56,
.m-56 {
  margin: 56px !important; }

.margin-top-56,
.mt-56 {
  margin-top: 56px !important; }

.margin-right-56,
.mr-56 {
  margin-right: 56px !important; }

.margin-bottom-56,
.mb-56 {
  margin-bottom: 56px !important; }

.margin-left-56,
.ml-56 {
  margin-left: 56px !important; }

.margin-horizontal-56,
.mh-56 {
  margin-left: 56px;
  margin-right: 56px; }

.margin-vertical-56,
.mv-56 {
  margin-top: 56px;
  margin-bottom: 56px; }

.margin-57,
.m-57 {
  margin: 57px !important; }

.margin-top-57,
.mt-57 {
  margin-top: 57px !important; }

.margin-right-57,
.mr-57 {
  margin-right: 57px !important; }

.margin-bottom-57,
.mb-57 {
  margin-bottom: 57px !important; }

.margin-left-57,
.ml-57 {
  margin-left: 57px !important; }

.margin-horizontal-57,
.mh-57 {
  margin-left: 57px;
  margin-right: 57px; }

.margin-vertical-57,
.mv-57 {
  margin-top: 57px;
  margin-bottom: 57px; }

.margin-58,
.m-58 {
  margin: 58px !important; }

.margin-top-58,
.mt-58 {
  margin-top: 58px !important; }

.margin-right-58,
.mr-58 {
  margin-right: 58px !important; }

.margin-bottom-58,
.mb-58 {
  margin-bottom: 58px !important; }

.margin-left-58,
.ml-58 {
  margin-left: 58px !important; }

.margin-horizontal-58,
.mh-58 {
  margin-left: 58px;
  margin-right: 58px; }

.margin-vertical-58,
.mv-58 {
  margin-top: 58px;
  margin-bottom: 58px; }

.margin-59,
.m-59 {
  margin: 59px !important; }

.margin-top-59,
.mt-59 {
  margin-top: 59px !important; }

.margin-right-59,
.mr-59 {
  margin-right: 59px !important; }

.margin-bottom-59,
.mb-59 {
  margin-bottom: 59px !important; }

.margin-left-59,
.ml-59 {
  margin-left: 59px !important; }

.margin-horizontal-59,
.mh-59 {
  margin-left: 59px;
  margin-right: 59px; }

.margin-vertical-59,
.mv-59 {
  margin-top: 59px;
  margin-bottom: 59px; }

.margin-60,
.m-60 {
  margin: 60px !important; }

.margin-top-60,
.mt-60 {
  margin-top: 60px !important; }

.margin-right-60,
.mr-60 {
  margin-right: 60px !important; }

.margin-bottom-60,
.mb-60 {
  margin-bottom: 60px !important; }

.margin-left-60,
.ml-60 {
  margin-left: 60px !important; }

.margin-horizontal-60,
.mh-60 {
  margin-left: 60px;
  margin-right: 60px; }

.margin-vertical-60,
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.margin-61,
.m-61 {
  margin: 61px !important; }

.margin-top-61,
.mt-61 {
  margin-top: 61px !important; }

.margin-right-61,
.mr-61 {
  margin-right: 61px !important; }

.margin-bottom-61,
.mb-61 {
  margin-bottom: 61px !important; }

.margin-left-61,
.ml-61 {
  margin-left: 61px !important; }

.margin-horizontal-61,
.mh-61 {
  margin-left: 61px;
  margin-right: 61px; }

.margin-vertical-61,
.mv-61 {
  margin-top: 61px;
  margin-bottom: 61px; }

.margin-62,
.m-62 {
  margin: 62px !important; }

.margin-top-62,
.mt-62 {
  margin-top: 62px !important; }

.margin-right-62,
.mr-62 {
  margin-right: 62px !important; }

.margin-bottom-62,
.mb-62 {
  margin-bottom: 62px !important; }

.margin-left-62,
.ml-62 {
  margin-left: 62px !important; }

.margin-horizontal-62,
.mh-62 {
  margin-left: 62px;
  margin-right: 62px; }

.margin-vertical-62,
.mv-62 {
  margin-top: 62px;
  margin-bottom: 62px; }

.margin-63,
.m-63 {
  margin: 63px !important; }

.margin-top-63,
.mt-63 {
  margin-top: 63px !important; }

.margin-right-63,
.mr-63 {
  margin-right: 63px !important; }

.margin-bottom-63,
.mb-63 {
  margin-bottom: 63px !important; }

.margin-left-63,
.ml-63 {
  margin-left: 63px !important; }

.margin-horizontal-63,
.mh-63 {
  margin-left: 63px;
  margin-right: 63px; }

.margin-vertical-63,
.mv-63 {
  margin-top: 63px;
  margin-bottom: 63px; }

.margin-64,
.m-64 {
  margin: 64px !important; }

.margin-top-64,
.mt-64 {
  margin-top: 64px !important; }

.margin-right-64,
.mr-64 {
  margin-right: 64px !important; }

.margin-bottom-64,
.mb-64 {
  margin-bottom: 64px !important; }

.margin-left-64,
.ml-64 {
  margin-left: 64px !important; }

.margin-horizontal-64,
.mh-64 {
  margin-left: 64px;
  margin-right: 64px; }

.margin-vertical-64,
.mv-64 {
  margin-top: 64px;
  margin-bottom: 64px; }

.margin-65,
.m-65 {
  margin: 65px !important; }

.margin-top-65,
.mt-65 {
  margin-top: 65px !important; }

.margin-right-65,
.mr-65 {
  margin-right: 65px !important; }

.margin-bottom-65,
.mb-65 {
  margin-bottom: 65px !important; }

.margin-left-65,
.ml-65 {
  margin-left: 65px !important; }

.margin-horizontal-65,
.mh-65 {
  margin-left: 65px;
  margin-right: 65px; }

.margin-vertical-65,
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.margin-66,
.m-66 {
  margin: 66px !important; }

.margin-top-66,
.mt-66 {
  margin-top: 66px !important; }

.margin-right-66,
.mr-66 {
  margin-right: 66px !important; }

.margin-bottom-66,
.mb-66 {
  margin-bottom: 66px !important; }

.margin-left-66,
.ml-66 {
  margin-left: 66px !important; }

.margin-horizontal-66,
.mh-66 {
  margin-left: 66px;
  margin-right: 66px; }

.margin-vertical-66,
.mv-66 {
  margin-top: 66px;
  margin-bottom: 66px; }

.margin-67,
.m-67 {
  margin: 67px !important; }

.margin-top-67,
.mt-67 {
  margin-top: 67px !important; }

.margin-right-67,
.mr-67 {
  margin-right: 67px !important; }

.margin-bottom-67,
.mb-67 {
  margin-bottom: 67px !important; }

.margin-left-67,
.ml-67 {
  margin-left: 67px !important; }

.margin-horizontal-67,
.mh-67 {
  margin-left: 67px;
  margin-right: 67px; }

.margin-vertical-67,
.mv-67 {
  margin-top: 67px;
  margin-bottom: 67px; }

.margin-68,
.m-68 {
  margin: 68px !important; }

.margin-top-68,
.mt-68 {
  margin-top: 68px !important; }

.margin-right-68,
.mr-68 {
  margin-right: 68px !important; }

.margin-bottom-68,
.mb-68 {
  margin-bottom: 68px !important; }

.margin-left-68,
.ml-68 {
  margin-left: 68px !important; }

.margin-horizontal-68,
.mh-68 {
  margin-left: 68px;
  margin-right: 68px; }

.margin-vertical-68,
.mv-68 {
  margin-top: 68px;
  margin-bottom: 68px; }

.margin-69,
.m-69 {
  margin: 69px !important; }

.margin-top-69,
.mt-69 {
  margin-top: 69px !important; }

.margin-right-69,
.mr-69 {
  margin-right: 69px !important; }

.margin-bottom-69,
.mb-69 {
  margin-bottom: 69px !important; }

.margin-left-69,
.ml-69 {
  margin-left: 69px !important; }

.margin-horizontal-69,
.mh-69 {
  margin-left: 69px;
  margin-right: 69px; }

.margin-vertical-69,
.mv-69 {
  margin-top: 69px;
  margin-bottom: 69px; }

.margin-70,
.m-70 {
  margin: 70px !important; }

.margin-top-70,
.mt-70 {
  margin-top: 70px !important; }

.margin-right-70,
.mr-70 {
  margin-right: 70px !important; }

.margin-bottom-70,
.mb-70 {
  margin-bottom: 70px !important; }

.margin-left-70,
.ml-70 {
  margin-left: 70px !important; }

.margin-horizontal-70,
.mh-70 {
  margin-left: 70px;
  margin-right: 70px; }

.margin-vertical-70,
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.margin-71,
.m-71 {
  margin: 71px !important; }

.margin-top-71,
.mt-71 {
  margin-top: 71px !important; }

.margin-right-71,
.mr-71 {
  margin-right: 71px !important; }

.margin-bottom-71,
.mb-71 {
  margin-bottom: 71px !important; }

.margin-left-71,
.ml-71 {
  margin-left: 71px !important; }

.margin-horizontal-71,
.mh-71 {
  margin-left: 71px;
  margin-right: 71px; }

.margin-vertical-71,
.mv-71 {
  margin-top: 71px;
  margin-bottom: 71px; }

.margin-72,
.m-72 {
  margin: 72px !important; }

.margin-top-72,
.mt-72 {
  margin-top: 72px !important; }

.margin-right-72,
.mr-72 {
  margin-right: 72px !important; }

.margin-bottom-72,
.mb-72 {
  margin-bottom: 72px !important; }

.margin-left-72,
.ml-72 {
  margin-left: 72px !important; }

.margin-horizontal-72,
.mh-72 {
  margin-left: 72px;
  margin-right: 72px; }

.margin-vertical-72,
.mv-72 {
  margin-top: 72px;
  margin-bottom: 72px; }

.margin-73,
.m-73 {
  margin: 73px !important; }

.margin-top-73,
.mt-73 {
  margin-top: 73px !important; }

.margin-right-73,
.mr-73 {
  margin-right: 73px !important; }

.margin-bottom-73,
.mb-73 {
  margin-bottom: 73px !important; }

.margin-left-73,
.ml-73 {
  margin-left: 73px !important; }

.margin-horizontal-73,
.mh-73 {
  margin-left: 73px;
  margin-right: 73px; }

.margin-vertical-73,
.mv-73 {
  margin-top: 73px;
  margin-bottom: 73px; }

.margin-74,
.m-74 {
  margin: 74px !important; }

.margin-top-74,
.mt-74 {
  margin-top: 74px !important; }

.margin-right-74,
.mr-74 {
  margin-right: 74px !important; }

.margin-bottom-74,
.mb-74 {
  margin-bottom: 74px !important; }

.margin-left-74,
.ml-74 {
  margin-left: 74px !important; }

.margin-horizontal-74,
.mh-74 {
  margin-left: 74px;
  margin-right: 74px; }

.margin-vertical-74,
.mv-74 {
  margin-top: 74px;
  margin-bottom: 74px; }

.margin-75,
.m-75 {
  margin: 75px !important; }

.margin-top-75,
.mt-75 {
  margin-top: 75px !important; }

.margin-right-75,
.mr-75 {
  margin-right: 75px !important; }

.margin-bottom-75,
.mb-75 {
  margin-bottom: 75px !important; }

.margin-left-75,
.ml-75 {
  margin-left: 75px !important; }

.margin-horizontal-75,
.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.margin-vertical-75,
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.margin-76,
.m-76 {
  margin: 76px !important; }

.margin-top-76,
.mt-76 {
  margin-top: 76px !important; }

.margin-right-76,
.mr-76 {
  margin-right: 76px !important; }

.margin-bottom-76,
.mb-76 {
  margin-bottom: 76px !important; }

.margin-left-76,
.ml-76 {
  margin-left: 76px !important; }

.margin-horizontal-76,
.mh-76 {
  margin-left: 76px;
  margin-right: 76px; }

.margin-vertical-76,
.mv-76 {
  margin-top: 76px;
  margin-bottom: 76px; }

.margin-77,
.m-77 {
  margin: 77px !important; }

.margin-top-77,
.mt-77 {
  margin-top: 77px !important; }

.margin-right-77,
.mr-77 {
  margin-right: 77px !important; }

.margin-bottom-77,
.mb-77 {
  margin-bottom: 77px !important; }

.margin-left-77,
.ml-77 {
  margin-left: 77px !important; }

.margin-horizontal-77,
.mh-77 {
  margin-left: 77px;
  margin-right: 77px; }

.margin-vertical-77,
.mv-77 {
  margin-top: 77px;
  margin-bottom: 77px; }

.margin-78,
.m-78 {
  margin: 78px !important; }

.margin-top-78,
.mt-78 {
  margin-top: 78px !important; }

.margin-right-78,
.mr-78 {
  margin-right: 78px !important; }

.margin-bottom-78,
.mb-78 {
  margin-bottom: 78px !important; }

.margin-left-78,
.ml-78 {
  margin-left: 78px !important; }

.margin-horizontal-78,
.mh-78 {
  margin-left: 78px;
  margin-right: 78px; }

.margin-vertical-78,
.mv-78 {
  margin-top: 78px;
  margin-bottom: 78px; }

.margin-79,
.m-79 {
  margin: 79px !important; }

.margin-top-79,
.mt-79 {
  margin-top: 79px !important; }

.margin-right-79,
.mr-79 {
  margin-right: 79px !important; }

.margin-bottom-79,
.mb-79 {
  margin-bottom: 79px !important; }

.margin-left-79,
.ml-79 {
  margin-left: 79px !important; }

.margin-horizontal-79,
.mh-79 {
  margin-left: 79px;
  margin-right: 79px; }

.margin-vertical-79,
.mv-79 {
  margin-top: 79px;
  margin-bottom: 79px; }

.margin-80,
.m-80 {
  margin: 80px !important; }

.margin-top-80,
.mt-80 {
  margin-top: 80px !important; }

.margin-right-80,
.mr-80 {
  margin-right: 80px !important; }

.margin-bottom-80,
.mb-80 {
  margin-bottom: 80px !important; }

.margin-left-80,
.ml-80 {
  margin-left: 80px !important; }

.margin-horizontal-80,
.mh-80 {
  margin-left: 80px;
  margin-right: 80px; }

.margin-vertical-80,
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.margin-81,
.m-81 {
  margin: 81px !important; }

.margin-top-81,
.mt-81 {
  margin-top: 81px !important; }

.margin-right-81,
.mr-81 {
  margin-right: 81px !important; }

.margin-bottom-81,
.mb-81 {
  margin-bottom: 81px !important; }

.margin-left-81,
.ml-81 {
  margin-left: 81px !important; }

.margin-horizontal-81,
.mh-81 {
  margin-left: 81px;
  margin-right: 81px; }

.margin-vertical-81,
.mv-81 {
  margin-top: 81px;
  margin-bottom: 81px; }

.margin-82,
.m-82 {
  margin: 82px !important; }

.margin-top-82,
.mt-82 {
  margin-top: 82px !important; }

.margin-right-82,
.mr-82 {
  margin-right: 82px !important; }

.margin-bottom-82,
.mb-82 {
  margin-bottom: 82px !important; }

.margin-left-82,
.ml-82 {
  margin-left: 82px !important; }

.margin-horizontal-82,
.mh-82 {
  margin-left: 82px;
  margin-right: 82px; }

.margin-vertical-82,
.mv-82 {
  margin-top: 82px;
  margin-bottom: 82px; }

.margin-83,
.m-83 {
  margin: 83px !important; }

.margin-top-83,
.mt-83 {
  margin-top: 83px !important; }

.margin-right-83,
.mr-83 {
  margin-right: 83px !important; }

.margin-bottom-83,
.mb-83 {
  margin-bottom: 83px !important; }

.margin-left-83,
.ml-83 {
  margin-left: 83px !important; }

.margin-horizontal-83,
.mh-83 {
  margin-left: 83px;
  margin-right: 83px; }

.margin-vertical-83,
.mv-83 {
  margin-top: 83px;
  margin-bottom: 83px; }

.margin-84,
.m-84 {
  margin: 84px !important; }

.margin-top-84,
.mt-84 {
  margin-top: 84px !important; }

.margin-right-84,
.mr-84 {
  margin-right: 84px !important; }

.margin-bottom-84,
.mb-84 {
  margin-bottom: 84px !important; }

.margin-left-84,
.ml-84 {
  margin-left: 84px !important; }

.margin-horizontal-84,
.mh-84 {
  margin-left: 84px;
  margin-right: 84px; }

.margin-vertical-84,
.mv-84 {
  margin-top: 84px;
  margin-bottom: 84px; }

.margin-85,
.m-85 {
  margin: 85px !important; }

.margin-top-85,
.mt-85 {
  margin-top: 85px !important; }

.margin-right-85,
.mr-85 {
  margin-right: 85px !important; }

.margin-bottom-85,
.mb-85 {
  margin-bottom: 85px !important; }

.margin-left-85,
.ml-85 {
  margin-left: 85px !important; }

.margin-horizontal-85,
.mh-85 {
  margin-left: 85px;
  margin-right: 85px; }

.margin-vertical-85,
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.margin-86,
.m-86 {
  margin: 86px !important; }

.margin-top-86,
.mt-86 {
  margin-top: 86px !important; }

.margin-right-86,
.mr-86 {
  margin-right: 86px !important; }

.margin-bottom-86,
.mb-86 {
  margin-bottom: 86px !important; }

.margin-left-86,
.ml-86 {
  margin-left: 86px !important; }

.margin-horizontal-86,
.mh-86 {
  margin-left: 86px;
  margin-right: 86px; }

.margin-vertical-86,
.mv-86 {
  margin-top: 86px;
  margin-bottom: 86px; }

.margin-87,
.m-87 {
  margin: 87px !important; }

.margin-top-87,
.mt-87 {
  margin-top: 87px !important; }

.margin-right-87,
.mr-87 {
  margin-right: 87px !important; }

.margin-bottom-87,
.mb-87 {
  margin-bottom: 87px !important; }

.margin-left-87,
.ml-87 {
  margin-left: 87px !important; }

.margin-horizontal-87,
.mh-87 {
  margin-left: 87px;
  margin-right: 87px; }

.margin-vertical-87,
.mv-87 {
  margin-top: 87px;
  margin-bottom: 87px; }

.margin-88,
.m-88 {
  margin: 88px !important; }

.margin-top-88,
.mt-88 {
  margin-top: 88px !important; }

.margin-right-88,
.mr-88 {
  margin-right: 88px !important; }

.margin-bottom-88,
.mb-88 {
  margin-bottom: 88px !important; }

.margin-left-88,
.ml-88 {
  margin-left: 88px !important; }

.margin-horizontal-88,
.mh-88 {
  margin-left: 88px;
  margin-right: 88px; }

.margin-vertical-88,
.mv-88 {
  margin-top: 88px;
  margin-bottom: 88px; }

.margin-89,
.m-89 {
  margin: 89px !important; }

.margin-top-89,
.mt-89 {
  margin-top: 89px !important; }

.margin-right-89,
.mr-89 {
  margin-right: 89px !important; }

.margin-bottom-89,
.mb-89 {
  margin-bottom: 89px !important; }

.margin-left-89,
.ml-89 {
  margin-left: 89px !important; }

.margin-horizontal-89,
.mh-89 {
  margin-left: 89px;
  margin-right: 89px; }

.margin-vertical-89,
.mv-89 {
  margin-top: 89px;
  margin-bottom: 89px; }

.margin-90,
.m-90 {
  margin: 90px !important; }

.margin-top-90,
.mt-90 {
  margin-top: 90px !important; }

.margin-right-90,
.mr-90 {
  margin-right: 90px !important; }

.margin-bottom-90,
.mb-90 {
  margin-bottom: 90px !important; }

.margin-left-90,
.ml-90 {
  margin-left: 90px !important; }

.margin-horizontal-90,
.mh-90 {
  margin-left: 90px;
  margin-right: 90px; }

.margin-vertical-90,
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.margin-91,
.m-91 {
  margin: 91px !important; }

.margin-top-91,
.mt-91 {
  margin-top: 91px !important; }

.margin-right-91,
.mr-91 {
  margin-right: 91px !important; }

.margin-bottom-91,
.mb-91 {
  margin-bottom: 91px !important; }

.margin-left-91,
.ml-91 {
  margin-left: 91px !important; }

.margin-horizontal-91,
.mh-91 {
  margin-left: 91px;
  margin-right: 91px; }

.margin-vertical-91,
.mv-91 {
  margin-top: 91px;
  margin-bottom: 91px; }

.margin-92,
.m-92 {
  margin: 92px !important; }

.margin-top-92,
.mt-92 {
  margin-top: 92px !important; }

.margin-right-92,
.mr-92 {
  margin-right: 92px !important; }

.margin-bottom-92,
.mb-92 {
  margin-bottom: 92px !important; }

.margin-left-92,
.ml-92 {
  margin-left: 92px !important; }

.margin-horizontal-92,
.mh-92 {
  margin-left: 92px;
  margin-right: 92px; }

.margin-vertical-92,
.mv-92 {
  margin-top: 92px;
  margin-bottom: 92px; }

.margin-93,
.m-93 {
  margin: 93px !important; }

.margin-top-93,
.mt-93 {
  margin-top: 93px !important; }

.margin-right-93,
.mr-93 {
  margin-right: 93px !important; }

.margin-bottom-93,
.mb-93 {
  margin-bottom: 93px !important; }

.margin-left-93,
.ml-93 {
  margin-left: 93px !important; }

.margin-horizontal-93,
.mh-93 {
  margin-left: 93px;
  margin-right: 93px; }

.margin-vertical-93,
.mv-93 {
  margin-top: 93px;
  margin-bottom: 93px; }

.margin-94,
.m-94 {
  margin: 94px !important; }

.margin-top-94,
.mt-94 {
  margin-top: 94px !important; }

.margin-right-94,
.mr-94 {
  margin-right: 94px !important; }

.margin-bottom-94,
.mb-94 {
  margin-bottom: 94px !important; }

.margin-left-94,
.ml-94 {
  margin-left: 94px !important; }

.margin-horizontal-94,
.mh-94 {
  margin-left: 94px;
  margin-right: 94px; }

.margin-vertical-94,
.mv-94 {
  margin-top: 94px;
  margin-bottom: 94px; }

.margin-95,
.m-95 {
  margin: 95px !important; }

.margin-top-95,
.mt-95 {
  margin-top: 95px !important; }

.margin-right-95,
.mr-95 {
  margin-right: 95px !important; }

.margin-bottom-95,
.mb-95 {
  margin-bottom: 95px !important; }

.margin-left-95,
.ml-95 {
  margin-left: 95px !important; }

.margin-horizontal-95,
.mh-95 {
  margin-left: 95px;
  margin-right: 95px; }

.margin-vertical-95,
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.margin-96,
.m-96 {
  margin: 96px !important; }

.margin-top-96,
.mt-96 {
  margin-top: 96px !important; }

.margin-right-96,
.mr-96 {
  margin-right: 96px !important; }

.margin-bottom-96,
.mb-96 {
  margin-bottom: 96px !important; }

.margin-left-96,
.ml-96 {
  margin-left: 96px !important; }

.margin-horizontal-96,
.mh-96 {
  margin-left: 96px;
  margin-right: 96px; }

.margin-vertical-96,
.mv-96 {
  margin-top: 96px;
  margin-bottom: 96px; }

.margin-97,
.m-97 {
  margin: 97px !important; }

.margin-top-97,
.mt-97 {
  margin-top: 97px !important; }

.margin-right-97,
.mr-97 {
  margin-right: 97px !important; }

.margin-bottom-97,
.mb-97 {
  margin-bottom: 97px !important; }

.margin-left-97,
.ml-97 {
  margin-left: 97px !important; }

.margin-horizontal-97,
.mh-97 {
  margin-left: 97px;
  margin-right: 97px; }

.margin-vertical-97,
.mv-97 {
  margin-top: 97px;
  margin-bottom: 97px; }

.margin-98,
.m-98 {
  margin: 98px !important; }

.margin-top-98,
.mt-98 {
  margin-top: 98px !important; }

.margin-right-98,
.mr-98 {
  margin-right: 98px !important; }

.margin-bottom-98,
.mb-98 {
  margin-bottom: 98px !important; }

.margin-left-98,
.ml-98 {
  margin-left: 98px !important; }

.margin-horizontal-98,
.mh-98 {
  margin-left: 98px;
  margin-right: 98px; }

.margin-vertical-98,
.mv-98 {
  margin-top: 98px;
  margin-bottom: 98px; }

.margin-99,
.m-99 {
  margin: 99px !important; }

.margin-top-99,
.mt-99 {
  margin-top: 99px !important; }

.margin-right-99,
.mr-99 {
  margin-right: 99px !important; }

.margin-bottom-99,
.mb-99 {
  margin-bottom: 99px !important; }

.margin-left-99,
.ml-99 {
  margin-left: 99px !important; }

.margin-horizontal-99,
.mh-99 {
  margin-left: 99px;
  margin-right: 99px; }

.margin-vertical-99,
.mv-99 {
  margin-top: 99px;
  margin-bottom: 99px; }

.margin-100,
.m-100 {
  margin: 100px !important; }

.margin-top-100,
.mt-100 {
  margin-top: 100px !important; }

.margin-right-100,
.mr-100 {
  margin-right: 100px !important; }

.margin-bottom-100,
.mb-100 {
  margin-bottom: 100px !important; }

.margin-left-100,
.ml-100 {
  margin-left: 100px !important; }

.margin-horizontal-100,
.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.margin-vertical-100,
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.height-0,
.h-0 {
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important; }

.width-0,
.w-0 {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important; }

.height-5,
.h-5 {
  height: 5px !important;
  min-height: 5px !important;
  max-height: 5px !important; }

.width-5,
.w-5 {
  width: 5px !important;
  min-width: 5px !important;
  max-width: 5px !important; }

.height-10,
.h-10 {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important; }

.width-10,
.w-10 {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important; }

.height-15,
.h-15 {
  height: 15px !important;
  min-height: 15px !important;
  max-height: 15px !important; }

.width-15,
.w-15 {
  width: 15px !important;
  min-width: 15px !important;
  max-width: 15px !important; }

.height-20,
.h-20 {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important; }

.width-20,
.w-20 {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important; }

.height-25,
.h-25 {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important; }

.width-25,
.w-25 {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important; }

.height-30,
.h-30 {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important; }

.width-30,
.w-30 {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important; }

.height-35,
.h-35 {
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important; }

.width-35,
.w-35 {
  width: 35px !important;
  min-width: 35px !important;
  max-width: 35px !important; }

.height-40,
.h-40 {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important; }

.width-40,
.w-40 {
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important; }

.height-45,
.h-45 {
  height: 45px !important;
  min-height: 45px !important;
  max-height: 45px !important; }

.width-45,
.w-45 {
  width: 45px !important;
  min-width: 45px !important;
  max-width: 45px !important; }

.height-50,
.h-50 {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important; }

.width-50,
.w-50 {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important; }

.height-55,
.h-55 {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important; }

.width-55,
.w-55 {
  width: 55px !important;
  min-width: 55px !important;
  max-width: 55px !important; }

.height-60,
.h-60 {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important; }

.width-60,
.w-60 {
  width: 60px !important;
  min-width: 60px !important;
  max-width: 60px !important; }

.height-65,
.h-65 {
  height: 65px !important;
  min-height: 65px !important;
  max-height: 65px !important; }

.width-65,
.w-65 {
  width: 65px !important;
  min-width: 65px !important;
  max-width: 65px !important; }

.height-70,
.h-70 {
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important; }

.width-70,
.w-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important; }

.height-75,
.h-75 {
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important; }

.width-75,
.w-75 {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important; }

.height-80,
.h-80 {
  height: 80px !important;
  min-height: 80px !important;
  max-height: 80px !important; }

.width-80,
.w-80 {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important; }

.height-85,
.h-85 {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important; }

.width-85,
.w-85 {
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important; }

.height-90,
.h-90 {
  height: 90px !important;
  min-height: 90px !important;
  max-height: 90px !important; }

.width-90,
.w-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important; }

.height-95,
.h-95 {
  height: 95px !important;
  min-height: 95px !important;
  max-height: 95px !important; }

.width-95,
.w-95 {
  width: 95px !important;
  min-width: 95px !important;
  max-width: 95px !important; }

.height-100,
.h-100 {
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important; }

.width-100,
.w-100 {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important; }

.height-105,
.h-105 {
  height: 105px !important;
  min-height: 105px !important;
  max-height: 105px !important; }

.width-105,
.w-105 {
  width: 105px !important;
  min-width: 105px !important;
  max-width: 105px !important; }

.height-110,
.h-110 {
  height: 110px !important;
  min-height: 110px !important;
  max-height: 110px !important; }

.width-110,
.w-110 {
  width: 110px !important;
  min-width: 110px !important;
  max-width: 110px !important; }

.height-115,
.h-115 {
  height: 115px !important;
  min-height: 115px !important;
  max-height: 115px !important; }

.width-115,
.w-115 {
  width: 115px !important;
  min-width: 115px !important;
  max-width: 115px !important; }

.height-120,
.h-120 {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important; }

.width-120,
.w-120 {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important; }

.height-125,
.h-125 {
  height: 125px !important;
  min-height: 125px !important;
  max-height: 125px !important; }

.width-125,
.w-125 {
  width: 125px !important;
  min-width: 125px !important;
  max-width: 125px !important; }

.height-130,
.h-130 {
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important; }

.width-130,
.w-130 {
  width: 130px !important;
  min-width: 130px !important;
  max-width: 130px !important; }

.height-135,
.h-135 {
  height: 135px !important;
  min-height: 135px !important;
  max-height: 135px !important; }

.width-135,
.w-135 {
  width: 135px !important;
  min-width: 135px !important;
  max-width: 135px !important; }

.height-140,
.h-140 {
  height: 140px !important;
  min-height: 140px !important;
  max-height: 140px !important; }

.width-140,
.w-140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important; }

.height-145,
.h-145 {
  height: 145px !important;
  min-height: 145px !important;
  max-height: 145px !important; }

.width-145,
.w-145 {
  width: 145px !important;
  min-width: 145px !important;
  max-width: 145px !important; }

.height-150,
.h-150 {
  height: 150px !important;
  min-height: 150px !important;
  max-height: 150px !important; }

.width-150,
.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important; }

.height-155,
.h-155 {
  height: 155px !important;
  min-height: 155px !important;
  max-height: 155px !important; }

.width-155,
.w-155 {
  width: 155px !important;
  min-width: 155px !important;
  max-width: 155px !important; }

.height-160,
.h-160 {
  height: 160px !important;
  min-height: 160px !important;
  max-height: 160px !important; }

.width-160,
.w-160 {
  width: 160px !important;
  min-width: 160px !important;
  max-width: 160px !important; }

.height-165,
.h-165 {
  height: 165px !important;
  min-height: 165px !important;
  max-height: 165px !important; }

.width-165,
.w-165 {
  width: 165px !important;
  min-width: 165px !important;
  max-width: 165px !important; }

.height-170,
.h-170 {
  height: 170px !important;
  min-height: 170px !important;
  max-height: 170px !important; }

.width-170,
.w-170 {
  width: 170px !important;
  min-width: 170px !important;
  max-width: 170px !important; }

.height-175,
.h-175 {
  height: 175px !important;
  min-height: 175px !important;
  max-height: 175px !important; }

.width-175,
.w-175 {
  width: 175px !important;
  min-width: 175px !important;
  max-width: 175px !important; }

.height-180,
.h-180 {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important; }

.width-180,
.w-180 {
  width: 180px !important;
  min-width: 180px !important;
  max-width: 180px !important; }

.height-185,
.h-185 {
  height: 185px !important;
  min-height: 185px !important;
  max-height: 185px !important; }

.width-185,
.w-185 {
  width: 185px !important;
  min-width: 185px !important;
  max-width: 185px !important; }

.height-190,
.h-190 {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important; }

.width-190,
.w-190 {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important; }

.height-195,
.h-195 {
  height: 195px !important;
  min-height: 195px !important;
  max-height: 195px !important; }

.width-195,
.w-195 {
  width: 195px !important;
  min-width: 195px !important;
  max-width: 195px !important; }

.height-200,
.h-200 {
  height: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important; }

.width-200,
.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important; }

.height-205,
.h-205 {
  height: 205px !important;
  min-height: 205px !important;
  max-height: 205px !important; }

.width-205,
.w-205 {
  width: 205px !important;
  min-width: 205px !important;
  max-width: 205px !important; }

.height-210,
.h-210 {
  height: 210px !important;
  min-height: 210px !important;
  max-height: 210px !important; }

.width-210,
.w-210 {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 210px !important; }

.height-215,
.h-215 {
  height: 215px !important;
  min-height: 215px !important;
  max-height: 215px !important; }

.width-215,
.w-215 {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important; }

.height-220,
.h-220 {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important; }

.width-220,
.w-220 {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important; }

.height-225,
.h-225 {
  height: 225px !important;
  min-height: 225px !important;
  max-height: 225px !important; }

.width-225,
.w-225 {
  width: 225px !important;
  min-width: 225px !important;
  max-width: 225px !important; }

.height-230,
.h-230 {
  height: 230px !important;
  min-height: 230px !important;
  max-height: 230px !important; }

.width-230,
.w-230 {
  width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important; }

.height-235,
.h-235 {
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important; }

.width-235,
.w-235 {
  width: 235px !important;
  min-width: 235px !important;
  max-width: 235px !important; }

.height-240,
.h-240 {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important; }

.width-240,
.w-240 {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important; }

.height-245,
.h-245 {
  height: 245px !important;
  min-height: 245px !important;
  max-height: 245px !important; }

.width-245,
.w-245 {
  width: 245px !important;
  min-width: 245px !important;
  max-width: 245px !important; }

.height-250,
.h-250 {
  height: 250px !important;
  min-height: 250px !important;
  max-height: 250px !important; }

.width-250,
.w-250 {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important; }

.height-255,
.h-255 {
  height: 255px !important;
  min-height: 255px !important;
  max-height: 255px !important; }

.width-255,
.w-255 {
  width: 255px !important;
  min-width: 255px !important;
  max-width: 255px !important; }

.height-260,
.h-260 {
  height: 260px !important;
  min-height: 260px !important;
  max-height: 260px !important; }

.width-260,
.w-260 {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important; }

.height-265,
.h-265 {
  height: 265px !important;
  min-height: 265px !important;
  max-height: 265px !important; }

.width-265,
.w-265 {
  width: 265px !important;
  min-width: 265px !important;
  max-width: 265px !important; }

.height-270,
.h-270 {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important; }

.width-270,
.w-270 {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important; }

.height-275,
.h-275 {
  height: 275px !important;
  min-height: 275px !important;
  max-height: 275px !important; }

.width-275,
.w-275 {
  width: 275px !important;
  min-width: 275px !important;
  max-width: 275px !important; }

.height-280,
.h-280 {
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important; }

.width-280,
.w-280 {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important; }

.height-285,
.h-285 {
  height: 285px !important;
  min-height: 285px !important;
  max-height: 285px !important; }

.width-285,
.w-285 {
  width: 285px !important;
  min-width: 285px !important;
  max-width: 285px !important; }

.height-290,
.h-290 {
  height: 290px !important;
  min-height: 290px !important;
  max-height: 290px !important; }

.width-290,
.w-290 {
  width: 290px !important;
  min-width: 290px !important;
  max-width: 290px !important; }

.height-295,
.h-295 {
  height: 295px !important;
  min-height: 295px !important;
  max-height: 295px !important; }

.width-295,
.w-295 {
  width: 295px !important;
  min-width: 295px !important;
  max-width: 295px !important; }

.height-300,
.h-300 {
  height: 300px !important;
  min-height: 300px !important;
  max-height: 300px !important; }

.width-300,
.w-300 {
  width: 300px !important;
  min-width: 300px !important;
  max-width: 300px !important; }

.height-305,
.h-305 {
  height: 305px !important;
  min-height: 305px !important;
  max-height: 305px !important; }

.width-305,
.w-305 {
  width: 305px !important;
  min-width: 305px !important;
  max-width: 305px !important; }

.height-310,
.h-310 {
  height: 310px !important;
  min-height: 310px !important;
  max-height: 310px !important; }

.width-310,
.w-310 {
  width: 310px !important;
  min-width: 310px !important;
  max-width: 310px !important; }

.height-315,
.h-315 {
  height: 315px !important;
  min-height: 315px !important;
  max-height: 315px !important; }

.width-315,
.w-315 {
  width: 315px !important;
  min-width: 315px !important;
  max-width: 315px !important; }

.height-320,
.h-320 {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 320px !important; }

.width-320,
.w-320 {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important; }

.height-325,
.h-325 {
  height: 325px !important;
  min-height: 325px !important;
  max-height: 325px !important; }

.width-325,
.w-325 {
  width: 325px !important;
  min-width: 325px !important;
  max-width: 325px !important; }

.height-330,
.h-330 {
  height: 330px !important;
  min-height: 330px !important;
  max-height: 330px !important; }

.width-330,
.w-330 {
  width: 330px !important;
  min-width: 330px !important;
  max-width: 330px !important; }

.height-335,
.h-335 {
  height: 335px !important;
  min-height: 335px !important;
  max-height: 335px !important; }

.width-335,
.w-335 {
  width: 335px !important;
  min-width: 335px !important;
  max-width: 335px !important; }

.height-340,
.h-340 {
  height: 340px !important;
  min-height: 340px !important;
  max-height: 340px !important; }

.width-340,
.w-340 {
  width: 340px !important;
  min-width: 340px !important;
  max-width: 340px !important; }

.height-345,
.h-345 {
  height: 345px !important;
  min-height: 345px !important;
  max-height: 345px !important; }

.width-345,
.w-345 {
  width: 345px !important;
  min-width: 345px !important;
  max-width: 345px !important; }

.height-350,
.h-350 {
  height: 350px !important;
  min-height: 350px !important;
  max-height: 350px !important; }

.width-350,
.w-350 {
  width: 350px !important;
  min-width: 350px !important;
  max-width: 350px !important; }

.height-355,
.h-355 {
  height: 355px !important;
  min-height: 355px !important;
  max-height: 355px !important; }

.width-355,
.w-355 {
  width: 355px !important;
  min-width: 355px !important;
  max-width: 355px !important; }

.height-360,
.h-360 {
  height: 360px !important;
  min-height: 360px !important;
  max-height: 360px !important; }

.width-360,
.w-360 {
  width: 360px !important;
  min-width: 360px !important;
  max-width: 360px !important; }

.height-365,
.h-365 {
  height: 365px !important;
  min-height: 365px !important;
  max-height: 365px !important; }

.width-365,
.w-365 {
  width: 365px !important;
  min-width: 365px !important;
  max-width: 365px !important; }

.height-370,
.h-370 {
  height: 370px !important;
  min-height: 370px !important;
  max-height: 370px !important; }

.width-370,
.w-370 {
  width: 370px !important;
  min-width: 370px !important;
  max-width: 370px !important; }

.height-375,
.h-375 {
  height: 375px !important;
  min-height: 375px !important;
  max-height: 375px !important; }

.width-375,
.w-375 {
  width: 375px !important;
  min-width: 375px !important;
  max-width: 375px !important; }

.height-380,
.h-380 {
  height: 380px !important;
  min-height: 380px !important;
  max-height: 380px !important; }

.width-380,
.w-380 {
  width: 380px !important;
  min-width: 380px !important;
  max-width: 380px !important; }

.height-385,
.h-385 {
  height: 385px !important;
  min-height: 385px !important;
  max-height: 385px !important; }

.width-385,
.w-385 {
  width: 385px !important;
  min-width: 385px !important;
  max-width: 385px !important; }

.height-390,
.h-390 {
  height: 390px !important;
  min-height: 390px !important;
  max-height: 390px !important; }

.width-390,
.w-390 {
  width: 390px !important;
  min-width: 390px !important;
  max-width: 390px !important; }

.height-395,
.h-395 {
  height: 395px !important;
  min-height: 395px !important;
  max-height: 395px !important; }

.width-395,
.w-395 {
  width: 395px !important;
  min-width: 395px !important;
  max-width: 395px !important; }

.height-400,
.h-400 {
  height: 400px !important;
  min-height: 400px !important;
  max-height: 400px !important; }

.width-400,
.w-400 {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important; }

.height-405,
.h-405 {
  height: 405px !important;
  min-height: 405px !important;
  max-height: 405px !important; }

.width-405,
.w-405 {
  width: 405px !important;
  min-width: 405px !important;
  max-width: 405px !important; }

.height-410,
.h-410 {
  height: 410px !important;
  min-height: 410px !important;
  max-height: 410px !important; }

.width-410,
.w-410 {
  width: 410px !important;
  min-width: 410px !important;
  max-width: 410px !important; }

.height-415,
.h-415 {
  height: 415px !important;
  min-height: 415px !important;
  max-height: 415px !important; }

.width-415,
.w-415 {
  width: 415px !important;
  min-width: 415px !important;
  max-width: 415px !important; }

.height-420,
.h-420 {
  height: 420px !important;
  min-height: 420px !important;
  max-height: 420px !important; }

.width-420,
.w-420 {
  width: 420px !important;
  min-width: 420px !important;
  max-width: 420px !important; }

.height-425,
.h-425 {
  height: 425px !important;
  min-height: 425px !important;
  max-height: 425px !important; }

.width-425,
.w-425 {
  width: 425px !important;
  min-width: 425px !important;
  max-width: 425px !important; }

.height-430,
.h-430 {
  height: 430px !important;
  min-height: 430px !important;
  max-height: 430px !important; }

.width-430,
.w-430 {
  width: 430px !important;
  min-width: 430px !important;
  max-width: 430px !important; }

.height-435,
.h-435 {
  height: 435px !important;
  min-height: 435px !important;
  max-height: 435px !important; }

.width-435,
.w-435 {
  width: 435px !important;
  min-width: 435px !important;
  max-width: 435px !important; }

.height-440,
.h-440 {
  height: 440px !important;
  min-height: 440px !important;
  max-height: 440px !important; }

.width-440,
.w-440 {
  width: 440px !important;
  min-width: 440px !important;
  max-width: 440px !important; }

.height-445,
.h-445 {
  height: 445px !important;
  min-height: 445px !important;
  max-height: 445px !important; }

.width-445,
.w-445 {
  width: 445px !important;
  min-width: 445px !important;
  max-width: 445px !important; }

.height-450,
.h-450 {
  height: 450px !important;
  min-height: 450px !important;
  max-height: 450px !important; }

.width-450,
.w-450 {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important; }

.height-455,
.h-455 {
  height: 455px !important;
  min-height: 455px !important;
  max-height: 455px !important; }

.width-455,
.w-455 {
  width: 455px !important;
  min-width: 455px !important;
  max-width: 455px !important; }

.height-460,
.h-460 {
  height: 460px !important;
  min-height: 460px !important;
  max-height: 460px !important; }

.width-460,
.w-460 {
  width: 460px !important;
  min-width: 460px !important;
  max-width: 460px !important; }

.height-465,
.h-465 {
  height: 465px !important;
  min-height: 465px !important;
  max-height: 465px !important; }

.width-465,
.w-465 {
  width: 465px !important;
  min-width: 465px !important;
  max-width: 465px !important; }

.height-470,
.h-470 {
  height: 470px !important;
  min-height: 470px !important;
  max-height: 470px !important; }

.width-470,
.w-470 {
  width: 470px !important;
  min-width: 470px !important;
  max-width: 470px !important; }

.height-475,
.h-475 {
  height: 475px !important;
  min-height: 475px !important;
  max-height: 475px !important; }

.width-475,
.w-475 {
  width: 475px !important;
  min-width: 475px !important;
  max-width: 475px !important; }

.height-480,
.h-480 {
  height: 480px !important;
  min-height: 480px !important;
  max-height: 480px !important; }

.width-480,
.w-480 {
  width: 480px !important;
  min-width: 480px !important;
  max-width: 480px !important; }

.height-485,
.h-485 {
  height: 485px !important;
  min-height: 485px !important;
  max-height: 485px !important; }

.width-485,
.w-485 {
  width: 485px !important;
  min-width: 485px !important;
  max-width: 485px !important; }

.height-490,
.h-490 {
  height: 490px !important;
  min-height: 490px !important;
  max-height: 490px !important; }

.width-490,
.w-490 {
  width: 490px !important;
  min-width: 490px !important;
  max-width: 490px !important; }

.height-495,
.h-495 {
  height: 495px !important;
  min-height: 495px !important;
  max-height: 495px !important; }

.width-495,
.w-495 {
  width: 495px !important;
  min-width: 495px !important;
  max-width: 495px !important; }

.height-500,
.h-500 {
  height: 500px !important;
  min-height: 500px !important;
  max-height: 500px !important; }

.width-500,
.w-500 {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important; }

.height-auto {
  height: auto !important; }

.width-auto {
  width: auto !important; }

.height-percent-0,
.h-percent-0 {
  height: 0% !important;
  min-height: 0% !important;
  max-height: 0% !important; }

.width-percent-0,
.w-percent-0 {
  width: 0% !important;
  min-width: 0% !important;
  max-width: 0% !important; }

.height-percent-1,
.h-percent-1 {
  height: 1% !important;
  min-height: 1% !important;
  max-height: 1% !important; }

.width-percent-1,
.w-percent-1 {
  width: 1% !important;
  min-width: 1% !important;
  max-width: 1% !important; }

.height-percent-2,
.h-percent-2 {
  height: 2% !important;
  min-height: 2% !important;
  max-height: 2% !important; }

.width-percent-2,
.w-percent-2 {
  width: 2% !important;
  min-width: 2% !important;
  max-width: 2% !important; }

.height-percent-3,
.h-percent-3 {
  height: 3% !important;
  min-height: 3% !important;
  max-height: 3% !important; }

.width-percent-3,
.w-percent-3 {
  width: 3% !important;
  min-width: 3% !important;
  max-width: 3% !important; }

.height-percent-4,
.h-percent-4 {
  height: 4% !important;
  min-height: 4% !important;
  max-height: 4% !important; }

.width-percent-4,
.w-percent-4 {
  width: 4% !important;
  min-width: 4% !important;
  max-width: 4% !important; }

.height-percent-5,
.h-percent-5 {
  height: 5% !important;
  min-height: 5% !important;
  max-height: 5% !important; }

.width-percent-5,
.w-percent-5 {
  width: 5% !important;
  min-width: 5% !important;
  max-width: 5% !important; }

.height-percent-6,
.h-percent-6 {
  height: 6% !important;
  min-height: 6% !important;
  max-height: 6% !important; }

.width-percent-6,
.w-percent-6 {
  width: 6% !important;
  min-width: 6% !important;
  max-width: 6% !important; }

.height-percent-7,
.h-percent-7 {
  height: 7% !important;
  min-height: 7% !important;
  max-height: 7% !important; }

.width-percent-7,
.w-percent-7 {
  width: 7% !important;
  min-width: 7% !important;
  max-width: 7% !important; }

.height-percent-8,
.h-percent-8 {
  height: 8% !important;
  min-height: 8% !important;
  max-height: 8% !important; }

.width-percent-8,
.w-percent-8 {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important; }

.height-percent-9,
.h-percent-9 {
  height: 9% !important;
  min-height: 9% !important;
  max-height: 9% !important; }

.width-percent-9,
.w-percent-9 {
  width: 9% !important;
  min-width: 9% !important;
  max-width: 9% !important; }

.height-percent-10,
.h-percent-10 {
  height: 10% !important;
  min-height: 10% !important;
  max-height: 10% !important; }

.width-percent-10,
.w-percent-10 {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important; }

.height-percent-11,
.h-percent-11 {
  height: 11% !important;
  min-height: 11% !important;
  max-height: 11% !important; }

.width-percent-11,
.w-percent-11 {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important; }

.height-percent-12,
.h-percent-12 {
  height: 12% !important;
  min-height: 12% !important;
  max-height: 12% !important; }

.width-percent-12,
.w-percent-12 {
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important; }

.height-percent-13,
.h-percent-13 {
  height: 13% !important;
  min-height: 13% !important;
  max-height: 13% !important; }

.width-percent-13,
.w-percent-13 {
  width: 13% !important;
  min-width: 13% !important;
  max-width: 13% !important; }

.height-percent-14,
.h-percent-14 {
  height: 14% !important;
  min-height: 14% !important;
  max-height: 14% !important; }

.width-percent-14,
.w-percent-14 {
  width: 14% !important;
  min-width: 14% !important;
  max-width: 14% !important; }

.height-percent-15,
.h-percent-15 {
  height: 15% !important;
  min-height: 15% !important;
  max-height: 15% !important; }

.width-percent-15,
.w-percent-15 {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important; }

.height-percent-16,
.h-percent-16 {
  height: 16% !important;
  min-height: 16% !important;
  max-height: 16% !important; }

.width-percent-16,
.w-percent-16 {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important; }

.height-percent-17,
.h-percent-17 {
  height: 17% !important;
  min-height: 17% !important;
  max-height: 17% !important; }

.width-percent-17,
.w-percent-17 {
  width: 17% !important;
  min-width: 17% !important;
  max-width: 17% !important; }

.height-percent-18,
.h-percent-18 {
  height: 18% !important;
  min-height: 18% !important;
  max-height: 18% !important; }

.width-percent-18,
.w-percent-18 {
  width: 18% !important;
  min-width: 18% !important;
  max-width: 18% !important; }

.height-percent-19,
.h-percent-19 {
  height: 19% !important;
  min-height: 19% !important;
  max-height: 19% !important; }

.width-percent-19,
.w-percent-19 {
  width: 19% !important;
  min-width: 19% !important;
  max-width: 19% !important; }

.height-percent-20,
.h-percent-20 {
  height: 20% !important;
  min-height: 20% !important;
  max-height: 20% !important; }

.width-percent-20,
.w-percent-20 {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important; }

.height-percent-21,
.h-percent-21 {
  height: 21% !important;
  min-height: 21% !important;
  max-height: 21% !important; }

.width-percent-21,
.w-percent-21 {
  width: 21% !important;
  min-width: 21% !important;
  max-width: 21% !important; }

.height-percent-22,
.h-percent-22 {
  height: 22% !important;
  min-height: 22% !important;
  max-height: 22% !important; }

.width-percent-22,
.w-percent-22 {
  width: 22% !important;
  min-width: 22% !important;
  max-width: 22% !important; }

.height-percent-23,
.h-percent-23 {
  height: 23% !important;
  min-height: 23% !important;
  max-height: 23% !important; }

.width-percent-23,
.w-percent-23 {
  width: 23% !important;
  min-width: 23% !important;
  max-width: 23% !important; }

.height-percent-24,
.h-percent-24 {
  height: 24% !important;
  min-height: 24% !important;
  max-height: 24% !important; }

.width-percent-24,
.w-percent-24 {
  width: 24% !important;
  min-width: 24% !important;
  max-width: 24% !important; }

.height-percent-25,
.h-percent-25 {
  height: 25% !important;
  min-height: 25% !important;
  max-height: 25% !important; }

.width-percent-25,
.w-percent-25 {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important; }

.height-percent-26,
.h-percent-26 {
  height: 26% !important;
  min-height: 26% !important;
  max-height: 26% !important; }

.width-percent-26,
.w-percent-26 {
  width: 26% !important;
  min-width: 26% !important;
  max-width: 26% !important; }

.height-percent-27,
.h-percent-27 {
  height: 27% !important;
  min-height: 27% !important;
  max-height: 27% !important; }

.width-percent-27,
.w-percent-27 {
  width: 27% !important;
  min-width: 27% !important;
  max-width: 27% !important; }

.height-percent-28,
.h-percent-28 {
  height: 28% !important;
  min-height: 28% !important;
  max-height: 28% !important; }

.width-percent-28,
.w-percent-28 {
  width: 28% !important;
  min-width: 28% !important;
  max-width: 28% !important; }

.height-percent-29,
.h-percent-29 {
  height: 29% !important;
  min-height: 29% !important;
  max-height: 29% !important; }

.width-percent-29,
.w-percent-29 {
  width: 29% !important;
  min-width: 29% !important;
  max-width: 29% !important; }

.height-percent-30,
.h-percent-30 {
  height: 30% !important;
  min-height: 30% !important;
  max-height: 30% !important; }

.width-percent-30,
.w-percent-30 {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important; }

.height-percent-31,
.h-percent-31 {
  height: 31% !important;
  min-height: 31% !important;
  max-height: 31% !important; }

.width-percent-31,
.w-percent-31 {
  width: 31% !important;
  min-width: 31% !important;
  max-width: 31% !important; }

.height-percent-32,
.h-percent-32 {
  height: 32% !important;
  min-height: 32% !important;
  max-height: 32% !important; }

.width-percent-32,
.w-percent-32 {
  width: 32% !important;
  min-width: 32% !important;
  max-width: 32% !important; }

.height-percent-33,
.h-percent-33 {
  height: 33% !important;
  min-height: 33% !important;
  max-height: 33% !important; }

.width-percent-33,
.w-percent-33 {
  width: 33% !important;
  min-width: 33% !important;
  max-width: 33% !important; }

.height-percent-34,
.h-percent-34 {
  height: 34% !important;
  min-height: 34% !important;
  max-height: 34% !important; }

.width-percent-34,
.w-percent-34 {
  width: 34% !important;
  min-width: 34% !important;
  max-width: 34% !important; }

.height-percent-35,
.h-percent-35 {
  height: 35% !important;
  min-height: 35% !important;
  max-height: 35% !important; }

.width-percent-35,
.w-percent-35 {
  width: 35% !important;
  min-width: 35% !important;
  max-width: 35% !important; }

.height-percent-36,
.h-percent-36 {
  height: 36% !important;
  min-height: 36% !important;
  max-height: 36% !important; }

.width-percent-36,
.w-percent-36 {
  width: 36% !important;
  min-width: 36% !important;
  max-width: 36% !important; }

.height-percent-37,
.h-percent-37 {
  height: 37% !important;
  min-height: 37% !important;
  max-height: 37% !important; }

.width-percent-37,
.w-percent-37 {
  width: 37% !important;
  min-width: 37% !important;
  max-width: 37% !important; }

.height-percent-38,
.h-percent-38 {
  height: 38% !important;
  min-height: 38% !important;
  max-height: 38% !important; }

.width-percent-38,
.w-percent-38 {
  width: 38% !important;
  min-width: 38% !important;
  max-width: 38% !important; }

.height-percent-39,
.h-percent-39 {
  height: 39% !important;
  min-height: 39% !important;
  max-height: 39% !important; }

.width-percent-39,
.w-percent-39 {
  width: 39% !important;
  min-width: 39% !important;
  max-width: 39% !important; }

.height-percent-40,
.h-percent-40 {
  height: 40% !important;
  min-height: 40% !important;
  max-height: 40% !important; }

.width-percent-40,
.w-percent-40 {
  width: 40% !important;
  min-width: 40% !important;
  max-width: 40% !important; }

.height-percent-41,
.h-percent-41 {
  height: 41% !important;
  min-height: 41% !important;
  max-height: 41% !important; }

.width-percent-41,
.w-percent-41 {
  width: 41% !important;
  min-width: 41% !important;
  max-width: 41% !important; }

.height-percent-42,
.h-percent-42 {
  height: 42% !important;
  min-height: 42% !important;
  max-height: 42% !important; }

.width-percent-42,
.w-percent-42 {
  width: 42% !important;
  min-width: 42% !important;
  max-width: 42% !important; }

.height-percent-43,
.h-percent-43 {
  height: 43% !important;
  min-height: 43% !important;
  max-height: 43% !important; }

.width-percent-43,
.w-percent-43 {
  width: 43% !important;
  min-width: 43% !important;
  max-width: 43% !important; }

.height-percent-44,
.h-percent-44 {
  height: 44% !important;
  min-height: 44% !important;
  max-height: 44% !important; }

.width-percent-44,
.w-percent-44 {
  width: 44% !important;
  min-width: 44% !important;
  max-width: 44% !important; }

.height-percent-45,
.h-percent-45 {
  height: 45% !important;
  min-height: 45% !important;
  max-height: 45% !important; }

.width-percent-45,
.w-percent-45 {
  width: 45% !important;
  min-width: 45% !important;
  max-width: 45% !important; }

.height-percent-46,
.h-percent-46 {
  height: 46% !important;
  min-height: 46% !important;
  max-height: 46% !important; }

.width-percent-46,
.w-percent-46 {
  width: 46% !important;
  min-width: 46% !important;
  max-width: 46% !important; }

.height-percent-47,
.h-percent-47 {
  height: 47% !important;
  min-height: 47% !important;
  max-height: 47% !important; }

.width-percent-47,
.w-percent-47 {
  width: 47% !important;
  min-width: 47% !important;
  max-width: 47% !important; }

.height-percent-48,
.h-percent-48 {
  height: 48% !important;
  min-height: 48% !important;
  max-height: 48% !important; }

.width-percent-48,
.w-percent-48 {
  width: 48% !important;
  min-width: 48% !important;
  max-width: 48% !important; }

.height-percent-49,
.h-percent-49 {
  height: 49% !important;
  min-height: 49% !important;
  max-height: 49% !important; }

.width-percent-49,
.w-percent-49 {
  width: 49% !important;
  min-width: 49% !important;
  max-width: 49% !important; }

.height-percent-50,
.h-percent-50 {
  height: 50% !important;
  min-height: 50% !important;
  max-height: 50% !important; }

.width-percent-50,
.w-percent-50 {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important; }

.height-percent-51,
.h-percent-51 {
  height: 51% !important;
  min-height: 51% !important;
  max-height: 51% !important; }

.width-percent-51,
.w-percent-51 {
  width: 51% !important;
  min-width: 51% !important;
  max-width: 51% !important; }

.height-percent-52,
.h-percent-52 {
  height: 52% !important;
  min-height: 52% !important;
  max-height: 52% !important; }

.width-percent-52,
.w-percent-52 {
  width: 52% !important;
  min-width: 52% !important;
  max-width: 52% !important; }

.height-percent-53,
.h-percent-53 {
  height: 53% !important;
  min-height: 53% !important;
  max-height: 53% !important; }

.width-percent-53,
.w-percent-53 {
  width: 53% !important;
  min-width: 53% !important;
  max-width: 53% !important; }

.height-percent-54,
.h-percent-54 {
  height: 54% !important;
  min-height: 54% !important;
  max-height: 54% !important; }

.width-percent-54,
.w-percent-54 {
  width: 54% !important;
  min-width: 54% !important;
  max-width: 54% !important; }

.height-percent-55,
.h-percent-55 {
  height: 55% !important;
  min-height: 55% !important;
  max-height: 55% !important; }

.width-percent-55,
.w-percent-55 {
  width: 55% !important;
  min-width: 55% !important;
  max-width: 55% !important; }

.height-percent-56,
.h-percent-56 {
  height: 56% !important;
  min-height: 56% !important;
  max-height: 56% !important; }

.width-percent-56,
.w-percent-56 {
  width: 56% !important;
  min-width: 56% !important;
  max-width: 56% !important; }

.height-percent-57,
.h-percent-57 {
  height: 57% !important;
  min-height: 57% !important;
  max-height: 57% !important; }

.width-percent-57,
.w-percent-57 {
  width: 57% !important;
  min-width: 57% !important;
  max-width: 57% !important; }

.height-percent-58,
.h-percent-58 {
  height: 58% !important;
  min-height: 58% !important;
  max-height: 58% !important; }

.width-percent-58,
.w-percent-58 {
  width: 58% !important;
  min-width: 58% !important;
  max-width: 58% !important; }

.height-percent-59,
.h-percent-59 {
  height: 59% !important;
  min-height: 59% !important;
  max-height: 59% !important; }

.width-percent-59,
.w-percent-59 {
  width: 59% !important;
  min-width: 59% !important;
  max-width: 59% !important; }

.height-percent-60,
.h-percent-60 {
  height: 60% !important;
  min-height: 60% !important;
  max-height: 60% !important; }

.width-percent-60,
.w-percent-60 {
  width: 60% !important;
  min-width: 60% !important;
  max-width: 60% !important; }

.height-percent-61,
.h-percent-61 {
  height: 61% !important;
  min-height: 61% !important;
  max-height: 61% !important; }

.width-percent-61,
.w-percent-61 {
  width: 61% !important;
  min-width: 61% !important;
  max-width: 61% !important; }

.height-percent-62,
.h-percent-62 {
  height: 62% !important;
  min-height: 62% !important;
  max-height: 62% !important; }

.width-percent-62,
.w-percent-62 {
  width: 62% !important;
  min-width: 62% !important;
  max-width: 62% !important; }

.height-percent-63,
.h-percent-63 {
  height: 63% !important;
  min-height: 63% !important;
  max-height: 63% !important; }

.width-percent-63,
.w-percent-63 {
  width: 63% !important;
  min-width: 63% !important;
  max-width: 63% !important; }

.height-percent-64,
.h-percent-64 {
  height: 64% !important;
  min-height: 64% !important;
  max-height: 64% !important; }

.width-percent-64,
.w-percent-64 {
  width: 64% !important;
  min-width: 64% !important;
  max-width: 64% !important; }

.height-percent-65,
.h-percent-65 {
  height: 65% !important;
  min-height: 65% !important;
  max-height: 65% !important; }

.width-percent-65,
.w-percent-65 {
  width: 65% !important;
  min-width: 65% !important;
  max-width: 65% !important; }

.height-percent-66,
.h-percent-66 {
  height: 66% !important;
  min-height: 66% !important;
  max-height: 66% !important; }

.width-percent-66,
.w-percent-66 {
  width: 66% !important;
  min-width: 66% !important;
  max-width: 66% !important; }

.height-percent-67,
.h-percent-67 {
  height: 67% !important;
  min-height: 67% !important;
  max-height: 67% !important; }

.width-percent-67,
.w-percent-67 {
  width: 67% !important;
  min-width: 67% !important;
  max-width: 67% !important; }

.height-percent-68,
.h-percent-68 {
  height: 68% !important;
  min-height: 68% !important;
  max-height: 68% !important; }

.width-percent-68,
.w-percent-68 {
  width: 68% !important;
  min-width: 68% !important;
  max-width: 68% !important; }

.height-percent-69,
.h-percent-69 {
  height: 69% !important;
  min-height: 69% !important;
  max-height: 69% !important; }

.width-percent-69,
.w-percent-69 {
  width: 69% !important;
  min-width: 69% !important;
  max-width: 69% !important; }

.height-percent-70,
.h-percent-70 {
  height: 70% !important;
  min-height: 70% !important;
  max-height: 70% !important; }

.width-percent-70,
.w-percent-70 {
  width: 70% !important;
  min-width: 70% !important;
  max-width: 70% !important; }

.height-percent-71,
.h-percent-71 {
  height: 71% !important;
  min-height: 71% !important;
  max-height: 71% !important; }

.width-percent-71,
.w-percent-71 {
  width: 71% !important;
  min-width: 71% !important;
  max-width: 71% !important; }

.height-percent-72,
.h-percent-72 {
  height: 72% !important;
  min-height: 72% !important;
  max-height: 72% !important; }

.width-percent-72,
.w-percent-72 {
  width: 72% !important;
  min-width: 72% !important;
  max-width: 72% !important; }

.height-percent-73,
.h-percent-73 {
  height: 73% !important;
  min-height: 73% !important;
  max-height: 73% !important; }

.width-percent-73,
.w-percent-73 {
  width: 73% !important;
  min-width: 73% !important;
  max-width: 73% !important; }

.height-percent-74,
.h-percent-74 {
  height: 74% !important;
  min-height: 74% !important;
  max-height: 74% !important; }

.width-percent-74,
.w-percent-74 {
  width: 74% !important;
  min-width: 74% !important;
  max-width: 74% !important; }

.height-percent-75,
.h-percent-75 {
  height: 75% !important;
  min-height: 75% !important;
  max-height: 75% !important; }

.width-percent-75,
.w-percent-75 {
  width: 75% !important;
  min-width: 75% !important;
  max-width: 75% !important; }

.height-percent-76,
.h-percent-76 {
  height: 76% !important;
  min-height: 76% !important;
  max-height: 76% !important; }

.width-percent-76,
.w-percent-76 {
  width: 76% !important;
  min-width: 76% !important;
  max-width: 76% !important; }

.height-percent-77,
.h-percent-77 {
  height: 77% !important;
  min-height: 77% !important;
  max-height: 77% !important; }

.width-percent-77,
.w-percent-77 {
  width: 77% !important;
  min-width: 77% !important;
  max-width: 77% !important; }

.height-percent-78,
.h-percent-78 {
  height: 78% !important;
  min-height: 78% !important;
  max-height: 78% !important; }

.width-percent-78,
.w-percent-78 {
  width: 78% !important;
  min-width: 78% !important;
  max-width: 78% !important; }

.height-percent-79,
.h-percent-79 {
  height: 79% !important;
  min-height: 79% !important;
  max-height: 79% !important; }

.width-percent-79,
.w-percent-79 {
  width: 79% !important;
  min-width: 79% !important;
  max-width: 79% !important; }

.height-percent-80,
.h-percent-80 {
  height: 80% !important;
  min-height: 80% !important;
  max-height: 80% !important; }

.width-percent-80,
.w-percent-80 {
  width: 80% !important;
  min-width: 80% !important;
  max-width: 80% !important; }

.height-percent-81,
.h-percent-81 {
  height: 81% !important;
  min-height: 81% !important;
  max-height: 81% !important; }

.width-percent-81,
.w-percent-81 {
  width: 81% !important;
  min-width: 81% !important;
  max-width: 81% !important; }

.height-percent-82,
.h-percent-82 {
  height: 82% !important;
  min-height: 82% !important;
  max-height: 82% !important; }

.width-percent-82,
.w-percent-82 {
  width: 82% !important;
  min-width: 82% !important;
  max-width: 82% !important; }

.height-percent-83,
.h-percent-83 {
  height: 83% !important;
  min-height: 83% !important;
  max-height: 83% !important; }

.width-percent-83,
.w-percent-83 {
  width: 83% !important;
  min-width: 83% !important;
  max-width: 83% !important; }

.height-percent-84,
.h-percent-84 {
  height: 84% !important;
  min-height: 84% !important;
  max-height: 84% !important; }

.width-percent-84,
.w-percent-84 {
  width: 84% !important;
  min-width: 84% !important;
  max-width: 84% !important; }

.height-percent-85,
.h-percent-85 {
  height: 85% !important;
  min-height: 85% !important;
  max-height: 85% !important; }

.width-percent-85,
.w-percent-85 {
  width: 85% !important;
  min-width: 85% !important;
  max-width: 85% !important; }

.height-percent-86,
.h-percent-86 {
  height: 86% !important;
  min-height: 86% !important;
  max-height: 86% !important; }

.width-percent-86,
.w-percent-86 {
  width: 86% !important;
  min-width: 86% !important;
  max-width: 86% !important; }

.height-percent-87,
.h-percent-87 {
  height: 87% !important;
  min-height: 87% !important;
  max-height: 87% !important; }

.width-percent-87,
.w-percent-87 {
  width: 87% !important;
  min-width: 87% !important;
  max-width: 87% !important; }

.height-percent-88,
.h-percent-88 {
  height: 88% !important;
  min-height: 88% !important;
  max-height: 88% !important; }

.width-percent-88,
.w-percent-88 {
  width: 88% !important;
  min-width: 88% !important;
  max-width: 88% !important; }

.height-percent-89,
.h-percent-89 {
  height: 89% !important;
  min-height: 89% !important;
  max-height: 89% !important; }

.width-percent-89,
.w-percent-89 {
  width: 89% !important;
  min-width: 89% !important;
  max-width: 89% !important; }

.height-percent-90,
.h-percent-90 {
  height: 90% !important;
  min-height: 90% !important;
  max-height: 90% !important; }

.width-percent-90,
.w-percent-90 {
  width: 90% !important;
  min-width: 90% !important;
  max-width: 90% !important; }

.height-percent-91,
.h-percent-91 {
  height: 91% !important;
  min-height: 91% !important;
  max-height: 91% !important; }

.width-percent-91,
.w-percent-91 {
  width: 91% !important;
  min-width: 91% !important;
  max-width: 91% !important; }

.height-percent-92,
.h-percent-92 {
  height: 92% !important;
  min-height: 92% !important;
  max-height: 92% !important; }

.width-percent-92,
.w-percent-92 {
  width: 92% !important;
  min-width: 92% !important;
  max-width: 92% !important; }

.height-percent-93,
.h-percent-93 {
  height: 93% !important;
  min-height: 93% !important;
  max-height: 93% !important; }

.width-percent-93,
.w-percent-93 {
  width: 93% !important;
  min-width: 93% !important;
  max-width: 93% !important; }

.height-percent-94,
.h-percent-94 {
  height: 94% !important;
  min-height: 94% !important;
  max-height: 94% !important; }

.width-percent-94,
.w-percent-94 {
  width: 94% !important;
  min-width: 94% !important;
  max-width: 94% !important; }

.height-percent-95,
.h-percent-95 {
  height: 95% !important;
  min-height: 95% !important;
  max-height: 95% !important; }

.width-percent-95,
.w-percent-95 {
  width: 95% !important;
  min-width: 95% !important;
  max-width: 95% !important; }

.height-percent-96,
.h-percent-96 {
  height: 96% !important;
  min-height: 96% !important;
  max-height: 96% !important; }

.width-percent-96,
.w-percent-96 {
  width: 96% !important;
  min-width: 96% !important;
  max-width: 96% !important; }

.height-percent-97,
.h-percent-97 {
  height: 97% !important;
  min-height: 97% !important;
  max-height: 97% !important; }

.width-percent-97,
.w-percent-97 {
  width: 97% !important;
  min-width: 97% !important;
  max-width: 97% !important; }

.height-percent-98,
.h-percent-98 {
  height: 98% !important;
  min-height: 98% !important;
  max-height: 98% !important; }

.width-percent-98,
.w-percent-98 {
  width: 98% !important;
  min-width: 98% !important;
  max-width: 98% !important; }

.height-percent-99,
.h-percent-99 {
  height: 99% !important;
  min-height: 99% !important;
  max-height: 99% !important; }

.width-percent-99,
.w-percent-99 {
  width: 99% !important;
  min-width: 99% !important;
  max-width: 99% !important; }

.height-percent-100,
.h-percent-100 {
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important; }

.width-percent-100,
.w-percent-100 {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important; }

.height-8,
.h-8 {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important; }

.width-8,
.w-8 {
  width: 8px !important;
  min-width: 8px !important;
  max-width: 8px !important; }

.height-16,
.h-16 {
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important; }

.width-16,
.w-16 {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important; }

.height-24,
.h-24 {
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important; }

.width-24,
.w-24 {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important; }

.height-32,
.h-32 {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important; }

.width-32,
.w-32 {
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important; }

.height-48,
.h-48 {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important; }

.width-48,
.w-48 {
  width: 48px !important;
  min-width: 48px !important;
  max-width: 48px !important; }

.height-56,
.h-56 {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important; }

.width-56,
.w-56 {
  width: 56px !important;
  min-width: 56px !important;
  max-width: 56px !important; }

.height-64,
.h-64 {
  height: 64px !important;
  min-height: 64px !important;
  max-height: 64px !important; }

.width-64,
.w-64 {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important; }

.height-72,
.h-72 {
  height: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important; }

.width-72,
.w-72 {
  width: 72px !important;
  min-width: 72px !important;
  max-width: 72px !important; }

.height-88,
.h-88 {
  height: 88px !important;
  min-height: 88px !important;
  max-height: 88px !important; }

.width-88,
.w-88 {
  width: 88px !important;
  min-width: 88px !important;
  max-width: 88px !important; }

.height-96,
.h-96 {
  height: 96px !important;
  min-height: 96px !important;
  max-height: 96px !important; }

.width-96,
.w-96 {
  width: 96px !important;
  min-width: 96px !important;
  max-width: 96px !important; }

.height-104,
.h-104 {
  height: 104px !important;
  min-height: 104px !important;
  max-height: 104px !important; }

.width-104,
.w-104 {
  width: 104px !important;
  min-width: 104px !important;
  max-width: 104px !important; }

.height-112,
.h-112 {
  height: 112px !important;
  min-height: 112px !important;
  max-height: 112px !important; }

.width-112,
.w-112 {
  width: 112px !important;
  min-width: 112px !important;
  max-width: 112px !important; }

.height-128,
.h-128 {
  height: 128px !important;
  min-height: 128px !important;
  max-height: 128px !important; }

.width-128,
.w-128 {
  width: 128px !important;
  min-width: 128px !important;
  max-width: 128px !important; }

.height-136,
.h-136 {
  height: 136px !important;
  min-height: 136px !important;
  max-height: 136px !important; }

.width-136,
.w-136 {
  width: 136px !important;
  min-width: 136px !important;
  max-width: 136px !important; }

.height-144,
.h-144 {
  height: 144px !important;
  min-height: 144px !important;
  max-height: 144px !important; }

.width-144,
.w-144 {
  width: 144px !important;
  min-width: 144px !important;
  max-width: 144px !important; }

.height-152,
.h-152 {
  height: 152px !important;
  min-height: 152px !important;
  max-height: 152px !important; }

.width-152,
.w-152 {
  width: 152px !important;
  min-width: 152px !important;
  max-width: 152px !important; }

.height-168,
.h-168 {
  height: 168px !important;
  min-height: 168px !important;
  max-height: 168px !important; }

.width-168,
.w-168 {
  width: 168px !important;
  min-width: 168px !important;
  max-width: 168px !important; }

.height-176,
.h-176 {
  height: 176px !important;
  min-height: 176px !important;
  max-height: 176px !important; }

.width-176,
.w-176 {
  width: 176px !important;
  min-width: 176px !important;
  max-width: 176px !important; }

.height-184,
.h-184 {
  height: 184px !important;
  min-height: 184px !important;
  max-height: 184px !important; }

.width-184,
.w-184 {
  width: 184px !important;
  min-width: 184px !important;
  max-width: 184px !important; }

.height-192,
.h-192 {
  height: 192px !important;
  min-height: 192px !important;
  max-height: 192px !important; }

.width-192,
.w-192 {
  width: 192px !important;
  min-width: 192px !important;
  max-width: 192px !important; }

.height-208,
.h-208 {
  height: 208px !important;
  min-height: 208px !important;
  max-height: 208px !important; }

.width-208,
.w-208 {
  width: 208px !important;
  min-width: 208px !important;
  max-width: 208px !important; }

.height-216,
.h-216 {
  height: 216px !important;
  min-height: 216px !important;
  max-height: 216px !important; }

.width-216,
.w-216 {
  width: 216px !important;
  min-width: 216px !important;
  max-width: 216px !important; }

.height-224,
.h-224 {
  height: 224px !important;
  min-height: 224px !important;
  max-height: 224px !important; }

.width-224,
.w-224 {
  width: 224px !important;
  min-width: 224px !important;
  max-width: 224px !important; }

.height-232,
.h-232 {
  height: 232px !important;
  min-height: 232px !important;
  max-height: 232px !important; }

.width-232,
.w-232 {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important; }

.height-248,
.h-248 {
  height: 248px !important;
  min-height: 248px !important;
  max-height: 248px !important; }

.width-248,
.w-248 {
  width: 248px !important;
  min-width: 248px !important;
  max-width: 248px !important; }

.height-256,
.h-256 {
  height: 256px !important;
  min-height: 256px !important;
  max-height: 256px !important; }

.width-256,
.w-256 {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important; }

.height-264,
.h-264 {
  height: 264px !important;
  min-height: 264px !important;
  max-height: 264px !important; }

.width-264,
.w-264 {
  width: 264px !important;
  min-width: 264px !important;
  max-width: 264px !important; }

.height-272,
.h-272 {
  height: 272px !important;
  min-height: 272px !important;
  max-height: 272px !important; }

.width-272,
.w-272 {
  width: 272px !important;
  min-width: 272px !important;
  max-width: 272px !important; }

.height-288,
.h-288 {
  height: 288px !important;
  min-height: 288px !important;
  max-height: 288px !important; }

.width-288,
.w-288 {
  width: 288px !important;
  min-width: 288px !important;
  max-width: 288px !important; }

.height-296,
.h-296 {
  height: 296px !important;
  min-height: 296px !important;
  max-height: 296px !important; }

.width-296,
.w-296 {
  width: 296px !important;
  min-width: 296px !important;
  max-width: 296px !important; }

.height-304,
.h-304 {
  height: 304px !important;
  min-height: 304px !important;
  max-height: 304px !important; }

.width-304,
.w-304 {
  width: 304px !important;
  min-width: 304px !important;
  max-width: 304px !important; }

.height-312,
.h-312 {
  height: 312px !important;
  min-height: 312px !important;
  max-height: 312px !important; }

.width-312,
.w-312 {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important; }

.height-328,
.h-328 {
  height: 328px !important;
  min-height: 328px !important;
  max-height: 328px !important; }

.width-328,
.w-328 {
  width: 328px !important;
  min-width: 328px !important;
  max-width: 328px !important; }

.height-336,
.h-336 {
  height: 336px !important;
  min-height: 336px !important;
  max-height: 336px !important; }

.width-336,
.w-336 {
  width: 336px !important;
  min-width: 336px !important;
  max-width: 336px !important; }

.height-344,
.h-344 {
  height: 344px !important;
  min-height: 344px !important;
  max-height: 344px !important; }

.width-344,
.w-344 {
  width: 344px !important;
  min-width: 344px !important;
  max-width: 344px !important; }

.height-352,
.h-352 {
  height: 352px !important;
  min-height: 352px !important;
  max-height: 352px !important; }

.width-352,
.w-352 {
  width: 352px !important;
  min-width: 352px !important;
  max-width: 352px !important; }

.height-368,
.h-368 {
  height: 368px !important;
  min-height: 368px !important;
  max-height: 368px !important; }

.width-368,
.w-368 {
  width: 368px !important;
  min-width: 368px !important;
  max-width: 368px !important; }

.height-376,
.h-376 {
  height: 376px !important;
  min-height: 376px !important;
  max-height: 376px !important; }

.width-376,
.w-376 {
  width: 376px !important;
  min-width: 376px !important;
  max-width: 376px !important; }

.height-384,
.h-384 {
  height: 384px !important;
  min-height: 384px !important;
  max-height: 384px !important; }

.width-384,
.w-384 {
  width: 384px !important;
  min-width: 384px !important;
  max-width: 384px !important; }

.height-392,
.h-392 {
  height: 392px !important;
  min-height: 392px !important;
  max-height: 392px !important; }

.width-392,
.w-392 {
  width: 392px !important;
  min-width: 392px !important;
  max-width: 392px !important; }

.height-408,
.h-408 {
  height: 408px !important;
  min-height: 408px !important;
  max-height: 408px !important; }

.width-408,
.w-408 {
  width: 408px !important;
  min-width: 408px !important;
  max-width: 408px !important; }

.height-416,
.h-416 {
  height: 416px !important;
  min-height: 416px !important;
  max-height: 416px !important; }

.width-416,
.w-416 {
  width: 416px !important;
  min-width: 416px !important;
  max-width: 416px !important; }

.height-424,
.h-424 {
  height: 424px !important;
  min-height: 424px !important;
  max-height: 424px !important; }

.width-424,
.w-424 {
  width: 424px !important;
  min-width: 424px !important;
  max-width: 424px !important; }

.height-432,
.h-432 {
  height: 432px !important;
  min-height: 432px !important;
  max-height: 432px !important; }

.width-432,
.w-432 {
  width: 432px !important;
  min-width: 432px !important;
  max-width: 432px !important; }

.height-448,
.h-448 {
  height: 448px !important;
  min-height: 448px !important;
  max-height: 448px !important; }

.width-448,
.w-448 {
  width: 448px !important;
  min-width: 448px !important;
  max-width: 448px !important; }

.height-456,
.h-456 {
  height: 456px !important;
  min-height: 456px !important;
  max-height: 456px !important; }

.width-456,
.w-456 {
  width: 456px !important;
  min-width: 456px !important;
  max-width: 456px !important; }

.height-464,
.h-464 {
  height: 464px !important;
  min-height: 464px !important;
  max-height: 464px !important; }

.width-464,
.w-464 {
  width: 464px !important;
  min-width: 464px !important;
  max-width: 464px !important; }

.height-472,
.h-472 {
  height: 472px !important;
  min-height: 472px !important;
  max-height: 472px !important; }

.width-472,
.w-472 {
  width: 472px !important;
  min-width: 472px !important;
  max-width: 472px !important; }

.height-488,
.h-488 {
  height: 488px !important;
  min-height: 488px !important;
  max-height: 488px !important; }

.width-488,
.w-488 {
  width: 488px !important;
  min-width: 488px !important;
  max-width: 488px !important; }

.height-496,
.h-496 {
  height: 496px !important;
  min-height: 496px !important;
  max-height: 496px !important; }

.width-496,
.w-496 {
  width: 496px !important;
  min-width: 496px !important;
  max-width: 496px !important; }

.height-504,
.h-504 {
  height: 504px !important;
  min-height: 504px !important;
  max-height: 504px !important; }

.width-504,
.w-504 {
  width: 504px !important;
  min-width: 504px !important;
  max-width: 504px !important; }

.height-512,
.h-512 {
  height: 512px !important;
  min-height: 512px !important;
  max-height: 512px !important; }

.width-512,
.w-512 {
  width: 512px !important;
  min-width: 512px !important;
  max-width: 512px !important; }

.height-528,
.h-528 {
  height: 528px !important;
  min-height: 528px !important;
  max-height: 528px !important; }

.width-528,
.w-528 {
  width: 528px !important;
  min-width: 528px !important;
  max-width: 528px !important; }

.height-536,
.h-536 {
  height: 536px !important;
  min-height: 536px !important;
  max-height: 536px !important; }

.width-536,
.w-536 {
  width: 536px !important;
  min-width: 536px !important;
  max-width: 536px !important; }

.height-544,
.h-544 {
  height: 544px !important;
  min-height: 544px !important;
  max-height: 544px !important; }

.width-544,
.w-544 {
  width: 544px !important;
  min-width: 544px !important;
  max-width: 544px !important; }

.height-552,
.h-552 {
  height: 552px !important;
  min-height: 552px !important;
  max-height: 552px !important; }

.width-552,
.w-552 {
  width: 552px !important;
  min-width: 552px !important;
  max-width: 552px !important; }

.height-568,
.h-568 {
  height: 568px !important;
  min-height: 568px !important;
  max-height: 568px !important; }

.width-568,
.w-568 {
  width: 568px !important;
  min-width: 568px !important;
  max-width: 568px !important; }

.height-576,
.h-576 {
  height: 576px !important;
  min-height: 576px !important;
  max-height: 576px !important; }

.width-576,
.w-576 {
  width: 576px !important;
  min-width: 576px !important;
  max-width: 576px !important; }

.height-584,
.h-584 {
  height: 584px !important;
  min-height: 584px !important;
  max-height: 584px !important; }

.width-584,
.w-584 {
  width: 584px !important;
  min-width: 584px !important;
  max-width: 584px !important; }

.height-592,
.h-592 {
  height: 592px !important;
  min-height: 592px !important;
  max-height: 592px !important; }

.width-592,
.w-592 {
  width: 592px !important;
  min-width: 592px !important;
  max-width: 592px !important; }

.height-608,
.h-608 {
  height: 608px !important;
  min-height: 608px !important;
  max-height: 608px !important; }

.width-608,
.w-608 {
  width: 608px !important;
  min-width: 608px !important;
  max-width: 608px !important; }

.height-616,
.h-616 {
  height: 616px !important;
  min-height: 616px !important;
  max-height: 616px !important; }

.width-616,
.w-616 {
  width: 616px !important;
  min-width: 616px !important;
  max-width: 616px !important; }

.height-624,
.h-624 {
  height: 624px !important;
  min-height: 624px !important;
  max-height: 624px !important; }

.width-624,
.w-624 {
  width: 624px !important;
  min-width: 624px !important;
  max-width: 624px !important; }

.height-632,
.h-632 {
  height: 632px !important;
  min-height: 632px !important;
  max-height: 632px !important; }

.width-632,
.w-632 {
  width: 632px !important;
  min-width: 632px !important;
  max-width: 632px !important; }

.height-648,
.h-648 {
  height: 648px !important;
  min-height: 648px !important;
  max-height: 648px !important; }

.width-648,
.w-648 {
  width: 648px !important;
  min-width: 648px !important;
  max-width: 648px !important; }

.height-656,
.h-656 {
  height: 656px !important;
  min-height: 656px !important;
  max-height: 656px !important; }

.width-656,
.w-656 {
  width: 656px !important;
  min-width: 656px !important;
  max-width: 656px !important; }

.height-664,
.h-664 {
  height: 664px !important;
  min-height: 664px !important;
  max-height: 664px !important; }

.width-664,
.w-664 {
  width: 664px !important;
  min-width: 664px !important;
  max-width: 664px !important; }

.height-672,
.h-672 {
  height: 672px !important;
  min-height: 672px !important;
  max-height: 672px !important; }

.width-672,
.w-672 {
  width: 672px !important;
  min-width: 672px !important;
  max-width: 672px !important; }

.height-688,
.h-688 {
  height: 688px !important;
  min-height: 688px !important;
  max-height: 688px !important; }

.width-688,
.w-688 {
  width: 688px !important;
  min-width: 688px !important;
  max-width: 688px !important; }

.height-696,
.h-696 {
  height: 696px !important;
  min-height: 696px !important;
  max-height: 696px !important; }

.width-696,
.w-696 {
  width: 696px !important;
  min-width: 696px !important;
  max-width: 696px !important; }

.height-704,
.h-704 {
  height: 704px !important;
  min-height: 704px !important;
  max-height: 704px !important; }

.width-704,
.w-704 {
  width: 704px !important;
  min-width: 704px !important;
  max-width: 704px !important; }

.height-712,
.h-712 {
  height: 712px !important;
  min-height: 712px !important;
  max-height: 712px !important; }

.width-712,
.w-712 {
  width: 712px !important;
  min-width: 712px !important;
  max-width: 712px !important; }

.height-728,
.h-728 {
  height: 728px !important;
  min-height: 728px !important;
  max-height: 728px !important; }

.width-728,
.w-728 {
  width: 728px !important;
  min-width: 728px !important;
  max-width: 728px !important; }

.height-736,
.h-736 {
  height: 736px !important;
  min-height: 736px !important;
  max-height: 736px !important; }

.width-736,
.w-736 {
  width: 736px !important;
  min-width: 736px !important;
  max-width: 736px !important; }

.height-744,
.h-744 {
  height: 744px !important;
  min-height: 744px !important;
  max-height: 744px !important; }

.width-744,
.w-744 {
  width: 744px !important;
  min-width: 744px !important;
  max-width: 744px !important; }

.height-752,
.h-752 {
  height: 752px !important;
  min-height: 752px !important;
  max-height: 752px !important; }

.width-752,
.w-752 {
  width: 752px !important;
  min-width: 752px !important;
  max-width: 752px !important; }

.height-768,
.h-768 {
  height: 768px !important;
  min-height: 768px !important;
  max-height: 768px !important; }

.width-768,
.w-768 {
  width: 768px !important;
  min-width: 768px !important;
  max-width: 768px !important; }

.height-776,
.h-776 {
  height: 776px !important;
  min-height: 776px !important;
  max-height: 776px !important; }

.width-776,
.w-776 {
  width: 776px !important;
  min-width: 776px !important;
  max-width: 776px !important; }

.height-784,
.h-784 {
  height: 784px !important;
  min-height: 784px !important;
  max-height: 784px !important; }

.width-784,
.w-784 {
  width: 784px !important;
  min-width: 784px !important;
  max-width: 784px !important; }

.height-792,
.h-792 {
  height: 792px !important;
  min-height: 792px !important;
  max-height: 792px !important; }

.width-792,
.w-792 {
  width: 792px !important;
  min-width: 792px !important;
  max-width: 792px !important; }

.opacity-0-0 {
  opacity: 0 !important; }

.opacity-0-1 {
  opacity: 0.1 !important; }

.opacity-0-2 {
  opacity: 0.2 !important; }

.opacity-0-3 {
  opacity: 0.3 !important; }

.opacity-0-4 {
  opacity: 0.4 !important; }

.opacity-0-5 {
  opacity: 0.5 !important; }

.opacity-0-6 {
  opacity: 0.6 !important; }

.opacity-0-7 {
  opacity: 0.7 !important; }

.opacity-0-8 {
  opacity: 0.8 !important; }

.opacity-0-9 {
  opacity: 0.9 !important; }

.opacity-0-10 {
  opacity: 1 !important; }

.border,
.b {
  border: 1px solid rgba(0, 0, 0, 0.12); }

.border-top,
.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.border-right,
.br {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-bottom,
.bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.border-left,
.bl {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.border-horizontal,
.bh {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.border-vertical,
.bv {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.brad-1 {
  border-radius: 1px; }

.brad-2 {
  border-radius: 2px; }

.brad-3 {
  border-radius: 3px; }

.brad-4 {
  border-radius: 4px; }

.brad-5 {
  border-radius: 5px; }

.brad-6 {
  border-radius: 6px; }

.brad-7 {
  border-radius: 7px; }

.brad-8 {
  border-radius: 8px; }

.brad-9 {
  border-radius: 9px; }

.brad-10 {
  border-radius: 10px; }

.brad-11 {
  border-radius: 11px; }

.brad-12 {
  border-radius: 12px; }

md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px; }
  md-icon[md-font-icon].s4,
  i.s4 {
    font-size: 4px !important;
    width: 4px !important;
    height: 4px !important;
    min-width: 4px !important;
    min-height: 4px !important;
    line-height: 4px !important; }
  md-icon[md-font-icon].s6,
  i.s6 {
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    min-width: 6px !important;
    min-height: 6px !important;
    line-height: 6px !important; }
  md-icon[md-font-icon].s8,
  i.s8 {
    font-size: 8px !important;
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    min-height: 8px !important;
    line-height: 8px !important; }
  md-icon[md-font-icon].s10,
  i.s10 {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
    min-width: 10px !important;
    min-height: 10px !important;
    line-height: 10px !important; }
  md-icon[md-font-icon].s12,
  i.s12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    line-height: 12px !important; }
  md-icon[md-font-icon].s14,
  i.s14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    line-height: 14px !important; }
  md-icon[md-font-icon].s16,
  i.s16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    line-height: 16px !important; }
  md-icon[md-font-icon].s18,
  i.s18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important; }
  md-icon[md-font-icon].s20,
  i.s20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important; }
  md-icon[md-font-icon].s22,
  i.s22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    line-height: 22px !important; }
  md-icon[md-font-icon].s24,
  i.s24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important; }
  md-icon[md-font-icon].s26,
  i.s26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    min-width: 26px !important;
    min-height: 26px !important;
    line-height: 26px !important; }
  md-icon[md-font-icon].s28,
  i.s28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important; }
  md-icon[md-font-icon].s30,
  i.s30 {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important; }
  md-icon[md-font-icon].s32,
  i.s32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    line-height: 32px !important; }
  md-icon[md-font-icon].s34,
  i.s34 {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    line-height: 34px !important; }
  md-icon[md-font-icon].s36,
  i.s36 {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    line-height: 36px !important; }
  md-icon[md-font-icon].s38,
  i.s38 {
    font-size: 38px !important;
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important; }
  md-icon[md-font-icon].s40,
  i.s40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important; }
  md-icon[md-font-icon].s42,
  i.s42 {
    font-size: 42px !important;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    line-height: 42px !important; }
  md-icon[md-font-icon].s44,
  i.s44 {
    font-size: 44px !important;
    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
    line-height: 44px !important; }
  md-icon[md-font-icon].s46,
  i.s46 {
    font-size: 46px !important;
    width: 46px !important;
    height: 46px !important;
    min-width: 46px !important;
    min-height: 46px !important;
    line-height: 46px !important; }
  md-icon[md-font-icon].s48,
  i.s48 {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    line-height: 48px !important; }
  md-icon[md-font-icon].s50,
  i.s50 {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    line-height: 50px !important; }
  md-icon[md-font-icon].s52,
  i.s52 {
    font-size: 52px !important;
    width: 52px !important;
    height: 52px !important;
    min-width: 52px !important;
    min-height: 52px !important;
    line-height: 52px !important; }
  md-icon[md-font-icon].s54,
  i.s54 {
    font-size: 54px !important;
    width: 54px !important;
    height: 54px !important;
    min-width: 54px !important;
    min-height: 54px !important;
    line-height: 54px !important; }
  md-icon[md-font-icon].s56,
  i.s56 {
    font-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    line-height: 56px !important; }
  md-icon[md-font-icon].s58,
  i.s58 {
    font-size: 58px !important;
    width: 58px !important;
    height: 58px !important;
    min-width: 58px !important;
    min-height: 58px !important;
    line-height: 58px !important; }
  md-icon[md-font-icon].s60,
  i.s60 {
    font-size: 60px !important;
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;
    min-height: 60px !important;
    line-height: 60px !important; }
  md-icon[md-font-icon].s62,
  i.s62 {
    font-size: 62px !important;
    width: 62px !important;
    height: 62px !important;
    min-width: 62px !important;
    min-height: 62px !important;
    line-height: 62px !important; }
  md-icon[md-font-icon].s64,
  i.s64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    line-height: 64px !important; }
  md-icon[md-font-icon].s66,
  i.s66 {
    font-size: 66px !important;
    width: 66px !important;
    height: 66px !important;
    min-width: 66px !important;
    min-height: 66px !important;
    line-height: 66px !important; }
  md-icon[md-font-icon].s68,
  i.s68 {
    font-size: 68px !important;
    width: 68px !important;
    height: 68px !important;
    min-width: 68px !important;
    min-height: 68px !important;
    line-height: 68px !important; }
  md-icon[md-font-icon].s70,
  i.s70 {
    font-size: 70px !important;
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    line-height: 70px !important; }
  md-icon[md-font-icon].s72,
  i.s72 {
    font-size: 72px !important;
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    line-height: 72px !important; }
  md-icon[md-font-icon].s74,
  i.s74 {
    font-size: 74px !important;
    width: 74px !important;
    height: 74px !important;
    min-width: 74px !important;
    min-height: 74px !important;
    line-height: 74px !important; }
  md-icon[md-font-icon].s76,
  i.s76 {
    font-size: 76px !important;
    width: 76px !important;
    height: 76px !important;
    min-width: 76px !important;
    min-height: 76px !important;
    line-height: 76px !important; }
  md-icon[md-font-icon].s78,
  i.s78 {
    font-size: 78px !important;
    width: 78px !important;
    height: 78px !important;
    min-width: 78px !important;
    min-height: 78px !important;
    line-height: 78px !important; }
  md-icon[md-font-icon].s80,
  i.s80 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    line-height: 80px !important; }
  md-icon[md-font-icon].s82,
  i.s82 {
    font-size: 82px !important;
    width: 82px !important;
    height: 82px !important;
    min-width: 82px !important;
    min-height: 82px !important;
    line-height: 82px !important; }
  md-icon[md-font-icon].s84,
  i.s84 {
    font-size: 84px !important;
    width: 84px !important;
    height: 84px !important;
    min-width: 84px !important;
    min-height: 84px !important;
    line-height: 84px !important; }
  md-icon[md-font-icon].s86,
  i.s86 {
    font-size: 86px !important;
    width: 86px !important;
    height: 86px !important;
    min-width: 86px !important;
    min-height: 86px !important;
    line-height: 86px !important; }
  md-icon[md-font-icon].s88,
  i.s88 {
    font-size: 88px !important;
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    line-height: 88px !important; }
  md-icon[md-font-icon].s90,
  i.s90 {
    font-size: 90px !important;
    width: 90px !important;
    height: 90px !important;
    min-width: 90px !important;
    min-height: 90px !important;
    line-height: 90px !important; }
  md-icon[md-font-icon].s92,
  i.s92 {
    font-size: 92px !important;
    width: 92px !important;
    height: 92px !important;
    min-width: 92px !important;
    min-height: 92px !important;
    line-height: 92px !important; }
  md-icon[md-font-icon].s94,
  i.s94 {
    font-size: 94px !important;
    width: 94px !important;
    height: 94px !important;
    min-width: 94px !important;
    min-height: 94px !important;
    line-height: 94px !important; }
  md-icon[md-font-icon].s96,
  i.s96 {
    font-size: 96px !important;
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    line-height: 96px !important; }
  md-icon[md-font-icon].s98,
  i.s98 {
    font-size: 98px !important;
    width: 98px !important;
    height: 98px !important;
    min-width: 98px !important;
    min-height: 98px !important;
    line-height: 98px !important; }
  md-icon[md-font-icon].s100,
  i.s100 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    line-height: 100px !important; }
  md-icon[md-font-icon].s102,
  i.s102 {
    font-size: 102px !important;
    width: 102px !important;
    height: 102px !important;
    min-width: 102px !important;
    min-height: 102px !important;
    line-height: 102px !important; }
  md-icon[md-font-icon].s104,
  i.s104 {
    font-size: 104px !important;
    width: 104px !important;
    height: 104px !important;
    min-width: 104px !important;
    min-height: 104px !important;
    line-height: 104px !important; }
  md-icon[md-font-icon].s106,
  i.s106 {
    font-size: 106px !important;
    width: 106px !important;
    height: 106px !important;
    min-width: 106px !important;
    min-height: 106px !important;
    line-height: 106px !important; }
  md-icon[md-font-icon].s108,
  i.s108 {
    font-size: 108px !important;
    width: 108px !important;
    height: 108px !important;
    min-width: 108px !important;
    min-height: 108px !important;
    line-height: 108px !important; }
  md-icon[md-font-icon].s110,
  i.s110 {
    font-size: 110px !important;
    width: 110px !important;
    height: 110px !important;
    min-width: 110px !important;
    min-height: 110px !important;
    line-height: 110px !important; }
  md-icon[md-font-icon].s112,
  i.s112 {
    font-size: 112px !important;
    width: 112px !important;
    height: 112px !important;
    min-width: 112px !important;
    min-height: 112px !important;
    line-height: 112px !important; }
  md-icon[md-font-icon].s114,
  i.s114 {
    font-size: 114px !important;
    width: 114px !important;
    height: 114px !important;
    min-width: 114px !important;
    min-height: 114px !important;
    line-height: 114px !important; }
  md-icon[md-font-icon].s116,
  i.s116 {
    font-size: 116px !important;
    width: 116px !important;
    height: 116px !important;
    min-width: 116px !important;
    min-height: 116px !important;
    line-height: 116px !important; }
  md-icon[md-font-icon].s118,
  i.s118 {
    font-size: 118px !important;
    width: 118px !important;
    height: 118px !important;
    min-width: 118px !important;
    min-height: 118px !important;
    line-height: 118px !important; }
  md-icon[md-font-icon].s120,
  i.s120 {
    font-size: 120px !important;
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
    min-height: 120px !important;
    line-height: 120px !important; }
  md-icon[md-font-icon].s122,
  i.s122 {
    font-size: 122px !important;
    width: 122px !important;
    height: 122px !important;
    min-width: 122px !important;
    min-height: 122px !important;
    line-height: 122px !important; }
  md-icon[md-font-icon].s124,
  i.s124 {
    font-size: 124px !important;
    width: 124px !important;
    height: 124px !important;
    min-width: 124px !important;
    min-height: 124px !important;
    line-height: 124px !important; }
  md-icon[md-font-icon].s126,
  i.s126 {
    font-size: 126px !important;
    width: 126px !important;
    height: 126px !important;
    min-width: 126px !important;
    min-height: 126px !important;
    line-height: 126px !important; }
  md-icon[md-font-icon].s128,
  i.s128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    min-width: 128px !important;
    min-height: 128px !important;
    line-height: 128px !important; }
  md-icon[md-font-icon].s130,
  i.s130 {
    font-size: 130px !important;
    width: 130px !important;
    height: 130px !important;
    min-width: 130px !important;
    min-height: 130px !important;
    line-height: 130px !important; }
  md-icon[md-font-icon].s132,
  i.s132 {
    font-size: 132px !important;
    width: 132px !important;
    height: 132px !important;
    min-width: 132px !important;
    min-height: 132px !important;
    line-height: 132px !important; }
  md-icon[md-font-icon].s134,
  i.s134 {
    font-size: 134px !important;
    width: 134px !important;
    height: 134px !important;
    min-width: 134px !important;
    min-height: 134px !important;
    line-height: 134px !important; }
  md-icon[md-font-icon].s136,
  i.s136 {
    font-size: 136px !important;
    width: 136px !important;
    height: 136px !important;
    min-width: 136px !important;
    min-height: 136px !important;
    line-height: 136px !important; }
  md-icon[md-font-icon].s138,
  i.s138 {
    font-size: 138px !important;
    width: 138px !important;
    height: 138px !important;
    min-width: 138px !important;
    min-height: 138px !important;
    line-height: 138px !important; }
  md-icon[md-font-icon].s140,
  i.s140 {
    font-size: 140px !important;
    width: 140px !important;
    height: 140px !important;
    min-width: 140px !important;
    min-height: 140px !important;
    line-height: 140px !important; }
  md-icon[md-font-icon].s142,
  i.s142 {
    font-size: 142px !important;
    width: 142px !important;
    height: 142px !important;
    min-width: 142px !important;
    min-height: 142px !important;
    line-height: 142px !important; }
  md-icon[md-font-icon].s144,
  i.s144 {
    font-size: 144px !important;
    width: 144px !important;
    height: 144px !important;
    min-width: 144px !important;
    min-height: 144px !important;
    line-height: 144px !important; }
  md-icon[md-font-icon].s146,
  i.s146 {
    font-size: 146px !important;
    width: 146px !important;
    height: 146px !important;
    min-width: 146px !important;
    min-height: 146px !important;
    line-height: 146px !important; }
  md-icon[md-font-icon].s148,
  i.s148 {
    font-size: 148px !important;
    width: 148px !important;
    height: 148px !important;
    min-width: 148px !important;
    min-height: 148px !important;
    line-height: 148px !important; }
  md-icon[md-font-icon].s150,
  i.s150 {
    font-size: 150px !important;
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
    min-height: 150px !important;
    line-height: 150px !important; }
  md-icon[md-font-icon].s152,
  i.s152 {
    font-size: 152px !important;
    width: 152px !important;
    height: 152px !important;
    min-width: 152px !important;
    min-height: 152px !important;
    line-height: 152px !important; }
  md-icon[md-font-icon].s154,
  i.s154 {
    font-size: 154px !important;
    width: 154px !important;
    height: 154px !important;
    min-width: 154px !important;
    min-height: 154px !important;
    line-height: 154px !important; }
  md-icon[md-font-icon].s156,
  i.s156 {
    font-size: 156px !important;
    width: 156px !important;
    height: 156px !important;
    min-width: 156px !important;
    min-height: 156px !important;
    line-height: 156px !important; }
  md-icon[md-font-icon].s158,
  i.s158 {
    font-size: 158px !important;
    width: 158px !important;
    height: 158px !important;
    min-width: 158px !important;
    min-height: 158px !important;
    line-height: 158px !important; }
  md-icon[md-font-icon].s160,
  i.s160 {
    font-size: 160px !important;
    width: 160px !important;
    height: 160px !important;
    min-width: 160px !important;
    min-height: 160px !important;
    line-height: 160px !important; }
  md-icon[md-font-icon].s162,
  i.s162 {
    font-size: 162px !important;
    width: 162px !important;
    height: 162px !important;
    min-width: 162px !important;
    min-height: 162px !important;
    line-height: 162px !important; }
  md-icon[md-font-icon].s164,
  i.s164 {
    font-size: 164px !important;
    width: 164px !important;
    height: 164px !important;
    min-width: 164px !important;
    min-height: 164px !important;
    line-height: 164px !important; }
  md-icon[md-font-icon].s166,
  i.s166 {
    font-size: 166px !important;
    width: 166px !important;
    height: 166px !important;
    min-width: 166px !important;
    min-height: 166px !important;
    line-height: 166px !important; }
  md-icon[md-font-icon].s168,
  i.s168 {
    font-size: 168px !important;
    width: 168px !important;
    height: 168px !important;
    min-width: 168px !important;
    min-height: 168px !important;
    line-height: 168px !important; }
  md-icon[md-font-icon].s170,
  i.s170 {
    font-size: 170px !important;
    width: 170px !important;
    height: 170px !important;
    min-width: 170px !important;
    min-height: 170px !important;
    line-height: 170px !important; }
  md-icon[md-font-icon].s172,
  i.s172 {
    font-size: 172px !important;
    width: 172px !important;
    height: 172px !important;
    min-width: 172px !important;
    min-height: 172px !important;
    line-height: 172px !important; }
  md-icon[md-font-icon].s174,
  i.s174 {
    font-size: 174px !important;
    width: 174px !important;
    height: 174px !important;
    min-width: 174px !important;
    min-height: 174px !important;
    line-height: 174px !important; }
  md-icon[md-font-icon].s176,
  i.s176 {
    font-size: 176px !important;
    width: 176px !important;
    height: 176px !important;
    min-width: 176px !important;
    min-height: 176px !important;
    line-height: 176px !important; }
  md-icon[md-font-icon].s178,
  i.s178 {
    font-size: 178px !important;
    width: 178px !important;
    height: 178px !important;
    min-width: 178px !important;
    min-height: 178px !important;
    line-height: 178px !important; }
  md-icon[md-font-icon].s180,
  i.s180 {
    font-size: 180px !important;
    width: 180px !important;
    height: 180px !important;
    min-width: 180px !important;
    min-height: 180px !important;
    line-height: 180px !important; }
  md-icon[md-font-icon].s182,
  i.s182 {
    font-size: 182px !important;
    width: 182px !important;
    height: 182px !important;
    min-width: 182px !important;
    min-height: 182px !important;
    line-height: 182px !important; }
  md-icon[md-font-icon].s184,
  i.s184 {
    font-size: 184px !important;
    width: 184px !important;
    height: 184px !important;
    min-width: 184px !important;
    min-height: 184px !important;
    line-height: 184px !important; }
  md-icon[md-font-icon].s186,
  i.s186 {
    font-size: 186px !important;
    width: 186px !important;
    height: 186px !important;
    min-width: 186px !important;
    min-height: 186px !important;
    line-height: 186px !important; }
  md-icon[md-font-icon].s188,
  i.s188 {
    font-size: 188px !important;
    width: 188px !important;
    height: 188px !important;
    min-width: 188px !important;
    min-height: 188px !important;
    line-height: 188px !important; }
  md-icon[md-font-icon].s190,
  i.s190 {
    font-size: 190px !important;
    width: 190px !important;
    height: 190px !important;
    min-width: 190px !important;
    min-height: 190px !important;
    line-height: 190px !important; }
  md-icon[md-font-icon].s192,
  i.s192 {
    font-size: 192px !important;
    width: 192px !important;
    height: 192px !important;
    min-width: 192px !important;
    min-height: 192px !important;
    line-height: 192px !important; }
  md-icon[md-font-icon].s194,
  i.s194 {
    font-size: 194px !important;
    width: 194px !important;
    height: 194px !important;
    min-width: 194px !important;
    min-height: 194px !important;
    line-height: 194px !important; }
  md-icon[md-font-icon].s196,
  i.s196 {
    font-size: 196px !important;
    width: 196px !important;
    height: 196px !important;
    min-width: 196px !important;
    min-height: 196px !important;
    line-height: 196px !important; }
  md-icon[md-font-icon].s198,
  i.s198 {
    font-size: 198px !important;
    width: 198px !important;
    height: 198px !important;
    min-width: 198px !important;
    min-height: 198px !important;
    line-height: 198px !important; }
  md-icon[md-font-icon].s200,
  i.s200 {
    font-size: 200px !important;
    width: 200px !important;
    height: 200px !important;
    min-width: 200px !important;
    min-height: 200px !important;
    line-height: 200px !important; }
  md-icon[md-font-icon].s202,
  i.s202 {
    font-size: 202px !important;
    width: 202px !important;
    height: 202px !important;
    min-width: 202px !important;
    min-height: 202px !important;
    line-height: 202px !important; }
  md-icon[md-font-icon].s204,
  i.s204 {
    font-size: 204px !important;
    width: 204px !important;
    height: 204px !important;
    min-width: 204px !important;
    min-height: 204px !important;
    line-height: 204px !important; }
  md-icon[md-font-icon].s206,
  i.s206 {
    font-size: 206px !important;
    width: 206px !important;
    height: 206px !important;
    min-width: 206px !important;
    min-height: 206px !important;
    line-height: 206px !important; }
  md-icon[md-font-icon].s208,
  i.s208 {
    font-size: 208px !important;
    width: 208px !important;
    height: 208px !important;
    min-width: 208px !important;
    min-height: 208px !important;
    line-height: 208px !important; }
  md-icon[md-font-icon].s210,
  i.s210 {
    font-size: 210px !important;
    width: 210px !important;
    height: 210px !important;
    min-width: 210px !important;
    min-height: 210px !important;
    line-height: 210px !important; }
  md-icon[md-font-icon].s212,
  i.s212 {
    font-size: 212px !important;
    width: 212px !important;
    height: 212px !important;
    min-width: 212px !important;
    min-height: 212px !important;
    line-height: 212px !important; }
  md-icon[md-font-icon].s214,
  i.s214 {
    font-size: 214px !important;
    width: 214px !important;
    height: 214px !important;
    min-width: 214px !important;
    min-height: 214px !important;
    line-height: 214px !important; }
  md-icon[md-font-icon].s216,
  i.s216 {
    font-size: 216px !important;
    width: 216px !important;
    height: 216px !important;
    min-width: 216px !important;
    min-height: 216px !important;
    line-height: 216px !important; }
  md-icon[md-font-icon].s218,
  i.s218 {
    font-size: 218px !important;
    width: 218px !important;
    height: 218px !important;
    min-width: 218px !important;
    min-height: 218px !important;
    line-height: 218px !important; }
  md-icon[md-font-icon].s220,
  i.s220 {
    font-size: 220px !important;
    width: 220px !important;
    height: 220px !important;
    min-width: 220px !important;
    min-height: 220px !important;
    line-height: 220px !important; }
  md-icon[md-font-icon].s222,
  i.s222 {
    font-size: 222px !important;
    width: 222px !important;
    height: 222px !important;
    min-width: 222px !important;
    min-height: 222px !important;
    line-height: 222px !important; }
  md-icon[md-font-icon].s224,
  i.s224 {
    font-size: 224px !important;
    width: 224px !important;
    height: 224px !important;
    min-width: 224px !important;
    min-height: 224px !important;
    line-height: 224px !important; }
  md-icon[md-font-icon].s226,
  i.s226 {
    font-size: 226px !important;
    width: 226px !important;
    height: 226px !important;
    min-width: 226px !important;
    min-height: 226px !important;
    line-height: 226px !important; }
  md-icon[md-font-icon].s228,
  i.s228 {
    font-size: 228px !important;
    width: 228px !important;
    height: 228px !important;
    min-width: 228px !important;
    min-height: 228px !important;
    line-height: 228px !important; }
  md-icon[md-font-icon].s230,
  i.s230 {
    font-size: 230px !important;
    width: 230px !important;
    height: 230px !important;
    min-width: 230px !important;
    min-height: 230px !important;
    line-height: 230px !important; }
  md-icon[md-font-icon].s232,
  i.s232 {
    font-size: 232px !important;
    width: 232px !important;
    height: 232px !important;
    min-width: 232px !important;
    min-height: 232px !important;
    line-height: 232px !important; }
  md-icon[md-font-icon].s234,
  i.s234 {
    font-size: 234px !important;
    width: 234px !important;
    height: 234px !important;
    min-width: 234px !important;
    min-height: 234px !important;
    line-height: 234px !important; }
  md-icon[md-font-icon].s236,
  i.s236 {
    font-size: 236px !important;
    width: 236px !important;
    height: 236px !important;
    min-width: 236px !important;
    min-height: 236px !important;
    line-height: 236px !important; }
  md-icon[md-font-icon].s238,
  i.s238 {
    font-size: 238px !important;
    width: 238px !important;
    height: 238px !important;
    min-width: 238px !important;
    min-height: 238px !important;
    line-height: 238px !important; }
  md-icon[md-font-icon].s240,
  i.s240 {
    font-size: 240px !important;
    width: 240px !important;
    height: 240px !important;
    min-width: 240px !important;
    min-height: 240px !important;
    line-height: 240px !important; }
  md-icon[md-font-icon].s242,
  i.s242 {
    font-size: 242px !important;
    width: 242px !important;
    height: 242px !important;
    min-width: 242px !important;
    min-height: 242px !important;
    line-height: 242px !important; }
  md-icon[md-font-icon].s244,
  i.s244 {
    font-size: 244px !important;
    width: 244px !important;
    height: 244px !important;
    min-width: 244px !important;
    min-height: 244px !important;
    line-height: 244px !important; }
  md-icon[md-font-icon].s246,
  i.s246 {
    font-size: 246px !important;
    width: 246px !important;
    height: 246px !important;
    min-width: 246px !important;
    min-height: 246px !important;
    line-height: 246px !important; }
  md-icon[md-font-icon].s248,
  i.s248 {
    font-size: 248px !important;
    width: 248px !important;
    height: 248px !important;
    min-width: 248px !important;
    min-height: 248px !important;
    line-height: 248px !important; }
  md-icon[md-font-icon].s250,
  i.s250 {
    font-size: 250px !important;
    width: 250px !important;
    height: 250px !important;
    min-width: 250px !important;
    min-height: 250px !important;
    line-height: 250px !important; }
  md-icon[md-font-icon].s252,
  i.s252 {
    font-size: 252px !important;
    width: 252px !important;
    height: 252px !important;
    min-width: 252px !important;
    min-height: 252px !important;
    line-height: 252px !important; }
  md-icon[md-font-icon].s254,
  i.s254 {
    font-size: 254px !important;
    width: 254px !important;
    height: 254px !important;
    min-width: 254px !important;
    min-height: 254px !important;
    line-height: 254px !important; }
  md-icon[md-font-icon].s256,
  i.s256 {
    font-size: 256px !important;
    width: 256px !important;
    height: 256px !important;
    min-width: 256px !important;
    min-height: 256px !important;
    line-height: 256px !important; }

@font-face {
  font-family: 'icomoon';
  src: url("../assets/icons/fonts/icomoon.eot?5km1tg");
  src: url("../assets/icons/fonts/icomoon.eot?#iefix5km1tg") format("embedded-opentype"), url("../assets/icons/fonts/icomoon.ttf?5km1tg") format("truetype"), url("../assets/icons/fonts/icomoon.woff?5km1tg") format("woff"), url("../assets/icons/fonts/icomoon.svg?5km1tg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"]:not(.md-font) {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="icon-"].icon-account-alert:before, [class*=" icon-"]:not(.md-font).icon-account-alert:before {
    content: "\e600"; }
  [class^="icon-"].icon-account-box-outline:before, [class*=" icon-"]:not(.md-font).icon-account-box-outline:before {
    content: "\e601"; }
  [class^="icon-"].icon-account-box:before, [class*=" icon-"]:not(.md-font).icon-account-box:before {
    content: "\e602"; }
  [class^="icon-"].icon-account-check:before, [class*=" icon-"]:not(.md-font).icon-account-check:before {
    content: "\e603"; }
  [class^="icon-"].icon-account-circle:before, [class*=" icon-"]:not(.md-font).icon-account-circle:before {
    content: "\e604"; }
  [class^="icon-"].icon-account-key:before, [class*=" icon-"]:not(.md-font).icon-account-key:before {
    content: "\e605"; }
  [class^="icon-"].icon-account-location:before, [class*=" icon-"]:not(.md-font).icon-account-location:before {
    content: "\e606"; }
  [class^="icon-"].icon-account-minus:before, [class*=" icon-"]:not(.md-font).icon-account-minus:before {
    content: "\e607"; }
  [class^="icon-"].icon-account-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-account-multiple-outline:before {
    content: "\e608"; }
  [class^="icon-"].icon-account-multiple-plus:before, [class*=" icon-"]:not(.md-font).icon-account-multiple-plus:before {
    content: "\e609"; }
  [class^="icon-"].icon-account-multiple:before, [class*=" icon-"]:not(.md-font).icon-account-multiple:before {
    content: "\e60a"; }
  [class^="icon-"].icon-account-network:before, [class*=" icon-"]:not(.md-font).icon-account-network:before {
    content: "\e60b"; }
  [class^="icon-"].icon-account-outline:before, [class*=" icon-"]:not(.md-font).icon-account-outline:before {
    content: "\e60c"; }
  [class^="icon-"].icon-account-plus:before, [class*=" icon-"]:not(.md-font).icon-account-plus:before {
    content: "\e60d"; }
  [class^="icon-"].icon-account-remove:before, [class*=" icon-"]:not(.md-font).icon-account-remove:before {
    content: "\e60e"; }
  [class^="icon-"].icon-account-search:before, [class*=" icon-"]:not(.md-font).icon-account-search:before {
    content: "\e60f"; }
  [class^="icon-"].icon-account-switch:before, [class*=" icon-"]:not(.md-font).icon-account-switch:before {
    content: "\e610"; }
  [class^="icon-"].icon-account:before, [class*=" icon-"]:not(.md-font).icon-account:before {
    content: "\e611"; }
  [class^="icon-"].icon-airballoon:before, [class*=" icon-"]:not(.md-font).icon-airballoon:before {
    content: "\e612"; }
  [class^="icon-"].icon-airplane-off:before, [class*=" icon-"]:not(.md-font).icon-airplane-off:before {
    content: "\e613"; }
  [class^="icon-"].icon-airplane:before, [class*=" icon-"]:not(.md-font).icon-airplane:before {
    content: "\e614"; }
  [class^="icon-"].icon-alarm-check:before, [class*=" icon-"]:not(.md-font).icon-alarm-check:before {
    content: "\e615"; }
  [class^="icon-"].icon-alarm-multiple:before, [class*=" icon-"]:not(.md-font).icon-alarm-multiple:before {
    content: "\e616"; }
  [class^="icon-"].icon-alarm-off:before, [class*=" icon-"]:not(.md-font).icon-alarm-off:before {
    content: "\e617"; }
  [class^="icon-"].icon-alarm-plus:before, [class*=" icon-"]:not(.md-font).icon-alarm-plus:before {
    content: "\e618"; }
  [class^="icon-"].icon-alarm:before, [class*=" icon-"]:not(.md-font).icon-alarm:before {
    content: "\e619"; }
  [class^="icon-"].icon-album:before, [class*=" icon-"]:not(.md-font).icon-album:before {
    content: "\e61a"; }
  [class^="icon-"].icon-alert-box:before, [class*=" icon-"]:not(.md-font).icon-alert-box:before {
    content: "\e61b"; }
  [class^="icon-"].icon-alert-circle:before, [class*=" icon-"]:not(.md-font).icon-alert-circle:before {
    content: "\e61c"; }
  [class^="icon-"].icon-alert-octagon:before, [class*=" icon-"]:not(.md-font).icon-alert-octagon:before {
    content: "\e61d"; }
  [class^="icon-"].icon-alert:before, [class*=" icon-"]:not(.md-font).icon-alert:before {
    content: "\e61e"; }
  [class^="icon-"].icon-alpha:before, [class*=" icon-"]:not(.md-font).icon-alpha:before {
    content: "\e61f"; }
  [class^="icon-"].icon-alphabetical:before, [class*=" icon-"]:not(.md-font).icon-alphabetical:before {
    content: "\e620"; }
  [class^="icon-"].icon-amazon-clouddrive:before, [class*=" icon-"]:not(.md-font).icon-amazon-clouddrive:before {
    content: "\e621"; }
  [class^="icon-"].icon-amazon:before, [class*=" icon-"]:not(.md-font).icon-amazon:before {
    content: "\e622"; }
  [class^="icon-"].icon-ambulance:before, [class*=" icon-"]:not(.md-font).icon-ambulance:before {
    content: "\e623"; }
  [class^="icon-"].icon-android-debug-bridge:before, [class*=" icon-"]:not(.md-font).icon-android-debug-bridge:before {
    content: "\e624"; }
  [class^="icon-"].icon-android-studio:before, [class*=" icon-"]:not(.md-font).icon-android-studio:before {
    content: "\e625"; }
  [class^="icon-"].icon-android:before, [class*=" icon-"]:not(.md-font).icon-android:before {
    content: "\e626"; }
  [class^="icon-"].icon-apple-finder:before, [class*=" icon-"]:not(.md-font).icon-apple-finder:before {
    content: "\e627"; }
  [class^="icon-"].icon-apple-ios:before, [class*=" icon-"]:not(.md-font).icon-apple-ios:before {
    content: "\e628"; }
  [class^="icon-"].icon-apple-mobileme:before, [class*=" icon-"]:not(.md-font).icon-apple-mobileme:before {
    content: "\e629"; }
  [class^="icon-"].icon-apple-safari:before, [class*=" icon-"]:not(.md-font).icon-apple-safari:before {
    content: "\e62a"; }
  [class^="icon-"].icon-apple:before, [class*=" icon-"]:not(.md-font).icon-apple:before {
    content: "\e62b"; }
  [class^="icon-"].icon-appnet:before, [class*=" icon-"]:not(.md-font).icon-appnet:before {
    content: "\e62c"; }
  [class^="icon-"].icon-apps:before, [class*=" icon-"]:not(.md-font).icon-apps:before {
    content: "\e62d"; }
  [class^="icon-"].icon-archive:before, [class*=" icon-"]:not(.md-font).icon-archive:before {
    content: "\e62e"; }
  [class^="icon-"].icon-arrange-bring-forward:before, [class*=" icon-"]:not(.md-font).icon-arrange-bring-forward:before {
    content: "\e62f"; }
  [class^="icon-"].icon-arrange-bring-to-front:before, [class*=" icon-"]:not(.md-font).icon-arrange-bring-to-front:before {
    content: "\e630"; }
  [class^="icon-"].icon-arrange-send-backward:before, [class*=" icon-"]:not(.md-font).icon-arrange-send-backward:before {
    content: "\e631"; }
  [class^="icon-"].icon-arrange-send-to-back:before, [class*=" icon-"]:not(.md-font).icon-arrange-send-to-back:before {
    content: "\e632"; }
  [class^="icon-"].icon-arrow-all:before, [class*=" icon-"]:not(.md-font).icon-arrow-all:before {
    content: "\e633"; }
  [class^="icon-"].icon-arrow-bottom-left:before, .icon-event-2[class^="icon-"]:before, .icon-event-2:not(.md-font)[class*=" icon-"][class^="icon-"]:before, [class*=" icon-"]:not(.md-font).icon-arrow-bottom-left:before, .icon-event-2[class*=" icon-"]:not(.md-font):before {
    content: "\e634"; }
  [class^="icon-"].icon-arrow-bottom-right:before, [class*=" icon-"]:not(.md-font).icon-arrow-bottom-right:before {
    content: "\e635"; }
  [class^="icon-"].icon-arrow-collapse:before, [class*=" icon-"]:not(.md-font).icon-arrow-collapse:before {
    content: "\e636"; }
  [class^="icon-"].icon-arrow-down-bold-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-down-bold-circle-outline:before {
    content: "\e637"; }
  [class^="icon-"].icon-arrow-down-bold-circle:before, [class*=" icon-"]:not(.md-font).icon-arrow-down-bold-circle:before {
    content: "\e638"; }
  [class^="icon-"].icon-arrow-down-bold-hexagon-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-down-bold-hexagon-outline:before {
    content: "\e639"; }
  [class^="icon-"].icon-arrow-down-bold:before, [class*=" icon-"]:not(.md-font).icon-arrow-down-bold:before {
    content: "\e63a"; }
  [class^="icon-"].icon-arrow-down:before, [class*=" icon-"]:not(.md-font).icon-arrow-down:before {
    content: "\e63b"; }
  [class^="icon-"].icon-arrow-expand:before, [class*=" icon-"]:not(.md-font).icon-arrow-expand:before {
    content: "\e63c"; }
  [class^="icon-"].icon-arrow-left-bold-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-left-bold-circle-outline:before {
    content: "\e63d"; }
  [class^="icon-"].icon-arrow-left-bold-circle:before, [class*=" icon-"]:not(.md-font).icon-arrow-left-bold-circle:before {
    content: "\e63e"; }
  [class^="icon-"].icon-arrow-left-bold-hexagon-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-left-bold-hexagon-outline:before {
    content: "\e63f"; }
  [class^="icon-"].icon-arrow-left-bold:before, [class*=" icon-"]:not(.md-font).icon-arrow-left-bold:before {
    content: "\e640"; }
  [class^="icon-"].icon-arrow-left:before, [class*=" icon-"]:not(.md-font).icon-arrow-left:before {
    content: "\e641"; }
  [class^="icon-"].icon-arrow-right-bold-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-right-bold-circle-outline:before {
    content: "\e642"; }
  [class^="icon-"].icon-arrow-right-bold-circle:before, [class*=" icon-"]:not(.md-font).icon-arrow-right-bold-circle:before {
    content: "\e643"; }
  [class^="icon-"].icon-arrow-right-bold-hexagon-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-right-bold-hexagon-outline:before {
    content: "\e644"; }
  [class^="icon-"].icon-arrow-right-bold:before, [class*=" icon-"]:not(.md-font).icon-arrow-right-bold:before {
    content: "\e645"; }
  [class^="icon-"].icon-arrow-right:before, [class*=" icon-"]:not(.md-font).icon-arrow-right:before {
    content: "\e646"; }
  [class^="icon-"].icon-arrow-top-left:before, [class*=" icon-"]:not(.md-font).icon-arrow-top-left:before {
    content: "\e647"; }
  [class^="icon-"].icon-arrow-top-right:before, .icon-event-1[class^="icon-"]:before, .icon-event-1:not(.md-font)[class*=" icon-"][class^="icon-"]:before, [class*=" icon-"]:not(.md-font).icon-arrow-top-right:before, .icon-event-1[class*=" icon-"]:not(.md-font):before {
    content: "\e648"; }
  [class^="icon-"].icon-arrow-up-bold-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-up-bold-circle-outline:before {
    content: "\e649"; }
  [class^="icon-"].icon-arrow-up-bold-circle:before, [class*=" icon-"]:not(.md-font).icon-arrow-up-bold-circle:before {
    content: "\e64a"; }
  [class^="icon-"].icon-arrow-up-bold-hexagon-outline:before, [class*=" icon-"]:not(.md-font).icon-arrow-up-bold-hexagon-outline:before {
    content: "\e64b"; }
  [class^="icon-"].icon-arrow-up-bold:before, [class*=" icon-"]:not(.md-font).icon-arrow-up-bold:before {
    content: "\e64c"; }
  [class^="icon-"].icon-arrow-up:before, [class*=" icon-"]:not(.md-font).icon-arrow-up:before {
    content: "\e64d"; }
  [class^="icon-"].icon-at:before, [class*=" icon-"]:not(.md-font).icon-at:before {
    content: "\e64e"; }
  [class^="icon-"].icon-attachment:before, [class*=" icon-"]:not(.md-font).icon-attachment:before {
    content: "\e64f"; }
  [class^="icon-"].icon-audiobook:before, [class*=" icon-"]:not(.md-font).icon-audiobook:before {
    content: "\e650"; }
  [class^="icon-"].icon-auto-fix:before, [class*=" icon-"]:not(.md-font).icon-auto-fix:before {
    content: "\e651"; }
  [class^="icon-"].icon-auto-upload:before, [class*=" icon-"]:not(.md-font).icon-auto-upload:before {
    content: "\e652"; }
  [class^="icon-"].icon-baby:before, [class*=" icon-"]:not(.md-font).icon-baby:before {
    content: "\e653"; }
  [class^="icon-"].icon-backburger:before, [class*=" icon-"]:not(.md-font).icon-backburger:before {
    content: "\e654"; }
  [class^="icon-"].icon-backup-restore:before, [class*=" icon-"]:not(.md-font).icon-backup-restore:before {
    content: "\e655"; }
  [class^="icon-"].icon-bank:before, [class*=" icon-"]:not(.md-font).icon-bank:before {
    content: "\e656"; }
  [class^="icon-"].icon-barcode:before, [class*=" icon-"]:not(.md-font).icon-barcode:before {
    content: "\e657"; }
  [class^="icon-"].icon-barley:before, [class*=" icon-"]:not(.md-font).icon-barley:before {
    content: "\e658"; }
  [class^="icon-"].icon-barrel:before, [class*=" icon-"]:not(.md-font).icon-barrel:before {
    content: "\e659"; }
  [class^="icon-"].icon-basecamp:before, [class*=" icon-"]:not(.md-font).icon-basecamp:before {
    content: "\e65a"; }
  [class^="icon-"].icon-basket-fill:before, [class*=" icon-"]:not(.md-font).icon-basket-fill:before {
    content: "\e65b"; }
  [class^="icon-"].icon-basket-unfill:before, [class*=" icon-"]:not(.md-font).icon-basket-unfill:before {
    content: "\e65c"; }
  [class^="icon-"].icon-basket:before, [class*=" icon-"]:not(.md-font).icon-basket:before {
    content: "\e65d"; }
  [class^="icon-"].icon-battery-10:before, [class*=" icon-"]:not(.md-font).icon-battery-10:before {
    content: "\e65e"; }
  [class^="icon-"].icon-battery-20:before, [class*=" icon-"]:not(.md-font).icon-battery-20:before {
    content: "\e65f"; }
  [class^="icon-"].icon-battery-30:before, [class*=" icon-"]:not(.md-font).icon-battery-30:before {
    content: "\e660"; }
  [class^="icon-"].icon-battery-40:before, [class*=" icon-"]:not(.md-font).icon-battery-40:before {
    content: "\e661"; }
  [class^="icon-"].icon-battery-50:before, [class*=" icon-"]:not(.md-font).icon-battery-50:before {
    content: "\e662"; }
  [class^="icon-"].icon-battery-60:before, [class*=" icon-"]:not(.md-font).icon-battery-60:before {
    content: "\e663"; }
  [class^="icon-"].icon-battery-70:before, [class*=" icon-"]:not(.md-font).icon-battery-70:before {
    content: "\e664"; }
  [class^="icon-"].icon-battery-80:before, [class*=" icon-"]:not(.md-font).icon-battery-80:before {
    content: "\e665"; }
  [class^="icon-"].icon-battery-90:before, [class*=" icon-"]:not(.md-font).icon-battery-90:before {
    content: "\e666"; }
  [class^="icon-"].icon-battery-alert:before, [class*=" icon-"]:not(.md-font).icon-battery-alert:before {
    content: "\e667"; }
  [class^="icon-"].icon-battery-charging-20:before, [class*=" icon-"]:not(.md-font).icon-battery-charging-20:before {
    content: "\e668"; }
  [class^="icon-"].icon-battery-charging-30:before, [class*=" icon-"]:not(.md-font).icon-battery-charging-30:before {
    content: "\e669"; }
  [class^="icon-"].icon-battery-charging-40:before, [class*=" icon-"]:not(.md-font).icon-battery-charging-40:before {
    content: "\e66a"; }
  [class^="icon-"].icon-battery-charging-60:before, [class*=" icon-"]:not(.md-font).icon-battery-charging-60:before {
    content: "\e66b"; }
  [class^="icon-"].icon-battery-charging-80:before, [class*=" icon-"]:not(.md-font).icon-battery-charging-80:before {
    content: "\e66c"; }
  [class^="icon-"].icon-battery-charging-90:before, [class*=" icon-"]:not(.md-font).icon-battery-charging-90:before {
    content: "\e66d"; }
  [class^="icon-"].icon-battery-charging-100:before, [class*=" icon-"]:not(.md-font).icon-battery-charging-100:before {
    content: "\e66e"; }
  [class^="icon-"].icon-battery-minus:before, [class*=" icon-"]:not(.md-font).icon-battery-minus:before {
    content: "\e66f"; }
  [class^="icon-"].icon-battery-negative:before, [class*=" icon-"]:not(.md-font).icon-battery-negative:before {
    content: "\e670"; }
  [class^="icon-"].icon-battery-outline:before, [class*=" icon-"]:not(.md-font).icon-battery-outline:before {
    content: "\e671"; }
  [class^="icon-"].icon-battery-plus:before, [class*=" icon-"]:not(.md-font).icon-battery-plus:before {
    content: "\e672"; }
  [class^="icon-"].icon-battery-positive:before, [class*=" icon-"]:not(.md-font).icon-battery-positive:before {
    content: "\e673"; }
  [class^="icon-"].icon-battery-standard:before, [class*=" icon-"]:not(.md-font).icon-battery-standard:before {
    content: "\e674"; }
  [class^="icon-"].icon-battery-unknown:before, [class*=" icon-"]:not(.md-font).icon-battery-unknown:before {
    content: "\e675"; }
  [class^="icon-"].icon-battery:before, [class*=" icon-"]:not(.md-font).icon-battery:before {
    content: "\e676"; }
  [class^="icon-"].icon-beach:before, [class*=" icon-"]:not(.md-font).icon-beach:before {
    content: "\e677"; }
  [class^="icon-"].icon-beaker-empty-outline:before, [class*=" icon-"]:not(.md-font).icon-beaker-empty-outline:before {
    content: "\e678"; }
  [class^="icon-"].icon-beaker-empty:before, [class*=" icon-"]:not(.md-font).icon-beaker-empty:before {
    content: "\e679"; }
  [class^="icon-"].icon-beaker-outline:before, [class*=" icon-"]:not(.md-font).icon-beaker-outline:before {
    content: "\e67a"; }
  [class^="icon-"].icon-beaker:before, [class*=" icon-"]:not(.md-font).icon-beaker:before {
    content: "\e67b"; }
  [class^="icon-"].icon-beats:before, [class*=" icon-"]:not(.md-font).icon-beats:before {
    content: "\e67c"; }
  [class^="icon-"].icon-beer:before, [class*=" icon-"]:not(.md-font).icon-beer:before {
    content: "\e67d"; }
  [class^="icon-"].icon-behance:before, [class*=" icon-"]:not(.md-font).icon-behance:before {
    content: "\e67e"; }
  [class^="icon-"].icon-bell-off:before, [class*=" icon-"]:not(.md-font).icon-bell-off:before {
    content: "\e67f"; }
  [class^="icon-"].icon-bell-outline:before, [class*=" icon-"]:not(.md-font).icon-bell-outline:before {
    content: "\e680"; }
  [class^="icon-"].icon-bell-ring-outline:before, [class*=" icon-"]:not(.md-font).icon-bell-ring-outline:before {
    content: "\e681"; }
  [class^="icon-"].icon-bell-ring:before, [class*=" icon-"]:not(.md-font).icon-bell-ring:before {
    content: "\e682"; }
  [class^="icon-"].icon-bell-sleep:before, [class*=" icon-"]:not(.md-font).icon-bell-sleep:before {
    content: "\e683"; }
  [class^="icon-"].icon-bell:before, [class*=" icon-"]:not(.md-font).icon-bell:before {
    content: "\e684"; }
  [class^="icon-"].icon-beta:before, [class*=" icon-"]:not(.md-font).icon-beta:before {
    content: "\e685"; }
  [class^="icon-"].icon-bike:before, [class*=" icon-"]:not(.md-font).icon-bike:before {
    content: "\e686"; }
  [class^="icon-"].icon-bing:before, [class*=" icon-"]:not(.md-font).icon-bing:before {
    content: "\e687"; }
  [class^="icon-"].icon-binoculars:before, [class*=" icon-"]:not(.md-font).icon-binoculars:before {
    content: "\e688"; }
  [class^="icon-"].icon-bio:before, [class*=" icon-"]:not(.md-font).icon-bio:before {
    content: "\e689"; }
  [class^="icon-"].icon-bitbucket:before, [class*=" icon-"]:not(.md-font).icon-bitbucket:before {
    content: "\e68a"; }
  [class^="icon-"].icon-black-mesa:before, [class*=" icon-"]:not(.md-font).icon-black-mesa:before {
    content: "\e68b"; }
  [class^="icon-"].icon-blackberry:before, [class*=" icon-"]:not(.md-font).icon-blackberry:before {
    content: "\e68c"; }
  [class^="icon-"].icon-blinds:before, [class*=" icon-"]:not(.md-font).icon-blinds:before {
    content: "\e68d"; }
  [class^="icon-"].icon-block-helper:before, [class*=" icon-"]:not(.md-font).icon-block-helper:before {
    content: "\e68e"; }
  [class^="icon-"].icon-blogger:before, [class*=" icon-"]:not(.md-font).icon-blogger:before {
    content: "\e68f"; }
  [class^="icon-"].icon-bluetooth-audio:before, [class*=" icon-"]:not(.md-font).icon-bluetooth-audio:before {
    content: "\e690"; }
  [class^="icon-"].icon-bluetooth-connect:before, [class*=" icon-"]:not(.md-font).icon-bluetooth-connect:before {
    content: "\e691"; }
  [class^="icon-"].icon-bluetooth-settings:before, [class*=" icon-"]:not(.md-font).icon-bluetooth-settings:before {
    content: "\e692"; }
  [class^="icon-"].icon-bluetooth-transfer:before, [class*=" icon-"]:not(.md-font).icon-bluetooth-transfer:before {
    content: "\e693"; }
  [class^="icon-"].icon-bluetooth:before, [class*=" icon-"]:not(.md-font).icon-bluetooth:before {
    content: "\e694"; }
  [class^="icon-"].icon-blur-linear:before, [class*=" icon-"]:not(.md-font).icon-blur-linear:before {
    content: "\e695"; }
  [class^="icon-"].icon-blur-off:before, [class*=" icon-"]:not(.md-font).icon-blur-off:before {
    content: "\e696"; }
  [class^="icon-"].icon-blur-radial:before, [class*=" icon-"]:not(.md-font).icon-blur-radial:before {
    content: "\e697"; }
  [class^="icon-"].icon-blur:before, [class*=" icon-"]:not(.md-font).icon-blur:before {
    content: "\e698"; }
  [class^="icon-"].icon-bone:before, [class*=" icon-"]:not(.md-font).icon-bone:before {
    content: "\e699"; }
  [class^="icon-"].icon-book-multiple-variant:before, [class*=" icon-"]:not(.md-font).icon-book-multiple-variant:before {
    content: "\e69a"; }
  [class^="icon-"].icon-book-multiple:before, [class*=" icon-"]:not(.md-font).icon-book-multiple:before {
    content: "\e69b"; }
  [class^="icon-"].icon-book-open:before, [class*=" icon-"]:not(.md-font).icon-book-open:before {
    content: "\e69c"; }
  [class^="icon-"].icon-book-variant:before, [class*=" icon-"]:not(.md-font).icon-book-variant:before {
    content: "\e69d"; }
  [class^="icon-"].icon-book:before, [class*=" icon-"]:not(.md-font).icon-book:before {
    content: "\e69e"; }
  [class^="icon-"].icon-bookmark-check:before, [class*=" icon-"]:not(.md-font).icon-bookmark-check:before {
    content: "\e69f"; }
  [class^="icon-"].icon-bookmark-music:before, [class*=" icon-"]:not(.md-font).icon-bookmark-music:before {
    content: "\e6a0"; }
  [class^="icon-"].icon-bookmark-outline-plus:before, [class*=" icon-"]:not(.md-font).icon-bookmark-outline-plus:before {
    content: "\e6a1"; }
  [class^="icon-"].icon-bookmark-outline:before, [class*=" icon-"]:not(.md-font).icon-bookmark-outline:before {
    content: "\e6a2"; }
  [class^="icon-"].icon-bookmark-plus:before, [class*=" icon-"]:not(.md-font).icon-bookmark-plus:before {
    content: "\e6a3"; }
  [class^="icon-"].icon-bookmark-remove:before, [class*=" icon-"]:not(.md-font).icon-bookmark-remove:before {
    content: "\e6a4"; }
  [class^="icon-"].icon-bookmark:before, [class*=" icon-"]:not(.md-font).icon-bookmark:before {
    content: "\e6a5"; }
  [class^="icon-"].icon-border-all:before, [class*=" icon-"]:not(.md-font).icon-border-all:before {
    content: "\e6a6"; }
  [class^="icon-"].icon-border-bottom:before, [class*=" icon-"]:not(.md-font).icon-border-bottom:before {
    content: "\e6a7"; }
  [class^="icon-"].icon-border-color:before, [class*=" icon-"]:not(.md-font).icon-border-color:before {
    content: "\e6a8"; }
  [class^="icon-"].icon-border-horizontal:before, [class*=" icon-"]:not(.md-font).icon-border-horizontal:before {
    content: "\e6a9"; }
  [class^="icon-"].icon-border-inside:before, [class*=" icon-"]:not(.md-font).icon-border-inside:before {
    content: "\e6aa"; }
  [class^="icon-"].icon-border-left:before, [class*=" icon-"]:not(.md-font).icon-border-left:before {
    content: "\e6ab"; }
  [class^="icon-"].icon-border-none:before, [class*=" icon-"]:not(.md-font).icon-border-none:before {
    content: "\e6ac"; }
  [class^="icon-"].icon-border-outside:before, [class*=" icon-"]:not(.md-font).icon-border-outside:before {
    content: "\e6ad"; }
  [class^="icon-"].icon-border-right:before, [class*=" icon-"]:not(.md-font).icon-border-right:before {
    content: "\e6ae"; }
  [class^="icon-"].icon-border-top:before, [class*=" icon-"]:not(.md-font).icon-border-top:before {
    content: "\e6af"; }
  [class^="icon-"].icon-border-vertical:before, [class*=" icon-"]:not(.md-font).icon-border-vertical:before {
    content: "\e6b0"; }
  [class^="icon-"].icon-bowling:before, [class*=" icon-"]:not(.md-font).icon-bowling:before {
    content: "\e6b1"; }
  [class^="icon-"].icon-box-download:before, [class*=" icon-"]:not(.md-font).icon-box-download:before {
    content: "\e6b2"; }
  [class^="icon-"].icon-box-upload:before, [class*=" icon-"]:not(.md-font).icon-box-upload:before {
    content: "\e6b3"; }
  [class^="icon-"].icon-box:before, [class*=" icon-"]:not(.md-font).icon-box:before {
    content: "\e6b4"; }
  [class^="icon-"].icon-briefcase-checked:before, [class*=" icon-"]:not(.md-font).icon-briefcase-checked:before {
    content: "\e6b5"; }
  [class^="icon-"].icon-briefcase-download:before, [class*=" icon-"]:not(.md-font).icon-briefcase-download:before {
    content: "\e6b6"; }
  [class^="icon-"].icon-briefcase-upload:before, [class*=" icon-"]:not(.md-font).icon-briefcase-upload:before {
    content: "\e6b7"; }
  [class^="icon-"].icon-briefcase:before, [class*=" icon-"]:not(.md-font).icon-briefcase:before {
    content: "\e6b8"; }
  [class^="icon-"].icon-brightness-1:before, [class*=" icon-"]:not(.md-font).icon-brightness-1:before {
    content: "\e6b9"; }
  [class^="icon-"].icon-brightness-2:before, [class*=" icon-"]:not(.md-font).icon-brightness-2:before {
    content: "\e6ba"; }
  [class^="icon-"].icon-brightness-3:before, [class*=" icon-"]:not(.md-font).icon-brightness-3:before {
    content: "\e6bb"; }
  [class^="icon-"].icon-brightness-4:before, [class*=" icon-"]:not(.md-font).icon-brightness-4:before {
    content: "\e6bc"; }
  [class^="icon-"].icon-brightness-5:before, [class*=" icon-"]:not(.md-font).icon-brightness-5:before {
    content: "\e6bd"; }
  [class^="icon-"].icon-brightness-6:before, [class*=" icon-"]:not(.md-font).icon-brightness-6:before {
    content: "\e6be"; }
  [class^="icon-"].icon-brightness-7:before, [class*=" icon-"]:not(.md-font).icon-brightness-7:before {
    content: "\e6bf"; }
  [class^="icon-"].icon-brightness-auto:before, [class*=" icon-"]:not(.md-font).icon-brightness-auto:before {
    content: "\e6c0"; }
  [class^="icon-"].icon-brightness:before, [class*=" icon-"]:not(.md-font).icon-brightness:before {
    content: "\e6c1"; }
  [class^="icon-"].icon-broom:before, [class*=" icon-"]:not(.md-font).icon-broom:before {
    content: "\e6c2"; }
  [class^="icon-"].icon-brush:before, [class*=" icon-"]:not(.md-font).icon-brush:before {
    content: "\e6c3"; }
  [class^="icon-"].icon-bug:before, [class*=" icon-"]:not(.md-font).icon-bug:before {
    content: "\e6c4"; }
  [class^="icon-"].icon-bulletin-board:before, [class*=" icon-"]:not(.md-font).icon-bulletin-board:before {
    content: "\e6c5"; }
  [class^="icon-"].icon-bullhorn:before, [class*=" icon-"]:not(.md-font).icon-bullhorn:before {
    content: "\e6c6"; }
  [class^="icon-"].icon-bus:before, .icon-event-5[class^="icon-"]:before, .icon-event-5:not(.md-font)[class*=" icon-"][class^="icon-"]:before, .icon-event-6[class^="icon-"]:before, .icon-event-6:not(.md-font)[class*=" icon-"][class^="icon-"]:before, .icon-event-13[class^="icon-"]:before, .icon-event-13:not(.md-font)[class*=" icon-"][class^="icon-"]:before, .icon-event-14[class^="icon-"]:before, .icon-event-14:not(.md-font)[class*=" icon-"][class^="icon-"]:before, [class*=" icon-"]:not(.md-font).icon-bus:before, .icon-event-5[class*=" icon-"]:not(.md-font):before, .icon-event-6[class*=" icon-"]:not(.md-font):before, .icon-event-13[class*=" icon-"]:not(.md-font):before, .icon-event-14[class*=" icon-"]:not(.md-font):before {
    content: "\e6c7"; }
  [class^="icon-"].icon-cake-variant:before, [class*=" icon-"]:not(.md-font).icon-cake-variant:before {
    content: "\e6c8"; }
  [class^="icon-"].icon-cake:before, [class*=" icon-"]:not(.md-font).icon-cake:before {
    content: "\e6c9"; }
  [class^="icon-"].icon-calculator:before, [class*=" icon-"]:not(.md-font).icon-calculator:before {
    content: "\e6ca"; }
  [class^="icon-"].icon-calendar-blank:before, [class*=" icon-"]:not(.md-font).icon-calendar-blank:before {
    content: "\e6cb"; }
  [class^="icon-"].icon-calendar-check-multiple:before, [class*=" icon-"]:not(.md-font).icon-calendar-check-multiple:before {
    content: "\e6cc"; }
  [class^="icon-"].icon-calendar-check:before, [class*=" icon-"]:not(.md-font).icon-calendar-check:before {
    content: "\e6cd"; }
  [class^="icon-"].icon-calendar-clock:before, [class*=" icon-"]:not(.md-font).icon-calendar-clock:before {
    content: "\e6ce"; }
  [class^="icon-"].icon-calendar-multiple:before, [class*=" icon-"]:not(.md-font).icon-calendar-multiple:before {
    content: "\e6cf"; }
  [class^="icon-"].icon-calendar-plus:before, [class*=" icon-"]:not(.md-font).icon-calendar-plus:before {
    content: "\e6d0"; }
  [class^="icon-"].icon-calendar-remove:before, [class*=" icon-"]:not(.md-font).icon-calendar-remove:before {
    content: "\e6d1"; }
  [class^="icon-"].icon-calendar-select:before, [class*=" icon-"]:not(.md-font).icon-calendar-select:before {
    content: "\e6d2"; }
  [class^="icon-"].icon-calendar-text:before, [class*=" icon-"]:not(.md-font).icon-calendar-text:before {
    content: "\e6d3"; }
  [class^="icon-"].icon-calendar-today:before, [class*=" icon-"]:not(.md-font).icon-calendar-today:before {
    content: "\e6d4"; }
  [class^="icon-"].icon-calendar:before, [class*=" icon-"]:not(.md-font).icon-calendar:before {
    content: "\e6d5"; }
  [class^="icon-"].icon-camcorder-box-off:before, [class*=" icon-"]:not(.md-font).icon-camcorder-box-off:before {
    content: "\e6d6"; }
  [class^="icon-"].icon-camcorder-box:before, [class*=" icon-"]:not(.md-font).icon-camcorder-box:before {
    content: "\e6d7"; }
  [class^="icon-"].icon-camcorder-off:before, [class*=" icon-"]:not(.md-font).icon-camcorder-off:before {
    content: "\e6d8"; }
  [class^="icon-"].icon-camcorder:before, [class*=" icon-"]:not(.md-font).icon-camcorder:before {
    content: "\e6d9"; }
  [class^="icon-"].icon-camera-front-variant:before, [class*=" icon-"]:not(.md-font).icon-camera-front-variant:before {
    content: "\e6da"; }
  [class^="icon-"].icon-camera-front:before, [class*=" icon-"]:not(.md-font).icon-camera-front:before {
    content: "\e6db"; }
  [class^="icon-"].icon-camera-iris:before, [class*=" icon-"]:not(.md-font).icon-camera-iris:before {
    content: "\e6dc"; }
  [class^="icon-"].icon-camera-party-mode:before, [class*=" icon-"]:not(.md-font).icon-camera-party-mode:before {
    content: "\e6dd"; }
  [class^="icon-"].icon-camera-rear-variant:before, [class*=" icon-"]:not(.md-font).icon-camera-rear-variant:before {
    content: "\e6de"; }
  [class^="icon-"].icon-camera-rear:before, [class*=" icon-"]:not(.md-font).icon-camera-rear:before {
    content: "\e6df"; }
  [class^="icon-"].icon-camera-switch:before, [class*=" icon-"]:not(.md-font).icon-camera-switch:before {
    content: "\e6e0"; }
  [class^="icon-"].icon-camera-timer:before, [class*=" icon-"]:not(.md-font).icon-camera-timer:before {
    content: "\e6e1"; }
  [class^="icon-"].icon-camera:before, [class*=" icon-"]:not(.md-font).icon-camera:before {
    content: "\e6e2"; }
  [class^="icon-"].icon-cancel:before, [class*=" icon-"]:not(.md-font).icon-cancel:before {
    content: "\e6e3"; }
  [class^="icon-"].icon-candycane:before, [class*=" icon-"]:not(.md-font).icon-candycane:before {
    content: "\e6e4"; }
  [class^="icon-"].icon-car-wash:before, [class*=" icon-"]:not(.md-font).icon-car-wash:before {
    content: "\e6e5"; }
  [class^="icon-"].icon-car:before, [class*=" icon-"]:not(.md-font).icon-car:before {
    content: "\e6e6"; }
  [class^="icon-"].icon-carrot:before, [class*=" icon-"]:not(.md-font).icon-carrot:before {
    content: "\e6e7"; }
  [class^="icon-"].icon-cart-outline:before, [class*=" icon-"]:not(.md-font).icon-cart-outline:before {
    content: "\e6e8"; }
  [class^="icon-"].icon-cart:before, [class*=" icon-"]:not(.md-font).icon-cart:before {
    content: "\e6e9"; }
  [class^="icon-"].icon-cash-100:before, [class*=" icon-"]:not(.md-font).icon-cash-100:before {
    content: "\e6ea"; }
  [class^="icon-"].icon-cash-multiple:before, [class*=" icon-"]:not(.md-font).icon-cash-multiple:before {
    content: "\e6eb"; }
  [class^="icon-"].icon-cash-usd:before, [class*=" icon-"]:not(.md-font).icon-cash-usd:before {
    content: "\e6ec"; }
  [class^="icon-"].icon-cash:before, [class*=" icon-"]:not(.md-font).icon-cash:before {
    content: "\e6ed"; }
  [class^="icon-"].icon-cast-connected:before, [class*=" icon-"]:not(.md-font).icon-cast-connected:before {
    content: "\e6ee"; }
  [class^="icon-"].icon-cast:before, [class*=" icon-"]:not(.md-font).icon-cast:before {
    content: "\e6ef"; }
  [class^="icon-"].icon-castle:before, [class*=" icon-"]:not(.md-font).icon-castle:before {
    content: "\e6f0"; }
  [class^="icon-"].icon-cat:before, [class*=" icon-"]:not(.md-font).icon-cat:before {
    content: "\e6f1"; }
  [class^="icon-"].icon-cellphone-android:before, [class*=" icon-"]:not(.md-font).icon-cellphone-android:before {
    content: "\e6f2"; }
  [class^="icon-"].icon-cellphone-dock:before, [class*=" icon-"]:not(.md-font).icon-cellphone-dock:before {
    content: "\e6f3"; }
  [class^="icon-"].icon-cellphone-iphone:before, [class*=" icon-"]:not(.md-font).icon-cellphone-iphone:before {
    content: "\e6f4"; }
  [class^="icon-"].icon-cellphone-link-off:before, [class*=" icon-"]:not(.md-font).icon-cellphone-link-off:before {
    content: "\e6f5"; }
  [class^="icon-"].icon-cellphone-link:before, [class*=" icon-"]:not(.md-font).icon-cellphone-link:before {
    content: "\e6f6"; }
  [class^="icon-"].icon-cellphone-settings:before, [class*=" icon-"]:not(.md-font).icon-cellphone-settings:before {
    content: "\e6f7"; }
  [class^="icon-"].icon-cellphone:before, [class*=" icon-"]:not(.md-font).icon-cellphone:before {
    content: "\e6f8"; }
  [class^="icon-"].icon-chair-school:before, [class*=" icon-"]:not(.md-font).icon-chair-school:before {
    content: "\e6f9"; }
  [class^="icon-"].icon-chart-arc:before, [class*=" icon-"]:not(.md-font).icon-chart-arc:before {
    content: "\e6fa"; }
  [class^="icon-"].icon-chart-areaspline:before, [class*=" icon-"]:not(.md-font).icon-chart-areaspline:before {
    content: "\e6fb"; }
  [class^="icon-"].icon-chart-bar:before, [class*=" icon-"]:not(.md-font).icon-chart-bar:before {
    content: "\e6fc"; }
  [class^="icon-"].icon-chart-histogram:before, [class*=" icon-"]:not(.md-font).icon-chart-histogram:before {
    content: "\e6fd"; }
  [class^="icon-"].icon-chart-line:before, [class*=" icon-"]:not(.md-font).icon-chart-line:before {
    content: "\e6fe"; }
  [class^="icon-"].icon-chart-pie:before, [class*=" icon-"]:not(.md-font).icon-chart-pie:before {
    content: "\e6ff"; }
  [class^="icon-"].icon-check-all:before, [class*=" icon-"]:not(.md-font).icon-check-all:before {
    content: "\e700"; }
  [class^="icon-"].icon-check-bookmark:before, [class*=" icon-"]:not(.md-font).icon-check-bookmark:before {
    content: "\e701"; }
  [class^="icon-"].icon-check-circle:before, [class*=" icon-"]:not(.md-font).icon-check-circle:before {
    content: "\e702"; }
  [class^="icon-"].icon-check:before, [class*=" icon-"]:not(.md-font).icon-check:before {
    content: "\e703"; }
  [class^="icon-"].icon-checkbox-blank-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-checkbox-blank-circle-outline:before {
    content: "\e704"; }
  [class^="icon-"].icon-checkbox-blank-circle:before, [class*=" icon-"]:not(.md-font).icon-checkbox-blank-circle:before {
    content: "\e705"; }
  [class^="icon-"].icon-checkbox-blank-outline:before, [class*=" icon-"]:not(.md-font).icon-checkbox-blank-outline:before {
    content: "\e706"; }
  [class^="icon-"].icon-checkbox-blank:before, [class*=" icon-"]:not(.md-font).icon-checkbox-blank:before {
    content: "\e707"; }
  [class^="icon-"].icon-checkbox-marked-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-checkbox-marked-circle-outline:before {
    content: "\e708"; }
  [class^="icon-"].icon-checkbox-marked-circle:before, [class*=" icon-"]:not(.md-font).icon-checkbox-marked-circle:before {
    content: "\e709"; }
  [class^="icon-"].icon-checkbox-marked-outline:before, [class*=" icon-"]:not(.md-font).icon-checkbox-marked-outline:before {
    content: "\e70a"; }
  [class^="icon-"].icon-checkbox-marked:before, [class*=" icon-"]:not(.md-font).icon-checkbox-marked:before {
    content: "\e70b"; }
  [class^="icon-"].icon-checkbox-multiple-blank-outline:before, [class*=" icon-"]:not(.md-font).icon-checkbox-multiple-blank-outline:before {
    content: "\e70c"; }
  [class^="icon-"].icon-checkbox-multiple-blank:before, [class*=" icon-"]:not(.md-font).icon-checkbox-multiple-blank:before {
    content: "\e70d"; }
  [class^="icon-"].icon-checkbox-multiple-marked-outline:before, [class*=" icon-"]:not(.md-font).icon-checkbox-multiple-marked-outline:before {
    content: "\e70e"; }
  [class^="icon-"].icon-checkbox-multiple-marked:before, [class*=" icon-"]:not(.md-font).icon-checkbox-multiple-marked:before {
    content: "\e70f"; }
  [class^="icon-"].icon-checkerboard:before, [class*=" icon-"]:not(.md-font).icon-checkerboard:before {
    content: "\e710"; }
  [class^="icon-"].icon-chevron-double-down:before, [class*=" icon-"]:not(.md-font).icon-chevron-double-down:before {
    content: "\e711"; }
  [class^="icon-"].icon-chevron-double-left:before, [class*=" icon-"]:not(.md-font).icon-chevron-double-left:before {
    content: "\e712"; }
  [class^="icon-"].icon-chevron-double-right:before, [class*=" icon-"]:not(.md-font).icon-chevron-double-right:before {
    content: "\e713"; }
  [class^="icon-"].icon-chevron-double-up:before, [class*=" icon-"]:not(.md-font).icon-chevron-double-up:before {
    content: "\e714"; }
  [class^="icon-"].icon-chevron-down:before, [class*=" icon-"]:not(.md-font).icon-chevron-down:before {
    content: "\e715"; }
  [class^="icon-"].icon-chevron-left:before, [class*=" icon-"]:not(.md-font).icon-chevron-left:before {
    content: "\e716"; }
  [class^="icon-"].icon-chevron-right:before, [class*=" icon-"]:not(.md-font).icon-chevron-right:before {
    content: "\e717"; }
  [class^="icon-"].icon-chevron-up:before, [class*=" icon-"]:not(.md-font).icon-chevron-up:before {
    content: "\e718"; }
  [class^="icon-"].icon-church:before, [class*=" icon-"]:not(.md-font).icon-church:before {
    content: "\e719"; }
  [class^="icon-"].icon-cisco-webex:before, [class*=" icon-"]:not(.md-font).icon-cisco-webex:before {
    content: "\e71a"; }
  [class^="icon-"].icon-city:before, [class*=" icon-"]:not(.md-font).icon-city:before {
    content: "\e71b"; }
  [class^="icon-"].icon-clapperboard:before, [class*=" icon-"]:not(.md-font).icon-clapperboard:before {
    content: "\e71c"; }
  [class^="icon-"].icon-clipboard-account:before, [class*=" icon-"]:not(.md-font).icon-clipboard-account:before {
    content: "\e71d"; }
  [class^="icon-"].icon-clipboard-alert:before, [class*=" icon-"]:not(.md-font).icon-clipboard-alert:before {
    content: "\e71e"; }
  [class^="icon-"].icon-clipboard-arrow-down:before, [class*=" icon-"]:not(.md-font).icon-clipboard-arrow-down:before {
    content: "\e71f"; }
  [class^="icon-"].icon-clipboard-arrow-left:before, [class*=" icon-"]:not(.md-font).icon-clipboard-arrow-left:before {
    content: "\e720"; }
  [class^="icon-"].icon-clipboard-check:before, [class*=" icon-"]:not(.md-font).icon-clipboard-check:before {
    content: "\e721"; }
  [class^="icon-"].icon-clipboard-outline:before, [class*=" icon-"]:not(.md-font).icon-clipboard-outline:before {
    content: "\e722"; }
  [class^="icon-"].icon-clipboard-text:before, [class*=" icon-"]:not(.md-font).icon-clipboard-text:before {
    content: "\e723"; }
  [class^="icon-"].icon-clipboard:before, [class*=" icon-"]:not(.md-font).icon-clipboard:before {
    content: "\e724"; }
  [class^="icon-"].icon-clippy:before, [class*=" icon-"]:not(.md-font).icon-clippy:before {
    content: "\e725"; }
  [class^="icon-"].icon-clock-fast:before, [class*=" icon-"]:not(.md-font).icon-clock-fast:before {
    content: "\e726"; }
  [class^="icon-"].icon-clock:before, [class*=" icon-"]:not(.md-font).icon-clock:before {
    content: "\e727"; }
  [class^="icon-"].icon-close-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-close-circle-outline:before {
    content: "\e728"; }
  [class^="icon-"].icon-close-circle:before, [class*=" icon-"]:not(.md-font).icon-close-circle:before {
    content: "\e729"; }
  [class^="icon-"].icon-close-network:before, [class*=" icon-"]:not(.md-font).icon-close-network:before {
    content: "\e72a"; }
  [class^="icon-"].icon-close:before, [class*=" icon-"]:not(.md-font).icon-close:before {
    content: "\e72b"; }
  [class^="icon-"].icon-closed-caption:before, [class*=" icon-"]:not(.md-font).icon-closed-caption:before {
    content: "\e72c"; }
  [class^="icon-"].icon-cloud-check:before, [class*=" icon-"]:not(.md-font).icon-cloud-check:before {
    content: "\e72d"; }
  [class^="icon-"].icon-cloud-circle:before, [class*=" icon-"]:not(.md-font).icon-cloud-circle:before {
    content: "\e72e"; }
  [class^="icon-"].icon-cloud-download:before, [class*=" icon-"]:not(.md-font).icon-cloud-download:before {
    content: "\e72f"; }
  [class^="icon-"].icon-cloud-outline-off:before, [class*=" icon-"]:not(.md-font).icon-cloud-outline-off:before {
    content: "\e730"; }
  [class^="icon-"].icon-cloud-outline:before, [class*=" icon-"]:not(.md-font).icon-cloud-outline:before {
    content: "\e731"; }
  [class^="icon-"].icon-cloud-upload:before, [class*=" icon-"]:not(.md-font).icon-cloud-upload:before {
    content: "\e732"; }
  [class^="icon-"].icon-cloud:before, [class*=" icon-"]:not(.md-font).icon-cloud:before {
    content: "\e733"; }
  [class^="icon-"].icon-code-array:before, [class*=" icon-"]:not(.md-font).icon-code-array:before {
    content: "\e734"; }
  [class^="icon-"].icon-code-braces:before, [class*=" icon-"]:not(.md-font).icon-code-braces:before {
    content: "\e735"; }
  [class^="icon-"].icon-code-equal:before, [class*=" icon-"]:not(.md-font).icon-code-equal:before {
    content: "\e736"; }
  [class^="icon-"].icon-code-greater-than-or-equal:before, [class*=" icon-"]:not(.md-font).icon-code-greater-than-or-equal:before {
    content: "\e737"; }
  [class^="icon-"].icon-code-greater-than:before, [class*=" icon-"]:not(.md-font).icon-code-greater-than:before {
    content: "\e738"; }
  [class^="icon-"].icon-code-less-than-or-equal:before, [class*=" icon-"]:not(.md-font).icon-code-less-than-or-equal:before {
    content: "\e739"; }
  [class^="icon-"].icon-code-less-than:before, [class*=" icon-"]:not(.md-font).icon-code-less-than:before {
    content: "\e73a"; }
  [class^="icon-"].icon-code-not-equal-variant:before, [class*=" icon-"]:not(.md-font).icon-code-not-equal-variant:before {
    content: "\e73b"; }
  [class^="icon-"].icon-code-not-equal:before, [class*=" icon-"]:not(.md-font).icon-code-not-equal:before {
    content: "\e73c"; }
  [class^="icon-"].icon-code-string:before, [class*=" icon-"]:not(.md-font).icon-code-string:before {
    content: "\e73d"; }
  [class^="icon-"].icon-code-tags:before, [class*=" icon-"]:not(.md-font).icon-code-tags:before {
    content: "\e73e"; }
  [class^="icon-"].icon-codepen:before, [class*=" icon-"]:not(.md-font).icon-codepen:before {
    content: "\e73f"; }
  [class^="icon-"].icon-coffee-to-go:before, [class*=" icon-"]:not(.md-font).icon-coffee-to-go:before {
    content: "\e740"; }
  [class^="icon-"].icon-coffee:before, [class*=" icon-"]:not(.md-font).icon-coffee:before {
    content: "\e741"; }
  [class^="icon-"].icon-cog-box:before, [class*=" icon-"]:not(.md-font).icon-cog-box:before {
    content: "\e742"; }
  [class^="icon-"].icon-cog:before, [class*=" icon-"]:not(.md-font).icon-cog:before {
    content: "\e743"; }
  [class^="icon-"].icon-coin:before, [class*=" icon-"]:not(.md-font).icon-coin:before {
    content: "\e744"; }
  [class^="icon-"].icon-color-helper:before, [class*=" icon-"]:not(.md-font).icon-color-helper:before {
    content: "\e745"; }
  [class^="icon-"].icon-comment-account-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-account-outline:before {
    content: "\e746"; }
  [class^="icon-"].icon-comment-account:before, [class*=" icon-"]:not(.md-font).icon-comment-account:before {
    content: "\e747"; }
  [class^="icon-"].icon-comment-alert-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-alert-outline:before {
    content: "\e748"; }
  [class^="icon-"].icon-comment-alert:before, [class*=" icon-"]:not(.md-font).icon-comment-alert:before {
    content: "\e749"; }
  [class^="icon-"].icon-comment-check-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-check-outline:before {
    content: "\e74a"; }
  [class^="icon-"].icon-comment-check:before, [class*=" icon-"]:not(.md-font).icon-comment-check:before {
    content: "\e74b"; }
  [class^="icon-"].icon-comment-multipe-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-multipe-outline:before {
    content: "\e74c"; }
  [class^="icon-"].icon-comment-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-outline:before {
    content: "\e74d"; }
  [class^="icon-"].icon-comment-plus-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-plus-outline:before {
    content: "\e74e"; }
  [class^="icon-"].icon-comment-processing-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-processing-outline:before {
    content: "\e74f"; }
  [class^="icon-"].icon-comment-processing:before, [class*=" icon-"]:not(.md-font).icon-comment-processing:before {
    content: "\e750"; }
  [class^="icon-"].icon-comment-remove-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-remove-outline:before {
    content: "\e751"; }
  [class^="icon-"].icon-comment-text-outline:before, [class*=" icon-"]:not(.md-font).icon-comment-text-outline:before {
    content: "\e752"; }
  [class^="icon-"].icon-comment-text:before, [class*=" icon-"]:not(.md-font).icon-comment-text:before {
    content: "\e753"; }
  [class^="icon-"].icon-comment:before, [class*=" icon-"]:not(.md-font).icon-comment:before {
    content: "\e754"; }
  [class^="icon-"].icon-compare:before, [class*=" icon-"]:not(.md-font).icon-compare:before {
    content: "\e755"; }
  [class^="icon-"].icon-compass-outline:before, [class*=" icon-"]:not(.md-font).icon-compass-outline:before {
    content: "\e756"; }
  [class^="icon-"].icon-compass:before, [class*=" icon-"]:not(.md-font).icon-compass:before {
    content: "\e757"; }
  [class^="icon-"].icon-console:before, [class*=" icon-"]:not(.md-font).icon-console:before {
    content: "\e758"; }
  [class^="icon-"].icon-content-copy:before, [class*=" icon-"]:not(.md-font).icon-content-copy:before {
    content: "\e759"; }
  [class^="icon-"].icon-content-cut:before, [class*=" icon-"]:not(.md-font).icon-content-cut:before {
    content: "\e75a"; }
  [class^="icon-"].icon-content-duplicate:before, [class*=" icon-"]:not(.md-font).icon-content-duplicate:before {
    content: "\e75b"; }
  [class^="icon-"].icon-content-paste:before, [class*=" icon-"]:not(.md-font).icon-content-paste:before {
    content: "\e75c"; }
  [class^="icon-"].icon-content-save-all:before, [class*=" icon-"]:not(.md-font).icon-content-save-all:before {
    content: "\e75d"; }
  [class^="icon-"].icon-content-save:before, [class*=" icon-"]:not(.md-font).icon-content-save:before {
    content: "\e75e"; }
  [class^="icon-"].icon-contrast-box:before, [class*=" icon-"]:not(.md-font).icon-contrast-box:before {
    content: "\e75f"; }
  [class^="icon-"].icon-contrast-circle:before, [class*=" icon-"]:not(.md-font).icon-contrast-circle:before {
    content: "\e760"; }
  [class^="icon-"].icon-contrast:before, [class*=" icon-"]:not(.md-font).icon-contrast:before {
    content: "\e761"; }
  [class^="icon-"].icon-controller-xbox:before, [class*=" icon-"]:not(.md-font).icon-controller-xbox:before {
    content: "\e762"; }
  [class^="icon-"].icon-cow:before, [class*=" icon-"]:not(.md-font).icon-cow:before {
    content: "\e763"; }
  [class^="icon-"].icon-credit-card-multiple:before, [class*=" icon-"]:not(.md-font).icon-credit-card-multiple:before {
    content: "\e764"; }
  [class^="icon-"].icon-credit-card:before, [class*=" icon-"]:not(.md-font).icon-credit-card:before {
    content: "\e765"; }
  [class^="icon-"].icon-crop-free:before, [class*=" icon-"]:not(.md-font).icon-crop-free:before {
    content: "\e766"; }
  [class^="icon-"].icon-crop-landscape:before, [class*=" icon-"]:not(.md-font).icon-crop-landscape:before {
    content: "\e767"; }
  [class^="icon-"].icon-crop-portrait:before, [class*=" icon-"]:not(.md-font).icon-crop-portrait:before {
    content: "\e768"; }
  [class^="icon-"].icon-crop-square:before, [class*=" icon-"]:not(.md-font).icon-crop-square:before {
    content: "\e769"; }
  [class^="icon-"].icon-crop:before, [class*=" icon-"]:not(.md-font).icon-crop:before {
    content: "\e76a"; }
  [class^="icon-"].icon-crosshairs-gps:before, [class*=" icon-"]:not(.md-font).icon-crosshairs-gps:before {
    content: "\e76b"; }
  [class^="icon-"].icon-crosshairs:before, [class*=" icon-"]:not(.md-font).icon-crosshairs:before {
    content: "\e76c"; }
  [class^="icon-"].icon-crown:before, [class*=" icon-"]:not(.md-font).icon-crown:before {
    content: "\e76d"; }
  [class^="icon-"].icon-cube-outline:before, [class*=" icon-"]:not(.md-font).icon-cube-outline:before {
    content: "\e76e"; }
  [class^="icon-"].icon-cube-unfolded:before, [class*=" icon-"]:not(.md-font).icon-cube-unfolded:before {
    content: "\e76f"; }
  [class^="icon-"].icon-cube:before, [class*=" icon-"]:not(.md-font).icon-cube:before {
    content: "\e770"; }
  [class^="icon-"].icon-cup-water:before, [class*=" icon-"]:not(.md-font).icon-cup-water:before {
    content: "\e771"; }
  [class^="icon-"].icon-cup:before, [class*=" icon-"]:not(.md-font).icon-cup:before {
    content: "\e772"; }
  [class^="icon-"].icon-currency-btc:before, [class*=" icon-"]:not(.md-font).icon-currency-btc:before {
    content: "\e773"; }
  [class^="icon-"].icon-currency-eur:before, [class*=" icon-"]:not(.md-font).icon-currency-eur:before {
    content: "\e774"; }
  [class^="icon-"].icon-currency-gbp:before, [class*=" icon-"]:not(.md-font).icon-currency-gbp:before {
    content: "\e775"; }
  [class^="icon-"].icon-currency-inr:before, [class*=" icon-"]:not(.md-font).icon-currency-inr:before {
    content: "\e776"; }
  [class^="icon-"].icon-currency-rub:before, [class*=" icon-"]:not(.md-font).icon-currency-rub:before {
    content: "\e777"; }
  [class^="icon-"].icon-currency-try:before, [class*=" icon-"]:not(.md-font).icon-currency-try:before {
    content: "\e778"; }
  [class^="icon-"].icon-currency-usd:before, [class*=" icon-"]:not(.md-font).icon-currency-usd:before {
    content: "\e779"; }
  [class^="icon-"].icon-cursor-default-outline:before, [class*=" icon-"]:not(.md-font).icon-cursor-default-outline:before {
    content: "\e77a"; }
  [class^="icon-"].icon-cursor-default:before, [class*=" icon-"]:not(.md-font).icon-cursor-default:before {
    content: "\e77b"; }
  [class^="icon-"].icon-cursor-move:before, [class*=" icon-"]:not(.md-font).icon-cursor-move:before {
    content: "\e77c"; }
  [class^="icon-"].icon-cursor-pointer:before, [class*=" icon-"]:not(.md-font).icon-cursor-pointer:before {
    content: "\e77d"; }
  [class^="icon-"].icon-data:before, [class*=" icon-"]:not(.md-font).icon-data:before {
    content: "\e77e"; }
  [class^="icon-"].icon-debug-step-into:before, [class*=" icon-"]:not(.md-font).icon-debug-step-into:before {
    content: "\e77f"; }
  [class^="icon-"].icon-debug-step-out:before, [class*=" icon-"]:not(.md-font).icon-debug-step-out:before {
    content: "\e780"; }
  [class^="icon-"].icon-debug-step-over:before, [class*=" icon-"]:not(.md-font).icon-debug-step-over:before {
    content: "\e781"; }
  [class^="icon-"].icon-decimal-decrease:before, [class*=" icon-"]:not(.md-font).icon-decimal-decrease:before {
    content: "\e782"; }
  [class^="icon-"].icon-decimal-increase:before, [class*=" icon-"]:not(.md-font).icon-decimal-increase:before {
    content: "\e783"; }
  [class^="icon-"].icon-delete-variant:before, [class*=" icon-"]:not(.md-font).icon-delete-variant:before {
    content: "\e784"; }
  [class^="icon-"].icon-delete:before, [class*=" icon-"]:not(.md-font).icon-delete:before {
    content: "\e785"; }
  [class^="icon-"].icon-deskphone:before, [class*=" icon-"]:not(.md-font).icon-deskphone:before {
    content: "\e786"; }
  [class^="icon-"].icon-desktop-mac:before, [class*=" icon-"]:not(.md-font).icon-desktop-mac:before {
    content: "\e787"; }
  [class^="icon-"].icon-desktop-tower:before, [class*=" icon-"]:not(.md-font).icon-desktop-tower:before {
    content: "\e788"; }
  [class^="icon-"].icon-details:before, [class*=" icon-"]:not(.md-font).icon-details:before {
    content: "\e789"; }
  [class^="icon-"].icon-deviantart:before, [class*=" icon-"]:not(.md-font).icon-deviantart:before {
    content: "\e78a"; }
  [class^="icon-"].icon-diamond:before, [class*=" icon-"]:not(.md-font).icon-diamond:before {
    content: "\e78b"; }
  [class^="icon-"].icon-dice-1:before, [class*=" icon-"]:not(.md-font).icon-dice-1:before {
    content: "\e78c"; }
  [class^="icon-"].icon-dice-2:before, [class*=" icon-"]:not(.md-font).icon-dice-2:before {
    content: "\e78d"; }
  [class^="icon-"].icon-dice-3:before, [class*=" icon-"]:not(.md-font).icon-dice-3:before {
    content: "\e78e"; }
  [class^="icon-"].icon-dice-4:before, [class*=" icon-"]:not(.md-font).icon-dice-4:before {
    content: "\e78f"; }
  [class^="icon-"].icon-dice-5:before, [class*=" icon-"]:not(.md-font).icon-dice-5:before {
    content: "\e790"; }
  [class^="icon-"].icon-dice-6:before, [class*=" icon-"]:not(.md-font).icon-dice-6:before {
    content: "\e791"; }
  [class^="icon-"].icon-dice:before, [class*=" icon-"]:not(.md-font).icon-dice:before {
    content: "\e792"; }
  [class^="icon-"].icon-directions:before, [class*=" icon-"]:not(.md-font).icon-directions:before {
    content: "\e793"; }
  [class^="icon-"].icon-disk-alert:before, [class*=" icon-"]:not(.md-font).icon-disk-alert:before {
    content: "\e794"; }
  [class^="icon-"].icon-disqus-outline:before, [class*=" icon-"]:not(.md-font).icon-disqus-outline:before {
    content: "\e795"; }
  [class^="icon-"].icon-disqus:before, [class*=" icon-"]:not(.md-font).icon-disqus:before {
    content: "\e796"; }
  [class^="icon-"].icon-division-box:before, [class*=" icon-"]:not(.md-font).icon-division-box:before {
    content: "\e797"; }
  [class^="icon-"].icon-division:before, [class*=" icon-"]:not(.md-font).icon-division:before {
    content: "\e798"; }
  [class^="icon-"].icon-dns:before, [class*=" icon-"]:not(.md-font).icon-dns:before {
    content: "\e799"; }
  [class^="icon-"].icon-document:before, [class*=" icon-"]:not(.md-font).icon-document:before {
    content: "\e79a"; }
  [class^="icon-"].icon-domain:before, [class*=" icon-"]:not(.md-font).icon-domain:before {
    content: "\e79b"; }
  [class^="icon-"].icon-dots-horizontal:before, [class*=" icon-"]:not(.md-font).icon-dots-horizontal:before {
    content: "\e79c"; }
  [class^="icon-"].icon-dots-vertical:before, [class*=" icon-"]:not(.md-font).icon-dots-vertical:before {
    content: "\e79d"; }
  [class^="icon-"].icon-download:before, [class*=" icon-"]:not(.md-font).icon-download:before {
    content: "\e79e"; }
  [class^="icon-"].icon-drag-horizontal:before, [class*=" icon-"]:not(.md-font).icon-drag-horizontal:before {
    content: "\e79f"; }
  [class^="icon-"].icon-drag-vertical:before, [class*=" icon-"]:not(.md-font).icon-drag-vertical:before {
    content: "\e7a0"; }
  [class^="icon-"].icon-drag:before, [class*=" icon-"]:not(.md-font).icon-drag:before {
    content: "\e7a1"; }
  [class^="icon-"].icon-drawing-box:before, [class*=" icon-"]:not(.md-font).icon-drawing-box:before {
    content: "\e7a2"; }
  [class^="icon-"].icon-drawing:before, [class*=" icon-"]:not(.md-font).icon-drawing:before {
    content: "\e7a3"; }
  [class^="icon-"].icon-dribbble-box:before, [class*=" icon-"]:not(.md-font).icon-dribbble-box:before {
    content: "\e7a4"; }
  [class^="icon-"].icon-dribbble:before, [class*=" icon-"]:not(.md-font).icon-dribbble:before {
    content: "\e7a5"; }
  [class^="icon-"].icon-drone:before, [class*=" icon-"]:not(.md-font).icon-drone:before {
    content: "\e7a6"; }
  [class^="icon-"].icon-dropbox:before, [class*=" icon-"]:not(.md-font).icon-dropbox:before {
    content: "\e7a7"; }
  [class^="icon-"].icon-drupal:before, [class*=" icon-"]:not(.md-font).icon-drupal:before {
    content: "\e7a8"; }
  [class^="icon-"].icon-duck:before, [class*=" icon-"]:not(.md-font).icon-duck:before {
    content: "\e7a9"; }
  [class^="icon-"].icon-dumbbell:before, [class*=" icon-"]:not(.md-font).icon-dumbbell:before {
    content: "\e7aa"; }
  [class^="icon-"].icon-earth-off:before, [class*=" icon-"]:not(.md-font).icon-earth-off:before {
    content: "\e7ab"; }
  [class^="icon-"].icon-earth:before, [class*=" icon-"]:not(.md-font).icon-earth:before {
    content: "\e7ac"; }
  [class^="icon-"].icon-ebay:before, [class*=" icon-"]:not(.md-font).icon-ebay:before {
    content: "\e7ad"; }
  [class^="icon-"].icon-edge:before, [class*=" icon-"]:not(.md-font).icon-edge:before {
    content: "\e7ae"; }
  [class^="icon-"].icon-eject:before, [class*=" icon-"]:not(.md-font).icon-eject:before {
    content: "\e7af"; }
  [class^="icon-"].icon-elevation-decline:before, [class*=" icon-"]:not(.md-font).icon-elevation-decline:before {
    content: "\e7b0"; }
  [class^="icon-"].icon-elevation-rise:before, [class*=" icon-"]:not(.md-font).icon-elevation-rise:before {
    content: "\e7b1"; }
  [class^="icon-"].icon-elevator:before, [class*=" icon-"]:not(.md-font).icon-elevator:before {
    content: "\e7b2"; }
  [class^="icon-"].icon-email-open:before, [class*=" icon-"]:not(.md-font).icon-email-open:before {
    content: "\e7b3"; }
  [class^="icon-"].icon-email-secure:before, [class*=" icon-"]:not(.md-font).icon-email-secure:before {
    content: "\e7b4"; }
  [class^="icon-"].icon-email:before, [class*=" icon-"]:not(.md-font).icon-email:before {
    content: "\e7b5"; }
  [class^="icon-"].icon-emoticon-cool:before, [class*=" icon-"]:not(.md-font).icon-emoticon-cool:before {
    content: "\e7b6"; }
  [class^="icon-"].icon-emoticon-devil:before, [class*=" icon-"]:not(.md-font).icon-emoticon-devil:before {
    content: "\e7b7"; }
  [class^="icon-"].icon-emoticon-happy:before, [class*=" icon-"]:not(.md-font).icon-emoticon-happy:before {
    content: "\e7b8"; }
  [class^="icon-"].icon-emoticon-neutral:before, [class*=" icon-"]:not(.md-font).icon-emoticon-neutral:before {
    content: "\e7b9"; }
  [class^="icon-"].icon-emoticon-poop:before, [class*=" icon-"]:not(.md-font).icon-emoticon-poop:before {
    content: "\e7ba"; }
  [class^="icon-"].icon-emoticon-sad:before, [class*=" icon-"]:not(.md-font).icon-emoticon-sad:before {
    content: "\e7bb"; }
  [class^="icon-"].icon-emoticon-tongue:before, [class*=" icon-"]:not(.md-font).icon-emoticon-tongue:before {
    content: "\e7bc"; }
  [class^="icon-"].icon-emoticon:before, [class*=" icon-"]:not(.md-font).icon-emoticon:before {
    content: "\e7bd"; }
  [class^="icon-"].icon-engine-outline:before, [class*=" icon-"]:not(.md-font).icon-engine-outline:before {
    content: "\e7be"; }
  [class^="icon-"].icon-engine:before, [class*=" icon-"]:not(.md-font).icon-engine:before {
    content: "\e7bf"; }
  [class^="icon-"].icon-equal-box:before, [class*=" icon-"]:not(.md-font).icon-equal-box:before {
    content: "\e7c0"; }
  [class^="icon-"].icon-equal:before, [class*=" icon-"]:not(.md-font).icon-equal:before {
    content: "\e7c1"; }
  [class^="icon-"].icon-eraser:before, [class*=" icon-"]:not(.md-font).icon-eraser:before {
    content: "\e7c2"; }
  [class^="icon-"].icon-escalator:before, [class*=" icon-"]:not(.md-font).icon-escalator:before {
    content: "\e7c3"; }
  [class^="icon-"].icon-etsy:before, [class*=" icon-"]:not(.md-font).icon-etsy:before {
    content: "\e7c4"; }
  [class^="icon-"].icon-evernote:before, [class*=" icon-"]:not(.md-font).icon-evernote:before {
    content: "\e7c5"; }
  [class^="icon-"].icon-exclamation:before, [class*=" icon-"]:not(.md-font).icon-exclamation:before {
    content: "\e7c6"; }
  [class^="icon-"].icon-exit-to-app:before, [class*=" icon-"]:not(.md-font).icon-exit-to-app:before {
    content: "\e7c7"; }
  [class^="icon-"].icon-export:before, [class*=" icon-"]:not(.md-font).icon-export:before {
    content: "\e7c8"; }
  [class^="icon-"].icon-eye-off:before, [class*=" icon-"]:not(.md-font).icon-eye-off:before {
    content: "\e7c9"; }
  [class^="icon-"].icon-eye:before, [class*=" icon-"]:not(.md-font).icon-eye:before {
    content: "\e7ca"; }
  [class^="icon-"].icon-eyedropper-variant:before, [class*=" icon-"]:not(.md-font).icon-eyedropper-variant:before {
    content: "\e7cb"; }
  [class^="icon-"].icon-eyedropper:before, [class*=" icon-"]:not(.md-font).icon-eyedropper:before {
    content: "\e7cc"; }
  [class^="icon-"].icon-facebook-box:before, [class*=" icon-"]:not(.md-font).icon-facebook-box:before {
    content: "\e7cd"; }
  [class^="icon-"].icon-facebook:before, [class*=" icon-"]:not(.md-font).icon-facebook:before {
    content: "\e7ce"; }
  [class^="icon-"].icon-factory:before, [class*=" icon-"]:not(.md-font).icon-factory:before {
    content: "\e7cf"; }
  [class^="icon-"].icon-fan:before, [class*=" icon-"]:not(.md-font).icon-fan:before {
    content: "\e7d0"; }
  [class^="icon-"].icon-fast-forward:before, [class*=" icon-"]:not(.md-font).icon-fast-forward:before {
    content: "\e7d1"; }
  [class^="icon-"].icon-ferry:before, [class*=" icon-"]:not(.md-font).icon-ferry:before {
    content: "\e7d2"; }
  [class^="icon-"].icon-file-cloud:before, [class*=" icon-"]:not(.md-font).icon-file-cloud:before {
    content: "\e7d3"; }
  [class^="icon-"].icon-file-delimited:before, [class*=" icon-"]:not(.md-font).icon-file-delimited:before {
    content: "\e7d4"; }
  [class^="icon-"].icon-file-document-box:before, [class*=" icon-"]:not(.md-font).icon-file-document-box:before {
    content: "\e7d5"; }
  [class^="icon-"].icon-file-document:before, [class*=" icon-"]:not(.md-font).icon-file-document:before {
    content: "\e7d6"; }
  [class^="icon-"].icon-file-excel-box:before, [class*=" icon-"]:not(.md-font).icon-file-excel-box:before {
    content: "\e7d7"; }
  [class^="icon-"].icon-file-excel:before, [class*=" icon-"]:not(.md-font).icon-file-excel:before {
    content: "\e7d8"; }
  [class^="icon-"].icon-file-find:before, [class*=" icon-"]:not(.md-font).icon-file-find:before {
    content: "\e7d9"; }
  [class^="icon-"].icon-file-hidden:before, [class*=" icon-"]:not(.md-font).icon-file-hidden:before {
    content: "\e7da"; }
  [class^="icon-"].icon-file-image-box:before, [class*=" icon-"]:not(.md-font).icon-file-image-box:before {
    content: "\e7db"; }
  [class^="icon-"].icon-file-image:before, [class*=" icon-"]:not(.md-font).icon-file-image:before {
    content: "\e7dc"; }
  [class^="icon-"].icon-file-multiple:before, [class*=" icon-"]:not(.md-font).icon-file-multiple:before {
    content: "\e7dd"; }
  [class^="icon-"].icon-file-music:before, [class*=" icon-"]:not(.md-font).icon-file-music:before {
    content: "\e7de"; }
  [class^="icon-"].icon-file-outline:before, [class*=" icon-"]:not(.md-font).icon-file-outline:before {
    content: "\e7df"; }
  [class^="icon-"].icon-file-pdf-box:before, [class*=" icon-"]:not(.md-font).icon-file-pdf-box:before {
    content: "\e7e0"; }
  [class^="icon-"].icon-file-pdf:before, [class*=" icon-"]:not(.md-font).icon-file-pdf:before {
    content: "\e7e1"; }
  [class^="icon-"].icon-file-powerpoint-box:before, [class*=" icon-"]:not(.md-font).icon-file-powerpoint-box:before {
    content: "\e7e2"; }
  [class^="icon-"].icon-file-powerpoint:before, [class*=" icon-"]:not(.md-font).icon-file-powerpoint:before {
    content: "\e7e3"; }
  [class^="icon-"].icon-file-presentation-box:before, [class*=" icon-"]:not(.md-font).icon-file-presentation-box:before {
    content: "\e7e4"; }
  [class^="icon-"].icon-file-video:before, [class*=" icon-"]:not(.md-font).icon-file-video:before {
    content: "\e7e5"; }
  [class^="icon-"].icon-file-word-box:before, [class*=" icon-"]:not(.md-font).icon-file-word-box:before {
    content: "\e7e6"; }
  [class^="icon-"].icon-file-word:before, [class*=" icon-"]:not(.md-font).icon-file-word:before {
    content: "\e7e7"; }
  [class^="icon-"].icon-file-xml:before, [class*=" icon-"]:not(.md-font).icon-file-xml:before {
    content: "\e7e8"; }
  [class^="icon-"].icon-file:before, [class*=" icon-"]:not(.md-font).icon-file:before {
    content: "\e7e9"; }
  [class^="icon-"].icon-fill:before, [class*=" icon-"]:not(.md-font).icon-fill:before {
    content: "\e7ea"; }
  [class^="icon-"].icon-film:before, [class*=" icon-"]:not(.md-font).icon-film:before {
    content: "\e7eb"; }
  [class^="icon-"].icon-filmstrip-off:before, [class*=" icon-"]:not(.md-font).icon-filmstrip-off:before {
    content: "\e7ec"; }
  [class^="icon-"].icon-filmstrip:before, [class*=" icon-"]:not(.md-font).icon-filmstrip:before {
    content: "\e7ed"; }
  [class^="icon-"].icon-filter-outline:before, [class*=" icon-"]:not(.md-font).icon-filter-outline:before {
    content: "\e7ee"; }
  [class^="icon-"].icon-filter-remove-outline:before, [class*=" icon-"]:not(.md-font).icon-filter-remove-outline:before {
    content: "\e7ef"; }
  [class^="icon-"].icon-filter-remove:before, [class*=" icon-"]:not(.md-font).icon-filter-remove:before {
    content: "\e7f0"; }
  [class^="icon-"].icon-filter-variant:before, [class*=" icon-"]:not(.md-font).icon-filter-variant:before {
    content: "\e7f1"; }
  [class^="icon-"].icon-filter:before, [class*=" icon-"]:not(.md-font).icon-filter:before {
    content: "\e7f2"; }
  [class^="icon-"].icon-fire:before, [class*=" icon-"]:not(.md-font).icon-fire:before {
    content: "\e7f3"; }
  [class^="icon-"].icon-fish:before, [class*=" icon-"]:not(.md-font).icon-fish:before {
    content: "\e7f4"; }
  [class^="icon-"].icon-flag-checkered-variant:before, [class*=" icon-"]:not(.md-font).icon-flag-checkered-variant:before {
    content: "\e7f5"; }
  [class^="icon-"].icon-flag-checkered:before, [class*=" icon-"]:not(.md-font).icon-flag-checkered:before {
    content: "\e7f6"; }
  [class^="icon-"].icon-flag-outline-variant:before, [class*=" icon-"]:not(.md-font).icon-flag-outline-variant:before {
    content: "\e7f7"; }
  [class^="icon-"].icon-flag-outline:before, [class*=" icon-"]:not(.md-font).icon-flag-outline:before {
    content: "\e7f8"; }
  [class^="icon-"].icon-flag-triangle:before, [class*=" icon-"]:not(.md-font).icon-flag-triangle:before {
    content: "\e7f9"; }
  [class^="icon-"].icon-flag-variant:before, [class*=" icon-"]:not(.md-font).icon-flag-variant:before {
    content: "\e7fa"; }
  [class^="icon-"].icon-flag:before, [class*=" icon-"]:not(.md-font).icon-flag:before {
    content: "\e7fb"; }
  [class^="icon-"].icon-flash-auto:before, [class*=" icon-"]:not(.md-font).icon-flash-auto:before {
    content: "\e7fc"; }
  [class^="icon-"].icon-flash-off:before, [class*=" icon-"]:not(.md-font).icon-flash-off:before {
    content: "\e7fd"; }
  [class^="icon-"].icon-flash:before, [class*=" icon-"]:not(.md-font).icon-flash:before {
    content: "\e7fe"; }
  [class^="icon-"].icon-flashlight-off:before, [class*=" icon-"]:not(.md-font).icon-flashlight-off:before {
    content: "\e7ff"; }
  [class^="icon-"].icon-flashlight:before, [class*=" icon-"]:not(.md-font).icon-flashlight:before {
    content: "\e800"; }
  [class^="icon-"].icon-flattr:before, [class*=" icon-"]:not(.md-font).icon-flattr:before {
    content: "\e801"; }
  [class^="icon-"].icon-flickr-after:before, [class*=" icon-"]:not(.md-font).icon-flickr-after:before {
    content: "\e802"; }
  [class^="icon-"].icon-flickr-before:before, [class*=" icon-"]:not(.md-font).icon-flickr-before:before {
    content: "\e803"; }
  [class^="icon-"].icon-flip-to-back:before, [class*=" icon-"]:not(.md-font).icon-flip-to-back:before {
    content: "\e804"; }
  [class^="icon-"].icon-flip-to-front:before, [class*=" icon-"]:not(.md-font).icon-flip-to-front:before {
    content: "\e805"; }
  [class^="icon-"].icon-floppy:before, [class*=" icon-"]:not(.md-font).icon-floppy:before {
    content: "\e806"; }
  [class^="icon-"].icon-flower:before, [class*=" icon-"]:not(.md-font).icon-flower:before {
    content: "\e807"; }
  [class^="icon-"].icon-folder-account:before, [class*=" icon-"]:not(.md-font).icon-folder-account:before {
    content: "\e808"; }
  [class^="icon-"].icon-folder-download:before, [class*=" icon-"]:not(.md-font).icon-folder-download:before {
    content: "\e809"; }
  [class^="icon-"].icon-folder-google-drive:before, [class*=" icon-"]:not(.md-font).icon-folder-google-drive:before {
    content: "\e80a"; }
  [class^="icon-"].icon-folder-image:before, [class*=" icon-"]:not(.md-font).icon-folder-image:before {
    content: "\e80b"; }
  [class^="icon-"].icon-folder-lock-open:before, [class*=" icon-"]:not(.md-font).icon-folder-lock-open:before {
    content: "\e80c"; }
  [class^="icon-"].icon-folder-lock:before, [class*=" icon-"]:not(.md-font).icon-folder-lock:before {
    content: "\e80d"; }
  [class^="icon-"].icon-folder-move:before, [class*=" icon-"]:not(.md-font).icon-folder-move:before {
    content: "\e80e"; }
  [class^="icon-"].icon-folder-multiple-image:before, [class*=" icon-"]:not(.md-font).icon-folder-multiple-image:before {
    content: "\e80f"; }
  [class^="icon-"].icon-folder-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-folder-multiple-outline:before {
    content: "\e810"; }
  [class^="icon-"].icon-folder-multiple:before, [class*=" icon-"]:not(.md-font).icon-folder-multiple:before {
    content: "\e811"; }
  [class^="icon-"].icon-folder-outline-lock:before, [class*=" icon-"]:not(.md-font).icon-folder-outline-lock:before {
    content: "\e812"; }
  [class^="icon-"].icon-folder-outline:before, [class*=" icon-"]:not(.md-font).icon-folder-outline:before {
    content: "\e813"; }
  [class^="icon-"].icon-folder-plus:before, [class*=" icon-"]:not(.md-font).icon-folder-plus:before {
    content: "\e814"; }
  [class^="icon-"].icon-folder-remove:before, [class*=" icon-"]:not(.md-font).icon-folder-remove:before {
    content: "\e815"; }
  [class^="icon-"].icon-folder-upload:before, [class*=" icon-"]:not(.md-font).icon-folder-upload:before {
    content: "\e816"; }
  [class^="icon-"].icon-folder:before, [class*=" icon-"]:not(.md-font).icon-folder:before {
    content: "\e817"; }
  [class^="icon-"].icon-food-apple:before, [class*=" icon-"]:not(.md-font).icon-food-apple:before {
    content: "\e818"; }
  [class^="icon-"].icon-food-variant:before, [class*=" icon-"]:not(.md-font).icon-food-variant:before {
    content: "\e819"; }
  [class^="icon-"].icon-food:before, [class*=" icon-"]:not(.md-font).icon-food:before {
    content: "\e81a"; }
  [class^="icon-"].icon-football-helmet:before, [class*=" icon-"]:not(.md-font).icon-football-helmet:before {
    content: "\e81b"; }
  [class^="icon-"].icon-football:before, [class*=" icon-"]:not(.md-font).icon-football:before {
    content: "\e81c"; }
  [class^="icon-"].icon-format-align-center:before, [class*=" icon-"]:not(.md-font).icon-format-align-center:before {
    content: "\e81d"; }
  [class^="icon-"].icon-format-align-justify:before, [class*=" icon-"]:not(.md-font).icon-format-align-justify:before {
    content: "\e81e"; }
  [class^="icon-"].icon-format-align-left:before, [class*=" icon-"]:not(.md-font).icon-format-align-left:before {
    content: "\e81f"; }
  [class^="icon-"].icon-format-align-right:before, [class*=" icon-"]:not(.md-font).icon-format-align-right:before {
    content: "\e820"; }
  [class^="icon-"].icon-format-bold:before, [class*=" icon-"]:not(.md-font).icon-format-bold:before {
    content: "\e821"; }
  [class^="icon-"].icon-format-clear:before, [class*=" icon-"]:not(.md-font).icon-format-clear:before {
    content: "\e822"; }
  [class^="icon-"].icon-format-color-fill:before, [class*=" icon-"]:not(.md-font).icon-format-color-fill:before {
    content: "\e823"; }
  [class^="icon-"].icon-format-color:before, [class*=" icon-"]:not(.md-font).icon-format-color:before {
    content: "\e824"; }
  [class^="icon-"].icon-format-float-center:before, [class*=" icon-"]:not(.md-font).icon-format-float-center:before {
    content: "\e825"; }
  [class^="icon-"].icon-format-float-left:before, [class*=" icon-"]:not(.md-font).icon-format-float-left:before {
    content: "\e826"; }
  [class^="icon-"].icon-format-float-none:before, [class*=" icon-"]:not(.md-font).icon-format-float-none:before {
    content: "\e827"; }
  [class^="icon-"].icon-format-float-right:before, [class*=" icon-"]:not(.md-font).icon-format-float-right:before {
    content: "\e828"; }
  [class^="icon-"].icon-format-header-1:before, [class*=" icon-"]:not(.md-font).icon-format-header-1:before {
    content: "\e829"; }
  [class^="icon-"].icon-format-header-2:before, [class*=" icon-"]:not(.md-font).icon-format-header-2:before {
    content: "\e82a"; }
  [class^="icon-"].icon-format-header-3:before, [class*=" icon-"]:not(.md-font).icon-format-header-3:before {
    content: "\e82b"; }
  [class^="icon-"].icon-format-header-4:before, [class*=" icon-"]:not(.md-font).icon-format-header-4:before {
    content: "\e82c"; }
  [class^="icon-"].icon-format-header-5:before, [class*=" icon-"]:not(.md-font).icon-format-header-5:before {
    content: "\e82d"; }
  [class^="icon-"].icon-format-header-6:before, [class*=" icon-"]:not(.md-font).icon-format-header-6:before {
    content: "\e82e"; }
  [class^="icon-"].icon-format-header-decrease:before, [class*=" icon-"]:not(.md-font).icon-format-header-decrease:before {
    content: "\e82f"; }
  [class^="icon-"].icon-format-header-down:before, [class*=" icon-"]:not(.md-font).icon-format-header-down:before {
    content: "\e830"; }
  [class^="icon-"].icon-format-header-equal:before, [class*=" icon-"]:not(.md-font).icon-format-header-equal:before {
    content: "\e831"; }
  [class^="icon-"].icon-format-header-increase:before, [class*=" icon-"]:not(.md-font).icon-format-header-increase:before {
    content: "\e832"; }
  [class^="icon-"].icon-format-header-pound:before, [class*=" icon-"]:not(.md-font).icon-format-header-pound:before {
    content: "\e833"; }
  [class^="icon-"].icon-format-header-up:before, [class*=" icon-"]:not(.md-font).icon-format-header-up:before {
    content: "\e834"; }
  [class^="icon-"].icon-format-indent-decrease:before, [class*=" icon-"]:not(.md-font).icon-format-indent-decrease:before {
    content: "\e835"; }
  [class^="icon-"].icon-format-indent-increase:before, [class*=" icon-"]:not(.md-font).icon-format-indent-increase:before {
    content: "\e836"; }
  [class^="icon-"].icon-format-italic:before, [class*=" icon-"]:not(.md-font).icon-format-italic:before {
    content: "\e837"; }
  [class^="icon-"].icon-format-line-spacing:before, [class*=" icon-"]:not(.md-font).icon-format-line-spacing:before {
    content: "\e838"; }
  [class^="icon-"].icon-format-list-bulleted:before, [class*=" icon-"]:not(.md-font).icon-format-list-bulleted:before {
    content: "\e839"; }
  [class^="icon-"].icon-format-list-numbers:before, [class*=" icon-"]:not(.md-font).icon-format-list-numbers:before {
    content: "\e83a"; }
  [class^="icon-"].icon-format-object-inline:before, [class*=" icon-"]:not(.md-font).icon-format-object-inline:before {
    content: "\e83b"; }
  [class^="icon-"].icon-format-object-square:before, [class*=" icon-"]:not(.md-font).icon-format-object-square:before {
    content: "\e83c"; }
  [class^="icon-"].icon-format-object-tight:before, [class*=" icon-"]:not(.md-font).icon-format-object-tight:before {
    content: "\e83d"; }
  [class^="icon-"].icon-format-object-top-bottom:before, [class*=" icon-"]:not(.md-font).icon-format-object-top-bottom:before {
    content: "\e83e"; }
  [class^="icon-"].icon-format-paint:before, [class*=" icon-"]:not(.md-font).icon-format-paint:before {
    content: "\e83f"; }
  [class^="icon-"].icon-format-paragraph:before, [class*=" icon-"]:not(.md-font).icon-format-paragraph:before {
    content: "\e840"; }
  [class^="icon-"].icon-format-quote:before, [class*=" icon-"]:not(.md-font).icon-format-quote:before {
    content: "\e841"; }
  [class^="icon-"].icon-format-size:before, [class*=" icon-"]:not(.md-font).icon-format-size:before {
    content: "\e842"; }
  [class^="icon-"].icon-format-strikethrough:before, [class*=" icon-"]:not(.md-font).icon-format-strikethrough:before {
    content: "\e843"; }
  [class^="icon-"].icon-format-subscript:before, [class*=" icon-"]:not(.md-font).icon-format-subscript:before {
    content: "\e844"; }
  [class^="icon-"].icon-format-superscript:before, [class*=" icon-"]:not(.md-font).icon-format-superscript:before {
    content: "\e845"; }
  [class^="icon-"].icon-format-text:before, [class*=" icon-"]:not(.md-font).icon-format-text:before {
    content: "\e846"; }
  [class^="icon-"].icon-format-textdirection-l-to-r:before, [class*=" icon-"]:not(.md-font).icon-format-textdirection-l-to-r:before {
    content: "\e847"; }
  [class^="icon-"].icon-format-textdirection-r-to-l:before, [class*=" icon-"]:not(.md-font).icon-format-textdirection-r-to-l:before {
    content: "\e848"; }
  [class^="icon-"].icon-format-underline:before, [class*=" icon-"]:not(.md-font).icon-format-underline:before {
    content: "\e849"; }
  [class^="icon-"].icon-forum:before, [class*=" icon-"]:not(.md-font).icon-forum:before {
    content: "\e84a"; }
  [class^="icon-"].icon-forward:before, [class*=" icon-"]:not(.md-font).icon-forward:before {
    content: "\e84b"; }
  [class^="icon-"].icon-foursquare:before, [class*=" icon-"]:not(.md-font).icon-foursquare:before {
    content: "\e84c"; }
  [class^="icon-"].icon-fridge:before, [class*=" icon-"]:not(.md-font).icon-fridge:before {
    content: "\e84d"; }
  [class^="icon-"].icon-fullscreen-exit:before, [class*=" icon-"]:not(.md-font).icon-fullscreen-exit:before {
    content: "\e84e"; }
  [class^="icon-"].icon-fullscreen:before, [class*=" icon-"]:not(.md-font).icon-fullscreen:before {
    content: "\e84f"; }
  [class^="icon-"].icon-function:before, [class*=" icon-"]:not(.md-font).icon-function:before {
    content: "\e850"; }
  [class^="icon-"].icon-gamepad-variant:before, [class*=" icon-"]:not(.md-font).icon-gamepad-variant:before {
    content: "\e851"; }
  [class^="icon-"].icon-gamepad:before, [class*=" icon-"]:not(.md-font).icon-gamepad:before {
    content: "\e852"; }
  [class^="icon-"].icon-gas-station:before, [class*=" icon-"]:not(.md-font).icon-gas-station:before {
    content: "\e853"; }
  [class^="icon-"].icon-gavel:before, [class*=" icon-"]:not(.md-font).icon-gavel:before {
    content: "\e854"; }
  [class^="icon-"].icon-gender-female:before, [class*=" icon-"]:not(.md-font).icon-gender-female:before {
    content: "\e855"; }
  [class^="icon-"].icon-gender-male-female:before, [class*=" icon-"]:not(.md-font).icon-gender-male-female:before {
    content: "\e856"; }
  [class^="icon-"].icon-gender-male:before, [class*=" icon-"]:not(.md-font).icon-gender-male:before {
    content: "\e857"; }
  [class^="icon-"].icon-gender-transgender:before, [class*=" icon-"]:not(.md-font).icon-gender-transgender:before {
    content: "\e858"; }
  [class^="icon-"].icon-gift:before, [class*=" icon-"]:not(.md-font).icon-gift:before {
    content: "\e859"; }
  [class^="icon-"].icon-git:before, [class*=" icon-"]:not(.md-font).icon-git:before {
    content: "\e85a"; }
  [class^="icon-"].icon-github-box:before, [class*=" icon-"]:not(.md-font).icon-github-box:before {
    content: "\e85b"; }
  [class^="icon-"].icon-github:before, [class*=" icon-"]:not(.md-font).icon-github:before {
    content: "\e85c"; }
  [class^="icon-"].icon-glass-flute:before, [class*=" icon-"]:not(.md-font).icon-glass-flute:before {
    content: "\e85d"; }
  [class^="icon-"].icon-glass-mug:before, [class*=" icon-"]:not(.md-font).icon-glass-mug:before {
    content: "\e85e"; }
  [class^="icon-"].icon-glass-stange:before, [class*=" icon-"]:not(.md-font).icon-glass-stange:before {
    content: "\e85f"; }
  [class^="icon-"].icon-glass-tulip:before, [class*=" icon-"]:not(.md-font).icon-glass-tulip:before {
    content: "\e860"; }
  [class^="icon-"].icon-glasses:before, [class*=" icon-"]:not(.md-font).icon-glasses:before {
    content: "\e861"; }
  [class^="icon-"].icon-gmail:before, [class*=" icon-"]:not(.md-font).icon-gmail:before {
    content: "\e862"; }
  [class^="icon-"].icon-google-chrome:before, [class*=" icon-"]:not(.md-font).icon-google-chrome:before {
    content: "\e863"; }
  [class^="icon-"].icon-google-circles-communities:before, [class*=" icon-"]:not(.md-font).icon-google-circles-communities:before {
    content: "\e864"; }
  [class^="icon-"].icon-google-circles-extended:before, [class*=" icon-"]:not(.md-font).icon-google-circles-extended:before {
    content: "\e865"; }
  [class^="icon-"].icon-google-circles-invite:before, [class*=" icon-"]:not(.md-font).icon-google-circles-invite:before {
    content: "\e866"; }
  [class^="icon-"].icon-google-circles:before, [class*=" icon-"]:not(.md-font).icon-google-circles:before {
    content: "\e867"; }
  [class^="icon-"].icon-google-controller-off:before, [class*=" icon-"]:not(.md-font).icon-google-controller-off:before {
    content: "\e868"; }
  [class^="icon-"].icon-google-controller:before, [class*=" icon-"]:not(.md-font).icon-google-controller:before {
    content: "\e869"; }
  [class^="icon-"].icon-google-drive:before, [class*=" icon-"]:not(.md-font).icon-google-drive:before {
    content: "\e86a"; }
  [class^="icon-"].icon-google-earth:before, [class*=" icon-"]:not(.md-font).icon-google-earth:before {
    content: "\e86b"; }
  [class^="icon-"].icon-google-glass:before, [class*=" icon-"]:not(.md-font).icon-google-glass:before {
    content: "\e86c"; }
  [class^="icon-"].icon-google-maps:before, [class*=" icon-"]:not(.md-font).icon-google-maps:before {
    content: "\e86d"; }
  [class^="icon-"].icon-google-pages:before, [class*=" icon-"]:not(.md-font).icon-google-pages:before {
    content: "\e86e"; }
  [class^="icon-"].icon-google-play:before, [class*=" icon-"]:not(.md-font).icon-google-play:before {
    content: "\e86f"; }
  [class^="icon-"].icon-google-plus-box:before, [class*=" icon-"]:not(.md-font).icon-google-plus-box:before {
    content: "\e870"; }
  [class^="icon-"].icon-google-plus:before, [class*=" icon-"]:not(.md-font).icon-google-plus:before {
    content: "\e871"; }
  [class^="icon-"].icon-google:before, [class*=" icon-"]:not(.md-font).icon-google:before {
    content: "\e872"; }
  [class^="icon-"].icon-grid-off:before, [class*=" icon-"]:not(.md-font).icon-grid-off:before {
    content: "\e873"; }
  [class^="icon-"].icon-grid:before, [class*=" icon-"]:not(.md-font).icon-grid:before {
    content: "\e874"; }
  [class^="icon-"].icon-group:before, [class*=" icon-"]:not(.md-font).icon-group:before {
    content: "\e875"; }
  [class^="icon-"].icon-hand-pointing-right:before, [class*=" icon-"]:not(.md-font).icon-hand-pointing-right:before {
    content: "\e876"; }
  [class^="icon-"].icon-hanger:before, [class*=" icon-"]:not(.md-font).icon-hanger:before {
    content: "\e877"; }
  [class^="icon-"].icon-hangouts:before, [class*=" icon-"]:not(.md-font).icon-hangouts:before {
    content: "\e878"; }
  [class^="icon-"].icon-harddisk:before, [class*=" icon-"]:not(.md-font).icon-harddisk:before {
    content: "\e879"; }
  [class^="icon-"].icon-headphones-box:before, [class*=" icon-"]:not(.md-font).icon-headphones-box:before {
    content: "\e87a"; }
  [class^="icon-"].icon-headphones-dots:before, [class*=" icon-"]:not(.md-font).icon-headphones-dots:before {
    content: "\e87b"; }
  [class^="icon-"].icon-headphones:before, [class*=" icon-"]:not(.md-font).icon-headphones:before {
    content: "\e87c"; }
  [class^="icon-"].icon-headset-dock:before, [class*=" icon-"]:not(.md-font).icon-headset-dock:before {
    content: "\e87d"; }
  [class^="icon-"].icon-headset-off:before, [class*=" icon-"]:not(.md-font).icon-headset-off:before {
    content: "\e87e"; }
  [class^="icon-"].icon-headset:before, [class*=" icon-"]:not(.md-font).icon-headset:before {
    content: "\e87f"; }
  [class^="icon-"].icon-heart-box-outline:before, [class*=" icon-"]:not(.md-font).icon-heart-box-outline:before {
    content: "\e880"; }
  [class^="icon-"].icon-heart-box:before, [class*=" icon-"]:not(.md-font).icon-heart-box:before {
    content: "\e881"; }
  [class^="icon-"].icon-heart-broken:before, [class*=" icon-"]:not(.md-font).icon-heart-broken:before {
    content: "\e882"; }
  [class^="icon-"].icon-heart-outline:before, [class*=" icon-"]:not(.md-font).icon-heart-outline:before {
    content: "\e883"; }
  [class^="icon-"].icon-heart:before, [class*=" icon-"]:not(.md-font).icon-heart:before {
    content: "\e884"; }
  [class^="icon-"].icon-help-circle:before, [class*=" icon-"]:not(.md-font).icon-help-circle:before {
    content: "\e885"; }
  [class^="icon-"].icon-help:before, .icon-event-4[class^="icon-"]:before, .icon-event-4:not(.md-font)[class*=" icon-"][class^="icon-"]:before, [class*=" icon-"]:not(.md-font).icon-help:before, .icon-event-4[class*=" icon-"]:not(.md-font):before {
    content: "\e886"; }
  [class^="icon-"].icon-hexagon-outline:before, [class*=" icon-"]:not(.md-font).icon-hexagon-outline:before {
    content: "\e887"; }
  [class^="icon-"].icon-hexagon:before, [class*=" icon-"]:not(.md-font).icon-hexagon:before {
    content: "\e888"; }
  [class^="icon-"].icon-history:before, [class*=" icon-"]:not(.md-font).icon-history:before {
    content: "\e889"; }
  [class^="icon-"].icon-hololens:before, [class*=" icon-"]:not(.md-font).icon-hololens:before {
    content: "\e88a"; }
  [class^="icon-"].icon-home-modern:before, [class*=" icon-"]:not(.md-font).icon-home-modern:before {
    content: "\e88b"; }
  [class^="icon-"].icon-home-variant:before, [class*=" icon-"]:not(.md-font).icon-home-variant:before {
    content: "\e88c"; }
  [class^="icon-"].icon-home:before, [class*=" icon-"]:not(.md-font).icon-home:before {
    content: "\e88d"; }
  [class^="icon-"].icon-hops:before, [class*=" icon-"]:not(.md-font).icon-hops:before {
    content: "\e88e"; }
  [class^="icon-"].icon-hospital-building:before, [class*=" icon-"]:not(.md-font).icon-hospital-building:before {
    content: "\e88f"; }
  [class^="icon-"].icon-hospital-marker:before, [class*=" icon-"]:not(.md-font).icon-hospital-marker:before {
    content: "\e890"; }
  [class^="icon-"].icon-hospital:before, [class*=" icon-"]:not(.md-font).icon-hospital:before {
    content: "\e891"; }
  [class^="icon-"].icon-hotel:before, [class*=" icon-"]:not(.md-font).icon-hotel:before {
    content: "\e892"; }
  [class^="icon-"].icon-houzz-box:before, [class*=" icon-"]:not(.md-font).icon-houzz-box:before {
    content: "\e893"; }
  [class^="icon-"].icon-human-child:before, [class*=" icon-"]:not(.md-font).icon-human-child:before {
    content: "\e894"; }
  [class^="icon-"].icon-human-male-female:before, [class*=" icon-"]:not(.md-font).icon-human-male-female:before {
    content: "\e895"; }
  [class^="icon-"].icon-human:before, [class*=" icon-"]:not(.md-font).icon-human:before {
    content: "\e896"; }
  [class^="icon-"].icon-image-album:before, [class*=" icon-"]:not(.md-font).icon-image-album:before {
    content: "\e897"; }
  [class^="icon-"].icon-image-area-close:before, [class*=" icon-"]:not(.md-font).icon-image-area-close:before {
    content: "\e898"; }
  [class^="icon-"].icon-image-area:before, [class*=" icon-"]:not(.md-font).icon-image-area:before {
    content: "\e899"; }
  [class^="icon-"].icon-image-broken:before, [class*=" icon-"]:not(.md-font).icon-image-broken:before {
    content: "\e89a"; }
  [class^="icon-"].icon-image-filter-black-white:before, [class*=" icon-"]:not(.md-font).icon-image-filter-black-white:before {
    content: "\e89b"; }
  [class^="icon-"].icon-image-filter-center-focus:before, [class*=" icon-"]:not(.md-font).icon-image-filter-center-focus:before {
    content: "\e89c"; }
  [class^="icon-"].icon-image-filter-drama:before, [class*=" icon-"]:not(.md-font).icon-image-filter-drama:before {
    content: "\e89d"; }
  [class^="icon-"].icon-image-filter-frames:before, [class*=" icon-"]:not(.md-font).icon-image-filter-frames:before {
    content: "\e89e"; }
  [class^="icon-"].icon-image-filter-hdr:before, [class*=" icon-"]:not(.md-font).icon-image-filter-hdr:before {
    content: "\e89f"; }
  [class^="icon-"].icon-image-filter-none:before, [class*=" icon-"]:not(.md-font).icon-image-filter-none:before {
    content: "\e8a0"; }
  [class^="icon-"].icon-image-filter-tilt-shift:before, [class*=" icon-"]:not(.md-font).icon-image-filter-tilt-shift:before {
    content: "\e8a1"; }
  [class^="icon-"].icon-image-filter-vintage:before, [class*=" icon-"]:not(.md-font).icon-image-filter-vintage:before {
    content: "\e8a2"; }
  [class^="icon-"].icon-image-filter:before, [class*=" icon-"]:not(.md-font).icon-image-filter:before {
    content: "\e8a3"; }
  [class^="icon-"].icon-image:before, [class*=" icon-"]:not(.md-font).icon-image:before {
    content: "\e8a4"; }
  [class^="icon-"].icon-import:before, [class*=" icon-"]:not(.md-font).icon-import:before {
    content: "\e8a5"; }
  [class^="icon-"].icon-inbox:before, [class*=" icon-"]:not(.md-font).icon-inbox:before {
    content: "\e8a6"; }
  [class^="icon-"].icon-indent:before, [class*=" icon-"]:not(.md-font).icon-indent:before {
    content: "\e8a7"; }
  [class^="icon-"].icon-information-outline:before, [class*=" icon-"]:not(.md-font).icon-information-outline:before {
    content: "\e8a8"; }
  [class^="icon-"].icon-information:before, [class*=" icon-"]:not(.md-font).icon-information:before {
    content: "\e8a9"; }
  [class^="icon-"].icon-instagram:before, [class*=" icon-"]:not(.md-font).icon-instagram:before {
    content: "\e8aa"; }
  [class^="icon-"].icon-instapaper:before, [class*=" icon-"]:not(.md-font).icon-instapaper:before {
    content: "\e8ab"; }
  [class^="icon-"].icon-internet-explorer:before, [class*=" icon-"]:not(.md-font).icon-internet-explorer:before {
    content: "\e8ac"; }
  [class^="icon-"].icon-invert-colors:before, [class*=" icon-"]:not(.md-font).icon-invert-colors:before {
    content: "\e8ad"; }
  [class^="icon-"].icon-jira:before, [class*=" icon-"]:not(.md-font).icon-jira:before {
    content: "\e8ae"; }
  [class^="icon-"].icon-jsfiddle:before, [class*=" icon-"]:not(.md-font).icon-jsfiddle:before {
    content: "\e8af"; }
  [class^="icon-"].icon-keg:before, [class*=" icon-"]:not(.md-font).icon-keg:before {
    content: "\e8b0"; }
  [class^="icon-"].icon-key-change:before, [class*=" icon-"]:not(.md-font).icon-key-change:before {
    content: "\e8b1"; }
  [class^="icon-"].icon-key-minus:before, [class*=" icon-"]:not(.md-font).icon-key-minus:before {
    content: "\e8b2"; }
  [class^="icon-"].icon-key-plus:before, [class*=" icon-"]:not(.md-font).icon-key-plus:before {
    content: "\e8b3"; }
  [class^="icon-"].icon-key-remove:before, [class*=" icon-"]:not(.md-font).icon-key-remove:before {
    content: "\e8b4"; }
  [class^="icon-"].icon-key-variant:before, [class*=" icon-"]:not(.md-font).icon-key-variant:before {
    content: "\e8b5"; }
  [class^="icon-"].icon-key:before, [class*=" icon-"]:not(.md-font).icon-key:before {
    content: "\e8b6"; }
  [class^="icon-"].icon-keyboard-backspace:before, [class*=" icon-"]:not(.md-font).icon-keyboard-backspace:before {
    content: "\e8b7"; }
  [class^="icon-"].icon-keyboard-caps:before, [class*=" icon-"]:not(.md-font).icon-keyboard-caps:before {
    content: "\e8b8"; }
  [class^="icon-"].icon-keyboard-close:before, [class*=" icon-"]:not(.md-font).icon-keyboard-close:before {
    content: "\e8b9"; }
  [class^="icon-"].icon-keyboard-off:before, [class*=" icon-"]:not(.md-font).icon-keyboard-off:before {
    content: "\e8ba"; }
  [class^="icon-"].icon-keyboard-return:before, [class*=" icon-"]:not(.md-font).icon-keyboard-return:before {
    content: "\e8bb"; }
  [class^="icon-"].icon-keyboard-tab:before, [class*=" icon-"]:not(.md-font).icon-keyboard-tab:before {
    content: "\e8bc"; }
  [class^="icon-"].icon-keyboard-variant:before, [class*=" icon-"]:not(.md-font).icon-keyboard-variant:before {
    content: "\e8bd"; }
  [class^="icon-"].icon-keyboard:before, [class*=" icon-"]:not(.md-font).icon-keyboard:before {
    content: "\e8be"; }
  [class^="icon-"].icon-label-outline:before, [class*=" icon-"]:not(.md-font).icon-label-outline:before {
    content: "\e8bf"; }
  [class^="icon-"].icon-label:before, [class*=" icon-"]:not(.md-font).icon-label:before {
    content: "\e8c0"; }
  [class^="icon-"].icon-language-csharp:before, [class*=" icon-"]:not(.md-font).icon-language-csharp:before {
    content: "\e8c1"; }
  [class^="icon-"].icon-language-css3:before, [class*=" icon-"]:not(.md-font).icon-language-css3:before {
    content: "\e8c2"; }
  [class^="icon-"].icon-language-html5:before, [class*=" icon-"]:not(.md-font).icon-language-html5:before {
    content: "\e8c3"; }
  [class^="icon-"].icon-language-javascript:before, [class*=" icon-"]:not(.md-font).icon-language-javascript:before {
    content: "\e8c4"; }
  [class^="icon-"].icon-language-python-text:before, [class*=" icon-"]:not(.md-font).icon-language-python-text:before {
    content: "\e8c5"; }
  [class^="icon-"].icon-language-python:before, [class*=" icon-"]:not(.md-font).icon-language-python:before {
    content: "\e8c6"; }
  [class^="icon-"].icon-laptop-chromebook:before, [class*=" icon-"]:not(.md-font).icon-laptop-chromebook:before {
    content: "\e8c7"; }
  [class^="icon-"].icon-laptop-mac:before, [class*=" icon-"]:not(.md-font).icon-laptop-mac:before {
    content: "\e8c8"; }
  [class^="icon-"].icon-laptop-windows:before, [class*=" icon-"]:not(.md-font).icon-laptop-windows:before {
    content: "\e8c9"; }
  [class^="icon-"].icon-laptop:before, [class*=" icon-"]:not(.md-font).icon-laptop:before {
    content: "\e8ca"; }
  [class^="icon-"].icon-lastfm:before, [class*=" icon-"]:not(.md-font).icon-lastfm:before {
    content: "\e8cb"; }
  [class^="icon-"].icon-launch:before, [class*=" icon-"]:not(.md-font).icon-launch:before {
    content: "\e8cc"; }
  [class^="icon-"].icon-layers-off:before, [class*=" icon-"]:not(.md-font).icon-layers-off:before {
    content: "\e8cd"; }
  [class^="icon-"].icon-layers:before, [class*=" icon-"]:not(.md-font).icon-layers:before {
    content: "\e8ce"; }
  [class^="icon-"].icon-leaf:before, [class*=" icon-"]:not(.md-font).icon-leaf:before {
    content: "\e8cf"; }
  [class^="icon-"].icon-library-books:before, [class*=" icon-"]:not(.md-font).icon-library-books:before {
    content: "\e8d0"; }
  [class^="icon-"].icon-library-music:before, [class*=" icon-"]:not(.md-font).icon-library-music:before {
    content: "\e8d1"; }
  [class^="icon-"].icon-library-plus:before, [class*=" icon-"]:not(.md-font).icon-library-plus:before {
    content: "\e8d2"; }
  [class^="icon-"].icon-library:before, [class*=" icon-"]:not(.md-font).icon-library:before {
    content: "\e8d3"; }
  [class^="icon-"].icon-lightbulb-outline:before, [class*=" icon-"]:not(.md-font).icon-lightbulb-outline:before {
    content: "\e8d4"; }
  [class^="icon-"].icon-lightbulb:before, [class*=" icon-"]:not(.md-font).icon-lightbulb:before {
    content: "\e8d5"; }
  [class^="icon-"].icon-link-off:before, [class*=" icon-"]:not(.md-font).icon-link-off:before {
    content: "\e8d6"; }
  [class^="icon-"].icon-link-variant-off:before, [class*=" icon-"]:not(.md-font).icon-link-variant-off:before {
    content: "\e8d7"; }
  [class^="icon-"].icon-link-variant:before, [class*=" icon-"]:not(.md-font).icon-link-variant:before {
    content: "\e8d8"; }
  [class^="icon-"].icon-link:before, [class*=" icon-"]:not(.md-font).icon-link:before {
    content: "\e8d9"; }
  [class^="icon-"].icon-linkedin-box:before, [class*=" icon-"]:not(.md-font).icon-linkedin-box:before {
    content: "\e8da"; }
  [class^="icon-"].icon-linkedin:before, [class*=" icon-"]:not(.md-font).icon-linkedin:before {
    content: "\e8db"; }
  [class^="icon-"].icon-linode:before, [class*=" icon-"]:not(.md-font).icon-linode:before {
    content: "\e8dc"; }
  [class^="icon-"].icon-linux:before, [class*=" icon-"]:not(.md-font).icon-linux:before {
    content: "\e8dd"; }
  [class^="icon-"].icon-lock-outline:before, [class*=" icon-"]:not(.md-font).icon-lock-outline:before {
    content: "\e8de"; }
  [class^="icon-"].icon-lock-unlocked-outline:before, [class*=" icon-"]:not(.md-font).icon-lock-unlocked-outline:before {
    content: "\e8df"; }
  [class^="icon-"].icon-lock-unlocked:before, [class*=" icon-"]:not(.md-font).icon-lock-unlocked:before {
    content: "\e8e0"; }
  [class^="icon-"].icon-lock:before, [class*=" icon-"]:not(.md-font).icon-lock:before {
    content: "\e8e1"; }
  [class^="icon-"].icon-login:before, [class*=" icon-"]:not(.md-font).icon-login:before {
    content: "\e8e2"; }
  [class^="icon-"].icon-logout:before, [class*=" icon-"]:not(.md-font).icon-logout:before {
    content: "\e8e3"; }
  [class^="icon-"].icon-looks:before, [class*=" icon-"]:not(.md-font).icon-looks:before {
    content: "\e8e4"; }
  [class^="icon-"].icon-loupe:before, [class*=" icon-"]:not(.md-font).icon-loupe:before {
    content: "\e8e5"; }
  [class^="icon-"].icon-lumx:before, [class*=" icon-"]:not(.md-font).icon-lumx:before {
    content: "\e8e6"; }
  [class^="icon-"].icon-magnet-on:before, [class*=" icon-"]:not(.md-font).icon-magnet-on:before {
    content: "\e8e7"; }
  [class^="icon-"].icon-magnet:before, [class*=" icon-"]:not(.md-font).icon-magnet:before {
    content: "\e8e8"; }
  [class^="icon-"].icon-magnify-minus:before, [class*=" icon-"]:not(.md-font).icon-magnify-minus:before {
    content: "\e8e9"; }
  [class^="icon-"].icon-magnify-plus:before, [class*=" icon-"]:not(.md-font).icon-magnify-plus:before {
    content: "\e8ea"; }
  [class^="icon-"].icon-magnify:before, [class*=" icon-"]:not(.md-font).icon-magnify:before {
    content: "\e8eb"; }
  [class^="icon-"].icon-mail-ru:before, [class*=" icon-"]:not(.md-font).icon-mail-ru:before {
    content: "\e8ec"; }
  [class^="icon-"].icon-map-marker-circle:before, [class*=" icon-"]:not(.md-font).icon-map-marker-circle:before {
    content: "\e8ed"; }
  [class^="icon-"].icon-map-marker-multiple:before, [class*=" icon-"]:not(.md-font).icon-map-marker-multiple:before {
    content: "\e8ee"; }
  [class^="icon-"].icon-map-marker-off:before, [class*=" icon-"]:not(.md-font).icon-map-marker-off:before {
    content: "\e8ef"; }
  [class^="icon-"].icon-map-marker-radius:before, [class*=" icon-"]:not(.md-font).icon-map-marker-radius:before {
    content: "\e8f0"; }
  [class^="icon-"].icon-map-marker:before, [class*=" icon-"]:not(.md-font).icon-map-marker:before {
    content: "\e8f1"; }
  [class^="icon-"].icon-map:before, [class*=" icon-"]:not(.md-font).icon-map:before {
    content: "\e8f2"; }
  [class^="icon-"].icon-margin:before, [class*=" icon-"]:not(.md-font).icon-margin:before {
    content: "\e8f3"; }
  [class^="icon-"].icon-markdown:before, [class*=" icon-"]:not(.md-font).icon-markdown:before {
    content: "\e8f4"; }
  [class^="icon-"].icon-marker-check:before, [class*=" icon-"]:not(.md-font).icon-marker-check:before {
    content: "\e8f5"; }
  [class^="icon-"].icon-martini:before, [class*=" icon-"]:not(.md-font).icon-martini:before {
    content: "\e8f6"; }
  [class^="icon-"].icon-material-ui:before, [class*=" icon-"]:not(.md-font).icon-material-ui:before {
    content: "\e8f7"; }
  [class^="icon-"].icon-maxcdn:before, [class*=" icon-"]:not(.md-font).icon-maxcdn:before {
    content: "\e8f8"; }
  [class^="icon-"].icon-medium:before, [class*=" icon-"]:not(.md-font).icon-medium:before {
    content: "\e8f9"; }
  [class^="icon-"].icon-memory:before, [class*=" icon-"]:not(.md-font).icon-memory:before {
    content: "\e8fa"; }
  [class^="icon-"].icon-menu-down:before, [class*=" icon-"]:not(.md-font).icon-menu-down:before {
    content: "\e8fb"; }
  [class^="icon-"].icon-menu-left:before, [class*=" icon-"]:not(.md-font).icon-menu-left:before {
    content: "\e8fc"; }
  [class^="icon-"].icon-menu-right:before, [class*=" icon-"]:not(.md-font).icon-menu-right:before {
    content: "\e8fd"; }
  [class^="icon-"].icon-menu-up:before, [class*=" icon-"]:not(.md-font).icon-menu-up:before {
    content: "\e8fe"; }
  [class^="icon-"].icon-menu:before, [class*=" icon-"]:not(.md-font).icon-menu:before {
    content: "\e8ff"; }
  [class^="icon-"].icon-message-alert:before, [class*=" icon-"]:not(.md-font).icon-message-alert:before {
    content: "\e900"; }
  [class^="icon-"].icon-message-draw:before, [class*=" icon-"]:not(.md-font).icon-message-draw:before {
    content: "\e901"; }
  [class^="icon-"].icon-message-image:before, [class*=" icon-"]:not(.md-font).icon-message-image:before {
    content: "\e902"; }
  [class^="icon-"].icon-message-processing:before, [class*=" icon-"]:not(.md-font).icon-message-processing:before {
    content: "\e903"; }
  [class^="icon-"].icon-message-reply:before, [class*=" icon-"]:not(.md-font).icon-message-reply:before {
    content: "\e904"; }
  [class^="icon-"].icon-message-text-outline:before, [class*=" icon-"]:not(.md-font).icon-message-text-outline:before {
    content: "\e905"; }
  [class^="icon-"].icon-message-text:before, [class*=" icon-"]:not(.md-font).icon-message-text:before {
    content: "\e906"; }
  [class^="icon-"].icon-message-video:before, [class*=" icon-"]:not(.md-font).icon-message-video:before {
    content: "\e907"; }
  [class^="icon-"].icon-message:before, [class*=" icon-"]:not(.md-font).icon-message:before {
    content: "\e908"; }
  [class^="icon-"].icon-microphone-dots:before, [class*=" icon-"]:not(.md-font).icon-microphone-dots:before {
    content: "\e909"; }
  [class^="icon-"].icon-microphone-off:before, [class*=" icon-"]:not(.md-font).icon-microphone-off:before {
    content: "\e90a"; }
  [class^="icon-"].icon-microphone-outline:before, [class*=" icon-"]:not(.md-font).icon-microphone-outline:before {
    content: "\e90b"; }
  [class^="icon-"].icon-microphone-settings:before, [class*=" icon-"]:not(.md-font).icon-microphone-settings:before {
    content: "\e90c"; }
  [class^="icon-"].icon-microphone-variant-off:before, [class*=" icon-"]:not(.md-font).icon-microphone-variant-off:before {
    content: "\e90d"; }
  [class^="icon-"].icon-microphone-variant:before, [class*=" icon-"]:not(.md-font).icon-microphone-variant:before {
    content: "\e90e"; }
  [class^="icon-"].icon-microphone:before, [class*=" icon-"]:not(.md-font).icon-microphone:before {
    content: "\e90f"; }
  [class^="icon-"].icon-minus-box:before, [class*=" icon-"]:not(.md-font).icon-minus-box:before {
    content: "\e910"; }
  [class^="icon-"].icon-minus-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-minus-circle-outline:before {
    content: "\e911"; }
  [class^="icon-"].icon-minus-circle:before, [class*=" icon-"]:not(.md-font).icon-minus-circle:before {
    content: "\e912"; }
  [class^="icon-"].icon-minus-network:before, [class*=" icon-"]:not(.md-font).icon-minus-network:before {
    content: "\e913"; }
  [class^="icon-"].icon-minus:before, [class*=" icon-"]:not(.md-font).icon-minus:before {
    content: "\e914"; }
  [class^="icon-"].icon-monitor-multiple:before, [class*=" icon-"]:not(.md-font).icon-monitor-multiple:before {
    content: "\e915"; }
  [class^="icon-"].icon-monitor:before, [class*=" icon-"]:not(.md-font).icon-monitor:before {
    content: "\e916"; }
  [class^="icon-"].icon-more:before, [class*=" icon-"]:not(.md-font).icon-more:before {
    content: "\e917"; }
  [class^="icon-"].icon-mouse-off:before, [class*=" icon-"]:not(.md-font).icon-mouse-off:before {
    content: "\e918"; }
  [class^="icon-"].icon-mouse-variant-off:before, [class*=" icon-"]:not(.md-font).icon-mouse-variant-off:before {
    content: "\e919"; }
  [class^="icon-"].icon-mouse-variant:before, [class*=" icon-"]:not(.md-font).icon-mouse-variant:before {
    content: "\e91a"; }
  [class^="icon-"].icon-mouse:before, [class*=" icon-"]:not(.md-font).icon-mouse:before {
    content: "\e91b"; }
  [class^="icon-"].icon-movie:before, [class*=" icon-"]:not(.md-font).icon-movie:before {
    content: "\e91c"; }
  [class^="icon-"].icon-multiplication-box:before, [class*=" icon-"]:not(.md-font).icon-multiplication-box:before {
    content: "\e91d"; }
  [class^="icon-"].icon-multiplication:before, [class*=" icon-"]:not(.md-font).icon-multiplication:before {
    content: "\e91e"; }
  [class^="icon-"].icon-music-box-outline:before, [class*=" icon-"]:not(.md-font).icon-music-box-outline:before {
    content: "\e91f"; }
  [class^="icon-"].icon-music-box:before, [class*=" icon-"]:not(.md-font).icon-music-box:before {
    content: "\e920"; }
  [class^="icon-"].icon-music-circle:before, [class*=" icon-"]:not(.md-font).icon-music-circle:before {
    content: "\e921"; }
  [class^="icon-"].icon-music-note-eighth:before, [class*=" icon-"]:not(.md-font).icon-music-note-eighth:before {
    content: "\e922"; }
  [class^="icon-"].icon-music-note-half:before, [class*=" icon-"]:not(.md-font).icon-music-note-half:before {
    content: "\e923"; }
  [class^="icon-"].icon-music-note-off:before, [class*=" icon-"]:not(.md-font).icon-music-note-off:before {
    content: "\e924"; }
  [class^="icon-"].icon-music-note-quarter:before, [class*=" icon-"]:not(.md-font).icon-music-note-quarter:before {
    content: "\e925"; }
  [class^="icon-"].icon-music-note-sixteenth:before, [class*=" icon-"]:not(.md-font).icon-music-note-sixteenth:before {
    content: "\e926"; }
  [class^="icon-"].icon-music-note-whole:before, [class*=" icon-"]:not(.md-font).icon-music-note-whole:before {
    content: "\e927"; }
  [class^="icon-"].icon-music-note:before, [class*=" icon-"]:not(.md-font).icon-music-note:before {
    content: "\e928"; }
  [class^="icon-"].icon-nature-people:before, [class*=" icon-"]:not(.md-font).icon-nature-people:before {
    content: "\e929"; }
  [class^="icon-"].icon-nature:before, [class*=" icon-"]:not(.md-font).icon-nature:before {
    content: "\e92a"; }
  [class^="icon-"].icon-navigation:before, [class*=" icon-"]:not(.md-font).icon-navigation:before {
    content: "\e92b"; }
  [class^="icon-"].icon-needle:before, [class*=" icon-"]:not(.md-font).icon-needle:before {
    content: "\e92c"; }
  [class^="icon-"].icon-nest-protect:before, [class*=" icon-"]:not(.md-font).icon-nest-protect:before {
    content: "\e92d"; }
  [class^="icon-"].icon-nest-thermostat:before, [class*=" icon-"]:not(.md-font).icon-nest-thermostat:before {
    content: "\e92e"; }
  [class^="icon-"].icon-newspaper:before, [class*=" icon-"]:not(.md-font).icon-newspaper:before {
    content: "\e92f"; }
  [class^="icon-"].icon-nfc-tap:before, [class*=" icon-"]:not(.md-font).icon-nfc-tap:before {
    content: "\e930"; }
  [class^="icon-"].icon-nfc-variant:before, [class*=" icon-"]:not(.md-font).icon-nfc-variant:before {
    content: "\e931"; }
  [class^="icon-"].icon-nfc:before, [class*=" icon-"]:not(.md-font).icon-nfc:before {
    content: "\e932"; }
  [class^="icon-"].icon-no:before, [class*=" icon-"]:not(.md-font).icon-no:before {
    content: "\e933"; }
  [class^="icon-"].icon-not-equal:before, [class*=" icon-"]:not(.md-font).icon-not-equal:before {
    content: "\e934"; }
  [class^="icon-"].icon-note-outline:before, [class*=" icon-"]:not(.md-font).icon-note-outline:before {
    content: "\e935"; }
  [class^="icon-"].icon-note-text:before, [class*=" icon-"]:not(.md-font).icon-note-text:before {
    content: "\e936"; }
  [class^="icon-"].icon-note:before, [class*=" icon-"]:not(.md-font).icon-note:before {
    content: "\e937"; }
  [class^="icon-"].icon-numeric-0-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-0-box-multiple-outline:before {
    content: "\e938"; }
  [class^="icon-"].icon-numeric-0-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-0-box-outline:before {
    content: "\e939"; }
  [class^="icon-"].icon-numeric-0-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-0-box:before {
    content: "\e93a"; }
  [class^="icon-"].icon-numeric-1-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-1-box-multiple-outline:before {
    content: "\e93b"; }
  [class^="icon-"].icon-numeric-1-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-1-box-outline:before {
    content: "\e93c"; }
  [class^="icon-"].icon-numeric-1-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-1-box:before {
    content: "\e93d"; }
  [class^="icon-"].icon-numeric-2-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-2-box-multiple-outline:before {
    content: "\e93e"; }
  [class^="icon-"].icon-numeric-2-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-2-box-outline:before {
    content: "\e93f"; }
  [class^="icon-"].icon-numeric-2-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-2-box:before {
    content: "\e940"; }
  [class^="icon-"].icon-numeric-3-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-3-box-multiple-outline:before {
    content: "\e941"; }
  [class^="icon-"].icon-numeric-3-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-3-box-outline:before {
    content: "\e942"; }
  [class^="icon-"].icon-numeric-3-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-3-box:before {
    content: "\e943"; }
  [class^="icon-"].icon-numeric-4-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-4-box-multiple-outline:before {
    content: "\e944"; }
  [class^="icon-"].icon-numeric-4-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-4-box-outline:before {
    content: "\e945"; }
  [class^="icon-"].icon-numeric-4-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-4-box:before {
    content: "\e946"; }
  [class^="icon-"].icon-numeric-5-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-5-box-multiple-outline:before {
    content: "\e947"; }
  [class^="icon-"].icon-numeric-5-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-5-box-outline:before {
    content: "\e948"; }
  [class^="icon-"].icon-numeric-5-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-5-box:before {
    content: "\e949"; }
  [class^="icon-"].icon-numeric-6-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-6-box-multiple-outline:before {
    content: "\e94a"; }
  [class^="icon-"].icon-numeric-6-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-6-box-outline:before {
    content: "\e94b"; }
  [class^="icon-"].icon-numeric-6-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-6-box:before {
    content: "\e94c"; }
  [class^="icon-"].icon-numeric-7-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-7-box-multiple-outline:before {
    content: "\e94d"; }
  [class^="icon-"].icon-numeric-7-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-7-box-outline:before {
    content: "\e94e"; }
  [class^="icon-"].icon-numeric-7-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-7-box:before {
    content: "\e94f"; }
  [class^="icon-"].icon-numeric-8-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-8-box-multiple-outline:before {
    content: "\e950"; }
  [class^="icon-"].icon-numeric-8-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-8-box-outline:before {
    content: "\e951"; }
  [class^="icon-"].icon-numeric-8-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-8-box:before {
    content: "\e952"; }
  [class^="icon-"].icon-numeric-9-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-9-box-multiple-outline:before {
    content: "\e953"; }
  [class^="icon-"].icon-numeric-9-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-9-box-outline:before {
    content: "\e954"; }
  [class^="icon-"].icon-numeric-9-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-9-box:before {
    content: "\e955"; }
  [class^="icon-"].icon-numeric-9-plus-box-multiple-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-9-plus-box-multiple-outline:before {
    content: "\e956"; }
  [class^="icon-"].icon-numeric-9-plus-box-outline:before, [class*=" icon-"]:not(.md-font).icon-numeric-9-plus-box-outline:before {
    content: "\e957"; }
  [class^="icon-"].icon-numeric-9-plus-box:before, [class*=" icon-"]:not(.md-font).icon-numeric-9-plus-box:before {
    content: "\e958"; }
  [class^="icon-"].icon-numeric:before, [class*=" icon-"]:not(.md-font).icon-numeric:before {
    content: "\e959"; }
  [class^="icon-"].icon-nutriton:before, [class*=" icon-"]:not(.md-font).icon-nutriton:before {
    content: "\e95a"; }
  [class^="icon-"].icon-odnoklassniki:before, [class*=" icon-"]:not(.md-font).icon-odnoklassniki:before {
    content: "\e95b"; }
  [class^="icon-"].icon-office:before, [class*=" icon-"]:not(.md-font).icon-office:before {
    content: "\e95c"; }
  [class^="icon-"].icon-oil:before, [class*=" icon-"]:not(.md-font).icon-oil:before {
    content: "\e95d"; }
  [class^="icon-"].icon-omega:before, [class*=" icon-"]:not(.md-font).icon-omega:before {
    content: "\e95e"; }
  [class^="icon-"].icon-onedrive:before, [class*=" icon-"]:not(.md-font).icon-onedrive:before {
    content: "\e95f"; }
  [class^="icon-"].icon-open-in-app:before, [class*=" icon-"]:not(.md-font).icon-open-in-app:before {
    content: "\e960"; }
  [class^="icon-"].icon-open-in-new:before, [class*=" icon-"]:not(.md-font).icon-open-in-new:before {
    content: "\e961"; }
  [class^="icon-"].icon-ornament-variant:before, [class*=" icon-"]:not(.md-font).icon-ornament-variant:before {
    content: "\e962"; }
  [class^="icon-"].icon-ornament:before, [class*=" icon-"]:not(.md-font).icon-ornament:before {
    content: "\e963"; }
  [class^="icon-"].icon-outbox:before, [class*=" icon-"]:not(.md-font).icon-outbox:before {
    content: "\e964"; }
  [class^="icon-"].icon-outdent:before, [class*=" icon-"]:not(.md-font).icon-outdent:before {
    content: "\e965"; }
  [class^="icon-"].icon-owl:before, [class*=" icon-"]:not(.md-font).icon-owl:before {
    content: "\e966"; }
  [class^="icon-"].icon-package-down:before, [class*=" icon-"]:not(.md-font).icon-package-down:before {
    content: "\e967"; }
  [class^="icon-"].icon-package-up:before, [class*=" icon-"]:not(.md-font).icon-package-up:before {
    content: "\e968"; }
  [class^="icon-"].icon-package-variant-closed:before, [class*=" icon-"]:not(.md-font).icon-package-variant-closed:before {
    content: "\e969"; }
  [class^="icon-"].icon-package-variant:before, [class*=" icon-"]:not(.md-font).icon-package-variant:before {
    content: "\e96a"; }
  [class^="icon-"].icon-package:before, [class*=" icon-"]:not(.md-font).icon-package:before {
    content: "\e96b"; }
  [class^="icon-"].icon-palette-advanced:before, [class*=" icon-"]:not(.md-font).icon-palette-advanced:before {
    content: "\e96c"; }
  [class^="icon-"].icon-palette:before, [class*=" icon-"]:not(.md-font).icon-palette:before {
    content: "\e96d"; }
  [class^="icon-"].icon-panda:before, [class*=" icon-"]:not(.md-font).icon-panda:before {
    content: "\e96e"; }
  [class^="icon-"].icon-pandora:before, [class*=" icon-"]:not(.md-font).icon-pandora:before {
    content: "\e96f"; }
  [class^="icon-"].icon-panorama-fisheye:before, [class*=" icon-"]:not(.md-font).icon-panorama-fisheye:before {
    content: "\e970"; }
  [class^="icon-"].icon-panorama-horizontal:before, [class*=" icon-"]:not(.md-font).icon-panorama-horizontal:before {
    content: "\e971"; }
  [class^="icon-"].icon-panorama-vertical:before, [class*=" icon-"]:not(.md-font).icon-panorama-vertical:before {
    content: "\e972"; }
  [class^="icon-"].icon-panorama-wide-angle:before, [class*=" icon-"]:not(.md-font).icon-panorama-wide-angle:before {
    content: "\e973"; }
  [class^="icon-"].icon-panorama:before, [class*=" icon-"]:not(.md-font).icon-panorama:before {
    content: "\e974"; }
  [class^="icon-"].icon-paper-cut-vertical:before, [class*=" icon-"]:not(.md-font).icon-paper-cut-vertical:before {
    content: "\e975"; }
  [class^="icon-"].icon-paperclip:before, [class*=" icon-"]:not(.md-font).icon-paperclip:before {
    content: "\e976"; }
  [class^="icon-"].icon-parking:before, [class*=" icon-"]:not(.md-font).icon-parking:before {
    content: "\e977"; }
  [class^="icon-"].icon-pause-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-pause-circle-outline:before {
    content: "\e978"; }
  [class^="icon-"].icon-pause-circle:before, [class*=" icon-"]:not(.md-font).icon-pause-circle:before {
    content: "\e979"; }
  [class^="icon-"].icon-pause-octagon-outline:before, [class*=" icon-"]:not(.md-font).icon-pause-octagon-outline:before {
    content: "\e97a"; }
  [class^="icon-"].icon-pause-octagon:before, [class*=" icon-"]:not(.md-font).icon-pause-octagon:before {
    content: "\e97b"; }
  [class^="icon-"].icon-pause:before, [class*=" icon-"]:not(.md-font).icon-pause:before {
    content: "\e97c"; }
  [class^="icon-"].icon-paw:before, [class*=" icon-"]:not(.md-font).icon-paw:before {
    content: "\e97d"; }
  [class^="icon-"].icon-pen:before, [class*=" icon-"]:not(.md-font).icon-pen:before {
    content: "\e97e"; }
  [class^="icon-"].icon-pencil-box-outline:before, [class*=" icon-"]:not(.md-font).icon-pencil-box-outline:before {
    content: "\e97f"; }
  [class^="icon-"].icon-pencil-box:before, [class*=" icon-"]:not(.md-font).icon-pencil-box:before {
    content: "\e980"; }
  [class^="icon-"].icon-pencil:before, [class*=" icon-"]:not(.md-font).icon-pencil:before {
    content: "\e981"; }
  [class^="icon-"].icon-people:before, [class*=" icon-"]:not(.md-font).icon-people:before {
    content: "\e982"; }
  [class^="icon-"].icon-percent:before, [class*=" icon-"]:not(.md-font).icon-percent:before {
    content: "\e983"; }
  [class^="icon-"].icon-person-box:before, [class*=" icon-"]:not(.md-font).icon-person-box:before {
    content: "\e984"; }
  [class^="icon-"].icon-person-minus:before, [class*=" icon-"]:not(.md-font).icon-person-minus:before {
    content: "\e985"; }
  [class^="icon-"].icon-person-plus:before, [class*=" icon-"]:not(.md-font).icon-person-plus:before {
    content: "\e986"; }
  [class^="icon-"].icon-pharmacy:before, [class*=" icon-"]:not(.md-font).icon-pharmacy:before {
    content: "\e987"; }
  [class^="icon-"].icon-phone-bluetooth:before, [class*=" icon-"]:not(.md-font).icon-phone-bluetooth:before {
    content: "\e988"; }
  [class^="icon-"].icon-phone-dots:before, [class*=" icon-"]:not(.md-font).icon-phone-dots:before {
    content: "\e989"; }
  [class^="icon-"].icon-phone-forward:before, [class*=" icon-"]:not(.md-font).icon-phone-forward:before {
    content: "\e98a"; }
  [class^="icon-"].icon-phone-hangup:before, [class*=" icon-"]:not(.md-font).icon-phone-hangup:before {
    content: "\e98b"; }
  [class^="icon-"].icon-phone-in-talk:before, [class*=" icon-"]:not(.md-font).icon-phone-in-talk:before {
    content: "\e98c"; }
  [class^="icon-"].icon-phone-incoming:before, [class*=" icon-"]:not(.md-font).icon-phone-incoming:before {
    content: "\e98d"; }
  [class^="icon-"].icon-phone-locked:before, [class*=" icon-"]:not(.md-font).icon-phone-locked:before {
    content: "\e98e"; }
  [class^="icon-"].icon-phone-log:before, [class*=" icon-"]:not(.md-font).icon-phone-log:before {
    content: "\e98f"; }
  [class^="icon-"].icon-phone-missed:before, [class*=" icon-"]:not(.md-font).icon-phone-missed:before {
    content: "\e990"; }
  [class^="icon-"].icon-phone-outgoing:before, [class*=" icon-"]:not(.md-font).icon-phone-outgoing:before {
    content: "\e991"; }
  [class^="icon-"].icon-phone-paused:before, [class*=" icon-"]:not(.md-font).icon-phone-paused:before {
    content: "\e992"; }
  [class^="icon-"].icon-phone:before, [class*=" icon-"]:not(.md-font).icon-phone:before {
    content: "\e993"; }
  [class^="icon-"].icon-picture:before, [class*=" icon-"]:not(.md-font).icon-picture:before {
    content: "\e994"; }
  [class^="icon-"].icon-pig:before, [class*=" icon-"]:not(.md-font).icon-pig:before {
    content: "\e995"; }
  [class^="icon-"].icon-pill:before, [class*=" icon-"]:not(.md-font).icon-pill:before {
    content: "\e996"; }
  [class^="icon-"].icon-pin-off:before, [class*=" icon-"]:not(.md-font).icon-pin-off:before {
    content: "\e997"; }
  [class^="icon-"].icon-pin:before, [class*=" icon-"]:not(.md-font).icon-pin:before {
    content: "\e998"; }
  [class^="icon-"].icon-pine-tree-box:before, [class*=" icon-"]:not(.md-font).icon-pine-tree-box:before {
    content: "\e999"; }
  [class^="icon-"].icon-pine-tree:before, [class*=" icon-"]:not(.md-font).icon-pine-tree:before {
    content: "\e99a"; }
  [class^="icon-"].icon-pinterest-box:before, [class*=" icon-"]:not(.md-font).icon-pinterest-box:before {
    content: "\e99b"; }
  [class^="icon-"].icon-pinterest:before, [class*=" icon-"]:not(.md-font).icon-pinterest:before {
    content: "\e99c"; }
  [class^="icon-"].icon-pizza:before, [class*=" icon-"]:not(.md-font).icon-pizza:before {
    content: "\e99d"; }
  [class^="icon-"].icon-plane:before, [class*=" icon-"]:not(.md-font).icon-plane:before {
    content: "\e99e"; }
  [class^="icon-"].icon-play-box-outline:before, [class*=" icon-"]:not(.md-font).icon-play-box-outline:before {
    content: "\e99f"; }
  [class^="icon-"].icon-play-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-play-circle-outline:before {
    content: "\e9a0"; }
  [class^="icon-"].icon-play-circle:before, [class*=" icon-"]:not(.md-font).icon-play-circle:before {
    content: "\e9a1"; }
  [class^="icon-"].icon-play:before, [class*=" icon-"]:not(.md-font).icon-play:before {
    content: "\e9a2"; }
  [class^="icon-"].icon-playlist-minus:before, [class*=" icon-"]:not(.md-font).icon-playlist-minus:before {
    content: "\e9a3"; }
  [class^="icon-"].icon-playlist-plus:before, [class*=" icon-"]:not(.md-font).icon-playlist-plus:before {
    content: "\e9a4"; }
  [class^="icon-"].icon-playstation:before, [class*=" icon-"]:not(.md-font).icon-playstation:before {
    content: "\e9a5"; }
  [class^="icon-"].icon-plus-box:before, [class*=" icon-"]:not(.md-font).icon-plus-box:before {
    content: "\e9a6"; }
  [class^="icon-"].icon-plus-circle-outline:before, [class*=" icon-"]:not(.md-font).icon-plus-circle-outline:before {
    content: "\e9a7"; }
  [class^="icon-"].icon-plus-circle:before, [class*=" icon-"]:not(.md-font).icon-plus-circle:before {
    content: "\e9a8"; }
  [class^="icon-"].icon-plus-network:before, [class*=" icon-"]:not(.md-font).icon-plus-network:before {
    content: "\e9a9"; }
  [class^="icon-"].icon-plus-one:before, [class*=" icon-"]:not(.md-font).icon-plus-one:before {
    content: "\e9aa"; }
  [class^="icon-"].icon-plus:before, [class*=" icon-"]:not(.md-font).icon-plus:before {
    content: "\e9ab"; }
  [class^="icon-"].icon-pocket:before, [class*=" icon-"]:not(.md-font).icon-pocket:before {
    content: "\e9ac"; }
  [class^="icon-"].icon-poll-box:before, [class*=" icon-"]:not(.md-font).icon-poll-box:before {
    content: "\e9ad"; }
  [class^="icon-"].icon-poll:before, [class*=" icon-"]:not(.md-font).icon-poll:before {
    content: "\e9ae"; }
  [class^="icon-"].icon-polymer:before, [class*=" icon-"]:not(.md-font).icon-polymer:before {
    content: "\e9af"; }
  [class^="icon-"].icon-popcorn:before, [class*=" icon-"]:not(.md-font).icon-popcorn:before {
    content: "\e9b0"; }
  [class^="icon-"].icon-pound-box:before, [class*=" icon-"]:not(.md-font).icon-pound-box:before {
    content: "\e9b1"; }
  [class^="icon-"].icon-pound:before, [class*=" icon-"]:not(.md-font).icon-pound:before {
    content: "\e9b2"; }
  [class^="icon-"].icon-power-settings:before, [class*=" icon-"]:not(.md-font).icon-power-settings:before {
    content: "\e9b3"; }
  [class^="icon-"].icon-power-socket:before, [class*=" icon-"]:not(.md-font).icon-power-socket:before {
    content: "\e9b4"; }
  [class^="icon-"].icon-power:before, [class*=" icon-"]:not(.md-font).icon-power:before {
    content: "\e9b5"; }
  [class^="icon-"].icon-presentation-play:before, [class*=" icon-"]:not(.md-font).icon-presentation-play:before {
    content: "\e9b6"; }
  [class^="icon-"].icon-presentation:before, [class*=" icon-"]:not(.md-font).icon-presentation:before {
    content: "\e9b7"; }
  [class^="icon-"].icon-printer-3d:before, [class*=" icon-"]:not(.md-font).icon-printer-3d:before {
    content: "\e9b8"; }
  [class^="icon-"].icon-printer:before, [class*=" icon-"]:not(.md-font).icon-printer:before {
    content: "\e9b9"; }
  [class^="icon-"].icon-pulse:before, [class*=" icon-"]:not(.md-font).icon-pulse:before {
    content: "\e9ba"; }
  [class^="icon-"].icon-puzzle:before, [class*=" icon-"]:not(.md-font).icon-puzzle:before {
    content: "\e9bb"; }
  [class^="icon-"].icon-qrcode:before, [class*=" icon-"]:not(.md-font).icon-qrcode:before {
    content: "\e9bc"; }
  [class^="icon-"].icon-quadcopter:before, [class*=" icon-"]:not(.md-font).icon-quadcopter:before {
    content: "\e9bd"; }
  [class^="icon-"].icon-quality-high:before, [class*=" icon-"]:not(.md-font).icon-quality-high:before {
    content: "\e9be"; }
  [class^="icon-"].icon-question-mark-circle:before, [class*=" icon-"]:not(.md-font).icon-question-mark-circle:before {
    content: "\e9bf"; }
  [class^="icon-"].icon-quick-reply:before, [class*=" icon-"]:not(.md-font).icon-quick-reply:before {
    content: "\e9c0"; }
  [class^="icon-"].icon-quicktime:before, [class*=" icon-"]:not(.md-font).icon-quicktime:before {
    content: "\e9c1"; }
  [class^="icon-"].icon-rabbit:before, [class*=" icon-"]:not(.md-font).icon-rabbit:before {
    content: "\e9c2"; }
  [class^="icon-"].icon-radiator:before, [class*=" icon-"]:not(.md-font).icon-radiator:before {
    content: "\e9c3"; }
  [class^="icon-"].icon-radio-tower:before, [class*=" icon-"]:not(.md-font).icon-radio-tower:before {
    content: "\e9c4"; }
  [class^="icon-"].icon-radio:before, [class*=" icon-"]:not(.md-font).icon-radio:before {
    content: "\e9c5"; }
  [class^="icon-"].icon-radioactive:before, [class*=" icon-"]:not(.md-font).icon-radioactive:before {
    content: "\e9c6"; }
  [class^="icon-"].icon-radiobox-blank:before, [class*=" icon-"]:not(.md-font).icon-radiobox-blank:before {
    content: "\e9c7"; }
  [class^="icon-"].icon-radiobox-marked:before, [class*=" icon-"]:not(.md-font).icon-radiobox-marked:before {
    content: "\e9c8"; }
  [class^="icon-"].icon-raspberrypi:before, [class*=" icon-"]:not(.md-font).icon-raspberrypi:before {
    content: "\e9c9"; }
  [class^="icon-"].icon-rdio:before, [class*=" icon-"]:not(.md-font).icon-rdio:before {
    content: "\e9ca"; }
  [class^="icon-"].icon-read:before, [class*=" icon-"]:not(.md-font).icon-read:before {
    content: "\e9cb"; }
  [class^="icon-"].icon-readability:before, [class*=" icon-"]:not(.md-font).icon-readability:before {
    content: "\e9cc"; }
  [class^="icon-"].icon-receipt:before, [class*=" icon-"]:not(.md-font).icon-receipt:before {
    content: "\e9cd"; }
  [class^="icon-"].icon-recycle:before, [class*=" icon-"]:not(.md-font).icon-recycle:before {
    content: "\e9ce"; }
  [class^="icon-"].icon-reddit:before, [class*=" icon-"]:not(.md-font).icon-reddit:before {
    content: "\e9cf"; }
  [class^="icon-"].icon-redo-variant:before, [class*=" icon-"]:not(.md-font).icon-redo-variant:before {
    content: "\e9d0"; }
  [class^="icon-"].icon-redo:before, [class*=" icon-"]:not(.md-font).icon-redo:before {
    content: "\e9d1"; }
  [class^="icon-"].icon-refresh:before, [class*=" icon-"]:not(.md-font).icon-refresh:before {
    content: "\e9d2"; }
  [class^="icon-"].icon-relative-scale:before, [class*=" icon-"]:not(.md-font).icon-relative-scale:before {
    content: "\e9d3"; }
  [class^="icon-"].icon-reload:before, [class*=" icon-"]:not(.md-font).icon-reload:before {
    content: "\e9d4"; }
  [class^="icon-"].icon-remote:before, [class*=" icon-"]:not(.md-font).icon-remote:before {
    content: "\e9d5"; }
  [class^="icon-"].icon-rename-box:before, [class*=" icon-"]:not(.md-font).icon-rename-box:before {
    content: "\e9d6"; }
  [class^="icon-"].icon-repeat-off:before, [class*=" icon-"]:not(.md-font).icon-repeat-off:before {
    content: "\e9d7"; }
  [class^="icon-"].icon-repeat-once:before, [class*=" icon-"]:not(.md-font).icon-repeat-once:before {
    content: "\e9d8"; }
  [class^="icon-"].icon-repeat:before, [class*=" icon-"]:not(.md-font).icon-repeat:before {
    content: "\e9d9"; }
  [class^="icon-"].icon-replay:before, [class*=" icon-"]:not(.md-font).icon-replay:before {
    content: "\e9da"; }
  [class^="icon-"].icon-reply-all:before, [class*=" icon-"]:not(.md-font).icon-reply-all:before {
    content: "\e9db"; }
  [class^="icon-"].icon-reply:before, [class*=" icon-"]:not(.md-font).icon-reply:before {
    content: "\e9dc"; }
  [class^="icon-"].icon-reproduction:before, [class*=" icon-"]:not(.md-font).icon-reproduction:before {
    content: "\e9dd"; }
  [class^="icon-"].icon-resize-bottom-right:before, [class*=" icon-"]:not(.md-font).icon-resize-bottom-right:before {
    content: "\e9de"; }
  [class^="icon-"].icon-responsive:before, [class*=" icon-"]:not(.md-font).icon-responsive:before {
    content: "\e9df"; }
  [class^="icon-"].icon-restore:before, [class*=" icon-"]:not(.md-font).icon-restore:before {
    content: "\e9e0"; }
  [class^="icon-"].icon-rewind:before, [class*=" icon-"]:not(.md-font).icon-rewind:before {
    content: "\e9e1"; }
  [class^="icon-"].icon-ribbon:before, [class*=" icon-"]:not(.md-font).icon-ribbon:before {
    content: "\e9e2"; }
  [class^="icon-"].icon-road:before, [class*=" icon-"]:not(.md-font).icon-road:before {
    content: "\e9e3"; }
  [class^="icon-"].icon-rocket:before, [class*=" icon-"]:not(.md-font).icon-rocket:before {
    content: "\e9e4"; }
  [class^="icon-"].icon-rotate-3d:before, [class*=" icon-"]:not(.md-font).icon-rotate-3d:before {
    content: "\e9e5"; }
  [class^="icon-"].icon-rotate-left-variant:before, [class*=" icon-"]:not(.md-font).icon-rotate-left-variant:before {
    content: "\e9e6"; }
  [class^="icon-"].icon-rotate-left:before, [class*=" icon-"]:not(.md-font).icon-rotate-left:before {
    content: "\e9e7"; }
  [class^="icon-"].icon-rotate-right-variant:before, [class*=" icon-"]:not(.md-font).icon-rotate-right-variant:before {
    content: "\e9e8"; }
  [class^="icon-"].icon-rotate-right:before, [class*=" icon-"]:not(.md-font).icon-rotate-right:before {
    content: "\e9e9"; }
  [class^="icon-"].icon-routes:before, [class*=" icon-"]:not(.md-font).icon-routes:before {
    content: "\e9ea"; }
  [class^="icon-"].icon-rss-box:before, [class*=" icon-"]:not(.md-font).icon-rss-box:before {
    content: "\e9eb"; }
  [class^="icon-"].icon-ruler:before, [class*=" icon-"]:not(.md-font).icon-ruler:before {
    content: "\e9ec"; }
  [class^="icon-"].icon-run:before, [class*=" icon-"]:not(.md-font).icon-run:before {
    content: "\e9ed"; }
  [class^="icon-"].icon-sale:before, [class*=" icon-"]:not(.md-font).icon-sale:before {
    content: "\e9ee"; }
  [class^="icon-"].icon-satellite-variant:before, [class*=" icon-"]:not(.md-font).icon-satellite-variant:before {
    content: "\e9ef"; }
  [class^="icon-"].icon-satellite:before, [class*=" icon-"]:not(.md-font).icon-satellite:before {
    content: "\e9f0"; }
  [class^="icon-"].icon-scale-bathroom:before, [class*=" icon-"]:not(.md-font).icon-scale-bathroom:before {
    content: "\e9f1"; }
  [class^="icon-"].icon-scale:before, [class*=" icon-"]:not(.md-font).icon-scale:before {
    content: "\e9f2"; }
  [class^="icon-"].icon-school:before, [class*=" icon-"]:not(.md-font).icon-school:before {
    content: "\e9f3"; }
  [class^="icon-"].icon-screen-rotation-lock:before, [class*=" icon-"]:not(.md-font).icon-screen-rotation-lock:before {
    content: "\e9f4"; }
  [class^="icon-"].icon-screen-rotation:before, [class*=" icon-"]:not(.md-font).icon-screen-rotation:before {
    content: "\e9f5"; }
  [class^="icon-"].icon-script:before, [class*=" icon-"]:not(.md-font).icon-script:before {
    content: "\e9f6"; }
  [class^="icon-"].icon-security-network:before, [class*=" icon-"]:not(.md-font).icon-security-network:before {
    content: "\e9f7"; }
  [class^="icon-"].icon-security:before, [class*=" icon-"]:not(.md-font).icon-security:before {
    content: "\e9f8"; }
  [class^="icon-"].icon-select-all:before, [class*=" icon-"]:not(.md-font).icon-select-all:before {
    content: "\e9f9"; }
  [class^="icon-"].icon-select-inverse:before, [class*=" icon-"]:not(.md-font).icon-select-inverse:before {
    content: "\e9fa"; }
  [class^="icon-"].icon-select-off:before, [class*=" icon-"]:not(.md-font).icon-select-off:before {
    content: "\e9fb"; }
  [class^="icon-"].icon-select:before, [class*=" icon-"]:not(.md-font).icon-select:before {
    content: "\e9fc"; }
  [class^="icon-"].icon-send:before, [class*=" icon-"]:not(.md-font).icon-send:before {
    content: "\e9fd"; }
  [class^="icon-"].icon-server-minus:before, [class*=" icon-"]:not(.md-font).icon-server-minus:before {
    content: "\e9fe"; }
  [class^="icon-"].icon-server-network-off:before, [class*=" icon-"]:not(.md-font).icon-server-network-off:before {
    content: "\e9ff"; }
  [class^="icon-"].icon-server-network:before, [class*=" icon-"]:not(.md-font).icon-server-network:before {
    content: "\ea00"; }
  [class^="icon-"].icon-server-off:before, [class*=" icon-"]:not(.md-font).icon-server-off:before {
    content: "\ea01"; }
  [class^="icon-"].icon-server-plus:before, [class*=" icon-"]:not(.md-font).icon-server-plus:before {
    content: "\ea02"; }
  [class^="icon-"].icon-server-remove:before, [class*=" icon-"]:not(.md-font).icon-server-remove:before {
    content: "\ea03"; }
  [class^="icon-"].icon-server-security:before, [class*=" icon-"]:not(.md-font).icon-server-security:before {
    content: "\ea04"; }
  [class^="icon-"].icon-server:before, [class*=" icon-"]:not(.md-font).icon-server:before {
    content: "\ea05"; }
  [class^="icon-"].icon-shape-plus:before, [class*=" icon-"]:not(.md-font).icon-shape-plus:before {
    content: "\ea06"; }
  [class^="icon-"].icon-share-variant:before, [class*=" icon-"]:not(.md-font).icon-share-variant:before {
    content: "\ea07"; }
  [class^="icon-"].icon-share:before, [class*=" icon-"]:not(.md-font).icon-share:before {
    content: "\ea08"; }
  [class^="icon-"].icon-shield-outline:before, [class*=" icon-"]:not(.md-font).icon-shield-outline:before {
    content: "\ea09"; }
  [class^="icon-"].icon-shield:before, [class*=" icon-"]:not(.md-font).icon-shield:before {
    content: "\ea0a"; }
  [class^="icon-"].icon-shopping-music:before, [class*=" icon-"]:not(.md-font).icon-shopping-music:before {
    content: "\ea0b"; }
  [class^="icon-"].icon-shopping:before, [class*=" icon-"]:not(.md-font).icon-shopping:before {
    content: "\ea0c"; }
  [class^="icon-"].icon-shuffle:before, [class*=" icon-"]:not(.md-font).icon-shuffle:before {
    content: "\ea0d"; }
  [class^="icon-"].icon-sigma:before, [class*=" icon-"]:not(.md-font).icon-sigma:before {
    content: "\ea0e"; }
  [class^="icon-"].icon-sign-caution:before, [class*=" icon-"]:not(.md-font).icon-sign-caution:before {
    content: "\ea0f"; }
  [class^="icon-"].icon-signal:before, [class*=" icon-"]:not(.md-font).icon-signal:before {
    content: "\ea10"; }
  [class^="icon-"].icon-silverware-fork:before, [class*=" icon-"]:not(.md-font).icon-silverware-fork:before {
    content: "\ea11"; }
  [class^="icon-"].icon-silverware-spoon:before, [class*=" icon-"]:not(.md-font).icon-silverware-spoon:before {
    content: "\ea12"; }
  [class^="icon-"].icon-silverware-variant:before, [class*=" icon-"]:not(.md-font).icon-silverware-variant:before {
    content: "\ea13"; }
  [class^="icon-"].icon-silverware:before, [class*=" icon-"]:not(.md-font).icon-silverware:before {
    content: "\ea14"; }
  [class^="icon-"].icon-sim-alert:before, [class*=" icon-"]:not(.md-font).icon-sim-alert:before {
    content: "\ea15"; }
  [class^="icon-"].icon-sim:before, [class*=" icon-"]:not(.md-font).icon-sim:before {
    content: "\ea16"; }
  [class^="icon-"].icon-sitemap:before, [class*=" icon-"]:not(.md-font).icon-sitemap:before {
    content: "\ea17"; }
  [class^="icon-"].icon-skip-next:before, [class*=" icon-"]:not(.md-font).icon-skip-next:before {
    content: "\ea18"; }
  [class^="icon-"].icon-skip-previous:before, [class*=" icon-"]:not(.md-font).icon-skip-previous:before {
    content: "\ea19"; }
  [class^="icon-"].icon-skype-business:before, [class*=" icon-"]:not(.md-font).icon-skype-business:before {
    content: "\ea1a"; }
  [class^="icon-"].icon-skype:before, [class*=" icon-"]:not(.md-font).icon-skype:before {
    content: "\ea1b"; }
  [class^="icon-"].icon-sleep-off:before, [class*=" icon-"]:not(.md-font).icon-sleep-off:before {
    content: "\ea1c"; }
  [class^="icon-"].icon-sleep:before, [class*=" icon-"]:not(.md-font).icon-sleep:before {
    content: "\ea1d"; }
  [class^="icon-"].icon-smoking-off:before, [class*=" icon-"]:not(.md-font).icon-smoking-off:before {
    content: "\ea1e"; }
  [class^="icon-"].icon-smoking:before, [class*=" icon-"]:not(.md-font).icon-smoking:before {
    content: "\ea1f"; }
  [class^="icon-"].icon-snapchat:before, [class*=" icon-"]:not(.md-font).icon-snapchat:before {
    content: "\ea20"; }
  [class^="icon-"].icon-snowman:before, [class*=" icon-"]:not(.md-font).icon-snowman:before {
    content: "\ea21"; }
  [class^="icon-"].icon-sofa:before, [class*=" icon-"]:not(.md-font).icon-sofa:before {
    content: "\ea22"; }
  [class^="icon-"].icon-sort-alphabetical:before, [class*=" icon-"]:not(.md-font).icon-sort-alphabetical:before {
    content: "\ea23"; }
  [class^="icon-"].icon-sort-ascending:before, [class*=" icon-"]:not(.md-font).icon-sort-ascending:before {
    content: "\ea24"; }
  [class^="icon-"].icon-sort-descending:before, [class*=" icon-"]:not(.md-font).icon-sort-descending:before {
    content: "\ea25"; }
  [class^="icon-"].icon-sort-numeric:before, [class*=" icon-"]:not(.md-font).icon-sort-numeric:before {
    content: "\ea26"; }
  [class^="icon-"].icon-sort-variant:before, [class*=" icon-"]:not(.md-font).icon-sort-variant:before {
    content: "\ea27"; }
  [class^="icon-"].icon-sort:before, [class*=" icon-"]:not(.md-font).icon-sort:before {
    content: "\ea28"; }
  [class^="icon-"].icon-soundcloud:before, [class*=" icon-"]:not(.md-font).icon-soundcloud:before {
    content: "\ea29"; }
  [class^="icon-"].icon-source-fork:before, [class*=" icon-"]:not(.md-font).icon-source-fork:before {
    content: "\ea2a"; }
  [class^="icon-"].icon-source-pull:before, [class*=" icon-"]:not(.md-font).icon-source-pull:before {
    content: "\ea2b"; }
  [class^="icon-"].icon-speaker-off:before, [class*=" icon-"]:not(.md-font).icon-speaker-off:before {
    content: "\ea2c"; }
  [class^="icon-"].icon-speaker:before, [class*=" icon-"]:not(.md-font).icon-speaker:before {
    content: "\ea2d"; }
  [class^="icon-"].icon-speedometer:before, [class*=" icon-"]:not(.md-font).icon-speedometer:before {
    content: "\ea2e"; }
  [class^="icon-"].icon-spellcheck:before, [class*=" icon-"]:not(.md-font).icon-spellcheck:before {
    content: "\ea2f"; }
  [class^="icon-"].icon-spotify:before, [class*=" icon-"]:not(.md-font).icon-spotify:before {
    content: "\ea30"; }
  [class^="icon-"].icon-spotlight-beam:before, [class*=" icon-"]:not(.md-font).icon-spotlight-beam:before {
    content: "\ea31"; }
  [class^="icon-"].icon-spotlight:before, [class*=" icon-"]:not(.md-font).icon-spotlight:before {
    content: "\ea32"; }
  [class^="icon-"].icon-spreadsheet:before, [class*=" icon-"]:not(.md-font).icon-spreadsheet:before {
    content: "\ea33"; }
  [class^="icon-"].icon-square-inc-cash:before, [class*=" icon-"]:not(.md-font).icon-square-inc-cash:before {
    content: "\ea34"; }
  [class^="icon-"].icon-square-inc:before, [class*=" icon-"]:not(.md-font).icon-square-inc:before {
    content: "\ea35"; }
  [class^="icon-"].icon-stackoverflow:before, [class*=" icon-"]:not(.md-font).icon-stackoverflow:before {
    content: "\ea36"; }
  [class^="icon-"].icon-star-circle:before, [class*=" icon-"]:not(.md-font).icon-star-circle:before {
    content: "\ea37"; }
  [class^="icon-"].icon-star-half:before, [class*=" icon-"]:not(.md-font).icon-star-half:before {
    content: "\ea38"; }
  [class^="icon-"].icon-star-of-david:before, [class*=" icon-"]:not(.md-font).icon-star-of-david:before {
    content: "\ea39"; }
  [class^="icon-"].icon-star-outline:before, [class*=" icon-"]:not(.md-font).icon-star-outline:before {
    content: "\ea3a"; }
  [class^="icon-"].icon-star:before, [class*=" icon-"]:not(.md-font).icon-star:before {
    content: "\ea3b"; }
  [class^="icon-"].icon-steam:before, [class*=" icon-"]:not(.md-font).icon-steam:before {
    content: "\ea3c"; }
  [class^="icon-"].icon-stethoscope:before, [class*=" icon-"]:not(.md-font).icon-stethoscope:before {
    content: "\ea3d"; }
  [class^="icon-"].icon-stocking:before, [class*=" icon-"]:not(.md-font).icon-stocking:before {
    content: "\ea3e"; }
  [class^="icon-"].icon-stop:before, [class*=" icon-"]:not(.md-font).icon-stop:before {
    content: "\ea3f"; }
  [class^="icon-"].icon-store-24-hour:before, [class*=" icon-"]:not(.md-font).icon-store-24-hour:before {
    content: "\ea40"; }
  [class^="icon-"].icon-store:before, [class*=" icon-"]:not(.md-font).icon-store:before {
    content: "\ea41"; }
  [class^="icon-"].icon-stove:before, [class*=" icon-"]:not(.md-font).icon-stove:before {
    content: "\ea42"; }
  [class^="icon-"].icon-subway:before, [class*=" icon-"]:not(.md-font).icon-subway:before {
    content: "\ea43"; }
  [class^="icon-"].icon-sunglasses:before, [class*=" icon-"]:not(.md-font).icon-sunglasses:before {
    content: "\ea44"; }
  [class^="icon-"].icon-swap-horizontal:before, [class*=" icon-"]:not(.md-font).icon-swap-horizontal:before {
    content: "\ea45"; }
  [class^="icon-"].icon-swap-vertical:before, [class*=" icon-"]:not(.md-font).icon-swap-vertical:before {
    content: "\ea46"; }
  [class^="icon-"].icon-swim:before, [class*=" icon-"]:not(.md-font).icon-swim:before {
    content: "\ea47"; }
  [class^="icon-"].icon-sword:before, [class*=" icon-"]:not(.md-font).icon-sword:before {
    content: "\ea48"; }
  [class^="icon-"].icon-sync-alert:before, [class*=" icon-"]:not(.md-font).icon-sync-alert:before {
    content: "\ea49"; }
  [class^="icon-"].icon-sync-off:before, [class*=" icon-"]:not(.md-font).icon-sync-off:before {
    content: "\ea4a"; }
  [class^="icon-"].icon-sync:before, [class*=" icon-"]:not(.md-font).icon-sync:before {
    content: "\ea4b"; }
  [class^="icon-"].icon-tab-unselected:before, [class*=" icon-"]:not(.md-font).icon-tab-unselected:before {
    content: "\ea4c"; }
  [class^="icon-"].icon-tab:before, [class*=" icon-"]:not(.md-font).icon-tab:before {
    content: "\ea4d"; }
  [class^="icon-"].icon-table-column-plus-after:before, [class*=" icon-"]:not(.md-font).icon-table-column-plus-after:before {
    content: "\ea4e"; }
  [class^="icon-"].icon-table-column-plus-before:before, [class*=" icon-"]:not(.md-font).icon-table-column-plus-before:before {
    content: "\ea4f"; }
  [class^="icon-"].icon-table-column-remove:before, [class*=" icon-"]:not(.md-font).icon-table-column-remove:before {
    content: "\ea50"; }
  [class^="icon-"].icon-table-column-width:before, [class*=" icon-"]:not(.md-font).icon-table-column-width:before {
    content: "\ea51"; }
  [class^="icon-"].icon-table-edit:before, [class*=" icon-"]:not(.md-font).icon-table-edit:before {
    content: "\ea52"; }
  [class^="icon-"].icon-table-large:before, [class*=" icon-"]:not(.md-font).icon-table-large:before {
    content: "\ea53"; }
  [class^="icon-"].icon-table-row-height:before, [class*=" icon-"]:not(.md-font).icon-table-row-height:before {
    content: "\ea54"; }
  [class^="icon-"].icon-table-row-plus-after:before, [class*=" icon-"]:not(.md-font).icon-table-row-plus-after:before {
    content: "\ea55"; }
  [class^="icon-"].icon-table-row-plus-before:before, [class*=" icon-"]:not(.md-font).icon-table-row-plus-before:before {
    content: "\ea56"; }
  [class^="icon-"].icon-table-row-remove:before, [class*=" icon-"]:not(.md-font).icon-table-row-remove:before {
    content: "\ea57"; }
  [class^="icon-"].icon-table:before, [class*=" icon-"]:not(.md-font).icon-table:before {
    content: "\ea58"; }
  [class^="icon-"].icon-tablet-android:before, [class*=" icon-"]:not(.md-font).icon-tablet-android:before {
    content: "\ea59"; }
  [class^="icon-"].icon-tablet-ipad:before, [class*=" icon-"]:not(.md-font).icon-tablet-ipad:before {
    content: "\ea5a"; }
  [class^="icon-"].icon-tablet:before, [class*=" icon-"]:not(.md-font).icon-tablet:before {
    content: "\ea5b"; }
  [class^="icon-"].icon-tag-faces:before, [class*=" icon-"]:not(.md-font).icon-tag-faces:before {
    content: "\ea5c"; }
  [class^="icon-"].icon-tag-multiple:before, [class*=" icon-"]:not(.md-font).icon-tag-multiple:before {
    content: "\ea5d"; }
  [class^="icon-"].icon-tag-outline:before, [class*=" icon-"]:not(.md-font).icon-tag-outline:before {
    content: "\ea5e"; }
  [class^="icon-"].icon-tag-text-outline:before, [class*=" icon-"]:not(.md-font).icon-tag-text-outline:before {
    content: "\ea5f"; }
  [class^="icon-"].icon-tag:before, [class*=" icon-"]:not(.md-font).icon-tag:before {
    content: "\ea60"; }
  [class^="icon-"].icon-taxi:before, [class*=" icon-"]:not(.md-font).icon-taxi:before {
    content: "\ea61"; }
  [class^="icon-"].icon-teamviewer:before, [class*=" icon-"]:not(.md-font).icon-teamviewer:before {
    content: "\ea62"; }
  [class^="icon-"].icon-telegram:before, [class*=" icon-"]:not(.md-font).icon-telegram:before {
    content: "\ea63"; }
  [class^="icon-"].icon-television-guide:before, [class*=" icon-"]:not(.md-font).icon-television-guide:before {
    content: "\ea64"; }
  [class^="icon-"].icon-television:before, [class*=" icon-"]:not(.md-font).icon-television:before {
    content: "\ea65"; }
  [class^="icon-"].icon-temperature-celsius:before, [class*=" icon-"]:not(.md-font).icon-temperature-celsius:before {
    content: "\ea66"; }
  [class^="icon-"].icon-temperature-fahrenheit:before, [class*=" icon-"]:not(.md-font).icon-temperature-fahrenheit:before {
    content: "\ea67"; }
  [class^="icon-"].icon-temperature-kelvin:before, [class*=" icon-"]:not(.md-font).icon-temperature-kelvin:before {
    content: "\ea68"; }
  [class^="icon-"].icon-tennis:before, [class*=" icon-"]:not(.md-font).icon-tennis:before {
    content: "\ea69"; }
  [class^="icon-"].icon-tent:before, [class*=" icon-"]:not(.md-font).icon-tent:before {
    content: "\ea6a"; }
  [class^="icon-"].icon-terrain:before, [class*=" icon-"]:not(.md-font).icon-terrain:before {
    content: "\ea6b"; }
  [class^="icon-"].icon-text-to-speech-off:before, [class*=" icon-"]:not(.md-font).icon-text-to-speech-off:before {
    content: "\ea6c"; }
  [class^="icon-"].icon-text-to-speech:before, [class*=" icon-"]:not(.md-font).icon-text-to-speech:before {
    content: "\ea6d"; }
  [class^="icon-"].icon-texture:before, [class*=" icon-"]:not(.md-font).icon-texture:before {
    content: "\ea6e"; }
  [class^="icon-"].icon-theater:before, [class*=" icon-"]:not(.md-font).icon-theater:before {
    content: "\ea6f"; }
  [class^="icon-"].icon-theme-light-dark:before, [class*=" icon-"]:not(.md-font).icon-theme-light-dark:before {
    content: "\ea70"; }
  [class^="icon-"].icon-thermometer-lines:before, [class*=" icon-"]:not(.md-font).icon-thermometer-lines:before {
    content: "\ea71"; }
  [class^="icon-"].icon-thermometer:before, [class*=" icon-"]:not(.md-font).icon-thermometer:before {
    content: "\ea72"; }
  [class^="icon-"].icon-thumb-down-outline:before, [class*=" icon-"]:not(.md-font).icon-thumb-down-outline:before {
    content: "\ea73"; }
  [class^="icon-"].icon-thumb-down:before, [class*=" icon-"]:not(.md-font).icon-thumb-down:before {
    content: "\ea74"; }
  [class^="icon-"].icon-thumb-up-outline:before, [class*=" icon-"]:not(.md-font).icon-thumb-up-outline:before {
    content: "\ea75"; }
  [class^="icon-"].icon-thumb-up:before, [class*=" icon-"]:not(.md-font).icon-thumb-up:before {
    content: "\ea76"; }
  [class^="icon-"].icon-thumbs-up-down:before, [class*=" icon-"]:not(.md-font).icon-thumbs-up-down:before {
    content: "\ea77"; }
  [class^="icon-"].icon-ticket-account:before, [class*=" icon-"]:not(.md-font).icon-ticket-account:before {
    content: "\ea78"; }
  [class^="icon-"].icon-ticket:before, [class*=" icon-"]:not(.md-font).icon-ticket:before {
    content: "\ea79"; }
  [class^="icon-"].icon-tie:before, [class*=" icon-"]:not(.md-font).icon-tie:before {
    content: "\ea7a"; }
  [class^="icon-"].icon-tile-four:before, [class*=" icon-"]:not(.md-font).icon-tile-four:before {
    content: "\ea7b"; }
  [class^="icon-"].icon-timelapse:before, [class*=" icon-"]:not(.md-font).icon-timelapse:before {
    content: "\ea7c"; }
  [class^="icon-"].icon-timer-3:before, [class*=" icon-"]:not(.md-font).icon-timer-3:before {
    content: "\ea7d"; }
  [class^="icon-"].icon-timer-10:before, [class*=" icon-"]:not(.md-font).icon-timer-10:before {
    content: "\ea7e"; }
  [class^="icon-"].icon-timer-off:before, [class*=" icon-"]:not(.md-font).icon-timer-off:before {
    content: "\ea7f"; }
  [class^="icon-"].icon-timer-sand:before, [class*=" icon-"]:not(.md-font).icon-timer-sand:before {
    content: "\ea80"; }
  [class^="icon-"].icon-timer:before, [class*=" icon-"]:not(.md-font).icon-timer:before {
    content: "\ea81"; }
  [class^="icon-"].icon-timetable:before, [class*=" icon-"]:not(.md-font).icon-timetable:before {
    content: "\ea82"; }
  [class^="icon-"].icon-toggle-switch-off:before, [class*=" icon-"]:not(.md-font).icon-toggle-switch-off:before {
    content: "\ea83"; }
  [class^="icon-"].icon-toggle-switch-on:before, [class*=" icon-"]:not(.md-font).icon-toggle-switch-on:before {
    content: "\ea84"; }
  [class^="icon-"].icon-tooltip-edit:before, [class*=" icon-"]:not(.md-font).icon-tooltip-edit:before {
    content: "\ea85"; }
  [class^="icon-"].icon-tooltip-image:before, [class*=" icon-"]:not(.md-font).icon-tooltip-image:before {
    content: "\ea86"; }
  [class^="icon-"].icon-tooltip-outline-plus:before, [class*=" icon-"]:not(.md-font).icon-tooltip-outline-plus:before {
    content: "\ea87"; }
  [class^="icon-"].icon-tooltip-outline:before, [class*=" icon-"]:not(.md-font).icon-tooltip-outline:before {
    content: "\ea88"; }
  [class^="icon-"].icon-tooltip-text:before, [class*=" icon-"]:not(.md-font).icon-tooltip-text:before {
    content: "\ea89"; }
  [class^="icon-"].icon-tooltip:before, [class*=" icon-"]:not(.md-font).icon-tooltip:before {
    content: "\ea8a"; }
  [class^="icon-"].icon-tor:before, [class*=" icon-"]:not(.md-font).icon-tor:before {
    content: "\ea8b"; }
  [class^="icon-"].icon-traffic-light:before, [class*=" icon-"]:not(.md-font).icon-traffic-light:before {
    content: "\ea8c"; }
  [class^="icon-"].icon-train:before, [class*=" icon-"]:not(.md-font).icon-train:before {
    content: "\ea8d"; }
  [class^="icon-"].icon-tram:before, [class*=" icon-"]:not(.md-font).icon-tram:before {
    content: "\ea8e"; }
  [class^="icon-"].icon-transcribe-close:before, [class*=" icon-"]:not(.md-font).icon-transcribe-close:before {
    content: "\ea8f"; }
  [class^="icon-"].icon-transcribe:before, [class*=" icon-"]:not(.md-font).icon-transcribe:before {
    content: "\ea90"; }
  [class^="icon-"].icon-transfer:before, [class*=" icon-"]:not(.md-font).icon-transfer:before {
    content: "\ea91"; }
  [class^="icon-"].icon-trash:before, [class*=" icon-"]:not(.md-font).icon-trash:before {
    content: "\ea92"; }
  [class^="icon-"].icon-tree:before, [class*=" icon-"]:not(.md-font).icon-tree:before {
    content: "\ea93"; }
  [class^="icon-"].icon-trello:before, [class*=" icon-"]:not(.md-font).icon-trello:before {
    content: "\ea94"; }
  [class^="icon-"].icon-trending-down:before, [class*=" icon-"]:not(.md-font).icon-trending-down:before {
    content: "\ea95"; }
  [class^="icon-"].icon-trending-neutral:before, [class*=" icon-"]:not(.md-font).icon-trending-neutral:before {
    content: "\ea96"; }
  [class^="icon-"].icon-trending-up:before, [class*=" icon-"]:not(.md-font).icon-trending-up:before {
    content: "\ea97"; }
  [class^="icon-"].icon-truck:before, [class*=" icon-"]:not(.md-font).icon-truck:before {
    content: "\ea98"; }
  [class^="icon-"].icon-tshirt-crew:before, [class*=" icon-"]:not(.md-font).icon-tshirt-crew:before {
    content: "\ea99"; }
  [class^="icon-"].icon-tshirt-v:before, [class*=" icon-"]:not(.md-font).icon-tshirt-v:before {
    content: "\ea9a"; }
  [class^="icon-"].icon-tumblr-reblog:before, [class*=" icon-"]:not(.md-font).icon-tumblr-reblog:before {
    content: "\ea9b"; }
  [class^="icon-"].icon-tumblr:before, [class*=" icon-"]:not(.md-font).icon-tumblr:before {
    content: "\ea9c"; }
  [class^="icon-"].icon-twitch:before, [class*=" icon-"]:not(.md-font).icon-twitch:before {
    content: "\ea9d"; }
  [class^="icon-"].icon-twitter-box:before, [class*=" icon-"]:not(.md-font).icon-twitter-box:before {
    content: "\ea9e"; }
  [class^="icon-"].icon-twitter-circle:before, [class*=" icon-"]:not(.md-font).icon-twitter-circle:before {
    content: "\ea9f"; }
  [class^="icon-"].icon-twitter-retweet:before, [class*=" icon-"]:not(.md-font).icon-twitter-retweet:before {
    content: "\eaa0"; }
  [class^="icon-"].icon-twitter:before, [class*=" icon-"]:not(.md-font).icon-twitter:before {
    content: "\eaa1"; }
  [class^="icon-"].icon-ubuntu:before, [class*=" icon-"]:not(.md-font).icon-ubuntu:before {
    content: "\eaa2"; }
  [class^="icon-"].icon-umbrella-outline:before, [class*=" icon-"]:not(.md-font).icon-umbrella-outline:before {
    content: "\eaa3"; }
  [class^="icon-"].icon-umbrella:before, [class*=" icon-"]:not(.md-font).icon-umbrella:before {
    content: "\eaa4"; }
  [class^="icon-"].icon-undo-variant:before, [class*=" icon-"]:not(.md-font).icon-undo-variant:before {
    content: "\eaa5"; }
  [class^="icon-"].icon-undo:before, [class*=" icon-"]:not(.md-font).icon-undo:before {
    content: "\eaa6"; }
  [class^="icon-"].icon-unfold-less:before, [class*=" icon-"]:not(.md-font).icon-unfold-less:before {
    content: "\eaa7"; }
  [class^="icon-"].icon-unfold-more:before, [class*=" icon-"]:not(.md-font).icon-unfold-more:before {
    content: "\eaa8"; }
  [class^="icon-"].icon-ungroup:before, [class*=" icon-"]:not(.md-font).icon-ungroup:before {
    content: "\eaa9"; }
  [class^="icon-"].icon-untappd:before, [class*=" icon-"]:not(.md-font).icon-untappd:before {
    content: "\eaaa"; }
  [class^="icon-"].icon-upload:before, [class*=" icon-"]:not(.md-font).icon-upload:before {
    content: "\eaab"; }
  [class^="icon-"].icon-usb:before, [class*=" icon-"]:not(.md-font).icon-usb:before {
    content: "\eaac"; }
  [class^="icon-"].icon-vector-curve:before, [class*=" icon-"]:not(.md-font).icon-vector-curve:before {
    content: "\eaad"; }
  [class^="icon-"].icon-vector-point:before, [class*=" icon-"]:not(.md-font).icon-vector-point:before {
    content: "\eaae"; }
  [class^="icon-"].icon-vector-square:before, [class*=" icon-"]:not(.md-font).icon-vector-square:before {
    content: "\eaaf"; }
  [class^="icon-"].icon-verified:before, [class*=" icon-"]:not(.md-font).icon-verified:before {
    content: "\eab0"; }
  [class^="icon-"].icon-vibration:before, [class*=" icon-"]:not(.md-font).icon-vibration:before {
    content: "\eab1"; }
  [class^="icon-"].icon-video-off:before, [class*=" icon-"]:not(.md-font).icon-video-off:before {
    content: "\eab2"; }
  [class^="icon-"].icon-video-switch:before, [class*=" icon-"]:not(.md-font).icon-video-switch:before {
    content: "\eab3"; }
  [class^="icon-"].icon-video:before, [class*=" icon-"]:not(.md-font).icon-video:before {
    content: "\eab4"; }
  [class^="icon-"].icon-view-agenda:before, [class*=" icon-"]:not(.md-font).icon-view-agenda:before {
    content: "\eab5"; }
  [class^="icon-"].icon-view-array:before, [class*=" icon-"]:not(.md-font).icon-view-array:before {
    content: "\eab6"; }
  [class^="icon-"].icon-view-carousel:before, [class*=" icon-"]:not(.md-font).icon-view-carousel:before {
    content: "\eab7"; }
  [class^="icon-"].icon-view-column:before, [class*=" icon-"]:not(.md-font).icon-view-column:before {
    content: "\eab8"; }
  [class^="icon-"].icon-view-dashboard:before, [class*=" icon-"]:not(.md-font).icon-view-dashboard:before {
    content: "\eab9"; }
  [class^="icon-"].icon-view-day:before, [class*=" icon-"]:not(.md-font).icon-view-day:before {
    content: "\eaba"; }
  [class^="icon-"].icon-view-headline:before, [class*=" icon-"]:not(.md-font).icon-view-headline:before {
    content: "\eabb"; }
  [class^="icon-"].icon-view-list:before, [class*=" icon-"]:not(.md-font).icon-view-list:before {
    content: "\eabc"; }
  [class^="icon-"].icon-view-module:before, [class*=" icon-"]:not(.md-font).icon-view-module:before {
    content: "\eabd"; }
  [class^="icon-"].icon-view-quilt:before, [class*=" icon-"]:not(.md-font).icon-view-quilt:before {
    content: "\eabe"; }
  [class^="icon-"].icon-view-stream:before, [class*=" icon-"]:not(.md-font).icon-view-stream:before {
    content: "\eabf"; }
  [class^="icon-"].icon-view-week:before, [class*=" icon-"]:not(.md-font).icon-view-week:before {
    content: "\eac0"; }
  [class^="icon-"].icon-vimeo:before, [class*=" icon-"]:not(.md-font).icon-vimeo:before {
    content: "\eac1"; }
  [class^="icon-"].icon-vine:before, [class*=" icon-"]:not(.md-font).icon-vine:before {
    content: "\eac2"; }
  [class^="icon-"].icon-vk-box:before, [class*=" icon-"]:not(.md-font).icon-vk-box:before {
    content: "\eac3"; }
  [class^="icon-"].icon-vk-circle:before, [class*=" icon-"]:not(.md-font).icon-vk-circle:before {
    content: "\eac4"; }
  [class^="icon-"].icon-vk:before, [class*=" icon-"]:not(.md-font).icon-vk:before {
    content: "\eac5"; }
  [class^="icon-"].icon-voicemail:before, [class*=" icon-"]:not(.md-font).icon-voicemail:before {
    content: "\eac6"; }
  [class^="icon-"].icon-volume-high:before, [class*=" icon-"]:not(.md-font).icon-volume-high:before {
    content: "\eac7"; }
  [class^="icon-"].icon-volume-low:before, [class*=" icon-"]:not(.md-font).icon-volume-low:before {
    content: "\eac8"; }
  [class^="icon-"].icon-volume-medium:before, [class*=" icon-"]:not(.md-font).icon-volume-medium:before {
    content: "\eac9"; }
  [class^="icon-"].icon-volume-off:before, [class*=" icon-"]:not(.md-font).icon-volume-off:before {
    content: "\eaca"; }
  [class^="icon-"].icon-volume:before, [class*=" icon-"]:not(.md-font).icon-volume:before {
    content: "\eacb"; }
  [class^="icon-"].icon-vpn:before, [class*=" icon-"]:not(.md-font).icon-vpn:before {
    content: "\eacc"; }
  [class^="icon-"].icon-walk:before, [class*=" icon-"]:not(.md-font).icon-walk:before {
    content: "\eacd"; }
  [class^="icon-"].icon-wallet-giftcard:before, [class*=" icon-"]:not(.md-font).icon-wallet-giftcard:before {
    content: "\eace"; }
  [class^="icon-"].icon-wallet-membership:before, [class*=" icon-"]:not(.md-font).icon-wallet-membership:before {
    content: "\eacf"; }
  [class^="icon-"].icon-wallet-travel:before, [class*=" icon-"]:not(.md-font).icon-wallet-travel:before {
    content: "\ead0"; }
  [class^="icon-"].icon-wallet:before, [class*=" icon-"]:not(.md-font).icon-wallet:before {
    content: "\ead1"; }
  [class^="icon-"].icon-washing-machine:before, [class*=" icon-"]:not(.md-font).icon-washing-machine:before {
    content: "\ead2"; }
  [class^="icon-"].icon-watch-export:before, [class*=" icon-"]:not(.md-font).icon-watch-export:before {
    content: "\ead3"; }
  [class^="icon-"].icon-watch-import:before, [class*=" icon-"]:not(.md-font).icon-watch-import:before {
    content: "\ead4"; }
  [class^="icon-"].icon-watch:before, [class*=" icon-"]:not(.md-font).icon-watch:before {
    content: "\ead5"; }
  [class^="icon-"].icon-water-off:before, [class*=" icon-"]:not(.md-font).icon-water-off:before {
    content: "\ead6"; }
  [class^="icon-"].icon-water-pump:before, [class*=" icon-"]:not(.md-font).icon-water-pump:before {
    content: "\ead7"; }
  [class^="icon-"].icon-water:before, [class*=" icon-"]:not(.md-font).icon-water:before {
    content: "\ead8"; }
  [class^="icon-"].icon-weather-cloudy:before, [class*=" icon-"]:not(.md-font).icon-weather-cloudy:before {
    content: "\ead9"; }
  [class^="icon-"].icon-weather-fog:before, [class*=" icon-"]:not(.md-font).icon-weather-fog:before {
    content: "\eada"; }
  [class^="icon-"].icon-weather-hail:before, [class*=" icon-"]:not(.md-font).icon-weather-hail:before {
    content: "\eadb"; }
  [class^="icon-"].icon-weather-lightning:before, [class*=" icon-"]:not(.md-font).icon-weather-lightning:before {
    content: "\eadc"; }
  [class^="icon-"].icon-weather-night:before, [class*=" icon-"]:not(.md-font).icon-weather-night:before {
    content: "\eadd"; }
  [class^="icon-"].icon-weather-partlycloudy:before, [class*=" icon-"]:not(.md-font).icon-weather-partlycloudy:before {
    content: "\eade"; }
  [class^="icon-"].icon-weather-pouring:before, [class*=" icon-"]:not(.md-font).icon-weather-pouring:before {
    content: "\eadf"; }
  [class^="icon-"].icon-weather-rainy:before, [class*=" icon-"]:not(.md-font).icon-weather-rainy:before {
    content: "\eae0"; }
  [class^="icon-"].icon-weather-snowy:before, [class*=" icon-"]:not(.md-font).icon-weather-snowy:before {
    content: "\eae1"; }
  [class^="icon-"].icon-weather-sunny:before, [class*=" icon-"]:not(.md-font).icon-weather-sunny:before {
    content: "\eae2"; }
  [class^="icon-"].icon-weather-sunset-down:before, [class*=" icon-"]:not(.md-font).icon-weather-sunset-down:before {
    content: "\eae3"; }
  [class^="icon-"].icon-weather-sunset-up:before, [class*=" icon-"]:not(.md-font).icon-weather-sunset-up:before {
    content: "\eae4"; }
  [class^="icon-"].icon-weather-sunset:before, [class*=" icon-"]:not(.md-font).icon-weather-sunset:before {
    content: "\eae5"; }
  [class^="icon-"].icon-weather-windy-variant:before, [class*=" icon-"]:not(.md-font).icon-weather-windy-variant:before {
    content: "\eae6"; }
  [class^="icon-"].icon-weather-windy:before, [class*=" icon-"]:not(.md-font).icon-weather-windy:before {
    content: "\eae7"; }
  [class^="icon-"].icon-web:before, [class*=" icon-"]:not(.md-font).icon-web:before {
    content: "\eae8"; }
  [class^="icon-"].icon-webcam:before, [class*=" icon-"]:not(.md-font).icon-webcam:before {
    content: "\eae9"; }
  [class^="icon-"].icon-weight-kilogram:before, [class*=" icon-"]:not(.md-font).icon-weight-kilogram:before {
    content: "\eaea"; }
  [class^="icon-"].icon-weight:before, [class*=" icon-"]:not(.md-font).icon-weight:before {
    content: "\eaeb"; }
  [class^="icon-"].icon-whatsapp:before, [class*=" icon-"]:not(.md-font).icon-whatsapp:before {
    content: "\eaec"; }
  [class^="icon-"].icon-wheelchair-accessibility:before, [class*=" icon-"]:not(.md-font).icon-wheelchair-accessibility:before {
    content: "\eaed"; }
  [class^="icon-"].icon-white-balance-auto:before, [class*=" icon-"]:not(.md-font).icon-white-balance-auto:before {
    content: "\eaee"; }
  [class^="icon-"].icon-white-balance-incandescent:before, [class*=" icon-"]:not(.md-font).icon-white-balance-incandescent:before {
    content: "\eaef"; }
  [class^="icon-"].icon-white-balance-irradescent:before, [class*=" icon-"]:not(.md-font).icon-white-balance-irradescent:before {
    content: "\eaf0"; }
  [class^="icon-"].icon-white-balance-sunny:before, [class*=" icon-"]:not(.md-font).icon-white-balance-sunny:before {
    content: "\eaf1"; }
  [class^="icon-"].icon-wifi:before, [class*=" icon-"]:not(.md-font).icon-wifi:before {
    content: "\eaf2"; }
  [class^="icon-"].icon-wii:before, [class*=" icon-"]:not(.md-font).icon-wii:before {
    content: "\eaf3"; }
  [class^="icon-"].icon-wikipedia:before, [class*=" icon-"]:not(.md-font).icon-wikipedia:before {
    content: "\eaf4"; }
  [class^="icon-"].icon-window-close:before, [class*=" icon-"]:not(.md-font).icon-window-close:before {
    content: "\eaf5"; }
  [class^="icon-"].icon-window-closed:before, [class*=" icon-"]:not(.md-font).icon-window-closed:before {
    content: "\eaf6"; }
  [class^="icon-"].icon-window-maximize:before, [class*=" icon-"]:not(.md-font).icon-window-maximize:before {
    content: "\eaf7"; }
  [class^="icon-"].icon-window-minimize:before, [class*=" icon-"]:not(.md-font).icon-window-minimize:before {
    content: "\eaf8"; }
  [class^="icon-"].icon-window-open:before, [class*=" icon-"]:not(.md-font).icon-window-open:before {
    content: "\eaf9"; }
  [class^="icon-"].icon-window-restore:before, [class*=" icon-"]:not(.md-font).icon-window-restore:before {
    content: "\eafa"; }
  [class^="icon-"].icon-windows:before, [class*=" icon-"]:not(.md-font).icon-windows:before {
    content: "\eafb"; }
  [class^="icon-"].icon-wordpress:before, [class*=" icon-"]:not(.md-font).icon-wordpress:before {
    content: "\eafc"; }
  [class^="icon-"].icon-worker:before, [class*=" icon-"]:not(.md-font).icon-worker:before {
    content: "\eafd"; }
  [class^="icon-"].icon-wunderlist:before, [class*=" icon-"]:not(.md-font).icon-wunderlist:before {
    content: "\eafe"; }
  [class^="icon-"].icon-xbox-controller-off:before, [class*=" icon-"]:not(.md-font).icon-xbox-controller-off:before {
    content: "\eaff"; }
  [class^="icon-"].icon-xbox-controller:before, [class*=" icon-"]:not(.md-font).icon-xbox-controller:before {
    content: "\eb00"; }
  [class^="icon-"].icon-xbox:before, [class*=" icon-"]:not(.md-font).icon-xbox:before {
    content: "\eb01"; }
  [class^="icon-"].icon-xda:before, [class*=" icon-"]:not(.md-font).icon-xda:before {
    content: "\eb02"; }
  [class^="icon-"].icon-xml:before, [class*=" icon-"]:not(.md-font).icon-xml:before {
    content: "\eb03"; }
  [class^="icon-"].icon-yeast:before, [class*=" icon-"]:not(.md-font).icon-yeast:before {
    content: "\eb04"; }
  [class^="icon-"].icon-yelp:before, [class*=" icon-"]:not(.md-font).icon-yelp:before {
    content: "\eb05"; }
  [class^="icon-"].icon-youtube-play:before, [class*=" icon-"]:not(.md-font).icon-youtube-play:before {
    content: "\eb06"; }
  [class^="icon-"].icon-youtube:before, [class*=" icon-"]:not(.md-font).icon-youtube:before {
    content: "\eb07"; }
  [class^="icon-"].icon-zip-box:before, [class*=" icon-"]:not(.md-font).icon-zip-box:before {
    content: "\eb08"; }

/* https://github.com/google/material-design-icons/blob/master/iconfont/codepoints */
.tech-info-type-1:before {
  font-family: 'Material Icons';
  content: '\e1c1'; }

.tech-info-type-2:before {
  font-family: 'Material Icons';
  content: '\e62a'; }

.tech-info-type-9:before {
  font-family: 'Material Icons';
  content: '\e3e6'; }

.tech-info-type-10:before {
  font-family: 'Material Icons';
  content: '\e3e7'; }

.tech-info-type-11:before {
  font-family: 'Material Icons';
  content: '\e430'; }

.tech-info-type-12:before {
  font-family: 'Material Icons';
  content: '\e436'; }

.tech-info-type-15:before {
  font-family: 'Material Icons';
  content: '\e86a'; }

.tech-info-type-16:before {
  font-family: 'Material Icons';
  content: '\e04c'; }

.tech-info-type-17:before {
  font-family: 'Material Icons';
  content: '\e438'; }

.tech-info-type-18:before {
  font-family: 'Material Icons';
  content: '\e322'; }

.tech-info-type-19:before {
  font-family: 'Material Icons';
  content: '\e434'; }

.tech-info-type-20:before {
  font-family: 'Material Icons';
  content: '\e2c0'; }

.tech-info-type-21:before {
  font-family: 'Material Icons';
  content: '\e923'; }

.tech-info-type-22:before {
  font-family: 'Material Icons';
  content: '\e1b3'; }

.tech-info-type-23:before {
  font-family: 'Material Icons';
  content: '\e1b5'; }

.tech-info-type-24:before {
  font-family: 'Material Icons';
  content: '\e1e0'; }

.tech-info-type-25:before {
  font-family: 'Material Icons';
  content: '\e1d8'; }

.tech-info-type-26:before {
  font-family: 'Material Icons';
  content: '\e1da'; }

.tech-info-type-27:before {
  font-family: 'Material Icons';
  content: '\e62b'; }

.tech-info-type-30:before {
  font-family: 'Material Icons';
  content: '\e1d0'; }

.tech-info-type-31:before {
  font-family: 'Material Icons';
  content: '\e2c1'; }

/*-----------------------------------------------------------------------*/
/* Contains custom elements, components and general styles that
/* follows Google(TM) Material Design(TM) specs
/*
/* https://www.google.com/design/spec/material-design/introduction.html
/*-----------------------------------------------------------------------*/
/*----------------------------------------------------------------*/
/*  Avatars
/*----------------------------------------------------------------*/
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF; }
  .avatar.square {
    border-radius: 0; }
  .avatar.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px; }
  .avatar.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px; }
  .avatar.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px; }

/*----------------------------------------------------------------*/
/*  Forms
/*----------------------------------------------------------------*/
.form-wrapper {
  background: #FFFFFF;
  padding: 16px; }
  .form-wrapper .form-title {
    font-size: 21px;
    padding: 8px 0; }

/*----------------------------------------------------------------*/
/*  Navigation - Simple
/*----------------------------------------------------------------*/
.navigation-simple .item {
  position: relative;
  cursor: pointer;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  text-transform: none;
  line-height: 48px;
  max-height: 48px;
  height: 48px; }
  .navigation-simple .item md-icon {
    margin: 0 16px 0 0; }
  .navigation-simple .item .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 1; }
  .navigation-simple .item.selected {
    background-color: rgba(0, 0, 0, 0.06); }

.navigation-simple .subheader {
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  margin-top: 8px; }
  .navigation-simple .subheader.light {
    border-top: 1px solid rgba(255, 255, 255, 0.12); }

.navigation-simple md-divider {
  margin: 8px 0; }

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left; }
  table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
    min-width: 120px; }
    table.simple thead tr th:first-child {
      padding-left: 24px; }
    table.simple thead tr th:last-child {
      padding-right: 24px; }
  table.simple tbody tr td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    table.simple tbody tr td:first-child {
      padding-left: 24px; }
    table.simple tbody tr td:last-child {
      padding-right: 24px; }
  table.simple tbody tr:last-child td {
    border-bottom: none; }
  table.simple.clickable tbody tr {
    cursor: pointer; }
    table.simple.clickable tbody tr:hover {
      background: rgba(0, 0, 0, 0.03); }

/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/
html {
  font-size: 62.5%;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.4;
  letter-spacing: -0.1px; }

body {
  font-size: 1.3rem; }

html, body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

form {
  font-size: 1.6rem; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal; }

h1, .h1 {
  font-size: 2.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.4rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.2rem; }

.la {
  color: red !important; }

a {
  text-decoration: none; }
  a:hover, a:active {
    text-decoration: underline; }

abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54); }

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px; }
  blockquote footer {
    font-style: normal; }
    blockquote footer:before {
      content: '\2014 \00A0'; }
  blockquote.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px; }
    blockquote.reverse footer:before {
      content: ''; }
    blockquote.reverse footer:after {
      content: '\2014 \00A0'; }

dl dt {
  font-weight: bold; }

dl dd {
  margin: 4px 0 16px 0; }

mark {
  background: #F7F49A; }

small {
  font-size: 80%; }

table thead tr th {
  text-align: left; }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-size-1 {
  font-size: 1px; }

.font-size-2 {
  font-size: 2px; }

.font-size-3 {
  font-size: 3px; }

.font-size-4 {
  font-size: 4px; }

.font-size-5 {
  font-size: 5px; }

.font-size-6 {
  font-size: 6px; }

.font-size-7 {
  font-size: 7px; }

.font-size-8 {
  font-size: 8px; }

.font-size-9 {
  font-size: 9px; }

.font-size-10 {
  font-size: 10px; }

.font-size-11 {
  font-size: 11px; }

.font-size-12 {
  font-size: 12px; }

.font-size-13 {
  font-size: 13px; }

.font-size-14 {
  font-size: 14px; }

.font-size-15 {
  font-size: 15px; }

.font-size-16 {
  font-size: 16px; }

.font-size-17 {
  font-size: 17px; }

.font-size-18 {
  font-size: 18px; }

.font-size-19 {
  font-size: 19px; }

.font-size-20 {
  font-size: 20px; }

.font-size-21 {
  font-size: 21px; }

.font-size-22 {
  font-size: 22px; }

.font-size-23 {
  font-size: 23px; }

.font-size-24 {
  font-size: 24px; }

.font-size-25 {
  font-size: 25px; }

.font-size-26 {
  font-size: 26px; }

.font-size-27 {
  font-size: 27px; }

.font-size-28 {
  font-size: 28px; }

.font-size-29 {
  font-size: 29px; }

.font-size-30 {
  font-size: 30px; }

.font-size-31 {
  font-size: 31px; }

.font-size-32 {
  font-size: 32px; }

.font-size-33 {
  font-size: 33px; }

.font-size-34 {
  font-size: 34px; }

.font-size-35 {
  font-size: 35px; }

.font-size-36 {
  font-size: 36px; }

.font-size-37 {
  font-size: 37px; }

.font-size-38 {
  font-size: 38px; }

.font-size-39 {
  font-size: 39px; }

.font-size-40 {
  font-size: 40px; }

.font-size-41 {
  font-size: 41px; }

.font-size-42 {
  font-size: 42px; }

.font-size-43 {
  font-size: 43px; }

.font-size-44 {
  font-size: 44px; }

.font-size-45 {
  font-size: 45px; }

.font-size-46 {
  font-size: 46px; }

.font-size-47 {
  font-size: 47px; }

.font-size-48 {
  font-size: 48px; }

.font-size-49 {
  font-size: 49px; }

.font-size-50 {
  font-size: 50px; }

.font-size-51 {
  font-size: 51px; }

.font-size-52 {
  font-size: 52px; }

.font-size-53 {
  font-size: 53px; }

.font-size-54 {
  font-size: 54px; }

.font-size-55 {
  font-size: 55px; }

.font-size-56 {
  font-size: 56px; }

.font-size-57 {
  font-size: 57px; }

.font-size-58 {
  font-size: 58px; }

.font-size-59 {
  font-size: 59px; }

.font-size-60 {
  font-size: 60px; }

.font-size-61 {
  font-size: 61px; }

.font-size-62 {
  font-size: 62px; }

.font-size-63 {
  font-size: 63px; }

.font-size-64 {
  font-size: 64px; }

.font-size-65 {
  font-size: 65px; }

.font-size-66 {
  font-size: 66px; }

.font-size-67 {
  font-size: 67px; }

.font-size-68 {
  font-size: 68px; }

.font-size-69 {
  font-size: 69px; }

.font-size-70 {
  font-size: 70px; }

.font-size-71 {
  font-size: 71px; }

.font-size-72 {
  font-size: 72px; }

.font-size-73 {
  font-size: 73px; }

.font-size-74 {
  font-size: 74px; }

.font-size-75 {
  font-size: 75px; }

.font-size-76 {
  font-size: 76px; }

.font-size-77 {
  font-size: 77px; }

.font-size-78 {
  font-size: 78px; }

.font-size-79 {
  font-size: 79px; }

.font-size-80 {
  font-size: 80px; }

.font-size-81 {
  font-size: 81px; }

.font-size-82 {
  font-size: 82px; }

.font-size-83 {
  font-size: 83px; }

.font-size-84 {
  font-size: 84px; }

.font-size-85 {
  font-size: 85px; }

.font-size-86 {
  font-size: 86px; }

.font-size-87 {
  font-size: 87px; }

.font-size-88 {
  font-size: 88px; }

.font-size-89 {
  font-size: 89px; }

.font-size-90 {
  font-size: 90px; }

.font-size-91 {
  font-size: 91px; }

.font-size-92 {
  font-size: 92px; }

.font-size-93 {
  font-size: 93px; }

.font-size-94 {
  font-size: 94px; }

.font-size-95 {
  font-size: 95px; }

.font-size-96 {
  font-size: 96px; }

.font-size-97 {
  font-size: 97px; }

.font-size-98 {
  font-size: 98px; }

.font-size-99 {
  font-size: 99px; }

.font-size-100 {
  font-size: 100px; }

.line-height-2 {
  line-height: 2px; }

.line-height-4 {
  line-height: 4px; }

.line-height-6 {
  line-height: 6px; }

.line-height-8 {
  line-height: 8px; }

.line-height-10 {
  line-height: 10px; }

.line-height-12 {
  line-height: 12px; }

.line-height-14 {
  line-height: 14px; }

.line-height-16 {
  line-height: 16px; }

.line-height-18 {
  line-height: 18px; }

.line-height-20 {
  line-height: 20px; }

.line-height-22 {
  line-height: 22px; }

.line-height-24 {
  line-height: 24px; }

.line-height-26 {
  line-height: 26px; }

.line-height-28 {
  line-height: 28px; }

.line-height-30 {
  line-height: 30px; }

.line-height-32 {
  line-height: 32px; }

.line-height-34 {
  line-height: 34px; }

.line-height-36 {
  line-height: 36px; }

.line-height-38 {
  line-height: 38px; }

.line-height-40 {
  line-height: 40px; }

.line-height-42 {
  line-height: 42px; }

.line-height-44 {
  line-height: 44px; }

.line-height-46 {
  line-height: 46px; }

.line-height-48 {
  line-height: 48px; }

.line-height-50 {
  line-height: 50px; }

.line-height-52 {
  line-height: 52px; }

.line-height-54 {
  line-height: 54px; }

.line-height-56 {
  line-height: 56px; }

.line-height-58 {
  line-height: 58px; }

.line-height-60 {
  line-height: 60px; }

.line-height-62 {
  line-height: 62px; }

.line-height-64 {
  line-height: 64px; }

.line-height-66 {
  line-height: 66px; }

.line-height-68 {
  line-height: 68px; }

.line-height-70 {
  line-height: 70px; }

.line-height-72 {
  line-height: 72px; }

.line-height-74 {
  line-height: 74px; }

.line-height-76 {
  line-height: 76px; }

.line-height-78 {
  line-height: 78px; }

.line-height-80 {
  line-height: 80px; }

.line-height-82 {
  line-height: 82px; }

.line-height-84 {
  line-height: 84px; }

.line-height-86 {
  line-height: 86px; }

.line-height-88 {
  line-height: 88px; }

.line-height-90 {
  line-height: 90px; }

.line-height-92 {
  line-height: 92px; }

.line-height-94 {
  line-height: 94px; }

.line-height-96 {
  line-height: 96px; }

.line-height-98 {
  line-height: 98px; }

.line-height-100 {
  line-height: 100px; }

.line-height-102 {
  line-height: 102px; }

.line-height-104 {
  line-height: 104px; }

.line-height-106 {
  line-height: 106px; }

.line-height-108 {
  line-height: 108px; }

.line-height-110 {
  line-height: 110px; }

.line-height-112 {
  line-height: 112px; }

.line-height-114 {
  line-height: 114px; }

.line-height-116 {
  line-height: 116px; }

.line-height-118 {
  line-height: 118px; }

.line-height-120 {
  line-height: 120px; }

md-icon,
md-icon[md-font-icon],
i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px; }
  md-icon.s4,
  md-icon[md-font-icon].s4,
  i.s4 {
    font-size: 4px !important;
    width: 4px !important;
    height: 4px !important;
    min-width: 4px !important;
    min-height: 4px !important;
    line-height: 4px !important; }
  md-icon.s6,
  md-icon[md-font-icon].s6,
  i.s6 {
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    min-width: 6px !important;
    min-height: 6px !important;
    line-height: 6px !important; }
  md-icon.s8,
  md-icon[md-font-icon].s8,
  i.s8 {
    font-size: 8px !important;
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    min-height: 8px !important;
    line-height: 8px !important; }
  md-icon.s10,
  md-icon[md-font-icon].s10,
  i.s10 {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
    min-width: 10px !important;
    min-height: 10px !important;
    line-height: 10px !important; }
  md-icon.s12,
  md-icon[md-font-icon].s12,
  i.s12 {
    font-size: 12px !important;
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    line-height: 12px !important; }
  md-icon.s14,
  md-icon[md-font-icon].s14,
  i.s14 {
    font-size: 14px !important;
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    line-height: 14px !important; }
  md-icon.s16,
  md-icon[md-font-icon].s16,
  i.s16 {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    line-height: 16px !important; }
  md-icon.s18,
  md-icon[md-font-icon].s18,
  i.s18 {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important; }
  md-icon.s20,
  md-icon[md-font-icon].s20,
  i.s20 {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important; }
  md-icon.s22,
  md-icon[md-font-icon].s22,
  i.s22 {
    font-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    line-height: 22px !important; }
  md-icon.s24,
  md-icon[md-font-icon].s24,
  i.s24 {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important; }
  md-icon.s26,
  md-icon[md-font-icon].s26,
  i.s26 {
    font-size: 26px !important;
    width: 26px !important;
    height: 26px !important;
    min-width: 26px !important;
    min-height: 26px !important;
    line-height: 26px !important; }
  md-icon.s28,
  md-icon[md-font-icon].s28,
  i.s28 {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important; }
  md-icon.s30,
  md-icon[md-font-icon].s30,
  i.s30 {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important; }
  md-icon.s32,
  md-icon[md-font-icon].s32,
  i.s32 {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    line-height: 32px !important; }
  md-icon.s34,
  md-icon[md-font-icon].s34,
  i.s34 {
    font-size: 34px !important;
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    line-height: 34px !important; }
  md-icon.s36,
  md-icon[md-font-icon].s36,
  i.s36 {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    line-height: 36px !important; }
  md-icon.s38,
  md-icon[md-font-icon].s38,
  i.s38 {
    font-size: 38px !important;
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important; }
  md-icon.s40,
  md-icon[md-font-icon].s40,
  i.s40 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important; }
  md-icon.s42,
  md-icon[md-font-icon].s42,
  i.s42 {
    font-size: 42px !important;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    line-height: 42px !important; }
  md-icon.s44,
  md-icon[md-font-icon].s44,
  i.s44 {
    font-size: 44px !important;
    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
    line-height: 44px !important; }
  md-icon.s46,
  md-icon[md-font-icon].s46,
  i.s46 {
    font-size: 46px !important;
    width: 46px !important;
    height: 46px !important;
    min-width: 46px !important;
    min-height: 46px !important;
    line-height: 46px !important; }
  md-icon.s48,
  md-icon[md-font-icon].s48,
  i.s48 {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    line-height: 48px !important; }
  md-icon.s50,
  md-icon[md-font-icon].s50,
  i.s50 {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    line-height: 50px !important; }
  md-icon.s52,
  md-icon[md-font-icon].s52,
  i.s52 {
    font-size: 52px !important;
    width: 52px !important;
    height: 52px !important;
    min-width: 52px !important;
    min-height: 52px !important;
    line-height: 52px !important; }
  md-icon.s54,
  md-icon[md-font-icon].s54,
  i.s54 {
    font-size: 54px !important;
    width: 54px !important;
    height: 54px !important;
    min-width: 54px !important;
    min-height: 54px !important;
    line-height: 54px !important; }
  md-icon.s56,
  md-icon[md-font-icon].s56,
  i.s56 {
    font-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    line-height: 56px !important; }
  md-icon.s58,
  md-icon[md-font-icon].s58,
  i.s58 {
    font-size: 58px !important;
    width: 58px !important;
    height: 58px !important;
    min-width: 58px !important;
    min-height: 58px !important;
    line-height: 58px !important; }
  md-icon.s60,
  md-icon[md-font-icon].s60,
  i.s60 {
    font-size: 60px !important;
    width: 60px !important;
    height: 60px !important;
    min-width: 60px !important;
    min-height: 60px !important;
    line-height: 60px !important; }
  md-icon.s62,
  md-icon[md-font-icon].s62,
  i.s62 {
    font-size: 62px !important;
    width: 62px !important;
    height: 62px !important;
    min-width: 62px !important;
    min-height: 62px !important;
    line-height: 62px !important; }
  md-icon.s64,
  md-icon[md-font-icon].s64,
  i.s64 {
    font-size: 64px !important;
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    line-height: 64px !important; }
  md-icon.s66,
  md-icon[md-font-icon].s66,
  i.s66 {
    font-size: 66px !important;
    width: 66px !important;
    height: 66px !important;
    min-width: 66px !important;
    min-height: 66px !important;
    line-height: 66px !important; }
  md-icon.s68,
  md-icon[md-font-icon].s68,
  i.s68 {
    font-size: 68px !important;
    width: 68px !important;
    height: 68px !important;
    min-width: 68px !important;
    min-height: 68px !important;
    line-height: 68px !important; }
  md-icon.s70,
  md-icon[md-font-icon].s70,
  i.s70 {
    font-size: 70px !important;
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    line-height: 70px !important; }
  md-icon.s72,
  md-icon[md-font-icon].s72,
  i.s72 {
    font-size: 72px !important;
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    line-height: 72px !important; }
  md-icon.s74,
  md-icon[md-font-icon].s74,
  i.s74 {
    font-size: 74px !important;
    width: 74px !important;
    height: 74px !important;
    min-width: 74px !important;
    min-height: 74px !important;
    line-height: 74px !important; }
  md-icon.s76,
  md-icon[md-font-icon].s76,
  i.s76 {
    font-size: 76px !important;
    width: 76px !important;
    height: 76px !important;
    min-width: 76px !important;
    min-height: 76px !important;
    line-height: 76px !important; }
  md-icon.s78,
  md-icon[md-font-icon].s78,
  i.s78 {
    font-size: 78px !important;
    width: 78px !important;
    height: 78px !important;
    min-width: 78px !important;
    min-height: 78px !important;
    line-height: 78px !important; }
  md-icon.s80,
  md-icon[md-font-icon].s80,
  i.s80 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    line-height: 80px !important; }
  md-icon.s82,
  md-icon[md-font-icon].s82,
  i.s82 {
    font-size: 82px !important;
    width: 82px !important;
    height: 82px !important;
    min-width: 82px !important;
    min-height: 82px !important;
    line-height: 82px !important; }
  md-icon.s84,
  md-icon[md-font-icon].s84,
  i.s84 {
    font-size: 84px !important;
    width: 84px !important;
    height: 84px !important;
    min-width: 84px !important;
    min-height: 84px !important;
    line-height: 84px !important; }
  md-icon.s86,
  md-icon[md-font-icon].s86,
  i.s86 {
    font-size: 86px !important;
    width: 86px !important;
    height: 86px !important;
    min-width: 86px !important;
    min-height: 86px !important;
    line-height: 86px !important; }
  md-icon.s88,
  md-icon[md-font-icon].s88,
  i.s88 {
    font-size: 88px !important;
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    line-height: 88px !important; }
  md-icon.s90,
  md-icon[md-font-icon].s90,
  i.s90 {
    font-size: 90px !important;
    width: 90px !important;
    height: 90px !important;
    min-width: 90px !important;
    min-height: 90px !important;
    line-height: 90px !important; }
  md-icon.s92,
  md-icon[md-font-icon].s92,
  i.s92 {
    font-size: 92px !important;
    width: 92px !important;
    height: 92px !important;
    min-width: 92px !important;
    min-height: 92px !important;
    line-height: 92px !important; }
  md-icon.s94,
  md-icon[md-font-icon].s94,
  i.s94 {
    font-size: 94px !important;
    width: 94px !important;
    height: 94px !important;
    min-width: 94px !important;
    min-height: 94px !important;
    line-height: 94px !important; }
  md-icon.s96,
  md-icon[md-font-icon].s96,
  i.s96 {
    font-size: 96px !important;
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    line-height: 96px !important; }
  md-icon.s98,
  md-icon[md-font-icon].s98,
  i.s98 {
    font-size: 98px !important;
    width: 98px !important;
    height: 98px !important;
    min-width: 98px !important;
    min-height: 98px !important;
    line-height: 98px !important; }
  md-icon.s100,
  md-icon[md-font-icon].s100,
  i.s100 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    line-height: 100px !important; }
  md-icon.s102,
  md-icon[md-font-icon].s102,
  i.s102 {
    font-size: 102px !important;
    width: 102px !important;
    height: 102px !important;
    min-width: 102px !important;
    min-height: 102px !important;
    line-height: 102px !important; }
  md-icon.s104,
  md-icon[md-font-icon].s104,
  i.s104 {
    font-size: 104px !important;
    width: 104px !important;
    height: 104px !important;
    min-width: 104px !important;
    min-height: 104px !important;
    line-height: 104px !important; }
  md-icon.s106,
  md-icon[md-font-icon].s106,
  i.s106 {
    font-size: 106px !important;
    width: 106px !important;
    height: 106px !important;
    min-width: 106px !important;
    min-height: 106px !important;
    line-height: 106px !important; }
  md-icon.s108,
  md-icon[md-font-icon].s108,
  i.s108 {
    font-size: 108px !important;
    width: 108px !important;
    height: 108px !important;
    min-width: 108px !important;
    min-height: 108px !important;
    line-height: 108px !important; }
  md-icon.s110,
  md-icon[md-font-icon].s110,
  i.s110 {
    font-size: 110px !important;
    width: 110px !important;
    height: 110px !important;
    min-width: 110px !important;
    min-height: 110px !important;
    line-height: 110px !important; }
  md-icon.s112,
  md-icon[md-font-icon].s112,
  i.s112 {
    font-size: 112px !important;
    width: 112px !important;
    height: 112px !important;
    min-width: 112px !important;
    min-height: 112px !important;
    line-height: 112px !important; }
  md-icon.s114,
  md-icon[md-font-icon].s114,
  i.s114 {
    font-size: 114px !important;
    width: 114px !important;
    height: 114px !important;
    min-width: 114px !important;
    min-height: 114px !important;
    line-height: 114px !important; }
  md-icon.s116,
  md-icon[md-font-icon].s116,
  i.s116 {
    font-size: 116px !important;
    width: 116px !important;
    height: 116px !important;
    min-width: 116px !important;
    min-height: 116px !important;
    line-height: 116px !important; }
  md-icon.s118,
  md-icon[md-font-icon].s118,
  i.s118 {
    font-size: 118px !important;
    width: 118px !important;
    height: 118px !important;
    min-width: 118px !important;
    min-height: 118px !important;
    line-height: 118px !important; }
  md-icon.s120,
  md-icon[md-font-icon].s120,
  i.s120 {
    font-size: 120px !important;
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
    min-height: 120px !important;
    line-height: 120px !important; }
  md-icon.s122,
  md-icon[md-font-icon].s122,
  i.s122 {
    font-size: 122px !important;
    width: 122px !important;
    height: 122px !important;
    min-width: 122px !important;
    min-height: 122px !important;
    line-height: 122px !important; }
  md-icon.s124,
  md-icon[md-font-icon].s124,
  i.s124 {
    font-size: 124px !important;
    width: 124px !important;
    height: 124px !important;
    min-width: 124px !important;
    min-height: 124px !important;
    line-height: 124px !important; }
  md-icon.s126,
  md-icon[md-font-icon].s126,
  i.s126 {
    font-size: 126px !important;
    width: 126px !important;
    height: 126px !important;
    min-width: 126px !important;
    min-height: 126px !important;
    line-height: 126px !important; }
  md-icon.s128,
  md-icon[md-font-icon].s128,
  i.s128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    min-width: 128px !important;
    min-height: 128px !important;
    line-height: 128px !important; }
  md-icon.s130,
  md-icon[md-font-icon].s130,
  i.s130 {
    font-size: 130px !important;
    width: 130px !important;
    height: 130px !important;
    min-width: 130px !important;
    min-height: 130px !important;
    line-height: 130px !important; }
  md-icon.s132,
  md-icon[md-font-icon].s132,
  i.s132 {
    font-size: 132px !important;
    width: 132px !important;
    height: 132px !important;
    min-width: 132px !important;
    min-height: 132px !important;
    line-height: 132px !important; }
  md-icon.s134,
  md-icon[md-font-icon].s134,
  i.s134 {
    font-size: 134px !important;
    width: 134px !important;
    height: 134px !important;
    min-width: 134px !important;
    min-height: 134px !important;
    line-height: 134px !important; }
  md-icon.s136,
  md-icon[md-font-icon].s136,
  i.s136 {
    font-size: 136px !important;
    width: 136px !important;
    height: 136px !important;
    min-width: 136px !important;
    min-height: 136px !important;
    line-height: 136px !important; }
  md-icon.s138,
  md-icon[md-font-icon].s138,
  i.s138 {
    font-size: 138px !important;
    width: 138px !important;
    height: 138px !important;
    min-width: 138px !important;
    min-height: 138px !important;
    line-height: 138px !important; }
  md-icon.s140,
  md-icon[md-font-icon].s140,
  i.s140 {
    font-size: 140px !important;
    width: 140px !important;
    height: 140px !important;
    min-width: 140px !important;
    min-height: 140px !important;
    line-height: 140px !important; }
  md-icon.s142,
  md-icon[md-font-icon].s142,
  i.s142 {
    font-size: 142px !important;
    width: 142px !important;
    height: 142px !important;
    min-width: 142px !important;
    min-height: 142px !important;
    line-height: 142px !important; }
  md-icon.s144,
  md-icon[md-font-icon].s144,
  i.s144 {
    font-size: 144px !important;
    width: 144px !important;
    height: 144px !important;
    min-width: 144px !important;
    min-height: 144px !important;
    line-height: 144px !important; }
  md-icon.s146,
  md-icon[md-font-icon].s146,
  i.s146 {
    font-size: 146px !important;
    width: 146px !important;
    height: 146px !important;
    min-width: 146px !important;
    min-height: 146px !important;
    line-height: 146px !important; }
  md-icon.s148,
  md-icon[md-font-icon].s148,
  i.s148 {
    font-size: 148px !important;
    width: 148px !important;
    height: 148px !important;
    min-width: 148px !important;
    min-height: 148px !important;
    line-height: 148px !important; }
  md-icon.s150,
  md-icon[md-font-icon].s150,
  i.s150 {
    font-size: 150px !important;
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
    min-height: 150px !important;
    line-height: 150px !important; }
  md-icon.s152,
  md-icon[md-font-icon].s152,
  i.s152 {
    font-size: 152px !important;
    width: 152px !important;
    height: 152px !important;
    min-width: 152px !important;
    min-height: 152px !important;
    line-height: 152px !important; }
  md-icon.s154,
  md-icon[md-font-icon].s154,
  i.s154 {
    font-size: 154px !important;
    width: 154px !important;
    height: 154px !important;
    min-width: 154px !important;
    min-height: 154px !important;
    line-height: 154px !important; }
  md-icon.s156,
  md-icon[md-font-icon].s156,
  i.s156 {
    font-size: 156px !important;
    width: 156px !important;
    height: 156px !important;
    min-width: 156px !important;
    min-height: 156px !important;
    line-height: 156px !important; }
  md-icon.s158,
  md-icon[md-font-icon].s158,
  i.s158 {
    font-size: 158px !important;
    width: 158px !important;
    height: 158px !important;
    min-width: 158px !important;
    min-height: 158px !important;
    line-height: 158px !important; }
  md-icon.s160,
  md-icon[md-font-icon].s160,
  i.s160 {
    font-size: 160px !important;
    width: 160px !important;
    height: 160px !important;
    min-width: 160px !important;
    min-height: 160px !important;
    line-height: 160px !important; }
  md-icon.s162,
  md-icon[md-font-icon].s162,
  i.s162 {
    font-size: 162px !important;
    width: 162px !important;
    height: 162px !important;
    min-width: 162px !important;
    min-height: 162px !important;
    line-height: 162px !important; }
  md-icon.s164,
  md-icon[md-font-icon].s164,
  i.s164 {
    font-size: 164px !important;
    width: 164px !important;
    height: 164px !important;
    min-width: 164px !important;
    min-height: 164px !important;
    line-height: 164px !important; }
  md-icon.s166,
  md-icon[md-font-icon].s166,
  i.s166 {
    font-size: 166px !important;
    width: 166px !important;
    height: 166px !important;
    min-width: 166px !important;
    min-height: 166px !important;
    line-height: 166px !important; }
  md-icon.s168,
  md-icon[md-font-icon].s168,
  i.s168 {
    font-size: 168px !important;
    width: 168px !important;
    height: 168px !important;
    min-width: 168px !important;
    min-height: 168px !important;
    line-height: 168px !important; }
  md-icon.s170,
  md-icon[md-font-icon].s170,
  i.s170 {
    font-size: 170px !important;
    width: 170px !important;
    height: 170px !important;
    min-width: 170px !important;
    min-height: 170px !important;
    line-height: 170px !important; }
  md-icon.s172,
  md-icon[md-font-icon].s172,
  i.s172 {
    font-size: 172px !important;
    width: 172px !important;
    height: 172px !important;
    min-width: 172px !important;
    min-height: 172px !important;
    line-height: 172px !important; }
  md-icon.s174,
  md-icon[md-font-icon].s174,
  i.s174 {
    font-size: 174px !important;
    width: 174px !important;
    height: 174px !important;
    min-width: 174px !important;
    min-height: 174px !important;
    line-height: 174px !important; }
  md-icon.s176,
  md-icon[md-font-icon].s176,
  i.s176 {
    font-size: 176px !important;
    width: 176px !important;
    height: 176px !important;
    min-width: 176px !important;
    min-height: 176px !important;
    line-height: 176px !important; }
  md-icon.s178,
  md-icon[md-font-icon].s178,
  i.s178 {
    font-size: 178px !important;
    width: 178px !important;
    height: 178px !important;
    min-width: 178px !important;
    min-height: 178px !important;
    line-height: 178px !important; }
  md-icon.s180,
  md-icon[md-font-icon].s180,
  i.s180 {
    font-size: 180px !important;
    width: 180px !important;
    height: 180px !important;
    min-width: 180px !important;
    min-height: 180px !important;
    line-height: 180px !important; }
  md-icon.s182,
  md-icon[md-font-icon].s182,
  i.s182 {
    font-size: 182px !important;
    width: 182px !important;
    height: 182px !important;
    min-width: 182px !important;
    min-height: 182px !important;
    line-height: 182px !important; }
  md-icon.s184,
  md-icon[md-font-icon].s184,
  i.s184 {
    font-size: 184px !important;
    width: 184px !important;
    height: 184px !important;
    min-width: 184px !important;
    min-height: 184px !important;
    line-height: 184px !important; }
  md-icon.s186,
  md-icon[md-font-icon].s186,
  i.s186 {
    font-size: 186px !important;
    width: 186px !important;
    height: 186px !important;
    min-width: 186px !important;
    min-height: 186px !important;
    line-height: 186px !important; }
  md-icon.s188,
  md-icon[md-font-icon].s188,
  i.s188 {
    font-size: 188px !important;
    width: 188px !important;
    height: 188px !important;
    min-width: 188px !important;
    min-height: 188px !important;
    line-height: 188px !important; }
  md-icon.s190,
  md-icon[md-font-icon].s190,
  i.s190 {
    font-size: 190px !important;
    width: 190px !important;
    height: 190px !important;
    min-width: 190px !important;
    min-height: 190px !important;
    line-height: 190px !important; }
  md-icon.s192,
  md-icon[md-font-icon].s192,
  i.s192 {
    font-size: 192px !important;
    width: 192px !important;
    height: 192px !important;
    min-width: 192px !important;
    min-height: 192px !important;
    line-height: 192px !important; }
  md-icon.s194,
  md-icon[md-font-icon].s194,
  i.s194 {
    font-size: 194px !important;
    width: 194px !important;
    height: 194px !important;
    min-width: 194px !important;
    min-height: 194px !important;
    line-height: 194px !important; }
  md-icon.s196,
  md-icon[md-font-icon].s196,
  i.s196 {
    font-size: 196px !important;
    width: 196px !important;
    height: 196px !important;
    min-width: 196px !important;
    min-height: 196px !important;
    line-height: 196px !important; }
  md-icon.s198,
  md-icon[md-font-icon].s198,
  i.s198 {
    font-size: 198px !important;
    width: 198px !important;
    height: 198px !important;
    min-width: 198px !important;
    min-height: 198px !important;
    line-height: 198px !important; }
  md-icon.s200,
  md-icon[md-font-icon].s200,
  i.s200 {
    font-size: 200px !important;
    width: 200px !important;
    height: 200px !important;
    min-width: 200px !important;
    min-height: 200px !important;
    line-height: 200px !important; }
  md-icon.s202,
  md-icon[md-font-icon].s202,
  i.s202 {
    font-size: 202px !important;
    width: 202px !important;
    height: 202px !important;
    min-width: 202px !important;
    min-height: 202px !important;
    line-height: 202px !important; }
  md-icon.s204,
  md-icon[md-font-icon].s204,
  i.s204 {
    font-size: 204px !important;
    width: 204px !important;
    height: 204px !important;
    min-width: 204px !important;
    min-height: 204px !important;
    line-height: 204px !important; }
  md-icon.s206,
  md-icon[md-font-icon].s206,
  i.s206 {
    font-size: 206px !important;
    width: 206px !important;
    height: 206px !important;
    min-width: 206px !important;
    min-height: 206px !important;
    line-height: 206px !important; }
  md-icon.s208,
  md-icon[md-font-icon].s208,
  i.s208 {
    font-size: 208px !important;
    width: 208px !important;
    height: 208px !important;
    min-width: 208px !important;
    min-height: 208px !important;
    line-height: 208px !important; }
  md-icon.s210,
  md-icon[md-font-icon].s210,
  i.s210 {
    font-size: 210px !important;
    width: 210px !important;
    height: 210px !important;
    min-width: 210px !important;
    min-height: 210px !important;
    line-height: 210px !important; }
  md-icon.s212,
  md-icon[md-font-icon].s212,
  i.s212 {
    font-size: 212px !important;
    width: 212px !important;
    height: 212px !important;
    min-width: 212px !important;
    min-height: 212px !important;
    line-height: 212px !important; }
  md-icon.s214,
  md-icon[md-font-icon].s214,
  i.s214 {
    font-size: 214px !important;
    width: 214px !important;
    height: 214px !important;
    min-width: 214px !important;
    min-height: 214px !important;
    line-height: 214px !important; }
  md-icon.s216,
  md-icon[md-font-icon].s216,
  i.s216 {
    font-size: 216px !important;
    width: 216px !important;
    height: 216px !important;
    min-width: 216px !important;
    min-height: 216px !important;
    line-height: 216px !important; }
  md-icon.s218,
  md-icon[md-font-icon].s218,
  i.s218 {
    font-size: 218px !important;
    width: 218px !important;
    height: 218px !important;
    min-width: 218px !important;
    min-height: 218px !important;
    line-height: 218px !important; }
  md-icon.s220,
  md-icon[md-font-icon].s220,
  i.s220 {
    font-size: 220px !important;
    width: 220px !important;
    height: 220px !important;
    min-width: 220px !important;
    min-height: 220px !important;
    line-height: 220px !important; }
  md-icon.s222,
  md-icon[md-font-icon].s222,
  i.s222 {
    font-size: 222px !important;
    width: 222px !important;
    height: 222px !important;
    min-width: 222px !important;
    min-height: 222px !important;
    line-height: 222px !important; }
  md-icon.s224,
  md-icon[md-font-icon].s224,
  i.s224 {
    font-size: 224px !important;
    width: 224px !important;
    height: 224px !important;
    min-width: 224px !important;
    min-height: 224px !important;
    line-height: 224px !important; }
  md-icon.s226,
  md-icon[md-font-icon].s226,
  i.s226 {
    font-size: 226px !important;
    width: 226px !important;
    height: 226px !important;
    min-width: 226px !important;
    min-height: 226px !important;
    line-height: 226px !important; }
  md-icon.s228,
  md-icon[md-font-icon].s228,
  i.s228 {
    font-size: 228px !important;
    width: 228px !important;
    height: 228px !important;
    min-width: 228px !important;
    min-height: 228px !important;
    line-height: 228px !important; }
  md-icon.s230,
  md-icon[md-font-icon].s230,
  i.s230 {
    font-size: 230px !important;
    width: 230px !important;
    height: 230px !important;
    min-width: 230px !important;
    min-height: 230px !important;
    line-height: 230px !important; }
  md-icon.s232,
  md-icon[md-font-icon].s232,
  i.s232 {
    font-size: 232px !important;
    width: 232px !important;
    height: 232px !important;
    min-width: 232px !important;
    min-height: 232px !important;
    line-height: 232px !important; }
  md-icon.s234,
  md-icon[md-font-icon].s234,
  i.s234 {
    font-size: 234px !important;
    width: 234px !important;
    height: 234px !important;
    min-width: 234px !important;
    min-height: 234px !important;
    line-height: 234px !important; }
  md-icon.s236,
  md-icon[md-font-icon].s236,
  i.s236 {
    font-size: 236px !important;
    width: 236px !important;
    height: 236px !important;
    min-width: 236px !important;
    min-height: 236px !important;
    line-height: 236px !important; }
  md-icon.s238,
  md-icon[md-font-icon].s238,
  i.s238 {
    font-size: 238px !important;
    width: 238px !important;
    height: 238px !important;
    min-width: 238px !important;
    min-height: 238px !important;
    line-height: 238px !important; }
  md-icon.s240,
  md-icon[md-font-icon].s240,
  i.s240 {
    font-size: 240px !important;
    width: 240px !important;
    height: 240px !important;
    min-width: 240px !important;
    min-height: 240px !important;
    line-height: 240px !important; }
  md-icon.s242,
  md-icon[md-font-icon].s242,
  i.s242 {
    font-size: 242px !important;
    width: 242px !important;
    height: 242px !important;
    min-width: 242px !important;
    min-height: 242px !important;
    line-height: 242px !important; }
  md-icon.s244,
  md-icon[md-font-icon].s244,
  i.s244 {
    font-size: 244px !important;
    width: 244px !important;
    height: 244px !important;
    min-width: 244px !important;
    min-height: 244px !important;
    line-height: 244px !important; }
  md-icon.s246,
  md-icon[md-font-icon].s246,
  i.s246 {
    font-size: 246px !important;
    width: 246px !important;
    height: 246px !important;
    min-width: 246px !important;
    min-height: 246px !important;
    line-height: 246px !important; }
  md-icon.s248,
  md-icon[md-font-icon].s248,
  i.s248 {
    font-size: 248px !important;
    width: 248px !important;
    height: 248px !important;
    min-width: 248px !important;
    min-height: 248px !important;
    line-height: 248px !important; }
  md-icon.s250,
  md-icon[md-font-icon].s250,
  i.s250 {
    font-size: 250px !important;
    width: 250px !important;
    height: 250px !important;
    min-width: 250px !important;
    min-height: 250px !important;
    line-height: 250px !important; }
  md-icon.s252,
  md-icon[md-font-icon].s252,
  i.s252 {
    font-size: 252px !important;
    width: 252px !important;
    height: 252px !important;
    min-width: 252px !important;
    min-height: 252px !important;
    line-height: 252px !important; }
  md-icon.s254,
  md-icon[md-font-icon].s254,
  i.s254 {
    font-size: 254px !important;
    width: 254px !important;
    height: 254px !important;
    min-width: 254px !important;
    min-height: 254px !important;
    line-height: 254px !important; }
  md-icon.s256,
  md-icon[md-font-icon].s256,
  i.s256 {
    font-size: 256px !important;
    width: 256px !important;
    height: 256px !important;
    min-width: 256px !important;
    min-height: 256px !important;
    line-height: 256px !important; }

.text-boxed, .text-boxed-light {
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 8px;
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgba(0, 0, 0, 0.12);
  white-space: nowrap; }

.text-boxed-light {
  background-color: rgba(255, 255, 255, 0.7); }

.text-truncate, .allocations-view > .md-subheader .info-allocation,
.allocations-view > md-toolbar .info-allocation, .allocations-proData-view > .md-subheader .info-allocation,
.allocations-proData-view > md-toolbar .info-allocation, .allocationMonitrips-view > .md-subheader .info-allocationMonitrip,
.allocationMonitrips-view > md-toolbar .info-allocationMonitrip, .service-report .service-sidebar .service-view > .md-subheader .info-allocation,
.service-report .service-sidebar .service-view > md-toolbar .info-allocation {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-overflow {
  text-overflow: clip;
  overflow: visible; }

.text-nowrap {
  white-space: nowrap; }

.text-first-letter-uppercase:first-letter {
  text-transform: uppercase; }

/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/
.admin-action-list__center-align {
  text-align: center !important; }

.admin-action-list__input {
  margin-bottom: 0;
  margin-top: 30px; }

.admin-action-list .limit-select,
.admin-action-list .page-select {
  width: auto !important; }

.admin-action-list__row:hover {
  background: #E8EAF6;
  cursor: pointer; }

.admin-action-list__row:hover .admin-action-list__row__delete {
  opacity: 1; }

.admin-action-list__row__delete {
  opacity: 0;
  transition: all 0.5s; }

#allocations-report .header-allocations-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #allocations-report .header-allocations-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

#allocations-report [kb-item].kb-active {
  background: #EEEEEE; }

#allocations-report .allocations-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #allocations-report .allocations-list p {
    margin: 0; }
  #allocations-report .allocations-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

#allocations-report .allocation-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  #allocations-report .allocation-item .avatar {
    font-size: 14px; }
    #allocations-report .allocation-item .avatar md-icon {
      color: #FFFFFF; }
  #allocations-report .allocation-item.completed {
    background: #EEEEEE; }
    #allocations-report .allocation-item.completed .title,
    #allocations-report .allocation-item.completed .notes {
      color: rgba(0, 0, 0, 0.54); }
  #allocations-report .allocation-item.selected {
    background: #FFF8E1; }
  #allocations-report .allocation-item .info {
    margin: 0 16px 0 8px; }
    #allocations-report .allocation-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    #allocations-report .allocation-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
    #allocations-report .allocation-item .info md-icon {
      margin: 0;
      margin-right: 3px; }
  #allocations-report .allocation-item .secondary-text md-icon {
    margin: 0;
    margin-right: 3px; }

.allocations-view {
  max-width: 420px;
  min-height: 420px;
  width: 420px; }
  .allocations-view > .md-subheader,
  .allocations-view > md-toolbar {
    background-color: #263238;
    color: #fff; }
    .allocations-view > .md-subheader .info-allocation,
    .allocations-view > md-toolbar .info-allocation {
      max-height: 25px;
      margin-bottom: 5px;
      font-size: 14px; }
    .allocations-view > .md-subheader md-icon,
    .allocations-view > md-toolbar md-icon {
      margin-left: 0;
      margin-right: 2px;
      color: #fff; }
    .allocations-view > .md-subheader md-input-container,
    .allocations-view > md-toolbar md-input-container {
      margin-bottom: 0; }
      .allocations-view > .md-subheader md-input-container > *,
      .allocations-view > md-toolbar md-input-container > * {
        color: #fff !important; }
      .allocations-view > .md-subheader md-input-container input,
      .allocations-view > md-toolbar md-input-container input {
        border-bottom-color: #fff !important; }
  .allocations-view .service-occurrences-list .md-list-item-text > * {
    font-size: 16px; }
  .allocations-view .service-occurrences-list .md-secundary span {
    line-height: 1.3em; }
  .allocations-view .div-allocation-people-status {
    padding: 6px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap; }
    .allocations-view .div-allocation-people-status > div {
      position: relative;
      min-width: 75px;
      max-width: 75px;
      width: 75px;
      min-height: 82px;
      max-height: 82px;
      height: 82px;
      padding: 2px 0;
      margin: 2px;
      text-align: center;
      font-weight: bold; }
      .allocations-view .div-allocation-people-status > div .avatar {
        margin: 0;
        font-weight: bold; }
      .allocations-view .div-allocation-people-status > div .name-allocation-people {
        flex: 1;
        padding: 6px;
        line-height: 1em; }
      .allocations-view .div-allocation-people-status > div .div-loading {
        top: -10px; }
  .allocations-view .div-timeline {
    min-height: 300px; }
    .allocations-view .div-timeline .timeline-events-list {
      min-height: 300px; }
      .allocations-view .div-timeline .timeline-events-list .event-item {
        padding: 4px 16px 8px 16px;
        border: none !important; }
        .allocations-view .div-timeline .timeline-events-list .event-item .info {
          margin-right: 0; }
        .allocations-view .div-timeline .timeline-events-list .event-item .title {
          margin-bottom: 3px;
          font-size: 16px;
          font-weight: bold; }
        .allocations-view .div-timeline .timeline-events-list .event-item .notes {
          padding-top: 3px;
          font-size: 14px; }
          .allocations-view .div-timeline .timeline-events-list .event-item .notes md-icon {
            margin-left: 0;
            margin-right: 3px; }
        .allocations-view .div-timeline .timeline-events-list .event-item .total-balancing span:before {
          content: '('; }
        .allocations-view .div-timeline .timeline-events-list .event-item .total-balancing span:after {
          content: ')'; }
        .allocations-view .div-timeline .timeline-events-list .event-item .total-balancing span .balancing-positive:before {
          content: ' +'; }
        .allocations-view .div-timeline .timeline-events-list .event-item .secondary-text.time {
          margin-top: 3px; }
        .allocations-view .div-timeline .timeline-events-list .event-item .secondary-text md-icon {
          margin-left: 0;
          margin-right: 3px; }
      .allocations-view .div-timeline .timeline-events-list .md-list-item-inner {
        padding: 6px 0; }
      .allocations-view .div-timeline .timeline-events-list .avatar {
        margin-right: 5px;
        font-size: 17px !important; }
        .allocations-view .div-timeline .timeline-events-list .avatar.occurrence-event {
          font-size: 18px !important; }
  .allocations-view .md-subheader-inner {
    padding-bottom: 8px; }
  .allocations-view md-list-item .md-list-item-inner {
    padding: 8px 0;
    z-index: 1; }
  .allocations-view p {
    margin: 0; }
  .allocations-view .md-subheader .md-subheader-inner {
    padding-top: 8px; }
  .allocations-view .header-allocations-view {
    min-height: 80px;
    max-height: 80px;
    background-color: #263238 !important;
    color: #fff !important; }
    .allocations-view .header-allocations-view .md-toolbar-tools-top .title {
      padding-left: 0; }
    .allocations-view .header-allocations-view .info-allocations {
      font-size: 14px; }
    .allocations-view .header-allocations-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .allocations-view .div-floating-list-button {
    bottom: 12px; }
  .allocations-view .div-allocations-img .allocations-img {
    z-index: 2; }
    .allocations-view .div-allocations-img .allocations-img .ng-image-gallery-modal {
      height: 225px; }
      .allocations-view .div-allocations-img .allocations-img .ng-image-gallery-modal img {
        width: 100%; }
  .allocations-view .div-allocations-img .allocations-map-img {
    position: absolute;
    right: 0; }
  .allocations-view md-content {
    overflow-x: hidden; }
    .allocations-view md-content .door-occurrences .div-total-occurrences {
      border-right: solid 1px rgba(0, 0, 0, 0.16); }
    .allocations-view md-content .div-occurrences,
    .allocations-view md-content .door-occurrences,
    .allocations-view md-content .centroid-occurrences {
      position: relative;
      width: 100%; }
      .allocations-view md-content .div-occurrences > div,
      .allocations-view md-content .door-occurrences > div,
      .allocations-view md-content .centroid-occurrences > div {
        width: 100%; }
      .allocations-view md-content .div-occurrences .subtitle,
      .allocations-view md-content .door-occurrences .subtitle,
      .allocations-view md-content .centroid-occurrences .subtitle {
        padding: 12px; }
      .allocations-view md-content .div-occurrences .div-total-occurrences,
      .allocations-view md-content .door-occurrences .div-total-occurrences,
      .allocations-view md-content .centroid-occurrences .div-total-occurrences {
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer; }
        .allocations-view md-content .div-occurrences .div-total-occurrences md-icon,
        .allocations-view md-content .door-occurrences .div-total-occurrences md-icon,
        .allocations-view md-content .centroid-occurrences .div-total-occurrences md-icon {
          margin: 0;
          margin-right: 5px; }
    .allocations-view md-content .list-item {
      padding-top: 12px;
      font-size: 14px; }
      .allocations-view md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
    .allocations-view md-content .model-details {
      padding: 16px 16px;
      height: auto; }
    .allocations-view md-content p {
      margin: 0; }

@media screen and (max-width: 600px) {
  .sidenav-allocation-view,
  .allocations-view {
    max-width: 100% !important;
    width: 100% !important; } }

@media print {
  .allocations-view {
    max-width: 100%;
    height: 100%;
    width: 100%; }
    .allocations-view md-content {
      overflow: visible !important;
      /*
      md-list {
        .event-item {
          page-break-inside: avoid;
        }
      }
      */ }
    .allocations-view md-toolbar {
      color: #000000;
      background-color: #fff; }
      .allocations-view md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    .allocations-view .content {
      display: block; }
      .allocations-view .content .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .allocations-view .content table {
        max-width: 60%; }
        .allocations-view .content table tr,
        .allocations-view .content table th,
        .allocations-view .content table td {
          text-align: center !important; }
        .allocations-view .content table tbody td {
          padding: 0 12px 0 0 !important; } }

#allocations-pro-data-report .header-allocations-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #allocations-pro-data-report .header-allocations-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

#allocations-pro-data-report [kb-item].kb-active {
  background: #EEEEEE; }

#allocations-pro-data-report .allocations-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #allocations-pro-data-report .allocations-list p {
    margin: 0; }
  #allocations-pro-data-report .allocations-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

#allocations-pro-data-report .allocation-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  #allocations-pro-data-report .allocation-item .avatar {
    font-size: 14px; }
    #allocations-pro-data-report .allocation-item .avatar md-icon {
      color: #FFFFFF; }
  #allocations-pro-data-report .allocation-item.completed {
    background: #EEEEEE; }
    #allocations-pro-data-report .allocation-item.completed .title,
    #allocations-pro-data-report .allocation-item.completed .notes {
      color: rgba(0, 0, 0, 0.54); }
  #allocations-pro-data-report .allocation-item.selected {
    background: #FFF8E1; }
  #allocations-pro-data-report .allocation-item .info {
    margin: 0 16px 0 8px; }
    #allocations-pro-data-report .allocation-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    #allocations-pro-data-report .allocation-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
    #allocations-pro-data-report .allocation-item .info md-icon {
      margin: 0;
      margin-right: 3px; }
  #allocations-pro-data-report .allocation-item .secondary-text md-icon {
    margin: 0;
    margin-right: 3px; }

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important; }
  #allocations-pro-data-report {
    font-family: sans-serif;
    font-size: 14px; }
    #allocations-pro-data-report .allocations-list {
      border: 0; }
      #allocations-pro-data-report .allocations-list .allocation-table table tr,
      #allocations-pro-data-report .allocations-list .allocation-table table th,
      #allocations-pro-data-report .allocations-list .allocation-table table td {
        font-family: sans-serif;
        font-size: 14px;
        text-align: center !important; }
      #allocations-pro-data-report .allocations-list .allocation-table table thead {
        display: table-row-group !important; }
        #allocations-pro-data-report .allocations-list .allocation-table table thead th {
          padding: 0 12px 0 0 !important; }
      #allocations-pro-data-report .allocations-list .allocation-table table tbody {
        font-family: sans-serif;
        font-size: 14px; }
        #allocations-pro-data-report .allocations-list .allocation-table table tbody tr {
          margin: 200px; }
        #allocations-pro-data-report .allocations-list .allocation-table table tbody td {
          padding: 0 12px 0 0 !important; }
      #allocations-pro-data-report .allocations-list .allocation-table .total-info {
        margin-top: 50px;
        font-size: 17px; } }

.allocations-proData-view {
  max-width: 380px;
  min-height: 420px;
  width: 380px; }
  .allocations-proData-view > .md-subheader,
  .allocations-proData-view > md-toolbar {
    background-color: #263238;
    color: #fff; }
    .allocations-proData-view > .md-subheader .info-allocation,
    .allocations-proData-view > md-toolbar .info-allocation {
      max-height: 25px;
      margin-bottom: 5px;
      font-size: 14px; }
    .allocations-proData-view > .md-subheader md-icon,
    .allocations-proData-view > md-toolbar md-icon {
      margin-left: 0;
      margin-right: 2px;
      color: #fff; }
    .allocations-proData-view > .md-subheader md-input-container,
    .allocations-proData-view > md-toolbar md-input-container {
      margin-bottom: 0; }
      .allocations-proData-view > .md-subheader md-input-container > *,
      .allocations-proData-view > md-toolbar md-input-container > * {
        color: #fff !important; }
      .allocations-proData-view > .md-subheader md-input-container input,
      .allocations-proData-view > md-toolbar md-input-container input {
        border-bottom-color: #fff !important; }
  .allocations-proData-view .service-occurrences-list .md-list-item-text > * {
    font-size: 16px; }
  .allocations-proData-view .service-occurrences-list .md-secundary span {
    line-height: 1.3em; }
  .allocations-proData-view .div-allocation-people-status {
    padding: 6px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap; }
    .allocations-proData-view .div-allocation-people-status > div {
      position: relative;
      min-width: 95px;
      max-width: 95px;
      width: 95px;
      min-height: 72px;
      max-height: 72px;
      height: auto;
      padding: 2px 0;
      margin: 2px;
      text-align: center;
      font-weight: bold; }
      .allocations-proData-view .div-allocation-people-status > div .avatar {
        position: relative;
        min-height: 40px;
        margin: 0;
        font-weight: bold; }
      .allocations-proData-view .div-allocation-people-status > div .name-allocation-people {
        flex: 1;
        padding: 6px;
        line-height: 1em; }
  .allocations-proData-view .div-timeline {
    min-height: 300px; }
    .allocations-proData-view .div-timeline .timeline-events-list {
      min-height: 300px; }
      .allocations-proData-view .div-timeline .timeline-events-list .event-item {
        padding: 4px 16px 8px 16px;
        border: none !important; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .info {
          margin-right: 0; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .title {
          margin-bottom: 3px;
          font-size: 16px;
          font-weight: bold; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .notes {
          padding-top: 3px;
          font-size: 14px; }
          .allocations-proData-view .div-timeline .timeline-events-list .event-item .notes md-icon {
            margin-left: 0;
            margin-right: 3px; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .total-balancing span:before {
          content: '('; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .total-balancing span:after {
          content: ')'; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .total-balancing span .balancing-positive:before {
          content: ' +'; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .secondary-text.time {
          margin-top: 3px; }
        .allocations-proData-view .div-timeline .timeline-events-list .event-item .secondary-text md-icon {
          margin-left: 0;
          margin-right: 3px; }
      .allocations-proData-view .div-timeline .timeline-events-list .md-list-item-inner {
        padding: 6px 0; }
      .allocations-proData-view .div-timeline .timeline-events-list .avatar {
        margin-right: 5px;
        font-size: 17px !important; }
        .allocations-proData-view .div-timeline .timeline-events-list .avatar.occurrence-event {
          font-size: 24px !important; }
  .allocations-proData-view .md-subheader-inner {
    padding-bottom: 8px; }
  .allocations-proData-view md-list-item .md-list-item-inner {
    padding: 8px 0;
    z-index: 1; }
  .allocations-proData-view p {
    margin: 0; }
  .allocations-proData-view .md-subheader .md-subheader-inner {
    padding-top: 8px; }
  .allocations-proData-view .header-allocations-proData-view {
    min-height: 80px;
    max-height: 80px;
    background-color: #263238 !important;
    color: #fff !important; }
    .allocations-proData-view .header-allocations-proData-view .md-toolbar-tools-top .title {
      padding-left: 0; }
    .allocations-proData-view .header-allocations-proData-view .info-allocations {
      font-size: 14px; }
    .allocations-proData-view .header-allocations-proData-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .allocations-proData-view .div-floating-list-button {
    bottom: 12px; }
  .allocations-proData-view .div-allocations-proData-img .allocations-proData-img {
    z-index: 2; }
    .allocations-proData-view .div-allocations-proData-img .allocations-proData-img .ng-image-gallery-modal {
      height: 225px; }
      .allocations-proData-view .div-allocations-proData-img .allocations-proData-img .ng-image-gallery-modal img {
        width: 100%; }
  .allocations-proData-view .div-allocations-proData-img .allocations-proData-map-img {
    position: absolute;
    right: 0; }
  .allocations-proData-view md-content {
    overflow-x: hidden; }
    .allocations-proData-view md-content .door-occurrences .div-total-occurrences {
      border-right: solid 1px rgba(0, 0, 0, 0.16); }
    .allocations-proData-view md-content .div-occurrences,
    .allocations-proData-view md-content .door-occurrences,
    .allocations-proData-view md-content .centroid-occurrences {
      position: relative;
      width: 100%; }
      .allocations-proData-view md-content .div-occurrences > div,
      .allocations-proData-view md-content .door-occurrences > div,
      .allocations-proData-view md-content .centroid-occurrences > div {
        width: 100%; }
      .allocations-proData-view md-content .div-occurrences .subtitle,
      .allocations-proData-view md-content .door-occurrences .subtitle,
      .allocations-proData-view md-content .centroid-occurrences .subtitle {
        padding: 12px; }
      .allocations-proData-view md-content .div-occurrences .div-total-occurrences,
      .allocations-proData-view md-content .door-occurrences .div-total-occurrences,
      .allocations-proData-view md-content .centroid-occurrences .div-total-occurrences {
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer; }
        .allocations-proData-view md-content .div-occurrences .div-total-occurrences md-icon,
        .allocations-proData-view md-content .door-occurrences .div-total-occurrences md-icon,
        .allocations-proData-view md-content .centroid-occurrences .div-total-occurrences md-icon {
          margin: 0;
          margin-right: 5px; }
    .allocations-proData-view md-content .list-item {
      padding-top: 12px;
      font-size: 14px; }
      .allocations-proData-view md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
    .allocations-proData-view md-content .model-details {
      padding: 16px 16px;
      height: auto; }
    .allocations-proData-view md-content p {
      margin: 0; }

@media screen and (max-width: 600px) {
  .sidenav-allocation-view,
  .allocations-proData-view {
    max-width: 100% !important;
    width: 100% !important; } }

@media print {
  .allocations-proData-view {
    max-width: 100%;
    height: 100%;
    width: 100%; }
    .allocations-proData-view md-content {
      overflow: visible !important; }
    .allocations-proData-view md-toolbar {
      color: #000000;
      background-color: #fff; }
      .allocations-proData-view md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    .allocations-proData-view .content {
      display: block; }
      .allocations-proData-view .content .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .allocations-proData-view .content table {
        max-width: 60%; }
        .allocations-proData-view .content table tr,
        .allocations-proData-view .content table th,
        .allocations-proData-view .content table td {
          text-align: center !important; }
        .allocations-proData-view .content table tbody td {
          padding: 0 12px 0 0 !important; } }

.header-allocations-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-allocations-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

.allocations-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .allocations-list p {
    margin: 0; }
  .allocations-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.allocation-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .allocation-item .avatar {
    font-size: 14px; }
  .allocation-item .info {
    margin: 0 16px 0 8px; }
    .allocation-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .allocation-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .allocation-item .secondary-text md-icon {
    margin: 0; }

#allocationMonitrips-report .header-allocationMonitrips-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #allocationMonitrips-report .header-allocationMonitrips-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

#allocationMonitrips-report [kb-item].kb-active {
  background: #EEEEEE; }

#allocationMonitrips-report .allocationMonitrips-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #allocationMonitrips-report .allocationMonitrips-list p {
    margin: 0; }
  #allocationMonitrips-report .allocationMonitrips-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

#allocationMonitrips-report .allocationMonitrip-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none; }
  #allocationMonitrips-report .allocationMonitrip-item .avatar {
    font-size: 14px; }
    #allocationMonitrips-report .allocationMonitrip-item .avatar md-icon {
      color: #FFFFFF; }
  #allocationMonitrips-report .allocationMonitrip-item .notes {
    margin: 2px 0; }
  #allocationMonitrips-report .allocationMonitrip-item.selected {
    background: #FFF8E1; }
  #allocationMonitrips-report .allocationMonitrip-item .info {
    margin: 0 16px 0 8px; }
    #allocationMonitrips-report .allocationMonitrip-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    #allocationMonitrips-report .allocationMonitrip-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
    #allocationMonitrips-report .allocationMonitrip-item .info md-icon {
      margin: 0;
      margin-right: 3px; }
  #allocationMonitrips-report .allocationMonitrip-item .secondary-text md-icon {
    margin: 0;
    margin-right: 3px; }

.allocationMonitrips-view {
  max-width: 380px;
  min-height: 420px;
  width: 380px; }
  .allocationMonitrips-view > .md-subheader,
  .allocationMonitrips-view > md-toolbar {
    background-color: #263238;
    color: #fff; }
    .allocationMonitrips-view > .md-subheader .info-allocationMonitrip,
    .allocationMonitrips-view > md-toolbar .info-allocationMonitrip {
      max-height: 25px;
      margin-bottom: 5px;
      font-size: 14px; }
    .allocationMonitrips-view > .md-subheader md-icon,
    .allocationMonitrips-view > md-toolbar md-icon {
      margin-left: 0;
      margin-right: 2px;
      color: #fff; }
    .allocationMonitrips-view > .md-subheader md-input-container,
    .allocationMonitrips-view > md-toolbar md-input-container {
      margin-bottom: 0; }
      .allocationMonitrips-view > .md-subheader md-input-container > *,
      .allocationMonitrips-view > md-toolbar md-input-container > * {
        color: #fff !important; }
      .allocationMonitrips-view > .md-subheader md-input-container input,
      .allocationMonitrips-view > md-toolbar md-input-container input {
        border-bottom-color: #fff !important; }
  .allocationMonitrips-view .service-occurrences-list .md-list-item-text > * {
    font-size: 16px; }
  .allocationMonitrips-view .service-occurrences-list .md-secundary span {
    line-height: 1.3em; }
  .allocationMonitrips-view .div-allocationMonitrip-people-status {
    padding: 6px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap; }
    .allocationMonitrips-view .div-allocationMonitrip-people-status > div {
      position: relative;
      min-width: 75px;
      max-width: 75px;
      width: 75px;
      min-height: 82px;
      max-height: 82px;
      height: 82px;
      padding: 2px 0;
      margin: 2px;
      text-align: center;
      font-weight: bold; }
      .allocationMonitrips-view .div-allocationMonitrip-people-status > div .avatar {
        margin: 0;
        font-weight: bold; }
      .allocationMonitrips-view .div-allocationMonitrip-people-status > div .name-allocationMonitrip-people {
        flex: 1;
        padding: 6px;
        line-height: 1em; }
      .allocationMonitrips-view .div-allocationMonitrip-people-status > div .div-loading {
        top: -10px; }
  .allocationMonitrips-view .div-timeline {
    min-height: 300px; }
    .allocationMonitrips-view .div-timeline .timeline-events-list {
      min-height: 300px; }
      .allocationMonitrips-view .div-timeline .timeline-events-list .event-item {
        padding: 4px 16px 8px 16px;
        border: none !important; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .info {
          margin-right: 0; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .title {
          margin-bottom: 3px;
          font-size: 16px;
          font-weight: bold; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .notes {
          padding-top: 3px;
          font-size: 14px; }
          .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .notes md-icon {
            margin-left: 0;
            margin-right: 3px; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .total-balancing span:before {
          content: '('; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .total-balancing span:after {
          content: ')'; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .total-balancing span .balancing-positive:before {
          content: ' +'; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .secondary-text.time {
          margin-top: 3px; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .event-item .secondary-text md-icon {
          margin-left: 0;
          margin-right: 3px; }
      .allocationMonitrips-view .div-timeline .timeline-events-list .md-list-item-inner {
        padding: 6px 0; }
      .allocationMonitrips-view .div-timeline .timeline-events-list .avatar {
        margin-right: 5px;
        font-size: 17px !important; }
        .allocationMonitrips-view .div-timeline .timeline-events-list .avatar.occurrence-event {
          font-size: 18px !important; }
  .allocationMonitrips-view .md-subheader-inner {
    padding-bottom: 8px; }
  .allocationMonitrips-view md-list-item .md-list-item-inner {
    padding: 8px 0;
    z-index: 1; }
  .allocationMonitrips-view p {
    margin: 0; }
  .allocationMonitrips-view .md-subheader .md-subheader-inner {
    padding-top: 8px; }
  .allocationMonitrips-view .header-allocationMonitrips-view {
    min-height: 80px;
    max-height: 80px;
    background-color: #263238 !important;
    color: #fff !important; }
    .allocationMonitrips-view .header-allocationMonitrips-view .md-toolbar-tools-top .title {
      padding-left: 0; }
    .allocationMonitrips-view .header-allocationMonitrips-view .info-allocationMonitrips {
      font-size: 14px; }
    .allocationMonitrips-view .header-allocationMonitrips-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .allocationMonitrips-view .div-floating-list-button {
    bottom: 12px; }
  .allocationMonitrips-view .div-allocationMonitrips-img .allocationMonitrips-img {
    z-index: 2; }
    .allocationMonitrips-view .div-allocationMonitrips-img .allocationMonitrips-img .ng-image-gallery-modal {
      height: 225px; }
      .allocationMonitrips-view .div-allocationMonitrips-img .allocationMonitrips-img .ng-image-gallery-modal img {
        width: 100%; }
  .allocationMonitrips-view .div-allocationMonitrips-img .allocationMonitrips-map-img {
    position: absolute;
    right: 0; }
  .allocationMonitrips-view md-content {
    overflow-x: hidden; }
    .allocationMonitrips-view md-content .door-occurrences .div-total-occurrences {
      border-right: solid 1px rgba(0, 0, 0, 0.16); }
    .allocationMonitrips-view md-content .div-occurrences,
    .allocationMonitrips-view md-content .door-occurrences,
    .allocationMonitrips-view md-content .centroid-occurrences {
      position: relative;
      width: 100%; }
      .allocationMonitrips-view md-content .div-occurrences > div,
      .allocationMonitrips-view md-content .door-occurrences > div,
      .allocationMonitrips-view md-content .centroid-occurrences > div {
        width: 100%; }
      .allocationMonitrips-view md-content .div-occurrences .subtitle,
      .allocationMonitrips-view md-content .door-occurrences .subtitle,
      .allocationMonitrips-view md-content .centroid-occurrences .subtitle {
        padding: 12px; }
      .allocationMonitrips-view md-content .div-occurrences .div-total-occurrences,
      .allocationMonitrips-view md-content .door-occurrences .div-total-occurrences,
      .allocationMonitrips-view md-content .centroid-occurrences .div-total-occurrences {
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer; }
        .allocationMonitrips-view md-content .div-occurrences .div-total-occurrences md-icon,
        .allocationMonitrips-view md-content .door-occurrences .div-total-occurrences md-icon,
        .allocationMonitrips-view md-content .centroid-occurrences .div-total-occurrences md-icon {
          margin: 0;
          margin-right: 5px; }
    .allocationMonitrips-view md-content .list-item {
      padding-top: 12px;
      font-size: 14px; }
      .allocationMonitrips-view md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
    .allocationMonitrips-view md-content .model-details {
      padding: 16px 16px;
      height: auto; }
    .allocationMonitrips-view md-content p {
      margin: 0; }

@media screen and (max-width: 600px) {
  .sidenav-allocationMonitrip-view,
  .allocationMonitrips-view {
    max-width: 100% !important;
    width: 100% !important; } }

@media print {
  .allocationMonitrips-view {
    max-width: 100%;
    height: 100%;
    width: 100%; }
    .allocationMonitrips-view md-content {
      overflow: visible !important;
      /*
      md-list {
        .event-item {
          page-break-inside: avoid;
        }
      }
      */ }
    .allocationMonitrips-view md-toolbar {
      color: #000000;
      background-color: #fff; }
      .allocationMonitrips-view md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    .allocationMonitrips-view .content {
      display: block; }
      .allocationMonitrips-view .content .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .allocationMonitrips-view .content table {
        max-width: 60%; }
        .allocationMonitrips-view .content table tr,
        .allocationMonitrips-view .content table th,
        .allocationMonitrips-view .content table td {
          text-align: center !important; }
        .allocationMonitrips-view .content table tbody td {
          padding: 0 12px 0 0 !important; } }

#centroids-report .header-centroids-list {
  height: 65px;
  min-height: 65px !important;
  max-height: 65px;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #centroids-report .header-centroids-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }
  #centroids-report .header-centroids-list .div-list-controls .order-by-directive {
    max-width: 180px;
    margin-right: 5px; }

#centroids-report .centroids-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #centroids-report .centroids-list p {
    margin: 0; }
  #centroids-report .centroids-list [kb-item].kb-active {
    background: #EEEEEE; }
  #centroids-report .centroids-list .div-centroids {
    width: 100%;
    height: auto;
    padding: 6px 12px;
    display: block; }
  #centroids-report .centroids-list .centroid-item {
    position: relative;
    height: auto !important;
    padding: 16px;
    margin-bottom: 15px;
    text-transform: none;
    cursor: pointer; }
    #centroids-report .centroids-list .centroid-item .avatar {
      font-size: 14px; }
      #centroids-report .centroids-list .centroid-item .avatar md-icon {
        color: #FFFFFF; }
    #centroids-report .centroids-list .centroid-item.completed {
      background: #EEEEEE; }
      #centroids-report .centroids-list .centroid-item.completed .title,
      #centroids-report .centroids-list .centroid-item.completed .notes {
        color: rgba(0, 0, 0, 0.54); }
    #centroids-report .centroids-list .centroid-item.selected {
      background: #FFF8E1; }
    #centroids-report .centroids-list .centroid-item .info {
      margin: 0 16px 0 8px; }
      #centroids-report .centroids-list .centroid-item .info .title {
        font-size: 15px;
        font-weight: 500; }
      #centroids-report .centroids-list .centroid-item .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
      #centroids-report .centroids-list .centroid-item .info md-icon {
        margin: 0;
        margin-right: 3px; }
    #centroids-report .centroids-list .centroid-item .secondary-text md-icon {
      margin: 0;
      margin-right: 3px;
      vertical-align: text-top; }
    #centroids-report .centroids-list .centroid-item .div-centroid-totals {
      max-width: 400px;
      margin: 0 16px 0 20px; }
      #centroids-report .centroids-list .centroid-item .div-centroid-totals md-icon {
        margin-top: 3px; }
      #centroids-report .centroids-list .centroid-item .div-centroid-totals .div-centroid-total {
        width: 200px; }
  #centroids-report .centroids-list .week-tracker-readings {
    position: relative;
    min-height: 260px;
    width: 100%; }
    #centroids-report .centroids-list .week-tracker-readings > div {
      width: 100%; }
    #centroids-report .centroids-list .week-tracker-readings .md-subheader-content {
      display: flex;
      justify-content: space-between; }
  #centroids-report .centroids-list .div-day-events {
    max-width: 100%;
    width: 100%; }
    #centroids-report .centroids-list .div-day-events .nv-noData {
      font-size: 16px !important; }

@media screen and (width: 1920px) {
  #centroids-report .centroids-list {
    padding: 0 70px; } }

@media print {
  #centroids-report .header-centroids-list {
    height: 150px;
    min-height: 150px !important;
    max-height: 150px;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: stretch !important; }
    #centroids-report .header-centroids-list .totals {
      flex-direction: row !important;
      margin-top: 5px; }
  #centroids-report .centroids-list .div-centroids {
    display: block !important;
    position: relative !important; }
  #centroids-report .centroids-list .div-day-events {
    display: block !important; }
  #centroids-report .centroids-list .centroid-item {
    box-shadow: none;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    position: relative;
    display: block;
    page-break-inside: avoid; }
  #centroids-report table {
    max-width: 100%; }
    #centroids-report table tr,
    #centroids-report table th,
    #centroids-report table td {
      text-align: center !important;
      padding: 5px; }
    #centroids-report table tbody td, #centroids-report table tfoot td {
      padding: 0 12px 0 0 !important; }
  #centroids-report .print-group {
    position: relative;
    display: block; }
  #centroids-report .print-info {
    position: relative;
    display: block !important;
    float: right; }
  #centroids-report .centroids-table {
    display: block !important;
    max-width: 305px !important;
    min-width: 305px !important;
    width: 305px !important;
    margin-bottom: 35px;
    float: left; } }

#centroids-report .header-centroids-list {
  height: 65px;
  min-height: 65px !important;
  max-height: 65px;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #centroids-report .header-centroids-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }
  #centroids-report .header-centroids-list .div-list-controls .order-by-directive {
    max-width: 180px;
    margin-right: 5px; }

#centroids-report .centroids-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #centroids-report .centroids-list p {
    margin: 0; }
  #centroids-report .centroids-list [kb-item].kb-active {
    background: #EEEEEE; }
  #centroids-report .centroids-list .div-centroids {
    width: 100%;
    height: auto;
    padding: 6px 12px;
    display: block; }
    #centroids-report .centroids-list .div-centroids .div-static-map {
      position: relative;
      min-width: 80px;
      max-width: 80px;
      min-height: 250px;
      max-height: 250px;
      margin-top: 10px;
      margin-left: 12px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      box-shadow: -1px 1px 6px 0 rgba(0, 0, 0, 0.12); }
      #centroids-report .centroids-list .div-centroids .div-static-map:hover {
        min-width: 250px;
        max-width: 250px; }
        #centroids-report .centroids-list .div-centroids .div-static-map:hover .button-toggle {
          opacity: 0; }
      #centroids-report .centroids-list .div-centroids .div-static-map .div-background {
        position: absolute;
        width: 250px;
        height: 250px;
        background: url("/assets/img/default_static_map_640_600.png");
        opacity: .4; }
      #centroids-report .centroids-list .div-centroids .div-static-map .button-toggle {
        position: absolute;
        top: 50%;
        left: -10px;
        opacity: 1;
        z-index: 4;
        background-color: white;
        box-shadow: -1px 1px 6px 0 rgba(0, 0, 0, 0.2);
        -webkit-transition: opacity 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out;
        -ms-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out; }
      #centroids-report .centroids-list .div-centroids .div-static-map img {
        height: 250px;
        z-index: 3;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
  #centroids-report .centroids-list .centroid-item {
    position: relative;
    height: auto !important;
    padding: 16px;
    margin-bottom: 15px;
    text-transform: none;
    cursor: pointer; }
    #centroids-report .centroids-list .centroid-item .div-centroid-item-wrapper {
      overflow-x: hidden; }
    #centroids-report .centroids-list .centroid-item .avatar {
      font-size: 14px; }
      #centroids-report .centroids-list .centroid-item .avatar md-icon {
        color: #FFFFFF; }
    #centroids-report .centroids-list .centroid-item.completed {
      background: #EEEEEE; }
      #centroids-report .centroids-list .centroid-item.completed .title,
      #centroids-report .centroids-list .centroid-item.completed .notes {
        color: rgba(0, 0, 0, 0.54); }
    #centroids-report .centroids-list .centroid-item.selected {
      background: #FFF8E1; }
    #centroids-report .centroids-list .centroid-item .info {
      margin: 0 16px 0 8px; }
      #centroids-report .centroids-list .centroid-item .info .title {
        font-size: 15px;
        font-weight: 500; }
      #centroids-report .centroids-list .centroid-item .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
      #centroids-report .centroids-list .centroid-item .info md-icon {
        margin: 0;
        margin-right: 3px; }
    #centroids-report .centroids-list .centroid-item .secondary-text md-icon {
      margin: 0;
      margin-right: 3px;
      vertical-align: text-top; }
    #centroids-report .centroids-list .centroid-item .div-centroid-totals {
      max-width: 400px;
      margin-right: 12px; }
      #centroids-report .centroids-list .centroid-item .div-centroid-totals md-icon {
        margin-top: 3px; }
      #centroids-report .centroids-list .centroid-item .div-centroid-totals .div-centroid-total {
        width: 200px; }
  #centroids-report .centroids-list .week-tracker-readings {
    position: relative;
    min-height: 260px;
    width: 100%; }
    #centroids-report .centroids-list .week-tracker-readings .md-subheader {
      color: rgba(63, 81, 181, 0.8);
      background-color: transparent; }
      #centroids-report .centroids-list .week-tracker-readings .md-subheader .md-subheader-inner {
        padding: 12px;
        padding-top: 0; }
        #centroids-report .centroids-list .week-tracker-readings .md-subheader .md-subheader-inner .title {
          color: rgba(63, 81, 181, 0.8); }
    #centroids-report .centroids-list .week-tracker-readings > div {
      width: 100%; }
    #centroids-report .centroids-list .week-tracker-readings .md-subheader-content {
      display: flex;
      justify-content: space-between; }
  #centroids-report .centroids-list .div-day-events {
    max-width: 100%;
    width: 100%;
    margin-top: 5px;
    padding-top: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-right: solid 1px rgba(0, 0, 0, 0.04); }
    #centroids-report .centroids-list .div-day-events .nv-noData {
      font-size: 16px !important; }

@media screen and (width: 1920px) {
  #centroids-report .centroids-list {
    padding: 0 70px; } }

@media print {
  #centroids-report .header-centroids-list {
    height: 150px;
    min-height: 150px !important;
    max-height: 150px;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: stretch !important; }
    #centroids-report .header-centroids-list .totals {
      flex-direction: row !important;
      margin-top: 5px; }
  #centroids-report .centroids-list .div-centroids {
    display: block !important;
    position: relative !important; }
  #centroids-report .centroids-list .div-day-events {
    display: block !important; }
  #centroids-report .centroids-list .centroid-item {
    box-shadow: none;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    position: relative;
    display: block;
    page-break-inside: avoid; }
  #centroids-report table {
    max-width: 100%; }
    #centroids-report table tr,
    #centroids-report table th,
    #centroids-report table td {
      text-align: center !important;
      padding: 5px; }
    #centroids-report table tbody td, #centroids-report table tfoot td {
      padding: 0 12px 0 0 !important; }
  #centroids-report .print-group {
    position: relative;
    display: block; }
  #centroids-report .print-info {
    position: relative;
    display: block !important;
    float: right; }
  #centroids-report .centroids-table {
    display: block !important;
    max-width: 305px !important;
    min-width: 305px !important;
    width: 305px !important;
    margin-bottom: 35px;
    float: left; } }

.header-centroids-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-centroids-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }
  .header-centroids-list .div-sub-total {
    flex-flow: row wrap;
    margin-right: 10px; }

.centroids-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .centroids-list p {
    margin: 0; }
  .centroids-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.centroid-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .centroid-item .avatar {
    font-size: 14px; }
  .centroid-item .info {
    margin: 0 16px 0 8px; }
    .centroid-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .centroid-item .info md-icon {
      margin: 0;
      margin-right: 3px; }
    .centroid-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .centroid-item .secondary-text md-icon {
    margin: 0;
    margin-right: 3px; }

@media screen and (max-width: 450px) {
  .header-centroids-list {
    height: 120px;
    min-height: 120px !important;
    max-height: 120px;
    padding: 8px 24px;
    flex-direction: column !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .header-centroids-list .div-total > md-icon {
      margin-left: 0;
      margin-right: 5px; } }

.header-contracts-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-contracts-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

[kb-item].kb-active {
  background: #EEEEEE; }

.contracts-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .contracts-list p {
    margin: 0; }
  .contracts-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.contract-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .contract-item .avatar {
    font-size: 14px; }
  .contract-item.completed {
    background: #EEEEEE; }
    .contract-item.completed .title,
    .contract-item.completed .notes {
      color: rgba(0, 0, 0, 0.54); }
  .contract-item.selected {
    background: #FFF8E1; }
  .contract-item .info {
    margin: 0 16px 0 8px; }
    .contract-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .contract-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .contract-item .secondary-text md-icon {
    margin: 0; }

.contracts-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px; }
  .contracts-view .md-subheader .md-subheader-inner {
    padding-top: 8px; }
  .contracts-view .header-contracts-view {
    min-height: 80px;
    max-height: 80px;
    background-color: #263238 !important;
    color: #fff !important; }
    .contracts-view .header-contracts-view .md-toolbar-tools-top .title {
      padding-left: 0; }
    .contracts-view .header-contracts-view .info-contracts {
      font-size: 14px; }
    .contracts-view .header-contracts-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .contracts-view .div-floating-list-button {
    bottom: 12px; }
  .contracts-view md-content {
    overflow-x: hidden; }
    .contracts-view md-content .list-item {
      padding-top: 12px;
      font-size: 14px; }
      .contracts-view md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
      .contracts-view md-content .list-item .note {
        font-style: italic; }
    .contracts-view md-content .contract-details {
      padding: 16px;
      height: auto; }
      .contracts-view md-content .contract-details .info {
        margin-top: 5px;
        font-size: 14px; }
        .contracts-view md-content .contract-details .info .notes {
          font-style: italic; }
    .contracts-view md-content p {
      margin: 0; }

@media print {
  .sidenav-contracts-view {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    transform: translate(0, 0);
    overflow: visible; }
    .sidenav-contracts-view .contracts-view {
      max-width: 100%;
      height: 100%;
      overflow: visible; }
      .sidenav-contracts-view .contracts-view .content-contracts-view .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .sidenav-contracts-view .contracts-view .content-contracts-view table {
        max-width: 60%; }
        .sidenav-contracts-view .contracts-view .content-contracts-view table tr,
        .sidenav-contracts-view .contracts-view .content-contracts-view table th,
        .sidenav-contracts-view .contracts-view .content-contracts-view table td {
          text-align: center !important; }
        .sidenav-contracts-view .contracts-view .content-contracts-view table tbody td {
          padding: 0 12px 0 0 !important; } }

.header-lines-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-lines-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

.lines-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .lines-list p {
    margin: 0; }
  .lines-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.line-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .line-item .avatar {
    font-size: 14px; }
  .line-item .info {
    margin: 0 16px 0 8px; }
    .line-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .line-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .line-item .secondary-text md-icon {
    margin: 0; }

.header-employees-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-employees-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

.employees-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .employees-list p {
    margin: 0; }
  .employees-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.employee-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .employee-item .avatar {
    font-size: 14px; }
  .employee-item .info {
    margin: 0 16px 0 8px; }
    .employee-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .employee-item .info md-icon {
      margin: 0;
      margin-right: 3px; }
    .employee-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .employee-item .secondary-text md-icon {
    margin: 0;
    margin-right: 3px; }

.header-modelOperationLogs-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-modelOperationLogs-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

[kb-item].kb-active {
  background: #EEEEEE; }

.modelOperationLogs-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .modelOperationLogs-list p {
    margin: 0; }
  .modelOperationLogs-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.modelOperationLog-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .modelOperationLog-item .avatar {
    font-size: 14px; }
  .modelOperationLog-item.completed {
    background: #EEEEEE; }
    .modelOperationLog-item.completed .title,
    .modelOperationLog-item.completed .notes {
      color: rgba(0, 0, 0, 0.54); }
  .modelOperationLog-item.selected {
    background: #FFF8E1; }
  .modelOperationLog-item .info {
    margin: 0 16px 0 8px; }
    .modelOperationLog-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .modelOperationLog-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .modelOperationLog-item .secondary-text md-icon {
    margin: 0; }

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important; }
  #modelOperationLogs {
    max-width: 100%;
    height: 100%;
    width: 100%;
    overflow: visible !important; }
    #modelOperationLogs .modelOperationLogs-list {
      max-width: 100%;
      height: 100%;
      width: 100%;
      overflow: visible !important;
      border: 0;
      outline: none; }
      #modelOperationLogs .modelOperationLogs-list .modelOperationLog-table {
        width: 100%;
        border: 0;
        z-index: 999; }
    #modelOperationLogs .modelOperationLogs-view,
    #modelOperationLogs .header,
    #modelOperationLogs .main-sidenav,
    #modelOperationLogs .md-sidenav-right {
      display: none; } }

.modelOperationLogs-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px; }
  .modelOperationLogs-view .md-subheader .md-subheader-inner {
    padding-top: 8px; }
  .modelOperationLogs-view .header-modelOperationLogs-view {
    min-height: 80px;
    max-height: 80px;
    background-color: #263238 !important;
    color: #fff !important; }
    .modelOperationLogs-view .header-modelOperationLogs-view .md-toolbar-tools-top .title {
      padding-left: 0; }
    .modelOperationLogs-view .header-modelOperationLogs-view .info-modelOperationLogs {
      font-size: 14px; }
    .modelOperationLogs-view .header-modelOperationLogs-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .modelOperationLogs-view .div-floating-list-button {
    bottom: 12px; }
  .modelOperationLogs-view .div-modelOperationLogs-img .modelOperationLogs-img {
    z-index: 2; }
    .modelOperationLogs-view .div-modelOperationLogs-img .modelOperationLogs-img .ng-image-gallery-modal {
      height: 225px; }
      .modelOperationLogs-view .div-modelOperationLogs-img .modelOperationLogs-img .ng-image-gallery-modal img {
        width: 100%; }
  .modelOperationLogs-view .div-modelOperationLogs-img .modelOperationLogs-map-img {
    position: absolute;
    right: 0; }
  .modelOperationLogs-view md-content {
    overflow-x: hidden; }
    .modelOperationLogs-view md-content .door-occurrences .div-total-occurrences {
      border-right: solid 1px rgba(0, 0, 0, 0.16); }
    .modelOperationLogs-view md-content .div-occurrences,
    .modelOperationLogs-view md-content .door-occurrences,
    .modelOperationLogs-view md-content .centroid-occurrences {
      position: relative;
      width: 100%; }
      .modelOperationLogs-view md-content .div-occurrences > div,
      .modelOperationLogs-view md-content .door-occurrences > div,
      .modelOperationLogs-view md-content .centroid-occurrences > div {
        width: 100%; }
      .modelOperationLogs-view md-content .div-occurrences .subtitle,
      .modelOperationLogs-view md-content .door-occurrences .subtitle,
      .modelOperationLogs-view md-content .centroid-occurrences .subtitle {
        padding: 12px; }
      .modelOperationLogs-view md-content .div-occurrences .div-total-occurrences,
      .modelOperationLogs-view md-content .door-occurrences .div-total-occurrences,
      .modelOperationLogs-view md-content .centroid-occurrences .div-total-occurrences {
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer; }
        .modelOperationLogs-view md-content .div-occurrences .div-total-occurrences md-icon,
        .modelOperationLogs-view md-content .door-occurrences .div-total-occurrences md-icon,
        .modelOperationLogs-view md-content .centroid-occurrences .div-total-occurrences md-icon {
          margin: 0;
          margin-right: 5px; }
    .modelOperationLogs-view md-content .list-item {
      padding-top: 12px;
      font-size: 14px; }
      .modelOperationLogs-view md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
    .modelOperationLogs-view md-content .model-details {
      padding: 16px 16px;
      height: auto; }
    .modelOperationLogs-view md-content p {
      margin: 0; }

@media print {
  .sidenav-modelOperationLogs-view {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    transform: translate(0, 0);
    overflow: visible; }
    .sidenav-modelOperationLogs-view .modelOperationLogs-view {
      max-width: 100%;
      height: 100%;
      overflow: visible; }
      .sidenav-modelOperationLogs-view .modelOperationLogs-view .content-modelOperationLogs-view .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .sidenav-modelOperationLogs-view .modelOperationLogs-view .content-modelOperationLogs-view table {
        max-width: 60%; }
        .sidenav-modelOperationLogs-view .modelOperationLogs-view .content-modelOperationLogs-view table tr,
        .sidenav-modelOperationLogs-view .modelOperationLogs-view .content-modelOperationLogs-view table th,
        .sidenav-modelOperationLogs-view .modelOperationLogs-view .content-modelOperationLogs-view table td {
          text-align: center !important; }
        .sidenav-modelOperationLogs-view .modelOperationLogs-view .content-modelOperationLogs-view table tbody td {
          padding: 0 12px 0 0 !important; } }

.organization-select-container {
  width: 100%; }

.header-services-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-services-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

.services-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .services-list p {
    margin: 0; }
  .services-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.service-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .service-item .avatar {
    font-size: 14px; }
  .service-item .info {
    margin: 0 16px 0 8px; }
    .service-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .service-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .service-item .secondary-text md-icon {
    margin: 0; }

#simCards .header-simCards-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #simCards .header-simCards-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

#simCards .simCards-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #simCards .simCards-list [kb-item].kb-active {
    background: #EEEEEE; }
  #simCards .simCards-list p {
    margin: 0; }
  #simCards .simCards-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  #simCards .simCards-list .simCard-item {
    position: relative;
    height: auto !important;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-transform: none;
    cursor: pointer; }
    #simCards .simCards-list .simCard-item .avatar {
      font-size: 14px; }
    #simCards .simCards-list .simCard-item.completed {
      background: #EEEEEE; }
      #simCards .simCards-list .simCard-item.completed .title,
      #simCards .simCards-list .simCard-item.completed .notes {
        color: rgba(0, 0, 0, 0.54); }
    #simCards .simCards-list .simCard-item.selected {
      background: #FFF8E1; }
    #simCards .simCards-list .simCard-item .info {
      margin: 0 16px 0 8px; }
      #simCards .simCards-list .simCard-item .info .title {
        font-size: 15px;
        font-weight: 500; }
      #simCards .simCards-list .simCard-item .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
    #simCards .simCards-list .simCard-item .secondary-text md-icon {
      margin: 0; }
    #simCards .simCards-list .simCard-item .note {
      font-style: italic; }

.simCards-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px; }
  .simCards-view .md-subheader .md-subheader-inner {
    padding-top: 8px; }
  .simCards-view .header-simCards-view {
    min-height: 80px;
    max-height: 80px;
    background-color: #263238 !important;
    color: #fff !important; }
    .simCards-view .header-simCards-view .md-toolbar-tools-top .title {
      padding-left: 0; }
    .simCards-view .header-simCards-view .info-simCards {
      font-size: 14px; }
    .simCards-view .header-simCards-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .simCards-view .div-floating-list-button {
    bottom: 12px; }
  .simCards-view md-content {
    overflow-x: hidden; }
    .simCards-view md-content .list-item {
      padding-top: 12px;
      font-size: 14px; }
      .simCards-view md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
    .simCards-view md-content .simCard-details {
      padding: 16px;
      height: auto; }
      .simCards-view md-content .simCard-details .info {
        margin-top: 5px;
        font-size: 14px; }
        .simCards-view md-content .simCard-details .info .notes {
          font-style: italic; }
    .simCards-view md-content .note {
      font-style: italic; }
    .simCards-view md-content p {
      margin: 0; }

@media print {
  .sidenav-simCards-view {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    transform: translate(0, 0);
    overflow: visible; }
    .sidenav-simCards-view .simCards-view {
      max-width: 100%;
      height: 100%;
      overflow: visible; }
      .sidenav-simCards-view .simCards-view .content-simCards-view .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .sidenav-simCards-view .simCards-view .content-simCards-view table {
        max-width: 60%; }
        .sidenav-simCards-view .simCards-view .content-simCards-view table tr,
        .sidenav-simCards-view .simCards-view .content-simCards-view table th,
        .sidenav-simCards-view .simCards-view .content-simCards-view table td {
          text-align: center !important; }
        .sidenav-simCards-view .simCards-view .content-simCards-view table tbody td {
          padding: 0 12px 0 0 !important; } }

#terminals-list-dashboard .header-terminals-list {
  height: 45px;
  min-height: 45px !important;
  max-height: 45px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #terminals-list-dashboard .header-terminals-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

#terminals-list-dashboard .terminals-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #terminals-list-dashboard .terminals-list p {
    margin: 0; }
  #terminals-list-dashboard .terminals-list .div-terminals {
    width: 100%;
    height: auto;
    padding: 6px 12px;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    flex-flow: row wrap; }

@media screen and (max-width: 600px) {
  #terminals-list-dashboard .terminals-list .div-terminals {
    height: auto;
    padding: 6px 0;
    width: 100%;
    -webkit-flex-flow: column nowrap;
    -moz-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    -o-flex-flow: column nowrap;
    flex-flow: column nowrap; } }

#terminals-list-dashboard .header-terminals-list {
  height: 45px;
  min-height: 45px !important;
  max-height: 45px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #terminals-list-dashboard .header-terminals-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

@media screen and (max-width: 600px) {
  #terminals-list-dashboard .terminals-status-list .div-terminals {
    height: auto;
    padding: 6px 0; }
    #terminals-list-dashboard .terminals-status-list .div-terminals .div-terminals {
      width: 100%;
      -webkit-flex-flow: column nowrap;
      -moz-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
      -o-flex-flow: column nowrap;
      flex-flow: column nowrap; } }

#terminals-list-dashboard .header-terminals-list {
  height: 45px;
  min-height: 45px !important;
  max-height: 45px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #terminals-list-dashboard .header-terminals-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

@media screen and (max-width: 600px) {
  #terminals-list-dashboard .terminals-status-list .div-terminals {
    height: auto;
    padding: 6px 0; }
    #terminals-list-dashboard .terminals-status-list .div-terminals .div-terminals {
      width: 100%;
      -webkit-flex-flow: column nowrap;
      -moz-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
      -o-flex-flow: column nowrap;
      flex-flow: column nowrap; } }

#terminals-list-dashboard .header-terminals-list {
  height: 45px;
  min-height: 45px !important;
  max-height: 45px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #terminals-list-dashboard .header-terminals-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }

@media screen and (max-width: 600px) {
  #terminals-list-dashboard .terminals-status-list .div-terminals {
    height: auto;
    padding: 6px 0; }
    #terminals-list-dashboard .terminals-status-list .div-terminals .div-terminals {
      width: 100%;
      -webkit-flex-flow: column nowrap;
      -moz-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
      -o-flex-flow: column nowrap;
      flex-flow: column nowrap; } }

.header-terminals-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-terminals-list md-checkbox {
    margin: 0; }
    .header-terminals-list md-checkbox.md-checked .md-ink-ripple {
      color: #3f51b5 !important; }
    .header-terminals-list md-checkbox.md-checked .md-icon {
      background-color: #3f51b5 !important; }
  .header-terminals-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }
  .header-terminals-list .div-total .div-icon-loading {
    max-width: 100px; }
  .header-terminals-list .div-sub-total {
    flex-flow: row wrap;
    margin-right: 10px; }

.command-actions md-menu-item {
  width: 100% !important; }

.terminals-list-sections {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .terminals-list-sections p {
    margin: 0; }
  .terminals-list-sections .md-subheader .md-subheader-inner {
    padding-top: 0; }
  .terminals-list-sections .div-terminals {
    width: 100%;
    height: auto;
    padding: 6px 0;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    flex-flow: row wrap; }
    .terminals-list-sections .div-terminals .terminal-item {
      position: relative;
      height: auto !important;
      padding: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      text-transform: none;
      cursor: pointer; }
      .terminals-list-sections .div-terminals .terminal-item .avatar {
        font-size: 14px; }
      .terminals-list-sections .div-terminals .terminal-item .info {
        margin: 0 16px 0 8px; }
        .terminals-list-sections .div-terminals .terminal-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        .terminals-list-sections .div-terminals .terminal-item .info md-icon {
          margin: 0;
          margin-right: 3px; }
        .terminals-list-sections .div-terminals .terminal-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
      .terminals-list-sections .div-terminals .terminal-item .secondary-text md-icon {
        margin: 0;
        margin-right: 3px; }
      .terminals-list-sections .div-terminals .terminal-item md-input-container {
        margin: 30px 0; }
      .terminals-list-sections .div-terminals .terminal-item md-checkbox {
        margin: 0; }
        .terminals-list-sections .div-terminals .terminal-item md-checkbox.md-checked .md-ink-ripple {
          color: #3f51b5 !important; }
        .terminals-list-sections .div-terminals .terminal-item md-checkbox.md-checked .md-icon {
          background-color: #3f51b5 !important; }
    .terminals-list-sections .div-terminals .scroll-load {
      padding: 15px; }

@media screen and (max-width: 680px) {
  .header-terminals-list .terminal-actions-menu-text {
    display: none; }
  .header-terminals-list md-menu-bar {
    padding: 0px; }
    .header-terminals-list md-menu-bar button {
      min-width: 30px !important; } }

#installation-branch {
  background-color: whitesmoke; }
  #installation-branch .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    background-color: #394b6d;
    color: #FFFFFF; }
    #installation-branch .center .header .search-wrapper {
      background: #fff; }
      #installation-branch .center .header .search-wrapper .div-search {
        height: 56px;
        min-height: 56px;
        padding: 18px; }
        #installation-branch .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }
  #installation-branch .center .header-branches-list {
    height: 65px;
    min-height: 65px !important;
    max-height: 65px;
    padding: 8px 24px;
    flex-direction: column !important;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #installation-branch .center .header-branches-list .div-total > md-icon {
      margin-left: 0;
      margin-right: 5px; }
    #installation-branch .center .header-branches-list .expandable-search .mdl-layout {
      align-items: center;
      justify-content: center; }
    #installation-branch .center .header-branches-list .expandable-search .mdl-layout__content {
      padding: 24px;
      flex: none; }
    #installation-branch .center .header-branches-list .expandable-search .mdl-button--icon {
      top: 0; }
    #installation-branch .center .header-branches-list .expandable-search .mdl-textfield--expandable {
      padding: 0; }
      #installation-branch .center .header-branches-list .expandable-search .mdl-textfield--expandable .mdl-textfield__expandable-holder .mdl-textfield__input {
        font-size: 1.3rem; }
    #installation-branch .center .header-branches-list .expandable-search .mdl-textfield--expandable.is-focused, #installation-branch .center .header-branches-list .expandable-search .is-dirty {
      min-width: 300px;
      max-width: 300px; }
      #installation-branch .center .header-branches-list .expandable-search .mdl-textfield--expandable.is-focused .mdl-textfield__expandable-holder .mdl-textfield__input, #installation-branch .center .header-branches-list .expandable-search .is-dirty .mdl-textfield__expandable-holder .mdl-textfield__input {
        min-width: 300px;
        max-width: 300px; }
  #installation-branch .center .branches-list {
    height: 100%;
    padding-bottom: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    #installation-branch .center .branches-list p {
      margin: 0; }
    #installation-branch .center .branches-list .md-subheader .md-subheader-inner {
      padding-top: 0; }
  #installation-branch .center .branch-item {
    position: relative;
    height: auto !important;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-transform: none;
    cursor: pointer; }
    #installation-branch .center .branch-item .avatar {
      font-size: 14px; }
    #installation-branch .center .branch-item .info {
      margin: 0 16px 0 8px; }
      #installation-branch .center .branch-item .info .title {
        font-size: 15px;
        font-weight: 500; }
      #installation-branch .center .branch-item .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
    #installation-branch .center .branch-item .secondary-text md-icon {
      margin: 0; }
    #installation-branch .center .branch-item .selected {
      background-color: rgba(0, 0, 0, 0.12); }

@media screen and (max-width: 960px) {
  #installation-branch .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #installation-branch .center .header {
    padding-left: 16px; } }

.terminals-list {
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .terminals-list p {
    margin: 0; }

@media screen and (max-width: 450px) {
  .header-branches-list {
    height: 120px !important;
    min-height: 120px !important;
    max-height: 120px;
    display: block !important;
    padding: 8px 15px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .header-branches-list .div-total > md-icon {
      margin-left: 0;
      margin-right: 5px; }
    .header-branches-list .expandable-search .mdl-button--icon {
      top: 5px !important; }
    .header-branches-list .expandable-search .mdl-textfield--expandable.is-focused, .header-branches-list .expandable-search .is-dirty {
      min-width: unset !important;
      max-width: unset !important;
      flex: 1; }
      .header-branches-list .expandable-search .mdl-textfield--expandable.is-focused .mdl-textfield__expandable-holder, .header-branches-list .expandable-search .is-dirty .mdl-textfield__expandable-holder {
        flex: 1; }
        .header-branches-list .expandable-search .mdl-textfield--expandable.is-focused .mdl-textfield__expandable-holder .mdl-textfield__input, .header-branches-list .expandable-search .is-dirty .mdl-textfield__expandable-holder .mdl-textfield__input {
          padding-top: 10px !important;
          min-width: unset !important; } }

#installation-organizations {
  background-color: whitesmoke; }
  #installation-organizations .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    background-color: #394b6d;
    color: #FFFFFF; }
    #installation-organizations .center .header .search-wrapper {
      background: #fff; }
      #installation-organizations .center .header .search-wrapper .div-search {
        height: 56px;
        min-height: 56px;
        padding: 18px; }
        #installation-organizations .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }
  #installation-organizations .center .header-organizations-list {
    height: 65px;
    min-height: 65px !important;
    max-height: 65px;
    padding: 8px 24px;
    flex-direction: column !important;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #installation-organizations .center .header-organizations-list .div-total > md-icon {
      margin-left: 0;
      margin-right: 5px; }
    #installation-organizations .center .header-organizations-list .expandable-search .mdl-layout {
      align-items: center;
      justify-content: center; }
    #installation-organizations .center .header-organizations-list .expandable-search .mdl-layout__content {
      padding: 24px;
      flex: none; }
    #installation-organizations .center .header-organizations-list .expandable-search .mdl-button--icon {
      top: 0; }
    #installation-organizations .center .header-organizations-list .expandable-search .mdl-textfield--expandable {
      padding: 0; }
      #installation-organizations .center .header-organizations-list .expandable-search .mdl-textfield--expandable .mdl-textfield__expandable-holder .mdl-textfield__input {
        font-size: 1.3rem; }
    #installation-organizations .center .header-organizations-list .expandable-search .mdl-textfield--expandable.is-focused, #installation-organizations .center .header-organizations-list .expandable-search .is-dirty {
      min-width: 300px;
      max-width: 300px; }
      #installation-organizations .center .header-organizations-list .expandable-search .mdl-textfield--expandable.is-focused .mdl-textfield__expandable-holder .mdl-textfield__input, #installation-organizations .center .header-organizations-list .expandable-search .is-dirty .mdl-textfield__expandable-holder .mdl-textfield__input {
        min-width: 300px;
        max-width: 300px; }
  #installation-organizations .center .organizations-list {
    height: 100%;
    padding-bottom: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    #installation-organizations .center .organizations-list p {
      margin: 0; }
    #installation-organizations .center .organizations-list .md-subheader .md-subheader-inner {
      padding-top: 0; }
  #installation-organizations .center .organization-item {
    position: relative;
    height: auto !important;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-transform: none;
    cursor: pointer; }
    #installation-organizations .center .organization-item .avatar {
      font-size: 14px; }
      #installation-organizations .center .organization-item .avatar md-icon {
        margin: 0;
        color: #fff; }
    #installation-organizations .center .organization-item .info {
      margin: 0 16px 0 8px; }
      #installation-organizations .center .organization-item .info .title {
        font-size: 15px;
        font-weight: 500; }
      #installation-organizations .center .organization-item .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
    #installation-organizations .center .organization-item .secondary-text md-icon {
      margin: 0;
      margin-right: 3px; }
    #installation-organizations .center .organization-item .secondary-text .div-icon-loading {
      display: inline-block; }
    #installation-organizations .center .organization-item .selected {
      background-color: rgba(0, 0, 0, 0.12); }

@media screen and (max-width: 960px) {
  #installation-organizations .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #installation-organizations .center .header {
    padding-left: 16px; } }

@media screen and (max-width: 450px) {
  .header-organizations-list {
    height: 120px !important;
    min-height: 120px !important;
    max-height: 120px;
    display: block !important;
    padding: 8px 15px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .header-organizations-list .div-total > md-icon {
      margin-left: 0;
      margin-right: 5px; }
    .header-organizations-list .expandable-search .mdl-button--icon {
      top: 5px !important; }
    .header-organizations-list .expandable-search .mdl-textfield--expandable.is-focused, .header-organizations-list .expandable-search .is-dirty {
      min-width: unset !important;
      max-width: unset !important;
      flex: 1; }
      .header-organizations-list .expandable-search .mdl-textfield--expandable.is-focused .mdl-textfield__expandable-holder, .header-organizations-list .expandable-search .is-dirty .mdl-textfield__expandable-holder {
        flex: 1; }
        .header-organizations-list .expandable-search .mdl-textfield--expandable.is-focused .mdl-textfield__expandable-holder .mdl-textfield__input, .header-organizations-list .expandable-search .is-dirty .mdl-textfield__expandable-holder .mdl-textfield__input {
          padding-top: 10px !important;
          min-width: unset !important; } }

#installation-terminals {
  background-color: whitesmoke; }
  #installation-terminals .div-center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    background-color: #394b6d;
    color: #FFFFFF; }
  #installation-terminals .div-center .header-terminals-list {
    max-height: 100px;
    min-height: auto !important;
    height: auto;
    padding: 8px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #installation-terminals .div-center .header-terminals-list .div-total > md-icon {
      margin-left: 0;
      margin-right: 5px; }
  #installation-terminals .div-center .terminals-list {
    height: 100%;
    padding-bottom: 36px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    #installation-terminals .div-center .terminals-list p {
      margin: 0; }
    #installation-terminals .div-center .terminals-list .md-subheader .md-subheader-inner {
      padding-top: 0; }
    #installation-terminals .div-center .terminals-list .div-terminals-group {
      width: 100%;
      -webkit-flex-flow: row wrap;
      -moz-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      flex-flow: row wrap; }
  #installation-terminals .div-center .div-floating-list-button {
    z-index: 10; }

@media screen and (max-width: 960px) {
  #installation-terminals .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #installation-terminals .center .header {
    padding-left: 16px; }
  #installation-terminals .terminals-list .div-terminals-group {
    width: 100%;
    -webkit-flex-flow: column nowrap;
    -moz-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    -o-flex-flow: column nowrap;
    flex-flow: column nowrap; } }

#technicalInfos .header-technicalInfos-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #technicalInfos .header-technicalInfos-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }
  #technicalInfos .header-technicalInfos-list .div-list-controls .order-by-directive {
    max-width: 180px;
    margin-right: 5px; }

#technicalInfos [kb-item].kb-active {
  background: #EEEEEE; }

#technicalInfos .technicalInfos-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #technicalInfos .technicalInfos-list p {
    margin: 0; }
  #technicalInfos .technicalInfos-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

#technicalInfos .technicalInfo-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  #technicalInfos .technicalInfo-item .avatar {
    font-size: 14px; }
    #technicalInfos .technicalInfo-item .avatar md-icon {
      color: #FFFFFF; }
  #technicalInfos .technicalInfo-item.completed {
    background: #EEEEEE; }
    #technicalInfos .technicalInfo-item.completed .title,
    #technicalInfos .technicalInfo-item.completed .notes {
      color: rgba(0, 0, 0, 0.54); }
  #technicalInfos .technicalInfo-item.selected {
    background: #FFF8E1; }
  #technicalInfos .technicalInfo-item .info {
    margin: 0 16px 0 8px; }
    #technicalInfos .technicalInfo-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    #technicalInfos .technicalInfo-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  #technicalInfos .technicalInfo-item .secondary-text md-icon {
    margin-right: 3px;
    vertical-align: text-top; }

md-dialog#trackerReadingsImages-list {
  max-width: 100% !important;
  min-height: 95% !important;
  width: 95% !important; }
  md-dialog#trackerReadingsImages-list .transformable {
    -webkit-transition: min-height 150ms linear;
    -moz-transition: min-height 150ms linear;
    -o-transition: min-height 150ms linear;
    -ms-transition: min-height 150ms linear;
    transition: min-height 150ms linear; }
  md-dialog#trackerReadingsImages-list .expanded {
    min-height: 200px; }
  md-dialog#trackerReadingsImages-list .collapsed {
    min-height: 64px; }
  md-dialog#trackerReadingsImages-list .header-trackerReading-list {
    background-color: #394b6d;
    color: #FFFFFF; }
    md-dialog#trackerReadingsImages-list .header-trackerReading-list md-icon {
      margin: 0;
      color: #FFFFFF; }
    md-dialog#trackerReadingsImages-list .header-trackerReading-list.md-subheader {
      min-height: 70px; }
    md-dialog#trackerReadingsImages-list .header-trackerReading-list .md-button[disabled] md-icon {
      opacity: .4; }
    md-dialog#trackerReadingsImages-list .header-trackerReading-list .total-trackerReadings-list {
      max-height: 25px; }
    md-dialog#trackerReadingsImages-list .header-trackerReading-list .secondary-info md-icon {
      margin-right: 5px; }
    md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters {
      padding: 5px 0;
      font-size: 14px; }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters .date-range-filter {
        margin-top: 10px; }
        md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters .date-range-filter md-input-container md-datepicker input {
          color: #FFFFFF; }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters .terminal-filter {
        margin-top: 10px; }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters .item {
        cursor: pointer; }
        md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters .item.selected {
          background-color: rgba(0, 0, 0, 0.06); }
        md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters .item label {
          cursor: pointer; }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters .select-filter {
        padding: 0 24px 0 16px; }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters md-icon {
        color: rgba(0, 0, 0, 0.56); }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters md-input-container {
        max-height: 30px; }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters md-select {
        height: 35px !important; }
      md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters mdp-time-picker > div {
        padding: 0 24px 0 12px; }
        md-dialog#trackerReadingsImages-list .header-trackerReading-list .filters mdp-time-picker > div md-input-container {
          -webkit-flex: 1;
          -moz-flex: 1;
          -ms-flex: 1;
          -o-flex: 1;
          flex: 1; }
  md-dialog#trackerReadingsImages-list.md-locked-open .header {
    color: #FFFFFF; }
    md-dialog#trackerReadingsImages-list.md-locked-open .header .logo {
      padding-top: 27px; }
      md-dialog#trackerReadingsImages-list.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  md-dialog#trackerReadingsImages-list:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }
  md-dialog#trackerReadingsImages-list .md-subheader {
    z-index: 3 !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.08); }
    md-dialog#trackerReadingsImages-list .md-subheader .div-loading-trackerReadings-list {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  md-dialog#trackerReadingsImages-list md-input-container {
    margin-bottom: 0; }
    md-dialog#trackerReadingsImages-list md-input-container > * {
      color: #FFFFFF !important; }
    md-dialog#trackerReadingsImages-list md-input-container input {
      border-bottom-color: #FFFFFF !important; }
    md-dialog#trackerReadingsImages-list md-input-container .md-errors-spacer {
      display: none; }
  md-dialog#trackerReadingsImages-list .div-floating-list-button {
    bottom: 12px; }
  md-dialog#trackerReadingsImages-list .trackerReadings-list {
    height: 100%;
    padding-bottom: 36px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    md-dialog#trackerReadingsImages-list .trackerReadings-list p {
      margin: 0; }
    md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group {
      height: auto;
      min-height: 51px;
      margin: 5px 0;
      padding: 0 0 6px; }
      md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group:not(:last-of-type) {
        border-bottom: solid 1px rgba(0, 0, 0, 0.08); }
      md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group .md-subheader .div-totals {
        min-width: 350px; }
        md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group .md-subheader .div-totals .info .disabled {
          opacity: .4; }
        md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group .md-subheader .div-totals > div {
          margin: 0 8px; }
          md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group .md-subheader .div-totals > div:first-of-type {
            margin-left: 0; }
      md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group .md-subheader .button-show-hide-trackerReadings {
        font-size: 13px; }
      md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group .md-subheader .md-subheader-inner {
        padding: 8px 16px 6px; }
      md-dialog#trackerReadingsImages-list .trackerReadings-list .div-group .div-trackerReadings-group {
        width: 100%;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        flex-flow: row wrap; }
  md-dialog#trackerReadingsImages-list .trackerReading-item {
    position: relative;
    height: auto !important;
    min-height: 20%;
    min-width: 345px !important;
    max-width: 345px;
    width: 345px;
    margin: 6px;
    padding: 8px 0 0;
    text-transform: none;
    cursor: pointer; }
    md-dialog#trackerReadingsImages-list .trackerReading-item .div-event-img {
      min-height: 215px;
      max-width: 330px;
      width: 100%; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-event-img .event-img {
        z-index: 1; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-event-img .event-img-png {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0; }
        md-dialog#trackerReadingsImages-list .trackerReading-item .div-event-img .event-img-png img {
          box-shadow: none; }
    md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item {
      color: #FFFFFF; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .avatar {
        font-size: 14px; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .event-type-info {
        min-width: 300px;
        max-width: 300px;
        max-height: 25px;
        height: 25px; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .info {
        min-width: 190px;
        max-width: 193px;
        margin-right: 4px; }
        md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .info .notes md-icon {
          margin-right: 3px;
          color: #FFFFFF; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .event-header {
        min-width: auto; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .secondary-text md-icon {
        margin: 0;
        margin-right: 5px; }
      md-dialog#trackerReadingsImages-list .trackerReading-item .div-trackerReading-item .trackerReading-address {
        max-height: 35px;
        text-overflow: ellipsis;
        overflow: hidden; }
  md-dialog#trackerReadingsImages-list md-content {
    position: relative;
    padding: 0 !important;
    display: block;
    overflow-x: hidden; }
    md-dialog#trackerReadingsImages-list md-content .div-event-img .div-wrapper-event-img {
      z-index: 1;
      background-color: #FFFFFF; }
      md-dialog#trackerReadingsImages-list md-content .div-event-img .div-wrapper-event-img .div-img {
        position: relative; }
        md-dialog#trackerReadingsImages-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img {
          width: 100%;
          z-index: 2; }
          md-dialog#trackerReadingsImages-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal {
            height: 225px; }
            md-dialog#trackerReadingsImages-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal .galleria-image {
              margin: 0; }
        md-dialog#trackerReadingsImages-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png {
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0; }
          md-dialog#trackerReadingsImages-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png img {
            box-shadow: none; }
    md-dialog#trackerReadingsImages-list md-content .div-event-img .event-map-img {
      position: absolute;
      right: 0; }
    md-dialog#trackerReadingsImages-list md-content .list-item {
      width: 100%;
      min-height: 180px;
      padding: 12px 8px 6px;
      cursor: pointer;
      font-size: 14px;
      color: white; }
      md-dialog#trackerReadingsImages-list md-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
        md-dialog#trackerReadingsImages-list md-content .list-item .title .event-edit-button {
          margin-left: 0;
          padding-left: 0;
          font-size: 22px; }
      md-dialog#trackerReadingsImages-list md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
      md-dialog#trackerReadingsImages-list md-content .list-item .div-event-buttons {
        min-width: 140px; }
        md-dialog#trackerReadingsImages-list md-content .list-item .div-event-buttons button {
          margin-top: 4px;
          margin-bottom: 0; }
      md-dialog#trackerReadingsImages-list md-content .list-item md-icon {
        color: white; }
    md-dialog#trackerReadingsImages-list md-content md-input-container {
      min-width: 130px; }
    md-dialog#trackerReadingsImages-list md-content p {
      margin: 0; }
  md-dialog#trackerReadingsImages-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 960px) {
  md-dialog#trackerReadingsImages-list {
    min-height: 100% !important;
    width: 100% !important; } }

@media print {
  body {
    -webkit-print-color-adjust: exact !important; }
  .page-break-after {
    position: relative !important;
    display: block !important;
    page-break-before: always !important;
    page-break-after: always !important;
    float: none; }
  md-dialog#trackerReadingsImages-list {
    position: relative;
    min-height: 860px !important;
    min-width: 100% !important;
    height: 100%;
    width: 100%;
    display: block !important;
    overflow: visible !important; }
    md-dialog#trackerReadingsImages-list md-content {
      position: relative !important;
      display: block !important;
      overflow: visible !important;
      border: none !important;
      box-shadow: none; }
      md-dialog#trackerReadingsImages-list md-content .div-group {
        float: none !important;
        border-bottom: none; }
        md-dialog#trackerReadingsImages-list md-content .div-group .md-subheader {
          position: fixed !important;
          top: auto !important;
          margin: 12px 0;
          box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 3px 1px -2px rgba(0, 0, 0, 0.08); }
      md-dialog#trackerReadingsImages-list md-content .div-trackerReadings-group {
        display: block;
        min-height: 100px; }
        md-dialog#trackerReadingsImages-list md-content .div-trackerReadings-group > * {
          float: left !important; }
        md-dialog#trackerReadingsImages-list md-content .div-trackerReadings-group .trackerReading-item {
          height: auto !important; }
          md-dialog#trackerReadingsImages-list md-content .div-trackerReadings-group .trackerReading-item:first-of-type, md-dialog#trackerReadingsImages-list md-content .div-trackerReadings-group .trackerReading-item:nth-of-type(2) {
            margin-top: 100px !important; }
          md-dialog#trackerReadingsImages-list md-content .div-trackerReadings-group .trackerReading-item .div-event-img {
            min-height: 205px;
            max-height: 205px;
            margin-bottom: 5px; }
      md-dialog#trackerReadingsImages-list md-content .div-trackerReadings-group,
      md-dialog#trackerReadingsImages-list md-content .trackerReading-item,
      md-dialog#trackerReadingsImages-list md-content .div-event-img,
      md-dialog#trackerReadingsImages-list md-content .div-trackerReading-item {
        position: relative !important;
        page-break-inside: avoid !important; }
    md-dialog#trackerReadingsImages-list md-toolbar {
      color: #000000;
      background-color: #FFFFFF; }
      md-dialog#trackerReadingsImages-list md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    md-dialog#trackerReadingsImages-list .content {
      display: block; }
      md-dialog#trackerReadingsImages-list .content .md-subheader {
        position: initial !important;
        margin: 12px 0; } }

.tracker-reading-events-total {
  min-width: 350px; }
  .tracker-reading-events-total .info .disabled {
    opacity: .4; }
  .tracker-reading-events-total > div {
    margin: 0 8px; }
    .tracker-reading-events-total > div:first-of-type {
      margin-left: 0; }

.people-graph .nvd3.nv-scatter .nv-groups .nv-point,
.people-graph .nvd3 .nv-groups .nv-point {
  stroke-width: 5px;
  fill-opacity: .95 !important;
  stroke-opacity: .95 !important; }

#events .header-events-list {
  height: 65px;
  min-height: 65px !important;
  max-height: 65px;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  #events .header-events-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }
  #events .header-events-list .div-list-controls .order-by-directive {
    max-width: 180px;
    margin-right: 5px; }

#events .events-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  #events .events-list p {
    margin: 0; }
  #events .events-list [kb-item].kb-active {
    background: #EEEEEE; }
  #events .events-list .event-item {
    position: relative;
    height: auto !important;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-transform: none;
    cursor: pointer; }
    #events .events-list .event-item .avatar {
      font-size: 14px; }
      #events .events-list .event-item .avatar md-icon {
        color: #FFFFFF; }
    #events .events-list .event-item.completed {
      background: #EEEEEE; }
      #events .events-list .event-item.completed .title,
      #events .events-list .event-item.completed .notes {
        color: rgba(0, 0, 0, 0.54); }
    #events .events-list .event-item.selected {
      background: #FFF8E1; }
    #events .events-list .event-item .info {
      margin: 0 16px 0 8px; }
      #events .events-list .event-item .info .title {
        font-size: 15px;
        font-weight: 500; }
      #events .events-list .event-item .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
      #events .events-list .event-item .info md-icon {
        margin: 0;
        margin-right: 3px; }
    #events .events-list .event-item .secondary-text md-icon {
      margin: 0;
      margin-right: 3px;
      vertical-align: text-top; }

@media screen and (max-width: 600px) {
  #events .header-events-list .div-sub-total {
    margin-top: 0 !important; } }

#trackerReadings {
  background-color: whitesmoke; }
  #trackerReadings .main-sidenav.open {
    z-index: 60; }
  #trackerReadings md-sidenav {
    background-color: #fafafa; }
    #trackerReadings md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #trackerReadings md-sidenav .header .logo {
        padding-top: 27px; }
        #trackerReadings md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #trackerReadings md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #trackerReadings md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #trackerReadings md-sidenav md-content .item {
      cursor: pointer; }
      #trackerReadings md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #trackerReadings md-sidenav .content {
      padding: 12px 0; }
    #trackerReadings md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #trackerReadings .center {
    padding: 16px; }
    #trackerReadings .center .div-total {
      font-size: 20px; }
      #trackerReadings .center .div-total > md-icon {
        margin: 0 5px 0 0; }
    #trackerReadings .center .header {
      height: 110px;
      min-height: 100px;
      max-height: 136px;
      padding: 12px;
      padding-left: 0;
      background-color: #394b6d;
      color: #FFFFFF; }
      #trackerReadings .center .header .search-wrapper {
        background: #fff; }
        #trackerReadings .center .header .search-wrapper .div-search {
          height: 56px;
          trackerReading-height: 56px;
          padding: 18px; }
          #trackerReadings .center .header .search-wrapper .div-search .input-search {
            height: 56px;
            padding-left: 16px;
            margin: 0;
            border-radius: 0;
            border: none;
            color: rgba(0, 0, 0, 0.54);
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #trackerReadings .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #trackerReadings .center .header {
    padding-left: 16px; } }

.header-users-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;
  padding: 8px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .header-users-list .div-total > md-icon {
    margin-left: 0;
    margin-right: 5px; }
  .header-users-list .div-sub-total {
    flex-flow: row wrap;
    margin-right: 10px; }

.users-list {
  height: 100%;
  padding-bottom: 36px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  overflow-x: hidden; }
  .users-list p {
    margin: 0; }
  .users-list .md-subheader .md-subheader-inner {
    padding-top: 0; }

.user-item {
  position: relative;
  height: auto !important;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: none;
  cursor: pointer; }
  .user-item .avatar {
    font-size: 14px; }
  .user-item .info {
    margin: 0 16px 0 8px; }
    .user-item .info .title {
      font-size: 15px;
      font-weight: 500; }
    .user-item .info md-icon {
      margin: 0;
      margin-right: 3px; }
    .user-item .info .secondary-title {
      margin-left: 5px;
      font-size: 12px !important; }
  .user-item .secondary-text md-icon {
    margin: 0;
    margin-right: 3px; }

@media screen and (max-width: 450px) {
  .header-users-list {
    height: 120px;
    min-height: 120px !important;
    max-height: 120px;
    padding: 8px 24px;
    flex-direction: column !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .header-users-list .div-total > md-icon {
      margin-left: 0;
      margin-right: 5px; } }

md-dialog#allocationTrackerReading-list {
  max-width: 100% !important;
  min-height: 95% !important;
  width: 95% !important; }
  md-dialog#allocationTrackerReading-list .header-trackerReading-list {
    background-color: #394b6d;
    color: #FFFFFF; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list md-icon {
      margin: 0;
      color: #FFFFFF; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list.md-subheader {
      min-height: 70px; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list .md-button[disabled] md-icon {
      opacity: .4; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list .total-trackerReadings-list {
      max-height: 25px; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list .secondary-info md-icon {
      margin-right: 5px; }
  md-dialog#allocationTrackerReading-list .md-subheader {
    z-index: 3 !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08); }
  md-dialog#allocationTrackerReading-list md-input-container {
    margin-bottom: 0; }
    md-dialog#allocationTrackerReading-list md-input-container > * {
      color: #FFFFFF !important; }
    md-dialog#allocationTrackerReading-list md-input-container input {
      border-bottom-color: #FFFFFF !important; }
  md-dialog#allocationTrackerReading-list .div-floating-list-button {
    bottom: 12px; }
  md-dialog#allocationTrackerReading-list .trackerReadings-list {
    height: 100%;
    padding-bottom: 36px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    md-dialog#allocationTrackerReading-list .trackerReadings-list p {
      margin: 0; }
    md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group {
      height: auto;
      padding: 0 0 6px 0; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group:not(:last-of-type) {
        border-bottom: solid 1px rgba(0, 0, 0, 0.08); }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals {
        min-width: 370px; }
        md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals > div {
          margin: 0 8px; }
          md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals > div:first-of-type {
            margin-left: 0; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .button-show-hide-trackerReadings {
        font-size: 13px; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .md-subheader-inner {
        padding: 8px 16px 6px; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .div-trackerReadings-group {
        width: 100%;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        flex-flow: row wrap; }
  md-dialog#allocationTrackerReading-list .trackerReading-item {
    position: relative;
    height: auto !important;
    min-height: 20%;
    min-width: 345px !important;
    max-width: 345px;
    width: 345px;
    margin: 6px;
    padding: 8px 0 0;
    text-transform: none;
    cursor: pointer; }
    md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img {
      min-height: 215px;
      max-width: 330px;
      width: 100%; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img .event-img {
        z-index: 1; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img:hover .event-img-png {
        display: block;
        z-index: 2; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img .event-img-png {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 97%;
        display: none; }
        md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img .event-img-png img {
          box-shadow: none; }
    md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item {
      color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .avatar {
        font-size: 14px; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .info {
        min-width: 190px;
        max-width: 193px;
        margin-right: 4px; }
        md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .info .notes md-icon {
          margin-right: 3px;
          color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .secondary-text md-icon {
        margin: 0;
        margin-right: 5px; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .trackerReading-address {
        max-height: 35px;
        text-overflow: ellipsis;
        overflow: hidden; }
  md-dialog#allocationTrackerReading-list md-content {
    position: relative;
    padding: 0 !important;
    display: block;
    overflow-x: hidden; }
    md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img {
      z-index: 1;
      background-color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img {
        position: relative; }
        md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img {
          width: 100%;
          z-index: 2; }
          md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal {
            height: 225px; }
            md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal .galleria-image {
              margin: 0; }
        md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img:hover .event-img-png {
          display: block;
          z-index: 2; }
        md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 97%;
          display: none; }
          md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png img {
            box-shadow: none; }
    md-dialog#allocationTrackerReading-list md-content .div-event-img .event-map-img {
      position: absolute;
      right: 0; }
    md-dialog#allocationTrackerReading-list md-content .list-item {
      width: 100%;
      padding: 12px 8px;
      font-size: 14px;
      color: white; }
      md-dialog#allocationTrackerReading-list md-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
        md-dialog#allocationTrackerReading-list md-content .list-item .title .event-edit-button {
          margin-left: 0;
          padding-left: 0;
          font-size: 22px; }
      md-dialog#allocationTrackerReading-list md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
      md-dialog#allocationTrackerReading-list md-content .list-item .div-event-buttons button {
        margin-top: 4px;
        margin-bottom: 0; }
      md-dialog#allocationTrackerReading-list md-content .list-item md-icon {
        color: white; }
    md-dialog#allocationTrackerReading-list md-content md-input-container {
      margin: 0; }
    md-dialog#allocationTrackerReading-list md-content p {
      margin: 0; }
  md-dialog#allocationTrackerReading-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 960px) {
  md-dialog#allocationTrackerReading-list {
    min-height: 100% !important;
    width: 100% !important; } }

@media print {
  body {
    -webkit-print-color-adjust: exact !important; }
  .page-break-after {
    position: relative !important;
    display: block !important;
    page-break-before: always !important;
    page-break-after: always !important;
    float: none; }
  .md-dialog-container {
    height: auto !important;
    overflow: visible;
    z-index: 999;
    border: none !important;
    box-shadow: none !important; }
  md-dialog#allocationTrackerReading-list {
    position: relative;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
    display: block !important;
    overflow: visible !important; }
    md-dialog#allocationTrackerReading-list md-content {
      position: relative !important;
      display: block !important;
      overflow: visible !important;
      border: none !important;
      box-shadow: none; }
      md-dialog#allocationTrackerReading-list md-content .div-group {
        float: none !important;
        border-bottom: none; }
        md-dialog#allocationTrackerReading-list md-content .div-group .md-subheader {
          position: fixed !important;
          top: auto !important;
          margin: 12px 0;
          box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08); }
      md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group {
        display: block; }
        md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group > * {
          float: left !important; }
        md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item {
          height: 335px !important;
          max-height: 335px !important; }
          md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item:first-of-type, md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item:nth-of-type(2) {
            margin-top: 100px !important; }
          md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item .div-event-img {
            min-height: 205px;
            max-height: 205px;
            margin-bottom: 5px; }
      md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group,
      md-dialog#allocationTrackerReading-list md-content .trackerReading-item,
      md-dialog#allocationTrackerReading-list md-content .div-event-img,
      md-dialog#allocationTrackerReading-list md-content .div-trackerReading-item {
        position: relative !important;
        page-break-inside: avoid !important; }
    md-dialog#allocationTrackerReading-list md-toolbar {
      color: #000000;
      background-color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    md-dialog#allocationTrackerReading-list .content {
      display: block; }
      md-dialog#allocationTrackerReading-list .content .md-subheader {
        position: initial !important;
        margin: 12px 0; } }

#allocations-report .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #allocations-report .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #allocations-report .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #allocations-report .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #allocations-report .sidenav .content {
    padding: 24px 0; }
    #allocations-report .sidenav .content .item {
      cursor: pointer; }
      #allocations-report .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #allocations-report .sidenav .content .item label {
        cursor: pointer; }
    #allocations-report .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #allocations-report .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #allocations-report .sidenav .content md-input-container {
      max-height: 30px; }
    #allocations-report .sidenav .content md-select {
      height: 35px !important; }
    #allocations-report .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #allocations-report .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #allocations-report .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #allocations-report .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #allocations-report .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #allocations-report .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

@media print {
  .timeline-div {
    page-break-before: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important; }
    .timeline-div * {
      page-break-inside: avoid !important; } }

md-toast .div-loading-allocations-list md-progress-circular svg path {
  stroke: #FFFFFF; }

#allocations-pro-data-report .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #allocations-pro-data-report .sidenav .header {
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #allocations-pro-data-report .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #allocations-pro-data-report .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #allocations-pro-data-report .sidenav .content {
    padding: 24px 0; }
    #allocations-pro-data-report .sidenav .content .item {
      cursor: pointer; }
      #allocations-pro-data-report .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #allocations-pro-data-report .sidenav .content .item label {
        cursor: pointer; }
    #allocations-pro-data-report .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #allocations-pro-data-report .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #allocations-pro-data-report .sidenav .content md-input-container {
      max-height: 30px; }
    #allocations-pro-data-report .sidenav .content md-select {
      height: 35px !important; }
    #allocations-pro-data-report .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #allocations-pro-data-report .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #allocations-pro-data-report .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #allocations-pro-data-report .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #allocations-pro-data-report .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #allocations-pro-data-report .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

md-toast .div-loading-trackerReadings-list md-progress-circular svg path {
  stroke: #FFFFFF; }

md-dialog#allocationMonitripTrackerReading-list {
  max-width: 100% !important;
  min-height: 95% !important;
  width: 95% !important; }
  md-dialog#allocationMonitripTrackerReading-list .header-trackerReading-list {
    background-color: #394b6d;
    color: #FFFFFF; }
    md-dialog#allocationMonitripTrackerReading-list .header-trackerReading-list md-icon {
      margin: 0;
      color: #FFFFFF; }
    md-dialog#allocationMonitripTrackerReading-list .header-trackerReading-list.md-subheader {
      min-height: 70px; }
    md-dialog#allocationMonitripTrackerReading-list .header-trackerReading-list .md-button[disabled] md-icon {
      opacity: .4; }
    md-dialog#allocationMonitripTrackerReading-list .header-trackerReading-list .total-trackerReadings-list {
      max-height: 25px; }
    md-dialog#allocationMonitripTrackerReading-list .header-trackerReading-list .secondary-info md-icon {
      margin-right: 5px; }
  md-dialog#allocationMonitripTrackerReading-list .md-subheader {
    z-index: 3 !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08); }
  md-dialog#allocationMonitripTrackerReading-list md-input-container {
    margin-bottom: 0; }
    md-dialog#allocationMonitripTrackerReading-list md-input-container > * {
      color: #FFFFFF !important; }
    md-dialog#allocationMonitripTrackerReading-list md-input-container input {
      border-bottom-color: #FFFFFF !important; }
  md-dialog#allocationMonitripTrackerReading-list .div-floating-list-button {
    bottom: 12px; }
  md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list {
    height: 100%;
    padding-bottom: 36px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list p {
      margin: 0; }
    md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group {
      height: auto;
      padding: 0 0 6px 0; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group:not(:last-of-type) {
        border-bottom: solid 1px rgba(0, 0, 0, 0.08); }
      md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals {
        min-width: 370px; }
        md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals > div {
          margin: 0 8px; }
          md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals > div:first-of-type {
            margin-left: 0; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group .md-subheader .button-show-hide-trackerReadings {
        font-size: 13px; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group .md-subheader .md-subheader-inner {
        padding: 8px 16px 6px; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReadings-list .div-group .div-trackerReadings-group {
        width: 100%;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        flex-flow: row wrap; }
  md-dialog#allocationMonitripTrackerReading-list .trackerReading-item {
    position: relative;
    height: auto !important;
    min-height: 20%;
    min-width: 345px !important;
    max-width: 345px;
    width: 345px;
    margin: 6px;
    padding: 8px 0 0;
    text-transform: none;
    cursor: pointer; }
    md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-event-img {
      min-height: 215px;
      max-width: 330px;
      width: 100%; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-event-img .event-img {
        z-index: 1; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-event-img:hover .event-img-png {
        display: block;
        z-index: 2; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-event-img .event-img-png {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 97%;
        display: none; }
        md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-event-img .event-img-png img {
          box-shadow: none; }
    md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-trackerReading-item {
      color: #FFFFFF; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-trackerReading-item .avatar {
        font-size: 14px; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-trackerReading-item .info {
        min-width: 190px;
        max-width: 193px;
        margin-right: 4px; }
        md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-trackerReading-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-trackerReading-item .info .notes md-icon {
          margin-right: 3px;
          color: #FFFFFF; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-trackerReading-item .secondary-text md-icon {
        margin: 0;
        margin-right: 5px; }
      md-dialog#allocationMonitripTrackerReading-list .trackerReading-item .div-trackerReading-item .trackerReading-address {
        max-height: 35px;
        text-overflow: ellipsis;
        overflow: hidden; }
  md-dialog#allocationMonitripTrackerReading-list md-content {
    position: relative;
    padding: 0 !important;
    display: block;
    overflow-x: hidden; }
    md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img {
      z-index: 1;
      background-color: #FFFFFF; }
      md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img {
        position: relative; }
        md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img {
          width: 100%;
          z-index: 2; }
          md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal {
            height: 225px; }
            md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal .galleria-image {
              margin: 0; }
        md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img:hover .event-img-png {
          display: block;
          z-index: 2; }
        md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 97%;
          display: none; }
          md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png img {
            box-shadow: none; }
    md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img .event-map-img {
      position: absolute;
      right: 0; }
    md-dialog#allocationMonitripTrackerReading-list md-content .list-item {
      width: 100%;
      padding: 12px 8px;
      font-size: 14px;
      color: white; }
      md-dialog#allocationMonitripTrackerReading-list md-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
        md-dialog#allocationMonitripTrackerReading-list md-content .list-item .title .event-edit-button {
          margin-left: 0;
          padding-left: 0;
          font-size: 22px; }
      md-dialog#allocationMonitripTrackerReading-list md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
      md-dialog#allocationMonitripTrackerReading-list md-content .list-item .div-event-buttons button {
        margin-top: 4px;
        margin-bottom: 0; }
      md-dialog#allocationMonitripTrackerReading-list md-content .list-item md-icon {
        color: white; }
    md-dialog#allocationMonitripTrackerReading-list md-content md-input-container {
      margin: 0; }
    md-dialog#allocationMonitripTrackerReading-list md-content p {
      margin: 0; }
  md-dialog#allocationMonitripTrackerReading-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 960px) {
  md-dialog#allocationMonitripTrackerReading-list {
    min-height: 100% !important;
    width: 100% !important; } }

@media print {
  body {
    -webkit-print-color-adjust: exact !important; }
  .page-break-after {
    position: relative !important;
    display: block !important;
    page-break-before: always !important;
    page-break-after: always !important;
    float: none; }
  .md-dialog-container {
    height: auto !important;
    overflow: visible;
    z-index: 999;
    border: none !important;
    box-shadow: none !important; }
  md-dialog#allocationMonitripTrackerReading-list {
    position: relative;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
    display: block !important;
    overflow: visible !important; }
    md-dialog#allocationMonitripTrackerReading-list md-content {
      position: relative !important;
      display: block !important;
      overflow: visible !important;
      border: none !important;
      box-shadow: none; }
      md-dialog#allocationMonitripTrackerReading-list md-content .div-group {
        float: none !important;
        border-bottom: none; }
        md-dialog#allocationMonitripTrackerReading-list md-content .div-group .md-subheader {
          position: fixed !important;
          top: auto !important;
          margin: 12px 0;
          box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08); }
      md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReadings-group {
        display: block; }
        md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReadings-group > * {
          float: left !important; }
        md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item {
          height: 335px !important;
          max-height: 335px !important; }
          md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item:first-of-type, md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item:nth-of-type(2) {
            margin-top: 100px !important; }
          md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item .div-event-img {
            min-height: 205px;
            max-height: 205px;
            margin-bottom: 5px; }
      md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReadings-group,
      md-dialog#allocationMonitripTrackerReading-list md-content .trackerReading-item,
      md-dialog#allocationMonitripTrackerReading-list md-content .div-event-img,
      md-dialog#allocationMonitripTrackerReading-list md-content .div-trackerReading-item {
        position: relative !important;
        page-break-inside: avoid !important; }
    md-dialog#allocationMonitripTrackerReading-list md-toolbar {
      color: #000000;
      background-color: #FFFFFF; }
      md-dialog#allocationMonitripTrackerReading-list md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    md-dialog#allocationMonitripTrackerReading-list .content {
      display: block; }
      md-dialog#allocationMonitripTrackerReading-list .content .md-subheader {
        position: initial !important;
        margin: 12px 0; } }

#allocations .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #allocations .sidenav .header {
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #allocations .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #allocations .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #allocations .sidenav .content {
    padding: 24px 0; }
    #allocations .sidenav .content .item {
      cursor: pointer; }
      #allocations .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #allocations .sidenav .content .item label {
        cursor: pointer; }
    #allocations .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #allocations .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #allocations .sidenav .content md-input-container {
      max-height: 30px; }
    #allocations .sidenav .content md-select {
      height: 35px !important; }
    #allocations .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #allocations .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #allocations .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #allocations .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #allocations .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #allocations .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#allocationMonitrips-report .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #allocationMonitrips-report .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #allocationMonitrips-report .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #allocationMonitrips-report .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #allocationMonitrips-report .sidenav .content {
    padding: 24px 0; }
    #allocationMonitrips-report .sidenav .content .item {
      cursor: pointer; }
      #allocationMonitrips-report .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #allocationMonitrips-report .sidenav .content .item label {
        cursor: pointer; }
    #allocationMonitrips-report .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #allocationMonitrips-report .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #allocationMonitrips-report .sidenav .content md-input-container {
      max-height: 30px; }
    #allocationMonitrips-report .sidenav .content md-select {
      height: 35px !important; }
    #allocationMonitrips-report .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #allocationMonitrips-report .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #allocationMonitrips-report .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #allocationMonitrips-report .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #allocationMonitrips-report .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #allocationMonitrips-report .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#centroids-report .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #centroids-report .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #centroids-report .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #centroids-report .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #centroids-report .sidenav .content {
    padding: 24px 0; }
    #centroids-report .sidenav .content .item {
      cursor: pointer; }
      #centroids-report .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #centroids-report .sidenav .content .item label {
        cursor: pointer; }
    #centroids-report .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #centroids-report .sidenav .content .occurrences-subheader {
      background-color: transparent; }
    #centroids-report .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #centroids-report .sidenav .content md-input-container {
      max-height: 30px; }
    #centroids-report .sidenav .content md-select {
      height: 35px !important; }
    #centroids-report .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #centroids-report .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #centroids-report .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #centroids-report .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #centroids-report .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #centroids-report .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#centroids .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none;
  overflow-x: hidden; }
  #centroids .sidenav .header {
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #centroids .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #centroids .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #centroids .sidenav .content {
    padding: 24px 0; }
    #centroids .sidenav .content .item {
      cursor: pointer; }
      #centroids .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #centroids .sidenav .content .item label {
        cursor: pointer; }
    #centroids .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #centroids .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #centroids .sidenav .content md-input-container {
      max-height: 30px; }
    #centroids .sidenav .content md-select {
      height: 35px !important; }
    #centroids .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #centroids .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #centroids .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #centroids .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #centroids .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #centroids .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#contracts .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #contracts .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #contracts .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #contracts .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #contracts .sidenav .content {
    padding: 24px 0; }
    #contracts .sidenav .content .item {
      cursor: pointer; }
      #contracts .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #contracts .sidenav .content .item label {
        cursor: pointer; }
    #contracts .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #contracts .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #contracts .sidenav .content md-input-container {
      max-height: 30px; }
    #contracts .sidenav .content md-select {
      height: 35px !important; }
    #contracts .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #contracts .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #contracts .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #contracts .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #contracts .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #contracts .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#lines .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #lines .sidenav .header {
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #lines .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #lines .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #lines .sidenav .content {
    padding: 24px 0; }
    #lines .sidenav .content .item {
      cursor: pointer; }
      #lines .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #lines .sidenav .content .item label {
        cursor: pointer; }
    #lines .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #lines .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #lines .sidenav .content md-input-container {
      max-height: 30px; }
    #lines .sidenav .content md-select {
      height: 35px !important; }
    #lines .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #lines .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #lines .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #lines .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #lines .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #lines .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#employees .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none;
  overflow-x: hidden; }
  #employees .sidenav .header {
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #employees .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #employees .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #employees .sidenav .content {
    padding: 24px 0; }
    #employees .sidenav .content .item {
      cursor: pointer; }
      #employees .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #employees .sidenav .content .item label {
        cursor: pointer; }
    #employees .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #employees .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #employees .sidenav .content md-input-container {
      max-height: 30px; }
    #employees .sidenav .content md-select {
      height: 35px !important; }
    #employees .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #employees .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #employees .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #employees .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #employees .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #employees .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

md-toast .div-loading-modelOperationLog-list md-progress-circular svg path {
  stroke: #FFFFFF; }

#modelOperationLogs .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #modelOperationLogs .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #modelOperationLogs .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #modelOperationLogs .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #modelOperationLogs .sidenav .content {
    padding: 24px 0; }
    #modelOperationLogs .sidenav .content .item {
      cursor: pointer; }
      #modelOperationLogs .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #modelOperationLogs .sidenav .content .item label {
        cursor: pointer; }
    #modelOperationLogs .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #modelOperationLogs .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #modelOperationLogs .sidenav .content md-input-container {
      max-height: 30px; }
    #modelOperationLogs .sidenav .content md-select {
      height: 35px !important; }
    #modelOperationLogs .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #modelOperationLogs .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #modelOperationLogs .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #modelOperationLogs .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #modelOperationLogs .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #modelOperationLogs .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

md-dialog.trackerReadings-list {
  min-height: 600px;
  max-width: 640px;
  width: 640px; }
  md-dialog.trackerReadings-list--is-editing {
    min-height: "650px"; }
    md-dialog.trackerReadings-list--is-editing md-dialog-content {
      min-height: inherit; }
  md-dialog.trackerReadings-list .trackerReading {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    md-dialog.trackerReadings-list .trackerReading__header {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      max-height: 410px;
      min-height: 410px; }
    md-dialog.trackerReadings-list .trackerReading__content {
      color: white !important;
      flex-grow: 1;
      padding: 0px 16px; }
      md-dialog.trackerReadings-list .trackerReading__content .read-more-btn {
        cursor: pointer;
        text-decoration: underline; }
      md-dialog.trackerReadings-list .trackerReading__content .title {
        font-size: 18px;
        font-weight: bold; }
      md-dialog.trackerReadings-list .trackerReading__content md-icon {
        color: white; }
      md-dialog.trackerReadings-list .trackerReading__content textarea, md-dialog.trackerReadings-list .trackerReading__content input, md-dialog.trackerReadings-list .trackerReading__content label {
        color: white; }
      md-dialog.trackerReadings-list .trackerReading__content textarea, md-dialog.trackerReadings-list .trackerReading__content label, md-dialog.trackerReadings-list .trackerReading__content md-text {
        font-size: 16px;
        line-height: 1.4; }
      md-dialog.trackerReadings-list .trackerReading__content md-select md-select-value span {
        color: white;
        font-size: 16px; }
  md-dialog.trackerReadings-list .truncate-obs-text {
    max-height: 50px;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  md-dialog.trackerReadings-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.trackerReadings-list .header-trackerReadings-list {
    background-color: #263238;
    opacity: 0.7;
    position: absolute;
    z-index: 999999; }
    md-dialog.trackerReadings-list .header-trackerReadings-list .total-trackerReadings-list {
      max-height: 25px; }
    md-dialog.trackerReadings-list .header-trackerReadings-list md-icon {
      color: #fff;
      margin: 0; }
  md-dialog.trackerReadings-list .div-floating-list-button {
    bottom: 12px; }
  md-dialog.trackerReadings-list .pointer {
    cursor: pointer; }
  md-dialog.trackerReadings-list md-dialog-content {
    position: relative;
    min-height: 600px;
    display: flex;
    overflow-x: hidden;
    padding: 0 !important; }
    md-dialog.trackerReadings-list md-dialog-content .div-event-img {
      min-height: 265px;
      max-height: 265px;
      max-width: 300px;
      z-index: 3; }
      md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img {
        background-color: #fff;
        max-width: 100%;
        z-index: 1; }
        md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img .div-img {
          position: relative; }
          md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img .div-img .event-img {
            width: 100%;
            z-index: 2; }
            md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal {
              height: 225px; }
              md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal .galleria-image {
                margin: 0; }
          md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img .div-img:hover .event-img-png {
            display: block;
            z-index: 2; }
          md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img .div-img .event-img-png {
            display: none;
            height: 97%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%; }
            md-dialog.trackerReadings-list md-dialog-content .div-event-img .div-wrapper-event-img .div-img .event-img-png img {
              box-shadow: none; }
    md-dialog.trackerReadings-list md-dialog-content .event-map-img {
      min-height: 600px;
      transition: 0.4s; }
      md-dialog.trackerReadings-list md-dialog-content .event-map-img.disabled {
        opacity: 0.3; }
    md-dialog.trackerReadings-list md-dialog-content .div-disabled-map {
      background-color: white;
      top: 0;
      height: 410px;
      opacity: .8;
      position: absolute;
      right: 0;
      transition: 0.3s;
      width: 100%;
      z-index: 2;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out; }
      md-dialog.trackerReadings-list md-dialog-content .div-disabled-map.loading {
        margin-bottom: -100px; }
      md-dialog.trackerReadings-list md-dialog-content .div-disabled-map > div {
        padding: 12px;
        position: absolute;
        right: 70px;
        text-align: center;
        top: 215px; }
        md-dialog.trackerReadings-list md-dialog-content .div-disabled-map > div .show-map-message .md-icon, md-dialog.trackerReadings-list md-dialog-content .div-disabled-map > div .show-map-message span {
          transition: 0.4s; }
    md-dialog.trackerReadings-list md-dialog-content .div-disabled-map:hover > div .show-map-message md-icon {
      color: #e6840e !important; }
    md-dialog.trackerReadings-list md-dialog-content .div-disabled-map:hover > div .show-map-message span {
      color: #262626 !important; }
    md-dialog.trackerReadings-list md-dialog-content .event-infos {
      margin-top: auto; }
    md-dialog.trackerReadings-list md-dialog-content .list-item {
      color: white;
      font-size: 14px;
      max-height: 190px;
      min-height: 190px;
      padding-top: 12px;
      width: 100%;
      z-index: 3; }
      md-dialog.trackerReadings-list md-dialog-content .list-item label {
        font-size: 18px !important; }
      md-dialog.trackerReadings-list md-dialog-content .list-item label, md-dialog.trackerReadings-list md-dialog-content .list-item textarea {
        color: white; }
      md-dialog.trackerReadings-list md-dialog-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
        md-dialog.trackerReadings-list md-dialog-content .list-item .title .event-edit-button {
          color: white;
          font-size: 22px;
          margin-left: 0;
          padding-left: 0; }
      md-dialog.trackerReadings-list md-dialog-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
      md-dialog.trackerReadings-list md-dialog-content .list-item .div-event-buttons button {
        margin-bottom: 0;
        margin-top: 4px; }
      md-dialog.trackerReadings-list md-dialog-content .list-item md-icon {
        color: white; }
    md-dialog.trackerReadings-list md-dialog-content md-input-container {
      margin: 0; }
    md-dialog.trackerReadings-list md-dialog-content p {
      margin: 0; }
  md-dialog.trackerReadings-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 576px) {
  md-dialog.trackerReadings-list md-dialog-content {
    min-height: 730px; }
    md-dialog.trackerReadings-list md-dialog-content .div-disabled-map > div {
      top: 120px; } }

#services .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #services .sidenav .header {
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #services .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #services .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #services .sidenav .content {
    padding: 24px 0; }
    #services .sidenav .content .item {
      cursor: pointer; }
      #services .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #services .sidenav .content .item label {
        cursor: pointer; }
    #services .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #services .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #services .sidenav .content md-input-container {
      max-height: 30px; }
    #services .sidenav .content md-select {
      height: 35px !important; }
    #services .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #services .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #services .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #services .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #services .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #services .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#simCards .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #simCards .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #simCards .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #simCards .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #simCards .sidenav .content {
    padding: 24px 0; }
    #simCards .sidenav .content .item {
      cursor: pointer; }
      #simCards .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #simCards .sidenav .content .item label {
        cursor: pointer; }
    #simCards .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #simCards .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #simCards .sidenav .content md-input-container {
      max-height: 30px; }
    #simCards .sidenav .content md-select {
      height: 35px !important; }
    #simCards .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #simCards .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #simCards .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #simCards .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #simCards .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #simCards .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

md-dialog.terminal-view {
  max-width: 720px;
  min-height: 85%;
  width: 630px;
  overflow: hidden; }
  md-dialog.terminal-view .header-terminal-view {
    background-color: #263238;
    color: #fff; }
    md-dialog.terminal-view .header-terminal-view .total-terminal-view {
      max-height: 25px; }
    md-dialog.terminal-view .header-terminal-view md-icon {
      margin: 0;
      color: #fff; }
  md-dialog.terminal-view .div-floating-list-button {
    bottom: 12px; }
  md-dialog.terminal-view .avatar-tab {
    margin-bottom: 3px;
    margin-left: 5px;
    border-radius: 50%;
    color: white !important;
    font-size: 12px !important;
    width: 15px !important;
    height: 15px !important;
    min-width: 15px !important;
    min-height: 15px !important;
    line-height: 15px !important; }
  md-dialog.terminal-view .location-icon-tab {
    margin-bottom: 3px;
    margin-left: 5px; }
  md-dialog.terminal-view md-dialog-content {
    position: relative;
    max-height: 100%;
    min-height: 382px;
    padding: 0 !important;
    display: block;
    overflow-x: hidden; }
    md-dialog.terminal-view md-dialog-content .list-item {
      padding-top: 12px;
      font-size: 14px;
      color: white; }
      md-dialog.terminal-view md-dialog-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
        md-dialog.terminal-view md-dialog-content .list-item .title .event-edit-button {
          margin-left: 0;
          padding-left: 0;
          font-size: 22px; }
      md-dialog.terminal-view md-dialog-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
        md-dialog.terminal-view md-dialog-content .list-item .info .notes md-icon {
          margin-left: 0;
          margin-right: 5px; }
      md-dialog.terminal-view md-dialog-content .list-item .div-event-buttons button {
        margin-top: 4px;
        margin-bottom: 0; }
      md-dialog.terminal-view md-dialog-content .list-item md-icon {
        color: white;
        margin-right: 5px; }
    md-dialog.terminal-view md-dialog-content .terminal-tabs .md-tab-content {
      min-height: 200px; }
    md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart {
      position: relative;
      min-width: 100%;
      min-height: 400px;
      max-height: 450px;
      background-color: #FFFFFF; }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .no-display {
        display: none; }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .title {
        margin: 0 0 12px;
        padding: 12px 12px 0;
        color: rgba(0, 0, 0, 0.54); }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .div-line-char {
        position: relative;
        padding: 0 12px;
        padding-right: 24px; }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .nvd3.nv-scatter .nv-groups .nv-point,
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .nvd3 .nv-groups .nv-point {
        stroke-width: 5px;
        fill-opacity: .95 !important;
        stroke-opacity: .95 !important; }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .nv-noData {
        font-size: 16px !important;
        font-style: italic; }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .no-data {
        margin-left: -40px; }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-chart .point-avatar {
        width: 5px;
        height: 5px;
        border-radius: 50%; }
    md-dialog.terminal-view md-dialog-content .terminal-tabs .div-technical-info-status {
      padding: 6px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row wrap; }
      md-dialog.terminal-view md-dialog-content .terminal-tabs .div-technical-info-status > div {
        min-width: 95px;
        max-width: 95px;
        width: 95px;
        min-height: 82px;
        max-height: 82px;
        height: 82px;
        padding: 2px 0;
        margin: 2px;
        text-align: center;
        font-weight: bold; }
        md-dialog.terminal-view md-dialog-content .terminal-tabs .div-technical-info-status > div .avatar {
          margin: 0;
          font-weight: bold; }
        md-dialog.terminal-view md-dialog-content .terminal-tabs .div-technical-info-status > div .name-technical-info {
          flex: 1;
          padding: 6px;
          line-height: 1em; }
    md-dialog.terminal-view md-dialog-content .div-map-img {
      padding: 6px;
      overflow: hidden; }
      md-dialog.terminal-view md-dialog-content .div-map-img .div-gmaps {
        min-height: 350px; }
        md-dialog.terminal-view md-dialog-content .div-map-img .div-gmaps .gmaps {
          overflow: hidden; }
      md-dialog.terminal-view md-dialog-content .div-map-img .div-position-not-found {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: .7;
        background-color: #FFF;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
        md-dialog.terminal-view md-dialog-content .div-map-img .div-position-not-found.loading {
          margin-bottom: -100px; }
        md-dialog.terminal-view md-dialog-content .div-map-img .div-position-not-found > div {
          text-align: center;
          padding: 12px; }
    md-dialog.terminal-view md-dialog-content md-input-container {
      margin: 0; }
    md-dialog.terminal-view md-dialog-content p {
      margin: 0; }
    md-dialog.terminal-view md-dialog-content .trackerReadings-list {
      height: 100%;
      padding-bottom: 36px;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      overflow-x: hidden;
      overflow-y: auto; }
      md-dialog.terminal-view md-dialog-content .trackerReadings-list p {
        margin: 0; }
      md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group {
        height: auto;
        padding: 0 0 6px; }
        md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group:not(:last-of-type) {
          border-bottom: solid 1px rgba(0, 0, 0, 0.08); }
        md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals {
          min-width: 370px; }
          md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals .info .disabled {
            opacity: .4; }
          md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals > div {
            margin: 0 8px; }
            md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals > div:first-of-type {
              margin-left: 0; }
        md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group .md-subheader .button-show-hide-trackerReadings {
          font-size: 13px; }
        md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group .md-subheader .md-subheader-inner {
          padding: 8px 16px 6px; }
        md-dialog.terminal-view md-dialog-content .trackerReadings-list .div-group .div-trackerReadings-group {
          width: 100%;
          -webkit-flex-flow: row wrap;
          -moz-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          flex-flow: row wrap; }
    md-dialog.terminal-view md-dialog-content .trackerReading-item {
      position: relative;
      height: auto !important;
      min-height: 20%;
      min-width: 345px !important;
      max-width: 345px;
      width: 345px;
      margin: 6px;
      padding: 8px 0 0;
      text-transform: none;
      cursor: pointer; }
      md-dialog.terminal-view md-dialog-content .trackerReading-item .div-event-img {
        min-height: 215px;
        max-width: 330px;
        width: 100%; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-event-img .event-img {
          z-index: 1; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-event-img:hover .event-img-png {
          display: block;
          z-index: 2; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-event-img .event-img-png {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 97%;
          display: none; }
          md-dialog.terminal-view md-dialog-content .trackerReading-item .div-event-img .event-img-png img {
            box-shadow: none; }
      md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item {
        color: #FFFFFF; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item .avatar {
          font-size: 14px; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item .info {
          min-width: 190px;
          max-width: 193px;
          margin-right: 4px; }
          md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item .info .secondary-title {
            margin-left: 5px;
            font-size: 12px !important; }
          md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item .info .notes md-icon {
            margin-right: 3px;
            color: #FFFFFF; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item .secondary-info md-icon {
          margin-right: 3px;
          color: #FFFFFF; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item .secondary-text md-icon {
          margin: 0;
          margin-right: 5px; }
        md-dialog.terminal-view md-dialog-content .trackerReading-item .div-trackerReading-item .trackerReading-address {
          max-height: 35px;
          text-overflow: ellipsis;
          overflow: hidden; }
  md-dialog.terminal-view md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (min-width: 600px) {
  md-dialog.terminal-view {
    min-height: 85%;
    max-height: 100%; }
    md-dialog.terminal-view md-dialog-content {
      flex: 1 1 100%;
      display: flex; }
      md-dialog.terminal-view md-dialog-content md-tabs {
        flex: 1 1 100%; }
  md-dialog.extended-width-height {
    min-width: 95% !important;
    max-width: 100% !important;
    min-height: 95% !important;
    max-height: 100% !important; }
    md-dialog.extended-width-height md-dialog-content {
      flex: 1 1 100% !important;
      display: flex; }
      md-dialog.extended-width-height md-dialog-content md-tabs {
        flex: 1 1 100% !important; } }

@media screen and (max-width: 960px) {
  md-dialog.terminal-view md-dialog-content md-tabs {
    height: 100%; } }

@media screen and (min-width: 1280px) {
  md-dialog.terminal-view md-dialog-content md-tabs .terminal-infos-header {
    position: sticky;
    width: 100%;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; } }

#terminals-dashboard .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #terminals-dashboard .sidenav .header {
    height: 155px;
    min-height: 155px;
    max-height: 155px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #terminals-dashboard .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #terminals-dashboard .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #terminals-dashboard .sidenav .content {
    padding: 24px 0; }
    #terminals-dashboard .sidenav .content .item {
      cursor: pointer; }
      #terminals-dashboard .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #terminals-dashboard .sidenav .content .item label {
        cursor: pointer; }
    #terminals-dashboard .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #terminals-dashboard .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #terminals-dashboard .sidenav .content md-input-container {
      max-height: 30px; }
    #terminals-dashboard .sidenav .content md-select {
      height: 35px !important; }
    #terminals-dashboard .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #terminals-dashboard .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #terminals-dashboard .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #terminals-dashboard .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #terminals-dashboard .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #terminals-dashboard .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#terminals .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none;
  overflow-x: hidden; }
  #terminals .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #terminals .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #terminals .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #terminals .sidenav .content {
    padding: 24px 0; }
    #terminals .sidenav .content .item {
      cursor: pointer; }
      #terminals .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #terminals .sidenav .content .item label {
        cursor: pointer; }
    #terminals .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #terminals .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #terminals .sidenav .content md-input-container {
      max-height: 30px; }
    #terminals .sidenav .content md-select {
      height: 35px !important; }
    #terminals .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #terminals .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #terminals .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #terminals .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #terminals .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #terminals .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#technicalInfos .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #technicalInfos .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #technicalInfos .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #technicalInfos .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #technicalInfos .sidenav .content {
    padding: 24px 0; }
    #technicalInfos .sidenav .content .item {
      cursor: pointer; }
      #technicalInfos .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #technicalInfos .sidenav .content .item label {
        cursor: pointer; }
    #technicalInfos .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #technicalInfos .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #technicalInfos .sidenav .content md-input-container {
      max-height: 30px; }
    #technicalInfos .sidenav .content md-select {
      height: 35px !important; }
    #technicalInfos .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #technicalInfos .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #technicalInfos .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #technicalInfos .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #technicalInfos .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #technicalInfos .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

#events .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none; }
  #events .sidenav .header {
    height: 114px;
    min-height: 114px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #events .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #events .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #events .sidenav .content {
    padding: 24px 0; }
    #events .sidenav .content .item {
      cursor: pointer; }
      #events .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #events .sidenav .content .item label {
        cursor: pointer; }
    #events .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #events .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #events .sidenav .content md-input-container {
      max-height: 30px; }
    #events .sidenav .content md-select {
      height: 35px !important; }
    #events .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #events .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #events .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #events .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #events .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #events .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

@media print {
  .timeline-div {
    page-break-before: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important; }
    .timeline-div * {
      page-break-inside: avoid !important; } }

#users .sidenav {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  z-index: 2;
  background: 0 0;
  box-shadow: none;
  overflow-x: hidden; }
  #users .sidenav .header {
    height: 174px;
    min-height: 174px;
    max-height: 174px;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #users .sidenav .header .logo .logo-icon {
      margin: 0 16px 0 0; }
    #users .sidenav .header .logo .logo-text {
      font-size: 24px; }
  #users .sidenav .content {
    padding: 24px 0; }
    #users .sidenav .content .item {
      cursor: pointer; }
      #users .sidenav .content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
      #users .sidenav .content .item label {
        cursor: pointer; }
    #users .sidenav .content .select-filter {
      padding: 0 24px 0 16px; }
    #users .sidenav .content md-icon {
      color: rgba(0, 0, 0, 0.56); }
    #users .sidenav .content md-input-container {
      max-height: 30px; }
    #users .sidenav .content md-select {
      height: 35px !important; }
    #users .sidenav .content mdp-time-picker > div {
      padding: 0 24px 0 12px; }
      #users .sidenav .content mdp-time-picker > div md-input-container {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        flex: 1; }
  #users .sidenav.md-locked-open .header {
    color: #FFFFFF; }
    #users .sidenav.md-locked-open .header .logo {
      padding-top: 27px; }
      #users .sidenav.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  #users .sidenav:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }

.centroid-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px; }
  .centroid-view .md-subheader .md-subheader-inner {
    padding-top: 8px; }
  .centroid-view .header-centroid-view {
    padding-bottom: 10px;
    background-color: #263238 !important;
    color: #fff !important; }
    .centroid-view .header-centroid-view .md-toolbar-tools-top {
      min-height: 50px;
      height: 50px; }
      .centroid-view .header-centroid-view .md-toolbar-tools-top .title {
        padding-left: 0; }
    .centroid-view .header-centroid-view .info-centroid {
      font-size: 14px; }
    .centroid-view .header-centroid-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .centroid-view .div-floating-list-button {
    bottom: 12px; }
  .centroid-view .div-centroid-img .centroid-img {
    z-index: 2; }
    .centroid-view .div-centroid-img .centroid-img .ng-image-gallery-modal {
      height: 225px; }
      .centroid-view .div-centroid-img .centroid-img .ng-image-gallery-modal img {
        width: 100%; }
  .centroid-view .div-centroid-img .centroid-map-img {
    position: absolute;
    right: 0; }
  .centroid-view md-content {
    overflow-x: hidden; }
    .centroid-view md-content .day-tracker-readings,
    .centroid-view md-content .week-tracker-readings {
      position: relative;
      min-height: 260px;
      width: 100%; }
      .centroid-view md-content .day-tracker-readings > div,
      .centroid-view md-content .week-tracker-readings > div {
        width: 100%; }
    .centroid-view md-content .list-item {
      padding-top: 12px;
      font-size: 14px;
      color: white; }
      .centroid-view md-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
      .centroid-view md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
      .centroid-view md-content .list-item md-icon {
        color: white; }
    .centroid-view md-content .div-day-events {
      width: 100%;
      max-width: 350px;
      margin-left: -25px; }
      .centroid-view md-content .div-day-events .nv-noData {
        font-size: 16px !important; }
    .centroid-view md-content p {
      margin: 0; }

@media print {
  .sidenav-centroid-view {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    transform: translate(0, 0);
    overflow: visible; }
    .sidenav-centroid-view .centroid-view {
      max-width: 100%;
      height: 100%;
      overflow: visible; }
      .sidenav-centroid-view .centroid-view .content-centroid-view .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .sidenav-centroid-view .centroid-view .content-centroid-view table {
        max-width: 55%; }
        .sidenav-centroid-view .centroid-view .content-centroid-view table tr,
        .sidenav-centroid-view .centroid-view .content-centroid-view table th,
        .sidenav-centroid-view .centroid-view .content-centroid-view table td {
          text-align: center !important; }
        .sidenav-centroid-view .centroid-view .content-centroid-view table tbody td {
          padding: 0 12px 0 0 !important; }
      .sidenav-centroid-view .centroid-view .content-centroid-view .div-day-events {
        width: 100%;
        padding: 0 12px;
        margin-left: 0; } }

.events-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px; }
  .events-view .header-events-view {
    min-height: 50px;
    max-height: 100px;
    background-color: #263238 !important;
    color: #fff !important; }
    .events-view .header-events-view .md-toolbar-tools-top {
      min-height: 50px;
      height: 50px; }
      .events-view .header-events-view .md-toolbar-tools-top .title {
        padding-left: 0; }
    .events-view .header-events-view .info-events {
      font-size: 14px; }
    .events-view .header-events-view md-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #fff; }
  .events-view .div-floating-list-button {
    bottom: 12px; }
  .events-view .div-events-img .events-img {
    z-index: 2; }
    .events-view .div-events-img .events-img .ng-image-gallery-modal {
      height: 225px; }
      .events-view .div-events-img .events-img .ng-image-gallery-modal img {
        width: 100%; }
  .events-view .div-events-img .events-map-img {
    position: absolute;
    right: 0; }
  .events-view md-content {
    overflow-x: hidden; }
    .events-view md-content md-tabs {
      width: 100%; }
      .events-view md-content md-tabs .door-occurrences .div-total-occurrences {
        border-right: solid 1px rgba(0, 0, 0, 0.16); }
      .events-view md-content md-tabs .div-occurrences,
      .events-view md-content md-tabs .door-occurrences,
      .events-view md-content md-tabs .centroid-occurrences {
        position: relative;
        width: 100%; }
        .events-view md-content md-tabs .div-occurrences > div,
        .events-view md-content md-tabs .door-occurrences > div,
        .events-view md-content md-tabs .centroid-occurrences > div {
          width: 100%; }
        .events-view md-content md-tabs .div-occurrences .subtitle,
        .events-view md-content md-tabs .door-occurrences .subtitle,
        .events-view md-content md-tabs .centroid-occurrences .subtitle {
          padding: 12px; }
        .events-view md-content md-tabs .div-occurrences .div-total-occurrences,
        .events-view md-content md-tabs .door-occurrences .div-total-occurrences,
        .events-view md-content md-tabs .centroid-occurrences .div-total-occurrences {
          padding-top: 12px;
          padding-bottom: 12px;
          cursor: pointer; }
          .events-view md-content md-tabs .div-occurrences .div-total-occurrences md-icon,
          .events-view md-content md-tabs .door-occurrences .div-total-occurrences md-icon,
          .events-view md-content md-tabs .centroid-occurrences .div-total-occurrences md-icon {
            margin: 0;
            margin-right: 5px; }
      .events-view md-content md-tabs .div-timeline {
        min-height: 300px; }
        .events-view md-content md-tabs .div-timeline .div-people-graph {
          position: relative;
          min-height: 380px; }
          .events-view md-content md-tabs .div-timeline .div-people-graph .md-subheader-inner {
            padding: 8px 12px !important;
            padding-right: 0 !important; }
        .events-view md-content md-tabs .div-timeline .timeline-events-list {
          min-height: 300px; }
          .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item {
            padding: 4px 16px 8px 16px;
            border: none !important; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .info {
              margin-right: 0; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .events-info {
              width: 100%; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .title {
              margin-bottom: 3px;
              font-size: 16px;
              font-weight: bold; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .notes {
              padding-top: 3px;
              font-size: 14px; }
              .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .notes md-icon {
                margin-left: 0;
                margin-right: 3px; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .total-balancing {
              text-overflow: clip;
              overflow: visible; }
              .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .total-balancing span:before {
                content: '('; }
              .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .total-balancing span:after {
                content: ')'; }
              .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .total-balancing span .balancing-positive:before {
                content: ' +'; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .secondary-text.time {
              margin-top: 3px; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .event-item .secondary-text md-icon {
              margin-left: 0;
              margin-right: 3px; }
          .events-view md-content md-tabs .div-timeline .timeline-events-list .md-list-item-inner {
            padding: 6px 0; }
          .events-view md-content md-tabs .div-timeline .timeline-events-list .avatar {
            margin-right: 10px;
            font-size: 17px !important; }
            .events-view md-content md-tabs .div-timeline .timeline-events-list .avatar.occurrence-event {
              font-size: 24px !important; }
        .events-view md-content md-tabs .div-timeline .md-subheader-content {
          font-weight: bold; }
      .events-view md-content md-tabs .list-item {
        padding-top: 12px;
        font-size: 14px; }
        .events-view md-content md-tabs .list-item .info {
          margin-left: 0;
          margin-right: 0; }
      .events-view md-content md-tabs p {
        margin: 0; }
  .events-view .div-timeline--printing people-graph {
    width: 700px !important; }

@media print {
  .sidenav-events-view {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    transform: translate(0, 0);
    overflow: visible; }
    .sidenav-events-view .events-view {
      max-width: 100%;
      height: 100%;
      overflow: visible; }
      .sidenav-events-view .events-view .header-events-view .info {
        padding-right: 16px !important; }
      .sidenav-events-view .events-view .event-item {
        page-break-before: always !important;
        page-break-after: always !important;
        page-break-inside: avoid !important; }
        .sidenav-events-view .events-view .event-item * {
          page-break-inside: avoid !important; }
      .sidenav-events-view .events-view .content-events-view .md-subheader {
        position: initial !important;
        margin: 12px 0; }
      .sidenav-events-view .events-view .content-events-view table {
        max-width: 60%; }
        .sidenav-events-view .events-view .content-events-view table tr,
        .sidenav-events-view .events-view .content-events-view table th,
        .sidenav-events-view .events-view .content-events-view table td {
          text-align: center !important; }
        .sidenav-events-view .events-view .content-events-view table tbody td {
          padding: 0 12px 0 0 !important; }
      .sidenav-events-view .events-view md-tabs-wrapper,
      .sidenav-events-view .events-view .lineY-div {
        display: none !important; } }

.line-selected-before {
  border-left: 6px solid #2196F3;
  height: 5px;
  margin-left: 6px; }

.line-before {
  border-left: 3px solid #cecece;
  height: 5px;
  margin-left: 8px; }

.timeline-button-apply {
  width: 90%; }

.icon-arrow-back {
  padding-left: 4px;
  padding-right: 6px; }

.icon-arrow-forward {
  padding-left: 6px;
  padding-right: 4px; }

.timeline-date-control {
  width: 100%; }

.date-control {
  float: left;
  width: 32.3%;
  margin: 0.5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.date-prev {
  text-align: left;
  border-bottom: 1px #bdbdbd solid; }

.date-current {
  text-align: center;
  cursor: default;
  font-weight: bold;
  font-size: medium;
  border-top: 1px #bdbdbd solid;
  border-left: 1px #bdbdbd solid;
  border-right: 1px #bdbdbd solid; }

.date-next {
  text-align: right;
  border-bottom: 1px #bdbdbd solid; }

.timeline-container {
  width: 100%;
  overflow-x: auto;
  overflow-x: hidden;
  padding-top: 5px; }

.text-muted {
  color: #999; }

small, .small {
  font-size: 85%; }

.timeline {
  list-style: none;
  padding: 0px 0 10px;
  position: relative; }
  .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #cecece;
    left: 3%;
    margin-left: -1.5px; }

.timeline-bar-selected {
  list-style: none;
  padding: 20px 0 20px;
  position: relative; }
  .timeline-bar-selected:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 6px;
    background-color: #2196F3;
    left: 0%;
    margin-left: -1.5px; }

.timeline-selected:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 6px;
  background-color: #2196F3;
  left: 0%;
  margin-left: -9px;
  margin-bottom: -20px; }

.timeline > li {
  margin-bottom: 10px;
  position: relative; }
  .timeline > li:before {
    content: " ";
    display: table; }
  .timeline > li:after {
    content: " ";
    display: table;
    clear: both; }
  .timeline > li:before {
    content: " ";
    display: table; }
  .timeline > li:after {
    content: " ";
    display: table;
    clear: both; }
  .timeline > li > .timeline-panel {
    float: left;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 5px;
    margin-left: 4.5%;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    width: 90%;
    cursor: pointer; }
    .timeline > li > .timeline-panel:before {
      position: absolute;
      right: -15px;
      display: inline-block;
      border-top: 15px solid transparent;
      border-left: 15px solid #ccc;
      border-right: 0 solid #ccc;
      border-bottom: 15px solid transparent;
      content: " "; }
  .timeline > li > .timeline-badge {
    color: #fff;
    width: 15px;
    height: 15px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: -1px;
    left: 3%;
    margin-left: -8px;
    background-color: #cecece;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%; }
  .timeline > li.timeline-inverted > .timeline-panel {
    /*float: right;*/ }
    .timeline > li.timeline-inverted > .timeline-panel:before {
      border-left-width: 0;
      right: auto;
      margin-top: 0px; }
    .timeline > li.timeline-inverted > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto; }

.timeline-badge {
  cursor: pointer; }
  .timeline-badge.selected {
    background-color: #2196F3 !important; }

.timeline-title {
  margin-top: 0;
  color: inherit;
  margin-bottom: 0px;
  font-size: 1.1em;
  font-weight: bold; }

.timeline-apply {
  text-align: center; }

.timeline-body > p, .timeline-body > ul {
  margin-bottom: 0; }

.timeline-body > p + p {
  margin-top: 5px; }

.timeline-icon {
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px; }

.span-date {
  vertical-align: middle; }

.md-button.md-raised:not([disabled]):hover {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.95); }

#technical {
  background-color: whitesmoke; }
  #technical .div-center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    background-color: #394b6d;
    color: #FFFFFF; }
  #technical .div-center .header-terminals-list {
    height: 44px;
    min-height: 44px !important;
    max-height: 44px;
    padding: 8px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    #technical .div-center .header-terminals-list .div-total {
      font-size: 14px; }
      #technical .div-center .header-terminals-list .div-total > md-icon {
        margin-left: 0;
        margin-right: 5px; }
  #technical .div-center .terminals-list {
    height: 100%;
    padding-bottom: 36px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    #technical .div-center .terminals-list p {
      margin: 0; }
    #technical .div-center .terminals-list .md-subheader .md-subheader-inner {
      padding-top: 0; }
  #technical .div-center .terminal-item {
    position: relative;
    height: auto !important;
    padding: 12px;
    padding-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-transform: none;
    cursor: pointer; }
    #technical .div-center .terminal-item .avatar {
      font-size: 14px; }
    #technical .div-center .terminal-item .info {
      margin: 0 16px 0 8px; }
      #technical .div-center .terminal-item .info .notes {
        font-size: 14px; }
      #technical .div-center .terminal-item .info .title {
        font-size: 15px;
        font-weight: 700; }
      #technical .div-center .terminal-item .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
    #technical .div-center .terminal-item .secondary-text md-icon {
      margin: 0; }
    #technical .div-center .terminal-item .div-sensors-status {
      margin: 8px 10px 0 6px; }
      #technical .div-center .terminal-item .div-sensors-status > div {
        padding: 2px 0;
        margin: 0 2px;
        border: solid 1px;
        text-align: center;
        font-size: 12px;
        font-weight: bold; }
        #technical .div-center .terminal-item .div-sensors-status > div md-icon {
          margin: 0; }
        #technical .div-center .terminal-item .div-sensors-status > div md-icon,
        #technical .div-center .terminal-item .div-sensors-status > div .icon {
          margin-right: 5px; }

@media screen and (max-width: 960px) {
  #technical .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #technical .center .header {
    padding-left: 16px; } }

.line-selected-before {
  border-left: 6px solid #2196F3;
  height: 5px;
  margin-left: 6px; }

.line-before {
  border-left: 3px solid #cecece;
  height: 5px;
  margin-left: 8px; }

.timeline-button-apply {
  width: 90%; }

.icon-arrow-back {
  padding-left: 4px;
  padding-right: 6px; }

.icon-arrow-forward {
  padding-left: 6px;
  padding-right: 4px; }

.timeline-date-control {
  width: 100%; }

.date-control {
  float: left;
  width: 32.3%;
  margin: 0.5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.date-prev {
  text-align: left;
  border-bottom: 1px #bdbdbd solid; }

.date-current {
  text-align: center;
  cursor: default;
  font-weight: bold;
  font-size: medium;
  border-top: 1px #bdbdbd solid;
  border-left: 1px #bdbdbd solid;
  border-right: 1px #bdbdbd solid; }

.date-next {
  text-align: right;
  border-bottom: 1px #bdbdbd solid; }

.timeline-container {
  width: 100%;
  overflow-x: auto;
  overflow-x: hidden;
  padding-top: 5px; }

.text-muted {
  color: #999; }

small, .small {
  font-size: 85%; }

.timeline {
  list-style: none;
  padding: 0px 0 10px;
  position: relative; }
  .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #cecece;
    left: 3%;
    margin-left: -1.5px; }

.timeline-bar-selected {
  list-style: none;
  padding: 20px 0 20px;
  position: relative; }
  .timeline-bar-selected:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 6px;
    background-color: #2196F3;
    left: 0%;
    margin-left: -1.5px; }

.timeline-selected:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 6px;
  background-color: #2196F3;
  left: 0%;
  margin-left: -9px;
  margin-bottom: -20px; }

.timeline > li {
  margin-bottom: 10px;
  position: relative; }
  .timeline > li:before {
    content: " ";
    display: table; }
  .timeline > li:after {
    content: " ";
    display: table;
    clear: both; }
  .timeline > li:before {
    content: " ";
    display: table; }
  .timeline > li:after {
    content: " ";
    display: table;
    clear: both; }
  .timeline > li > .timeline-panel {
    float: left;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 5px;
    margin-left: 4.5%;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    width: 90%;
    cursor: pointer; }
    .timeline > li > .timeline-panel:before {
      position: absolute;
      right: -15px;
      display: inline-block;
      border-top: 15px solid transparent;
      border-left: 15px solid #ccc;
      border-right: 0 solid #ccc;
      border-bottom: 15px solid transparent;
      content: " "; }
  .timeline > li > .timeline-badge {
    color: #fff;
    width: 15px;
    height: 15px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: -1px;
    left: 3%;
    margin-left: -8px;
    background-color: #cecece;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%; }
  .timeline > li.timeline-inverted > .timeline-panel {
    /*float: right;*/ }
    .timeline > li.timeline-inverted > .timeline-panel:before {
      border-left-width: 0;
      right: auto;
      margin-top: 0px; }
    .timeline > li.timeline-inverted > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto; }

.timeline-badge {
  cursor: pointer; }
  .timeline-badge.selected {
    background-color: #2196F3 !important; }

.timeline-title {
  margin-top: 0;
  color: inherit;
  margin-bottom: 0px; }

.timeline-apply {
  text-align: center; }

.timeline-body > p, .timeline-body > ul {
  margin-bottom: 0; }

.timeline-body > p + p {
  margin-top: 5px; }

.timeline-icon {
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px; }

.span-date {
  vertical-align: middle; }

.md-button.md-raised:not([disabled]):hover {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.95); }

.slideshow-hour-tooltip {
  color: #3f51b5; }

md-toast .div-loading-trackerReadings-list md-progress-circular svg path {
  stroke: #FFFFFF; }

md-dialog.allocationPRODATA-list {
  max-width: 600px;
  min-height: 320px;
  width: 600px; }
  md-dialog.allocationPRODATA-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.allocationPRODATA-list .allocation-save-success {
    color: green !important; }
  md-dialog.allocationPRODATA-list .allocation-save-error {
    color: red !important; }
  md-dialog.allocationPRODATA-list .background-allocation-save-success {
    background-color: green !important; }
  md-dialog.allocationPRODATA-list .background-allocation-save-error {
    background-color: red !important; }
  md-dialog.allocationPRODATA-list .error-msg {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54); }
  md-dialog.allocationPRODATA-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.allocationPRODATA-list .header-allocationPRODATA-list {
    background-color: #394b6d;
    color: #fff; }
    md-dialog.allocationPRODATA-list .header-allocationPRODATA-list.md-subheader {
      min-height: 70px; }
    md-dialog.allocationPRODATA-list .header-allocationPRODATA-list .total-allocationPRODATA-list {
      max-height: 25px; }
    md-dialog.allocationPRODATA-list .header-allocationPRODATA-list md-icon {
      margin: 0;
      color: #fff; }
    md-dialog.allocationPRODATA-list .header-allocationPRODATA-list md-input-container {
      margin-bottom: 0; }
      md-dialog.allocationPRODATA-list .header-allocationPRODATA-list md-input-container > * {
        color: #fff !important; }
      md-dialog.allocationPRODATA-list .header-allocationPRODATA-list md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.allocationPRODATA-list md-dialog-content {
    position: relative;
    min-height: 200px; }
    md-dialog.allocationPRODATA-list md-dialog-content md-list-item .md-list-item-inner {
      padding: 6px 0;
      z-index: 1; }
    md-dialog.allocationPRODATA-list md-dialog-content p {
      margin: 0; }

md-dialog#allocationTrackerReading-list {
  max-width: 100% !important;
  min-height: 95% !important;
  width: 95% !important; }
  md-dialog#allocationTrackerReading-list .transformable {
    -webkit-transition: min-height 150ms linear;
    -moz-transition: min-height 150ms linear;
    -o-transition: min-height 150ms linear;
    -ms-transition: min-height 150ms linear;
    transition: min-height 150ms linear; }
  md-dialog#allocationTrackerReading-list .expanded {
    min-height: 250px; }
  md-dialog#allocationTrackerReading-list .collapsed {
    min-height: 64px; }
  md-dialog#allocationTrackerReading-list .header-trackerReading-list {
    background-color: #394b6d;
    color: #FFFFFF; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list md-icon {
      margin: 0;
      color: #FFFFFF; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list.md-subheader {
      min-height: 70px; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list .md-button[disabled] md-icon {
      opacity: .4; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list .total-trackerReadings-list {
      max-height: 25px; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list .secondary-info md-icon {
      margin-right: 5px; }
    md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters {
      padding: 5px 0;
      font-size: 14px; }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters .date-range-filter {
        margin-top: 10px; }
        md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters .date-range-filter md-input-container md-datepicker input {
          color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters .terminal-filter {
        margin-top: 10px; }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters .item {
        cursor: pointer; }
        md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters .item.selected {
          background-color: rgba(0, 0, 0, 0.06); }
        md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters .item label {
          cursor: pointer; }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters .select-filter {
        padding: 0 24px 0 16px; }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters md-icon {
        color: rgba(0, 0, 0, 0.56); }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters md-input-container {
        max-height: 30px; }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters md-select {
        height: 35px !important; }
      md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters mdp-time-picker > div {
        padding: 0 24px 0 12px; }
        md-dialog#allocationTrackerReading-list .header-trackerReading-list .filters mdp-time-picker > div md-input-container {
          -webkit-flex: 1;
          -moz-flex: 1;
          -ms-flex: 1;
          -o-flex: 1;
          flex: 1; }
  md-dialog#allocationTrackerReading-list.md-locked-open .header {
    color: #FFFFFF; }
    md-dialog#allocationTrackerReading-list.md-locked-open .header .logo {
      padding-top: 27px; }
      md-dialog#allocationTrackerReading-list.md-locked-open .header .logo .logo-icon md-icon {
        color: #FFFFFF; }
  md-dialog#allocationTrackerReading-list:not(.md-locked-open) .header {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-top: 24px; }
  md-dialog#allocationTrackerReading-list .md-subheader {
    z-index: 3 !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08); }
    md-dialog#allocationTrackerReading-list .md-subheader .div-loading-trackerReadings-list {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  md-dialog#allocationTrackerReading-list md-input-container {
    margin-bottom: 0; }
    md-dialog#allocationTrackerReading-list md-input-container > * {
      color: #FFFFFF !important; }
    md-dialog#allocationTrackerReading-list md-input-container input {
      border-bottom-color: #FFFFFF !important; }
  md-dialog#allocationTrackerReading-list .div-floating-list-button {
    bottom: 12px; }
  md-dialog#allocationTrackerReading-list .trackerReadings-list {
    height: 100%;
    padding-bottom: 36px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    md-dialog#allocationTrackerReading-list .trackerReadings-list p {
      margin: 0; }
    md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group {
      height: auto;
      padding: 0 0 6px 0; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group:not(:last-of-type) {
        border-bottom: solid 1px rgba(0, 0, 0, 0.08); }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals {
        min-width: 370px; }
        md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals .info .disabled {
          opacity: .4; }
        md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals > div {
          margin: 0 8px; }
          md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .div-totals > div:first-of-type {
            margin-left: 0; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .button-show-hide-trackerReadings {
        font-size: 13px; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .md-subheader .md-subheader-inner {
        padding: 8px 16px 6px; }
      md-dialog#allocationTrackerReading-list .trackerReadings-list .div-group .div-trackerReadings-group {
        width: 100%;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        flex-flow: row wrap; }
  md-dialog#allocationTrackerReading-list .trackerReading-item {
    position: relative;
    height: auto !important;
    min-height: 20%;
    min-width: 345px !important;
    max-width: 345px;
    width: 345px;
    margin: 6px;
    padding: 8px 0 0;
    text-transform: none;
    cursor: pointer; }
    md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img {
      min-height: 215px;
      max-width: 330px;
      width: 100%; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img .event-img {
        z-index: 1; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img:hover .event-img-png {
        display: block;
        z-index: 2; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img .event-img-png {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 97%;
        display: none; }
        md-dialog#allocationTrackerReading-list .trackerReading-item .div-event-img .event-img-png img {
          box-shadow: none; }
    md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item {
      color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .avatar {
        font-size: 14px; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .info {
        min-width: 190px;
        max-width: 193px;
        margin-right: 4px; }
        md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .info .notes md-icon {
          margin-right: 3px;
          color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .secondary-text md-icon {
        margin: 0;
        margin-right: 5px; }
      md-dialog#allocationTrackerReading-list .trackerReading-item .div-trackerReading-item .trackerReading-address {
        max-height: 35px;
        text-overflow: ellipsis;
        overflow: hidden; }
  md-dialog#allocationTrackerReading-list md-content {
    position: relative;
    padding: 0 !important;
    display: block;
    overflow-x: hidden; }
    md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img {
      z-index: 1;
      background-color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img {
        position: relative; }
        md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img {
          width: 100%;
          z-index: 2; }
          md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal {
            height: 225px; }
            md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal .galleria-image {
              margin: 0; }
        md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img:hover .event-img-png {
          display: block;
          z-index: 2; }
        md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 97%;
          display: none; }
          md-dialog#allocationTrackerReading-list md-content .div-event-img .div-wrapper-event-img .div-img .event-img-png img {
            box-shadow: none; }
    md-dialog#allocationTrackerReading-list md-content .div-event-img .event-map-img {
      position: absolute;
      right: 0; }
    md-dialog#allocationTrackerReading-list md-content .list-item {
      width: 100%;
      padding: 12px 8px;
      font-size: 14px;
      color: white; }
      md-dialog#allocationTrackerReading-list md-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
        md-dialog#allocationTrackerReading-list md-content .list-item .title .event-edit-button {
          margin-left: 0;
          padding-left: 0;
          font-size: 22px; }
      md-dialog#allocationTrackerReading-list md-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
      md-dialog#allocationTrackerReading-list md-content .list-item .div-event-buttons button {
        margin-top: 4px;
        margin-bottom: 0; }
      md-dialog#allocationTrackerReading-list md-content .list-item md-icon {
        color: white; }
    md-dialog#allocationTrackerReading-list md-content md-input-container {
      margin: 0; }
    md-dialog#allocationTrackerReading-list md-content p {
      margin: 0; }
  md-dialog#allocationTrackerReading-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 960px) {
  md-dialog#allocationTrackerReading-list {
    min-height: 100% !important;
    width: 100% !important; } }

@media print {
  body {
    -webkit-print-color-adjust: exact !important; }
  .page-break-after {
    position: relative !important;
    display: block !important;
    page-break-before: always !important;
    page-break-after: always !important;
    float: none; }
  .md-dialog-container {
    height: auto !important;
    overflow: visible;
    z-index: 999;
    border: none !important;
    box-shadow: none !important; }
  md-dialog#allocationTrackerReading-list {
    position: relative;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
    display: block !important;
    overflow: visible !important; }
    md-dialog#allocationTrackerReading-list md-content {
      position: relative !important;
      display: block !important;
      overflow: visible !important;
      border: none !important;
      box-shadow: none; }
      md-dialog#allocationTrackerReading-list md-content .div-group {
        float: none !important;
        border-bottom: none; }
        md-dialog#allocationTrackerReading-list md-content .div-group .md-subheader {
          position: fixed !important;
          top: auto !important;
          margin: 12px 0;
          box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08); }
      md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group {
        display: block; }
        md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group > * {
          float: left !important; }
        md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item {
          height: 360px !important;
          max-height: 360px !important; }
          md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item:first-of-type, md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item:nth-of-type(2) {
            margin-top: 100px !important; }
          md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group .trackerReading-item .div-event-img {
            min-height: 205px;
            max-height: 205px;
            margin-bottom: 5px; }
      md-dialog#allocationTrackerReading-list md-content .div-trackerReadings-group,
      md-dialog#allocationTrackerReading-list md-content .trackerReading-item,
      md-dialog#allocationTrackerReading-list md-content .div-event-img,
      md-dialog#allocationTrackerReading-list md-content .div-trackerReading-item {
        position: relative !important;
        page-break-inside: avoid !important; }
    md-dialog#allocationTrackerReading-list md-toolbar {
      color: #000000;
      background-color: #FFFFFF; }
      md-dialog#allocationTrackerReading-list md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    md-dialog#allocationTrackerReading-list .content {
      display: block; }
      md-dialog#allocationTrackerReading-list .content .md-subheader {
        position: initial !important;
        margin: 12px 0; } }

md-dialog.allocation-csv-import {
  overflow: hidden; }
  md-dialog.allocation-csv-import md-toolbar {
    background-color: #394b6d; }
    md-dialog.allocation-csv-import md-toolbar .title {
      font-size: 17px; }
  md-dialog.allocation-csv-import md-dialog-content {
    display: block;
    position: relative;
    overflow: hidden; }
    md-dialog.allocation-csv-import md-dialog-content div[ng-include] {
      padding: 16px; }
    md-dialog.allocation-csv-import md-dialog-content .allocation-csv-description .title {
      margin: 0;
      line-height: 1.4em;
      font-weight: 400; }
    md-dialog.allocation-csv-import md-dialog-content .allocation-csv-description .csv-columns {
      padding-top: 18px; }
      md-dialog.allocation-csv-import md-dialog-content .allocation-csv-description .csv-columns .list-csv-columns {
        margin-left: 25px;
        font-size: 13px; }
    md-dialog.allocation-csv-import md-dialog-content md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
      min-height: 500px;
      overflow: hidden; }
    md-dialog.allocation-csv-import md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.allocation-csv-import md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
  md-dialog.allocation-csv-import md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.allocation-csv-import {
    width: 100%; }
    md-dialog.allocation-csv-import .div-input-lines > :not(:first-child) {
      margin-top: 0; }
    md-dialog.allocation-csv-import md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.allocation-csv-import {
    min-width: 600px; }
    md-dialog.allocation-csv-import md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.allocation-csv-import md-dialog-content .div-line-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.allocation-csv-import {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog {
    max-width: 720px; }
    md-dialog.allocation-csv-import {
      width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.allocation-csv-import {
    width: 90%; } }

md-dialog.allocationCSV-list {
  max-width: 600px;
  min-height: 320px;
  width: 600px; }
  md-dialog.allocationCSV-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.allocationCSV-list .allocation-save-success {
    color: green !important; }
  md-dialog.allocationCSV-list .allocation-save-error {
    color: red !important; }
  md-dialog.allocationCSV-list .background-allocation-save-success {
    background-color: green !important; }
  md-dialog.allocationCSV-list .background-allocation-save-error {
    background-color: red !important; }
  md-dialog.allocationCSV-list .error-msg {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54); }
  md-dialog.allocationCSV-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.allocationCSV-list .header-allocationCSV-list {
    background-color: #394b6d;
    color: #fff; }
    md-dialog.allocationCSV-list .header-allocationCSV-list.md-subheader {
      min-height: 70px; }
    md-dialog.allocationCSV-list .header-allocationCSV-list .total-allocationCSV-list {
      max-height: 25px; }
    md-dialog.allocationCSV-list .header-allocationCSV-list md-icon {
      margin: 0;
      color: #fff; }
    md-dialog.allocationCSV-list .header-allocationCSV-list md-input-container {
      margin-bottom: 0; }
      md-dialog.allocationCSV-list .header-allocationCSV-list md-input-container > * {
        color: #fff !important; }
      md-dialog.allocationCSV-list .header-allocationCSV-list md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.allocationCSV-list md-dialog-content {
    position: relative;
    min-height: 200px; }
    md-dialog.allocationCSV-list md-dialog-content md-list-item .md-list-item-inner {
      padding: 6px 0;
      z-index: 1; }
    md-dialog.allocationCSV-list md-dialog-content p {
      margin: 0; }

md-dialog.allocationTicketsCentroidsCSV-list {
  max-width: 720px;
  min-height: 320px;
  width: 720px; }
  md-dialog.allocationTicketsCentroidsCSV-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.allocationTicketsCentroidsCSV-list .allocation-save-success {
    color: green !important; }
  md-dialog.allocationTicketsCentroidsCSV-list .allocation-save-error {
    color: red !important; }
  md-dialog.allocationTicketsCentroidsCSV-list .background-allocation-save-success {
    background-color: green !important; }
  md-dialog.allocationTicketsCentroidsCSV-list .background-allocation-save-error {
    background-color: red !important; }
  md-dialog.allocationTicketsCentroidsCSV-list .error-msg {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54); }
  md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list {
    background-color: #394b6d;
    color: #fff; }
    md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list .md-subheader .md-subheader-inner {
      padding-top: 0; }
    md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list.md-subheader {
      min-height: 100px; }
    md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list .total-allocationCSV-list {
      max-height: 25px; }
    md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list md-icon {
      margin: 0;
      color: #fff; }
    md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list md-input-container {
      margin-bottom: 0; }
      md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list md-input-container > * {
        color: #fff !important; }
      md-dialog.allocationTicketsCentroidsCSV-list .header-allocationCSV-list md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.allocationTicketsCentroidsCSV-list md-content {
    position: relative;
    min-height: 200px; }
    md-dialog.allocationTicketsCentroidsCSV-list md-content .div-group .md-subheader .md-subheader-inner {
      background-color: rgba(0, 0, 0, 0.06); }
    md-dialog.allocationTicketsCentroidsCSV-list md-content .div-group > div {
      width: 100%; }
      md-dialog.allocationTicketsCentroidsCSV-list md-content .div-group > div .list-item {
        width: 100%; }
    md-dialog.allocationTicketsCentroidsCSV-list md-content md-list-item .md-list-item-inner {
      padding: 6px 0;
      z-index: 1; }
    md-dialog.allocationTicketsCentroidsCSV-list md-content p {
      margin: 0; }

md-dialog.allocation-edit {
  overflow: hidden; }
  md-dialog.allocation-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.allocation-edit md-dialog-content {
    display: block;
    position: relative;
    padding: 16px; }
    md-dialog.allocation-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.allocation-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.allocation-edit md-dialog-content .div-autocomplete:first-child {
      padding-left: 3px; }
    md-dialog.allocation-edit md-dialog-content .div-autocomplete > md-icon {
      max-width: 24px;
      padding-top: 3px;
      margin: 22px 6px 0 0; }
  md-dialog.allocation-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.allocation-edit {
    width: 100%; }
    md-dialog.allocation-edit .div-input-lines > :not(:first-child) {
      margin-top: 0; }
    md-dialog.allocation-edit .div-autocomplete,
    md-dialog.allocation-edit .input-date {
      max-height: 35px; }
    md-dialog.allocation-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.allocation-edit .div-autocomplete:first-child {
        margin-top: 0; }
    md-dialog.allocation-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.allocation-edit {
    min-width: 600px; }
    md-dialog.allocation-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.allocation-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.allocation-edit md-dialog-content .div-line-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.allocation-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.allocation-edit {
    max-width: 780px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.allocation-edit {
    width: 90%; } }

md-toast .div-loading-trackerReadings-list md-progress-circular svg path {
  stroke: #FFFFFF; }

md-dialog.centroid-events-graph-dialog {
  overflow: hidden; }
  md-dialog.centroid-events-graph-dialog md-dialog-content {
    position: relative;
    min-height: 200px;
    max-height: 400px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.centroid-events-graph-dialog md-dialog-content .div-terminal-terminals .header-terminal-terminals {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.centroid-events-graph-dialog md-dialog-content .div-terminal-terminals .header-terminal-terminals > span {
        padding: 12px 0 0; }
    md-dialog.centroid-events-graph-dialog md-dialog-content .div-terminal-terminals .div-terminal .terminal-item {
      cursor: move !important; }
    md-dialog.centroid-events-graph-dialog md-dialog-content .div-edit-terminal {
      padding: 0 8px; }
  md-dialog.centroid-events-graph-dialog md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }
  md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container {
    margin: 0; }
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container label {
      font-size: 16px; }
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container md-datepicker .md-datepicker-input-container {
      border-color: white; }
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__date-filters md-input-container md-datepicker .md-datepicker-expand-triangle {
      border-top-color: white !important; }
  md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter {
    padding-top: 5px; }
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input,
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container input:focus,
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label,
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete md-input-container label::after {
      border-color: white; }
    md-dialog.centroid-events-graph-dialog .header-centroid-events-graph-dialog__centroid-filter md-autocomplete button path {
      fill: white; }
  md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog {
    height: 100%;
    background-color: whitesmoke; }
    md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header {
      height: auto;
      padding: 12px;
      background-color: #394b6d;
      color: #FFFFFF; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header .search-wrapper {
        background: #fff; }
        md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header .search-wrapper .div-search {
          height: 56px;
          min-height: 56px;
          padding: 18px; }
          md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header .search-wrapper .div-search .input-search {
            height: 56px;
            padding-left: 16px;
            margin: 0;
            border-radius: 0;
            border: none;
            color: rgba(0, 0, 0, 0.54);
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header md-input-container > * {
        color: #fff !important; }
        md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header md-input-container > *:focus {
          border-bottom-color: #fff; }
        md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header md-input-container > *.md-input-focused {
          border-bottom-width: 1px !important; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header md-input-container .md-errors-spacer {
        display: none; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header md-checkbox {
        width: 20px;
        margin-bottom: 0; }
    md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header-centroid-events-graph-dialog {
      height: 64px;
      min-height: 64px !important;
      max-height: 64px;
      padding: 8px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .header-centroid-events-graph-dialog .div-total > md-icon {
        margin-left: 0;
        margin-right: 5px; }
    md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminals-list {
      height: 100%;
      padding-bottom: 36px;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      overflow-x: hidden; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminals-list p {
        margin: 0; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminals-list .md-subheader .md-subheader-inner {
        padding-top: 0; }
    md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminal-item {
      position: relative;
      height: auto !important;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      text-transform: none;
      cursor: pointer; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminal-item .avatar {
        font-size: 14px; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminal-item .info {
        margin: 0 16px 0 8px; }
        md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminal-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminal-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
      md-dialog.centroid-events-graph-dialog .content-centroid-events-graph-dialog .center .terminal-item .secondary-text md-icon {
        margin: 0; }

@media screen and (max-width: 600px) {
  md-dialog.centroid-events-graph-dialog {
    width: 100%; }
    md-dialog.centroid-events-graph-dialog md-autocomplete md-autocomplete-wrap > md-input-container {
      margin-top: 0; }
    md-dialog.centroid-events-graph-dialog md-autocomplete md-autocomplete-wrap > button {
      top: 0 !important; }
    md-dialog.centroid-events-graph-dialog .div-input-terminals > :not(:first-child) {
      margin-top: 0; }
    md-dialog.centroid-events-graph-dialog .div-autocomplete,
    md-dialog.centroid-events-graph-dialog .input-date {
      max-height: 35px; }
    md-dialog.centroid-events-graph-dialog .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.centroid-events-graph-dialog .div-autocomplete:first-child {
        margin-top: 0; }
    md-dialog.centroid-events-graph-dialog md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.centroid-events-graph-dialog {
    min-width: 600px; }
    md-dialog.centroid-events-graph-dialog .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.centroid-events-graph-dialog md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.centroid-events-graph-dialog md-dialog-content .div-terminal-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.centroid-events-graph-dialog {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.centroid-events-graph-dialog {
    max-width: 820px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.centroid-events-graph-dialog {
    width: 90%; }
    md-dialog.centroid-events-graph-dialog .installation-terminals .header .div-search {
      border-left: 1px solid rgba(0, 0, 0, 0.12); }
    md-dialog.centroid-events-graph-dialog .installation-terminals .center .header {
      padding-left: 16px; } }

md-dialog.centroid-csv-import {
  overflow: hidden; }
  md-dialog.centroid-csv-import md-toolbar {
    background-color: #394b6d; }
    md-dialog.centroid-csv-import md-toolbar .title {
      font-size: 17px; }
  md-dialog.centroid-csv-import md-dialog-content {
    display: block;
    position: relative;
    padding: 16px; }
    md-dialog.centroid-csv-import md-dialog-content .centroid-csv-description .title {
      margin: 0;
      centroid-height: 1.4em;
      font-weight: 400; }
    md-dialog.centroid-csv-import md-dialog-content .centroid-csv-description .csv-columns {
      padding-top: 18px; }
      md-dialog.centroid-csv-import md-dialog-content .centroid-csv-description .csv-columns .list-csv-columns {
        margin-left: 25px;
        font-size: 13px; }
        md-dialog.centroid-csv-import md-dialog-content .centroid-csv-description .csv-columns .list-csv-columns ul {
          list-style: none;
          font-size: 13px;
          font-style: italic; }
    md-dialog.centroid-csv-import md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.centroid-csv-import md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
  md-dialog.centroid-csv-import md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.centroid-csv-import {
    width: 100%; }
    md-dialog.centroid-csv-import .div-input-centroids > :not(:first-child) {
      margin-top: 0; }
    md-dialog.centroid-csv-import md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.centroid-csv-import {
    min-width: 600px; }
    md-dialog.centroid-csv-import md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.centroid-csv-import md-dialog-content .div-centroid-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.centroid-csv-import {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog {
    max-width: 720px; }
    md-dialog.centroid-csv-import {
      width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.centroid-csv-import {
    width: 90%; } }

md-dialog.centroidCSV-list {
  max-width: 600px;
  min-height: 320px;
  width: 600px; }
  md-dialog.centroidCSV-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.centroidCSV-list .centroid-save-success {
    color: green !important; }
  md-dialog.centroidCSV-list .centroid-save-error {
    color: red !important; }
  md-dialog.centroidCSV-list .background-centroid-save-success {
    background-color: green !important; }
  md-dialog.centroidCSV-list .background-centroid-save-error {
    background-color: red !important; }
  md-dialog.centroidCSV-list .error-msg {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54); }
  md-dialog.centroidCSV-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.centroidCSV-list .header-centroidCSV-list {
    background-color: #394b6d;
    color: #fff; }
    md-dialog.centroidCSV-list .header-centroidCSV-list.md-subheader {
      min-height: 70px; }
    md-dialog.centroidCSV-list .header-centroidCSV-list .total-centroidCSV-list {
      max-height: 25px; }
    md-dialog.centroidCSV-list .header-centroidCSV-list md-icon {
      margin: 0;
      color: #fff;
      margin-right: 3px; }
    md-dialog.centroidCSV-list .header-centroidCSV-list md-input-container {
      margin-bottom: 0; }
      md-dialog.centroidCSV-list .header-centroidCSV-list md-input-container > * {
        color: #fff !important; }
      md-dialog.centroidCSV-list .header-centroidCSV-list md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.centroidCSV-list md-dialog-content {
    position: relative;
    min-height: 200px; }
    md-dialog.centroidCSV-list md-dialog-content md-list-item .md-list-item-inner {
      padding: 6px 0;
      z-index: 1; }
    md-dialog.centroidCSV-list md-dialog-content p {
      margin: 0; }

md-dialog.centroid-edit {
  overflow: hidden; }
  md-dialog.centroid-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.centroid-edit md-dialog-content {
    position: relative;
    min-height: 200px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.centroid-edit md-dialog-content .div-centroid-centroids .header-centroid-centroids {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.centroid-edit md-dialog-content .div-centroid-centroids .header-centroid-centroids > span {
        padding: 12px 0 0; }
    md-dialog.centroid-edit md-dialog-content .div-centroid-centroids .div-centroid .centroid-item {
      cursor: move !important; }
    md-dialog.centroid-edit md-dialog-content .div-edit-centroid {
      padding: 0 8px; }
    md-dialog.centroid-edit md-dialog-content .div-gmaps {
      min-height: 350px; }
      md-dialog.centroid-edit md-dialog-content .div-gmaps .gmaps {
        overflow: hidden; }
    md-dialog.centroid-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.centroid-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.centroid-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.centroid-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.centroid-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.centroid-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.div-centroid-centroids.as-sortable-dragging {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.centroid-edit {
    width: 100%;
    height: 100%; }
    md-dialog.centroid-edit .div-input-centroids > :not(:first-child) {
      margin-top: 0; }
    md-dialog.centroid-edit .div-autocomplete,
    md-dialog.centroid-edit .input-date {
      max-height: 35px; }
    md-dialog.centroid-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.centroid-edit .div-autocomplete:first-child,
      md-dialog.centroid-edit .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.centroid-edit .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.centroid-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.centroid-edit {
    min-width: 600px; }
    md-dialog.centroid-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.centroid-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.centroid-edit md-dialog-content .div-centroid-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.centroid-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.centroid-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.centroid-edit {
    width: 90%; } }

md-dialog.contract-edit {
  min-height: 400px;
  overflow: hidden; }
  md-dialog.contract-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.contract-edit md-dialog-content {
    position: relative;
    min-height: 300px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.contract-edit md-dialog-content .header-contract-simCards {
      min-height: 40px;
      max-height: 40px; }
    md-dialog.contract-edit md-dialog-content .div-edit-contract {
      padding: 0 8px; }
    md-dialog.contract-edit md-dialog-content .div-simCard {
      font-size: 14px; }
      md-dialog.contract-edit md-dialog-content .div-simCard .list-item .info .title {
        font-size: 15px;
        font-weight: 600; }
    md-dialog.contract-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.contract-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.contract-edit md-dialog-content .note {
      font-style: italic; }
    md-dialog.contract-edit md-dialog-content md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
      min-height: 300px;
      overflow: hidden; }
    md-dialog.contract-edit md-dialog-content md-tab-content {
      overflow: hidden; }
    md-dialog.contract-edit md-dialog-content md-tab-content > div {
      overflow-x: hidden;
      padding: 16px; }
    md-dialog.contract-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.contract-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.contract-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.contract-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.contract-edit {
    width: 100%; }
    md-dialog.contract-edit .div-input-contracts > :not(:first-child) {
      margin-top: 0; }
    md-dialog.contract-edit .div-autocomplete,
    md-dialog.contract-edit .input-date {
      max-height: 35px; }
    md-dialog.contract-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.contract-edit .div-autocomplete:first-child,
      md-dialog.contract-edit .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.contract-edit .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.contract-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.contract-edit {
    min-width: 600px; }
    md-dialog.contract-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.contract-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.contract-edit md-dialog-content .div-contract-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.contract-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.contract-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.contract-edit {
    width: 90%; } }

@media screen and (max-height: 700px) {
  md-dialog.contract-edit {
    min-height: 550px;
    max-height: 550px; } }

md-dialog.line-csv-import {
  overflow: hidden; }
  md-dialog.line-csv-import md-toolbar {
    background-color: #394b6d; }
    md-dialog.line-csv-import md-toolbar .title {
      font-size: 17px; }
  md-dialog.line-csv-import md-dialog-content {
    display: block;
    position: relative;
    padding: 16px; }
    md-dialog.line-csv-import md-dialog-content .line-csv-description .title {
      margin: 0;
      line-height: 1.4em;
      font-weight: 400; }
    md-dialog.line-csv-import md-dialog-content .line-csv-description .csv-columns {
      padding-top: 18px; }
      md-dialog.line-csv-import md-dialog-content .line-csv-description .csv-columns .list-csv-columns {
        margin-left: 25px;
        font-size: 13px; }
    md-dialog.line-csv-import md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.line-csv-import md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
  md-dialog.line-csv-import md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.line-csv-import {
    width: 100%; }
    md-dialog.line-csv-import .div-input-lines > :not(:first-child) {
      margin-top: 0; }
    md-dialog.line-csv-import md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.line-csv-import {
    min-width: 600px; }
    md-dialog.line-csv-import md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.line-csv-import md-dialog-content .div-line-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.line-csv-import {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog {
    max-width: 720px; }
    md-dialog.line-csv-import {
      width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.line-csv-import {
    width: 90%; } }

md-dialog.lineCSV-list {
  max-width: 600px;
  min-height: 320px;
  width: 600px; }
  md-dialog.lineCSV-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.lineCSV-list .line-save-success {
    color: green !important; }
  md-dialog.lineCSV-list .line-save-error {
    color: red !important; }
  md-dialog.lineCSV-list .background-line-save-success {
    background-color: green !important; }
  md-dialog.lineCSV-list .background-line-save-error {
    background-color: red !important; }
  md-dialog.lineCSV-list .error-msg {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54); }
  md-dialog.lineCSV-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.lineCSV-list .header-lineCSV-list {
    background-color: #394b6d;
    color: #fff; }
    md-dialog.lineCSV-list .header-lineCSV-list.md-subheader {
      min-height: 70px; }
    md-dialog.lineCSV-list .header-lineCSV-list .total-lineCSV-list {
      max-height: 25px; }
    md-dialog.lineCSV-list .header-lineCSV-list md-icon {
      margin: 0;
      color: #fff; }
    md-dialog.lineCSV-list .header-lineCSV-list md-input-container {
      margin-bottom: 0; }
      md-dialog.lineCSV-list .header-lineCSV-list md-input-container > * {
        color: #fff !important; }
      md-dialog.lineCSV-list .header-lineCSV-list md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.lineCSV-list md-dialog-content {
    position: relative;
    min-height: 200px; }
    md-dialog.lineCSV-list md-dialog-content md-list-item .md-list-item-inner {
      padding: 6px 0;
      z-index: 1; }
    md-dialog.lineCSV-list md-dialog-content p {
      margin: 0; }

md-dialog.line-edit {
  overflow: hidden; }
  md-dialog.line-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.line-edit md-dialog-content {
    position: relative;
    min-height: 250px;
    display: block;
    padding: 16px;
    overflow-x: hidden; }
    md-dialog.line-edit md-dialog-content .div-line-centroids .header-line-centroids {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.line-edit md-dialog-content .div-line-centroids .header-line-centroids > span {
        padding: 12px 0 0; }
    md-dialog.line-edit md-dialog-content .div-line-centroids .div-centroid .line-item {
      cursor: move !important; }
    md-dialog.line-edit md-dialog-content .div-edit-centroid {
      padding: 0 8px; }
    md-dialog.line-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.line-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.line-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.line-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.line-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.line-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.div-line-centroids.as-sortable-dragging {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.line-edit {
    width: 100%; }
    md-dialog.line-edit .div-input-lines > :not(:first-child) {
      margin-top: 0; }
    md-dialog.line-edit .div-autocomplete,
    md-dialog.line-edit .input-date {
      max-height: 55px; }
    md-dialog.line-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.line-edit .div-autocomplete:first-child {
        margin-top: 0; }
      md-dialog.line-edit .div-autocomplete md-icon {
        margin-bottom: 5px; }
    md-dialog.line-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.line-edit {
    min-width: 600px; }
    md-dialog.line-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.line-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.line-edit md-dialog-content .div-line-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.line-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.line-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.line-edit {
    width: 90%; } }

md-dialog.employee-csv-import {
  overflow: hidden; }
  md-dialog.employee-csv-import md-toolbar {
    background-color: #394b6d; }
    md-dialog.employee-csv-import md-toolbar .title {
      font-size: 17px; }
  md-dialog.employee-csv-import md-dialog-content {
    display: block;
    position: relative;
    padding: 16px; }
    md-dialog.employee-csv-import md-dialog-content .employee-csv-description .title {
      margin: 0;
      employee-height: 1.4em;
      font-weight: 400; }
    md-dialog.employee-csv-import md-dialog-content .employee-csv-description .csv-columns {
      padding-top: 18px; }
      md-dialog.employee-csv-import md-dialog-content .employee-csv-description .csv-columns .list-csv-columns {
        margin-left: 25px;
        font-size: 13px; }
        md-dialog.employee-csv-import md-dialog-content .employee-csv-description .csv-columns .list-csv-columns ul {
          list-style: none;
          font-size: 13px;
          font-style: italic; }
    md-dialog.employee-csv-import md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.employee-csv-import md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
  md-dialog.employee-csv-import md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.employee-csv-import {
    width: 100%; }
    md-dialog.employee-csv-import .div-input-employees > :not(:first-child) {
      margin-top: 0; }
    md-dialog.employee-csv-import md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.employee-csv-import {
    min-width: 600px; }
    md-dialog.employee-csv-import md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.employee-csv-import md-dialog-content .div-employee-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.employee-csv-import {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog {
    max-width: 720px; }
    md-dialog.employee-csv-import {
      width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.employee-csv-import {
    width: 90%; } }

md-dialog.employeeCSV-list {
  max-width: 600px;
  min-height: 320px;
  width: 600px; }
  md-dialog.employeeCSV-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.employeeCSV-list .employee-save-success {
    color: green !important; }
  md-dialog.employeeCSV-list .employee-save-error {
    color: red !important; }
  md-dialog.employeeCSV-list .background-employee-save-success {
    background-color: green !important; }
  md-dialog.employeeCSV-list .background-employee-save-error {
    background-color: red !important; }
  md-dialog.employeeCSV-list .error-msg {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54); }
  md-dialog.employeeCSV-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.employeeCSV-list .header-employeeCSV-list {
    background-color: #394b6d;
    color: #fff; }
    md-dialog.employeeCSV-list .header-employeeCSV-list.md-subheader {
      min-height: 70px; }
    md-dialog.employeeCSV-list .header-employeeCSV-list .total-employeeCSV-list {
      max-height: 25px; }
    md-dialog.employeeCSV-list .header-employeeCSV-list md-icon {
      margin: 0;
      color: #fff;
      margin-right: 3px; }
    md-dialog.employeeCSV-list .header-employeeCSV-list md-input-container {
      margin-bottom: 0; }
      md-dialog.employeeCSV-list .header-employeeCSV-list md-input-container > * {
        color: #fff !important; }
      md-dialog.employeeCSV-list .header-employeeCSV-list md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.employeeCSV-list md-dialog-content {
    position: relative;
    min-height: 200px; }
    md-dialog.employeeCSV-list md-dialog-content md-list-item .md-list-item-inner {
      padding: 6px 0;
      z-index: 1; }
    md-dialog.employeeCSV-list md-dialog-content p {
      margin: 0; }

md-dialog.employee-edit {
  min-height: 300px;
  overflow: hidden; }
  md-dialog.employee-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.employee-edit md-dialog-content {
    position: relative;
    min-height: 200px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.employee-edit md-dialog-content .div-employee-employees .header-employee-employees {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.employee-edit md-dialog-content .div-employee-employees .header-employee-employees > span {
        padding: 12px 0 0; }
    md-dialog.employee-edit md-dialog-content .div-employee-employees .div-employee .employee-item {
      cursor: move !important; }
    md-dialog.employee-edit md-dialog-content .div-edit-employee {
      padding: 0 8px; }
    md-dialog.employee-edit md-dialog-content .div-gmaps {
      min-height: 350px; }
      md-dialog.employee-edit md-dialog-content .div-gmaps .gmaps {
        overflow: hidden; }
    md-dialog.employee-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.employee-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.employee-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.employee-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.employee-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.employee-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.div-employee-employees.as-sortable-dragging {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.employee-edit {
    width: 100%; }
    md-dialog.employee-edit .div-input-employees > :not(:first-child) {
      margin-top: 0; }
    md-dialog.employee-edit .div-autocomplete,
    md-dialog.employee-edit .input-date {
      max-height: 35px; }
    md-dialog.employee-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.employee-edit .div-autocomplete:first-child,
      md-dialog.employee-edit .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.employee-edit .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.employee-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.employee-edit {
    min-width: 600px; }
    md-dialog.employee-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.employee-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.employee-edit md-dialog-content .div-employee-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.employee-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.employee-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.employee-edit {
    width: 90%; } }

@media screen and (max-height: 700px) {
  md-dialog.employee-edit {
    min-height: 550px;
    max-height: 550px; } }

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important; }
  .modelOperationLog-table thead, .modelOperationLog-table tfoot {
    display: table-row-group; } }

md-dialog.organization-edit {
  min-height: 200px;
  overflow: hidden; }
  md-dialog.organization-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.organization-edit md-dialog-content {
    display: block;
    position: relative;
    padding: 16px; }
    md-dialog.organization-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.organization-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.organization-edit md-dialog-content .div-autocomplete:first-child {
      padding-left: 3px; }
    md-dialog.organization-edit md-dialog-content .div-autocomplete > md-icon {
      max-width: 24px;
      padding-top: 3px;
      margin: 22px 6px 0 0; }
    md-dialog.organization-edit md-dialog-content .div-gmaps {
      min-height: 350px; }
      md-dialog.organization-edit md-dialog-content .div-gmaps .gmaps {
        overflow: hidden; }
  md-dialog.organization-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.organization-edit {
    width: 100%; }
    md-dialog.organization-edit md-autocomplete md-autocomplete-wrap > md-input-container {
      margin-top: 0; }
    md-dialog.organization-edit md-autocomplete md-autocomplete-wrap > button {
      top: 0 !important; }
    md-dialog.organization-edit .div-autocomplete {
      max-height: 35px;
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.organization-edit .div-autocomplete:first-child {
        margin-top: 0; }
      md-dialog.organization-edit .div-autocomplete > md-icon {
        margin-top: 3px !important; }
    md-dialog.organization-edit .div-input-lines > :not(:first-child) {
      margin-top: 0; }
    md-dialog.organization-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.organization-edit {
    min-width: 600px; }
    md-dialog.organization-edit .div-organization-inputs {
      max-height: 75px; }
    md-dialog.organization-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.organization-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.organization-edit md-dialog-content .div-line-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.organization-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.organization-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.organization-edit {
    width: 90%; } }

md-dialog.service-edit {
  overflow: hidden; }
  md-dialog.service-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.service-edit md-dialog-content {
    display: block;
    position: relative;
    padding: 16px; }
    md-dialog.service-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.service-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.service-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.service-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.service-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.service-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.service-edit {
    width: 100%; }
    md-dialog.service-edit .div-input-lines > :not(:first-child) {
      margin-top: 0; }
    md-dialog.service-edit .div-autocomplete,
    md-dialog.service-edit .input-date {
      max-height: 35px; }
    md-dialog.service-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.service-edit .div-autocomplete:first-child {
        margin-top: 0; }
    md-dialog.service-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.service-edit {
    min-width: 600px; }
    md-dialog.service-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.service-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.service-edit md-dialog-content .div-line-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.service-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.service-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.service-edit {
    width: 90%; } }

md-dialog.serviceCSV-list {
  max-width: 600px;
  min-height: 320px;
  width: 600px; }
  md-dialog.serviceCSV-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.serviceCSV-list .service-save-success {
    color: green !important; }
  md-dialog.serviceCSV-list .service-save-error {
    color: red !important; }
  md-dialog.serviceCSV-list .background-service-save-success {
    background-color: green !important; }
  md-dialog.serviceCSV-list .background-service-save-error {
    background-color: red !important; }
  md-dialog.serviceCSV-list .error-msg {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54); }
  md-dialog.serviceCSV-list .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.serviceCSV-list .header-serviceCSV-list {
    background-color: #394b6d;
    color: #fff; }
    md-dialog.serviceCSV-list .header-serviceCSV-list.md-subheader {
      min-height: 70px; }
    md-dialog.serviceCSV-list .header-serviceCSV-list .total-serviceCSV-list {
      max-height: 25px; }
    md-dialog.serviceCSV-list .header-serviceCSV-list md-icon {
      margin: 0;
      color: #fff; }
    md-dialog.serviceCSV-list .header-serviceCSV-list md-input-container {
      margin-bottom: 0; }
      md-dialog.serviceCSV-list .header-serviceCSV-list md-input-container > * {
        color: #fff !important; }
      md-dialog.serviceCSV-list .header-serviceCSV-list md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.serviceCSV-list md-dialog-content {
    position: relative;
    min-height: 200px; }
    md-dialog.serviceCSV-list md-dialog-content md-list-item .md-list-item-inner {
      padding: 6px 0;
      z-index: 1; }
    md-dialog.serviceCSV-list md-dialog-content p {
      margin: 0; }

md-dialog.service-csv-import {
  overflow: hidden; }
  md-dialog.service-csv-import md-toolbar {
    background-color: #394b6d; }
    md-dialog.service-csv-import md-toolbar .title {
      font-size: 17px; }
  md-dialog.service-csv-import md-dialog-content {
    display: block;
    position: relative;
    padding: 16px; }
    md-dialog.service-csv-import md-dialog-content .service-csv-description .title {
      margin: 0;
      line-height: 1.4em;
      font-weight: 400; }
    md-dialog.service-csv-import md-dialog-content .service-csv-description .csv-columns {
      padding-top: 18px; }
      md-dialog.service-csv-import md-dialog-content .service-csv-description .csv-columns .list-csv-columns {
        margin-left: 25px;
        font-size: 13px; }
    md-dialog.service-csv-import md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.service-csv-import md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
  md-dialog.service-csv-import md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.service-csv-import {
    width: 100%; }
    md-dialog.service-csv-import .div-input-lines > :not(:first-child) {
      margin-top: 0; }
    md-dialog.service-csv-import md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.service-csv-import {
    min-width: 600px; }
    md-dialog.service-csv-import md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.service-csv-import md-dialog-content .div-line-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.service-csv-import {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog {
    max-width: 720px; }
    md-dialog.service-csv-import {
      width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.service-csv-import {
    width: 90%; } }

md-dialog.simCardInstallation-confirm {
  min-height: 300px;
  overflow: hidden; }
  md-dialog.simCardInstallation-confirm md-toolbar .title {
    font-size: 17px; }
  md-dialog.simCardInstallation-confirm md-dialog-content {
    position: relative;
    min-height: 300px;
    padding: 0 0 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.simCardInstallation-confirm md-dialog-content .div-simCardOrganization-simCardOrganizations .header-simCardOrganization-simCardOrganizations {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.simCardInstallation-confirm md-dialog-content .div-simCardOrganization-simCardOrganizations .header-simCardOrganization-simCardOrganizations > span {
        padding: 12px 0 0; }
    md-dialog.simCardInstallation-confirm md-dialog-content .div-simCardOrganization-simCardOrganizations .div-simCardOrganization .simCardOrganization-item {
      cursor: move !important; }
    md-dialog.simCardInstallation-confirm md-dialog-content .div-edit-simCardOrganization {
      padding: 0 8px; }
    md-dialog.simCardInstallation-confirm md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.simCardInstallation-confirm md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.simCardInstallation-confirm md-dialog-content md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
      min-height: 300px;
      overflow: hidden; }
    md-dialog.simCardInstallation-confirm md-dialog-content md-tab-content {
      overflow: hidden; }
    md-dialog.simCardInstallation-confirm md-dialog-content md-tab-content > div {
      overflow-x: hidden;
      padding: 16px; }
    md-dialog.simCardInstallation-confirm md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.simCardInstallation-confirm md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.simCardInstallation-confirm md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.simCardInstallation-confirm md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.div-simCardOrganization-simCardOrganizations.as-sortable-dragging {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.simCardInstallation-confirm {
    width: 100%; }
    md-dialog.simCardInstallation-confirm .div-input-simCardOrganizations > :not(:first-child) {
      margin-top: 0; }
    md-dialog.simCardInstallation-confirm .div-autocomplete,
    md-dialog.simCardInstallation-confirm .input-date {
      max-height: 35px; }
    md-dialog.simCardInstallation-confirm .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.simCardInstallation-confirm .div-autocomplete:first-child,
      md-dialog.simCardInstallation-confirm .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.simCardInstallation-confirm .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.simCardInstallation-confirm md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.simCardInstallation-confirm {
    min-width: 350px; }
    md-dialog.simCardInstallation-confirm .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCardInstallation-confirm md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCardInstallation-confirm md-dialog-content .div-simCardOrganization-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.simCardInstallation-confirm {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.simCardInstallation-confirm {
    max-width: 500px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.simCardInstallation-confirm {
    width: 90%; } }

@media screen and (max-height: 700px) {
  md-dialog.simCardInstallation-confirm {
    min-height: 550px;
    max-height: 550px; } }

md-dialog.simCard-edit {
  min-height: 300px;
  overflow: hidden; }
  md-dialog.simCard-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.simCard-edit md-dialog-content {
    position: relative;
    min-height: 300px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.simCard-edit md-dialog-content .div-simCard-simCards .header-simCard-simCards {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.simCard-edit md-dialog-content .div-simCard-simCards .header-simCard-simCards > span {
        padding: 12px 0 0; }
    md-dialog.simCard-edit md-dialog-content .div-simCard-simCards .div-simCard .simCard-item {
      cursor: move !important; }
    md-dialog.simCard-edit md-dialog-content .div-edit-simCard {
      padding: 0 8px; }
    md-dialog.simCard-edit md-dialog-content .div-gmaps {
      min-height: 350px; }
      md-dialog.simCard-edit md-dialog-content .div-gmaps .gmaps {
        overflow: hidden; }
    md-dialog.simCard-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.simCard-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.simCard-edit md-dialog-content md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
      min-height: 300px;
      overflow: hidden; }
    md-dialog.simCard-edit md-dialog-content md-tab-content {
      overflow: hidden; }
    md-dialog.simCard-edit md-dialog-content md-tab-content > div {
      overflow-x: hidden;
      padding: 16px; }
    md-dialog.simCard-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.simCard-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.simCard-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.simCard-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.div-simCard-simCards.as-sortable-dragging {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.simCard-edit {
    width: 100%; }
    md-dialog.simCard-edit .div-input-simCards > :not(:first-child) {
      margin-top: 0; }
    md-dialog.simCard-edit .div-autocomplete,
    md-dialog.simCard-edit .input-date {
      max-height: 35px; }
    md-dialog.simCard-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.simCard-edit .div-autocomplete:first-child,
      md-dialog.simCard-edit .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.simCard-edit .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.simCard-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.simCard-edit {
    min-width: 600px; }
    md-dialog.simCard-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCard-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCard-edit md-dialog-content .div-simCard-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.simCard-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.simCard-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.simCard-edit {
    width: 90%; } }

@media screen and (max-height: 700px) {
  md-dialog.simCard-edit {
    min-height: 550px;
    max-height: 550px; } }

md-dialog.simCardOrganization-edit {
  min-height: 300px;
  overflow: hidden; }
  md-dialog.simCardOrganization-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.simCardOrganization-edit md-dialog-content {
    position: relative;
    min-height: 300px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.simCardOrganization-edit md-dialog-content .div-simCardOrganization-simCardOrganizations .header-simCardOrganization-simCardOrganizations {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.simCardOrganization-edit md-dialog-content .div-simCardOrganization-simCardOrganizations .header-simCardOrganization-simCardOrganizations > span {
        padding: 12px 0 0; }
    md-dialog.simCardOrganization-edit md-dialog-content .div-simCardOrganization-simCardOrganizations .div-simCardOrganization .simCardOrganization-item {
      cursor: move !important; }
    md-dialog.simCardOrganization-edit md-dialog-content .div-edit-simCardOrganization {
      padding: 0 8px; }
    md-dialog.simCardOrganization-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.simCardOrganization-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.simCardOrganization-edit md-dialog-content md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
      min-height: 300px;
      overflow: hidden; }
    md-dialog.simCardOrganization-edit md-dialog-content md-tab-content {
      overflow: hidden; }
    md-dialog.simCardOrganization-edit md-dialog-content md-tab-content > div {
      overflow-x: hidden;
      padding: 16px; }
    md-dialog.simCardOrganization-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.simCardOrganization-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.simCardOrganization-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.simCardOrganization-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.div-simCardOrganization-simCardOrganizations.as-sortable-dragging {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.simCardOrganization-edit {
    width: 100%; }
    md-dialog.simCardOrganization-edit .div-input-simCardOrganizations > :not(:first-child) {
      margin-top: 0; }
    md-dialog.simCardOrganization-edit .div-autocomplete,
    md-dialog.simCardOrganization-edit .input-date {
      max-height: 35px; }
    md-dialog.simCardOrganization-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.simCardOrganization-edit .div-autocomplete:first-child,
      md-dialog.simCardOrganization-edit .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.simCardOrganization-edit .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.simCardOrganization-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.simCardOrganization-edit {
    min-width: 350px; }
    md-dialog.simCardOrganization-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCardOrganization-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCardOrganization-edit md-dialog-content .div-simCardOrganization-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.simCardOrganization-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.simCardOrganization-edit {
    max-width: 500px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.simCardOrganization-edit {
    width: 90%; } }

@media screen and (max-height: 700px) {
  md-dialog.simCardOrganization-edit {
    min-height: 550px;
    max-height: 550px; } }

md-dialog.simCard-installation-component {
  overflow: hidden; }
  md-dialog.simCard-installation-component md-toolbar .title {
    font-size: 17px; }
  md-dialog.simCard-installation-component md-dialog-content {
    position: relative;
    min-height: 200px;
    max-height: 400px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.simCard-installation-component md-dialog-content .div-terminal-terminals .header-terminal-terminals {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.simCard-installation-component md-dialog-content .div-terminal-terminals .header-terminal-terminals > span {
        padding: 12px 0 0; }
    md-dialog.simCard-installation-component md-dialog-content .div-terminal-terminals .div-terminal .terminal-item {
      cursor: move !important; }
    md-dialog.simCard-installation-component md-dialog-content .div-edit-terminal {
      padding: 0 8px; }
  md-dialog.simCard-installation-component md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }
  md-dialog.simCard-installation-component .installation-simCards {
    height: 100%;
    background-color: whitesmoke; }
    md-dialog.simCard-installation-component .installation-simCards .center .header {
      height: auto;
      padding: 12px;
      background-color: #394b6d;
      color: #FFFFFF; }
      md-dialog.simCard-installation-component .installation-simCards .center .header .search-wrapper {
        background: #fff; }
        md-dialog.simCard-installation-component .installation-simCards .center .header .search-wrapper .div-search {
          height: 56px;
          min-height: 56px;
          padding: 18px; }
          md-dialog.simCard-installation-component .installation-simCards .center .header .search-wrapper .div-search .input-search {
            height: 56px;
            padding-left: 16px;
            margin: 0;
            border-radius: 0;
            border: none;
            color: rgba(0, 0, 0, 0.54);
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none; }
      md-dialog.simCard-installation-component .installation-simCards .center .header md-input-container > * {
        color: #fff !important; }
        md-dialog.simCard-installation-component .installation-simCards .center .header md-input-container > *:focus {
          border-bottom-color: #fff; }
        md-dialog.simCard-installation-component .installation-simCards .center .header md-input-container > *.md-input-focused {
          border-bottom-width: 1px !important; }
      md-dialog.simCard-installation-component .installation-simCards .center .header md-input-container .md-errors-spacer {
        display: none; }
      md-dialog.simCard-installation-component .installation-simCards .center .header md-checkbox {
        width: 20px;
        margin-bottom: 0; }
    md-dialog.simCard-installation-component .installation-simCards .center .header-simCard-installation-component {
      height: 64px;
      min-height: 64px !important;
      max-height: 64px;
      padding: 8px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      md-dialog.simCard-installation-component .installation-simCards .center .header-simCard-installation-component .div-total > md-icon {
        margin-left: 0;
        margin-right: 5px; }
    md-dialog.simCard-installation-component .installation-simCards .center .terminals-list {
      height: 100%;
      padding-bottom: 36px;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      overflow-x: hidden; }
      md-dialog.simCard-installation-component .installation-simCards .center .terminals-list p {
        margin: 0; }
      md-dialog.simCard-installation-component .installation-simCards .center .terminals-list .md-subheader .md-subheader-inner {
        padding-top: 0; }
    md-dialog.simCard-installation-component .installation-simCards .center .terminal-item {
      position: relative;
      height: auto !important;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      text-transform: none;
      cursor: pointer; }
      md-dialog.simCard-installation-component .installation-simCards .center .terminal-item .avatar {
        font-size: 14px; }
      md-dialog.simCard-installation-component .installation-simCards .center .terminal-item .info {
        margin: 0 16px 0 8px; }
        md-dialog.simCard-installation-component .installation-simCards .center .terminal-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        md-dialog.simCard-installation-component .installation-simCards .center .terminal-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
      md-dialog.simCard-installation-component .installation-simCards .center .terminal-item .secondary-text md-icon {
        margin: 0; }

@media screen and (max-width: 600px) {
  md-dialog.simCard-installation-component {
    width: 100%; }
    md-dialog.simCard-installation-component md-autocomplete md-autocomplete-wrap > md-input-container {
      margin-top: 0; }
    md-dialog.simCard-installation-component md-autocomplete md-autocomplete-wrap > button {
      top: 0 !important; }
    md-dialog.simCard-installation-component .div-input-terminals > :not(:first-child) {
      margin-top: 0; }
    md-dialog.simCard-installation-component .div-autocomplete,
    md-dialog.simCard-installation-component .input-date {
      max-height: 35px; }
    md-dialog.simCard-installation-component .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.simCard-installation-component .div-autocomplete:first-child {
        margin-top: 0; }
    md-dialog.simCard-installation-component md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.simCard-installation-component {
    min-width: 600px; }
    md-dialog.simCard-installation-component .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCard-installation-component md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.simCard-installation-component md-dialog-content .div-terminal-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.simCard-installation-component {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.simCard-installation-component {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.simCard-installation-component {
    width: 90%; }
    md-dialog.simCard-installation-component .installation-terminals .header .div-search {
      border-left: 1px solid rgba(0, 0, 0, 0.12); }
    md-dialog.simCard-installation-component .installation-terminals .center .header {
      padding-left: 16px; } }

md-dialog.terminal-edit {
  overflow: hidden; }
  md-dialog.terminal-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.terminal-edit md-dialog-content {
    position: relative;
    min-height: 200px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.terminal-edit md-dialog-content .div-terminal-terminals .header-terminal-terminals {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.terminal-edit md-dialog-content .div-terminal-terminals .header-terminal-terminals > span {
        padding: 12px 0 0; }
    md-dialog.terminal-edit md-dialog-content .div-terminal-terminals .div-terminal .terminal-item {
      cursor: move !important; }
    md-dialog.terminal-edit md-dialog-content .div-edit-terminal {
      padding: 0 8px; }
    md-dialog.terminal-edit md-dialog-content .div-gmaps {
      min-height: 350px; }
      md-dialog.terminal-edit md-dialog-content .div-gmaps .gmaps {
        overflow: hidden; }
    md-dialog.terminal-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.terminal-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.terminal-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.terminal-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.terminal-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.terminal-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.div-terminal-terminals.as-sortable-dragging {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.terminal-edit {
    width: 100%; }
    md-dialog.terminal-edit md-autocomplete md-autocomplete-wrap > md-input-container {
      margin-top: 0; }
    md-dialog.terminal-edit md-autocomplete md-autocomplete-wrap > button {
      top: 0 !important; }
    md-dialog.terminal-edit .div-input-terminals > :not(:first-child) {
      margin-top: 0; }
    md-dialog.terminal-edit .div-autocomplete,
    md-dialog.terminal-edit .input-date {
      max-height: 35px; }
    md-dialog.terminal-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.terminal-edit .div-autocomplete:first-child {
        margin-top: 0; }
    md-dialog.terminal-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.terminal-edit {
    min-width: 600px; }
    md-dialog.terminal-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.terminal-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.terminal-edit md-dialog-content .div-terminal-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.terminal-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.terminal-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.terminal-edit {
    width: 90%; } }

md-dialog.terminals-update-version {
  max-width: 420px;
  width: 420px;
  overflow: hidden; }
  md-dialog.terminals-update-version md-toolbar {
    background-color: #394b6d; }
  md-dialog.terminals-update-version md-dialog-content {
    position: relative;
    padding: 16px;
    min-height: 200px;
    display: block; }
    md-dialog.terminals-update-version md-dialog-content .list-item md-icon {
      margin-right: 5px; }
  md-dialog.terminals-update-version md-toolbar {
    min-height: 52px;
    height: 52px; }
    md-dialog.terminals-update-version md-toolbar .title {
      padding: 18px 0 0;
      font-size: 17px; }
    md-dialog.terminals-update-version md-toolbar md-input-container {
      margin-bottom: 0; }
      md-dialog.terminals-update-version md-toolbar md-input-container > * {
        color: #fff !important; }
      md-dialog.terminals-update-version md-toolbar md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.terminals-update-version md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.terminals-camera {
  max-width: 720px;
  min-height: 420px;
  width: 500px;
  overflow: hidden; }
  md-dialog.terminals-camera .md-subheader .md-subheader-inner {
    padding-top: 0; }
  md-dialog.terminals-camera .header-terminals-camera {
    background-color: #263238;
    color: #fff; }
    md-dialog.terminals-camera .header-terminals-camera .total-terminals-camera {
      max-height: 25px; }
    md-dialog.terminals-camera .header-terminals-camera md-icon {
      margin: 0;
      color: #fff; }
  md-dialog.terminals-camera .div-floating-list-button {
    bottom: 12px; }
  md-dialog.terminals-camera .avatar-tab {
    margin-bottom: 3px;
    margin-left: 5px;
    border-radius: 50%;
    color: white !important;
    font-size: 17px !important;
    width: 17px !important;
    height: 17px !important;
    min-width: 17px !important;
    min-height: 17px !important;
    line-height: 17px !important; }
  md-dialog.terminals-camera md-dialog-content {
    position: relative;
    max-height: 100%;
    min-height: 382px;
    padding: 0 !important;
    display: block;
    overflow-x: hidden;
    overflow-y: hidden; }
    md-dialog.terminals-camera md-dialog-content md-tabs.md-default-theme md-ink-bar, md-dialog.terminals-camera md-dialog-content md-tabs md-ink-bar {
      color: #3f51b5 !important;
      background-color: #3f51b5 !important; }
    md-dialog.terminals-camera md-dialog-content .info-header {
      color: white; }
    md-dialog.terminals-camera md-dialog-content .div-event-img {
      min-height: 235px; }
      md-dialog.terminals-camera md-dialog-content .div-event-img .event-img {
        z-index: 2; }
        md-dialog.terminals-camera md-dialog-content .div-event-img .event-img .ng-image-gallery-modal {
          height: 225px; }
      md-dialog.terminals-camera md-dialog-content .div-event-img .div-legend-img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 12px;
        z-index: 5;
        color: white;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.26); }
        md-dialog.terminals-camera md-dialog-content .div-event-img .div-legend-img * {
          color: white; }
        md-dialog.terminals-camera md-dialog-content .div-event-img .div-legend-img .notes {
          margin-right: 90px; }
      md-dialog.terminals-camera md-dialog-content .div-event-img .event-map-img {
        position: absolute;
        right: 0; }
    md-dialog.terminals-camera md-dialog-content .div-gps-img {
      position: absolute;
      opacity: 0.7;
      right: 0;
      padding: 5px;
      z-index: 10; }
    md-dialog.terminals-camera md-dialog-content .div-img-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.38);
      z-index: 2; }
      md-dialog.terminals-camera md-dialog-content .div-img-loading .div-icon-loading {
        margin: auto;
        display: inline-table; }
    md-dialog.terminals-camera md-dialog-content .terminal-configuration-fields {
      padding: 16px;
      margin-top: 15px; }
      md-dialog.terminals-camera md-dialog-content .terminal-configuration-fields .md-chips {
        box-shadow: none;
        font-size: 12px; }
      md-dialog.terminals-camera md-dialog-content .terminal-configuration-fields .terminalModel-info {
        font-size: 14px; }
        md-dialog.terminals-camera md-dialog-content .terminal-configuration-fields .terminalModel-info md-icon {
          margin-right: 5px; }
    md-dialog.terminals-camera md-dialog-content .list-item {
      padding-top: 10px;
      font-size: 14px; }
      md-dialog.terminals-camera md-dialog-content .list-item .title {
        font-size: 18px;
        font-weight: bold; }
        md-dialog.terminals-camera md-dialog-content .list-item .title .event-edit-button {
          margin-left: 0;
          padding-left: 0;
          font-size: 22px; }
      md-dialog.terminals-camera md-dialog-content .list-item .info {
        margin-left: 0;
        margin-right: 0; }
        md-dialog.terminals-camera md-dialog-content .list-item .info .notes md-icon {
          margin-left: 0;
          margin-right: 5px;
          color: white; }
      md-dialog.terminals-camera md-dialog-content .list-item .div-event-buttons button {
        margin-top: 4px;
        margin-bottom: 0; }
      md-dialog.terminals-camera md-dialog-content .list-item md-icon {
        margin-right: 5px;
        color: white; }
    md-dialog.terminals-camera md-dialog-content .div-map-img {
      padding: 6px;
      overflow: hidden; }
      md-dialog.terminals-camera md-dialog-content .div-map-img .div-gmaps {
        min-height: 350px; }
        md-dialog.terminals-camera md-dialog-content .div-map-img .div-gmaps .gmaps {
          overflow: hidden; }
      md-dialog.terminals-camera md-dialog-content .div-map-img .div-position-not-found {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: .7;
        background-color: #FFF;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
        md-dialog.terminals-camera md-dialog-content .div-map-img .div-position-not-found.loading {
          margin-bottom: -100px; }
        md-dialog.terminals-camera md-dialog-content .div-map-img .div-position-not-found > div {
          text-align: center;
          padding: 12px; }
    md-dialog.terminals-camera md-dialog-content .trackerReadings-list {
      height: 100%;
      padding-bottom: 36px;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      overflow-x: hidden;
      overflow-y: auto; }
      md-dialog.terminals-camera md-dialog-content .trackerReadings-list p {
        margin: 0; }
      md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group {
        height: auto;
        padding: 0 0 6px 0; }
        md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group:not(:last-of-type) {
          border-bottom: solid 1px rgba(0, 0, 0, 0.08); }
        md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals {
          min-width: 370px; }
          md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals .info .disabled {
            opacity: .4; }
          md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals > div {
            margin: 0 8px; }
            md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group .md-subheader .div-totals > div:first-of-type {
              margin-left: 0; }
        md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group .md-subheader .button-show-hide-trackerReadings {
          font-size: 13px; }
        md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group .md-subheader .md-subheader-inner {
          padding: 8px 16px 6px; }
        md-dialog.terminals-camera md-dialog-content .trackerReadings-list .div-group .div-trackerReadings-group {
          width: 100%;
          -webkit-flex-flow: row wrap;
          -moz-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          flex-flow: row wrap; }
    md-dialog.terminals-camera md-dialog-content .trackerReading-item {
      position: relative;
      height: auto !important;
      min-height: 20%;
      min-width: 345px !important;
      max-width: 345px;
      width: 345px;
      margin: 6px;
      padding: 8px 0 0;
      text-transform: none;
      cursor: pointer; }
      md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-event-img {
        min-height: 215px;
        max-width: 330px;
        width: 100%; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-event-img .event-img {
          z-index: 1; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-event-img:hover .event-img-png {
          display: block;
          z-index: 2; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-event-img .event-img-png {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 97%;
          display: none; }
          md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-event-img .event-img-png img {
            box-shadow: none; }
      md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item {
        color: #FFFFFF; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item .avatar {
          font-size: 14px; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item .info {
          min-width: 190px;
          max-width: 193px;
          margin-right: 4px; }
          md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item .info .secondary-title {
            margin-left: 5px;
            font-size: 12px !important; }
          md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item .info .notes md-icon {
            margin-right: 3px;
            color: #FFFFFF; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item .secondary-info md-icon {
          margin-right: 3px;
          color: #FFFFFF; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item .secondary-text md-icon {
          margin: 0;
          margin-right: 5px; }
        md-dialog.terminals-camera md-dialog-content .trackerReading-item .div-trackerReading-item .trackerReading-address {
          max-height: 35px;
          text-overflow: ellipsis;
          overflow: hidden; }
    md-dialog.terminals-camera md-dialog-content md-input-container {
      margin: 0; }
    md-dialog.terminals-camera md-dialog-content p {
      margin: 0; }
  md-dialog.terminals-camera md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

.selectTerminalModel .select-terminalModel-header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0; }

.selectTerminalModel .select-terminalModel-select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  padding: 0; }
  .selectTerminalModel .select-terminalModel-select-header md-input-container {
    width: 100%; }

@media screen and (min-width: 600px) {
  md-dialog.terminals-camera {
    min-height: 95%;
    max-height: 100%; }
    md-dialog.terminals-camera form {
      flex: 1 1 100%; }
    md-dialog.terminals-camera md-dialog-content {
      flex: 1 1 100%;
      display: flex; }
      md-dialog.terminals-camera md-dialog-content md-tabs {
        flex: 1 1 100%; }
  md-dialog.extended-width-height {
    min-width: 95% !important;
    max-width: 100% !important;
    min-height: 95% !important;
    max-height: 100% !important; }
    md-dialog.extended-width-height form {
      flex: 1 1 100% !important; }
    md-dialog.extended-width-height md-dialog-content {
      flex: 1 1 100% !important;
      display: flex; }
      md-dialog.extended-width-height md-dialog-content md-tabs {
        flex: 1 1 100% !important; } }

@media screen and (max-width: 960px) {
  md-dialog.terminals-camera md-dialog-content md-tabs {
    height: 100%; } }

md-dialog.terminals-move {
  overflow: hidden; }
  md-dialog.terminals-move md-toolbar .title {
    font-size: 17px; }
  md-dialog.terminals-move md-dialog-content {
    position: relative;
    min-height: 200px;
    max-height: 400px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.terminals-move md-dialog-content .div-terminal-terminals .header-terminal-terminals {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.terminals-move md-dialog-content .div-terminal-terminals .header-terminal-terminals > span {
        padding: 12px 0 0; }
    md-dialog.terminals-move md-dialog-content .div-terminal-terminals .div-terminal .terminal-item {
      cursor: move !important; }
    md-dialog.terminals-move md-dialog-content .div-edit-terminal {
      padding: 0 8px; }
  md-dialog.terminals-move md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }
  md-dialog.terminals-move .installation-terminals {
    height: 100%;
    background-color: whitesmoke; }
    md-dialog.terminals-move .installation-terminals .center .header {
      height: auto;
      background-color: #394b6d;
      color: #FFFFFF; }
      md-dialog.terminals-move .installation-terminals .center .header .search-wrapper {
        background: #fff; }
        md-dialog.terminals-move .installation-terminals .center .header .search-wrapper .div-search {
          height: 56px;
          min-height: 56px;
          padding: 18px; }
          md-dialog.terminals-move .installation-terminals .center .header .search-wrapper .div-search .input-search {
            height: 56px;
            padding-left: 16px;
            margin: 0;
            border-radius: 0;
            border: none;
            color: rgba(0, 0, 0, 0.54);
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none; }
      md-dialog.terminals-move .installation-terminals .center .header md-input-container > * {
        color: #fff !important; }
        md-dialog.terminals-move .installation-terminals .center .header md-input-container > *:focus {
          border-bottom-color: #fff; }
        md-dialog.terminals-move .installation-terminals .center .header md-input-container > *.md-input-focused {
          border-bottom-width: 1px !important; }
      md-dialog.terminals-move .installation-terminals .center .header md-checkbox {
        width: 20px;
        margin-bottom: 0; }
    md-dialog.terminals-move .installation-terminals .center .header-terminals-move {
      height: 64px;
      min-height: 64px !important;
      max-height: 64px;
      padding: 8px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      md-dialog.terminals-move .installation-terminals .center .header-terminals-move .div-total > md-icon {
        margin-left: 0;
        margin-right: 5px; }
    md-dialog.terminals-move .installation-terminals .center .terminals-list {
      height: 100%;
      padding-bottom: 36px;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      overflow-x: hidden; }
      md-dialog.terminals-move .installation-terminals .center .terminals-list p {
        margin: 0; }
      md-dialog.terminals-move .installation-terminals .center .terminals-list .md-subheader .md-subheader-inner {
        padding-top: 0; }
    md-dialog.terminals-move .installation-terminals .center .terminal-item {
      position: relative;
      height: auto !important;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      text-transform: none;
      cursor: pointer; }
      md-dialog.terminals-move .installation-terminals .center .terminal-item .avatar {
        font-size: 14px; }
      md-dialog.terminals-move .installation-terminals .center .terminal-item .info {
        margin: 0 16px 0 8px; }
        md-dialog.terminals-move .installation-terminals .center .terminal-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        md-dialog.terminals-move .installation-terminals .center .terminal-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
      md-dialog.terminals-move .installation-terminals .center .terminal-item .secondary-text md-icon {
        margin: 0; }

@media screen and (max-width: 600px) {
  md-dialog.terminals-move {
    width: 100%; }
    md-dialog.terminals-move md-autocomplete md-autocomplete-wrap > md-input-container {
      margin-top: 0; }
    md-dialog.terminals-move md-autocomplete md-autocomplete-wrap > button {
      top: 0 !important; }
    md-dialog.terminals-move .div-input-terminals > :not(:first-child) {
      margin-top: 0; }
    md-dialog.terminals-move .div-autocomplete,
    md-dialog.terminals-move .input-date {
      max-height: 35px; }
    md-dialog.terminals-move .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.terminals-move .div-autocomplete:first-child {
        margin-top: 0; }
    md-dialog.terminals-move md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.terminals-move {
    min-width: 600px; }
    md-dialog.terminals-move .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.terminals-move md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.terminals-move md-dialog-content .div-terminal-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.terminals-move {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.terminals-move {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.terminals-move {
    width: 90%; }
    md-dialog.terminals-move .installation-terminals .header .div-search {
      border-left: 1px solid rgba(0, 0, 0, 0.12); }
    md-dialog.terminals-move .installation-terminals .center .header {
      padding-left: 16px; } }

md-dialog.user-edit {
  overflow: hidden; }
  md-dialog.user-edit md-toolbar .title {
    font-size: 17px; }
  md-dialog.user-edit md-dialog-content {
    position: relative;
    min-height: 320px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.user-edit md-dialog-content .div-user-centroids .header-user-centroids {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.user-edit md-dialog-content .div-user-centroids .header-user-centroids > span {
        padding: 12px 0 0; }
    md-dialog.user-edit md-dialog-content .div-user-centroids .div-user .user-item {
      cursor: move !important; }
    md-dialog.user-edit md-dialog-content .div-edit-user {
      padding: 0 8px; }
    md-dialog.user-edit md-dialog-content .div-chips-search-autocomplete .chips-search-header md-icon {
      margin: 0; }
    md-dialog.user-edit md-dialog-content .div-chips-search-autocomplete .chips-search-header span {
      padding: 3px; }
    md-dialog.user-edit md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.user-edit md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.user-edit md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.user-edit md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.user-edit md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.user-edit md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }
  md-dialog.user-edit .md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap md-input-container {
    margin: 5px 5px 0 0; }

.div-user-centroids.as-sortable-dragging {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  opacity: .8 !important; }

@media screen and (max-width: 600px) {
  md-dialog.user-edit {
    width: 100%;
    height: 100%; }
    md-dialog.user-edit .div-input-centroids > :not(:first-child) {
      margin-top: 0; }
    md-dialog.user-edit .div-autocomplete,
    md-dialog.user-edit .input-date {
      max-height: 35px; }
    md-dialog.user-edit .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.user-edit .div-autocomplete:first-child,
      md-dialog.user-edit .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.user-edit .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.user-edit md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.user-edit {
    min-width: 600px; }
    md-dialog.user-edit .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.user-edit md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.user-edit md-dialog-content .div-user-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.user-edit {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.user-edit {
    max-width: 720px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.user-edit {
    width: 90%; } }

md-dialog.user-password {
  overflow: hidden; }
  md-dialog.user-password md-toolbar .title {
    font-size: 17px; }
  md-dialog.user-password md-dialog-content {
    position: relative;
    min-height: 200px;
    padding: 16px;
    display: block;
    overflow-x: hidden; }
    md-dialog.user-password md-dialog-content .div-user-centroids .header-user-centroids {
      height: 46px;
      min-height: 46px;
      width: 108%;
      margin-bottom: 12px;
      margin-left: -20px;
      padding: 12px 16px; }
      md-dialog.user-password md-dialog-content .div-user-centroids .header-user-centroids > span {
        padding: 12px 0 0; }
    md-dialog.user-password md-dialog-content .div-user-centroids .div-user .user-item {
      cursor: move !important; }
    md-dialog.user-password md-dialog-content .div-edit-user {
      padding: 0 8px; }
    md-dialog.user-password md-dialog-content md-input-container md-icon {
      color: rgba(0, 0, 0, 0.54); }
    md-dialog.user-password md-dialog-content md-input-container > label {
      padding-left: 0 !important; }
    md-dialog.user-password md-dialog-content mdp-time-picker > div > button {
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      md-dialog.user-password md-dialog-content mdp-time-picker > div > button md-icon {
        margin-left: 0 !important;
        margin-right: 0 !important; }
    md-dialog.user-password md-dialog-content mdp-time-picker > div md-input-container {
      margin-left: 0 !important;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      flex: 1; }
  md-dialog.user-password md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.03); }

@media screen and (max-width: 600px) {
  md-dialog.user-password {
    width: 100%;
    height: 100%; }
    md-dialog.user-password .div-autocomplete,
    md-dialog.user-password .input-date {
      max-height: 35px; }
    md-dialog.user-password .div-autocomplete {
      padding-left: 3px;
      margin: 18px 0; }
      md-dialog.user-password .div-autocomplete:first-child,
      md-dialog.user-password .div-autocomplete md-input-container {
        margin-top: 0; }
      md-dialog.user-password .div-autocomplete md-input-container + button {
        top: 5px; }
    md-dialog.user-password md-input-container {
      max-height: 70px; } }

@media screen and (min-width: 600px) {
  md-dialog.user-password {
    min-width: 400px; }
    md-dialog.user-password .div-autocomplete:not(:first-child) {
      margin-left: 10px; }
    md-dialog.user-password md-input-container:not(:first-child) {
      margin-left: 10px; }
    md-dialog.user-password md-dialog-content .div-user-inputs {
      max-height: 70px; } }

@media screen and (max-width: 1280px) {
  md-dialog.user-password {
    width: 80%; } }

@media screen and (min-width: 960px) {
  md-dialog.user-password {
    max-width: 500px;
    width: 90%; } }

@media screen and (max-width: 960px) {
  md-dialog.user-password {
    width: 90%; } }

md-dialog.terminalsImportConfiguration-list {
  max-width: 420px;
  width: 420px;
  overflow: hidden; }
  md-dialog.terminalsImportConfiguration-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.terminalsImportConfiguration-list md-dialog-content {
    position: relative;
    min-height: 200px;
    display: block; }
    md-dialog.terminalsImportConfiguration-list md-dialog-content .list-item md-icon {
      margin-right: 5px; }
  md-dialog.terminalsImportConfiguration-list md-toolbar {
    min-height: 52px;
    height: 52px; }
    md-dialog.terminalsImportConfiguration-list md-toolbar .title {
      padding: 18px 0 0;
      font-size: 17px; }
    md-dialog.terminalsImportConfiguration-list md-toolbar md-input-container {
      margin-bottom: 0; }
      md-dialog.terminalsImportConfiguration-list md-toolbar md-input-container > * {
        color: #fff !important; }
      md-dialog.terminalsImportConfiguration-list md-toolbar md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.terminalsImportConfiguration-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

md-dialog.terminalModels-select-list {
  max-width: 420px;
  width: 420px;
  overflow: hidden; }
  md-dialog.terminalModels-select-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.terminalModels-select-list md-dialog-content {
    position: relative;
    min-height: 200px;
    display: block; }
    md-dialog.terminalModels-select-list md-dialog-content .list-item md-icon {
      margin-right: 5px; }
    md-dialog.terminalModels-select-list md-dialog-content .md-chips {
      box-shadow: none; }
  md-dialog.terminalModels-select-list md-toolbar {
    min-height: 52px;
    height: 52px; }
    md-dialog.terminalModels-select-list md-toolbar .title {
      padding: 18px 0 0;
      font-size: 17px; }
    md-dialog.terminalModels-select-list md-toolbar md-input-container {
      margin-bottom: 0; }
      md-dialog.terminalModels-select-list md-toolbar md-input-container > * {
        color: #fff !important; }
      md-dialog.terminalModels-select-list md-toolbar md-input-container input {
        border-bottom-color: #fff !important; }
  md-dialog.terminalModels-select-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  md-dialog.terminalModels-select-list .list-item .avatar {
    font-size: 10px; }

/**
* Copyright 2015 Google Inc. All Rights Reserved.
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*      http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
.admin {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  width: 100%;
  height: 100%;
  /* iOS Safari specific workaround */
  /* END iOS Safari specific workaround */
  /* TODO: Find a proper solution to have the graphs
  * not float around outside their container in IE10/11.
  * Using a browserhacks.com solution for now.
  */
  /* TODO end */ }
  .admin__section {
    width: 100%;
    padding-left: 0;
    box-sizing: border-box; }
  .admin__link {
    border-radius: 0 !important; }
    .admin__link__icon {
      margin-right: 5px; }
  .admin__arrow {
    transition: transform 0.5s; }
    .admin__arrow--rotated {
      transform: rotate(-180deg); }
  .admin__sublist {
    background: #37474F;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s; }
    .admin__sublist--opened {
      max-height: 440px; }
    .admin__sublist .md-button {
      text-transform: none !important;
      padding-left: 20px !important;
      box-sizing: border-box; }
  .admin .mdl-layout__header {
    min-height: 52px; }
  .admin .mdl-layout__header-row {
    height: 45px; }
  .admin .mdl-layout__drawer {
    width: 240px; }
  .admin .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 10px; }
  .admin .mdl-layout__content {
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden; }
  .admin .demo-avatar {
    width: 48px;
    height: 48px;
    border-radius: 24px; }
  .admin .demo-layout .demo-header .mdl-textfield {
    padding: 0;
    margin-top: 41px; }
  .admin .demo-layout .demo-header .mdl-textfield .mdl-textfield__expandable-holder {
    bottom: 19px; }
  .admin .demo-layout .mdl-layout__header .mdl-layout__drawer-button {
    color: rgba(0, 0, 0, 0.54); }
  .admin .mdl-layout__drawer .avatar {
    margin-bottom: 16px; }
  .admin .demo-drawer {
    border: none;
    overflow-x: hidden;
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -ms-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out; }
    .admin .demo-drawer.side-nav-closed {
      width: 50px;
      z-index: 10; }
      .admin .demo-drawer.side-nav-closed .demo-drawer-header {
        padding-left: 5px;
        padding-right: 5px; }
  .admin .main-content {
    margin-left: 0;
    -webkit-transition: margin-left 0.3s ease-in-out;
    -moz-transition: margin-left 0.3s ease-in-out;
    -ms-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out; }
    .admin .main-content.side-nav-closed {
      margin-left: 50px !important; }
  .admin .div-button-side-nav-collapse {
    position: absolute;
    top: 8px;
    left: 190px;
    width: 50px;
    z-index: 9; }
    .admin .div-button-side-nav-collapse button {
      min-width: auto;
      padding: 4px;
      background-color: #263238;
      color: #FFF !important; }
    .admin .div-button-side-nav-collapse.side-nav-closed {
      left: 40px; }
  .admin .demo-drawer .mdl-menu__container {
    z-index: -1; }
  .admin .demo-drawer .demo-navigation {
    z-index: -2; }
  .admin .demo-drawer .mdl-menu .mdl-menu__item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .admin .demo-drawer-header {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px; }
  .admin .demo-avatar-dropdown {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%; }
  .admin .demo-navigation {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
  .admin .demo-layout .demo-navigation .mdl-navigation__link {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;
    font-weight: 500; }
  .admin .demo-layout .demo-navigation .mdl-navigation__link:hover {
    background-color: #00BCD4;
    color: white; }
  .admin .demo-layout .demo-navigation .mdl-navigation__link--active {
    background-color: #607D8B;
    color: white; }
  .admin .demo-layout .demo-navigation .mdl-navigation__link--subactive {
    background-color: #90A4AE;
    color: white; }
  .admin .demo-navigation .mdl-navigation__link .material-icons {
    font-size: 24px;
    color: white; }
  .admin .demo-content {
    height: 100%;
    padding: 0 !important; }
  .admin .demo-charts {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center; }
  .admin .demo-chart:nth-child(1) {
    color: #ACEC00; }
  .admin .demo-chart:nth-child(2) {
    color: #00BBD6; }
  .admin .demo-chart:nth-child(3) {
    color: #BA65C9; }
  .admin .demo-chart:nth-child(4) {
    color: #EF3C79; }
  .admin .demo-graphs {
    padding: 16px 32px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
  .admin _:-ms-input-placeholder, .admin :root .demo-graphs {
    min-height: 664px; }
  .admin _:-ms-input-placeholder, .admin :root .demo-graph {
    max-height: 300px; }
  .admin .demo-graph:nth-child(1) {
    color: #00b9d8; }
  .admin .demo-graph:nth-child(2) {
    color: #d9006e; }
  .admin .demo-cards {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start; }
  .admin .demo-cards .demo-separator {
    height: 32px; }
  .admin .demo-cards .mdl-card__title.mdl-card__title {
    color: white;
    font-size: 24px;
    font-weight: 400; }
  .admin .demo-cards ul {
    padding: 0; }
  .admin .demo-cards h3 {
    font-size: 1em; }
  .admin .demo-updates .mdl-card__title {
    background-position: 90% 100%;
    background-repeat: no-repeat; }
  .admin .demo-cards .mdl-card__actions a {
    color: #00BCD4;
    text-decoration: none; }
  .admin .demo-options h3 {
    margin: 0; }
  .admin .demo-options .mdl-checkbox__box-outline {
    border-color: white; }
  .admin .demo-options ul {
    margin: 0;
    list-style-type: none; }
  .admin .demo-options li {
    margin: 4px 0; }
  .admin .demo-options .material-icons {
    color: white; }
  .admin .demo-options .mdl-card__actions {
    height: 64px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .admin .mdl-textfield--expandable .mdl-button--icon {
    top: -20px; }

@media screen and (min-width: 960px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@media screen and (max-width: 960px) {
  .main-content {
    margin-left: 0 !important; } }

@media print {
  .admin .mdl-layout {
    overflow: visible !important; }
  .admin .mdl-layout__content {
    overflow: visible !important; } }

#toolbar {
  height: 54px;
  min-height: 54px;
  max-height: 54px;
  z-index: 55 !important; }
  #toolbar #navigation-toggle {
    width: 54px;
    min-width: 54px;
    height: 54px;
    margin: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0; }
  #toolbar .div-branch-organization-button button {
    margin: 0; }
  #toolbar .user-menu {
    height: 54px;
    padding: 0; }
    #toolbar .user-menu md-menu-item .md-button {
      font-size: 15px; }
      #toolbar .user-menu md-menu-item .md-button md-icon {
        margin: auto 16px auto 0; }
    #toolbar .user-menu .user-button {
      margin: 0;
      padding: 0 16px;
      height: 54px;
      text-transform: none; }
      #toolbar .user-menu .user-button .avatar-wrapper {
        position: relative; }
        #toolbar .user-menu .user-button .avatar-wrapper .status {
          position: absolute;
          bottom: -3px;
          right: 5px; }
      #toolbar .user-menu .user-button .username {
        margin: 0 12px 0 8px; }
    #toolbar .user-menu .logout-second-user {
      min-width: 10px; }
  #toolbar .toolbar-separator {
    height: 54px;
    width: 1px;
    background: rgba(0, 0, 0, 0.12); }

@media screen and (max-width: 600px) {
  #toolbar .user-menu .user-button {
    min-width: 64px;
    padding: 0 2px 0 10px; } }

.platform {
  background: #F0F1F2;
  color: #2C2C2C;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 100%; }

.index {
  position: relative; }
  .index__img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 293px;
    height: 169px; }

.login__card {
  background: white;
  border: solid 1px #e8e1e1;
  border-radius: 5px;
  box-shadow: 0 0 5px #e0e0e0;
  width: 25em;
  height: 27em;
  padding: 2em;
  box-sizing: border-box;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; }
  .login__card__center {
    text-align: center; }
  .login__card__logo {
    text-align: center;
    margin-bottom: 2em; }
    .login__card__logo__img {
      width: 50%; }

#nav-bar-menu-bar .admin__link md-icon + span {
  vertical-align: middle; }

.menu-content-user-menu .div-username {
  padding: 0 16px; }

.dashboard {
  width: 100%;
  height: 100%;
  position: relative; }
  .dashboard__map {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0; }
    .dashboard__map .centroid-marker-info-window .centroid-name,
    .dashboard__map .centroid-marker-info-window .centroid-address {
      margin: 0; }
    .dashboard__map .centroid-marker-info-window .centroid-address {
      font-size: 13px; }
    .dashboard__map .centroid-marker-info-window .div-button button {
      margin-bottom: 0; }
  .dashboard #num {
    border: 1px;
    border-color: black;
    font-size: 25px;
    font-weight: bolder;
    position: absolute;
    left: 0;
    top: 55%;
    height: 200px;
    width: 100%;
    text-align: center;
    z-index: 100; }
  .dashboard__infobutton {
    bottom: 85px;
    right: 0;
    line-height: 35px !important;
    width: 35px !important;
    height: 35px !important;
    position: absolute;
    font-size: 1.5em; }
  .dashboard__labels {
    font-family: "Verdana", "Arial", sans-serif;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    width: 40px;
    white-space: nowrap;
    line-height: 11px; }
    .dashboard__labels__space {
      height: 6px; }
    .dashboard__labels .dashboard-organization-name.name-length-6 {
      font-size: 9.5px; }
  .dashboard__button {
    position: fixed;
    bottom: 20px;
    right: 50px;
    -webkit-transition: right 0.3s ease-out;
    -moz-transition: right 0.3s ease-out;
    -ms-transition: right 0.3s ease-out;
    -o-transition: right 0.3s ease-out;
    transition: right 0.3s ease-out; }
    .dashboard__button.right-side-nav-open {
      right: 320px; }

@media (max-width: 800px) {
  .dashboard__panel__card {
    padding: 0; } }

@media print {
  .dashboard {
    overflow: visible !important; }
    .dashboard * {
      -webkit-transition: none !important;
      transition: none !important; }
    .dashboard__map {
      display: none; } }

#allocations-report {
  background-color: whitesmoke; }
  #allocations-report .detail-resize {
    width: 72%; }
  #allocations-report .main-sidenav.open {
    z-index: 60; }
  #allocations-report md-sidenav {
    max-width: 420px;
    width: 420px;
    background-color: #fafafa; }
    #allocations-report md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #allocations-report md-sidenav .header .logo {
        padding-top: 27px; }
        #allocations-report md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #allocations-report md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #allocations-report md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #allocations-report md-sidenav md-content .item {
      cursor: pointer; }
      #allocations-report md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #allocations-report md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #allocations-report .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #allocations-report .center .header .search-wrapper {
      background: #fff; }
      #allocations-report .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #allocations-report .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #allocations-report .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #allocations-report .center .header {
    padding-left: 16px; } }

@media print {
  #allocations-report {
    position: absolute;
    display: block;
    overflow: visible !important; }
    #allocations-report md-content {
      overflow: visible !important; }
    #allocations-report .md-sidenav-right {
      max-width: 100%;
      width: 100%;
      display: block;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } }

#allocations-pro-data-report {
  background-color: whitesmoke; }
  #allocations-pro-data-report .detail-resize {
    width: 72%; }
  #allocations-pro-data-report .main-sidenav.open {
    z-index: 60; }
  #allocations-pro-data-report md-sidenav {
    max-width: 380px;
    width: 380px;
    background-color: #fafafa; }
    #allocations-pro-data-report md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #allocations-pro-data-report md-sidenav .header .logo {
        padding-top: 27px; }
        #allocations-pro-data-report md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #allocations-pro-data-report md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #allocations-pro-data-report md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #allocations-pro-data-report md-sidenav md-content .item {
      cursor: pointer; }
      #allocations-pro-data-report md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
  #allocations-pro-data-report md-fab-speed-dial md-fab-actions .md-fab-action-item button span {
    font-size: 12px; }
  #allocations-pro-data-report .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #allocations-pro-data-report .center .header .search-wrapper {
      background: #fff; }
      #allocations-pro-data-report .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #allocations-pro-data-report .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }
    #allocations-pro-data-report .center .header .print-wrapper {
      margin: 150px 0 0 10px; }
      #allocations-pro-data-report .center .header .print-wrapper .div-icon-loading md-progress-circular.md-default-theme path,
      #allocations-pro-data-report .center .header .print-wrapper .div-icon-loading md-progress-circular path {
        stroke: white; }
    #allocations-pro-data-report .center .header .print-btn md-icon {
      color: #FFFFFF; }
    #allocations-pro-data-report .center .header .print-btn:disabled md-icon {
      color: gray; }

@media screen and (max-width: 960px) {
  #allocations-pro-data-report .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #allocations-pro-data-report .center .header {
    padding-left: 16px; }
    #allocations-pro-data-report .center .header .print-btn md-icon {
      color: #FFFFFF; } }

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important; }
  .content-card {
    -webkit-print-color-adjust: economy !important; }
  #allocations-pro-data-report {
    -webkit-print-color-adjust: economy !important;
    position: absolute;
    display: block;
    overflow: visible !important; }
    #allocations-pro-data-report md-content {
      overflow: visible !important; }
    #allocations-pro-data-report .md-sidenav-right {
      max-width: 100%;
      width: 100%;
      display: block;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } }

#allocations {
  background-color: whitesmoke; }
  #allocations .main-sidenav.open {
    z-index: 60; }
  #allocations md-sidenav {
    background-color: #fafafa; }
    #allocations md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #allocations md-sidenav .header .logo {
        padding-top: 27px; }
        #allocations md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #allocations md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #allocations md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #allocations md-sidenav md-content .item {
      cursor: pointer; }
      #allocations md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #allocations md-sidenav .content {
      padding: 12px 0; }
    #allocations md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #allocations .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #allocations .center .header .search-wrapper {
      background: #fff; }
      #allocations .center .header .search-wrapper .div-search {
        height: 56px;
        line-height: 56px;
        padding: 18px; }
        #allocations .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #allocations .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #allocations .center .header {
    padding-left: 16px; } }

#allocationMonitrips-report {
  background-color: whitesmoke; }
  #allocationMonitrips-report .detail-resize {
    width: 72%; }
  #allocationMonitrips-report .main-sidenav.open {
    z-index: 60; }
  #allocationMonitrips-report md-sidenav {
    max-width: 380px;
    width: 380px;
    background-color: #fafafa; }
    #allocationMonitrips-report md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #allocationMonitrips-report md-sidenav .header .logo {
        padding-top: 27px; }
        #allocationMonitrips-report md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #allocationMonitrips-report md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #allocationMonitrips-report md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #allocationMonitrips-report md-sidenav md-content .item {
      cursor: pointer; }
      #allocationMonitrips-report md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #allocationMonitrips-report md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #allocationMonitrips-report .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #allocationMonitrips-report .center .header .search-wrapper {
      background: #fff; }
      #allocationMonitrips-report .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #allocationMonitrips-report .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #allocationMonitrips-report .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #allocationMonitrips-report .center .header {
    padding-left: 16px; } }

@media print {
  #allocationMonitrips-report {
    position: absolute;
    display: block;
    overflow: visible !important; }
    #allocationMonitrips-report md-content {
      overflow: visible !important; }
    #allocationMonitrips-report .md-sidenav-right {
      max-width: 100%;
      width: 100%;
      display: block;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } }

#centroids-report {
  background-color: whitesmoke; }
  #centroids-report .detail-resize {
    width: 72%; }
  #centroids-report .main-sidenav.open {
    z-index: 60; }
  #centroids-report md-sidenav {
    background-color: #fafafa;
    width: 500px;
    max-width: 500px; }
    #centroids-report md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #centroids-report md-sidenav .header .logo {
        padding-top: 27px; }
        #centroids-report md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #centroids-report md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #centroids-report md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #centroids-report md-sidenav md-content .item {
      cursor: pointer; }
      #centroids-report md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #centroids-report md-sidenav md-content .md-subheader.md-primary {
      color: rgba(0, 0, 0, 0.56); }
    #centroids-report md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #centroids-report .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #centroids-report .center .header .search-wrapper {
      background: #fff; }
      #centroids-report .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #centroids-report .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }
    #centroids-report .center .header .print-wrapper {
      padding-top: 50px;
      margin-left: 8px; }
      #centroids-report .center .header .print-wrapper md-fab-trigger button {
        min-width: 50px;
        max-width: 50px;
        margin: 0;
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out; }
        #centroids-report .center .header .print-wrapper md-fab-trigger button:disabled {
          opacity: .4; }
  #centroids-report .center .centroids-report-tabs .div-tab-label .div-icon-loading {
    margin-top: 4px;
    margin-left: 8px; }
  #centroids-report .center .centroids-report-tabs md-tab-content {
    overflow-y: hidden; }

@media screen and (max-width: 960px) {
  #events .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #events .center .header {
    padding-left: 16px; } }

@media print {
  #centroids-report .center .centroids-report-tabs .div-tab-label md-tab-content {
    overflow-y: auto; } }

#centroids {
  background-color: whitesmoke; }
  #centroids .main-sidenav.open {
    z-index: 60; }
  #centroids md-sidenav {
    background-color: #fafafa; }
    #centroids md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #centroids md-sidenav .header .logo {
        padding-top: 27px; }
        #centroids md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #centroids md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #centroids md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #centroids md-sidenav md-content .item {
      cursor: pointer; }
      #centroids md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #centroids md-sidenav .content {
      padding: 12px 0; }
    #centroids md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #centroids .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #centroids .center .header .search-wrapper {
      background: #fff; }
      #centroids .center .header .search-wrapper .div-search {
        height: 56px;
        centroid-height: 56px;
        padding: 18px; }
        #centroids .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #centroids .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #centroids .center .header {
    padding-left: 16px; } }

#contracts {
  background-color: whitesmoke; }
  #contracts .detail-resize {
    width: 72%; }
  #contracts .main-sidenav.open {
    z-index: 60; }
  #contracts md-sidenav {
    background-color: #fafafa;
    width: 500px;
    max-width: 500px; }
    #contracts md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #contracts md-sidenav .header .logo {
        padding-top: 27px; }
        #contracts md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #contracts md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #contracts md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #contracts md-sidenav md-content .item {
      cursor: pointer; }
      #contracts md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #contracts md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #contracts .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #contracts .center .header .search-wrapper {
      background: #fff; }
      #contracts .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #contracts .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #contracts .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #contracts .center .header {
    padding-left: 16px; } }

#lines {
  background-color: whitesmoke; }
  #lines .main-sidenav.open {
    z-index: 60; }
  #lines md-sidenav {
    background-color: #fafafa; }
    #lines md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #lines md-sidenav .header .logo {
        padding-top: 27px; }
        #lines md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #lines md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #lines md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #lines md-sidenav md-content .item {
      cursor: pointer; }
      #lines md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #lines md-sidenav .content {
      padding: 12px 0; }
    #lines md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #lines .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #lines .center .header .search-wrapper {
      background: #fff; }
      #lines .center .header .search-wrapper .div-search {
        height: 56px;
        line-height: 56px;
        padding: 18px; }
        #lines .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #lines .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #lines .center .header {
    padding-left: 16px; } }

#employees {
  background-color: whitesmoke; }
  #employees .main-sidenav.open {
    z-index: 60; }
  #employees md-sidenav {
    background-color: #fafafa; }
    #employees md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #employees md-sidenav .header .logo {
        padding-top: 27px; }
        #employees md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #employees md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #employees md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #employees md-sidenav md-content .item {
      cursor: pointer; }
      #employees md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #employees md-sidenav .content {
      padding: 12px 0; }
    #employees md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #employees .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #employees .center .header .search-wrapper {
      background: #fff; }
      #employees .center .header .search-wrapper .div-search {
        height: 56px;
        employee-height: 56px;
        padding: 18px; }
        #employees .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #employees .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #employees .center .header {
    padding-left: 16px; } }

.assessment {
  display: flex;
  padding-top: 5px;
  width: 100%;
  flex-wrap: wrap;
  position: relative; }
  .assessment__loading {
    background: rgba(245, 245, 245, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000; }
    .assessment__loading__spinner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto; }
  .assessment__text {
    width: 60%; }
    .assessment__text__row {
      display: flex;
      line-height: 30px; }
      .assessment__text__row__option {
        display: flex;
        justify-content: space-between; }
      .assessment__text__row__title {
        width: 35%;
        font-weight: bold; }
      .assessment__text__row__options {
        width: 60%; }
      .assessment__text__row__actions {
        margin-top: 40px; }
  .assessment__images {
    width: 40%; }
    .assessment__images__title {
      font-weight: bold;
      line-height: 40px; }
      .assessment__images__title__info {
        font-weight: normal; }
    .assessment__images__wrapper {
      width: 320px;
      margin-bottom: 4px; }
      .assessment__images__wrapper__img {
        border: solid 1px #455A64;
        max-width: 100%;
        position: relative;
        z-index: 2; }
  .assessment__footer {
    width: 100%;
    margin-top: 20px; }
    .assessment__footer__buttons {
      padding-top: 10px;
      display: flex;
      align-items: center; }

#modelOperationLogs {
  background-color: whitesmoke; }
  #modelOperationLogs .detail-resize {
    width: 72%; }
  #modelOperationLogs .main-sidenav.open {
    z-index: 60; }
  #modelOperationLogs md-sidenav {
    background-color: #fafafa;
    width: 500px;
    max-width: 500px; }
    #modelOperationLogs md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #modelOperationLogs md-sidenav .header .logo {
        padding-top: 27px; }
        #modelOperationLogs md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #modelOperationLogs md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #modelOperationLogs md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #modelOperationLogs md-sidenav md-content .item {
      cursor: pointer; }
      #modelOperationLogs md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #modelOperationLogs md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #modelOperationLogs .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #modelOperationLogs .center .header .search-wrapper {
      background: #fff; }
      #modelOperationLogs .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #modelOperationLogs .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #modelOperationLogs .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #modelOperationLogs .center .header {
    padding-left: 16px; } }

.violation {
  display: flex;
  padding-top: 5px;
  width: 100%;
  flex-wrap: wrap;
  position: relative; }
  .violation__loading {
    background: rgba(245, 245, 245, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000; }
    .violation__loading__spinner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto; }
  .violation__text {
    width: 60%; }
    .violation__text__row {
      display: flex;
      line-height: 30px; }
      .violation__text__row__option {
        display: flex;
        justify-content: space-between; }
      .violation__text__row__title {
        width: 35%;
        font-weight: bold; }
      .violation__text__row__options {
        width: 60%; }
      .violation__text__row__actions {
        margin-top: 40px; }
  .violation__images {
    width: 40%; }
    .violation__images__title {
      font-weight: bold;
      line-height: 40px; }
      .violation__images__title__info {
        font-weight: normal; }
    .violation__images__wrapper {
      width: 320px;
      margin-bottom: 4px; }
      .violation__images__wrapper__img {
        border: solid 1px #455A64;
        max-width: 100%;
        position: relative;
        z-index: 2; }
  .violation__footer {
    width: 100%;
    margin-top: 20px; }
    .violation__footer__buttons {
      padding-top: 10px;
      display: flex;
      align-items: center; }
      .violation__footer__buttons__left {
        text-align: left; }
      .violation__footer__buttons__center {
        text-align: center; }
      .violation__footer__buttons__right {
        text-align: right; }

#organizations {
  background-color: whitesmoke; }
  #organizations .main-sidenav.open {
    z-index: 60; }
  #organizations md-sidenav {
    background-color: #fafafa; }
    #organizations md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #organizations md-sidenav .header .logo {
        padding-top: 27px; }
        #organizations md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #organizations md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #organizations md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #organizations md-sidenav md-content .item {
      cursor: pointer; }
      #organizations md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #organizations md-sidenav .content {
      padding: 12px 0; }
    #organizations md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #organizations .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #organizations .center .header .search-wrapper {
      background: #fff; }
      #organizations .center .header .search-wrapper .div-search {
        height: 56px;
        line-height: 56px;
        padding: 18px; }
        #organizations .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #organizations .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #organizations .center .header {
    padding-left: 16px; } }

.anomaly-report__row:hover {
  background: #E8EAF6;
  cursor: pointer; }

.anomaly-report__filters-buttons {
  text-align: right; }

.anomaly-report .filters-frame {
  background-color: #FFFFFF; }
  .anomaly-report .filters-frame .md-input-date {
    margin-left: 10px;
    margin-right: 10px;
    width: 120px; }
  .anomaly-report .filters-frame .filters {
    height: 50px; }

.anomaly-report .page-select.ng-scope {
  width: auto; }

.anomaly-report .center-align {
  text-align: center !important; }

.anomaly-report .limit-select.ng-scope {
  width: auto; }

.anomaly-report .table.md-table td.md-cell, .anomaly-report table.md-table th.md-column {
  vertical-align: middle;
  text-align: center; }

.anomaly-report__assessment {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  flex-wrap: wrap; }
  .anomaly-report__assessment__text {
    width: 60%; }
    .anomaly-report__assessment__text__row {
      display: flex;
      line-height: 50px; }
      .anomaly-report__assessment__text__row__title {
        width: 40%;
        font-weight: bold; }
  .anomaly-report__assessment__images {
    width: 40%; }
    .anomaly-report__assessment__images__title {
      font-weight: bold;
      line-height: 30px; }
      .anomaly-report__assessment__images__title__info {
        font-weight: normal; }
    .anomaly-report__assessment__images__wrapper {
      padding-bottom: 10px; }
      .anomaly-report__assessment__images__wrapper__img {
        border: solid 1px #455A64;
        max-width: 100%;
        max-height: 240px;
        position: relative;
        z-index: 2; }

.custom-dialog {
  max-height: 100%;
  width: 55%; }

.assessment-report__row:hover {
  background: #E8EAF6;
  cursor: pointer; }

.assessment-report__filters-buttons {
  text-align: right; }

.assessment-report .filters-frame {
  background-color: #FFFFFF; }
  .assessment-report .filters-frame .md-input-date {
    margin-left: 10px;
    margin-right: 10px;
    width: 120px; }
  .assessment-report .filters-frame .filters {
    height: 50px; }

.assessment-report .page-select.ng-scope {
  width: auto; }

.assessment-report .center-align {
  text-align: center !important; }

.assessment-report .limit-select.ng-scope {
  width: auto; }

.assessment-report .table.md-table td.md-cell, .assessment-report table.md-table th.md-column {
  vertical-align: middle;
  text-align: center; }

.assessment-report__assessment {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  flex-wrap: wrap; }
  .assessment-report__assessment__text {
    width: 60%; }
    .assessment-report__assessment__text__row {
      display: flex;
      line-height: 35px; }
      .assessment-report__assessment__text__row__title {
        width: 40%;
        font-weight: bold; }
      .assessment-report__assessment__text__row__separator {
        width: 95%; }
  .assessment-report__assessment__images {
    width: 40%; }
    .assessment-report__assessment__images__title {
      font-weight: bold;
      line-height: 30px; }
      .assessment-report__assessment__images__title__info {
        font-weight: normal; }
    .assessment-report__assessment__images__wrapper {
      padding-bottom: 10px; }
      .assessment-report__assessment__images__wrapper__img {
        border: solid 1px #455A64;
        max-width: 100%;
        max-height: 240px;
        position: relative;
        z-index: 2; }

.custom-dialog {
  max-height: 100%;
  width: 55%; }

.event-report__row:hover {
  background: #E8EAF6;
  cursor: pointer; }

.event-report__filters-buttons {
  text-align: right; }

.event-report .filters-frame {
  background-color: #FFFFFF; }
  .event-report .filters-frame .md-input-date .md-errors-spacer {
    display: none; }
  .event-report .filters-frame .filters {
    height: 50px; }

.event-report .page-select.ng-scope {
  width: auto; }

.event-report .limit-select.ng-scope {
  width: auto; }

.event-report table.md-table td.md-cell,
.event-report table.md-table th.md-column {
  padding-right: 0 !important;
  vertical-align: middle;
  text-align: left !important; }

.event-report table.md-table .td-event-type {
  width: 120px; }

.event-report table.md-table .td-event-time,
.event-report table.md-table .td-event-type,
.event-report table.md-table .td-centroid {
  font-size: 14px; }

.event-report__assessment {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  flex-wrap: wrap; }
  .event-report__assessment__text {
    width: 60%; }
    .event-report__assessment__text__row {
      display: flex;
      line-height: 50px; }
      .event-report__assessment__text__row__title {
        width: 40%;
        font-weight: bold; }
  .event-report__assessment__images {
    width: 40%; }
    .event-report__assessment__images__title {
      font-weight: bold;
      line-height: 30px; }
      .event-report__assessment__images__title__info {
        font-weight: normal; }
    .event-report__assessment__images__wrapper {
      padding-bottom: 10px; }
      .event-report__assessment__images__wrapper__img {
        border: solid 1px #455A64;
        max-width: 100%;
        max-height: 240px;
        position: relative;
        z-index: 2; }

.custom-dialog {
  max-height: 100%;
  width: 55%; }

.service-report {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: whitesmoke; }
  .service-report > * {
    width: 100%;
    height: 100%; }
  .service-report .service-filters {
    position: absolute !important;
    left: 12px;
    top: 12px;
    max-height: 300px;
    min-width: 220px;
    padding: 4px 0;
    z-index: 60;
    background-color: whitesmoke;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .service-report .service-filters:not(.filters-open) {
      min-width: 0; }
    .service-report .service-filters .sidenav {
      width: 100%;
      padding: 22px 16px 12px;
      background: 0 0;
      overflow: hidden; }
      .service-report .service-filters .sidenav md-input-container {
        max-height: 35px;
        margin: 16px 0; }
      .service-report .service-filters .sidenav .input-date label {
        right: 45px !important; }
      .service-report .service-filters .sidenav .input-date md-datepicker .md-datepicker-button {
        max-width: 35px;
        margin-left: 0;
        padding-left: 0;
        margin-right: 0;
        padding-right: 0; }
        .service-report .service-filters .sidenav .input-date md-datepicker .md-datepicker-button md-icon {
          margin-left: 0;
          margin-right: 0; }
      .service-report .service-filters .sidenav .input-date md-datepicker .md-datepicker-input-container {
        margin-left: 0;
        border-bottom-width: 0; }
      .service-report .service-filters .sidenav .onlyOccurrences-input-container {
        margin-top: 4px;
        margin-bottom: 12px; }
        .service-report .service-filters .sidenav .onlyOccurrences-input-container md-switch {
          margin: 0; }
    .service-report .service-filters .button-toggle-sidenav {
      z-index: 61; }
  .service-report .service-map-div {
    position: relative;
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
    z-index: 3; }
    .service-report .service-map-div .service-map {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 0; }
    .service-report .service-map-div .div-button-toggle-view-sidenav {
      position: fixed;
      top: 50%;
      right: 0;
      padding: 4px 0;
      cursor: pointer;
      z-index: 3;
      background-color: white; }
      .service-report .service-map-div .div-button-toggle-view-sidenav:hover {
        background-color: #f1f0f1; }
  .service-report .service-sidebar {
    max-width: 380px; }
    .service-report .service-sidebar .service-view > .md-subheader,
    .service-report .service-sidebar .service-view > md-toolbar {
      background-color: #263238;
      color: #fff; }
      .service-report .service-sidebar .service-view > .md-subheader *,
      .service-report .service-sidebar .service-view > md-toolbar * {
        color: #fff; }
      .service-report .service-sidebar .service-view > .md-subheader .info-allocation,
      .service-report .service-sidebar .service-view > md-toolbar .info-allocation {
        max-height: 25px;
        margin-bottom: 5px;
        font-size: 14px; }
      .service-report .service-sidebar .service-view > .md-subheader md-icon,
      .service-report .service-sidebar .service-view > md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px; }
      .service-report .service-sidebar .service-view > .md-subheader md-input-container,
      .service-report .service-sidebar .service-view > md-toolbar md-input-container {
        margin-bottom: 0; }
        .service-report .service-sidebar .service-view > .md-subheader md-input-container > *,
        .service-report .service-sidebar .service-view > md-toolbar md-input-container > * {
          color: #fff !important; }
        .service-report .service-sidebar .service-view > .md-subheader md-input-container input,
        .service-report .service-sidebar .service-view > md-toolbar md-input-container input {
          border-bottom-color: #fff !important; }
    .service-report .service-sidebar .service-occurrences-list .md-list-item-text > * {
      font-size: 16px; }
    .service-report .service-sidebar .service-occurrences-list .md-secundary span {
      line-height: 1.3em; }
    .service-report .service-sidebar .div-allocation-people-status {
      padding: 6px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row wrap; }
      .service-report .service-sidebar .div-allocation-people-status > div {
        position: relative;
        min-width: 75px;
        max-width: 85px;
        width: 75px;
        min-height: 92px;
        max-height: 92px;
        height: 92px;
        padding: 2px 0;
        margin: 2px;
        text-align: center;
        font-weight: bold; }
        .service-report .service-sidebar .div-allocation-people-status > div .avatar {
          margin: 0;
          font-weight: bold; }
        .service-report .service-sidebar .div-allocation-people-status > div .allocation-totals {
          max-height: 20px;
          flex: 1;
          padding: 6px;
          line-height: 1em; }
        .service-report .service-sidebar .div-allocation-people-status > div .div-loading {
          top: -10px; }
    .service-report .service-sidebar .timeline-events-list .event-item {
      padding: 4px 16px 8px 16px;
      border: none !important; }
      .service-report .service-sidebar .timeline-events-list .event-item .info {
        margin-right: 0; }
      .service-report .service-sidebar .timeline-events-list .event-item .title {
        margin-bottom: 3px;
        font-size: 16px;
        font-weight: bold; }
      .service-report .service-sidebar .timeline-events-list .event-item .notes {
        padding-top: 3px;
        font-size: 14px; }
        .service-report .service-sidebar .timeline-events-list .event-item .notes md-icon {
          margin-left: 0;
          margin-right: 3px; }
      .service-report .service-sidebar .timeline-events-list .event-item .total-balancing {
        text-overflow: clip;
        overflow: visible; }
        .service-report .service-sidebar .timeline-events-list .event-item .total-balancing span:before {
          content: '('; }
        .service-report .service-sidebar .timeline-events-list .event-item .total-balancing span:after {
          content: ')'; }
        .service-report .service-sidebar .timeline-events-list .event-item .total-balancing span .balancing-positive:before {
          content: ' +'; }
      .service-report .service-sidebar .timeline-events-list .event-item .secondary-text.time {
        margin-top: 3px; }
      .service-report .service-sidebar .timeline-events-list .event-item .secondary-text md-icon {
        margin-left: 0;
        margin-right: 3px; }
    .service-report .service-sidebar .timeline-events-list .md-list-item-inner {
      padding: 6px 0; }
    .service-report .service-sidebar .timeline-events-list .avatar {
      margin-right: 5px;
      font-size: 17px !important; }
      .service-report .service-sidebar .timeline-events-list .avatar.occurrence-event {
        font-size: 24px !important; }
    .service-report .service-sidebar .md-subheader-inner {
      padding-bottom: 8px; }
    .service-report .service-sidebar md-list-item .md-list-item-inner {
      padding: 8px 0;
      z-index: 1; }
    .service-report .service-sidebar p {
      margin: 0; }
  .service-report .centroid-marker-info-window h3, .service-report .centroid-marker-info-window h4, .service-report .centroid-marker-info-window p {
    margin: 0 !important; }

@media screen and (max-width: 459px) {
  .service-report .service-sidebar {
    max-width: 100%;
    width: 100%; } }

@media screen and (max-width: 600px) {
  .service-report .service-filters.filters-open {
    height: 100%; } }

@media screen and (max-width: 960px) {
  .service-report .service-filters.filters-open {
    max-height: 310px;
    z-index: 63; }
  .service-report .service-filters .sidenav {
    padding-top: 0; } }

@media print {
  .service-report .service-sidebar {
    max-width: 100%;
    height: 100%;
    width: 100%;
    overflow: visible !important; }
    .service-report .service-sidebar .service-view,
    .service-report .service-sidebar md-content {
      overflow: visible !important; }
    .service-report .service-sidebar md-toolbar {
      color: #000000;
      background-color: #fff; }
      .service-report .service-sidebar md-toolbar md-icon {
        margin-left: 0;
        margin-right: 2px;
        color: #000000; }
    .service-report .service-sidebar .content {
      display: block; }
      .service-report .service-sidebar .content .md-subheader {
        position: initial !important;
        margin: 12px 0; } }

.technical-report__row:hover {
  background: #E8EAF6; }

.technical-report__filters-buttons {
  text-align: right; }

.technical-report .filters-frame {
  background-color: #FFFFFF; }
  .technical-report .filters-frame .md-input-date {
    margin-left: 10px;
    margin-right: 10px;
    width: 120px; }
  .technical-report .filters-frame .filters {
    height: 50px; }

.technical-report .page-select.ng-scope {
  width: auto; }

.technical-report .center-align {
  text-align: center !important; }

.technical-report .limit-select.ng-scope {
  width: auto; }

.technical-report .table.md-table td.md-cell, .technical-report table.md-table th.md-column {
  vertical-align: middle;
  text-align: center; }

.technical-report__assessment {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  flex-wrap: wrap; }
  .technical-report__assessment__text {
    width: 60%; }
    .technical-report__assessment__text__row {
      display: flex;
      line-height: 50px; }
      .technical-report__assessment__text__row__title {
        width: 40%;
        font-weight: bold; }
  .technical-report__assessment__images {
    width: 40%; }
    .technical-report__assessment__images__title {
      font-weight: bold;
      line-height: 30px; }
      .technical-report__assessment__images__title__info {
        font-weight: normal; }
    .technical-report__assessment__images__wrapper {
      padding-bottom: 10px; }
      .technical-report__assessment__images__wrapper__img {
        border: solid 1px #455A64;
        max-width: 100%;
        max-height: 240px;
        position: relative;
        z-index: 2; }

.custom-dialog {
  max-height: 100%;
  width: 55%; }

#services {
  background-color: whitesmoke; }
  #services .main-sidenav.open {
    z-index: 60; }
  #services md-sidenav {
    background-color: #fafafa; }
    #services md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #services md-sidenav .header .logo {
        padding-top: 27px; }
        #services md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #services md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #services md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #services md-sidenav md-content .item {
      cursor: pointer; }
      #services md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #services md-sidenav .content {
      padding: 12px 0; }
    #services md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #services .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #services .center .header .search-wrapper {
      background: #fff; }
      #services .center .header .search-wrapper .div-search {
        height: 56px;
        line-height: 56px;
        padding: 18px; }
        #services .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #services .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #services .center .header {
    padding-left: 16px; } }

#simCards {
  background-color: whitesmoke; }
  #simCards .detail-resize {
    width: 72%; }
  #simCards .main-sidenav.open {
    z-index: 60; }
  #simCards md-sidenav {
    background-color: #fafafa;
    width: 500px;
    max-width: 500px; }
    #simCards md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #simCards md-sidenav .header .logo {
        padding-top: 27px; }
        #simCards md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #simCards md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #simCards md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #simCards md-sidenav md-content .item {
      cursor: pointer; }
      #simCards md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #simCards md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #simCards .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #simCards .center .header .search-wrapper {
      background: #fff; }
      #simCards .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #simCards .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #simCards .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #simCards .center .header {
    padding-left: 16px; } }

#terminals-dashboard {
  background-color: whitesmoke; }
  #terminals-dashboard .main-sidenav.open {
    z-index: 60; }
  #terminals-dashboard md-sidenav {
    background-color: #fafafa; }
    #terminals-dashboard md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #terminals-dashboard md-sidenav .header .logo {
        padding-top: 27px; }
        #terminals-dashboard md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #terminals-dashboard md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #terminals-dashboard md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #terminals-dashboard md-sidenav md-content .item {
      cursor: pointer; }
      #terminals-dashboard md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #terminals-dashboard md-sidenav .content {
      padding: 12px 0; }
    #terminals-dashboard md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #terminals-dashboard md-tab-content {
    overflow: hidden; }
  #terminals-dashboard md-tab-content > div {
    overflow: hidden; }
    #terminals-dashboard md-tab-content > div .tabs-list a {
      color: #1565C0; }
  #terminals-dashboard md-ink-bar {
    color: #3f51b5;
    background: #3f51b5; }
  #terminals-dashboard .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    padding: 12px;
    background-color: #394b6d;
    color: #FFFFFF; }
    #terminals-dashboard .center .header .search-wrapper {
      background: #fff; }
      #terminals-dashboard .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #terminals-dashboard .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }
  #terminals-dashboard .div-icon-loading {
    margin: 4px 0 0 8px; }
  #terminals-dashboard .terminal-item {
    margin: 6px 8px; }
  #terminals-dashboard .terminals-status-list {
    height: 100%;
    padding-bottom: 36px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-x: hidden; }
    #terminals-dashboard .terminals-status-list p {
      margin: 0; }
    #terminals-dashboard .terminals-status-list .div-terminals {
      width: 100%;
      height: auto;
      padding: 6px 0 6px 12px;
      -webkit-flex-flow: row wrap;
      -moz-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      flex-flow: row wrap; }
  #terminals-dashboard .paginationEnd {
    font-size: 17px;
    padding: 10px; }
  #terminals-dashboard .load-more {
    width: 100%; }
    #terminals-dashboard .load-more:hover {
      background-color: rgba(0, 0, 0, 0.26); }
  #terminals-dashboard md-tab-item[tabindex="0"].camera ~ md-ink-bar {
    background-color: #1565C0 !important; }
  #terminals-dashboard md-tab-item[tabindex="0"].terminalsOk ~ md-ink-bar {
    background-color: #4CAF50 !important; }
  #terminals-dashboard md-tab-item[tabindex="0"].terminalsCriticals ~ md-ink-bar {
    background-color: red !important; }
  #terminals-dashboard md-tab-item[tabindex="0"].terminalsOff ~ md-ink-bar {
    background-color: #616161 !important; }

@media screen and (max-width: 1280px) {
  #terminals-dashboard .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #terminals-dashboard .center .header {
    padding-left: 16px; } }

@media screen and (width: 1920px) {
  #terminals-list-dashboard .terminals-list {
    padding: 0 70px; }
  #terminals-list-dashboard .terminals-status-list {
    padding: 0 190px; } }

@media screen and (width: 1366px) {
  #terminals-list-dashboard .terminals-list {
    padding: 0 80px; }
  #terminals-list-dashboard .terminals-status-list {
    padding: 0 110px; } }

@media screen and (width: 1440px) {
  #terminals-list-dashboard .terminals-list {
    padding: 0 120px; }
  #terminals-list-dashboard .terminals-status-list {
    padding: 0 150px; } }

@media screen and (width: 1280px) {
  #terminals-list-dashboard .terminals-list {
    padding: 0 40px; }
  #terminals-list-dashboard .terminals-status-list {
    padding: 0 65px; } }

#terminals {
  background-color: whitesmoke; }
  #terminals .main-sidenav.open {
    z-index: 60; }
  #terminals md-sidenav {
    background-color: #fafafa; }
    #terminals md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #terminals md-sidenav .header .logo {
        padding-top: 27px; }
        #terminals md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #terminals md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #terminals md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #terminals md-sidenav md-content .item {
      cursor: pointer; }
      #terminals md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #terminals md-sidenav .content {
      padding: 12px 0; }
    #terminals md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #terminals .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #terminals .center .header .search-wrapper {
      background: #fff; }
      #terminals .center .header .search-wrapper .div-search {
        height: 56px;
        line-height: 56px;
        padding: 18px; }
        #terminals .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #terminals .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #terminals .center .header {
    padding-left: 16px; } }

#terminals-installation .installation-tabs {
  height: 100%; }
  #terminals-installation .installation-tabs md-pagination-wrapper {
    width: 100% !important;
    transform: translate(0px, 0px);
    justify-content: space-between;
    align-content: space-between; }
    #terminals-installation .installation-tabs md-pagination-wrapper md-tab-item {
      flex: 1; }
  #terminals-installation .installation-tabs md-tabs-canvas,
  #terminals-installation .installation-tabs md-pagination-wrapper,
  #terminals-installation .installation-tabs md-tabs-canvas {
    height: 70px !important; }
  #terminals-installation .installation-tabs md-tabs-content-wrapper {
    margin-top: 21px; }

#technicalInfos {
  background-color: whitesmoke; }
  #technicalInfos .detail-resize {
    width: 72%; }
  #technicalInfos .main-sidenav.open {
    z-index: 60; }
  #technicalInfos md-sidenav {
    background-color: #fafafa;
    width: 500px;
    max-width: 500px; }
    #technicalInfos md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #technicalInfos md-sidenav .header .logo {
        padding-top: 27px; }
        #technicalInfos md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #technicalInfos md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #technicalInfos md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #technicalInfos md-sidenav md-content .item {
      cursor: pointer; }
      #technicalInfos md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #technicalInfos md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #technicalInfos .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #technicalInfos .center .header .search-wrapper {
      background: #fff; }
      #technicalInfos .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #technicalInfos .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #technicalInfos .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #technicalInfos .center .header {
    padding-left: 16px; } }

#events {
  background-color: whitesmoke; }
  #events .detail-resize {
    width: 72%; }
  #events .main-sidenav.open {
    z-index: 60; }
  #events md-sidenav {
    width: 500px;
    max-width: 500px;
    background-color: #fafafa; }
    #events md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #events md-sidenav .header .logo {
        padding-top: 27px; }
        #events md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #events md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #events md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #events md-sidenav md-content .item {
      cursor: pointer; }
      #events md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #events md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #events .center .header {
    height: 80px;
    min-height: 80px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #events .center .header .search-wrapper {
      background: #fff; }
      #events .center .header .search-wrapper .div-search {
        height: 45px;
        line-height: 45px;
        padding: 10px; }
        #events .center .header .search-wrapper .div-search .input-search {
          height: 45px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }
    #events .center .header .print-wrapper md-button {
      margin-left: 10px; }
    #events .center .header .print-wrapper md-menu-bar {
      padding: 5px; }
      #events .center .header .print-wrapper md-menu-bar md-menu .export-btn {
        min-height: 54px; }
      #events .center .header .print-wrapper md-menu-bar md-menu .md-button {
        min-width: 40px !important; }
        #events .center .header .print-wrapper md-menu-bar md-menu .md-button md-icon {
          color: rgba(255, 255, 255, 0.87); }
    #events .center .header .print-wrapper .export-images-btn {
      min-width: 50px; }
      #events .center .header .print-wrapper .export-images-btn md-icon {
        color: rgba(255, 255, 255, 0.87); }

@media screen and (max-width: 960px) {
  #events .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #events .center .header {
    padding-left: 16px; } }

#trackerReadings {
  background-color: whitesmoke; }
  #trackerReadings .main-sidenav.open {
    z-index: 60; }
  #trackerReadings md-sidenav {
    background-color: #fafafa; }
    #trackerReadings md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #trackerReadings md-sidenav .header .logo {
        padding-top: 27px; }
        #trackerReadings md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #trackerReadings md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #trackerReadings md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #trackerReadings md-sidenav md-content .item {
      cursor: pointer; }
      #trackerReadings md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #trackerReadings md-sidenav .content {
      padding: 12px 0; }
    #trackerReadings md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #trackerReadings .center {
    padding: 16px; }
    #trackerReadings .center .div-total {
      font-size: 20px; }
      #trackerReadings .center .div-total > md-icon {
        margin: 0 5px 0 0; }
    #trackerReadings .center .header {
      height: 110px;
      min-height: 100px;
      max-height: 136px;
      padding: 12px;
      padding-left: 0;
      background-color: #394b6d;
      color: #FFFFFF; }
      #trackerReadings .center .header .search-wrapper {
        background: #fff; }
        #trackerReadings .center .header .search-wrapper .div-search {
          height: 56px;
          trackerReading-height: 56px;
          padding: 18px; }
          #trackerReadings .center .header .search-wrapper .div-search .input-search {
            height: 56px;
            padding-left: 16px;
            margin: 0;
            border-radius: 0;
            border: none;
            color: rgba(0, 0, 0, 0.54);
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #trackerReadings .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #trackerReadings .center .header {
    padding-left: 16px; } }

.trackerReading-view .div-event-img {
  height: 245px; }
  .trackerReading-view .div-event-img .div-wrapper-event-img {
    z-index: 1;
    background-color: #fff; }
    .trackerReading-view .div-event-img .div-wrapper-event-img .div-img {
      position: relative; }
      .trackerReading-view .div-event-img .div-wrapper-event-img .div-img .event-img {
        width: 100%;
        z-index: 2; }
        .trackerReading-view .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal {
          height: 225px; }
          .trackerReading-view .div-event-img .div-wrapper-event-img .div-img .event-img .ng-image-gallery-modal .galleria-image {
            margin: 0; }
      .trackerReading-view .div-event-img .div-wrapper-event-img .div-img .event-img-png {
        width: 100%;
        height: 225px;
        margin: 0; }
        .trackerReading-view .div-event-img .div-wrapper-event-img .div-img .event-img-png img {
          box-shadow: none; }
  .trackerReading-view .div-event-img .event-map-img {
    position: absolute;
    right: 0; }

.trackerReading-view .list-item {
  padding-top: 12px;
  font-size: 14px;
  color: white; }
  .trackerReading-view .list-item .title {
    font-size: 18px;
    font-weight: bold; }
    .trackerReading-view .list-item .title .event-edit-button {
      margin-left: 0;
      padding-left: 0;
      font-size: 22px; }
  .trackerReading-view .list-item .info {
    margin-left: 0;
    margin-right: 0; }
  .trackerReading-view .list-item .div-event-buttons button {
    margin-top: 4px;
    margin-bottom: 0; }
  .trackerReading-view .list-item md-icon {
    color: white; }

.trackerReading-view md-input-container {
  margin: 0; }

.trackerReading-view p {
  margin: 0; }

#users {
  background-color: whitesmoke; }
  #users .main-sidenav.open {
    z-index: 60; }
  #users md-sidenav {
    background-color: #fafafa; }
    #users md-sidenav .header {
      color: #FFFFFF;
      background-color: #394b6d; }
      #users md-sidenav .header .logo {
        padding-top: 27px; }
        #users md-sidenav .header .logo .logo-icon {
          margin: 0 16px 0 0; }
          #users md-sidenav .header .logo .logo-icon md-icon {
            color: #FFFFFF; }
        #users md-sidenav .header .logo .logo-text {
          font-size: 24px; }
    #users md-sidenav md-content .item {
      cursor: pointer; }
      #users md-sidenav md-content .item.selected {
        background-color: rgba(0, 0, 0, 0.06); }
    #users md-sidenav .content {
      padding: 12px 0; }
    #users md-sidenav .div-add-button {
      padding: 0 32px 6px; }
  #users .center .header {
    height: 110px;
    min-height: 100px;
    max-height: 136px;
    padding: 12px;
    padding-left: 0;
    background-color: #394b6d;
    color: #FFFFFF; }
    #users .center .header .search-wrapper {
      background: #fff; }
      #users .center .header .search-wrapper .div-search {
        height: 56px;
        user-height: 56px;
        padding: 18px; }
        #users .center .header .search-wrapper .div-search .input-search {
          height: 56px;
          padding-left: 16px;
          margin: 0;
          border-radius: 0;
          border: none;
          color: rgba(0, 0, 0, 0.54);
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none; }

@media screen and (max-width: 960px) {
  #users .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  #users .center .header {
    padding-left: 16px; } }

.action-editor {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex; }
  .action-editor__data {
    min-width: 300px;
    margin-right: 10px; }
  .action-editor__save {
    text-align: right;
    margin-right: 15px; }

.employee-editor {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex; }
  .employee-editor__data {
    min-width: 300px;
    margin-right: 10px; }
  .employee-editor__save {
    text-align: right;
    margin-right: 15px; }

.line-visualizer {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex; }
  .line-visualizer__map {
    width: 600px;
    height: 300px; }

.poi-editor {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex; }
  .poi-editor__data {
    min-width: 300px;
    margin-right: 10px; }
  .poi-editor__map {
    width: 350px;
    height: 200px; }

.poi-selection {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex; }
  .poi-selection__data {
    min-width: 450px;
    margin: 10px; }
  .poi-selection__save {
    text-align: right;
    margin-right: 15px; }
  .poi-selection__map {
    margin: auto;
    width: 350px;
    height: 200px;
    margin-top: 10px; }
  .poi-selection__autocomplete-template li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: normal; }
  .poi-selection__autocomplete-template li:last-child {
    border-bottom-width: 0; }
  .poi-selection__autocomplete-template__item-title {
    display: block;
    line-height: 2; }
  .poi-selection__autocomplete-template__item-metadata {
    display: block;
    line-height: 2; }

md-dialog.branchOrganizationsSecundario-list {
  max-width: 720px;
  width: 720px;
  overflow: hidden; }
  md-dialog.branchOrganizationsSecundario-list md-toolbar {
    background-color: #394b6d; }
  md-dialog.branchOrganizationsSecundario-list md-dialog-content {
    position: relative;
    min-height: 200px;
    display: block; }
  md-dialog.branchOrganizationsSecundario-list md-toolbar .title {
    padding: 18px 0 0;
    font-size: 17px; }
  md-dialog.branchOrganizationsSecundario-list md-toolbar md-input-container {
    margin-bottom: 0; }
    md-dialog.branchOrganizationsSecundario-list md-toolbar md-input-container > * {
      color: #fff !important; }
    md-dialog.branchOrganizationsSecundario-list md-toolbar md-input-container input {
      border-bottom-color: #fff !important; }
  md-dialog.branchOrganizationsSecundario-list md-dialog-actions {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.12); }

#layout-content-with-toolbar #toolbar .logo {
  min-width: 140px;
  max-width: 140px;
  margin-left: 16px; }
  #layout-content-with-toolbar #toolbar .logo .logo-image {
    display: block;
    background: #039BE5;
    width: 32px;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2px; }
  #layout-content-with-toolbar #toolbar .logo .logo-text {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 16px;
    font-size: 16px; }

#layout-content-with-toolbar #toolbar .div-controls-toolbar {
  max-width: 305px; }
  #layout-content-with-toolbar #toolbar .div-controls-toolbar .div-branchOrganization {
    max-width: 200px; }
    #layout-content-with-toolbar #toolbar .div-controls-toolbar .div-branchOrganization button {
      height: 54px;
      margin-left: 0; }
  #layout-content-with-toolbar #toolbar .div-controls-toolbar .div-user {
    min-width: 103px; }

md-menu-content md-menu-item {
  padding: 4px 0; }

@media screen and (max-width: 600px) {
  #layout-content-with-toolbar #toolbar .div-controls-toolbar {
    max-width: 155px; }
    #layout-content-with-toolbar #toolbar .div-controls-toolbar .div-branchOrganization {
      max-width: 70px; } }

/**
* Copyright 2015 Google Inc. All Rights Reserved.
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*      http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
#toolbar-vertical {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  width: 100%;
  height: 100%;
  /* iOS Safari specific workaround */
  /* END iOS Safari specific workaround */
  /* TODO: Find a proper solution to have the graphs
  * not float around outside their container in IE10/11.
  * Using a browserhacks.com solution for now.
  */
  /* TODO end */ }
  #toolbar-vertical__section {
    width: 100%;
    padding-left: 240px;
    box-sizing: border-box; }
  #toolbar-vertical__link {
    border-radius: 0 !important; }
    #toolbar-vertical__link__icon {
      margin-right: 5px; }
  #toolbar-vertical__arrow {
    transition: transform 0.5s; }
    #toolbar-vertical__arrow--rotated {
      transform: rotate(-180deg); }
  #toolbar-vertical__sublist {
    background: #37474F;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s; }
    #toolbar-vertical__sublist--opened {
      max-height: 440px; }
    #toolbar-vertical__sublist .md-button {
      text-transform: none !important;
      padding-left: 20px !important;
      box-sizing: border-box; }
  #toolbar-vertical .mdl-layout__header {
    min-height: 52px; }
  #toolbar-vertical .mdl-layout__header-row {
    height: 45px; }
  #toolbar-vertical .mdl-layout__drawer {
    width: 100%;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  #toolbar-vertical .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 10px 16px; }
  #toolbar-vertical .mdl-layout__content {
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden; }
  #toolbar-vertical .demo-avatar {
    width: 48px;
    height: 48px;
    border-radius: 24px; }
  #toolbar-vertical .demo-layout .demo-header .mdl-textfield {
    padding: 0px;
    margin-top: 41px; }
  #toolbar-vertical .demo-layout .demo-header .mdl-textfield .mdl-textfield__expandable-holder {
    bottom: 19px; }
  #toolbar-vertical .demo-layout .mdl-layout__header .mdl-layout__drawer-button {
    color: rgba(0, 0, 0, 0.54); }
  #toolbar-vertical .mdl-layout__drawer .avatar {
    margin-bottom: 16px; }
  #toolbar-vertical .demo-drawer {
    border: none;
    overflow-x: hidden;
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -ms-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out; }
    #toolbar-vertical .demo-drawer.side-nav-closed {
      width: 50px;
      z-index: 10; }
      #toolbar-vertical .demo-drawer.side-nav-closed .demo-drawer-header {
        padding-left: 5px;
        padding-right: 5px; }
  #toolbar-vertical .main-content {
    margin-left: 240px;
    -webkit-transition: margin-left 0.3s ease-in-out;
    -moz-transition: margin-left 0.3s ease-in-out;
    -ms-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out; }
    #toolbar-vertical .main-content.side-nav-closed {
      margin-left: 50px !important; }
  #toolbar-vertical .div-button-side-nav-collapse {
    position: absolute;
    top: 8px;
    left: 190px;
    width: 50px;
    z-index: 9; }
    #toolbar-vertical .div-button-side-nav-collapse button {
      min-width: auto;
      padding: 4px;
      background-color: #263238;
      color: #FFF !important; }
    #toolbar-vertical .div-button-side-nav-collapse.side-nav-closed {
      left: 40px; }
  #toolbar-vertical .demo-drawer .mdl-menu__container {
    z-index: -1; }
  #toolbar-vertical .demo-drawer .demo-navigation {
    z-index: -2; }
  #toolbar-vertical .demo-drawer .mdl-menu .mdl-menu__item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  #toolbar-vertical .demo-drawer-header {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 16px; }
  #toolbar-vertical .demo-avatar-dropdown {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%; }
  #toolbar-vertical .demo-navigation {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
  #toolbar-vertical .demo-layout .demo-navigation .mdl-navigation__link {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;
    font-weight: 500; }
  #toolbar-vertical .demo-layout .demo-navigation .mdl-navigation__link:hover {
    background-color: #00BCD4;
    color: white; }
  #toolbar-vertical .demo-layout .demo-navigation .mdl-navigation__link--active {
    background-color: #607D8B;
    color: white; }
  #toolbar-vertical .demo-layout .demo-navigation .mdl-navigation__link--subactive {
    background-color: #90A4AE;
    color: white; }
  #toolbar-vertical .demo-navigation .mdl-navigation__link .material-icons {
    font-size: 24px;
    color: white; }
  #toolbar-vertical .demo-content {
    height: 100%;
    padding: 0 !important; }
  #toolbar-vertical .demo-charts {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center; }
  #toolbar-vertical .demo-chart:nth-child(1) {
    color: #ACEC00; }
  #toolbar-vertical .demo-chart:nth-child(2) {
    color: #00BBD6; }
  #toolbar-vertical .demo-chart:nth-child(3) {
    color: #BA65C9; }
  #toolbar-vertical .demo-chart:nth-child(4) {
    color: #EF3C79; }
  #toolbar-vertical .demo-graphs {
    padding: 16px 32px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
  #toolbar-vertical _:-ms-input-placeholder, #toolbar-vertical :root .demo-graphs {
    min-height: 664px; }
  #toolbar-vertical _:-ms-input-placeholder, #toolbar-vertical :root .demo-graph {
    max-height: 300px; }
  #toolbar-vertical .demo-graph:nth-child(1) {
    color: #00b9d8; }
  #toolbar-vertical .demo-graph:nth-child(2) {
    color: #d9006e; }
  #toolbar-vertical .demo-cards {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start; }
  #toolbar-vertical .demo-cards .demo-separator {
    height: 32px; }
  #toolbar-vertical .demo-cards .mdl-card__title.mdl-card__title {
    color: white;
    font-size: 24px;
    font-weight: 400; }
  #toolbar-vertical .demo-cards ul {
    padding: 0; }
  #toolbar-vertical .demo-cards h3 {
    font-size: 1em; }
  #toolbar-vertical .demo-updates .mdl-card__title {
    background-position: 90% 100%;
    background-repeat: no-repeat; }
  #toolbar-vertical .demo-cards .mdl-card__actions a {
    color: #00BCD4;
    text-decoration: none; }
  #toolbar-vertical .demo-options h3 {
    margin: 0; }
  #toolbar-vertical .demo-options .mdl-checkbox__box-outline {
    border-color: white; }
  #toolbar-vertical .demo-options ul {
    margin: 0;
    list-style-type: none; }
  #toolbar-vertical .demo-options li {
    margin: 4px 0; }
  #toolbar-vertical .demo-options .material-icons {
    color: white; }
  #toolbar-vertical .demo-options .mdl-card__actions {
    height: 64px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  #toolbar-vertical .mdl-textfield--expandable .mdl-button--icon {
    top: -20px; }

@media screen and (min-width: 960px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@media screen and (max-width: 960px) {
  .main-content {
    margin-left: 0px !important; } }

searchbar .div-toggle-searchbar {
  position: absolute;
  width: 30px;
  height: 60px;
  top: 20px;
  left: 320px;
  padding: 3px 3px 0 0;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-left-width: 0;
  background: #394b6d;
  color: white;
  font-size: 1em;
  line-height: 60px;
  text-align: center;
  z-index: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  box-shadow: 5px 2px 6px rgba(0, 0, 0, 0.12) inset; }
  searchbar .div-toggle-searchbar.closed {
    left: 0; }

.searchbar {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 200px;
  width: 320px;
  height: 100%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 9; }
  .searchbar.closed {
    left: -320px; }
  .searchbar .div-organizations {
    height: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.54);
    background-color: whitesmoke;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s; }
    .searchbar .div-organizations .center .header {
      height: 100px;
      min-height: 100px;
      max-height: 100px;
      padding: 12px;
      background-color: #394b6d;
      color: #FFFFFF;
      font-size: 14px; }
      .searchbar .div-organizations .center .header .search-wrapper {
        background: #fff; }
        .searchbar .div-organizations .center .header .search-wrapper .div-search {
          height: 50px;
          min-height: 50px;
          padding: 14px; }
          .searchbar .div-organizations .center .header .search-wrapper .div-search .input-search {
            height: 50px;
            padding-left: 16px;
            margin: 0;
            border-radius: 0;
            border: none;
            color: rgba(0, 0, 0, 0.54);
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none; }
    .searchbar .div-organizations .center .header-organizations-list {
      height: 60px;
      min-height: 60px !important;
      max-height: 60px;
      padding: 8px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-size: 14px; }
      .searchbar .div-organizations .center .header-organizations-list .div-total > md-icon {
        margin-left: 0;
        margin-right: 5px; }
    .searchbar .div-organizations .center .div-list-orderBy {
      min-width: 165px;
      margin-top: 10px; }
    .searchbar .div-organizations .center .organizations-list {
      height: 100%;
      padding-bottom: 50px;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      overflow-x: hidden; }
      .searchbar .div-organizations .center .organizations-list p {
        margin: 0; }
      .searchbar .div-organizations .center .organizations-list .md-subheader .md-subheader-inner {
        padding-top: 0; }
    .searchbar .div-organizations .center .organization-item {
      position: relative;
      height: auto !important;
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      text-transform: none;
      cursor: pointer; }
      .searchbar .div-organizations .center .organization-item .avatar {
        font-size: 14px; }
      .searchbar .div-organizations .center .organization-item .info {
        margin: 0 16px 0 8px; }
        .searchbar .div-organizations .center .organization-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        .searchbar .div-organizations .center .organization-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        .searchbar .div-organizations .center .organization-item .info md-icon {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0; }
      .searchbar .div-organizations .center .organization-item .secondary-text md-icon {
        margin: 0; }
      .searchbar .div-organizations .center .organization-item .selected {
        background-color: rgba(0, 0, 0, 0.12); }
    .searchbar .div-organizations .center .organization-people-count {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s; }

@media screen and (max-width: 600px) {
  .searchbar .div-organizations .header .div-search {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  .searchbar .div-organizations .center .header {
    padding-left: 16px; }
  .searchbar md-autocomplete {
    max-width: 80%;
    width: 80%; } }

.div-sidebar .sidebar--visible {
  opacity: 1 !important;
  visibility: visible !important; }

.div-sidebar .sidebar md-whiteframe {
  min-width: 382px;
  width: 382px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: white;
  overflow-y: scroll;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
  z-index: 1; }

.div-sidebar .sidebar md-tabs.md-dynamic-height,
.div-sidebar .sidebar md-tabs-content-wrapper {
  overflow: hidden !important; }

.div-sidebar .sidebar md-switch {
  margin-bottom: 0 !important; }

.div-sidebar .sidebar md-input-container {
  margin: 0 !important;
  padding: 0 !important; }

.div-sidebar .sidebar__streetview {
  width: 100%; }

.div-sidebar .sidebar__legend {
  margin: 30px;
  padding: 5px; }

.div-sidebar .sidebar__container {
  width: 100%;
  margin-top: 25px;
  margin-bottom: -30px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14); }

.div-sidebar .sidebar__logo {
  bottom: 10px;
  left: 10px;
  width: 130px;
  height: 25px;
  position: absolute;
  z-index: 1000;
  transition: all 0.3s; }
  .div-sidebar .sidebar__logo--opened {
    left: 360px; }

.div-sidebar .sidebar__close {
  z-index: 1;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-left-width: 0;
  background: white;
  color: black;
  position: absolute;
  width: 30px;
  height: 60px;
  top: 100px;
  left: 0;
  font-size: 1.4em;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
  transition-delay: 0.3s; }
  .div-sidebar .sidebar__close--opened {
    width: 400px; }

.div-sidebar .sidebar__center {
  text-align: center; }
  .div-sidebar .sidebar__center__inputs {
    padding: 1em;
    box-sizing: border-box; }

.div-sidebar .sidebar__placeholderlogo {
  margin: 70px auto 20px;
  display: block; }

.div-sidebar .sidebar__list {
  width: 300px;
  width: 100%; }
  .div-sidebar .sidebar__list .md-tabs-canvas,
  .div-sidebar .sidebar__list md-pagination-wrapper,
  .div-sidebar .sidebar__list md-tabs-canvas {
    height: 70px !important; }
  .div-sidebar .sidebar__list md-tabs-content-wrapper {
    box-sizing: border-box; }
  .div-sidebar .sidebar__list__icon {
    font-size: 1.5em; }
  .div-sidebar .sidebar__list__placeholder {
    text-align: center;
    font-size: 1.5em;
    margin-top: 1em; }
    .div-sidebar .sidebar__list__placeholder__icon {
      font-size: 3em; }

.div-sidebar .sidebar md-tab-content {
  min-height: 300px; }

.div-sidebar .sidebar mdp-time-picker md-input-container {
  width: 100%;
  margin: 0; }

.div-sidebar .sidebar mdp-time-picker .md-button.md-icon-button {
  margin: 0; }

.div-sidebar .sidebar .input-container-datepicker {
  margin-right: 5px !important; }

.div-sidebar .sidebar .div-events-filters .div-switch-ghost-event {
  cursor: pointer;
  width: 100%; }

.div-sidebar .sidebar .div-events-filters .select-filter md-input-container {
  margin: 18px 0 !important;
  padding-left: 36px !important; }
  .div-sidebar .sidebar .div-events-filters .select-filter md-input-container md-icon {
    color: rgba(0, 0, 0, 0.56); }
  .div-sidebar .sidebar .div-events-filters .select-filter md-input-container md-select {
    height: 35px !important; }

.div-sidebar .sidebar #tab-content-5 {
  padding-right: 0; }

.div-sidebar .sidenav-centroid-view {
  z-index: 62;
  width: 400px; }

.div-sidebar .sidenav-events-view {
  z-index: 61;
  width: 400px; }

.div-sidebar .button-toggle-sidenav {
  z-index: 61; }

.div-sidebar .event-map-div {
  position: relative;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
  z-index: 3; }
  .div-sidebar .event-map-div .event-map {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0; }
  .div-sidebar .event-map-div .div-button-toggle-view-sidenav {
    width: auto;
    height: auto;
    position: fixed;
    top: 50%;
    right: 0;
    padding: 4px 0;
    cursor: pointer;
    z-index: 3;
    background-color: white; }
    .div-sidebar .event-map-div .div-button-toggle-view-sidenav:hover {
      background-color: #f1f0f1; }

.div-sidebar .div-button-side-nav-collapse {
  position: absolute;
  top: 5px;
  right: 305px;
  width: 50px !important;
  height: auto !important;
  -webkit-transition: width 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out;
  -moz-transition: width 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out;
  -ms-transition: width 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out;
  -o-transition: width 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out;
  transition: width 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out; }
  .div-sidebar .div-button-side-nav-collapse.side-nav-closed {
    right: 120px;
    width: auto !important; }
    .div-sidebar .div-button-side-nav-collapse.side-nav-closed button {
      padding: 4px 12px; }
  .div-sidebar .div-button-side-nav-collapse button {
    min-width: auto;
    padding: 4px;
    background-color: #263238 !important;
    color: #FFF !important;
    -webkit-transition: width 0.3s ease-out;
    -moz-transition: width 0.3s ease-out;
    -ms-transition: width 0.3s ease-out;
    -o-transition: width 0.3s ease-out;
    transition: width 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out; }
    .div-sidebar .div-button-side-nav-collapse button:hover {
      opacity: .8; }

.div-sidebar .div-legend .list-item {
  cursor: initial; }

.ng-image-gallery.inline .ng-image-gallery-modal {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 250px; }

.ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .prev, .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .next {
  display: block !important;
  position: absolute;
  top: -9999px;
  left: -9999px; }

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8px;
  flex: 0 0 8px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span:not(.img-bubble) {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  overflow: hidden;
  -webkit-transition: all 0s;
  transition: all 0s; }

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  position: relative;
  display: inline-block;
  margin: 1px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 100%;
  background-size: cover;
  background-position: center center;
  vertical-align: middle; }

.prev-arrow {
  float: left;
  text-align: left;
  width: 20px;
  cursor: pointer;
  outline: none;
  border: 0;
  padding: 5px; }

.next-arrow {
  float: right;
  text-align: right;
  width: 20px;
  cursor: pointer;
  outline: none;
  border: 0;
  padding: 5px; }

.image-slider-status {
  text-align: center;
  font-weight: bold;
  line-height: 2.5; }

.image-control {
  cursor: pointer;
  outline: none;
  border: 0; }

.image-address {
  height: 30px; }

.image-controls {
  float: left;
  width: 31%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.last-your__table {
  width: 100%;
  text-align: left;
  font-size: 0.9em; }
  .last-your__table tr {
    line-height: 1.5em; }
  .last-your__table th {
    padding-right: 6px; }

.real_time_header {
  text-align: center; }

.no-events {
  font-weight: 500;
  padding: 10px;
  text-align: center; }

.image-slideshow-address {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 2.4em;
  text-align: justify;
  padding-right: 1em;
  margin: 0; }
  .image-slideshow-address:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0; }
  .image-slideshow-address:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white; }

.md-input-date {
  width: 50%;
  float: left; }

.md-input-date-icon {
  float: left; }

.md-input-date-date {
  width: 70%;
  float: left;
  margin-left: 5px; }

.md-input-time {
  width: 50%;
  float: left; }

.date-checkbox-now {
  margin-top: -20px;
  float: right;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

md-checkbox.md-checked.date-checkbox-now .md-icon {
  background-color: #3f51b5; }

@media screen and (max-width: 600px) {
  .div-sidebar .sidebar .sidebar__close--opened {
    max-width: 94%;
    width: 94%; }
  .div-sidebar .sidebar md-whiteframe {
    max-width: 85%;
    width: 85%;
    z-index: 2; }
    .div-sidebar .sidebar md-whiteframe .ng-image-gallery .next-arrow {
      padding-right: 0; }
  .div-sidebar .sidenav-centroid-view {
    max-width: 100%;
    width: 100%; }
  .div-sidebar .div-button-side-nav-collapse:not(.side-nav-closed) {
    display: none; }
  .div-sidebar .div-button-side-nav-collapse.side-nav-closed {
    top: 100px;
    right: 2px; }
  .div-sidebar .sidenav-centroid-view,
  .div-sidebar .sidenav-events-view {
    max-width: 100%;
    width: 100% !important; } }

@media print {
  .div-sidebar--centroid-sidenav-open .sidenav-events-view {
    display: none !important; }
  .div-sidebar .sidebar__logo {
    display: none; }
  .div-sidebar .sidenav-events-view {
    min-width: 100% !important;
    width: 100% !important; }
  .div-sidebar .sidenav-centroid-view {
    max-width: 100% !important;
    width: 100% !important; } }

.terminal-info-component {
  height: auto !important; }
  .terminal-info-component .terminal-item.extended {
    position: relative;
    height: auto !important;
    min-height: 20%;
    min-width: 358px;
    max-width: 358px;
    width: 358px;
    margin: 6px;
    padding: 8px 8px 16px 8px;
    text-transform: none;
    cursor: pointer; }
    .terminal-info-component .terminal-item.extended .avatar {
      font-size: 14px; }
    .terminal-info-component .terminal-item.extended .info {
      margin: 0 16px 0 8px; }
      .terminal-info-component .terminal-item.extended .info .title {
        font-size: 15px;
        font-weight: 700; }
      .terminal-info-component .terminal-item.extended .info .secondary-title {
        margin-left: 5px;
        font-size: 12px !important; }
    .terminal-info-component .terminal-item.extended .secondary-text md-icon {
      margin: 0; }
    .terminal-info-component .terminal-item.extended .div-event-img {
      min-height: 215px;
      width: 325px; }
      .terminal-info-component .terminal-item.extended .div-event-img .event-img {
        z-index: 1; }
        .terminal-info-component .terminal-item.extended .div-event-img .event-img .ng-image-gallery-modal {
          height: 205px; }
      .terminal-info-component .terminal-item.extended .div-event-img .div-legend-img {
        position: absolute;
        bottom: 10px;
        left: 27px;
        width: 273px;
        padding: 8px;
        z-index: 1;
        color: white;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.26); }
        .terminal-info-component .terminal-item.extended .div-event-img .div-legend-img * {
          color: white; }
        .terminal-info-component .terminal-item.extended .div-event-img .div-legend-img .notes {
          margin-right: 0; }
        .terminal-info-component .terminal-item.extended .div-event-img .div-legend-img .img-icon-event .avatar {
          opacity: .7; }
      .terminal-info-component .terminal-item.extended .div-event-img .event-map-img {
        position: absolute;
        right: 0; }
    .terminal-info-component .terminal-item.extended .div-terminal-item {
      width: 325px; }
      .terminal-info-component .terminal-item.extended .div-terminal-item .avatar {
        font-size: 14px; }
      .terminal-info-component .terminal-item.extended .div-terminal-item .info {
        margin: 0 8px 0 4px; }
        .terminal-info-component .terminal-item.extended .div-terminal-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        .terminal-info-component .terminal-item.extended .div-terminal-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        .terminal-info-component .terminal-item.extended .div-terminal-item .info .notes md-icon {
          margin-right: 5px; }
      .terminal-info-component .terminal-item.extended .div-terminal-item .secondary-text md-icon {
        margin: 0;
        margin-right: 5px; }
      .terminal-info-component .terminal-item.extended .div-terminal-item .div-sensors-status {
        margin: 8px 0 0 6px; }
        .terminal-info-component .terminal-item.extended .div-terminal-item .div-sensors-status > div {
          padding: 2px 0;
          margin: 0 2px;
          border: solid 1px;
          text-align: center;
          font-weight: bold; }
          .terminal-info-component .terminal-item.extended .div-terminal-item .div-sensors-status > div md-icon {
            margin: 0; }
          .terminal-info-component .terminal-item.extended .div-terminal-item .div-sensors-status > div md-icon,
          .terminal-info-component .terminal-item.extended .div-terminal-item .div-sensors-status > div .icon {
            margin-right: 5px; }
  .terminal-info-component .terminal-item.simple {
    position: relative;
    min-width: 273px;
    max-width: 273px;
    width: 273px;
    margin: 3px;
    text-transform: none;
    cursor: pointer; }
    .terminal-info-component .terminal-item.simple .div-event-img {
      width: 100%;
      height: 204.75px !important;
      min-height: 204.75px; }
      .terminal-info-component .terminal-item.simple .div-event-img .event-img {
        z-index: 1; }
        .terminal-info-component .terminal-item.simple .div-event-img .event-img .ng-image-gallery-modal {
          height: 205px; }
      .terminal-info-component .terminal-item.simple .div-event-img .galleria-image {
        margin: 0 !important; }
      .terminal-info-component .terminal-item.simple .div-event-img .div-legend-img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 273px;
        padding: 5px 10px;
        z-index: 1;
        color: white;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.26); }
        .terminal-info-component .terminal-item.simple .div-event-img .div-legend-img * {
          color: white; }
        .terminal-info-component .terminal-item.simple .div-event-img .div-legend-img .img-icon-event .avatar {
          width: 30px;
          min-width: 30px;
          height: 30px;
          line-height: 27px;
          opacity: .7; }
      .terminal-info-component .terminal-item.simple .div-event-img .event-map-img {
        position: absolute;
        right: 0; }
    .terminal-info-component .terminal-item.simple .div-terminal-item {
      width: 273px;
      padding: 6px;
      color: white; }
      .terminal-info-component .terminal-item.simple .div-terminal-item .avatar {
        font-size: 14px; }
      .terminal-info-component .terminal-item.simple .div-terminal-item .info {
        margin: 0 8px 0 4px; }
        .terminal-info-component .terminal-item.simple .div-terminal-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        .terminal-info-component .terminal-item.simple .div-terminal-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        .terminal-info-component .terminal-item.simple .div-terminal-item .info .notes md-icon {
          margin-right: 5px;
          color: white; }
      .terminal-info-component .terminal-item.simple .div-terminal-item .secondary-text md-icon {
        margin: 0;
        margin-right: 5px;
        color: white; }
      .terminal-info-component .terminal-item.simple .div-terminal-item .div-sensors-status {
        margin: 8px 0 0 6px; }
        .terminal-info-component .terminal-item.simple .div-terminal-item .div-sensors-status > div {
          padding: 2px 0;
          margin: 0 2px;
          border: solid 1px;
          text-align: center;
          font-weight: bold; }
          .terminal-info-component .terminal-item.simple .div-terminal-item .div-sensors-status > div md-icon {
            margin: 0; }
          .terminal-info-component .terminal-item.simple .div-terminal-item .div-sensors-status > div md-icon,
          .terminal-info-component .terminal-item.simple .div-terminal-item .div-sensors-status > div .icon {
            margin-right: 5px; }
  .terminal-info-component .terminal-item.compact {
    position: relative;
    height: auto !important;
    min-height: 138px;
    max-height: 148px;
    min-width: 358px;
    max-width: 358px;
    width: 358px;
    margin: 6px;
    padding: 8px 8px 16px;
    text-transform: none;
    cursor: pointer; }
    .terminal-info-component .terminal-item.compact .div-event-img {
      min-height: 215px;
      width: 100%; }
      .terminal-info-component .terminal-item.compact .div-event-img .event-img {
        z-index: 1; }
        .terminal-info-component .terminal-item.compact .div-event-img .event-img .ng-image-gallery-modal {
          height: 205px; }
      .terminal-info-component .terminal-item.compact .div-event-img .div-legend-img {
        position: absolute;
        bottom: 10px;
        left: 35px;
        width: 273px;
        padding: 12px;
        z-index: 1;
        color: white;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.26); }
        .terminal-info-component .terminal-item.compact .div-event-img .div-legend-img * {
          color: white; }
        .terminal-info-component .terminal-item.compact .div-event-img .div-legend-img .img-icon-event .avatar {
          line-height: 37px;
          opacity: .7; }
      .terminal-info-component .terminal-item.compact .div-event-img .event-map-img {
        position: absolute;
        right: 0; }
    .terminal-info-component .terminal-item.compact .div-terminal-item {
      width: 325px; }
      .terminal-info-component .terminal-item.compact .div-terminal-item .avatar {
        font-size: 14px; }
      .terminal-info-component .terminal-item.compact .div-terminal-item .info {
        margin: 0 8px 0 4px; }
        .terminal-info-component .terminal-item.compact .div-terminal-item .info .title {
          font-size: 15px;
          font-weight: 500; }
        .terminal-info-component .terminal-item.compact .div-terminal-item .info .secondary-title {
          margin-left: 5px;
          font-size: 12px !important; }
        .terminal-info-component .terminal-item.compact .div-terminal-item .info .notes md-icon {
          margin-right: 5px; }
      .terminal-info-component .terminal-item.compact .div-terminal-item .secondary-text md-icon {
        margin: 0;
        margin-right: 5px; }
      .terminal-info-component .terminal-item.compact .div-terminal-item .div-sensors-status {
        margin: 8px 0 0 6px; }
        .terminal-info-component .terminal-item.compact .div-terminal-item .div-sensors-status > div {
          padding: 2px 0;
          margin: 0 2px;
          border: solid 1px;
          text-align: center;
          font-weight: bold; }
          .terminal-info-component .terminal-item.compact .div-terminal-item .div-sensors-status > div md-icon {
            margin: 0; }
          .terminal-info-component .terminal-item.compact .div-terminal-item .div-sensors-status > div md-icon,
          .terminal-info-component .terminal-item.compact .div-terminal-item .div-sensors-status > div .icon {
            margin-right: 5px; }

.toast-loading-component .div-toast-loading md-progress-circular svg path {
  stroke: #FFFFFF; }

.toast-loading-component button {
  font-weight: bold; }

.help-icon-directive {
  vertical-align: text-top;
  margin-left: 5px;
  color: #9E9E9E; }

.order-by-directive button {
  margin-left: 0 !important; }
  .order-by-directive button md-icon {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s; }
    .order-by-directive button md-icon.descending {
      -webkit-transform: rotate3d(0, 0, 0, 0);
      -moz-transform: rotate3d(0, 0, 0, 0);
      -ms-transform: rotate3d(0, 0, 0, 0);
      -o-transform: rotate3d(0, 0, 0, 0);
      transform: rotate3d(0, 0, 0, 0); }

.pagination-directive button {
  margin: 0 !important;
  -webkit-transform: opacity 0.3s ease-in-out;
  -moz-transform: opacity 0.3s ease-in-out;
  -ms-transform: opacity 0.3s ease-in-out;
  -o-transform: opacity 0.3s ease-in-out;
  transform: opacity 0.3s ease-in-out; }
  .pagination-directive button[disabled] {
    opacity: .4; }
